/** 
 * @Date:   2018-04-17T11:30:14+05:30 
 * @Project: School-Bus
 * @Filename: vehicleInfo.dir.ts
 * @Last modified by:   group10
 * @Last modified time: 2018-04-26T18:27:13+05:30
 * @Copyright: Group10 Technologies
 */

import { Observable } from 'rxjs';
import { NgRedux, select } from '@angular-redux/store';
import { AppState } from './../../../store/index';
import { StorageService } from "./../../../services/storage.service";
import { ActivatedRoute, Params, NavigationEnd, Router } from '@angular/router';
import { Component, Output, EventEmitter } from '@angular/core';
import { ApiService } from './../../../services/ApiService';
import { ImeiFinderServices } from './../../../services/imeiFinder.service';
import { UtilityServices } from '../../../services/utility.service';

declare var $: any;

@Component({
  selector: 'vehicle-info',
  templateUrl: './vehicleInfo.template.html',
  styles: [`


.clickable{
    cursor: pointer;
}


.moving{
  background: #248a00;
    padding: 3px;
    border-radius: 2px;
    font-weight: 600;
    color: #ffff;
    font-size: 10px;
    width: 17px;
    height: 17px;
    text-align: center;
}
 .halt{
   background: #f50b0b;
     padding: 3px;
     border-radius: 2px;
     font-weight: 600;
     color: #ffff;
     font-size: 10px;
     width: 17px;
     height: 17px;
     text-align: center;
     letter-spacing: 2px;
 }
 .offline{
    background: #838383;
    padding: 3px;
    border-radius: 2px;
    font-weight: 600;
    color: #ffff;
    font-size: 10px;
    width: 17px;
    height: 17px;
    text-align: center;
    letter-spacing: 2px;
}

 .list-item-container{
    display: inline-block;
    list-style: none;
    position: relative;
    width: 100%;
    border-bottom: 1px solid #e6e6e6;
    min-height: 50px;
    padding: 2%;
    cursor:default;
 }

 .updates{
   font-style: italic;
   color: grey;
 }
 /*Search bar stylings*/

 .searchbar {
     padding-top: 20px;
     padding-left: 18px;
     padding-right: 20px;
     padding-bottom: 20px;
     position: relative;
     width: 99%;
 }

 .searchbar input[type=text] {
     background-color: #f7f7f7;
     border: 1px solid lightgrey;
     height: 40px;
     border-radius: 0.2em;
 }

 .srchb {
   position: absolute;
   right: 6px;
   top: 68px;
   padding: 08px 15px;
   color: #464040;
   font-size: 15px;
 }

 .srchb:hover {
     cursor: pointer;
 }

 .popupContent{
    position: absolute;
    z-index: 999999999;
    padding: 10px;
    border: 1px solid lightgrey;
    width: 84%;
    margin-left: 18px;
    border-radius: 2px;
    background-color: #fffffff2;
    margin-top: -19px;
 }

 .field-icon {
  float: right;
  margin-left: -25px;
  margin-top: -30px;
  margin-right: -27px;
  position: relative;
  z-index: 2;
  cursor: pointer;
  font-size: 19px;
  color: lightgrey;
  cursor:pointer;
}
 
i.fa.fa-eraser.field-icon:hover {
  color: #524a4a;
}

.addedShadow{
  box-shadow: 0 5px 20px 0px rgba(0, 0, 0, 0.1);
 -moz-box-shadow: 0 5px 20px 0px rgba(0, 0, 0, 0.1);
 -webkit-box-shadow: 0 5px 20px 0px rgba(0, 0, 0, 0.1);
 height:46px;
}
.panel-default>.panel-heading {
 color: #333;
 background-color: #ffff;
 border-color: #ffff;
}
.panel {
 box-shadow: 1px 2px 4px 0px lightgrey;
}
.panel-default {
 border-color: #fdfafa;
}




`
  ],
})

export class vehicleInfoDir {

  // public vehicleData$:  Observable<any>;

  @select('trackerData') public trackerData$: Observable<any>;
  @select('vehicleData') public vehicleData$: Observable<any>;

  public VehicleInfoList: any = [];
  public routesNoAdd: any = [];
  public popUpInfo: boolean;
  public queryString: string;
  public overSpeedInput: boolean = false;
  public speedFilterInput: number;
  public offlineVehicleTime: number = 1800000;
  public trackerStatus: string;
  public routeNameMapping = new Map();
  public newAlertConfigRoutes1: any = [];
  public newAlertConfigRoutes2: any = [];
  public showTableRowLoader: boolean = false;
  public showProgressBar: boolean = false;
  public totalAPIdata = 0;
  public completedAPIdata = 0;
  public noDataFound:boolean = false;

  public beforeSortRoutes: any = [];
  public afterSortRoutes: any = [];

  public vehRouteData:any = {};

  constructor(
    public apiService: ApiService,
    public imeiservce: ImeiFinderServices,
    public storage: StorageService,
    public router: Router,
    public utilService: UtilityServices,
    private ngRedux: NgRedux<AppState>
  ) {
    // this.title = "Angular 2 simple search";
    ///    this.names = ['Prashobh','Abraham','Anil','Sam','Natasha','Marry','Zian','karan'];
  }


  @Output() VehInfoMapPanEmitterFlag: EventEmitter<any> = new EventEmitter<any>();
  ngOnChanges() {

    this.getTrackerInfo();
  }
  ngOnInit() {

    this.fetchVehicleDatas();
  }

  async fetchVehicleDatas(){
    await this.getOrgVehData().then((res:any)=>{
      if(res && res.vehDetails && Object.keys(res.vehDetails).length>0){
        this.vehRouteData = res.vehDetails;
      }
    })
    this.getVehicleDatas();
  }

  getOrgVehData(){
    return new Promise((resolve,reject)=>{
      let apiHeader = {
           data:{
               key:localStorage.getItem('scbToken'),
            //    extra: {
            //    pageJump: 1
            //  }
           }
      }
      this.apiService.getAllVehicleAllRouteReport(apiHeader).then(response => {
        let result=response;
        if(result.status=="success"){
          resolve(response.response);
        }else{
          reject('failure data');
        }
      }).catch((err)=>{
        reject(err);
      });

    })
  }
  async getVehicleDatas() {

    let routeInfo:any = [];
    let routeTags: string[] = ['Morning', 'AfterNoon', 'Evening'];
    let routeObj:any = {};
    routeObj = {
      data: {
        key: localStorage.getItem('scbToken'),
        // extra: {
        //   maxlimit: 'all'
        // },
        extra: {
          pageJump: 0,
          // orderByDateCreated: -1
          //limit:25
        },
      }
    };
    this.showTableRowLoader = true;
    this.showProgressBar = true;

    this.utilService.getApiLoopData('getRoutes', routeObj, 'getRouteInVehicleInfo', 'totalroutecnt');

    this.utilService._subject.subscribe((subData: any) => {
      if (subData.type == 'getRouteInVehicleInfo') {

        let apiRes = subData.data;

        this.totalAPIdata = subData.total ? subData.total : 0;

        routeInfo.push(...subData.data);

        this.completedAPIdata = routeInfo.length;
        this.showTableRowLoader = true;

        if ((this.completedAPIdata / this.totalAPIdata) == 1) {
          this.showProgressBar = false;
          this.showTableRowLoader = false;
        }


        if (apiRes) {

          // let apiRes = response.response;
          Object.keys(apiRes).forEach((item, index) => {
            if (apiRes[item].routeName === 'Walkers_M' || apiRes[item].routeName === 'Walkers_N' || apiRes[item].routeName === 'Walkers_A') { }
            else {



              ////console.log('5555555555555 ',apiRes[item].routeName);
              var max_chars1 = 4;
              var pad_char1 = "0";
              var numericPart11 = apiRes[item].routeName.match(/\d+/);
              numericPart11 = (new Array(max_chars1 + 1).join(pad_char1) + numericPart11).slice(-max_chars1);
              //console.log('6666666666666666666 ',numericPart11);
              if (this.routesNoAdd.includes(numericPart11)) {
                //console.log('1111111111 ',numericPart11);
                //this.routesNoAdd.push(sessions[1]);
              } else {
                this.routeNameMapping.set(apiRes[item].routeName, apiRes[item].tags);
                this.beforeSortRoutes.push(apiRes[item].routeName);
                this.afterSortRoutes.push(apiRes[item].routeName + '-' + apiRes[item].routeId);
                this.routesNoAdd.push(numericPart11);
                //this.newAlertConfigRoutes1.push({"routeName":sessions[1]+""+sessions[0]}) ;
              }


            }
            ;

          });

          var a = this.beforeSortRoutes;
          var e = this.afterSortRoutes;

          var numericPart;
          var letterPart;
          var max_chars = 4;
          var pad_char = "0";
          var b = [];
          for (var i = 0; i < e.length; i++) {
            var sessionsSplit = [];
            sessionsSplit = e[i].split('-');
            numericPart = sessionsSplit[0].match(/\d+/);
            numericPart = (new Array(max_chars + 1).join(pad_char) + numericPart).slice(-max_chars);
            letterPart = sessionsSplit[0].match(/[A-z]+/);
            b[i] = (letterPart + numericPart) + '-' + sessionsSplit[1];

          }
          b.sort(function (a, b) {
            if (a > b)
              return 1;
            if (a < b)
              return -1;
            // a must be equal to b
            return 0;
          });
          // console.log('BBBBBBBBB ', b);
          for (var i = 0; i < b.length; i++) {
            var sessionsSplitt = [];
            // console.log('KRISHNAN----- : ',b[i]);
            sessionsSplitt = b[i].split('-');
            var sessions = [];
            // console.log('Session ', sessionsSplitt);
            b[i] = sessionsSplitt[0].replace(/0+(?=[1-9])/, '-');


            sessions = b[i].split('-');

            // console.log('KRISHNAN : ',sessions[1]+""+sessions[0]);
            if(sessions[1]!=undefined && sessions[1] !=null && sessions[0]!=undefined && sessions[0]!=null){
            let pushedData = { "routeName": sessions[1] + "" + sessions[0] };
            let dataIndex = this.newAlertConfigRoutes1.findIndex((ele: any) => ele['routeName'] == pushedData['routeName']);
            if (dataIndex == -1) {
              this.newAlertConfigRoutes1.push(pushedData);
            }
            }
            // console.log(i);
            // if(i==b.length-1) this.showTableRowLoader = false;
          }
          this.checkNoData();
        }
        else {
          this.showTableRowLoader = false;
          this.showProgressBar = false;
        }
      }
    })
    // this.apiService.getRoutes(routeObj).then(response => {
    // }).catch(error => { });




    this.ngRedux.getState().vehicleData.filter((item: any) => {
      this.newAlertConfigRoutes2.push(item);
      // console.log('KRISH : ',item);;
    });
  }

  checkNoData(){
    let enableNoData:boolean = true;
    this.newAlertConfigRoutes1.map((route:any)=>{
      this.newAlertConfigRoutes2.map((item:any)=>{
        if(item.vehicleInformation.type != 'gate' && item.routeAssigned && item.routeAssigned.includes(route.routeName)) enableNoData = false;
      })
    })
    console.log(enableNoData);
    this.noDataFound = enableNoData;
  }

  getTrackerInfo = (): void => {
    this.apiService.getCurrentLocation({
      data: {
        key: localStorage.getItem('scbToken'),
        extra: {
          orderByDateCreated: -1
        }
      }
    }).then(response => {
      let result = response;
      if (result.status === 'success') {
        this.VehicleInfoList = result.response;


        this.speedFilterInput = 0;
      }
    }).catch(error => {
      console.log('Error while logging in', error);
    });
    // this.beatVehInfo();

  }

  beatVehInfo() {
    //  this.vehicleData$ =  this.ngRedux.getState().vehicleData;
    //  setTimeout( () => {
    //    this.beatVehInfo();
    //  }, 800 );
  }

  hoverMe() {
    this.popUpInfo = true;
  }
  hideHover() {
    this.popUpInfo = false;
  }

  passSearchValueH(searchText: any) {
    $('#displayText').val(searchText);
    this.queryString = 'halt';
    this.speedFilterInput = 0;
  }
  passSearchValueM(searchText: any) {
    $('#displayText').val(searchText);
    this.queryString = 'moving';
    this.speedFilterInput = 0;
  }

  passSearchValueNoUpdate(searchText: any) {
    $('#displayText').val(searchText);
    this.queryString = 'offline';
    this.speedFilterInput = 0;
  }

  passSpeedLimit(value: any) {
    this.queryString = '';
    this.speedFilterInput = value;
  }

  clearFilter() {
    this.queryString = '';
    this.speedFilterInput = 0;
  }

  getVehStatus(speed: number, gpsTime: number): string {
    let status = null;
    let currentTime = Math.floor(Date.now() / 1000);
    let gpsTimeTemp = gpsTime / 1000;
    if ((currentTime - gpsTimeTemp) >= 1800) {
      status = 'offline';
      this.trackerStatus = 'offline';
    } else if (speed <= 5) {
      status = 'halt';
      this.trackerStatus = 'halt';
    } else if (speed > 5) {
      status = 'moving';
      this.trackerStatus = 'moving';
    } else {
      // do nothing..
    }
    return status;
  }

  passRouteId(deviceId: any) {

    let emitObj = {
      vehId: deviceId,
    };
    this.VehInfoMapPanEmitterFlag.emit(emitObj);
  }

  setVehName(vehicleID: any, vehicleNumber: any, report: any) {
    this.storage.set({
      'vehicleId': '',
      'vehicleName': '',
      'report': ''
    })
    this.storage.set({
      'vehicleId': vehicleID,
      'vehicleName': vehicleNumber,
      'report': report
    });

    this.router.navigate(['/movementReport']);

  }


}

/*
Notes : npm install node-sass -g
*/
