import { Injectable } from '@angular/core';
import {} from '@types/googlemaps';

declare var google: any;
@Injectable()

export class decodeAddress {
  constructor( ) {

  }

  geocode(decodeConfig: any) {
    return new Promise((resolve, reject) => {
        let geocoder = new google.maps.Geocoder();
        geocoder.geocode(decodeConfig, function(results: any, status: any) {
          if(results!=null && results.length){
            resolve(results[0]);
          }else  reject('Error: No response from map.');
        });
    });
  }

}
