import { Component, OnInit, ViewChild, Input } from '@angular/core';
declare const Highcharts: any;
@Component({
    selector: "g10-ms-donut-chart",
    templateUrl: "./donut-chart.component.html",
    styleUrls: ["./donut-chart.component.css"]
})

export class DonutChartComponent{
    @Input() _data:any;

    constructor(){}
    ngOnInit() {
        this.callDonutChart(this._data);
        // console.log('Donut_data',this._data)
    }
    callDonutChart(pichartArrayObj:any){  
        // console.log('Donut Ob', pichartArrayObj)
        let parent=this;
        Highcharts.chart('donutChart', {
            chart: {
                   plotBackgroundColor: null,
                   plotBorderWidth: null,
                   plotShadow: false
               },
               title: {
                   style: {
                       fontSize:'100%'
                   },                
                   text: 'Overall Performance Chart',
                //    align: 'center',
                //    verticalAlign: 'middle'
               },
               credits: {
                enabled: false
              },
               legend: {
                align: 'center',
                reversed: true,
                // padding: 50,
                // symbolHeight: 6,
                // symbolWidth: 6,
           },
               tooltip: {
                 pointFormat: 'Percentage: <b>{point.percentage:.1f}%</b><br/>' + 'Count: <b>{point.totCnt}</b><br/>'
               },
               plotOptions: {
                   pie: {
                       allowPointSelect: true, borderWidth: 0,
                       shadow: {
                            color: '#a4a4a4',
                            offsetX: 0,
                            offsetY: 0,
                            opacity: '0.5',
                            width: 3
                        },
                    //    center: [90, 90],
                       cursor: 'pointer',
                       innerSize: '30%',
                       showInLegend: true,
                       dataLabels: {                          
                        enabled: true,                    
                      style: {
                         textOutline: false
                      },                    
                       distance: -35,
                       format: '{point.percentage:.1f}%',                      
                       },
                       size:205,                     
                   },
                 },
               series: [{
                   type: 'pie',
                   name: '',
                   dataLabels: {
                           enabled: true,
                           format: '{point.percentage:.1f}%',
                       },
                   data:  pichartArrayObj,
                   point: {
                    events: {
  
                    }
                }
               }],
               exporting: {
                enabled: false
              }
               
         });
  
      }

}