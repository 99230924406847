import { Pipe, PipeTransform } from '@angular/core';
import { StorageService } from "../../services/storage.service";
import { filter } from 'rxjs/operators';

@Pipe({
    name: 'SessionPipe'
})

export class SessionPipe implements PipeTransform {
    allRouteInfo: any;
    noonval: any = [];
    mornval: any = [];
    eveningval: any = [];
    val: any;
    constructor(public storage: StorageService) {
        // this.allRouteInfo = this.storage.get('AllRouteInfo');
        Promise.resolve(this.storage.get('AllRouteInfo')).then((res: any) => {
            this.allRouteInfo = res;
        })
    }

    transform(vehList: any = [], session: any, returnData: boolean = false): any {

        let returnResult: any = [];

        if (session == '' || session == undefined || session == null) {
            return vehList;
        }
        // this.fetchRouteSession(session);

        const keys = Object.keys(this.allRouteInfo);

        let sessionCollected: any = [];
        keys.map((key: any) => {
            let tags = this.allRouteInfo[key].tags;
            tags = tags.map((item: string) => item.toLowerCase())
            // const tags_lowercase = tags.toLocaleString().toLowerCase().split(',');
            if (tags.indexOf(session.toLowerCase()) >= 0) {
                sessionCollected.push(this.allRouteInfo[key].vehNo);
                if (returnData) returnResult.push(this.allRouteInfo[key])
            }

        });

        if (returnData === true) {
            return returnResult;
        }

        return (vehList || []).filter((item: any) => sessionCollected.indexOf(item.VehicleNo) >= 0);

    }


    // fetchRouteSession(str: string) {


    //     if (str === 'Morning' || str === 'Afternoon' || str === 'Evening') {
    //         let key = Object.keys(this.allRouteInfo);
    //         key.map((v) => {
    //             if (this.allRouteInfo[v].tags[0] === str) {
    //                 if (str === 'Afternoon') {
    //                     this.mornval = []; this.eveningval = [];
    //                     this.allRouteInfo[v].vehNo != undefined ? this.noonval.push(this.allRouteInfo[v].vehNo) : false;
    //                 }
    //                 if (str === 'Morning') {
    //                     this.noonval = []; this.eveningval = [];
    //                     this.allRouteInfo[v].vehNo != undefined ? this.mornval.push(this.allRouteInfo[v].vehNo) : false;
    //                     // this.mornval.push(this.allRouteInfo[v].vehNo)
    //                 }
    //                 if (str === 'Evening') {
    //                     this.noonval = []; this.mornval = [];
    //                     this.allRouteInfo[v].vehNo != undefined ? this.eveningval.push(this.allRouteInfo[v].vehNo) : false;

    //                     // this.eveningval.push(this.allRouteInfo[v].vehNo)
    //                 }

    //             }
    //         })
    //     } else;
    //     if (str === "Morning") {
    //         console.log(this.val);
    //         console.log(this.mornval);
    //         return this.mornval;
    //     }
    //     if (str === "Evening") {
    //         console.log(this.eveningval);

    //         return this.eveningval;
    //     }
    //     if (str === "Afternoon") {
    //         // console.log(this.noonval);
    //         return this.noonval;
    //     }
    //     }
}