import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { roleFormModule } from './../../model/role-model';
import { Objectkeys } from './../../custom-pipes/objectkeys.pipe';
import { ApiService } from './../../services/ApiService';
import { COM } from './../../services/com.services';
import { FormControl, FormGroup, Validators, FormBuilder } from '@angular/forms';
import { ExcelService } from './../../services/excel.service';
import { excelUpload } from './../../services/excelUploadService'

const swal = require('sweetalert');
declare var $: any; declare var alasql: any;;

@Component({
    selector: 'student-route-management',
    templateUrl: './student-route-management.html',
    styleUrls: ['./student-route-management.css']
})
export class StudentRouteManagementComponent implements OnInit {

    public formControl: any;
    public Role: any = {};
    public roleControl: any;
    public orgReportStatus: any;
    public formError: any = {};
    studentForm: FormGroup;
    noData: boolean;
    listExcelConfiguration: any[]
    public showLoader: boolean = false;
    public newExcelObj: any = [];
    public fileNameFlag: any;
    inputData: any = {};
    propName: any
    colObj: any;
    serialNo: any
    constructor(
        private router: Router,
        private roleModule: roleFormModule,
        private api: ApiService,
        private com: COM,
        private excel: ExcelService,
        public excelUpload: excelUpload,


    ) {

        this.studentForm = new FormGroup({
            serialNo: new FormControl("S No", Validators.required),
            studentID: new FormControl("Student ID", Validators.required),

            studentName: new FormControl("Student Name", Validators.required),
            class: new FormControl(" Class", Validators.required),
            section: new FormControl(" Section", Validators.required),
            primaryMobileNo: new FormControl("Primary Contact Number", Validators.required),
            secondaryMobileNo: new FormControl(" Secondary Contact Number", Validators.required),

            pickupRoute: new FormControl("Pickup Route", Validators.required),
            pickupPoint: new FormControl("Pickup Point", Validators.required),
            pickupETA: new FormControl("Pickup ETA", Validators.required),
            dropRoute: new FormControl("Drop Route", Validators.required),
            dropPoint: new FormControl("Drop Point", Validators.required),

            dropETA: new FormControl("Drop ETA", Validators.required),


        });
    }

    ngOnInit() {
        this.getExcelconfigurationList()
    }

    navigate(url: string) {
        this.router.navigate(['/' + url, {}]);
    }
    getExcelconfigurationList() {
        let apiData = {
            data: {
                key: localStorage.getItem('scbToken'),
            }
        }
        this.api.getExcelconfigurationList(apiData).then((res: any) => {

            if (res.status === 'success') {
                this.inputData = res



            }
        })


    }
    downloadTemplate() {

        const fileName = 'Student Route Mapping Template';
        let excelDownloadData: any = [];

        let defaultColumn = Object.keys(this.studentForm.value);

        let newObject = {};
        let newObject1 = {};

        for (let column of defaultColumn) {
            newObject[this.inputData.response.serialNo] = "1";
            newObject[this.inputData.response.studentID] = "SD101";
            newObject[this.inputData.response.studentName] = "Varun";
            newObject[this.inputData.response.class] = "V";
            newObject[this.inputData.response.section] = "B";
            newObject[this.inputData.response.primaryMobileNo] = "9999999999";
            newObject[this.inputData.response.secondaryMobileNo] = "9999999998";
            newObject[this.inputData.response.pickupRoute] = "Route-P01";
            newObject[this.inputData.response.pickupPoint] = "C.R. Park Opp. SBI";
            newObject[this.inputData.response.pickupETA] = "07:00";
            newObject[this.inputData.response.dropRoute] = "Route-D01";
            newObject[this.inputData.response.dropPoint] = "C.R. Park Opp. SBI";
            newObject[this.inputData.response.dropETA] = "13:00";
            // newobject1
            newObject1[this.inputData.response.serialNo] = "2";
            newObject1[this.inputData.response.studentID] = "SD102";
            newObject1[this.inputData.response.studentName] = "Premendra Shankar";
            newObject1[this.inputData.response.class] = "V";
            newObject1[this.inputData.response.section] = "A";
            newObject1[this.inputData.response.primaryMobileNo] = "7777777777";
            newObject1[this.inputData.response.secondaryMobileNo] = "8888888810";
            newObject1[this.inputData.response.pickupRoute] = "Route-P02";
            newObject1[this.inputData.response.pickupPoint] = "Le Royal Meridian";
            newObject1[this.inputData.response.pickupETA] = "07:20";
            newObject1[this.inputData.response.dropRoute] = "Route-D02";
            newObject1[this.inputData.response.dropPoint] = "Le Royal Meridian";
            newObject1[this.inputData.response.dropETA] = "15:00";
        }
        excelDownloadData = [newObject, newObject1];

        this.excel.exportExcel(excelDownloadData, fileName);
    }
    onFileChange(event: any) {
        let reader = new FileReader();
        let parent: any = this;
        this.showLoader = true;
        let colObj = this.inputData.response
        if (event.target.files && event.target.files.length > 0) {
            let file = event.target.files[0];

            var apiKey = localStorage.getItem('scbToken');
            this.excelUpload.studentRouteMappingUpload(apiKey, file, 'pickup-input', function (apiResponse: any) {
                if (apiResponse.status == 'success') {
                    parent.showLoader = false;

                    let div = document.createElement("div");
                    div.innerHTML = "<div style='overflow:auto'>  Uploaded File Name : " + file.name + " <br> " + 'Student info update uploaded successfully' + "</div>";
                    swal({
                        title: 'Success',
                        content: div,
                        icon: 'success',
                    });

                    setTimeout(() => {
                        swal.close();
                    }, 2000);
                } else if (apiResponse.status == 'failure') {
                    parent.showLoader = false;
                    if (apiResponse.response) {
                        parent.newExcelObj = [];

                        let apiRepsponceArray: any = [];
                        let errorArray: any = [];
                        let orgError: any = [];
                        apiRepsponceArray = apiResponse.response.invalidData;
                        Object.keys(apiRepsponceArray).forEach(function (key: any) {
                            errorArray = apiRepsponceArray[key];



                            parent.newExcelObj.push(errorArray);
                            for (var index in errorArray) {

                                orgError.push(errorArray[index]);
                            }
                            let div = document.createElement("div");


                            if (apiResponse.ec == 'SCB22') {
                                parent.fileNameFlag = '3';
                                div.innerHTML = "<div style='overflow:auto'> Uploaded File Name : " + file.name + " <br> Invalid Mobile No <br><button  id='downloadID' class='btn btn-sm btn-info '>Download_Failure_Report</button></div>";
                            } else {
                                parent.fileNameFlag = '4';
                                //div.innerHTML = "<div style='overflow:auto'> Uploaded File Name : "+file.name + " <br> Duplicated <br>" + orgError + "</div>";
                                div.innerHTML = "<div style='overflow:auto'> Uploaded File Name : " + file.name + " <br> Duplicated <br><button  id='downloadID' class='btn btn-sm btn-info '>Download_Failure_Report</button></div>";
                            }
                            swal({
                                title: 'Failure',
                                content: div,
                                icon: 'error',
                            });

                            var downloadEvent = document.getElementById('downloadID');
                            if (downloadEvent) {
                                downloadEvent.addEventListener("click", (e: Event) => parent.downloadLink());
                            }


                        });




                    } else {
                        if (apiResponse.ec == 'SCB29') {
                            parent.showLoader = false;
                            swal("Failure", "invalid excel Data", "error", {
                                timer: 2000
                            });
                        }
                    }

                }
            });
        }
    }


    downloadLink(value: any) {

        let parent: any = this;
        parent.CreateCSV(this.newExcelObj);
    }
    CreateCSV(ExcelObj: any) { //console.log(ExcelObj);

        let sheetName = { sheetid: 'StudentDetails', header: true };
        /// console.log('ExcelObj',ExcelObj);
        let date: Date = new Date();
        let getFileName = '';
        if (this.fileNameFlag == '1' || this.fileNameFlag == '3' || this.fileNameFlag == '5') {
            getFileName = 'Invalid_Mobile_No_Upload_Failure_Report_' + date.getDate() + '-' + date.getMonth() + '-' + date.getFullYear() + ' ' + date.getHours() + ':' + date.getMinutes() + ':' + date.getSeconds() + '.xlsx';
        }
        if (this.fileNameFlag == '2') {
            getFileName = 'User_Not_Exists_Failure_Report_' + date.getDate() + '-' + date.getMonth() + '-' + date.getFullYear() + ' ' + date.getHours() + ':' + date.getMinutes() + ':' + date.getSeconds() + '.xlsx';
        }
        if (this.fileNameFlag == '4' || this.fileNameFlag == '6') {
            getFileName = 'Duplicate_Upload_Failure_Report_' + date.getDate() + '-' + date.getMonth() + '-' + date.getFullYear() + ' ' + date.getHours() + ':' + date.getMinutes() + ':' + date.getSeconds() + '.xlsx';
        }

        //if(ExcelObj.length>0){
        let fileName = "";

        alasql('SELECT INTO XLSX("' + getFileName + '",?) FROM ?',
            [sheetName, ExcelObj]);


    }


}

// let tempObj = {
//     'ID': errorArray.studentID,
//     'Name': errorArray.studentName,
//     'Class': errorArray.class,
//     'Section': errorArray.section,
//     'Primary Mobile No': errorArray.MemberPrimaryMobileNo,
//     'Drop ETA': errorArray.MemberSecondaryMobileNo,
//     "Drop Point": errorArray.dropPoint,
//     "Drop Route": errorArray.dropRoute,
//     "Pickup ETA": errorArray.pickupETA,
//     "Pickup Point": errorArray.pickupPoint,

//     "Pickup Route": errorArray.pickupRoute

// }


// let apiRepsponceArray: any = [];
// let errorArray: any = [];
// let orgError: any = [];
// apiRepsponceArray = apiResponse.response.invalidData;
// Object.keys(apiRepsponceArray).forEach(function (key: any) {
//     errorArray = apiRepsponceArray;
//     console.log(errorArray)
//     parent.newExcelObj.push(errorArray);
//     div.innerHTML = "<div style='overflow:auto'> Uploaded File Name : " + file.name + " <br> Duplicated <br><button  id='downloadID' class='btn btn-sm btn-info '>Download_Failure_Report</button></div>";

//     console.log(colObj)
//     swal({
//         title: 'Failure',
//         content: div,
//         icon: 'error',
//     });
//     var downloadEvent = document.getElementById('downloadID');
//     if (downloadEvent) {
//         // el.addEventListener('click', parent.jj, false);
//         downloadEvent.addEventListener("click", (e: Event) => parent.downloadLink());
//     }
// }