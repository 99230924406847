import {Pipe,PipeTransform} from '@angular/core';

@Pipe({name:'secondsToHhMm'})

export class SecondsFormatter implements PipeTransform {
    transform(totalSeconds:number):any{
         let hours   = Math.floor(totalSeconds / 3600);
         let minutes = Math.floor((totalSeconds - (hours * 3600)) / 60);
         let seconds = totalSeconds - (hours * 3600) - (minutes * 60);
         seconds = Math.round(seconds * 100) / 100;

         let result = "";
         if(hours) result = (hours < 10 ? "0" + hours : hours) + " h ";
         if(minutes) result += (minutes < 10 ? "0" + minutes : minutes) + " m ";
         result += (seconds  < 10 ? "0" + seconds : seconds) + " s";
         return result;
    }
}
