/**
 * @Author: Deepak Pookkote <group10>
 * @Date:   2018-05-08T17:15:45+05:30
 * @Email:  deepak@groupten.com
 * @Project: School-Bus
 * @Filename: mobileDashboard.ts
 * @Last modified by:   group10
 * @Last modified time: 2018-05-09T12:51:36+05:30
 * @Copyright: Group10 Technologies
 */



/*Auther:Deepak */
import {
  Component,
  ElementRef,
  AfterViewInit,
  OnDestroy,
  ViewChild,
} from '@angular/core';
import { RouterModule, Routes, Router } from '@angular/router';
import { AppComponent } from '../../../app.component';

import { globalService } from '../../../services/global.service';
import { ApiService } from '../../../services/ApiService';
import { authGaurd } from '../../../services/auth-guard.service'
import { StorageService } from '../../../services/storage.service';
import { NG_VALIDATORS, Validator, Validators, AbstractControl, ValidatorFn } from '@angular/forms';

declare var swal: any

@Component({
  //template: ``,
  templateUrl: './student-info.html',
  styles: [`
       tbody {
            display:block;
            height:350px;
            overflow:auto;
        }
        thead, tbody tr {
            display:table;
            width:100%;
            table-layout:fixed;
        }
        thead {
            width: calc( 100% - 1em )
        }

        thead > tr {
            font-weight : 600;
        }

        .table>tbody>tr>td,.table>tfoot>tr>td, .table>tfoot>tr>th, .table>thead>tr>td {
            border-top: 1px solid #f7f5f5;
        }
        .tableContainer{
            padding: 10px;
            border: 1px solid #f7f7f7;
            box-shadow: -3px 2px 2px 1px #f1f1f1;
        }

        .table> thead > tr > td {
            border-top: none;
        }      
        .layers {
            -webkit-box-orient: vertical;
            -webkit-box-direction: normal;
            -ms-flex-flow: column nowrap;
            flex-flow: column nowrap;
            -webkit-box-align: center;
            -ms-flex-align: center;
            align-items: center;
            box-shadow: 1px 1px 0px 0px lightgrey;
          }
    
      .mB-10 {
        margin-bottom: 10px!important;
      }
      .lh-1 {
        line-height: 1!important;
      }
    
    
      .field-icon {
            float: right;
            margin-left: -25px;
            margin-top: -25px;
            margin-right:5px;
            position: relative;
            z-index: 2;
            cursor:pointer;
        }
        .section-title-border {
            width: 8%;
            margin-bottom: 20px;
            border-bottom: 1px solid #00BCD4;
            border-bottom-width: medium;
            border-spacing: 1px;
        }
     
      `],
})
export class MobileStudentInfoComponent {


  private apiToken: any = localStorage.getItem("adminToken");
  public pageJump: number = 0;
  private showFilter: boolean = false;
  public showHostoryLog: boolean = true;

  public studentList: any = {};
  public studentDetails: any = [];
  public studentInfo: any = [];
  public MemberName: any = "";
  public MemberSection: any = "";
  public searchPhone: any = "";
  public MemberUid: any = "";

  public noMoreData: boolean = false;
  public limitOfRecords: number;
  public srchMobilenum: any = "";
  public filterObj: any = {};
  public filterStarted: boolean = false;
  public selectedFilter: any = "";
  public selectedOrder: any = "";
  deviceFilterObj: {};
  isLoading: boolean;


  /*Report Selection variables Ends */

  constructor(public globalService: globalService,
    public apiService: ApiService,
    public authGaurd: authGaurd,
    private storage: StorageService,
    public router: Router) {


  }
  private currentOrganization: any = this.storage.get('orgID');

  ngOnInit() {
    this.viewStudentList()
  }
  filterFunction() {
    this.deviceFilterObj = {};


    if (this.srchMobilenum) Reflect.set(this.deviceFilterObj, 'mobileno', this.srchMobilenum);
    console.log(this.srchMobilenum)

    this.viewStudentList();

  }
  clearFilters(value: any) {
    this.srchMobilenum = "";
    this.deviceFilterObj = {};
    this.viewStudentList();
  }
  viewStudentList = () => {

    var infoObj = {
      data: {
        key: this.apiToken,
        extra: {
          pageJump: this.pageJump,
        },
        // mobileno: this.searchPhone
      }
    };
    if (this.deviceFilterObj) {
      infoObj['data']['form'] = {};
      infoObj['data']['form'] = this.deviceFilterObj;
    }
    this.apiService.getStudentInfoMobile(infoObj).then(response => {


      if (response) {
        if (response != '' && response != undefined && response != null) {
          this.studentList = response.response; // here response is API output

          this.studentDetails = this.studentList
          if (this.pageJump == 0) {
            this.limitOfRecords = this.studentDetails.length;
          }
        }
      };
    }).catch(error => { });
  }

}

