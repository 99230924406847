/**
 * @Author: Deepak Pookkote <group10>
 * @Date:   2018-04-25T11:00:31+05:30
 * @Email:  deepak@groupten.com
 * @Project: School-Bus
 * @Filename: login.ts
 * @Last modified by:   group10
 * @Last modified time: 2018-05-22T15:48:36+05:30
 * @Copyright: Group10 Technologies
 */



/*Auther:Deepak */
import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { ApiService } from './../../services/ApiService';
import { GlobalUtils } from './../../services/global-utils';

declare var $: any;
@Component({

  templateUrl: './login.component.html',
  styles: [`
     $color1: #f4f4f4;
     $color2: #3197EE;

     .login-parent-div img{
        width: 100%;
        background-color: #f2f6fb;
      }



      .heightInc{
        height: 20vh;
      }

      .text-danger{
        padding: 5px;
        color: #de5350;
      }


     .login-form {
         width: 400px;
         margin: 28% auto 0 auto;
         font-size: 16px;
     }

     .IntroVideoLink{
        color: #8C96A9;
        text-decoration: none;
        //outline: none;
    }

     /*css for ripple pin points */

     /*Designate how the animation will take place*/
     @keyframes pulsate {
         0% {
         transform: scale(0.1);
         opacity: 0;
         }
         50% {
         opacity: 1;
         }
         100% {
         transform: scale(1.2);
         opacity: 0;
         }
     }

     /* .wrap is our container div and will vary in name on your site */
     .wrap {
         width: 90%;
         max-width: 672px;
         margin: 0 auto;
     }


     /* Alter the top and left positions to sit on top of your map location */
     .marker {
         width: 100px;
         height: 100px;
         position: absolute;
         top: 130px; /*positions our marker*/
         left: 200px; /*positions our marker*/
         display: block;
     }

     .pin {
         width: 20px;
         height: 20px;
         position: fixed;
         top: 288px;
         left: 486px;
         background: #81D4FA;
         border: 2px solid #FFF;
         border-radius: 50%;
         z-index: 1000;
     }

     .pin-effect {
         width: 100px;
         height: 100px;
         position: fixed;
         top: 248px;
         left:447px;
         display: block;
         background: rgba(3, 169, 244, 0.57);
         border-radius: 50%;
         opacity: 0;
         animation: pulsate 2400ms ease-out infinite;

     }

     /* Adjusts the background image and marker for smaller screens */
     @media only screen and (max-width: 414px) {
         .map-bg {
             background-position: -100px 0px;
         }

         .marker {
             left: 100px;
         }
     }
     .modal-content iframe{
        margin: 0 auto;
        display: block;
    }

    .videoBtn{
      background-color: #fdfdfd;
      border-color: #f5f3f3;
    }

    .passwordAssistant{
      border: 1px solid lightgray;
      border-radius: 3px;
    }
    .customHeader{
      padding: 20px;
      font-size: 28px;
      font-weight: 400;
      text-align: center;
      text-transform: capitalize;
    }

    .restSubmit {
      box-sizing: border-box;
      display: block;
      width: 100%;
      border-width: 1px;
      border-style: solid;
      padding: 16px;
      outline: 0;
      font-family: inherit;
      font-size: 0.95em;
      background: #28d;
      border-color: transparent;
      color: #fff;
    }

    .resetSubmit : hover {
      background:#2e78b7; 
    }

    .field-icon {
      float: right;
      margin-left: -25px;
      margin-top: -40px;
      margin-right: 8px;
      position: relative;
      z-index: 2;
      cursor: pointer;
      font-size: 19px;
      color: #6d6d6d;
    }
    .forgot-password-text-new :hover{
      color:#12a1cc;
    }
    .assistanceText :hover{ 
      color:#77839a;
    }

   `],
})
export class LoginPageComponent {
  public email: string = "";
  public password: string = "";
  public orgAdminUserName: string = "";
  public orgAdminPassword: string = "";
  public userNameError: boolean;
  public userPasswordError: boolean;
  public apiResponseMesage: string;
  public InvalidCredentials: boolean = false;
  public showLogin: boolean = true;
  public passwordAssistance = false;
  public beoforeResponse = true;
  public responseSuccess = false;
  public resetEmail: string = "";
  public assitanceForm: boolean = true;
  public passType: string = 'password';
  public passClass: string = 'fa fa-eye-slash field-icon toggle-password';
  public showLoading: boolean = false;

  constructor(private apiService: ApiService, private globalUtils: GlobalUtils,
    public router: Router) {

    //localStorage.setItem("scbToken",'');
    localStorage.removeItem('scbToken');


  }

  ngOnInit() {
    //localStorage.setItem("scbToken",'');
    localStorage.removeItem('scbToken');
    history.pushState(null, null, location.href);
    window.onpopstate = function () {
      history.go(1);
    };
  }


  initiateOrgUserLogin() {
    this.apiResponseMesage = '';
    this.InvalidCredentials = false;
    if (this.initiateFormValidation()) {
      this.apiService.orgAuthentication({
        data: {
          form: {
            loginname: this.orgAdminUserName,
            password: this.orgAdminPassword,
          }
        }
      }, 'orgadmin', 'organization/orgadminauthentication').then(response => {
        if (response.status == 'success') {
          let res = response;

          localStorage.setItem("scbToken", res.token);
          localStorage.setItem('orguserName', this.orgAdminUserName);
          localStorage.setItem('userLevel', res.uT);

          if (res['rInfo'] == undefined && this.orgAdminUserName) {
            res['rInfo'] = {};
            res['rInfo']['menu'] = {
              Dashboard: true
            }
          }
          if (res.rInfo) {

            localStorage.setItem('userInfo', JSON.stringify(res.rInfo));
          }
          // console.log("hi", response)
          setTimeout(() => {
            this.router.navigate(['/client-dashboard']);
          }, 300);
        } else {
          //fetch status codes....
          // console.log(this.globalUtils.getErrorMsg(response.ec));
          localStorage.setItem("scbToken", '');
          localStorage.setItem("orguserName", '');
          this.router.navigate(['/login']);
          localStorage.setItem('userInfo', '');
          localStorage.setItem('userLevel', '');
          this.apiResponseMesage = this.globalUtils.getErrorMsg(response.error.ec);
          this.InvalidCredentials = true;
        }


      }).catch(error => {
        // console.log("Error !", error);
      });
    }


  }


  characterValidator(event: any) {
    event.srcElement.value = event.srcElement.value.replace(/[`~!@#$%^&*()_|+\-=?;:'",.<>\{\}\[\]\\\/]/gi, '');
    if (event.srcElement.value != '') {
      //  console.log('t',event.srcElement.value);
      if ((event.which < 48 || event.which > 57)) {
        event.preventDefault();
      }
    } else {
      // event.srcElement.value=0;
    }

  }

  passwordValidator(event: any) {
    event.srcElement.value = event.srcElement.value.replace(/[()\-\[\];':"\\|,.+<>\/\s]/gi, '');
    if (event.srcElement.value != '') {
      //  console.log('t',event.srcElement.value);
      if ((event.which < 48 || event.which > 57)) {
        event.preventDefault();
      }
    } else {
      // event.srcElement.value=0;
    }
  }


  initiateFormValidation() {
    let isValid: boolean = true;
    //Org User
    if (!this.orgAdminUserName) {
      this.userNameError = true;
      isValid = false;
    } else {
      this.userNameError = false;
    }


    //Org Password
    if (!this.orgAdminPassword) {
      this.userPasswordError = true;
      isValid = false;
    } else {
      this.userPasswordError = false;
    }

    return isValid;
  }


  initiateForgotPassowrd() {
    let emailObj = {
      data: {
        form: {
          email: this.resetEmail
        }
      }
    }
    this.apiService.forgotPassword(emailObj).then(response => {
      if (response.status == "success") {
        this.responseSuccess = true;
        this.assitanceForm = false;

        this.beoforeResponse = false;
      } else {
        //console.log(this.globalUtils.getErrorMsg(response.ec));
      }
    }).catch(error => {
      // console.log('Error while Resetting in', error)
    });
  }

  resetFieldFunction() {
    this.resetEmail = '';
    this.orgAdminUserName = "";
    this.orgAdminPassword = "";
    this.responseSuccess = false;
    this.beoforeResponse = true;
  }

  hideValidation() {
    this.userNameError = false;
    this.userPasswordError = false;
    this.InvalidCredentials = false;
  }

  //  tooglepassword(){
  //   $(".toggle-password").click(function() {
  //   $(this).toggleClass("fa-eye fa-eye-slash");
  //     let x:any = document.getElementById("uPassword");
  //     if (x.type === "password") {
  //         x.type = "text";
  //     } else {
  //         x.type = "password";
  //     }
  //   });
  // }

  autofocus() {
    $(function () {
      $("#userEmail").focus();
    });
  }

  toggleIcon() {
    if (this.passType == 'password') {
      this.passType = 'text';
      this.passClass = 'fa fa-eye field-icon toggle-password';
    } else {
      this.passType = 'password';
      this.passClass = 'fa fa-eye-slash field-icon toggle-password';
    }
  }

  showLoader() {
    this.showLoading = true;
  }





}
