import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ApiService } from './../../../services/ApiService';
import { bootstrapDateTime } from './../../../services/bootstrapDateTime/datePicker';
import { StorageService } from './../../../services/storage.service';
import { COM } from './../../../services/com.services';
import { ApiCallDateFormatService } from "../../../services/api-call-date-format.service";
import { array_chunk } from './../../../utilities/common';


@Component({
    providers: [bootstrapDateTime],
    selector: 'rfid-status-check',
    template: `
    <div *ngIf="rfidStatusReports && loadCompo=== true">
        <div class="col-md-12" >
        <div class="col-md-6" ></div>

        </div><br><br>   
       <div class="table-content"> 
            <table class=" table table-stripped table-bordered" cellpadding="0" cellspacing="0" border="0">            
            <thead>
                <tr>
                    <th class="text-center">#</th>
                    <th class="text-center">Vehcile Name</th>
                    <th class="text-center">Route Name</th>
                    <th class="text-center"> Time /  Loc </th> 
                    <th class="text-center">Status</th>
                    </tr>
            </thead>
            <tbody>
                <ng-container *ngFor="let record of rfidStatusReports|objectkeys;let i = index;" >                 
                    <tr >
                        <td style="text-align:right" >{{ i+1 }}</td>
                        <td style="text-align:left">  
                            <span>{{ rfidStatusReports[record].vehName }}</span>
                        </td>
                        <td style="text-align:left">                   
                        <span>{{ rfidStatusReports[record].routeName }}</span>
                       </td>
                       <td style="text-align:left">
                            <span>   {{ rfidStatusReports[record].time | date: 'hh:mm a' }}</span>
                            <hr style="margin: 0px;">
                            <span title="{{rfidStatusReports[record].location}}" style="font-size: 12px;">
                            {{ ( rfidStatusReports[record].location?.length > 25 )? (rfidStatusReports[record].location | slice:0:25 )+'...' : (rfidStatusReports[record].location) }} 
                        </span> 
                       </td>
                       <td style="text-align:center">
                       <span *ngIf="rfidStatusReports[record].rfidId" ><i class="fa fa-check fa-1g" aria-hidden="true" style="color: green;"></i></span>
                           <span *ngIf="!rfidStatusReports[record].rfidId"><i class="fa fa-times fa-1g" aria-hidden="true" style="color: red;"></i></span>
                   
                       </td> 
                        
                </tr>
                </ng-container>
            </tbody>
            </table>
        </div>
    </div>
  
    <div class="" *ngIf="rfidStatusReports === 'null' && loadCompo=== true ">
    <h4 class="animated pulse text-center"  style="animation-duration: 1s;color:#999FBF; margin:70px;"><i class="fa fa-frown-o"></i>&nbsp;
                           No Data to Display</h4>
</div>

    <div class="loaderContainer" *ngIf="pageLoader">
   <loader></loader>
</div>
    
    `,

    styles: [`
    

table td, table th {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: center;
}
`
    ]
})

export class RfidStatusCheckComponent {
    public routeNameNull: any;
    public pageLoader: boolean;
    public rfidStatusReports: any = [];
    public rfidRouteDate: any;
    public selectedDaySection: any;
    public selectedRoute: any;
    public walkerVehId: any = [];
    public currentDate: any;
    public loadCompo: boolean;
    public enableDisableRFIDStatusCheckSubmitBtn:boolean=false;
    public vehInfo: any = {};
    constructor(
        private api: ApiService,
        public dateChange: ApiCallDateFormatService,
        private storage: StorageService,
        private com: COM
    ) {

        this.com.comAction.subscribe((e: any) => {
            this.pageLoader = true;
            this.loadCompo = false;
            if (e.type === 'rfidstatuscheck') {
                
                // this.enableDisableRFIDStatusCheckSubmitBtn=true;
                this.loadCompo = true;
                this.loadData(e);
                
            }
        });
    }
 @Output() disable = new EventEmitter();
    loadData(obj: any) {
         //added by krish
         
        this.rfidStatusReports = [];
        let sTime: any;
        let eTime: any;
        let selectedRoute: any = [];
        let routeInfo = this.storage.get('AllRouteInfo');
        let selectedVehInfo = [];
        const startDate = obj.sdate;
        const currentDate = obj.cdate;
        const session = obj.session;
        const route = obj.route;
        let routeData: any = {};
        this.vehInfo = obj.vehInfo;
        let routeType = obj.routeType;
        this.walkerVehId = obj.walkerVehId;
        // console.log(routeInfo);
        // console.log(startDate, currentDate, session, route);

        (Object.keys(routeInfo)).forEach((routeId) => {

            if (routeData[routeInfo[routeId].vehId] === undefined) {
                routeData[routeInfo[routeId].vehId] = [];
            }
               let routeArr = routeInfo[routeId].tags.map((caseConversion:any) => caseConversion.toLowerCase());
           
            // if (session == 'fn' && routeInfo[routeId].tags[0] == 'Morning') {
                if(session == 'fn' && routeArr.includes('morning')){
                sTime = '06:00:00';
                eTime = '11:00:00';

                selectedRoute.push(routeInfo[routeId].vehId);
                routeData[routeInfo[routeId].vehId].push({
                    routeName: routeInfo[routeId].routeName,
                    vehNo: routeInfo[routeId].vehNo
                });

                } else if (session == 'an' && routeArr.includes('afternoon')){

            // } else if (session == 'an' && routeInfo[routeId].tags[0] == 'Afternoon') {

                sTime = '11:00:01';
                eTime = '14:00:00';
                selectedRoute.push(routeInfo[routeId].vehId);
                routeData[routeInfo[routeId].vehId].push({
                    routeName: routeInfo[routeId].routeName,
                    vehNo: routeInfo[routeId].vehNo
                });
            } else if (session == 'en' && routeArr.includes('evening')){
            // } else if (session == 'en' && routeInfo[routeId].tags[0] == 'Evening') {

                sTime = '14:00:01';
                eTime = '21:00:00';
                selectedRoute.push(routeInfo[routeId].vehId);
                routeData[routeInfo[routeId].vehId].push({
                    routeName: routeInfo[routeId].routeName,
                    vehNo: routeInfo[routeId].vehNo
                });
            }
        });
        
        // return false;
        this.rfidRouteDate = routeData; //console.log(this.rfidRouteDate);

        let vehIds: any = [];
        if (route || routeType) {

            if (routeType === 'Walkers_M' || routeType === 'Walkers_N' || routeType === 'Walkers_A') {
                //  vehIds.push(this.selectedRoute);
                this.walkerVehId.concat(vehIds);
                selectedVehInfo = this.walkerVehId;
            } else {
                //vehIds.push(this.selectedRoute);
                
                vehIds.push(route);
                selectedVehInfo = vehIds;
            }

        } else {

            vehIds = selectedRoute;
            selectedVehInfo = this.walkerVehId.concat(vehIds);
        }

        const chunkVehInfo = array_chunk(selectedVehInfo, 5, false);

        // console.log(chunkVehInfo);
        let inc = 1;
        //console.log("111111111111111111111");
        setTimeout(() => {
            (async () => {
                for (let i = 0; i < chunkVehInfo.length; i++) {
                    const requestVeh = chunkVehInfo[i];

                    const res = await this.API(requestVeh, startDate, sTime, eTime);
                  
                    if (chunkVehInfo.length === inc) {
                        this.pageLoader = false;
                        this.disable.emit(false);
                        // this.enableDisableRFIDStatusCheckSubmitBtn=false;
                        // this.com.comAction.next({
                        //     type: 'rfidstatuscheck1',
                        //     value: false
            
                        // });
            
                    }
                    inc++;
                }
            })();
        }, 100);
      //  console.log("2222222222222222222");
    }


    API(vehInfo: any, date: any, sTime: any, eTime: any) {

        let apiDate = {
            data: {
                key: localStorage.getItem('scbToken'),
                form: {
                    fromDate: date,
                    fromTime: sTime,
                    toDate: date,
                    toTime: eTime,
                    vehList: vehInfo
                }
            }
        }

        return new Promise((resolve, reject) => {

            this.api.rfidStatusCheck(apiDate).then((status) => {


                if (status.status === 'success') {
                    let info: any = [];
                    let responce = status.response;
                    // console.log(this.rfidRouteDate);
                    Object.keys(responce).forEach((row) => {
                        // console.log('VehName', row, this.rfidRouteDate[row][0].vehNo);
                        let vehName = ''; let rName = '';
                        if (this.vehInfo && this.vehInfo[row] && this.vehInfo[row][0].vehNo) {
                            vehName = this.vehInfo[row][0].vehNo;
                        } else {
                            vehName = this.rfidRouteDate[row] && this.rfidRouteDate[row][0] && this.rfidRouteDate[row][0].vehNo ? this.rfidRouteDate[row][0].vehNo : '--';
                        }

                        rName = this.rfidRouteDate[row] && this.rfidRouteDate[row][0] && this.rfidRouteDate[row][0].routeName ? this.rfidRouteDate[row][0].routeName : '--';

                        let obj = {
                            vehName: vehName,
                            routeName: rName,
                            location: responce[row].rfidLastLoc,
                            rfidId: responce[row].rfidLastRFID,
                            time: responce[row].rfidLastTimestamp
                        }
                        info.push(obj);
                    });

                    this.rfidStatusReports = [...this.rfidStatusReports, ...info];
                    // console.log(this.rfidStatusReports);

                    resolve(this.rfidStatusReports);
                   // this.enableDisableRFIDStatusCheckSubmitBtn=true; //added by krish
                }
                
            });
            
        });
        
    }

}