import { Component,Input,Output,EventEmitter } from '@angular/core';
import { ApiService } from './../../../services/ApiService';

declare var swal:any
@Component({
  selector:'edit-campaign-template',
  template:`
    <section class="editTemplateSection">
      <div class="panel panel-default">
        <div class="panel-body">
          <div class="row">
            <div class="form-group col-md-6">
              <label class="control-label">Template Title<sup>*</sup></label>
              <input class="custom-form-control" type="text" [(ngModel)]='templateTitle'>
              <span class="text-danger" *ngIf="templateTitleError">Add Template Title</span>
            </div>
            <div class="form-group col-md-6">
              <label class="control-label">Template Message<sup>*</sup></label>
              <textarea class="form-control" rows=4 [(ngModel)]='templateMessage'></textarea>
              <span class="text-danger" *ngIf="templateContentError">Add Message</span>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12">
            <button [disabled]="this.templateTitle==null"
              class="webform-submit button-primary btn btn-primary form-submit pull-right mr5"
               style="color:#fff !important;margin-right:15px;" (click)="discardOperation()">
               <i class="fa fa-floppy-o mr5" aria-hidden="true"></i>cancel
             </button>
                <button [disabled]="this.templateTitle==null"
                  class="webform-submit button-primary btn btn-primary form-submit pull-right"
                   style="color:#fff !important;margin-right:15px;" (click)="saveTemplate()">
                   <i class="fa fa-floppy-o mr5" aria-hidden="true"></i>Save
                 </button>
            </div>
          </div>
        </div>
      </div>

    </section>
  `,
  styles:[`
      .editTemplateSection{
        margin-top:51px;
      }
      .border-gray{
          border: solid 1px #d0d0d0;
      }
    `],
})
export class editCampaignTemplate{
  public templateTitle:any;
  public templateMessage:any;
  public selectedTemplateId:any
  public templateContentError:boolean;
  public templateTitleError:boolean;

    @Output() hideCampaignEditDir:EventEmitter<boolean> = new EventEmitter<boolean>();
    @Input('templateId')
    set campaignTemplateId(templateID:any){
        this.selectedTemplateId = templateID;
    }


    constructor(private apiService:ApiService){}

    ngOnInit(){
        this.getTemplateDetails();
    }

    getTemplateDetails = ():void => {
        let requestHeader = {
            data:{
                key:localStorage.getItem('scbToken'),
                filter:{
                    templateId:this.selectedTemplateId
                }
            }
        }
        this.apiService.getAvailableCampaignTenplates(requestHeader).then(response => {
            this.templateTitle = response.response[0].templateTitle;
            this.templateMessage = response.response[0].templateMessage;
        }).catch(error => {});
    }

    saveTemplate = ():void => {
        let requestHeader = {
            data:{
                key:localStorage.getItem('scbToken'),
                form:{
                    templateId:this.selectedTemplateId,
                    templateTitle:this.templateTitle,
                    templateMessage:this.templateMessage
                }
            }
        }
        swal({
          title: 'Are you sure?',
          text: "The Selected Template Will Modified",
          type: 'warning',
          timer: 10000,
          reverseButtons:true,
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#e04d76',
          confirmButtonText: 'Yes, allow me to modify!',
          cancelButtonText: 'No, Dont Update'
        }).then(()=>{
          this.apiService.editCampaignTemplate(requestHeader).then(response => {
            var parent: any = this;
            if (response) {
              swal(
                'Modified!',
                'Notification has been Updated',
                'success'
              ).done();
              setTimeout( ()=>{ swal.close();}, 1000);
              this.hideCampaignEditDir.emit(true);
            };
          }).catch(error => {});
        },    function(dismiss:any) {
            if (dismiss === 'cancel') {
              swal(
                'Cancelled',
                'Cancelled :)',
                'error'
              ).done();
              setTimeout( ()=>{ swal.close();}, 1000);
              this.discardOperation();
            }
          });
    }

    discardOperation = ():void => {
        this.hideCampaignEditDir.emit(true);
    }


}
