import { Observable } from 'rxjs';
import { NgRedux, select } from '@angular-redux/store';
import { AppState } from './../../store/index';

import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { RouterModule, Routes, Router } from '@angular/router';
import { authGaurd } from './../../services/auth-guard.service';
import { ApiService } from './../../services/ApiService';
import { StorageService } from "./../../services/storage.service";
import { ImeiFinderServices } from './../../services/imeiFinder.service';
import { stringify } from '@angular/compiler/src/util';
declare var $: any;

@Component({
  selector: 'app-list-view',
  templateUrl: './list-view.component.html',
  styleUrls: ['./list-view.component.css']
})
export class ListViewComponent implements OnInit {

  @select('vehicleData') public vehicleData$: Observable<any>;
  @ViewChild('myVal') span: ElementRef;

  VehicleInfoList: any = [];
  trackerStatus: string;
  queryString: string = '';
  popUpInfo: boolean;
  overSpeedInput: boolean = false;
  speedFilterInput: number;
  offlineVehicleTime: number = 1800000;
  public mapVehicleNo: string;
  routeDetails: any;
  allRouteInfo: any;
  val: any;
  noonval: any = [];
  mornval: any = [];
  eveningval: any = [];
  finVal: any[];
  sessionTypeList: string = "Morning";
  overspeedval: number;

  public isLoading: boolean = true;
  public apiResLoading: boolean = false;

  constructor(private router: Router, public authGaurd: authGaurd, private apiService: ApiService, public imeiservce: ImeiFinderServices, public storage: StorageService,
    private ngRedux: NgRedux<AppState>) {
    this.authGaurd.checkToken();
    this.allRouteInfo = this.storage.get('AllRouteInfo');
    // this.routeDetails = this.storage.get('AllRouteInfo');
    Promise.resolve(this.storage.get('AllRouteInfo')).then((res: any) => {
      this.routeDetails = res;
    })
  }

  ngOnInit() {
    this.disableLoader();
    this.getTrackerInfo();
    this.overspeedval = +this.storage.get('overspeed');
    // this.overspeedval = 30;

  }

  ngAfterViewInit() {
    // this.routeDetails = this.storage.get('AllRouteInfo');
    this.route(this.queryString);
    // 
  }

  ngOnDestroy() {
    this.mapVehicleNo = '';
  }

  disableLoader() {
    this.vehicleData$.subscribe(() => {
      this.isLoading = false
    });
  }

  getTrackerInfo = (): void => {
    this.apiResLoading = true;
    this.apiService.getCurrentLocation({
      data: {
        key: localStorage.getItem('scbToken'),
        extra: {
          orderByDateCreated: -1
        }
      }
    }).then(response => {
      let result = response;
      this.apiResLoading = false;
      if (result.status == "success") {
        this.VehicleInfoList = result.response;
        console.log(this.VehicleInfoList)
      }
    }).catch(error => {
      console.log('Error while logging in', error);
    });
  }

  reportsection() {
    this.router.navigate(['/movementReport']);
  }

  mainmenu() {
    this.router.navigate(['/client-dashboard', {}]);
  }

  hoverMe() {
    this.popUpInfo = true;
  }
  hideHover() {
    this.popUpInfo = false;
  }

  passSearchValueH(searchText: any) {
    $('#displayText').val(searchText);
    this.queryString = "halt";
    this.speedFilterInput = 0;
  }
  passSearchValueM(searchText: any) {
    $('#displayText').val(searchText);
    this.queryString = "moving";
    this.speedFilterInput = 0;
  }

  passSearchValueNoUpdate(searchText: any) {
    $('#displayText').val(searchText);
    this.queryString = "offline";
    this.speedFilterInput = 0;
  }

  passSpeedLimit(value: any) {
    this.queryString = "";
    this.speedFilterInput = value;
  }

  clearFilter() {
    this.queryString = '';
    this.speedFilterInput = 0;
  }
  map(id: any) {
    // console.log(id);
    this.mapVehicleNo = id.VehicleNo;
    var mapProp = {
      center: new google.maps.LatLng(id.location.lat, id.location.lng),
      disableDefaultUI: true,
      mapTypeId: google.maps.MapTypeId.ROADMAP,
      zoom: 16,
      zoomControl: true,
      zoomControlOptions: {
        position: google.maps.ControlPosition.LEFT_TOP
      },
    };
    var map = new google.maps.Map(document.querySelector("#map_canvas"), mapProp);
    const contentString = `<div>
                        <table>
                        <tr>
                        <th><p>Near By : </p></th>
                        <td><p>${id.nearestLocation}</p></td>
                        </tr>
                        <tr>
                        <th><p>Speed : </p></th>
                        <td><p>${id.speed}</p></td>                       
                        <th><p>Time :</p></th>
                        <td><p>${new Date(id.gpsTimestamp).toLocaleString()}</p></td>
                        </tr>
                        </table> 
                        </div>`;

    var contentStrings = `<p><b>Near By : </b>${id.nearestLocation}</p>

                            <p><b>Speed : </b>${id.speed} <span style="margin-left:40px"><b>Time : </b> ${new Date(id.gpsTimestamp).toLocaleString()}</span></p>
    `


    var ic = "./../../../assets/webroot/images/map/geo-2.png"
    var marker = new google.maps.Marker({
      map: map,
      draggable: false,
      // animation: google.maps.Animation.DROP,
      position: id.location,
      icon: ic
    });
    marker.setMap(map);
    var infowindow = new google.maps.InfoWindow({
      content: contentStrings,
      pixelOffset: new google.maps.Size(0, 2),
    });
    //  setTimeout(() => {     
    infowindow.open(map, marker);
    //  }, 500);
    // marker.addListener('tilesloaded', function () {
    //   infowindow.open(map, marker);
    // });


  }

  clearMe(id: any) {
    let ids = id;
    if (this.queryString === ids) {
      $('#displayText').val('');
      this.queryString = '';
      this.domchange();
    }
  }

  delayMapLoad(id: any) {
    setTimeout(() => {
      this.map(id);
    }, 1000);
  }
  domchange() {

    // console.log('start',this.span.nativeElement.style.display)
    this.span.nativeElement.style.display = "none";
    if (this.queryString !== '') {
      this.span.nativeElement.style.display = "inline-block"
      // console.log('cond-true',this.span.nativeElement.style.display)
    } else {
      this.queryString = '';
      this.span.nativeElement.style.display = "none";

    }
  }
  setVehName(vehicleID: any, vehicleNumber: any, report: any) {
    this.storage.set({
      'vehicleId': '',
      'vehicleName': '',
      'report': ''
    })
    this.storage.set({
      'vehicleId': vehicleID,
      'vehicleName': vehicleNumber,
      'report': report
    });

    this.router.navigate(['/movementReport']);

  }

  route = (id: any) => {
    console.log('IDQQQQ : ', id);

    if (id !== 'halt' && id !== 'moving' && id !== 'offline' && id !== 'overspeed') {
      this.routeDetails && Object.keys(this.routeDetails).filter((val) => {
        // console.log(id,this.routeDetails[val].vehNo);
        if (id === this.routeDetails[val].vehNo) {
          // console.log(id)
        } else {
          return false;
        }

        //  return this.routeDetails[val].vehId === id;
      })
    } else {
      throw new Error('wrong');
    }
  }

  // fetchRouteSession(str: string) {

  //   if (str === 'Morning' || str === 'Afternoon' || str === 'Evening') {
  //     let key = Object.keys(this.allRouteInfo);
  //     key.filter((v) => {
  //       if (this.allRouteInfo[v].tags[0] === str) {
  //         if (str === 'Afternoon') {
  //           this.mornval = []; this.eveningval = [];
  //           this.allRouteInfo[v].vehNo != undefined ? this.noonval.push(this.allRouteInfo[v].vehNo) : false;
  //         }
  //         if (str === 'Morning') {
  //           this.noonval = []; this.eveningval = [];
  //           this.allRouteInfo[v].vehNo != undefined ? this.mornval.push(this.allRouteInfo[v].vehNo) : false;
  //           // this.mornval.push(this.allRouteInfo[v].vehNo)
  //         }
  //         if (str === 'Evening') {
  //           this.noonval = []; this.mornval = [];
  //           this.allRouteInfo[v].vehNo != undefined ? this.eveningval.push(this.allRouteInfo[v].vehNo) : false;

  //           // this.eveningval.push(this.allRouteInfo[v].vehNo)
  //         }

  //       }
  //     })
  //   } else{
  //   }
  //   if(str === "Morning"){
  //     // console.log(this.mornval);
  //     return this.mornval;
  //   }
  //   if (str === "Evening") {
  //     return this.eveningval;
  //   }
  //   if (str === "Afternoon") {
  //     return this.noonval;
  //   }
  // }


  // close=(id:any,removeCls:string,addCls:string)=>{
  //   let q = document.querySelector('#' + id);
  //   console.log(q);
  //   if(removeCls){
  //   q.classList.remove(removeCls);
  //   q.classList.add(addCls);
  //   }
  //   setTimeout(()=>{
  //     q.classList.add(removeCls);
  //   },1000)
  // }

}
