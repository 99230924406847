import { Component, Output, EventEmitter, HostListener, Input } from "@angular/core";
import { bootstrapDateTime } from '../../services/bootstrapDateTime/datePicker';
import { chosenUtility } from './../../services/chosen-utility/jq-chosen-utility.js';

declare var $: any;
@Component({

    selector: "date-time-picker",
    template: `

    <div class="form-group">
                   <label class="control-label">Start Date:</label>
                   <input class="custom-form-control" 
                   (focus)="startDatevaluate($event);" 
                   (change)="startDatevaluate($event);emitDateObj()"
                   (focusout)="startDatevaluate($event);emitDateObj()"
                   (mouseover)="startDatevaluate($event);emitDateObj()"
        

                 
                   id="StartDate">
                   <span *ngIf="startdatevalexist==false" class="text-danger">Choose Start Date</span>
                   <!--<span class="text-danger" *ngIf="daySummaryDatesMisMatchError">End Date in greater than Start Date</span>-->
     </div>
     <div class="form-group" >
                   <label class="control-label">End Date:</label>
                   <input class="custom-form-control" 
                   (focus)="endDatevaluate($event)" 
                   (change)="startDatevaluate($event);emitDateObj()"
                   (focusout)="endDatevaluate($event);emitDateObj()"
                   (mouseover)="endDatevaluate($event);emitDateObj()"
                  


                  
                  
                    id="EndDate">
                   <span *ngIf="enddatevalexist==false" class="text-danger">Choose End Date</span>
                   <!--<span class="text-danger" *ngIf="daySummaryDatesMisMatchError">End Date in greater than Start Date</span>-->
    </div>
    
  `
})


export class DateTimePicker {

    public startdatevalexist: boolean;
    public enddatevalexist: boolean;
    public errortext: boolean = false;

    @Output() public datevalue = new EventEmitter();
    clearvalues: string;

    @Input('startDate') startDate:any;
    @Input('endDate') endDate:any;

    constructor(public datetime: bootstrapDateTime, public choosenutility: chosenUtility) {

    }
    ngOnInit() {
        let pickerConfig: any = {
            timePicker: false,
            timeFormat: null,
            closePickerOnDateSelect: true
        }
        this.getinput('StartDate', 'EndDate', pickerConfig)

    }

    startDatevaluate(event: any) {

        if (event.target.value) {
            this.startdatevalexist = true;

        }
        if (!event.target.value) {
            this.startdatevalexist = false;

        }

    }

    endDatevaluate(event: any) {

        if (event.target.value) {
            this.enddatevalexist = true;

        }
        if (!event.target.value) {
            this.enddatevalexist = false;
        }

    }

    captureEmittedValueFromComponent(e: any) {
        e.target.value = e;
        return false;
    }

    emitDateObj() {
        let starttime = $('#StartDate').val() ? $('#StartDate').val() : null;
        let endtime = $('#EndDate').val() ? $('#EndDate').val() : null;

        this.datevalue.emit({ 'startDate': starttime, 'endDate': endtime });
    }

    getinput(ele1: any, ele2: any, pickerConfig: any) {
        let parent = this;
        let timePicker: boolean;
        let closePickerOnDateSelect: boolean;
        let timeFormat: any;
        if ((pickerConfig) && (Object.keys(pickerConfig).length)) {
            timePicker = pickerConfig.timePicker;
            timeFormat = pickerConfig.timeFormat;
            closePickerOnDateSelect = ('closePickerOnDateSelect' in pickerConfig) ? pickerConfig.closePickerOnDateSelect : false;
        } else {
            timePicker = true;
            timeFormat = 'Y-m-d H:i:s';
            closePickerOnDateSelect = false;
        }
        setTimeout(() => {
            $('#' + ele1).datetimepicker({
                format: 'Y-m-d',
                onChangeDateTime: function () {
                    // parent.isDataProcessed = false;
                },
                timepicker: false,
                maxDate: 0,
                closeOnDateSelect: closePickerOnDateSelect,
                autoclose: true
            });

            $('#' + ele2).datetimepicker({
                format: 'Y-m-d',
                onChangeDateTime: function () {
                    // parent.isDataProcessed = false;
                },
                timepicker: false,
                maxDate: 0,
                closeOnDateSelect: closePickerOnDateSelect,
                autoclose: true
            });
        }, 150);
    }
}