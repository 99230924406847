import { Injectable } from '@angular/core';

declare var nearestLatLngFromPoints: any;

@Injectable()

export class KDTreeService {

    constructor() {

    }


    findNearestLocation(latLng: any, find: any, count=10) {
        return new Promise((resolve, reject) => {
            try {
                let result = nearestLatLngFromPoints(latLng, find, count);
                result = result.sort((a:any, b:any) => a[1] - b[1]);
                // result = result && result[0] ? result && result[0][0] : [];
                resolve(result);
            } catch (error) {
                reject(error);
            }
        });
    }

    toRad(Value:any) {
        return Value * Math.PI / 180;
    }
     calcCrow(lat1:any, lon1:any, lat2:any, lon2:any) {
        // let R = 6371; // km
        let R = 6371e3;  // meter
        let dLat = this.toRad(lat2 - lat1);
        let dLon = this.toRad(lon2 - lon1);
        var lat1:any = this.toRad(lat1);
        var lat2:any = this.toRad(lat2);
    
        var a = Math.sin(dLat / 2) * Math.sin(dLat / 2) +
            Math.sin(dLon / 2) * Math.sin(dLon / 2) * Math.cos(lat1) * Math.cos(lat2);
        var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
        var d = R * c;
        return d;
    }
}
