import { Observable } from 'rxjs';
import { NgRedux, select } from '@angular-redux/store';
import { AppState } from './../../store/index';

import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { RouterModule, Routes, Router } from '@angular/router';
import { authGaurd } from './../../services/auth-guard.service';
import { ApiService } from './../../services/ApiService';
import { StorageService } from "./../../services/storage.service";
import { ImeiFinderServices } from './../../services/imeiFinder.service';
import {DomSanitizer} from '@angular/platform-browser';





@Component({
  selector: 'app-surveillance-dashboard',
  templateUrl: './surveillance-dashboard.component.html',
  styleUrls: ['./surveillance-dashboard.component.css']
})
export class SurveillanceDashboardComponent{


  @select('vehicleData') public vehicleData$: Observable<any>;
  @ViewChild('myVal') span: ElementRef;


  allRouteInfo: any;
  showOnlyMap: boolean = false;
  showOnlyVideo: boolean = false;
  showBothMapVideo: boolean = false;
  public mapHeight: number = 75;
  page: number = 0;
  prevBtn:boolean= false;
  nextBtn:boolean = false;
  vehicleCollector: any[];
  vehicleCollectorPaginated: any;
  availableCameraLength: number = 0;
  videoRender:any;
  cameraNotAvail: any=[];
  cameraAvail: any=[];
  dynamicChunker: number=0;
  layoutString ="col-sm-6";
  mapVisible=true;
  vClass="col-sm-6 shadow-h";

  constructor(private router: Router, 
    public authGaurd: authGaurd,
    public sanitizer: DomSanitizer,
    private apiService: ApiService, public imeiservce: ImeiFinderServices, public storage: StorageService,
    private ngRedux: NgRedux<AppState>) {
    this.authGaurd.checkToken();
    this.showOnlyMap = false;
    this.showOnlyVideo=false;
    this.showBothMapVideo =true;

  }

  ngOnInit() {
    this.chunkingDynamically();
    this.prepareCameraQuery();
    
    // console.log( this.sanitizer.bypassSecurityTrustResourceUrl('google.com') );
    // sanitizer.bypassSecurityTrustResourceUrl(cameraAvail[item])
   
  }

  pushNewNotif($event:any){

  }

  selected(str:string){
    this.showBothMapVideo = false;
    this.showOnlyMap = false;
    this.showOnlyVideo = false;
    if(str === 'bothMapVideo'){     
      // this.showBothMapVideo = true;
      // this.paginator('init')
      this.mapVisible=true;
      this.vClass='col-sm-6 shadow-h';
      this.paginator('init');
    }

    if(str === 'onlyVideo'){
      this.mapVisible=false;
      this.vClass=this.mapVisible?'col-sm-6 shadow-h':'col-sm-12 shadow-h';
      this.paginator('init');

   
      // this.showOnlyVideo = true;
      // this.paginator('init');
    }

  }

  prepareCameraQuery(){
    Promise.resolve (this.storage.get('AllRouteInfo')).then((res:any)=>{
        this.allRouteInfo = JSON.parse(JSON.stringify(res));
        this.vehicleCollector = [];
        Object.keys(res).forEach( (item:any) => 
                                                this.allRouteInfo[item].vehId && 
                                                this.vehicleCollector.push(this.allRouteInfo[item].vehNo) )
        this.vehicleCollector = this.uniqueItemOnly(this.vehicleCollector);
        this.dataFormatterCameraAPI();
    })
  }

  paginator(str:string){

    setTimeout(() => {
      

      if(str==='init'){
        this.page=0;
      }
      if(str==="prev"){      
          this.page>0 && this.page--;
          this.page<=0 && (this.prevBtn = true)
          this.nextBtn=false;// on previous btn click disables next btn
      }

      if(str==="next"){
        let d = Math.ceil(this.availableCameraLength/this.dynamicChunker)-1;
        this.page<d && this.page++;
        this.page>=d &&  (this.nextBtn = true);
        this.prevBtn = false;// on next btn click disables previous btn
      }
      
            this.chunkingDynamically();
            this.videoRender = this.vehicleCollectorPaginated[this.page];
      
    }, 300);

  }


    chunkingDynamically(){
      // if(this.showBothMapVideo){
        if(this.vClass==='col-sm-6 shadow-h'){ this.dynamicChunker = 4; }//this.dynamicChunker = 6 
        if(this.vClass==='col-sm-12 shadow-h'){ this.dynamicChunker = 12; } //this.dynamicChunker = 12

      this.vehicleCollectorPaginated=[];
      let d = Object.keys(this.cameraAvail);
      d = [...d];         
      this.vehicleCollectorPaginated = this.chunk(d,this.dynamicChunker);
    }

    dataFormatterCameraAPI(){
    
                let cameraAvail:any ={};
                let cameraNotAvail:any={};
                  this.apiService.getVehicleCameraLiveStream({
                    data: {
                      key: localStorage.getItem('scbToken'),
                      filter: {
                        vehNo: this.vehicleCollector
                    }
                  }
                }).then(res=>{
                  Object.keys(res.response).forEach((item:any)=>{
                   
                    if(res.response[item]){                      
                      cameraAvail[item] = res.response[item];
                    }
                    if(res.response[item] === false){                    
                      cameraNotAvail[item] = res.response[item];
                    }
                  
                })
                this.availableCameraLength = Object.keys(cameraAvail).length;
                this.cameraAvail = JSON.parse(JSON.stringify(cameraAvail));
                this.cameraNotAvail = JSON.parse(JSON.stringify(cameraNotAvail));  
                setTimeout(() => { this.paginator('init') }, 500);    
        })
       
      
    }




    // helper functions

   uniqueItemOnly = (a:any) => a.filter((item:any, pos:any) => a.indexOf(item) == pos);

   chunk:any= (array:any, size:any) => {
    if (!array.length) {
      return [];
    }
    const head = array.slice(0, size);
    const tail = array.slice(size);
    return [head, ...this.chunk(tail, size)];
   }



}
