import { Component, AfterViewInit, Input, Output, EventEmitter,OnDestroy} from '@angular/core';
import { globalService } from './../../../services/global.service';
import { ApiService } from './../../../services/ApiService';
import { decodeAddress } from './../../../services/decodeAddress.service';
import { StorageService } from './../../../services/storage.service';

import { COM } from './../../../services/com.services';


declare var $: any;
declare var google: any;

@Component({
  selector: 'stoppage-details',
  template: `
      <div class="timeline-heading">
    <div class="timeline-container animated fadeIn">
        <div class="animated fadeIn" style="padding: 0;">
            <div class="route-container" *ngIf="!showingStoppageDetails">
                <div class="route-heading-container" style="padding: 2%;background: rgba(158, 158, 158, 0.09);min-height: 90px;">
                    <div class="back-button pull-left grow err-chk-goback" (click)="hideStoppageDetailsDir($event)" style="cursor:pointer;" data-toggle="tooltip"
                        data-placement="bottom" title="go back">
                        <svg width="24" height="24" viewBox="0 0 24 24">
                            <g class="nc-icon-wrapper" fill="#429db5">
                                <path d="M21 11H6.83l3.58-3.59L9 6l-6 6 6 6 1.41-1.41L6.83 13H21z"></path>
                            </g>
                        </svg>
                    </div>

                    <div class="route-heading-tabs" style="padding:25px">
                        <div class="list-heading pull-left">Pickup Point Details</div>
                    </div>

                </div>
                <div *ngIf="!showFilters">
                        <h2 (click)="showFilter()" class="panel-title pull-right inactiveHeader meager-e2e-filter" style="margin: 11px 12px 0 0;">
                        Show Pickup Point &nbsp;&nbsp;
                        <i  aria-hidden="true" class="fa fa-caret-up"></i>
                        
                        </h2>
                </div> 
            <div *ngIf="showFilters">
                <div class="row col-xs-12 col-md-12" style="padding-left:25px;">
                    <div class="pull-left col-xs-8">
                        <h4>
                            <span class="fa fa-map-signs mr5" aria-hidden="true"></span>{{stoppageDetails.pickuppoint}}</h4>
                    </div>
                    <div class="editPickupPoints pull-right edit meager-e2e-pickupform" (click)="showEditPickUpForm($event)" style="cursor:pointer;font-size:17px;padding-top:5px;">
                        <span>
                            <i class="fa fa-pencil grow err-chk-student-edit" data-toggle="tooltip" data-placement="bottom" title="Edit" aria-hidden="true"></i>
                        </span>
                    </div>
                </div>

                <div class="row" style="padding-left: 26px;padding-right: 40px;padding-bottom: 10px;">
                    <div class="col-xs-4 col-lg-4 col-md-4" style="color:#6a6a6a;">
                        <h5>
                            <i title="Route timing" class="fa fa-clock-o mr5" aria-hidden="true"></i>{{stoppageDetails.expectedTime}}</h5>
                    </div>
                    <div class="col-xs-4 col-md-4 col-lg-4" style="color:#6a6a6a;">
                        <h5>
                            <i title="Students"class="fa fa-user mr5" aria-hidden="true"></i>{{stoppageDetails.totalmember}}</h5>
                    </div>
                    <div class="col-xs-4 col-md-4 col-lg-4" style="color:#6a6a6a;">
                        <h5>Radius:-&nbsp; {{stoppageDetails.pickupRadius}}</h5>
                    </div>
                </div>

                <div class="row" style="padding-left: 26px;padding-right: 40px;padding-bottom: 10px;">
                  <div class="col-xs-4 col-md-4 col-lg-4" style="color:#6a6a6a;">
                   <span>Alerts:</span>
                  </div>
                  <div class="col-xs-2 col-md-2 col-lg-2" style="color:#6a6a6a;" *ngIf="stoppageDetails.smsAlert==true">
                   <i class="fa fa-comments grey alertIcons" aria-hidden="true" title="Sms Alert" data-toggle="tooltip" data-placement="bottom"></i>
                  </div>
                  <div class="col-xs-2 col-md-2 col-lg-2" style="color:#6a6a6a;" *ngIf="stoppageDetails.emailAlert==true">
                   <i class="fa fa-envelope grey alertIcons" aria-hidden="true" title="Email Alert" data-toggle="tooltip" data-placement="bottom"></i>
                  </div>
                  <div class="col-xs-2 col-md-2 col-lg-2" style="color:#6a6a6a;" *ngIf="stoppageDetails.appAlert==true">
                   <i class="fa fa-bell grey alertIcons" aria-hidden="true" title="App Alert" data-toggle="tooltip" data-placement="bottom"></i>
                  </div>
                  <div class="col-xs-2 col-md-2 col-lg-2" style="color:#6a6a6a;" *ngIf="stoppageDetails.callAlert==true">
                   <i class="fa fa-phone alertIcons" aria-hidden="true" title="Call Alert" data-toggle="tooltip" data-placement="bottom"></i>
                  </div>

                </div>

                <div class="col-xs-8 col-md-8 col-lg-8"></div>
                <div>
                    <div class="row col-xs-12 col-md-12" style="padding-left:25px;">
                        <div class="pull-right col-xs-12 col-md-12 col-lg-12" style="font-size:14px;font-weight:500;">Address</div>
                        <div class="pull-right col-xs-12 col-md-12 col-lg-12" style="color:#6a6a6a;margin-top:5px;">{{stoppageDetails.pickupAddress}}</div>
                    </div>
                </div>
            </div>
<br>
<hr>
                <div class="row" style="padding-left: 26px;padding-right: 40px;padding-bottom: 10px;">
 
                    <div class="form-group " *ngIf="studentName" style="padding: 24px 3px 2px 12px;border-top: 0.5px solid linen;margin: 23px 2px 2px 0px;">
                        <input checked type="checkbox" class="chk regular-checkbox" [(ngModel)]="studentId" name="studentId">
                        <label class="control-label" style="margin: 2px 3px 2px 10px; ">Assign <span class="mr5 student-member-name" va>{{studentName}}</span> to this route   </label>
                        <button class="webform-submit button-primary btn btn-primary form-submit meager-e2e-assignRoutewithStudent" style="margin: 0 0 0 8px;" (click)="assingRouteToStudent(currentRouteId,stoppageId,studentId)">Assign </button>
                        <button class="webform-submit button-primary btn btn-warning form-submit meager-e2e-clear" style="margin: 0 0 0 8px;" (click)="clearLocalStorage($event)">Clear </button>
                 
                   </div>
                
                </div>
                
                <div *ngIf="!studentName">
                        <div class="row" style="padding-left: 26px;padding-right: 40px;padding-bottom: 10px;">
                                <h4>Assign student to current pickup point</h4>


                                <div class="col-md-6">
                                    <label class="control-label">Student Name</label>
                                    <input class="custom-form-control point-name point-input-text err-chk-studentname" [(ngModel)]="filterInfo.stuName">
                                    
                                </div>
                                <div class="col-md-6">
                                    <label class="control-label">Student Class</label>
                                    <!-- <input class="custom-form-control point-name point-input-text" [(ngModel)]="filterInfo.stuClass">-->
                                    <select class="custom-form-control point-name point-input-text meager-e2e-studClass" [(ngModel)]="filterInfo.stuClass">
                                    <option *ngFor="let item of classAndSessions.listofclasses" value="{{item}}">{{item}}</option>
                                    </select>
                                </div>
                        </div>
                        <div class="row" style="padding-left: 26px;padding-right: 40px;padding-bottom: 10px;">
                                <div class="col-md-6">
                                    <label class="control-label">Student Section</label>
                                    <!--<input class="custom-form-control point-name point-input-text" [(ngModel)]="filterInfo.stuSection"> -->
                                    <select class="custom-form-control point-name point-input-text meager-e2e-studSection" [(ngModel)]="filterInfo.stuSection">
                                    <option *ngFor="let item of classAndSessions.listofsections" value="{{item}}">{{item}}</option>
                                    </select>
                                </div>
                                <div class="col-md-6">
                                        <label class="control-label">Route Name</label>
                                        <single-search-select
                                        [searchType]="'onlyRoute'"
                                        [searchAPIUrl]="'getRoutes'"
                                        [otherParams]="'resetSearchUtility'"
                                        (emitUserSelections)="setSelectedRoute($event)">
                                        </single-search-select>
                                </div>  

                                <span *ngIf="filterError meager-e2e-error-text-search" style="color:red;">Searching keyword missing </span>
                        
                        </div>

                        
                        <button style="color:#fff !important;margin: 0 40px 0 0;" (click)="validator()" 
                        class="webform-submit button-primary btn btn-primary form-submit pull-right  err-chk-student-search">Search
                        </button>
                </div> 

               <div class="reponceData" style="padding: 20px 0px 0px 0px;" *ngIf="studentDetail?.length>0"> 

               <table class="table table-bordered table-striped table-condensed">
                        <thead>
                                <th>#</th>
                                <th>Sudent Name</th>
                                <th>Sudent ID</th>
                                <th>View</th>  
                       </thead>
                       <tbody>
                            <tr *ngFor="let sInfo of studentDetail; let i=index;">
                                
                                <td>{{i+1}}</td>
                                <td>{{sInfo.memberName}}</td>
                                <td>{{sInfo.memberUID}}</td>        
                                <td>
                                <i class="fa fa-eye meager-e2e-studentModelWindow" title="Click to view" style="color: green;cursor: pointer;" (click)="sendStudObj(sInfo)" aria-hidden="true"></i>
                                </td>                  
                           </tr>
                       
                       
                       </tbody>
             </table>

                 <br><br>

                </div>

                <div *ngIf="dataNotFound">
                    <h4 class="animated pulse text-center" style="animation-duration: 1s;color:#999FBF">
                    <i class="fa fa-frown-o"></i>&nbsp;No Students to Display</h4>
               </div>
            </div>
        </div>
    </div>
</div>


  `,
  styles: [`
  .list-heading{
    font-size: 25px;
    font-weight: 400;
  }
  .modal-backdrop {
   
         opacity: 0!important; 
}
  .card {
    font-size: 1em;
    overflow: hidden;
    padding: 0;
    border: none;
    border-radius: .28571429rem;
    box-shadow: 0 1px 3px 0 #d4d4d5, 0 0 0 1px #d4d4d5;
}

.card-block {
    font-size: 1em;
    position: relative;
    margin: 0;
    padding: 1em;
    border: none;
    border-top: 1px solid rgba(34, 36, 38, .1);
    box-shadow: none;
}

.card-img-top {
    display: block;
    width: 100%;
    height: auto;
}
.showStudentDetailPopup{
  
}
.card-title {
    font-size: 1.28571429em;
    font-weight: 700;
    line-height: 1.2857em;
}

.card-text {
    clear: both;
    margin-top: .5em;
    color: rgba(0, 0, 0, .68);
}

.card-footer {
    font-size: 1em;
    position: static;
    top: 0;
    left: 0;
    max-width: 100%;
    padding: .75em 1em;
    color: rgba(0, 0, 0, .4);
    border-top: 1px solid rgba(0, 0, 0, .05) !important;
    background: #fff;
}

.card-inverse .btn {
    border: 1px solid rgba(0, 0, 0, .05);
}
.text-bold {
    font-weight: 700;
}
  animation css */
  .grow { transition: all .2s ease-in-out; }
  .grow:hover { transform: scale(1.1); }

  .reponceData {
            width: 100%;
            height: 250px;
            margin: 0 0 0 0px;
            overflow: scroll;
    }   

  `]
})


export class stoppageDetailsDir {

  public showingStoppageDetails: boolean = false;
  public stoppageId: any;
  public stoppageDetails: any;
  public mapObj: any;
  public routeCreateMarker: any;
  public pickupGeofence: any;
  public studentId: any = {};
  public studentName: string;
  public currentRouteId: any;
  public selectedRoute: any;
  public filterError: boolean;
  public filterInfo: any = {};
  public showFilters: boolean = true;
  public filterData: any = {};
  public studentDetail: any;
  public dataNotFound: boolean;
  public showStuDetails: boolean;
  public classAndSessions:any={};
  public markers: any = {};
 
  @Input('routeId') _routeId: any;
  routeStopee: any;
  @Input('clickedStoppageId') //setting stoppage ID from other directive.
  set clickedStoppageId(clickedStoppageId: any) {
    this.stoppageId = clickedStoppageId;

  }
  @Input('stoppageDetails')
  set setStoppageDetails(stoppageDetails: any) {
    // console.log('datas :',stoppageDetails.pickuppoints[0])
    if(stoppageDetails.pickuppoints[0] && stoppageDetails.pickuppoints[0]['pickupAddress']==undefined){
      let address = stoppageDetails.pickuppoints[0]['pickuppointLocation'];
      stoppageDetails.pickuppoints[0]['pickupAddress']=address['lat']+','+address['lng'];
    }
    this.stoppageDetails = stoppageDetails.pickuppoints[0]; //modified as per API response.
  }

  @Input('mapObj')
  set map(map: any) {
    this.mapObj = map;
  }

  @Output() hideStoppageDetails: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() triggerEditPickUpForm: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() studentPopupControl: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor(
    private apiService: ApiService,
    private decodeAddress: decodeAddress,
    private globalservice: globalService,
    private storage: StorageService,
    private com: COM
  ) {
    //called first time before the ngOnInit(
      this.markers = {}
     
  }

  

  ngOnInit() {
    this.studentId = this.storage.get('sudentID');
    this.studentName = this.storage.get('studentName');  //this.showFilters=false;
    let cRouteId = this.storage.get('currentRouteID');
    if (cRouteId) {
      this.currentRouteId = cRouteId;
    } else {
      this.currentRouteId = this._routeId;
    }


    // console.log(this.currentRouteId);
    // console.log(this.stoppageId);
 setTimeout( ()=>{ this.createRouteMarker();this.plotRouteStoppages();}, 1000);
    
    
    $(document).ready(function () {
      $('[data-toggle="tooltip"]').tooltip();
    });

    this.getClassAndSessions();
  }

  createRouteMarker() {
    //Creating separate route marker.
    let mLatLng = new google.maps.LatLng(this.stoppageDetails.pickuppointLocation.lat, this.stoppageDetails.pickuppointLocation.lng);
    let parent: any = this;
    this.routeCreateMarker = new google.maps.Marker({
      position: mLatLng,
      map: this.mapObj,
    });
    this.pickupGeofence = new google.maps.Circle({
      strokeColor: '#FF0000',
      strokeOpacity: 0.8,
      strokeWeight: 2,
      fillColor: '#FF0000',
      fillOpacity: 0.35,
      map: this.mapObj,
      center: mLatLng,
      radius: this.stoppageDetails.pickupRadius
    });
    this.mapObj.setZoom(13);
    this.mapObj.panTo(mLatLng);

   
  }

  hideStoppageDetailsDir($event: any) {
    this.pickupGeofence.setMap(null);
    this.routeCreateMarker.setMap(null);
    this.hideStoppageDetails.emit(false);
    // console.log(this.markers);
    // this.deleteMarker(this.markers,'clearAll');
    this.clearAllMarker();
    // console.log(this.markers);
  }

  showEditPickUpForm() {
    this.pickupGeofence.setMap(null);
    this.routeCreateMarker.setMap(null);
    this.triggerEditPickUpForm.emit(true);
  }

  assingRouteToStudent(routeId: any, pickupId: any, stuId: any) {
    let arrayValues: any = [];

    arrayValues['routes'] = {};
    arrayValues['routes'][routeId] = {};
    arrayValues['routes'][routeId][pickupId] = [stuId];

    this.apiService.AssignMember({
      data: {
        key: localStorage.getItem('scbToken'),
        form: {
          routes: arrayValues['routes']
        }
      }
    }).then(response => {
      let result = response;
      if (result.status == "success") {
        this.storage.set({
          'studentName': '',
          'sudentID': '',
          'newRouteName': ''

        });
        swal('success', 'Member assigned to this route', 'Success');
      } else {

        swal('error', 'Member already assigned to another pickup point', 'error');
      }
    })
  }

  plotRouteStoppages(){
      
    this.routeStopee = Promise.resolve(this.storage.get('stoppage'));
    
    let mCount = 0;
  

    

    this.routeStopee.then((res:any)=>{
    
      this.clearAllMarker();
      // console.log(this.markers);
      for (var val of res) {

          let mlatLng; let cnt;

          mlatLng = new google.maps.LatLng(val.latlng.lat, val.latlng.lng);
          cnt =  ++mCount;
       
         
        if(val.id === this.stoppageDetails.pickupId){

        }else{

        let marker = new google.maps.Marker({
            position: mlatLng,
            map: this.mapObj,
            //animation: google.maps.Animation.DROP,
            label: {
              text: '' + cnt,
              color: 'white',
            },
            icon: {
              url: "./.../../assets/webroot/images/map/pin-red.png",
              //size: new google.maps.Size(64, 64),
              scaledSize: new google.maps.Size(65, 65),
              origin: new google.maps.Point(0, 0),
              anchor: new google.maps.Point(20, 40),
              labelOrigin: new google.maps.Point(32, 14)
            },
            data:val.id,
            addr:val.point,
            count:cnt,
            mLatlng: mlatLng,
           
          });

          var infowindow = new google.maps.InfoWindow();
          marker.addListener('mouseover', function (event: any) {
            infowindow.setContent('<p>' + this.addr + '</p>');
            infowindow.open(this.mapobj, this);                
          });

          this.markers['_'+cnt]   = marker;    
        }   
      }
  
     
    })
  }

 clearAllMarker(){
  if(Object.keys(this.markers).length){
    Object.keys(this.markers).forEach((item)=>this.markers[item].setMap(null));
    this.markers = {};
  }
 }

 

  clearLocalStorage(event: any) {
    this.storage.set({
      'studentName': '',
      'sudentID': '',
      'newRouteName': ''

    });
    this.hideStoppageDetailsDir(event);

  }

  setSelectedRoute(selectedRoute: any) {

    if (selectedRoute.length > 0) {
      this.selectedRoute = selectedRoute[0].routeId;  //carrying forward  previously declared global var strategy.

    } else {
      this.selectedRoute = '';
    }
  }

  validator() {

    let isValid = true;  //console.log(this.filterInfo);

    if (!this.filterInfo.stuName && !this.filterInfo.stuClass && !this.selectedRoute && !this.filterInfo.stuSection) {
      this.filterError = true;
      isValid = false;
    } else {
      this.filterError = false;
    }


    if (isValid == true && this.filterError == false) {
      this.getStudent();
    }

  }

  getStudent() {

    // console.log(this.filterInfo);
    this.filterData.routeId = this.selectedRoute ? this.selectedRoute : '';
    this.filterData.MemberClass = this.filterInfo.stuClass ? this.filterInfo.stuClass : '';
    this.filterData.MemberName = this.filterInfo.stuName ? this.filterInfo.stuName : '';
    this.filterData.MemberSection = this.filterInfo.stuSection ? this.filterInfo.stuSection : '';
    this.filterData.memberinfo = true;

    this.apiService.viewMember({
      data: {
        key: localStorage.getItem('scbToken'),
        filter: this.filterData

      }
    }).then(memberList => {
      if (memberList.status == 'success') {

        //this.showFilters=false;
        if (memberList.response == null || memberList.response == '' || memberList.response == undefined) {
          this.showFilters = true;
          this.dataNotFound = true;

        } else {
          this.showFilters = false;
          this.dataNotFound = false;
          this.studentDetail = memberList.response;

          // console.log(this.studentDetail);
        }

      } else {
        this.showFilters = true;
        //console.log('norespo',memberList.response);
      }
    })

  }
  showFilter() {
    this.showFilters = true;
  }

  showPopup(obj: any) {  // console.log(obj);
    this.showStuDetails = obj;
  }


  sendStudObj(obj: any) {
    // console.log('routeId'+ this._routeId + 'pickupId'+ this.stoppageId);
    obj.routeId = this._routeId;
    obj.pickupId = this.stoppageId;
    this.com.comAction.next({
      label: 'addStudent',
      payload: obj
    });
  }
  
  getClassAndSessions(){ 
    this.classAndSessions = this.storage.get('classandsection');
  }


  // dummyfun(){
  //   for (var val of res) {

  //     let mlatLng = new google.maps.LatLng(val.latlng.lat, val.latlng.lng);
  //    let cnt =  ++mCount;
   
     
  //   let marker = new google.maps.Marker({
  //       position: mlatLng,
  //       map: this.mapObj,
  //       //animation: google.maps.Animation.DROP,
  //       label: {
  //         text: '' + cnt,
  //         color: 'white',
  //       },
  //       icon: {
  //         url: "./.../../assets/webroot/images/map/pin-red.png",
  //         //size: new google.maps.Size(64, 64),
  //         scaledSize: new google.maps.Size(65, 65),
  //         origin: new google.maps.Point(0, 0),
  //         anchor: new google.maps.Point(20, 40),
  //         labelOrigin: new google.maps.Point(32, 14)
  //       },
  //       data:val.id,
  //       addr:val.point,
  //       count:cnt,
  //       mLatlng: mlatLng,
       
  //     });

  //     var infowindow = new google.maps.InfoWindow();
  //    marker.addListener('mouseover', function (event: any) {
  //       infowindow.setContent('<p>' + this.addr + '</p>');
  //       infowindow.open(this.mapobj, this);                
  //     });

  //     this.markers['_'+cnt]   = marker;         
  // }
  // }
}
