/**
 * @Author: Deepak Pookkote <group10>
 * @Date:   2018-04-24T11:51:53+05:30
 * @Email:  deepak@groupten.com
 * @Project: School-Bus
 * @Filename: admin.login.ts
 * @Last modified by:   group10
 * @Last modified time: 2018-05-22T15:48:16+05:30
 * @Copyright: Group10 Technologies
 */



/*Auther:Deepak */
import { Component } from '@angular/core';
import { RouterModule, Routes, Router } from '@angular/router';
import { NgForm } from '@angular/forms';
import { ApiService } from './../../services/ApiService';
import { GlobalUtils } from './../../services/global-utils';

declare var swal: any;

@Component({
  templateUrl: './admin.login.component.html',
  styles: [`
    $color1: #f4f4f4;
    $color2: #3197EE;

    .login-parent-div img{
       width: 100%;
       background-color: #f2f6fb;
     }
     .heightInc{
       height: 20vh;
     }

     .text-danger {
       padding: 5px;
       color: red;
     }

    .login-form {
        width: 400px;
        margin: 28% auto;
        font-size: 16px;
    }

    /*css for ripple pin points */

    /*Designate how the animation will take place*/
    @keyframes pulsate {
        0% {
        transform: scale(0.1);
        opacity: 0;
        }
        50% {
        opacity: 1;
        }
        100% {
        transform: scale(1.2);
        opacity: 0;
        }
    }

    /* .wrap is our container div and will vary in name on your site */
    .wrap {
        width: 90%;
        max-width: 672px;
        margin: 0 auto;
    }

    // .map-bg {
    //     background: url(images/map-bg.jpg) no-repeat;
    //     background-position: 0px 0px;
    //     background-size: auto;
    //     width: 100%;
    //     height: 330px;
    //     position: relative;
    // }

    /* Alter the top and left positions to sit on top of your map location */
    .marker {
        width: 100px;
        height: 100px;
        position: absolute;
        top: 130px; /*positions our marker*/
        left: 200px; /*positions our marker*/
        display: block;
    }

    .pin {
        width: 20px;
        height: 20px;
        position: fixed;
        top: 288px;
        left: 486px;private apiResponseMesage:string;
        background: #81D4FA;
        border: 2px solid #FFF;
        border-radius: 50%;
        z-index: 1000;
    }

    .pin-effect {
        width: 100px;
        height: 100px;
        position: fixed;
        top: 248px;
        left:447px;
        display: block;
        background: rgba(3, 169, 244, 0.57);
        border-radius: 50%;
        opacity: 0;
        animation: pulsate 2400ms ease-out infinite;

    }

    /* Adjusts the background image and marker for smaller screens */
    @media only screen and (max-width: 414px) {
        .map-bg {
            background-position: -100px 0px;
        }

        .marker {
            left: 100px;
        }
    }

  `],

})

export class adminLoginComponent {
  public email: string = "";
  public password: string = "";
  public InvalidCredentials:boolean=false;
  public sysAdminUserName:string="";
  public sysAdminPassword:string="";
  public userNameError:boolean;
  public userPasswordError:boolean;
  public apiResponseMesage:string;

  constructor(private apiService: ApiService,public router: Router,private globalUtils:GlobalUtils) {

    //this.preventLogin();
  }


  initiateAdminLogin(){
    if(this.initiateFormValidation()){
      this.apiService.orgAuthentication({
        data: {
          form: {
            loginname : this.sysAdminUserName,
            password: this.sysAdminPassword
          }
        }
      }, 'admin','admin/adminauthentication').then(response => {
        if( response.status == 'success') {
          this.apiResponseMesage = '';
          let res = response;
            localStorage.setItem("adminToken", res.token);
           
            localStorage.setItem('adminuserName', this.sysAdminUserName);
            this.router.navigate(['/sysAdmin/admin-dashboard']);
        }else{
          //fetch status codes....
          localStorage.setItem("adminToken", '');
         
          this.router.navigate(['/sysAdmin/admin-login']);
          this.apiResponseMesage = this.globalUtils.getErrorMsg(response.error.ec);
          this.InvalidCredentials = true;
        }


      }).catch(error=>{
         console.log('Error',error);
      });
    }


  }

  initiateFormValidation(){
    let isValid: boolean = true;
    //Org User
    if (!this.sysAdminUserName) {
      this.userNameError=true;
       isValid = false;
    } else {
      this.userNameError=false;
    }


      //Org Password
      if (!this.sysAdminPassword) {
        this.userPasswordError=true;
         isValid = false;
      } else {
        this.userPasswordError=false;
      }

    return isValid;
  }


}
