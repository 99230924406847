import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'nextedObjCount'
})

export class NextedObjCount implements PipeTransform {
    transform(obj: any): any {
        let count = 0;
        obj.forEach( (element: any) => {
            Object.keys(element).forEach( (nos) => {
              if ( element[nos] !== null && element[nos] !== 'null' ) {
                count = count + 1;
              }
            } );
         } );
        return count;
    }
}
