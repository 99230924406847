import { Component, OnInit } from '@angular/core';
import { ApiService } from './../../services/ApiService';
import { authGaurd } from './../../services/auth-guard.service';
import { GlobalUtils } from './../../services/global-utils';
import { RouterModule, Routes, Router } from '@angular/router';

declare var $:any;
@Component({
  selector: 'app-admin-profile',
  templateUrl: './admin-profile.component.html',
  styleUrls: ['./admin-profile.component.css']
})
export class AdminProfileComponent implements OnInit {



  public newPassword: string;
  public confirmPassword: string;
  public showNewPasswordError: boolean;
  public showConfirmPasswordError: boolean;
  public passwordChangeSucces: boolean;
  public passwordMismastchError: boolean;
  public showLoading: boolean = false;
  public assitanceForm: boolean = true;
  public passType2: string = 'password';
  public passType3: string = 'password';
  public passClass2: string = 'fa fa-eye-slash field-icon toggle-password';
  public passClass3: string = 'fa fa-eye-slash field-icon toggle-password';

  public adminInfo:any = {};



  constructor(private apiService: ApiService,
    public authGaurd: authGaurd,
    public router:Router,
    private globalUtils: GlobalUtils,) { }

  ngOnInit() {
    this.intiateView();
  }


  intiateView(){
    let adminObj = {
      data: {
        key: localStorage.getItem('adminToken')
      }
    }
    this.apiService.viewAdminProfile(adminObj).then(response => {
      console.log(response);
      this.adminInfo = response.response
    }).catch(error => {
      console.log('Error while logging in', error);
    });
  }




  updatePassword() {
    if (this.initiatePasswordValidator()) {
      let passwordObj = {
        data: {
          key: localStorage.getItem('adminToken'),
          form: {
            password: this.newPassword,
            loginname:localStorage.getItem('adminuserName'),
          }
        }
      }
      this.apiService.resetPasswordSysAdmin(passwordObj).then(response => {
        if (response.status === 'success') {
          setTimeout(() => {
            this.passwordChangeSucces = true;
          }, 1000);
          this.passwordChangeSucces = false;
          setTimeout(() => {
            $('#basicModal').modal('hide');
            this.logoutAdmin();
          }, 3500);
          setTimeout(() => {
            this.logoutAdmin();
          }, 4000)
          this.newPassword = '';
          this.confirmPassword = '';
        } else {
        //  console.log(response);
          this.passwordChangeSucces = false;
        }
      }).catch(error => {
        console.log('Error while logging in', error);
      });
    }

  }

  logoutAdmin() {
    setTimeout(() => {
      localStorage.setItem("adminToken", '');
      this.router.navigate(['/sysAdmin/admin-login']);
    }, 10);
  }

  passwordValidator(event: any) {
    event.srcElement.value = event.srcElement.value.replace(/[()\-\[\];':"\\|,+<>\/\s]/gi, '');
    if (event.srcElement.value != '') {
      //  console.log('t',event.srcElement.value);
      if ((event.which < 48 || event.which > 57)) {
        event.preventDefault();
      }
    } else {
      // event.srcElement.value=0;
    }
  }


  initiatePasswordValidator() {
    let isValid: boolean = true;


    if (!this.newPassword) {
      this.showNewPasswordError = true;
      isValid = false;
    } else {
      this.showNewPasswordError = false;
    }

    if (!this.confirmPassword) {
      this.showConfirmPasswordError = true;
      isValid = false;
    } else {
      this.showConfirmPasswordError = false;
    }

    if (this.newPassword != this.confirmPassword) {
      this.passwordMismastchError = true;
      isValid = false;
    } else {
      this.passwordMismastchError = false;
    }

    return isValid;
  }

  toggleIcon(control: any) {
   if (control === 'newpassword') {
      if (this.passType2 == 'password') {
        this.passType2 = 'text';
        this.passClass2 = 'fa fa-eye field-icon toggle-password';
      } else {
        this.passType2 = 'password';
        this.passClass2 = 'fa fa-eye-slash field-icon toggle-password';
      }
    } else if (control === 'confirmpassword') {
      if (this.passType3 == 'password') {
        this.passType3 = 'text';
        this.passClass3 = 'fa fa-eye field-icon toggle-password';
      } else {
        this.passType3 = 'password';
        this.passClass3 = 'fa fa-eye-slash field-icon toggle-password';
      }
    }

  }

}
