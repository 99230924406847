import { Pipe, PipeTransform } from "@angular/core";

function AvatarImage(letters:string, size:number, primaryColor:boolean) {
    var canvas = document.createElement('canvas');
    var context = canvas.getContext("2d");
    var size = size || 60;
    // Generate a random color every time function is called
    // 0xFFFFFF
    // 0
    const alpha = ['a', 'b', 'c', 'd', 'e', 'f', 'g', 'h', 'i', 'j', 'k', 'l', 'm', 'n', 'o', 'p', 'q', 'r', 's', 't', 'u', 'v', 'w', 'x', 'y', 'z', '0', '1', '2', '3', '4', '5', '6', '7', '8', '9', ' ', '#', '-', '.'];
    const colors = [
      "#EF5350", "#E53935", // red
      "#E91E63", "#C2185B", "#AD1457", "#880E4F", // pink
      "#9C27B0", "#7B1FA2", "#4A148C", //purple
      "#673AB7", "#512DA8", "#311B92",  // deep purple "#651FFF"
      "#3F51B5", "#3949AB", "#283593", "#304FFE", //Indigo
      "#009688", "#00796B", "#388E3C", //green
      "#2196F3", "#1976D2", "#1565C0", "#0D47A1",  // blue "#2962FF",
      "#AFB42B", "#9E9D24", "#827717", //lime
      "#F9A825", "#F57F17", // yellow
      "#FFA000", "#FF8F00", "#FF6F00", //Amber
      "#EF6C00", "#FF6D00", "#FF9100", //orange
      "#FF5722", "#BF360C", "#DD2C00", // deep orange
      "#A1887F", "#8D6E63", "#795548", // brown
      "#757575", "#607D8B", "#546E7A" //grey
  
    ];
    const character = letters.slice(0, 1); let characterColor = '';
    if (character && character != '') {
      characterColor = colors[alpha.indexOf(character.toLowerCase())];
    }
  
    var color = "#" + (Math.random() * 0xFFFFFF << 0.8).toString(16);
    // Set canvas with & height
    canvas.width = size;
    canvas.height = size;
    // Select a font family to support different language characters
    context.font = Math.round(canvas.width / 2) + "px"; //Arial
    context.textAlign = "center";
    // Setup background and front color
    context.fillStyle = primaryColor ? '#429DB5' : (characterColor || color);
    context.fillRect(0, 0, canvas.width, canvas.height);
    context.fillStyle = "#FFF";
    context.fillText(letters, size / 2, size / 1.5);
    // Set image representation in default format (png)
    let dataURI = canvas.toDataURL();
    // Dispose canvas element
    canvas = null;
    return dataURI;
  }
  
  @Pipe({
    name: 'letterAvatar'
  })
  export class LetterAvatarPipe implements PipeTransform {
  
    transform(value: any, size: number = 25,primaryColor=true) {
      return AvatarImage(value.slice(0, 2), size,primaryColor)
    }
  
  }