/**
 * @Date:   2018-04-06T10:19:30+05:30
 * @Last modified time: 2018-04-09T10:31:48+05:30
 */



import { Injectable } from '@angular/core';
import { RouterModule, Routes, Router } from '@angular/router';
import { ApiService } from './ApiService';

@Injectable()

export class VehicleServices {

  public trackerInformation:any=[];
  constructor(public router: Router,
              public apiService : ApiService){

                this.getTotalOrgVehicleList();

  }

    getTotalOrgVehicleList(){
      let orgVehObj= {
        data: {
         key:localStorage.getItem('scbToken')
       }
     };
     this.apiService.getAllOrgDevices(orgVehObj).then(response => {
       let result=response;
       if(result.status=="success"){
         if (result.response!=null && result.response!=undefined && result.response!='') {

         this.trackerInformation = response.response;
        // console.log(this.trackerInformation);
         }
       }
     }).catch(error => { });
    }

    findVehicle(vehId:string){
    //  console.log(vehId);
      return this.trackerInformation.filter((index:any)=>{return index.vehId == vehId})[0];

    }


}
