import { Component, Input } from '@angular/core';

declare const $: any;
declare const Highcharts: any;

@Component({
    selector:'g10-pickup-point-Chart',
    template:`
       
                 <div id="pickup-point-line-chart"></div>
       
    `,
    styles:[`
    div#svg-render {
        width: 277px !important;
        height: 250px !important;
        position: relative;
        overflow-y: hidden;
        overflow-x: scroll;
    }
    `]
})

export class PickupPointComponent{

    @Input() data: any;
    constructor(){}

    ngOnInit(){
     
      let obj = this.data; 
      let serialData:any=[];let serialData1:any=[];
      Object.keys(obj).map((i,j)=>{
        let  eta = obj[i].expectedTMS.replace(':','.');
        let  reached = obj[i].reachedTime;
        let readTime:any = reached.split(":");
        let reachedTMS:any = parseFloat(readTime[0]+'.'+readTime[1]);
        let temp = {
            x: Date.parse(i),
            y: parseFloat(eta),
            myData: obj[i]
        }
        let temp1 = {
          x: Date.parse(i),
          y: parseFloat(reachedTMS),
          myData: obj[i]
      }
      serialData.push(temp);
      serialData1.push(temp1);
      if(Object.keys(obj).length-1 == j){
         this.loadLineChart([{
          name:"Expected Time",data:serialData},{name:"Reached Time",
          data:serialData1}])
      }
     }).filter((item)=> item!=null && item);
    }
   
      loadLineChart(date:any){
      Highcharts.chart('pickup-point-line-chart', {
        chart: {
          type: 'line'
        },
        title: {
          text: 'Pickup Point Trends'
        },
        // subtitle: {
        //   // text: 'Irregular time data in Highcharts JS'
        // },
        legend:{
          enabled:true
        },
        xAxis: {
          type: 'datetime',
          dateTimeLabelFormats: { // don't display the dummy year
             month: '%e. %b',
            year: '%b'
          },
          title: {
            text: 'Date'
          }
        },
        yAxis: {
          title: {
            text: 'Time'
          },
          min: date[0].y,
          allowDecimals: true,
          labels: {
            formatter: function () {
              return this.value.toLocaleString('en', {minimumIntegerDigits:2,minimumFractionDigits:2,useGrouping:false}).toString().split('.').join(':');
              // return Highcharts.dateFormat('%H:%M', this.date);
            },
      
          },
        },
        tooltip: {
          formatter: function () {
            var d = this.points[0]['point']['myData'];
            // console.log('dddd',d);
            var info = '<div style="width:20px;word-wrap:break-all">';
            if (d.RouteName != undefined) info += '<strong>Route Name: </strong>' + d.RouteName + '<br>';
            if (d.callMadeTime != undefined) info += '<strong>Call Time: </strong>' + d.callMadeTime + '<br>';
            if (d.expectedTime != undefined) info += '<strong>Expected Time: </strong>' + d.expectedTMS + '<br>';
            if (d.reachedTime != undefined) info += '<strong>Reached Time: </strong>' + d.reachedTime + '<br>';
            if (d.pickupName != undefined) info += '<strong>Pickup Name: </strong>' + d.pickupName + '<br>';
            info += '</div>';
            return info;
      
            // return this.points.reduce(function (s, point) {
            //     return s + '<br/>' + point.series.name + ': ' +
            //         point.y + 'm';
            // }, '<b>' + this.x + '</b>');
          },
          shared: true
        },
        plotOptions: {
          series: {
            marker: {
              enabled: true
            }
          }
        },
        colors: ['#6CF', '#39F', '#06C', '#036', '#000'],
        series:date,
      
        responsive: {
          rules: [{
            condition: {
              maxWidth: 500
            },
            chartOptions: {
              plotOptions: {
                series: {
                  marker: {
                    radius: 2.5
                  }
                }
              }
            }
          }]
        }
      });
        }
}