export const ChannelPartnersConfig: any = [
  {
    channel_partner_name: "unicel",
    sms_service: true,
    obd_service: true,
    email_service: false,
    fa_icon_include_size: "fa fa-podcast fa-3x"
  },
  {
    channel_partner_name: "sarv",
    sms_service: false,
    obd_service: true,
    email_service: false,
    fa_icon_include_size: "fa fa-strikethrough fa-3x"
  },
  // {
  //   channel_partner_name: "sarv2",
  //   sms_service: true,
  //   obd_service: false,
  //   email_service: true,
  //   fa_icon_include_size: "fa fa-fax fa-3x"
  // },
  // {
  //   channel_partner_name: "unicel2",
  //   sms_service: true,
  //   obd_service: true,
  //   email_service: false,
  //   fa_icon_include_size: "fa fa-podcast fa-3x"
  // }
];
