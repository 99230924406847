/** 
 * @Date:   2018-04-17T11:30:14+05:30 
 * @Project: School-Bus
 * @Filename: vehicleInfo.dir.ts
 * @Last modified by:   group10
 * @Last modified time: 2018-04-26T18:27:13+05:30
 * @Copyright: Group10 Technologies
 */

 import { Observable } from 'rxjs';
 import { NgRedux, select } from '@angular-redux/store';
 import { AppState } from './../../../store/index';
 import { StorageService } from "./../../../services/storage.service";
 import { ActivatedRoute, Params, NavigationEnd, Router } from '@angular/router';
 import { Component, Output, EventEmitter } from '@angular/core';
 import { ApiService } from './../../../services/ApiService';
 import { ImeiFinderServices } from './../../../services/imeiFinder.service';
 import { UtilityServices } from '../../../services/utility.service';
 
 declare var $: any;
 
 @Component({
   selector: 'new-vehicle-info',
   templateUrl: './newVehicleInfo.template.html',
   styles: [`
 
 
 .clickable{
     cursor: pointer;
 }
 
 
 .moving{
   background: #248a00;
     padding: 3px;
     border-radius: 2px;
     font-weight: 600;
     color: #ffff;
     font-size: 10px;
     width: 17px;
     height: 17px;
     text-align: center;
 }
  .halt{
    background: #f50b0b;
      padding: 3px;
      border-radius: 2px;
      font-weight: 600;
      color: #ffff;
      font-size: 10px;
      width: 17px;
      height: 17px;
      text-align: center;
      letter-spacing: 2px;
  }
  .offline{
     background: #838383;
     padding: 3px;
     border-radius: 2px;
     font-weight: 600;
     color: #ffff;
     font-size: 10px;
     width: 17px;
     height: 17px;
     text-align: center;
     letter-spacing: 2px;
 }
 
  .list-item-container{
     display: inline-block;
     list-style: none;
     position: relative;
     width: 100%;
     border-bottom: 1px solid #e6e6e6;
     min-height: 50px;
     padding: 2%;
     cursor:default;
  }
 
  .updates{
    font-style: italic;
    color: grey;
  }
  /*Search bar stylings*/
 
  .searchbar {
      padding-top: 20px;
      padding-left: 18px;
      padding-right: 20px;
      padding-bottom: 20px;
      position: relative;
      width: 99%;
  }
 
  .searchbar input[type=text] {
      background-color: #f7f7f7;
      border: 1px solid lightgrey;
      height: 40px;
      border-radius: 0.2em;
  }
 
  .srchb {
    position: absolute;
    right: 6px;
    top: 68px;
    padding: 08px 15px;
    color: #464040;
    font-size: 15px;
  }
 
  .srchb:hover {
      cursor: pointer;
  }
 
  .popupContent{
     position: absolute;
     z-index: 999999999;
     padding: 10px;
     border: 1px solid lightgrey;
     width: 84%;
     margin-left: 18px;
     border-radius: 2px;
     background-color: #fffffff2;
     margin-top: -19px;
  }
 
  .field-icon {
   float: right;
   margin-left: -25px;
   margin-top: -30px;
   margin-right: -27px;
   position: relative;
   z-index: 2;
   cursor: pointer;
   font-size: 19px;
   color: lightgrey;
   cursor:pointer;
 }
  
 i.fa.fa-eraser.field-icon:hover {
   color: #524a4a;
 }
 
 .addedShadow{
   box-shadow: 0 5px 20px 0px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0 5px 20px 0px rgba(0, 0, 0, 0.1);
  -webkit-box-shadow: 0 5px 20px 0px rgba(0, 0, 0, 0.1);
  height:46px;
 }
 .panel-default>.panel-heading {
  color: #333;
  background-color: #ffff;
  border-color: #ffff;
 }
 .panel {
  box-shadow: 1px 2px 4px 0px lightgrey;
 }
 .panel-default {
  border-color: #fdfafa;
 }
 
 
 
 
 `
   ],
 })
 
 export class newVehicleInfoDir {
 
   // public vehicleData$:  Observable<any>;
 
   @select('trackerData') public trackerData$: Observable<any>;
   @select('vehicleData') public vehicleData$: Observable<any>;
 
   public VehicleInfoList: any = [];
   public routesNoAdd: any = [];
   public popUpInfo: boolean;
   public queryString: string;
   public overSpeedInput: boolean = false;
   public speedFilterInput: number;
   public offlineVehicleTime: number = 1800000;
   public trackerStatus: string;
   public routeNameMapping = new Map();
   public newAlertConfigRoutes1: any = [];
   public newAlertConfigRoutes2: any = [];
   public showTableRowLoader: boolean = true;
   public showProgressBar: boolean = false;
   public totalAPIdata = 0;
   public completedAPIdata = 0;
   public noDataFound:boolean = false;
 
   public beforeSortRoutes: any = [];
   public afterSortRoutes: any = [];
 
   public vehRouteData:any = {};
   public vehListData:any = [];
 
   constructor(
     public apiService: ApiService,
     public imeiservce: ImeiFinderServices,
     public storage: StorageService,
     public router: Router,
     public utilService: UtilityServices,
     private ngRedux: NgRedux<AppState>
   ) {
     // this.title = "Angular 2 simple search";
     ///    this.names = ['Prashobh','Abraham','Anil','Sam','Natasha','Marry','Zian','karan'];
   }
 
 
   @Output() VehInfoMapPanEmitterFlag: EventEmitter<any> = new EventEmitter<any>();
   ngOnChanges() {
 
     this.getTrackerInfo();
   }
   ngOnInit() {
 
     this.fetchVehicleDatas();
   }
 
   async fetchVehicleDatas(){
     await this.getOrgVehData().then((res:any)=>{
       if(res && res.vehDetails && Object.keys(res.vehDetails).length>0){
         this.vehRouteData = res.vehDetails;
       }
     })
     this.getVehicleDatas();
   }
 
   getOrgVehData(){
     return new Promise((resolve,reject)=>{
       let apiHeader = {
            data:{
                key:localStorage.getItem('scbToken'),
             //    extra: {
             //    pageJump: 1
             //  }
            }
       }
       this.apiService.getAllVehicleAllRouteReport(apiHeader).then(response => {
         let result=response;
         if(result.status=="success"){
           resolve(response.response);
         }else{
           reject('failure data');
         }
       }).catch((err)=>{
         reject(err);
       });
 
     })
   }
   async getVehicleDatas() {
 
     let routeInfo:any = [];
     let routeTags: string[] = ['Morning', 'AfterNoon', 'Evening'];
     let routeObj:any = {};
     routeObj = {
       data: {
         key: localStorage.getItem('scbToken'),
         // extra: {
         //   maxlimit: 'all'
         // },
         extra: {
           pageJump: 0,
           // orderByDateCreated: -1
           //limit:25
         },
       }
     };
     this.showTableRowLoader = true;
     this.showProgressBar = true;

     await this.getRouteDatas('getRoutes', routeObj, 'totalroutecnt').then((res:any)=>{
        if(res && res.data && res.data.length>0){
            res.data.map((item:any)=>{
                if(item && item['routeName']){
                    let pushedData = item['routeName'];
                    let dataIndex = this.newAlertConfigRoutes1.findIndex((ele: any) => ele == pushedData);
                    if (dataIndex == -1) {
                      this.newAlertConfigRoutes1.push(pushedData);
                    };
                }
            })
        }
    }).catch((err)=>{
        console.log(err);
    });

    this.vehicleData$.subscribe((sub:any)=>{
        if(sub && sub.length>0){        
            this.newAlertConfigRoutes1.map((route:any)=>{
                sub.map((item:any)=>{
                    if(item.vehicleInformation && item.vehicleInformation.type != 'gate' && item.routeAssigned && item.routeAssigned.includes(route)){
                        let dataIndex = this.vehListData.findIndex((ele: any) => ele['deviceId'] == item['deviceId']);
                        if (dataIndex == -1) {
                          this.vehListData.push(item);
                        }
                    }
                })
            })         
            this.showTableRowLoader = false;
            this.showProgressBar = false;
        }            
    })
    
    this.clearFilter();  

     this.ngRedux.getState().vehicleData.filter((item: any) => {
       this.newAlertConfigRoutes2.push(item);
       // console.log('KRISH : ',item);;
     });
   }


  getRouteDatas(apiMethod:string, paramData:any, totalCountName:string){
    return new Promise(async (res,rej)=>{
      try {
        let ping = 1, i = 0;
        let data:any = {
          data: [],
          total:0,
          status:''
        };
          while (ping) {
            paramData['data']['extra'] = {
              pageJump: i,
            };
            let resData:any = await this.utilService.getAPIcall(apiMethod, paramData, totalCountName).catch((err) => { console.log('err', err); ping = 0; });
            if(resData && resData['data'] && resData['data'].length > 0){
              data['data'] = [...data['data'],...resData['data']];
              data['total'] = resData['totalCount'];
            }else ping = 0;
            i++;
          }
        if(!ping) res(data);
      }
      catch (err) {
        console.log(err);
        rej()
      }
    })
  }

 
   checkNoData(){
     let enableNoData:boolean = true;
     this.newAlertConfigRoutes1.map((route:any)=>{
       this.newAlertConfigRoutes2.map((item:any)=>{
         if(item.vehicleInformation.type != 'gate' && item.routeAssigned && item.routeAssigned.includes(route)) enableNoData = false;
       })
     })
     this.noDataFound = enableNoData;
   }

   checkRoute(vehicle:any){
    if(vehicle && vehicle.routeAssigned && vehicle.routeAssigned.length>0){
        return vehicle.routeAssigned.some((rA:any)=>this.newAlertConfigRoutes1.indexOf(rA)===-1);
    }else return false;
   }
 
   getTrackerInfo = (): void => {
     this.apiService.getCurrentLocation({
       data: {
         key: localStorage.getItem('scbToken'),
         extra: {
           orderByDateCreated: -1
         }
       }
     }).then(response => {
       let result = response;
       if (result.status === 'success') {
         this.VehicleInfoList = result.response;
 
 
         this.speedFilterInput = 0;
       }
     }).catch(error => {
       console.log('Error while logging in', error);
     });
     // this.beatVehInfo();
 
   }
 
   beatVehInfo() {
     //  this.vehicleData$ =  this.ngRedux.getState().vehicleData;
     //  setTimeout( () => {
     //    this.beatVehInfo();
     //  }, 800 );
   }
 
   hoverMe() {
     this.popUpInfo = true;
   }
   hideHover() {
     this.popUpInfo = false;
   }
 
   passSearchValueH(searchText: any) {
     $('#displayText').val(searchText);
     this.queryString = 'halt';
     this.speedFilterInput = 0;
   }
   passSearchValueM(searchText: any) {
     $('#displayText').val(searchText);
     this.queryString = 'moving';
     this.speedFilterInput = 0;
   }
 
   passSearchValueNoUpdate(searchText: any) {
     $('#displayText').val(searchText);
     this.queryString = 'offline';
     this.speedFilterInput = 0;
   }
 
   passSpeedLimit(value: any) {
     this.queryString = '';
     this.speedFilterInput = value;
   }
 
   clearFilter() {
     this.queryString = '';
     this.speedFilterInput = 0;
   }
 
   getVehStatus(speed: number, gpsTime: number): string {
     let status = null;
     let currentTime = Math.floor(Date.now() / 1000);
     let gpsTimeTemp = gpsTime / 1000;
     if ((currentTime - gpsTimeTemp) >= 1800) {
       status = 'offline';
       this.trackerStatus = 'offline';
     } else if (speed <= 5) {
       status = 'halt';
       this.trackerStatus = 'halt';
     } else if (speed > 5) {
       status = 'moving';
       this.trackerStatus = 'moving';
     } else {
       // do nothing..
     }
     return status;
   }
 
   passRouteId(deviceId: any) {
 
     let emitObj = {
       vehId: deviceId,
     };
     this.VehInfoMapPanEmitterFlag.emit(emitObj);
   }
 
   setVehName(vehicleID: any, vehicleNumber: any, report: any) {
     this.storage.set({
       'vehicleId': '',
       'vehicleName': '',
       'report': ''
     })
     this.storage.set({
       'vehicleId': vehicleID,
       'vehicleName': vehicleNumber,
       'report': report
     });
 
     this.router.navigate(['/movementReport']);
 
   }
 
 
 }
 
 /*
 Notes : npm install node-sass -g
 */
 