import { Pipe, PipeTransform } from '@angular/core';
import { StorageService } from "../../services/storage.service";

@Pipe({
    name: 'VehStatus'
})

export class VehStatus implements PipeTransform {
  
    public trackerStatus: string;
    transform(value: any, speed: number, gpsTime: number,overspeed?:number): any {
        
        let status = null;
        let currentTime = Math.floor(Date.now() / 1000);
        let gpsTimeTemp = gpsTime / 1000;
        if ((currentTime - gpsTimeTemp) >= 1800) {
            status = 'offline';
            this.trackerStatus = "offline";
        }
        else if (speed <= 5) {
            status = 'halt';
            this.trackerStatus = "halt";
        }

        else if (speed > 5) {
           
            if(overspeed && speed >= overspeed){
                status = 'overspeed';
                this.trackerStatus = "overspeed";
            }else{
                status = 'moving';
                this.trackerStatus = "moving";
            }
        } else;
        
        // else if (overspeed && speed >= overspeed) {
        //     status = 'overspeed';
        //     this.trackerStatus = "overspeed";
        // }else {
            //do nothing..
       
   
        return status;
        
    }
    }
  
