import { Component, Input, Output, EventEmitter } from '@angular/core';
import { RouterModule, Routes, Router } from '@angular/router';
import { globalService } from './../../services/global.service';
import { ApiService } from './../../services/ApiService';
import { UtilityServices } from './../../services/utility.service';
import { VehicleServices } from './../../services/Vehicle-services';
import { COM } from './../../services/com.services';

import { StorageService } from './../../services/storage.service';


declare var $: any;
const swal = require('sweetalert');

@Component({
  selector: 'top-menu',
  templateUrl: './navbarTemplate.html',
  styles: [`

    .navbar-default .navbar-brand:hover,
    .navbar-default .navbar-brand:focus {
     background-color: white;
    }
   .navbar-default .navbar-nav>li>a:hover {
    background-color:#fbfbfb;
    }
    .navbar-default .navbar-nav>li>a.router-link-active .active {
     background-color:#background: #fbfbfb;
     }
    .navbar-default .navbar-nav>li>a {
    color:#e7e7e7;
    }
    .navbar-default .navbar-nav>.active>a, .navbar-default .navbar-nav>.active>a:focus, .navbar-default .navbar-nav>.active>a:hover {
    background-color: #e7e7e7;
    }
    .navbar-default {
    background-color: #ffffff;
    }
    .navbar-default .navbar-nav> li> a {
    color: #000;

    }
    .navbar-default .navbar-brand {
    color: #000;
    padding:13px;
    }

    .dropdown:hover .dropdown-menu {
    display: block;
    margin-top: 0;
  }
  /* BELL */

@-webkit-keyframes ring {
  0% {
    -webkit-transform: rotate(-15deg);
    transform: rotate(-15deg);
  }

  2% {
    -webkit-transform: rotate(15deg);
    transform: rotate(15deg);
  }

  4% {
    -webkit-transform: rotate(-18deg);
    transform: rotate(-18deg);
  }

  6% {
    -webkit-transform: rotate(18deg);
    transform: rotate(18deg);
  }

  8% {
    -webkit-transform: rotate(-22deg);
    transform: rotate(-22deg);
  }

  10% {
    -webkit-transform: rotate(22deg);
    transform: rotate(22deg);
  }

  12% {
    -webkit-transform: rotate(-18deg);
    transform: rotate(-18deg);
  }

  14% {
    -webkit-transform: rotate(18deg);
    transform: rotate(18deg);
  }

  16% {
    -webkit-transform: rotate(-12deg);
    transform: rotate(-12deg);
  }

  18% {
    -webkit-transform: rotate(12deg);
    transform: rotate(12deg);
  }

  20% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
}

@keyframes ring {
  0% {
    -webkit-transform: rotate(-15deg);
    -ms-transform: rotate(-15deg);
    transform: rotate(-15deg);
  }

  2% {
    -webkit-transform: rotate(15deg);
    -ms-transform: rotate(15deg);
    transform: rotate(15deg);
  }

  4% {
    -webkit-transform: rotate(-18deg);
    -ms-transform: rotate(-18deg);
    transform: rotate(-18deg);
  }

  6% {
    -webkit-transform: rotate(18deg);
    -ms-transform: rotate(18deg);
    transform: rotate(18deg);
  }

  8% {
    -webkit-transform: rotate(-22deg);
    -ms-transform: rotate(-22deg);
    transform: rotate(-22deg);
  }

  10% {
    -webkit-transform: rotate(22deg);
    -ms-transform: rotate(22deg);
    transform: rotate(22deg);
  }

  12% {
    -webkit-transform: rotate(-18deg);
    -ms-transform: rotate(-18deg);
    transform: rotate(-18deg);
  }

  14% {
    -webkit-transform: rotate(18deg);
    -ms-transform: rotate(18deg);
    transform: rotate(18deg);
  }

  16% {
    -webkit-transform: rotate(-12deg);
    -ms-transform: rotate(-12deg);
    transform: rotate(-12deg);
  }

  18% {
    -webkit-transform: rotate(12deg);
    -ms-transform: rotate(12deg);
    transform: rotate(12deg);
  }

  20% {
    -webkit-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    transform: rotate(0deg);
  }
}

.faa-ring.animated,
.faa-ring.animated-hover:hover,
.faa-parent.animated-hover:hover > .faa-ring {
  -webkit-animation: ring 2s ease infinite;
  animation: ring 2s ease infinite;
  transform-origin-x: 50%;
  transform-origin-y: 0px;
  transform-origin-z: initial;
}

.open > .dropdown-menu {
  -webkit-transform: scale(1, 1);
  transform: scale(1, 1);
  opacity:1;
}

.dropdown-menu {
  opacity:.3;
  -webkit-transform-origin: top;
  transform-origin: top;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  -webkit-transform: scale(1, 0);
  transition: all 0.3s linear;
  -webkit-transition: all 0.3s linear;
}


`  ],
})
export class TopMenuDir {


  public profileInformation: any = {};
  public orgName: any;
  public curOrgId: any;
  public userControl: any = {};
  public userLevel: any;
  public orgSetting: any;
  public vehOverView: any;
  public globalSetting: any;
  public roleManagement: any;
  public userManagement: any;
  // public studentRouteManagement: any
  public sessionTime: any = [];
  public student_Routemanagement: any = {};
  public isVisible: any = {
    Dashboard: true
  }

  public visible: any = {
    StudentRouteManagement: true
  }
  @Input("dirParam") _dir_param: any;
  orgAdminUserName: any;
  orgAdminPassword: any;
  public appReports: any = {};


  constructor(
    public router: Router,
    public globalService: globalService,
    public apiService: ApiService,
    public UtilityServices: UtilityServices,
    private storage: StorageService,
    private com: COM
    // public VehicleServices: VehicleServices,

  ) {

    this.curOrgId = this.storage.get('orgID');

    let uLevel = localStorage.getItem('userLevel');
    this.userLevel = uLevel;
    let uInfo = localStorage.getItem('userInfo');
    if ((uInfo) && (uLevel === '2')) {
      this.userControl = uInfo && JSON.parse(uInfo); //console.log(this.userControl);
      this.orgSetting = this.userControl['settings']['Organization Setting'];
      this.vehOverView = this.userControl['settings']['Vehicle Over View'];
      this.globalSetting = this.userControl['settings']['Global Configuration'];
      this.roleManagement = this.userControl['settings']['Role Management'];
      this.userManagement = this.userControl['settings']['User Management'];
      // this.studentRouteManagement = this.userControl['settings']['student Route Management'];

    } else {
      this.userControl = 1;
    }


    ///console.log(this.userControl);
  }

  ngOnInit() {


    //this.establishSocketConnection();
    if (this._dir_param.showMenu === 'showSchoolMenu') {
      this.viewOrgProfile();
    }

  }


  @Output() triggerVehicleId: EventEmitter<any> = new EventEmitter<any>();

  //   public notificationAction: any = {
  //     data: [],
  //     sos: (arg: any) => {
  //       this.notificationAction.data.push({
  //         category: "sos",
  //         gpsTime: arg.gpsTime,
  //         message: "SOS triggered for " + this.VehicleServices.findVehicle(arg.vehID).vehNo + " at " + this.UtilityServices.convertDateTime(arg.gpsTime) + ". "
  //       });
  //     },
  //     overspeed: (arg: any) => {
  //       this.notificationAction.data.push({
  //         category: "overspeed",
  //         gpsTime: arg.gpsTime,
  //         message: "Overspeeding " + this.VehicleServices.findVehicle(arg.vehID).vehNo + " at " + this.UtilityServices.convertDateTime(arg.gpsTime) + ". "
  //       });
  //     },
  //     /*
  //     category:"sos"
  //    gpsTime:1522837422000
  //    message:"SOS triggered"
  //    vehID:"1512449822968PanicTestTn09"
  // */
  //   }

  @Output() mapSocket: EventEmitter<any> = new EventEmitter();


  viewOrgProfile() {

    let userAuthInfo = {};
    try {
      userAuthInfo = JSON.parse(localStorage.getItem('userInfo'));
      // userAuthInfo['menu']['Dashboard'] = true;
    } catch (e) {
      console.log(e)
    }
    if (userAuthInfo && userAuthInfo['menu'] && (userAuthInfo['menu']['Dashboard'] == true || userAuthInfo['menu']['Dashboard'] == undefined)) {
      this.isVisible.Dashboard = true;
    } else {
      this.isVisible.Dashboard = false;

      if (this.router.url == "/client-dashboard") {

        this.router.navigate(['/movementReport']);

      }

    }
    // console.log(userAuthInfo)
    // if (userAuthInfo && userAuthInfo['reports'] && (userAuthInfo['reports']['studentroutemanagement']['studentRouteManagement'] == true || userAuthInfo['reports']['studentroutemanagement']['studentRouteManagement'] == undefined)) {
    //   this.isVisible.studentRouteManagement = true;
    // } else {
    //   this.isVisible.studentRouteManagement = false;
    // }
    // console.log(this.isVisible)
    this.storage.set({ 'video': false });
    this.apiService.getCurrentOrgDetails({
      data: {
        key: localStorage.getItem('scbToken'),
        filter: {},
        projections: ['name', 'reports', 'schoolsessionLists', 'classLists',
          'SectionLists', 'cameraModuleView', 'otherlang', "orgLocation"]
      }
    }).then(response => {
      let result = response;
      const classandsection: any = {};


      if (result.status === 'success') {
        this.storage.set({ 'schoolLatLng': result.response.orgLocation });

        this.storage.set({ 'video': result.response.cameraModuleView });
        this.com.comAction.next({
          type: 'StorageService',
          value: result.response.cameraModuleView
        })
        classandsection.listofclasses = result.response.classLists;
        classandsection.listofsections = result.response.SectionLists;

        let chkSessionProperty = result.response.hasOwnProperty('schoolsessionLists');
        let localLanguage = result.response.hasOwnProperty('otherlang');



        if (localLanguage) {
          this.storage.set({ 'linguastic': result.response.otherlang });
        }
        if (chkSessionProperty) {
          this.storage.set({ 'time': null });
          this.sessionTime = [];
          this.sessionTime = result.response.schoolsessionLists;
          this.storage.set({ 'time': this.sessionTime });
        } else {
          this.storage.set({ 'time': null });
        }
        this.profileInformation = result.response;
        if (this.profileInformation) {
          this.orgName = this.profileInformation.name;
        }

        // student_routemanagement
        if (result.response && result.response.reports && result.response.reports.studentroutemanagement
          && result.response.reports.studentroutemanagement.studentRouteManagement
        ) {
          this.student_Routemanagement = result.response.reports.studentroutemanagement.studentRouteManagement

        }


        this.com.comAction.next({
          lable: 'OrgReports',
          data: result.response.reports
        });
        this.storage.set({ 'classandsection': classandsection });


      }
    }).catch(error => {
      console.log('Error while logging in', error);
    });


  }


  listRoute() {
    //   if ( this.globalService.getData() ) {
    //   const navigationFrom = this.globalService.getData().navigationFrom; /// console.log(navigationFrom);
    //   if (navigationFrom !== 'editStudent') { // console.log('not',navigationFrom);
    //       this.globalService.setData({
    //         'sudentID': '',
    //         'studentName': '',
    //         'newRouteName': ''
    //       });
    //     }
    //  } else {
    //   // console.log('else',navigationFrom);
    //  }

    this.router.navigate(['/school-routes-list']);

    ///  hideAddRoute(e:any);

  }

  // establishSocketConnection() {
  //   // this.apiService.pushpin().then(res=>{
  //   //    if(localStorage.getItem('scbToken') != '') this.establishSocketConnection();
  //   //    if (res['type'] == 'geoPosition') {
  //   //       this.mapSocket.emit(res);
  //   //       //console.log('geoposition');
  //   //    }
  //   //    if(res['type'] == 'pushNotifications'){
  //   //        //this.emitNewNotif.emit(res);
  //   //       // console.log('push');

  //   //        this.notificationAction[ res['data']['category'] ](  res['data'] );
  //   //        // this.triggerVehicleId.emit(res['data']);
  //   //    }
  //   // }).catch(err=>{
  //   //    if(err=="error"){
  //   //     setTimeout( ()=>{ this.establishSocketConnection() },500000 ); // this.push();
  //   //    }
  //   // });
  // }
  /* 
  
  this.apiService.viewNotifications({
      data: {
          key: localStorage.getItem('scbToken'),
          filter: {
            notificationId: this._notificationId
          }
      }
    }).then(response => {
        let result=response;
  */



  logoutAdmin() {
    const parent = this;
    swal({
      title: 'Are you sure?',
      text: 'You will be logged out',
      icon: 'warning',
      buttons: [
        'No, cancel it!',
        'Yes, I am sure!'
      ],
      dangerMode: true,
    }).then(function (isConfirm: any) {
      if (isConfirm) {
        setTimeout(() => {
          localStorage.setItem('adminToken', '');
          localStorage.removeItem('adminToken');
          localStorage.removeItem('userInfo');
          parent.router.navigate(['/sysAdmin/admin-login']);
        }, 500);
      } else {
        //swal('Cancelled', 'Continue the session :)', 'error');
        //do nothing
      }
    });
  }

  repo() {
    // console.log('rep clicked');
    this.storage.set({
      'vehicleId': '',
      'vehicleName': '',
      'report': ''
    })
  }


  logoutOrg() {
    const parent = this;

    swal({
      title: 'Are you sure?',
      text: 'You will be logged out',
      icon: 'warning',
      buttons: [
        'No, cancel it!',
        'Yes, I am sure!'
      ],
      dangerMode: true,
    }).then((isConfirm: any) => {
      if (isConfirm) {




        setTimeout(() => {

          localStorage.setItem('scbToken', '');
          localStorage.removeItem('scbToken');
          localStorage.removeItem('userInfo');
          //  parent.router.navigate(['/login']);

          this.storage.removeAll();

          window.location.href = '/login';
        }, 500);



      } else {
        //swal('Cancelled', 'Continue the session :)', 'error');
        //do nothing
      }
    });
  }




}
/*
Notes : npm install node-sass -g
*/
