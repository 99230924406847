/**
 * @Author: Deepak Pookkote <group10>
 * @Date:   2018-04-24T11:51:53+05:30
 * @Email:  deepak@groupten.com
 * @Project: School-Bus
 * @Filename: uploadPickPoints.dir.ts
 * @Last modified by:   group10
 * @Last modified time: 2018-05-08T16:07:43+05:30
 * @Copyright: Group10 Technologies
 */



import { Component, ElementRef, AfterViewInit,HostListener,
  OnDestroy, ViewChild, Input, Output,EventEmitter, NgZone,
} from '@angular/core';
import { globalService } from './../../../services/global.service';
import { ApiService } from './../../../services/ApiService';
import { RouterModule, Routes, Router } from '@angular/router';
import { excelUpload } from './../../../services/excelUploadService'
import { GlobalUtils } from './../../../services/global-utils';

import { StorageService } from './../../../services/storage.service';


declare var $: any;
const swal = require('sweetalert');

@Component({
  selector:'add-pickUp-points',
  template:`
  <div class="route-heading-container" style="background: rgba(158, 158, 158, 0.09);min-height: 100px;">
    <div class = "header-tabs">
      <div class="back-button pull-left grow  grow err-chk-goback" (click)="hideStoppageDir($event)" style="cursor:pointer;" data-toggle="tooltip" data-placement="bottom" title="go back">
        <svg width="24" height="24" viewBox="0 0 24 24"> <g class="nc-icon-wrapper" fill="#429db5"> <path d="M21 11H6.83l3.58-3.59L9 6l-6 6 6 6 1.41-1.41L6.83 13H21z"></path> </g> </svg>
      </div>
      <div class="row route-heading-tabs" style="padding-top:23px">
          <div class="list-heading" style="color:#01bcd4;font-size:27px;">{{RouteName}}</div>
          <div class="" style="font-size:18px;margin-left:38px;">Add Pickup Point </div>
      </div>
    </div>
  </div>
    <div class="container-fluid upload-points-dir">
      <div class="upload-point-container">
        <div class="point-excel-upload">
          <div class="drop-file-container">
            <div class="icon-drag-drop">
              <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 48 48" xml:space="preserve" width="100" height="100"><g class="nc-icon-wrapper"><path fill="#E6E6E6" d="M41,47H7c-1.105,0-2-0.895-2-2V3c0-1.105,0.895-2,2-2l24,0l12,12v32C43,46.105,42.105,47,41,47z"></path> <path fill="#B3B3B3" d="M31,1v10c0,1.105,0.895,2,2,2h10L31,1z"></path> <path fill="#B3B3B3" d="M34,35H14c-0.552,0-1,0.448-1,1s0.448,1,1,1h20c0.552,0,1-0.448,1-1S34.552,35,34,35z"></path> <path fill="#B3B3B3" d="M34,25H14c-0.552,0-1,0.448-1,1s0.448,1,1,1h20c0.552,0,1-0.448,1-1S34.552,25,34,25z"></path> <path fill="#B3B3B3" d="M22,15h-8c-0.552,0-1,0.448-1,1s0.448,1,1,1h8c0.552,0,1-0.448,1-1S22.552,15,22,15z"></path></g></svg>
            </div>
            <div class="title">Drag and drop a csv - xls file</div>
            <div class="action-title"><i class="fa fa-upload mr5"></i><span class="  ">Or, select from your computer</span></div>
            <input  type="file" accept=".csv,.xml,.xls,.xlsx,.xlsm"  multiple="false" id="pickup-input" (change)="onFileChange($event)" class="test-stops-input ng-pristine ng-untouched ng-valid ng-isolate-scope err-chk-upload-pickup-pt meager-e2e-fileuplaod-pickup">
          </div>
        </div>
      </div>
        <span class="excel-template-downalod-ref pull-right meager-e2e-excelTemplate" (click)="downloadTemplate()">
          <a class="excel-template-link" tabindex="0">Download an excel template.</a>
        </span>
        <div class="form-actions text-center pickup-form-upload-btn">
            <div style="font-weight: 500;margin-right: 8%;margin-bottom:10px;" class="text-center">OR</div>
            <button class="webform-submit button-primary btn btn-primary form-submit meager-e2e-addForm" 
            style="margin-right: 8%;"
            (click)="showStoppageForm($event)">Add Form</button>
        </div>

    </div>
  `,
  styles:[`
    .createNewRoute{
         font-size: 2.27rem;
         font-weight: 400;
         width: 35px;
         border-radius: 50%;
         color: white;
         line-height: 35px;
         text-align: center;
         height: 35px;
         margin: 4px auto;
         box-shadow: 0px 2px 5px rgba(94, 94, 94, 0.68);
         cursor: pointer;
         background: #00BCD4;
         z-index: 10;
    }
    .add-new{
       font-size: 2.27rem;
       font-weight: 400;
       width: 35px;
       border-radius: 50%;
       color: white;
       line-height: 35px;
       text-align: center;
       height: 35px;
       margin: 4px auto;
       box-shadow: 0px 2px 5px rgba(94, 94, 94, 0.68);
       cursor: pointer;
       background: #00BCD4;
       z-index: 10;
    }
    .upload-point-container{
      margin-top: 1em;
      padding-bottom: 1em;
      background-color: #fff;
    }
    .point-excel-upload{
      padding: 1em;
      color: #b5c4c9;
      text-align: center;
    }
    .drop-file-container{
      position: relative;
      border: 2px dashed #b5c4c9;
      border-radius: 5px;
      padding: 30px;
      background-color: #f5f5f5;
    }
    .icon-drag-drop{
      font-size: 7em;
      margin-bottom: 20px;
      color: #b5c4c9;
    }
    .icon-upload{
      color: #4a4847;
      margin-right: .5em;
    }
    .drop-file-container input{
      position: absolute;
      opacity: 0;
      cursor: pointer;
      top: 0;
      left: 0;
      right: 0;
      height: 100%;
      width:100%;
    }
    .header-tabs{
      height:30px;
      padding:10px;
    }
    .stoppage-upload{
      margin-top:15px;
    }
    .pickup-form-upload-btn{
      margin-top:10%;
    }
    .excel-template-link{
      text-decoration:none;
      cursor:pointer;
      font-size:13px;
    }
  /*animation css */
    .grow { transition: all .2s ease-in-out; }
    .grow:hover { transform: scale(1.1); }

  `],


})

export class uploadPickPointsDir{

  public RouteName:any;

  constructor(
    public excelUpload:excelUpload, 
    private globalUtils:GlobalUtils, 
    private globalservice:globalService,
    private storage:StorageService
  ){

  }
  @Input('routeId') __routeId:any;

  @Input('routeStoppages')
  set name(routeStoppages: any) {

   let getRouteName = this.storage.get('newRouteName');
    
   if(getRouteName){
    this.RouteName=getRouteName;
   }else{
    this.RouteName=routeStoppages.routeName;
   }
    
    

  this.storage.set({
     'pickUprouteName':this.RouteName
  });
    // let getRouteName = this.globalservice.getData().newRouteName;
       
    // this.RouteName=getRouteName;


  }

  @Output() hideStoppageUpload:EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() triggerStoppageForm:EventEmitter<boolean> = new EventEmitter<boolean>();
//  @Output() emitShowUploadSuccessDialog:EventEmitter<any> = new EventEmitter<any>();
//  @Output() emitUploadDialogLoader:EventEmitter<boolean> = new EventEmitter<boolean>();

  @HostListener('drop', ['$event']) public onDrop($evt:any){
    $evt.preventDefault();
    $evt.stopPropagation();
    let files = $evt.dataTransfer.files;
    if(files.length > 0){
      // console.log(files);
    }
  }

  ngOnInit(){
    $(document).ready(function(){
        $('[data-toggle="tooltip"]').tooltip();
    });
    //console.log(this.RouteName);

    // console.log('*******');
  }

   hideStoppageDir($event:any){
    this.hideStoppageUpload.emit(false);
    localStorage.setItem("routeTimings",'');
  }

  showStoppageForm($event:any){
    this.triggerStoppageForm.emit(true);
  }

  onFileChange(event:any) {
    let reader = new FileReader();
    let parent:any = this;
    //this.emitShowUploadSuccessDialog.emit({showSuccessDialog:true,showUploadLoader:true,showUploadSuccess:false,showUploadFilure:false});
  //  this.emitUploadDialogLoader.emit(true);
    if(event.target.files && event.target.files.length > 0) {
      let file = event.target.files[0];
      var apiKey= localStorage.getItem('scbToken');
       this.excelUpload.pickupPointsExcelUpload(apiKey,this.__routeId,file,'pickup-input',function(obj:any){

          if(obj.status == 'success'){
              swal('Success', 'Excel Uploaded Successfully', 'success');
              setTimeout( () => { swal.close(); }, 1000);
          }else if(obj.status == 'failure'){

            let apiRepsponceArray:any =[];
            let errorArray:any=[];
            let orgError:any=[];
            apiRepsponceArray  =  obj.response.duplicated;
            Object.keys(apiRepsponceArray).forEach(function(key:any) {
              errorArray = apiRepsponceArray[key];

              for(var index in errorArray){
                orgError.push(errorArray[index]);
              }
              console.log(errorArray);
              if(errorArray.ETA){
                swal({
                  title: 'Failure',
                  text: 'ETA mismatch :-' + orgError,
                  icon: 'error',
                });
              }else {
                swal({
                  title: 'Failure',
                  text: 'Alreday Exisiting (Duplicated) :-' + orgError,
                  icon: 'error',
                });
              }
               
            });

          }else if(obj.status=='partial'){
              let apiRepsponceArray:any =[] =  obj.response.succeed;
              let DuplicatedArray:any=[]=obj.response.duplicated
              let errorArray:any=[];
              let duparray:any=[];
              let orgError:any=[];
              let dupValues:any=[];
               Object.keys(apiRepsponceArray).forEach(function(key:any) {
                 errorArray = apiRepsponceArray[key];

                 for(var index in errorArray){
                   orgError.push(errorArray[index]);
                 }
               });
               Object.keys(DuplicatedArray).forEach(function(key:any) {
                 duparray = DuplicatedArray[key];

                 for(var index in duparray){
                   dupValues.push(duparray[index]);
                 }
               });
               let span = document.createElement("span");
               span.innerHTML = "Success :-<b>"+orgError + "</b><br><hr>Duplicated :-<b>"+dupValues+"</b>";
               swal({
                 title: 'Partially Uploaded',
                 content: span,
                 icon: 'warning',
               });
          }
       });
    }
  }

  downloadTemplate() {
    window.open('https://api-school.placer.in/api/v2/webroot/sample/pickupInfo.xlsx');
  }

}

// "Success :-<b>"+orgError + "</b><br><hr>Duplicated :-<b>"+dupValues+"</b>"



