import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ApiService } from './../../../services/ApiService';
const swal = require('sweetalert');

@Component({
  selector: 'app-add-users',
  templateUrl: './add-users.component.html',
  styleUrls: ['./add-users.component.css']
})
export class AddUsersComponent implements OnInit {

  public formControl: any ;
  public userInfo: any = {};
  public formError: any = {};
  public dobData = '';
  public ss = [1,2,3,4];
  constructor(
    private router: Router,
    private api: ApiService
  ) {
    
   
   }

  ngOnInit() {

      this.loadData();
     
  }

  public roleInfo: any = [];
  loadData(){
    
    let apiData = {         
      data:{
          key: localStorage.getItem("scbToken"),
          // extra:{
          //   pageJump: this.pagejump
          // }
        }    
      }

        this.api.listRoles(apiData).then( (res: any)=>{
              if(res.status === 'success'){
                   // this.roleInfo = res.response;
                   this.roleInfo = res.response;
                 

              }
        });

  }

  addUser(formData: any){
    this.formError.uname = false;
    this.formError.loginname = false;
    this.formError.email = false;
    this.formError.userPass = false;
    this.formError.userPhone = false;
    this.formError.gender = false;
    this.formError.designation = false;
    this.formError.dob = false;
    this.formError.address = false;
    this.formError.area = false;
    this.formError.city = false;
    this.formError.statue = false;
    this.formError.country = false;
    this.formError.status = false;


       if(formData.valid){
              
             let form: any = {};
          
              form.name = formData.value.uname;
              form.loginname = formData.value.loginname;
              form.email = formData.value.email;
              form.password = formData.value.userPass;
              form.phone = formData.value.userPhone;
              form.designation = this.userInfo.designation;
              form.dob = this.userInfo.dobModified;
              form.gender = formData.value.gender;
              form.roleid = formData.value.role;
              form.address = formData.value.address;
              form.area = formData.value.area;
              form.city = formData.value.city;
              form.state = formData.value.statue;
              form.country = formData.value.country;
              form.status = formData.value.status;
              form.levels = 2;

             // this.api.addNewuser()
              
             let apiData = {
                data :{
                    key: localStorage.getItem('scbToken'),
                    form
                }
             }
 // console.log(apiData);
             this.api.newUser(apiData).then( (res: any)=>{
             
                 if(res.status === 'success'){
                        swal('User details added succefuly...!');
                         setTimeout( ()=>{
                               this.router.navigate(['/user-management',{}]);
                         });
                 }else{
                    if(res.status === 'failure'){
                         if(res.ec === 'SCB11'){
                             swal('User already exist');
                              
                         }
                    }

                 }

             });
              
       }else{

        if(formData.value.uname == '' || formData.value.uname == undefined){
          this.formError.uname = true;
        }
        if(formData.value.loginname == '' || formData.value.loginname == undefined){
          this.formError.loginname = true;
        }
        if(formData.value.email == '' || formData.value.email == undefined){
          this.formError.email = true;
        }
        if(formData.value.userPass == '' || formData.value.userPass == undefined){
          this.formError.userPass = true;
        }
        if(formData.value.userPhone == '' || formData.value.userPhone == undefined){
          this.formError.userPhone = true;
        }
        if(formData.value.gender == '' || formData.value.gender == undefined){
          this.formError.gender = true;
        }
        // if(formData.value.designation == '' || formData.value.designation == undefined){
        //   this.formError.designation = true;
        // }
        if(formData.value.role == '' || formData.value.role == undefined){
          this.formError.role = true;
        }
        if(formData.value.address == '' || formData.value.address == undefined){
          this.formError.address = true;
        }
        if(formData.value.area == '' || formData.value.area == undefined){
          this.formError.area = true;
        }
        if(formData.value.city == '' || formData.value.city == undefined){
          this.formError.city = true;
        }
        if(formData.value.statue == '' || formData.value.statue == undefined){
          this.formError.statue = true;
        }
        if(formData.value.country == '' || formData.value.country == undefined){
          this.formError.country = true;
        }
        if(formData.value.status == '' || formData.value.status == undefined){
          this.formError.status = true;
        }



       }
      
  }


  tooglepassword() {
    let x: any = document.getElementById("uPassword");
    if (x.type === "password") {
      x.type = "text";
    } else {
      x.type = "password";
    }
  }

  navigate(url: string){
       this.router.navigate(['/' + url , {}]);
  }

  selectedRole(event: any){
     
     // console.log(event.target.value);
  }
 
}
