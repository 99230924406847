import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';
import { roleFormModule } from '../../../model/role-model';
import { Objectkeys } from '../../../custom-pipes/objectkeys.pipe';
import { ApiService } from '../../../services/ApiService';
import { COM } from '../../../services/com.services';
import { StorageService } from './../../../services/storage.service';
// const swal = require('sweetalert');
import { ExcelService } from './../../../services/excel.service';
import { FormControl, FormGroup, Validators, FormBuilder } from '@angular/forms';
import { style } from '@angular/animations';
declare var swal: any;

@Component({
    selector: 'add-student-route-management',
    templateUrl: './add-student-route-management.html',
    styleUrls: ['./add-student-route-management.css']
})
export class AddStudentRouteManagementComponent implements OnInit {

    public formError: any = {};
    errorMesages: any[];
    public studentName: any;
    public studentId: any;
    public class: any;
    public section: any;
    public primaryMobileNo: any;
    public selectedPickupPoint: any;
    public studentDropLocation: any;
    public apiHeader: any = {}
    public showExcelDownload: boolean = false;
    public dateWiseExlStatus: any = {};
    studentForm: any;
    addressDisabled: boolean = true;
    validExcel = false;
    public sNo: any;
    submitted: boolean;
    inputData: any = {};

    isClicked: boolean = false;
    constructor(
        private router: Router,
        private formBuilder: FormBuilder,
        private api: ApiService,
        public storage: StorageService,
        private com: COM,
        private excel: ExcelService,

    ) {


    }


    ngOnInit() {

        this.getExcelconfigurationList()


    }


    studentRouteForm() {

        this.studentForm = this.formBuilder.group({
            serialNo: new FormControl(this.inputData.response.serialNo, Validators.required),
            studentID: new FormControl(this.inputData.response.studentID, Validators.required),

            studentName: new FormControl(this.inputData.response.studentName, Validators.required),
            class: new FormControl(this.inputData.response.class, Validators.required),
            section: new FormControl(this.inputData.response.section, Validators.required),
            primaryMobileNo: new FormControl(this.inputData.response.primaryMobileNo, Validators.required),
            secondaryMobileNo: new FormControl(this.inputData.response.secondaryMobileNo, Validators.required),

            pickupRoute: new FormControl(this.inputData.response.pickupRoute, Validators.required),
            pickupPoint: new FormControl(this.inputData.response.pickupPoint, Validators.required),
            pickupETA: new FormControl(this.inputData.response.pickupETA, Validators.required),
            dropRoute: new FormControl(this.inputData.response.dropRoute, Validators.required),
            dropPoint: new FormControl(this.inputData.response.dropPoint, Validators.required),

            dropETA: new FormControl(this.inputData.response.dropETA, Validators.required),

            // isDefault: new FormControl(this.inputData.response.isDefault, Validators.required)
        });
    }
    initialValue = {
        serialNo: 'S.No.',
        studentID: 'Student ID',
        studentName: 'Student Name',
        class: 'Class',
        section: 'Section',
        primaryMobileNo: 'Primary Mobile No',
        secondaryMobileNo: 'Secondary Mobile No',
        dropETA: "Drop ETA",
        dropPoint: "Drop Point",
        dropRoute: "Drop Route",
        pickupETA: "Pickup ETA",
        pickupPoint: "Pickup Point",

        pickupRoute: "Pickup Route"

    };
    navigate(url: string) {
        this.router.navigate(['/' + url, {}]);
    }


    updateExcel(submitType: any) {

        swal('Route details updated successfully...!');



        this.validExcel = !this.validExcel;

        let errorFlag = false; this.errorMesages = [];




    }
    getExcelconfigurationList() {
        let apiData = {
            data: {
                key: localStorage.getItem('scbToken'),
            }
        }
        this.api.getExcelconfigurationList(apiData).then((res: any) => {

            if (res.status === 'success') {
                this.inputData = res
                // this.default = this.inputData.response.isDefault
                this.studentRouteForm()
                // this.defaultForm()


            }
        })


    }

    studentRouteFormUpdate(obj: any) {

        this.formError = false;

        this.submitted = true;
        const xlDataArray = [];

        this.validExcel = !this.validExcel;

        let errorFlag = false; this.errorMesages = [];


        let apiData = {
            data: {
                key: localStorage.getItem('scbToken'),
                form: {
                    serialNo: this.studentForm.value.serialNo,
                    studentID: this.studentForm.value.studentID,
                    studentName: this.studentForm.value.studentName,
                    class: this.studentForm.value.class,
                    section: this.studentForm.value.section,
                    primaryMobileNo: this.studentForm.value.primaryMobileNo,
                    secondaryMobileNo: this.studentForm.value.secondaryMobileNo,
                    pickupRoute: this.studentForm.value.pickupRoute,
                    pickupPoint: this.studentForm.value.pickupPoint,
                    pickupETA: this.studentForm.value.pickupETA,
                    dropRoute: this.studentForm.value.dropRoute,
                    dropPoint: this.studentForm.value.dropPoint,
                    dropETA: this.studentForm.value.dropETA,

                    isDefault: this.isClicked
                },
            }
        }

        this.api.getExcelconfigurationUpdate(apiData).then((res: any) => {

            if (res.status === 'success') {

                swal('Excel configuration details updated successfuly...!');
                setTimeout(() => {
                }, 300);

            } else {
                swal('Something wrong...!');
            }
        })


    }
    formReset() {
        this.isClicked = !this.isClicked
        swal({
            title: 'Are you sure?',
            text: 'You will be reset form values',
            icon: 'warning',
            buttons: [
                'No, cancel it!',
                'Yes, I am sure!'
            ],
        }).then((isConfirm: any) => {
            if (isConfirm) {
                swal({
                    text: 'Your file has been Reset.',
                });
                this.studentForm.reset(this.initialValue)

            } else {


            }
        });

    }
    exportJson() {

        const fileName = 'Student Route Mapping Template';
        let excelDownloadData: any = [];

        let defaultColumn = Object.keys(this.studentForm.value);

        let newObject = {

        };
        let newObject1 = {

        };

        for (let column of defaultColumn) {
            newObject[this.studentForm.value.serialNo] = "1";
            newObject[this.studentForm.value.studentID] = "SD101", "SD102";
            newObject[this.studentForm.value.studentName] = "Varun";
            newObject[this.studentForm.value.class] = "V";
            newObject[this.studentForm.value.section] = "B";
            newObject[this.studentForm.value.primaryMobileNo] = "9999999999";
            newObject[this.studentForm.value.secondaryMobileNo] = "9999999998";
            newObject[this.studentForm.value.pickupRoute] = "Route-P01";
            newObject[this.studentForm.value.pickupPoint] = "C.R. Park Opp. SBI";
            newObject[this.studentForm.value.pickupETA] = "07:00";
            newObject[this.studentForm.value.dropRoute] = "Route-D01";
            newObject[this.studentForm.value.dropPoint] = "C.R. Park Opp. SBI";
            newObject[this.studentForm.value.dropETA] = "13:00";
            // newobject1
            newObject1[this.studentForm.value.serialNo] = "2";
            newObject1[this.studentForm.value.studentID] = "SD102";
            newObject1[this.studentForm.value.studentName] = "Premendra Shankar";
            newObject1[this.studentForm.value.class] = "V";
            newObject1[this.studentForm.value.section] = "A";
            newObject1[this.studentForm.value.primaryMobileNo] = "7777777777";
            newObject1[this.studentForm.value.secondaryMobileNo] = "8888888810";
            newObject1[this.studentForm.value.pickupRoute] = "Route-P02";
            newObject1[this.studentForm.value.pickupPoint] = "Le Royal Meridian";
            newObject1[this.studentForm.value.pickupETA] = "07:20";
            newObject1[this.studentForm.value.dropRoute] = "Route-D02";
            newObject1[this.studentForm.value.dropPoint] = "Le Royal Meridian";
            newObject1[this.studentForm.value.dropETA] = "15:00";

        }
        excelDownloadData = [newObject, newObject1];

        this.excel.exportExcel(excelDownloadData, fileName);
    }



}


