import { Component,Input,Output,EventEmitter} from "@angular/core";
import { ApiService } from "../../../services/ApiService";


@Component({
    selector:'route-wise-attendance',
    template:`

    <ng-container *ngIf="routewiseAttendance.length && !showLoader">
    <div class="panel panel-default">
       <div class="panel-heading">
          <div class="row mb5">
             <div class="reportHeading text-center mb5">RouteWise Attendance Report</div>
          </div>
          <div class="row mb5">
             <div class="col-md-6 text-left">
                <label class="control-label">Date : </label>
                <span class="reportStartDate"> {{selectedDate}}</span>
             </div>
             <div class="col-md-6 text-right">
                <label class="control-label">RouteName : </label>
                <span class="reportEndDate"> {{routeDetails}}</span>
             </div>
          </div>
          <div class="row mb5">
             <!-- <div class="col-md-6 text-left">
                <label class="control-label">Date : </label>
                <span class="reportStartDate"> {{selectedDate}}</span>
             </div> -->
             <div class="col-offset-md-6 text-right" style="padding: 0px 15px;">
             
                <span *ngIf="validExcel"> 
                <a href="{{xlString}}" target="_blank" class="meager-e2e-datewise-tab-attendance">
                    Download excel
                </a>
                </span>
             </div>
          </div>
       </div>
      
        
       
       <div class="panel-body" style="max-height:444px">
       <div class="row">
       <div class="col-md-12">
       
       <table class="table table-bordered tablelike text-center">
       <tbody>
       <tr>
         <td class="whitegradience">
            Total : 
            <ng-container *ngIf="showCount == true">
               <i class="fa fa-spinner fa-pulse fa-fw"></i>
               <span class="sr-only">Loading...</span>
            </ng-container>
            <ng-container *ngIf="showCount == false">
               {{routewiseAttendance.length}}
            </ng-container>
         </td>
          <td class="whitegradience">
             Boarded :
             <ng-container *ngIf="showCount == true">
                <i class="fa fa-spinner fa-pulse fa-fw"></i>
                <span class="sr-only">Loading...</span>
             </ng-container>
             <ng-container *ngIf="showCount == false">
                {{boardedCnt}}
             </ng-container>
          </td>
     
          <td class="whitegradience">
             Not Boarded :
             <ng-container *ngIf="showCount == true">
                <i class="fa fa-spinner fa-pulse fa-fw"></i>
                <span class="sr-only">Loading...</span>
             </ng-container>
             <ng-container *ngIf="showCount == false">
                {{notBoardedCnt}}
             </ng-container>
          </td>
       </tr>
       </tbody>
    </table>
       </div>
       </div>
       <div class="row">
       <div class="col-md-12 table-responsive">
           <table class="table table-bordered">
             <thead>
             <tr class="text-center">
             <th class="text-center">#</th>

                <th class="text-center">Student Id</th>
                <th class="text-center">Student Name</th>
                <th class="text-center">Class</th>
                <th class="text-center">Section</th>
                <th class="text-center">Boarded</th> 
                <th class="text-center">Deboarded</th>
                <!-- <th colspan="2" class="text-center">
                <tr class="text-center"><td class="present" style="left:61px;!important">Present</td></tr>
                  <tr class="text-center">
                     <td>Boarded</td>
                     <td style="position:relative;left:30%">Deboarded</td>
                  </tr>
                </th> -->
                <th class="text-center">Not Boarded</th>
             </tr>
             </thead>
             <tbody>
                <ng-container *ngFor="let item of routewiseAttendance;let n = index;">
                   <tr>
                      <td>{{n+1}}</td>
                      <td>{{item.memberUID}}</td>
                      <td>{{item.memberName}}</td>
                      <td>{{item.memberClass}}</td>
                      <td>{{item.memberSection}}</td>
                      <td>
                         <ng-container *ngIf="item.boarded_ts;else invalidData">
                            <!-- <span class="text-center"><i class="fa fa-check text-success" aria-hidden="true"></i></span><br><span>{{item.boarded_ts}}</span> -->
                            <div class="text-center" [attr.data-tooltip]="item.boarded_pickup">
                            <span><i class="fa fa-check-circle greenText" aria-hidden="true"></i></span>
                            <span style="margin-left: 16px;color: #6d6d6d;"><i class="fa fa-map-marker" aria-hidden="true"></i></span>                            
                            </div>
                            <div class="text-center" [attr.data-tooltip]="item.boarded_pickup">{{item.boarded_ts |  date:'HH:mm'}}</div>                         </ng-container>
                      </td>
                      <td>
                         <ng-container *ngIf="item.deboarded_ts;else invalidData">
                            <!-- <span class="text-center"><i class="fa fa-check text-success" aria-hidden="true"></i></span><br><span>{{item.deboarded_ts}}</span> -->
                            <div class="text-center" [attr.data-tooltip]="item.deboarded_pickup">
                            <span><i class="fa fa-check-circle greenText" aria-hidden="true"></i></span>
                            <span style="margin-left: 16px;color: #6d6d6d;"><i class="fa fa-map-marker" aria-hidden="true"></i></span>
                            </div>
                            <div class="text-center" [attr.data-tooltip]="item.deboarded_pickup">{{item.deboarded_ts |  date:'HH:mm'}}</div>
                            
                         </ng-container>
                      </td>
                      <td>
                         <ng-container *ngIf="item.absent;else invalidData">
                            <!-- <span class="text-center"><i class="fa fa-check text-success" aria-hidden="true"></i></span><br><span>{{item.notboarded_ts}}</span> -->
                              <div class="text-center" style="color:white">dummy</div>
                              <div class="text-center"><i class="fa fa-times-circle redText"></i></div>

                              <div class="text-center" style="color:white">dummy</div>
                           
                         </ng-container>
                      </td>
                   </tr>
                </ng-container>
             </tbody>
          </table>
          </div>
          </div>
          <ng-template #validData>
          </ng-template>
          <ng-template #invalidData>
             <!--<span class="text-center"><i class="fa fa-times text-danger" aria-hidden="true"></i></span><br><span><br> - </span>       -->
             <div class="text-center" style="color:white">dummy</div>
             <div class="text-center">-</div>
             <div class="text-center" style="color:white">dummy</div>

          </ng-template>
       </div>
    </div>
 </ng-container>
 <!-- <ng-container *ngIf="!showLoader && !routewiseAttendance.length">
 <div class="panel panel-default">
 <div class="panel-heading">
 <div class="row mb5">
     <div class="reportHeading text-center mb5">RouteWise Attendance Report</div>
 </div>
 </div>
 <div class="panel-body">
 <h4 class="animated pulse text-center" style="animation-duration: 1s;color:#999FBF"><i class="fa fa-frown-o"></i>&nbsp;No records found</h4>
 </div>
 </div>
 </ng-container> -->
 <ng-container *ngIf="!routewiseAttendance.length || showLoader">
 <div class="panel">
 <div class="panel-body">
 <div class="loaderContainer">
 <loader></loader>
</div>
</div>
</div>
 </ng-container>
   
    `,
    styleUrls:['../attendance-reports/attendance-reports.component.css'],
})

export class RoutewiseAttendanceComponent {
   public response: any;
   public routeRepo: any={};
  public  routewiseAttendance: any[];
  public  selectedDate: any;
   public routeDetails: any;
    public apiHeader:any = {}
public keyed:any = Object.keys;
    boardedCnt: number;
    deboardedCnt: number;
    notBoardedCnt: number;
    showCount:boolean;
    vehicleName: any;
    showLoader:boolean;
    validExcel:boolean = false;
    xlString:string = 'string';
// variable declarations


// end of variable declarations
    constructor(
        public api:ApiService
    ) {
        this.showCount= true;
        this.showLoader = true;
    }



//business logics for display


@Input('getRouteWiseData') // get api response in this variable
set getRouteWiseData(val:any){
   try{
      this.showLoader = true;
    this.valueResetter();
    this.apiHeader = val;
    this.selectedDate = val.data.form.fromDate.split('-').reverse().join('-');
    this.routewiseAttendance=[];
    this.getRouteAttendanceDataFromAPI();
   
    this.excelDownloaded();
   }catch(e){}
}
// @Input('getRouteData')
// set getRouteData(vals:any){
//     setTimeout(() => {
//         this.routeDetails = vals[0]['routeName'];
 
//     }, 200);
    
// }
@Output() errorDetected: EventEmitter<any> = new EventEmitter();
ngOnInit(){

 
}
valueResetter(){
    this.routewiseAttendance = [];
    this.apiHeader = {};
}

getRouteAttendanceDataFromAPI(){
   
    this.api.getTabAttendanceReport(this.apiHeader).then((res:any)=>{
        // this.routeRepo = res.response;     
      //   let routeRepo = res.response;

      let routeRepo = res.hasOwnProperty('response') ? res.response : res;
      
        this.boardedCnt = 0;
        this.deboardedCnt = 0;
        this.notBoardedCnt = 0;
       

      if(!routeRepo.ec){

      
        Object.keys(routeRepo).forEach((item:any)=>{

            Object.keys(routeRepo[item]).forEach((items:any)=>{

             

                let stud ={};
                let objCreator = {};
               
                if(routeRepo[item][items].hasOwnProperty('memInfo')){
                    stud = {...routeRepo[item][items]['memInfo']}
                }
               if(routeRepo[item][items].hasOwnProperty('routeInfo')){
                    this.routeDetails = routeRepo[item][items]['routeInfo']['RouteName'];
                    this.vehicleName = routeRepo[item][items]['routeInfo']['VehicleName']
                }

                if(Object.keys(routeRepo[item][items]).length > 2){

                           if(routeRepo[item][items].hasOwnProperty('boarding')){
                           // board = {...routeRepo[item][items]['boarding']}
                                    objCreator['boarded_ts'] = routeRepo[item][items]['boarding']['logTimeMS'];
                                    objCreator['boardedstatus'] = routeRepo[item][items]['boarding']['boardedstatus'];
                                    objCreator['boarded_pickup'] = routeRepo[item][items]['boarding']['pickuppointName'];
                                    
                                    if(routeRepo[item][items]['boarding']['boardedstatus'] === 'boarding'){
                                          this.boardedCnt++;
                                    }
                                }

                              if(routeRepo[item][items].hasOwnProperty('deboarding')){
                                    // deboard = {...routeRepo[item][items]['deboarding']}
                                    objCreator['deboarded_ts'] = routeRepo[item][items]['deboarding']['logTimeMS'];
                                    objCreator['deboardedstatus'] = routeRepo[item][items]['deboarding']['boardedstatus'];
                                    objCreator['deboarded_pickup'] = routeRepo[item][items]['deboarding']['pickuppointName'];
                                    
                                    if(routeRepo[item][items]['deboarding']['boardedstatus'] === 'deboarding'){
                                          this.deboardedCnt++;
                                    }
                                 }

                              if(routeRepo[item][items].hasOwnProperty('notboarding')){
                                 objCreator['absent'] = 'absent'
                                 }

                }else{
                   objCreator['absent'] = 'absent' 
                }

                if(objCreator.hasOwnProperty('absent') && objCreator['absent']){
                  this.notBoardedCnt++;
                }

                
                this.routewiseAttendance.push({...stud,...objCreator});
                
            });
        })
        this.showCount =false;
        this.errorDetected.emit({ec:''});
      }else{
         this.errorDetected.emit(routeRepo);
        
      }
      

    });

    this.showLoader = false;
    this.validExcel =true;
}

excelDownloaded(){
   try{
   let res = JSON.parse(JSON.stringify(this.apiHeader));

     let xlstr = 'https://api-school.placer.in/api/v2/index.php?path=report/download_tabattendance_reports&key='+localStorage.getItem('scbToken')+'&';
     
     if(res['data']['form']){
         Object.keys(res['data']['form']).forEach(item=>{
             xlstr += item+'=';
             xlstr += res['data']['form'][item]+'&';
         })
     }

     if(res['data']['filter']){
         Object.keys(res['data']['filter']).forEach(item=>{
             if(item !== 'reportType'){
                 xlstr += item+'=';
                 xlstr += res['data']['filter'][item]+'&';
             }
         })
       xlstr += 'reportType=route';
     }

     this.xlString =  xlstr;
   }catch(e){}
}
}