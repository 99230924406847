
/*Auther:Deepak */
import { Component, Input,Output,EventEmitter } from '@angular/core';
import { globalService } from './../../../services/global.service';
import { ApiService } from './../../../services/ApiService';
import { RouterModule, Routes, Router } from '@angular/router';
import { GlobalUtils } from './../../../services/global-utils';

const swal = require('sweetalert');
declare var $: any;

@Component({
  selector: 'user-form',
  templateUrl: './userFormTemplate.html',
  styles: [`

    .formContent{
      padding-left: 16px;
      padding-right: 16px;
      padding-bottom: 8px;
    }
    .userFormContents{
      padding:10px;;
    }
    .UserInfo-Panel{
      padding:20px;
    }

    .field-icon {
      float: right;
      margin-left: -25px;
      margin-top: -25px;
      margin-right:3px;
      position: relative;
      z-index: 2;
    }




`  ],
})
export class OrgUserFormDir {

  private dirUser: any = {};
  public formError: any = {};
  public showForm: boolean = false;
  private organization: any = {};
  private currentUserId: string = '';
  private errorMesages: any = [];
  private currentOrganization: any = this.globalService.getData().currentOrganization;
  private apiToken: any = localStorage.getItem("adminToken");
  public userInfo: any = [];
  public clickMessage: any;
  public vehicleInformation: any = [];
  public organizationId: any;
  public userform: any;
  public dobData: any;
  public emailInvalid: any;
  public mobileInvalid: any;

  private apiResponseMesage: string;

  constructor(
    public globalService: globalService,
    public apiService: ApiService,
    private router: Router,
    private globalUtils: GlobalUtils
  ) {
    this.organizationId = this.globalService.getData().currentOrganization;
    this.userform = this.globalService.getData().displayOrgUserForm;
    this.userInfo.dob = { date: '' };
  }


  @Output() hideUserFormComponent: EventEmitter<any> = new EventEmitter<any>();

  ngOnInit() {
  }

  //add user inside an organization validation

  /* function to convert the date in the desired format */
  dateRefine(arg: any) {
    //console.log(arg);
    if (arg != undefined) {
      let date = arg.date;
      return date;
    }
  }

  saveUserValidator() {

    let errorFlag = false; this.errorMesages = [];

    //User Information

    //set user Name
    this.formError.organizationUserName = false;
    if (this.userInfo.name == "" || this.userInfo.name == undefined) {
      this.formError.organizationUserName = true;

      errorFlag = true; (this.errorMesages).push("Name should not be empty");
    }


    //set User loginname
    this.formError.organizationUserLoginName = false;
    if (this.userInfo.loginName == "" || this.userInfo.loginName == undefined) {
      this.formError.organizationUserLoginName = true;
      errorFlag = true; (this.errorMesages).push("Login name should not be empty");
    }

    //set User Email
    this.formError.organizationUserEmail = false;
    if (this.userInfo.email == "" || this.userInfo.email == undefined) {
      this.formError.organizationUserEmail = true;
      errorFlag = true; (this.errorMesages).push("Email should not be empty");
    }

    //set User Password
    this.formError.organizationUserPassword = false;
    if (this.userInfo.password == "" || this.userInfo.password == undefined) {
      this.formError.organizationUserPassword = true;
      errorFlag = true; (this.errorMesages).push("Password should not be empty");
    }

    //set User Phone
    this.formError.organizationUserPhone = false;
    if (this.userInfo.phone == "" || this.userInfo.phone == undefined) {
      this.formError.organizationUserPhone = true;
      errorFlag = true; (this.errorMesages).push("Phone should not be empty");
    }

    //set User Address
    this.formError.organizationUserAddress = false;
    if (this.userInfo.address == "" || this.userInfo.address == undefined) {
      this.formError.organizationUserAddress = true;
      errorFlag = true; (this.errorMesages).push("Address should not be empty");
    }

    //set User City
    this.formError.organizationUserCity = false;
    if (this.userInfo.city == "" || this.userInfo.city == undefined) {
      this.formError.organizationUserCity = true;
      errorFlag = true; (this.errorMesages).push("City should not be empty");
    }

    //set User Area
    this.formError.organizationUserArea = false;
    if (this.userInfo.area == "" || this.userInfo.area == undefined) {
      this.formError.organizationUserArea = true;
      errorFlag = true; (this.errorMesages).push("Area should not be empty");
    }

    //set User State
    this.formError.organizationUserState = false;
    if (this.userInfo.state == "" || this.userInfo.state == undefined) {
      this.formError.organizationUserState = true;
      errorFlag = true; (this.errorMesages).push("State should not be empty");
    }

    //set User Country
    this.formError.organizationUserCountry = false;
    if (this.userInfo.country == "" || this.userInfo.country == undefined) {
      this.formError.organizationUserCountry = true;
      errorFlag = true; (this.errorMesages).push("Country should not be empty");
    }

    //set User levels
    // this.formError.organizationUserLevels = false;
    // if (this.userInfo.levels == "" || this.userInfo.levels == undefined) {
    //   this.formError.organizationUserLevels = true;
    //   errorFlag = true; (this.errorMesages).push("Levels should not be empty");
    // }

    //set User Status
    this.formError.organizationUserStatus = false;
    if (this.userInfo.status == "" || this.userInfo.status == undefined) {
      this.formError.organizationUserStatus = true;
      errorFlag = true; (this.errorMesages).push("Status should not be empty");
    }

    //set User Gender
    this.formError.organizationUserGender = false;
    if (this.userInfo.gender == "" || this.userInfo.gender == undefined) {
      this.formError.organizationUserGender = true;
      errorFlag = true; (this.errorMesages).push("User gender should not be empty");
    }

    if (errorFlag == true) { return false; } else { return true; }

  }

  /*user validation ends here */


  //add user and tracker

  userFormSave() {
    let parent: any = this;  console.log(this.saveUserValidator() + '%%%%%%%');
    if (this.saveUserValidator() == true) {
      this.apiService.orgUserCreate({
        data: {
          key: this.apiToken,
          form: {
            name: this.userInfo.name,
            loginname: this.userInfo.loginName,
            email: this.userInfo.email,
            password: this.userInfo.password,
            phone: this.userInfo.phone,
            gender: this.userInfo.gender,
            designation: this.userInfo.designation,
            dob: this.userInfo.dobModified,
            address: this.userInfo.address,
            area: this.userInfo.area,
            city: this.userInfo.city,
            state: this.userInfo.state,
            country: this.userInfo.country,
            levels: 1,
            status: this.userInfo.status,
            organizationId: this.currentOrganization

          }
        }
      }).then(response => {
        //  console.log('direct',response);
        let result = response;
        if (result.status == "success") {
          swal("Success", "User Added Successfully", "success");
          this.hideForm();
        } else {
          //console.log(this.globalUtils.getErrorMsg(response.ec));
          swal("Failure", this.globalUtils.getErrorMsg(response.ec), "error");
          // setTimeout( ()=>{ swal.close();}, 2000);
          //this.apiResponseMesage = this.globalUtils.getErrorMsg(response.ec);
        }
      })
        .catch(error => {
          console.log('error', error);
          // console.log('Error while saving the data', error);
          // swal("Failure","Error while adding user to Organization", "success").done();
        });

    }

  }


  hideForm(){
    this.hideUserFormComponent.emit(false);
  }


  /*saving user ends here */


  /*code for view individual user information ends here */

  tooglepassword() {
    let x: any = document.getElementById("uPassword");
    if (x.type === "password") {
      x.type = "text";
    } else {
      x.type = "password";
    }
  }





}

/*
Notes : npm install node-sass -g
*/