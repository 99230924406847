/**
 * @Author: Deepak Pookkote <group10>
 * @Date:   2018-04-17T11:13:02+05:30
 * @Email:  deepak@groupten.com
 * @Project: School-Bus
 * @Filename: view.component.ts
 * @Last modified by:   group10
 * @Last modified time: 2018-05-23T12:36:41+05:30
 * @Copyright: Group10 Technologies
 */

import { Component,
  ElementRef,
  AfterViewInit,
  OnDestroy,
  ViewChild,
} from '@angular/core';
import { RouterModule, Routes, Router } from '@angular/router';
import { AppComponent } from './../../../app.component';
import { globalService } from './../../../services/global.service';
import { ApiService } from './../../../services/ApiService';
import { authGaurd } from './../../../services/auth-guard.service';
import { StorageService } from './../../../services/storage.service';

const swal = require('sweetalert');

@Component({
// template: ``,
templateUrl: './view.component.html',

styleUrls: ['./only_viewadmin.min.css']
})
export class ViewAdminOrgPageComponent  {

 private viewMore:boolean=false;
 public showFilters:boolean=false;
 public organization:any={};
 public formError:any={};
 public errorMesages:any=[];
 public clickMessage :any;
 private modelService :any;
 private search :any={};
 private apiToken:any=localStorage.getItem("adminToken");
 public pageJump:number=0;
 public srchName:any="";
 public srchCategory:any="";
 public srchCity:any="";
 public srchState:any="";
 public srchImei:any="";
 public srchRegNo:any="";
 public srchBoxId:any="";
 public srchSimcard:any="";

constructor(public globalService :globalService,
           public apiService:ApiService,
           public router:Router,
           public authGaurd:authGaurd,
           private storage:StorageService
          ){

             this.storage.set({
                'orgID': ''
             })

           }

  ngOnInit(){
     this.authGaurd.checkAdminToken();
     this.orgView();
  }

 searchFilter(){
   this.pageJump = 0;
   this.orgView();
 }

 clearFilter() {
   this.srchName = ""; //function for clearing all the filters
   this.srchCategory = "";
   this.srchCity = "";
   this.srchState = "";
   this.srchImei= "";
   this.srchRegNo="";
   this.srchBoxId="";
   this.srchSimcard="";
   this.orgView();
 }

 viewDetails(orgId:any) {
  this.globalService.setData({
    currentOrganization:orgId
  });
   this.router.navigate(['/sysAdmin/view-admin-organization-details']);
 }

orgView() {
  this.apiService.orgView({
    data:{
      key:this.apiToken,
      filter:{
        name: this.srchName,
        category:this.srchCategory,
        city:this.srchCity,
        state:this.srchState,
        imei:this.srchImei,
        regNo:this.srchRegNo,
        boxId:this.srchBoxId,
        simCard:this.srchSimcard
      },
      extra:{
        orderByDateCreated:-1,
        pageJump: this.pageJump
      }
    }
  }).then(response => {
       let result=response;
         if(result.status=="success"){
           //if(result.response!=null && result.response!=undefined && result.response!=''){
             this.organization=result.response; // here response is API output
           //}

         }
     }).catch(error => {
         console.log('Error while logging in', error);
       });
}

// deleteOrganization(orgId:any){

//   console.log('stopped for migration purpose');
//   let parent: any = this;
//   var orgObj = {
//     data: {
//         key:this.apiToken,
//         form: {
//             orgId:orgId
//         }
//     }
//   };
//   swal({
//     title: 'Are you sure?',
//     text: "This Organization will be removed",
//     type: 'warning',
//     timer: 3000,
//     showCancelButton: true,
//     confirmButtonColor: '#3085d6',
//     cancelButtonColor: '#d33',
//     confirmButtonText: 'Yes, Do it!',
//     cancelButtonText: 'No, keep it'
//     }).then(()=> {
//     this.apiService.orgDelete(orgObj).then(response => {
//       var parent: any = this;
//       if (response) {
//         swal(
//           'Removed!',
//           'Organization has been Removed',
//           'success'
//         ).done();
//         setTimeout( ()=>{ swal.close();
//         }, 1000);
//         this.orgView();
//       };
//     }).catch(error => { });
//   },function(dismiss:any) {
//       if (dismiss === 'cancel') {
//         swal(
//           'Cancelled',
//           'Cancelled :)',
//           'error'
//         ).done();
//         setTimeout( ()=>{ swal.close();}, 1000);
//       }
//   });
// }

deleteOrganization(orgId:any){
  const parent = this;
  let orgObj = {
    data: {
        key:this.apiToken,
        form: {
            orgId:orgId
        }
    }
  };

  swal({
    title: 'Are you sure?',
    text: 'This Organization will be removed',
    icon: 'warning',
    buttons: [
      'No, cancel it!',
      'Yes, I am sure!'
    ],
    dangerMode: true,
  }).then(function(isConfirm: any) {
    if ( isConfirm ) {
      swal({
        title: 'Success!',
        text: 'Organization has been Removed',
        icon: 'success',
        timer: 2000
      }).then(function() {
        parent.apiService.orgDelete(orgObj).then(response => {
                if (response) {
                  parent.orgView();
                };
              }).catch(error => { });
      });
    } else {
      swal('Cancelled', 'Action aborted :)', 'error');
    }
  });
}

redirectToAddOrg(){
  this.router.navigate(['/sysAdmin/new-organization']);
}


}
