/**
 * @Author: Deepak Pookkote <group10>
 * @Date:   2018-04-17T10:51:11+05:30
 * @Email:  deepak@groupten.com
 * @Project: School-Bus
 * @Filename: studentExcelUpload.ts
 * @Last modified by:   group10
 * @Last modified time: 2018-05-09T12:56:32+05:30
 * @Copyright: Group10 Technologies
 */



/*Author :- Deepak */
import { Component,
     ElementRef,
     AfterViewInit,
     OnDestroy,
     ViewChild, Input, Output
 } from '@angular/core';
import { RouterModule, Routes, Router } from '@angular/router';
import { AppComponent } from './../../app.component';

import { globalService } from './../../services/global.service';
import { ApiService } from './../../services/ApiService';
import { excelUpload } from './../../services/excelUploadService'
import { authGaurd } from './../../services/auth-guard.service'


declare var $: any;
declare var alasql: any;;
declare var require: any;
const swal = require('sweetalert');

@Component({
  // template: ``,
   templateUrl: './rfidUpload.component.html',
  styles: [`

  /* layout.css Style */
.upload-drop-zone {
  height: 200px;
  border-width: 2px;
  margin-bottom: 20px;
}

/* skin.css Style*/
.upload-drop-zone {
  color: #ccc;
  border-style: dashed;
  border-color: #ccc;
  line-height: 200px;
  text-align: center
}
.upload-drop-zone.drop {
  color: #222;
  border-color: #222;
}


.image-preview-input {
    position: relative;
    overflow: hidden;
    margin: 0px;
    color: #333;
    background-color: #fff;
    border-color: #ccc;
}

.image-preview-input input[type=file] {
	position: absolute;
	top: 0;
	right: 0;
	margin: 0;
	padding: 0;
	font-size: 20px;
	cursor: pointer;
	opacity: 0;
	filter: alpha(opacity=0);
}
.image-preview-input-title {
    margin-left:2px;
}

.upload-point-container{
  margin-top: 2em;
  padding-bottom: 5em;
  background-color: #fff;
}
.point-excel-upload{
  padding: 1em;
  color: #b5c4c9;
  text-align: center;
}
.drop-file-container{
  position: relative;
  border: 2px dashed #b5c4c9;
  border-radius: 5px;
  padding: 30px;
  background-color: #dfdfd;
}
.icon-drag-drop{
  font-size: 7em;
  margin-bottom: 20px;
  color: #b5c4c9;
}
.icon-upload{
  color: #4a4847;
  margin-right: .5em;
}
.drop-file-container input{
  position: absolute;
  opacity: 0;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  height: 100%;
  width:100%;
}
.header-tabs{
  height:30px;
  padding:10px;
}
.stoppage-upload{
  margin-top:15px;
}
.dialogDynamicContent{
  margin-top:15%;
}
.panel-default>.panel-heading {
  color: #333;
  background-color: #fff;
  border-color: #ffffff;
  box-shadow: 1px 1px 2px 1px #efefef;
}
.panel {
  box-shadow: 1px 2px 4px 0px lightgrey;
}
.panel-default {
  border-color: #fdfafa;
}



    `],
    //styleUrls: ['./../../../webroot/css/pages/new-organization/only_neworg.min.css']
})
export class rfidUploadPage {

  public showLoader:boolean = false;
  public newExcelObj:any = [];
  public fileNameFlag:any;
  constructor(public router: Router,
    public excelUpload: excelUpload,
    public apiService:ApiService,
    public authGaurd:authGaurd) {


  }

  ngOnInit(){
      this.authGaurd.checkToken();
  }

  addStudent() {

    this.router.navigate(['/add-student-details',{}]);
  }
  backToDashboard(){

    //this.redirect('/student-dashboard',{});
    this.router.navigate(['/student-dashboard',{}]);
  }

  tableView(){
    this.router.navigate(['/school-students-list',{}]);
  }

  rfidUpload(){
    this.router.navigate(['/rfid-upload']);
  }

  excelUploadfunction() {
    this.router.navigate(['/student-excel-upload',{}]);
  }

  UpdateStudentExcelUpload() {
    this.router.navigate(['/studentInfo-update-upload',{}]);
  }
  


  onFileChange(event:any) {
    let reader = new FileReader();
    let parent:any = this;
    this.showLoader = true;
    parent.newExcelObj=[];
    // this.showUploadSuccess = false;
    // this.showUploadFailure = false;
    // this.showDialogLoader  = true; //show loader while Excel uploads.
    // this.showSuccessDialog = true; //show dialog.
    if(event.target.files && event.target.files.length > 0) {
      let file = event.target.files[0];
      var apiKey= localStorage.getItem('scbToken');
       this.excelUpload.uploadRFIDfile(apiKey,file,'pickup-input',function(apiResponse:any){
        // parent.showDialogLoader = false; //hide loader from dialog.
         if(apiResponse.status == 'success'){
          parent.showLoader = false; 
          // parent.showUploadSuccess = true;
           /// parent.showUploadFailure = false;
           let div = document.createElement("div");
           div.innerHTML = "<div style='overflow:auto'>  Uploaded File Name : "+file.name + " <br> " + 'RFID Excel Uploaded Successfully' + "</div>";
           swal({
             title: 'Success',
             content: div,
             icon: 'success',
           });

          //  swal("Success", "RFID Excel Uploaded Successfully", "success",{
          //    timer: 2000
          //  });
           setTimeout( ()=>{ swal.close();
             parent.router.navigate(['/school-students-list',{}]);
           }, 2000);
         }else if(apiResponse.status=='failure'){
          if(apiResponse.response){
            let apiRepsponceArray:any =[];
            parent.showLoader = false;
            let errorArray:any=[];
            let orgError:any=[];
            apiRepsponceArray  = apiResponse.response.failed.rfidfailed;

            Object.keys(apiRepsponceArray).forEach(function(key:any) {
              errorArray = apiRepsponceArray[key];

              for(var index in errorArray){
                orgError.push(errorArray[index]);
              }
             
            });

            if(errorArray.rfid){
              let div = document.createElement("div");
              
              
              if(apiResponse.ec =='SCB39'){ 
                
                let tempObj ={
                  'ID': errorArray.uid,
                  'RFID': errorArray.rfid
                    
                    
                  }
                  parent.newExcelObj.push(tempObj);
                  //console.log('111111 ',tempObj);
             // div.innerHTML = "<div style='overflow:auto'> Uploaded File Name : "+file.name + " <br> RFID Duplicated<br><button  id='downloadID' class='btn btn-sm btn-info '>Download_Failure_Report</button></div>";
               // div.innerHTML = "<div style='overflow:auto'>Uploaded File Name : "+file.name + " <br> RFID Duplicated<br>"+ errorArray.rfid +"</div>";
                div.innerHTML = "<div style='overflow:auto'> Uploaded File Name : "+file.name + " <br> RFID Duplicated <br><button  id='downloadID' class='btn btn-sm btn-info '>Download_Failure_Report</button></div>";                
              }else{ 
                div.innerHTML = "<div style='overflow:auto'>Uploaded File Name : "+file.name + " <br> RFID Duplicated<br>"+ errorArray.rfid +"</div>";  
              }
               swal({
                 title: 'Failure',
                 content: div ,
                 icon: 'error',
               });
               var downloadEvent = document.getElementById('downloadID');
              if(downloadEvent){
               // el.addEventListener('click', parent.jj, false);
               downloadEvent.addEventListener("click", (e:Event) => parent.downloadLink());
              }
            }else{
             let div = document.createElement("div");
             if(apiResponse.ec =='SCB39'){
             div.innerHTML = "<div style='overflow:auto'>Uploaded File Name : "+file.name + " <br> User not exist <br>"+ orgError +"</div>";
             }else{ 
              div.innerHTML = "<div style='overflow:auto'>Uploaded File Name : "+file.name + " <br> User Exist <br>"+ orgError +"</div>";
             }
             swal({
               title: 'Failure',
               content: div ,
               icon: 'error',
             });
            }
          } else {
            if(apiResponse.ec =='SCB29'){
              parent.showLoader = false;
              swal("Failure","invalid excel Data","error",{
                timer:2000
              });
            }

          }

        }
       });
    }
  }

  downloadLink(value:any){
    
    let parent: any = this;
    parent.CreateCSV(this.newExcelObj);
  }
  CreateCSV(ExcelObj:any){ //console.log(ExcelObj);
   
    let sheetName = { sheetid: 'StudentDetails', header: true};
    /// console.log('ExcelObj',ExcelObj);
    let date: Date = new Date();
    let getFileName='';
  
      getFileName='RFID_Duplicate_Upload_Failure_Report_'+date.getDate()+'-'+date.getMonth()+'-'+date.getFullYear() +' '+date.getHours()+':'+date.getMinutes()+':'+date.getSeconds()+'.xlsx';
    
    
     
    //if(ExcelObj.length>0){
      let fileName="";
      
      alasql('SELECT INTO XLSX("' + getFileName + '",?) FROM ?',
      [sheetName,ExcelObj]);
    // this.commonservice.JSONToCSVConvertor(ExcelObj, "Feedback_report", excelTitle, columns);
   // }
    // setTimeout(()=>{

    //   //this.xlsLoader1=false;
    //   // console.log('xlsLoader',this.xlsLoader1);
    // },2000)
    
    }
  downloadTemplate(){
  window.open('https://api-school.placer.in/api/v2/webroot/sample/studentrfidInfo.xls');
  }

}
