/**
 * @Author: Deepak Pookkote <group10>
 * @Date:   2018-04-17T11:27:35+05:30
 * @Email:  deepak@groupten.com
 * @Project: School-Bus
 * @Filename: addStudent.component.ts
 * @Last modified by:   group10
 * @Last modified time: 2018-05-09T12:48:22+05:30
 * @Copyright: Group10 Technologies
 */



import {
  Component, ElementRef, AfterViewInit
} from '@angular/core';
import { RouterModule, Routes, Router } from '@angular/router';
import { globalService } from './../../services/global.service';
import { ApiService } from './../../services/ApiService';
import { AppComponent } from './../../app.component';
import { authGaurd } from './../../services/auth-guard.service'


@Component({
  styles: [ `
    `],
  templateUrl: './addStudent.component.html'
})

export class addStudentComponent{

  constructor(public authGaurd :authGaurd,private apiService: ApiService,public router:Router){

  }

  private showSuccessDialog: boolean;
  private showFailureDialog: boolean;
  private showDialogLoader:boolean  = false;
  private showUploadSuccess:boolean = false;
  private showUploadFailure:boolean = false;

  ngOnInit(){
    this.authGaurd.checkToken();
  }


  tableView() {
    this.router.navigate(['/school-students-list',{}]);
  }

  excelUpload() {
    this.router.navigate(['/student-excel-upload',{}]);
  }

  rfidUpload() {
    this.router.navigate(['/rfid-upload']);
  }

  UpdateStudentExcelUpload() {
    this.router.navigate(['/studentInfo-update-upload',{}]);
  }
  

}
