import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'attendencefilter'
})

export class AttendenceFilter implements PipeTransform {
    transform(obj:any , objtype:any): any {
  
            if(objtype=='both'){

                return Object.keys(obj);
                
            }else if(objtype=='present'){

             return  (Object.keys(obj) ).filter( (precentval:any) => {
                        
                       if(obj[precentval].in){
                           
                           return precentval;
                            
                        }
                         
                 });

            }else if(objtype=='absent'){

              return  (Object.keys(obj) ).filter( (absentval:any) => {
                    
                    if(!obj[absentval].in ){
                        return absentval;
                    }
                  
             });
            }


    }
}