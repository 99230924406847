import { Component, OnInit, Input, Output, EventEmitter,SimpleChange } from '@angular/core';
import { Subscription, from  } from 'rxjs';
import { filter } from 'rxjs/operators';



@Component({
  selector: 'input-tagging',
  templateUrl: './input-tagging.component.html',
  styleUrls: ['./input-tagging.component.css']
})
export class InputTaggingComponent{


  private iterable:any= null;
  public tempCollection:any ={};
  public  keyed:any = Object.keys;
  public queryString:string='all';
  public checkTrack:string='';
  public showDropMenu:boolean = false;
  public hidespan = false;
  

  @Input()
  set getIterable(name: any) {
    this.iterable = name;
    Promise.resolve(this.iterable).then((val:any)=>  this.iterable = val);    
   
  }
 
  get getIterable(): any {     
    return this.iterable; 
  }

  @Output() sendRes = new EventEmitter<any>(); 
  
  constructor() { }

  ngOnInit() {
    setTimeout(() => {this.formTempArray()}, 500);  
  }


  dropDown(){
    this.showDropMenu = !this.showDropMenu;
  }

  formTempArray(){
    if(Object.keys(this.iterable).length && this.iterable.hasOwnProperty('sub')){
      this.iterable.main
      .forEach((item:any) => this.iterable.sub.includes(item.UserMobileNo) && ( this.tempCollection[item.UserMobileNo.toString()] = item ));
     return this.tempCollection;
    }  
  }




  selectedElem(val:any){
    this.tempCollection[val.UserMobileNo.toString()] = val;
    return this.tempCollection && this.sendRes.emit(this.tempCollection);
  }

  removeItem(index:any){    
    this.tempCollection[index] = null;
    delete this.tempCollection[index];
    return this.tempCollection && setTimeout(() => this.sendRes.emit(this.tempCollection), 50);
  }


  getChkEvent(event:any,item:any){  
    
    let rmno = item.UserMobileNo.toString();
    this.keyed(this.tempCollection).includes(item.UserMobileNo.toString()) ? event.target.checked = false : event.target.checked=true; 
    event.target.checked ? this.selectedElem(item) : this.removeItem(rmno);    
  }

  chekEvent(id:string){    
    return this.tempCollection[id] && this.keyed(this.tempCollection[id]).length && true || false;
  }


 }
