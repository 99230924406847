/**
 * @Project: School-Bus
 * @Filename: templateForm.dir.ts
 * @Last modified by:   group10
 * @Last modified time: 2018-05-22T15:16:12+05:30
 * @Copyright: Group10 Technologies
 */

import { Component,Output, EventEmitter } from '@angular/core';
import { globalService } from './../../../services/global.service';
import { ApiService } from './../../../services/ApiService';
import { GlobalUtils } from './../../../services/global-utils';

const swal = require('sweetalert');
declare var $: any ;

@Component({
  selector: 'template-form',
  template: `
  <div class="slideDown animated fadeIn">
<div class="TemplateInfo-Panel">
  <div class="row">
      <div>
          <div class="panel panel-default panel-table">
              <div class="panel-heading">
                  <div class="row">
                    <div class="col col-xs-6" >
                        <h3 class="panel-title">Add New Template</h3>
                    </div>
                  </div>
              </div>
              <!--add content here-->
              <div class="step_51 formContent" style="margin-top:15px;">
                  <div class="row">
                    <div class="col-md-6">
                      <label class="control-label" for="rTitle">Template Title<sup>*</sup></label>
                      <input type="text" maxlength="50" [(ngModel)]='availableCampaignTemplates.templateTitle' name="templateTitle" class="custom-form-control" id="rTitle">
                      <span class="text-danger" *ngIf="templateTitleError">Add Template Title!!</span>
                    </div>
                    <div class="col-md-6">
                      <label class="control-label" for="templates">Template Notes<sup>*</sup></label>
                      <textarea rows=4 [(ngModel)]='availableCampaignTemplates.templateMessage' name="templateMessage" class="custom-form-control" id="templates"></textarea>
                      <span class="text-danger" *ngIf="templateContentError">Add Template Content over here!!</span>
                    </div>
                  </div>
              </div>
              <div class="step_37 formContent" style="margin-top:10px">
                  <div class="row">
                    <div class="col-md-2" style="float:right" >
                      <button [disabled]="this.availableCampaignTemplates.templateTitle==null" type="button" (click)="createCampaignTemplate()" class="btn btn-primary custom-form-control"><i class="fa fa-floppy-o mr5" aria-hidden="true"></i>
                        Save
                      </button>
                    </div>
                    <div class="col-md-2" style="float:right">
                      <button  id="org_template_cancel_btn" (click)="hideForm();"
                      class="btn btn-danger custom-form-control"><i class="fa fa-ban mr5" aria-hidden="true"></i>Cancel</button>
                    </div>
                  </div>
              </div>
          </div>
              <!--add content here-->
      </div>
  </div>
</div>
</div>
  `,
  styles: [ `
    .TemplateInfo-Panel{
      padding:20px;
    }

    .formContent{
      padding-left: 16px;
      padding-right: 16px;
      padding-bottom: 8px;
    }



`  ],
})
export class OrgTemplateFormDir  {

  public showForm:boolean=false;
  private currentOrganization:any=this.globalService.getData().currentOrganization;
  private apiToken:any=localStorage.getItem("adminToken");
  public  templateInfo:any=[];
  public clickMessage :any;
  public organizationId:any;
  public templateform:any;

  public templateTitle:any;
  public templateMessage:any;
  public templateContentError:boolean;
  public templateTitleError:boolean;

  public availableCampaignTemplates:any=[];

  constructor(
    public globalService: globalService,
    public apiService: ApiService,
    private globalUtils:GlobalUtils
  ) {

      this.organizationId = this.globalService.getData().currentOrganization;
      this.templateform=this.globalService.getData().displayOrgTemplateForm;
   }


   @Output() hideTemplateFormComponent: EventEmitter<any> = new EventEmitter<any>();

   ngOnInit(){

  }

  hideForm(){
    this.hideTemplateFormComponent.emit(false);
  }


/*Es6 functions are being added here for create new template*/
  createCampaignTemplate = ():void =>{
    if(this.validateUserInputs()){
      let apiHeader = {
          data: {
              key: this.apiToken,
          form: {
                  organizationId:this.currentOrganization,
                  templateTitle: this.availableCampaignTemplates.templateTitle,
                  templateMessage: this.availableCampaignTemplates.templateMessage
                }
          }
      }
      this.apiService.createCampaignTemplate(apiHeader).then(response => {
        let result = response;
        if(result.status=="success"){
          swal("Success", "Notification Added Successfully", "success",{
            timer : 2000
          });
          this.availableCampaignTemplates.templateTitle = null;
          this.availableCampaignTemplates.templateMessage = null;
          this.hideForm();
        }else{
          swal("Failure",(this.globalUtils.getErrorMsg(response.ec)), "error");
          setTimeout(()=>{swal.close();}, 2000);
        }
      }).catch(error => {})
    }
  }

  validateUserInputs=():boolean=> {
    let isValid: boolean = true;
    if (!this.availableCampaignTemplates.templateTitle) {
      this.templateTitleError = true;
      isValid = false;
    } else {
      this.templateContentError = false;
    }

    if (!this.availableCampaignTemplates.templateMessage) {
      this.templateContentError = true;
      isValid = false;
    } else {
      this.templateContentError = false;
    }
    return isValid;
  }

}/*end here*/
