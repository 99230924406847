import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { roleFormModule } from './../../model/role-model';
import { Objectkeys } from './../../custom-pipes/objectkeys.pipe';
import { ApiService } from './../../services/ApiService';
import { COM } from './../../services/com.services';
import { FormControl, FormGroup, Validators, FormBuilder } from '@angular/forms';
import { ExcelService } from './../../services/excel.service';
import { excelUpload } from './../../services/excelUploadService'

declare var $: any; declare var alasql: any;;

@Component({
    selector: 'all-route-download',
    templateUrl: './all-route-download.html',
    // styleUrls: ['./student-route-management.css']
})
export class allRouteDownloadComponent {
    sessionTypeList: string = "";
    routeInfosession: any;
    sessionChange(e: any) {
        let sessionWiseRoute = e.target.value;
        this.routeInfosession = sessionWiseRoute;


    }
}