/*Auther:Deepak */
import { Component,  Input, Output, EventEmitter } from '@angular/core';
import { FormBuilder, FormGroup, Validators,FormControl } from '@angular/forms';
import { ApiService } from './../../../services/ApiService';
declare const $:any;
declare var swal:any;
@Component({
  selector:'available-templates',
  template:`
    <section class="availableTemplateSection">

        <div class="container animated fadeIn" style="min-height=800px;margin-top:25px;">
        <div class="row">
          <div>
            <div class="panel panel-default panel-table">
                <div class="panel-heading">
                <div class="row">
                    <div class="col col-xs-12 col-md-12 col-lg-12">
                        <span><h3 class="panel-title pull-left mr5">List of Available Templates</h3>
                      </span>
                    </div>
                </div>
                </div>
                  <div class="panel-body table-responsive routeDetails" style="max-height: 414px;">
                  <div class="animated fadeIn">
                    <div class="row" style="padding-left: 15px;padding-right: 15px;">
                    <div class="col-md-12 campaign-content-header campaign-searchbar-container">
                      <div class='search-box overlay-history-search'>
                          <form class='search-form'>
                            <input class='form-control meager-e2e-campaign-available-templates-search-box' placeholder='Find Templates' type='text' (change)="filterTemplates($event.target.value)">
                            <button class='btn btn-link search-btn meager-e2e-campaign-available-templates-search-btn'>
                              <i class='glyphicon glyphicon-search meager-e2e-campaign-available-templates-search-btn'></i>
                            </button>
                          </form>
                      </div>
                    </div>
                    </div>
                  </div>
                  <div *ngIf="availableCampaignTemplates?.length>0; else falsyTemplate" class="panel-body table-responsive">
                    <table id="mytable" class="table table-hover table-striped table-bordered table-list">
                        <thead style="border:1px solid rgba(144, 144, 144, 0.56); ">
                          <tr>
                              <th class="col-text text-center clickHead">#</th>
                              <th class="col-text text-left clickHead">Template Title</th>
                              <th class="col-text text-left clickHead">Template Content</th>
                          </tr>
                        </thead>
                        <tbody  style="border:1px solid rgba(144, 144, 144, 0.56);" >
                          <tr *ngFor="let template of availableCampaignTemplates; let i=index;" data-status="completed">
                            <td class="col-text text-center">{{limitOfRecords * pageJump + i + 1}}</td>
                            <td class="col-text text-capitalize text-left">{{template.templateTitle}}</td>
                            <td class="col-text text-justify">{{template.templateMessage}}</td>
                          </tr>
                        </tbody>
                    </table>
                  </div>
                  <div class="pagination pull-right" style="margin-right:15px;cursor:pointer;">
                    <a data-toggle="tooltip" title="Previous" *ngIf="pageJump<1">&nbsp;<i class="fa fa-angle-double-left" aria-hidden="true" class="disabled"></i></a>
                      <a  data-toggle="tooltip" title="Previous" *ngIf="pageJump>0" (click)="pageJump=pageJump-1;getAvailableCampaignTenplates()">&nbsp;<i class="fa fa-angle-double-left" aria-hidden="true"></i></a>
                    <a class="active" data-toggle="tooltip" title="Current Page">{{pageJump+1}}</a>
                    <a  data-toggle="tooltip" title="Next" *ngIf="availableCampaignTemplates?.length > 0" (click)="pageJump=pageJump+1;getAvailableCampaignTenplates()" >&nbsp;<i class="fa fa-angle-double-right" aria-hidden="true"></i></a>
                      <a  data-toggle="tooltip" title="Next" *ngIf="availableCampaignTemplates?.length < 1" class="disabled" >&nbsp;<i class="fa fa-angle-double-right" aria-hidden="true"></i></a>
                  </div>
                  <ng-template #falsyTemplate>
                     <h4 class="animated pulse text-center"  style="animation-duration: 1s;color:#999FBF"><i class="fa fa-frown-o"></i>&nbsp;No Templates to Display</h4>
                  </ng-template>
                </div>
              </div>
          </div>
       </div>
  </div>
    </section>
  `,
  styles:[`
      .availableTemplateSection{
      }
      .addTemplateFixedButton{
        font-size: 1.6em;
        width: 1.6em;
        text-align: center;
        line-height: 1.6em;
        background: #2396b9;
        color: #fff;
        border-radius: 30px;
        width: 45px;
        height: 45px;
        position: fixed;
        z-index: 999;
        right: 20px;
        bottom: 20px;
        cursor:pointer
      }
      .addTemplateFixedButton > i {
        margin:12px auto;
      }

      /*search box CSS starts */
      .search-box {
       display: inline-block;
       width: 100%;
       border-radius: 3px;
       padding: 4px 55px 4px 15px;
       position: relative;
       background: #fff;
       border: 1px solid #ddd;
       -webkit-transition: all 200ms ease-in-out;
       -moz-transition: all 200ms ease-in-out;
       transition: all 200ms ease-in-out;
      }
      .search-box.hovered, .search-box:hover, .search-box:active {
       border: 1px solid #aaa;
      }
      .search-box input[type=text] {
       border: none;
       box-shadow: none;
       display: inline-block;
       padding: 0;
       background: transparent;
      }
      .search-box input[type=text]:hover, .search-box input[type=text]:focus, .search-box input[type=text]:active {
       box-shadow: none;
      }
      .search-box .search-btn {
       position: absolute;
       right: 2px;
       top: 2px;
       color: #aaa;
       border-radius: 3px;
       font-size: 21px;
       padding: 5px 10px 1px;
       -webkit-transition: all 200ms ease-in-out;
       -moz-transition: all 200ms ease-in-out;
       transition: all 200ms ease-in-out;
      }
      .search-box .search-btn:hover {
       color: #fff;
       background-color: #8FBE00;
      }

      /*search box CSS ends */

    `],
})

export class availableTemplatesDir{

    public availableCampaignTemplates:any[];
    private viewSearch:boolean=false;
    private filterObj:any = {};
    public pageJump:number = 0;
    public limitOfRecords:number;

    @Output() triggerCreateTemplateForm: EventEmitter<any> = new EventEmitter<any>();
    @Output() triggerEditTemplate: EventEmitter<any> = new EventEmitter<any>();

    constructor(private fb: FormBuilder,public apiService: ApiService) {

    }

    ngOnInit(){
        this.getAvailableCampaignTenplates();
    }

    getAvailableCampaignTenplates = ():void => {
        let requestHeader = {
            data:{
                key:localStorage.getItem('scbToken'),
                extra: {
                    orderByDateCreated: "-1",
                    pageJump: this.pageJump
                }
            }
        }
        //setting filters , if applied.
        if(Object.getOwnPropertyNames(this.filterObj).length) requestHeader.data['filter'] = this.filterObj;
        else delete requestHeader.data['filter'];

        this.apiService.getAvailableCampaignTenplates(requestHeader).then(response => {
            this.availableCampaignTemplates = response.response;
            if(this.pageJump == 0)
              this.limitOfRecords = this.availableCampaignTemplates.length;
        }).catch(error => {})
    }

    filterTemplates = (filterText:any):void => {
        if(filterText){
            this.filterObj = {
                templateTitle:filterText
            }
        }else{
            delete this.filterObj.templateTitle;
        }
        this.getAvailableCampaignTenplates();
    }

    deleteCampaignTemplate = (templateID:any):void => {
        let requestHeader = {
            data:{
                key:localStorage.getItem('scbToken'),
                form:{
                    templateId: templateID
                }
            }
        };
        swal({
          title: 'Are you sure?',
          text: "The Selected Template Will be removed",
          type: 'warning',
          timer: 10000,
          reverseButtons:true,
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#e04d76',
          confirmButtonText: 'Yes, Remove it!',
          cancelButtonText: 'No, keep it'
        }).then(()=>{
          this.apiService.deleteCampaignTemplate(requestHeader).then(response => {
            var parent: any = this;
              if(response.status == 'success'){
                swal(
                  'Removed!',
                  'Template has been Removed',
                  'success'
                ).done();
                setTimeout( ()=>{ swal.close();}, 1000);
                this.getAvailableCampaignTenplates();
              };
          }).catch(error => {});
        },
        function(dismiss:any) {
        if (dismiss === 'cancel') {
          swal(
            'Cancelled',
            'Cancelled :)',
            'error'
          ).done();
          setTimeout( ()=>{ swal.close();}, 1000);
        }
      });
    }

    showEditDirective = (templateId:any):void => {
        this.triggerEditTemplate.emit(templateId);
    }

    redirectToAddTemplate(event:any){
      this.triggerCreateTemplateForm.emit(true);
    }

}
