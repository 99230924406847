import { combineReducers } from 'redux';

import { reducer as trackerDataReducer } from './tracker-data';
import { reducer as alertDataReducer } from './alert-data';
import { reducer as vehicleDataReducer } from './vehicle-data';


export class AppState {
  trackerData: any = {};
  alertData: any = {};
  vehicleData: any = {};
}

export const rootReducer =  combineReducers({
    trackerData : trackerDataReducer,
    alertData   : alertDataReducer,
    vehicleData : vehicleDataReducer
});
