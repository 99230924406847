/**
 * @Author: Deepak Pookkote <group10>
 * @Date:   2018-04-24T11:51:53+05:30
 * @Email:  deepak@groupten.com
 * @Project: School-Bus
 * @Filename: schoolVehicleList.ts
 * @Last modified by:   group10
 * @Last modified time: 2018-05-09T12:55:45+05:30
 * @Copyright: Group10 Technologies
 */



/*Auther:Deepak */
import { Component } from '@angular/core';
import {
     ElementRef,
     AfterViewInit,
     OnDestroy,
     ViewChild,
 } from '@angular/core';
  import { RouterModule, Routes, Router } from '@angular/router';
  import {ActivatedRoute} from '@angular/router';
  import { AppComponent } from './../../../app.component';

  import { globalService } from './../../../services/global.service';
  import { ApiService } from './../../../services/ApiService';
  import { chosenUtility } from './../../../services/chosen-utility/jq-chosen-utility.js';
  import {DatePipe} from '@angular/common';

@Component({
  //template: ``,
   templateUrl: './schoolVehicleNotMoveList.component.html',

   styles: [`

     .clickRouteClass{
      cursor: pointer;
      min-width: 20vh;
      /* background: red; */
      display: inline-block;
      min-width: 10px;
      padding: 5px 10px;
      font-size: 12px;
      font-weight: 700;
      line-height: 1;
      color: #fff;
      text-align: center;
      white-space: nowrap;
      vertical-align: middle;
      background-color: #777;
      border-radius: 10px;
      margin-right: 15px;
     }
     .customized-form-control{
        height: 42px;
        border: 1px solid lightgray;
        display: block;
        width: 100%;
        padding: 6px 12px;
        font-size: 14px;
        line-height: 1.42857143;
        color: #000;
        background-color: #fff;
        background-image: none;
        border: 1px solid #ccc;
        border-radius:2px;
     }
     .Windowactive {
       border-top: 3px solid #0095b9;
       padding-top: 7px;
     }
     .pagination a {
       color: black;
       float: left;
       padding: 7px 16px;
       text-decoration: none;
       border: 1px solid #bcb9b9;
     }
     .panel-default>.panel-heading {
      color: #333;
      background-color: #ffff;
      border-color: #ffff;
     }
     .panel {
      box-shadow: 1px 2px 4px 0px lightgrey;
     }
     .panel-default {
      border-color: #fdfafa;
     }

     /*search box CSS starts */
     .search-box {
      display: inline-block;
      width: 100%;
      border-radius: 3px;
      padding: 4px 55px 4px 15px;
      position: relative;
      background: #fff;
      border: 1px solid #ddd;
      -webkit-transition: all 200ms ease-in-out;
      -moz-transition: all 200ms ease-in-out;
      transition: all 200ms ease-in-out;
     }
     .search-box.hovered, .search-box:hover, .search-box:active {
      border: 1px solid #aaa;
     }
     .search-box input[type=text] {
      border: none;
      box-shadow: none;
      display: inline-block;
      padding: 0;
      background: transparent;
     }
     .search-box input[type=text]:hover, .search-box input[type=text]:focus, .search-box input[type=text]:active {
      box-shadow: none;
     }
     .search-box .search-btn {
      position: absolute;
      right: 2px;
      top: 2px;
      color: #aaa;
      border-radius: 3px;
      font-size: 21px;
      padding: 5px 10px 1px;
      -webkit-transition: all 200ms ease-in-out;
      -moz-transition: all 200ms ease-in-out;
      transition: all 200ms ease-in-out;
     }
     .search-box .search-btn:hover {
      color: #fff;
      background-color: #8FBE00;
     }

     div.section-title{
       font-size:20px;
     }
     div.section-title-border{
       margin-bottom: 20px;
       border-bottom: 1px solid #00BCD4;
       border-bottom-width: medium;
       border-spacing: 1px;
       width:13%;
     }

     .report-basic-info,.student-pickup-info,.student-drop-info,.student-notif-status{
       margin-bottom:10px;
     }

     input[type=checkbox], input[type=radio] {
       margin: 0px 4px -3px !important;
       line-height: normal;
     }

     /*search box CSS ends */


    `],
})
export class schoolVehicleNotMoveList{

  isLoading:any;
  loader:any
  private vehicleNotMovedHistory:any = [];
  public notMovedData:any=[];
  public flagSet:boolean = false;

 
  constructor(private router: Router,private apiService:ApiService,private chosenUtility:chosenUtility,private datePipe: DatePipe){}

  ngOnInit(){
    this.getAllOrgRoutes();
    setInterval(() => {
      this.getAllOrgRoutes();
    }, 50000);
   
  }


  getAllOrgRoutes():void{
    this.notMovedData=[];
    let apiHeader = {
      data: {
        key:  localStorage.getItem('scbToken'),
      }
    }

    
      let todayDate = this.datePipe.transform(new Date(),"yyyy-M-dd");
      let currentTime = this.datePipe.transform(new Date(),"HH");
      
      
      this.apiService.viewVehicleNotMoved(apiHeader).then(response => {
        this.vehicleNotMovedHistory = response.response;
        let sessionClass='';
       

       
        for(var id in this.vehicleNotMovedHistory){
        
          
           
           
  var n = this.vehicleNotMovedHistory[id].routeName.includes("Walker");
  if(n){ 

  }
else{
         this.flagSet = false;
        
          if (this.vehicleNotMovedHistory[id].routeTimeInfo == null) {
            this.flagSet = true;
         
          }
          else {
            for (var id1 in this.vehicleNotMovedHistory[id].routeTimeInfo) {
             
              if (todayDate != this.vehicleNotMovedHistory[id].routeTimeInfo.date) {
                this.flagSet = true;
                
              } else {
  
              }
  
              if(this.vehicleNotMovedHistory[id].routeTimeInfo.kms<0.5){ 
               
                this.flagSet = true;
              }
            }
          }
       
         
        
         //console.log(sessionClass in this.vehicleNotMovedHistory[id].tags[0]);
           if(this.flagSet){ 
            
            if((+currentTime>5) && (+currentTime<9)){ 

              sessionClass='morning';
              if(sessionClass == this.vehicleNotMovedHistory[id].tags[0] || 'Morning'==this.vehicleNotMovedHistory[id].tags[0]){  
              let objFrame={};
              objFrame['routeName'] = this.vehicleNotMovedHistory[id]['routeName'];
              objFrame['vehRegno'] = this.vehicleNotMovedHistory[id]['vehRegno'];
            
              this.notMovedData.push(objFrame);
              }

                      }

                      if((+currentTime>11) && (+currentTime<15) ){ 
                        sessionClass='afternoon';
                        if(sessionClass == this.vehicleNotMovedHistory[id].tags[0] || 'Afternoon'==this.vehicleNotMovedHistory[id].tags[0]){  
             let objFrame={};
             objFrame['routeName'] = this.vehicleNotMovedHistory[id]['routeName'];
             objFrame['vehRegno'] = this.vehicleNotMovedHistory[id]['vehRegno'];
           
             this.notMovedData.push(objFrame);
            }

          }

          if((+currentTime>=15) && (+currentTime<18)){ 

            sessionClass='evening';
            if(sessionClass == this.vehicleNotMovedHistory[id].tags[0] || 'Evening'==this.vehicleNotMovedHistory[id].tags[0]){  
            let objFrame={};
            objFrame['routeName'] = this.vehicleNotMovedHistory[id]['routeName'];
            objFrame['vehRegno'] = this.vehicleNotMovedHistory[id]['vehRegno'];
          
            this.notMovedData.push(objFrame);
            }

                    }
           }
          
         
          }
//END
          
        }
      }).catch(error => {});

      // setInterval(() => {
      //   this.getAllOrgRoutes();
      // }, 20000);
  }


  





}
