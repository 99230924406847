import { Component, OnInit } from '@angular/core';
import { StorageService } from './../../../services/storage.service';
import { bootstrapDateTime } from './../../../services/bootstrapDateTime/datePicker';
import { GlobalUtils } from './../../../services/global-utils';
import { chosenUtility } from './../../../services/chosen-utility/jq-chosen-utility.js';
import { ApiService } from './../../../services/ApiService';
import { ApiCallDateFormatService } from './../../../services/api-call-date-format.service';

declare const $: any;
@Component({
  providers: [bootstrapDateTime],
  selector: 'app-rfidnotassignedlog',
  templateUrl: './rfidnotassignedlog.component.html',
  styleUrls: ['./rfidnotassignedlog.component.css']
})
export class RfidnotassignedlogComponent implements OnInit {

  public orgName: string;
  public disable: boolean = false;
  public selectedDate: any;
  public loeader: boolean;
  public xlDownload: string = '';
  constructor(
    private storage: StorageService,
    private bootstrapDateTime: bootstrapDateTime,
    private api: ApiService,
    private globalUtils: GlobalUtils,
    private chosenUtility: chosenUtility,
    public dateChange: ApiCallDateFormatService
  ) { }

  ngOnInit() {
    this.initJqUtils(undefined, 'routeHistoryStartDate', undefined);
  }
  initJqUtils(eleChosenId?: string, eleReportStartTime?: string, pickerConfig?: any) {
    let parent = this;
    let timePicker: boolean;
    let timeFormat: any;
    if ((pickerConfig) && (Object.keys(pickerConfig).length)) {
      timePicker = pickerConfig.timePicker;
      timeFormat = pickerConfig.timeFormat;
    } else {
      timePicker = true;
      timeFormat = 'd-m-Y';
    }
    setTimeout(() => {
      if (eleChosenId) this.chosenUtility.initializeChosen(eleChosenId);
      if (eleReportStartTime) {
        parent.bootstrapDateTime.datetime({
          component: eleReportStartTime,
          closeOnDateSelect: true
        });
      }
      $('#eventStartTime').datetimepicker({
        format: 'H:i',
        datepicker: false,
        defaultTime: '00:00',
        timepicker: true,
      });
      $('#eventEndTime').datetimepicker({
        format: 'H:i',
        datepicker: false,
        defaultTime: '23:59',
        timepicker: true,
      });
    }, 150);
  }

  getLogs() {
    this.loeader = true;
    let startDate = $('#routeHistoryStartDate').val();
    let fdate = this.dateChange.dateConversionForApicalls(startDate, 'onlydate');

    this.xlDownload = `${this.api.base}report/download_rfidnotassigned&key=${localStorage.getItem('adminToken')}&
    fromDate=${fdate.trim()}&fromTime=${'00:00:01'}&toDate=${fdate.trim()}&toTime=${'23:59:59'}`;

    setTimeout(() => { this.loeader = false; }, 1000);

  }

}
