import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ApiService } from './../../services/ApiService';
@Component({
  selector: 'app-role-management',
  templateUrl: './role-management.component.html',
  styleUrls: ['./role-management.component.css']
})
export class RoleManagementComponent implements OnInit {

  public pagejump =0;
  public listRolesDetail: any = {};
  public noData: boolean = false;
  
  constructor(
    private router: Router,
    private api: ApiService
  ) { }

  ngOnInit() {

      this.listRoles();
  }


   listRoles(){
       let apiData = {
         
              data:{
                  key: localStorage.getItem("scbToken"),
                  extra:{
                    pageJump: this.pagejump
                  }
                }
            
       }

       this.api.listRoles(apiData).then( (res: any)=>{ 
              if(res.status === 'success'){
                this.noData = false; 
                  if(res.response.length > 0){ 
                    this.listRolesDetail = res.response;
                  }else{
                      this.noData = true;
                  }
                  
              }else{
                 this.noData = true;
              }
       });


   }


  navigate(url: string){
    this.router.navigate(['/' + url , {}]); 
  }

  editRole(id: any){
     
       this.router.navigate(['/edit-roles',id]);
  }
  deleteRole(roleid: any){
     // let roleID = id;
   let parent = this;
       let apiData = {
            data: {
                key: localStorage.getItem('scbToken'),
                form:{
                  roleid
                }
            }
       }

       swal({
        title: 'Are you sure?',
        text: 'Do you want to delete role',
        icon: 'warning',
        buttons: [
          'No, cancel it!',
          'Yes, I am sure!'
        ],
        dangerMode: true,
      }).then(function(isConfirm: any) {

            if(isConfirm){
              parent.api.deleterole(apiData).then( (res: any)=>{ 
                      if(res.status === 'success'){
                            swal('Successfuly deleted...!');

                            setTimeout( ()=>{
                                   // parent  .router.navigate(['/role-management', {}]);
                                   parent.listRoles();
                            },300);
                      }else{
                         swal('Something wrong..!');
                      }
                 });
            }else{

            }

      });

  }

}
