import { Component } from '@angular/core';
import { AppComponent } from '../../app.component';
import { NgRedux, select } from '@angular-redux/store';
import { AppState } from './../../store/index';
import { globalService } from './../../services/global.service';

import { MessageService } from './../../services/message.service';

@Component({
    selector : 'tig-tracker-listener',
    template : ``
})

export class TrigTrackerListenerComponent extends AppComponent {
 constructor(
    public storage: globalService,
    public ngRedux: NgRedux<AppState>,
    public messageService: MessageService
 ) {
     super( storage, ngRedux, messageService );
     this.tracker_listener();
     this.alert_listener();
 }



}