/**
 * @Author: Deepak Pookkote <group10>
 * @Date:   2018-04-24T11:51:53+05:30
 * @Email:  deepak@groupten.com
 * @Project: School-Bus
 * @Filename: lastUpdateStatus.dir.ts
 * @Last modified by:   group10
 * @Last modified time: 2018-04-30T14:31:59+05:30
 * @Copyright: Group10 Technologies
 */



/*Auther:Deepak */
import { Component } from '@angular/core';
import { ApiService } from './../../../services/ApiService';
import { chosenUtility } from './../../../services/chosen-utility/jq-chosen-utility.js';


declare const $:any;
declare const google:any;

@Component({
    selector:'vehicle-update-status',
    template:`

    <div class="container animated fadeIn" style="margin-top:25px;margin-bottom:3%">
      <div class="row">
        <div>
          <div class="panel panel-default panel-table">
              <div class="panel-heading">
                  <div class="row">
                      <div class="col col-xs-12">
                        <h2 class="panel-title notificationHeader pull-left">Vehicle List</h2>
                      </div>
                  </div>
              </div>

                <div class="panel-body table-responsive">
                <div class="animated fadeIn" style="padding-left: 15px;padding-right: 15px">
                  <div class="row">
                      <div class="col-md-12 campaign-content-header campaign-searchbar-container">
                        <div class='search-box overlay-history-search'>
                            <form class='search-form'>
                              <input class='form-control' placeholder='Find Vehicles' type='text'
                              (change)="srchVehicleName=$event.target.value;filterFunction();">
                              <button class='btn btn-link search-btn'>
                                <i class='glyphicon glyphicon-search'></i>
                              </button>
                            </form>
                        </div>
                      </div>
                  </div>
                </div>
                <div *ngIf="trackerInfo ;else falsyTemplate" class="panel-body table-responsive">
                    <table id="mytable" class="table table-hover table-bordered table-list">
                        <thead >
                          <tr class="addedShadow">
                              <th class="col-text" style="text-align:center">#</th>
                              <th class="col-text" style="text-align:center">Vehicle Number</th>
                              <th class="col-text" style="text-align:center">Primary Imei</th>
                              <th class="col-text" style="text-align:center">Secondary Imei</th>
                          </tr>
                        </thead>
                        <tbody  style="border:1px solid rgba(144, 144, 144, 0.56);" >
                        <tr *ngFor="let device of VehicleDetails;let i=index;" data-status="completed">
                          <td  width="10%" class="col-text" style="text-align:right">{{limitOfRecords * pageJump + i + 1}}</td>
                          <td  width="20%" class="col-text text-capitalize"><i class="fa fa-bus mr5" aria-hidden="true"></i>{{device.vehNo}}</td>
                          <td  width="35%" *ngIf="!device.Primary" class="col-text text-center text-capitalize">----</td>
                          <td  width="35%" *ngIf="device.Primary" class="col-text text-capitalize">
                            <div class="row">
                              <span class="col-md-6" title="{{device.Primary.speed}}" [ngStyle]="{'color':getColor(device.Primary.speed,device.Primary.lateUpdateStatus)}"><i style="color: #645959;font-size: 12px;" class="fa fa-microchip mr5" aria-hidden="true"></i>{{device.Primary.imei}}</span>
                              <span class="pull-right col-md-6" title="Last Updated Date Time"><i class="fa fa-clock-o mr5" aria-hidden="true"></i>{{device.Primary.gpsDateTime}}
                              </span>
                              <span title="Click here to view map" (click)="plotPrimaryMovementLocation(device);" style="margin-top: 5px;font-size: 12px;cursor:pointer" class="col-md-12" ><i class="fa fa-map-marker mr5" aria-hidden="true"></i>{{device.Primary.nearestLocation}}</span>
                            </div>
                          </td>
                          <td width="35%" *ngIf="device.Secondary" class="col-text text-capitalize">
                            <div class="row">
                              <span class="col-md-6" title="{{device.Secondary.speed}}" [ngStyle]="{'color':getColor(device.Secondary.speed,device.Secondary.lateUpdateStatus)}" ><i style="color: #645959;font-size: 12px;" class="fa fa-microchip mr5" aria-hidden="true"></i>{{device.Secondary.imei}}</span>
                              <span class="pull-right col-md-6" title="Last Updated Date Time"><i class="fa fa-clock-o mr5" aria-hidden="true"></i>{{device.Secondary.gpsDateTime}}
                              </span>
                              <span title="Click here to view map" (click)="plotSecondaryMovementLocation(device);" style="margin-top: 5px;font-size: 12px;cursor:pointer" class="col-md-12" ><i class="fa fa-map-marker mr5" aria-hidden="true"></i>{{device.Secondary.nearestLocation}}</span>
                            </div>
                          </td>
                          <td width="35%" *ngIf="!device.Secondary" class="col-text text-center text-capitalize">----</td>
                        </tr>
                        </tbody>
                    </table>
                </div>
                <div class="pagination pull-right" style="margin-right:15px;cursor:pointer;">
                  <a data-toggle="tooltip" title="Previous" *ngIf="pageJump<1">&nbsp;<i class="fa fa-angle-double-left" aria-hidden="true" class="disabled"></i></a>
                    <a  data-toggle="tooltip" title="Previous" *ngIf="pageJump>0" (click)="pageJump=pageJump-1;TrackersLastUpdatedStatus();">&nbsp;<i class="fa fa-angle-double-left" aria-hidden="true"></i></a>
                  <a class="active" data-toggle="tooltip" title="Current Page">{{pageJump+1}}</a>
                  <a  data-toggle="tooltip" title="Next" *ngIf="trackerInfo && VehicleDetails?.length > 0" (click)="pageJump=pageJump+1;TrackersLastUpdatedStatus();" >&nbsp;<i class="fa fa-angle-double-right" aria-hidden="true"></i></a>
                    <a  data-toggle="tooltip" title="Next" *ngIf="trackerInfo && VehicleDetails?.length < 1" class="disabled" >&nbsp;<i class="fa fa-angle-double-right" aria-hidden="true"></i></a>
                </div>
                <ng-template #falsyTemplate>
                   <h4 class="animated pulse text-center"  style="animation-duration: 1s;color:#999FBF"><i class="fa fa-frown-o"></i>&nbsp;No vehicles to display</h4>
                </ng-template>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--adding model here-->

    <div id="myModal" class="modal fade" style="padding:7%;">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <button type="button" class="close" data-dismiss="modal" aria-hidden="true">&times;</button>
              <h4 class="modal-title">Vehicle Location</h4>
          </div>
          <div class="modal-body">
              <div id="pointLocationMap" style="height:300px"></div>
          </div>
        </div>
      </div>
    </div>
    <!--@end model-->

    `,
    styles:[`

      /*search box CSS starts */
      .search-box {
       display: inline-block;
       width: 100%;
       border-radius: 3px;
       padding: 4px 55px 4px 15px;
       position: relative;
       background: #fff;
       border: 1px solid #ddd;
       -webkit-transition: all 200ms ease-in-out;
       -moz-transition: all 200ms ease-in-out;
       transition: all 200ms ease-in-out;
      }
      .search-box.hovered, .search-box:hover, .search-box:active {
       border: 1px solid #aaa;
      }
      .search-box input[type=text] {
       border: none;
       box-shadow: none;
       display: inline-block;
       padding: 0;
       background: transparent;
      }
      .search-box input[type=text]:hover, .search-box input[type=text]:focus, .search-box input[type=text]:active {
       box-shadow: none;
      }
      .search-box .search-btn {
       position: absolute;
       right: 2px;
       top: 2px;
       color: #aaa;
       border-radius: 3px;
       font-size: 21px;
       padding: 5px 10px 1px;
       -webkit-transition: all 200ms ease-in-out;
       -moz-transition: all 200ms ease-in-out;
       transition: all 200ms ease-in-out;
      }
      .search-box .search-btn:hover {
       color: #fff;
       background-color: #8FBE00;
      }

      div.section-title{
        font-size:20px;
      }
      div.section-title-border{
        margin-bottom: 20px;
        border-bottom: 1px solid #00BCD4;
        border-bottom-width: medium;
        border-spacing: 1px;
        width:13%;
      }

      .report-basic-info,.student-pickup-info,.student-drop-info,.student-notif-status{
        margin-bottom:10px;
      }

      input[type=checkbox], input[type=radio] {
        margin: 0px 4px -3px !important;
        line-height: normal;
      }

      /*search box CSS ends */

      .ImeiClass{
        margin-right:30px;
        padding: 3px;
        border-radius: 5px;
      }
      .pagination a {
        color: black;
        float: left;
        padding: 7px 16px;
        text-decoration: none;
        border: 1px solid #bcb9b9;
      }

      .addedShadow{
        box-shadow: 0 5px 20px 0px rgba(0, 0, 0, 0.1);
       -moz-box-shadow: 0 5px 20px 0px rgba(0, 0, 0, 0.1);
       -webkit-box-shadow: 0 5px 20px 0px rgba(0, 0, 0, 0.1);
       height:46px;
      }
      .panel-default>.panel-heading {
       color: #333;
       background-color: #ffff;
       border-color: #ffff;
      }
      .panel {
       box-shadow: 1px 2px 4px 0px lightgrey;
      }
      .panel-default {
       border-color: #fdfafa;
      }
      

        `]
})

export class VehicleUpdateStatusDir{

  public trackerInfo:any=[];
  public vehcileBasicInfo:any = [];
  public VehicleDetails:any[] = [];
  public srchVehicleName:any;
  public  pageJump:number=0;
  public limitOfRecords:number;

  offlineVehicleTime:number = 1800000; //30 minuites in milliseconds



    constructor(private apiService:ApiService,private chosenUtility:chosenUtility){

    }
    ngOnInit(){
      this.TrackersLastUpdatedStatus();
    }

    filterFunction(){
       this.pageJump=0;
       this.TrackersLastUpdatedStatus();
       this.srchVehicleName = "";

    }

    getColor(speed:any,update:any) {
      let status = '';
      if(speed > 4 && update ==="C"){
        status = 'green';
      }else if(speed < 4 && update==="C"){
        status = 'red';
      }else if(speed == 0 && update==="L"){
        status = 'grey';
      }else {
        status = 'grey';
      }
      return status;
    }



    TrackersLastUpdatedStatus(){
      this.apiService.viewLastUpdatedStatus({
        data: {
              key: localStorage.getItem('scbToken'),
               filter: {
                 regNo :this.srchVehicleName
               },
               extra: {
                   orderByDateCreated: "-1",
                   pageJump: this.pageJump
               }
          }
      }).then(response => {
        let result=response;
        if(result.status=="success"){
          this.trackerInfo= response.response;
          this.vehcileBasicInfo = this.trackerInfo['vehDetails'];
          let availableVehicleInfo = this.trackerInfo['vehDetails'];
        //  console.log(this.vehcileBasicInfo);
          let desiredRouteInfo:any[] = [];
          this.VehicleDetails = [];
            for(let deviceID in availableVehicleInfo){
              Object.assign(availableVehicleInfo[deviceID],{deviceID:deviceID});

              //Find the late update
              // Primary device
              availableVehicleInfo[deviceID].dateTime;
              if(availableVehicleInfo[deviceID].Primary){
                let lateUpdateP = this.calLateUpdates(availableVehicleInfo[deviceID].dateTime, availableVehicleInfo[deviceID].Primary.gpsDateTime);
                availableVehicleInfo[deviceID].Primary.lateUpdateStatus = lateUpdateP;
                //console.log(lateUpdateP);

              }

              // Secondary device
              if(availableVehicleInfo[deviceID].Secondary){
              let lateUpdateS = this.calLateUpdates(availableVehicleInfo[deviceID].dateTime, availableVehicleInfo[deviceID].Secondary.gpsDateTime);
                //console.log(lateUpdateS);
              availableVehicleInfo[deviceID].Secondary.lateUpdateStatus = lateUpdateS;
              }



              this.VehicleDetails.push(availableVehicleInfo[deviceID]);

              //console.log(this.VehicleDetails);

              if(this.pageJump == 0){
                this.limitOfRecords = this.VehicleDetails.length;
              }

          }
        }


        }).catch(error => {
          console.log('Error while logging in', error);
      });
  }

  // Function to late updates
  calLateUpdates=(currentTime:any, gpsDateTime:any)=>{

    let Currenttimestamp = new Date(currentTime).getTime();

    let gpsTimestamp = new Date(gpsDateTime).getTime();

    let CalcTimeDifference = Currenttimestamp - gpsTimestamp;

    if(CalcTimeDifference >= this.offlineVehicleTime){
      return "L";
    }else{
      return "C";

    }
  }

  plotPrimaryMovementLocation(info:any){
   // console.log(info.Primary.nearestLocation);
    if(info.Primary.nearestLocation){
        $('#myModal').modal('show');

        //map initialization.
        let mapOptions = {
          scrollwheel: true,
          zoom: 12,
          center: new google.maps.LatLng(info.Primary.location.lat, info.Primary.location.lng),
          disableDefaultUI: false
        }
        let haltedMap = new google.maps.Map(document.getElementById('pointLocationMap'), mapOptions);
        let mLatlng = new google.maps.LatLng(parseFloat(info.Primary.location.lat),parseFloat(info.Primary.location.lng));
        let haltLocationMarker = new google.maps.Marker({
            position: mLatlng,
            icon: './..../../assets/webroot/images/map/geo-2.png',
            map: haltedMap,
        });

        let infoWindow = new google.maps.InfoWindow({ maxWidth: 320 });

        haltLocationMarker.addListener('mouseover', function() {
             let content =  `<div><b>Location:</b><span class="locationText" style="">${info.Primary.nearestLocation}</span><br/><b>Speed:</b>${info.Primary.speed} Km/h</div>`
             infoWindow.setContent(content);
             infoWindow.open(haltedMap,this);
       });

       haltLocationMarker.addListener('mouseout', function() {
             infoWindow.close();
       });
    }else{
        $('#myModal').modal('hide');
    }
}


plotSecondaryMovementLocation(info:any){
  //console.log(info.Secondary.nearestLocation);
  if(info.Secondary.nearestLocation){
      $('#myModal').modal('show');

      //map initialization.
      let mapOptions = {
        scrollwheel: true,
        zoom: 12,
        center: new google.maps.LatLng(info.Secondary.location.lat, info.Secondary.location.lng),
        disableDefaultUI: false
      }
      let haltedMap = new google.maps.Map(document.getElementById('pointLocationMap'), mapOptions);
      let mLatlng = new google.maps.LatLng(parseFloat(info.Secondary.location.lat),parseFloat(info.Secondary.location.lng));
      let haltLocationMarker = new google.maps.Marker({
          position: mLatlng,
          icon: './..../../assets/webroot/images/map/geo-2.png',
          map: haltedMap,
      });

      let infoWindow = new google.maps.InfoWindow({ maxWidth: 320 });

      haltLocationMarker.addListener('mouseover', function() {
           let content =  `<div><b>Location:</b><span class="locationText" style="">${info.Secondary.nearestLocation}</span><br/><b>Speed:</b>${info.Secondary.speed} Km/h</div>`
           infoWindow.setContent(content);
           infoWindow.open(haltedMap,this);
     });

     haltLocationMarker.addListener('mouseout', function() {
           infoWindow.close();
     });
  }else{
      $('#myModal').modal('hide');
  }
}


}
