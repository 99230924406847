/**
 * @Author: Deepak Pookkote <group10>
 * @Date:   2018-04-17T11:31:29+05:30
 * @Email:  deepak@groupten.com
 * @Project: School-Bus
 * @Filename: templateForm.dir.ts
 * @Last modified by:   group10
 * @Last modified time: 2018-05-22T15:16:12+05:30
 * @Copyright: Group10 Technologies
 */



/*Auther:Deepak */
import { Component, Input, Output, EventEmitter } from '@angular/core';
import { globalService } from './../../../services/global.service';
import { ApiService } from './../../../services/ApiService';
import { GlobalUtils } from './../../../services/global-utils';

const swal = require('sweetalert');
declare var $: any;

@Component({
  selector: 'update-template-form',
  template: `
  <div class="slideDown animated fadeIn">
<div class="TemplateInfo-Panel">
  <div class="row">
      <div>
          <div class="panel panel-default panel-table">
              <div class="panel-heading">
                  <div class="row">
                    <div class="col col-xs-6">
                        <h3 class="panel-title">Update Template</h3>
                    </div>
                  </div>
              </div>
              <!--add content here-->
              <div class="step_51 formContent" style="margin-top:15px;">
                  <div class="row">
                    <div class="col-md-6">
                      <label class="control-label" for="rTitle">Template Title<sup>*</sup></label>
                      <input type="text" maxlength="50" [(ngModel)]='availableCampaignTemplates.templateTitle' name="templateTitle" class="custom-form-control" id="rTitle">
                      <span class="text-danger" *ngIf="templateTitleError">Add Template Title!!</span>
                    </div>
                    <div class="col-md-6">
                      <label class="control-label" for="templates">Template Notes<sup>*</sup></label>
                      <textarea rows=4 [(ngModel)]='availableCampaignTemplates.templateMessage' name="templateMessage" class="custom-form-control" id="templates"></textarea>
                      <span class="text-danger" *ngIf="templateContentError">Add Template Content over here!!</span>
                    </div>
                  </div>
              </div>
              <div class="step_37 formContent" style="margin-top:10px">
                  <div class="row">
                    <div class="col-md-2" style="float:right" >
                      <button (click)="EditTemplate()" class="btn btn-primary custom-form-control"><i class="fa fa-pencil mr5" aria-hidden="true"></i>
                        Update
                      </button>
                    </div>
                    <div class="col-md-2" style="float:right">
                      <button  id="org_template_cancel_btn" (click)="hideUpdateForm();"
                      class="btn btn-danger custom-form-control"><i class="fa fa-ban mr5" aria-hidden="true"></i>Cancel</button>
                    </div>
                  </div>
              </div>
              
          </div>
              <!--add content here-->
      </div>
  </div>
</div>
</div>
  `,
  styles: [`
    .TemplateInfo-Panel{
      padding:20px;
    }

    .formContent{
      padding-left: 16px;
      padding-right: 16px;
      padding-bottom: 8px;
    }



`  ],
})
export class OrgTemplateUpdateFormDir {


  public showForm: boolean = false;
  private currentTemplateId: string = '';
  private currentOrganization: any = this.globalService.getData().currentOrganization;
  private apiToken: any = localStorage.getItem("adminToken");
  public templateInfo: any = [];
  public clickMessage: any;
  public organizationId: any;
  public templateform: any;

  public templateTitle: any;
  public templateMessage: any;
  public templateContentError: boolean;
  public templateTitleError: boolean;

  public availableCampaignTemplates: any = [];

  constructor(
    public globalService: globalService,
    public apiService: ApiService,
    private globalUtils: GlobalUtils
  ) {

    this.organizationId = this.globalService.getData().currentOrganization;
    this.templateform = this.globalService.getData().displayOrgTemplateForm;
  }

  @Input('templateId') _templateId: any;

  @Output() hideTemplateUpdateFormComponent: EventEmitter<any> = new EventEmitter<any>();

  ngOnInit() {

    this.currentTemplateId = this._templateId;

    if (this.currentTemplateId != '') {
      this.getAvailableCampaignTenplatesAdmin();
    }
  }

  hideUpdateForm() {
    this.hideTemplateUpdateFormComponent.emit(false);
  }


  validateUserInputs = (): boolean => {
    let isValid: boolean = true;
    if (!this.availableCampaignTemplates.templateTitle) {
      this.templateTitleError = true;
      isValid = false;
    } else {
      this.templateContentError = false;
    }

    if (!this.availableCampaignTemplates.templateMessage) {
      this.templateContentError = true;
      isValid = false;
    } else {
      this.templateContentError = false;
    }
    return isValid;
  }


  EditTemplate = (): void => {
    const parent = this;
    let requestHeader = {
      data: {
        key: this.apiToken,
        form: {
          templateId: this.currentTemplateId,
          templateTitle: this.availableCampaignTemplates.templateTitle,
          templateMessage: this.availableCampaignTemplates.templateMessage
        }
      }
    };
    swal({
      title: 'Are you sure?',
      text: 'The selected template will be modified',
      icon: 'warning',
      buttons: [
        'No, cancel it!',
        'Yes, I am sure!'
      ],
      dangerMode: true,
    }).then(function (isConfirm: any) {
      if (isConfirm) {
        parent.apiService.editCampaignTemplate(requestHeader).then(response => {
          if (response) {
            //parent.getAvailableCampaignTenplatesAdmin();
            parent.hideUpdateForm();
          }
        }).catch(error => { });
      } else {
        //s swal('Cancelled', 'Continue the session :)', 'error');
      }
    });
  }
  /*edit template @end*/


  /*View Template Information on click of edit button */
  getAvailableCampaignTenplatesAdmin = () => {
    this.apiService.viewOrgMessageTemplateAdmin({
      data: {
        key: this.apiToken,
        filter: {
          orgId: this.currentOrganization,
          templateId: this.currentTemplateId
        }
      }
    }).then(response => {
      let result = response;
      if (result) {
        if (result.response != null && result.response != undefined && result.response != '') {
          this.availableCampaignTemplates = response.response
          this.availableCampaignTemplates = this.availableCampaignTemplates[0];
          //console.log(this.availableCampaignTemplates.templateId);
        }
      }

    }).catch(error => {
      console.log('Error while logging in', error);
    });
  }

}/*end here*/
