import { Component, OnInit, ViewChild, Input } from '@angular/core';
declare var AmCharts: any;
@Component({
    selector: 'g10-sm-clock-chart',
    template:`
    <div id="amClockChart"></div>
    `,
    styles:[`
        #amClockChart{
            width: 39%;
            height: 100%;
            position: relative;        
            float: left;
        }
    
    `]
})

export class ClockChartComponent{
    @Input() _data:any;
    constructor(){}

    ngOnInit(){
       
        this.renderChart();
    }

   async renderChart(){
          let keys = Object.keys(this._data);
          let finalArray: any=[];
          let pad = (num:number) => {
            return num > 9 ? num : "0" + num;
          };

          // let TMS = 
          let tms =(arr: any)=>{
            arr.map((itm: any, i:any) => {
                let m: any = Number(itm.slice(3, 5));
                let h: any = Number(itm.slice(0, 2));
                if (m == 0) {
                    let val = pad(parseInt(h) - 1)+":"+pad(60-2)+"-"+pad(h)+":"+pad(2);
                    finalArray.push(val);
                } else {
                  let temp = pad(h)+":"+pad(m-2)+"-"+pad(h)+":"+pad(parseInt(m + 2));
                  finalArray.push(temp);
                }
                if(arr.length-1 == i){
                  let chartObj: any=[];
                  finalArray.forEach((itm: any,i:number)=>{
                    let colorCode = "#" + (0x1000000 + Math.random() * 0xffffff).toString(16).substr(1, 6);
                    let split = itm.split('-');
                    chartObj.push({
                        "color": colorCode,
                        "endValue": split[1].replace(':','.'),
                        "startValue": split[0].replace(':','.'),
                        "innerRadius": "80%",
                        "alpha": 0.8
                      });
                  });
                    setTimeout(()=>{            
                       
                  AmCharts.makeChart("amClockChart", {
                    "type": "gauge",
                    "startDuration": 0.3,
                    "axes": [{
                      "axisAlpha": 0.3,
                      "endAngle": 360,
                      "endValue": 12,
                      "minorTickInterval": 0.2,
                      "showFirstLabel": false,
                      "startAngle": 0,
                      "axisThickness": 1,
                      "valueInterval": 1,
                      "labelOffset": 5,
                      "inside": false,
                      "bands": chartObj,
                    }]
                    
                  });
                        this.loadCss();
                    },1000);
                }
              });
           
              // return finalArray;
          }
          tms(keys);
         
    }
    loadCss(){
         document.getElementById('amClockChart').style.height='200px';
         document.getElementById('amClockChart').style.width='200px';
         setTimeout(()=>{
            (document.querySelector('.amcharts-chart-div a') as HTMLElement).style.display='none'
   
         },1000);
         }
}