/*Auther:Deepak */
import { Component, OnInit, Input, Output, OnChanges, EventEmitter, ChangeDetectorRef } from '@angular/core';

import { Observable } from 'rxjs';
import { NgRedux, select } from '@angular-redux/store';
import { AppState } from './../../store/index';

import { TrackerData_View } from './../../actions/tracker-data';
import { ImeiFinderServices } from './../../services/imeiFinder.service';


declare var $: any;

@Component({
  selector: 'slide-alerts',
  styles: [`

    /* BELL */

  @-webkit-keyframes ring {
    0% {
      -webkit-transform: rotate(-15deg);
      transform: rotate(-15deg);
    }

    2% {
      -webkit-transform: rotate(15deg);
      transform: rotate(15deg);
    }

    4% {
      -webkit-transform: rotate(-18deg);
      transform: rotate(-18deg);
    }

    6% {
      -webkit-transform: rotate(18deg);
      transform: rotate(18deg);
    }

    8% {
      -webkit-transform: rotate(-22deg);
      transform: rotate(-22deg);
    }

    10% {
      -webkit-transform: rotate(22deg);
      transform: rotate(22deg);
    }

    12% {
      -webkit-transform: rotate(-18deg);
      transform: rotate(-18deg);
    }

    14% {
      -webkit-transform: rotate(18deg);
      transform: rotate(18deg);
    }

    16% {
      -webkit-transform: rotate(-12deg);
      transform: rotate(-12deg);
    }

    18% {
      -webkit-transform: rotate(12deg);
      transform: rotate(12deg);
    }

    20% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
  }

  @keyframes ring {
    0% {
      -webkit-transform: rotate(-15deg);
      -ms-transform: rotate(-15deg);
      transform: rotate(-15deg);
    }

    2% {
      -webkit-transform: rotate(15deg);
      -ms-transform: rotate(15deg);
      transform: rotate(15deg);
    }

    4% {
      -webkit-transform: rotate(-18deg);
      -ms-transform: rotate(-18deg);
      transform: rotate(-18deg);
    }

    6% {
      -webkit-transform: rotate(18deg);
      -ms-transform: rotate(18deg);
      transform: rotate(18deg);
    }

    8% {
      -webkit-transform: rotate(-22deg);
      -ms-transform: rotate(-22deg);
      transform: rotate(-22deg);
    }

    10% {
      -webkit-transform: rotate(22deg);
      -ms-transform: rotate(22deg);
      transform: rotate(22deg);
    }

    12% {
      -webkit-transform: rotate(-18deg);
      -ms-transform: rotate(-18deg);
      transform: rotate(-18deg);
    }

    14% {
      -webkit-transform: rotate(18deg);
      -ms-transform: rotate(18deg);
      transform: rotate(18deg);
    }

    16% {
      -webkit-transform: rotate(-12deg);
      -ms-transform: rotate(-12deg);
      transform: rotate(-12deg);
    }

    18% {
      -webkit-transform: rotate(12deg);
      -ms-transform: rotate(12deg);
      transform: rotate(12deg);
    }

    20% {
      -webkit-transform: rotate(0deg);
      -ms-transform: rotate(0deg);
      transform: rotate(0deg);
    }
  }

  .faa-ring.animated,
  .faa-ring.animated-hover:hover,
  .faa-parent.animated-hover:hover > .faa-ring {
    -webkit-animation: ring 2s ease infinite;
    animation: ring 2s ease infinite;
    transform-origin-x: 50%;
    transform-origin-y: 0px;
    transform-origin-z: initial;
  }

.dropDown{
  padding-top: 20px;
  padding-left: 19px;
  padding-right: 19px;
  background-color: #fbfbfb;
  /* border-bottom: 4px solid white; */
  display: none;
  margin: 36px auto;
  right: 1%;
  position: absolute;
  z-index: 99999999;
  margin-left: 34%;
  min-width:75vh;
  max-width:76vh;
  border: 1px solid #d4d3d3;
  border-radius: 2px;
}
.selector{
    // position: absolute;
    // padding: 5px;
    // right: 16%;
    // margin:10px auto;
    // border-bottom-left-radius: 10px;
    // border-bottom-right-radius: 10px;
    // cursor: pointer;
    // z-index: 999999999999999;
}
animation css */
.grow { transition: all .2s ease-in-out; }
.grow:hover { transform: scale(1.1); }

ul li{
  display: inline-table;
  margin: 0px;
  padding-left: 14px;
  padding-right: 8px;
  padding-top: 6px;
  padding-bottom: 4px;
  //border-top: 1px solid #f1f1f1;
  border-left: 1px solid #d0cbcb;
  background: linear-gradient(to bottom, #fff 0, #f3f3f3 100%);
  cursor:pointer;

}

.supClass {
  background:#E91E63;
  display: inline;
  padding: .2em .3em;
  font-size: 79%;
  font-weight: 700;
  line-height: 1;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: .25em;
  margin-left: -10px;
  top: -0.9em;
}
.typeItem{
  padding: 5px;
}
.subClass{
    padding: 2px 7px;
    margin-left: 4px;
    border-radius: 5%;
    background: #4e90bb;
    display: inline;
    font-size: 83%;
    font-weight: 700;
    line-height: 1;
    color: #fff;
    text-align: center;
    white-space: nowrap;
    vertical-align: baseline;
}

.jcarousel {
    overflow: hidden;
    position: relative;
}
.jcarousel ul {
    list-style: none;
    margin: 0;
    padding: 0;
    position: relative;
    width:20000em;
    display:inline-table;
}
.jcarousel li {
    float: left;
    width: 132px;
}
.checked{
  border-bottom: 2px solid #3498db;
}
.notifTypeControl{
  text-decoration: none;
}
.prev {
    position: absolute;
    z-index: 999999999999;
    top: 2px;
    background: #fbfbfb;
    padding: 4px;
    border-right: 1px solid #e8e3e3;
}
.next {
    position: absolute;
    z-index: 999999999999;
    top: 2px;
    right: -2px;
    background: #fbfbfb;
    padding: 4px;
    border-left: 1px solid #e8e3e3;
}
.default{
  background:#d0c6c9;
}

.err{ color: red; }

    `],
  templateUrl: './slideAlerts.html',
})

export class slideAlertsComponent {

  // (this.ngRedux.getState().trackerData)

  public notifTypes: any[];
  public selectedType: any = {};
  public vehInfo: any = [];
  private notificationsList: any = [];
  private notificationTemplateArray: any = [];
  private Sos: boolean = true;
  private Overspeed: boolean = false;
  private Halt: boolean = false;
  private Ac: boolean = false;
  private Geofence: boolean = false;
  private selectedNotification: string;
  public __notificationObject_all: any = [];
  public __notificationObject: any = [];
  private NotificationTypeObject: any = {};
  public notifCategory: any = {
    sos: 0,
    overspeed: 0,
    ac: 0,
    halt: 0,
    geofence: 0
  };

  // @select('alertData') public __notificationObject: Observable<any>;
  // @select('alertData')
  //   set notifyFun(alertData$: any) {
  //     this.__notificationObject = alertData$;
  //     this.notifCategory = {
  //       sos:false,
  //       overspeed:false,
  //       ac:false,
  //       halt:false,
  //       geofence:false
  //     }
  //     this.checkCategory(alertData$.type);
  // }

  //  @Input('notificationObject') __notificationObject:any;

  //   @Input('notificationObject') //setting stoppage ID from other directive.
  //   set notificationObjectAction(e: any) {

  //  //   this.__notificationObject = e;

  //   }








  public displayNotifications: number = 0;

  OverspeedClick() {
    this.selectedNotification = 'overspeed'
    this.Sos = false;
    this.Overspeed = true;
    this.Halt = false;
    this.Ac = false;
    this.Geofence = false;

  }
  AcClick() {
    this.selectedNotification = 'AC'
    this.Sos = false;
    this.Overspeed = false;
    this.Halt = false;
    this.Ac = true;
    this.Geofence = false;

  }
  GeofenceClick() {
    this.selectedNotification = 'Geofence'
    this.Sos = false;
    this.Overspeed = false;
    this.Halt = false;
    this.Ac = false;
    this.Geofence = true;

  }
  SosClick() {
    this.selectedNotification = 'SOS'
    this.Sos = true;
    this.Overspeed = false;
    this.Halt = false;
    this.Ac = false;
    this.Geofence = false;

  }
  HaltClick() {
    this.selectedNotification = 'Halt'
    this.Sos = false;
    this.Overspeed = false;
    this.Halt = true;
    this.Ac = false;
    this.Geofence = false;

  }

  constructor(
    private ngRedux: NgRedux<AppState>,
    public imeiservce: ImeiFinderServices,
    private changeDetector: ChangeDetectorRef
  ) {
    this.alert_pulse();

    //this.localAlert();
  }



  ngAfterViewChecked() {
    this.changeDetector.detectChanges();
  }

  ngOnInit() {
    $(document).ready(function () {
      $('.selector').click(function () {
        $('.dropDown').slideToggle(400);
      });
    });

    this.listClick(true, '');
    this.jcarousalNaviagtor();
    this.closeNotifications();



  }

  alert_pulse() { // console.log('alert pulse...', this.__notificationObject );
    this.__notificationObject_all = (this.ngRedux.getState().alertData);
    //if(this.__notificationObject.length )this.checkCategory(this.__notificationObject);

     //   //Sort the data based on date
  //   //this.__notificationObject_all




    if(this.__notificationObject_all.length )this.checkCategory(this.__notificationObject_all);
    if(this.__notificationObject.length == 0){
      this.__notificationObject = this.__notificationObject_all;
    }

    this.__notificationObject_all.sort(this.GetSortOrder('gpsTime'));
   // this.getVehInfo();
    setTimeout( () => {  this.alert_pulse();

    }, 10000 );


  }


 



  // localAlert() {
  //   this.__notificationObject_all = [{
  //     "category": "sos",
  //     "message": "SOS triggered",
  //     "vehID": "1525690775928DemoBus1",
  //     "imei": 866710035998449,
  //     "gpsTime": 1533101183000
  //   }, {
  //     "category": "overspeed",
  //     "message": "Overspeed alert",
  //     "vehID": "1525690775928DemoBus1",
  //     "imei": 866710035998449,
  //     "speed": 60,
  //     "gpsTime": 1533101189000,
  //     "nearestLocation": "0.03 km from Group10 Technologies Pvt Ltd, #28, Vigneshwara Street, Ward 174, Zone 13 Adyar, Chennai, Chennai district, Tamil Nadu, 600032, India"
  //   }, {
  //     "category": "overspeed",
  //     "message": "Overspeed alert",
  //     "vehID": "1525690775928DemoBus1",
  //     "imei": 866710035998449,
  //     "speed": 64,
  //     "gpsTime": 1533101195000,
  //     "nearestLocation": "0.03 km from Group10 Technologies Pvt Ltd, #28, Vigneshwara Street, Ward 174, Zone 13 Adyar, Chennai, Chennai district, Tamil Nadu, 600032, India"
  //   }, {
  //     "category": "sos",
  //     "message": "SOS triggered",
  //     "vehID": "1525690775928DemoBus1",
  //     "imei": 866710035998449,
  //     "gpsTime": 1533101201000
  //   }, {
  //     "category": "overspeed",
  //     "message": "Overspeed alert",
  //     "vehID": "1525690775928DemoBus1",
  //     "imei": 866710035998449,
  //     "speed": 67,
  //     "gpsTime": 1533101207000,
  //     "nearestLocation": "0.02 km from Group10 Technologies Pvt Ltd, #28, Vigneshwara Street, Ward 174, Zone 13 Adyar, Chennai, Chennai district, Tamil Nadu, 600032, India"
  //   }];


  //   //console.log(this.__notificationObject_all[0].gpsTime);

  //  // Sort the data based on date
  //   //this.__notificationObject_all
  //   // this.__notificationObject_all.sort(function(a:any, b:any) {
  //   //   var dateA = (a.this.__notificationObject_all.gpsTime), dateB =(b.this.__notificationObject_all.gpsTime);
  //   //   console.log( dateA - dateB);
  //   //   return dateA - dateB;
  //   // });

  //  // console.log(this.__notificationObject_all);


  //   if (this.__notificationObject_all.length) this.checkCategory(this.__notificationObject_all);
  //   if (this.__notificationObject.length == 0) {
  //     this.__notificationObject = this.__notificationObject_all;
  //   }
  //   console.log(this.__notificationObject_all.sort(this.GetSortOrder('gpsTime')));
  // }

  //       getVehInfo( ) {
  //         (this.__notificationObject).map( (itm: any) => {
  //          this.vehInfo[itm.imei] = (this.ngRedux.getState().trackerData).filter( ( data: any ) =>  data.imei === itm.imei )[0]['vehNo'];

  //         /*
  //         category:"overspeed"
  //         gpsTime:1530089860000
  //         message:"Overspeed alert"
  //         speed:51
  //         vehID:"1525690775928DemoBus1"
  // */
  //         } );
  //       }


  // alertInformation:any = [];]
  // alertDataJson(){
  //   this.alertInformation = [];
  // }




  filterNotification(item: string) {
  //  console.log("filter: " + item);
    this.__notificationObject = this.__notificationObject_all.filter((index: any) => index.category == item);
    this.displayNotifications = -1;
    this.goNextMessage();
    //console.log(this.__notificationObject);
  }


  goNextMessage() {
    //console.log("in goNextMessage : " + this.displayNotifications);
    //console.log(this.__notificationObject.length);
    if (this.__notificationObject.length) {
      this.displayNotifications++;
    }

    if (this.displayNotifications >= this.__notificationObject.length) {
      this.displayNotifications = this.__notificationObject.length - 1;
    }
  }

  goPreviousMessage(i: any) {
    //console.log("in goPrevMessage : " + this.displayNotifications);
    //console.log(this.__notificationObject.length);
    if (this.__notificationObject) {
      this.displayNotifications--;
    }

    if (this.displayNotifications < 1) {
      this.displayNotifications = 0;
    }
  }

  removeNotification(i: any) {
    this.__notificationObject.splice(i, 1);
  }

  listClick = (event: any, newValue: any): void => {
    this.selectedType = newValue;
  }

  closeNotifications = () => {
    $("#close").click(function () {
      $(".dropDown").hide();
    });
  }


  jcarousalNaviagtor = () => { //Navigator function to choose types
    $(function () {
      $('.jcarousel')
        .on('jcarousel:createend', function (event: any, carouselInstance: any) {
          $('.jcarousel-pagination').jcarouselPagination({
            perPage: 1,
            item: function (page: any) {
              return '<a href="#' + page + '" class="' + (page == 1 ? "active" : "") + '">' + page + '</a>';
            }
          })
            .on('jcarouselpagination:active', 'a', function () {
              $(this).addClass('active');
            })
            .on('jcarouselpagination:inactive', 'a', function () {
              $(this).removeClass('active');
            });
          $('.jcarousel-control.prev').jcarouselControl({
            target: '-=1',
            method: function () {
              $('.jcarousel-pagination').find('.active').prev().trigger('click');
            }
          });
          $('.jcarousel-control.next').jcarouselControl({
            target: '+=1',
            method: function () {
              $('.jcarousel-pagination').find('.active').next().trigger('click');
            }
          });
          $('.jcarousel-control')
            .on('jcarouselcontrol:inactive', function () {
              $(this).removeClass('active');
            })
            .on('jcarouselcontrol:active', function () {
              $(this).addClass('active');
            });
        })
        .jcarousel({
          list: '.jcarousel-items',
          items: '.jcarousel-item'
        });
      $('.jcarousel-pagination a').click(function () {
        //console.log('$(this)', $(this));
        //console.log('$(this).hasClass("active")', $(this).hasClass('active'));
        if ($(this).hasClass('active')) {
          // console.log(working test);
        }
      });
    });
  }

  public notificationFilter = {
    sos: function (item: any) {
      return item.category == 'sos';
    },
    halt: function (item: any) {
      return item.category == 'halt';
    },
    overspeed: function (item: any) {
      return item.category == 'overspeed';
    },
    geofence: function (item: any) {
      return item.category == 'geofence';
    },
    ac: function (item: any) {
      return item.category == 'ac';
    }
  }

  checkCategory(type: string) {
    this.notifCategory = {
      sos: 0,
      overspeed: 0,
      ac: 0,
      halt: 0,
      geofence: 0
    }
    for (let id of type) {
      let cat = id['category'];
      this.notifCategory[cat]++;
    }

    // console.log(JSON.stringify(type));
    //  console.log(this.notifCategory);
    //  let isNotif = (this.__notificationObject.filter( this.notificationFilter[type]).length)?true:false;
    //  if(isNotif) this.notifCategory[type] = true;
    //  else this.notifCategory[type] = false;
    //   console.log(this.notifCategory);
  }

  GetSortOrder(prop:any) {  
    return function(a:any, b:any) {  
        if (a[prop] < b[prop]) {  
            return 1;  
        } else if (a[prop] < b[prop]) {  
            return -1;  
        }  
        return 0;  
    }  
}


}
