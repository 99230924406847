/**
 * @Author: Deepak Pookkote <group10>
 * @Date:   2018-04-24T11:51:53+05:30
 * @Email:  deepak@groupten.com
 * @Project: School-Bus
 * @Filename: routeForm.dir.ts
 * @Last modified by:   group10
 * @Last modified time: 2018-05-02T10:44:42+05:30
 * @Copyright: Group10 Technologies
 */



import {
  Component,
  Input, Output, EventEmitter, NgZone, OnChanges, SimpleChanges
} from '@angular/core';
import { ApiService } from './../../../services/ApiService';
import { decodeAddress } from './../../../services/decodeAddress.service';
import { AmazingTimePickerService } from 'amazing-time-picker';
import { globalService } from './../../../services/global.service';
import { StorageService } from './../../../services/storage.service';


declare var $: any;
declare var google: any;
@Component({
  selector: 'new-route-form',
  template: `
  <!--contents for add Route div begins -->

  <div class="route-heading-container animated fadeIn" style="padding: 2%;background: rgba(158, 158, 158, 0.09);min-height:94px;">
    <div class="back-button pull-left grow meager-e2e-routeform-goback" id="back_btn" (click)="hideAddRoute($event)" style="cursor:pointer;" title="go back">
    <svg width="24" height="24" viewBox="0 0 24 24">
     <g class="nc-icon-wrapper" fill="#429db5">
        <path d="M21 11H6.83l3.58-3.59L9 6l-6 6 6 6 1.41-1.41L6.83 13H21z"></path>
      </g>
    </svg>
    </div>
    <div class="route-heading-tabs" style="padding:25px">
        <div class="list-heading pull-left">Add New Route</div>
    </div>
  </div>
  <div class="routeDetails" style="overflow-y: scroll;max-height: 73vh;overflow-x: hidden;padding:25px;">
    <div class="row fillRouteInfo">
    <div class="form-group">
      <label class="control-label">Route Name<sup>*</sup></label>
      <input class="custom-form-control point-name point-input-text meager-e2e-routeform-rname" [(ngModel)]="routeName" appAutofocus>
      <span class="text-danger meager-e2e-routeform-rname-errtxt" *ngIf="showRouteNameError">Route name is a required field.</span>
    </div>
    <div class="form-group">
      <label class="control-label">Route Type<sup>*</sup></label>
        <select class="custom-form-control point-input-text meager-e2e-routeform-rtype" [(ngModel)]="routeType">
          <option value="pickup">Pickup</option>
          <option value="drop">Drop</option>
         <!-- <option value="walker">Walker</option>-->
        </select>
        <span class="text-danger meager-e2e-routeform-rtype-errtxt" *ngIf="showRouteTypeError">Choose Route type</span>
    </div>
    <div class="form-group">
      <label class="control-label">Route Start Time (HH:MM)<sup>*</sup></label>
      <input class="custom-form-control point-input-text meager-e2e-routeform-rstarttime" [(ngModel)]="routeStartTime"  atp-time-picker  type="time">
      <span class="text-danger meager-e2e-routeform-rstarttime-errtxt" *ngIf="showRouteStartTimeError">You Should Follow (HH:MM) Format</span>
    </div>
    <div class="form-group">
      <label class="control-label">Route End Time (HH:MM)<sup>*</sup></label>
      <input class="custom-form-control point-input-text meager-e2e-routeform-rendtime" [(ngModel)]="routeEndTime" (ngModelChange)="getAvailableVehicles()" atp-time-picker  type="time">
      <span class="text-danger  meager-e2e-routeform-rendtime-errtxt" *ngIf="showRouteEndTimeError">You Should Follow (HH:MM) Format</span>
    </div>
    <div class="form-group">
      <label class="control-label">Route Start Location<sup>*</sup></label>
      <textarea class="custom-form-control point-input-text  meager-e2e-routeform-rloc" id="locationField"  type="text" [(ngModel)]="inputRouteStartLocation" (change)="geocodeAddress()"></textarea>
      <span class="text-danger  meager-e2e-routeform-rloc-errtxt" *ngIf="showRouteLocationError">Route location is a required field.</span>
    </div>
    <div class="form-group">
    <label class="control-label">Tags<sup>*</sup></label>
      <input class="custom-form-control  meager-e2e-routeform-rtag" (change)="store()" [(ngModel)]="tag" maxlength="20" >
      <span class="text-danger  meager-e2e-routeform-rtag-errtxt" *ngIf="showRouteTagError">Route tag is a required field.</span>
    </div>
    <div class="">
      <span class="tagClass cont mr5 text-capitalize mb5" *ngFor="let tag of tagList;let i=index;">{{tag}}&nbsp;<i class="fa fa-times-circle rotate" aria-hidden="true" (click)="removeTag(i)" style="cursor: pointer;"></i></span>
    </div>
    <div class="form-group" >
      <label class="control-label">Assign Vehicle</label>
      <select class="custom-form-control point-input-text meager-e2e-routeform-vehid" [(ngModel)]="vehicleId">
        <option value=""></option>
        <option *ngFor="let device of vehAvailable" value = "{{device.deviceId}}">{{device.VehicleNo}}</option>
      </select>
      <span class="text-danger  meager-e2e-routeform-vehid-errtxt" *ngIf="showRouteVehicleError">Route vehicle is a required field.</span>
     
      <ng-container *ngIf="vehicleId && vehicleId!=''">
        <ng-container 
          *ngIf="availableVehObj[vehicleId] && 
                 availableVehObj[vehicleId]['vehicleInformation'] && 
                 availableVehObj[vehicleId]['vehicleInformation']['driverName'] && 
                 availableVehObj[vehicleId]['vehicleInformation']['driverName']!=''; else noDriverFound">
        </ng-container>
      </ng-container>
      <ng-template #noDriverFound><span class="meager-e2e-routeform-vehid-errtxt" style="color:#424242cc">*No driver assigned for this vehicle</span></ng-template>

    </div>

    <ng-container 
      *ngIf="vehicleId && vehicleId!='' && availableVehObj[vehicleId] && 
             availableVehObj[vehicleId]['vehicleInformation'] && 
             availableVehObj[vehicleId]['vehicleInformation']['driverName'] && 
             availableVehObj[vehicleId]['vehicleInformation']['driverName']!=''">
             <div class="form-group">
               <label class="control-label">Driver Name</label>
               <input class="custom-form-control point-name point-input-text meager-e2e-routeform-drivername" [(ngModel)]="availableVehObj[vehicleId]['vehicleInformation']['driverName']" disabled>
               <span class="text-danger meager-e2e-routeform-drivername-errtxt" *ngIf="showRouteDriverError">Route driver is a required field.</span>
             </div>
    </ng-container>

    <!--<div class="form-group" >

   <!--<input type="checkbox" class="custom-form-control point-input-text" [(ngModel)]="studentMemberId">-->
   <!--  <input class="chk regular-checkbox " name="smsAlert" type="checkbox"  >
    <label class="control-label" style="margin: 0 0 0 17px;">Assign <span class="mr5 student-member-name" va>{{studentMemberName}}</span> to this route </label>
    
  </div>-->

    </div>

    <div class="form-actions pull-right">
      <button class="webform-submit button-primary btn btn-primary form-submit  meager-e2e-routeform-submit" (click)="createRouteObj()">Submit</button>
    </div>

  </div>


  <!--contents for Route ends here-->


  `,
  styles: [`


  .student-member-name{
    color: cadetblue;
    font-size: 15px;
    font-weight: 600;
   }
  list-heading{
    font-size: 30px;
    font-weight: 400;
  }
  .routeDetails::-webkit-scrollbar-track
  {
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
    background-color: #F5F5F5;
  }
  .cont .rotate {
    cursor:pointer;
    font-size: 15px;
  }

  .cont .rotate:hover{
    color: red;
    transition: 0.9s;
    transform: rotateY(180deg);
  }

`  ],
})

export class SchoolRouteFormDir {

  public addForm: boolean = false;
  public inputRouteStartLocation: string;
  public routeName: string;
  public routeType: string;
  public driverId: string;
  public routeStartTime: string;
  public routeEndTime: string;
  public orgVehicles: any;
  public vehicleId: any;
  public mapObj: any;

  public showRouteNameError: boolean;
  public showRouteTypeError: boolean;
  public showRouteDriverError: boolean;
  public showRouteStartTimeError: boolean;
  public showRouteEndTimeError: boolean;
  public showRouteLocationError: boolean;
  public showRouteTagError: boolean;
  public showRouteVehicleError: boolean;

  public tagList: any = [];
  public tag = '';
  public vehAvailable: any;
  public routeCreateMarker: any;

  public availableVehObj:any = {};

  public studentId: boolean;
  public studentMemberName: string = '';

  @Input('routeVehicles')
  set routeVehicles(routeVehicles: any) {
    this.processOrgVehicles(routeVehicles);
  }

  @Input('mapObj')
  set map(map: any) {
    this.mapObj = map;
  }

  @Output() RouteList: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() hideAddRouteForm: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() createRoute: EventEmitter<any> = new EventEmitter<any>();

  constructor(
    private apiService: ApiService,
    private decodeAddress: decodeAddress,
    private globalService: globalService,
    private storage: StorageService
  ) {
  }


  ngOnInit() {
    this.createRouteMarker();


    //this.getAvailableVehicles();

    // let studentName = this.globalService.getData().studentName;

    //  if(studentName!=null||studentName!=undefined||studentName!=''){

    //     this.studentMemberName=studentName;
    //    console.log(this.studentMemberName);
    // }


  }

  ngOnChanges(changes: SimpleChanges) {
    //console.log(changes);
  }

  checkVechAssing(e: any) {
    if (e.target.value === 'walker') {
      this.vehicleId = '';
    } else {

    }
  }

  createRouteMarker() {
    //Creating separate route marker.
    let mLatLng = new google.maps.LatLng(28.7041, 77.1025);
    let parent: any = this;
    this.routeCreateMarker = new google.maps.Marker({
      position: mLatLng,
      map: this.mapObj,
      draggable: true
    });

    this.routeCreateMarker.addListener('dragend', function (e: any) {
      parent.reverseGeocodeAddress(new google.maps.LatLng(e.latLng.lat(), e.latLng.lng()))
    });
    this.mapObj.panTo(mLatLng);
    this.bindMapClickEvt();
  }

  bindMapClickEvt() {
    let parent: any = this;
    this.mapObj.addListener('click', function (e: any) {
      var clickedLatLng = new google.maps.LatLng(e.latLng.lat(), e.latLng.lng());
      parent.routeCreateMarker.setPosition(clickedLatLng);
      google.maps.event.trigger(parent.routeCreateMarker, 'dragend', e);
    });
  }

  unbindMapClickEvt() {
    google.maps.event.clearListeners(this.mapObj, 'click');
  }

  geocodeAddress() {
    let address = this.inputRouteStartLocation;
    //geocoding using custom service.
    var config = {
      'address': address
    };
    this.decodeAddress.geocode(config).then(response => {
      this.routeCreateMarker.setPosition(response['geometry'].location);
      this.mapObj.panTo(response['geometry'].location);
    }).catch(error => { });
  }

  reverseGeocodeAddress($event: any) {
    let geocoder = new google.maps.Geocoder();
    let address = $event;
    let parent: any = this;
    //reverse geocoding using custom service.
    var config = {
      'latLng': $event
    }
    this.decodeAddress.geocode(config).then(response => {
      this.inputRouteStartLocation = response['formatted_address'];
      $('#locationField').focus();
      $('#locationField').blur();
    }).catch(error => {
      console.log('Error - ', error);
    });
  }

  store() {
    this.tagList.push(this.tag);
    this.tag = '';
  }
  //function to remove the contact information from temporary array
  removeTag(i: number) {
    this.tagList.splice(i, 1);
  }

  hideAddRoute(event: any) {

    let navigationFrom = this.storage.get('navigationFrom');
    if (navigationFrom != 'editStudent') {
      this.storage.set({
        'sudentID': '',
        'studentName': '',
        'newRouteName': ''
      });
    }


    this.addForm = false;
    this.routeCreateMarker.setMap(null);
    this.hideAddRouteForm.emit(false);
    this.unbindMapClickEvt();
  }


  validateTime(time: string) {

    let isValid = /^([0-1]?[0-9]|2[0-4]):([0-5][0-9])?$/.test(time);
    return isValid;
  }


  getAvailableVehicles() {
    let isValid: boolean = true;

    if (!(this.validateTime(this.routeStartTime))) {
      this.showRouteStartTimeError = true;
      isValid = false;
    } else {
      this.showRouteStartTimeError = false;
    }

    if (!(this.validateTime(this.routeEndTime))) {
      this.showRouteEndTimeError = true;
      isValid = false;
    } else {
      this.showRouteEndTimeError = false;
    }

    if (isValid) {

      let parent: any;
      if (((this.routeStartTime) && (this.routeStartTime))) {

        var apiHeader = {
          data: {
            key: localStorage.getItem('scbToken'),
            filter: {
              routeStartTime: this.routeStartTime,
              routeEndTime: this.routeEndTime
            }
          }
        }
        this.apiService.getOrgVehicles(apiHeader).then(response => {
          this.vehAvailable = response.response;

          if(response.response && response.response.length>0){
            response.response.map((item:any)=>{
              if(item.deviceId) this.availableVehObj[item.deviceId] = item;
            })
          }

        }).catch(error => { });
      }
    }

  }


  //function to create route object for emission.
  createRouteObj() {
    if (this.validateUserInputs()) {

      this.storage.set({
        'newRouteName': this.routeName,
        'navigationFrom': ''
      });


      var data = {
        data: {
          key: localStorage.getItem('scbToken'),
          form: {
            routeName: this.routeName,
            // driverId: this.driverId,
            routeStartTime: this.routeStartTime,
            routeEndTime: this.routeEndTime,
            routeStatus: 'onTrip',
            routeType: this.routeType,
            routeAddress: this.inputRouteStartLocation,
            routeStartLocation: {
              lat: this.routeCreateMarker.getPosition().lat(),
              lng: this.routeCreateMarker.getPosition().lng()
            },
            tags: this.tagList,
            vehId: this.vehicleId
          }
        }
      };

      // console.log(data);
      this.routeCreateMarker.setMap(null);
      this.hideAddRouteForm.emit(true);
      this.createRoute.emit(data);
    }
  }

  validateUserInputs() {
    let isValid: boolean = true;
    if (!this.routeName) {
      this.showRouteNameError = true;
      isValid = false;
    } else {
      this.showRouteNameError = false;
    }

    if (!this.routeType) {
      this.showRouteTypeError = true;
      isValid = false;
    } else {
      this.showRouteTypeError = false;
    }

    // if (!this.driverId) {
    //   this.showRouteDriverError = true;
    //   isValid = false
    // } else {
    //   this.showRouteDriverError = false;
    // }

    if (!(this.validateTime(this.routeStartTime))) {
      this.showRouteStartTimeError = true;
      isValid = false;
    } else {
      this.showRouteStartTimeError = false;
    }

    if (!(this.validateTime(this.routeEndTime))) {
      this.showRouteEndTimeError = true;
      isValid = false;
    } else {
      this.showRouteEndTimeError = false;
    }

    if (!this.inputRouteStartLocation) {
      this.showRouteLocationError = true;
      isValid = false;
    } else {
      this.showRouteLocationError = false;
    }

    if (!this.tagList.length) {
      this.showRouteTagError = true;
      isValid = false
    } else {
      this.showRouteTagError = false;
    }
    //
    // if(!this.vehicleId){
    //   this.showRouteVehicleError = true;
    //   isValid = false;
    // }else{
    //   this.showRouteVehicleError = false;
    // }
    return isValid;
  }

  processOrgVehicles(routeVehicles: any) {
    this.orgVehicles = routeVehicles;
  }
}

/*
Notes : npm install node-sass -g
*/
