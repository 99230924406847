import { colorLine } from './lineFile.config';
import {  MapBasedAnalyticComponent } from './map-based-analytic.component';
import { LatLong } from './map.interface';
import { MapService } from './map.service';
import { mapStyle } from './mapStyle';
import { ReasonSummaryPipe } from './reason-summary.pipe';

export const mapAnalyticComponentConfig : any =[
    ReasonSummaryPipe,MapBasedAnalyticComponent];

export const mapAnalyticServiceConfig : any =[MapService];

// export const mapAnalyticServiceConfig : any =[MapService];

