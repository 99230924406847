import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ApiService } from './../../../services/ApiService';
import { bootstrapDateTime } from './../../../services/bootstrapDateTime/datePicker';
import { StorageService } from './../../../services/storage.service';
import { COM } from './../../../services/com.services';
import { ApiCallDateFormatService } from "../../../services/api-call-date-format.service";
import { array_chunk } from './../../../utilities/common';
declare const Highcharts: any;

@Component({
    providers: [bootstrapDateTime],
    selector: 'routewise-rfid-count-check',
    template: `
    <div *ngIf="rfidStatusReports && loadCompo=== true">
        <div class="col-md-12" >
        <div class="col-md-6" ></div>

        </div><br><br>
        

        <div class="col-md-6 pull-right">
        <button class="btn btn-secondary calenderview pull-right" style="border-top: 3px solid #0095b9;padding-top: 7px;" type="button" (click)="viewTable()" *ngIf="!showhide && totalHide"><i aria-hidden="true" class="fa fa-table"></i>&nbsp;View on Table</button>
        <button class="btn btn-secondary calenderview pull-right" style="border-top: 3px solid #0095b9;padding-top: 7px;" type="button" (click)="viewChart()" *ngIf="showhide && totalHide"><i aria-hidden="true" class="fa fa-bar-chart"></i>&nbsp;View on Chart</button>
    </div>


        <!-- <table class="table-bordered tablelike text-center"  style="padding-top: 10px;">
        



                    <tr>
                    <td>
                         Total  Count :
                   <ng-container *ngIf="rfidCntBoolean == false">
                            
                   <i class="fa fa-spinner fa-pulse fa-fw"></i>
                   <span class="sr-only">Loading...</span>
                   </ng-container>
                   <ng-container *ngIf="rfidCntBoolean == true">
                   {{totalRfidCount-walkersTotalCount}}
                   </ng-container>
                    </td>
                    <td>
                         Sub Total  Count :
                   <ng-container *ngIf="rfidCntBoolean == false">
                            
                   <i class="fa fa-spinner fa-pulse fa-fw"></i>
                   <span class="sr-only">Loading...</span>
                   </ng-container>
                   <ng-container *ngIf="rfidCntBoolean == true">
                   {{totalRfidCount}}
                   </ng-container>
                    </td>
                    <td>
                    Swiped Count :
                    <ng-container *ngIf="rfidCntBoolean == false">
                             
                    <i class="fa fa-spinner fa-pulse fa-fw"></i>
                    <span class="sr-only">Loading...</span>
                          
                    </ng-container>
                    <ng-container *ngIf="rfidCntBoolean == true">
                    {{this.totalRfidSwipedCount}}
                    </ng-container>
                    </td>
                    <td>
                            Not Swiped count :
                    <ng-container *ngIf="rfidCntBoolean == false">
                             
                    <i class="fa fa-spinner fa-pulse fa-fw"></i>
                    <span class="sr-only">Loading...</span>
                    </ng-container>
                    <ng-container *ngIf="rfidCntBoolean == true">
                    {{this.totalRfidNotSwipedCount}}
                    </ng-container>
                    
                    </td>

                    </tr>
                    </table>
         -->
        <br> 

   <h4> <b>Summary</b></h4>





<table style=" width: 100%;border-collapse: collapse;">
  
   <th style="border: 0px;"></th>
    <th style="border: 0px;">Swiped Count</th>
    <th style="border: 0px;">Not Swiped Count</th>
  
  <tr>
    <td class="table-text-left">No. of Bus Users :
    <ng-container *ngIf="rfidCntBoolean == false">
                            
                            <i class="fa fa-spinner fa-pulse fa-fw"></i>
                            <span class="sr-only">Loading...</span>
                            </ng-container>
                            <ng-container *ngIf="rfidCntBoolean == true">
                            {{totalRfidCount}}
                            </ng-container>
    </td>
    <td>  <ng-container *ngIf="rfidCntBoolean == false">
                             
                             <i class="fa fa-spinner fa-pulse fa-fw"></i>
                             <span class="sr-only">Loading...</span>
                                   
                             </ng-container>
                             <ng-container *ngIf="rfidCntBoolean == true">
                             {{this.totalRfidSwipedCount }}
                             </ng-container></td>
    <td>
    <ng-container *ngIf="rfidCntBoolean == false">
                             
                             <i class="fa fa-spinner fa-pulse fa-fw"></i>
                             <span class="sr-only">Loading...</span>
                             </ng-container>
                             <ng-container *ngIf="rfidCntBoolean == true">
                             {{this.totalRfidNotSwipedCount}}
                             </ng-container>
    </td>
  </tr>

  <tr>
    <td class="table-text-left">No. of Walkers :
    <ng-container *ngIf="rfidCntBoolean == false">
                            
                            <i class="fa fa-spinner fa-pulse fa-fw"></i>
                            <span class="sr-only">Loading...</span>
                            </ng-container>
                            <ng-container *ngIf="rfidCntBoolean == true">
                            {{walkersTotalCount}}
                            </ng-container>
    </td>
    <td><ng-container *ngIf="rfidCntBoolean == false">
                             
                             <i class="fa fa-spinner fa-pulse fa-fw"></i>
                             <span class="sr-only">Loading...</span>
                                   
                             </ng-container>
                             <ng-container *ngIf="rfidCntBoolean == true">
                             {{walkersSwipeCount}}
                             </ng-container></td>
    <td> <ng-container *ngIf="rfidCntBoolean == false">
                             
                             <i class="fa fa-spinner fa-pulse fa-fw"></i>
                             <span class="sr-only">Loading...</span>
                             </ng-container>
                             <ng-container *ngIf="rfidCntBoolean == true">
                             {{walkersNotSwipeCount}}
                             </ng-container></td>
  </tr>
  <tr>
  <td class="table-text-left">Total No. of Students : 
  <ng-container *ngIf="rfidCntBoolean == false">
                            
                            <i class="fa fa-spinner fa-pulse fa-fw"></i>
                            <span class="sr-only">Loading...</span>
                            </ng-container>
                            <ng-container *ngIf="rfidCntBoolean == true">
                            {{totalRfidCount + walkersTotalCount}}
                            </ng-container>
  </td>
    <td><ng-container *ngIf="rfidCntBoolean == false">
                             
                             <i class="fa fa-spinner fa-pulse fa-fw"></i>
                             <span class="sr-only">Loading...</span>
                                   
                             </ng-container>
                             <ng-container *ngIf="rfidCntBoolean == true">
                             {{this.totalRfidSwipedCount + walkersSwipeCount }}
                             </ng-container></td>
    <td> <ng-container *ngIf="rfidCntBoolean == false">
                             
                             <i class="fa fa-spinner fa-pulse fa-fw"></i>
                             <span class="sr-only">Loading...</span>
                             </ng-container>
                             <ng-container *ngIf="rfidCntBoolean == true">
                             {{this.totalRfidNotSwipedCount + walkersNotSwipeCount}}
                             </ng-container></td>
  </tr>
  
</table>









<br>




        <ng-container *ngIf="renderChart">
<div class="col-md-12 ChartContainer" id="container" style="min-width: 510px;height: 600px; margin: 0 auto"></div>

</ng-container>
<h4><b>
    In details</b></h4>
<ng-container *ngIf="renderTable">
       <div class="table-content"  > 
            <table class=" table table-stripped table-bordered" cellpadding="0" cellspacing="0" border="0">            
            <thead>
                <tr>
                    <th class="text-center">#</th>
                    <th class="text-center">Route Name</th>
                    <th class="text-center">Total Count </th>
                    <th class="text-center"> Swiped Count </th> 
                    <th class="text-center"> Not Swiped Count </th> 

                   
                    </tr>
            </thead>
            <tbody>
                <ng-container *ngFor="let record of rfidStatusReports|objectkeys;let i = index;" >   
                     
                    <tr >
                        <td style="text-align:right" >{{ i+1 }}</td>
                        <td style="text-align:left">  
                            <span>{{ rfidStatusReports[record].routeName }}</span>
                        </td>
                        <td style="text-align:right">                   
                        <span>{{ rfidStatusReports[record].totalMember }}</span>
                       </td>
                       <td style="text-align:right">                   
                        <span>{{ rfidStatusReports[record].swipeCount }}</span>
                       </td>
                       <td style="text-align:right">                   
                       <span>{{ rfidStatusReports[record].totalMember - rfidStatusReports[record].swipeCount }}</span>
                      </td>
                       
                        
                </tr>
                </ng-container>
            </tbody>
            </table>
        </div>
        </ng-container>
    </div>
  
    <div class="" *ngIf="rfidStatusReports === 'null' && loadCompo=== true ">
    <h4 class="animated pulse text-center"  style="animation-duration: 1s;color:#999FBF; margin:70px;"><i class="fa fa-frown-o"></i>&nbsp;
                           No Data to Display</h4>
</div>


    <div class="loaderContainer" *ngIf="pageLoader">
   <loader></loader>
    </div>
    
    `,

    styles: [`
    

table td, table th {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: center;
}
.table-text-left{
    text-align: left !important;
    padding-left:1.5rem;
}
`
    ]
})

export class RouteWiseRFIDCountComponent {
    public walkersTotalCount = 0;
    public walkersSwipeCount = 0;
    public walkersNotSwipeCount = 0;
    public routeNameNull: any;
    public pageLoader: boolean;
    public rfidStatusReports: any = [];
    public rfidRouteDate: any;
    public selectedDaySection: any;
    public selectedRoute: any;
    public walkerVehId: any = [];
    public totalRfidCount: any;//krish
    public totalRfidSwipedCount: any;//krish
    public totalRfidNotSwipedCount: any;//krish
    public totalRfidCnt: any = [];//krish
    public totalRfidCntChart: any = [];//krish
    public allRouteNames: any = [];//krish
    public allRouteNamesChart: any = [];//krish
    public totalRfidSwipedCnt: any = [];//krish
    public totalRfidSwipedCntChart: any = [];//krish
    public totalRfidNotSwipedCnt: any = [];//krish
    public totalRfidNotSwipedCntChart: any = [];//krish
    public rfidCntBoolean: boolean;//krish
    public showhide: boolean;//krish
    public renderTable: boolean = true;//krish
    public renderChart: boolean = false;//krish
    public flagChart: boolean = false;//krish
    public currentDate: any;
    public loadCompo: boolean;
    public vehInfo: any = {};
    routewiseNotSwippedArr: any;
    routewiseSwippedArr: any;
    totCntroutewiseArr: any;
    routesArr: any;
    totalHide: boolean = false;
    selectedDaySession: any;
    public enableDisableRFIDRouteWiseSubmitBtn: boolean = false;
    constructor(
        private api: ApiService,
        public dateChange: ApiCallDateFormatService,
        private storage: StorageService,
        private com: COM
    ) {
        this.totalHide = false;

        this.com.comAction.subscribe((e: any) => {
            this.pageLoader = true;
            this.loadCompo = false;
            this.routesArr = [];
            this.renderTable = true;
            this.renderChart = false;
            this.totalRfidCount = [];//krish
            this.totalRfidSwipedCount = [];//krish
            this.totalRfidNotSwipedCount = [];//krish
            this.totalRfidCnt = [];//krish
            this.totalRfidCntChart = [];//krish
            this.allRouteNames = [];//krish
            this.allRouteNamesChart = [];//krish
            this.totalRfidSwipedCnt = [];//krish
            this.totalRfidSwipedCntChart = [];//krish
            this.totalRfidNotSwipedCnt = [];//krishh
            this.totalRfidNotSwipedCntChart = [];//krishh
            this.showhide = true;
            //this.showhide=false;//
            if (e.type === 'rfidstatuscheck') {



                this.loadCompo = true;
                this.loadData(e);
            }
        });
    }
    @Output() disable = new EventEmitter();
    loadData(obj: any) {
        this.selectedDaySession = obj.session;

        this.rfidStatusReports = [];
        let sTime: any;
        let eTime: any;
        let selectedRoute: any = [];
        let routeInfo = this.storage.get('AllRouteInfo');
        let selectedVehInfo = [];
        const startDate = obj.sdate;
        const currentDate = obj.cdate;
        const session = obj.session;
        const route = obj.route;
        const routeIDE = obj.routeID;
        let routeData: any = {};

        this.vehInfo = obj.vehInfo;
        let routeType = obj.routeType;
        this.walkerVehId = obj.walkerVehId;

        this.rfidCntBoolean = false;
        this.flagChart = false;
        (Object.keys(routeInfo)).forEach((routeId) => {



            // if (routeData[routeInfo[routeId].vehId] === undefined) {
            //     routeData[routeInfo[routeId].vehId] = [];
            // }
            let routeArr = routeInfo[routeId].tags.map((caseConversion: any) => caseConversion.toLowerCase());
            //if (session == 'fn' && routeInfo[routeId].tags[0] == 'Morning') {
            if (session == 'fn' && routeArr.includes('morning')) {
                sTime = '06:00:00';
                eTime = '11:00:00';

                //    if(routeId=='oWdaZMJIkgUAeBsmPRHx0178961530373208'){ //WALKER ROUTE NOT ADDED BY KRISH

                //    }else{ 

                selectedRoute.push(routeId);
                //}

                //     routeData[routeInfo[routeId].vehId].push({
                //         routeName: routeInfo[routeId].routeName,
                //         vehNo: routeInfo[routeId].vehNo
                //  });


                //} else if (session == 'an' && routeInfo[routeId].tags[0] == 'Afternoon') {
            } else if (session == 'an' && routeArr.includes('afternoon')) {
                sTime = '11:00:01';
                eTime = '14:00:00';


                // if(routeId=='GDztOEhxCrUsVHdeJgvX5278601530374848'){ //WALKER ROUTE NOT ADDED BY KRISH

                //    }else{ 

                selectedRoute.push(routeId);
                // }


                //     routeData[routeInfo[routeId].vehId].push({
                //         routeName: routeInfo[routeId].routeName,
                //         vehNo: routeInfo[routeId].vehNo
                //     });
            } else if (session == 'en' && routeArr.includes('evening')) {
                //} else if (session == 'en' && routeInfo[routeId].tags[0] == 'Evening') {

                sTime = '14:00:01';
                eTime = '21:00:00';
                // if(routeId=='VqIBnHpzcoAdxQhEjwvZ0489561530376615'){ //WALKER ROUTE NOT ADDED BY KRISH

                // }else{ 

                selectedRoute.push(routeId);
                // }

                //     routeData[routeInfo[routeId].vehId].push({
                //         routeName: routeInfo[routeId].routeName,
                //         vehNo: routeInfo[routeId].vehNo
                //     });
            }
        });

        this.rfidRouteDate = routeData;

        let vehIds: any = [];

        if (route || routeType) {

            if (routeType === 'Walkers_M' || routeType === 'Walkers_N' || routeType === 'Walkers_A') {
                //  vehIds.push(this.selectedRoute);

                this.flagChart = true;
                vehIds.push(routeIDE);
                selectedVehInfo = vehIds;
            } else {
                // vehIds.push(this.selectedRoute);

                vehIds.push(routeIDE);
                selectedVehInfo = vehIds;
            }

        } else {

            vehIds = selectedRoute;
            selectedVehInfo = this.walkerVehId.concat(vehIds);
        }

        const chunkVehInfo = array_chunk(selectedVehInfo, 3, false);
        //

        let inc = 1;

        setTimeout(() => {
            (async () => {
                for (let i = 0; i < chunkVehInfo.length; i++) {


                    const requestVeh = chunkVehInfo[i];

                    const res = await this.API(requestVeh, startDate, sTime, eTime);

                    if (chunkVehInfo.length === inc) {
                        this.pageLoader = false;
                        this.disable.emit(false);
                    }
                    inc++;
                }
                //var aa = [1,2,3,4,5,6,7,8,9,10];

                this.totalRfidCntChart = [];
                this.totalRfidSwipedCntChart = []
                this.allRouteNamesChart = []
                this.totalRfidNotSwipedCntChart = []




                this.rfidStatusReports = this.rfidStatusReports.sort((a: any, b: any) => {
                    let init = parseInt(a.routeName);
                    let next = parseInt(b.routeName);
                    return init - next;
                });

                this.rfidStatusReports.filter((item: any) => {
                    if (!item.routeName.toLowerCase().includes('walk')) {
                        this.allRouteNamesChart.push(item.routeName);
                        this.totalRfidCntChart.push(item.totalMember);
                        this.totalRfidSwipedCntChart.push(item.swipeCount);
                        let n = +item.totalMember - (+item.swipeCount);
                        this.totalRfidNotSwipedCntChart.push(n);
                    }
                })



                this.totalRfidCount = this.totalRfidCntChart.reduce((a: number, c: number) => a + c)
                this.totalRfidSwipedCount = this.totalRfidSwipedCntChart.reduce((a: number, c: number) => a + c)
                this.totalRfidNotSwipedCount = this.totalRfidNotSwipedCntChart.reduce((a: number, c: number) => a + c)



                this.rfidCntBoolean = true;

                this.showhide = true;
                this.totalHide = true;


            })();
        }, 100);

    }


    strMatch(arg1: string, arg2: string) {
        return arg1.toLowerCase().includes(arg2.toLowerCase());
    }



    API(vehInfo: any, date: any, sTime: any, eTime: any) {

        this.totalHide = false;

        let apiDate = {
            data: {
                key: localStorage.getItem('scbToken'),
                form: {
                    fromDate: date,
                    fromTime: sTime,
                    toDate: date,
                    toTime: eTime,
                    routeId: vehInfo
                }
            }
        }

        return new Promise((resolve, reject) => {

            this.api.getRFIDRoutewiseCount(apiDate).then((status) => {

                if (status.status === 'success') {
                    let info: any = [];
                    let responce = status.response;

                    Object.keys(responce).forEach((row) => {

                        Object.keys(responce[row]).forEach((item, index) => {


                            if (responce[row][item].routeName) {

                                if (this.strMatch(responce[row][item].routeName, 'walkers') == true) {
                                    this.walkersTotalCount = responce[row][item].totalMember;
                                    this.walkersSwipeCount = responce[row][item].swipeCount;
                                    this.walkersNotSwipeCount = this.walkersTotalCount - this.walkersSwipeCount



                                }

                                let obj = {

                                    routeName: responce[row][item].routeName,
                                    totalMember: responce[row][item].totalMember,
                                    swipeCount: responce[row][item].swipeCount,



                                }

                                info.push(obj);
                            }
                        })



                    });

                    this.rfidStatusReports = [...this.rfidStatusReports, ...info];

                    resolve(this.rfidStatusReports);
                }

            });
        });
    }


    higchart(totalRfidCnt: any, totalRfidSwipedCnt: any, allRouteNames: any, totalRfidNotSwipedCnt: any) {

        // Highcharts.chart('container1', { //start chart
        //     chart: {
        //         type: 'column'
        //     },
        //     title: {
        //         text: 'RFID ROUTEWISE COUNT'
        //     },
        //      subtitle: {
        //         text: 'Total count : '+this.totalRfidCount+' , Swiped Count : '+this.totalRfidSwipedCount+' , Not Swiped Count : '+this.totalRfidNotSwipedCount

        //     },
        //     xAxis: {
        //         categories:this.allRouteNames,
        //     },
        // yAxis: [{
        //     min: 0,
        //     title: {
        //         text: 'STUDENT COUNTS'
        //     }
        // }],
        // legend: {
        //     shadow: false
        // },
        // tooltip: {
        //     shared: true
        // },
        // plotOptions: {
        //     column: {
        //         grouping: false,
        //         shadow: false,
        //         borderWidth: 0
        //     }
        // },
        // series: [//{
        //     /// name: 'Total count',
        //     // color: 'rgba(165,170,217,1)',
        //     // data:totalRfidCnt,
        //     // pointPadding: 0.3,
        //     // pointPlacement: -0.1
        // {
        //     name: 'Swiped',
        //     color: 'rgba(46,139,87)',
        //     data:totalRfidSwipedCnt,
        //     pointPadding: 0.3,
        //     pointPlacement: -0.1
        // }, {
        //      name: 'Not Swiped',
        //     color: 'rgba(232, 126, 4, 1)',
        //      data:totalRfidNotSwipedCnt, 
        //      pointPadding: 0.4,
        //      pointPlacement: -0.1,

        // } 
        // ]
        // })  //end chart

        Highcharts.chart('container', {
            chart: {
                type: 'column'
            },
            title: {
                text: 'RFID ROUTEWISE COUNT'
            },
            subtitle: {
                text: 'For ' + this.sessiondeAbbrevated(this.selectedDaySession) + ' routes, Total count : ' + this.totalRfidCount + ' , Swiped Count : ' + this.totalRfidSwipedCount + ' , Not Swiped Count : ' + this.totalRfidNotSwipedCount

            },
            xAxis: {
                categories: this.allRouteNamesChart,
            },
            yAxis: {
                min: 0,
                title: {
                    text: 'STUDENT COUNTS'
                },
                stackLabels: {
                    enabled: true,
                    style: {
                        fontWeight: 'bold',
                        color: ( // theme
                            Highcharts.defaultOptions.title.style &&
                            Highcharts.defaultOptions.title.style.color
                        ) || 'gray'
                    }
                }
            },
            legend: {
                align: 'left',
                x: 0,
                verticalAlign: 'top',
                y: 25,
                floating: false,
                backgroundColor:
                    Highcharts.defaultOptions.legend.backgroundColor || 'white',
                borderColor: '#CCC',
                borderWidth: 1,
                shadow: false
            },
            tooltip: {
                headerFormat: '<b>{point.x}</b><br/>',
                pointFormat: '{series.name}: {point.y}<br/>Total: {point.stackTotal}'
            },
            plotOptions: {
                column: {
                    stacking: 'normal',
                    dataLabels: {
                        enabled: true
                    }
                }
            },
            series: [{
                name: 'Swiped',
                color: '#64C7E9',
                data: totalRfidSwipedCnt

            }, {
                name: 'Not Swiped',
                color: '#95a5a6',
                data: totalRfidNotSwipedCnt
            }]
        });
    }

    viewTable(): void {
        this.showhide = true;
        this.renderChart = false;
        this.renderTable = true;

    }

    viewChart(): void {
        this.showhide = false;
        this.renderChart = true;
        this.renderTable = false;
        setTimeout(() => {
            this.higchart(this.totalRfidCntChart, this.totalRfidSwipedCntChart, this.allRouteNamesChart, this.totalRfidNotSwipedCntChart);
        }, 300);
    }

    sessiondeAbbrevated(str: string) {
        if (str === 'fn') return 'Morning'
        if (str === 'an') return 'Afternoon'
        if (str === 'en') return 'Evening'
    }
    subFunction(a: number, b: number) {
        return a - b;
    }


    reSet() {
        this.routesArr = [];
        this.renderTable = true;
        this.renderChart = false;
        this.totalRfidCount = [];//krish
        this.totalRfidCount = [];//krish
        this.totalRfidSwipedCount = [];//krish
        this.totalRfidNotSwipedCount = [];//krish
        this.totalRfidCnt = [];//krish
        this.totalRfidCntChart = [];//krish
        this.allRouteNames = [];//krish
        this.allRouteNamesChart = [];//krish
        this.totalRfidSwipedCnt = [];//krish
        this.totalRfidSwipedCntChart = [];//krish
        this.totalRfidNotSwipedCnt = [];//krish
        this.totalRfidNotSwipedCntChart = [];//krish
        // this.rfidCntBoolean: boolean;//krish
        // this.showhide:boolean;//krish
        // this.renderTable:boolean=true;//krish
        //// this.renderChart:boolean=false;//krish
    }
}