/**
 * @Author: Deepak Pookkote <group10>
 * @Date:   2018-04-24T11:51:53+05:30
 * @Email:  deepak@groupten.com
 * @Project: School-Bus
 * @Filename: createTemplate.dir.ts
 * @Last modified by:   group10
 * @Last modified time: 2018-05-08T14:17:02+05:30
 * @Copyright: Group10 Technologies
 */



import { Component, Input, Output, EventEmitter } from '@angular/core';
import { ApiService } from './../../../services/ApiService';
import { GlobalUtils } from './../../../services/global-utils';

declare var swal:any;

@Component({
  selector:'create-template',
  template:`
    <section class="createTemplateSection">
      <div class="panel panel-default">
        <div class="panel-body">
          <div class="row">
            <div class="form-group col-md-6">
              <label class="control-label">Template Title<sup>*</sup></label>
              <input maxlength="30" class="custom-form-control" type="text" [(ngModel)]='templateTitle'>
              <span class="text-danger" *ngIf="templateTitleError">Add Template Title</span>
            </div>
            <div class="form-group col-md-6">
              <label class="control-label">Template Message<sup>*</sup></label>
              <textarea class="form-control" rows=4 [(ngModel)]='templateMessage'></textarea>
              <span class="text-danger" *ngIf="templateContentError">Add Message</span>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12">
                <button [disabled]="this.templateTitle==null"
                  class="webform-submit button-primary btn btn-primary form-submit pull-right"
                   style="color:#fff !important;margin-right:15px;"  (click)="createCampaignTemplate()">
                   <i class="fa fa-floppy-o mr5" aria-hidden="true"></i>Save
                 </button>
            </div>
          </div>
        </div>
      </div>

    </section>
  `,
  styles:[`
      .createTemplateSection{
        margin-top:51px;
      }
      .border-gray{
          border: solid 1px #d0d0d0;
      }
    `],
})

export class createTemplateSectionDir {
  public templateTitle:any;
  public templateMessage:any;
  public templateContentError:boolean;
  public templateTitleError:boolean;

    constructor(private apiService: ApiService,private globalUtils:GlobalUtils) {
    }

    createCampaignTemplate = ():void =>{
      if(this.validateUserInputs()){
        let apiHeader = {
            data: {
                key: localStorage.getItem('scbToken'),
            form: {
                    templateTitle: this.templateTitle,
                    templateMessage: this.templateMessage
                  }
            }
        }
        this.apiService.createCampaignTemplate(apiHeader).then(response => {
          let result = response;
          if(result.status=="success"){
            swal("Success", "Campagin Added Successfully", "success").done();
            this.templateTitle = null;
            this.templateMessage = null;
          }else{
            swal("Failure",this.globalUtils.getErrorMsg(response.ec), "error").done();
          }
        }).catch(error => {})
      }
    }

    validateUserInputs=():boolean=> {
      let isValid: boolean = true;
      if (!this.templateTitle) {
        this.templateTitleError = true;
        isValid = false;
      } else {
        this.templateContentError = false;
      }

      if (!this.templateMessage) {
        this.templateContentError = true;
        isValid = false;
      } else {
        this.templateContentError = false;
      }
      return isValid;
    }







}
