import { UnixTimeStampFormatter } from './custom-pipes/unixTimestamp-formatter';
import { SecondsFormatter } from './custom-pipes/secondsToHhMmFormatter';
import { FilterPipe } from './custom-pipes/filter.pipe';
import { Objectkeys } from './custom-pipes/objectkeys.pipe';
import { AttendenceFilter } from './custom-pipes/addendenceFillter.pipe';
import { StringReplacer } from './custom-pipes/stringreplace.pipe';
import { NextedObjCount } from './custom-pipes/nextedObjCount.pipe';
import { FilterGate } from './custom-pipes/filterquery.pipe';
import { DateTime } from './custom-pipes/dateTime.pipe';
import { VehStatus } from './pages/routelistview/list-view.pipe';
import { SessionPipe } from './pages/routelistview/session.pipe';

import { FilterBusOrGate } from './custom-pipes/filterBusOrGate.pipe';

import { StringSortPipe } from './custom-pipes/stringSort.pipe';
import { RouteTypeFilterPipe } from './custom-pipes/routeTypeFilter.pipe';
import { DatestringtounixPipe } from './custom-pipes/datestringtounix.pipe';
import { TagFilters } from './custom-pipes/filterTagInput.pipe';

import { SafePipe, sanitizeHtmlPipe } from './custom-pipes/safe.pipe';
import { LetterAvatarPipe } from './custom-pipes/letterAvatar.pipe';
import { ArrayFilterPipe, ArrayRemoveDuplicatePipe } from './custom-pipes/arrayFilter.pipe';


export const PipeDeclarations: any = [UnixTimeStampFormatter, SecondsFormatter,
    FilterPipe, SessionPipe, Objectkeys, AttendenceFilter, StringReplacer, FilterGate, DateTime, VehStatus, FilterBusOrGate, TagFilters,
    NextedObjCount, StringSortPipe, RouteTypeFilterPipe, DatestringtounixPipe, SafePipe, sanitizeHtmlPipe,LetterAvatarPipe,ArrayFilterPipe,ArrayRemoveDuplicatePipe
];
