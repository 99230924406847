
import {
  Component, ElementRef, AfterViewInit,
  OnDestroy, ViewChild, Input, Output, NgZone, HostListener
} from '@angular/core';
import { RouterModule, Routes, Router } from '@angular/router';
import { AppComponent } from './../../app.component';
import { globalService } from './../../services/global.service';
import { ApiService } from './../../services/ApiService';
import { authGaurd } from './../../services/auth-guard.service';
declare var $: any;
@Component({
  templateUrl: './school-campaign.html',
  styles: [ `

    .campaign-filter-list  li{
      border:none;
      padding-left:4px;
    }
    .campaignInfoSection{

    }
    .campaign-filter-list{
      font-size: 16px;
      line-height: 24px;
      outline: 0;
    }
    .campaigner-sidebar-link{
      color: black;
      display: block;
    }
    .campaigner-sidebar-link:hover{
      text-decoration:none;
    }
    .campaign-filter-list li:hover{
      background-color:#f9f9f9;
    }
    .tab-active{
      border-top: 3px solid #0095b9;
      padding-top: 7px;
    }
    .no-outline{
        outline:none;
    }

    /*search box CSS starts */
    .search-box {
     display: inline-block;
     width: 100%;
     border-radius: 3px;
     padding: 4px 55px 4px 15px;
     position: relative;
     background: #fff;
     border: 1px solid #ddd;
     -webkit-transition: all 200ms ease-in-out;
     -moz-transition: all 200ms ease-in-out;
     transition: all 200ms ease-in-out;
    }
    .search-box.hovered, .search-box:hover, .search-box:active {
     border: 1px solid #aaa;
    }
    .search-box input[type=text] {
     border: none;
     box-shadow: none;
     display: inline-block;
     padding: 0;
     background: transparent;
    }
    .search-box input[type=text]:hover, .search-box input[type=text]:focus, .search-box input[type=text]:active {
     box-shadow: none;
    }
    .search-box .search-btn {
     position: absolute;
     right: 2px;
     top: 2px;
     color: #aaa;
     border-radius: 3px;
     font-size: 21px;
     padding: 5px 10px 1px;
     -webkit-transition: all 200ms ease-in-out;
     -moz-transition: all 200ms ease-in-out;
     transition: all 200ms ease-in-out;
    }
    .search-box .search-btn:hover {
     color: #fff;
     background-color: #8FBE00;
    }

    /*search box CSS ends */

    .panel,.panel-heading{
      border:none;
      background:#fff;
    }
    .campaign-list-panel-body{
      height: 353px;
    }
    .campaign-list{
      overflow-y: auto;

    }
    .campaign-list li{
      cursor:pointer;
    }
     .campaign-searchbar-container{
       max-width:460px
     }
     .campaign-sort-label{
       margin-top:4%;
     }
     .campaign-sort-options{

     }
     .campaign-title{
       font-size:17px;
     }
     .campaign-list-symbol{
       padding-top: 0.3%;
     }
     .campaign-purpose , .campaign-member-wrapper , .campaign-last-edit,.campaign-member-message-sent{
          margin: 1% 0 1% 0;
     }
     .campaign-purpose{
       font-size: 14px;
       color: #484848;
     }
     .campaign-last-edit{
       font-size: 13px;
       color: #737373;
     }

     @media (max-width:1367px){
       .campaign-basic-info{
         margin-left:-5%;
       }
     }


     ul.orgCampaignList > li{
         padding: 4%;
         cursor:pointer;
         border-radius:0;
     }
     .campaignSectionHeading{
         font-size:16px;
         color:black;
     }
     .pl12{padding-left:14px;}
     .heading-border{
         height: 1px;border: 1px solid #64c7e9;width: 3em;
     }
     .wrapperSectionList{border-right: 1px solid #808080b3;height:425px;}
     li.selected{border-left:3px solid #2196F3}

     .panel-body {
       padding: 0px;
     }

     i.ShowingFilter{
       color:#8BC34A !important;
     }
     .partitionHeading{
        font-size: 1.6em;
        font-weight: 200;
     }
     .reportExcelDownloadLink{
        text-decoration: none;
        cursor: pointer;
      }

    `]
})

export class schoolCampaignComponent{
  
  
  
  @HostListener('click', ['$event']) onClick(e: any) {
    var targetEle = e.target.id;
    targetEle = targetEle.split('-');
   
    if (targetEle[0] == "filterHolidayDate") {
     
     
        
         setTimeout(() => {
          $('#filterHolidayDate').datetimepicker({
            // format: 'Y-m-d',
            format: 'Y-m-d',

            closeOnDateSelect: true,
            timepicker: false
          });
         }, 200)

       
     
    }
  }
  public eventActionControl: any = {};
  public showCreateCampaignForm:boolean = false;
  public filterDateBoolean:boolean = false;
  public showAvailableTemplateForm:boolean = false;
  public showEditTemplateDir:boolean;
  public showCampaignHistorySection:boolean = true;
  private editTemplateId:any;
  private orgCampaigns:any = [];
  private sortByFilter:string;
  private filterObj:any = {};
  private clickedCampaignObj = {};
  public showcreateTemplateForm:boolean = false;
  public showCampaignContent:boolean = false;
  public downloadCampaignDetails:string;
  public isLoading: boolean ;
  /**
   * filter utils starts here.
   */
  showFilterOptions:boolean = false;
  globalFilterObj:any = {};
  filterCampaignName:string;
  filterRouteName:string;
  filterPickupName:string;
  filterViaMessage:string;
  filterCampaignType:string;
   filterDate: string;
  /**
   * filter utils ends here.
   */

  constructor(private apiService:ApiService,public authGaurd:authGaurd){
    this.downloadCampaignDetails = null;
    
  }

  ngOnInit(){
      this.getOrgCampaigns();
      this.authGaurd.checkToken();
  }

  getOrgCampaigns = ():void => {
      this.isLoading = true;
      
      let apiHeader:any={};
      if(this.filterDateBoolean){ 
        apiHeader = {
          data:{
              key:localStorage.getItem('scbToken'),
              form:{ 
                fromDate:this.filterDate,
                toDate:this.filterDate,
                 fromTime:'00:00:01',
                 toTime:'23:59:59',
               },
            extra:{
              logTimeMS :-1
            }
          }
      }
      }else{
         apiHeader = {
            data:{
                key:localStorage.getItem('scbToken'),
                extra:{
                logTimeMS :-1
              }
            }
        }
      }
      
        if(Object.keys(this.globalFilterObj).length) apiHeader.data['filter'] = this.globalFilterObj;
        this.orgCampaigns = [];
        this.showCampaignContent = false;
        this.apiService.viewOrgCampaigns(apiHeader).then(response => {
          const res = response.response;
            //this.orgCampaigns = response.response;
            if(res) {
            //  console.log('response.response1',response.response);
              Object.keys(res).forEach( (key:any) => {
                let campaignInfo = res[key];
                // console.log('campVal',res[key]); 
                    let obj = {
                  campaignId:campaignInfo.campaignID,
                  campaignTitle:campaignInfo.messageTitle,
                  campaignDate:campaignInfo.logTimeMS,
                  campaignType:campaignInfo.messageType
                }
                this.orgCampaigns.push(obj);
              } );
              // for(let campaignId in response.response){
              //  // let campaignInfo = response.response[campaignId];
              //  let campaignInfo = response.response;
               
              //   let obj = {
              //     campaignId:campaignId,
              //     campaignTitle:campaignInfo.messageTitle,
              //     campaignDate:campaignInfo.logTimeMS,
              //     campaignType:campaignInfo.messageType
              //   }
              //   this.orgCampaigns.push(obj);
              // }
             // this.orgCampaigns = Object.assign([],response.response);
              
             //this.orgCampaigns = this.orgCampaigns.map(function(index:any) {index.bcmsDateTime = index.bcmsDateTime.split(" ")[0]; return index})
             
            //  console.log('orgCampaigns',this.orgCampaigns,this.orgCampaigns[0].campaignId); 
             this.getCampaignDetails(0,this.orgCampaigns[0].campaignId); //fetching first campaign details on load.
            }

            this.isLoading = false;
            //console.log(this.isLoading)

        }).catch(error => {})
  }

  applyFilters = () => {
      if(this.sortByFilter)  {
          this.filterObj = {
              messageType:this.sortByFilter
          }
      }else {
          delete this.filterObj['messageType']
      }
      this.showCampaignContent = false;
      this.getOrgCampaigns();
  }

  filterByCampaignName = (searchInputs:any) => {
      if(searchInputs){
          this.filterObj = {
              messageTitle:searchInputs
          }
      }else{
          delete this.filterObj['messageTitle'];
      }
      this.showCampaignContent = false;
      this.getOrgCampaigns();
  };

  //Initialize all campaign history section.
  initAllCampaignSection = () => {
      
      setTimeout(()=>{    //<<<---    using ()=> syntax
        this.getOrgCampaigns();
        this.setContainerDisplayFlag({showCampaignHistorySection:true});
   }, 3000);
     
  }

  //Initialize create campaign section.
  initCreateCampaignSection = () => {
      this.setContainerDisplayFlag({showCreateCampaignForm:true});
  }

  //Initialize available template section.
   showAvailableTemplatesSection =() => {
    this.setContainerDisplayFlag({showAvailableTemplateForm:true});
  }

  triggerCreateTemplateFormDir =($event: any):void => {
    this.setContainerDisplayFlag({showcreateTemplateForm:true});
  }

  triggerEditTemplate = (param:any):void => {
      this.setContainerDisplayFlag({showEditTemplateDir:true});
  }

  hideCampaignEditDir = (param:boolean) => {
      this.setContainerDisplayFlag({showAvailableTemplateForm:true});
  }

  setContainerDisplayFlag = ({showCreateCampaignForm = false,showAvailableTemplateForm = false,showcreateTemplateForm = false,showCampaignHistorySection = false,showEditTemplateDir = false}) =>{
      this.showCreateCampaignForm = showCreateCampaignForm;
      this.showAvailableTemplateForm = showAvailableTemplateForm;
      this.showcreateTemplateForm = showcreateTemplateForm;
      this.showCampaignHistorySection = showCampaignHistorySection;
      this.showEditTemplateDir = showEditTemplateDir;
      
     //resetting global filter obj.
     this.resetGlobalFilter();
  };

  // getCampaignDetails(campaignId:any){
  //   let campaignDetails:any = [];
  //   let desiredObj = this.orgCampaigns.filter(function(index:any){return index.campaignId == campaignId})[0];
  //   this.clickedCampaignObj = Object.assign({},desiredObj);
  //   this.downloadCampaignDetails = this.apiService.base+`broadcasting/download_CampaignList&key=${localStorage.getItem('scbToken')}&campaignid=${campaignId}`;
  //   let apiHeader = {
  //     data:{
  //       key:localStorage.getItem('scbToken'),
  //       filter:{
  //         campaignID:campaignId
  //       }
  //     }
  //   }
  //   this.apiService.viewOrgCampaigns(apiHeader).then(response => {
  //     if(response.response){
  //       campaignDetails = response.response[campaignId];
  //       this.clickedCampaignObj = {
  //         campaignId:campaignId,
  //         campaignTitle:campaignDetails['messageTitle'],
  //         campaignType:campaignDetails['messageType'],
  //         campaignTemplate:campaignDetails['messages'],
  //         campaignCount:campaignDetails['campaignsCnt'],
  //         messagevia:campaignDetails['messagevia'],
  //       }
  //       this.showCampaignContent = true;
  //     }
  //   }).catch(error => {})
    
  // }
  getDateFilterData(value:any){ 
//console.log(value);
  }
  getCampaignDetails(campaignIndx:any,campaignId:any){
    let campaignDetails:any = [];
    let desiredObj = this.orgCampaigns[campaignIndx];
    // console.log('desiredObj',desiredObj);
   
    this.clickedCampaignObj = Object.assign({},desiredObj);

    // console.log('clickedCampaignObj1',this.clickedCampaignObj);
    this.downloadCampaignDetails = this.apiService.base+`broadcasting/download_CampaignList&key=${localStorage.getItem('scbToken')}&campaignid=${campaignId}`;
    let apiHeader = {
      data:{
        key:localStorage.getItem('scbToken'),
        filter:{
          campaignID:campaignId
        }
      }
    }
    this.apiService.viewOrgCampaigns(apiHeader).then(response => {
      if(response.response){
        campaignDetails = response.response[0];
        this.clickedCampaignObj = {
          campaignId:campaignDetails['campaignID'],
          campaignTitle:campaignDetails['messageTitle'],
          campaignType:campaignDetails['messageType'],
          campaignTemplate:campaignDetails['messages'],
          campaignCount:campaignDetails['campaignsCnt'],
          messagevia:campaignDetails['messagevia'],
        }
        this.showCampaignContent = true;
        // console.log('clickedCampaignObj2',this.clickedCampaignObj);
      }
    }).catch(error => {})
    
  }
  setFilterObj1( datevalue: any){ 
//console.log(datevalue);

  }
  setFilterObj(Datevalue:any):void{
    this.globalFilterObj = {};
  // console.log('eee ',Datevalue)
   this.filterDateBoolean=false;
   if(Datevalue){ 
    //  Reflect.set(this.globalFilterObj,'fromDate',Datevalue); 
    //  Reflect.set(this.globalFilterObj,'fromTime','00:00:01'); 
    //  Reflect.set(this.globalFilterObj,'toDate',Datevalue); 
    //  Reflect.set(this.globalFilterObj,'toTime','23:59:59'); 
    this.filterDate=Datevalue;
    this.filterDateBoolean=true;
  }
    if(this.filterCampaignName)  Reflect.set(this.globalFilterObj,'messageTitle',this.filterCampaignName);
    if(this.filterRouteName) Reflect.set(this.globalFilterObj,'routeName',this.filterRouteName);
    if(this.filterPickupName) Reflect.set(this.globalFilterObj,'pickupName',this.filterPickupName);
    if(this.filterViaMessage) Reflect.set(this.globalFilterObj,'messagevia',this.filterViaMessage);
    if(this.filterCampaignType) Reflect.set(this.globalFilterObj,'messageType',this.filterCampaignType);
    this.getOrgCampaigns();
  }

  resetGlobalFilter():void{
    this.globalFilterObj = {};
    this.filterCampaignName = null;
    this.filterRouteName = null;
    this.filterPickupName = null;
    this.filterViaMessage = null; 
    this.filterCampaignType = null;
    this.filterDate=null;
    this.filterDateBoolean=false;
    this.getOrgCampaigns();
  }

  hideCampaignForm(event:any){
  //  console.log('hideCampaignForm',event);
    
   if(!event){
    
    this.initAllCampaignSection();
    // this.getOrgCampaigns();
   }
  }
}
