
import { Component, Input, Output } from '@angular/core';
import { ApiService } from './../../../services/ApiService';
import { chosenUtility } from './../../../services/chosen-utility/jq-chosen-utility.js';
import { bootstrapDateTime } from './../../../services/bootstrapDateTime/datePicker';
import { GlobalUtils } from './../../../services/global-utils';
declare const $: any;
import { DOCUMENT } from "@angular/platform-browser";
import { Objectkeys } from '../../../custom-pipes/objectkeys.pipe';
import { containsElement } from '@angular/animations/browser/src/render/shared';
import { IncrementDir } from './../../../pages/increment-index/inc';
import { setRootDomAdapter } from '@angular/platform-browser/src/dom/dom_adapter';
import { StorageService } from './../../../services/storage.service';
import { COM } from './../../../services/com.services';
import { Router } from '@angular/router';
import { RouteSwapConfig } from '../../configuration/route.swap.dir';
import { ApiCallDateFormatService } from "../../../services/api-call-date-format.service";
import { DatePipe } from '@angular/common';

declare const Pickathing: any;
interface IAvailableReports {
    general: any,
    RFIDWithClass: any,
    RFIDWithMember: any,
    RFIDWithRoute: any,
    RFIDWithAttendance: any,
    RFIDWithSmsLog: any,
    RFIDStatusCheck: any,
    RFIDRouteWiseCount: any,
    RFIDAppPush: any,
}
@Component({
    selector: 'app-reports-section',
    templateUrl: './app-reports.html',
    styleUrls: ['./app-reports.css']
})
export class AppReportsComponent {




}





