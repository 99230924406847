import { Component } from '@angular/core';

import { globalService } from './../../services/global.service';
import { ApiService } from './../../services/ApiService';

declare var FusionCharts:any;
@Component({
  selector:"fusion-timeline",
  template:`
  <div id="chart-container"  style="height:34vh;margin-left:3px;overflow-y:auto;">.</div>
  `
})

export class FusionGanttTimelineChart{
//redis old 52.74.225.104
//redis new 13.229.92.219

public chartSTART:number = 1;
public chartEND:number = 1;
public processData:any; public tasksData:any;

constructor(private apiService: ApiService){

}

  ngOnInit(){
    this.loadValueForFusion();

  }

  loadValueForFusion(){
     this.apiService.timelineGraph({
       data: {
         key: localStorage.getItem('scbToken')
       }
     }).then( res=>{
       let lineChart = res.response.lineChart;
       this.chartSTART = lineChart.chartStart;
       this.chartEND   = lineChart.chartEnd;
       this.processData = lineChart.process;
       this.tasksData = lineChart.tasks;
        this.fusion();
       //console.log('API',lineChart);
       //console.log(this.processData);
       //console.log('data',this.tasksData);
     }).catch( err=>{

     });
  }

  fusion(){
    let chartStart = 15; //this.chartSTART; //11;
        let chartEnd = 17; // this.chartEND; //13;
        let diff = chartEnd - chartStart;
        if(diff<1){ diff=1; }
        let colorbar = ["#8FC182", "#2E98C0", "#6D9EAA", "#F64E7D", "#F66E96", "#9A60AE", "#562568", "#6B3873", "#AA325D", "#B44568", "#D4495C", "#F65C46", "#FF8E63", "#F39052", "#F0D786", "#8F8A66", "#4EC68F", "#227B64", "#4F5648"];

        let timeCategories:any = [];
        let hourInterval:any = [];
        let hourInterval1:any =[];
        for(var i=0;i<=60;i++){
            hourInterval1.push(i);
        }
        let hourInterval3:any = [0,3,6,9,12,15,18,21,24,27,30,33,36,39,42,45,48,51,54,57,60];
        let hourInterval5:any = [0, 5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 55];
        let hourInterval10:any = [0, 10, 20, 30, 40, 50];
        let hourInterval15:any = [0, 15, 30, 45];
        let hourInterval30:any = [0, 30];
        /*
        if(chartEnd-chartStart){
            hourInterval=hourInterval5;
        }else if()*/

        //console.log(diff);

        diff = 3;

        switch (diff) {

          case 0:
              {
                  hourInterval = hourInterval1;
              }
              break;
            case 1:
                {
                    hourInterval = hourInterval5;
                }
                break;
            case 2:
                {
                    hourInterval = hourInterval10;
                }
                break;
            case 3:
                {

                    hourInterval = hourInterval15;
                }
                break;
            default:
                {
                    hourInterval = hourInterval30;
                }
                break;
        }



        for (var i = chartStart; i <= chartEnd; i++) {

            (hourInterval).forEach((intervalItem:any)=> {
                timeCategories.push({
                    "start": (i) + ":" + intervalItem + ":00",
                    "end": (i) + ":" + (intervalItem + 4) + ":59",
                    "label": (i) + ":" + intervalItem + (i < 12 ? ' am' : ' pm')
                });
            });


        }
//console.log( timeCategories );

        FusionCharts.ready(()=> {
            let timeShiftChart = new FusionCharts({
                type: 'gantt',
                renderAt: 'chart-container',
                width: '100%',
                height: '250',
                dataFormat: 'json',
                dataSource: {
                    "chart": {
                        "dateformat": "dd/mm/yyyy",
                        "outputdateformat": "hh12:mn ampm",
                        "canvasBorderAlpha": "0",
                        "theme": "zune",
                        "canvasBgColor": "#fffffff",
                        "canvasBgAlpha": "0",
                        "showCanvasBg": "0",
                        "legendBorderAlpha": "0",
                        "legendShadow": "0",
                        "usePlotGradientColor": "0",
                        "showCanvasBorder": "0",
                        "flatScrollBars": "1",
                        "gridbordercolor": "#fffcfc",
                        "gridborderalpha": "20",
                        "slackFillColor": "#e44a00",
                        "taskBarFillMix": "light+0",
                        "adjustDiv": "0",
                        "showValues": "0",
                        "numDivLines": "1",
                        "showTaskLabels": "1",

                    },
                    "categories": [
                        //                        {
                        //                            "category": [{
                        //                                "start": chartStart,
                        //                                "end": chartEnd,
                        //                                "label": "Time"
                        //                            }]
                        //                        },
                        {
                            "align": "left",
                            "category": timeCategories
                        }
                    ],
                    "processes": {
                        "fontsize": "12",
                        "isbold": "1",
                        "align": "left",
                        "headertext": "Routes",
                        "headerfontsize": "14",
                        "headervalign": "middle",
                        "headeralign": "left",
                        "process":this.processData,
                    },
                    "tasks": {
                        "showlabels": "1",
                        "task":this.tasksData
                    }
                }
            }).render();
        });

  }
}
