import { Component, Input, Output, EventEmitter } from "@angular/core";
import { ApiService } from "../../../services/ApiService";

@Component({
    selector: 'date-wise-attendance',
    template: `
    <ng-container *ngIf="dateWiseAttendanceDatas.length"> 
    <div class="panel panel-default">
    <div class="panel-heading">
        <div class="row mb5">
            <div class="reportHeading text-center mb5">Datewise Attendance Report</div>
        </div>
        <div class="row mb5">
            <div class="col-md-6 text-left">
                <label class="control-label">Date : </label>
                <span class="reportStartDate">{{intermediateObj.reportStartTime}}</span>
            </div>
            <div class="col-md-6 text-right">
                <!-- <label class="control-label"> </label> -->
                <span *ngIf="validExcel">
                <a href="{{xlString}}" target="_blank" class="meager-e2e-datewise-tab-attendance">
                    Download excel
                </a>
                </span>
            </div>
        </div>
        
     </div>


     <div class="panel-body memberRfidPanelBody" style="max-height:444px" (scroll)="onScroll($event)">
        <div class="row">
        <div class="col-sm-12">
            <div *ngIf="dateWiseAttendanceDatas?.length>0;"  >
                <table class="table table-stripped table-bordered" *ngIf="!noRouteRfidRecords">
                    <thead>
                        <tr>
                           
                            <th class="text-center v-center slNumber-report">#</th>
                    <th class="text-center">ID</th>
                    <th class="text-center">Name </th>
                    <th class="text-center"> Class </th> 
                    <th class="text-center"> Section </th> 
                    
                    <th class="text-center"> Time </th> 
                    <th class="text-center"> Status</th>
                    <th class="text-center"> Route Name </th>
                    <th class="text-center"> Pickup Point </th>
                        </tr>
                    </thead>
                    <tbody >
                        <tr *ngFor="let record of dateWiseAttendanceDatas|objectkeys;let i = index;">
                        <td >{{ i+1 }}</td>
                        <td>  
                            <span>{{ dateWiseAttendanceDatas[record].memberUid }}</span>
                        </td>
                        <td>                   
                        <span>{{ dateWiseAttendanceDatas[record].memberName }}</span>
                       </td>
                       <td>                   
                        <span>{{ dateWiseAttendanceDatas[record].memberClass }}</span>
                       </td>
                       <td>                   
                       <span>{{ dateWiseAttendanceDatas[record].memberSection }}</span>
                      </td>
                     
                       
                       <td>                   
                        <span>{{ dateWiseAttendanceDatas[record].dateTime  | date:'HH:mm' }}</span>
                       </td>
                       <td class="text-center">
                        <span>{{ dateWiseAttendanceDatas[record].boardedStatus }}</span>
                      </td>
                       <td>                   
                       <span>{{ dateWiseAttendanceDatas[record].routeName }}</span>
                      </td>
                      <td>                   
                      <span>{{ dateWiseAttendanceDatas[record].ppName }}</span>
                     </td>
                            
                        </tr>
                    </tbody>
                </table>
         
            </div>
            </div>
        </div>
    </div>

        </div>
        </ng-container>
   
        <ng-container *ngIf="!dateWiseAttendanceDatas.length">
        <div class="panel panel-default">
        <div class="panel-heading">
        <div class="row mb5">
            <div class="reportHeading text-center mb5">Datewise Attendance Report</div>
        </div>
        </div>
        <div class="panel-body">
        <h4 class="animated pulse text-center" style="animation-duration: 1s;color:#999FBF"><i class="fa fa-frown-o"></i>&nbsp;<span class="meager-e2e-datewise-attendance-report-no-records">No records found</span></h4>
        </div>
        </div>
        </ng-container>
    
    `,
    styleUrls: ['./attendance-reports.component.css'],
})

export class DatewiseAttendanceComponent {


    public apiHeader: any = {};
    public dateWiseAttendanceDatas: any = [];

    public list: any = [];
    public pageList: any = [];
    public currentPage = 1;
    public numberPerPage = 50;
    public numberOfPages = 0;
    public scrollIncrement = 0;
    public windowObj: any;
    public intermediateObj: any = {};
    public xlString: any = "";
    validExcel: boolean = false;
    constructor(public api: ApiService) {
        this.apiHeader = {}

    }

    @Input('getRouteWiseData') // get api response in this variable
    set getRouteWiseData(val: any) {

        Promise.resolve(val).then(res => {
            // console.log(this.stringFormer(res));

            this.apiHeader = {}
            this.intermediateObj['reportStartTime'] = res.data.form.fromDate
            this.apiHeader = res;
            let extra = { pageJump: 0 }
            this.apiHeader.data['extra'] = { ...extra }
            this.dateWiseAttendanceDatas = [];
            this.makeapiCall();
            this.excelDownloaded();
        })

    }
    @Output() errorDetected: EventEmitter<any> = new EventEmitter();

    ngOnInit() {

    }

    onScroll($event: any) {

        this.windowObj = $event;

        if (($event.target.scrollTop + $event.target.offsetHeight) >= $event.target.scrollHeight) {
            this.scrollIncrement = this.scrollIncrement + 1;
            this.apiHeader.data.extra.pageJump = this.scrollIncrement;
            this.makeapiCall();

        }
    }



    makeapiCall() {

        this.api.getTabAttendanceReport(this.apiHeader).then(response => {


            if (response.status === 'success') {
                let info: any = [];
                let responseDatas = response.response;
                var attendenceDateWiseDetail = response;
                var i: any;
                for (i in attendenceDateWiseDetail.response) {

                    let obj = {

                        memberUid: attendenceDateWiseDetail.response[i].memberUID,
                        memberName: attendenceDateWiseDetail.response[i].memberName,
                        memberClass: attendenceDateWiseDetail.response[i].memberClass,
                        memberSection: attendenceDateWiseDetail.response[i].memberSection,
                        dateTime: attendenceDateWiseDetail.response[i].logTimeMS,
                        routeName: attendenceDateWiseDetail.response[i].routeName,
                        ppName: attendenceDateWiseDetail.response[i].pickuppointName,
                        boardedStatus: this.capitalize(attendenceDateWiseDetail.response[i].boardedstatus).replace(/ing/g, 'ed')

                    }

                    info.push(obj);
                }
                this.dateWiseAttendanceDatas = [...this.dateWiseAttendanceDatas, ...info];
                if (this.dateWiseAttendanceDatas.length) this.validExcel = true;

            }
        }).catch(error => { })

    }

    capitalize = (str: string) => str.replace(/(?:^|\s)\S/g, (anoStr) => anoStr.toUpperCase());

    excelDownloaded() {
        try {
            let res = JSON.parse(JSON.stringify(this.apiHeader));

            let xlstr = this.api.base + 'report/download_tabattendance_reports&key=' + localStorage.getItem('scbToken') + '&';

            if (res['data']['form']) {
                Object.keys(res['data']['form']).forEach(item => {
                    xlstr += item + '=';
                    xlstr += res['data']['form'][item] + '&';
                })
            }

            if (res['data']['filter']) {
                Object.keys(res['data']['filter']).forEach(item => {
                    if (item !== 'reportType') {
                        xlstr += item + '=';
                        xlstr += res['data']['filter'][item] + '&';
                    }
                })
                xlstr += 'reportType=date';
            }

            this.xlString = xlstr;
        } catch (e) { }
    }

}