import { Injectable } from '@angular/core';
import { RouterModule, Routes, Router } from '@angular/router';
import { ApiService } from './ApiService';

@Injectable()

export class GetDataServices {

  constructor(public router: Router,
              public apiservice:ApiService) {
        
  }

  p_u_P(){ /*function to fetch live data.*/
    console.log('inside function');
  }


}
