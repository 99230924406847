import { Component, OnInit } from '@angular/core';

@Component({
   
    template:`

    <top-menu [dirParam]="{showMenu:'showSchoolMenu'}"></top-menu>
    <div class="container-body">
            <br><br><br>
                <div id="notfound" align="center" >
                    <div class="notfound">
                        <div class="notfound-404">
                            <h3>Sorry, you are not allowed to access this page</h3>
                        </div>
                      
                    </div>
                </div>
                <div class="img"  align="center" >
                        <img src="assets/webroot/images/common/loading.gif" height="400px">
                    </div>

  </div>

 
    `,
    styles:[`
    .container-body{
                  background: #f1f2f0; 
                  height: 100vh;
              }  
  
    `]
})

export class PermissionRestractedPage implements OnInit {
    constructor() { }

    ngOnInit() { }
}