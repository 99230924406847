import { Component } from "@angular/core";
import { ChannelPartnersConfig } from "../channel-partners.config";
import { ChannelPartnersConfigComponent } from "../channel-partners.component";
declare var $: any;

@Component({
    selector: "app-channelpartnervendor-sarv",
    templateUrl: "./channel-partner-vendor-sarv.component.html",
    styleUrls: ["./channel-partner-vendor-sarv.component.css"],
})
export class SarvVendorComponent {

    public channelpartnerconfig: any;
    public strReplaced: any;

    constructor() {
       
    }
    ngOnInit(){
         this.channelpartnerconfig = ChannelPartnersConfig[1];
         let strReplace = this.channelpartnerconfig.fa_icon_include_size;
        this.strReplaced = strReplace.replace("fa-3x", "fa-1x");
        console.log(this.strReplaced);
    }

    browse(){
        this.bs_input_file();
    }

    bs_input_file() {
	$(".input-file").before(
		function() {
			if ( ! $(this).prev().hasClass('input-ghost') ) {
				var element = $("<input type='file' class='input-ghost' style='visibility:hidden; height:0'>");
				element.attr("name",$(this).attr("name"));
				element.change(function(){
					element.next(element).find('input').val((element.val()).split('\\').pop());
				});
				$(this).find("button.btn-choose").click(function(){
					element.click();
				});
				$(this).find("button.btn-reset").click(function(){
					element.val(null);
					$(this).parents(".input-file").find('input').val('');
				});
				$(this).find('input').css("cursor","pointer");
				$(this).find('input').mousedown(function() {
					$(this).parents('.input-file').prev().click();
					return false;
				});
				return element;
			}
		}
	);
    }


}
