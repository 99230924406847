import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params, NavigationEnd, Router } from '@angular/router';
import { Subscription } from 'rxjs/Subscription';
import { ApiService } from './../../../services/ApiService';
import { roleFormModule } from './../../../model/role-model';
import { COM } from './../../../services/com.services';
declare var swal: any;

@Component({
  selector: 'app-edit-role',
  templateUrl: './edit-role.component.html',
  styleUrls: ['./edit-role.component.css']
})
export class EditRoleComponent implements OnInit {

  public pagejump = 0;
  public singleRoleInfo: any = {};
  public roleControl: any;
  public Role: any = {};
  public modelObj: any = {};
  public filter: any;
  public formObj: any = {};
  public orgReportStatus: any;
  public orgReportsStatus: any;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private api: ApiService,
    private modelDate: roleFormModule,
    private com: COM
  ) {

    this.modelObj = this.modelDate.jsonObj;
    this.orgReportsStatus = this.modelDate.orgReportsStatus;
    this.com.comAction.subscribe((e: any) => {
      if (e.lable === 'OrgReports') {
        this.orgReportStatus = e.data;
        if (this.orgReportsStatus['rfid']['RFIDRouteWiseCount'] == undefined) {
          this.orgReportsStatus['rfid']['RFIDRouteWiseCount'] = "RFID RouteWise Count";
        }
        if (this.orgReportsStatus['rfid']['RFIDAppPush'] == undefined) {
          this.orgReportsStatus['rfid']['RFIDAppPush'] = "RFID App Push";
        }
        if (this.orgReportsStatus['tracking']['vehcoordinate'] == undefined) {
          this.orgReportsStatus['tracking']['vehcoordinate'] = "Vehicle Co-ordinate Report";
        }
        if (this.orgReportsStatus['alertlog']['callEligibleReport'] == undefined) {
          this.orgReportsStatus['alertlog']['callEligibleReport'] = "Call Eligible Report";
        }
        if (this.orgReportsStatus['alertlog']['callProcessedAnalysReport'] == undefined) {
          this.orgReportsStatus['alertlog']['callProcessedAnalysReport'] = "Call Alert Analysis Report";
        }


        if (this.orgReportsStatus['others']['breakDownReport'] == undefined) {
          this.orgReportsStatus['others']['breakDownReport'] = "Break Down Report";
        }

        if (this.orgReportsStatus['attendance'] == undefined) {
          this.orgReportsStatus['attendance'] = {};
        }


        if (this.orgReportsStatus['attendance']['memberwiseAttendance'] == undefined) {
          this.orgReportsStatus['attendance']['memberwiseAttendance'] = "Memberwise Attendance";
        }
        if (this.orgReportsStatus['attendance']['routewiseAttendance'] == undefined) {
          this.orgReportsStatus['attendance']['routewiseAttendance'] = "Routewise Attendance";
        }
        if (this.orgReportsStatus['attendance']['datewiseAttendance'] == undefined) {
          this.orgReportsStatus['attendance']['datewiseAttendance'] = "Datewise Attendance";
        }

      }
    })

  }
  public errorMesages: any = [];//validator instance
  ngOnInit() {

    this.route.params.subscribe((params: Params): void => {
      this.getRoleInfo(params);
    });


  }

  getRoleInfo(filter: any) {

    this.filter = filter;

    let apiData = {
      data: {
        key: localStorage.getItem("scbToken"),
        filter
      }
    }


    this.api.listRoles(apiData).then((res: any) => {

      if (res.status === 'success') {




        let roleInfo = JSON.parse(JSON.stringify(res.response));
        this.Role.name = roleInfo[0].roleName;
        //this.singleRoleInfo =  roleInfo[0]['roleInfo'];

        this.singleRoleInfo = roleInfo[0]['roleInfo'];// Object.assign(this.modelDate.jsonObj, roleInfo[0]['roleInfo']);

        if (this.singleRoleInfo['menu'] && this.singleRoleInfo['menu']['Dashboard'] == undefined) {
          this.singleRoleInfo['menu']['Dashboard'] = true;
        }

        if (this.singleRoleInfo['reports'] && this.singleRoleInfo['reports']['rfid'] && this.singleRoleInfo['reports']['rfid']['RFIDRouteWiseCount'] == undefined) {
          this.singleRoleInfo['reports']['rfid']['RFIDRouteWiseCount'] = true;
        }
        if (this.singleRoleInfo['reports'] && this.singleRoleInfo['reports']['rfid'] && this.singleRoleInfo['reports']['rfid']['RFIDappPush'] == undefined) {
          this.singleRoleInfo['reports']['rfid']['RFIDappPush'] = true;
        }
        if (this.singleRoleInfo['reports'] && this.singleRoleInfo['reports']['tracking'] && this.singleRoleInfo['reports']['tracking']['vehcoordinate'] == undefined) {
          this.singleRoleInfo['reports']['tracking']['vehcoordinate'] = true;
        }


        if (this.singleRoleInfo['reports'] && this.singleRoleInfo['reports']['alertlog'] && this.singleRoleInfo['reports']['alertlog']['callEligibleReport'] == undefined) {
          this.singleRoleInfo['reports']['alertlog']['callEligibleReport'] = true;
        }

        if (this.singleRoleInfo['reports'] && this.singleRoleInfo['reports']['alertlog'] && this.singleRoleInfo['reports']['alertlog']['callProcessedAnalysReport'] == undefined) {
          this.singleRoleInfo['reports']['alertlog']['callProcessedAnalysReport'] = true;
        }

        if (this.singleRoleInfo['reports'] && this.singleRoleInfo['reports']['others'] && this.singleRoleInfo['reports']['others']['breakDownReport'] == undefined) {
          this.singleRoleInfo['reports']['others']['breakDownReport'] = true;
        }



        if (this.singleRoleInfo['reports'] && this.singleRoleInfo['reports']['attendance'] == undefined) {
          this.singleRoleInfo['reports']['attendance'] = {
            memberwiseAttendance: false,
            routewiseAttendance: false,
            datewiseAttendance: false

          };
        }


      }



    });

  }

  public formError: any = {};
  updateRole(obj: any) {
    this.formError.name = false;
    if (obj.valid) {

      let roleinfo = (this.singleRoleInfo);
      let roleid = this.filter.roleid;


      if (roleinfo['menu'] && roleinfo['menu']['Dashboard'] == undefined) {
        roleinfo['menu']['Dashboard'] = false;
      }
      let apiData = {
        data: {
          key: localStorage.getItem('scbToken'),
          form: {
            roleid,
            rolename: obj.value.rName,
            roleinfo
          }
        }
      }

      this.api.editRole(apiData).then((res: any) => {

        if (res.status === 'success') {

          swal('Role details updated successfuly...!');
          setTimeout(() => {
            this.router.navigate(['role-management', {}]);
          }, 300);

        } else {
          swal('Something wrong...!');
        }
      })

    } else {


      if (obj.value.rName == '' || obj.value.rName == undefined) {
        this.formError.name = true;
      }
    }
  }

  navigate(url: string) {
    this.router.navigate(['/' + url, {}]);
  }

  change(str: any, sub: string, val: any) {

    if (str[sub][val] === true) {
      str[sub][val] = false;
    } else {
      str[sub][val] = true;
    }
  }

  changeReports(str: any, sub: string, val: any) {

    if (str['reports'][sub][val] === true) {
      str['reports'][sub][val] = false;
    } else {
      str['reports'][sub][val] = true;
    }
  }

}
