import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'dateTime'
})

// export class DateTime implements PipeTransform {


//     transform(value:any,fromDateTime: any, toDateTime: any, flag: string): any {
//         console.log(flag);
//     }
// }
export class DateTime implements PipeTransform {

    transform(value:any,fromDateTime: any, toDateTime: any, flag: string): any {
        let _fromDatetime;
        let _toDateTime;
        // console.log(fromDateTime.gpsTimestamp,toDateTime.serverDate);
        if (typeof fromDateTime === 'number'){
            _fromDatetime = fromDateTime;
        }
        if(typeof toDateTime === 'number'){
            _toDateTime = toDateTime;
        }

        if(typeof fromDateTime === 'string'){
            _fromDatetime = this.strToNumberTime(fromDateTime);
        }
        if(typeof toDateTime === 'string'){
            _toDateTime = this.strToNumberTime(toDateTime);
        }

        if(flag==="difference"){
          return Math.abs(_fromDatetime-_toDateTime);
        }
        if(flag==="diffNotMs"){
            return Math.abs(_fromDatetime - _toDateTime)/1000;
        }
        if(flag === "lateupdate"){
            let currTime = Math.floor(Date.now() / 1000);
           
               
        // const currTime = 1558614300000/1000;

       
          
          _fromDatetime = fromDateTime/1000;

         let _diff = currTime - _fromDatetime;
  
        
         if((_diff >=900) &&(_diff <=1800)){               
             return 'lateupdate';
          }else{
          
             return;
          }

                    
        }
        if(flag === 'simpleTimeConvertor'){
           
            _fromDatetime = this.numToStrTime(fromDateTime)
            // console.log(_fromDatetime);
            return _fromDatetime;
        }

    }

    timeConversion(startTime:number,endTime:number){
        return Math.abs(startTime-endTime);
    }

    numToStrTime(time:number){
        if(typeof time === 'number'){
            let timer = time.toString().length;
                if(timer === 10){
                    time = time *1000;
                }
                if(timer === 13){
                    time = time;
                }
                

                let d = new Date(time);
            return d.toLocaleString("en-GB", { hour12: false }).replace(/\//g, '-').replace(/,/g, '');
        }
    }

    strToNumberTime(time:string){
       
        return new Date(time).getTime();
    }

//     console.log(_fromDatetime, '-', _toDateTime, _fromDatetime - _toDateTime)
// if ((_toDateTime - _fromDatetime) >= 900) && (_toDateTime - _fromDatetime) <= 1800)) {
//     console.log('lateupdate');
//     return 'lateupdate';
// }else {
//     console.log(new Date(_fromDatetime).toLocaleString(), new Date(_toDateTime).toLocaleString());
//     console.log(Math.abs(_fromDatetime - _toDateTime) + ' >= ' + 900, Math.abs(_fromDatetime - _toDateTime) + ' <= ' + 1800)
//     console.log('not lateupdate');
//     return;
// }
}