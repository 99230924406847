import { Component,Input,Output} from '@angular/core';


@Component({
   selector: 'g10-table-view',
   templateUrl:'./table-view.component.html',
   styleUrls:['./table-view.component.css']

})

export class TableViewComponent{

    @Input() _data: any;
    public finalData: any={};
    constructor(){}


    ngOnInit(){
            this.intComponent();
    }
    sortValue(arr:any, type:string) {
        let obj = {};
        let res = arr.sort((a:any, b:any) => {
          let k1:any = Object.keys(a);
          let k2:any = Object.keys(b);
          if (type == "asc") return a[k1] > b[k2] ? 1 : -1;
          else return a[k1] < b[k2] ? 1 : -1;
        });
        return res;
      }

    intComponent(){
        let keys = Object.keys(this._data.data);
        let obj = this._data.data;
        let TMSObj={};
        let roundTime = (time:any, minutesToRound:number) => {
   
         let [hours, minutes] = time.split(':');
         hours = parseInt(hours);
         minutes = parseInt(minutes);
     
         // Convert hours and minutes to time in minutes
         time = (hours * 60) + minutes; 
     
         let rounded = Math.round(time / minutesToRound) * minutesToRound;
         let rHr:any = ''+Math.floor(rounded / 60)
         let rMin:any = ''+ rounded % 60
     
         return rHr.padStart(2, '0')+':'+rMin.padStart(2, '0')
     }
        for(let date in obj){
            for(let pp in obj[date]){
   
             let good=0,avg=0,bad=0;
               let data = obj[date][pp].split('#$#');
               let callMadeTM = data[2];       
               let expectedTM = data[1];
               let exTMS = roundTime(expectedTM.slice(0,2)+':'+ expectedTM.slice(2,4),5);
               let reachedTM = data[0];
               if(TMSObj[exTMS]==undefined) TMSObj[exTMS]=0;          
               let timeDiff = this.difftimestamp(Number(callMadeTM),Number(reachedTM),'time_str_no_mins');
               // if(timeDiff >=4 && timeDiff <=11) good++;
               // if((timeDiff == 2 || timeDiff == 3)|| (timeDiff>=12) && timeDiff <=17) avg++;
               if(timeDiff <2 || timeDiff >=18 ) bad++;
               if(bad==1)TMSObj[exTMS]+=1;
            }   
            let pad = (num:number) => {
                return num > 9 ? num : "0" + num;
              };       
            if(keys.indexOf(date) == keys.length-1 ){
                // console.log('table-view --- date',date,TMSObj)
                let objKeys = Object.keys(TMSObj);
                let tms =(arr: any)=>{
                    return arr.map((itm: any, i:any) => {
                        let m: any = Number(itm.slice(3, 5));
                        let h: any = Number(itm.slice(0, 2));
                        let temp:any=0;
                        if (m == 0)
                            temp = pad(parseInt(h) - 1)+":"+pad(60-2)+"-"+pad(h)+":"+pad(2);
                         else
                            temp = pad(h)+":"+pad(m-2)+"-"+pad(h)+":"+pad(parseInt(m + 2));
                        //  console.log('retArray',itm);
                         let tmpObj={};
                         tmpObj[temp]= TMSObj[itm];
                         return tmpObj;                        
                      });
                  }
                  let finalArray = tms(objKeys);
                  let sortedVal = this.sortValue(finalArray,'desc');
                //   this.finalData = sortedVal;
                for(let i in sortedVal){
                    let key = Object.keys(sortedVal[i])[0];
                    this.finalData[key] = sortedVal[i][key];
                }   
           }
         }
    }
    difftimestamp(time1: number, time2: number, flag: string) {
        let time;
        
        time = Math.abs((time1 / 1000) - (time2 / 1000));
        time = Math.floor(Math.floor(time) / 60);
        if (flag === 'grade') {
            if (time <= 4) {
                return " C ";
            }
            if (time >= 5 && time <= 10) {
                return " A ";
            }
            if (time >= 11 && time <= 15) {
                return " B ";
            }
            if (time > 15) {
                return " D ";
            }

        }
        if (flag === 'time') {
            return time;
        }
        if (flag === 'time_str') {
            
            return time <= 1 ? time + ' min' : time + ' mins';
            
        }
        if (flag === 'time_str_no_mins') {

            return time ;
        }

    } 
}