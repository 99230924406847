import { Pipe,PipeTransform } from "@angular/core";

@Pipe({
    name: 'TagFilters'
})

export class TagFilters implements PipeTransform{

    transform(value:any,to:string){              
    try{
        //checks for string value if not 'all' array filter works else value returnrd
        return to === 'all' && value || value.filter ( (filtered:any)=> filtered.UserName.includes(to) && filtered);
    }catch(e){}
   
    }
}