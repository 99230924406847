import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'FilterBusOrGate'
})

export class FilterBusOrGate implements PipeTransform {
    transform(obj: any,vehtype:string): any {
        return obj.filter((item:any)=>{
            return item.vehicleInformation.type === vehtype;
        })
                
        }
    }