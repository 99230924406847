import { Component, Input } from '@angular/core';
declare const flatpickr:any;

  const classDict = {
    bad: "redClass",
    good: "greenClass",
    avg: "orangeClass"
  };
@Component({
      selector:'g10-flat-picker-chart',
      template:`
      <div class="row" style="margin-left: 0px;">
          <div [ngClass]="clumVal">
            <input type="text"  data-inline=true  placeholder="Select Date.."
            class=date style="display:none">
            </div>
       </div>
      `,
      styles:[`
      span.flatpickr-day.selected {
        color: black;
        background: white;
        border: none;
    }
      `]

})

export class FlatPickerComponent{
    @Input() data:any
     constructor(){}
    public clumVal ='col-md-3';
     ngOnInit(){
        //  setTimeout(()=>{
            this.renderCalender(this.data); 
        //  },1000);
           
     }
     get2DigitFmt(val:any) {
        return ("0" + val).slice(-2);
      }
     renderCalender(dates:any){
        let dateArray = Object.keys(dates);
        let dateList = dateArray[0]; //console.log('dateList',dateList)
        let sDate = dateList.slice(0, 4) + "-" + dateList.slice(4, 6) + "-01";
  
        let eDate = dateArray[dateArray.length - 1];
        let endDate =
          eDate.slice(0, 4) + "-" + eDate.slice(4, 6) + "-" + eDate.slice(6, 8);
       
        let mCnts = this.findMonthDiff(dateList, eDate);
        // let monthCount:any = Object.values(mCnts).join(",");
        if(mCnts>1) this.clumVal='col-md-6';
        let get2Dig = (val:any)=>{
            return ("0" + val).slice(-2);
        }
        const fp = flatpickr(".date", {
          minDate: sDate, // dates.slice(0,4)+':'+dates.slice(2,4),
          // maxDate:
          defaultDate:[sDate],
          noCalendar: false,
          position: "center",
          showMonths: mCnts,
          onDayCreate: function(dObj:any, dStr:any, fp:any, dayElem:any) {
            var date = dayElem.dateObj;
             let key =
                date.getFullYear() +
                get2Dig(parseInt(date.getMonth() + 1)) +
                get2Dig(date.getDate());
              let value = classDict[dates[key]];
            if (value) {
              dayElem.className += " " + value;
            }
            // console.log(date.getMonth()+1,value,key);
          }
        });
     }
   
      
      findMonthDiff(d1:any, d2:any) {
        let date1 = [];
        date1.push(d1.slice(4, 6)), date1.push(d2.slice(4, 6));
        var count:any = {};
        date1.forEach(function(i) {
          count[i] = (count[i] || 0) + 1;
        });
        let cnt:any= Object.keys(count).length;
        return cnt;
      }
      
      
    
}