import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'reasonSummary'
})
export class ReasonSummaryPipe implements PipeTransform {



  transform(value: any, ...args: any[]): any {

    if (args.length <= 1) {
      return;
    } else {
      let weekperf = args[1];
      if (args[0] == 'good') {
        return `Bus reaches the pickup point on time. No change in ETA required.`
      }
      if (args[0] == 'avg') {
        return `Bus reaches the pickup point at ${weekperf.ata}  which is little ${weekperf.isPositiveValue ? 'later' : 'earlier'} than the actual ETA of ${weekperf.eta}.<spanstyle="color:#89849b;">Suggestion: Consider revising ETA if the trend continues.</span>`;
      }
      if (args[0] == 'bad') {
        return `Consistently bus reaches the pickup point at ${weekperf.ata} which is much ${weekperf.isPositiveValue ? 'later' : 'earlier'} than the actual ETA of  ${weekperf.eta}.<span style="color:#89849b;"> Suggestion: Change in ETA strongly recommended.</span>`;
      }

    }

  }

}


