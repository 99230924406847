import { Component, OnInit, } from '@angular/core';
import 'rxjs/add/operator/pairwise';

import { Observable } from 'rxjs';
import 'rxjs/add/observable/interval';
import 'rxjs/add/operator/takeWhile';

import { PUP_Listener } from './adapter/tracker-listener/listener.pup';
const PUP = new PUP_Listener(); PUP.contructor();

import { TrackerData_Add } from './actions/tracker-data';
import { VehicleListUpdate } from './actions/vehicle-data';
import { Alert_Add } from './actions/alert-data';
import { NgRedux, select } from '@angular-redux/store';
import { AppState } from './store/index';

import { MessageService } from './services/message.service';


import {
  RouterModule,
  Routes,
  Router,
  NavigationStart,
  NavigationEnd,
  NavigationError,
  NavigationCancel,
  RoutesRecognized
} from '@angular/router';

import { globalService } from './services/global.service';
import { ApiService } from './services/ApiService';
import { GetDataServices } from './services/dataServices';


@Component({
  selector: 'my-app',
  template: `

  <router-outlet></router-outlet>
  `,
})
export class AppComponent {

  public tracker_listener_running: boolean;

  constructor(
    //  public router: Router,
    public storage: globalService,
    public ngRedux: NgRedux<AppState>,
    public messageService: MessageService
  ) {

  }

  tracker_listener() {
    /*
  try {
   PUP.start()
       .then( ( soc_res: any ) => {
         // console.log( soc_res );
         this.ngRedux.dispatch({
          type : TrackerData_Add,
          payload : {
            data : soc_res.data
          }
       });
         this.tracker_listener();
       } )
       .catch( (error: boolean) => {
           this.tracker_listener();
       } );
      } catch ( e ) {

      }
      */

    try {
      // console.log(PUP,PUP.start());
      PUP.start()
        .then((soc_res: any) => {
          // console.log( soc_res );
          soc_res.data['VehicleNo'] = this.storage.data['vehicleLists'][soc_res.data['imei']];
          // console.log( soc_res );
          this.ngRedux.dispatch({
            type: VehicleListUpdate,
            payload: soc_res.data
          });
          // send message to subscribers via observable subject
          this.messageService.sendMessage(soc_res.data);
          this.tracker_listener();
        })
        .catch((error: boolean) => {
          this.tracker_listener();
        });
    } catch (e) {
        // console.error(e);
    }

  }
  public lastAlert: any;

  alert_listener() {



    try {
      PUP.start_alert()
        .then((soc_res: any) => {
          // console.log( soc_res );          
          const currentTime = Math.floor(Date.now() / 1000);

          if (this.lastAlert == '' || this.lastAlert == undefined) {

            this.speedAlert();
            this.lastAlert = currentTime;
          } else {
            let oneminitues = currentTime - this.lastAlert;

            if (oneminitues >= 60) {

              this.speedAlert();
              this.lastAlert = currentTime;

            }
          }

          this.ngRedux.dispatch({
            type: Alert_Add,
            payload: {
              data: soc_res.data
            }
          });
          this.alert_listener();
        })
        .catch((error: boolean) => {
          this.alert_listener();
        });


    } catch (e) {
      // console.error(e);
    }

  }


  speedAlert() {

    var audio = new Audio('./assets/webroot/media/speedAlert_high.mp3');

    audio.play();
  }



}


/*
Notes : npm install node-sass -g
*/
