import { Alert_Add } from './../actions/alert-data';

export function reducer( state: any = [], action: any ) {
    switch (action.type) {
        case Alert_Add: {
          return [ ...state, action.payload.data ];
          }
          default: {
            return state;
          }
    }
}
