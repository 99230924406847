/**
 * @Author: Deepak Pookkote <group10>
 * @Date:   2018-04-17T11:03:34+05:30
 * @Email:  deepak@groupten.com
 * @Project: School-Bus
 * @Filename: editStudent.ts
 * @Last modified by:   group10
 * @Last modified time: 2018-05-09T12:56:16+05:30
 * @Copyright: Group10 Technologies
 */



/*Author :- Deepak */
import { Component,
     ElementRef,
     AfterViewInit,
     OnDestroy,
     ViewChild,
 } from '@angular/core';
import { RouterModule, Routes, Router } from '@angular/router';
import { AppComponent } from './../../app.component';

import { globalService } from './../../services/global.service';
import { ApiService } from './../../services/ApiService';
import { authGaurd } from './../../services/auth-guard.service'

declare var $:any;

@Component({
  //template: ``,
   templateUrl: './editStudent.component.html',
  styles: [`


    .calenderview{
      linear-gradient(to bottom,#fff 0,#f3f3f3 100%);
    background-image: linear-gradient(rgb(255, 255, 255) 0px, rgb(243, 243, 243) 100%);
    background-position-x: initial;
    background-position-y: initial;
    background-size: initial;
    background-repeat-x: initial;
    background-repeat-y: initial;
    background-attachment: initial;
    background-origin: initial;
    background-clip: initial;
    background-color: initial;
    }


    .btn {
    padding: 9px 12px;
    border: 1px solid rgba(0, 0, 0, 0.09);
    border-radius: 0px;
    color:#0096b9!important;
    }

    .action-items {
      font-size:16px;
      cursor:pointer;
    }
    .action-items:hover {
      color:cornflowerblue;
    }
    input[type=search]::-webkit-search-cancel-button {
    -webkit-appearance: searchfield-cancel-button;
    }

  .fixed_headers td,
  .fixed_headers th {
    min-width: 150px;
  }
  .fixed_headers thead tr {
    display: block;
    position: relative;
  }
  .fixed_headers tbody {
    display: block;
    overflow-x:hidden;
    overflow-y:scroll;
    width: 100%;
    height: 300px;
  }



    `],
})
export class studentEdit  {


  private showDialog: boolean;
  private userOperationData: any = {}; ///holding temporary user data , in case of dialog shown.
  private showSuccessDialog: boolean;
  private showFailureDialog: boolean;
  private showDialogLoader:boolean  = false;
  private showUploadSuccess:boolean = false;
  private showUploadFailure:boolean = false;


  constructor(public apiService:ApiService,public router:Router,public authGaurd:authGaurd){

  }

  ngOnInit(){
    this.authGaurd.checkToken();
  }

  tableView() {
    this.router.navigate(['/school-students-list']);
  }

  backToDashboard(){
    this.router.navigate(['/student-dashboard']);
  }

  addStudent() {

    this.router.navigate(['/add-student-details']);
  }

  excelUpload() {
    this.router.navigate(['/student-excel-upload',{}]);
  }

  rfidUpload(){
    this.router.navigate(['/rfid-upload']);
  }

  UpdateStudentExcelUpload() {
    this.router.navigate(['/studentInfo-update-upload',{}]);
  }

}
