/**
 * @Author: Rakesh Roushan
 * @Date:   2018-04-25T14:33:23+05:30
 * @Email:  rakesh@groupten.com
 * @Project: SChool-Bus
 * @Filename: global-utils.ts
 * @Last modified by:   group10
 * @Last modified time: 2018-05-02T10:31:20+05:30
 * @Copyright: Group10 Technologies
 */
import { Injectable } from '@angular/core';
const errorCodes =  {
  "SCB1": "Key Missing",
  "SCB2": "Key Expired",
  "SCB3": "Authentication Failed",
  "SCB4": "Given value is empty",
  "SCB5": "Need OrganizationId and TrackerId",
  "SCB6": "Not valid organization",
  "SCB7": "User Id not found",
  "SCB8": "Need organizationId and userId",
  "SCB9": "IMEI already Engaged",
  "SCB10": "Tracker already exist",
  "SCB11": "User already exist",
  "SCB12": "Need Organization Id",
  "SCB13": "Wrong Date Format",
  "SCB14": "Mobile Id missing",
  "SCB15": "No otp token found",
  "SCB16": "You are exceed the maximum limit of OTP",
  "SCB17": "OTP token is empty",
  "SCB18": "OTP Failed",
  "SCB19": "OTP Expired",
  "SCB20": "No OTP found",
  "SCB21": "User mobile number was already added",
  "SCB22": "Invalid Mobile Number",
  "SCB23": "Template Title/Template Message is empty",
  "SCB24": "Template Title already used",
  "SCB25": "Need Template Id",
  "SCB26": "The report can be generated for max 1 day",
  "SCB27": "Broadcast message is empty",
  "SCB28": "No member assigned",
  "SCB29": "Invalid Excel Data",
  "SCB30": "Route Id Missing",
  "SCB31": "Mac Id is empty",
  "SCB32": "Mobile Number not exist",
  "SCB33": "Not valid user",
  "SCB34": "MemberId Missing",
  "SCB35": "The report can be generated for max 3 days ",
  "SCB36": "The report can be generated for max 7 days ",
  "SCB37": "Vehicle Id missing",
  "SCB38": "The report can be generated for max 1 month",
  "SCB39": "Member not exist",
  "SCB40": "Member already assigned for 2 pickup points",
  "SCB41": "Pickup point not exist",
  "SCB42": "Route not exist",
  "SCB43": "Invalid Time",
  "SCB44": "Start Time was greater than End Time",
  "SCB45": "Clouning Route id is missing",
  "SCB46": "Clouning pickuppoints is missing",
  "SCB47": "vehicle already assigned to this timing",
  "SCB48": "Pickup Id missing",
  "SCB49": "Route Start time or End Time is Missing",
  "SCB50": "Pickup Point is missing",
  "SCB51": "Update Time Id missing",
  "SCB52": "Holiday List is missing",
  "SCB53": "Member Class is empty",
  "SCB54": "Class holidayid missing",
  "SCB55": "Notification Id Missing",
  "SCB56": "Username fields are empty",
  "SCB57": "Password fields are empty",
  "SCB58": "Mismatch old password",
  "SCB59": "Check operator data",
  "SCB60": "Invalid Timing data",
  "SCB61": "Tracker id not found",
  "SCB62": "Maximum user mobile number added to this organization",
  "SCB63": "Route Name already used",
  "SCB64": "ETA time is beyond than route time",
  "SCB65": "Select atleast 2 routes for merge",
  "SCB66": "ETA time is beyond than route time",
  "SCB67": "Email already used",
  "SCB68": "Swaping Route id is missing",
  "SCB69": "Swaping vehicle id is missing",
  "SCB70": "RFID Alredy exists",
  "SCB71": "Campaign id is missing",
  "SCB72": "The report can be generated for max 5 days",
  "SCB73": "Organization Holiday",
  "SCB81": "Duplicate in ETA",

}
@Injectable()
export class GlobalUtils {
    constructor(){
    }

    getErrorMsg = (errCode:string):string => {
        return Reflect.get(errorCodes,errCode);
    }
}
