import { Component, Input, Output, EventEmitter, HostListener } from '@angular/core';
import { ApiService } from './../../../services/ApiService';
import { chosenUtility } from './../../../services/chosen-utility/jq-chosen-utility.js';
import { bootstrapDateTime } from './../../../services/bootstrapDateTime/datePicker';
import { GlobalUtils } from './../../../services/global-utils';
import { StorageService } from "./../../../services/storage.service";
import { array_chunk } from './../../../utilities/common';
import { async } from 'rxjs/internal/scheduler/async';
import { FormControl } from '@angular/forms';
import { DatePipe } from '@angular/common'
import { ApiCallDateFormatService } from "../../../services/api-call-date-format.service";
import { IfStmt } from '@angular/compiler';
import { RouterModule, Routes, Router } from '@angular/router';
declare const pako: any;
declare const $: any;
declare const Highcharts: any;
declare const Pickathing: any;
@Component({
    providers: [bootstrapDateTime],
    selector: 'not-reached-report-section',
    styleUrls: ['./notReached.css'],
    template: `
    <div [ngStyle]="{'display': !isShow ? 'none' :'block'}" >
    <br><br><br>
    <button class="" (click)="returnSideNave()"><i class="fa fa-angle-double-right" aria-hidden="true"></i></button></div>
<div >
    <section class="tracking-report-section">
    <div class="row">
        <div class="col-md-12 pull-left fixedHeight availableReportOptions" [ngStyle]="{'display': isShow ? 'none' :'block'}">
            <div class="divHeading" style="margin-bottom: 2em;padding-top: 4%;">
                <span class="">Available Reports</span>
                <div style="height: 1px;border: 1px solid #64c7e9;width: 3em;"></div>
            </div>
            <div class="form-group">
                <label class="control-label">Select Report </label>
                <select class="custom-form-control chosen-select" name="demo-select" id="demo-select">
                       <option style="display:none">Select an Option</option> 
                       <!-- <ng-container *ngIf="readytoDisplay"> -->
                       <option *ngFor="let option of reportOptions" value="{{option.value}}" class="meager-e2e-rfid-{{option.reportName}}">{{option.reportName}}</option>
                      <!-- </ng-container> -->
                            </select>
            </div>
            <!--<div class="form-group">
                <label class="control-label">Select Report </label>
                <select class="custom-form-control chosen-select" id="availableReportsDropdown">
                    <option value=""></option>
                    <option *ngFor="let option of reportOptions" value="{{option.value}}">{{option.reportName}}</option>
                </select>
            </div> -->



<!-- Sessionwise Alert Log Summary report code starts here -900 -->

<section class="callAlertLogSection" *ngIf="showDateMenu" style="overflow-x:hidden">
<div class="divHeading" style="margin-bottom: 1.5em;padding-top: 2.5%;">
    <span class="">Available Options</span>
    <div style="height: 1px;border: 1px solid #64c7e9;width: 3em;"></div>
</div>




<div class="form-group">
    <label class="control-label">Start Date:<sup>*</sup></label>
    <input class="custom-form-control meager-e2e-calls-report-call-alert-date" id="routeHistoryStartDate">
    <span class="text-danger" *ngIf="callInfoStartDateError" >Choose Start Date</span>
    
</div>
<div class="form-group">
                    <label class="control-label">End Date:</label>
                    <sup style="margin-left:0">*</sup>
                    <input class="custom-form-control meager-e2e-calls-report-call-log-cnt-end-date" id="routeHistoryEndDate" >
                    <span class="text-danger" *ngIf="callLogCountEndDateError ">Choose End Date</span>
                    <span class="text-danger" *ngIf="routesDatesMisMatchError">End Date should be greater than Start Date</span>
                    <span class="text-danger" *ngIf="DayLimitError">Day Limit should be 5 days</span>
                </div>
<div class="form-group">
                              <label class="control-label">Route Type :<sup>*</sup></label>
                                <select class="custom-form-control meager-e2e-calls-report-call-made-report-route-type" id="" name="ostype"
                                       
                                        
                                        [(ngModel)]="sessionType">
                                        <option value="" class="text-capitalize" selected="">Select</option>
                                    
                                        <option value="Morning" class="text-capitalize">Morning</option>
                                        <option value="Afternoon" class="text-capitalize">Afternoon</option>
                                        <option value="Evening" class="text-capitalize">Evening</option>
                                    </select>
                                    <span *ngIf="sessionErr" class="text-danger">Choose Route Type</span>
                        </div>
<div class="form-group form-actions">
    <button class="webform-submit button-primary btn btn-primary form-submit pull-right viewReportBtn meager-e2e-calls-report-call-alert-view-btn" (click)="notReachedOnTimeReport('')">View</button>
    <span class="text-danger">{{displayErrorMessage}}</span>
</div>
</section>

            <section class="callAlertLogSection" *ngIf="reportSections.callalertmadelog" style="overflow-x:hidden">
                <div class="divHeading" style="margin-bottom: 1.5em;padding-top: 2.5%;">
                    <span class="">Available Options</span>
                    <div style="height: 1px;border: 1px solid #64c7e9;width: 3em;"></div>
                </div>
                <div class="form-group">
                    <label class="control-label">Available Routes</label>
                    <single-search-select
                    [searchType]="'onlyRoute'"
                    [searchAPIUrl]="'getRoutes'"
                    [otherParams]="'resetSearchUtility'"
                    (emitUserSelections)="setUserSelections($event)" class="meager-e2e-calls-report-call-alert-log-available-route">
                    </single-search-select>
                </div>
              
                <div class="form-group">
                    <label class="control-label">Available Class:</label>
                    <select class="custom-form-control meager-e2e-calls-report-call-alert-available-classes" [(ngModel)]="selectedClass">
                        <option value="">Select Class</option>
                        <option *ngFor="let class of orgClassesDet.classes" value="{{class}}">{{class}}</option>
                    </select>
                    <span class = "text-danger" *ngIf="chooseClassError">Select Class </span>
                </div>
                <div class="form-group">
                    <label class="control-label">Sections:</label>
                     <select class="custom-form-control meager-e2e-calls-report-call-alert-section" [(ngModel)]="selectedSection">
                         <option value="">Select Section</option>
                         <option *ngFor="let section of orgClassesDet.sections" value="{{section}}">{{section}}</option>
                     </select>
                </div>
                <div class="form-group">
                    <label class="control-label">Date:<sup>*</sup></label>
                    <input class="custom-form-control meager-e2e-calls-report-call-alert-date" id="routeHistoryStartDate">
                    <span class="text-danger" *ngIf="callInfoStartDateError" >Choose Start Date</span>
                    <!--<span class="text-danger" *ngIf="routesDatesMisMatchError">End Date should be greater than Start Date</span>-->
                </div>
               
                <div class="form-group">
                    <label class="control-label">Alert Duration:</label>
                    <select class="custom-form-control meager-e2e-calls-report-call-alert-duration" [(ngModel)]="callLogHaltDuration" (change)="callHaltDurationMin=1;callHaltDurationMax=60;">
                        <option value=1>Select Duration</option>
                        <option value=2>less than a minute</option>
                        <option value=3>Between a duration</option>
                    </select>
                </div>
                <div class="form-group">
                              <label class="control-label">Route Type :<sup>*</sup></label>
                                <select class="custom-form-control meager-e2e-calls-report-call-made-report-route-type" id="" name="ostype"
                                       
                                        
                                        [(ngModel)]="sessionType">
                                        <option value="" class="text-capitalize" selected="">Select</option>
                                    
                                        <option value="Morning" class="text-capitalize">Morning</option>
                                        <option value="Afternoon" class="text-capitalize">Afternoon</option>
                                        <option value="Evening" class="text-capitalize">Evening</option>
                                    </select>
                                    <span *ngIf="sessionErr" class="text-danger">Choose Route Type</span>
                        </div>
                <div class="row form-group" *ngIf="callLogHaltDuration == '3'">
                    <label class="control-label col-lg-12">Range (in minutes):</label>
                    <div class="col-lg-6">
                        <input type="number" placeholder="Min" class="custom-form-control" min="1" max="60" [(ngModel)]="callHaltDurationMin">
                    </div>
                    <div class="col-lg-6">
                        <input type="number" placeholder="Max" class="custom-form-control" min="1" max="60" [(ngModel)]="callHaltDurationMax">
                    </div>
                    <span class="text-danger" style="margin-left:15px" *ngIf="showHaltDurationError ">Max range is less than min</span>
                </div>
                <div class="form-group form-actions">
                    <button class="webform-submit button-primary btn btn-primary form-submit pull-right viewReportBtn meager-e2e-calls-report-call-alert-view-btn" (click)="viewCallAlertLog('callalertmadelog')">View</button>
                    <span class="text-danger">{{displayErrorMessage}}</span>
                </div>
            </section>
            <section class="callAlertLogCoutSection" *ngIf="reportSections.callLogCount">
                <div class="divHeading" style="margin-bottom: 1.5em;padding-top: 2.5%;">
                    <span class="">Available Options</span>
                    <div style="height: 1px;border: 1px solid #64c7e9;width: 3em;"></div>
                </div>
                <div class="form-group">
                    <label class="control-label">Available Routes</label>
                    <single-search-select
                    [searchType]="'onlyRoute'"
                    [searchAPIUrl]="'getRoutes'"
                    [otherParams]="'resetSearchUtility'"
                    (emitUserSelections)="setUserSelections($event)">
                    </single-search-select>
                </div>
                <div class="form-group">
                    <label class="control-label">Start Date:</label>
                    <sup style="margin-left:0">*</sup>
                    <input class="custom-form-control meager-e2e-calls-report-call-log-cnt-start-date" id="routeHistoryStartDate">
                    <span class="text-danger" *ngIf="callLogCountStartDateError" >Choose Start Date</span>  
                    <!--<span class="text-danger" *ngIf="routesDatesMisMatchError">End Date should be greater than Start Date</span>-->
                </div>
                <div class="form-group">
                    <label class="control-label">End Date:</label>
                    <sup style="margin-left:0">*</sup>
                    <input class="custom-form-control meager-e2e-calls-report-call-log-cnt-end-date" id="routeHistoryEndDate" >
                    <span class="text-danger" *ngIf="callLogCountEndDateError ">Choose End Date</span>
                    <span class="text-danger" *ngIf="routesDatesMisMatchError">End Date should be greater than Start Date</span>
                    <span class="text-danger" *ngIf="DayLimitError">Day Limit should be 5 days</span>
                </div>
                <div class="form-group form-actions">
                    <button class="webform-submit button-primary btn btn-primary form-submit pull-right viewReportBtn meager-e2e-calls-report-call-log-cnt-view-btn" (click)="viewCallAlertLogCount('callLogCount')">View</button>
                    <span class="text-danger">{{displayErrorMessage}}</span>
                </div>
            </section>
            <section class="appAlertLogSection" *ngIf="reportSections.appalertmadelog" style="overflow-x:hidden">
                <div class="divHeading" style="margin-bottom: 1.5em;padding-top: 2.5%;">
                    <span class="">Available Options</span>
                    <div style="height: 1px;border: 1px solid #64c7e9;width: 3em;"></div>
                </div>
                <div class="form-group">
                    <label class="control-label">Available Routes</label>
                    <single-search-select
                    [searchType]="'onlyRoute'"
                    [searchAPIUrl]="'getRoutes'"
                    [otherParams]="'resetSearchUtility'"
                    (emitUserSelections)="setUserSelections($event)">
                    </single-search-select>
                </div>
                <div class="form-group">
                    <label class="control-label">Start Date--3333:</label>
                    <input class="custom-form-control" id="appHistoryStartDate">
                    <span class="text-danger" *ngIf="callLogCountStartDateError" >Choose Start Date</span>
                    <span class="text-danger" *ngIf="routesDatesMisMatchError">End Date should be greater than Start Date</span>
                </div>
                <div class="form-group">
                    <label class="control-label">End Date--4444:</label>
                    <input class="custom-form-control" id="appHistoryEndDate" >
                    <span class="text-danger" *ngIf="callLogCountEndDateError ">Choose End Date</span>
                    <span class="text-danger" *ngIf="routesDatesMisMatchError">End Date should be greater than Start Date</span>
                </div>
                <div class="form-group">
                    <label class="control-label">OS Type:</label>
                    <select class="custom-form-control" [(ngModel)]="AppLogOsFilter">
                        <option value=''>Android & ios</option>
                        <option value='Android'>Android</option>
                        <option value='iOS'>ios</option>
                    </select>
                </div>
                <div class="form-group">
                    <label class="control-label">Alert Duration:</label>
                    <select class="custom-form-control" [(ngModel)]="AppLogHaltDuration">
                        <option value=1>Select Duration</option>
                        <option value=2>less than a minute</option>
                        <option value=3>Between a duration</option>
                    </select>
                </div>
                <div class="row form-group" *ngIf="AppLogHaltDuration == '3'">
                    <label class="control-label col-lg-12">Range (in minutes):</label>
                    <div class="col-lg-6">
                        <input type="number" placeholder="Min" class="custom-form-control" min="0" max="60" [(ngModel)]="appHaltDurationMin">
                    </div>
                    <div class="col-lg-6">
                        <input type="number" placeholder="Max" class="custom-form-control" min="0" max="60" [(ngModel)]="appHaltDurationMax">
                    </div>
                    <span class="text-danger" style="margin-left:15px" *ngIf="showAppDurationError ">Max range is less than min</span>
                </div>

                <div class="form-group form-actions">
                    <button class="webform-submit button-primary btn btn-primary form-submit pull-right viewReportBtn" (click)="viewAppAlertLog('appalertmadelog')">View</button>
                    <span class="text-danger">{{displayErrorMessage}}</span>
                </div>
            </section>
            <section class="smsAlertLogSection" *ngIf="reportSections.smsalertmadelog" style="overflow-x:hidden">
                <div class="divHeading" style="margin-bottom: 1.5em;padding-top: 2.5%;">
                    <span class="">Available Options</span>
                    <div style="height: 1px;border: 1px solid #64c7e9;width: 3em;"></div>
                </div>
                <div class="form-group">
                    <label class="control-label">Available Routes</label>
                    <single-search-select
                    [searchType]="'onlyRoute'"
                    [searchAPIUrl]="'getRoutes'"
                    [otherParams]="'resetSearchUtility'"
                    (emitUserSelections)="setUserSelections($event)">
                    </single-search-select>
                </div>
                <div class="form-group">
                    <label class="control-label">Route Type:</label>
                    <select class="custom-form-control" [(ngModel)]="smsLogRouteType">
                        <option value=""></option>
                        <option value="pickup">Pickup</option>
                        <option value="drop">Drop</option>
                    </select>
                </div>
                <div class="form-group">
                    <label class="control-label">Start Date---555:</label>
                    <input class="custom-form-control" id="smsHistoryStartDate">
                    <span class="text-danger" *ngIf="callLogCountStartDateError" >Choose Start Date</span>
                    <!--<span class="text-danger" *ngIf="routesDatesMisMatchError">End Date should be greater than Start Date</span>-->
                </div>
                <div class="form-group">
                    <label class="control-label">End Date---666:</label>
                    <input class="custom-form-control" id="smsHistoryEndDate" >
                    <span class="text-danger" *ngIf="callLogCountEndDateError ">Choose End Date</span>
                    <span class="text-danger" *ngIf="routesDatesMisMatchError">End Date should be greater than Start Date</span>
                </div>
                <div class="form-group">
                    <label class="control-label">Alert Duration:</label>
                    <select class="custom-form-control" [(ngModel)]="smsLogHaltDuration" (change)="smsHaltDurationMin=1;smsHaltDurationMax=60;">
                        <option value=1>Select Duration</option>
                        <option value=2>less than a minute</option>
                        <option value=3>Between a duration</option>
                    </select>
                </div>
                <div class="row form-group" *ngIf="smsLogHaltDuration == '3'">
                    <label class="control-label col-lg-12">Range (in minutes):</label>
                    <div class="col-lg-6">
                        <input type="number" placeholder="Min" class="custom-form-control" min="1" max="60" [(ngModel)]="smsHaltDurationMin">
                    </div>
                    <div class="col-lg-6">
                        <input type="number" placeholder="Max" class="custom-form-control" min="1" max="60" [(ngModel)]="smsHaltDurationMax">
                    </div>
                </div>
                <div class="form-group form-actions">
                    <button class="webform-submit button-primary btn btn-primary form-submit pull-right viewReportBtn" (click)="viewSmsAlertLog('smsalertmadelog')">View</button>
                    <span class="text-danger">{{displayErrorMessage}}</span>
                </div>
            </section>
            <section class="alertLogSummarySection" *ngIf="reportSections.alertlogsummary"></section>
            <section class="routehistoryLogSection" *ngIf="reportSections.routehistorylog">
                <div class="divHeading" style="margin-bottom: 1.5em;padding-top: 2.5%;">
                    <span class="">Available Options</span>
                    <div style="height: 1px;border: 1px solid #64c7e9;width: 3em;"></div>
                </div>
                <div class="form-group">
                    <label class="control-label">Select Route: <sup>*</sup></label>
                    <single-search-select
                    [searchType]="'onlyRoute'"
                    [searchAPIUrl]="'getRoutes'"
                    [otherParams]="'resetSearchUtility'"
                    (emitUserSelections)="setSelectedItem($event)">
                    </single-search-select>
                    <span class="text-danger" *ngIf="RoutNameError">Choose Route</span>
                </div>
                <div class="form-group">
                    <label class="control-label">Start Date: <sup>*</sup></label>
                    <input class="custom-form-control meager-e2e-calls-report-route-history-start-date" id="routeHistoryStartDate">
                    <span class="text-danger" *ngIf="routeHistoryStartDateError" (change)="InitiateInputValidator();">Choose Start Date</span>
                    <!--<span class="text-danger" *ngIf="routesDatesMisMatchError">End Date should be greater than Start Date</span>-->
                </div>
                <div class="form-group">
                    <label class="control-label">End Date: <sup>*</sup></label>
                    <input class="custom-form-control meager-e2e-calls-report-route-history-end-date" id="routeHistoryEndDate" (change)="InitiateInputValidator();">
                    <span class="text-danger" *ngIf="routeHistoryEndDateError">Choose End Date</span><br>
                      <span class="text-danger" *ngIf="routesDatesMisMatchError">End Date should be greater than Start Date</span>
                      <span class="text-danger" *ngIf="durationErrRouteHistoryLog">Reports can generate only for 3 days</span>

                </div>
                <div class="form-group form-actions">
                    <button class="webform-submit button-primary btn btn-primary form-submit pull-right viewReportBtn meager-e2e-calls-report-route-history-view-btn" (click)="viewRouteHistoryLog('routehistorylog')">View</button>
                </div>
                  <span class="text-danger" >{{displayErrorMessage}}</span>
            </section>
            <section class="memberHistoryLog" *ngIf="reportSections.memberhistorylog">
                <div class="divHeading" style="margin-bottom: 1.5em;padding-top: 2.5%;">
                    <span class="">Available Options</span>
                    <div style="height: 1px;border: 1px solid #64c7e9;width: 3em;"></div>
                </div>
                <div class="form-group">
                     <label class="control-label">
                     
                     Student Name <sup>*</sup></label>
                     <single-search-select
                     [searchType]="'member'"
                     [searchAPIUrl]="'viewMember'"
                     [otherParams]="'resetSearchUtility'"
                     (emitUserSelections)="setSelectedStudent($event)">
                     </single-search-select>
                     <span class = "text-danger" *ngIf="MemberNameError">Choose MemberName</span>
                </div>
                <div class="form-group">
                    <label class="control-label">Start Date: <sup>*</sup></label>
                    <input class="custom-form-control meager-e2e-calls-report-member-history-start-date" id="memberHistoryStartDate">
                    <span class="text-danger" *ngIf="MemberInfoStartDateError" >Choose Start Date <sup>*</sup></span>
                    <!--<span class="text-danger" *ngIf="routesDatesMisMatchError">End Date should be greater than Start Date</span>-->
                </div>
                <div class="form-group">
                    <label class="control-label">End Date: <sup>*</sup></label>
                    <input class="custom-form-control meager-e2e-calls-report-member-history-end-date" id="memberHistoryEndDate">
                    <span class="text-danger" *ngIf="MemberInfoEndDateError" >Choose End Date <sup>*</sup></span>
                    <span class="text-danger" *ngIf="routesDatesMisMatchError">End Date should be greater than Start Date</span>
                </div>
                <div class="form-group form-actions">
                    <button class="webform-submit button-primary btn btn-primary form-submit pull-right viewReportBtn meager-e2e-calls-report-member-history-view-btn" (click)="viewMemberHistoryLog('memberhistorylog')">View</button>
                    <span class = "text-danger">{{displayErrorMessage}}</span>
                </div>
            </section>
            <section class="memberHistoryLog" *ngIf="reportSections.callMadeReport">
                            <div class="divHeading" style="margin-bottom: 1.5em;padding-top: 2.5%;">
                                 <span class="">Available Options</span>
                                 <div style="height: 1px;border: 1px solid #64c7e9;width: 3em;"></div>
                            </div>
                         <!--   <div class="form-group">
                                <label class="control-label">Route type:<sup>*</sup></label>
                                <select class="custom-form-control" id="ostype" name="ostype"
                                    (change)="routeType=$event.target.value;"
                                    [(ngModel)]="routeType">
                                    <option value="" class="text-capitalize">Select</option>
                                   
                                    <option value="pickup" class="text-capitalize">pickup</option>
                                    <option value="drop" class="text-capitalize">drop</option>
                                </select>
                                <span *ngIf="routeError" class="text-danger">Choose Route Type</span>
                            </div> -->
                            <div class="form-group">
                                    <label class="control-label">Date:<sup>*</sup></label>
                                    <input class="custom-form-control meager-e2e-calls-report-call-made-report-date" id="selectedLogDate" 
                                    placeholder="Choose date" (change)="initiateEmptyDateValidator()">
                                    <span *ngIf="fromDateError" class="text-danger">Choose date</span>
                            </div>
                            <div class="form-group">
                              <label class="control-label">Route Type :<sup>*</sup></label>
                                <select class="custom-form-control meager-e2e-calls-report-call-made-report-route-type" id="" name="ostype"
                                       
                                        (change)="sessionType=$event.target.value;
                                        getSelectedRouteList($event.target.value);initiateEmptyDateValidator();"
                                        [(ngModel)]="sessionType">
                                        <option value="" class="text-capitalize">Select</option>
                                    
                                        <option value="Morning" class="text-capitalize">Morning</option>
                                        <option value="Afternoon" class="text-capitalize">Afternoon</option>
                                        <option value="Evening" class="text-capitalize">Evening</option>
                                    </select>
                                    <span *ngIf="sessionErr" class="text-danger">Choose Route Type</span>
                        </div>
                                               
                        <div class="form-group" *ngIf="sessionWiseRoute?.length >0">
                              <label class="control-label">Route Name :</label>
                              <select class="custom-form-control" [(ngModel)]="routeNameCallMade" name="routeName">
                                    <option value="" selected>Select</option>
                                    <option *ngFor="let route of sessionWiseRoute|objectkeys"
                                     value={{sessionWiseRoute[route].routeId}} > 
                                     {{ sessionWiseRoute[route].routeName}}</option>
                              </select>
                        </div>
       
             <!--         <div class="form-group col-md-6">
                                    <label class="control-label">Start Time:<sup>*</sup></label>
                                    <input class="custom-form-control input-sm" id="eventStartTime" placeholder="Start time"> 
                                    <span *ngIf="fromStartTimeError" class="text-danger">Choose start time</span>

                            </div>

                     <div class="form-group col-md-6">
                                        <label class="control-label">End Time:<sup>*</sup></label>
                                        <input class="custom-form-control input-sm" id="eventEndTime" placeholder="End date">
                                        <span *ngIf="fromEndTimeError" class="text-danger">Choose end time</span>
                            </div>--->
                            <div class="form-group form-actions">
                                <button class="webform-submit button-primary btn btn-primary form-submit pull-right  
                                viewReportBtn meager-e2e-calls-report-call-made-report-view-btn" [disabled]="isValidForView" (click)="filterFunction('callMadeReport')">View</button>
                                <span class = "text-danger">{{displayErrorMessage}}</span>
                           </div> 
            </section>


            
            <section class="memberHistoryLog" *ngIf="reportSections.callMadeEarly">
            <div class="divHeading" style="margin-bottom: 1.5em;padding-top: 2.5%;">
                 <span class="">Available Options</span>
                 <div style="height: 1px;border: 1px solid #64c7e9;width: 3em;"></div>
            </div>
         <!--   <div class="form-group">
                <label class="control-label">Route type:<sup>*</sup></label>
                <select class="custom-form-control" id="ostype" name="ostype"
                    (change)="routeType=$event.target.value;"
                    [(ngModel)]="routeType">
                    <option value="" class="text-capitalize">Select</option>
                   
                    <option value="pickup" class="text-capitalize">pickup</option>
                    <option value="drop" class="text-capitalize">drop</option>
                </select>
                <span *ngIf="routeError" class="text-danger">Choose Route Type</span>
            </div> -->
            <div class="form-group">
                    <label class="control-label">Date:<sup>*</sup></label>
                    <input class="custom-form-control meager-e2e-calls-report-call-made-report-date" id="selectedLogDate" 
                    placeholder="Choose date" (change)="initiateEmptyDateValidator()">
                    <span *ngIf="fromDateError" class="text-danger">Choose date</span>
            </div>
            <div class="form-group">
              <label class="control-label">Route Type :<sup>*</sup></label>
                <select class="custom-form-control meager-e2e-calls-report-call-made-report-route-type" id="" name="ostype"
                       
                        (change)="sessionType=$event.target.value;
                        getSelectedRouteList($event.target.value);initiateEmptyDateValidator();"
                        [(ngModel)]="sessionType">
                        <option value="" class="text-capitalize">Select</option>
                    
                        <option value="Morning" class="text-capitalize">Morning</option>
                        <option value="Afternoon" class="text-capitalize">Afternoon</option>
                        <option value="Evening" class="text-capitalize">Evening</option>
                    </select>
                    <span *ngIf="sessionErr" class="text-danger">Choose Route Type</span>
        </div>
                               
       

<!--         <div class="form-group col-md-6">
                    <label class="control-label">Start Time:<sup>*</sup></label>
                    <input class="custom-form-control input-sm" id="eventStartTime" placeholder="Start time"> 
                    <span *ngIf="fromStartTimeError" class="text-danger">Choose start time</span>

            </div>

     <div class="form-group col-md-6">
                        <label class="control-label">End Time:<sup>*</sup></label>
                        <input class="custom-form-control input-sm" id="eventEndTime" placeholder="End date">
                        <span *ngIf="fromEndTimeError" class="text-danger">Choose end time</span>
            </div>--->
            <div class="form-group form-actions">
                <button class="webform-submit button-primary btn btn-primary form-submit pull-right  
                viewReportBtn meager-e2e-calls-report-call-made-report-view-btn" [disabled]="isValidForView" (click)="filterFunctioncallMadeEarly('callMadeEarly')">View</button>
                <span class = "text-danger">{{displayErrorMessage}}</span>
           </div> 
</section>
           
            
         <section class="memberHistoryLog" *ngIf="reportSections.callMadeDelayed">
         <div class="divHeading" style="margin-bottom: 1.5em;padding-top: 2.5%;">
              <span class="">Available Options</span>
              <div style="height: 1px;border: 1px solid #64c7e9;width: 3em;"></div>
         </div>
      <!--   <div class="form-group">
             <label class="control-label">Route type:<sup>*</sup></label>
             <select class="custom-form-control" id="ostype" name="ostype"
                 (change)="routeType=$event.target.value;"
                 [(ngModel)]="routeType">
                 <option value="" class="text-capitalize">Select</option>
                
                 <option value="pickup" class="text-capitalize">pickup</option>
                 <option value="drop" class="text-capitalize">drop</option>
             </select>
             <span *ngIf="routeError" class="text-danger">Choose Route Type</span>
         </div> -->
         <div class="form-group">
                 <label class="control-label">Date:<sup>*</sup></label>
                 <input class="custom-form-control meager-e2e-calls-report-call-made-report-date" id="selectedLogDate" 
                 placeholder="Choose date" (change)="initiateEmptyDateValidator()">
                 <span *ngIf="fromDateError" class="text-danger">Choose date</span>
         </div>
         <div class="form-group">
           <label class="control-label">Route Type :<sup>*</sup></label>
             <select class="custom-form-control meager-e2e-calls-report-call-made-report-route-type" id="" name="ostype"
                    
                     (change)="sessionType=$event.target.value;
                     getSelectedRouteList($event.target.value);initiateEmptyDateValidator();"
                     [(ngModel)]="sessionType">
                     <option value="" class="text-capitalize">Select</option>
                 
                     <option value="Morning" class="text-capitalize">Morning</option>
                     <option value="Afternoon" class="text-capitalize">Afternoon</option>
                     <option value="Evening" class="text-capitalize">Evening</option>
                 </select>
                 <span *ngIf="sessionErr" class="text-danger">Choose Route Type</span>
     </div>
                            
    

<!--         <div class="form-group col-md-6">
                 <label class="control-label">Start Time:<sup>*</sup></label>
                 <input class="custom-form-control input-sm" id="eventStartTime" placeholder="Start time"> 
                 <span *ngIf="fromStartTimeError" class="text-danger">Choose start time</span>

         </div>

  <div class="form-group col-md-6">
                     <label class="control-label">End Time:<sup>*</sup></label>
                     <input class="custom-form-control input-sm" id="eventEndTime" placeholder="End date">
                     <span *ngIf="fromEndTimeError" class="text-danger">Choose end time</span>
         </div>--->
         <div class="form-group form-actions">
             <button class="webform-submit button-primary btn btn-primary form-submit pull-right  
             viewReportBtn meager-e2e-calls-report-call-made-report-view-btn" [disabled]="isValidForView" (click)="filterFunctioncallMadeDelayed('callMadeDelayed')">View</button>
             <span class = "text-danger">{{displayErrorMessage}}</span>
        </div> 
</section>
        
<section class="memberHistoryLog" *ngIf="reportSections.nrOnTime">
<div class="divHeading" style="margin-bottom: 1.5em;padding-top: 2.5%;">
     <span class="">Available Options</span>
     <div style="height: 1px;border: 1px solid #64c7e9;width: 3em;"></div>
</div>
<!--   <div class="form-group">
    <label class="control-label">Route type:<sup>*</sup></label>
    <select class="custom-form-control" id="ostype" name="ostype"
        (change)="routeType=$event.target.value;"
        [(ngModel)]="routeType">
        <option value="" class="text-capitalize">Select</option>
       
        <option value="pickup" class="text-capitalize">pickup</option>
        <option value="drop" class="text-capitalize">drop</option>
    </select>
    <span *ngIf="routeError" class="text-danger">Choose Route Type</span>
</div> -->
<div class="form-group">
        <label class="control-label">Date:<sup>*</sup></label>
        <input class="custom-form-control meager-e2e-calls-report-call-made-report-date" id="selectedLogDate" 
        placeholder="Choose date" (change)="initiateEmptyDateValidator()">
        <span *ngIf="fromDateError" class="text-danger">Choose date</span>
</div>
<div class="form-group">
  <label class="control-label">Route Type :<sup>*</sup></label>
    <select class="custom-form-control meager-e2e-calls-report-call-made-report-route-type" id="" name="ostype"
           
            (change)="sessionType=$event.target.value;
            getSelectedRouteList($event.target.value);initiateEmptyDateValidator();"
            [(ngModel)]="sessionType">
            <option value="" class="text-capitalize">Select</option>
        
            <option value="Morning" class="text-capitalize">Morning</option>
            <option value="Afternoon" class="text-capitalize">Afternoon</option>
            <option value="Evening" class="text-capitalize">Evening</option>
        </select>
        <span *ngIf="sessionErr" class="text-danger">Choose Route Type</span>
</div>
                   


<!--         <div class="form-group col-md-6">
        <label class="control-label">Start Time:<sup>*</sup></label>
        <input class="custom-form-control input-sm" id="eventStartTime" placeholder="Start time"> 
        <span *ngIf="fromStartTimeError" class="text-danger">Choose start time</span>

</div>

<div class="form-group col-md-6">
            <label class="control-label">End Time:<sup>*</sup></label>
            <input class="custom-form-control input-sm" id="eventEndTime" placeholder="End date">
            <span *ngIf="fromEndTimeError" class="text-danger">Choose end time</span>
</div>--->
<div class="form-group form-actions">
    <button class="webform-submit button-primary btn btn-primary form-submit pull-right  
    viewReportBtn meager-e2e-calls-report-call-made-report-view-btn" [disabled]="isValidForView" (click)="filterFunctioncallMadenrOnTime('nrOnTime')">View</button>
    <span class = "text-danger">{{displayErrorMessage}}</span>
</div> 
</section>



<section class="memberHistoryLog" *ngIf="reportSections.rOnTime">
<div class="divHeading" style="margin-bottom: 1.5em;padding-top: 2.5%;">
     <span class="">Available Options</span>
     <div style="height: 1px;border: 1px solid #64c7e9;width: 3em;"></div>
</div>
<!--   <div class="form-group">
    <label class="control-label">Route type:<sup>*</sup></label>
    <select class="custom-form-control" id="ostype" name="ostype"
        (change)="routeType=$event.target.value;"
        [(ngModel)]="routeType">
        <option value="" class="text-capitalize">Select</option>
       
        <option value="pickup" class="text-capitalize">pickup</option>
        <option value="drop" class="text-capitalize">drop</option>
    </select>
    <span *ngIf="routeError" class="text-danger">Choose Route Type</span>
</div> -->
<div class="form-group">
        <label class="control-label">Date:<sup>*</sup></label>
        <input class="custom-form-control meager-e2e-calls-report-call-made-report-date" id="selectedLogDate" 
        placeholder="Choose date" (change)="initiateEmptyDateValidator()">
        <span *ngIf="fromDateError" class="text-danger">Choose date</span>
</div>
<div class="form-group">
  <label class="control-label">Route Type :<sup>*</sup></label>
    <select class="custom-form-control meager-e2e-calls-report-call-made-report-route-type" id="" name="ostype"
           
            (change)="sessionType=$event.target.value;
            getSelectedRouteList($event.target.value);initiateEmptyDateValidator();"
            [(ngModel)]="sessionType">
            <option value="" class="text-capitalize">Select</option>
        
            <option value="Morning" class="text-capitalize">Morning</option>
            <option value="Afternoon" class="text-capitalize">Afternoon</option>
            <option value="Evening" class="text-capitalize">Evening</option>
        </select>
        <span *ngIf="sessionErr" class="text-danger">Choose Route Type</span>
</div>
                   


<!--         <div class="form-group col-md-6">
        <label class="control-label">Start Time:<sup>*</sup></label>
        <input class="custom-form-control input-sm" id="eventStartTime" placeholder="Start time"> 
        <span *ngIf="fromStartTimeError" class="text-danger">Choose start time</span>

</div>

<div class="form-group col-md-6">
            <label class="control-label">End Time:<sup>*</sup></label>
            <input class="custom-form-control input-sm" id="eventEndTime" placeholder="End date">
            <span *ngIf="fromEndTimeError" class="text-danger">Choose end time</span>
</div>--->
<div class="form-group form-actions">
    <button class="webform-submit button-primary btn btn-primary form-submit pull-right  
    viewReportBtn meager-e2e-calls-report-call-made-report-view-btn" [disabled]="isValidForView" (click)="filterFunctioncallMaderOnTime('rOnTime')">View</button>
    <span class = "text-danger">{{displayErrorMessage}}</span>
</div> 
</section>







                <section class="memberHistoryLog" *ngIf="reportSections.callProcessedReport">
                           <div class="divHeading" style="margin-bottom: 1.5em;padding-top: 2.5%;">
                                 <span class="">Available Options</span>
                                 <div style="height: 1px;border: 1px solid #64c7e9;width: 3em;"></div>
                            </div>
                            <div class="form-group">
                                <label class="control-label">Session:<sup>*</sup></label>
                                <select value="morning" class="custom-form-control meager-e2e-calls-report-call-processed-report-session" id="ostype" name="ostype"
                                    (change)="dayTagging=$event.target.value;"
                                    [(ngModel)]="dayTagging">
                                   
                                    <option value="morning" class="text-capitalize" selected>Morning</option>
                                    <option value="afternoon" class="text-capitalize">Afternoon</option>
                                    <option value="evening" class="text-capitalize">Evening</option>
                                </select>
                                 <span *ngIf="dayTagging===undefined" class="text-danger">Choose Option</span>
                            </div>
                            <div class="form-group">
                                    <label class="control-label">Date:<sup>*</sup></label>
                                    <input class="custom-form-control meager-e2e-calls-report-call-processed-report-date" id="selectedLogDate" placeholder="Choose date">
                                    <span *ngIf="fromDateError" class="text-danger">Choose date</span>
                            </div>
                            <div class="form-group form-actions">
                                <button class="webform-submit button-primary btn btn-primary form-submit pull-right viewReportBtn meager-e2e-calls-report-call-processed-report-view-btn" (click)="callProcessedReport('callProcessedReport');">View</button>
                                <button class="webform-submit button-primary btn btn-primary form-submit pull-left viewReportBtn" *ngIf="enablecsv" (click)="export_table_to_csv(html)">Csv Download</button>
                                <span class = "text-danger">{{displayErrorMessage}}</span>
                           </div>
            </section>
    



        </div>
        <div class="col" *ngIf="!isLoading; else pageLoader ">

        <section class="callAlertLogContent" *ngIf="reportSections.routecallalertmadelog &&  reportSectionsContent.routecallalertmadelog">
        <div class="callLogHeader mb5">
        <div class="reportHeading text-center mb5">Sessionwise Alert Log Summary</div>
        <div class="row vehicleBasicDetails" *ngIf="isDateSelected">
                <div class="col-md-6">
                    <label class="control-label mr5 basicDetailsLabel">Date:</label>
                    <span>{{reportBasicDetails.fromDate | datestringtounix | date:'dd-LL-yyyy' }}</span>
                </div>
        </div>
    </div>


    <div class="result-container" style="">
    <div class="row">
      <div class="col-md-12 fixed-table-header">
            <div class="col-md-2 text-left "> <i class="fa fa-expand" title="Click to expand"></i></div>
            <div class="col-md-3 text-center">Route Name</div>
            
      </div>
    </div>
    <section style="max-height:550px;overflow-y:auto;overflow-x:hidden;" (scroll)="onScroll($event)">
      <div class="row filteredDataSet" *ngFor="let route of newAlertConfigRoutes;let $index=index;" style=" ">
        <div class="col-md-12">
          <table class="table table-condensed filteredOrgRoutes" style="">
            <tbody>
                <tr class="text-center" *ngIf="route.routeName!='Walkers_M' && route.routeName!='Walkers_N'  && route.routeName!='Walkers_A' ">
                  <td class="col-md-1 text-left" (click)="expandRow($index,route.routeId,reportBasicDetails.fromDate,route.routeName)" style="cursor:pointer"><i class="glyphicon glyphicon-plus" title="Click to get route details"></i></td>
                  <td class="col-md-3 text-left" >{{route.routeName}}</td>
                  
                </tr>
            </tbody>
          </table>
        </div>
        

        <div class="col-md-12">
        <div *ngIf="showTableRowLoader">
        <div class="table-loader-container text-center" *ngIf="$index ===  this.storeIndex">
        <i class="fa fa-spinner fa-spin" style="font-size:24px" style="margin-right:5px"></i>
        Loading...
    </div>
    </div>
    
        <table class="table table-condensed table-bordered bg-info animated fade-in" *ngIf="!showTableRowLoader && $index === expandedIndex">
          
        <tbody>
        <div class="call-log-container" *ngIf="!noCallAlertData; else noDataFound">
        <div class="call-log-details" *ngFor="let info of callAlertLog">
            <div class="pickupInfo mb5"> 
                <span class=""><label class="control-label mr5 basicDetailsLabel">{{info.routeName}}</label > - <span style="font-size: 15px">{{info.pickupName}}</span></span>
                <span class="pickupEta pull-right">{{info.pickupEta}}</span>
            </div>
            <div class="pickupEta"></div>
            <div class="table-container">
                <table class="table table-bordered table-stripped call-details-table" >
                    <thead>
                        <tr>
                            <th class="text-center" rowspan="2" style="vertical-align: middle;">#</th>
                            <th class="text-center" rowspan="2" style="vertical-align: middle;">Member Name</th>
                            <th class="text-center mr5" colspan="2">Call Details</th>
                            <th class="text-center" rowspan="2" style="vertical-align: middle;">Call Log Time</th>
                            <th class="text-center" rowspan="2" style="vertical-align: middle;">Vehicle Arrival</th>
                            <!--<th class="text-center" rowspan="2" style="vertical-align: middle;">Time Difference</th>-->
                        </tr>
                        <tr>
                            <th class="text-center">Mobile Number</th>
                            <th class="">
                                <div class="primaryCallInfo  mr5" style="display:inline-block">
                                    <i class="fa fa-phone mr5 reportBodyIcon" aria-hidden="true"></i>
                                </div>&nbsp;
                                <div class="primaryCallInfo  mr5" style="display:inline-block">
                                    <i class="fa fa-envelope mr5 reportBodyIcon" aria-hidden="true"></i>
                                </div>&nbsp;
                                <div class="primaryCallInfo  mr5" style="display:inline-block">
                                <i class="fa fa-mobile mr5 reportBodyIcon" aria-hidden="true"></i>
                            </div>&nbsp;
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let studentDetails of info.studentDetails;let i = index">
                            <td class="inheritValign text-center">{{i+1}}</td>
                            <td class="inheritValign">
                                <div class="memberName mb5">{{studentDetails.memberName}}</div>
                                <div class="memberClassInfo">
                                    <span class="memberClass text-muted">{{studentDetails.memberClass}}</span>,
                                    <span class="memberSection text-muted">{{studentDetails.memberSection}}</span>
                                </div>
                            </td>
                            <td>
                                <div class="primaryMobile text-center mb5">{{studentDetails.mobileNo}}</div>
                                <div class="secondaryMobile text-center">{{studentDetails.mobileNo2}}</div>
                            </td>
                            <td>
                                <div class="primaryMobileInfo " *ngIf="studentDetails.mobileNo">
                                    <span class="mr5" *ngIf="studentDetails.call1;then showTick else showCross">{{studentDetails.call1}}</span>&nbsp;&nbsp;
                                    <span class="mr5" *ngIf="studentDetails.sms1;then showTick else showCross">{{studentDetails.sms1}}</span>&nbsp;&nbsp;
                                    <span class="mr5" *ngIf="studentDetails.app;then showTick else showCross"></span>&nbsp;&nbsp;
                                </div>
                                <div class="secondaryMobileInfo "*ngIf="studentDetails.mobileNo2">
                                    <span class="mr5" *ngIf="studentDetails.call2;then showTick else showCross">{{studentDetails.call1}}</span>&nbsp;&nbsp;
                                    <span class="mr5" *ngIf="studentDetails.sms2;then showTick else showCross">{{studentDetails.sms1}}</span>&nbsp;&nbsp;
                                    <span class="mr5" *ngIf="studentDetails.app;then showTick else showCross"></span>&nbsp;&nbsp;
                                </div>
                                
                            </td>
                            <td class="inheritValign text-center">{{studentDetails.callTriggerTime | unixTimestampFormatter}}</td>
                            <td class="inheritValign text-center"><div *ngIf="studentDetails.vehReachTime; else showHyphen">{{studentDetails.vehReachTime | unixTimestampFormatter}}</div></td>
                            <!--<td class="inheritValign">{{studentDetails.callmadeDiff*60 | secondsToHhMm}}</td>-->
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
          </tbody>
        </table>
    </div>


      </div>
    </section>
  </div>


        </section>

            <section class="callAlertLogContent" *ngIf="reportSections.callalertmadelog &&  reportSectionsContent.callalertmadelog">
                <div class="callLogHeader mb5">
                    <div class="reportHeading text-center mb5">Call Log</div>
                    <div class="row vehicleBasicDetails" *ngIf="isDateSelected">
                            <div class="col-md-6">
                                <label class="control-label mr5 basicDetailsLabel">Report Start Date:</label>
                                <span>{{reportBasicDetails.fromDate | datestringtounix | date:'dd-LL-yyyy' }}</span>
                            </div>
                    </div>
                </div>
   
   <section>

   
   <div class="row overlayFilteredResult" style="margin-top:4%;text-align:left">
     
   </div>
   <div class="result-container" style="border:1px solid #80808038;">
     
     <section class="routeListContainer" >
     
     <div class="nav-tabs-custom" *ngIf="callAlertSessionWise">
     <ul class="nav nav-tabs">
         <ng-container >
             <li *ngFor="let tab of newAlertConfigRoutes; let $index=index;" ><a data-target="#tab_1"
                     data-toggle="tab" (click)="expandRowCallAlertLog($index,tab.routeId,tab.routeName)" class="text-uppercase"><b>{{tab.routeName}}</b></a></li>
         </ng-container>
     </ul>
 </div>
 <div class="tab-content" *ngIf="callAlertSessionWise">
     

     


      <div *ngIf="showTableRowLoader">
        
        <i class="fa fa-spinner fa-spin" style="font-size:24px" style="margin-right:5px"></i>
        Loading...
    
    </div>

    <div class="call-log-details" *ngFor="let info of callAlertLog" > 
         <div class="pickupInfo mb5" *ngIf="!showTableRowLoader"> 
               <span class=""><label class="control-label mr5 basicDetailsLabel">{{info.routeName}}</label > - <span style="font-size: 15px">{{info.pickupName}}</span></span>
               <span class="pickupEta pull-right">{{info.pickupEta}}</span>
           </div>
           <div class="pickupEta"></div>
           
             <table class="table table-condensed table-bordered bg-info animated fade-in" *ngIf="!showTableRowLoader ">
             <thead>
             <tr>
                 <th class="text-center" rowspan="2" style="vertical-align: middle;">#</th>
                 <th class="text-center" rowspan="2" style="vertical-align: middle;">Member Name</th>
                 <th class="text-center mr5" colspan="2">Call Details</th>
                 <th class="text-center" rowspan="2" style="vertical-align: middle;">Call Log Time</th>
                 <th class="text-center" rowspan="2" style="vertical-align: middle;">Vehicle Arrival</th>
                 <!--<th class="text-center" rowspan="2" style="vertical-align: middle;">Time Difference</th>-->
             </tr>
             <tr>
                 <th class="text-center">Mobile Number</th>
                 <th class="">
                     <div class="primaryCallInfo  mr5" style="display:inline-block">
                         <i class="fa fa-phone mr5 reportBodyIcon" aria-hidden="true"></i>
                     </div>&nbsp;
                     <div class="primaryCallInfo  mr5" style="display:inline-block">
                         <i class="fa fa-envelope mr5 reportBodyIcon" aria-hidden="true"></i>
                     </div>&nbsp;
                     <div class="primaryCallInfo  mr5" style="display:inline-block">
                     <i class="fa fa-mobile mr5 reportBodyIcon" aria-hidden="true"></i>
                 </div>&nbsp;
                 </th>
             </tr>
         </thead>
         <tbody>
             <tr *ngFor="let studentDetails of info.studentDetails;let i = index">
                 <td class="inheritValign text-right">{{i+1}}</td>
                 <td class="inheritValign">
                     <div class="memberName mb5">{{studentDetails.memberName}}</div>
                     <div class="memberClassInfo">
                         <span class="memberClass text-muted">{{studentDetails.memberClass}}</span>,
                         <span class="memberSection text-muted">{{studentDetails.memberSection}}</span>
                     </div>
                 </td>
                 <td>
                     <div class="primaryMobile text-right mb5">{{studentDetails.mobileNo}}</div>
                     <div class="secondaryMobile text-right">{{studentDetails.mobileNo2}}</div>
                 </td>
                 <td>
                     <div class="primaryMobileInfo " *ngIf="studentDetails.mobileNo">
                         <span class="mr5 text-right" *ngIf="studentDetails.call1;then showTick else showCross">{{studentDetails.call1}}</span>&nbsp;&nbsp;
                         <span class="mr5 text-right" *ngIf="studentDetails.sms1;then showTick else showCross">{{studentDetails.sms1}}</span>&nbsp;&nbsp;
                         <span class="mr5 text-right" *ngIf="studentDetails.app;then showTick else showCross"></span>&nbsp;&nbsp;
                     </div>
                     <div class="secondaryMobileInfo "*ngIf="studentDetails.mobileNo2">
                         <span class="mr5 text-right" *ngIf="studentDetails.call2;then showTick else showCross">{{studentDetails.call1}}</span>&nbsp;&nbsp;
                         <span class="mr5 text-right" *ngIf="studentDetails.sms2;then showTick else showCross">{{studentDetails.sms1}}</span>&nbsp;&nbsp;
                         <span class="mr5 text-right" *ngIf="studentDetails.app;then showTick else showCross"></span>&nbsp;&nbsp;
                     </div>
                     
                 </td>
                 <td class="inheritValign text-right">{{studentDetails.callTriggerTime | unixTimestampFormatter}}</td>
                 <td class="inheritValign text-right"><div *ngIf="studentDetails.vehReachTime; else showHyphen">{{studentDetails.vehReachTime | unixTimestampFormatter}}</div></td>
                 <!--<td class="inheritValign">{{studentDetails.callmadeDiff*60 | secondsToHhMm}}</td>-->
             </tr>
         </tbody>
             </table>
             </div>
 </div>
 
       <div class="row filteredDataSet" *ngFor="let route of newAlertConfigRoutes;let $index=index;"  style=" ">
         <div class="col-md-12" *ngIf="!callAlertSessionWise">
           <table class="table table-condensed filteredOrgRoutes" style="">
             <tbody>
                 <tr class="text-center">
                   <td class="col-md-1" (click)="expandRowCallAlertLog($index,route.routeId,route.routeName)" style="cursor:pointer"><i class="glyphicon glyphicon-plus"></i></td>
                   <td class="col-md-3 text-left">{{route.routeName}}</td>
                   
                 </tr>
             </tbody>
           </table>
         </div>
         <div class="col-md-12">
         <div *ngIf="showTableRowLoader && !callAlertSessionWise">
        <div class="table-loader-container text-center" *ngIf="$index ===  this.storeIndex">
        <i class="fa fa-spinner fa-spin" style="font-size:24px" style="margin-right:5px"></i>
        Loading...
    </div>
    </div>
         <div class="call-log-details" *ngFor="let info of callAlertLog"  > 
         <div class="pickupInfo mb5" *ngIf="$index === expandedIndex  && !callAlertSessionWise"> 
               <span class=""><label class="control-label mr5 basicDetailsLabel">{{info.routeName}}</label > - <span style="font-size: 15px">{{info.pickupName}}</span></span>
               <span class="pickupEta pull-right">{{info.pickupEta}}</span>
           </div>
           <div class="pickupEta"></div>
           
             <table class="table table-condensed table-bordered bg-info animated fade-in" *ngIf="!showTableRowLoader && $index === expandedIndex">
             <thead>
             <tr>
                 <th class="text-center" rowspan="2" style="vertical-align: middle;">#</th>
                 <th class="text-center" rowspan="2" style="vertical-align: middle;">Member Name</th>
                 <th class="text-center mr5" colspan="2">Call Details</th>
                 <th class="text-center" rowspan="2" style="vertical-align: middle;">Call Log Time</th>
                 <th class="text-center" rowspan="2" style="vertical-align: middle;">Vehicle Arrival</th>
                 <!--<th class="text-center" rowspan="2" style="vertical-align: middle;">Time Difference</th>-->
             </tr>
             <tr>
                 <th class="text-center">Mobile Number</th>
                 <th class="">
                     <div class="primaryCallInfo  mr5" style="display:inline-block">
                         <i class="fa fa-phone mr5 reportBodyIcon" aria-hidden="true"></i>
                     </div>&nbsp;
                     <div class="primaryCallInfo  mr5" style="display:inline-block">
                         <i class="fa fa-envelope mr5 reportBodyIcon" aria-hidden="true"></i>
                     </div>&nbsp;
                     <div class="primaryCallInfo  mr5" style="display:inline-block">
                     <i class="fa fa-mobile mr5 reportBodyIcon" aria-hidden="true"></i>
                 </div>&nbsp;
                 </th>
             </tr>
         </thead>
         <tbody>
             <tr *ngFor="let studentDetails of info.studentDetails;let i = index">
                 <td class="inheritValign text-right">{{i+1}}</td>
                 <td class="inheritValign">
                     <div class="memberName mb5">{{studentDetails.memberName}}</div>
                     <div class="memberClassInfo">
                         <span class="memberClass text-muted">{{studentDetails.memberClass}}</span>,
                         <span class="memberSection text-muted">{{studentDetails.memberSection}}</span>
                     </div>
                 </td>
                 <td>
                     <div class="primaryMobile text-right mb5">{{studentDetails.mobileNo}}</div>
                     <div class="secondaryMobile text-right">{{studentDetails.mobileNo2}}</div>
                 </td>
                 <td>
                     <div class="primaryMobileInfo " *ngIf="studentDetails.mobileNo">
                         <span class="mr5 text-right" *ngIf="studentDetails.call1;then showTick else showCross">{{studentDetails.call1}}</span>&nbsp;&nbsp;
                         <span class="mr5 text-right" *ngIf="studentDetails.sms1;then showTick else showCross">{{studentDetails.sms1}}</span>&nbsp;&nbsp;
                         <span class="mr5 text-right" *ngIf="studentDetails.app;then showTick else showCross"></span>&nbsp;&nbsp;
                     </div>
                     <div class="secondaryMobileInfo "*ngIf="studentDetails.mobileNo2">
                         <span class="mr5 text-right" *ngIf="studentDetails.call2;then showTick else showCross">{{studentDetails.call1}}</span>&nbsp;&nbsp;
                         <span class="mr5 text-right" *ngIf="studentDetails.sms2;then showTick else showCross">{{studentDetails.sms1}}</span>&nbsp;&nbsp;
                         <span class="mr5 text-right" *ngIf="studentDetails.app;then showTick else showCross"></span>&nbsp;&nbsp;
                     </div>
                     
                 </td>
                 <td class="inheritValign text-right">{{studentDetails.callTriggerTime | unixTimestampFormatter}}</td>
                 <td class="inheritValign text-right"><div *ngIf="studentDetails.vehReachTime; else showHyphen">{{studentDetails.vehReachTime | unixTimestampFormatter}}</div></td>
                 <!--<td class="inheritValign">{{studentDetails.callmadeDiff*60 | secondsToHhMm}}</td>-->
             </tr>
         </tbody>
             </table>
             </div>
         </div>
       </div>
     </section>
   </div>
    
 </section>
           







                <div class="call-log-container" *ngIf="!noCallAlertData; else noDataFound">
                    <div class="call-log-details" *ngFor="let info of callAlertLog">
                        <div class="pickupInfo mb5"> 
                            <span class=""><label class="control-label mr5 basicDetailsLabel">{{info.routeName}}</label > - <span style="font-size: 15px">{{info.pickupName}}</span></span>
                            <span class="pickupEta pull-right">{{info.pickupEta}}</span>
                        </div>
                        <div class="pickupEta"></div>
                        <div class="table-container">
                            <table class="table table-bordered table-stripped call-details-table" >
                                <thead>
                                    <tr>
                                        <th class="text-center" rowspan="2" style="vertical-align: middle;">#</th>
                                        <th class="text-center" rowspan="2" style="vertical-align: middle;">Member Name</th>
                                        <th class="text-center mr5" colspan="2">Call Details</th>
                                        <th class="text-center" rowspan="2" style="vertical-align: middle;">Call Log Time</th>
                                        <th class="text-center" rowspan="2" style="vertical-align: middle;">Vehicle Arrival</th>
                                        <!--<th class="text-center" rowspan="2" style="vertical-align: middle;">Time Difference</th>-->
                                    </tr>
                                    <tr>
                                        <th class="text-center">Mobile Number</th>
                                        <th class="">
                                            <div class="primaryCallInfo  mr5" style="display:inline-block">
                                                <i class="fa fa-phone mr5 reportBodyIcon" aria-hidden="true"></i>
                                            </div>&nbsp;
                                            <div class="primaryCallInfo  mr5" style="display:inline-block">
                                                <i class="fa fa-envelope mr5 reportBodyIcon" aria-hidden="true"></i>
                                            </div>&nbsp;
                                            <div class="primaryCallInfo  mr5" style="display:inline-block">
                                            <i class="fa fa-mobile mr5 reportBodyIcon" aria-hidden="true"></i>
                                        </div>&nbsp;
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let studentDetails of info.studentDetails;let i = index">
                                        <td class="inheritValign text-right">{{i+1}}</td>
                                        <td class="inheritValign">
                                            <div class="memberName mb5">{{studentDetails.memberName}}</div>
                                            <div class="memberClassInfo">
                                                <span class="memberClass text-muted">{{studentDetails.memberClass}}</span>,
                                                <span class="memberSection text-muted">{{studentDetails.memberSection}}</span>
                                            </div>
                                        </td>
                                        <td>
                                            <div class="primaryMobile text-right mb5">{{studentDetails.mobileNo}}</div>
                                            <div class="secondaryMobile text-right">{{studentDetails.mobileNo2}}</div>
                                        </td>
                                        <td>
                                            <div class="primaryMobileInfo " *ngIf="studentDetails.mobileNo">
                                                <span class="mr5 text-right" *ngIf="studentDetails.call1;then showTick else showCross">{{studentDetails.call1}}</span>&nbsp;&nbsp;
                                                <span class="mr5 text-right" *ngIf="studentDetails.sms1;then showTick else showCross">{{studentDetails.sms1}}</span>&nbsp;&nbsp;
                                                <span class="mr5 text-right" *ngIf="studentDetails.app;then showTick else showCross"></span>&nbsp;&nbsp;
                                            </div>
                                            <div class="secondaryMobileInfo "*ngIf="studentDetails.mobileNo2">
                                                <span class="mr5 text-right" *ngIf="studentDetails.call2;then showTick else showCross">{{studentDetails.call1}}</span>&nbsp;&nbsp;
                                                <span class="mr5 text-right" *ngIf="studentDetails.sms2;then showTick else showCross">{{studentDetails.sms1}}</span>&nbsp;&nbsp;
                                                <span class="mr5 text-right" *ngIf="studentDetails.app;then showTick else showCross"></span>&nbsp;&nbsp;
                                            </div>
                                            
                                        </td>
                                        <td class="inheritValign text-right">{{studentDetails.callTriggerTime | unixTimestampFormatter}}</td>
                                        <td class="inheritValign text-right"><div *ngIf="studentDetails.vehReachTime; else showHyphen">{{studentDetails.vehReachTime | unixTimestampFormatter}}</div></td>
                                        <!--<td class="inheritValign">{{studentDetails.callmadeDiff*60 | secondsToHhMm}}</td>-->
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div> 
            </section>






            <section class="callAlertLogCountContent" *ngIf="reportSections.callLogCount &&  reportSectionsContent.callLogCount">
                <div class="panel panel-default">
                    <div class="panel-heading">
                        <div class="row vehicleBasicDetails mb5">
                             <div class="reportHeading text-center mb5">Call Log count</div>
                        </div>
                    </div>
                    <div class="panel-body" style="height:515px;overflow-y:hidden">


                    <div class="panel-group">
                    <div class="panel panel-default">
                      <div class="panel-heading">
                    
                                    <div class="row">
                                        <div class="col-xs-6 text-left">RouteName: 
                                        <span *ngIf="selectedRouteName"><b> {{ selectedRouteName }} </b></span>    
                                        <span *ngIf="!selectedRouteName"><b>All Routes</b></span> 
                                        </div>
                                       
                                        <div class="col-xs-6 text-right">
                                            <a data-toggle="collapse" href="#collapse1"><i class="fa fa-filter"></i> Apply Filters</a>
                                        </div>
                                    </div>
                        </div>
                      <div id="collapse1" class="panel-collapse collapse">
                        <div class="panel-body" style="height:13vh;overflow:hidden;">
                            <div class="row mb5">
                                <div class="form-group col-md-3">
                                    <input class="chk regular-checkbox" type="checkbox" style="outline:none" [checked]="callCountLogInbuiltFilter.showOnlyCallCount" (change)="callCountLogInbuiltFilter.showOnlyCallCount = !callCountLogInbuiltFilter.showOnlyCallCount">
                                    <label class="control-label" style="vertical-align:middle">Only call Count</label>
                                </div>
                                <div class="form-group col-md-3">
                                    <input class="chk regular-checkbox" type="checkbox" style="outline:none" [checked]="callCountLogInbuiltFilter.showOnlySmsCount" (change)="callCountLogInbuiltFilter.showOnlySmsCount = !callCountLogInbuiltFilter.showOnlySmsCount">
                                    <label class="control-label" style="vertical-align:middle">Only SMS Count</label>
                                </div>
                                <div class="form-group col-md-3">
                                    <input class="chk regular-checkbox" type="checkbox" style="outline:none" [checked]="callCountLogInbuiltFilter.showOnlyAppCount" (change)="callCountLogInbuiltFilter.showOnlyAppCount = !callCountLogInbuiltFilter.showOnlyAppCount">
                                    <label class="control-label" style="vertical-align:middle">Only App count</label>
                                </div>
                            </div>  
                        </div>                       
                      </div>
                    </div>
                  </div>



                   <!-- <button data-toggle="collapse" class="btn_design" data-target="#demo">Collapsible</button>
                        <div class="row mb5" style="padding-left:8%" id="demo" class="collapse">
                            <div class="col-md-2">
                                 <label class="control-label">Filter by:</label>
                            </div> 
                            <div class="form-group col-md-3">
                                <input class="chk regular-checkbox" type="checkbox" style="outline:none" [checked]="callCountLogInbuiltFilter.showOnlyCallCount" (change)="callCountLogInbuiltFilter.showOnlyCallCount = !callCountLogInbuiltFilter.showOnlyCallCount">
                                <label class="control-label" style="vertical-align:middle">Only call Count</label>
                            </div>
                            <div class="form-group col-md-3">
                                <input class="chk regular-checkbox" type="checkbox" style="outline:none" [checked]="callCountLogInbuiltFilter.showOnlySmsCount" (change)="callCountLogInbuiltFilter.showOnlySmsCount = !callCountLogInbuiltFilter.showOnlySmsCount">
                                <label class="control-label" style="vertical-align:middle">Only SMS Count</label>
                            </div>
                            <div class="form-group col-md-3">
                                <input class="chk regular-checkbox" type="checkbox" style="outline:none" [checked]="callCountLogInbuiltFilter.showOnlyAppCount" (change)="callCountLogInbuiltFilter.showOnlyAppCount = !callCountLogInbuiltFilter.showOnlyAppCount">
                                <label class="control-label" style="vertical-align:middle">Only App count</label>
                            </div>
                        </div>-->
                        <div class="row">
                            <div class="col-md-12">
                                <table class="table table-condensed table-bordered custom-table animated fade-in"  *ngIf="!noCallAlertCountData; else noDataFound">
                                <!--
                                    <thead>
                                        <tr>
                                      
                                           <th class="text-center">#</th>
                                            <th class="text-center">Route Name</th>
                                       
                                            <th class="text-center" *ngFor="let selDate of callAlertLogSelectedDates">{{selDate | datestringtounix | date:'dd-LL-yyyy'}}</th>
                                        </tr>
                                    </thead>

                                -->
                                    <thead style="background:#eeeeee;">
                                        
                                        <th class="text-center">Date</th>
                                        <th class="text-center" *ngIf="callCountLogInbuiltFilter.showOnlyCallCount">Call</th>
                                        <th class="text-center" *ngIf="callCountLogInbuiltFilter.showOnlySmsCount">SMS</th>
                                        <th class="text-center"  *ngIf="callCountLogInbuiltFilter.showOnlyAppCount">APP</th>
                                        

                                    </thead>
                                    
                                    <tbody>
                                    <ng-container *ngFor="let count of ext_key(callAlertLogDatesCount)">
                                   
                                    <tr>
                                    <td class="text-right">{{count.split('-').reverse().join('-')}}</td>
                                    <td class="text-right" *ngIf="callCountLogInbuiltFilter.showOnlyCallCount">{{callAlertLogDatesCount[count].orgTotalCallCnt}}</td>
                                    <td class="text-right" *ngIf="callCountLogInbuiltFilter.showOnlySmsCount">{{callAlertLogDatesCount[count].orgTotalSmsCnt}}</td>
                                    <td class="text-right" *ngIf="callCountLogInbuiltFilter.showOnlyAppCount">{{callAlertLogDatesCount[count].orgTotalAppCnt}}</td>                                   
                                    </tr>
                                    </ng-container>

                                    </tbody>


                                    <!-- <tbody style="height:395px">
                                        
                                        <tr>
                                          
                                                <td class="text-center">Total Count</td>
                                                <td class="text-center">
                                                    <span *ngIf="selectedRouteName"> {{ selectedRouteName }} </span>    
                                                    <span *ngIf="!selectedRouteName"> -- </span> 
                                                </td>
                                        
                                            <td class="text-center" *ngFor="let count of callAlertLogDatesCount" style="line-height:2.3">
                                                <div title="Total calls count" style="display:inline-block" *ngIf="callCountLogInbuiltFilter.showOnlyCallCount">
                                                    <div style="margin-right:5px;"><i class="fa fa-phone mr5 reportBodyIcon" aria-hidden="true"></i></div>
                                                    <div style="margin-right:5px;font-weight: bold;">{{count.orgCallMadeCnt}}</div>
                                                  
                                                </div>
                                                <div title="Total SMS sent" style="display:inline-block" *ngIf="callCountLogInbuiltFilter.showOnlySmsCount">
                                                    <div style="margin-right:5px;"><i class="fa fa-envelope mr5 reportBodyIcon" aria-hidden="true"></i></div>
                                                    <div style="margin-right:5px;font-weight: bold;">{{count.orgSmsMadeCnt}}</div>
                            
                                                </div>
                                                <div title="Total App alerts" style="display:inline-block" *ngIf="callCountLogInbuiltFilter.showOnlyAppCount">
                                                    <div style="margin-right:5px;"><i class="fa fa-mobile mr5 reportBodyIcon" aria-hidden="true" style="font-size:16px"></i></div>
                                                    <div style="margin-right:5px;font-weight: bold;">{{count.orgAppMadeCnt}}</div>
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody> -->
                                    <ng-template #noDataFound>
                                        <h4 class="animated pulse text-center" style="animation-duration: 1s;color:#999FBF"><i class="fa fa-frown-o"></i>&nbsp;<span class="meager-e2e-call-alert-log-no-records">No records found</span></h4>
                                    </ng-template>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section class="appAlertLogSectionContent" *ngIf="reportSections.appalertmadelog && reportSectionsContent.appalertmadelog">
                <div class="reportHeading text-center mb5">App Alert Log</div>
                <div class="row vehicleBasicDetails" *ngIf="isDateSelected">
                    <div class="col-md-12">
                        <div class="col-md-6">
                            <label class="control-label mr5 basicDetailsLabel">Report Start Date:</label>
                            <span>{{reportBasicDetails.fromDate}},{{reportBasicDetails.fromTime}}</span>
                        </div>
                        <div class="col-md-6 text-right">
                            <label class="control-label mr5 basicDetailsLabel">Report End Date:</label>
                            <span>{{reportBasicDetails.toDate}},{{reportBasicDetails.toTime}}</span>
                        </div>

                    </div>
                </div>
                <table class="table table-stripped custom-table" *ngIf="appAlertLogExists; else noDataFound">
                    <thead>
                        <tr>
                            <th style="width: 8%;">#</th>
                            <th>Member Name</th>
                            <th>Mobile Number</th>
                            <th>Pickup Arrival</th>
                            <th>Call Timing</th>
                            <th>Duration<i class="fa fa-filter mr5 pull-right reportIcon AppAlertFilterIcon" [style.color]="showAppAlertFilterOptions ? '#65c8ea' : 'rgba(0, 0, 0, 0.54)'" style="" (click)="showAppAlertFilterOptions = !showAppAlertFilterOptions"></i></th>
                        </tr>
                        <tr class="animated fade-in" *ngIf="showAppAlertFilterOptions">
                                <th></th>
                              <th><input class="custom-form-control" placeholder="Member Name"  [(ngModel)]="appLogFilterName" (keydown.enter)="setAlertLogTableFilter($event.target.value,'memberName')"></th>
                              <th><input class="custom-form-control" placeholder="Mobile Number" [(ngModel)]="appLogFilterMobile" (keydown.enter)="setAlertLogTableFilter($event.target.value,'mobileNo')"></th>
                                    <th></th>
                                    <th></th>
                              <th><i class="fa fa-refresh pull-right reportIcon" (click)="appLogFilterName='';appLogFilterId='';appLogFilterMobile='';resetAppLogFilters()"></i></th>
                        </tr>
                    </thead>
                    <tbody  style="height:495px">
                        <tr *ngFor="let callInfo of appAlertLog;let i = index">
                            <th style="width: 8%;">{{i+1}}</th>
                            <td>
                                <div class="memberInfo" style="margin-top:5%">
                                    <div class='memberName mb5'>{{callInfo.memberName|titlecase}}</div>
                                    <div class="memberRouteDetails text-muted mb5">
                                        <div class="memberRouteName">{{callInfo.routeName}}</div>
                                        <div class="memberPickupName">{{callInfo.pickupName}}</div>
                                    </div>
                                </div>
                            </td>
                            <td>
                                <div class="mobileInfo">
                                    <div class="mobileNumber mb5">{{callInfo.mobileNo}}</div>
                                    <div class="mobileOsType text-muted">{{callInfo.ostype}}</div>
                                </div>
                            </td>
                            <td *ngIf="callInfo.pickupReachTime; else showBlankField">{{callInfo.pickupReachTime | unixTimestampFormatter}}</td>
                            <td *ngIf="callInfo.logTimeMS;else showBlankField">{{callInfo.logTimeMS | unixTimestampFormatter}}</td>
                            <td *ngIf="callInfo.callmadeDiff;else showBlankField">{{callInfo.callmadeDiff | secondsToHhMm}}</td>
                        </tr>
                    </tbody>
                </table>
            </section>
            <section class="smsAlertLogSectionContent" *ngIf="reportSections.smsalertmadelog && reportSectionsContent.smsalertmadelog">
                <div class="reportHeading text-center mb5" style="border-bottom: 1px solid #eee;height: 60px;">SMS Alert Log</div>
                <div class="row vehicleBasicDetails" *ngIf="isDateSelected">
                    <div class="col-md-12">
                        <div class="col-md-6">
                            <label class="control-label mr5 basicDetailsLabel">Report Start Date:</label>
                            <span>{{reportBasicDetails.fromDate}},{{reportBasicDetails.fromTime}}</span>
                        </div>
                        <div class="col-md-6 text-right">
                            <label class="control-label mr5 basicDetailsLabel">Report End Date:</label>
                            <span>{{reportBasicDetails.toDate}},{{reportBasicDetails.toTime}}</span>
                        </div>

                    </div>
                </div>

                <table class="table table-stripped custom-table" *ngIf="smsLogExists; else noDataFound">
                    <thead>
                        <tr>
                            <th style="width:8%">#</th>
                            <th>Member Name</th>
                            <th>Mobile Number</th>
                            <th>Pickup Arrival</th>
                            <th>Call Timing</th>
                            <th>Duration</th>
                        </tr>
                    </thead>
                    <tbody  style="height:495px">
                        <tr *ngFor="let callInfo of smsAlertLog;let i = index">
                            <td style="width:8%">{{i+1}}</td>
                            <td>
                                <div class="memberInfo" style="margin-top:5%">
                                    <div class='memberName mb5'>{{callInfo.memberName|titlecase}}</div>
                                    <div class="memberRouteDetails text-muted mb5">
                                        <div class="memberRouteName">{{callInfo.routeName}}</div>
                                        <div class="memberPickupName">{{callInfo.pickupName}}</div>
                                    </div>
                                </div>
                            </td>
                            <td>{{callInfo.mobileNo}}</td>
                            <td *ngIf="callInfo.pickupReachTime;else showBlankField">{{callInfo.pickupReachTime | unixTimestampFormatter}}</td>
                            <td *ngIf="callInfo.logTimeMS;else showBlankField">{{callInfo.logTimeMS | unixTimestampFormatter}}</td>
                            <td *ngIf="callInfo.callmadeDiff;else showBlankField">{{callInfo.callmadeDiff | secondsToHhMm}}</td>
                        </tr>
                    </tbody>
                </table>
            </section>

            <section class="alertLogSummarySection" *ngIf="reportSections.alertlogsummary && reportSectionsContent.alertlogsummary"></section>
            <section class="routehistoryLogSection" *ngIf="reportSections.routehistorylog && reportSectionsContent.routehistorylog">
                <div class="panel panel-default">
                    <div class="panel-heading">
                        <div class="row vehicleBasicDetails mb5">
                            <div class="reportHeading text-center mb5">Route Pickup history
                            </div>
                        </div>
                        <div class="row vehicleBasicDetails">
                            <div class="col-md-6">
                                <label class="control-label mr5 basicDetailsLabel">Route Name:</label>
                                <span>{{rawRouteHistoryLog.routeInformation.name}}</span>
                            </div>
                            <div class="col-md-6 text-right">
                                <label class="control-label mr5 basicDetailsLabel">Route Start Time:</label>
                                <span>{{rawRouteHistoryLog.routeInformation.startTime}}</span>
                            </div>
                        </div>
                        <div class="row vehicleBasicDetails">
                                <div class="col-md-6 text-left">
                                    <label class="control-label mr5 basicDetailsLabel">Vehicle Name:</label>
                                    <span>{{rawRouteHistoryLog.routeInformation.vehNo}}</span>
                                </div>
                                <div class="col-md-6 text-right">
                                    <label class="control-label mr5 basicDetailsLabel">Route End Time:</label>
                                    <span>{{rawRouteHistoryLog.routeInformation.endTime}}</span>
                                </div>
                        </div>
                        <div class="row">
                            <div class="col-md-12 reportExcelDownloadLink text-right mb5">
                                <a href="{{routeHistoryExcelDownloadLink}}" class="reportExcelDownloadLink" target="_blank">Download excel Report</a>
                            </div>
                        </div>
                    </div>
                    <div class="panel-body">
                        <div class="row">
                            <div class="col-md-12 ChartContainer" id="routeLogChartContainer" style="min-width: 310px;height: 400px; margin: 0 auto"></div>
                            <div class="col-md-12  reportTableContainer" *ngFor="let route of routeHistoryLog">
                                <div class="form-group">
                                    <label class="control-label">Date:</label>
                                    <span>{{route.date | datestringtounix | date:'dd-LL-yyyy'}}</span>
                                    <div style="height: 1px;border: 1px solid #64c7e9;width: 3em;"></div>
                                </div>

                                <table class="table table-condensed table-bordered animated fade-in" >
                                    <thead>
                                        <tr>
                                            <th class="text-center">Pickup Point</th>
                                            <th class="text-center">Pickup Point ETA</th>
                                            <th class="text-center">Call Trigger Time</th>
                                            <th class="text-center">Total Members</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr  *ngFor="let pickupInfo of route.pickup">
                                            <td class="text-left">{{pickupInfo.pickUp}}</td>
                                            <td class="text-right">{{pickupInfo.eta}}</td>
                                            <td class="text-right">{{pickupInfo.pickupReachTime}}</td>
                                            <td class="text-right">{{pickupInfo.memberCount}}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section class="memberHistoryLog" *ngIf="reportSections.memberhistorylog && reportSectionsContent.memberhistorylog">
                <div class="panel panel-default">
                    <div class="panel-heading">
                        <div class="row memberBasicDetails mb5">
                            <div class="reportHeading text-center mb5">Member Pickup history
                            </div>
                        </div>
                        <div class="row"> 
                            <div class="col-md-6 text-left">
                                <label class="control-label">Member Name : </label>
                                <span>{{memberBasicInfo.membername}}</span>
                            </div>
                            <div class="col-md-6 text-right">
                                <label class="control-label">Member Class : </label>
                                <span>{{memberBasicInfo.memberclass}},{{memberBasicInfo.membersec}}</span>
                            </div>

                        </div>

                        <div class="row">
                            <div class="col-md-6 text-left">
                                <label class="control-label">Member Pickup Route : </label>
                                <span>{{memberRouteInfo.pickup.routeName}}</span>
                            </div>
                            <div class="col-md-6 text-right">
                                <label class="control-label">Member Drop Route : </label>
                                <span>{{memberRouteInfo.drop.routeName}}</span>
                            </div>
                     </div>
                     <div class="row">

                        <div class="col-md-6 text-left">
                            <label class="control-label">Member Pickup : </label>
                            <span>{{memberBasicInfo.pickupName}}</span>
                        </div>

                        <div class="col-md-6 text-right">
                            <label class="control-label">Member Drop : </label>
                            <span>{{memberBasicInfo.pickupName}}</span>
                        </div>
                            
                     </div>

                       
                        <div class="row">
                                <div class="col-md-6 text-left">
                                <label class="control-label">Member UID : </label>
                                <span>{{memberBasicInfo.memberUID}}</span>
                            </div>
                            <div class="col-md-6 text-right">
                                <label class="control-label">Member Contact Info : </label>
                                <span>{{memberBasicInfo.memberPrimaryMobileno}}</span>
                            </div>
                        </div>
                    </div>
                    <div class="panel-body">
                        <div class="row">
                            <div class="col-md-12  reportTableContainer tableResponsive">
                                <table class="table table-bordered">
                                <thead>
                                    <tr>
                                         <th class="text-center" rowspan="2" style="vertical-align: middle;">Date</th>
                                         <th class="text-center" colspan="2">Pickup Details</th>
                                         <th class="text-center" colspan="2">Drop Details</th>
                                    </tr>
                                    <tr>
                                         <th class="text-center">Pickup ETA</th>
                                         <th class="text-center">Call Trigger Time</th>
                                         <th class="text-center">Drop ETA</th>
                                         <th class="text-center">Call Trigger Time</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let info of memberHistoryLog">
                                        <td class="text-right">{{info.date | datestringtounix | date: 'dd-LL-yyyy'}}</td>
                                        <td class="text-right">{{info.pickup.expectedTime}}</td>
                                        <td class="text-right">{{info.pickup.pickupReachTime}}</td>
                                        <td class="text-right">{{info.drop.expectedTime}}</td>
                                        <td class="text-right">{{info.drop.pickupReachTime}}</td>
                                    </tr>
                                </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section *ngIf="reportSections.callMadeReport && reportSectionsContent.callMadeReport" >
            
            <h2 class="text-center">Call Made Report : Day Wise</h2>
            <div class="table-responsive" *ngIf="dispRouteInformationList?.length>0; else falsyTemplate" >
            
                    <!-- <caption class="pull-left" style="color: #353333;font-weight: 600;"> -->
                    
                    <!-- <i class="fa fa-calendar"> {{displaySelectedDate}} </i><br> -->
                    <table class="table-bordered tablelike text-center">
                    <tr>
                    <td>
                         Total Call Count :
                   <ng-container *ngIf="callMadeTemplate == true">
                            <i class="fa fa-spinner fa-pulse fa-fw"></i>
                            <span class="sr-only">Loading...</span>
                   </ng-container>
                   <ng-container *ngIf="callMadeTemplate == false">
                   {{ovralCount}}
                   </ng-container>
                    </td>
                    <td>
                    Call Made Count :
                    <ng-container *ngIf="callMadeTemplate == true">
                             <i class="fa fa-spinner fa-pulse fa-fw"></i>
                             <span class="sr-only">Loading...</span>
                          
                    </ng-container>
                    <ng-container *ngIf="callMadeTemplate == false">
                    {{callMadeCount}}
                    </ng-container>
                    </td>
                    <td>
                            Call count :
                    <ng-container *ngIf="callMadeTemplate == true">
                             <i class="fa fa-spinner fa-pulse fa-fw"></i>
                             <span class="sr-only">Loading...</span>
                    </ng-container>
                    <ng-container *ngIf="callMadeTemplate == false">
                    {{callMadeTotal}}
                    </ng-container>
                    
                    </td>

                    </tr>
                    </table>
                    <br>
                 
            
                    <div class="row" >
                        <div class="col-md-12" style="border-bottom: 0px solid gainsboro!important; font-weight: 600; padding: 3px 6px 10px 0px;border: 0.5px solid gainsboro;">
                            <div class="col-md-4">
                                Route Name
                            </div>
                            <div class="col-md-4">
                                Call Count
                            </div>
                            <div class="col-md-4">
                                <i class="fa fa-expand"></i>
                            </div>
                        </div>
                </div>
               <!-- <div class="row" *ngFor="let item of dispRouteInformationList; let $index=index">-->

                <div id="divID1" class="row" *ngFor="let item of dispRouteInformationList; let $index=index">

                    <div class="col-md-12" style="border-bottom: 0px solid gainsboro!important; padding: 3px 6px 10px 0px;border: 0.5px solid gainsboro;">
                        
                    
                        <div class="col-md-4" style="text-align:left">
                            {{item.routeName}} 
                        </div>
                        <div class="col-md-4" style="text-align:right">
                            <span title="Call Made Count" style="text-align:right" >{{item.totalRouteCallCount}}</span>/
                            <span title="Total Call Count" style="text-align:right" >{{item.totalmobNoCount}}</span>
                        
                            </div>
                            <div class="col-md-4" style="cursor: pointer;" title="Click to Expend">
                            <i *ngIf="expendedIndex==$index && expendRouteName==item.routeName" class="fa fa-minus" style="color: #429db5;" (click)="showRouteTable($index,item.routeName)"></i> 
                                <i  *ngIf="expendedIndex!=$index || expendedIndex=='' && expendRouteName!=item.routeName" class="fa fa-plus" style="color: #429db5;" (click)="showRouteTable($index,item.routeName)"></i>
            
                            </div>
            
            
                            <table *ngIf="expendedIndex==$index && expendRouteName==item.routeName" class="table table-bordered table-hover" style="margin: 41px 5px 0 14px;box-shadow:1px 1px 1px 1px #eaeaea">
            
                                <thead>
                                <tr>
                                    <th>#</th>
                                    <th>Pickup Point</th>
                                    <th>Expected Time</th>
                                    <th>Call Made Time</th>
                                    
                                </tr>
                                </thead>
                                <tbody>
                                <tr *ngFor="let stoppage of item.pickupPoint;let i=index">
                                    <td style="text-align:right">{{i+1}}</td>
                                    <td style="width:45%;text-align:left">{{stoppage.pickuppointName}}</td>
                                    <td style="text-align:right">{{stoppage.eta}}</td>
                            <td *ngIf="stoppage.callTime!=''" style="text-align:right">{{stoppage.callTime * 1000 | date:'HH:mm'}}</td>
                                    <td *ngIf="stoppage.callTime==''" style="text-align:right">--</td>
                                   
                                </tr>
                                </tbody>
                    
                        </table>

                         
            
                    </div>
                </div>
                 <div class="row"  *ngIf="lastRes">
                    <div class="col-md-8" >
                             <loader></loader>
                        
                    </div>
                 </div>
                    
                   
      
          </div>
          <br><br>
          <ng-template #falsyTemplate >
            <h4 class="animated pulse text-center" style="animation-duration: 1s;color:#999FBF" >
                <i class="fa fa-frown-o" ></i>&nbsp;No data to display</h4>
          </ng-template>
            
            </section>




<section *ngIf="reportSections.callMadeEarly && reportSectionsContent.callMadeEarly" >
            
<h2 class="text-center">Call Made Early Report : Day Wise</h2>
<div class="table-responsive" *ngIf="dispRouteInformationListCallMadeEarly?.length>0; else falsyTemplate" >

        <!-- <caption class="pull-left" style="color: #353333;font-weight: 600;"> -->
        
        <!-- <i class="fa fa-calendar"> {{displaySelectedDate}} </i><br> -->
        <table class="table-bordered tablelike text-center">
        <tr>
       
        
       

        </tr>
        </table>
        <br>
     

        <div class="row" >
            <div class="col-md-12" style="border-bottom: 0px solid gainsboro!important; font-weight: 600; padding: 3px 6px 10px 0px;border: 0.5px solid gainsboro;">
                <div class="col-md-4">
                    Route Name
                </div>
                
                <div class="col-md-4">
                    <i class="fa fa-expand"></i>
                </div>
            </div>
    </div>
   <!-- <div class="row" *ngFor="let item of dispRouteInformationListCallMadeEarly; let $index=index">-->

    <div id="divID1" class="row" *ngFor="let item of dispRouteInformationListCallMadeEarly; let $index=index">

        <div class="col-md-12" style="border-bottom: 0px solid gainsboro!important; padding: 3px 6px 10px 0px;border: 0.5px solid gainsboro;">
            
        
            <div class="col-md-4" style="text-align:left">
                {{item.routeName}} 
            </div>
           
                <div class="col-md-4" style="cursor: pointer;" title="Click to Expend">
                <i *ngIf="expendedIndex==$index && expendRouteName==item.routeName" class="fa fa-minus" style="color: #429db5;" (click)="showRouteTable($index,item.routeName)"></i> 
                    <i  *ngIf="expendedIndex!=$index || expendedIndex=='' && expendRouteName!=item.routeName" class="fa fa-plus" style="color: #429db5;" (click)="showRouteTable($index,item.routeName)"></i>

                </div>


                <table *ngIf="expendedIndex==$index && expendRouteName==item.routeName" class="table table-bordered table-hover" style="margin: 41px 5px 0 14px;box-shadow:1px 1px 1px 1px #eaeaea">

                    <thead>
                    <tr>
                        <th>#</th>
                        <th>Pickup Point</th>
                        <th>Expected Time</th>
                        <th>Call Made Time</th>
                        <th>Difference</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr *ngFor="let stoppage of item.pickupPoint;let i=index">
                        <td style="text-align:right">{{i+1}}</td>
                        <td style="width:45%;text-align:left">{{stoppage.pickuppointName}}</td>
                        <td style="text-align:right">{{stoppage.eta}}</td>
                <td *ngIf="stoppage.callTime!=''" style="text-align:right">{{stoppage.callTime * 1000 | date:'HH:mm'}}</td>
                        <td *ngIf="stoppage.callTime==''" style="text-align:right">--</td>
                        <td style="text-align:right">{{stoppage.diff}}</td>
                    </tr>
                    </tbody>
        
            </table>

             

        </div>
    </div>
     <div class="row"  *ngIf="lastRes">
        <div class="col-md-8" >
                 <loader></loader>
            
        </div>
     </div>
        
       

</div>
<br><br>
<ng-template #falsyTemplate >
<h4 class="animated pulse text-center" style="animation-duration: 1s;color:#999FBF" >
    <i class="fa fa-frown-o" ></i>&nbsp;No data to display</h4>
</ng-template>

</section>


<section *ngIf="reportSections.callMadeDelayed && reportSectionsContent.callMadeDelayed" >
            
<h2 class="text-center">Call Made Delayed Report : Day Wise</h2>
<div class="table-responsive" *ngIf="dispRouteInformationListCallMadeDelay?.length>0; else falsyTemplate" >

        <!-- <caption class="pull-left" style="color: #353333;font-weight: 600;"> -->
        
        <!-- <i class="fa fa-calendar"> {{displaySelectedDate}} </i><br> -->
        <table class="table-bordered tablelike text-center">
        <tr>
       
        
       

        </tr>
        </table>
        <br>
     

        <div class="row" >
            <div class="col-md-12" style="border-bottom: 0px solid gainsboro!important; font-weight: 600; padding: 3px 6px 10px 0px;border: 0.5px solid gainsboro;">
                <div class="col-md-4">
                    Route Name
                </div>
                
                <div class="col-md-4">
                    <i class="fa fa-expand"></i>
                </div>
            </div>
    </div>
   <!-- <div class="row" *ngFor="let item of dispRouteInformationListCallMadeDelay; let $index=index">-->

    <div id="divID1" class="row" *ngFor="let item of dispRouteInformationListCallMadeDelay; let $index=index">

        <div class="col-md-12" style="border-bottom: 0px solid gainsboro!important; padding: 3px 6px 10px 0px;border: 0.5px solid gainsboro;">
            
        
            <div class="col-md-4" style="text-align:left">
                {{item.routeName}} 
            </div>
           
                <div class="col-md-4" style="cursor: pointer;" title="Click to Expend">
                <i *ngIf="expendedIndex==$index && expendRouteName==item.routeName" class="fa fa-minus" style="color: #429db5;" (click)="showRouteTable($index,item.routeName)"></i> 
                    <i  *ngIf="expendedIndex!=$index || expendedIndex=='' && expendRouteName!=item.routeName" class="fa fa-plus" style="color: #429db5;" (click)="showRouteTable($index,item.routeName)"></i>

                </div>


                <table *ngIf="expendedIndex==$index && expendRouteName==item.routeName" class="table table-bordered table-hover" style="margin: 41px 5px 0 14px;box-shadow:1px 1px 1px 1px #eaeaea">

                    <thead>
                    <tr>
                        <th>#</th>
                        <th>Pickup Point</th>
                        <th>Expected Time</th>
                        <th>Call Made Time</th>
                        <th>Difference</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr *ngFor="let stoppage of item.pickupPoint;let i=index">
                        <td style="text-align:right">{{i+1}}</td>
                        <td style="width:45%;text-align:left">{{stoppage.pickuppointName}}</td>
                        <td style="text-align:right">{{stoppage.eta}}</td>
                <td *ngIf="stoppage.callTime!=''" style="text-align:right">{{stoppage.callTime * 1000 | date:'HH:mm'}}</td>
                        <td *ngIf="stoppage.callTime==''" style="text-align:right">--</td>
                        <td style="text-align:right">{{stoppage.diff}}</td>
                    </tr>
                    </tbody>
        
            </table>

             

        </div>
    </div>
     <div class="row"  *ngIf="lastRes">
        <div class="col-md-8" >
                 <loader></loader>
            
        </div>
     </div>
        
       

</div>
<br><br>
<ng-template #falsyTemplate >
<h4 class="animated pulse text-center" style="animation-duration: 1s;color:#999FBF" >
    <i class="fa fa-frown-o" ></i>&nbsp;No data to display</h4>
</ng-template>

</section>





<section *ngIf="reportSections.rOnTime && reportSectionsContent.rOnTime" >
            
<h2 class="text-center">Reached On Time Report : Day Wise</h2>
<div class="nav-tabs-custom" >
        <ul class="nav nav-tabs">
            <ng-container >
                <li *ngFor="let tab of newAlertConfigRoutes; let $index=index;" ><a data-target="#tab_1"
                        data-toggle="tab" (click)="expandRowReachedOnTime($index,tab.routeId,tab.routeName)" class="text-uppercase"><b>{{tab.routeName}}</b></a></li>
            </ng-container>
        </ul>
    </div>
	
	<div *ngIf="showTableRowLoader">
        <div class="table-loader-container text-center" >
        <i class="fa fa-spinner fa-spin" style="font-size:24px" style="margin-right:5px"></i>
        Loading...
    </div>
    </div>
	<div class="tab-content" *ngIf="ReachedOnTimeList?.length>0">
    <table  class="table table-bordered table-hover" style="margin: 41px 5px 0 14px;box-shadow:1px 1px 1px 1px #eaeaea">

    <thead>
    <tr>
        <th>#</th>
        <th>Pickup Point</th>
        <th>Expected Time</th>
        <th>Location Reached</th>
        <th>Difference</th>
    </tr>
    </thead>
    <tbody>
    <tr *ngFor="let stoppage of ReachedOnTimeList;let i=index">
        <td style="text-align:right">{{i+1}}</td>
        <td style="width:45%;text-align:left">{{stoppage.pickuppointName}}</td>
        <td style="text-align:right">{{stoppage.eta}}</td>
<td *ngIf="stoppage.callTime!=''" style="text-align:right">{{stoppage.callTime  | date:'HH:mm'}}</td>
        <td *ngIf="stoppage.callTime==''" style="text-align:right">--</td>
        <td style="text-align:right">{{stoppage.diff}}</td>
    </tr>
    </tbody>

</table>
    </div>
<br><br>
<ng-template #falsyTemplate >
<h4 class="animated pulse text-center" style="animation-duration: 1s;color:#999FBF" >
    <i class="fa fa-frown-o" ></i>&nbsp;No data to display</h4>
</ng-template>

</section>

<!-- style="position:relative;left:80px;top: -5px;"-->
<section *ngIf="reportSections.nrOnTime && reportSectionsContent.nrOnTime"   >
<ng-template #contentloader>
<div class="loaderContainer">
<loader></loader>
</div>
</ng-template>           
<h3 class="text-center">Not Reached On Time Report : Day Wise</h3>
<br>
<div class="table-responsive"  >

<div class="tab-content" *ngIf="!notReachedAllPPShow" >
<!--<div id="container" style="width: 100%; height: 400px; margin: 0 auto"> </div>-->
<div id="container5" style="width: 100%; height: 400px; margin: 0 auto"> </div>
<div class="row">
  <div class="column1" style="">
  <div id="container2" class="" style="width: 100%; height: 305px; margin-top:3px; margin-bottom: 3px; margin-right:1px; "> </div>
  </div>
  <div class="column" style="max-height: 400px;overflow-y: scroll;height:305px;width: 83%;" *ngIf="tableHideShowPie">
  <table id="tb1">
  <ng-container *ngFor="let routeNames of uniqueRouteNamesBasedGradesSorted" >
  
  
  <tr>
  <td style="text-align:left;vertical-align:top;padding:0"> <span>
  {{routeNames.routeName}}
   </span>
  </td>
  <td style="overflow: hidden;
  margin-left: 55px;
  height: 50px;
  line-height: 45px;text-align:left;vertical-align:top;padding:0">
  <ng-container *ngFor="let stoppage of notReachedAllPPSortedDisplayAll">
    <li  class="new1" title="Pickup :{{stoppage.ppName}}&#13;Difference :{{stoppage.differenceCallMadeReached}}" style="text-align:left;vertical-align:top;" [ngStyle]="getMyStyles(stoppage.tableGrade)" *ngIf="routeNames.routeName===stoppage.routeName" ><span class="text-secondary">{{stoppage.callMadeTime }}</span></li>
    </ng-container>
  </td>
  </tr>
  
    
     
    
    
    
  </ng-container>
  </table>
  
  </div>
</div>

<div class="container">
	<div class="row">
	<!--	<div class="col-md-5">
			<div id="container2" style="width: 100%; height: 400px; margin: 0 auto"> </div>
			
		</div> -->
	<!--	<div class="col-md-7" style="max-height: 400px;overflow-y: scroll;" *ngIf="tableHideShowPie">
        
        <table id="tb1">
        <ng-container *ngFor="let routeNames of uniqueRouteNames" >
        
        
        <tr>
        <td style="text-align:left;vertical-align:top;padding:0"> <span>
        {{routeNames}}
         </span>
        </td>
        <td style="overflow: hidden;
        margin-left: 55px;
        height: 50px;
        line-height: 45px;text-align:left;vertical-align:top;padding:0">
        <ng-container *ngFor="let stoppage of notReachedAllPPSortedDisplayAll">
          <li  class="new1" title="" style="text-align:left;vertical-align:top;" [ngStyle]="getMyStyles(stoppage.tableGrade)" *ngIf="routeNames===stoppage.routeName" ><span class="text-secondary">{{stoppage.callMadeTime }}</span></li>
          </ng-container>
        </td>
        </tr>
        
          
           
          
          
          
        </ng-container>
        </table>
        
        </div> -->
        <div class="col-md-12 " style="margin-top: 50px;">
        <div id="container1" style="width: 100%; height: 400px; margin: 0 auto"> </div>
        </div>
	</div>
</div>


</div>  
       
   
  

     
        
       

</div>
<br><br>
<ng-template #falsyTemplate >
<h4 class="animated pulse text-center" style="animation-duration: 1s;color:#999FBF" >
    <i class="fa fa-frown-o" ></i>&nbsp;No data to display</h4>
</ng-template>

</section>



            <section *ngIf="reportSections.callProcessedReport && reportSectionsContent.callProceesedReport;" >
            <h2 style="margin-top:3rem">Call Alert Timing Report </h2>
            <div *ngIf="contentLoading==false;else contentloader">
            <div class="container-fluid row" *ngIf="getVehicleReachLog.response != null;else falsytemplate">
            <div class="table-responsive" style="height:78vh;overflow-y:scroll !important">
            <table class="table table-bordered table-stripped table-hover" id="callprocessedreport">
            <thead>
            <tr>
            <div>
            <th class="mytd" style="text-align:center">S.No</th>
            <th  class="mytd" style="text-align:center">Name</th>
            <th  class="mytd myaddresstd" style="text-align:center">PickupName</th>

            <th  class="mytd" style="text-align:center">Eta</th>

            <th  class="mytd" style="text-align:center">Call Triggered</th>

            <!-- <th>Students(B)</th> -->

            <th  class="mytd" style="text-align:center">Vehicle Reached</th>
            <!-- only for admin temp serve -->
             <!--<th  class="mytd" style="text-align:center">Time Duration</th>-->
            
           <!-- <th  class="mytd" style="text-align:center">Grade</th> -->
           <!-- endof only for admin temp serve -->
            <th class="mytd" style="text-align:center">Count</th>
           
            </div>
            </tr>       
            </thead>
        
           <ng-container *ngFor="let key of getVehicleReachLog.response|objectkeys;let i = index;last as isLast">
            <tr *ngIf="difftimestamp(getVehicleReachLog.response[key]?.callTs,getVehicleReachLog.response[key]?.vehTs,'time')<1000">
           
            
            <serial-number>
               <ng-container callproceesedrepo> 

             
                <td class="mytd" style="text-align:right">{{i+1}}</td>
          
                <td  class="mytd" style="text-align:left">{{routeIdCollection[getVehicleReachLog.response[key]?.routeId]}}</td>
                <td  class="mytd myaddresstd" style="text-align:left">{{getVehicleReachLog.response[key]?.pickupname | str_replace:',':' ':true}}</td>
                <td  class="mytd" style="text-align:right">{{getVehicleReachLog.response[key]?.pickupeta}}</td>
                <td  class="mytd" style="text-align:right">{{getVehicleReachLog.response[key]?.callTs|date:'hh:mm:ss a'}}</td>
                <td  class="mytd" style="text-align:right">{{getVehicleReachLog.response[key]?.vehTs|date:'hh:mm:ss a'}}</td>
                <!-- only for admin temp serve -->
              <!-- <td  class="mytd">{{difftimestamp(getVehicleReachLog.response[key]?.callTs,getVehicleReachLog.response[key]?.vehTs,'time_str')}}</td> -->
               <!--<td  class="mytd">{{difftimestamp(getVehicleReachLog.response[key]?.callTs,getVehicleReachLog.response[key]?.vehTs,'grade')}}</td> -->
               <!-- end of only for admin temp serve -->
                <td  class="mytd" style="text-align:right">
                 <span *ngIf="this.callprocpkpstd[getVehicleReachLog.response[key].pickupPointId]" >
            {{ this.callprocpkpstd[getVehicleReachLog.response[key].pickupPointId] | nextedObjCount}}
 </span> 
 </td>
          
          
          </ng-container> 
            </serial-number>
          
          
                </tr>
            </ng-container>
            </table>   
            <div *ngIf="falsytemplateval"> 
            <serial-number>
            <ng-container  notFound>
              <h4 class="animated pulse text-center" style="animation-duration: 1s;color:#999FBF">
                <i class="fa fa-frown-o"></i>&nbsp;No data to display</h4>
                </ng-container>
            </serial-number>
            </div>
            </div>
            <br>
             </div>
            </div>
           
           
              </section>
             

        </div>
    </div>
</section>

<ng-template #showHyphen><div class="text-center">-</div></ng-template>
<ng-template #showBlankField><td>-</td></ng-template>
<ng-template #showTick>
    <i class="fa fa-check mr5" aria-hidden="true" style="color: #8BC34A;"></i>
</ng-template>
<ng-template #showCross>
    <i class="fa fa-close mr5" aria-hidden="true" style="color: #F44336;"></i>
</ng-template>
<ng-template #noDataFound>
    <h4 class="animated pulse text-center" style="animation-duration: 1s;color:#999FBF"><i class="fa fa-frown-o"></i>&nbsp;<span class="meager-e2e-call-processed-report-no-records">No records found</span></h4>
</ng-template>
<ng-template #pageLoader>
    <div class="loaderContainer">
        <loader></loader>
    </div>
</ng-template>
</div>
    `,
    styles: [`
        .availableReportOptions{
            border-right:1px solid #9e9e9e91;
            padding: 0.9em;
        }
        
        [data-tooltip]:before{
            left: 5% !important;
         }
#tb1 {
  position: relative;
  margin: 4px auto;
  width: 90%;
  display: contents;
    overflow-x: auto;
    white-space: nowrap;
 /* border: 1px solid #333;*/
}
#tb1 td, #tb1 th {
  /*border: 1px solid #999;*/
}
#tb1 td.col {
  width: 150px;
  max-width: 150px;
  word-break: break-all;
}
#tb1 td input {
  width: 130px;
}

        .new1 > span { 
    /*        position: absolute;
            top: 1.7em; 
            left: 0; 
            font-size:71%;
            line-height: 110%;
            content: attr(data-step); 
            color: #6c757d; */ 

            position: absolute;
            top: 1.8em; 
            left: -0.4em; 
            font-size:71%;
            line-height: 110%;
            
            content: attr(data-step); 
            color: #6c757d;
        
        }
.new1 {
  /*  width: 1em;
    height: 1em;
    text-align: center;
    line-height: 2em;
    border-radius: 1em;
    background: orange;
    margin: 0 1em;
    display: inline-block;
    color: white;
    position: relative;*/

    width: 1em;
    height: 1em;
    text-align: center;
    line-height: 5em;
    border-radius: 1em;
    background: orange;
    margin: 0 2em;
    display: inline-block;
    color: white;
    position: relative;
  }
  
 
  .new1::before{
   /* content: '';
    position: absolute;
    top: .5em;
    left: -4em;
    width: 4em;
    height: .01em;
    background:#BDBDBD;
    z-index: -1; */

    content: '';
    position: absolute;
    top: .5em;
    left: -5em;
    width: 6em;
    height: .01em;
    background:#BDBDBD;
    z-index: -1;

  } 
  
  * {
    box-sizing: border-box;
  }
  
  /* Create two equal columns that floats next to each other */
  .column {
    float: left;
    width: 83%;
    padding: 10px;
    height: 300px; /* Should be removed. Only for demonstration */
  }
  .column1 {
    float: left;
    width: 17%;
    padding: 6px;
    height: 300px; /* Should be removed. Only for demonstration */
  }
  .new1:first-child::before {
    display: none;
  }
  li > span { 
    position: absolute;
    top: 2em; 
    left: 0; 
    line-height: 110%;
    color: #000; }
        .reportHeading{
            font-size:1.7em;
        }
        div.fixedHeight{
            height: 582px;
            overflow-y:auto;
        }
        .applyFilterBtn,.viewReportBtn{
            color: #fff;
            background-color: #64c7e9;
            border-color: #64c7e9;
            padding: 12px 10px 12px 10px !important;
            font-size: 11px;
        }

        label.basicDetailsLabel {
            font-weight: 600;
            font-size: 14px;
        }
        .reportExcelDownloadLink{
            text-decoration: none;
            cursor: pointer;
        }
        .panel-heading{
            background:none;
            position:sticky;
        }
        .panel-body{
            height: 447px;
            overflow-y: auto;
        }
        .animation-bottom10{
        animation: animationBottom10 2s;
        }
        @keyframes animationBottom10 {
          0% {   transform:translateY(10px); }
          100%{  transform:translateY(0px); }
        }

      .border-deda {
        border: 1px solid #dedada;
        -webkit-box-shadow: 0 6px 4px -4px grey;
        -moz-box-shadow: 0 6px 4px -4px grey;
        box-shadow: 0 6px 4px -4px grey;
        height: 144px;
        overflow-y: auto;
        margin-left:-15px;
        margin-right:-15px;
      }

      .searchbar {
          padding-bottom: 20px;
      }
      .border-deda::-webkit-scrollbar {
          background-color: white;
          width: 2px;
      }

      .border-deda::-moz-scrollbar {
          background-color: white;
          width: 2px;
      }

      .border-deda::-webkit-scrollbar-thumb {
          background-color: #3498db;
      }

      .border-deda::-moz-scrollbar-thumb {
          background-color: #3498db;
      }

      input[type=search]::-webkit-search-cancel-button {
      -webkit-appearance: searchfield-cancel-button;
      }
      .reportIcon{
            cursor:pointer;
      }
      .callAlertFilterIcon{
          font-size: 18px;
          margin-top: 2px;
      }

      .custom-table tbody {
        display:block;
        height:440px;
        overflow:auto;
      }
      .custom-table thead, .custom-table tbody tr {
           display:table;
           width:100%;
           table-layout:fixed;
      }
      tr{
         height:48px;
      }
      .custom-table th {
        color: rgba(0, 0, 0, 0.54);
        font-size: 15px;
        font-weight: 500;
        white-space: nowrap;
      }
     .mytd{
                min-width: 50px !important;
                width: 209px;
                height: 50px;
                vertical-align: bottom;
                padding: 15px;
                text-align: left;
                border-bottom:1px solid silver;
      }
      .myaddresstd{
            width: 209px;
      }
      .custom-table tbody td{
        vertical-align:middle !important;
        color: rgba(0, 0, 0, 0.87) !important;
        font-size: 13px !important;
        border-top: 1px rgba(0, 0, 0, 0.12) solid !important;
        padding: 0 24px !important;
      }
      ::placeholder {
        color: #e4e4e4;
        opacity: 1; /* Firefox */
      }
    div.callLogHeader{
        box-shadow: 0 8px 6px -6px #8888882e;
    }  
    div.call-log-details > .table-container {
        padding: 1%;
    }
    .inheritValign{
        vertical-align: inherit;
    }
    .call-log-details{
        margin-bottom:3%;
    }
    .call-log-details:hover{
        box-shadow: 0 8px 6px -6px #8888882e;
    }
    .call-log-container{
        max-height: 515px;
        overflow: auto;
        padding:1%;
    }

    .col-md-6 span {
        padding: 3px 0px 5px 8px;
    }

    div.pickupInfo{
        margin-bottom: 10px;
        border-left: 5px solid #03A9F4;
        padding: 1%;
        font-size: 1.2em;
        color: #9e9e9eeb;
        background: #9e9e9e0a;
    }
    .call-details-table th{
        font-weight: 500;
        color: rgb(130, 130, 130);
    }
    div.memberClassInfo{
        font-size:13px;
    }
    .loaderContainer{
        margin: 20% 0 0 30%;
    }

    .form-group.col-md-6 {
        padding: 5px 6px 0px 3px;
    }
    .table-responsive{
         overflow: hidden!important;
    }
    .apiloader {
        border: 5px solid #f3f3f3;
        border-radius: 50%;
        border-top: 5px solid #616161; 
        width: 50px;
        height: 50px;
        -webkit-animation: spin 2s linear infinite;
        animation: spin 2s linear infinite;
      }
      
      @-webkit-keyframes spin {
        0% { -webkit-transform: rotate(0deg); }
        100% { -webkit-transform: rotate(360deg); }
      }
      
      @keyframes spin {
        0% { transform: rotate(0deg); }
        100% { transform: rotate(360deg); }
      }


      .tablelike{
        width:100%;
      }

      .control-label{
        font-weight: 300;
        color: #424242;
        padding-left: 5px !important;
      }

      
      


        `]
})

//export class alertsReportGroup {
export class analyticsReportGroup {
    public reportOptions: any[] = [];
    public showDateMenu: boolean = false;
    public orgClassesDet: any;
    private orgRoutes: any[];
    isShow: boolean;
    public sendData: number = 0;
    private rawRouteHistoryLog: any;
    private routeHistoryLog: any[] = [];
    private routeHistoryChartObj: any;
    private routeHistoryChartSeries: any[] = [];
    private memberHistoryLog: any[] = [];
    private callAlertLog: any[];
    private callAlertLogTemp: any[];
    private callLogExcelDownloadLink: string;
    private callALertLogCount: any[];
    private noCallAlertData: boolean;
    private noCallAlertCountData: boolean;
    private callAlertLogSelectedDates: any[];
    private callAlertLogDatesCount: any = {};
    public callCountLogInbuiltFilter: any;
    public routeSection: any;//krishnan
    public sessionWiseRoute: any;//krishnan
    private callAlertRouteFilter: any;
    private callAlertStudentFilter: any;
    private showCallAlertFilterOptions: boolean;
    private callLogFilterName: string;
    private callLogFilterId: string;
    private callLogFilterMobile: any;
    public newAlertConfigRoutes: any = [];
    public notReachedAllRoutes: any = [];
    public notReachedAllPP: any = [];
    public getAllPP: any = [];
    public notReachedAllPPSorted: any = [];
    public notReachedAllPPSortedDisplay: any = [];
    public finalObjForPieChartArrayGlobal: any = [];
    public notReachedAllPPSortedDisplayPie: any = [];
    public notReachedAllPPSortedDisplayAll: any = [];
    public tableHideShowPie: boolean = false;

    private notReachedAllPPShow: boolean = false;
    public notReachedAllRoutesPage: any = [];
    public beforeSortRoutes: any = [];
    public afterSortRoutes: any = [];
    private selectedClass: string;
    private selectedSection: string;
    private reportBasicDetails: any;
    private isDateSelected: boolean;
    private memberBasicInfo: any;
    private selectedReport: any;
    private resetSearchUtility: any;
    public studentDetails: any = [];
    private studentList: any = {};
    private globalFilterObj: any;
    public sharehintContainer: any = false;
    public pageJump: number = 0;
    public srchMemberName: any = "";
    private selectedMember: any;
    private userSelection: any[];
    public callLogHaltDuration: number;
    public callHaltDurationMin: number;
    public callHaltDurationMax: number;
    public routeHistoryExcelDownloadLink: string;
    public isLoading: boolean;
    public contentLoading: boolean = false;
    public lastRes: boolean = false;
    /*App alert log vars declaration starts*/
    public appAlertLog: any[];
    public appLogFilterName: string;
    public appLogFilterId: string;
    public appLogFilterMobile: string;
    public showAppAlertFilterOptions: boolean;
    public appAlertLogExists: boolean;
    public AppLogHaltDuration: number;
    public appHaltDurationMin: number;
    public appHaltDurationMax: number;
    public AppLogOsFilter: string;
    public memberRouteInfo: any;
    public routesNoAdd: any = [];
    /*App alert log vars declaration ends*/

    /*SMS alert log vars declaration starts*/
    public smsLogExists: boolean;
    public smsAlertLog: any[];
    public smsLogFilterName: string;
    public smsLogFilterId: string;
    public smsLogFilterMobile: string;
    public smsAppAlertFilterOptions: boolean;
    public smsAlertLogExists: boolean;
    public smsLogHaltDuration: number;
    public smsHaltDurationMin: number;
    public smsHaltDurationMax: number;
    public smsLogRouteType: string;
    public enablecsv: boolean;
    /*SMS alert log vars declaration ends*/

    //Validation Instances being decalred here..
    private RoutNameError: boolean;
    private routeHistoryStartDateError: boolean;
    private routeHistoryEndDateError: boolean;
    private routesDatesMisMatchError: boolean;
    private noMoreDataError: boolean;
    private MemberNameError: boolean = false;
    private MemberInfoStartDateError: boolean = false;
    private MemberInfoEndDateError: boolean = false;
    private chooseClassError: boolean;
    private callInfoEndDateError: boolean;
    private callInfoStartDateError: boolean;
    private displayErrorMessage: string;
    private showHaltDurationError: boolean;
    private showAppDurationError: boolean;
    public routeName: string;
    //public srchDateLog:any="";
    public displayLoaderText: boolean = true;
    public fromDateError: boolean;
    public routeType: string;
    public dayTagging: string = 'morning';

    public displaySelectedDate: any;
    public RouteListInfo: any = [];
    private dispRouteInformationList: any = [];
    private dispRouteInformationListCallMadeEarly: any = [];
    private dispRouteInformationListCallMadeDelay: any = [];
    public notReachedOnTimeList: any = [];
    public objData: any = [];
    public objData1: any = [];
    public ReachedOnTimeList: any = [];

    public current_page: any = 1;
    public records_per_page: any = 10;
    public todayDate = new Date().toISOString().slice(0, 10);
    public noMoreData: boolean = false;
    navIsFixed: boolean;
    public ovralCount: number;
    public callMadeCount: number; //krishnan
    public callMadeTotal: number; //krishnan
    public callMadeFlag: number = 0; //krishnan
    public routeNameCallMade: any;//krishnan
    public expendRouteName: any = {};
    public setRouteNameFlag: any = {};
    public expendedIndex: any = {};
    public falseTemp: boolean;
    public diffTime: any;

    public callprocpkpstd: any = [];
    public selectedRouteName: any;
    public sessionType: any;
    public sessionErr: boolean;
    public durationErrRouteHistoryLog: boolean = false;
    public ext_key: any = Object.keys;

    public reportSections = {
        alertlogsummary: false,
        appalertmadelog: false,
        smsalertmadelog: false,
        callalertmadelog: false,
        routecallalertmadelog: false,
        memberhistorylog: false,
        routehistorylog: false,
        callLogCount: false,
        callMadeReport: false,
        callMadeEarly: false,
        callMadeDelayed: false,
        nrOnTime: false,
        rOnTime: false,
        callProcessedReport: false,
    }
    public reportSectionsContent = {
        alertlogsummary: false,
        appalertmadelog: false,
        smsalertmadelog: false,
        callalertmadelog: false,
        routecallalertmadelog: false,
        memberhistorylog: false,
        routehistorylog: false,
        callLogCount: false,
        callMadeReport: false,
        callMadeEarly: false,
        callMadeDelayed: false,
        nrOnTime: false,
        rOnTime: false,
        callProceesedReport: false,
    }
    private reportLookUpTitle = {
        alertlogsummary: 'Alert log summary',
        appalertmadelog: 'App alert log',
        smsalertmadelog: 'SMS Log',
        callalertmadelog: 'Call alert log',
        routecallalertmadelog: 'Sessionwise Alert Log Summary',
        memberhistorylog: 'Member history log',
        routehistorylog: 'Route history log',
        callLogCount: 'Call log count',
        callMadeReport: 'Call Made Report',
        callMadeEarly: 'Call Made Early Report',
        callMadeDelayed: 'Call Made Delayed Report',
        nrOnTime: 'Not Reaced OnTime Report',
        rOnTime: 'Reaced OnTime Report',
        callProcessedReport: 'Call Alert Timing Report',
    }

    private reportLookUpFunction = {
        alertlogsummary: "initAlertLogReport",
        appalertmadelog: "initAppAlertLogReport",
        smsalertmadelog: 'initSmsLogReport',
        callalertmadelog: "initCallAlertLogReport",
        routecallalertmadelog: "initRouteCallAlertLogReport",
        memberhistorylog: "memberHistoryLogReport",
        routehistorylog: "routeHistoryReport",
        callLogCount: 'initCallLogCountReport',
        callMadeReport: 'initCallMadeReport',
        callMadeEarly: 'initCallMadeEarlyReport',
        callMadeDelayed: 'initCallMadeDelayedReport',
        nrOnTime: 'initnrOnTimeReport',
        rOnTime: 'initrOnTimeReport',
        callProcessedReport: 'initCallProcessedReport',
    }
    public callProcessedtoTime: any;
    callProcessedDate: any;
    callProcessedfromTime: any;
    public routeIdCollection: any = [];

    getVehicleReachLog: any = {};
    studentsRecordStore: any;
    falsytemplateval: boolean = false;
    sessionbasedRoutes: any;
    isValidForView: boolean;
    callMadeTemplate: boolean = true;
    public selectedRouteStoppages: any = [];
    private pickUpNotifconfigs: any = [];
    public Tabs: any = [];
    public expandedIndex: any = -1;
    public showTableRowLoader: boolean = false;
    public nodatafound: boolean = false;
    public storeIndex: any;
    public noMoredata: boolean = true;
    public alreadyLoading: boolean = false;
    public callAlertSessionWise: boolean = false;
    public getAllPPIdName: any = [];
    public totalPPCnt: number = 0;
    public chartPPName: any = [];
    public timseSeriesChart: any = [];
    public timseSeriesChartObject: any = [];
    public timseSeriesChartObjectOrdered: any = [];
    public chartGradeName: any = [];
    public timseSeriesChartSorted: any = [];
    isRouteBased: boolean = false;
    //public expandedIndex:any = -1;
    public routePageJump: number = 0;
    public totalNrOntimetotalPages: number;
    public globalDate: any;
    public tabs: any;
    selected = new FormControl(0);
    public uniqueRouteNames: any = [];
    public uniqueRouteNamesBasedGrades: any = [];
    public uniqueRouteNamesBasedGradesSorted: any = [];
    @Output() valueChange = new EventEmitter();

    public sum: boolean = false;
    tabData: any;
    public routeNameMapping = new Map();
    constructor(private chosenUtility: chosenUtility, public storageservice: StorageService, private router: Router, private apiService: ApiService,
        private bootstrapDateTime: bootstrapDateTime, private globalUtils: GlobalUtils,
        public dateChange: ApiCallDateFormatService, public datepipe: DatePipe) {
        this.appAlertLog = [];
        this.smsAlertLog = [];
        this.callAlertLog = [];
        this.callALertLogCount = [];
        this.callAlertLogSelectedDates = [];
        this.callAlertLogDatesCount = {};
        this.callAlertRouteFilter = [];
        this.callAlertStudentFilter = [];
        this.userSelection = [];
        this.globalFilterObj = {};
        this.reportBasicDetails = {};
        this.callCountLogInbuiltFilter = {
            showOnlyCallCount: true,
            showOnlySmsCount: true,
            showOnlyAppCount: true
        };
        this.showCallAlertFilterOptions = false;
        this.showAppAlertFilterOptions = false;
        this.isDateSelected = false;
        this.appAlertLogExists = false;
        this.smsLogExists = false;
        this.selectedClass = '';
        this.selectedSection = '';
        this.noCallAlertCountData = false;
        this.showHaltDurationError = false;
        this.callLogExcelDownloadLink = null;
        this.isLoading = false;
        this.enablecsv = false;
    }

    ngOnInit() {

        this.initChosen();

        // //this.loadCSS();
        this.pickathpluginInit();
        let pickerConfig: any = {
            timePicker: false,
            timeFormat: null
        }
        this.initJqUtils(undefined, 'routeHistoryStartDate', 'routeHistoryEndDate', pickerConfig);
        this.viewMemberSearchList();
        //  this.tabs = ['First', 'Second', 'Third'];
        // this.viewMemberSearchList();
        // this.tabs = ['First', 'Second', 'Third'];
        // this.objData = [
        //     { adName: "AdName 1"},
        //     { adName: "AdName 2"},
        //     { adName: "AdName 3"},
        //     { adName: "AdName 4"},
        //     { adName: "AdName 5"},
        //     { adName: "AdName 6"},
        //     { adName: "AdName 7"},
        //     { adName: "AdName 8"},
        //     { adName: "AdName 9"},
        //     { adName: "AdName 10"}
        // ];


    }

    pickathpluginInit() {
        return new Promise(function (resolve, reject) {

            setTimeout(() =>


                (new Pickathing('demo-select', true, { searchLabel: 'Search for options...' }), resolve(true)), 500);

        }).then(function (res1) {



            return res1 && new Promise((res2, reject) => { // (*)
                // res2('Second')
                var srchIpBox: any = document.getElementsByClassName('Pickathing-dropdown');


                // var PickathingList:any = document.getElementsByClassName('Pickathing-list');
                var span = document.createElement('span');
                var i = document.createElement('i');

                span.setAttribute('id', 'pickathSearchOption');

                i.setAttribute('class', 'fa fa-search');

                srchIpBox[0].appendChild(span);
                span.appendChild(i);

                // PickathingList[0].style.display = 'none';

                span.style.position = 'absolute';
                span.style.top = '16px';
                span.style.right = '19px';
                span.style.zIndex = '999';
                res2(true);
            });

        }).then(function (result) { // (**)



            return result == true && new Promise((res3, reject) => {
                res3(true);
            });

        }).then(function (result) {



            var targetButton: any = document.getElementsByClassName('Pickathing-option');

            /// targetButton[0].innerHTML = '';
            targetButton[0].style.display = "none";

        });
    }


    // loadCSS(){
    //     setTimeout(() => {



    //         var demoSelect = new Pickathing('demo-select', true, {searchLabel: 'Search for options...'});

    //     }, 200);
    //    // this is to dynamically create search box for pickath


    //         // this.readytoDisplay = true;

    // }
    ngAfterViewInit() {
        /// this.dynamicallyinjectSearch();
        // this.defaultSelectOptionRemoval('Pickathing-option');
    }
    // dynamicallyinjectSearch(){
    //     setTimeout(() => {
    //         var srchIpBox:any = document.getElementsByClassName('Pickathing-dropdown');


    //         // var PickathingList:any = document.getElementsByClassName('Pickathing-list');
    //         var span = document.createElement('span');
    //         var i = document.createElement('i');

    //         span.setAttribute('id','pickathSearchOption');

    //         i.setAttribute('class','fa fa-search');

    //         srchIpBox[0].appendChild(span);
    //         span.appendChild(i);

    //         // PickathingList[0].style.display = 'none';

    //         span.style.position = 'absolute';
    //         span.style.top = '16px';
    //         span.style.right = '19px';
    //         span.style.zIndex = '999';

    //     }, 600);
    // }
    // defaultSelectOptionRemoval(str:string){
    //     setTimeout(() => {
    //         var targetButton:any = document.getElementsByClassName(str);

    //         // targetButton[0].innerHTML = '';
    //         targetButton[0].style.display = "none";


    //     }, 700);

    // }
    public parameter: any;
    @Input('availableRoutes')
    set availableReports(para: any) {
        this.parameter = para;
    }

    @Input('AlertReports')
    set AlertReports(data: any) {
        if (data !== undefined) {
            this.AlertControls(data.alertlog);
        }
    }

    @Input('availableClasses')
    set availableClasses(param: any) {
        this.getOrgClasses(param);
    }
    AlertControls(obj: any) {
        let report
        if (localStorage.getItem('userInfo')) {
            report = JSON.parse(localStorage.getItem('userInfo'));
        }

        let uLevel = JSON.parse(localStorage.getItem('userLevel'));

        if (uLevel === 1) {
            let lone = Object.assign(this.parameter);
            this.generateOptions(lone);
        } else if (uLevel === 2) {
            let alertControl = Object.assign(this.parameter, report.reports.alertlog);
            this.generateOptions(alertControl);
        }

    }
    generateOptions(param: any) {
        for (let obj in param) {
            let data = {};
            if (param[obj]) {
                // console.log('1111111111 ',this.reportLookUpTitle[obj]);
                data['reportName'] = this.reportLookUpTitle[obj];

                data['reportName'] = 'Not Reached Report';
                //  console.log('2222222222222 ',obj);
                //data['value'] = obj;
                data['value'] = 'notReachedReport';
                this.showDateMenu = true;
                this.reportOptions.push(data);
            }
        }
    }

    getOrgClasses(param: any) {
        this.orgClassesDet = param;
    }

    initReportSection({ alertlogsummary = false, appalertmadelog = false, smsalertmadelog = false, callalertmadelog = false, memberhistorylog = false, routehistorylog = false, callLogCount = false, callMadeReport = false, callMadeEarly = false, callMadeDelayed = false, nrOnTime = false, rOnTime = false, callProcessedReport = false, routecallalertmadelog = false } = {}) {
        let providedParam = Object.getOwnPropertyNames(arguments[0]);
        let calleFunction = this.reportLookUpFunction[providedParam[0]];
        this.reportSections.alertlogsummary = alertlogsummary;
        this.reportSections.appalertmadelog = appalertmadelog;
        this.reportSections.smsalertmadelog = smsalertmadelog;
        this.reportSections.callalertmadelog = callalertmadelog;
        this.reportSections.memberhistorylog = memberhistorylog;
        this.reportSections.routehistorylog = routehistorylog;
        this.reportSections.callLogCount = callLogCount;
        this.reportSections.callMadeReport = callMadeReport;
        this.reportSections.callMadeEarly = callMadeEarly;
        this.reportSections.callMadeDelayed = callMadeDelayed;
        this.reportSections.nrOnTime = nrOnTime;
        this.reportSections.rOnTime = rOnTime;
        this.reportSections.callProcessedReport = callProcessedReport;
        this.reportSections.routecallalertmadelog = routecallalertmadelog;

        this[`${calleFunction}`](calleFunction);

        this.initReportSectionContent({});
        this.resetAllValues();
    }

    initReportSectionContent({ alertlogsummary = false, appalertmadelog = false, smsalertmadelog = false, callalertmadelog = false, memberhistorylog = false, routehistorylog = false, callLogCount = false, callMadeReport = false, callMadeEarly = false, callMadeDelayed = false, nrOnTime = false, rOnTime = false, callProcessedReport = false, routecallalertmadelog = false } = {}) {
        this.reportSectionsContent.alertlogsummary = alertlogsummary;
        this.reportSectionsContent.appalertmadelog = appalertmadelog;
        this.reportSectionsContent.smsalertmadelog = smsalertmadelog;
        this.reportSectionsContent.callalertmadelog = callalertmadelog;
        this.reportSectionsContent.memberhistorylog = memberhistorylog;
        this.reportSectionsContent.routehistorylog = routehistorylog;
        this.reportSectionsContent.callLogCount = callLogCount;
        this.reportSectionsContent.callMadeReport = callMadeReport;
        this.reportSectionsContent.callMadeEarly = callMadeEarly;
        this.reportSectionsContent.callMadeDelayed = callMadeDelayed;
        this.reportSectionsContent.nrOnTime = nrOnTime;
        this.reportSectionsContent.rOnTime = rOnTime;
        this.reportSectionsContent.callProceesedReport = callProcessedReport;
        this.reportSectionsContent.routecallalertmadelog = routecallalertmadelog;

    }


    resetAllValues() {
        this.routeHistoryLog = [];
        this.rawRouteHistoryLog = [];
        this.appAlertLog = [];
        this.callALertLogCount = [];
        this.callAlertLogSelectedDates = [];
        this.callAlertLogDatesCount = {};
        this.userSelection = [];
        this.selectedMember = null;
        this.selectedClass = '';
        this.selectedSection = '';
        this.reportBasicDetails = {};
        this.showAppAlertFilterOptions = false;
        this.showCallAlertFilterOptions = false;
        this.appAlertLogExists = false;
        this.AppLogOsFilter = '';
        this.AppLogHaltDuration = 1;
        this.isLoading = false;
        this.callLogHaltDuration = 1;
    }
    /*Driver functions to initialize various report sections start */
    initAlertLogReport(): void {

        setTimeout(() => { this.resetCallTempErr() }, 200);

    }

    initAppAlertLogReport(): void {
        this.isDateSelected = false;
        this.appAlertLogExists = false;
        this.showAppDurationError = false;
        this.appHaltDurationMin = null;
        this.appHaltDurationMax = null;

        this.initJqUtils(undefined, 'appHistoryStartDate', 'appHistoryEndDate', undefined);
        this.viewAppAlertLog('appalertmadelog'); // API call on report selection.
        setTimeout(() => { this.resetCallTempErr() }, 200);
    }

    initSmsLogReport(): void {
        this.smsLogHaltDuration = null;
        this.smsLogRouteType = null;
        this.initJqUtils(undefined, 'smsHistoryStartDate', 'smsHistoryEndDate', undefined);
        this.viewSmsAlertLog('smsalertmadelog');
    }

    initCallAlertLogReport(): void {
        this.isDateSelected = false;
        this.showHaltDurationError = false;
        this.callLogHaltDuration = null;
        this.callLogExcelDownloadLink = null;
        let pickerConfig: any = {
            timePicker: false,
            timeFormat: null
        }
        this.callLogHaltDuration = 1;
        this.initJqUtils(undefined, 'routeHistoryStartDate', 'routeHistoryEndDate', pickerConfig);
        //this.viewCallAlertLog('callalertmadelog');
        setTimeout(() => { this.resetCallTempErr() }, 200);

    }
    initRouteCallAlertLogReport(): void {
        this.isDateSelected = false;
        this.showHaltDurationError = false;
        this.callLogHaltDuration = null;
        this.callLogExcelDownloadLink = null;
        let pickerConfig: any = {
            timePicker: false,
            timeFormat: null
        }
        this.callLogHaltDuration = 1;
        this.initJqUtils(undefined, 'routeHistoryStartDate', 'routeHistoryEndDate', pickerConfig);
        //this.viewCallAlertLog('callalertmadelog');
        setTimeout(() => { this.resetCallTempErr() }, 200);

    }


    initCallLogCountReport(): void {
        this.initJqUtils(undefined, 'routeHistoryStartDate', 'routeHistoryEndDate', undefined);
        setTimeout(() => { this.resetCallTempErr() }, 200);

    }

    initCallMadeReport(): void {
        this.initJqUtils(undefined, 'selectedLogDate', 'eventStartTime', 'eventEndTime');
        setTimeout(() => { this.resetCallTempErr() }, 200);

    }
    initCallMadeEarlyReport(): void {
        this.initJqUtils(undefined, 'selectedLogDate', 'eventStartTime', 'eventEndTime');
        setTimeout(() => { this.resetCallTempErr() }, 200);

    }
    initCallMadeDelayedReport(): void {
        this.initJqUtils(undefined, 'selectedLogDate', 'eventStartTime', 'eventEndTime');
        setTimeout(() => { this.resetCallTempErr() }, 200);

    }
    initnrOnTimeReport(): void {
        this.initJqUtils(undefined, 'selectedLogDate', 'eventStartTime', 'eventEndTime');
        setTimeout(() => { this.resetCallTempErr() }, 200);

    }
    initrOnTimeReport(): void {
        this.initJqUtils(undefined, 'selectedLogDate', 'eventStartTime', 'eventEndTime');
        setTimeout(() => { this.resetCallTempErr() }, 200);

    }
    initCallProcessedReport(): void {

        let pickerConfig: any = {
            timePicker: false,
            timeFormat: null
        }
        this.initJqUtils(undefined, 'selectedLogDate', undefined, pickerConfig);
        setTimeout(() => { this.resetCallTempErr() }, 200);

    }

    getVehicleReachLogData = () => {

        let headerData = this.apiHeaderConfig();

        return this.apiService.getVehicleReachLog({

            data: {
                key: localStorage.getItem('scbToken'),
                form: {
                    fromDate: headerData.fromdate,
                    fromTime: headerData.fromtime + ':00',
                    toDate: headerData.todate,
                    toTime: headerData.totime + ':00',
                    routeType: headerData.routetype
                }
            }
        }).then(res => {

            return res;
        })


    }


    getStudentPickupLogUsingRouteId = () => {

        let headerData = this.apiHeaderConfig();
        return this.apiService.getRoutes({
            data: {
                key: localStorage.getItem('scbToken'),
                projections: [
                    'routeName',
                    'routeType',
                    'routeStartTime', 'routeEndTime'
                ],
                filter: {
                    routeStartTime: headerData.fromtime,
                    routeEndTime: headerData.totime,
                    routeType: headerData.routetype,
                },
                extra: {
                    maxlimit: "all"
                }
            }

        }).then(res => {
            for (let getrouteId of res.response) {
                this.routeIdCollection[getrouteId.routeId] = getrouteId.routeName;
            }
            this.apiService.getStudentPickupLog({
                data: {
                    key: localStorage.getItem('scbToken'), form: {
                        routeId: Object.keys(this.routeIdCollection)
                    }
                }
            }).then(stdres => {

                this.callprocpkpstd = stdres.response;

            });
        });
    }



    difftimestamp(time1: number, time2: number, flag: string) {
        let time;

        time = Math.abs((time1 / 1000) - (time2 / 1000));
        time = Math.floor(Math.floor(time) / 60);
        if (flag === 'grade') {
            if (time <= 4) {
                return " C ";
            }
            if (time >= 5 && time <= 10) {
                return " A ";
            }
            if (time >= 11 && time <= 15) {
                return " B ";
            }
            if (time > 15) {
                return " D ";
            }

        }
        if (flag === 'time') {
            return time;
        }
        if (flag === 'time_str') {

            return time <= 1 ? time + ' min' : time + ' mins';

        }
        if (flag === 'time_str_no_mins') {

            return time;
        }

    }

    downloadcsv = (csv: any, filename: any) => {

        let csvFile;
        let downloadLink;

        // CSV FILE
        csvFile = new Blob([csv], { type: "text/csv" });

        // Download link
        downloadLink = document.createElement("a");

        // File name
        downloadLink.download = filename;

        // We have to create a link to the file
        downloadLink.href = window.URL.createObjectURL(csvFile);

        // Make sure that the link is not displayed
        downloadLink.style.display = "none";

        // Add the link to your DOM
        document.body.appendChild(downloadLink);

        // Lanzamos
        downloadLink.click();

    }

    export_table_to_csv = (html: any, filename: any) => {

        let csv = [];

        let rows = document.querySelectorAll("#callprocessedreport tr");

        for (let i = 0; i < rows.length; i++) {

            let row = [], cols: any = rows[i].querySelectorAll("td, th");

            for (let j = 0; j < cols.length; j++)
                row.push(cols[j].innerText);

            csv.push(row.join(","));

        }

        let filenames = this.apiHeaderConfig().fromdate;

        filenames = 'CallProcessedReport-' + filenames + '.csv';

        this.downloadcsv(csv.join("\n"), filenames);


    }

    concurrentApiCalls(): void {
        const concurrentCall = async () => {

            const studentsRecord = this.getStudentPickupLogUsingRouteId();
            const vehicleReachLog = this.getVehicleReachLogData();

            this.studentsRecordStore = await studentsRecord;
            this.getVehicleReachLog = await vehicleReachLog;
            setTimeout(() => {


                // Object.keys(this.getVehicleReachLog.response).forEach(key => { //hided by krish
                //     console.log('MMMMMMM',this.getVehicleReachLog.response[key].pickupeta);
                //   })


            }, 1000);
            this.contentLoading = false;
            this.enablecsv = true;

            setTimeout(() => {
                this.falsytemplateval = true;
            }, 2 * 1000);

            // this.proceesDataReached = await vehicleReachLog;
        };
        concurrentCall();
    }

    apiHeaderConfig() {


        // return () => {
        let date = $('#selectedLogDate').val();

        if (this.dayTagging && this.dayTagging != undefined && date != '') {
            let sessions: any; let fromTime: string; let toTime: string; let routeType: string; let fromDate: string; let toDate: string;
            date = date.trim(' ');
            switch (this.dayTagging) {
                case 'morning': sessions = '06:00-11:59';
                    sessions = sessions.split('-');
                    fromTime = sessions[0];
                    toTime = sessions[1];
                    routeType = 'pickup';
                    fromDate = this.dateChange.dateConversionForApicalls(date, 'onlydate');
                    toDate = this.dateChange.dateConversionForApicalls(date, 'onlydate');
                    break;
                case 'afternoon': sessions = '12:00-14:59';
                    sessions = sessions.split('-');
                    fromTime = sessions[0];
                    toTime = sessions[1];
                    routeType = 'drop';
                    fromDate = this.dateChange.dateConversionForApicalls(date, 'onlydate');
                    toDate = this.dateChange.dateConversionForApicalls(date, 'onlydate');
                    break;
                case 'evening': sessions = '15:00-19:00';
                    sessions = sessions.split('-');
                    fromTime = sessions[0];
                    toTime = sessions[1];
                    routeType = 'drop';
                    fromDate = this.dateChange.dateConversionForApicalls(date, 'onlydate');
                    toDate = this.dateChange.dateConversionForApicalls(date, 'onlydate');
                    break;

            }
            return {
                fromdate: fromDate,
                fromtime: fromTime,
                totime: toTime,
                todate: toDate,
                routetype: routeType,
            }
        }
        // }

    }
    initCallProcessedreportValidator() {
        this.displayErrorMessage = '';
        let isValid = true;
        let date = $('#selectedLogDate').val()
        let dateObj = this.genericDateValidate(date);

        if (dateObj.stTimeStampString.length <= 10) {
            this.fromDateError = true;
            isValid = false;
        } else {
            this.fromDateError = false;
        }
        return isValid;
    }
    callProcessedReport(sectionContentId: string) {


        if (this.initCallProcessedreportValidator()) {



            if (this.enablecsv == true) {
                this.enablecsv = false;
            }
            this.contentLoading = true;

            this.concurrentApiCalls();

            let obj = {};
            obj['' + sectionContentId] = true;
            this.isLoading = false;
            // this.enablecsv = true;
            this.initReportSectionContent(obj);

        }


    }

    memberHistoryLogReport(): void {
        let pickerConfig: any = {
            timePicker: false,
            timeFormat: null
        }
        this.initJqUtils(undefined, 'memberHistoryStartDate', 'memberHistoryEndDate', pickerConfig);
        setTimeout(() => { this.resetCallTempErr() }, 200);

    }

    //Route history functions start.
    routeHistoryReport(): void {
        let parent = this;
        this.getOrgRoutes();
    }

    viewRouteHistoryLog(sectionContentId: string): void {
        if (this.InitiateInputValidator()) {
            let routeId = this.userSelection[0]['routeId'];
            let routeHistoryStartDate = this.bootstrapDateTime.getInputvalue('routeHistoryStartDate');
            let routeHistoryEndDate = this.bootstrapDateTime.getInputvalue('routeHistoryEndDate');
            // let startDateService:any,endDateService:any;
            routeHistoryStartDate = this.dateChange.dateConversionForApicalls(routeHistoryStartDate, 'onlydate');
            routeHistoryEndDate = this.dateChange.dateConversionForApicalls(routeHistoryEndDate, 'onlydate');
            let apiHeader = {
                data: {
                    key: localStorage.getItem('scbToken'),
                    // form: {
                    //     routeId: routeId,
                    //     fromDate: routeHistoryStartDate.trim(),
                    //     fromTime: '00:00:00',
                    //     toDate: routeHistoryEndDate.trim(),
                    //     toTime: '23:59:59'
                    // }
                    form: {
                        routeId: routeId,
                        fromDate: routeHistoryStartDate,
                        fromTime: '00:00:00',
                        toDate: routeHistoryEndDate,
                        toTime: '23:59:59'
                    }
                }
            }
            this.isLoading = true;
            this.routeHistoryLog = [];
            this.routeHistoryChartSeries = [];
            this.routeHistoryExcelDownloadLink = `${this.apiService.base}report/download_route_stopagereports&key=${localStorage.getItem('scbToken')}&routeId=${routeId}&fromDate=${routeHistoryStartDate}&fromTime=00:00:01&toDate=${routeHistoryEndDate}&toTime=23:59:00`;
            this.apiService.getRoutePickUpHistory(apiHeader).then(response => {
                if (response.status == "success") {
                    this.displayErrorMessage = '';
                    this.noMoreDataError = false;
                    let apiresp = response.response.pickupdata;
                    //Creation of chart categories  starts here..
                    let dates = Object.getOwnPropertyNames(apiresp);
                    let chartGroups = Object.assign(Object.getOwnPropertyNames(apiresp[dates[0]].pickupInformation).map(function (index, value) { return 'P-' + (value + 1) }));
                    let legendColorCount = 0; //restricted to 3 as per API response.
                    let legendColorCollections = ['#90ed7d', '#7cb5ec', '#9e9e9e'];
                    //Creation of chart categories ends..
                    this.rawRouteHistoryLog = response.response;
                    for (let index in apiresp) {
                        let data = apiresp[index].pickupInformation;
                        let gObj = {};
                        let chartPointsCollection = [];
                        let chartSeriesObj = {};
                        chartSeriesObj['name'] = index;
                        chartSeriesObj['color'] = legendColorCollections[legendColorCount++];
                        gObj['date'] = index
                        gObj['pickup'] = [];
                        chartSeriesObj['data'] = [];
                        let arr = [];
                        for (let i in data) {
                            let info = data[i];
                            let obj = {
                                pickUp: info.pickuppoint,
                                eta: info.expectedTime,
                                memberCount: info.memberCount,
                                haltTime: (info.halt != '0' ? info.halt : '-'),
                                pickupReachTime: (info.pickupReachTime ? info.pickupReachTime : '-')
                            };
                            arr.push(obj);
                            //chart processing
                            let pickupInMilli = (info.pickupRTMilliseconds) ? (info.pickupRTMilliseconds * 1000) : null;
                            let haltTime = (info.halt) ? info.halt : '-';
                            let pickupName = info.pickuppoint;
                            let chartPoint = { y: pickupInMilli, haltTime: haltTime, pickupPointName: pickupName, pickupEta: info.expectedTime };
                            chartPointsCollection.push(chartPoint);
                            //chart processing
                        }
                        Object.assign(gObj['pickup'], arr);
                        this.routeHistoryLog.push(gObj);

                        Object.assign(chartSeriesObj['data'], chartPointsCollection);
                        this.routeHistoryChartSeries.push(chartSeriesObj);
                        this.routeHistoryChartObj = {
                            chartCategories: chartGroups,
                            chartSeries: this.routeHistoryChartSeries
                        }
                    }
                    let obj = {};
                    obj['' + sectionContentId] = true;
                    this.isLoading = false;
                    this.initReportSectionContent(obj);
                    setTimeout(() => {
                        this.initRouteLogChart(this.routeHistoryChartObj);
                    }, 100)
                } else {
                    this.displayErrorMessage = this.globalUtils.getErrorMsg(response.ec);
                }

            }).catch(error => { })
        }
    }

    initRouteLogChart(chartObj: any): void {
        Highcharts.setOptions({
            global: {
                useUTC: false
            }
        });
        Highcharts.chart('routeLogChartContainer', {
            chart: {
                zoomType: 'x'
            },
            title: {
                text: 'Route Pickups'
            },
            xAxis: {
                categories: chartObj.chartCategories,
                title: {
                    text: 'Available Pickup points'
                }
            },
            tooltip: {
                crosshairs: true,
                formatter: function () {
                    //return '<b>Pickup Point:</b> '+this.point.pickupPointName+'<br><b>Time:</b> '+ Highcharts.dateFormat('%H:%M:%S', new Date(this.y)) + '<br><b>Halt Time:</b> ' + this.point.haltTime + '';
                    return `<b>Pickup Point:</b> ${this.point.pickupPointName}<br><b>Reach Time:</b> ${Highcharts.dateFormat('%H:%M:%S', new Date(this.y))}<br><b>Halt Time:</b> ${this.point.haltTime} <br><b>ETA:</b>${this.point.pickupEta}`;
                }
            },
            yAxis: {
                type: 'datetime',
                dateTimeLabelFormats: {
                    second: '%H:%M:%S'
                },
                title: {
                    text: 'Timings(HH:mm:ss)'
                }
            },
            credits: {
                enabled: false
            },

            plotOptions: {
                line: {}
            },
            series: chartObj.chartSeries
        });
    }


    /*input validation function by Deepak*/
    InitiateInputValidator = () => {
        this.displayErrorMessage = '';
        let isValid: boolean = true;
        if (!this.userSelection.length) {
            this.RoutNameError = true;
            isValid = false;
        } else {
            this.RoutNameError = false;
        }

        let inp_val_stDate = $('#routeHistoryStartDate').val().split('-');
        let inp_val_endDate = $('#routeHistoryEndDate').val().split('-');

        inp_val_stDate = `${inp_val_stDate[1]}/${inp_val_stDate[0]}/${inp_val_stDate[2]}`;
        let inp_val_stDate_TimeStamp = new Date(inp_val_stDate).getTime();
        let inp_val_stTimeStampString = inp_val_stDate_TimeStamp.toString();

        inp_val_endDate = `${inp_val_endDate[1]}/${inp_val_endDate[0]}/${inp_val_endDate[2]}`;
        let inp_val_endDate_TimeStamp = new Date(inp_val_endDate).getTime();
        let inp_val_endTimeStampString = inp_val_endDate_TimeStamp.toString();



        // public durationErrRouteHistoryLog:boolean = false;

        // let routeHistoryStartDate = $('#routeHistoryStartDate').val()
        if (inp_val_stTimeStampString.length <= 10) {
            // if (!routeHistoryStartDate) {
            this.routeHistoryStartDateError = true;
            isValid = false;

        } else {
            this.routeHistoryStartDateError = false;
        }

        // let routeHistoryEndDate = $('#routeHistoryEndDate').val()
        // if (!routeHistoryEndDate) {
        if (inp_val_endTimeStampString.length <= 10) {
            this.routeHistoryEndDateError = true;
            isValid = false;

        } else {
            this.routeHistoryEndDateError = false;
        }

        if (inp_val_stDate_TimeStamp > inp_val_endDate_TimeStamp) {
            // if ($('#routeHistoryEndDate').val() < $('#routeHistoryStartDate').val()) {
            this.routesDatesMisMatchError = true;
            isValid = false;
        } else {
            this.routesDatesMisMatchError = false;
        }


        if ((inp_val_endDate_TimeStamp - inp_val_stDate_TimeStamp) > (172800 * 1000)) {
            this.durationErrRouteHistoryLog = true;
            isValid = false;
        } else {
            this.durationErrRouteHistoryLog = false;
        }
        return isValid;
    }
    //Route history functions end.

    //Member log functions start

    filterMember(Mname: any) {
        $('#memberDisplayName').val(Mname);
        this.pageJump = 0;
        this.srchMemberName = "";
        this.srchMemberName = Mname;
        this.viewMemberSearchList();

    }

    closeShareContainer() {
        let parent: any = this;
        setTimeout(function () {
            parent.sharehintContainer = false;
        }, 1000);
    }

    passMemberId(memID: any) {
        this.selectedMember = memID;
    }


    //getMembers funtion
    viewMemberSearchList() {
        this.apiService.viewMember({
            data: {
                key: localStorage.getItem('scbToken'),
                filter: {
                    MemberName: this.srchMemberName
                },
                extra: {
                    pageJump: this.pageJump
                }

            }
        }).then(response => {
            let result = response;
            if (result.status == "success") {
                this.studentList = result.response; // here response is API output
                this.studentDetails = $.map(this.studentList, function (value: any, index: any) {
                    return [value]
                });
            }
        }).catch(error => {

        });
    }

    setSelectedStudent(selectedItem: any) {
        if (selectedItem) {
            this.selectedMember = selectedItem;
        }
    }

    /*input validation function by Deepak*/
    InitiateMemberInputValidator = () => {
        this.displayErrorMessage = '';
        let isValid: boolean = true;

        let memberName = (this.selectedMember.length && this.selectedMember[0].memberId) ? this.selectedMember[0].memberId : false;

        if (!memberName) {
            this.MemberNameError = true;
            isValid = false;
        } else {
            this.MemberNameError = false;
        }



        let stDate = ($('#memberHistoryStartDate').val()).split('-');
        stDate = `${stDate[1]}/${stDate[0]}/${stDate[2]}`;
        let stTimeStamp = new Date(stDate).getTime();


        let endDate = ($('#memberHistoryEndDate').val()).split('-');
        endDate = `${endDate[1]}/${endDate[0]}/${endDate[2]}`;
        let endTimeStamp = new Date(endDate).getTime();

        // let memberInfoStartDate = $('#memberHistoryStartDate').val();
        let stTimeStampString = stTimeStamp.toString();
        let endTimeStampString = endTimeStamp.toString();


        if (stTimeStampString.length <= 10) {

            this.MemberInfoStartDateError = true;
            isValid = false;
        } else {

            this.MemberInfoStartDateError = false;
        }

        if (endTimeStampString.length <= 10) {
            this.MemberInfoEndDateError = true;
            isValid = false;

        } else {
            this.MemberInfoEndDateError = false;

        }

        if (stTimeStamp > endTimeStamp) {
            // if ($('#memberHistoryStartDate').val() > $('#memberHistoryEndDate').val()) {
            this.routesDatesMisMatchError = true;
            isValid = false;
        } else {
            this.routesDatesMisMatchError = false;
        }
        // isValid = false;
        return isValid;
    }

    viewMemberHistoryLog(sectionContentId: string) {

        if (this.InitiateMemberInputValidator()) {
            let apiHeader = {
                data: {
                    key: localStorage.getItem('scbToken'),
                    form: {

                        memberId: this.selectedMember[0].memberId,
                        fromDate: this.dateChange.dateConversionForApicalls(this.bootstrapDateTime.getInputvalue('memberHistoryStartDate').trim(), 'onlydate'),
                        fromTime: "00:00:01",
                        toDate: this.dateChange.dateConversionForApicalls(this.bootstrapDateTime.getInputvalue('memberHistoryEndDate').trim(), 'onlydate'),
                        toTime: "23:59:59"
                    }
                }
            }
            let sDate = this.dateChange.dateConversionForApicalls(this.bootstrapDateTime.getInputvalue('memberHistoryStartDate').trim(), 'onlydate');
            this.isLoading = true;
            this.apiService.getMemberPickupLog(apiHeader).then(response => {
                if (response.status === 'success') {
                    this.displayErrorMessage = '';
                    let apiRes = response.response.routepickupInfo;
                    this.memberBasicInfo = response.response.memberInformation;
                    let firstData = apiRes[Object.getOwnPropertyNames(apiRes)[0]];
                    let pickupDropInfo = {
                        pickupName: '-',
                        dropName: '-'
                    };
                    this.memberRouteInfo = response.response.routepickupInfo[sDate];
                    if ('pickup' in firstData) pickupDropInfo.pickupName = firstData.pickup.pickuppoint;
                    if ('drop' in firstData) pickupDropInfo.dropName = firstData.drop.pickuppoint;

                    Object.assign(this.memberBasicInfo, pickupDropInfo);
                    this.memberHistoryLog = [];
                    for (let index in apiRes) {
                        let pInfo = apiRes[index];
                        if (!('pickup' in pInfo)) {
                            pInfo['pickup'] = {};
                            Reflect.set(pInfo['pickup'], 'pickupReachTime', '-');
                            Reflect.set(pInfo['halt'], 'halt', '-');
                        } else {
                            pInfo.pickup.pickupReachTime = (pInfo.pickup.pickupReachTime) ? pInfo.pickup.pickupReachTime : '-';
                            pInfo.pickup.halt = (pInfo.pickup.halt) ? pInfo.pickup.halt : '-';
                        }

                        if (!('drop' in pInfo)) {
                            pInfo['drop'] = {};
                            Reflect.set(pInfo['drop'], 'pickupReachTime', '-');
                            Reflect.set(pInfo['drop'], 'halt', '-');
                        } else {
                            pInfo.drop.pickupReachTime = (pInfo.drop.pickupReachTime) ? pInfo.drop.pickupReachTime : '-';
                            pInfo.drop.halt = (pInfo.drop.halt) ? pInfo.drop.halt : '-';
                        }

                        let obj = {
                            date: index,
                            pickup: pInfo.pickup,
                            drop: pInfo.drop
                        };
                        this.memberHistoryLog.push(obj);
                    }

                    let obj = {};
                    obj['' + sectionContentId] = true;
                    this.isLoading = false;
                    this.initReportSectionContent(obj);
                } else {
                    this.displayErrorMessage = this.globalUtils.getErrorMsg(response.ec);
                }
            }).catch(error => { })
        }

    }

    //Member log function end
    onKeydown(param: any, key: string) {
        Reflect.set(this.globalFilterObj, key, param);
        if (!this.callLogFilterName) {
            if ('memberName' in this.globalFilterObj) delete this.globalFilterObj['memberName']
        }

        if (!this.callLogFilterId) {
            if ('memberUid' in this.globalFilterObj) delete this.globalFilterObj['memberUid']
        }

        if (!this.callLogFilterMobile) {
            if ('mobileNo' in this.globalFilterObj) delete this.globalFilterObj['mobileNo']
        }

        /*if(param){
            Reflect.set(this.globalFilterObj,key,param);
        }else{
            if(key in this.globalFilterObj) delete this.globalFilterObj[key];
        } */
        //  this.viewCallAlertLog('callalertmadelog');
    }

    resetcallLogFilters() {
        this.globalFilterObj = {};
        this.callAlertRouteFilter = [];
        this.callAlertStudentFilter = [];
        //this.viewCallAlertLog('callalertmadelog');
    }

    initiateCallalertLogInputValidator() { //validator by Deepak
        let isValid: boolean = true;
        this.displayErrorMessage = '';
        if (this.selectedSection && !this.selectedClass) {
            this.chooseClassError = true;
            isValid = false;
        } else {
            this.chooseClassError = false;
        }


        // let callInfoEndDate = $('#routeHistoryEndDate').val();
        let callInfoStartDate = $('#routeHistoryStartDate').val();
        /* if(callInfoStartDate){
           this.callInfoEndDateError = true;
           isValid = false;
         }else {
           this.callInfoEndDateError = false;
         } */
        //following code hided by krish
        //         if (!callInfoStartDate) {   
        //             this.callInfoStartDateError = true;
        //             isValid = false;
        //         } else {
        //             this.callInfoStartDateError = false;
        //         }

        // if(this.sessionType=='' || this.sessionType==undefined ){ 
        //     this.sessionErr=true;
        //     isValid = false;
        // }else{ 
        //     this.sessionErr=false;

        // }
        //above code hided by krish
        /*  if(callInfoStartDate){
            if( $('#routeHistoryStartDate').val() > $('#routeHistoryEndDate').val() ){
              this.routesDatesMisMatchError = true;
              isValid = false;
            }else {
              this.routesDatesMisMatchError = false;
            }
          } */

        if ((this.callLogHaltDuration == 3)) {
            if ((this.callHaltDurationMin) && (this.callHaltDurationMax)) {
                if (this.callHaltDurationMin > this.callHaltDurationMax) {
                    this.showHaltDurationError = true;
                    isValid = false;
                } else {
                    this.showHaltDurationError = false;
                }
            } else {
                isValid = false;
                this.showHaltDurationError = true;
            }
        }
        return isValid;
    }


    notReachedOnTimeReport() {
        //console.log('HELLOOOOOOOO enters ');2050
        //this.initCallLogCountReport();



        this.sum = true;
        this.valueChange.emit(this.sum);


        this.isShow = true;
        this.storageservice.set({
            'notReachedSelectedDate': $('#routeHistoryStartDate').val().trim(),

        })
        this.storageservice.set({
            'notReachedSelectedEndDate': $('#routeHistoryEndDate').val().trim(),

        })
        this.sendData = 300;
        this.router.navigate(['/analytics/vehicle-reach-insight']);
    }

    loadData(val: any) {
        //console.log(val);
        this.tabData = val
    }
    expandRowCallAlertLog(index: number, routeId: any, routeName: any): void {
        //if(index != this.expandedIndex){
        //console.log('TTTTTTTTTTT ',this.alreadyLoading);
        if (!this.alreadyLoading) {
            if (this.setRouteNameFlag[routeName]) {
                this.alreadyLoading = true;
                this.storeIndex = index;
                let apiHeader = {
                    data: {
                        key: localStorage.getItem('scbToken'),

                    }
                }


                apiHeader.data['form'] = {
                    fromDate: this.globalDate,


                };
                apiHeader.data['filter'] = {
                    routeId: routeId

                }
                this.showTableRowLoader = true;

                this.apiService.getCallAlertLog(apiHeader).then(response => {
                    if (response.status === 'success') {
                        this.displayErrorMessage = '';
                        if (response.response) {
                            this.callAlertLog = Object.keys(response.response).map((index) => {
                                return { pickupId: index, pickupName: response.response[index][0]['pickupPointName'], pickupEta: response.response[index][0]['eta'], studentDetails: response.response[index], routeName: response.response[index][0]['routeName'] };
                            });
                            //this.noMoreDataError = false;
                            this.showTableRowLoader = false;
                            this.callAlertLogTemp = this.callAlertLog;
                            this.routeNameMapping.set(routeName, this.callAlertLogTemp);
                            this.setRouteNameFlag[routeName] = false;
                            this.alreadyLoading = false;
                        }
                        else {
                            this.noCallAlertData = true;
                            this.setRouteNameFlag[routeName] = true;
                            this.showTableRowLoader = false;
                            this.alreadyLoading = false;
                            //this.noMoreDataError = true;
                        }
                        // let obj = {
                        //     [sectionContentId]: true
                        // }
                        // this.callLogExcelDownloadLink = `https://api-school.placer.in/api/v2/index.php?path=Proxialert/download_callalertlog&key=${localStorage.getItem('scbToken')}${((reportStartDate) && (reportEndDate)) ? `&fromDate=${reportStartDate[0]}&fromTime=${reportStartDate[1]}&toDate=${reportEndDate[0]}&toTime=${reportEndDate[1]}` : ``}${(this.callAlertRouteFilter.length) ? `&routeId=${apiHeader.data['filter']['routeId']}` : ``}${(this.selectedClass) ? `&memberClass=${apiHeader.data['filter']['memberClass']}` : ``}${((this.selectedClass) && (this.selectedSection)) ? `&memberSection=${apiHeader.data['filter']['memberSection']}` : ``}${(this.callLogHaltDuration == 2) ? `&haltDiffMin=0&haltDiffMax=1` : ``}`;
                        this.isLoading = false;
                        this.alreadyLoading = false;
                        // this.initReportSectionContent(obj);
                    } else {
                        this.showTableRowLoader = false;
                        this.setRouteNameFlag[routeName] = true;
                        this.displayErrorMessage = this.globalUtils.getErrorMsg(response.ec);
                    }
                    this.expandedIndex = index === this.expandedIndex ? -1 : index;

                }).catch(error => { })
                /// apiHeader.data['filter'], 'routeId', routeId


            } else {

                var myJson = {};
                var obj = {};




                this.storeIndex = index;
                this.showTableRowLoader = false;

                //this.callAlertLog = [];
                this.showTableRowLoader = true;
                this.nodatafound = false;
                this.showTableRowLoader = false;
                for (let entry of Array.from(this.routeNameMapping.entries())) {
                    //console.log('QQQQQQrrrrrr ',entry[0],routeName)
                    if (entry[0] === routeName) {
                        this.callAlertLog = entry[1];
                        this.expandedIndex = index === this.expandedIndex ? -1 : index;
                        this.alreadyLoading = false;
                    }

                }


            }
        }
        // }else{ 


        //     this.expandedIndex = index === this.expandedIndex ? -1 : index;
        // }


    }
    /*call log count report function start here*/

    //Validator by Deepak
    private callLogCountEndDateError: boolean;
    private callLogCountStartDateError: boolean;
    DayLimitError: boolean;

    initiateCallLogCountValidator() {
        let isValid: boolean = true;
        this.displayErrorMessage = '';

        let stDate = ($('#routeHistoryStartDate').val()).split('-');
        stDate = `${stDate[1]}/${stDate[0]}/${stDate[2]}`;
        let stTimeStamp = new Date(stDate).getTime();


        let endDate = ($('#routeHistoryEndDate').val()).split('-');
        endDate = `${endDate[1]}/${endDate[0]}/${endDate[2]}`;
        let endTimeStamp = new Date(endDate).getTime();

        // let memberInfoStartDate = $('#memberHistoryStartDate').val();
        let stTimeStampString = stTimeStamp.toString();
        let endTimeStampString = endTimeStamp.toString();


        // let callInfoEndDate = $('#routeHistoryEndDate').val();
        // let callInfoStartDate = $('#routeHistoryStartDate').val();


        if (stTimeStampString.length <= 10) {
            // if (!callInfoStartDate) {
            this.callLogCountStartDateError = true;
            isValid = false;
        } else {
            this.callLogCountStartDateError = false;
        }
        if (endTimeStampString.length <= 10) {
            this.callLogCountEndDateError = true;
            isValid = false;
        } else {
            this.callLogCountEndDateError = false;
        }

        if (stTimeStampString.length >= 10 && endTimeStampString.length >= 10) {


            if (stTimeStamp > endTimeStamp) {


                // if ($('#routeHistoryStartDate').val() > $('#routeHistoryEndDate').val()) {
                this.routesDatesMisMatchError = true;
                isValid = false;
            } else {
                this.routesDatesMisMatchError = false;
            }
        }

        if (stTimeStampString.length >= 10 && endTimeStampString.length >= 10) {

            // if (callInfoEndDate && callInfoStartDate) {
            // let endLimit = new Date(callInfoEndDate).getTime() / 1000;
            // let startLimit = new Date(callInfoStartDate).getTime() / 1000;
            let startLimit = stTimeStamp / 1000; let endLimit = endTimeStamp / 1000;
            var time_diff = (endLimit - startLimit);
            var days = Math.floor(time_diff / 60 / 60 / 24);           // no of days
            if (days > 5) {
                this.DayLimitError = true;
                isValid = false;
            } else {
                this.DayLimitError = false;
            }

        }

        return isValid;
    }

    viewCallAlertLogCount(sectionContentId: string): void {
        if (this.initiateCallLogCountValidator()) {
            // let reportStartDate = this.bootstrapDateTime.getInputvalue('routeHistoryStartDate').split(' ');
            // let reportEndDate = this.bootstrapDateTime.getInputvalue('routeHistoryEndDate').split(' ');

            let reportStartDate = this.bootstrapDateTime.getInputvalue('routeHistoryStartDate')
            let reportEndDate = this.bootstrapDateTime.getInputvalue('routeHistoryEndDate')
            reportStartDate = this.dateChange.dateConversionForApicalls(reportStartDate, 'date_time');
            reportEndDate = this.dateChange.dateConversionForApicalls(reportEndDate, 'date_time');

            let apiHeader = {
                data: {
                    key: localStorage.getItem('scbToken'),
                    form: {
                        // fromDate: reportStartDate[0],
                        // fromTime: reportStartDate[1] + ':00',
                        // toDate: reportEndDate[0],
                        // toTime: reportEndDate[1] + ':00'
                        fromDate: reportStartDate.date,
                        fromTime: reportStartDate.time,
                        toDate: reportEndDate.date,
                        toTime: reportEndDate.time
                    }
                }
            }
            if (this.callAlertRouteFilter.length) {
                if (!('filter' in apiHeader.data)) apiHeader.data['filter'] = {};
                Reflect.set(apiHeader.data['filter'], 'routeId', this.callAlertRouteFilter[0]['genericId']);
            }

            //member filter
            // if(this.callAlertStudentFilter.length){
            //     if(!('filter' in apiHeader.data)) apiHeader.data['filter'] = {};
            //     Reflect.set(apiHeader.data['filter'],'memberName',this.callAlertStudentFilter[0]['genericName']);
            // }

            this.isLoading = true;
            this.callALertLogCount = [];
            this.callAlertLogSelectedDates = [];
            this.callAlertLogDatesCount = {};
            this.noCallAlertCountData = false;
            this.apiService.getCallALertLogCout(apiHeader).then(response => {
                if (response.status == "success") {
                    this.displayErrorMessage = '';
                    this.DayLimitError = false;
                    let apiRes = response.response;

                    if (apiRes) {
                        Object.keys(apiRes).forEach((item, index) => {

                            this.callAlertLogDatesCount[item] = apiRes[item].LogCounts;

                        });

                        // this.callAlertLogSelectedDates = Object.keys(apiRes);
                        // let selectedRoutes = Object.keys(apiRes[this.callAlertLogSelectedDates[0]]['RouteInfo']);

                        // for (let route of selectedRoutes) {
                        //     let arr = [];
                        //     let totalCallMade = null;
                        //     for (let dateIndex of this.callAlertLogSelectedDates) {
                        //         let info = apiRes[dateIndex]['RouteInfo'][route];
                        //         info['date'] = dateIndex;
                        //         arr.push(info);
                        //     }

                        //     let obj = {
                        //         info: arr,
                        //         routeId: route,
                        //         routeName: arr[0]['routeName'],
                        //     }
                        //     this.callALertLogCount.push(obj);
                        // }

                        // //setting call log count.
                        // for (let dateIndex of this.callAlertLogSelectedDates) {
                        //     let obj = {
                        //         orgAppMadeCnt: apiRes[dateIndex]['LogCounts']['orgTotalAppCnt'],
                        //         orgSmsMadeCnt: apiRes[dateIndex]['LogCounts']['orgTotalSmsCnt'],
                        //         orgCallMadeCnt: apiRes[dateIndex]['LogCounts']['orgTotalCallCnt']
                        //     }
                        //     this.callAlertLogDatesCount.push(obj);
                        // }

                        this.selectedRouteName = this.callAlertRouteFilter[0] && this.callAlertRouteFilter[0].routeName ? this.callAlertRouteFilter[0].routeName : '';
                    } else {
                        this.noCallAlertCountData = true;
                    }
                    let obj = {
                        [sectionContentId]: true
                    }
                    this.isLoading = false;
                    this.initReportSectionContent(obj);
                } else {
                    this.displayErrorMessage = this.globalUtils.getErrorMsg(response.ec);
                }

            }).catch(error => { });
        }

    }

    /*call log count report function ends here */

    /*App alert log functions start here*/
    appAlertLogValidator() {
        this.displayErrorMessage = '';
        let isValid = true;
        if ((this.AppLogHaltDuration == 3)) {
            if ((this.appHaltDurationMin) && (this.appHaltDurationMax)) {
                if (this.appHaltDurationMin > this.appHaltDurationMax) {
                    this.showAppDurationError = true;
                    isValid = false;
                } else {
                    this.showAppDurationError = false;
                }
            } else {
                isValid = false;
                this.showAppDurationError = true;
            }
        }
        return isValid;
    }

    viewAppAlertLog(sectionContentId: string) {
        if (this.appAlertLogValidator()) {
            let reportStartDate = this.bootstrapDateTime.getInputvalue('appHistoryStartDate');
            let reportEndDate = this.bootstrapDateTime.getInputvalue('appHistoryEndDate');
            reportStartDate = this.dateChange.dateConversionForApicalls(reportStartDate, 'date_time');
            reportEndDate = this.dateChange.dateConversionForApicalls(reportEndDate, 'date_time');

            let apiHeader = {
                data: {
                    key: localStorage.getItem('scbToken'),
                    extra: {
                        orderByDateCreated: -1
                    }
                }
            }
            if ((reportStartDate) && (reportEndDate)) {
                reportStartDate = reportStartDate.split(' ');
                reportEndDate = reportEndDate.split(' ');
                apiHeader.data['form'] = {
                    // fromDate: reportStartDate[0],
                    // fromTime: reportStartDate[1],
                    // toDate: reportEndDate[0],
                    // toTime: reportEndDate[1]
                    fromDate: reportStartDate.date,
                    fromTime: reportStartDate.time,
                    toDate: reportEndDate.date,
                    toTime: reportEndDate.time
                }
                this.reportBasicDetails = apiHeader.data['form'];
                this.isDateSelected = true;
            } else {
                this.isDateSelected = false;
            }

            //check if route filter is aplied
            if (this.callAlertRouteFilter.length) {
                if (!('filter' in apiHeader.data)) apiHeader.data['filter'] = {};
                let routeId = this.callAlertRouteFilter[0].routeId;
                Reflect.set(apiHeader.data['filter'], 'routeId', routeId);
            }

            if (this.AppLogOsFilter) {
                if (!('filter' in apiHeader.data)) apiHeader.data['filter'] = {};
                Reflect.set(apiHeader.data['filter'], 'ostype', this.AppLogOsFilter);
            } else {
                if ('filter' in apiHeader) {
                    delete apiHeader['data']['filter']['ostype'];
                }
            }

            //check if alert duration is applied.
            if ((this.AppLogHaltDuration == 2) || (this.AppLogHaltDuration == 3)) {
                if (!('filter' in apiHeader.data)) apiHeader.data['filter'] = {};
                if (this.AppLogHaltDuration == 2) { //case for alert duration less than a minute.
                    Reflect.set(apiHeader.data['filter'], 'haltDiffMin', 0);
                    Reflect.set(apiHeader.data['filter'], 'haltDiffMax', 1);
                } else {//case for alert duration other than a minute.
                    Reflect.set(apiHeader.data['filter'], 'haltDiffMin', this.appHaltDurationMin);
                    Reflect.set(apiHeader.data['filter'], 'haltDiffMax', this.appHaltDurationMax);
                }
            }

            //setting filters
            if (Object.keys(this.globalFilterObj).length) apiHeader['data']['filter'] = this.globalFilterObj;

            this.callAlertLog = [];
            this.appAlertLog = [];

            this.apiService.getAppAlertLog(apiHeader).then(response => {
                if (response.response) {
                    this.appAlertLogExists = true;
                    Object.assign(this.appAlertLog, response.response);
                } else {
                    this.appAlertLogExists = false;
                }

                let obj = {
                    [sectionContentId]: true
                }
                this.initReportSectionContent(obj);
            }).catch(error => { })
        }

    }

    resetAppLogFilters() {
        this.globalFilterObj = {};
        this.callAlertRouteFilter = [];
        this.callAlertStudentFilter = [];
        this.viewAppAlertLog('appalertmadelog');
    }

    setAlertLogTableFilter(param: string, key: string) {
        Reflect.set(this.globalFilterObj, key, param);
        if (!this.appLogFilterName) {
            if ('memberName' in this.globalFilterObj) delete this.globalFilterObj['memberName']
        }

        if (!this.appLogFilterId) {
            if ('memberUid' in this.globalFilterObj) delete this.globalFilterObj['memberUid']
        }

        if (!this.appLogFilterMobile) {
            if ('mobileNo' in this.globalFilterObj) delete this.globalFilterObj['mobileNo']
        }
        this.viewAppAlertLog('appalertmadelog');
    }


    /*App alert log function ends here*/

    /*SMS ALert log function start*/


    viewSmsAlertLog(sectionContentId: string): void {
        let reportStartDate = this.bootstrapDateTime.getInputvalue('smsHistoryStartDate');
        let reportEndDate = this.bootstrapDateTime.getInputvalue('smsHistoryEndDate');

        reportStartDate = this.dateChange.dateConversionForApicalls(reportStartDate, 'date_time');
        reportEndDate = this.dateChange.dateConversionForApicalls(reportEndDate, 'date_time');

        let apiHeader = {
            data: {
                key: localStorage.getItem('scbToken'),
                extra: {
                    orderByDateCreated: -1
                }
            }
        }

        //check for date filter.
        if ((reportStartDate) && (reportEndDate)) {
            reportStartDate = reportStartDate.split(' ');
            reportEndDate = reportEndDate.split(' ');
            apiHeader.data['form'] = {
                // fromDate: reportStartDate[0],
                // fromTime: reportStartDate[1],
                // toDate: reportEndDate[0],
                // toTime: reportEndDate[1]
                fromDate: reportStartDate.date,
                fromTime: reportStartDate.time,
                toDate: reportEndDate.date,
                toTime: reportEndDate.time
            }
            this.reportBasicDetails = apiHeader.data['form'];
            this.isDateSelected = true;
        } else {
            this.isDateSelected = false;
        }

        //check if route filter is applied
        if (this.callAlertRouteFilter.length) {
            if (!('filter' in apiHeader.data)) apiHeader.data['filter'] = {};
            let routeId = this.callAlertRouteFilter[0].routeId;
            Reflect.set(apiHeader.data['filter'], 'routeId', routeId);
        }

        //check if route type filter is applied.
        if (this.smsLogRouteType) {
            if (!('filter' in apiHeader.data)) apiHeader.data['filter'] = {};
            Reflect.set(apiHeader.data['filter'], 'routeType', this.smsLogRouteType);
        }

        //check if alert duration is applied.
        if ((this.smsLogHaltDuration == 2) || (this.smsLogHaltDuration == 3)) {
            if (!('filter' in apiHeader.data)) apiHeader.data['filter'] = {};
            if (this.smsLogHaltDuration == 2) { //case for alert duration less than a minute.
                Reflect.set(apiHeader.data['filter'], 'haltDiffMin', 0);
                Reflect.set(apiHeader.data['filter'], 'haltDiffMax', 1);
            } else {//case for alert duration other than a minute.
                Reflect.set(apiHeader.data['filter'], 'haltDiffMin', this.smsHaltDurationMin);
                Reflect.set(apiHeader.data['filter'], 'haltDiffMax', this.smsHaltDurationMax);
            }
        }

        this.smsAlertLog = [];
        this.callAlertRouteFilter = [];

        this.apiService.getSmsAlertLog(apiHeader).then(response => {
            if (response.response) {
                Object.assign(this.smsAlertLog, response.response);
                this.smsLogExists = true;
            } else {
                this.smsLogExists = false;
            }
            let obj = {
                [sectionContentId]: true
            }
            this.initReportSectionContent(obj);
        }).catch(error => { })
    }

    /*SMS alert log function end*/


    /*Driver functions to initialize various report sections end*/

    initChosen() {
        setTimeout(() => {
            // this.chosenUtility.initializeChosen('availableReportsDropdown');
        }, 100);
        //  this.bindJqChangeEvt('availableReportsDropdown');
        this.initJqUtils(undefined, 'routeHistoryStartDate', 'routeHistoryEndDate', undefined);
        setTimeout(() => { this.resetCallTempErr() }, 200);
        this.bindJqChangeEvt('demo-select');
    }

    getOrgRoutes() {
        let apiHeader = {
            data: {
                key: localStorage.getItem('scbToken')
            }
        }
        this.apiService.getRoutes(apiHeader).then(response => {
            this.orgRoutes = Object.assign([], response.response);
            let pickerConfig: any = {
                timePicker: false,
                timeFormat: null
            }
            this.initJqUtils('availableRouteDropdown', 'routeHistoryStartDate', 'routeHistoryEndDate', pickerConfig);
        }).catch(error => { })
    }

    setUserSelections(userSelectedValue: any) {
        if (userSelectedValue) this.callAlertRouteFilter = userSelectedValue;
    }

    setUserStudentsSelections(userSelectedValue: any) {
        if (userSelectedValue) this.callAlertStudentFilter = userSelectedValue;
    }

    setSelectedItem(selectedItem: any) {
        if (selectedItem) this.userSelection = selectedItem;
    }

    //Jquery util for change event start.
    bindJqChangeEvt(ele: any) {
        let parent = this;
        $('#' + ele).change(function (e: any) {
            parent.selectedReport = $(this).val();
            let obj = {};
            obj[parent.selectedReport] = true;
            parent.initReportSection(obj);
        });
    }

    initJqUtils(eleChosenId?: string, eleReportStartTime?: string, eleReportEndTime?: string, pickerConfig?: any) {
        let parent = this;
        let timePicker: boolean;
        let timeFormat: any;
        let closeOnDateSelect: boolean;
        if ((pickerConfig) && (Object.keys(pickerConfig).length)) {
            timePicker = pickerConfig.timePicker;
            timeFormat = pickerConfig.timeFormat;
            closeOnDateSelect = true;
        } else {
            timePicker = true;
            // timeFormat = 'Y-m-d H:i:s';
            timeFormat = 'd-m-Y H:i:s';

            closeOnDateSelect = false;
        }
        setTimeout(() => {
            if (eleChosenId) this.chosenUtility.initializeChosen(eleChosenId);
            if (eleReportStartTime) {
                parent.bootstrapDateTime.datetime({
                    component: eleReportStartTime,
                    timepicker: timePicker,
                    format: timeFormat,
                    closeOnDateSelect: closeOnDateSelect
                });
            }
            if (eleReportEndTime) {
                parent.bootstrapDateTime.datetime({
                    component: eleReportEndTime,
                    timepicker: timePicker,
                    format: timeFormat,
                    closeOnDateSelect: closeOnDateSelect
                });
            }
            $('#eventStartTime').datetimepicker({
                format: 'H:i',
                datepicker: false,
                defaultTime: '00:00',
                timepicker: true,
            });
            $('#eventEndTime').datetimepicker({
                format: 'H:i',
                datepicker: false,
                defaultTime: '23:59',
                timepicker: true,
            });
        }, 150);


    }
    //Jquery util for change event end.

    /*Jquery util to get control value without ngModel*/
    getControlValue(eleId: any) {
        return $('#' + eleId).val();
    }

    // Call Made Reports 
    fromEndTimeError: boolean;
    fromStartTimeError: boolean;


    @HostListener("window:scroll", [])
    onWindowScroll() {
        if (window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop > 100) {
            this.navIsFixed = true;
        } else if (this.navIsFixed && window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop < 10) { this.navIsFixed = false; }
    } scrollToTop() {
        (function smoothscroll() {
            var currentScroll = document.documentElement.scrollTop || document.body.scrollTop; if (currentScroll > 0) {
                window.requestAnimationFrame(smoothscroll);
                window.scrollTo(0, currentScroll - (currentScroll / 5));
            }
        })();
    }

    public routeError: boolean;

    initiateEmptyDateValidator() {
        this.displayErrorMessage = '';
        let isValid: boolean = true;
        let fromdate = $('#selectedLogDate').val();
        this.displaySelectedDate = fromdate;


        // let StartDate = this.bootstrapDateTime.getInputvalue('eventStartTime');
        // let Enddate = this.bootstrapDateTime.getInputvalue('eventEndTime');
        // if (!this.routeType) {
        //     this.routeError = true;
        //     isValid = true;
        // } else {
        //     this.routeError = false;

        // }

        if (!fromdate) {
            this.fromDateError = true;
            isValid = false;
        } else {
            this.fromDateError = false;
            this.isValidForView = false;
        }
        if (!this.sessionType) {
            this.sessionErr = true;
            isValid = false;
        } else {
            this.sessionErr = false;
            this.isValidForView = false;
        }

        // if (!StartDate) {
        //     this.fromStartTimeError = true;
        //     isValid = false;
        // } else {
        //     this.fromStartTimeError = false;
        // }

        // if (!Enddate) {
        //     this.fromEndTimeError = true;
        //     isValid = false;
        // } else {
        //     this.fromEndTimeError = false;
        // }

        return isValid;
    }
    getSelectedRouteList(section: string) {

        //this.routeSection = section;



        if (section != "") {
            let routeData = {
                data: {
                    key: localStorage.getItem('scbToken'),
                    //  projections: [
                    //     // section,"routeName"
                    //  ],
                    filter: {
                        tags: section
                    },
                    extra: {
                        maxlimit: "all"
                    }
                }
            }
            this.apiService.getRoutes(routeData).then((res: any) => {

                if (res.status === 'success') {
                    this.sessionWiseRoute = res.response;
                } else {
                    this.sessionWiseRoute = null;
                }


            })


        }
    }

    filterFunctioncallMaderOnTime(selectedReport: any) {

        //NOT REACHED ON TIME CODE STARTS HERE- GET ROUTES ONLY STARTS HERE
        this.newAlertConfigRoutes = [];
        this.beforeSortRoutes = [];
        this.afterSortRoutes = [];
        var routeObj = {
            data: {
                key: localStorage.getItem('scbToken'),
                extra: {
                    maxlimit: 'all'
                },
                filter: {
                    tags: this.sessionType
                }
            }
        };

        this.globalDate = $('#selectedLogDate').val();

        this.isLoading = true;
        this.apiService.getRoutes(routeObj).then(response => {

            if (response.response) {


                //(this.newAlertConfigRoutes.length) ?this.newAlertConfigRoutes = [...this.newAlertConfigRoutes , ...response.response]:this.newAlertConfigRoutes = response.response;
                let apiRes = response.response;
                //this.newAlertConfigRoutes=[];
                Object.keys(apiRes).forEach((item, index) => {
                    if (apiRes[item].routeName === 'Walkers_M' || apiRes[item].routeName === 'Walkers_N' || apiRes[item].routeName === 'Walkers_A') { }
                    else {

                        this.beforeSortRoutes.push(apiRes[item].routeName);
                        this.afterSortRoutes.push(apiRes[item].routeName + '-' + apiRes[item].routeId);
                    }
                    ;

                });
                var a = this.beforeSortRoutes;
                var e = this.afterSortRoutes;

                var numericPart;
                var letterPart;
                var max_chars = 4;
                var pad_char = "0";
                var b = [];
                for (var i = 0; i < e.length; i++) {
                    var sessionsSplit = [];
                    sessionsSplit = e[i].split('-');
                    numericPart = sessionsSplit[0].match(/\d+/);
                    numericPart = (new Array(max_chars + 1).join(pad_char) + numericPart).slice(-max_chars);
                    letterPart = sessionsSplit[0].match(/[A-z]+/);
                    b[i] = (letterPart + numericPart) + '-' + sessionsSplit[1];
                    //console.log('11111111111111  ',b[i]);
                }
                b.sort(function (a, b) {
                    if (a > b)
                        return 1;
                    if (a < b)
                        return -1;
                    // a must be equal to b
                    return 0;
                });

                for (var i = 0; i < b.length; i++) {
                    var sessionsSplitt = [];
                    sessionsSplitt = b[i].split('-');
                    var sessions = [];
                    //console.log('2222222222222  ',b[i]);
                    b[i] = sessionsSplitt[0].replace(/0+(?=[1-9])/, '-');


                    sessions = b[i].split('-');
                    this.setRouteNameFlag[sessions[1] + "" + sessions[0]] = true;
                    //console.log('11111111111111  ',b[i]);
                    this.newAlertConfigRoutes.push({ "routeName": sessions[1] + "" + sessions[0], routeId: sessionsSplitt[1] });
                }
                this.isLoading = false;

                let obj = {};
                obj['' + selectedReport] = true;
                this.initReportSectionContent(obj);

            } else {
                this.noMoredata = true;
            }

        }).catch(error => { });

        //NOT REACHED ON TIME CODE STARTS HERE- GET ROUTES ONLY ENDS HERE

    }



    expandRowReachedOnTime(index: number, routeId: any, routeName: any): void {
        this.ReachedOnTimeList = [];
        let apiHeader = {
            data: {
                key: localStorage.getItem('scbToken'),

            }
        }

        var fieldsSplit = this.globalDate.replace(/\s/g, "").split(/-/);

        this.showTableRowLoader = true;
        var requestDate = fieldsSplit[2] + "-" + fieldsSplit[1] + "-" + fieldsSplit[0];
        apiHeader.data['form'] = {
            fromDate: requestDate,
            //toDate: this.globalDate,
            toDate: requestDate,
            fromTime: "00:00:01",
            toTime: "23:59:59",
            routeId: routeId

        };
        let infoDetails: any = [];
        let ppobj = {};
        this.apiService.getVehicleReachLog(apiHeader).then(response => {

            if (response['status'] === 'success') {
                this.RouteListInfo = response['response'];
                for (let routeId in this.RouteListInfo) {
                    let ppName = this.RouteListInfo[routeId].pickupname;


                    let etaTime = this.RouteListInfo[routeId].pickupeta;

                    var fields = this.globalDate.split(/-/);

                    var nameMonth = fields[1] + "/" + fields[0] + "/" + fields[2];
                    var requestDate = fields[2] + "/" + fields[1] + "/" + fields[0];

                    var nameDate = fields[0];
                    var nameYear = fields[1];

                    var replaceDate = this.globalDate.replace(/-/gi, "/");
                    let etaTimeDate = nameMonth.concat(etaTime);

                    var date = new Date(etaTimeDate); // some mock date
                    var etamilliseconds = date.getTime();



                    if (this.RouteListInfo[routeId].vehTs == '') {


                        ppobj = {
                            pickuppointName: ppName,
                            callTime: this.RouteListInfo[routeId].vehTs,
                            eta: this.RouteListInfo[routeId].pickupeta,
                            diff: '--',
                        }

                        //pckpt.push(ppobj);

                    } else {
                        var callTimeMilliseconds = this.RouteListInfo[routeId].vehTs;


                        var differTime = this.difftimestamp(Number(etamilliseconds), Number(callTimeMilliseconds), 'time_str_no_mins');


                        ppobj = {
                            pickuppointName: ppName,
                            callTime: this.RouteListInfo[routeId].vehTs,
                            eta: this.RouteListInfo[routeId].pickupeta,
                            diff: differTime,
                        }

                        //2025
                        infoDetails.push(ppobj);
                    }



                }

                this.showTableRowLoader = false;

                this.ReachedOnTimeList = [...this.ReachedOnTimeList, ...infoDetails];

            }

        })


    }

    expandRowNotReachedOnTime(index: number, routeId: any, routeName: any): void {
        this.changePage(1);


    }
    returnSideNave() {

        this.isShow = false
    }
    filterFunctioncallMadenrOnTime(selectedReport: any) {




        this.globalDate = $('#selectedLogDate').val().trim();

        let sessionTypes = this.sessionType;
        let fromTime: any = '';
        let toTime: any = '';
        if (sessionTypes == 'Morning') {
            fromTime = '05:00:00';
            toTime = '10:59:00';

        }

        if (sessionTypes == 'Afternoon') {
            fromTime = '11:00:00';
            toTime = '14:00:00';

        }

        if (sessionTypes == 'Evening') {
            fromTime = '14:30:00';
            toTime = '18:30:00';

        }

        this.getAllPP = [];
        this.notReachedAllPPSortedDisplay = [];

        this.chartPPName = [];
        this.chartGradeName = [];
        this.timseSeriesChart = [];
        var fields = this.globalDate.split(/-/);

        var selectedDate = fields[2] + "-" + fields[1] + "-" + fields[0];

        var startDate = new Date(selectedDate); //YYYY-MM-DD
        var endDate = new Date('2020-02-07'); //YYYY-MM-DD
        var dateArr = this.getDateArray(startDate, endDate);
        let obj = {};
        obj['' + selectedReport] = true;
        this.initReportSectionContent(obj);
        this.isLoading = true;
        this.showTableRowLoader = true;
        this.notReachedAllPPShow = true;
        let dateArray: any = [];

        //GET ALL PP CODE STARTS HERE
        let apiHeader = {
            data: {
                key: localStorage.getItem('scbToken'),

                "form": {
                    "fromTime": fromTime,
                    "toTime": toTime
                }
            }
        }



        this.apiService.getAllPPET(apiHeader).then(response => {

            //this.isShow=true;

            var strData = atob(response.response);

            var charData = strData.split('').map(function (x) { return x.charCodeAt(0); });


            var binData = new Uint8Array(charData);


            var data = pako.inflate(binData);


            var strData: string = String.fromCharCode.apply(null, new Uint16Array(data));

            var stringToObject = JSON.parse(strData);
            let allPPObj = {};
            let infoPPDetails: any = [];
            infoPPDetails = [];
            // let totalPPNo:number=0;
            // console.log('rrrrrrrrrrrrrr');
            let parent = this;
            this.totalPPCnt = 0;
            Object.keys(stringToObject).forEach(function (key) {
                let splitData = stringToObject[key].split('#$#');
                //let callMadeTime = splitData[0];
                //console.log('vvvvvvvvvvvv')
                //totalPPNo++;
                parent.totalPPCnt++;
                // console.log('eeeeeeeeeeeeeeeeeeeee')
                let ppName = splitData[1];
                // console.log(Object.keys(stringToObject));
                //console.log('ppNameeeeeeeeeeee : ',ppName ,' ', key);
                //2070
                allPPObj = {
                    ppKey: key,
                    ppName: ppName

                }
                infoPPDetails.push(allPPObj);

            });

            //  console.log('FIRST ',this.totalPPCnt);

            this.getAllPP = [...this.getAllPP, ...infoPPDetails];


        }).catch(error => { })
        //GET ALL PP CODE ENDS HERE
        //console.log('111111111111111  ',this.getAllPP.length);


        //  (async () => {
        //   const res =  await this.API(routeIds);

        //    })(); 

        setTimeout(() => {
            (async () => {
                let indexValue: number = 0;
                let barChartObject: any = [];
                let goodpercentageData: any = [];
                let avgpercentageData: any = [];
                let badpercentageData: any = [];
                let totalCountOf: number = 0;
                let totalCountOfGood: number = 0;
                let totalCountOfAvg: number = 0;
                let totalCountOfBad: number = 0;
                let allRouteNames: any = [];
                for (let i = 0; i < dateArr.length; i++) {//krish
                    this.notReachedAllPP = [];
                    this.notReachedAllPPSorted = [];
                    let startDate = this.datepipe.transform(dateArr[i], 'yyyy-MM-dd');

                    let fromDate: any = startDate;
                    let toDate: any = startDate;


                    dateArray.push(startDate);
                    let apiHeader1 = {
                        data: {
                            key: localStorage.getItem('scbToken'),

                            "form": {
                                "fromDate": fromDate,
                                "fromTime": fromTime,
                                "toDate": fromDate,
                                "toTime": toTime
                            }
                        }
                    }
                    // console.log('BEFORE-111111111111')
                    let infoDetails: any = [];
                    let ppobj = {};
                    infoDetails = [];
                    let parent = this;
                    const res = await this.apiService.getAllPPReachedTime(apiHeader1).then(response => {

                        // console.log('BEFORE-22222222222222');

                        var strData = atob(response.response);

                        var charData = strData.split('').map(function (x) { return x.charCodeAt(0); });


                        var binData = new Uint8Array(charData);


                        var data = pako.inflate(binData);


                        var strData: string = String.fromCharCode.apply(null, new Uint16Array(data));

                        var obj = JSON.parse(strData);



                        var fields = fromDate.split(/-/);

                        var nameMonth = fields[1] + "/" + fields[2] + "/" + fields[0];

                        var pushDateArray = fields[2] + "-" + fields[1] + "-" + fields[0];

                        //console.log('pushDateArray ',pushDateArray);



                        let goodValue: number = 0;
                        let avgValue: number = 0;
                        let badValue: number = 0;

                        Object.keys(obj).forEach(function (key) {

                            indexValue++;

                            let splitData = obj[key].split('#$#');
                            let reachedTime = splitData[0];

                            let etaTime = splitData[1];
                            let callMadeTime = splitData[2];
                            let routeName = splitData[4];
                            allRouteNames.push(routeName);

                            var hour = etaTime.substring(0, 2);
                            var minute = etaTime.substring(2, 4);
                            var mergeHourMin = ' ' + hour + ':' + minute;
                            var mergeHourMinSecons = ' ' + hour + ':' + minute + ':' + '00';


                            let etaTimeDate = nameMonth.concat(mergeHourMin);

                            var date = new Date(etaTimeDate);

                            var etamilliseconds = date.getTime();

                            var reachTimeMilliseconds = reachedTime;

                            // console.log('callTimeMilliseconds : ',callTimeMilliseconds);

                            var differTimeCallReached = parent.difftimestamp(Number(callMadeTime), Number(reachTimeMilliseconds), 'time_str_no_mins');
                            var intDifferenceCallReached = Number(differTimeCallReached);
                            let gradeCallReached: number;
                            let percheck: number = 10;


                            //GOOD STARTS HERE        
                            if ((intDifferenceCallReached >= 5) && (intDifferenceCallReached <= 10)) {

                                //gradeCallReached=(10/10)*100;
                                gradeCallReached = (0 / 10) * 100;
                                goodValue++;
                            }

                            if ((intDifferenceCallReached === 4) || (intDifferenceCallReached === 11)) {

                                //gradeCallReached=(9/10)*100;
                                gradeCallReached = (1 / 10) * 100;
                                goodValue++;
                            }

                            if ((intDifferenceCallReached === 3) || (intDifferenceCallReached === 12)) {

                                //gradeCallReached=(8/10)*100;
                                gradeCallReached = (2 / 10) * 100;
                                goodValue++;
                            }
                            //GOOD ENDS HERE

                            //AVERAGE STARTS HERE

                            if ((intDifferenceCallReached === 2) || (intDifferenceCallReached === 13)) {

                                //gradeCallReached=(7/10)*100;
                                gradeCallReached = (3 / 10) * 100;
                                avgValue++;
                            }
                            if (intDifferenceCallReached === 14) {

                                //gradeCallReached=(6/10)*100;
                                gradeCallReached = (4 / 10) * 100;
                                avgValue++;
                            }
                            if (intDifferenceCallReached === 15) {

                                //gradeCallReached=(5/10)*100;
                                gradeCallReached = (5 / 10) * 100;
                                avgValue++;
                            }

                            //AVERAGE STARTS HERE


                            //BAD STARTS HERE
                            if (intDifferenceCallReached === 16) {

                                //gradeCallReached=(4/10)*100;
                                gradeCallReached = (6 / 10) * 100;
                                badValue++;
                            }

                            if (intDifferenceCallReached === 17) {

                                //gradeCallReached=(3/10)*100;
                                gradeCallReached = (7 / 10) * 100;
                                badValue++;
                            }
                            if (intDifferenceCallReached === 18) {

                                //gradeCallReached=(2/10)*100;
                                gradeCallReached = (8 / 10) * 100;
                                badValue++;
                            }
                            if ((intDifferenceCallReached === 19)) {

                                //gradeCallReached=(1/10)*100;
                                gradeCallReached = (9 / 10) * 100;
                                badValue++;
                            }
                            if ((intDifferenceCallReached < 2)) {

                                //gradeCallReached=(0/10)*100;
                                gradeCallReached = (10 / 10) * 100;
                                badValue++;
                            }
                            if ((intDifferenceCallReached >= 20)) {

                                //gradeCallReached=(0/10)*100;
                                gradeCallReached = (10 / 10) * 100;
                                badValue++;
                            }

                            //BAD ENDS HERE           
                            ///////////////////////////




                            //if(gradeCallReached===0 ||gradeCallReached===10 || gradeCallReached===20 || gradeCallReached===30 || gradeCallReached===40 || gradeCallReached===50 || gradeCallReached===60    ){
                            ppobj = {
                                reachedTime: reachTimeMilliseconds,
                                etaTime: etaTimeDate,
                                // difference:differTime,
                                differenceCallMadeReached: differTimeCallReached,
                                callMadeTime: callMadeTime,
                                ppId: key,
                                // grade:grade,
                                gradeCallReached: gradeCallReached,
                                indexValue: indexValue,
                                mergeHourMinSecons: mergeHourMinSecons,
                                routeName: routeName

                            }

                            infoDetails.push(ppobj);

                        });



                        this.notReachedAllPP = [...this.notReachedAllPP, ...infoDetails];
                        //this.notReachedAllPPSorted = this.notReachedAllPP.sort((a:any, b:any) => b.callMadeTime - a.callMadeTime)
                        this.notReachedAllPPSorted = this.notReachedAllPP.sort(this.sortFunctionDate);


                        let finalObj: any = {};
                        let ppObjects: any = [];


                        let timeSeriesArray: any = [];

                        let finalObjPpName: any = {};
                        //let tooltipObjName:any={};
                        // let tooltipObjArray:any=[];
                        let ppObjectsName: any = [];
                        let finalObjPpNameGrade: any = {};
                        let ppObjectsNameGrade: any = [];

                        ppObjectsName = [];
                        ppObjectsNameGrade = [];
                        finalObj = {};
                        ppObjects = [];

                        let barchartIncrement: number = 0;
                        let goodVal: number = 0;
                        let avgVal: number = 0;
                        let badVal: number = 0;
                        let tableGrade: any = '';
                        (Object.keys(this.notReachedAllPPSorted)).forEach((sortedDataKey: string) => {
                            //console.log('111111111111 ',this.notReachedAllPPSorted[sortedDataKey].ppId,' *** ',this.notReachedAllPPSorted[sortedDataKey].difference);
                            (Object.keys(this.getAllPP)).forEach((keyPP: string) => {

                                // console.log('111111111111 ',this.getAllPP[keyPP].ppKey,' *** ',this.getAllPP[keyPP].ppName);

                                if (this.notReachedAllPPSorted[sortedDataKey].ppId === this.getAllPP[keyPP].ppKey) {

                                    barchartIncrement++;
                                    //console.log('VALUE : ',this.notReachedAllPPSorted[sortedDataKey].gradeCallReached);
                                    if ((this.notReachedAllPPSorted[sortedDataKey].gradeCallReached === 0) || (this.notReachedAllPPSorted[sortedDataKey].gradeCallReached === 10) || (this.notReachedAllPPSorted[sortedDataKey].gradeCallReached === 20)) {
                                        //console.log('GOODDDDDDDDDDD');
                                        goodVal++;
                                        tableGrade = 'GOOD';

                                    }
                                    if ((this.notReachedAllPPSorted[sortedDataKey].gradeCallReached === 30) || (this.notReachedAllPPSorted[sortedDataKey].gradeCallReached === 40) || (this.notReachedAllPPSorted[sortedDataKey].gradeCallReached === 50)) {
                                        //console.log('AVERAGEEEEEEEEEEEE');
                                        avgVal++;
                                        tableGrade = 'AVG';
                                    }

                                    if ((this.notReachedAllPPSorted[sortedDataKey].gradeCallReached === 60) || (this.notReachedAllPPSorted[sortedDataKey].gradeCallReached === 70) || (this.notReachedAllPPSorted[sortedDataKey].gradeCallReached === 80) || (this.notReachedAllPPSorted[sortedDataKey].gradeCallReached === 90) || (this.notReachedAllPPSorted[sortedDataKey].gradeCallReached === 100)) {
                                        //  console.log('BADDDDDDDDDDDDDDDD');
                                        badVal++;
                                        tableGrade = 'BAD';

                                    }

                                    //console.log('Location Reached' ,this.notReachedAllPPSorted[sortedDataKey].reachedTime,' **** ',this.notReachedAllPPSorted[sortedDataKey].gradeCallReached);

                                    finalObj = {
                                        reachedTime: this.notReachedAllPPSorted[sortedDataKey].reachedTime,
                                        etaTime: this.notReachedAllPPSorted[sortedDataKey].etaTime,
                                        callMadeTime: this.notReachedAllPPSorted[sortedDataKey].callMadeTime,
                                        differenceCallMadeReached: this.notReachedAllPPSorted[sortedDataKey].differenceCallMadeReached,
                                        ppName: this.getAllPP[keyPP].ppName,
                                        gradeCallReached: this.notReachedAllPPSorted[sortedDataKey].gradeCallReached,
                                        indexValue: this.notReachedAllPPSorted[sortedDataKey].indexValue,
                                        tableGrade: tableGrade,
                                        ppID: this.notReachedAllPPSorted[sortedDataKey].ppId,
                                        selectedDate: fromDate,
                                        mergeHourMinSecons: this.notReachedAllPPSorted[sortedDataKey].mergeHourMinSecons,
                                        routeName: this.notReachedAllPPSorted[sortedDataKey].routeName

                                    }
                                    // console.log('finalObj ',finalObj);
                                    let mergeData = this.getAllPP[keyPP].ppName + ' ' + this.notReachedAllPPSorted[sortedDataKey].gradeCallReached;
                                    // tooltipObjName={ 
                                    // PickupName:this.getAllPP[keyPP].ppName,
                                    // gradeCallReached:this.notReachedAllPPSorted[sortedDataKey].gradeCallReached
                                    // }

                                    finalObjPpName = {
                                        ppName: this.getAllPP[keyPP].ppName

                                    }
                                    finalObjPpNameGrade = {
                                        grade: this.notReachedAllPPSorted[sortedDataKey].gradeCallReached

                                    }
                                    ppObjects.push(finalObj);
                                    ppObjectsName.push(this.getAllPP[keyPP].indexValue);


                                    var callMadeMilliSeconds = this.notReachedAllPPSorted[sortedDataKey].callMadeTime;
                                    var callMadeMilliSecondsToDate = new Date(parseInt(callMadeMilliSeconds, 10));
                                    //var callMadeMilliSecondsToDateToString = callMadeMilliSecondsToDate.toString();
                                    let callMadeDateFormatted = this.datepipe.transform(callMadeMilliSecondsToDate.toString(), 'dd-MM-yyyy HH:mm:ss');


                                    var ReachedMilliSeconds = this.notReachedAllPPSorted[sortedDataKey].reachedTime;
                                    var ReachedMilliSecondsToDate = new Date(parseInt(ReachedMilliSeconds, 10));
                                    //var callMadeMilliSecondsToDateToString = callMadeMilliSecondsToDate.toString();
                                    let ReachedDateFormatted = this.datepipe.transform(ReachedMilliSecondsToDate.toString(), 'dd-MM-yyyy HH:mm:ss');


                                    let timeSeriesObj = {
                                        x: Number(this.notReachedAllPPSorted[sortedDataKey].callMadeTime),
                                        y: this.notReachedAllPPSorted[sortedDataKey].gradeCallReached,
                                        pickupName: this.getAllPP[keyPP].ppName,
                                        callMade: callMadeDateFormatted,
                                        diff: this.notReachedAllPPSorted[sortedDataKey].differenceCallMadeReached,
                                        Reached: ReachedDateFormatted,
                                        //color: '#FF00FF',
                                        etaTime: this.notReachedAllPPSorted[sortedDataKey].etaTime

                                    };
                                    timeSeriesArray.push(timeSeriesObj);

                                    let yAxisObj = {
                                        y: this.notReachedAllPPSorted[sortedDataKey].gradeCallReached,
                                        pickupName: this.getAllPP[keyPP].ppName,
                                        callMade: callMadeDateFormatted,
                                        diff: this.notReachedAllPPSorted[sortedDataKey].differenceCallMadeReached,
                                        Reached: ReachedDateFormatted,
                                        //color: '#FF00FF',
                                        etaTime: this.notReachedAllPPSorted[sortedDataKey].etaTime
                                        //marker: { radius: 15}

                                    }

                                    ppObjectsNameGrade.push(yAxisObj)
                                    // tooltipObjArray.push(tooltipObjName);

                                }





                            });
                        });
                        // console.log('INCREMENT ',barchartIncrement ,' ', goodVal ,' ', avgVal ,' ', badVal);
                        this.notReachedAllPPSortedDisplay = [...this.notReachedAllPPSortedDisplay, ...ppObjects];
                        this.timseSeriesChart = [...this.timseSeriesChart, ...timeSeriesArray];
                        //this.timseSeriesChartSorted =this.timseSeriesChart.sort(this.sortFunctionChart);

                        // console.log('BEFORE- 777777777777777777 ',this.notReachedAllPPSortedDisplay.length);  
                        this.chartPPName = [...this.chartPPName, ...ppObjectsName];
                        this.chartGradeName = [...this.chartGradeName, ...ppObjectsNameGrade];



                        totalCountOf = totalCountOf + barchartIncrement;
                        totalCountOfGood = totalCountOfGood + goodVal;
                        totalCountOfAvg = totalCountOfAvg + avgVal;
                        totalCountOfBad = totalCountOfBad + badVal;







                    }).catch(error => { })






                }//krish date loop ends
                this.uniqueRouteNames = Array.from(new Set(allRouteNames));

                //console.log("uniqueArray: ", this.uniqueRouteNames);
                ///  CODE STARTS FOR AREA CHART

                let dateCount = dateArr.length;
                //ROUTE SORTING CODE STARTS HERE
                let routeGrades: any = [];
                for (let r = 0; r < this.uniqueRouteNames.length; r++) {

                    //console.log(this.uniqueRouteNames[r]);

                    let routeNameS = this.uniqueRouteNames[r];
                    //DATE-WISE STARTS HERE
                    let goodMarkTotal: number = 0;
                    let avgMarkTotal: number = 0;
                    let badMarkTotal: number = 0;
                    let ReachedTriggeresTotal: number = 0;

                    let allPPNames: any = [];
                    let distinctPpNames: any = [];


                    let incre: number = 0;



                    let tableGrades: any = '';

                    //GET ALL ROUTES PP

                    let getAllRouesPPSObj = {};
                    let getAllRoutesPPArray: any = [];



                    (Object.keys(this.getAllPP)).forEach((keyPP: string) => {   //LOOP ALL PP CODE STARTS HERE


                        (Object.keys(this.notReachedAllPPSortedDisplay)).forEach((sortedDataKey: string) => { //sorted pp details loop code starts here

                            //COMPARE ALL PP=SORTED PP CODE STARTS HERE

                            if (routeNameS === this.notReachedAllPPSortedDisplay[sortedDataKey].routeName) {
                                if (this.notReachedAllPPSortedDisplay[sortedDataKey].ppID === this.getAllPP[keyPP].ppKey) {
                                    //console.log('11111111111111111 :',this.getAllPP[keyPP].ppName,' ',this.notReachedAllPPSortedDisplay[sortedDataKey].selectedDate);
                                    let totalDifference: number = 0;
                                    let diffReachedTriggeres: number = 0;
                                    let PPName: any = '';


                                    incre++;
                                    totalPP++;

                                    diffReachedTriggeres = diffReachedTriggeres + this.notReachedAllPPSortedDisplay[sortedDataKey].differenceCallMadeReached
                                    PPName = this.getAllPP[keyPP].ppKey;
                                    allPPNames.push(PPName)

                                    getAllRouesPPSObj = {
                                        // reachedTime:this.notReachedAllPPSorted[sortedDataKey].reachedTime,
                                        // etaTime:this.notReachedAllPPSorted[sortedDataKey].etaTime,
                                        // callMadeTime:this.notReachedAllPPSorted[sortedDataKey].callMadeTime,
                                        differenceCallMadeReached: this.notReachedAllPPSortedDisplay[sortedDataKey].differenceCallMadeReached,
                                        ppName: this.getAllPP[keyPP].ppName,
                                        // gradeCallReached:this.notReachedAllPPSorted[sortedDataKey].gradeCallReached,
                                        // indexValue:this.notReachedAllPPSorted[sortedDataKey].indexValue,
                                        // tableGrade:tableGrade,
                                        // ppID:this.notReachedAllPPSorted[sortedDataKey].ppId,
                                        selectedDate: this.notReachedAllPPSortedDisplay[sortedDataKey].selectedDate,
                                        // mergeHourMinSecons:this.notReachedAllPPSorted[sortedDataKey].mergeHourMinSecons,
                                        // routeName:this.notReachedAllPPSorted[sortedDataKey].routeName

                                    }
                                    getAllRoutesPPArray.push(getAllRouesPPSObj);

                                }
                                //console.log('55555555555555555 : '); 
                            }



                        });//sorted pp details loop code ends here





                    });//LOOP ALL PP CODE ENDS HERE


                    distinctPpNames = Array.from(new Set(allPPNames));
                    //console.log(routeNameS ,' ',distinctPpNames.length);
                    let markGood: number = 0;
                    let markAvg: number = 0;
                    let markBad: number = 0;
                    let gradeGive: number = 0;
                    for (let pp = 0; pp < distinctPpNames.length; pp++) {



                        let ppNames = distinctPpNames[pp];
                        let totalDifferencesVal: number = 0;
                        let incrementValues: number = 0;
                        (Object.keys(this.notReachedAllPPSortedDisplay)).forEach((sortedDataKey: string) => {
                            if (ppNames === this.notReachedAllPPSortedDisplay[sortedDataKey].ppID) {
                                incrementValues++;
                                // console.log('DIFF ',this.notReachedAllPPSortedDisplay[sortedDataKey].differenceCallMadeReached);
                                totalDifferencesVal = totalDifferencesVal + this.notReachedAllPPSortedDisplay[sortedDataKey].differenceCallMadeReached;

                            }


                        });

                        //console.log('TOTAL : ',totalDifferencesVal);
                        if (totalDifferencesVal === 0 && totalDifferencesVal === 0) {
                        } else {


                            let avgReachedTriggeresVal = Math.round((totalDifferencesVal / incrementValues));


                            if ((avgReachedTriggeresVal >= 5) && (avgReachedTriggeresVal <= 10)) {

                                gradeGive = (0 / 10) * 100;
                                markGood++;
                                tableGrades = 'GOOD';
                            }

                            if ((avgReachedTriggeresVal === 4) || (avgReachedTriggeresVal === 11)) {

                                gradeGive = (1 / 10) * 100;
                                markGood++;
                                tableGrades = 'GOOD';
                            }

                            if ((avgReachedTriggeresVal === 3) || (avgReachedTriggeresVal === 12)) {

                                gradeGive = (2 / 10) * 100;
                                markGood++;
                                tableGrades = 'GOOD';
                            }
                            //GOOD ENDS HERE

                            //AVERAGE STARTS HERE

                            if ((avgReachedTriggeresVal === 2) || (avgReachedTriggeresVal === 13)) {

                                gradeGive = (3 / 10) * 100;
                                tableGrades = 'AVG';
                                markAvg++;
                            }
                            if (avgReachedTriggeresVal === 14) {

                                gradeGive = (4 / 10) * 100;
                                tableGrades = 'AVG';
                                markAvg++;
                            }
                            if (avgReachedTriggeresVal === 15) {

                                gradeGive = (5 / 10) * 100;
                                tableGrades = 'AVG';
                                markAvg++;
                            }

                            //AVERAGE STARTS HERE


                            //BAD STARTS HERE
                            if (avgReachedTriggeresVal === 16) {

                                gradeGive = (6 / 10) * 100;
                                tableGrades = 'BAD';
                                markBad++;
                            }

                            if (avgReachedTriggeresVal === 17) {

                                gradeGive = (7 / 10) * 100;
                                tableGrades = 'BAD';
                                markBad++;
                            }
                            if (avgReachedTriggeresVal === 18) {

                                gradeGive = (8 / 10) * 100;
                                tableGrades = 'BAD';
                                markBad++;
                            }
                            if ((avgReachedTriggeresVal === 19)) {

                                gradeGive = (9 / 10) * 100;
                                tableGrades = 'BAD';
                                markBad++;
                            }
                            if ((avgReachedTriggeresVal < 2)) {

                                gradeGive = (10 / 10) * 100;
                                tableGrades = 'BAD';
                                markBad++;

                            }
                            if ((avgReachedTriggeresVal >= 20)) {

                                gradeGive = (10 / 10) * 100;
                                tableGrades = 'BAD';
                                markBad++;
                            }


                        }




                    }



                    let routeObj = {

                        routeName: this.uniqueRouteNames[r],
                        gradeCntGood: markGood,
                        gradeCntAvg: markAvg,
                        gradeCntBad: markBad
                    }
                    routeGrades.push(routeObj);
                }
                this.uniqueRouteNamesBasedGrades = [...this.uniqueRouteNamesBasedGrades, ...routeGrades];
                this.uniqueRouteNamesBasedGradesSorted = this.uniqueRouteNamesBasedGrades.sort(this.sortFunctionRoutes);








                //console.log('TOTAL : ',this.totalPPCnt,' ',goodMarkTotal,' ',avgMarkTotal,' ',badMarkTotal);
                //ROUTE SORTING CODE ENDS HERE




                //DATE WISE BAR CHART OBJECT CREATION CODE STARTS HERE
                for (let ii = 0; ii < dateArr.length; ii++) {//krish
                    let goodMark: number = 0;
                    let avgMark: number = 0;
                    let badMark: number = 0;
                    let totalPP: number = 0;
                    let inco: number = 0;


                    let startDate = this.datepipe.transform(dateArr[ii], 'yyyy-MM-dd');
                    (Object.keys(this.getAllPP)).forEach((keyPP: string) => {   //LOOP ALL PP CODE STARTS HERE

                        let incre: number = 0;


                        let diffReachedTriggeres: number = 0;
                        let tableGrades: any = '';
                        let PPName: any = '';

                        (Object.keys(this.notReachedAllPPSortedDisplay)).forEach((sortedDataKey: string) => { //sorted pp details loop code starts here

                            //COMPARE ALL PP=SORTED PP CODE STARTS HERE


                            if (this.notReachedAllPPSortedDisplay[sortedDataKey].ppID === this.getAllPP[keyPP].ppKey) {





                                if (startDate === this.notReachedAllPPSortedDisplay[sortedDataKey].selectedDate) {

                                    incre++;
                                    totalPP++;

                                    diffReachedTriggeres = diffReachedTriggeres + this.notReachedAllPPSortedDisplay[sortedDataKey].differenceCallMadeReached
                                    PPName = this.getAllPP[keyPP].ppName;

                                }


                            }
                            //COMPARE ALL PP=SORTED PP CODE ENDS HERE


                        });//sorted pp details loop code ends here


                        if (diffReachedTriggeres === 0 && incre === 0) {
                        } else {
                            inco++;

                            let avgReachedTriggeres = Math.round((diffReachedTriggeres / incre));

                            let giveGrade: number = 0;

                            //GIVE GRADE CODE STARTS HERE
                            //GOOD STARTS HERE        
                            if ((avgReachedTriggeres >= 5) && (avgReachedTriggeres <= 10)) {

                                giveGrade = (0 / 10) * 100;
                                goodMark++;
                                tableGrades = 'GOOD';
                            }

                            if ((avgReachedTriggeres === 4) || (avgReachedTriggeres === 11)) {

                                giveGrade = (1 / 10) * 100;
                                goodMark++;
                                tableGrades = 'GOOD';
                            }

                            if ((avgReachedTriggeres === 3) || (avgReachedTriggeres === 12)) {

                                giveGrade = (2 / 10) * 100;
                                goodMark++;
                                tableGrades = 'GOOD';
                            }
                            //GOOD ENDS HERE

                            //AVERAGE STARTS HERE

                            if ((avgReachedTriggeres === 2) || (avgReachedTriggeres === 13)) {

                                giveGrade = (3 / 10) * 100;
                                tableGrades = 'AVG';
                                avgMark++;
                            }
                            if (avgReachedTriggeres === 14) {

                                giveGrade = (4 / 10) * 100;
                                tableGrades = 'AVG';
                                avgMark++;
                            }
                            if (avgReachedTriggeres === 15) {

                                giveGrade = (5 / 10) * 100;
                                tableGrades = 'AVG';
                                avgMark++;
                            }

                            //AVERAGE STARTS HERE


                            //BAD STARTS HERE
                            if (avgReachedTriggeres === 16) {

                                giveGrade = (6 / 10) * 100;
                                tableGrades = 'BAD';
                                badMark++;
                            }

                            if (avgReachedTriggeres === 17) {

                                giveGrade = (7 / 10) * 100;
                                tableGrades = 'BAD';
                                badMark++;
                            }
                            if (avgReachedTriggeres === 18) {

                                giveGrade = (8 / 10) * 100;
                                tableGrades = 'BAD';
                                badMark++;
                            }
                            if ((avgReachedTriggeres === 19)) {

                                giveGrade = (9 / 10) * 100;
                                tableGrades = 'BAD';
                                badMark++;
                            }
                            if ((avgReachedTriggeres < 2)) {

                                giveGrade = (10 / 10) * 100;

                                badMark++;
                                tableGrades = 'BAD';
                            }
                            if ((avgReachedTriggeres >= 20)) {

                                giveGrade = (10 / 10) * 100;
                                tableGrades = 'BAD';
                                badMark++;
                            }


                        }
                        //BAD ENDS HERE           

                    });//LOOP ALL PP CODE ENDS HERE
                    let goodPercentageDatas: number;
                    let avgPercentageDatas: number;
                    let badPercentageDatas: number;
                    goodPercentageDatas = Math.round((goodMark / this.totalPPCnt) * 100);
                    avgPercentageDatas = Math.round((avgMark / this.totalPPCnt) * 100);
                    badPercentageDatas = Math.round((badMark / this.totalPPCnt) * 100);

                    goodpercentageData.push({ y: goodPercentageDatas, totCnt: goodMark });
                    avgpercentageData.push({ y: avgPercentageDatas, totCnt: avgMark });
                    badpercentageData.push({ y: badPercentageDatas, totCnt: badMark });


                }
                //DATE WISE BAR CHART OBJECT CREATION CODE ENDS HERE



                let goodMark: number = 0;
                let avgMark: number = 0;
                let badMark: number = 0;
                let totalPP: number = 0;
                let inco: number = 0;
                let finalObjForPieChart = {};
                let finalObjForPieChartArray: any = [];
                let timeSeriesArrayObj: any = [];
                //var callMadeMilliSeconds = this.notReachedAllPPSortedDisplay[sortedDataKey].callMadeTime;
                var callMadeMilliSecondsToDatex = new Date(parseInt('37800000', 10));
                let callMadeDateFormattedx = this.datepipe.transform(callMadeMilliSecondsToDatex.toString(), 'HH:mm:ss');


                (Object.keys(this.getAllPP)).forEach((keyPP: string) => {   //LOOP ALL PP CODE STARTS HERE

                    let incre: number = 0;


                    let diffReachedTriggeres: number = 0;
                    let tableGrades: any = '';
                    let PPName: any = '';
                    let routeName: any = '';
                    let expectedTimeArray: any = [];
                    let callMadeTimeArray: any = [];
                    let reachedTimeArray: any = [];
                    (Object.keys(this.notReachedAllPPSortedDisplay)).forEach((sortedDataKey: string) => { //sorted pp details loop code starts here

                        //COMPARE ALL PP=SORTED PP CODE STARTS HERE


                        if (this.notReachedAllPPSortedDisplay[sortedDataKey].ppID === this.getAllPP[keyPP].ppKey) {







                            incre++;
                            totalPP++;

                            diffReachedTriggeres = diffReachedTriggeres + this.notReachedAllPPSortedDisplay[sortedDataKey].differenceCallMadeReached
                            PPName = this.getAllPP[keyPP].ppName;
                            routeName = this.notReachedAllPPSortedDisplay[sortedDataKey].routeName;

                            var reachedMilliSeconds = this.notReachedAllPPSortedDisplay[sortedDataKey].reachedTime;
                            var reachedMilliSecondsToDate = new Date(parseInt(reachedMilliSeconds, 10));
                            let reachedDateFormatted = this.datepipe.transform(reachedMilliSecondsToDate.toString(), 'HH:mm:ss');
                            reachedTimeArray.push(reachedDateFormatted);


                            expectedTimeArray.push(this.notReachedAllPPSortedDisplay[sortedDataKey].mergeHourMinSecons)


                            var callMadeMilliSeconds = this.notReachedAllPPSortedDisplay[sortedDataKey].callMadeTime;
                            var callMadeMilliSecondsToDate = new Date(parseInt(callMadeMilliSeconds, 10));
                            let callMadeDateFormatted = this.datepipe.transform(callMadeMilliSecondsToDate.toString(), 'HH:mm:ss');
                            callMadeTimeArray.push(callMadeDateFormatted);



                        }
                        //COMPARE ALL PP=SORTED PP CODE ENDS HERE


                    });//sorted pp details loop code ends here


                    if (diffReachedTriggeres === 0 && incre === 0) {
                    } else {
                        inco++;
                        // let expAvg=this.getAverageTime(expectedTimeArray);
                        // let callMadeAvg=this.getAverageTime(callMadeTimeArray);
                        // let reachedAvg=this.getAverageTime(reachedTimeArray);


                        let avgReachedTriggeres = Math.round((diffReachedTriggeres / incre));

                        let giveGrade: number = 0;

                        //GIVE GRADE CODE STARTS HERE
                        //GOOD STARTS HERE       -----  yyy 
                        if ((avgReachedTriggeres >= 5) && (avgReachedTriggeres <= 10)) {

                            giveGrade = (0 / 10) * 100;
                            goodMark++;
                            tableGrades = 'GOOD';
                        }

                        if ((avgReachedTriggeres === 4) || (avgReachedTriggeres === 11)) {

                            giveGrade = (1 / 10) * 100;
                            goodMark++;
                            tableGrades = 'GOOD';
                        }

                        if ((avgReachedTriggeres === 3) || (avgReachedTriggeres === 12)) {

                            giveGrade = (2 / 10) * 100;
                            goodMark++;
                            tableGrades = 'GOOD';
                        }
                        //GOOD ENDS HERE

                        //AVERAGE STARTS HERE

                        if ((avgReachedTriggeres === 2) || (avgReachedTriggeres === 13)) {

                            giveGrade = (3 / 10) * 100;
                            tableGrades = 'AVG';
                            avgMark++;
                        }
                        if (avgReachedTriggeres === 14) {

                            giveGrade = (4 / 10) * 100;
                            tableGrades = 'AVG';
                            avgMark++;
                        }
                        if (avgReachedTriggeres === 15) {

                            giveGrade = (5 / 10) * 100;
                            tableGrades = 'AVG';
                            avgMark++;
                        }

                        //AVERAGE STARTS HERE


                        //BAD STARTS HERE
                        if (avgReachedTriggeres === 16) {

                            giveGrade = (6 / 10) * 100;
                            tableGrades = 'BAD';
                            badMark++;
                        }

                        if (avgReachedTriggeres === 17) {

                            giveGrade = (7 / 10) * 100;
                            tableGrades = 'BAD';
                            badMark++;
                        }
                        if (avgReachedTriggeres === 18) {

                            giveGrade = (8 / 10) * 100;
                            tableGrades = 'BAD';
                            badMark++;
                        }
                        if ((avgReachedTriggeres === 19)) {

                            giveGrade = (9 / 10) * 100;
                            tableGrades = 'BAD';
                            badMark++;
                        }
                        if ((avgReachedTriggeres < 2)) {

                            giveGrade = (10 / 10) * 100;

                            badMark++;
                            tableGrades = 'BAD';
                        }
                        if ((avgReachedTriggeres >= 20)) {

                            giveGrade = (10 / 10) * 100;
                            tableGrades = 'BAD';
                            badMark++;
                        }
                        let expAvg = this.getAverageTime(expectedTimeArray);
                        let callMadeAvg = this.getAverageTime(callMadeTimeArray);//2050
                        let reachedAvg = this.getAverageTime(reachedTimeArray);

                        //convert  time to milliseconds starts here
                        let splitexpAvg = expAvg.split(':');

                        let timetomilli = this.timetomilli(splitexpAvg[0], splitexpAvg[1], splitexpAvg[2]);


                        let splitcallMadeAvg = callMadeAvg.split(':');
                        var mergeHourMins = ' ' + splitcallMadeAvg[0] + ':' + splitcallMadeAvg[1];
                        let dummyDate = '02/06/2020';
                        let etaTimeDate = dummyDate.concat(mergeHourMins);

                        var date = new Date(etaTimeDate);

                        var etamilliseconds = date.getTime();



                        //let timetomillisplitcallMadeAvg=this.timetomilli(splitcallMadeAvg[0],splitcallMadeAvg[1],splitcallMadeAvg[2]);

                        let splitreachedAvg = reachedAvg.split(':');

                        let timetomillisplitreachedAvg = this.timetomilli(splitreachedAvg[0], splitreachedAvg[1], splitreachedAvg[2]);


                        let timeSeriesObj = {
                            x: Number(etamilliseconds),
                            y: giveGrade,
                            pickupName: this.getAllPP[keyPP].ppName,
                            callMade: callMadeAvg,
                            diff: avgReachedTriggeres,
                            Reached: reachedAvg,
                            //color: '#FF00FF',
                            etaTime: expAvg,
                            routeName: routeName

                        };
                        timeSeriesArrayObj.push(timeSeriesObj);

                        //convert  time to milliseconds ends here

                        //var callMadeMilliSecondsToDatex = new Date(timetomilli, 10);

                        //let callMadeDateFormattedx =this.datepipe.transform(callMadeMilliSecondsToDatex.toString(), 'HH:mm:ss');





                        finalObjForPieChart = {

                            reachedTime: reachedAvg,
                            etaTime: expAvg,

                            callMadeTime: callMadeAvg,
                            differenceCallMadeReached: avgReachedTriggeres,
                            ppName: this.getAllPP[keyPP].ppName,

                            tableGrade: tableGrades,
                            routeName: routeName

                        }
                        finalObjForPieChartArray.push(finalObjForPieChart);
                    }
                    //BAD ENDS HERE           

                });//LOOP ALL PP CODE ENDS HERE

                this.timseSeriesChartObject = [...this.timseSeriesChartObject, ...timeSeriesArrayObj];
                this.timseSeriesChartObjectOrdered = this.timseSeriesChartObject.sort(this.sortFunctionTimeSeries);
                //timseSeriesChartObjectOrdered
                this.finalObjForPieChartArrayGlobal = [...this.finalObjForPieChartArrayGlobal, ...finalObjForPieChartArray];

                ///CODE ENDS FOR AREA CHART -xxx
                let goodPercentageDatas: number;
                let avgPercentageDatas: number;
                let badPercentageDatas: number;
                goodPercentageDatas = Math.round((goodMark / this.totalPPCnt) * 100);
                avgPercentageDatas = Math.round((avgMark / this.totalPPCnt) * 100);
                badPercentageDatas = Math.round((badMark / this.totalPPCnt) * 100);


                //PIE CHART OBJECT CREATION CODE STARTS HER
                let pieChartObj1 = {
                    name: 'GOOD', y: goodPercentageDatas, totCnt: goodMark, color: '#a1b126', sliced: false

                }
                let pieChartObj2 = {

                    name: 'AVG', y: avgPercentageDatas, totCnt: avgMark, color: '#ef870d', sliced: false
                }
                let pieChartObj3 = {

                    name: 'BAD', y: badPercentageDatas, totCnt: badMark, color: '#e03e03', sliced: true
                }

                let pichartArrayObj: any = [];

                pichartArrayObj.push(pieChartObj1);
                pichartArrayObj.push(pieChartObj2);
                pichartArrayObj.push(pieChartObj3);
                //PIE CHART OBJECT CREATION CODE ENDS HERE

                //BAR CHART CREATION CODE STARTS HERE

                //BAR CHART CREATION CODE ENDS HERE

                let barChartObj1 = {
                    name: 'GOOD', data: goodpercentageData, color: '#a1b126'

                }
                let barChartObj2 = {

                    name: 'AVG', data: avgpercentageData, color: '#ef870d'
                }
                let barChartObj3 = {

                    name: 'BAD', data: badpercentageData, color: '#e03e03'
                }

                barChartObject.push(barChartObj1);
                barChartObject.push(barChartObj2);
                barChartObject.push(barChartObj3);


                //PIE CHART CODE STARTS HER


                let goodPercentagePie = Math.round((totalCountOfGood / totalCountOf) * 100);
                let avgPercentagePie = Math.round((totalCountOfAvg / totalCountOf) * 100);
                let badPercentagePie = Math.round((totalCountOfBad / totalCountOf) * 100);



                // let pieChartObj1={ 
                //     name: 'GOOD', y: goodPercentagePie,totCnt:totalCountOfGood,color:'#a1b126',sliced: false

                // }
                // let pieChartObj2={ 

                //     name: 'AVG', y: avgPercentagePie,totCnt:totalCountOfAvg,color:'#ef870d',sliced: false
                // }
                // let pieChartObj3={ 

                //     name: 'BAD', y: badPercentagePie,totCnt:totalCountOfBad,color:'#e03e03',sliced: true
                // }

                // let pichartArrayObj:any=[];

                // pichartArrayObj.push(pieChartObj1);
                // pichartArrayObj.push(pieChartObj2);
                // pichartArrayObj.push(pieChartObj3);

                //PIE CHART CODE ENDS HERE

                this.notReachedAllPPShow = false;
                this.showTableRowLoader = false;
                this.isLoading = false;
                this.isShow = true;
                this.highchart5();
                setTimeout(() => {
                    // this.notReachedAllPPShow=false;
                    //   this.showTableRowLoader=false;
                    //   this.isLoading=false; 
                    //this.highchart(this.chartPPName,this.chartGradeName);

                    this.highchart2(barChartObject, dateArray);
                    this.highchart3(pichartArrayObj);
                    this.showPieChartTableDefault();
                    this.highchart4(this.timseSeriesChartObjectOrdered);

                }, 300);


            })();
        }, 100);

        //88888 
        //2030

        //NOT REACHED CODE ENDS HERE


        //NOT REACHED ON TIME CODE STARTS HERE- GET ROUTES ONLY ENDS HERE



    }
    getAverageTime(times: any) {
        var count = times.length
        var timesInSeconds = [];
        // loop through times
        for (var i = 0; i < count; i++) {
            // parse
            var pieces = times[i].split(':');
            var ampm = pieces[2].split(' ');
            var hrs = Number(pieces[0]);
            var mins = Number(pieces[1]);
            var secs = Number(ampm[0]);
            var ampm = ampm[1];
            // convert to 24 hr format (military time)
            if (ampm == 'PM') hrs = hrs + 12;
            // find value in seconds of time
            var totalSecs = hrs * 60 * 60;
            totalSecs += mins * 60;
            totalSecs += secs;
            // add to array
            timesInSeconds[i] = totalSecs;
        }
        // find average timesInSeconds
        var total = 0;

        for (var j = 0; j < count; j++) {
            total = total + Number(timesInSeconds[j]);
        }
        var avg = Math.round(total / count);

        // turn seconds back into a time
        var avgMins = Math.floor(avg / 60);

        var avgSecs = avg - (60 * avgMins);
        var avgHrs = Math.floor(avgMins / 60);

        avgMins = avgMins - (60 * avgHrs);
        // convert back to 12 hr. format
        var avgAmpm = 'AM';
        if (avgHrs > 12) {
            avgAmpm = 'PM';
            avgHrs = avgHrs - 12;
        }
        // add leading zeros for seconds, minutes
        let secondsNo: any = '';
        let minutesNo: any = '';
        secondsNo = ('0' + avgSecs).slice(-2);
        minutesNo = ('0' + avgMins).slice(-2);
        if (avgHrs < 10) {
            return ('0' + avgHrs) + ':' + minutesNo + ':' + secondsNo;
        } else {
            return avgHrs + ':' + minutesNo + ':' + secondsNo;
        }
    }

    getDateArray(start: any, end: any) {
        var arr = new Array();
        var dt = new Date(start);
        while (dt <= end) {
            arr.push(new Date(dt));
            dt.setDate(dt.getDate() + 1);
        }
        return arr;
    }
    highchart3(pichartArrayObj: any) {
        let parent = this;
        Highcharts.chart('container2', {
            chart: {
                plotBackgroundColor: null,
                plotBorderWidth: null,
                plotShadow: false
            },
            title: {
                style: {
                    fontSize: '100%'
                },

                text: '',
                align: 'center',
                verticalAlign: 'middle',
                //y: 10,
            },
            credits: {
                enabled: false
            },
            legend: {
                symbolHeight: 12,
                symbolWidth: 12,
                // symbolRadius: 6,
                symbolRadius: 0
            },
            tooltip: {
                //pointFormat: '{series.name} : {point.percentage:.1f}% : {point.totCnt}'
                pointFormat: 'Percentage: <b>{point.percentage:.1f}%</b><br/>' + 'Count: <b>{point.totCnt}</b><br/>'
            },
            plotOptions: {
                pie: {
                    allowPointSelect: true, borderWidth: 0,
                    shadow: {
                        color: '#a4a4a4',
                        offsetX: 0,
                        offsetY: 0,
                        opacity: '0.5',
                        width: 3
                    },
                    center: [70, 70],
                    cursor: 'pointer',
                    innerSize: '30%',
                    showInLegend: true,
                    dataLabels: {

                        enabled: true,

                        style: {
                            textOutline: false
                        },

                        distance: -30,

                        format: '{point.percentage:.1f}%',

                    },
                    size: 150,

                },


            },
            series: [{
                type: 'pie',
                name: '',
                dataLabels: {
                    enabled: true,
                    format: '{point.percentage:.1f}%',

                },
                data: pichartArrayObj,
                point: {
                    events: {
                        click: function () {
                            // alert(this.name);

                            // alert(parent.notReachedAllPPSortedDisplay.length);

                            //loop here starts here
                            //parent.notReachedAllPPSortedDisplay=[];
                            let finalObject = {};
                            let pushArray: any = [];
                            finalObject = {};
                            pushArray = [];
                            parent.notReachedAllPPSortedDisplayPie = [];
                            (Object.keys(parent.finalObjForPieChartArrayGlobal)).forEach((sortedDataKey: string) => {


                                if (parent.finalObjForPieChartArrayGlobal[sortedDataKey].tableGrade == this.name) {
                                    finalObject = {
                                        callMadeTime: parent.finalObjForPieChartArrayGlobal[sortedDataKey].callMadeTime,
                                        differenceCallMadeReached: parent.finalObjForPieChartArrayGlobal[sortedDataKey].differenceCallMadeReached,
                                        etaTime: parent.finalObjForPieChartArrayGlobal[sortedDataKey].etaTime,
                                        // gradeCallReached: parent.finalObjForPieChartArrayGlobal[sortedDataKey].gradeCallReached,
                                        // indexValue: parent.notReachedAllPPSortedDisplay[sortedDataKey].indexValue,
                                        ppName: parent.finalObjForPieChartArrayGlobal[sortedDataKey].ppName,
                                        reachedTime: parent.finalObjForPieChartArrayGlobal[sortedDataKey].reachedTime,
                                        tableGrade: parent.finalObjForPieChartArrayGlobal[sortedDataKey].tableGrade,
                                        routeName: parent.finalObjForPieChartArrayGlobal[sortedDataKey].routeName,
                                    };


                                    pushArray.push(finalObject);
                                    parent.tableHideShowPie = true;
                                }

                            });
                            parent.notReachedAllPPSortedDisplayPie = [...parent.notReachedAllPPSortedDisplayPie, ...pushArray];
                            //loop ends here





                        }
                    }
                }

                //    [{name:'Good',y:40, color:'#a1b126'},
                //    {name:'Average',y:20,  color:'#ef870d'},
                //    {name:'Bad',y:40, color:'#e03e03',}

                //    ]
            }]

        });

    }
    highchart2(barChartObject: any, dateArray: any) {

        Highcharts.chart('container1', {
            chart: {
                type: 'bar'
            },
            title: {
                text: 'Detailed Report'
            },
            credits: {
                enabled: false
            },
            xAxis: {
                categories:
                    // ['06-02-2020'],
                    dateArray,
                labels:
                {
                    enabled: true
                },
            },
            yAxis: {
                min: 0,
                labels:
                {
                    enabled: false
                },
                title: {
                    text: ''
                }
            },
            legend: {
                reversed: true
            },
            plotOptions: {
                series: {
                    stacking: 'normal',
                    pointWidth: 25,

                    dataLabels: {

                        enabled: true,

                        style: {
                            textOutline: false
                        },

                        //distance: -50,
                        format: '{point.percentage:.1f}%',
                        // format: '{point.percentage}%',

                    }
                },
                column: {
                    pointPadding: 0.1

                }
            },
            series: barChartObject,
            tooltip: {
                //pointFormat: '{series.name} : {point.percentage:.1f}% : {point.totCnt}'

                // formatter: function() {
                //     return '<strong>X value: </strong>'+ this.x;
                //   }

                pointFormat: ' <b>{series.name}</b><br/>' + 'Percentage: <b>{point.percentage}%</b><br/>' + 'Count: <b>{point.totCnt}</b><br/>'
            }
            // [{
            //     name: '(0 - 40) %',
            //     color:'#27AE60',
            //     data: [10]
            // }, {
            //     name: '(41 - 70) %',
            //     color:'#D35400',
            //     data: [50]
            // }, {
            //     name: '(71 - 100) % ',
            //     color:'#E74C3C',
            //     data: [60]
            // }]
        });


    }
    higchart1(ppname: any, ppgrade: any) {
        Highcharts.chart('container', {

            chart: {
                styledMode: true
            },

            title: {
                text: 'Gradients in styled mode'
            },
            credits: {
                enabled: false
            },

            xAxis: {
                categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
            },

            defs: {
                gradient0: {
                    tagName: 'linearGradient',
                    id: 'gradient-0',
                    x1: 0,
                    y1: 0,
                    x2: 0,
                    y2: 1,
                    children: [{
                        tagName: 'stop',
                        offset: 0
                    }, {
                        tagName: 'stop',
                        offset: 1
                    }]
                },
                gradient1: {
                    tagName: 'linearGradient',
                    id: 'gradient-1',
                    x1: 0,
                    y1: 0,
                    x2: 0,
                    y2: 1,
                    children: [{
                        tagName: 'stop',
                        offset: 0
                    }, {
                        tagName: 'stop',
                        offset: 1
                    }]
                }
            },

            series: [{
                type: 'area',
                keys: ['y', 'selected'],
                data: [
                    [29.9, false],
                    [71.5, false],
                    [106.4, false],
                    [129.2, false],
                    [144.0, false],
                    [176.0, false],
                    [135.6, false],
                    [148.5, false],
                    [216.4, true],
                    [194.1, false],
                    [95.6, false],
                    [54.4, false]
                ]
            }]
        });

    }
    timetomilli(hrs: any, min: any, sec: any) {



        return ((hrs * 60 * 60 + min * 60 + sec) * 1000)
    }
    //const timezones = new Date().getTimezoneOffset();
    highchart4(timseSeriesChart: any) {


        Highcharts.chart('container5', {
            chart: {
                zoomType: 'x'
            },
            title: {
                text: ''
            },
            credits: {
                enabled: false
            },
            // subtitle: {
            //     text: document.ontouchstart === undefined ?
            //         'Click and drag in the plot area to zoom in' : 'Pinch the chart to zoom in'
            // },
            xAxis: {
                ordinal: false,
                type: 'datetime',
                title: {
                    text: '',
                    align: 'high',
                    style: {
                        color: '#6D869F',
                        fontSize: '10px'
                    }
                },
                dateTimeLabelFormats: {
                    second: '%H:%M:%S',
                    minute: '%H:%M',
                    hour: '%H:%M',
                    day: '%b. %e',
                    week: '%b. %e',
                    month: '%b. %y',
                    year: '%Y'
                }
            },
            yAxis: {
                title: {
                    text: 'Grade'
                },
                min: 0,
                max: 120,
            },
            tooltip: {
                crosshairs: true,
                pointFormat: 'Pickup Name: <b>{point.pickupName}</b><br/>' + 'Exp Time: <b>{point.etaTime}</b><br/>' + 'Call Triggered: <b>{point.callMade}</b><br/>' + 'Reached: <b>{point.Reached}</b><br/>' + 'Difference(Mins): <b>{point.diff}</b><br/>' + 'Grade: <b>{point.y}</b><br/>',
                shared: true
            },
            legend: {
                enabled: false
            },
            plotOptions: {
                area: {
                    fillColor: {
                        linearGradient: [0, 0, 0, 300],
                        stops: [
                            // [0, Highcharts.getOptions().colors[0]],
                            //[1, Highcharts.Color(Highcharts.getOptions().colors[0]).setOpacity(0).get('rgba')]
                            [0, 'rgb(255, 25, 25)'],
                            [1, 'rgb(255, 230, 230)']
                        ]
                    },
                    // marker: {
                    //     radius: 2
                    // },
                    marker: {
                        enabled: false,
                        symbol: 'circle'
                        //-----continue with other properties----
                    },
                    lineWidth: 0,
                    states: {
                        hover: {
                            //lineWidth: null
                            enabled: true
                        }
                    },
                    // turboThreshold: 0
                    //threshold: null
                },
                legend: {
                    enabled: false
                },
                series: {
                    animation: {
                        duration: 3000
                    }
                }
            },

            series:
                [{
                    type: 'area',
                    //  name: 'USD to EUR',
                    data: timseSeriesChart,
                    marker: {
                        symbol: 'circle'
                    },

                    threshold: null,
                    turboThreshold: 3000
                }]

        });


    }
    getMyStyles(styleSet: any) {
        let myStyles;
        if (styleSet == 'GOOD') {
            myStyles = {
                'background': '#a1b126',

            };
        } else if (styleSet == 'AVG') {
            myStyles = {
                'background': '#ef870d',
            };
        } else {
            myStyles = {
                'background': '#e03e03',
            };
        }
        return myStyles;
    }

    getContentByTitle(routeName: any) {

    }
    highchart5() {
        Highcharts.setOptions({
            global: {
                useUTC: false
            }
        });
    }
    highchart(ppname: any, ppgrade: any) {

        Highcharts.chart('container', {
            chart: {
                type: 'area',
                scrollablePlotArea: {
                    minWidth: 700,
                    scrollPositionX: 1
                }
            },
            title: {
                text: ''
            },
            credits: {
                enabled: false
            },
            subtitle: {
                text: ''
            },
            xAxis: {

                tickInterval: 10,
                min: 0,
                //max: 400,
                labels:
                {
                    enabled: false
                }
            },
            yAxis: {
                title: {
                    text: 'Grade (%)'
                },
                min: 0,
                max: 120,
                // labels: {
                //     formatter: function () {
                //         return this.value + '°';
                //     }
                // }
            },
            tooltip: {
                crosshairs: true,
                pointFormat: 'Pickup Name: <b>{point.pickupName}</b><br/>' + 'Exp Time: <b>{point.etaTime}</b><br/>' + 'Call Triggered: <b>{point.callMade}</b><br/>' + 'Reached: <b>{point.Reached}</b><br/>' + 'Difference: <b>{point.diff}</b><br/>' + 'Grade: <b>{point.y}</b><br/>',
                shared: true
            },

            // linearGradient: [0, 0, 0, 300],
            // stops: [
            //     [0, 'rgb(255, 25, 25)'],
            // [1, 'rgb(255, 230, 230)']
            // ]
            plotOptions: {
                area: {
                    fillColor: {
                        linearGradient: [0, 0, 0, 300],
                        stops: [
                            // [0, Highcharts.getOptions().colors[0]],
                            //[1, Highcharts.Color(Highcharts.getOptions().colors[0]).setOpacity(0).get('rgba')]
                            [0, 'rgb(255, 25, 25)'],
                            [1, 'rgb(255, 230, 230)']
                        ]
                    },
                    // marker: {
                    //     radius: 2
                    // },
                    marker: {
                        enabled: false,
                        symbol: 'circle'
                        //-----continue with other properties----
                    },
                    lineWidth: 0,
                    states: {
                        hover: {
                            //lineWidth: null
                            enabled: false
                        }
                    },
                    // turboThreshold: 0
                    //threshold: null
                },
                legend: {
                    enabled: false
                }
            },
            series: [{
                name: '',
                marker: {
                    symbol: 'circle'
                },
                data: ppgrade,
                threshold: null,
                turboThreshold: 2000

            }]
        });


    }

    showPieChartTableDefault() {

        let finalObject = {};
        let finalObjectAll = {};
        let pushArray: any = [];
        let pushArrayAll: any = [];
        finalObject = {};
        finalObjectAll = {};
        pushArrayAll = [];

        pushArray = [];
        this.notReachedAllPPSortedDisplayPie = [];
        (Object.keys(this.finalObjForPieChartArrayGlobal)).forEach((sortedDataKey: string) => {


            if (this.finalObjForPieChartArrayGlobal[sortedDataKey].tableGrade == 'BAD') {
                finalObject = {
                    callMadeTime: this.finalObjForPieChartArrayGlobal[sortedDataKey].callMadeTime,
                    differenceCallMadeReached: this.finalObjForPieChartArrayGlobal[sortedDataKey].differenceCallMadeReached,
                    etaTime: this.finalObjForPieChartArrayGlobal[sortedDataKey].etaTime,
                    // gradeCallReached: parent.finalObjForPieChartArrayGlobal[sortedDataKey].gradeCallReached,
                    // indexValue: parent.notReachedAllPPSortedDisplay[sortedDataKey].indexValue,
                    ppName: this.finalObjForPieChartArrayGlobal[sortedDataKey].ppName,
                    reachedTime: this.finalObjForPieChartArrayGlobal[sortedDataKey].reachedTime,
                    tableGrade: this.finalObjForPieChartArrayGlobal[sortedDataKey].tableGrade,
                    routeName: this.finalObjForPieChartArrayGlobal[sortedDataKey].routeName
                };
                pushArray.push(finalObject);
                this.tableHideShowPie = true;





            }

            //0000
            //if(this.finalObjForPieChartArrayGlobal[sortedDataKey].routeName==='20M'){

            let splitData = this.finalObjForPieChartArrayGlobal[sortedDataKey].callMadeTime.split(':');
            // let callTrigerTime=' '+splitData[0]
            var callTrigerTime = ' ' + splitData[0] + ':' + splitData[1];
            finalObjectAll = {
                differenceCallMadeReached: this.finalObjForPieChartArrayGlobal[sortedDataKey].differenceCallMadeReached,
                etaTime: this.finalObjForPieChartArrayGlobal[sortedDataKey].etaTime,
                callMadeTime: callTrigerTime,
                // gradeCallReached: parent.finalObjForPieChartArrayGlobal[sortedDataKey].gradeCallReached,
                // indexValue: parent.notReachedAllPPSortedDisplay[sortedDataKey].indexValue,
                ppName: this.finalObjForPieChartArrayGlobal[sortedDataKey].ppName,
                reachedTime: this.finalObjForPieChartArrayGlobal[sortedDataKey].reachedTime,
                tableGrade: this.finalObjForPieChartArrayGlobal[sortedDataKey].tableGrade,
                routeName: this.finalObjForPieChartArrayGlobal[sortedDataKey].routeName

            }
            pushArrayAll.push(finalObjectAll);
            //  }


        });
        this.notReachedAllPPSortedDisplayPie = [...this.notReachedAllPPSortedDisplayPie, ...pushArray];

        this.notReachedAllPPSortedDisplayAll = [...this.notReachedAllPPSortedDisplayAll, ...pushArrayAll];

    }
    filterFunctioncallMadeDelayed(selectedReport: any) {
        this.dispRouteInformationListCallMadeDelay = [];
        //this.RouteListInfo=[];
        //this.pageJump=0;
        //this.dispRouteInformationList=[]; //krishnan
        this.isValidForView = true;

        let flagValue = 0;

        let fromdate = $('#selectedLogDate').val();
        //let routeNameVal = $('#routeName').val();

        if (this.routeNameCallMade === undefined || this.routeNameCallMade === '') {



            if (fromdate) this.todayDate = fromdate;

            if (fromdate) this.todayDate = fromdate;
            else this.todayDate = new Date().toISOString().slice(0, 10);
            if (this.initiateEmptyDateValidator()) {
                //this.initiateCallMadeEarlyReport(selectedReport,fromdate);
                this.initiateCallMadeDelayedReport(selectedReport, fromdate);

            }

        } else {

            let routeIds: any = [];
            this.callMadeTemplate = true;
            this.ovralCount = 0;
            //routeIds=this.routeNameCallMade;mmm
            // var array3 = new Array;
            // array3.push(routeIds);
            let callMadeLocal: number = 0; //krishnan
            let callTotalMadeLocal: number = 0;  //krishnan
            routeIds.push(this.routeNameCallMade);
            (async () => {
                const res = await this.API(routeIds);
                if (res) {

                    this.noMoreData = false;
                    this.ovralCount = res['TotalCallCount'];
                    this.RouteListInfo = res['response'];

                    let info = [];
                    let pckpt: any = [];
                    for (let routeId in this.RouteListInfo) {
                        let routeInfo = this.RouteListInfo[routeId];
                        pckpt = [];
                        for (let pck in routeInfo.pickupPoint) {
                            let pckInfo = routeInfo.pickupPoint[pck];

                            //                         var hour=6*60 * 60 * 1000;
                            //   var minutess=50*60*1000;
                            //   var secondss=0*1000;
                            //   var tot=hour+minutess+secondss;
                            //   var timeStr=this.difftimestamp(Number('1581038400000'),Number('1581037800000'),'time_str');


                            //   callTime: 1581039304
                            // eta: "07:10"
                            // pickuppointName: "Nr. Progressive Apptt, Sec 55"
                            let etaTime = pckInfo.eta;
                            var fields = fromdate.split(/-/);

                            var nameMonth = fields[1] + "/" + fields[0] + "/" + fields[2];

                            var nameDate = fields[0];
                            var nameYear = fields[1];
                            var replaceDate = fromdate.replace(/-/gi, "/");
                            let etaTimeDate = nameMonth.concat(etaTime);

                            var date = new Date(etaTimeDate); // some mock date
                            var etamilliseconds = date.getTime();
                            //let etaTime=pckInfo.eta;


                            if (pckInfo.callTime == '') {



                                let ppobj = {
                                    pickuppointName: pckInfo.pickuppointName,
                                    callTime: pckInfo.callTime,
                                    eta: pckInfo.eta,
                                    diff: '--',
                                }

                                pckpt.push(ppobj);

                            } else {
                                var callTimeMilliseconds = pckInfo.callTime * 1000;


                                var differTime = this.difftimestamp(Number(etamilliseconds), Number(callTimeMilliseconds), 'time_str_no_mins');


                                let ppobj = {
                                    pickuppointName: pckInfo.pickuppointName,
                                    callTime: pckInfo.callTime,
                                    eta: pckInfo.eta,
                                    diff: differTime,
                                }

                                pckpt.push(ppobj);
                            }
                        }
                        let obj = {
                            routeType: routeInfo.routeType,
                            routeName: routeInfo.routeName,
                            totalRouteCallCount: routeInfo.totalRouteCallCount,
                            totalmobNoCount: routeInfo.totalmobNoCount,
                            pickupPoint: pckpt
                        }
                        callMadeLocal = callMadeLocal + routeInfo.totalRouteCallCount;
                        callTotalMadeLocal = callTotalMadeLocal + routeInfo.totalmobNoCount;
                        info.push(obj);

                    }
                    this.dispRouteInformationListCallMadeDelay = [];
                    this.isValidForView = false;
                    this.dispRouteInformationListCallMadeDelay = [...this.dispRouteInformationListCallMadeDelay, ...info];


                }
                let obj = {};
                obj['' + selectedReport] = true;
                this.isLoading = false;
                this.initReportSectionContent(obj);
                this.callMadeFlag = 1;//krishnan
                this.callMadeCount = callMadeLocal;//krishnan
                this.callMadeTotal = callTotalMadeLocal;//krishnan
                this.callMadeTemplate = false;
            })();
        }




        //this.todayDate="";



    }

    filterFunctioncallMadeEarly(selectedReport: any) {

        //this.pageJump=0;
        //this.dispRouteInformationList=[]; //krishnan
        this.dispRouteInformationListCallMadeEarly = [];
        this.isValidForView = true;
        //this.RouteListInfo=[];
        let flagValue = 0;

        let fromdate = $('#selectedLogDate').val();
        //let routeNameVal = $('#routeName').val();

        if (this.routeNameCallMade === undefined || this.routeNameCallMade === '') {



            if (fromdate) this.todayDate = fromdate;

            if (fromdate) this.todayDate = fromdate;
            else this.todayDate = new Date().toISOString().slice(0, 10);
            if (this.initiateEmptyDateValidator()) {
                this.initiateCallMadeEarlyReport(selectedReport, fromdate);

            }

        } else {

            let routeIds: any = [];
            this.callMadeTemplate = true;
            this.ovralCount = 0;
            //routeIds=this.routeNameCallMade;mmm
            // var array3 = new Array;
            // array3.push(routeIds);
            let callMadeLocal: number = 0; //krishnan
            let callTotalMadeLocal: number = 0;  //krishnan
            routeIds.push(this.routeNameCallMade);
            (async () => {
                const res = await this.API(routeIds);
                if (res) {

                    this.noMoreData = false;
                    this.ovralCount = res['TotalCallCount'];
                    this.RouteListInfo = res['response'];

                    let info = [];
                    let pckpt: any = [];
                    for (let routeId in this.RouteListInfo) {
                        let routeInfo = this.RouteListInfo[routeId];
                        pckpt = [];
                        for (let pck in routeInfo.pickupPoint) {
                            let pckInfo = routeInfo.pickupPoint[pck];

                            //                         var hour=6*60 * 60 * 1000;
                            //   var minutess=50*60*1000;
                            //   var secondss=0*1000;
                            //   var tot=hour+minutess+secondss;
                            //   var timeStr=this.difftimestamp(Number('1581038400000'),Number('1581037800000'),'time_str');


                            //   callTime: 1581039304
                            // eta: "07:10"
                            // pickuppointName: "Nr. Progressive Apptt, Sec 55"
                            let etaTime = pckInfo.eta;
                            var fields = fromdate.split(/-/);

                            var nameMonth = fields[1] + "/" + fields[0] + "/" + fields[2];

                            var nameDate = fields[0];
                            var nameYear = fields[1];
                            var replaceDate = fromdate.replace(/-/gi, "/");
                            let etaTimeDate = nameMonth.concat(etaTime);

                            var date = new Date(etaTimeDate); // some mock date
                            var etamilliseconds = date.getTime();
                            //let etaTime=pckInfo.eta;


                            if (pckInfo.callTime == '') {



                                let ppobj = {
                                    pickuppointName: pckInfo.pickuppointName,
                                    callTime: pckInfo.callTime,
                                    eta: pckInfo.eta,
                                    diff: '--',
                                }

                                pckpt.push(ppobj);

                            } else {
                                var callTimeMilliseconds = pckInfo.callTime * 1000;


                                var differTime = this.difftimestamp(Number(etamilliseconds), Number(callTimeMilliseconds), 'time_str_no_mins');


                                let ppobj = {
                                    pickuppointName: pckInfo.pickuppointName,
                                    callTime: pckInfo.callTime,
                                    eta: pckInfo.eta,
                                    diff: differTime,
                                }

                                pckpt.push(ppobj);
                            }
                        }
                        let obj = {
                            routeType: routeInfo.routeType,
                            routeName: routeInfo.routeName,
                            totalRouteCallCount: routeInfo.totalRouteCallCount,
                            totalmobNoCount: routeInfo.totalmobNoCount,
                            pickupPoint: pckpt
                        }
                        callMadeLocal = callMadeLocal + routeInfo.totalRouteCallCount;
                        callTotalMadeLocal = callTotalMadeLocal + routeInfo.totalmobNoCount;
                        info.push(obj);

                    }
                    this.dispRouteInformationListCallMadeEarly = [];//2027
                    this.isValidForView = false;
                    this.dispRouteInformationListCallMadeEarly = [...this.dispRouteInformationListCallMadeEarly, ...info];


                }
                let obj = {};
                obj['' + selectedReport] = true;
                this.isLoading = false;
                this.initReportSectionContent(obj);
                this.callMadeFlag = 1;//krishnan
                this.callMadeCount = callMadeLocal;//krishnan
                this.callMadeTotal = callTotalMadeLocal;//krishnan
                this.callMadeTemplate = false;
            })();
        }




        //this.todayDate="";

    }
    filterFunction(selectedReport: any) {
        //this.pageJump=0;
        //this.dispRouteInformationList=[]; //krishnan
        this.isValidForView = true;
        this.RouteListInfo = [];
        this.dispRouteInformationList = [];
        let flagValue = 0;

        let fromdate = $('#selectedLogDate').val();
        //let routeNameVal = $('#routeName').val();

        if (this.routeNameCallMade === undefined || this.routeNameCallMade === '') {



            if (fromdate) this.todayDate = fromdate;

            if (fromdate) this.todayDate = fromdate;
            else this.todayDate = new Date().toISOString().slice(0, 10);
            if (this.initiateEmptyDateValidator()) {
                this.initiateCallMadeReport(selectedReport, fromdate);

            }

        } else {

            let routeIds: any = [];
            this.callMadeTemplate = true;
            this.ovralCount = 0;
            //routeIds=this.routeNameCallMade;mmm
            // var array3 = new Array;
            // array3.push(routeIds);
            let callMadeLocal: number = 0; //krishnan
            let callTotalMadeLocal: number = 0;  //krishnan
            routeIds.push(this.routeNameCallMade);
            (async () => {
                const res = await this.API(routeIds);
                if (res) {

                    this.noMoreData = false;
                    this.ovralCount = res['TotalCallCount'];
                    this.RouteListInfo = res['response'];

                    let info = [];
                    let pckpt: any = [];
                    for (let routeId in this.RouteListInfo) {
                        let routeInfo = this.RouteListInfo[routeId];
                        pckpt = [];
                        for (let pck in routeInfo.pickupPoint) {
                            let pckInfo = routeInfo.pickupPoint[pck];

                            //                         var hour=6*60 * 60 * 1000;
                            //   var minutess=50*60*1000;
                            //   var secondss=0*1000;
                            //   var tot=hour+minutess+secondss;
                            //   var timeStr=this.difftimestamp(Number('1581038400000'),Number('1581037800000'),'time_str');


                            //   callTime: 1581039304
                            // eta: "07:10"
                            // pickuppointName: "Nr. Progressive Apptt, Sec 55"
                            let etaTime = pckInfo.eta;
                            var fields = fromdate.split(/-/);

                            var nameMonth = fields[1] + "/" + fields[0] + "/" + fields[2];

                            var nameDate = fields[0];
                            var nameYear = fields[1];
                            var replaceDate = fromdate.replace(/-/gi, "/");
                            let etaTimeDate = nameMonth.concat(etaTime);

                            var date = new Date(etaTimeDate); // some mock date
                            var etamilliseconds = date.getTime();
                            //let etaTime=pckInfo.eta;


                            if (pckInfo.callTime == '') {



                                let ppobj = {
                                    pickuppointName: pckInfo.pickuppointName,
                                    callTime: pckInfo.callTime,
                                    eta: pckInfo.eta,
                                    diff: '--',
                                }

                                pckpt.push(ppobj);

                            } else {
                                var callTimeMilliseconds = pckInfo.callTime * 1000;


                                var differTime = this.difftimestamp(Number(etamilliseconds), Number(callTimeMilliseconds), 'time_str');


                                let ppobj = {
                                    pickuppointName: pckInfo.pickuppointName,
                                    callTime: pckInfo.callTime,
                                    eta: pckInfo.eta,
                                    diff: differTime,
                                }

                                pckpt.push(ppobj);
                            }
                        }
                        let obj = {
                            routeType: routeInfo.routeType,
                            routeName: routeInfo.routeName,
                            totalRouteCallCount: routeInfo.totalRouteCallCount,
                            totalmobNoCount: routeInfo.totalmobNoCount,
                            pickupPoint: pckpt
                        }
                        callMadeLocal = callMadeLocal + routeInfo.totalRouteCallCount;
                        callTotalMadeLocal = callTotalMadeLocal + routeInfo.totalmobNoCount;
                        info.push(obj);

                    }
                    this.dispRouteInformationList = [];
                    this.isValidForView = false;
                    this.dispRouteInformationList = [...this.dispRouteInformationList, ...info];


                }
                let obj = {};
                obj['' + selectedReport] = true;
                this.isLoading = false;
                this.initReportSectionContent(obj);
                this.callMadeFlag = 1;//krishnan
                this.callMadeCount = callMadeLocal;//krishnan
                this.callMadeTotal = callTotalMadeLocal;//krishnan
                this.callMadeTemplate = false;
            })();
        }




        //this.todayDate="";
    }

    FilterObj: any = {};
    initiateCallMaderOntimeReport(selectedReport: any, fromdate: any) {
        this.dispRouteInformationList = [];
        this.FilterObj = {};
        this.callMadeCount = 0;
        this.callMadeTotal = 0;
        this.callMadeTemplate = true;
        this.ovralCount = 0;
        let routeData = {
            data: {
                key: localStorage.getItem('scbToken'),
                // filter: {
                //     routeType: this.routeType
                // },
                extra: {
                    maxlimit: "all"
                }
            }
        }
        let routeIds: any = [];
        this.apiService.getRoutes(routeData).then((res: any) => {

            if (res.status === 'success') {
                let responseData = res.response;

                Object.keys(responseData).forEach(res => {
                    const tags = responseData[res].tags;

                    if ((this.sessionType === 'Morning') && (tags[0] === 'Morning' || tags[1] === 'Morning') && (responseData[res].routeName !== 'Walkers_M')) {

                        routeIds.push(responseData[res].routeId);

                    }
                    if ((this.sessionType === 'Evening') && (tags[0] === 'Evening' || tags[1] === 'Evening') && (responseData[res].routeName !== 'Walkers_A')) {

                        routeIds.push(responseData[res].routeId);
                    }
                    if ((this.sessionType === 'Afternoon') && (tags[0] === 'Afternoon' || tags[1] === 'Afternoon') && (responseData[res].routeName !== 'Walkers_N')) {

                        routeIds.push(responseData[res].routeId);
                    }
                    //routeIds.push(responseData[res].routeId);

                });
                //start sorting

                //ends sorting
                let callMadeLocal: number = 0; //krishnan
                let callTotalMadeLocal: number = 0;  //krishnan
                setTimeout(() => {
                    let chunkVal = array_chunk(routeIds, 3, false);

                    this.callMadeFlag = 1;//krishnan

                    (async () => {

                        for (let i = 0; i < chunkVal.length; i++) {
                            if (i === (chunkVal.length - 1)) {
                                this.lastRes = false;
                            } else {
                                this.lastRes = true;
                            }
                            const res = await this.API(chunkVal[i]);
                            if (res) {

                                this.noMoreData = false;
                                this.ovralCount = res['TotalCallCount'];
                                this.RouteListInfo = res['response'];

                                let info = [];
                                let pckpt: any = [];
                                for (let routeId in this.RouteListInfo) {
                                    let routeInfo = this.RouteListInfo[routeId];
                                    pckpt = [];
                                    for (let pck in routeInfo.pickupPoint) {
                                        let pckInfo = routeInfo.pickupPoint[pck];

                                        //                         var hour=6*60 * 60 * 1000;
                                        //   var minutess=50*60*1000;
                                        //   var secondss=0*1000;
                                        //   var tot=hour+minutess+secondss;
                                        //   var timeStr=this.difftimestamp(Number('1581038400000'),Number('1581037800000'),'time_str');


                                        //   callTime: 1581039304
                                        // eta: "07:10"
                                        // pickuppointName: "Nr. Progressive Apptt, Sec 55"
                                        let etaTime = pckInfo.eta;
                                        var fields = fromdate.split(/-/);

                                        var nameMonth = fields[1] + "/" + fields[0] + "/" + fields[2];

                                        var nameDate = fields[0];
                                        var nameYear = fields[1];
                                        var replaceDate = fromdate.replace(/-/gi, "/");
                                        let etaTimeDate = nameMonth.concat(etaTime);

                                        var date = new Date(etaTimeDate); // some mock date
                                        var etamilliseconds = date.getTime();
                                        //let etaTime=pckInfo.eta;


                                        if (pckInfo.callTime == '') {



                                            let ppobj = {
                                                pickuppointName: pckInfo.pickuppointName,
                                                callTime: pckInfo.callTime,
                                                eta: pckInfo.eta,
                                                diff: '--',
                                            }

                                            pckpt.push(ppobj);

                                        } else {
                                            var callTimeMilliseconds = pckInfo.callTime * 1000;


                                            var differTime = this.difftimestamp(Number(etamilliseconds), Number(callTimeMilliseconds), 'time_str');


                                            let ppobj = {
                                                pickuppointName: pckInfo.pickuppointName,
                                                callTime: pckInfo.callTime,
                                                eta: pckInfo.eta,
                                                diff: differTime,
                                            }

                                            pckpt.push(ppobj);
                                        }
                                        // pckpt.push(pckInfo);
                                    }
                                    let obj = {
                                        routeType: routeInfo.routeType,
                                        routeName: routeInfo.routeName,
                                        totalRouteCallCount: routeInfo.totalRouteCallCount,
                                        totalmobNoCount: routeInfo.totalmobNoCount,
                                        pickupPoint: pckpt
                                    }
                                    callMadeLocal = callMadeLocal + routeInfo.totalRouteCallCount;
                                    callTotalMadeLocal = callTotalMadeLocal + routeInfo.totalmobNoCount;
                                    info.push(obj);
                                }
                                this.dispRouteInformationList = [...this.dispRouteInformationList, ...info];

                            }
                            let obj = {};
                            obj['' + selectedReport] = true;
                            this.isLoading = false;
                            this.initReportSectionContent(obj);
                            //this.lastRes = true;
                        }
                        this.isValidForView = false;
                        this.callMadeFlag = 1;//krishnan
                        this.callMadeCount = callMadeLocal;//krishnan
                        this.callMadeTotal = callTotalMadeLocal;//krishnan
                        this.callMadeTemplate = false;

                    })();

                }, 300);
            }
        })


    }
    initiateCallMadenrOntimeReport(selectedReport: any, fromdate: any) {


        this.dispRouteInformationList = [];
        this.FilterObj = {};
        this.callMadeCount = 0;
        this.callMadeTotal = 0;
        this.callMadeTemplate = true;
        this.ovralCount = 0;
        let routeData = {
            data: {
                key: localStorage.getItem('scbToken'),
                // filter: {
                //     routeType: this.routeType
                // },
                extra: {
                    maxlimit: "all"
                }
            }
        }
        let routeIds: any = [];
        this.apiService.getRoutes(routeData).then((res: any) => {

            if (res.status === 'success') {
                let responseData = res.response;

                Object.keys(responseData).forEach(res => {
                    const tags = responseData[res].tags;

                    if ((this.sessionType === 'Morning') && (tags[0] === 'Morning' || tags[1] === 'Morning') && (responseData[res].routeName !== 'Walkers_M')) {

                        routeIds.push(responseData[res].routeId);
                    }
                    if ((this.sessionType === 'Evening') && (tags[0] === 'Evening' || tags[1] === 'Evening') && (responseData[res].routeName !== 'Walkers_A')) {

                        routeIds.push(responseData[res].routeId);
                    }
                    if ((this.sessionType === 'Afternoon') && (tags[0] === 'Afternoon' || tags[1] === 'Afternoon') && (responseData[res].routeName !== 'Walkers_N')) {

                        routeIds.push(responseData[res].routeId);
                    }
                    //routeIds.push(responseData[res].routeId);

                });

                let callMadeLocal: number = 0; //krishnan
                let callTotalMadeLocal: number = 0;  //krishnan
                setTimeout(() => {
                    let chunkVal = array_chunk(routeIds, 3, false);
                    this.callMadeFlag = 1;//krishnan

                    (async () => {

                        for (let i = 0; i < chunkVal.length; i++) {
                            if (i === (chunkVal.length - 1)) {
                                this.lastRes = false;
                            } else {
                                this.lastRes = true;
                            }
                            const res = await this.API(chunkVal[i]);
                            if (res) {

                                this.noMoreData = false;
                                this.ovralCount = res['TotalCallCount'];
                                this.RouteListInfo = res['response'];

                                let info = [];
                                let pckpt: any = [];
                                for (let routeId in this.RouteListInfo) {
                                    let routeInfo = this.RouteListInfo[routeId];
                                    pckpt = [];
                                    for (let pck in routeInfo.pickupPoint) {
                                        let pckInfo = routeInfo.pickupPoint[pck];

                                        //                         var hour=6*60 * 60 * 1000;
                                        //   var minutess=50*60*1000;
                                        //   var secondss=0*1000;
                                        //   var tot=hour+minutess+secondss;
                                        //   var timeStr=this.difftimestamp(Number('1581038400000'),Number('1581037800000'),'time_str');


                                        //   callTime: 1581039304
                                        // eta: "07:10"
                                        // pickuppointName: "Nr. Progressive Apptt, Sec 55"
                                        let etaTime = pckInfo.eta;
                                        var fields = fromdate.split(/-/);

                                        var nameMonth = fields[1] + "/" + fields[0] + "/" + fields[2];

                                        var nameDate = fields[0];
                                        var nameYear = fields[1];
                                        var replaceDate = fromdate.replace(/-/gi, "/");
                                        let etaTimeDate = nameMonth.concat(etaTime);

                                        var date = new Date(etaTimeDate); // some mock date
                                        var etamilliseconds = date.getTime();
                                        //let etaTime=pckInfo.eta;


                                        if (pckInfo.callTime == '') {



                                            let ppobj = {
                                                pickuppointName: pckInfo.pickuppointName,
                                                callTime: pckInfo.callTime,
                                                eta: pckInfo.eta,
                                                diff: '--',
                                            }

                                            pckpt.push(ppobj);

                                        } else {
                                            var callTimeMilliseconds = pckInfo.callTime * 1000;


                                            var differTime = this.difftimestamp(Number(etamilliseconds), Number(callTimeMilliseconds), 'time_str');


                                            let ppobj = {
                                                pickuppointName: pckInfo.pickuppointName,
                                                callTime: pckInfo.callTime,
                                                eta: pckInfo.eta,
                                                diff: differTime,
                                            }

                                            pckpt.push(ppobj);
                                        }
                                        // pckpt.push(pckInfo);
                                    }
                                    let obj = {
                                        routeType: routeInfo.routeType,
                                        routeName: routeInfo.routeName,
                                        totalRouteCallCount: routeInfo.totalRouteCallCount,
                                        totalmobNoCount: routeInfo.totalmobNoCount,
                                        pickupPoint: pckpt
                                    }
                                    callMadeLocal = callMadeLocal + routeInfo.totalRouteCallCount;
                                    callTotalMadeLocal = callTotalMadeLocal + routeInfo.totalmobNoCount;
                                    info.push(obj);
                                }
                                this.dispRouteInformationList = [...this.dispRouteInformationList, ...info];

                            }
                            let obj = {};
                            obj['' + selectedReport] = true;
                            this.isLoading = false;
                            this.initReportSectionContent(obj);
                            //this.lastRes = true;
                        }
                        this.isValidForView = false;
                        this.callMadeFlag = 1;//krishnan
                        this.callMadeCount = callMadeLocal;//krishnan
                        this.callMadeTotal = callTotalMadeLocal;//krishnan
                        this.callMadeTemplate = false;

                    })();

                }, 300);
            }
        })


    }
    initiateCallMadeDelayedReport(selectedReport: any, fromdate: any) {

        this.dispRouteInformationList = [];
        this.dispRouteInformationListCallMadeDelay = [];
        this.FilterObj = {};
        this.callMadeCount = 0;
        this.callMadeTotal = 0;
        this.callMadeTemplate = true;
        this.ovralCount = 0;
        let routeData = {
            data: {
                key: localStorage.getItem('scbToken'),
                // filter: {
                //     routeType: this.routeType
                // },
                extra: {
                    maxlimit: "all"
                }
            }
        }
        let routeIds: any = [];
        this.apiService.getRoutes(routeData).then((res: any) => {

            if (res.status === 'success') {
                let responseData = res.response;

                Object.keys(responseData).forEach(res => {
                    const tags = responseData[res].tags;

                    if ((this.sessionType === 'Morning') && (tags[0] === 'Morning' || tags[1] === 'Morning') && (responseData[res].routeName !== 'Walkers_M')) {

                        routeIds.push(responseData[res].routeId);
                    }
                    if ((this.sessionType === 'Evening') && (tags[0] === 'Evening' || tags[1] === 'Evening') && (responseData[res].routeName !== 'Walkers_A')) {

                        routeIds.push(responseData[res].routeId);
                    }
                    if ((this.sessionType === 'Afternoon') && (tags[0] === 'Afternoon' || tags[1] === 'Afternoon') && (responseData[res].routeName !== 'Walkers_N')) {

                        routeIds.push(responseData[res].routeId);
                    }
                    //routeIds.push(responseData[res].routeId);

                });

                let callMadeLocal: number = 0; //krishnan
                let callTotalMadeLocal: number = 0;  //krishnan
                setTimeout(() => {
                    let chunkVal = array_chunk(routeIds, 3, false);
                    this.callMadeFlag = 1;//krishnan

                    (async () => {

                        for (let i = 0; i < chunkVal.length; i++) {
                            if (i === (chunkVal.length - 1)) {
                                this.lastRes = false;
                            } else {
                                this.lastRes = true;
                            }
                            const res = await this.API(chunkVal[i]);
                            if (res) {

                                this.noMoreData = false;
                                this.ovralCount = res['TotalCallCount'];
                                this.RouteListInfo = res['response'];

                                let info = [];
                                let pckpt: any = [];
                                for (let routeId in this.RouteListInfo) {
                                    let routeInfo = this.RouteListInfo[routeId];
                                    pckpt = [];
                                    for (let pck in routeInfo.pickupPoint) {
                                        let pckInfo = routeInfo.pickupPoint[pck];

                                        //                         var hour=6*60 * 60 * 1000;
                                        //   var minutess=50*60*1000;
                                        //   var secondss=0*1000;
                                        //   var tot=hour+minutess+secondss;
                                        //   var timeStr=this.difftimestamp(Number('1581038400000'),Number('1581037800000'),'time_str');


                                        //   callTime: 1581039304
                                        // eta: "07:10"
                                        // pickuppointName: "Nr. Progressive Apptt, Sec 55"
                                        let etaTime = pckInfo.eta;
                                        var fields = fromdate.split(/-/);

                                        var nameMonth = fields[1] + "/" + fields[0] + "/" + fields[2];

                                        var nameDate = fields[0];
                                        var nameYear = fields[1];
                                        var replaceDate = fromdate.replace(/-/gi, "/");
                                        let etaTimeDate = nameMonth.concat(etaTime);

                                        var date = new Date(etaTimeDate); // some mock date
                                        var etamilliseconds = date.getTime();
                                        //let etaTime=pckInfo.eta;


                                        if (pckInfo.callTime == '') {



                                            let ppobj = {
                                                pickuppointName: pckInfo.pickuppointName,
                                                callTime: pckInfo.callTime,
                                                eta: pckInfo.eta,
                                                diff: '--',
                                            }

                                            pckpt.push(ppobj);

                                        } else {
                                            var callTimeMilliseconds = pckInfo.callTime * 1000;


                                            var differTime = this.difftimestamp(Number(etamilliseconds), Number(callTimeMilliseconds), 'time_str_no_mins');


                                            let ppobj = {
                                                pickuppointName: pckInfo.pickuppointName,
                                                callTime: pckInfo.callTime,
                                                eta: pckInfo.eta,
                                                diff: differTime,
                                            }

                                            pckpt.push(ppobj);
                                        }
                                        // pckpt.push(pckInfo);
                                    }
                                    let obj = {
                                        routeType: routeInfo.routeType,
                                        routeName: routeInfo.routeName,
                                        totalRouteCallCount: routeInfo.totalRouteCallCount,
                                        totalmobNoCount: routeInfo.totalmobNoCount,
                                        pickupPoint: pckpt
                                    }
                                    callMadeLocal = callMadeLocal + routeInfo.totalRouteCallCount;
                                    callTotalMadeLocal = callTotalMadeLocal + routeInfo.totalmobNoCount;
                                    info.push(obj);
                                }
                                this.dispRouteInformationListCallMadeDelay = [...this.dispRouteInformationListCallMadeDelay, ...info];

                            }
                            let obj = {};
                            obj['' + selectedReport] = true;
                            this.isLoading = false;
                            this.initReportSectionContent(obj);
                            //this.lastRes = true;
                        }
                        this.isValidForView = false;
                        this.callMadeFlag = 1;//krishnan
                        this.callMadeCount = callMadeLocal;//krishnan
                        this.callMadeTotal = callTotalMadeLocal;//krishnan
                        this.callMadeTemplate = false;

                    })();

                }, 300);
            }
        })

    }
    initiateCallMadeEarlyReport(selectedReport: any, fromdate: any) {

        this.isLoading = true;
        this.dispRouteInformationList = [];
        this.dispRouteInformationListCallMadeEarly = [];
        this.FilterObj = {};
        this.callMadeCount = 0;
        this.callMadeTotal = 0;
        this.callMadeTemplate = true;
        this.ovralCount = 0;
        let routeData = {
            data: {
                key: localStorage.getItem('scbToken'),
                // filter: {
                //     routeType: this.routeType
                // },
                extra: {
                    maxlimit: "all"
                }
            }
        }
        let routeIds: any = [];
        this.apiService.getRoutes(routeData).then((res: any) => {

            if (res.status === 'success') {
                let responseData = res.response;

                Object.keys(responseData).forEach(res => {
                    const tags = responseData[res].tags;

                    if ((this.sessionType === 'Morning') && (tags[0] === 'Morning' || tags[1] === 'Morning') && (responseData[res].routeName !== 'Walkers_M')) {

                        routeIds.push(responseData[res].routeId);
                    }
                    if ((this.sessionType === 'Evening') && (tags[0] === 'Evening' || tags[1] === 'Evening') && (responseData[res].routeName !== 'Walkers_A')) {

                        routeIds.push(responseData[res].routeId);
                    }
                    if ((this.sessionType === 'Afternoon') && (tags[0] === 'Afternoon' || tags[1] === 'Afternoon') && (responseData[res].routeName !== 'Walkers_N')) {

                        routeIds.push(responseData[res].routeId);
                    }
                    //routeIds.push(responseData[res].routeId);

                });

                let callMadeLocal: number = 0; //krishnan
                let callTotalMadeLocal: number = 0;  //krishnan
                setTimeout(() => {
                    let chunkVal = array_chunk(routeIds, 3, false);
                    this.callMadeFlag = 1;//krishnan

                    (async () => {

                        for (let i = 0; i < chunkVal.length; i++) {
                            if (i === (chunkVal.length - 1)) {
                                this.lastRes = false;
                            } else {
                                this.lastRes = true;
                            }
                            const res = await this.API(chunkVal[i]);
                            if (res) {

                                this.noMoreData = false;
                                this.ovralCount = res['TotalCallCount'];
                                this.RouteListInfo = res['response'];

                                let info = [];
                                let pckpt: any = [];
                                for (let routeId in this.RouteListInfo) {
                                    let routeInfo = this.RouteListInfo[routeId];
                                    pckpt = [];
                                    for (let pck in routeInfo.pickupPoint) {
                                        let pckInfo = routeInfo.pickupPoint[pck];

                                        //                         var hour=6*60 * 60 * 1000;
                                        //   var minutess=50*60*1000;
                                        //   var secondss=0*1000;
                                        //   var tot=hour+minutess+secondss;
                                        //   var timeStr=this.difftimestamp(Number('1581038400000'),Number('1581037800000'),'time_str');


                                        //   callTime: 1581039304
                                        // eta: "07:10"
                                        // pickuppointName: "Nr. Progressive Apptt, Sec 55"
                                        let etaTime = pckInfo.eta;
                                        var fields = fromdate.split(/-/);

                                        var nameMonth = fields[1] + "/" + fields[0] + "/" + fields[2];

                                        var nameDate = fields[0];
                                        var nameYear = fields[1];
                                        var replaceDate = fromdate.replace(/-/gi, "/");
                                        let etaTimeDate = nameMonth.concat(etaTime);

                                        var date = new Date(etaTimeDate); // some mock date
                                        var etamilliseconds = date.getTime();
                                        //let etaTime=pckInfo.eta;
                                        //console.log('QQQQ :',pckInfo.callTime);

                                        if (pckInfo.callTime == '') {



                                            let ppobj = {
                                                pickuppointName: pckInfo.pickuppointName,
                                                callTime: pckInfo.callTime,
                                                eta: pckInfo.eta,
                                                diff: '--',
                                            }

                                            pckpt.push(ppobj);

                                        } else {
                                            var callTimeMilliseconds = pckInfo.callTime * 1000;


                                            var differTime = this.difftimestamp(Number(etamilliseconds), Number(callTimeMilliseconds), 'time_str_no_mins');


                                            let ppobj = {
                                                pickuppointName: pckInfo.pickuppointName,
                                                callTime: pckInfo.callTime,
                                                eta: pckInfo.eta,
                                                diff: differTime,
                                            }

                                            pckpt.push(ppobj);
                                        }
                                        // pckpt.push(pckInfo);
                                    }
                                    let obj = {
                                        routeType: routeInfo.routeType,
                                        routeName: routeInfo.routeName,
                                        totalRouteCallCount: routeInfo.totalRouteCallCount,
                                        totalmobNoCount: routeInfo.totalmobNoCount,
                                        pickupPoint: pckpt
                                    }
                                    callMadeLocal = callMadeLocal + routeInfo.totalRouteCallCount;
                                    callTotalMadeLocal = callTotalMadeLocal + routeInfo.totalmobNoCount;
                                    info.push(obj);
                                }
                                this.dispRouteInformationListCallMadeEarly = [...this.dispRouteInformationListCallMadeEarly, ...info];

                            }
                            let obj = {};
                            obj['' + selectedReport] = true;
                            this.isLoading = false;
                            this.initReportSectionContent(obj);
                            //this.lastRes = true;
                        }
                        this.isValidForView = false;
                        this.callMadeFlag = 1;//krishnan
                        this.callMadeCount = callMadeLocal;//krishnan
                        this.callMadeTotal = callTotalMadeLocal;//krishnan
                        this.callMadeTemplate = false;

                    })();

                }, 300);
            }
        })



    }
    initiateCallMadeReport(selectedReport: any, fromdate: any) {
        this.isLoading = true;
        this.dispRouteInformationList = [];
        this.FilterObj = {};
        this.callMadeCount = 0;
        this.callMadeTotal = 0;
        this.callMadeTemplate = true;
        this.ovralCount = 0;
        let routeData = {
            data: {
                key: localStorage.getItem('scbToken'),
                // filter: {
                //     routeType: this.routeType
                // },
                extra: {
                    maxlimit: "all"
                }
            }
        }
        let routeIds: any = [];
        let routeIdsSort: any = [];
        this.apiService.getRoutes(routeData).then((res: any) => {

            if (res.status === 'success') {
                let responseData = res.response;

                Object.keys(responseData).forEach(res => {
                    const tags = responseData[res].tags;

                    if ((this.sessionType === 'Morning') && (tags[0] === 'Morning' || tags[1] === 'Morning') && (responseData[res].routeName !== 'Walkers_M')) {

                        routeIds.push(responseData[res].routeId);
                        var max_chars1 = 4;
                        var pad_char1 = "0";
                        var numericPart11 = responseData[res].routeName.match(/\d+/);
                        numericPart11 = (new Array(max_chars1 + 1).join(pad_char1) + numericPart11).slice(-max_chars1);

                        if (this.routesNoAdd.includes(numericPart11)) {

                        } else {
                            this.routeNameMapping.set(responseData[res].routeName, responseData[res].tags);
                            this.beforeSortRoutes.push(responseData[res].routeName);
                            this.afterSortRoutes.push(responseData[res].routeName + '-' + responseData[res].routeId);
                            this.routesNoAdd.push(numericPart11);

                        }

                    }
                    if ((this.sessionType === 'Evening') && (tags[0] === 'Evening' || tags[1] === 'Evening') && (responseData[res].routeName !== 'Walkers_A')) {

                        routeIds.push(responseData[res].routeId);
                        var max_chars1 = 4;
                        var pad_char1 = "0";
                        var numericPart11 = responseData[res].routeName.match(/\d+/);
                        numericPart11 = (new Array(max_chars1 + 1).join(pad_char1) + numericPart11).slice(-max_chars1);

                        if (this.routesNoAdd.includes(numericPart11)) {

                        } else {
                            this.routeNameMapping.set(responseData[res].routeName, responseData[res].tags);
                            this.beforeSortRoutes.push(responseData[res].routeName);
                            this.afterSortRoutes.push(responseData[res].routeName + '-' + responseData[res].routeId);
                            this.routesNoAdd.push(numericPart11);

                        }

                    }
                    if ((this.sessionType === 'Afternoon') && (tags[0] === 'Afternoon' || tags[1] === 'Afternoon') && (responseData[res].routeName !== 'Walkers_N')) {

                        routeIds.push(responseData[res].routeId);
                        var max_chars1 = 4;
                        var pad_char1 = "0";
                        var numericPart11 = responseData[res].routeName.match(/\d+/);
                        numericPart11 = (new Array(max_chars1 + 1).join(pad_char1) + numericPart11).slice(-max_chars1);

                        if (this.routesNoAdd.includes(numericPart11)) {

                        } else {
                            this.routeNameMapping.set(responseData[res].routeName, responseData[res].tags);
                            this.beforeSortRoutes.push(responseData[res].routeName);
                            this.afterSortRoutes.push(responseData[res].routeName + '-' + responseData[res].routeId);
                            this.routesNoAdd.push(numericPart11);

                        }

                    }
                    //routeIds.push(responseData[res].routeId);

                });
                var a = this.beforeSortRoutes;
                var e = this.afterSortRoutes;

                var numericPart;
                var letterPart;
                var max_chars = 4;
                var pad_char = "0";
                var b = [];
                for (var i = 0; i < e.length; i++) {
                    var sessionsSplit = [];
                    sessionsSplit = e[i].split('-');
                    numericPart = sessionsSplit[0].match(/\d+/);
                    numericPart = (new Array(max_chars + 1).join(pad_char) + numericPart).slice(-max_chars);
                    letterPart = sessionsSplit[0].match(/[A-z]+/);
                    b[i] = (letterPart + numericPart) + '-' + sessionsSplit[1];

                }
                b.sort(function (a, b) {
                    if (a > b)
                        return 1;
                    if (a < b)
                        return -1;
                    // a must be equal to b
                    return 0;
                });

                for (var i = 0; i < b.length; i++) {
                    var sessionsSplitt = [];
                    //console.log('KRISHNAN----- : ',b[i]);
                    sessionsSplitt = b[i].split('-');
                    var sessions = [];

                    b[i] = sessionsSplitt[0].replace(/0+(?=[1-9])/, '-');


                    sessions = b[i].split('-');
                    //console.log('KRISHNAN-------- : ',sessions[1]+""+sessions[0]);
                    routeIdsSort.push(sessionsSplitt[1]);
                    //this.newAlertConfigRoutes1.push({"routeName":sessions[1]+""+sessions[0]}) ;
                }

                let callMadeLocal: number = 0; //krishnan
                let callTotalMadeLocal: number = 0;  //krishnan
                setTimeout(() => {
                    let chunkVal = array_chunk(routeIdsSort, 3, false);
                    this.callMadeFlag = 1;//krishnan
                    // console.log("yyyyyyyy");
                    (async () => {

                        for (let i = 0; i < chunkVal.length; i++) {
                            if (i === (chunkVal.length - 1)) {
                                this.lastRes = false;
                            } else {
                                this.lastRes = true;
                            }
                            const res = await this.API(chunkVal[i]);
                            if (res) {
                                // console.log("yyyyyyyy",res);
                                this.noMoreData = false;
                                this.ovralCount = res['TotalCallCount'];
                                this.RouteListInfo = res['response'];

                                let info = [];
                                let pckpt: any = [];
                                for (let routeId in this.RouteListInfo) {
                                    let routeInfo = this.RouteListInfo[routeId];
                                    pckpt = [];
                                    for (let pck in routeInfo.pickupPoint) {
                                        let pckInfo = routeInfo.pickupPoint[pck];

                                        //                         var hour=6*60 * 60 * 1000;
                                        //   var minutess=50*60*1000;
                                        //   var secondss=0*1000;
                                        //   var tot=hour+minutess+secondss;
                                        //   var timeStr=this.difftimestamp(Number('1581038400000'),Number('1581037800000'),'time_str');

                                        //   console.log('QQQQ :',timeStr);
                                        //   callTime: 1581039304
                                        // eta: "07:10"
                                        // pickuppointName: "Nr. Progressive Apptt, Sec 55"
                                        let etaTime = pckInfo.eta;
                                        var fields = fromdate.split(/-/);

                                        var nameMonth = fields[1] + "/" + fields[0] + "/" + fields[2];

                                        var nameDate = fields[0];
                                        var nameYear = fields[1];
                                        var replaceDate = fromdate.replace(/-/gi, "/");
                                        let etaTimeDate = nameMonth.concat(etaTime);

                                        var date = new Date(etaTimeDate); // some mock date
                                        var etamilliseconds = date.getTime();
                                        //let etaTime=pckInfo.eta;
                                        //console.log('QQQQ :',pckInfo.callTime);

                                        if (pckInfo.callTime == '') {



                                            let ppobj = {
                                                pickuppointName: pckInfo.pickuppointName,
                                                callTime: pckInfo.callTime,
                                                eta: pckInfo.eta,
                                                diff: '--',
                                            }

                                            pckpt.push(ppobj);

                                        } else {
                                            var callTimeMilliseconds = pckInfo.callTime * 1000;


                                            var differTime = this.difftimestamp(Number(etamilliseconds), Number(callTimeMilliseconds), 'time_str');


                                            let ppobj = {
                                                pickuppointName: pckInfo.pickuppointName,
                                                callTime: pckInfo.callTime,
                                                eta: pckInfo.eta,
                                                diff: differTime,
                                            }

                                            pckpt.push(ppobj);
                                        }
                                        // pckpt.push(pckInfo);
                                    }
                                    let obj = {
                                        routeType: routeInfo.routeType,
                                        routeName: routeInfo.routeName,
                                        totalRouteCallCount: routeInfo.totalRouteCallCount,
                                        totalmobNoCount: routeInfo.totalmobNoCount,
                                        pickupPoint: pckpt
                                    }
                                    callMadeLocal = callMadeLocal + routeInfo.totalRouteCallCount;
                                    callTotalMadeLocal = callTotalMadeLocal + routeInfo.totalmobNoCount;
                                    info.push(obj);
                                }
                                this.dispRouteInformationList = [...this.dispRouteInformationList, ...info];

                            }
                            let obj = {};
                            obj['' + selectedReport] = true;
                            this.isLoading = false;
                            this.initReportSectionContent(obj);
                            //this.lastRes = true;
                        }
                        this.isValidForView = false;
                        this.callMadeFlag = 1;//krishnan
                        this.callMadeCount = callMadeLocal;//krishnan
                        this.callMadeTotal = callTotalMadeLocal;//krishnan
                        this.callMadeTemplate = false;

                    })();

                }, 300);
            }
        })




    }

    API(array: any) {
        let StartDate = this.bootstrapDateTime.getInputvalue('eventStartTime');
        let Enddate = this.bootstrapDateTime.getInputvalue('eventEndTime');

        let fromdate = this.dateChange.dateConversionForApicalls($('#selectedLogDate').val(), 'onlydate');

        return new Promise((resolve, reject) => {


            let apiHeader = {
                data: {
                    key: localStorage.getItem('scbToken'),
                    form: {
                        fromDate: fromdate.trim(),
                        fromTime: '00:00:01',
                        toTime: '20:00:00',
                        routeType: this.routeType,
                        routeList: array
                    },
                    extra: {
                        orderByDateCreated: -1
                    }
                }
            };

            this.apiService.viewRouteWiseCallReport(apiHeader).then(response => {

                resolve((response));
            });

        })
    }
    public routeNameExpende: any = {};
    public expendIcon: boolean = true;
    showRouteTable(index: any, routeName: any) {


        if (this.expendRouteName == routeName && this.expendedIndex == index) {
            this.expendRouteName = '';
            this.expendedIndex = '';
            this.expendIcon = true;
        } else {
            this.expendRouteName = routeName;
            this.expendedIndex = index;
            this.expendIcon = false;

        }
        // if(index !=){

        // }

    }

    numPages() {
        return Math.ceil(this.notReachedAllRoutes.length / this.records_per_page);
    }
    prevPage() {
        if (this.current_page > 1) {
            this.current_page--;
            this.changePage(this.current_page);
        }
    }
    nextPage() {
        //console.log(' yyyyy ',this.current_page,this.numPages());
        if (this.current_page < this.numPages()) {
            this.current_page++;
            //console.log(' yyyyyttttttt ',this.current_page,this.numPages());
            this.changePage(this.current_page);
        }
    }
    changePage(page: any) {



    }
    expandRow(index: number, routeId: any, date: any, routeName: any): void {




        if (index != this.expandedIndex) {

            if (this.setRouteNameFlag[routeName]) {
                this.storeIndex = index;
                this.showTableRowLoader = false;

                //this.callAlertLog = [];
                this.showTableRowLoader = true;
                let apiHeader = {
                    data: {
                        key: localStorage.getItem('scbToken'),
                        "filter": {
                            "routeId": routeId
                        },
                        "form": {
                            "fromDate": date
                        }
                    }
                }
                this.apiService.getCallAlertLog(apiHeader).then(response => {
                    if (response.status === 'success') {
                        this.nodatafound = false;
                        this.displayErrorMessage = '';

                        if (response.response) {
                            this.showTableRowLoader = false;
                            this.callAlertLog = Object.keys(response.response).map((index) => {
                                return { pickupId: index, pickupName: response.response[index][0]['pickupPointName'], pickupEta: response.response[index][0]['eta'], studentDetails: response.response[index], routeName: response.response[index][0]['routeName'] };
                            });
                            //this.noMoreDataError = false;
                            this.callAlertLogTemp = this.callAlertLog;
                            this.routeNameMapping.set(routeName, this.callAlertLogTemp);
                            this.setRouteNameFlag[routeName] = false;
                        }
                        else {
                            this.noCallAlertData = true;
                            this.showTableRowLoader = false;
                            this.nodatafound = true;
                            this.setRouteNameFlag[routeName] = true;
                            //this.noMoreDataError = true;
                        }
                        this.expandedIndex = index === this.expandedIndex ? -1 : index;

                    } else {
                        this.nodatafound = true;
                        this.setRouteNameFlag[routeName] = true;
                        this.expandedIndex = index === this.expandedIndex ? -1 : index;
                        this.displayErrorMessage = this.globalUtils.getErrorMsg(response.ec);
                    }

                }).catch(error => { })




            } else {
                var myJson = {};
                var obj = {};




                this.storeIndex = index;
                this.showTableRowLoader = false;

                //this.callAlertLog = [];
                this.showTableRowLoader = true;
                this.nodatafound = false;
                this.showTableRowLoader = false;
                for (let entry of Array.from(this.routeNameMapping.entries())) {

                    if (entry[0] === routeName) {
                        this.callAlertLog = entry[1];
                        this.expandedIndex = index === this.expandedIndex ? -1 : index;
                    }

                }



            }

        } else {
            this.showTableRowLoader = false;
            this.expandedIndex = index === this.expandedIndex ? -1 : index;
        }


    }
    //     mapToObj(map:any){
    //     var obj = {}
    //     map.forEach(function(v:any, k:any){
    //       obj[k] = v
    //     })
    //     return obj
    //   }
    sortFunctionChart(a: any, b: any) {
        var dateA = new Date(Number(a.y));
        var dateB = new Date(Number(b.y));
        return dateA > dateB ? 1 : -1;

    }
    sortFunctionRoutes(a: any, b: any) {
        var dateA = new Date(Number(a.gradeCntBad));
        var dateB = new Date(Number(b.gradeCntBad));
        return dateA > dateB ? -1 : 1;



    }
    sortFunctionDate(a: any, b: any) {
        //var dateA = (a.grade); 
        //var dateB = (b.grade); 
        //  return a.gradeCallReached - b.gradeCallReached;
        //return dateA > dateB ? 1 : -1;  

        var dateA = new Date(Number(a.callMadeTime));
        var dateB = new Date(Number(b.callMadeTime));
        return dateA > dateB ? 1 : -1;

    };

    sortFunctionTimeSeries(a: any, b: any) {
        var dateA = new Date(Number(a.x));
        var dateB = new Date(Number(b.x));
        return dateA > dateB ? 1 : -1;

    }


    genericDateValidate(param1: string, param2?: string) {

        let endDate: any; let endTimeStamp: any; let endTimeStampString;
        let stDate: any = (param1).split('-');
        stDate = `${stDate[1]}/${stDate[0]}/${stDate[2]}`;
        let stTimeStamp = new Date(stDate).getTime();
        let stTimeStampString = stTimeStamp.toString();

        if (param2) {
            endDate = (param2).split('-');
            endDate = `${endDate[1]}/${endDate[0]}/${endDate[2]}`;
            endTimeStamp = new Date(endDate).getTime();
            endTimeStampString = endTimeStamp.toString();
        }

        return {
            stTimeStamp: stTimeStamp,
            endTimeStamp: endTimeStamp,
            stTimeStampString: stTimeStampString,
            endTimeStampString: endTimeStampString,

        }
    }
    onScroll($event: any) {
        //alert("111");
        if ((($event.target.scrollTop + $event.target.offsetHeight)) == $event.target.scrollHeight) {

            if (this.noMoredata == false) {
                this.routePageJump++;


                var routeObj = {};
                if (this.sessionType == '') {
                    routeObj = {
                        data: {
                            key: localStorage.getItem('scbToken'),
                            extra: {
                                pageJump: this.routePageJump
                            }
                        }
                    };
                    //}
                    this.apiService.getRoutes(routeObj).then(response => {

                        if (response.response) {



                            (this.newAlertConfigRoutes.length) ? this.newAlertConfigRoutes = [...this.newAlertConfigRoutes, ...response.response] : this.newAlertConfigRoutes = response.response;
                            let apiRes = response.response;
                            Object.keys(apiRes).forEach((item, index) => {


                                this.setRouteNameFlag[apiRes[item].routeName] = true;
                            });

                            this.isLoading = false;
                            let sectionContentId = "routecallalertmadelog";
                            let obj = {
                                [sectionContentId]: true
                            }
                            this.initReportSectionContent(obj);
                            this.noMoredata = false;
                        } else {
                            this.noMoredata = true;
                        }

                    }).catch(error => { });
                }

            }
        }

    }
    resetCallTempErr() {
        this.chooseClassError = false;
        this.callInfoStartDateError = false;
        this.showHaltDurationError = false;
        this.callLogCountStartDateError = false;
        this.callLogCountEndDateError = false;
        this.routesDatesMisMatchError = false;
        this.DayLimitError = false;
        this.showAppDurationError = false;
        this.fromDateError = false;
        this.sessionErr = false;
        this.RoutNameError = false;
        this.routeHistoryStartDateError = false;
        this.routesDatesMisMatchError = false;
        this.routeHistoryEndDateError = false;
        this.MemberNameError = false;
        this.MemberInfoStartDateError = false;
        this.MemberInfoEndDateError = false;
        this.durationErrRouteHistoryLog = false;
        this.displayErrorMessage = '';

    }

}
