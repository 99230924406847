import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ApiService } from './../../services/ApiService';
@Component({
  selector: 'app-user-management',
  templateUrl: './user-management.component.html',
  styleUrls: ['./user-management.component.css']
})
export class UserManagementComponent implements OnInit {
 

  public listRolesDetail: any;
  public userDetails: any;
  constructor( 
    private router: Router,
    private api: ApiService
  ) { }

  ngOnInit() {
     this.loadUsers();
  }

   loadUsers(){
          
      let apiData = {
          data:{
             key: localStorage.getItem('scbToken'),

          }
      }
  //     let roleList = {         
  //       data:{
  //           key: localStorage.getItem("scbToken"),
  //       }   
  //     }

  //   this.api.listRoles(roleList).then( (res: any)=>{
  //     if(res.status === 'success'){
  //       //  console.log(res.response);
         
  //           this.listRolesDetail = res.response;
         
  //     }
  //  }); 
    this.api.listUsers(apiData).then( (res: any)=>{
          if(res.status === 'success'){
              this.userDetails = res.response;
              ///console.log(this.userDetails);
          }
    });
  }
  editUser(orguserid: string){  

       this.router.navigate(['/edit-users',orguserid]);
        
  }
 
  navigate(url: string){
    this.router.navigate(['/' + url, {}]);
  }
  
  deleteUser(orguserid: any){
      
     let apiData = {
        data: {
            key: localStorage.getItem('scbToken'),
            form: {
              orguserid: orguserid
            }
        }
     }
   let parant = this;
     swal({
      title: 'Are you sure?',
      text: 'Do you want to delete role',
      icon: 'warning',
      buttons: [
        'No, cancel it!',
        'Yes, I am sure!'
      ],
      dangerMode: true,
    }).then(function(isConfirm: any) {

          if(isConfirm){

               parant.api.deleteUser(apiData).then( (res:any)=>{
                       if(res.status === 'success'){
                          swal({
                            title: 'Success..!',
                            text: 'User deleted successfuly..!',
                            icon: 'success',
                          });

                          setTimeout( ()=>{
                               parant.loadUsers();  
                          },300);

                       }
               })
                
          }else{

          }

    });

  }

  sortTable(n: any) {
    var table, rows, switching, i, x, y, shouldSwitch, dir, switchcount = 0;
    table = document.getElementById("mytable");
    switching = true;
    //Set the sorting direction to ascending:
    dir = "asc";
    /*Make a loop that will continue until
    no switching has been done:*/
    while (switching) {
      //start by saying: no switching is done:
      switching = false;
      rows = table.getElementsByTagName("TR");
      /*Loop through all table rows (except the
      first, which contains table headers):*/
      for (i = 1; i < (rows.length - 1); i++) {
        //start by saying there should be no switching:
        shouldSwitch = false;
        /*Get the two elements you want to compare,
        one from current row and one from the next:*/
        x = rows[i].getElementsByTagName("TD")[n];
        y = rows[i + 1].getElementsByTagName("TD")[n];
        /*check if the two rows should switch place,
        based on the direction, asc or desc:*/
        if (dir == "asc") {
          if (x.innerHTML.toLowerCase() > y.innerHTML.toLowerCase()) {
            //if so, mark as a switch and break the loop:
            shouldSwitch = true;
            break;
          }
        } else if (dir == "desc") {
          if (x.innerHTML.toLowerCase() < y.innerHTML.toLowerCase()) {
            //if so, mark as a switch and break the loop:
            shouldSwitch = true;
            break;
          }
        }
      }
      if (shouldSwitch) {
        /*If a switch has been marked, make the switch
        and mark that a switch has been done:*/
        rows[i].parentNode.insertBefore(rows[i + 1], rows[i]);
        switching = true;
        //Each time a switch is done, increase this count by 1:
        switchcount++;
      } else {
        /*If no switching has been done AND the direction is "asc",
        set the direction to "desc" and run the while loop again.*/
        if (switchcount == 0 && dir == "asc") {
          dir = "desc";
          switching = true;
        }
      }
    }
  }



}
