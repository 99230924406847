import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'objectkeys'
})

export class Objectkeys implements PipeTransform {
    transform(value: any){
       let res = value != undefined ? Object.keys(value) : [];
       return res;
    //    if( typeof res == "string")
    //      return [res];
    //    else
    //      return res;  
    }
}