/**
 * @Author: Deepak Pookkote <group10>
 * @Date:   2018-04-24T11:51:53+05:30
 * @Email:  deepak@groupten.com
 * @Project: School-Bus
 * @Filename: userLog.dir.ts
 * @Last modified by:   group10
 * @Last modified time: 2018-04-26T17:50:22+05:30
 * @Copyright: Group10 Technologies
 */



/*Auther:Deepak */
import { Component,Input } from '@angular/core';
import { RouterModule, Routes, Router } from '@angular/router';
import { globalService } from './../../../services/global.service';
import { ApiService } from './../../../services/ApiService';

declare var $:any;

@Component({
  selector: 'user-log',
  template: `
  <div class="UserLogBag">
    <div class="row">
      <div class="col-md-12">
        <div class="widget no-padding blank routeDetails">
          <div class="timeline-sec">
            <ul *ngFor="let log of UserLog;let i=index;">
              <li>
                <div class="timeline">
                  <div class="user-timeline">
                    <span><i class="fa fa-user-circle-o iconClassLog" aria-hidden="true"></i></span>
                  </div>
                  <div class="timeline-detail">
                    <div class="timeline-head">
                      <h3>{{log.userName}}<span><i class="fa fa-clock-o mr5"></i>{{log.loginTime}}</span><i class=""></i></h3>

                    </div>
                    <div class="timeline-content">
                      <p>Email: {{log.userEmail}}</p>
                    </div>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
  `,
  styles: [ `


.widget {
  float: left;
  margin-top: 10px;
  padding: 25px 12px;
  width: 100%;
  height: 72vh;
  overflow-y: scroll;
  overflow-x: hidden;
}

.timeline-sec {
  float: left;
  width: 100%;
}
.timeline-sec > ul {
  float: left;
  list-style: none outside none;
  margin: 0;
  padding: 0;
  position: relative;
  width: 100%;
}

.timeline-sec > ul:before {
background: none repeat scroll 0 0 #fafafa;
content: "";
height: 100%;
left: 22px;
position: absolute;
top: 0;
width: 2px;
}


.timeline-sec > ul > li {
float: left;
margin-bottom: 15px;
position: relative;
width: 100%;
}

.timeline {
float: left;
padding-left: 45px;
position: relative;
width: 100%;
}

.user-timeline {
left: 0;
position: absolute;
top: 0;
width: 70px;
}

.user-timeline > span {
float: left;
position: relative;
width: 100%;
}

.iconClassLog{
  font-size: 25px;
  margin-left: -10px;
  margin-top: 10px;
}

.timeline-detail {
background: none repeat scroll 0 0 #f5f5f5;
float: left;
position: relative;
width: 100%;
}

.timeline-detail:before {
  border-bottom: 8px solid transparent;
  border-right: 8px solid #f5f5f5;
  border-top: 8px solid transparent;
content: "";
left: -7px;
position: absolute;
top: 12px;
}
.timeline-head {
background: none repeat scroll 0 0 #f5f5f5;
float: left;
width: 100%;
}

.timeline-head > h3 {
color: #333333;
float: left;
font-family: Roboto;
font-size: 14px;
font-weight: normal;
letter-spacing: 0.3px;
line-height: 22px;
margin: 0;
padding: 9px 0 9px 20px;
}

.timeline-head > h3 > span {
color: #999999;
font-size: 11px;
letter-spacing: 0.3px;
margin-left: 20px;
margin-right: 12px;
}
.timeline-head > h3 > i {
font-family: Lato;
font-size: 11px;
font-style: normal;
letter-spacing: 0.3px;
padding: 2px 6px;
}

.timeline-content {
float: left;
padding: 2px 20px;
width: 100%;
}

.green, .support-ticket .done, .edit-remove > li > .edit, .toggle-switch *:checked + span:before, .friend-list li > span.online:before {
background: -moz-linear-gradient(top, #0fce33 0%, #07bf29 100%);
background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,#07bf29), color-stop(100%,#f9f9f9));
background: -webkit-linear-gradient(top, #0fce33 0%,#07bf29 100%);
background: -o-linear-gradient(top, #0fce33 0%,#07bf29 100%);
background: -ms-linear-gradient(top, #0fce33 0%,#07bf29 100%);
background: linear-gradient(to bottom, #0fce33 0%,#07bf29 100%);
color: #fff;
}


`
],
})

export class userLogDir  {

  public UserLog:any=[];

  constructor(public apiService: ApiService,public globalService:globalService) {
  }

  ngOnInit(){
    this.displayUserLoginHistory();
  }

  displayUserLoginHistory=():void=>{
    this.apiService.getUserLogHistory({
      data: {
          key:localStorage.getItem('scbToken'),
          extra: {
              datalimit:10,
              orderByDateCreated:-1
          }
      }
    }).then(response => {
                   let result=response;
                   if(result.status=="success"){
                     this.UserLog = result.response;
                   }
            }).catch(error => {
                   console.log('Error while logging in', error);
            });
  }




 }

/*
Notes : npm install node-sass -g
*/
