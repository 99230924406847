/**
 * @Author: Deepak Pookkote <group10>
 * @Date:   2018-04-17T10:51:11+05:30
 * @Email:  deepak@groupten.com
 * @Project: School-Bus
 * @Filename: studentExcelUpload.ts
 * @Last modified by:   group10
 * @Last modified time: 2018-05-16T11:11:43+05:30
 * @Copyright: Group10 Technologies
 */



/*Author :- Deepak */
import {
  Component,
  ElementRef,
  AfterViewInit,
  OnDestroy,
  ViewChild, Input, Output
} from '@angular/core';
import { RouterModule, Routes, Router } from '@angular/router';
import { AppComponent } from './../../app.component';

import { globalService } from './../../services/global.service';
import { ApiService } from './../../services/ApiService';
import { excelUpload } from './../../services/excelUploadService';
import { authGaurd } from './../../services/auth-guard.service';


declare var $: any;
declare var alasql: any;;
const swal = require('sweetalert');

@Component({
  // template: ``,
  templateUrl: './studentExcelUpload.component.html',
  styles: [`



/* layout.css Style */
.upload-drop-zone {
height: 200px;
border-width: 2px;
margin-bottom: 20px;
}

/* skin.css Style*/
.upload-drop-zone {
color: #ccc;
border-style: dashed;
border-color: #ccc;
line-height: 200px;
text-align: center
}
.upload-drop-zone.drop {
color: #222;
border-color: #222;
}


.image-preview-input {
 position: relative;
 overflow: hidden;
 margin: 0px;
 color: #333;
 background-color: #fff;
 border-color: #ccc;
}

.image-preview-input input[type=file] {
position: absolute;
top: 0;
right: 0;
margin: 0;
padding: 0;
font-size: 20px;
cursor: pointer;
opacity: 0;
filter: alpha(opacity=0);
}
.image-preview-input-title {
 margin-left:2px;
}

.upload-point-container{
margin-top: 2em;
padding-bottom: 5em;
background-color: #fff;
}
.point-excel-upload{
padding: 1em;
color: #b5c4c9;
text-align: center;
}
.drop-file-container{
position: relative;
border: 2px dashed #b5c4c9;
border-radius: 5px;
padding: 30px;
background-color: #fdfdfd;
}
.icon-drag-drop{
font-size: 7em;
margin-bottom: 20px;
color: #b5c4c9;
}
.icon-upload{
color: #4a4847;
margin-right: .5em;
}
.drop-file-container input{
position: absolute;
opacity: 0;
cursor: pointer;
top: 0;
left: 0;
right: 0;
height: 100%;
width:100%;
}
.header-tabs{
height:30px;
padding:10px;
}
.stoppage-upload{
margin-top:15px;
}
.dialogDynamicContent{
margin-top:15%;
}
.panel-default>.panel-heading {
color: #333;
background-color: #fff;
border-color: #ffffff;
box-shadow: 1px 1px 2px 1px #efefef;
}
.panel {
box-shadow: 1px 2px 4px 0px lightgrey;
}
.panel-default {
border-color: #fdfafa;
}

/** loader css **/
section {
  position: relative;
  width: 100%;
  height: 80vh;
}
section:nth-child(2n) {
  background-color: #ffffff;
}

@keyframes animation {
  0% {
    width: 0;
  }
  25% {
    width: 30%;
    left: 15%;
  }
  50% {
    width: 70%;
    left: 40%;
  }
  100% {
    width: 15%;
    left: 100%;
  }
}
.loader-4 {
  position: relative;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 10%;
  height: 3px;
  border-radius: 50%;
  background-color: #fff;
  box-shadow: 0 0 40px 0 rgba(68, 16, 122, 0.4);
  overflow: hidden;
}
.loader-4::before, .loader-4::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 0;
  height: 4px;
  border-radius: 4px;
  z-index: 2;
}
.loader-4::before {
  background: linear-gradient(90deg, #ff1361, #fff800);
  animation: animation .8s linear infinite;
}
.loader-4::after {
  background: linear-gradient(90deg, #44107a, #ff1361);
  animation: animation .8s .4s linear infinite;
}

/** loader css **/


 `],
})
export class studentExcelUploadPage {

  public showLoader: boolean = false;
  public newExcelObj: any = [];
  public fileNameFlag: any;
  constructor(public router: Router,
    public excelUpload: excelUpload,
    public apiService: ApiService,
    public authGaurd: authGaurd) {


  }

  ngOnInit() {
    this.authGaurd.checkToken();
    // let btn = document.getElementById("coodownloadID");
    //   btn.addEventListener("click", (e:Event) => console.log('ssss'));
  }

  addStudent() {

    this.router.navigate(['/add-student-details', {}]);
  }
  backToDashboard() {

    this.router.navigate(['/student-dashboard', {}]);
  }

  tableiVew() {
    this.router.navigate(['/school-students-list', {}]);
  }

  rfidUpload() {
    this.router.navigate(['/rfid-upload']);
  }

  UpdateStudentExcelUpload() {
    this.router.navigate(['/studentInfo-update-upload', {}]);
  }
  downloadLink(value: any) {

    let parent: any = this;
    parent.CreateCSV(this.newExcelObj);
  }


  //API integration by rakesh.
  onFileChange(event: any) {
    this.showLoader = true;

    let reader = new FileReader();
    let parent: any = this;
    parent.newExcelObj = [];
    parent.fileNameFlag = '0';
    if (event.target.files && event.target.files.length > 0) {
      let file = event.target.files[0];
      var apiKey = localStorage.getItem('scbToken');
      this.excelUpload.uploadFile(apiKey, file, 'pickup-input', function (apiResponse: any) {
        parent.showDialogLoader = false; //hide loader from dialog.
        if (apiResponse.status == 'success') {

          parent.showLoader = false;
          // parent.showUploadSuccess = true;
          // parent.showUploadFailure = false;
          let div = document.createElement("div");
          div.innerHTML = "<div style='overflow:auto'>  Uploaded File Name : " + file.name + " <br> " + 'Student Excel Uploaded Successfully' + "</div>";
          swal({
            title: 'Success',
            content: div,
            icon: 'success',
          });
          // swal("Success", "Student Excel Uploaded Successfully", "success");
          setTimeout(() => {
            swal.close();
            parent.router.navigate(['/school-students-list', {}]);
          }, 2000);
        } else if (apiResponse.status == 'failure') {
          parent.showLoader = false;
          if (apiResponse.response) {
            let apiRepsponceArray: any = [];
            let errorArray: any = [];
            let orgError: any = [];
            apiRepsponceArray = apiResponse.response.duplicated;
            Object.keys(apiRepsponceArray).forEach(function (key: any) {
              errorArray = apiRepsponceArray[key];

              let tempObj = {
                'ID': errorArray.uid,
                'Name': errorArray.name,
                'Parent Name': errorArray.parentname,
                'Primary Mobile No': errorArray.MemberPrimaryMobileNo,
                'Secondary Mobile No': errorArray.MemberSecondaryMobileNo

              }



              parent.newExcelObj.push(tempObj);
              for (var index in errorArray) {

                orgError.push(errorArray[index]);
              }

            });

            if (errorArray.rfid) {
              let div = document.createElement("div");
              if (apiResponse.ec == 'SCB22') {

                parent.fileNameFlag = '1';
                //div.innerHTML = "<div style='overflow:auto'> Uploaded File Name : "+file.name + " <br> Invalid Mobile No <br>" + errorArray.rfid + "</div>";
                div.innerHTML = "<div style='overflow:auto'> Uploaded File Name : " + file.name + " <br> Invalid Mobile No <br><button  id='downloadID' class='btn btn-sm btn-info '>Download_Failure_Report</button></div>";



              } else {
                //div.innerHTML = "<div style='overflow:auto'> Uploaded File Name : "+file.name + " <br> RFID Duplicated<br>" + errorArray.rfid + "</div>";
                parent.newExcelObj = [];
                parent.fileNameFlag = '2';
                let tempObj = {
                  'ID': errorArray.uid,
                  'Name': errorArray.name,
                  'Parent Name': errorArray.parentname,
                  'RFID': errorArray.rfid


                }



                parent.newExcelObj.push(tempObj);

                div.innerHTML = "<div style='overflow:auto'> Uploaded File Name : " + file.name + " <br> RFID Duplicated<br><button  id='downloadID' class='btn btn-sm btn-info '>Download_Failure_Report</button></div>";
              }
              swal({
                title: 'Failure',
                content: div,
                icon: 'error',
              });
              var downloadEvent = document.getElementById('downloadID');
              if (downloadEvent) {
                // el.addEventListener('click', parent.jj, false);
                downloadEvent.addEventListener("click", (e: Event) => parent.downloadLink());
              }

            } else {
              let div = document.createElement("div");

              // parent.CreateCSV(parent.newExcelObj);

              if (apiResponse.ec == 'SCB22') {
                parent.fileNameFlag = '3';
                div.innerHTML = "<div style='overflow:auto'> Uploaded File Name : " + file.name + " <br> Invalid Mobile No <br><button  id='downloadID' class='btn btn-sm btn-info '>Download_Failure_Report</button></div>";
              } else {
                parent.fileNameFlag = '4';
                //div.innerHTML = "<div style='overflow:auto'> Uploaded File Name : "+file.name + " <br> Duplicated <br>" + orgError + "</div>";
                div.innerHTML = "<div style='overflow:auto'> Uploaded File Name : " + file.name + " <br> Duplicated <br><button  id='downloadID' class='btn btn-sm btn-info '>Download_Failure_Report</button></div>";
              }
              swal({
                title: 'Failure',
                content: div,
                icon: 'error',
              });
              //           let btn = document.getElementById("coodownloadID");
              //  btn.addEventListener("click", (e:Event) => console.log('ssss'));
              var downloadEvent = document.getElementById('downloadID');
              if (downloadEvent) {
                // el.addEventListener('click', parent.jj, false);
                downloadEvent.addEventListener("click", (e: Event) => parent.downloadLink());
              }
            }
          } else {
            if (apiResponse.ec == 'SCB29') {
              parent.showLoader = false;
              swal("Failure", "invalid excel Data", "error", {
                timer: 2000
              });
            }

          }

        } else if (apiResponse.status == 'partial') {
          parent.showLoader = false;
          let apiRepsponceArray: any = [] = apiResponse.response.succeed;
          let DuplicatedArray: any = [] = apiResponse.response.duplicated
          let errorArray: any = [];
          let duparray: any = [];
          let orgError: any = [];
          let dupValues: any = [];
          Object.keys(apiRepsponceArray).forEach(function (key: any) {
            errorArray = apiRepsponceArray[key];

            for (var index in errorArray) {
              orgError.push(errorArray[index]);
            }
          });
          Object.keys(DuplicatedArray).forEach(function (key: any) {
            duparray = DuplicatedArray[key];
            let tempObj = {
              'ID': duparray.uid,
              'Name': duparray.name,
              'Parent Name': duparray.parentname,
              'Primary Mobile No': duparray.MemberPrimaryMobileNo,
              'Secondary Mobile No': duparray.MemberSecondaryMobileNo

            }



            parent.newExcelObj.push(tempObj);
            for (var index in duparray) {
              dupValues.push(duparray[index]);
            }
          });
          let div = document.createElement("div");
          if (apiResponse.ec == 'SCB22') {
            //  div.innerHTML = "<div style='overflow:auto'> Uploaded File Name : "+file.name + " <br> Invalid Mobile No <br><button  id='downloadID' class='btn btn-sm btn-info '>Download</button></div>";
            //div.innerHTML = "<div style='overflow:auto'> Uploaded File Name : "+ file.name + " <br> Success :-" + orgError + " <br><hr>Invalid Mobile No :-" + dupValues + "</div>";
            parent.fileNameFlag = '5';
            div.innerHTML = "<div style='overflow:auto'> Uploaded File Name : " + file.name + " <br> Success :-" + orgError + " <br><hr>Invalid Mobile No <br><button  id='downloadID' class='btn btn-sm btn-info '>Download_Failure_Report </button></div>";
          } else {
            // div.innerHTML = "<div style='overflow:auto'> Uploaded File Name : "+ file.name + " <br> Success :-" + orgError + " <br><hr>Duplicated :-" + dupValues + "</div>";
            parent.fileNameFlag = '6';
            div.innerHTML = "<div style='overflow:auto'> Uploaded File Name : " + file.name + " <br> Success :-" + orgError + " <br><hr>Duplicated <br><button  id='downloadID' class='btn btn-sm btn-info '>Download_Failure_Report </button></div>";
          }
          swal({
            title: 'Partially Uploaded',
            content: div,
            icon: 'warning',
          });

          var downloadEvent = document.getElementById('downloadID');
          if (downloadEvent) {
            // el.addEventListener('click', parent.jj, false);
            downloadEvent.addEventListener("click", (e: Event) => parent.downloadLink());
          }

        }
      });
    }

  }

  CreateCSV(ExcelObj: any) { //console.log(ExcelObj);

    let sheetName = { sheetid: 'StudentDetails', header: true };
    /// console.log('ExcelObj',ExcelObj);
    let date: Date = new Date();
    let getFileName = '';
    if (this.fileNameFlag == '1' || this.fileNameFlag == '3' || this.fileNameFlag == '5') {
      getFileName = 'Invalid_Mobile_No_Upload_Failure_Report_' + date.getDate() + '-' + date.getMonth() + '-' + date.getFullYear() + ' ' + date.getHours() + ':' + date.getMinutes() + ':' + date.getSeconds() + '.xlsx';
    }
    if (this.fileNameFlag == '2') {
      getFileName = 'RFID_Duplicate_Upload_Failure_Report_' + date.getDate() + '-' + date.getMonth() + '-' + date.getFullYear() + ' ' + date.getHours() + ':' + date.getMinutes() + ':' + date.getSeconds() + '.xlsx';
    }
    if (this.fileNameFlag == '4' || this.fileNameFlag == '6') {
      getFileName = 'Duplicate_Upload_Failure_Report_' + date.getDate() + '-' + date.getMonth() + '-' + date.getFullYear() + ' ' + date.getHours() + ':' + date.getMinutes() + ':' + date.getSeconds() + '.xlsx';
    }

    //if(ExcelObj.length>0){
    let fileName = "";

    alasql('SELECT INTO XLSX("' + getFileName + '",?) FROM ?',
      [sheetName, ExcelObj]);
    // this.commonservice.JSONToCSVConvertor(ExcelObj, "Feedback_report", excelTitle, columns);
    // }
    // setTimeout(()=>{

    //   //this.xlsLoader1=false;
    //   // console.log('xlsLoader',this.xlsLoader1);
    // },2000)

  }

  downloadTemplate() {
    window.open('https://api-school.placer.in/api/v2/webroot/sample/studentInfo.xls');
  }

}
