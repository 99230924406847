import { Component, Output, EventEmitter, HostListener, Input } from "@angular/core";
import { bootstrapDateTime } from '../../services/bootstrapDateTime/datePicker';
import { chosenUtility } from '../../services/chosen-utility/jq-chosen-utility.js';

declare var $: any;
@Component({
    selector: "inline-date-time-picker",
    template: `
    <style>
    .form-inline .form-control{
        width: 61% !important;
    }
    </style>
    <!-- <p>{{showOnlySingleDate}}</p> -->
    <form class="form-inline" *ngIf="showOnlySingleDate==0">
    <div class="form-group {{myClass}}">
                   <label class="" for="StartDate">Start Date:</label>
                   <input class="custom-form-control err-chk-date-start" 
                    (input)="startDatevaluate($event);emitDateObj()"
                    (focus)="startDatevaluate($event);" 
                    (change)="startDatevaluate($event);emitDateObj()"
                    (focusout)="startDatevaluate($event);emitDateObj()"
                
        

                 
                   id="StartDate">
                   <!-- <div class="text-danger" style="height:10px;"><span *ngIf="startdatevalexist==false">Choose Start Date</span></div> -->
                   <!--<span class="text-danger" *ngIf="daySummaryDatesMisMatchError">End Date in greater than Start Date</span>-->
     </div>
     <div class="form-group {{myClass}}">
                   <label class="" for="EndDate">End Date:</label>
                   <input class="custom-form-control err-chk-date-end" 
                   (input)="endDatevaluate($event);emitDateObj()"
                   (focus)="endDatevaluate($event)" 
                   (change)="startDatevaluate($event);emitDateObj()"
                   (focusout)="endDatevaluate($event);emitDateObj()"
                   
                  


                  
                  
                    id="EndDate">
                  <!-- <div class="text-danger" style="height:10px;"><span *ngIf="enddatevalexist==false">Choose End Date</span></div> -->
                   <!--<span class="text-danger" *ngIf="daySummaryDatesMisMatchError">End Date in greater than Start Date</span>-->
    </div>
    </form>
    <form class="form-inline" *ngIf="showOnlySingleDate==3">
    <div class="form-group {{myClass}}">
                   <label class="" for="StartDate">Start Date:</label>
                   <input class="form-control err-chk-date-start" 
                    (input)="startDatevaluate($event);emitDateObj()"
                    (focus)="startDatevaluate($event);" 
                    (change)="startDatevaluate($event);emitDateObj()"
                    (focusout)="startDatevaluate($event);emitDateObj()"
                
        

                 
                   id="StartDate">
                   <!-- <div class="text-danger" style="height:10px;"><span *ngIf="startdatevalexist==false">Choose Start Date</span></div> -->
                   <!--<span class="text-danger" *ngIf="daySummaryDatesMisMatchError">End Date in greater than Start Date</span>-->
     </div>
     <div class="form-group {{myClass}}">
                   <label class="" for="EndDate">End Date:</label>
                   <input class="form-control err-chk-date-end" 
                   (input)="endDatevaluate($event);emitDateObj()"
                   (focus)="endDatevaluate($event)" 
                   (change)="startDatevaluate($event);emitDateObj()"
                   (focusout)="endDatevaluate($event);emitDateObj()"
                   
                  


                  
                  
                    id="EndDate">
                  <!-- <div class="text-danger" style="height:10px;"><span *ngIf="enddatevalexist==false">Choose End Date</span></div> -->
                   <!--<span class="text-danger" *ngIf="daySummaryDatesMisMatchError">End Date in greater than Start Date</span>-->
    </div>
    </form>
   <form class="form-inline" *ngIf="showOnlySingleDate==1">
   <div class="input-group {{myClass}}">
                   <span class="input-group-addon"><i class="fa fa-calendar"></i></span>
                   <input class="custom-form-control err-chk-date-single" 
                   (input)="endDatevaluate($event);emitDateObj()"
                   (focus)="endDatevaluate($event)" 
                   (change)="endDatevaluate($event);emitDateObj()"
                   (focusout)="endDatevaluate($event);emitDateObj()"
                  placeholder="Select date here"


                  
                  
                    id="singleDate">
                  <!-- <div class="text-danger" style="height:10px;"><span *ngIf="enddatevalexist==false">Choose End Date</span></div> -->
                   <!--<span class="text-danger" *ngIf="daySummaryDatesMisMatchError">End Date in greater than Start Date</span>-->
    </div>

   </form>

  `
})


export class InlineDateTimePicker {



    public startdatevalexist: boolean;
    public enddatevalexist: boolean;
    public singleDateValueExist: boolean;

    public errortext: boolean = false;
    public myClass: string = "";
    public showOnlySingleDate: number;

    @Output() public datevalue = new EventEmitter();
    clearvalues: string;

    @Input() setOwnStyle: any

    @Input('setMyClass')
    set setMyClass(val: any) {
        this.setMyOwnClass(val);
    }
    @Input('OnlySingleDate')
    set OnlySingleDate(val: number) {
        setTimeout(() => {
            this.singleDate(val);
        }, 200);
    }

    singleDate(val: any): void {

        if (val == true) {
            this.showOnlySingleDate = 1;
        }

        if (val == false) {
            this.showOnlySingleDate = 0;
        }
        if (val === 3) {
            this.showOnlySingleDate = 3
        }

    }

    setMyOwnClass(val: any): void {
        let stringFormation: any = '';
        if (typeof val !== undefined && val.length > 0) {
            val.map((myClassName: any) => {
                stringFormation += ' ' + myClassName;
            })
        }
        this.myClass = stringFormation;
    }

    constructor(public datetime: bootstrapDateTime, public choosenutility: chosenUtility) {

    }
    ngOnInit() {
        const pickerConfig: any = {
            timePicker: false,
            timeFormat: null,
            closePickerOnDateSelect: true,
            setDaysOfWeekDisabled: '[0,6]'
        }
        setTimeout(() => {
            if (this.showOnlySingleDate == 0) {

                this.getinput('StartDate', 'EndDate', pickerConfig);


            }
            if (this.showOnlySingleDate === 3) {

                this.getinput('StartDate', 'EndDate', pickerConfig);


            }
            if (this.showOnlySingleDate == 1) {
                this.getinput('singleDate', pickerConfig);
            }
        }, 200);
    }

    startDatevaluate(event: any) {

        if (event.target.value) {
            this.startdatevalexist = true;

        }
        if (!event.target.value) {
            this.startdatevalexist = false;

        }

    }

    endDatevaluate(event: any) {

        if (event.target.value) {
            this.enddatevalexist = true;

        }
        if (!event.target.value) {
            this.enddatevalexist = false;
        }

    }
    singleDatevaluate(event: any) {

        if (event.target.value) {
            this.singleDateValueExist = true;

        }
        if (!event.target.value) {
            this.singleDateValueExist = false;
        }

    }

    captureEmittedValueFromComponent(e: any) {
        e.target.value = e;
        return false;
    }

    emitDateObj() {
        let starttime = $('#StartDate').val() ? $('#StartDate').val() : null;
        let endtime = $('#EndDate').val() ? $('#EndDate').val() : null;
        let singleDate = $('#singleDate').val() ? $('#singleDate').val() : null;


        if (starttime !== null && endtime !== null) {
            starttime = this.dateReverser(starttime);
            endtime = this.dateReverser(endtime);

            this.datevalue.emit({ 'startDate': starttime, 'endDate': endtime });
        }
        if (singleDate !== null) {
            singleDate = this.dateReverser(singleDate);
            this.datevalue.emit({ 'startDate': singleDate, 'endDate': singleDate });
        }

    }

    getinput(ele1: any, ele2?: any, pickerConfig?: any) {
        // console.log($('#' + ele1),$('#' + ele2))
        // console.log(pickerConfig);

        let parent = this;
        let timePicker: boolean;
        let closePickerOnDateSelect: boolean;
        let timeFormat: any; let daysofweek: any;
        if ((pickerConfig) && (Object.keys(pickerConfig).length)) {
            timePicker = pickerConfig.timePicker;
            timeFormat = pickerConfig.timeFormat;
            daysofweek = pickerConfig.daysOfWeekDisabled;

            closePickerOnDateSelect = ('closePickerOnDateSelect' in pickerConfig) ? pickerConfig.closePickerOnDateSelect : false;
        } else {
            timePicker = true;
            timeFormat = 'Y-m-d H:i:s';
            closePickerOnDateSelect = false;
            daysofweek = pickerConfig.daysOfWeekDisabled;
        }
        setTimeout(() => {
            if (typeof ele1 === 'string') {
                $('#' + ele1).datetimepicker({
                    format: 'd-m-Y',
                    daysOfWeekDisabled: [0, 6],
                    timepicker: false,
                    maxDate: 0,
                    closeOnDateSelect: closePickerOnDateSelect,
                    autoclose: true,
                    onChangeDateTime: function (e: any) {
                        // let date = new Date(e),
                        // mnth = ("0" + (date.getMonth()+1)).slice(-2),
                        // day  = ("0" + date.getDate()).slice(-2);
                        // // console.log([ date.getFullYear(), mnth, day ].join("-"))
                        // return [ date.getFullYear(), mnth, day ].join("-");
                    },

                });
            }
            // console.log( '#' + JSON.stringify(ele2) , '#'+ele1);
            if (typeof ele2 === 'string') {

                $('#' + ele2).datetimepicker({
                    format: 'd-m-Y',
                    daysOfWeekDisabled: daysofweek,
                    timepicker: false,
                    maxDate: 0,
                    closeOnDateSelect: closePickerOnDateSelect,
                    autoclose: true,
                    onChangeDateTime: function (e: any) {
                        // let date = new Date(e),
                        // mnth = ("0" + (date.getMonth()+1)).slice(-2),
                        // day  = ("0" + date.getDate()).slice(-2);
                        // // console.log([ date.getFullYear(), mnth, day ].join("-"))
                        // return [ date.getFullYear(), mnth, day ].join("-");
                    },


                });

            }
        }, 200);
    }

    dateReverser(str: string) {
        return str.split("-").reverse().join("-");
    }


}