import { Component, OnInit, Input } from "@angular/core";

@Component({
  selector: "g10-loader",
  templateUrl: "./loader.component.html",
  styleUrls: ["./loader.component.css"]
})
export class LoaderComponent implements OnInit {
  constructor() {}
  stage: any = 'Loading....';
  @Input() set stages(value: string) {
    if (value) this.stage = value;
  }

  ngOnInit() {
    //this.canvas();
  }

  canvas() {
    var loader = document.getElementById("chart_loader");
    var canvas = document.createElement("canvas"),
      d = (canvas.width = canvas.height = 120),
      ctx: any = canvas.getContext("2d"),
      opacity;

    loader.appendChild(canvas);

    ctx.translate(d / 2, d / 2);
    ctx.rotate((Math.PI * 360) / 360);
    ctx.lineWidth = Math.ceil(d / 50);
    ctx.lineCap = "square";

    for (var i = 0; i <= 360; i++) {
      ctx.save();

      ctx.rotate((Math.PI * i) / 180);
      //ctx.translate(-ctx.lineWidth/2, ctx.lineWidth/2);

      ctx.beginPath();
      ctx.moveTo(0, 0);

      opacity = (360 - i * 0.95) / 360;

      ctx.strokeStyle = "rgba(255,255,255," + opacity.toFixed(2) + ")";
      ctx.lineTo(0, d + 30);
      ctx.stroke();
      ctx.closePath();

      ctx.restore();
    }

    ctx.globalCompositeOperation = "source-out";
    ctx.beginPath();
    ctx.arc(0, 0, d / 2, 2 * Math.PI, false);
    ctx.fillStyle = "blue";
    ctx.fill();

    ctx.globalCompositeOperation = "destination-out";
    ctx.beginPath();
    ctx.arc(0, 0, (d / 2) * 0.9, 2 * Math.PI, false);
    ctx.fill();
  }
}
