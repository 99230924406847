import { Injectable } from '@angular/core';

@Injectable()

export class roleFormModule {
   
   public jsonObj  = { 
                // dashborad :{
                //       'Routes': false,
                //       'Vehicle Info' : false,
                //       'Vehicle List view': false,
                //       'Alerts': false
                      
                // },
                menu : {
                      'Routes': false,
                      'Reports': false,                     
                      'Students': false,
                      'Campaigns': false,
                      'Settings': false
                },
                settings : {
                       'Organization Setting': false,
                       'Vehicle Over View': false,
                       'User Management': false,
                       'Role Management': false,
                       'Global Configuration': false
                },
                reports: {
                  rfid: {
                        general: false,
                        RFIDWithClass: false,
                        RFIDWithMember: false,
                        RFIDWithRoute: false,
                        RFIDWithAttendance: false,
                        RFIDWithSmsLog: false,
                        RFIDStatusCheck: false
                      },
                      tracking: {
                        movement: false,
                        halt: false,
                        haltDurationReport: false,
                        movementandhalt: false,
                        overspeed: false,
                        lowspeed: false,
                        betweentwospeed: false,
                        daysummary: false
                      },
                      alertlog: {
                        callalertmadelog: false,
                        routehistorylog: false,
                        memberhistorylog: false,
                        callLogCount: false,
                        callMadeReport: false,
                        callMadeEarly:false,
                        callMadeDelayed:false,
                        nrOnTime:false,
                        rOnTime:false,
                        callProcessedReport: false
                      },
                      others: {
                        panic: false,
                        vehiclelastupdate: false,
                        engine: false,
                        ac: false,
                        routevehiclemapped: false,
                        geofence: false,
                        accanddecc: false,
                        otherOverspeed: false,
                        notificationAlert: false,
                        vehicleBasedNotificationAlert: false
                      }
                }
          }
    public orgReportsStatus = {
            rfid: {
             general: 'General RFID Report',
             RFIDWithClass: 'Class wise RFID Report',
             RFIDWithMember: 'Member wise RFID Report',
             RFIDWithRoute: 'Route wise RFID Report',
             RFIDWithAttendance: 'Attendance RFID Report',
             RFIDWithSmsLog: 'SMS RFID Report',
             RFIDStatusCheck: 'RFID Status Check'
           },
           tracking: {
             movement: 'Movement Report',
             halt: 'Halt Report',
             haltDurationReport: 'Halt Duration Report',
             movementandhalt: 'Movement and Halt Report',
             overspeed: 'Overspeed Report',
             lowspeed: 'Lowspeed Report',
             betweentwospeed: 'Speed Range Report',
             daysummary: 'Day Summary Report'
           },
           alertlog: {
             callalertmadelog: 'Call Alert Made Report',
             routehistorylog: 'Route History Log Report',
             memberhistorylog: 'Member History Log Report',
             callLogCount: 'Call Log Count',
             callMadeReport: 'Call Made Report',
             callMadeEarly:'Call Made Early',
             callMadeDelayed:'Call Made Delayed',
             nrOnTime:'Not Reached On Time',
             rOnTime:'Reached On Time',
             callProcessedReport: 'Call Processed Report'
           },
           others: {
             panic: 'Panic Report',
             vehiclelastupdate: 'Vehicle Last Update Report',
             engine: 'Engine Report',
             ac: 'AC Report',
             routevehiclemapped: 'Route Vehicle Mapped Report',
             geofence: 'Geofence Report',
             accanddecc: 'Sudden Braking/Acceleration Report',
             otherOverspeed: 'Overspeed Report',
             notificationAlert: 'Notification Alert',
             vehicleBasedNotificationAlert: 'Vehicle Based Notification Alert'
           }
 }

}