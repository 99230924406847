/**
 * @Filename: trackerForm.dir.ts
 * @Last modified by:   group10
 * @Last modified time: 2018-05-22T15:19:43+05:30
 * @Copyright: Group10 Technologies
 */



/*Auther:Deepak */
import { Component, Input, EventEmitter, Output } from '@angular/core';
import { globalService } from './../../../services/global.service';
import { ApiService } from './../../../services/ApiService';
import { RouterModule, Routes, Router } from '@angular/router';
import { GlobalUtils } from './../../../services/global-utils';

const swal = require('sweetalert');

@Component({
  selector: 'update-tracker-form',
  templateUrl: './trackerUpdateFormTemplate.html',
  styles: [`

    .TrackerInfo-Panel{
      padding:20px;
    }

    .formContent{
      padding-left: 16px;
      padding-right: 16px;
      padding-bottom: 8px;
    }

    `  ],
})
export class OrgTrackerUpdateFormDir {



  public formError: any = {};
  public showForm: boolean = false;
  public trackerInfo: any = [];
  private currentTrackerId: string = '';
  private errorMesages: any = [];
  public clickMessage: any;
  public simCard1_provider:any;
  public simCard2_provider:any;
  private currentOrganization: any = this.globalService.getData().currentOrganization;
  private apiToken: any = localStorage.getItem("adminToken");

  public changedObj: any = {};

  constructor(

    public globalService: globalService,
    public apiService: ApiService,
    private router: Router,
    private globalUtils: GlobalUtils
  ) {


    this.trackerInfo.vehicleInformation = [];


  }

  @Input('trackerId') _trackerId: any;
  @Output() hideTrackerUpdateFormComponent: EventEmitter<any> = new EventEmitter<any>();

  ngOnInit() {

    this.currentTrackerId = this._trackerId;

    if (this.currentTrackerId != '') {
      this.viewTrackerInfo();
    }

  }


  hideUpdateForm() {
    this.hideTrackerUpdateFormComponent.emit(false);
  }

  /* funtion used to refine the date for general forms */
  dateRefine(arg: any) {
    if (arg != undefined) {
      let date = arg.date;
      return date;
    }
  }

  MobileNumberValidator(event: any) {
    // event.srcElement.value = event.srcElement.value.replace(/[^\d].+/, "");
    event.srcElement.value = event.srcElement.value.replace(/[^0-9]/g,'');


    if (event.srcElement.value != '') {
     
      if ((event.which < 48 || event.which > 57)) {
        event.preventDefault();
      }

    } else {
      // event.srcElement.value=0;
      
    }



    

  }



  saveTrackerValidator() {

    let errorFlag = false; this.errorMesages = [];

    //tracker Information

    //set tracker
    this.formError.organizationTrackerIMEI = false;
    if (this.trackerInfo.imei == "" || this.trackerInfo.imei == undefined) {
      this.formError.organizationTrackerIMEI = true;
      errorFlag = true; (this.errorMesages).push("IMEI-1 should not be empty");
    }

    // this.formError.organizationTrackerIMEI2 = false;
    // if (this.trackerInfo.imei2 == "" || this.trackerInfo.imei2 == undefined) {
    //   this.formError.organizationTrackerIMEI2 = true;
    //   errorFlag = true; (this.errorMesages).push("IMEI-2 should not be empty");
    // }

    //set BoxId
    this.formError.organizationTrackerBoxid1 = false;
    if (this.trackerInfo.BoxId == "" || this.trackerInfo.boxid == undefined) {
      this.formError.organizationTrackerBoxid1 = true;
      errorFlag = true; (this.errorMesages).push("BoxId-1 should not be empty");
    }

    // this.formError.organizationTrackerBoxid2 = false;
    // if (this.trackerInfo.boxid2 == "" || this.trackerInfo.boxid2 == undefined) {
    //   this.formError.organizationTrackerBoxid2 = true;
    //   errorFlag = true; (this.errorMesages).push("BoxId-2 should not be empty");
    // }

    //set Vehiclename
    this.formError.organizationTrackerVehiclename = false;
    if (this.trackerInfo.vehicleInformation.name == "" || this.trackerInfo.vehicleInformation.name == undefined) {
      this.formError.organizationTrackerVehiclename = true;
      errorFlag = true;
      (this.errorMesages).push("Vehiclename should not be empty");
    }

    this.formError.organizationTrackerType = false;
    if (this.trackerInfo.vehicleInformation.type == "" || this.trackerInfo.vehicleInformation.type == undefined) {
      this.formError.organizationTrackerType = true;
      errorFlag = true;
      (this.errorMesages).push("Type should not be empty");
    }

    //set Vehicle Registration Number
    this.formError.organizationTrackerRegistrationnumber = false;
    if (this.trackerInfo.vehicleInformation.regno == "" || this.trackerInfo.vehicleInformation.regno == undefined) {
      this.formError.organizationTrackerRegistrationnumber = true;
      errorFlag = true; (this.errorMesages).push("Registration number should not be empty");
    }

    if (errorFlag == true) { return false; } else { return true; }


  }





  // trackerFormUpdate() { 
  //   if (this.saveTrackerValidator() == true) {
  //     let apiHeader = {
  //       data: {
  //         key: this.apiToken,
  //         form: {
  //           imei: this.trackerInfo.imei,
  //           imei2: this.trackerInfo.imei2,
  //           boxid: this.trackerInfo.boxid,
  //           boxid2: this.trackerInfo.boxid2,
  //           simCard: this.trackerInfo.simCard,
  //           simCard2: this.trackerInfo.simCard2,
  //           vehicleInformation: {
  //             name: this.trackerInfo.vehicleInformation.name,
  //             type: this.trackerInfo.vehicleInformation.type,
  //             make: this.trackerInfo.vehicleInformation.make,
  //             regno: this.trackerInfo.vehicleInformation.regno,
  //             ownerName: this.trackerInfo.vehicleInformation.ownerName,
  //             ownerPhone: this.trackerInfo.vehicleInformation.ownerPhone,
  //             ownerAddress: this.trackerInfo.vehicleInformation.ownerAddress,
  //             model: this.trackerInfo.vehicleInformation.model,
  //             manufactureYear: this.trackerInfo.vehicleInformation.manufactureYear,
  //             purchasedYear: this.trackerInfo.vehicleInformation.purchasedYear,
  //             color: this.trackerInfo.vehicleInformation.color,
  //             fuel: this.trackerInfo.vehicleInformation.fuel,
  //             engineNumber: this.trackerInfo.vehicleInformation.engineNumber,
  //             chasisNumber: this.trackerInfo.vehicleInformation.chasisNumber,
  //             insuranceCompany: this.trackerInfo.vehicleInformation.insuranceCompany,
  //             insurancePolicyNumber: this.trackerInfo.vehicleInformation.insurancePolicyNumber,
  //             insuranceExpiryDate: this.trackerInfo.vehicleInformation.insuranceExpiryDate,
  //             driverName: this.trackerInfo.vehicleInformation.driverName,
  //             driverPhone: this.trackerInfo.vehicleInformation.driverPhone,
  //             driverAddress: this.trackerInfo.vehicleInformation.driverAddress
  //           },
  //           status: 'active',
  //           organizationId: this.currentOrganization,
  //           trackerId: this.currentTrackerId
  //         }

  //       }
  //     }
  //     //delete empty fields.
  //     for (let info in apiHeader.data.form) { if (!apiHeader.data.form[info]) delete apiHeader.data.form[info] };

  //     for (let info in apiHeader.data.form.vehicleInformation) { if (!apiHeader.data.form.vehicleInformation[info]) delete apiHeader.data.form.vehicleInformation[info] };

  //     this.apiService.orgTrackerUpdate(apiHeader).then(response => {
  //       let result = response;
  //       if (result.status == "success") {
  //         swal("Success", "Tracker updated Successfully", "success");
  //         setTimeout(() => {
  //           swal.close();
  //           this.viewTrackerInfo();
  //         }, 2000);
  //         this.hideUpdateForm();
  //       }
  //     })
  //       .catch(error => {
  //         console.log('Error while saving the data', error);
  //         swal("Failure", "Error while updating tracker", "success");
  //       });

  //   }//Validation

  // }
  /*updating user ends here */


  trackerFormUpdate() {

    this.changedObj['vehicleInformation'] = this.trackerInfo.vehicleInformation;
    if (Object.keys(this.changedObj).length === 0) {
      swal({
        title: "Nothing to update..! No changes happened",
      });
    } else {
      let form = this.changedObj;
      form.organizationId = this.currentOrganization;
      form.trackerId = this.currentTrackerId;
      form.simvendor= this.simCard1_provider;
           form.simvendor2=this.simCard2_provider;
      let apiHeader = {
        data: {
          key: this.apiToken,
          form
        }
      }
      this.apiService.orgTrackerUpdate(apiHeader).then(response => {
        let result = response;
        if (result.status == "success") {
          swal("Success", "Tracker updated Successfully", "success");
          setTimeout(() => {
            swal.close();
            this.viewTrackerInfo();
          }, 2000);
          this.hideUpdateForm();
        } else {
          if (result.status === 'failure') {
            if (result.ec === 'SCB9') {
              swal('IMEI already Engaged');
            }
          }
        }
      })
        .catch(error => {
          //  console.log('Error while saving the data', error);
          swal("Failure", "Error while updating tracker", "success");
        });

    }



  }

  public vehicleInfo: any;
  /*viewing user infomation onclick on the user row in the table */
  viewTrackerInfo() {

    this.apiService.orgTrackerView({
      data: {
        key: this.apiToken,
        filter: {
          organizationId: this.globalService.getData().currentOrganization,
          trackerId: this.currentTrackerId
        }
      }
    }).then(response => {
      let result = response;
      if (result.status == "success") {

        this.trackerInfo = response.response;
        this.trackerInfo = this.trackerInfo[0];
        this.vehicleInfo = this.trackerInfo.vehicleInformation;
        
        this.simCard1_provider= response.response[0].simvendor;
        this.simCard2_provider= response.response[0].simvendor2;
      }
    })
      .catch(error => {
        console.log('Error while logging in', error);
      });

  }

  updateObj(key: any, obj: any) {


    if ((key === 'imei') || (key === 'imei2') || (key === 'boxid') || (key === 'boxid2') || (key === 'simCard') ||
      (key === 'simCard2') || (key === 'driverName') || (key === 'driverPhone') || (key === 'driverAddress')) {

      this.changedObj[key] = obj;
    } else {

      //   if(this.changedObj['vehicleInformation'] === undefined){
      //     this.changedObj['vehicleInformation'] = {};
      //   }

      // this.changedObj['vehicleInformation'][key] = obj;
    }
    ////  console.log(this.changedObj);
  }
  /*code for view individual user information ends here */

}

/*
Notes : npm install node-sass -g
*/
