/*Auther:Deepak */
import { Component, Input } from '@angular/core';
import { RouterModule, Routes, Router } from '@angular/router';
import { globalService } from './../../services/global.service';
import { ApiService } from './../../services/ApiService';

const swal = require('sweetalert');

@Component({
  selector: 'alert-config',
  template: `
  <div class="container animated fadeIn" style="margin-top:25px;margin-bottom:3%">
    <div class="row">
      <!--Adiing contents here-->
      <div class="container-fluid">
        <div class="row">
          <div class="panel panel-default clearfix">
            <div class="panel-heading">
              <h2 class="panel-title">Alert Configuration</h2>
            </div>

            <div class="col-xs-12 toggle-header">
              <div class="col-xs-4">
              </div>
              <div class="col-xs-1 text-center">
                <span class="hidden-xs">SMS</span>
                <span class="visible-xs">S</span>
              </div>
              <div class="col-xs-1 text-center">
                <span class="hidden-xs">APP</span>
                <span class="visible-xs">M</span>
              </div>
              <div class="col-xs-1 text-center">
                <span class="hidden-xs">EMAIL</span>
                <span class="visible-xs">E</span>
              </div>
              <div class="col-xs-1 text-center">
                <span class="hidden-xs">CALL</span>
                <span class="visible-xs">C</span>
              </div>
              <div class="col-xs-1 text-center">
              <span class="hidden-xs">RFID</span>
              <span class="visible-xs">C</span>
            </div>
            </div>

            <div id="feature-1" class="collapse in">
              <div class="panel-body">
                <div class="row">
                  <div class="col-xs-4" style="padding-top:10px;">
                    Choose Feature
                  </div>
                  <div class="col-xs-1 text-center" style="padding-top:10px;">
                    <input class="chk regular-checkbox meager-e2e-configure-alert-sms" type="checkbox" [(ngModel)]="smsAlert" name="smsAlert" >
                  </div>
                  <div class="col-xs-1 text-center" style="padding-top:10px;">
                    <input class="chk regular-checkbox meager-e2e-configure-alert-app" type="checkbox" [(ngModel)]="appAlert" name="appAlert" >
                  </div>
                  <div class="col-xs-1 text-center" style="padding-top:10px;">
                    <input class="chk regular-checkbox meager-e2e-configure-alert-email" type="checkbox" [(ngModel)]="emailAlert" name="emailAlert">
                  </div>
                  <div class="col-xs-1 text-center" style="padding-top:10px;">
                    <input class="chk regular-checkbox meager-e2e-configure-alert-call" type="checkbox" [(ngModel)]="callAlert" name="callAlert" >
                  </div>
                  <div class="col-xs-1 text-center" style="padding-top:10px;">
                  <input class="chk regular-checkbox meager-e2e-configure-alert-rfid" type="checkbox" [(ngModel)]="rfidAlert" name="rfidAlert" >
                </div>
                </div>
              </div>
              <div class="panel-body">
                <div class="row">
                  <div class="col-xs-10">
                  </div>

                </div>
              </div>
            </div>
          </div>
          <!--<div class="pull-right">
            <ul class="list-inline pull-right">
                <li><button type="button" class="btn btn-primary next-step" (click)="editOrgAlertIngfo();" style="border-radius:0px;letter-spacing: 1px;">UPDATE</button></li>
            </ul>
          </div>-->
        </div>
      </div>
      <!--contents ends here-->
    </div>
    <div class= "row">
    <div class="container-fluid">
      <div class="row">
        <div class="panel panel-default clearfix">
          <div class="panel-heading">
            <h2 class="panel-title">Weekdays Configuration</h2>
          </div>

          <div class="col-xs-12 toggle-header">
            <div class="col-xs-4">
            </div>
            <div class="col-xs-1 text-center">
              <span class="hidden-xs meager-e2e-weekdays-sunday">Sunday</span>
              <span class="visible-xs meager-e2e-weekdays-sunday">S</span>
            </div>
            <div class="col-xs-1 text-center">
              <span class="hidden-xs meager-e2e-weekdays-monday">Monday</span>
              <span class="visible-xs meager-e2e-weekdays-monday">M</span>
            </div>
            <div class="col-xs-1 text-center">
              <span class="hidden-xs meager-e2e-weekdays-tuesday">Tuesday</span>
              <span class="visible-xs meager-e2e-weekdays-tuesday">T</span>
            </div>
            <div class="col-xs-1 text-center">
              <span class="hidden-xs meager-e2e-weekdays-wednesday">Wednesday</span>
              <span class="visible-xs meager-e2e-weekdays-wednesday">W</span>
            </div>
            <div class="col-xs-1 text-center">
              <span class="hidden-xs meager-e2e-weekdays-thursday">Thursday</span>
              <span class="visible-xs meager-e2e-weekdays-thursday">T</span>
            </div>
            <div class="col-xs-1 text-center">
              <span class="hidden-xs meager-e2e-weekdays-friday">Friday</span>
              <span class="visible-xs meager-e2e-weekdays-friday">F</span>
            </div>
            <div class="col-xs-1 text-center">
              <span class="hidden-xs meager-e2e-weekdays-saturday">Saturday</span>
              <span class="visible-xs meager-e2e-weekdays-saturday">S</span>
            </div>
          </div>

          <div id="feature-1" class="collapse in">
            <div class="panel-body">
              <div class="row">
                <div class="col-xs-4" style="padding-top:10px;">
                  Select Weekdays
                </div>
                <div class="col-xs-1 text-center" style="padding-top:10px;">
                  <input class="chk regular-checkbox meager-e2e-weekdays-sunday" type="checkbox" [(ngModel)]="sunday" name="sunday" >
                </div>
                <div class="col-xs-1 text-center" style="padding-top:10px;">
                  <input class="chk regular-checkbox meager-e2e-weekdays-monday" type="checkbox" [(ngModel)]="monday" name="monday" >
                </div>
                <div class="col-xs-1 text-center" style="padding-top:10px;">
                  <input class="chk regular-checkbox meager-e2e-weekdays-tuesday" type="checkbox" [(ngModel)]="tuesday" name="tuesday">
                </div>
                <div class="col-xs-1 text-center" style="padding-top:10px;">
                  <input class="chk regular-checkbox meager-e2e-weekdays-wednesday" type="checkbox" [(ngModel)]="wednesday" name="wednesday" >
                </div>
                <div class="col-xs-1 text-center" style="padding-top:10px;">
                  <input class="chk regular-checkbox meager-e2e-weekdays-thursday" type="checkbox" [(ngModel)]="thursday" name="thursday" >
                </div>
                <div class="col-xs-1 text-center" style="padding-top:10px;">
                  <input class="chk regular-checkbox meager-e2e-weekdays-friday" type="checkbox" [(ngModel)]="friday" name="friday" >
                </div>
                <div class="col-xs-1 text-center" style="padding-top:10px;">
                  <input class="chk regular-checkbox meager-e2e-weekdays-saturday" type="checkbox" [(ngModel)]="saturday" name="saturday" >
                </div>
              </div>
            </div>
            <div class="panel-body">
              <div class="row">
                <div class="col-xs-10">
                </div>

              </div>
            </div>
          </div>
        </div>
        <div class="pull-right">
          <ul class="list-inline pull-right">
              <li><button type="button" class="btn btn-primary next-step meager-e2e-configure-alert-update-btn" (click)="editOrgAlertIngfo();" style="border-radius:0px;letter-spacing: 1px;">UPDATE</button></li>
          </ul>
        </div>
      </div>
    </div>
    <!--contents ends here-->
    </div>
  </div>
  `,
  styles: [`
    div.section-title{
      font-size:20px;
    }
    div.section-title-border{
      margin-bottom: 20px;
      border-bottom: 1px solid #00BCD4;
      border-bottom-width: medium;
      border-spacing: 1px;
      width:10%;
    }

   .toggle-header{
     padding:10px 0;
     background: linear-gradient(rgb(255, 255, 255) 0px, rgb(243, 243, 243) 100%);
     color:black;
   }
   .txt-green{
       color:green;
   }
   .txt-red{
       color:red;
   }

   .panel-default>.panel-heading {
    color: #333;
    background-color: #ffff;
    border-color: #f0f0f0;
   }
   .panel {
    box-shadow: 1px 2px 4px 0px lightgrey;
   }
   .panel-default {
    border-color: #fdfafa;
   }


`  ],
})
export class alertConfigDir {


  public profileInformation:any={};
  public weekdaysConfig:any= [];
  public smsAlert:boolean;
  public appAlert:boolean;
  public emailAlert:boolean;
  public rfidAlert:boolean;
  public callAlert:boolean;
  public sunday:boolean;
  public monday:boolean;
  public tuesday:boolean;
  public wednesday:boolean;
  public thursday:boolean;
  public friday:boolean;
  public saturday:boolean;

  constructor(
    public router: Router,
    public globalService: globalService,
    private apiService: ApiService,
  ) {

  }

  ngOnInit(){
    this.viewOrgAlertInfo();
  }

  viewOrgAlertInfo() { //function to view organiation alert configuration
    this.apiService.getCurrentOrgDetails({
      data: {
        key: localStorage.getItem('scbToken'),
        filter: {}
    }
    }).then(response => {
       let result=response;
        if(result.status=="success"){
          if(result.response!=null && result.response!=undefined && result.respose!=''){
            this.profileInformation=result.response;
            this.smsAlert = this.profileInformation.smsAlert;
            this.appAlert = this.profileInformation.appAlert;
            this.emailAlert = this.profileInformation.emailAlert;
            this.callAlert = this.profileInformation.callAlert;
            this.rfidAlert = this.profileInformation.rfidAlert;
            }
         }
         //console.log(this.profileInformation.weekdays);
         this.weekdaysConfig = this.profileInformation.weekdays;
         if(this.weekdaysConfig){
           this.sunday = this.weekdaysConfig.sunday;
           this.monday = this.weekdaysConfig.monday;
           this.tuesday = this.weekdaysConfig.tuesday;
           this.wednesday = this.weekdaysConfig.wednesday;
           this.thursday = this.weekdaysConfig.thursday;
           this.friday = this.weekdaysConfig.friday;
           this.saturday = this.weekdaysConfig.saturday;
         }
         }).catch(error => {
          console.log('Error while logging in', error);
         });
  }


  editOrgAlertIngfo(){
    var editObj = {
      data: {
        key: localStorage.getItem('scbToken'),
          form: {
            smsAlert: this.smsAlert+"",
            appAlert: this.appAlert+"",
            emailAlert: this.emailAlert+"",
            callAlert:this.callAlert+"",
            rfidAlert:this.rfidAlert+"",
            weekdays: {
              sunday: this.sunday,
              monday: this.monday,
              tuesday: this.tuesday,
              wednesday: this.wednesday,
              thursday: this.thursday,
              friday: this.friday,
              saturday: this.saturday
           }
          }
        }
      }
      this.apiService.editOrgDetails(editObj).then(response => {
        if (response) {
          swal("Success", "Alert configuration Updated", "success");
          setTimeout( ()=>{ swal.close();}, 2000);
        }
        this.viewOrgAlertInfo();
      }).catch(error => {
           console.log('Error while logging in', error);
       });

  }





}
/*
Notes : npm install node-sass -g
*/
