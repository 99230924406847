import { ChannelPartnersConfigComponent } from "./channel-partners.component";
import { SarvVendorComponent } from "./channel-partner-vendor-sarv/channel-partner-vendor-sarv.component";
import { UnicelVendorComponent } from "./channel-partner-vendor-unicel/channel-partner-vendor-unicel.component";



export const ChannelPartnerRoutingConfig: any = {
    path: 'sysAdmin/channel-partner', children: [
        { path: '', component: ChannelPartnersConfigComponent },
        { path: 'sarv', component: SarvVendorComponent },
        { path: 'unicel', component: UnicelVendorComponent }
    ]
}

export const ChannelPartnerRoutingDeclarations: any = [
    ChannelPartnersConfigComponent, SarvVendorComponent, UnicelVendorComponent
];
