/**
 * @Author: Ragu N S
 *          Deepak Pookkote <group10>
 * @Date:   2018-04-24T11:51:53+05:30
 * @Email:  deepak@groupten.com
 * @Project: School-Bus
 * @Filename: dashboard.component.ts
 * @Last modified by:   group10
 * @Last modified time: 2018-05-25T10:49:20+05:30
 * @Copyright: Group10 Technologies
 */

import { Observable } from 'rxjs';
import { NgRedux, select } from '@angular-redux/store';
import { AppState } from './../../../store/index';

import {
    Component, ElementRef, AfterViewInit,
    OnDestroy, ViewChild, Input, Output, NgZone, HostListener
} from '@angular/core';
import { RouterModule, Routes, Router } from '@angular/router';
import { AppComponent } from './../../../app.component';
import { globalService } from './../../../services/global.service';
import { ApiService } from './../../../services/ApiService';
import { authGaurd } from './../../../services/auth-guard.service';
// import { MessageService } from './../../../services/message.service'

declare var $: any;

@Component({
    styles: [`


      .primary-div {
          //margin-top: 10%;
      }

      .bg-blue {
          background-color: #3498db;
      }

      /*Primary iconic nav*/

      .icon .prim-icon {
          margin-left: 10px;

      }

    //   .icon .icon-pad {
    //     padding: 6px 12px;
    //   }

       .icon .icon-pad {
        padding: 6px 6px;
     }

     .bottom{
        margin-top:0.97em;
        margin-left:0.7em;
        color:#f9f9f9;
     }

      .icon .icon-pad .prim-nav-a {
          color: white;
          padding-left: 2px;
          padding-right: 2px;
          padding-top: 0px;
          padding-bottom: 0px;
          font-size: 23px;
      }

      .img {
          height: 10px;
          z-index: 800;
      }

      .ul {
          position: relative;
          margin-top: -14px;
          padding: 0px 4px;
      }

      ul li .prim-nav-arrow {
        color: white;
        margin-left: 0px;
        padding-left: 24px;
        padding-right: 24px;
        /* opacity: 0.0; */
      }

      ul li .prim-nav-arrow-select {
          opacity: 1;
      }

      /*Secondary nav*/

      .route-text {
          margin-top: -15px;
          font-size: 20px;
          font-weight: 400;
          padding: 7px 18px;
      }

      .sec-nav a {
          text-decoration: none;
          color: black;
      }

      .sec-nav div {
          border-bottom: 3px solid lightgrey;
          border-top: 1px solid lightgrey;
      }

      .sec-nav div:hover {
          cursor: pointer;
          background-color: white;
          border-bottom: 3px solid #3498db;
      }

      .sec-nav .active {
          background-color: white;
          border-bottom: 3px solid #3498db;
      }

      .txt_pad {
          font-size: 15px;
          padding-left: 0px;
          padding-bottom: 10px;
          padding-top: 10px;
          padding-right: 20px;

      }

      .text_upcoming_pad {
        font-size: 15px;
        padding-left: 4px;
        padding-bottom: 10px;
        padding-top: 10px;
        padding-right: 20px;

      }

      div.parent{
        background:#CCC;
        height:200px
      }

      .list-inline>li {
      display: inline-block;
      padding-left: 5px;
      }

    `],
    templateUrl: './dashboard.component.html'
})

export class clientDashboardComponent {

    @select('trackerData') public trackerData$: Observable<any>;
    @select('vehicleData') public vehicleData$: Observable<any>;

    public activMapModule: string = '';
    public routeVehMapData: any = '';
    public closeEvent: boolean = false;

    public mapRefresh = true;
    public routeplotting: boolean = false;

    public mapGeoPosition: any = {};
    public sideMenu: boolean = true;
    public alertPanel: boolean = false;
    public settingsPanel: boolean = false;
    public userLogPanel: boolean = false;
    public vehicleInfo: boolean = false;
    public socketConnectionPermission: boolean = true;
    private showingTimeline: boolean = true;
    private showHide: boolean;
    public mapHeight: number = 91;
    private showPushNotifs: boolean;
    public clickedRouteId: any;
    private newNotifReceived: any;
    public clickedVehicleId: any;
    public clickedVehicleUniqueId: any;
    public popUpInformation: boolean;
    routeAnalytics: any = {}
    @Input() CloseEvent: boolean
    appReports: any;

    constructor(
        public apiService: ApiService,
        public authGaurd: authGaurd,
        public router: Router,
        private ngRedux: NgRedux<AppState>,
        // public mess:MessageService
    ) {


    }

    ngOnInit() {
        this.authGaurd.checkToken();
        this.viewOrgDetail()
        $(document).ready(function () {
            $('[data-toggle="tooltip"]').tooltip();
        });

        function adjustStyle(width: any) {
            width = parseInt(width);
            if (width < 701) {
                $('#size-stylesheet').attr('href', 'css/narrow.css');
            } else if ((width >= 701) && (width < 900)) {
                $('#size-stylesheet').attr('href', 'css/medium.css');
            } else {
                $('#size-stylesheet').attr('href', 'css/wide.css');
            }
        }

        $(function () {
            adjustStyle($(this).width());
            $(window).resize(function () {
                adjustStyle($(this).width());
            });
        });

        // this.MapToggle();
        this.mapBeat();



    }
    mapBeat() {
        this.mapRefresh = false; // Math.floor(Math.random() * 100);
        setTimeout(() => {
            this.mapRefresh = true;
        }, 50);

        // setTimeout( () => {
        //     this.mapBeat();
        //         }, 50000 );

        // console.log( this.mapRefresh );
    }
    routelistsview() {
        this.router.navigate(['/routelistview', {}]);
    }
    routeToDashboard2() {
        this.router.navigate(['/client-dashboard-2', {}]);
    }

    routeVehMapTrig(obj: any) {

        if (obj.routeID != '' || obj.routeID != {}) {
            this.activMapModule = 'roadMap';
            if (this.activMapModule == 'roadMap') {
                this.activMapModule = '';
                setTimeout(() => {
                    this.activMapModule = 'roadMap';

                }, 100);
            }
            this.routeVehMapData = obj;
        } else {
            this.activMapModule = ''
        }
    }

    // closebtnTrig(closeventEmitted:any){

    //     if(closeventEmitted=true){
    //         console.log(this.closeEvent);
    //         this.closeEvent=true;
    //     }
    //     if(closeventEmitted=false){
    //         console.log(this.closeEvent);
    //         this.closeEvent=false;
    //     }

    // }

    showMenu(menuName: string) {
        let menus = ['sideMenu', 'alertPanel', 'settingsPanel', 'userLogPanel', 'vehicleInfo'];
        menus.splice(menus.indexOf(menuName), 1);
        for (const menu of menus) {
            this[menu] = false;
        }
        this[menuName] = true;
    }

    getAlertDispStatus(event: any) { // event emotter to show alertoverlay on client dashboard.
        this.showPushNotifs = event;
    }

    getActiveRouteId(event: any) {
        this.clickedRouteId = event;
        if (event.routeMap == false) {
            this.activMapModule = '';
        }
    }

    // EmittedRouteType(event:any){
    //   if(event == 'AllRoutes'){
    //     this.setRouteType('AllRoutes')
    //   }
    // }

    emitDisplayInformation(event: boolean) {
        this.popUpInformation = event;
    }

    setDefaultRouteId(route: any) {
        this.clickedRouteId = route;
    }

    // setRouteType(routeType:string):void{
    //   this.alertPanel = false; //hide alert panel ,if shown.
    //   this.showRouteType = routeType;
    //   this.sidebarClickedTab = routeType;
    //   this.sideMenu = true;
    // }

    pushNewNotif(notif: any): void {
        this.newNotifReceived = notif;
    }

    emitClickedVehId(vehId: any) {
        this.clickedVehicleId = vehId;
    }

    emitMapPanFlag(flag: boolean) {

    }

    emitMapHeight(event: any) {
        this.mapHeight = event;
    }

    manipulateBottomBar() {
        this.mapHeight = 91;
    }

    VehInfoMapFlagEmitter($event: any) {
        this.clickedVehicleId = $event
    }
    // resetMapVal(){
    //     this.mess.getMessage().subscribe((dat:any)=>{
    //         if(dat.txt == 'activateMap'){
    //             this.activMapModule==="roadMap" ? this.activMapModule = '' : this.activMapModule
    //         }

    //     })

    // }
    surveillanceDashboard() {
        this.router.navigate(['/surveillance-dashboard', {}]);
    }

    analytics(url: string) {
        this.router.navigate(['/' + url, {}]);

    }
    viewOrgDetail() {
        this.apiService.getCurrentOrgDetails({
            data: {
                key: localStorage.getItem('scbToken'),
                filter: {},

            }
        }).then(response => {
            let result = response;
            const classandsection: any = {};


            // if (result.status === 'success') {
            //     this.routeAnalytics = result.response.reports.routeanalytics.routeAnalytics

            // }
            if (result.response && result.response.reports && result.response.reports.routeanalytics
                && result.response.reports.routeanalytics.routeAnalytics
            ) {
                this.routeAnalytics = result.response.reports.routeanalytics.routeAnalytics

            }




        })
    }
}
