/**
 * @Author: Rakesh Roushan
 * @Date:   2018-04-23T10:20:49+05:30
 * @Email:  rakesh@groupten.com
 * @Project: SChool-Bus
 * @Filename: route.move.dir.ts
 * @Last modified by:   group10
 * @Last modified time: 2018-04-23T15:25:35+05:30
 * @Copyright: Group10 Technologies
 */


 import { Component } from '@angular/core';
 import { ApiService } from './../../services/ApiService';
 import { FormControl, FormGroup, FormBuilder, Validators } from '@angular/forms';
 declare var swal:any;

 @Component({
     selector:'route-swap-config',
     template:`
         <div class="panel panel-default">
             <div class="panel-heading">Route Swap</div>
             <div class="panel-body">
                 <div class="row" style="margin-bottom:4%">
                     <div class="col-md-6">
                         <label class="control-label">Source route:</label>
                         <single-search-select class="meager-e2e-swap-source-route"
                         [searchType]="'onlyRoute'"
                         [searchAPIUrl]="'getRoutes'"
                         [otherParams]="'resetSearchUtility'"
                         (emitUserSelections)="setSourceRoute($event)">
                         (disableDependents)="disableDependentSearchFields($event)">
                         </single-search-select>
                     </div>
                     <div class="col-md-6">
                         <label class="control-label">Destination route:</label>
                         <single-search-select class="meager-e2e-swap-destination-route"
                         [searchType]="'onlyRoute'"
                         [searchAPIUrl]="'getRoutes'"
                         [otherParams]="reset"
                         [filterInputs]="routeFilterInput"
                         (emitUserSelections)="setDestinationRoute($event)">
                         </single-search-select>
                     </div>
                 </div>
                 <section class="selectedRoutesInfoWrapper" *ngIf="checkRouteSelectionStatus()">
                     <div class="row routeBasicInfo" style="margin-bottom:5%" >
                         <div class="col-md-6 partitionRight">
                             <div class="operationGroupHeading mb5">Route Basic Info</div>
                             <div class="basicInfoGroup">
                                 <label class="control-label otherConfigurationLabel">Route Name:</label>
                                 <span class="pull-right">{{sourceRoute[0].routeName}}</span>
                             </div>
                             <div class="basicInfoGroup">
                                 <label class="control-label">Route Start Time:</label>
                                 <span class="pull-right">{{sourceRoute[0].routeStartTime}}</span>
                             </div>
                             <div class="basicInfoGroup">
                                 <label class="control-label">Route Start Time:</label>
                                 <span class="pull-right">{{sourceRoute[0].routeEndTime}}</span>
                             </div>
                         </div>
                         <div class="col-md-6">
                             <div class="operationGroupHeading mb5">Route Basic Info</div>
                             <div class="basicInfoGroup">
                                 <label class="control-label otherConfigurationLabel">Route Name:</label>
                                 <span class="pull-right">{{destinationRoute[0].routeName}}</span>
                             </div>

                             <div class="basicInfoGroup">
                                 <label class="control-label">Route Start Time:</label>
                                 <span class="pull-right">{{destinationRoute[0].routeStartTime}}</span>
                             </div>
                             <div class="basicInfoGroup">
                                 <label class="control-label">Route Start Time:</label>
                                 <span class="pull-right">{{destinationRoute[0].routeEndTime}}</span>
                             </div>
                         </div>
                     </div>
                     <div class="row mb5">
                         <div class="col-md-6 partitionRight">
                             <div class="operationGroupHeading mb5">Assigned Vehicle Info:</div>
                             <div class="basicInfoGroup">
                                 <label class="control-label">Vehicle Name:</label>
                                 <span class="pull-right">{{sourceRoute[0].vehNo}}</span>
                             </div>
                         </div>
                         <div class="col-md-6">
                             <div class="operationGroupHeading mb5">Assigned Vehicle Info:</div>
                             <div class="basicInfoGroup">
                                 <label class="control-label">Vehicle Name:</label>
                                 <span class="pull-right">{{destinationRoute[0].vehNo ?destinationRoute[0].vehNo:'-'}}</span>
                             </div>
                         </div>
                     </div>
                     <div class="routeSwapInformationMesssage" *ngIf="!destinationRoute[0].vehNo || !sourceRoute[0].vehNo"> Route can't be swapped as ,as vehicle is not assigned to any one of routes</div>
                     <div class="routeSwapInformationMesssage" *ngIf="destinationRoute[0].vehNo && sourceRoute[0].vehNo">
                        <button class="btn btn-success pull-right" (click)="swapRoute()">Submit</button>
                     </div>
                 </section>
             </div>
         </div>
     `,
     styles:[`
         .operationGroupHeading{
             margin-bottom: 6%;
             border-left: 5px solid #03A9F4;
             padding: 8px 8px 8px 4px;
             font-size: 15px;
         }
         .basicInfoGroup{
             padding: 0 4% 1% 5%;
         }
         .partitionRight{
             border-right: 1px solid #9e9e9ebd;
         }
         .source-pckpt-list,.dest-pckpt-list{
             padding:4%;
             cursor:pointer;
             height: 55px;
         }
         .pickAddrContainer{
             overflow: auto;
             width: 67%;
         }
         .custom-dest-input{
             width: 20%;
             top: 10px;
             right: 20px;
             position: absolute;
         }
         input.ng-invalid{
           border:1px solid #f4433661;
         }
         li.pointSelected{
             background: #9e9e9e1f;
         }
         ul.fixedHeightList{
             height: 330px;
             overflow: auto;
         }
         .routeSwapInformationMesssage{
             font-size: 14px;
            color: #E91E63;
            margin-top: 3%;
         }

         `]
 })
 export class RouteSwapConfig{
     private sourceRoute:any;
     private destinationRoute:any;
     public routeFilterInput:any;
     private showDestinationFields:boolean;
     public reset:number;
     private routeMoveForm:FormGroup;
     constructor(private apiService:ApiService,public formBuilder:FormBuilder){
         this.sourceRoute = null;
         this.destinationRoute = null;
         this.routeFilterInput = null;
         this.showDestinationFields = false;
     }

     ngOnInit(){
         //this.routeMoveForm = this.formBuilder.group({});
     }

     checkRouteSelectionStatus(){
         let flag = false;
         if((this.sourceRoute.length) && (this.destinationRoute.length)) flag = true;
         return flag;
     }

     setSourceRoute(route:any):void{
             if((route)&&(route.length)){
                 // this.routeFilterInput = {
                 //     routeStartTime:route[0].routeStartTime,
                 //     routeEndTime:route[0].routeEndTime,
                 //     notIn:1
                 // }
                 this.sourceRoute = route;
                 this.reset = Math.random();
             }else{
                 this.routeFilterInput = {};
                 this.sourceRoute = [];
                 this.reset = Math.random();
             }
     }

     setDestinationRoute(route:any):void{
         if((route)&&(route.length)){
             this.destinationRoute = route;
             //this.getRoutePickupPoints(this.destinationRoute[0].routeId).then((response)=> {this.destinationPickupPoints = response;})
         }else{
             this.destinationRoute = [];
         }
     }


     disableDependentSearchFields(param:boolean):void{
         this.showDestinationFields = param;
     }

     swapRoute():void{
         let fromRouteId = this.sourceRoute[0].routeId;
         let toRouteId   = this.destinationRoute[0].routeId;
         let apiHeader   = {
             data:{
                 key:localStorage.getItem('scbToken'),
                 form:{
                     swaprouteId1:fromRouteId,
                     swaprouteId2:toRouteId
                 }
             }
         }
         this.apiService.swapRoute(apiHeader).then(response => {
            if(response.status=="success"){
                swal("Success", "Route Swap Successfull", "success").done();
              }else {
                  swal("Failure",response.message, "error").done();
              }
             console.log(response);
         }).catch(error => {})
     }
 }
