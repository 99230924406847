import { Component, Input, Output, EventEmitter, ComponentFactoryResolver} from '@angular/core';
import { RouterModule, Routes, Router } from '@angular/router';
import { globalService } from './../../services/global.service';
import { ApiService } from './../../services/ApiService';


import { chosenUtility } from './../../services/chosen-utility/jq-chosen-utility.js';

const swal = require('sweetalert');
@Component({
  selector: 'edit-notification',
  template: `
  <div class="container animated fadeIn" style="margin-top:25px;margin-bottom:3%">
    <div class="row">
      <!--Adiing contents here-->
      <div class="container-fluid">
        <div class="row">
          <div class="panel panel-default clearfix">
            <div class="panel-heading">
              <h2 class="panel-title">Update Configuration</h2>
            </div>

            <div id="feature-1" class="collapse in">
              <div class="panel-body">
                <div class="row">
                  <div class="col-md-4 form-group">
                    <label class="control-label">Notification For<sup>*</sup></label>
                    <select class="custom-form-control meager-e2e-view-notification-notification-for" id="notification" name="notification"  [(ngModel)]="notificationInfo.notificationFor" [disabled]="true">
                      <option>Select</option>
                      <!--<option value="geofence">Geofence</option>-->
                      <option value="overspeed">Overspeeding</option>
                      <option value="panic">Panic</option>
                      <option value="halt">Halt</option>
                      <option value="calendar">Calendar</option>
                      <option value="vehiclenotmove">Vehicle Not Moved</option>
                    </select>
                  </div>
                  <div class="col-md-4 form-group">
                    <label class="control-label">Notification Type<sup>*</sup></label>
                    <select class="custom-form-control meager-e2e-view-notification-notification-type" id="notificationtype" name="notificationType" 
                      [(ngModel)]="notificationInfo.notificationType" [disabled]="true" >
                      <option>choose</option>
                      <option value="sms">SMS</option>
                      <option value="email">EMAIL</option>
                      <option value="mobileapp">MOBILE APP</option>
                      <option value="webapp">WEB APPLICATION</option>
                    </select>
                  </div>
                  <div class="col-md-4 form-group" *ngIf="notificationInfo.notificationFor!='calendar' && notificationInfo.notificationFor!='vehiclenotmove'" >
                    <label class="control-label">Select Vehicles<sup>*</sup></label>
                    <input class="custom-form-control meager-e2e-view-notification-select-vehicles" [(ngModel)]="notificationInfo.vehNo" name="selectedtrackername" [disabled]="true">
                    <!--<select class="custom-form-control chosen-select"  multiple data-placeholder="Select Device" id="devicesDropdown">
                      <option value=""></option>
                      <option value="all">All Vehicles</option>
                      <option *ngFor="let vehicle of vehcileList" value="{{vehicle.deviceId}}" >{{vehicle.VehicleNo}}</option>
                    </select>-->
                  </div>
                </div>
                <div class="row" *ngIf="notificationInfo.notificationFor!='calendar'  && notificationInfo.notificationFor!='vehiclenotmove'">
                  <div class="form-group col-md-4">
                    <label class="control-label">Time From<sup>*</sup></label>
                    <input class="custom-form-control timepicker meager-e2e-view-notification-time-from" id="notif_timeFrom" type="time" [(ngModel)]="notificationInfo.startTime">
                    <span class="text-danger" *ngIf="NotificationEndTimeError">Please Enter Start Time </span>
                  </div>
                  <div class="form-group col-md-4">
                    <label class="control-label">Time Till<sup>*</sup></label>
                    <input class="custom-form-control timepicker mb15 meager-e2e-view-notification-time-till" id="notif_timeTo" type="time" [(ngModel)]="notificationInfo.endTime">
                    <span class="text-danger" *ngIf="NotificationEndTimeError">Please Enter End Time </span>
                  </div>
                </div>
                <!--Adding notification filters over here-->
                <div class="row notification Filters  animated fadeIn"  >
                  <div class="form-group col-md-4" *ngIf="notificationInfo.notificationFor=='overspeed'">
                    <label class="control-label"> Speed Limit(Kmph)<sup>*</sup></label>
                    <input class="custom-form-control" id="speed" type="number" [(ngModel)]="notificationInfo.overSpeedval">
                    <span class="text-danger" *ngIf="speedLimitError">Enter speed limit</span>

                  </div>
                  <!--Adding NotificationType Filters-->
                  <div class="col-md-4" *ngIf="notificationInfo.mobileNo!=null && notificationInfo.notificationType=='sms'">
                    <label class="control-label">Add Member for SMS<sup>*</sup></label>
                    <!--<input class="custom-form-control" (keyup)="MobileNumberValidator($event)" minlength="10" maxlength="10"
                     (change)="store()" [(ngModel)]="mobile">
                     <br>
                     <br>
                    <search-select [searchType]="'orguserNames'" [otherParams]="resetSearchUtility"
                                  [searchAPIUrl]="'getOrgMobiles'" (emitUserSelections)="setUserSelections($event)">
                    </search-select> -->
                  <input-tagging [getIterable]="userRegisteredMobile" (sendRes)="receivedCollect($event)"></input-tagging>
                  <small class="text-danger" *ngIf="mobileNumberError">Choose Numbers</small>

                  <!--<span class="text-danger" *ngIf="mobileNumberError">Enter Mobile Number's</span> -->
                  </div>
                  <div class="col-md-4" *ngIf="notificationInfo.mobileNo!=null && notificationInfo.notificationType=='mobileapp'">
                    <label class="control-label">Add Member for Mobile APP<sup>*</sup></label>
                    <!--<input class="custom-form-control" (keyup)="MobileNumberValidator($event)" minlength="10" maxlength="10"
                     (change)="store()" [(ngModel)]="mobile">
                     <br>
                     <br>
                    <search-select [searchType]="'orguserNames'" [otherParams]="resetSearchUtility"
                                  [searchAPIUrl]="'getOrgMobiles'" (emitUserSelections)="setUserSelections($event)">
                    </search-select> -->
                  <input-tagging [getIterable]="userRegisteredMobile" (sendRes)="receivedCollect($event)"></input-tagging>
                  <small class="text-danger" *ngIf="mobileNumberError">Choose Numbers</small>

                  <!--<span class="text-danger" *ngIf="mobileNumberError">Enter Mobile Number's</span> -->
                  </div>
                  <div class="form-groups col-md-4" *ngIf="notificationInfo.email!=null">
                    <label class="control-label">Enter Email ID's<sup>*</sup></label>
                  <!--  <input class="custom-form-control"
                     (change)="storeEmail()" [(ngModel)]="emailData"> -->
                     <input-tag-email [getIterable]="userRegisteredEmail" (sendRes)="receivedCollectEmail($event)"></input-tag-email>
                     <small class="text-danger" *ngIf="emailError">Choose Emails</small>

                     <!-- <span class="text-danger" *ngIf="emailError">Enter Email Id's</span> -->
                  </div>
                  <!--@End Filters-->
                </div>
                
                <div class="row animated fadeIn">
                <!-- itemContainerers(mobile or email)-->
                <div class="col-md-4" *ngIf="notificationInfo.overSpeedval">
                </div>
                
                <div class="form-groups col-md-4" *ngIf="notificationInfo.mobileNo">
                <label class="control-label"></label>
                  <div class="itemContainer">
                    <!--<span class="tagClass cont mr5 text-capitalize mb5" *ngFor="let mobile of mobileNumberList;let i=index;">{{mobile}}&nbsp;
                    <!--<i class="fa fa-times-circle rotate" aria-hidden="true" (click)="removeMobile(i)" style="cursor: pointer;"></i> 
                    </span>-->
                  </div> 
                </div>
                <div class="form-group col-md-4" *ngIf="notificationInfo.email">
                <label class="control-label"></label>
                  <div class="itemContainer">
                   <!-- <span class="tagClass cont mr5 text-capitalize mb5" *ngFor="let emailData of emailList;let i=index;">{{emailData}}&nbsp;<i class="fa fa-times-circle rotate" aria-hidden="true" (click)="removeEmail(i)" style="cursor: pointer;"></i></span> -->
                  </div>
                </div>
                <!--@End Item itemContainer-->
                </div>
                  <!--End notification filters -->

                <!-- <div class="row" style="padding-left:1.5%">
                    <input class="chk regular-checkbox mr5" type="checkbox"><span style="position: fixed;margin: 4px auto;padding-left: 10px;"> Restrict to time intervals</span>
                </div> -->

              </div>
            </div>
          </div>
          <div class="pull-right">
            <ul class="list-inline pull-right">
                <li><button (click)="updateNotification();" type="button" class="btn btn-primary next-step meager-e2e-view-notification-update-btn"  style="border-radius:0px;letter-spacing: 1px;">Update</button></li>
            </ul>
          </div>
        </div>
      </div>
      <!--contents ends here-->

    </div>
  </div>
  `,
  styles: [`
    .itemContainer {
      max-height: 45px;
      overflow-y: auto;
      margin: -35px auto;
      min-height: 50px;
      position: absolute;
    }
    .itemContainer{
      margin: -24px 0 -35px auto;
    }
`  ],
})
export class editNotificationSettingsDir {



    // private showFilter:boolean=false;
     private notificationList:any=[];
     private vehcileList: any = [];
     public VehicleName: any = "";
     public notificationInfo:any=[];


     public mobileNumberList: any = [];
     public mobileContact:any={}
     public mobile: any = '';
     public emailList: any = [];
     public emailData: any = '';
     public speedLimit: any;
     public startTime: any;
     public endTime: any;
     public overSpeedVal:any;

     private selectedAll:any;
     private selectedVehicles:any=[];
     public NotificationStartTimeError:boolean;
     public NotificationEndTimeError:boolean;
     


    @Input('notificationId') _notificationId: any;
  userRegisteredMobile: any={};
  collectedData: any;
  userRegisteredEmail: any={};
  emailError: boolean = false;
  mobileNumberError: boolean = false;

  constructor(
    public router: Router,
    public globalService: globalService,
    private apiService: ApiService,
    public chosenUtility: chosenUtility,
  ) {
  }

  ngOnInit() {
    this.getOrgVehcileList();
    this.viewNotificationList();
    //this.transFormSelectToChosen();
    this.getRegUserMobile();
    
  }


  store() {
    this.mobileNumberList.push(this.mobile);
    this.mobile = '';
  }
  //function to remove the contact information from temporary array
  removeMobile(i: number) {
    this.mobileNumberList.splice(i, 1);
  }

  storeEmail() {
    this.emailList.push(this.emailData);
    this.emailData = '';
  }
  //function to remove the contact information from temporary array
  removeEmail(i: number) {
    this.emailList.splice(i, 1);
  }

  MobileNumberValidator(event: any) {
    event.srcElement.value = event.srcElement.value.replace(/[^\d].+/, "");

    if (event.srcElement.value != '') {
      //  console.log('t',event.srcElement.value);
      if ((event.which < 48 || event.which > 57)) {
        event.preventDefault();
      }
    } else {
      // event.srcElement.value=0;
    }
  }

// //function to check whether user selected All vehicle or individual vehicles
//   checkChoosenVehicles(){
//     if(this.chosenUtility.getControlvalue('devicesDropdown')=='all'){
//       this.selectedAll=this.chosenUtility.getControlvalue('devicesDropdown');
//     }else{
//       this.selectedVehicles=this.chosenUtility.getControlvalue('devicesDropdown');
//
//     }
//   }




  getOrgVehcileList() {
    let parent = this;
    var vehObj = {
      data: {
        key: localStorage.getItem('scbToken'),
        filter: {
          regNo: this.VehicleName
        }
      }
    }
    this.apiService.getOrgVehicles(vehObj).then(response => {
      if (response.status = "success") {
        if (response.response != null && response.response != undefined && response.response != '') {
          this.vehcileList = response.response;
       
          setTimeout(() => {
          //  this.transFormSelectToChosen();
          }, 20) //Initialize chooosen after 2 seconds.
        }
      }
    }).catch(error => { });
  }

  transFormSelectToChosen() {
    this.chosenUtility.initializeChosen('devicesDropdown', '', true);
  }



  viewNotificationList() {
    console.log('BBBBBBBBBBBB')  ;this.apiService.viewNotifications({
      data: {
          key: localStorage.getItem('scbToken'),
          filter: {
            notificationId: this._notificationId
          }
      }
    }).then(response => {
        let result=response;
          if(result.status=="success"){
            if(result.response!=null && result.response!=undefined && result.response!=''){
              this.notificationInfo =result.response[0];
              this.mobileNumberList =this.notificationInfo.mobileNo;
             
              // console.log(this.mobileNumberList);
              setTimeout(() => {
                this.userRegisteredEmail['sub']=this.notificationInfo.email;                
              }, 20);
              // this.mobileNumberList = dat;
              setTimeout(() => {
              // this.mobileNumberList = dat;


                // this.mobileNumberList =this.notificationInfo.mobileNo;
                this.userRegisteredMobile['sub'] = this.notificationInfo.mobileNo;
        
                        this.transFormSelectToChosen();
                        this.chosenUtility.setControlValue('devicesDropdown',this.notificationInfo.vehId);
                      }, 20) //Initialize chooosen after 2 seconds.
              //console.log(this.notificationInfo);// here response is API output
          }
          }
        })
        .catch(error => {
          console.log('Error while logging in', error)
        });
  }


  //Check For Empty Validation
  validateUserInputs() {
    let isValid: boolean = true;

    if (!this.notificationInfo.startTime) {
      this.NotificationStartTimeError = true;
      isValid = false;
    } else {
      this.NotificationStartTimeError = false;
    }

    if (!this.notificationInfo.endTime) {
      this.NotificationEndTimeError = true;
      isValid = false;
    } else {
      this.NotificationEndTimeError = false;
    }

    if(this.emailList.length || this.mobileNumberList.length){


      isValid = true;
    }else{
      this.emailError = true;
      this.mobileNumberError = true;
      isValid = false;
    }

    return isValid;
  }




//   updateNotification(){
//   if (this.validateUserInputs()) {
//     let parent: any = this;
//     var notifObj ={
//       data: {
//         key: localStorage.getItem('scbToken'),
//         form: {
//             notificationId: this._notificationId,
//             mobileNo:this.mobileNumberList,
//             email: this.emailList,
//             startTime: this.notificationInfo.startTime,
//             endTime: this.notificationInfo.endTime,
//             allVehicles: this.selectedAll,
//             vehicleLists: this.selectedVehicles,
//         }
//     }
//     };
//     swal({
//       title: 'Are you sure?',
//       text: "The Selected Notification Will Modified",
//       type: 'warning',
//       timer: 10000,
//       reverseButtons:true,
//       showCancelButton: true,
//       confirmButtonColor: '#3085d6',
//       cancelButtonColor: '#e04d76',
//       confirmButtonText: 'Yes, allow me to modify!',
//       cancelButtonText: 'No, Dont Update'
//     }).then(()=>{
//       this.apiService.editNotification(notifObj).then(response => {
//         var parent: any = this;
//         if (response) {
//           swal(
//             'Modified!',
//             'Notification has been Updated',
//             'success'
//           ).done();
//           setTimeout( ()=>{ swal.close();}, 1000);
//           this.viewNotificationList();
//         };
//       }).catch(error => { });
//     },
//     function(dismiss:any) {
//     if (dismiss === 'cancel') {
//       swal(
//         'Cancelled',
//         'Cancelled :)',
//         'error'
//       ).done();
//       setTimeout( ()=>{ swal.close();}, 1000);
//     }
//   });
//   }
// }


updateNotification(){
  // Object.keys(this.collectedData).length && (this.mobileNumberList = Object.keys(this.collectedData).map((val:any)=>+val));
  // console.log(this.collectedData);
  

//return false;
//alert(this._notificationId);

if(this.notificationInfo.notificationFor=='calendar' && this.notificationInfo.notificationFor!='vehiclenotmove'){ 
  const parent = this;
  var notifObj1 ={
    data: {
      key: localStorage.getItem('scbToken'),
      form: {
          notificationId: this._notificationId,
         // mobileNo:this.mobileNumberList,
          email: this.emailList,
          startTime: this.notificationInfo.startTime,
          endTime: this.notificationInfo.endTime
          //allVehicles: this.selectedAll,
         // vehicleLists: this.selectedVehicles,
        //  overSpeedval: this.notificationInfo.overSpeedval,
        //  mobileContact:this.mobileContact
      }
  }
  };


  swal({
    title: 'Are you sure?',
    text: 'The selected notification will be modified',
    icon: 'warning',
    buttons: [
      'No, cancel it!',
      'Yes, I am sure!'
    ],
    dangerMode: true,
  }).then(function(isConfirm: any) {
    if ( isConfirm ) {
      swal({
        title: 'Success!',
        text: 'Notification updated',
        icon: 'success',
        timer : 2000
      }).then(function() {
        parent.apiService.editNotification(notifObj1).then(response => {
          if (response) {
            console.log('AAAAAAAAAA')  ;
            parent.viewNotificationList();
            }
          }).catch(error => { });
      });
    } else {
      swal('Cancelled', 'Action aborted :)', 'error');
    }
  });
}else if(this.notificationInfo.notificationFor!='calendar' && this.notificationInfo.notificationFor=='vehiclenotmove'){ 

  const parent = this;
  var notifObj2 ={
    data: {
      key: localStorage.getItem('scbToken'),
      form: {
          notificationId: this._notificationId,
          mobileNo:this.mobileNumberList,
         // email: this.emailList,
          startTime: this.notificationInfo.startTime,
          endTime: this.notificationInfo.endTime
          //allVehicles: this.selectedAll,
         // vehicleLists: this.selectedVehicles,
        //  overSpeedval: this.notificationInfo.overSpeedval,
        //  mobileContact:this.mobileContact
      }
  }
  };


  swal({
    title: 'Are you sure?',
    text: 'The selected notification will be modified',
    icon: 'warning',
    buttons: [
      'No, cancel it!',
      'Yes, I am sure!'
    ],
    dangerMode: true,
  }).then(function(isConfirm: any) {
    if ( isConfirm ) {
      swal({
        title: 'Success!',
        text: 'Notification updated',
        icon: 'success',
        timer : 2000
      }).then(function() {
        parent.apiService.editNotification(notifObj2).then(response => {
          if (response) {
              parent.viewNotificationList();
            }
          }).catch(error => { });
      });
    } else {
      swal('Cancelled', 'Action aborted :)', 'error');
    }
  });
}else if(this.notificationInfo.notificationFor!='calendar' && this.notificationInfo.notificationFor!='vehiclenotmove'){ 
  //if (this.validateUserInputs()) {
    const parent = this;
    var notifObj ={
      data: {
        key: localStorage.getItem('scbToken'),
        form: {
            notificationId: this._notificationId,
            mobileNo:this.mobileNumberList,
            email: this.emailList,
            startTime: this.notificationInfo.startTime,
            endTime: this.notificationInfo.endTime,
            allVehicles: this.selectedAll,
            vehicleLists: this.selectedVehicles,
            overSpeedval: this.notificationInfo.overSpeedval,
            mobileContact:this.mobileContact
        }
    }
    };


    swal({
      title: 'Are you sure?',
      text: 'The selected notification will be modified',
      icon: 'warning',
      buttons: [
        'No, cancel it!',
        'Yes, I am sure!'
      ],
      dangerMode: true,
    }).then(function(isConfirm: any) {
      if ( isConfirm ) {
        swal({
          title: 'Success!',
          text: 'Notification updated',
          icon: 'success',
          timer : 2000
        }).then(function() {
          parent.apiService.editNotification(notifObj).then(response => {
            if (response) {
                parent.viewNotificationList();
              }
            }).catch(error => { });
        });
      } else {
        swal('Cancelled', 'Action aborted :)', 'error');
      }
    });
  //}


}
}
// setUserSelections(param:any){
//   // console.log(param);
  
//   // this.mobileNumberList = [];

//   this.mobileNumberList = param.map(function(index:any){

//       // var val=`${index.UserMobileNo} - ${index.UserName}`;
//       // return index.UserMobileNo
//       return val;
//   });


// }

receivedCollect($event:any){
  this.collectedData = $event;
  this.mobileNumberList= Object.keys(this.collectedData)
  this.mobileNumberList = Object.keys(this.collectedData).length ? true : false;

  this.mobileNumberList = Object.keys(this.collectedData).map(item=>+item);

  let obj ={}
  Object.keys(this.collectedData).forEach((item:any,index:any)=>{   
    obj[this.collectedData[item].UserMobileNo] = this.collectedData[item].UserName
  })
  this.mobileContact = obj; 
}
receivedCollectEmail($event:any){
  this.collectedData = $event;
  this.emailList = Object.keys(this.collectedData)
}


getRegUserMobile(){
  this.apiService.getOrgMobiles({
    data:{
      key: localStorage.getItem('scbToken'),
      extra: {
        orderByDateCreated: -1
      }
    }
  }).then(res=>{
    // console.log(res)
    // this.userRegisteredMobile = res.response;

    this.userRegisteredMobile['main'] = res.response;
  })


  this.apiService.getOrgEmails({
    data:{
      key: localStorage.getItem('scbToken'),
      extra: {
        orderByDateCreated: -1
      }
    }
  }).then(res=>{
    this.userRegisteredEmail['main'] = res.response;
   
  });

}


}
/*
Notes : npm install node-sass -g
*/
