import { Component, Input, Output, HostListener, ElementRef, AfterViewInit, ViewChild } from '@angular/core';
import { ApiService } from './../../../services/ApiService';
import { chosenUtility } from './../../../services/chosen-utility/jq-chosen-utility.js';
import { StorageService } from "./../../../services/storage.service";
import { bootstrapDateTime } from './../../../services/bootstrapDateTime/datePicker';
import { GlobalUtils } from './../../../services/global-utils';
import { array_chunk } from './../../../utilities/common';
import { async } from 'rxjs/internal/scheduler/async';
import { FormControl } from '@angular/forms';
import { DatePipe } from '@angular/common'
import { ApiCallDateFormatService } from "../../../services/api-call-date-format.service";
import { IfStmt } from '@angular/compiler';
import { fromEvent, Subject, from } from "rxjs";
import { map, max } from 'rxjs/operators';
import { COM } from './../../../services/com.services';
import { ChartControllerService } from './../../../services/chartControler.service'
// import { SearchFilterComponent } from './searchFilter.component';
import {
  filter,
  debounceTime,
  distinctUntilChanged,
  tap
} from "rxjs/operators";
import { st } from '@angular/core/src/render3';
import { UtilityServices } from './../../../services/utility.service';
//import * as pako from 'pako';


declare const pako: any;
declare const $: any;
declare const Highcharts: any;
declare const Pickathing: any;

@Component({
  // template: ``,

  templateUrl: './ViewNotReachedPageComponent.component.html',
  providers: [bootstrapDateTime],
  styleUrls: ['./notReached.css'],
})
export class ViewNotReachedPageComponent {

  public getAllPP: any = {};
  public notReachedAllPPSortedDisplay: any = [];
  public timseSeriesChart: any = [];
  public timseSeriesChartObject: any = [];
  public timseSeriesChartObjectOrdered: any = [];
  public timseSeriesChartSorted: any = [];
  public isLoading: boolean;
  public showTableRowLoader: boolean = false;
  public notReachedAllPPShow: boolean = false;
  public totalPPCnt: number = 0;
  public notReachedAllPP: any = [];
  public notReachedAllPPSorted: any = [];
  public notReachedAllPPSortedModal: any = [];
  public notReachedAllModal: any = [];
  public notReachedAllModalSorted: any = [];
  public viewMore1: boolean = true;
  public finalObjForPieChartArrayGlobal: any = [];
  public notReachedAllPPSortedDisplayAll: any = [];
  public tableHideShowPie: boolean = false;
  isShow: boolean;
  public uniqueRouteNames: any = [];
  public uniqueRouteNamesBasedGrades: any = [];
  public uniqueRouteNamesBasedGradesSorted: any = [];
  public selDate: any = '';
  public selEndDate: any = '';
  public check1: boolean = false;
  public checkPopup: any = 'M3';
  public durationSelected: any = '';
  public modalRoute: any = '';
  public modalPpKey: any = '';
  public modalPickup: any = '';
  public modalCallMade: any = '';
  public modalReached: any = '';
  public modalDifference: any = '';
  globalMapObj: google.maps.Map;
  public singlePickupPointView: boolean = false;
  public enableLoader:boolean;
  public loaderMessage: any='';
  //  Raja Coded 16-Nov-2020
  public weeklyperf: any = {};
  //  {
  //   eta: '7:14 am',
  //   eta_timestamp_number: 5005,
  //   ata: '7:30am',
  //   ata_timestamp_number: 40004,
  //   time_difference: '25 min',
  //   timestamp_difference_number: 2003,
  //   isPositiveValue: false,
  //   performance: 'good'
  // }
  public allRouteInfo: any ={};
  //  {
  //   "uNPaozmVWdEAUgIvlBRC3516981528279122": {
  //   "RouteName": "1M",
  //   "date": "1581038520000",
  //   "pickupName": "Nr. Police Station Redlight, Nelson Mandela Rd, Vasant Kunj",
  //   "callMade": "6:52:0",
  //   "reachedTM": "6:59:35",
  //   "expectedTM": "0656",
  //   "diff": 3,
  //   "time_difference": "3 min",
  //   "ata": "6:59 am",
  //   "eta": "06:56 am",
  //   "performance": false,
  //   "timestamp_difference_number": 3,
  //   "location": {
  //   "lat": "28.534860687246",
  //   "lng": "77.151907682419"
  //   },
  //   "color": "avg"
  //   },
  //   "mYRTEaJiLKwOGDHcpqgo6948571528279122": {
  //   "RouteName": "1M",
  //   "date": "1581038520000",
  //   "pickupName": "Nr. Yes Bank, Kushagara School, Gate No. 2, B10, Vasant Kunj",
  //   "callMade": "6:52:0",
  //   "reachedTM": "6:59:35",
  //   "expectedTM": "0700",
  //   "diff": 0,
  //   "time_difference": "0 min",
  //   "ata": "6:59 am",
  //   "eta": "07:00 am",
  //   "performance": true,
  //   "timestamp_difference_number": 0,
  //   "location": {
  //   "lat": "28.532037691759",
  //   "lng": "77.153205871582"
  //   },
  //   "color": "good"
  //   },
  //   "qxwlAQprBSXYdkfmJFtu0124931528279122": {
  //   "RouteName": "1M",
  //   "date": "1581038520000",
  //   "pickupName": "Gate No. 01, B9, Vasant Kunj",
  //   "callMade": "6:52:0",
  //   "reachedTM": "7:6:16",
  //   "expectedTM": "0702",
  //   "diff": 4,
  //   "time_difference": "4 min",
  //   "ata": "7:06 am",
  //   "eta": "07:02 am",
  //   "performance": false,
  //   "timestamp_difference_number": 4,
  //   "location": {
  //   "lat": "28.529110933794",
  //   "lng": "77.157497406006"
  //   },
  //   "color": "avg"
  //   },
  //   "FSOqhwRIbEGmuocQJLsk0286511528279122": {
  //   "RouteName": "1M",
  //   "date": "1581038910000",
  //   "pickupName": "T Point nr. Gate No. 6, B1, Sec B, Vasant Kunj",
  //   "callMade": "6:58:30",
  //   "reachedTM": "7:10:59",
  //   "expectedTM": "0708",
  //   "diff": 2,
  //   "time_difference": "2 min",
  //   "ata": "7:10 am",
  //   "eta": "07:08 am",
  //   "performance": true,
  //   "timestamp_difference_number": 2,
  //   "location": {
  //   "lat": "28.524718287839",
  //   "lng": "77.158988714218"
  //   },
  //   "color": "avg"
  //   },
  //   "DNJeHSuPVqfmWtEcYOIi8237091528279122": {
  //   "RouteName": "1M",
  //   "date": "1581038840000",
  //   "pickupName": "Gate No. 1, B5 & 6, Sec B, Vasant Kunj",
  //   "callMade": "6:57:20",
  //   "reachedTM": "7:10:59",
  //   "expectedTM": "0707",
  //   "diff": 3,
  //   "time_difference": "3 min",
  //   "ata": "7:10 am",
  //   "eta": "07:07 am",
  //   "performance": false,
  //   "timestamp_difference_number": 3,
  //   "location": {
  //   "lat": "28.527395372825",
  //   "lng": "77.160587310791"
  //   },
  //   "color": "avg"
  //   },
  //   "DQesxJhYHUCVwufNKGzy6741351529490916": {
  //   "RouteName": "1M",
  //   "date": "1581038946000",
  //   "pickupName": "Nr. Gate no. 7, B1, Sec B, Vasant Kunj",
  //   "callMade": "6:59:6",
  //   "reachedTM": "7:10:59",
  //   "expectedTM": "0709",
  //   "diff": 1,
  //   "time_difference": "1 min",
  //   "ata": "7:10 am",
  //   "eta": "07:09 am",
  //   "performance": true,
  //   "timestamp_difference_number": 1,
  //   "location": {
  //   "lat": "28.523028602426",
  //   "lng": "77.157048940659"
  //   },
  //   "color": "good"
  //   },
  //   "GlzavDcQEmJygWKCFSNt7630941528279122": {
  //   "RouteName": "1M",
  //   "date": "1581039425000",
  //   "pickupName": "Church Red Light, Vasant Kunj",
  //   "callMade": "7:7:5",
  //   "reachedTM": "7:15:0",
  //   "expectedTM": "0717",
  //   "diff": 2,
  //   "time_difference": "2 min",
  //   "ata": "7:15 am",
  //   "eta": "07:17 am",
  //   "performance": true,
  //   "timestamp_difference_number": 2,
  //   "location": {
  //   "lat": "28.51620583702",
  //   "lng": "77.162185907364"
  //   },
  //   "color": "good"
  //   },
  //   "nFUKpBeiVCTkRbDufdZX7304691573562607": {
  //   "RouteName": "1M",
  //   "date": "1581039333000",
  //   "pickupName": "D3, Vasant Kunj",
  //   "callMade": "7:5:33",
  //   "reachedTM": "7:15:0",
  //   "expectedTM": "0715",
  //   "diff": 0,
  //   "time_difference": "0 min",
  //   "ata": "7:15 am",
  //   "eta": "07:15 am",
  //   "performance": true,
  //   "timestamp_difference_number": 0,
  //   "location": {
  //   "lat": "28.5163306",
  //   "lng": "77.1589417"
  //   },
  //   "color": "good"
  //   },
  //   "cigwQZNADjszTtMOSPqf1386591528279122": {
  //   "RouteName": "1M",
  //   "date": "1581039486000",
  //   "pickupName": "Gate No.1, Pkt B & C, Sec A, Vasant Kunj",
  //   "callMade": "7:8:6",
  //   "reachedTM": "7:20:41",
  //   "expectedTM": "0718",
  //   "diff": 2,
  //   "time_difference": "2 min",
  //   "ata": "7:20 am",
  //   "eta": "07:18 am",
  //   "performance": true,
  //   "timestamp_difference_number": 2,
  //   "location": {
  //   "lat": "28.512020061008",
  //   "lng": "77.166434526443"
  //   },
  //   "color": "good"
  //   },
  //   "nYRBSgXfxlcZPzVrNujG4036571528279122": {
  //   "RouteName": "1M",
  //   "date": "1581039120000",
  //   "pickupName": "Nr. CNG Station, B4, Vasant Kunj",
  //   "callMade": "7:2:0",
  //   "reachedTM": "7:12:39",
  //   "expectedTM": "0712",
  //   "diff": 0,
  //   "time_difference": "0 min",
  //   "ata": "7:12 am",
  //   "eta": "07:12 am",
  //   "performance": true,
  //   "timestamp_difference_number": 0,
  //   "location": {
  //   "lat": "28.521444358102",
  //   "lng": "77.167070453579"
  //   },
  //   "color": "good"
  //   }
  //   }
  public selectedReport: any;
  public sessionType: any = "Select Route";
  public searchPossionTop: boolean;
  public stDate: any;
  public enDate: any;
  public endDateError: boolean = false;
  public startDateError: boolean = false;
  public sessionErr: boolean;
  public routesDatesMisMatchError: boolean;
  public timeSeriesChart: any;
  public donutChartObj: any;
  public apiResponseData: any;
  public bobbleChart: any;
  public columnChartObj: any = [];
  public searchValue: string = '';
  public subjectObj: any = new Subject();
  public showBubbleChart: boolean = true;
  public showChartsInView: boolean;
  public maxDateErr:boolean;
  public filterDataUpdate:boolean;
  public switch_expression:boolean=true;
  public pickupPoint: boolean;
  public pickupPointLineChart:any=[];
  public pickupToLanLong={};
  public calenderChartView:any={};
  public selectedRoute:any;
  public dataNotFound: boolean= false;
  public selectedFromToDates:any=[];
  public schoolLatLng:any;
  public breakPickupPoint:any;
  constructor(
    private chosenUtility: chosenUtility,
    private apiService: ApiService,
    private bootstrapDateTime: bootstrapDateTime,
    public storageservice: StorageService,
    private globalUtils: GlobalUtils,
    public dateChange: ApiCallDateFormatService,
    public datepipe: DatePipe,
    public utilityService: UtilityServices,
    private com: COM,
    private charService: ChartControllerService
  ) {
    this.com.comAction.subscribe((res: any) => {
       
      if (res.type == 'searchSelected') {
        this.selectedRoute = res.data;
       if(res.searchType=="route") this.searchRouteFilterResult(res.data);
       if(res.searchType!="route"){
           
            this.searchByPickupPointResult(res);
       } 

      }
      if(res.type=='selectedSinglePickupPoint'){
          this.initBubbleChart(res);
      }
    })

  }
  public movementData={};
  public allRouteInfoData:any=[];
  ngOnInit() {
    this.schoolLatLng = this.storageservice.get('schoolLatLng');
    this.initChosen();
    this.check1 = true;
    $('[data-toggle="popover"]').popover({
      placement: 'bottom',
      trigger: 'hover'
    });
    $('[data-toggle="tooltip"]').tooltip();
    this.checkPopup = 'M33';
    // this.loadMovementData();
  }
  reload(e:any){
    this.notReachedOnTimeReportTop('');
    // console.log('relad',)
  }
 async initBubbleChart(res:any){
      // console.log('RES,',res);
      this.weeklyperf = {
        eta:res.data.eta,
        ata:res.data.ata,
        time_difference: res.data.time_difference,
        timestamp_difference_number: res.data.timestamp_difference_number,
        isPositiveValue: res.data.isPositiveValue,
        performance: res.data.performance,
        pickupName: res.data.pickupName
      }
      this.allRouteInfo = res.singleRoute;
      // console.log('this.weeklyperf',this.weeklyperf,'this.allRouteInfo',this.allRouteInfo)
      let session = this.storageservice.get('analyticsFilterSession');
      let sessionSplit = session.split('-');
      this.loaderMessage ='Collecting movement reports...';
    // console.log('this.weeklyperf',this.weeklyperf,' this.allRouteInfo', this.allRouteInfo)
      let tempMovement={};
      let sDate:string;
      // console.log('this.selectedFromToDates',this.selectedFromToDates);
      let finalDateValue = this.utilityService.checkWeekEnd(this.selectedFromToDates);
      // console.log('finalValue',finalDateValue)
      if(finalDateValue=='No Movement Data'){
        this.loaderMessage =  finalDateValue;
         setTimeout(()=>{this.enableLoader= false;},1500);
        }else{
          let startDate = this.datepipe.transform(finalDateValue, 'yyyy-MM-dd');
          if(tempMovement[startDate]==undefined) tempMovement[startDate]={};
          let toTms: any = res.data.reachedTM.split(':').map((item:any)=> this.pad(Number(item))).join(":");
          let apiObjData:any={
                  data:{
                  "form": {
                          "vehId": [res.vehID],
                          "fromDate": startDate,
                          "fromTime": sessionSplit[0],
                          "toDate": startDate,
                          "toTime": toTms,
                          "speed": {
                            "movement": {
                              "value": 5
                            }
                          }
                    },
                    "extra": {
                      "pageJump": 0
                    },
                    key: localStorage.getItem('scbToken') 
                  }       
              }
              let report = await this.movementReportAPICall(apiObjData);
              tempMovement[startDate] = report;
          // this.movementData = report;        
          // if(d==this.selectedFromToDates.length-1){
            this.loaderMessage ='Completed...!';            
            setTimeout(()=>{
                this.enableLoader= false;
                this.loaderMessage ='';
                this.breakPickupPoint = res.data
                // console.log(sDate,'this.selectedFromToDates',this.selectedFromToDates)
                this.movementData = tempMovement[startDate];
                this.allRouteInfoData=[this.allRouteInfo,res.data];
                this.singlePickupPointView = res.changeComp;
            },1000);
      // }
    }
      //   for(let d=0;d<this.selectedFromToDates.length;d++){
    //     let date = this.selectedFromToDates[d];
    //     let startDate = this.datepipe.transform(date, 'yyyy-MM-dd');
    //     if(d==0) sDate= startDate;
    //     // if(this.movementData[startDate]==undefined) this.movementData[startDate]={};
    //     if(tempMovement[startDate]==undefined) tempMovement[startDate]={};
    //     let toTms: any = res.data.reachedTM.split(':').map((item:any)=> this.pad(Number(item))).join(":");
    //     let apiObjData:any={
    //       data:{
    //       "form": {
    //               "vehId": [res.vehID],
    //               "fromDate": startDate,
    //               "fromTime": sessionSplit[0],
    //               "toDate": startDate,
    //               "toTime": toTms,
    //               "speed": {
    //                 "movement": {
    //                   "value": 5
    //                 }
    //               }
    //         },
    //         "extra": {
    //           "pageJump": 0
    //         },
    //         key: localStorage.getItem('scbToken') 
    //       }       
    //   }
    //   // console.log(apiObjData);
    //   let report = await this.movementReportAPICall(apiObjData);
    //   // this.movementData[startDate] = report;
    //   tempMovement[startDate] = report;
    //   // this.movementData = report;        
    //   if(d==this.selectedFromToDates.length-1){
    //     this.loaderMessage ='Completed...!';
    //     this.enableLoader= false;
    //     setTimeout(()=>{
    //       this.loaderMessage ='';
    //       this.breakPickupPoint = res.data
    //       // console.log(sDate,'this.selectedFromToDates',this.selectedFromToDates)
    //        this.movementData = tempMovement[sDate];
    //       this.allRouteInfoData=[this.allRouteInfo,res.data];
    //             this.singlePickupPointView = res.changeComp;
    //     },1000)
       
    //       // console.log(this.movementData,startDate)
    //        /**
    //         * [orgLocation]="schoolLatLng" 
    //         * [allRouteInfo]="[allRouteInfo,breakPickupPoint]" 
    //         * [weeklyperf]="weeklyperf" 
    //         * [movementReport]="movementData" 
    //         * [breakPickupPoint]="breakPickupPoint"
    //         */
    //       //console.log('this.move', this.movementData,'this',this.schoolLatLng);
    //   }
    //  }
  }
  pad(num:any){
    return num>9?num:'0'+num;
 }
  movementReportAPICall(obj:any){
    return new Promise((resolve,reject)=>{
                let temp:any =[];
                let recurcive = ()=>{
                     this.apiService.getCommonReport(obj).then((res:any)=>{
                     
                        if(res.response==null){
                          resolve(temp);
                        }else{
                          temp = temp.concat(res.response);
                            obj['data']['extra']['pageJump']+=1;
                           setTimeout(()=>{ recurcive();},1000) 
                        }
                     });
                }

                recurcive();
    })
  }
  // async loadMovementData(){
  //   let dates = ['2020-01-20',"2020-01-21","2020-01-22","2020-01-23"];
  //   for(let d=0;d<dates.length;d++){
  //       let arrLen = dates[d].split('-');
  //       let date = arrLen[arrLen.length-1];
  //       // this.movementAPI(dates[d])

  //   }
     
  // }
  // movementAPI(date:any){
  //   return new Promise(async(res,rej)=>{
  //         let apiObj= {
  //           data:{
  //             form:{
  //               fromDate: date,
  //               fromTime: "06:00:00",
  //               toDate: date,
  //               toTime: "09:00:00",
  //               vehId: ["1527504896265SNSGHR55AB3514"]
  //             },
  //             extra: {
  //               pageJump: this.pageJum
  //             },
  //             key: "Nqwx7YAdzwFhAOq5__bR__oHnMQEpRG5FezPRxCNxrdrJoI4XyytVOBHQCEVHiyXPyqvklQ2nO4yPtqp4uu7dTPEg4QYG67__8AiJtXnocWYjm9ZBhFOirrWDW0hjgsO5wb928ItPyUuTUtsDhJVUY0O21fCO__6uf4aMYWCGpldpW6tcTKjfI__77L__6jBZ5D5VbThzWHXzeAB062S8j4Q9iHtXdFYbr7FjGlcq4ZtrNdyKRk7EqbsU2InZLvLe6dQI__JPgBzfiNHhD4pLY5k0hJGc5D7AHrTbJzZ6Y7al2SZpsiQjWkgb3TLsg10xyzHdJtkAKf0"
  //           }
  //         }
  //      let response = await this.apiService.getCommonReport(apiObj);
       
  //  });
  // }

  initChosen() {
    this.initJqUtils(undefined, 'routeHistoryStartDate',
      'routeHistoryEndDate', undefined);
  }
  //Jquery util for change event start.

  initJqUtils(eleChosenId?: string, eleReportStartTime?: string, eleReportEndTime?: string, pickerConfig?: any) {
    let parent = this;
    let timePicker: boolean;
    let timeFormat: any;
    let closeOnDateSelect: boolean;
    if ((pickerConfig) && (Object.keys(pickerConfig).length)) {
      timePicker = pickerConfig.timePicker;
      timeFormat = pickerConfig.timeFormat;
      closeOnDateSelect = true;
    } else {
      timePicker = false;
      timeFormat = 'd-m-Y';
      closeOnDateSelect = false;
    }
    setTimeout(() => {
      if (eleChosenId) this.chosenUtility.initializeChosen(eleChosenId);
      if (eleReportStartTime) {
        parent.bootstrapDateTime.datetime({
          component: eleReportStartTime,
          closeOnDateSelect: closeOnDateSelect
        });
      }
      if (eleReportEndTime) {
        parent.bootstrapDateTime.datetime({
          component: eleReportEndTime,
          closeOnDateSelect: closeOnDateSelect
        });
      }
    }, 150);
  }
  
  dateDiff(ds1:any, ds2:any) {
    // To set two dates to two variables
    var date1 = new Date(ds1); //new Date("06/30/2019");
    var date2 = new Date(ds2); //new Date("07/30/2019");  
    // To calculate the time difference of two dates
    var Difference_In_Time = date2.getTime() - date1.getTime();  
    // To calculate the no. of days between two dates
    var Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24);
    //To display the final no. of days (result)
    return Difference_In_Days;
  }
  YYMMDD(date:any) {
    
    let ret =  date.split("-")[2] + "/" + date.split("-")[1] + "/" + date.split("-")[0];
    return ret;
  }
  isValid(sDate: string, eDate: string) {
    let isValid = true;
    let startSplit = sDate.split('-');
    let endSplit = eDate.split('-');
    let startMMDDYY = startSplit[1] + '/' + startSplit[0] + '/' + startSplit[2];
    let endMMDDYY = endSplit[1] + '/' + endSplit[0] + '/' + endSplit[2];
    let endTimeStamp = new Date(endMMDDYY).getTime();
    let startTimeStamp = new Date(startMMDDYY).getTime();
    if (sDate == '' || sDate == undefined || sDate == null) {
      isValid = false;
      this.startDateError = true;
    } else {
      this.startDateError = false;
    }
    if (eDate == '' || eDate == undefined || eDate == null) {
      isValid = false;
      this.endDateError = true;
    } else {
      this.endDateError = false;
    }
    if ((startTimeStamp.toString() > endTimeStamp.toString())) {
      isValid = false;
      this.routesDatesMisMatchError = true;
    } else {
      this.routesDatesMisMatchError = false;
    }
    if (this.sessionType == '' || this.sessionType == 'Select Route') {
      isValid = false;
      this.sessionErr = true;
    } else {
      this.sessionErr = false;
    }
   let maxDate = this.dateDiff(this.YYMMDD(sDate.trim()),this.YYMMDD(eDate.trim()));
   if(maxDate>30){
      isValid= false;
      this.maxDateErr=true;
   }else this.maxDateErr=false;
   return isValid;
  }
  notReachedOnTimeReport(e: any) {

    this.notReachedAllPPShow = false;
    let reportStartDate = this.bootstrapDateTime.getInputvalue('routeHistoryStartDate')
    let reportEndDate = this.bootstrapDateTime.getInputvalue('routeHistoryEndDate')
    this.enDate = reportEndDate;
    this.stDate = reportStartDate;
    if (this.isValid(reportStartDate, reportEndDate)) {
      this.filterFunctioncallMadenrOnTime(reportStartDate.split(' ')[0],
        reportEndDate.split(' ')[0], () => { this.searchPossionTop = true; });
      this.initJqUtils(undefined, 'routeHistoryStartDateTop',
        'routeHistoryEndDateTop', undefined);
    }
  }
  notReachedOnTimeReportTop(e: any) {

    this.notReachedAllPPShow = false;
    let reportStartDate = this.bootstrapDateTime.getInputvalue('routeHistoryStartDateTop')
    let reportEndDate = this.bootstrapDateTime.getInputvalue('routeHistoryEndDateTop')
    this.enDate = reportEndDate;
    this.stDate = reportStartDate;
    if (this.isValid(reportStartDate, reportEndDate)) {
      this.filterFunctioncallMadenrOnTime(reportStartDate.split(' ')[0],
        reportEndDate.split(' ')[0], () => { this.searchPossionTop = true; });
    }
  }// this.selectedRoute='';

  async searchRouteFilterResult(data: any) {
    let areaChartData = await this.charService.filterSearchKeyAreaChart(this.apiResponseData, this.getAllPP, data);
    let donutChartData = await this.charService.filterSearchKeyDonutChart(areaChartData,data, this.apiResponseData);
    let columCharData = await this.charService.filterSearchKeyColumnChart(data,this.apiResponseData,areaChartData)
    this.columnChartObj=[];
    this.donutChartObj=[];
    this.timeSeriesChart=[];
    this.pickupPoint = false;
    setTimeout(()=>{     
      this.filterDataUpdate = true;
      this.columnChartObj= columCharData;
      this.donutChartObj = donutChartData;
      this.timeSeriesChart = areaChartData['areaChart'][data];
      
      // console.log('timeSeriesChart',this.timeSeriesChart ,this.timeSeriesChart[data]);
    },200); 
  }

  async searchByPickupPointResult(data:any){
          this.enableLoader= true;
          //  console.log('this.searchByPickupPointResult',data);
           let routeInfo = this.storageservice.get('chartRouteInfo');         
          this.loaderMessage = 'Getting Vehicle Information';
          let routeData= routeInfo[data.routeName][data.data];
          let date = this.timeStampDateToHumanRead(Number(routeData.date),'-');
          let vehIdObj = {
            data:{
                filter:{ routeId: routeData.routeID },
                form: {
                    fromDate: date.split(' ')[0]
                },
                key: localStorage.getItem('scbToken') 
            }
        } 
  
         let vehiID =  await this.charService.getRouteVehicleInfo(vehIdObj);
        let bubbleObj={
           changeComp: true,
           data: routeData,
           singleRoute: Object['values'](routeInfo[data.routeName]),
           type: "selectedSinglePickupPoint",
           vehID: vehiID
        } 
        // console.log('routeInfo[data.routeName]',Object['values'](routeInfo[data.routeName]),routeInfo);
       this.initBubbleChart(bubbleObj);
      let areaChartData = await this.charService.areaChartFilterPickupPoint(data.data,this.apiResponseData, this.getAllPP);
      this.timeSeriesChart=[];
      setTimeout(()=>{
        this.pickupPoint = true;      
        this.pickupPointLineChart= areaChartData['lineChar'];
        this.calenderChartView = areaChartData['calender'];
        
      },300);
    }
  async filterFunctioncallMadenrOnTime(dateSelected: string,
    endSelectedDate: string, calback: any) {
      this.showChartsInView = false;
      this.pickupPoint = false;
      this.selectedRoute='';
    calback();
    let sessionTypes = this.sessionType;
    let fromTime: any = '';
    let toTime: any = '';
    if (sessionTypes == 'Morning') {
      fromTime = '05:00:00';
      toTime = '10:59:00';
    }
    if (sessionTypes == 'Afternoon') {
      fromTime = '11:00:00';
      toTime = '14:00:00';
    }
    if (sessionTypes == 'Evening') {
      fromTime = '14:30:00';
      toTime = '18:30:00';
    }
    this.storageservice.set({'analyticsFilterSession':fromTime+'-'+toTime});
    var fields = dateSelected.split(/-/);
    var fields1 = endSelectedDate.split(/-/);
    var selectedDate = fields[2] + "-" + fields[1] + "-" + fields[0];
    var selectEndDate = fields1[2] + "-" + fields1[1] + "-" + fields1[0];
    var startDate = new Date(selectedDate); //YYYY-MM-DD
    var endDate = new Date(selectEndDate); //YYYY-MM-DD
    var dateArr = this.getDateArray(startDate, endDate);
    //let obj = {};
    this.selectedFromToDates = dateArr;
    this.isLoading = true;
    this.showTableRowLoader = true;
    this.notReachedAllPPShow = true;
    let dateArray: any = [];
    let apiHeader = {
      data: {
        key: localStorage.getItem('scbToken'),
        "form": {
          "fromTime": fromTime,
          "toTime": toTime
        }
      }
    }
    this.apiService.getAllPPET(apiHeader).then(async response => {
      let stringToObject = await this.unCompressData(response);
      try {
        // console.log('stringToObject',stringToObject)
        let parent = this;
        Object.keys(stringToObject).forEach(function (key){
          let splitData = stringToObject[key].split('#$#');
          let ppName = splitData[1];
          if (parent.getAllPP[key] == undefined) parent.getAllPP[key] = {};
          if(parent.pickupToLanLong[key]==undefined) parent.pickupToLanLong[key]={}
          parent.getAllPP[key] = ppName;
          parent.pickupToLanLong[key] = splitData[splitData.length-1];
        });
      } catch (e) {
        console.log('Error', e);
      }
      // this.getAllPP = [...this.getAllPP, ...infoPPDetails];
      // console.log('parent.getAllPP',this.getAllPP)
    }).catch(error => { })

    let res: any = await this.callReachedTimeAPI(dateArr, fromTime, toTime);
    this.changeIndianTimeZone();
    if(Object.keys(res.response).length>0){
      this.dataNotFound =false;
      setTimeout(() => {
        this.apiResponseData = res;
        // console.log('RESponce',this.showChartsInView,res);
        this.loadDonutChart(res.response);
        this.loadAreaChart(res.data, res.response);
        this.loadColumnChart(res);
        this.bobbleChart = res;
        this.notReachedAllPPShow = false;
        this.showChartsInView = true;
      }, 2000);
    }else{
       this.dataNotFound=true;
       this.notReachedAllPPShow = false;
    }
    
  


  }
  changeIndianTimeZone() {
    Highcharts.setOptions({
      global: {
        useUTC: false
      }
    });
  }
  
  callReachedTimeAPI(dateArr: any, fromTime: string, toTime: string) {
    return new Promise(async (res, rej) => {
      let responce: any = {};
      let compressData: any = {};
     
      let dateTMS = (tms:any,Rtms:any) => { 
        let d:any =new Date(Number(Rtms)); 
        let date =
        d.getFullYear() + "/" + parseInt(d.getMonth() + 1) + "/" + d.getDate();
        return new Date(date + " " + tms).getTime();
      };
      for (let d = 0; d < dateArr.length; d++) {
        //dateArr.forEach(async (date: any,i:any)=>{
        let date = dateArr[d];
        let startDate = this.datepipe.transform(date, 'yyyy-MM-dd');
        let fromDate: any = startDate;
        let toDate: any = startDate;
        let apiHeader = {
          data: {
            key: localStorage.getItem('scbToken'),
            "form": {
              "fromDate": fromDate,
              "fromTime": fromTime,
              "toDate": fromDate,
              "toTime": toTime
            }
          }
        };
        let result = await this.apiService.getAllPPReachedTime(apiHeader);//.then(async response => { 
        if(result.response!=null){
        let obj = await this.unCompressData(result);
        // responce[fromDate] = obj;
        // console.log('unCompressData',apiHeader,obj)
        if (compressData[fromDate] == undefined) compressData[fromDate] = {};
        compressData[fromDate] = obj;
        var fields = fromDate.split(/-/);
        let allRouteNames: any = [];
        let parent = this;
        var nameMonth = fields[1] + "/" + fields[2] + "/" + fields[0];
        try {
          
          Object.keys(obj).map((reachTime: any, j) => {
            let splitData = obj[reachTime].split('#$#');
            let reachedTime = splitData[0];
            let etaTime = splitData[1];
            let callMadeTime = splitData[2];
            let routeName = splitData[4];
            allRouteNames.push(routeName);
            let eta = dateTMS(etaTime.slice(0, 2) + ':' + etaTime.slice(2, 4),reachedTime);
            var differTimeCallReached = parent.difftimestamp(Number(eta), Number(reachedTime), 'time_str_no_mins');
            var intDifferenceCallReached = Number(differTimeCallReached);
            if (responce[reachTime] == undefined) responce[reachTime] = {};
            responce[reachTime][fromDate] = intDifferenceCallReached;
            if (((dateArr.length - 1) == d) && ((Object.keys(obj).length - 1) == j)) {
              let retObj = { response: responce, data: compressData,obj:obj };
              res(retObj);
            }

          });
        } catch (e) {
          console.log('Error', e)
        }
      }else{
          res({response:responce})
      }

      }//);
    });
  }
  findArraySum(array: any) {
    return new Promise((res, rej) => {
      let sum = array.reduce(function (a: any, b: any) {
        return a + b;
      }, 0);
      res(sum);
    });
  }
  unCompressData(response: any) {
    return new Promise((res, rej) => {
      try {
        var strData = atob(response.response);
        var charData = strData.split('').map(function (x) { return x.charCodeAt(0); });
        var binData = new Uint8Array(charData);
        var data = pako.inflate(binData);
        var strData: string = String.fromCharCode.apply(null, new Uint16Array(data));
        var stringToObject = JSON.parse(strData);
        res(stringToObject);
      } catch (e) {
        rej({})
      }
    });
  }
  getAverageTime(times: any) {
    var count = times.length
    var timesInSeconds = [];
    // loop through times
    for (var i = 0; i < count; i++) {
      // parse
      var pieces = times[i].split(':');
      var ampm = pieces[2].split(' ');
      var hrs = Number(pieces[0]);
      var mins = Number(pieces[1]);
      var secs = Number(ampm[0]);
      var ampm = ampm[1];
      // convert to 24 hr format (military time)
      if (ampm == 'PM') hrs = hrs + 12;
      // find value in seconds of time
      var totalSecs = hrs * 60 * 60;
      totalSecs += mins * 60;
      totalSecs += secs;
      // add to array
      timesInSeconds[i] = totalSecs;
    }
    // find average timesInSeconds
    var total = 0;

    for (var j = 0; j < count; j++) {
      total = total + Number(timesInSeconds[j]);
    }
    var avg = Math.round(total / count);

    // turn seconds back into a time
    var avgMins = Math.floor(avg / 60);

    var avgSecs = avg - (60 * avgMins);
    var avgHrs = Math.floor(avgMins / 60);

    avgMins = avgMins - (60 * avgHrs);
    // convert back to 12 hr. format
    var avgAmpm = 'AM';
    if (avgHrs > 12) {
      avgAmpm = 'PM';
      avgHrs = avgHrs - 12;
    }
    // add leading zeros for seconds, minutes
    let secondsNo: any = '';
    let minutesNo: any = '';
    secondsNo = ('0' + avgSecs).slice(-2);
    minutesNo = ('0' + avgMins).slice(-2);
    if (avgHrs < 10) {
      return ('0' + avgHrs) + ':' + minutesNo + ':' + secondsNo;
    } else {
      return avgHrs + ':' + minutesNo + ':' + secondsNo;
    }
  }
  getDateArray(start: any, end: any) {
    var arr = new Array();
    var dt = new Date(start);
    while (dt <= end) {
      arr.push(new Date(dt));
      dt.setDate(dt.getDate() + 1);
    }
    return arr;
  }
  difftimestamp(time1: number, time2: number, flag: string) {
    let time;

    time = Math.abs((time1 / 1000) - (time2 / 1000));
    time = Math.floor(Math.floor(time) / 60);
    if (flag === 'grade') {
      if (time <= 4) {
        return " C ";
      }
      if (time >= 5 && time <= 10) {
        return " A ";
      }
      if (time >= 11 && time <= 15) {
        return " B ";
      }
      if (time > 15) {
        return " D ";
      }

    }
    if (flag === 'time') {
      return time;
    }
    if (flag === 'time_str') {

      return time <= 1 ? time + ' min' : time + ' mins';

    }
    if (flag === 'time_str_no_mins') {

      return time;
    }

  }
  loadDonutChart(obj: any) {
    let avgPickupPoint: any = {};
    let pickupKeys = Object.keys(obj);
    pickupKeys.map(async (pickupID: any, i) => {
      let pickupPoints: any = obj[pickupID];
      let pickPointSum: any = await this.findArraySum(Object["values"](pickupPoints));
      let totKeysLen = Object.keys(pickupPoints).length;
      if (avgPickupPoint[pickupID] == undefined) avgPickupPoint[pickupID] = {}
      avgPickupPoint[pickupID] = Math.round(pickPointSum / totKeysLen);
      if (pickupKeys.length - 1 == i) {
        let performanceResult = await this.charService.findPickupPointPerformance(avgPickupPoint);
        let donutChart = [
          {
            name: 'GOOD',
            y: Math.round(performanceResult['overallPickPoint'].good / pickupKeys.length * 100),
            totCnt: performanceResult['overallPickPoint'].good,
            color: '#a1b126',
            sliced: false
          },
          {
            name: 'AVG',
            y: Math.round(performanceResult['overallPickPoint'].avg / pickupKeys.length * 100),
            totCnt: performanceResult['overallPickPoint'].avg,
            color: '#ef870d',
            sliced: false
          },
          {
            name: 'BAD',
            y: Math.round(performanceResult['overallPickPoint'].bad / pickupKeys.length * 100),
            totCnt: performanceResult['overallPickPoint'].bad,
            color: '#e03e03',
            sliced: true
          }
        ];
        this.donutChartObj = donutChart;
      }
    });
  }
  sortFunctionTimeSeries(a: any, b: any) {
    var dateA = new Date(Number(a.date));
    var dateB = new Date(Number(b.date));
    return dateA > dateB ? 1 : -1;

  }
  timeStampToHumanRead(TMS: number) {
    let date:any = new Date(TMS);
    return date.getHours() + ':' + date.getMinutes() + ':' + date.getSeconds();
  }
  timeStampDateToHumanRead(TMS:any,concat: string) {
    let date = new Date(TMS);
    return date.getFullYear()+concat+ this.pad(Number(date.getMonth()+1)) +concat+ this.pad(date.getDate())
  }
  sortValue(arr: any, type: string, key: string) {
    let obj = {};
    let res = arr.sort((a: any, b: any) => {
      let k1: any = Object.keys(a);
      let k2: any = Object.keys(b);
      if (type == "asc") return a[k1][key] > b[k2][key] ? 1 : -1;
      else return a[k1][key] < b[k2][key] ? 1 : -1;
    });
    return res;
  }
  loadColumnChart(res: any) {
    let keys = Object.keys(res.data);
    let obj = res.data;
    let TMSObj = {};
    let roundTime = (time: any, minutesToRound: number) => {

      let [hours, minutes] = time.split(':');
      hours = parseInt(hours);
      minutes = parseInt(minutes);
      // Convert hours and minutes to time in minutes
      time = (hours * 60) + minutes;
      let rounded = Math.round(time / minutesToRound) * minutesToRound;
      let rHr: any = '' + Math.floor(rounded / 60)
      let rMin: any = '' + rounded % 60
      return rHr.padStart(2, '0') + ':' + rMin.padStart(2, '0')
    }
    let dateTMS = (tms:any,Rtms:any) => { 
      let d:any =new Date(Number(Rtms)); 
      let date =
      d.getFullYear() + "/" + parseInt(d.getMonth() + 1) + "/" + d.getDate();
      return new Date(date + " " + tms).getTime();
    };
    for (let date in obj) {
      for (let pp in obj[date]) {
        let good = 0, avg = 0, bad = 0;
        let data = obj[date][pp].split('#$#');
        let callMadeTM = data[2];
        let expectedTM = data[1];
        let exTMS = roundTime(expectedTM.slice(0, 2) + ':' + expectedTM.slice(2, 4), 5);
        let reachedTM = data[0];
        if (TMSObj[exTMS] == undefined) TMSObj[exTMS] = {};
        let expectedTime = dateTMS(exTMS,callMadeTM); 
        let timeDiff = this.difftimestamp(expectedTime, Number(reachedTM), 'time_str_no_mins');
        if (TMSObj[exTMS]['good'] == undefined) TMSObj[exTMS]['good'] = 0;
        if (TMSObj[exTMS]['bad'] == undefined) TMSObj[exTMS]['bad'] = 0;
        if (TMSObj[exTMS]['avg'] == undefined) TMSObj[exTMS]['avg'] = 0;
        if (timeDiff >= -2 && timeDiff <= 2) good++;
        if ((timeDiff > -5 && timeDiff <= -3) || (timeDiff >= 3 && timeDiff < 5)) avg++;
        if (timeDiff <= -5 || timeDiff >= 5) bad++;
        if (bad == 1) TMSObj[exTMS]['bad'] += 1;
        if (good == 1) TMSObj[exTMS]['good'] += 1;
        if (avg == 1) TMSObj[exTMS]['avg'] += 1;
      }
      let pad = (num: number) => {
        return num > 9 ? num : "0" + num;
      };
      if (keys.indexOf(date) == keys.length - 1) {
        // console.log('table-view --- date',date,TMSObj)
        let objKeys = Object.keys(TMSObj);
        let tms = (arr: any) => {
          return arr.map((itm: any, i: any) => {
            let m: any = Number(itm.slice(3, 5));
            let h: any = Number(itm.slice(0, 2));
            let temp: any = 0;
            if (m == 0)
              temp = pad(parseInt(h) - 1) + ":" + pad(60 - 2) + " - " + pad(h) + ":" + pad(2);
            else
              temp = pad(h) + ":" + pad(m - 2) + " - " + pad(h) + ":" + pad(parseInt(m + 2));
            //  console.log('retArray',itm);
            let tmpObj = {};
            tmpObj[temp] = TMSObj[itm];
            return tmpObj;
          });
        }
        let finalArray = tms(objKeys);
        // console.log('finalArray', finalArray)
        let sortedVal: any = this.sortValue(finalArray, 'desc', 'bad');

        //   this.finalData = sortedVal;
        this.columnChartObj = {};
        let category = [];
        let bad = [], avg = [], good = [];
        for (let i in sortedVal) {
          let int: any = i;
          let key = Object.keys(sortedVal[i])[0];
          category.push(key);
          good.push(sortedVal[i][key]['good']);
          avg.push(sortedVal[i][key]['avg']);
          bad.push(sortedVal[i][key]['bad']);
          //  console.log((sortedVal.length-1)==int)
          if ((sortedVal.length - 1) == int) {
            if (this.columnChartObj['category'] == undefined)
              this.columnChartObj['category'] = [];
            if (this.columnChartObj['series'] == undefined)
              this.columnChartObj['series'] = [];

            this.columnChartObj['category'] = category;
            this.columnChartObj['series'].push(
              {
                name: 'Bad',
                data: bad,
                color: '#e03e03'
              });
            this.columnChartObj['series'].push({
              name: 'Good',
              data: good,
              color: '#a1b126'
            });
            this.columnChartObj['series'].push({
              name: 'Average',
              data: avg,
              color: '#ef870d'
            });
          }
        }
      }
    }
  }
  async loadAreaChart(obj: any, responce: any) {
    // console.log('Area chart Load ', obj);
    let callMadeAvg = {};
    let reachedTMAvg = {};
    let expectedTMS = {};
    let finalData: any = [];
    let keys: any = Object.keys(obj);
    let lastInd: any = keys[keys.length - 1];
    let avgPickupPoint: any = {};
    for (let date in obj) {
      // if(callMadeAvg[date]==undefined) callMadeAvg[date]={};
      for (let p in obj[date]) {
        let data = obj[date][p].split('#$#');
        let callMadeTM = data[2];
        let expectedTM = data[1];
        let reachedTM = data[0];
        let pickupPoints: any = responce[p];
        let pickPointSum: any = await this.findArraySum(Object["values"](pickupPoints));
        let totKeysLen = Object.keys(pickupPoints).length;
        if (avgPickupPoint[p] == undefined) avgPickupPoint[p] = {};
        // if(avgPickupPoint[p]['avg']==undefined) avgPickupPoint[p]['avg'] ={}

        let avgReachedTriggeres = Math.round(pickPointSum / totKeysLen);
        avgPickupPoint[p] = {
          avg: avgReachedTriggeres,
          data: data
        };
        // if(callMadeAvg[date][p]==undefined) callMadeAvg[date][p]=[];
        if (callMadeAvg[p] == undefined) callMadeAvg[p] = [];
        if (reachedTMAvg[p] == undefined) reachedTMAvg[p] = [];
        if (expectedTMS[p] == undefined) expectedTMS[p] = [];
        var callMadeMSDate = new Date(parseInt(callMadeTM, 10));
        let callMadeDateFormatted = this.datepipe.transform(callMadeMSDate.toString(), 'HH:mm:ss');
        var reachedMSDate = new Date(parseInt(reachedTM, 10));
        let reachedDateFormatted = this.datepipe.transform(reachedMSDate.toString(), 'HH:mm:ss');
        reachedTMAvg[p].push(reachedDateFormatted);
        callMadeAvg[p].push(callMadeDateFormatted);
        expectedTMS[p].push(expectedTM.slice(0, 2) + ':' + expectedTM.slice(2) + ':00');
      }
      if ((keys.indexOf(date)) == (keys.length - 1)) {
        let pKeys = Object.keys(callMadeAvg);
        pKeys.map((pID: any, i: number) => {
          let callMade = callMadeAvg[pID];
          let calMadeAvgGrade = this.findGrade(avgPickupPoint[pID]['avg']);
          let callMadeAvgVal = this.getTimeAvgValue(this.getAverageTime(callMade));
          let reachedTimeDate = this.getAverageTime(reachedTMAvg[pID]);
          let expectedTimeDate = this.getAverageTime(expectedTMS[pID]);
          let callMadHM = this.timeStampToHumanRead(callMadeAvgVal);
          
          finalData.push({
            date: Number(callMadeAvgVal),
            value: Number(calMadeAvgGrade['giveGrade']),
            pickupName: this.getAllPP[pID],
            callMade: callMadHM,
            reachedTM: reachedTimeDate,
            expectedTM: expectedTimeDate,
            diff: avgPickupPoint[pID]['avg'],
            RouteName: avgPickupPoint[pID]['data'][4],
           
          })
          if (pKeys.length - 1 == i) {
            let areaChartObj = finalData.sort(this.sortFunctionTimeSeries)
            this.timeSeriesChart = areaChartObj;
            // console.log('timeSeriesCharttimeSeriesChart',this.timeSeriesChart)
          }

        });
      }
    }
  }

  getTimeAvgValue(dateValue: any) {
    let splitcallMadeAvg = dateValue.split(':');
    var mergeHourMins = ' ' + splitcallMadeAvg[0] + ':' + splitcallMadeAvg[1] + ':' + splitcallMadeAvg[2];
    let dummyDate = '02/06/2020';
    let etaTimeDate = dummyDate.concat(mergeHourMins);
    var date = new Date(etaTimeDate);
    var etamilliseconds = date.getTime();
    return etamilliseconds;
  }

  findGrade(avgReachedTriggeres: number) {
    // Start 
    let giveGrade = 0, tableGrades = '';
    if ((avgReachedTriggeres >= 5) && (avgReachedTriggeres <= 10)) {
      giveGrade = (0 / 10) * 100;
      tableGrades = 'GOOD';
    }
    if ((avgReachedTriggeres === 4) || (avgReachedTriggeres === 11)) {
      giveGrade = (1 / 10) * 100;
      tableGrades = 'GOOD';
    }

    if ((avgReachedTriggeres === 3) || (avgReachedTriggeres === 12)) {
      giveGrade = (2 / 10) * 100;
      tableGrades = 'GOOD';
    }
    //GOOD ENDS HERE

    //AVERAGE STARTS HERE

    if ((avgReachedTriggeres === 2) || (avgReachedTriggeres === 13)) {
      giveGrade = (3 / 10) * 100;
      tableGrades = 'AVG';
    }
    if (avgReachedTriggeres === 14) {
      giveGrade = (4 / 10) * 100;
      tableGrades = 'AVG';
    }
    if (avgReachedTriggeres === 15) {
      giveGrade = (5 / 10) * 100;
      tableGrades = 'AVG';
    }

    //BAD STARTS HERE
    if (avgReachedTriggeres === 16) {
      giveGrade = (6 / 10) * 100;
      tableGrades = 'BAD';
    }

    if (avgReachedTriggeres === 17) {
      giveGrade = (7 / 10) * 100;
      tableGrades = 'BAD';
    }
    if (avgReachedTriggeres === 18) {
      giveGrade = (8 / 10) * 100;
      tableGrades = 'BAD';
    }
    if ((avgReachedTriggeres === 19)) {
      giveGrade = (9 / 10) * 100;
      tableGrades = 'BAD';
    }
    if ((avgReachedTriggeres < 2)) {
      giveGrade = (10 / 10) * 100;
      tableGrades = 'BAD';
    }
    if ((avgReachedTriggeres >= 20)) {
      giveGrade = (10 / 10) * 100;
      tableGrades = 'BAD';
    }

    return ({ giveGrade: giveGrade, tableGrades: tableGrades });
  }
  // callTimeSeriesChart(timseSeriesChart: any) {


  //   Highcharts.chart('timeSeriesChart', {
  //     chart: {
  //       zoomType: 'x'
  //     },
  //     title: {
  //       text: ''
  //     },
  //     credits: {
  //       enabled: false
  //     },
  //     // time: {
  //     //     timezone: 'Asia/Kolkata'
  //     // },
  //     xAxis: {
  //       ordinal: false,
  //       type: 'datetime',
  //       title: {
  //         text: '',
  //         align: 'high',
  //         style: {
  //           color: '#6D869F',
  //           fontSize: '10px'
  //         }
  //       },
  //       dateTimeLabelFormats: {
  //         second: '%H:%M:%S',
  //         minute: '%H:%M',
  //         hour: '%H:%M',
  //         day: '%b. %e',
  //         week: '%b. %e',
  //         month: '%b. %y',
  //         year: '%Y'
  //       }
  //     },
  //     yAxis: {
  //       title: {
  //         text: 'Grade'
  //       },
  //       min: 0,
  //       max: 120,
  //     },
  //     tooltip: {
  //       crosshairs: true,
  //       pointFormat: 'Pickup Name: <b>{point.pickupName}</b><br/>' + 'Exp Time: <b>{point.expectedTM}</b><br/>' + 'Call Triggered: <b>{point.callMade}</b><br/>' + 'Reached: <b>{point.reachedTM}</b><br/>' + 'Difference(Mins): <b>{point.diff}</b><br/>' + 'Grade: <b>{point.y}</b><br/>',
  //       // pointFormat: 'Pickup Name: <b>{point.pickupName}</b><br/>' + 'Call Triggered: <b>{point.callMade}</b><br/>'+'Difference(Mins): <b>{point.diff}</b><br/>'+ 'Grade: <b>{point.y}</b><br/>',
  //       shared: true
  //     },
  //     legend: {
  //       enabled: false
  //     },
  //     plotOptions: {
  //       area: {
  //         fillColor: {
  //           linearGradient: [0, 0, 0, 300],
  //           stops: [
  //             // [0, Highcharts.getOptions().colors[0]],
  //             //[1, Highcharts.Color(Highcharts.getOptions().colors[0]).setOpacity(0).get('rgba')]
  //             [0, 'rgb(255, 25, 25)'],
  //             [1, 'rgb(255, 230, 230)']
  //           ]
  //         },
  //         // marker: {
  //         //     radius: 2
  //         // },
  //         marker: {
  //           enabled: false,
  //           symbol: 'circle'
  //           //-----continue with other properties----
  //         },
  //         lineWidth: 0,
  //         states: {
  //           hover: {
  //             //lineWidth: null
  //             enabled: true
  //           }
  //         },
  //         // turboThreshold: 0
  //         //threshold: null
  //       },
  //       legend: {
  //         enabled: false
  //       },
  //       series: {
  //         animation: {
  //           duration: 3000
  //         }
  //       }
  //     },

  //     series:
  //       [{
  //         type: 'area',
  //         //  name: 'USD to EUR',
  //         data: timseSeriesChart,
  //         marker: {
  //           symbol: 'circle'
  //         },

  //         threshold: null,
  //         turboThreshold: 3000
  //       }],
  //     exporting: {
  //       enabled: false
  //     }

  //   });


  // }




}
