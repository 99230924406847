/**
 * @Author: Deepak Pookkote <group10>
 * @Date:   2018-04-24T11:51:53+05:30
 * @Email:  deepak@groupten.com
 * @Project: School-Bus
 * @Filename: alert.dir.ts
 * @Last modified by:   group10
 * @Last modified time: 2018-04-25T15:08:48+05:30
 * @Copyright: Group10 Technologies
 */



/*Auther:Deepak */
import { Component,Input,Output,EventEmitter } from '@angular/core';
import { RouterModule, Routes, Router } from '@angular/router';
import { globalService } from './../../../services/global.service';
import { ApiService } from './../../../services/ApiService';


import { Observable } from 'rxjs';
import { NgRedux, select } from '@angular-redux/store';
import { AppState } from './../../../store/index';
import { ImeiFinderServices } from './../../../services/imeiFinder.service';

import { TrackerData_View } from './../../../actions/tracker-data';


@Component({
  selector: 'alert-panel',
  templateUrl: './alertPanel.template.html',
  styles: [ `

    .primary-div {
        margin-top: 10%;
    }

    .bg-blue {
        background-color: #3498db;
    }

    /*Primary iconic nav*/

    .icon .prim-icon {
        margin-left: 10px;
        margin-top: 5px;
    }

    .icon .icon-pad {
        padding: 2px 5px;
    }

    .icon .icon-pad .prim-nav-a {
        color: white;
        padding-left: 14px;
        padding-right: 14px;
        padding-top: 0px;
        padding-bottom: 0px;
        font-size: 24px;
    }

    .img {
        height: 10px;
        z-index: 800;
    }

    .ul {
        position: relative;
        margin-top: -14px;
        padding: 0px 4px;
    }

    ul li .prim-nav-arrow {
        color: white;
        margin-left: 1px;
        padding-left: 26px;
        padding-right: 24px;
        opacity: 0.0;
    }

    ul li .prim-nav-arrow-select {
        opacity: 1;
    }

    /*Secondary nav*/

    .route-text {
        margin-top: -15px;
        font-size: 20px;
        font-weight: 400;
        padding: 7px 18px;
    }

    .sec-nav a {
        text-decoration: none;
        color: black;
    }

    .sec-nav div {
        border-bottom: 3px solid lightgrey;
        border-top: 1px solid lightgrey;
    }

    .sec-nav div:hover {
        cursor: pointer;
        background-color: white;
        border-bottom: 3px solid #3498db;
    }

    .sec-nav .txt-default {
        background-color: white;
        border-bottom: 3px solid #3498db;
    }

    .txt_pad {
        font-size: 15px;
        padding-left: 0px;
        padding-bottom: 10px;
        padding-top: 10px;
        padding-right: 20px;

    }

    .text_upcoming_pad {
        font-size: 15px;
        padding-left: 18px;
        padding-bottom: 10px;
        padding-top: 10px;
        padding-right: 30px;

    }

    /*Search bar stylings*/

    .searchbar {
        padding-top: 20px;
        padding-left: 18px;
        padding-right: 20px;
        padding-bottom: 20px;
        position: relative;
        width: 99%;
    }

    .searchbar input[type=text] {
        background-color: #f7f7f7;
        border: 1px solid lightgrey;
        height: 40px;
        border-radius: 0.2em;
    }

    .srchb {
      position: absolute;
      right: 15px;
      top: 24px;
      padding: 08px 15px;
      color: #969494;
      font-size: 15px;
    }

    .srchb:hover {
        cursor: pointer;
    }

    /*secondary div and dropdown arrow stylings*/

    /*drop down arrow*/

    .activity{

          margin-top:-33px;
    }

    .pl {
        padding-left: 18px;
        font-size: 15px;
        font-weight: normal;
        color: #34495e;
    }

    .pr {
        padding-right: 28px;
        font-size: 15px;
        font-weight: normal;
        color: #34495e;
    }

    .drpdwn > label {
        text-decoration: none;
        font-weight: normal;
        cursor: pointer;
        padding-left: 30px;
    }

    .drpdwn > button {
        border: 1px solid white;
        background-color: white;
        font-size: 12px;

        padding-bottom: 5px!important;
        cursor: pointer;
    }

    .drpdown-li-pad {
        padding: 10px;
    }

    .fa-chevron-down {
        font-size: 10px;
    }

    /*scrollbar and its stylings*/

    .secondary-div {
        height: 380px;
        overflow-y: scroll;
        overflow-x: hidden;
    }

    .secondary-div::-webkit-scrollbar {
        background-color: white;
        width: 2px;
    }

    .secondary-div::-moz-scrollbar {
        background-color: white;
        width: 2px;
    }

    .secondary-div::-webkit-scrollbar-thumb {
        background-color: #3498db;
    }

    .secondary-div::-moz-scrollbar-thumb {
        background-color: #3498db;
    }

    .alert-left-btm-pad {
        padding: 10px 17px;
        font-size: 14px;
        color: #b5b5b5;
        overflow-y: auto;
    }

    .alert-pad-left {
        padding-left: 15px;
        padding-bottom: 5px;
        padding-top: 5px;

    }

    .alert-pad-left-txt {
        padding-left: 32px;
        padding-bottom: 3px;
        padding-top: 3px;
        color: #34495e;
        font-size: 12px;
    }

    .alert-pad-left-txt-time {
        padding-left: 32px;
        padding-bottom: 0px;
        padding-top: 0px;
        color: #b5b5b5;
        font-size: 12px;
    }

    .alert-left-row-pad {
        padding-left: 15px;
        padding-right: 15px;
    }

    .ic-bg-danger {
        color: red;
    }

    /*sos animation*/

    .sos.animate:before {
        -webkit-animation: sosimp 3s infinite linear;
        -moz-animation: sosimp 3s infinite linear;
        -ms-animation: sosimp 3s infinite linear;
    }

    @keyframes sosimp {
        0% {
            content: "◎"
        }
        20% {
          content: "◉"
        }
        40% {
            content: "◎"
        }
        60% {
            content: "◉"
        }
        80% {
            content: "◎"
        }
        100% {
            content: "◉"
        }
    }

    .notifications {
        height: 54vh;
    }

    .customDropDown{
      padding: 7px;
      font-size: 14px;
      min-width: 225px;
      min-height: 200px;
      margin-left: -91px;
      border: 1px solid #d6cfcf;
      background: #fffffff0;
    }
    .filterItems{
      padding:3px;
    }

    animation css */
    .grow { transition: all .2s ease-in-out; }
    .grow:hover { transform: scale(1.1); }


`  ],
})
export class AlertDir  {

    public __notificationObject: any = [];
    public vehInfo: any = [];

  @Output() showPushAlert: EventEmitter<any> = new EventEmitter<any>();


    public notificationDetails: any[];
    //private viewSearch:boolean=false;

  constructor(
    public router: Router,
    public globalService: globalService,
    public imeiservce: ImeiFinderServices,
    private ngRedux: NgRedux<AppState>

  ) {

    this.alert_pulse();

    }



    ngOnInit(){
      //this.initAlertsContainer();
    }


    getColor(alertType:any) {
      let status = '';

      if(alertType ==="sos"){
        status = '#ff6347';
      }else if(alertType ==="overspeed"){
        status = '#ff6347';
      }else if(alertType ==="ENGINE ON/OFF"){
        status = 'orange';
      }
      else if(alertType ==="AC ON/OFF"){
        status = 'orange';
      }
      else if(alertType ==="ROUTE STARTED IN DELAY"){
        status = 'orange';
      }
      else if(alertType ==="ROUTE COMPLETED IN DELAY"){
        status = 'orange';
      }
      else if(alertType ==="ROUTE STARTED" || alertType ==='ROUTE COMPLETED'){
        status = 'green';
      }else {
        status = 'grey';
      }
      return status;
    }

    // initAlertsContainer() {
    //   this.notificationDetails = [
    //     {
    //       Id:"1",
    //       alertType: "SOS",
    //       alertMessgae: "Vehicle Number : Demo of Demo Route nearby location name is under emergency",
    //       triggerTime:"00:00"

    //     },
    //     {
    //       Id:"2",
    //       alertType: "OVER SPEED",
    //       alertMessgae: "Vehicle number :Demo of Demo Route overspeeds at location",
    //       triggerTime:"00:00"

    //     },
    //     {
    //       Id:"3",
    //       alertType: "AC ON/OFF",
    //       alertMessgae: "AC running time is 00:00 hrs at engine off status on Vehicle number : Demo of Demo Route",
    //       triggerTime:"00:00"

    //     },
    //     {
    //       Id:"4",
    //       alertType: "ENGINE ON/OFF",
    //       alertMessgae: "Vehicle number :Demo of Demo Route engine was On at 00:00 am and Off at 00:00 am",
    //       triggerTime:"00:00"
    //     },
    //     {
    //       Id:"5",
    //       alertType: "ROUTE STARTED IN DELAY",
    //       alertMessgae: "Vehicle Number : Demo ofDemo Route started 00:00 min delay",
    //       triggerTime:"00:00"
    //     },
    //     {
    //       Id:"6",
    //       alertType: "ROUTE COMPLETED IN DELAY",
    //       alertMessgae: "Vehicle Number : Demo of Demo Route completed 00:00 min delay",
    //       triggerTime:"00:00"
    //     },
    //     {
    //       Id:"7",
    //       alertType: "ROUTE STARTED",
    //       alertMessgae: "Vehicle Number : Demo of Demo Route started by 00:00 am",
    //       triggerTime:"00:00"
    //     },
    //     {
    //       Id:"8",
    //       alertType: "ROUTE COMPLETED",
    //       alertMessgae: "Vehicle Number : Demo of Demo Route completed by 00:00 am",
    //       triggerTime:"00:00"
    //     },

    //   ];

    // }


    alert_pulse() { // console.log('alert pulse...', this.__notificationObject );
        this.__notificationObject = (this.ngRedux.getState().alertData);
        //this.getVehInfo();
        setTimeout( () => {  this.alert_pulse();  }, 10000 );
    }

    // getVehInfo( ) {

    //     //this.imeiservce.getImeiInformation();
    //     // (this.__notificationObject).map( (itm: any) => {
    //     //  this.vehInfo[itm.imei] = (this.ngRedux.getState().trackerData).filter( ( data: any ) =>  data.imei === itm.imei )[0]['vehNo'];

    //     // } );
    //     // (this.__notificationObject).map( (itm: any) => {
    //     //     this.vehInfo[itm.imei] =
    //     // } );


    //   }




 }

/*
Notes : npm install node-sass -g
*/
