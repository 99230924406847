import { Component, Input, Directive, Renderer2, ElementRef, OnInit } from '@angular/core';

@Component({

    selector: 'progress-bar',
    template:`<div></div>`,
    styles:[`
    @keyframes progressing {
    0%   {width:0%;}
    25%  {width:25%;}
    50%  {width:50%;}
    75%  {width:75%;}
    100% {width:100%}
    }
    `]

})

export class ProgressBar{

  constructor(private renderer: Renderer2, private el: ElementRef) { }
  

  ngOnInit() {

    this.progressBar();
   
  }
  progressBar=()=>{


    const myProgress = this.renderer.createElement('div');
    const myBar = this.renderer.createElement('div');
    const width = 0;



    this.renderer.appendChild(myProgress, myBar);
    this.renderer.appendChild(this.el.nativeElement, myProgress);

    this.renderer.addClass(myProgress, 'myProgress')
    this.renderer.addClass(myBar, 'myBar')
    // this.renderer.addClass(myBar, 'progressing')

    this.renderer.setStyle(myProgress, 'height', '3px')

    this.renderer.setStyle(myProgress, 'background-color', '#fefefe')
    this.renderer.setStyle(myProgress, 'border-radius', '20px');

    // this.renderer.setStyle(myBar,'transition')
    this.renderer.setStyle(myBar, 'height', '3px')
    this.renderer.setStyle(myBar, 'background-color', '#03A9F4');
    this.renderer.setStyle(myBar, this.getBrowserName() + 'animation-name', 'progressing')
    this.renderer.setStyle(myBar, this.getBrowserName() + 'animation-duration', '2s')
    this.renderer.setStyle(myBar, this.getBrowserName() + 'animation-delay', '0.5s')

    setTimeout(() => {
      this.renderer.setStyle(myBar, 'width', 0 + '%');
    }, 200)

    // console.log(this.el.nativeElement)


  }
  
  getBrowserName:any=()=>{
    if ((navigator.userAgent.indexOf("Opera") || navigator.userAgent.indexOf('OPR')) != -1) {
      return '-o-'
    }
    else if (navigator.userAgent.indexOf("Chrome") != -1){
      return '-webkit-'
    }
    else if (navigator.userAgent.indexOf("Safari") != -1) {
      return '-webkit-'
    }
    else if (navigator.userAgent.indexOf("Firefox") != -1) {
      return '-moz-'
    }
    else if (navigator.userAgent.indexOf("MSIE") != -1) //IF IE > 10
    {
      return '-ms-'
    }
    else{
      return '';
    }
  }

}