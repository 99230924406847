/**
    @Author:Rakesh Rousham
 * @Date:   2018-02-26T10:35:20+05:30
 * @Last modified time: 2018-05-11T16:53:22+05:30
 */



import { Component,EventEmitter,Input,Output,HostListener,ElementRef  } from '@angular/core';
import { ApiService } from './../../services/ApiService';

@Component({
    selector:'search-select',
    template:`
        <div class="wrapper" style="width: 100%;">
            <ul class="list-group selectAndSearchContainerList">
                <li class="list-group-item search-choice" *ngFor="let route of selectedRoutes">
                    <span class="selectedRouteName">{{route.genericName}}</span>
                    <a class="removeSearchItemLink" data-id="route.genericId" (click)=removeRouteFromChoices(route.genericId)></a>
                </li>
                <li class="list-group-item search-field">
                    <input class="custom-form-control" placeholder="Search here" type="text" (keyup)="keyUpHandler()" (keydown)="keyDownHandler" [(ngModel)]="userRouteText" style="border:none;box-shadow:none">
                </li>
            </ul>
            <div class="suggestion-dropdown animated fade-in border-deda" *ngIf="showSuggestionDropdown">
                <ul class="list-group" style="display:inline" *ngIf="noMatchingItemsFound">
                    <li class="list-group-item">No matching items found...</li>
                </ul>
                <ul class="list-group" style="display:inline" *ngIf="searchSuggestions">
                    <li class="list-group-item" *ngFor="let route of searchSuggestions" (click)="selectDropdownSuggestions(route)">{{route.genericName}}</li>
                </ul>
            </div>
        </div>


    `,
    styles:[`

        .border-deda {
          border-radius: 3px;
          border: 1px solid #dedada;
          -webkit-box-shadow: 0 6px 4px -4px grey;
          -moz-box-shadow: 0 6px 4px -4px grey;
          box-shadow: 0 6px 4px -4px grey;
          margin-top: 1%;
          max-height: 210px;
          overflow-y: auto;
        }
        .border-deda::-webkit-scrollbar {
            background-color: white;
            width: 2px;
        }

        .border-deda::-moz-scrollbar {
            background-color: white;
            width: 2px;
        }

        .border-deda::-webkit-scrollbar-thumb {
            background-color: #3498db;
        }

        .border-deda::-moz-scrollbar-thumb {
            background-color: #3498db;
        }

        .selectAndSearchContainerList{
            margin-bottom:1px;
            border: 1px solid #eee;
            max-height: 160px;
            overflow: auto;
        }
        .suggestion-dropdown{
            position:absolute;
            width:93%;
            z-index:10;
            border-top: 1px solid #eee;
            border-right: none;
        }
        .suggestion-dropdown ul li{
            cursor:pointer;
        }
        li.search-choice {
            position: relative;
            margin: 5px 0 5px 5px;
            padding: 8px 20px 8px 10px;
            -moz-border-radius: 2px;
            -webkit-border-radius: 2px;
            border-radius: 2px;
            background-color: #eee;
            background-clip: padding-box;
            color: #333;
            line-height: 13px;
            cursor: default;
            font-size: 12px;
            float: left;
            list-style: none;
            z-index: 100;
        }
        a.removeSearchItemLink{
            position: absolute;
            top: 8px;
            right: 3px;
            display: block;
            width: 12px;
            height: 12px;
            background: url(./../../assets/webroot/css/chosen-sprite.png) -42px 1px no-repeat;
            font-size: 1px;
            cursor:pointer;
        }
        li.search-field{
            padding: 0;
            display: inline-block;
            width: 100%;
        }
        `],
        /*host: {
           '(document:click)': 'onClick($event)',
       }, */
})

export class searchAndselectDir{
    public searchTypeText:string;
    public userRouteText:any
    private baseApiUrl:any;
    private baseAPiHeader:any;
    private typingTimer:any;
    public showSuggestionDropdown:boolean;               //timer identifier
    public noMatchingItemsFound:boolean;
    private doneTypingInterval = 500;  //time in ms, 5 second for example.
    public selectedRoutes:any[] = [];
    private searchSuggestions:any[];
    public selectionType:string;



    constructor(private apiService:ApiService,private _elementRef: ElementRef){}

    @Input('searchType')
    set searchType(text:string){
        this.searchTypeText = text;
    }

    @Input('searchAPIUrl')
    set ApiUrl(url:any){
        this.baseApiUrl = url;
    }

    @Input('searchApiHeader')
    set ApiHeader(header:any){
        this.baseAPiHeader = header;
    }

    @Input('selectionType')
    set selectType(type:any){
        this.selectionType = type;
    }

    @Input('otherParams')
    set otherParams(param:any){
        this.resetSelectSearchUtility(param);
    }

    @Output()
    public clickOutside = new EventEmitter();

    @Output() emitUserSelections: EventEmitter<any> = new EventEmitter<any>();

    @HostListener('document:click', ['$event.target'])
    public onClick(targetElement:any) {
        const clickedInside = this._elementRef.nativeElement.contains(targetElement);
         if(clickedInside && this.searchSuggestions.length) {
             this.showSuggestionDropdown = true;
         }
         else {
             this.showSuggestionDropdown = false;
         }

    }



    ngOnInit(){
    }

    keyUpHandler(){
        clearTimeout(this.typingTimer);
        this.typingTimer = setTimeout(this.getSuggestions, this.doneTypingInterval);
    }

    keyDownHandler(){
        clearTimeout(this.typingTimer);
    }

    getSuggestions = () => {
         if(this.searchTypeText == 'onlyRoute'){
             this.getRouteSuggestions();
         }else if(this.searchTypeText == 'pickup'){
             this.getPickupSuggestions();
         }else if(this.searchTypeText == 'orguserNames'){
            this.getOrgUserSuggestions();
        }else if(this.searchTypeText == 'orgDevices'){
            this.getOrgDevices();
         }
         else if(this.searchTypeText == 'orgEmails'){
            this.getOrgEmails();
         }else{
              this.getMemberSuggestion()
         }
    };



     selectDropdownSuggestions = (param:any) =>{
         let ifChoiceExists = this.selectedRoutes.findIndex(route => route.genericId == param.genericId);
         if(ifChoiceExists == -1) {
             if((this.selectionType)&&(this.selectionType=='single')) this.selectedRoutes = [];
             this.selectedRoutes.push(param);
         }
         this.emitUserSelections.emit(this.selectedRoutes);
     }

     removeRouteFromChoices = (genericId:any) => {
         this.selectedRoutes.splice(this.selectedRoutes.findIndex(route => route.genericId == genericId),1);
         this.emitUserSelections.emit(this.selectedRoutes);
     }

     getRouteSuggestions = ():void =>{
         let routeType = (this.searchTypeText == 'routePickup')?'pickup':'drop';
         this.baseAPiHeader = {
            data:{
                key:localStorage.getItem('scbToken'),
                filter:{
                    routeName:this.userRouteText
                }
            }
         }
        this.apiService[this.baseApiUrl](this.baseAPiHeader).then((response:any)=>{
             if(response.response){
                 this.searchSuggestions = [... response.response];
                 for(let route of this.searchSuggestions ){
                       Object.assign(route,{'genericName':route.routeName,'genericId':route.routeId});
                 }
                 this.noMatchingItemsFound = false;
             }else{
                 this.noMatchingItemsFound = true;
                 this.searchSuggestions = [];
             }
             this.showSuggestionDropdown = true;
        });
     }

     getPickupSuggestions = ():void => {
         this.baseAPiHeader = {
              data:{
                  key:localStorage.getItem('scbToken'),
                  filter:{
                      pickuppoint:this.userRouteText
                  }
              }
         }
         this.apiService[this.baseApiUrl](this.baseAPiHeader).then((response:any)=>{
                if(response.response){
                        this.searchSuggestions = [... response.response.pickuppoints];
                        for(let pickUp of this.searchSuggestions ){
                              Object.assign(pickUp,{'genericName':pickUp.pickuppoint,'genericId':pickUp.pickupId});
                        }
                        this.noMatchingItemsFound = false;
                }else{
                    this.noMatchingItemsFound = true;
                }
                this.showSuggestionDropdown = true;
         });
     }

     getMemberSuggestion = ():void => {
         this.baseAPiHeader = {
             data:{
                 key:localStorage.getItem('scbToken'),
                 filter:{
                     MemberName:this.userRouteText
                 }
             }
         }
         this.apiService[this.baseApiUrl](this.baseAPiHeader).then((response:any)=>{
                if(response.response){
                    this.searchSuggestions = [... response.response];
                    for(let member of this.searchSuggestions ){
                          Object.assign(member,{'genericName':member.memberName,'genericId':member.memberId});
                    }
                    this.noMatchingItemsFound = false;
                }else{
                      this.noMatchingItemsFound = true;
                      this.searchSuggestions = []; //Patch for clearing list when no matching items found.
                }
                this.showSuggestionDropdown = true;
         });
     }

     getOrgUserSuggestions = ():void => {
         this.baseAPiHeader = {
             data:{
                 key:localStorage.getItem('scbToken'),
                 filter:{
                     username:this.userRouteText
                 }
             }
         }
         this.apiService[this.baseApiUrl](this.baseAPiHeader).then((response:any)=>{
                if(response.response){
                    this.searchSuggestions = response.response;
                    for(let user of this.searchSuggestions ){
                          Object.assign(user,{'genericName':user.UserName,'genericId':user.userRegMobileId});
                    }
                    this.noMatchingItemsFound = false;
                }else{
                      this.noMatchingItemsFound = true;
                      this.searchSuggestions = []; //Patch for clearing list when no matching items found.
                }
                this.showSuggestionDropdown = true;
         });
     }

     getOrgEmails = ():void => {
        this.baseAPiHeader = {
            data:{
                key:localStorage.getItem('scbToken'),
                filter:{
                    username:this.userRouteText
                }
            }
        }
        this.apiService[this.baseApiUrl](this.baseAPiHeader).then((response:any)=>{
               if(response.response){
                   this.searchSuggestions = response.response;
                   for(let user of this.searchSuggestions ){
                         Object.assign(user,{'genericName':user.useremail,'genericId':user.userRegEmailId});
                   }
                   this.noMatchingItemsFound = false;
               }else{
                     this.noMatchingItemsFound = true;
                     this.searchSuggestions = []; //Patch for clearing list when no matching items found.
               }
               this.showSuggestionDropdown = true;
        });
    }

     getOrgDevices = ():void => {
         this.baseAPiHeader = {
             data:{
                 key:localStorage.getItem('scbToken'),
                 filter:{
                     regNo:this.userRouteText
                 }
             }
         }
         this.apiService[this.baseApiUrl](this.baseAPiHeader).then((response:any)=>{
                if(response.response){
                    this.searchSuggestions = [... response.response];
                    for(let vehicle of this.searchSuggestions ){
                          Object.assign(vehicle,{'genericName':vehicle.vehNo,'genericId':vehicle.vehId});
                    }
                    this.noMatchingItemsFound = false;
                }else{
                      this.noMatchingItemsFound = true;
                      this.searchSuggestions = []; //Patch for clearing list when no matching items found.
                }
                this.showSuggestionDropdown = true;
         });
     }

     resetSelectSearchUtility(param:any){
         this.selectedRoutes = [];
         this.searchSuggestions = [];
         this.emitUserSelections.emit(this.selectedRoutes);
         this.showSuggestionDropdown = false;
     }

    //close suggestion box on outside click.
/*    onClick(event:any) {
      if (!this._eref.nativeElement.contains(event.target)) // or some similar check
          this.showSuggestionDropdown = false;
     }  */
}
