
import { Component, OnInit, ViewChild, Input } from '@angular/core';
// import { ChartDataSets, ChartOptions, ChartType } from 'chart.js';
// import { Color, BaseChartDirective, Label } from 'ng2-charts';
// import * as pluginAnnotations from 'chartjs-plugin-annotation';
// import { SharedService } from 'src/app/Services/shared.service';

declare const $: any;
declare const Highcharts: any;
@Component({
  selector: "g10-ms-line-chart",
  templateUrl: "./linechart.component.html",
  // styleUrls: ["./d3chart.component.css"]
  styles: [`
  div#svg-render {
    width: 277px !important;
    height: 250px !important;
    position: relative;
    overflow-y: hidden;
    overflow-x: scroll;
}
`]
})
export class LineChartComponent {
  @Input() data: any;
  @Input() chartType:any;
  @Input() _pickupPoint: boolean;
  public pointInfo: any = [];

  ngOnInit() {
    // console.log('Line_data',this.data);
    //  let container:any =  document.getElementById('container');
    //   if(container) document.getElementById('container').innerHTML='';
    //   if(!this._pickupPoint){
      this.data = this.data.slice(0);
      this.data .sort(function(a:any,b:any) {
        return a.date - b.date;
      });
    //  console.log('chartType',this.chartType);
      this.loadData().then((chartData: any) => {
        this.chartPlot(chartData);
      })
    // }else{
        
    //     this.loadPickupPointChart(this.data);
    // }
  }
  getData() {
    return eval(this.data);
  }
  
  loadData() {
    return new Promise((res: any, rej: any) => {
      res(this.getData().map((item: any) => {

        let temp = {
          x: item.date,
          y: Number.isNaN(parseInt(item.value)) ? 0 : parseInt(item.value),
          myData: item
        }

        return temp;
      }))
    });



  }


  chartPlot(data: any) {
    Highcharts.chart('line-chart-container', {
      time: {
        timezone: 'Asia/Kolkata'
      },
      chart: {
        zoomType: 'x'
      },
      title: {
        text: 'Not Reached'
      },
      subtitle: {
        text: document.ontouchstart === undefined ?
          'Click and drag in the plot area to zoom in' : 'Pinch the chart to zoom in'
      },
      xAxis: {
        type: 'datetime',
        labels: {
          formatter: function () {
            // console.log(this.value);
            return new Date(this.value).toLocaleTimeString();
            // return Highcharts.dateFormat('%H:%M', this.date);
          },

        },
        min: data[0]['date'],
        max: data[(data.length - 1)]['date']

      },
      yAxis: {
        title: {
          text: 'values'
        }
      },


      tooltip: {
        formatter: function () {
          var d = this.points[0]['point']['myData'];

          var info = '<div style="width:20px;word-wrap:break-all">';
          if (d.RouteName != undefined) info += '<strong>Route Name: </strong>' + d.RouteName + '<br>';
          if (d.callMade != undefined) info += '<strong>Call Time: </strong>' + d.callMade + '<br>';
          if (d.diff != undefined) info += '<strong>Time Difference: </strong>' + d.diff + '<br>';
          if (d.reachedTM != undefined) info += '<strong>Expected Time: </strong>' + d.reachedTM + '<br>';
          if (d.pickupName != undefined) info += '<strong>Pickup Name: </strong>' + d.pickupName + '<br>';
          info += '</div>';
          return info;

          // return this.points.reduce(function (s, point) {
          //     return s + '<br/>' + point.series.name + ': ' +
          //         point.y + 'm';
          // }, '<b>' + this.x + '</b>');
        },
        shared: true
      },
      legend: {
        enabled: false
      },
      plotOptions: {
        series: {
          animation: {
            duration: 8000,
            complete: function () { }
          },
          marker: {

            fillColor: '#FFFFFF',
            lineWidth: 1,
            radius: 7,
            lineColor: null // inherit from series
          },
          // stickyTracking: true,
          events: {

            // mouseOver: function (e) {
            //     console.log(e);
            // },

          }
        },
        area: {
          fillColor: {
            linearGradient: {
              x1: 0,
              y1: 0,
              x2: 0,
              y2: 1
            },
            stops: [
              // [0, Highcharts.getOptions().colors[0]],
              // [1, Highcharts.color(Highcharts.getOptions().colors[0]).setOpacity(0).get('rgba')]
              [0,'#3498db'],
              [1,Highcharts.color('#3498db').setOpacity(0).get('rgba')]
            ]
          },

          lineWidth: 1,
          states: {
            hover: {
              lineWidth: 1
            }
          },
          threshold: null
        }
      },

      series: [{
        type: 'area',
        // name: '',

        data: data,
        marker: {
          fillColor: '#FFFFFF',
          lineWidth: 2,
          enabled: true,
          radius: 1.2
        },

        shadow: false,
      }]
    });
    
  }



}   