import { Observable } from 'rxjs';
import { NgRedux, select } from '@angular-redux/store';
import { AppState } from './../../store/index';

import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { RouterModule, Routes, Router } from '@angular/router';
import { authGaurd } from './../../services/auth-guard.service';
import { ApiService } from './../../services/ApiService';
import { StorageService } from "./../../services/storage.service";
import { ImeiFinderServices } from './../../services/imeiFinder.service';

declare var $: any;

@Component({
  selector: 'app-tabular-dashboard',
  templateUrl: './tabular-dashboard.component.html',
  styleUrls: ['./tabular-dashboard.component.css']
})
export class TabularDashboardComponent implements OnInit {

  @select('vehicleData') public vehicleData$: Observable<any>;
  @ViewChild('myVal') span: ElementRef;

  routeTypeFilter = 'Morning';

  VehicleInfoList: any = [];
  trackerStatus: string;
  queryString: string='';
  popUpInfo: boolean;
  overSpeedInput: boolean = false;
  speedFilterInput: number;
  offlineVehicleTime: number = 1800000;
  public mapVehicleNo: string;
  routeDetails: any;
  allRouteInfo: any;
  val: any;
  noonval: any = [];
  mornval: any = [];
  eveningval: any = [];
  finVal: any[];
  sessionTypeList:string;
  public mapGeoPosition: any = {};
  public socketConnectionPermission: boolean = true;
  public mapHeight: number = 61;
  public clickedVehicleId: any;
  overspeedval:number;
  public  vehicleDetails: any[];
  public  apivalues:any={};
  private rawAllVehicleData:any[] = [];
  private allVehicleAllRouteData:any[] = [];
  private selectedRouteDetails:any[] = [];
  private orgBasicDetails:any = {};
  private routes:any=[];
  public  pageJump:number=0;
  public limitOfRecords:number;
  private vehicleInfoAvailable:any=[];
  private allOrgRoutes:any[] = [];
  private filterRouteId:any;
  private filterVehicleReg:any;
  private globalFilterObj:any = {};
  private dummy:any=[];
  public isLoading: boolean; //Loader Var

  public showLastUpdateStatus: boolean = false;
  public showVehicleList: boolean = true;
  public sortedArray:any=[];
  constructor(private router: Router, public authGaurd: authGaurd, private apiService: ApiService, public imeiservce: ImeiFinderServices, public storage: StorageService,
    private ngRedux: NgRedux<AppState>) {
    this.authGaurd.checkToken();
    this.allRouteInfo = this.storage.get('AllRouteInfo');
  }

  ngOnInit() {
    //this.getTrackerInfo();
    this.overspeedval = +this.storage.get('overspeed');
    /// this.overspeedval = 40;

  this.getSortingVeh();
  }
getSortingVeh(){ 
  this.isLoading = true;
  let apiHeader = {
       data:{
           key:localStorage.getItem('scbToken'),
           extra: {
           pageJump: this.pageJump
         }
       }
  }
  if(Object.getOwnPropertyNames(this.globalFilterObj).length){
      apiHeader.data['filter'] = this.globalFilterObj;
  }else{
      delete apiHeader.data['filter'];
  }

  this.rawAllVehicleData = [];
  this.allVehicleAllRouteData = [];
  console.log('apiHeader ',apiHeader);
  this.apiService.getAllVehicleAllRouteReport(apiHeader).then(response => {
    let result=response;
    console.log('result ',result);
    if(result.status=="success"){
      this.isLoading = false;
      this.routes= [];
      let rawAllVehicleData = response.response;
      let availableRouteInfo = rawAllVehicleData['vehDetails'];
      let desiredRouteInfo:any[] = [];
      this.selectedRouteDetails = [];

//new code by krish starts here

let veh = rawAllVehicleData['vehDetails'];
  let gate:any=[];
  let nonGate:any=[];
  Object.keys(veh).map( item => {
    let str = item.toLowerCase();
     if(str.includes('gate')) gate.push(item);
     else nonGate.push(item)
    });
  
let temp = {};
  nonGate.forEach((item:any)=>{


    if('RouteInfo' in veh[item]){
      temp[item] = this.getOnlyRouteNameArray(veh[item]['RouteInfo'])
     
    }else gate.unshift(item);
   
  })
  
  let d:any = Object.keys(temp).sort((a,b) => temp[a]-temp[b]);
  

 this.sortedArray = JSON.parse(JSON.stringify(d));



  
  gate = this.uniqueItem(gate);

  gate.forEach((item:any)=>{
    this.sortedArray.push(item);
  });

  this.sortedArray = this.uniqueItem(this.sortedArray);

for (let i = 0; i < this.sortedArray.length; i++) {

Object.assign(availableRouteInfo[this.sortedArray[i]],{routeId:this.sortedArray[i]});
this.selectedRouteDetails.push(availableRouteInfo[this.sortedArray[i]]);
}
//new code by krish ends here

      // for(let routeId in availableRouteInfo){ //this is previous code starts here hided by krish
         
      //     Object.assign(availableRouteInfo[routeId],{routeId:routeId});
      //     this.selectedRouteDetails.push(availableRouteInfo[routeId]);
      // }  //this is previous code ends  here hided by krish
      if(this.selectedRouteDetails){
       
          for(let data in this.selectedRouteDetails){
            let routeNames: any = [];
            let routeInfo = this.selectedRouteDetails[data].RouteInfo;
            if(routeInfo){
              for(let id in routeInfo) {
                
                if(routeInfo[id].routeName){
                  let rInfo = {
                    routeName:routeInfo[id].routeName,
                    routeId:routeInfo[id].routeId,
                    vehReg:this.selectedRouteDetails[data].vehNo,
                  }
                  routeNames.push(rInfo);
              }
            }
          }
          console.log('VEHNO ',routeNames);
          this.routes.push({vehReg : this.selectedRouteDetails[data].vehNo, routeInfo: routeNames});
        }
      }
      if(this.pageJump == 0){
        this.limitOfRecords = this.routes.length;
      }
}
}).catch(error => {});


}
uniqueItem(arr:any){
  return Array.from(new Set(arr));
}
getOnlyRouteNameArray(obj:any){
  let res = Object.keys(obj).map((val:any)=> obj[val]['routeName'] );
  res = res.map((item:any)=> parseInt(item));
  res = Array.from(new Set(res));
 
  return res[0];
}
  getTrackerInfo = (): void => {
    this.apiService.getCurrentLocation({
      data: {
        key: localStorage.getItem('scbToken'),
        extra: {
          orderByDateCreated: -1
        }
      }
    }).then(response => {
      let result = response;
      if (result.status == "success") {
        this.VehicleInfoList = result.response;
        //console.log('111KKKKKKKKKKKKKKKKKKKKKKKKKKKKKKKKKKK1111111 ', result.response);
      }
    }).catch(error => {
      console.log('Error while logging in', error);
    });
  }

  emitVehData(event:any){
    this.clickedVehicleId = {vehId: event};

    // console.log(this.clickedVehicleId);
  }
  calFun(){ 
    console.log('Hello');
  }
  pushNewNotif( $event: any ) {

  }

}
