import { Component, OnInit, Input } from '@angular/core';
import { ApiService } from './../../../services/ApiService';
import { chosenUtility } from './../../../services/chosen-utility/jq-chosen-utility.js';
import { bootstrapDateTime } from './../../../services/bootstrapDateTime/datePicker';
import { GlobalUtils } from './../../../services/global-utils';
import { ApiCallDateFormatService } from "../../../services/api-call-date-format.service";
import { StorageService } from "../../../services/storage.service";

declare const Pickathing: any;

declare var $: any;

@Component({
    selector: 'attendance-reports-section',
    templateUrl: './attendance-reports.component.html',
    styleUrls: ['./attendance-reports.component.css']
})

export class AttendanceReportsComponent {
  
    public displayErrorMessage:string = '';
    public reportSections = {
        memberwiseAttendance: false,
        routewiseAttendance: false,
        datewiseAttendance: false
    }
    public reportSectionsContent = {
        memberwiseAttendance: false,
        routewiseAttendance: false,
        datewiseAttendance: false
    }

    private reportLookUpTitle = {
        memberwiseAttendance: 'Memberwise Attendance',
        routewiseAttendance: 'Routewise Attendance',
        datewiseAttendance: 'Datewise Attendance'
    }

    private reportLookUpFunction = {

        memberwiseAttendance: 'initMemberwiseAttendanceReport',
        routewiseAttendance: 'initRoutewiseAttendanceReport',
        datewiseAttendance: 'initDatewiseAttendanceReport'
    }
    public parameter: any;
    public reportOptions: any = [];
    public datesMisMatchError: boolean;
    public errStartDate: boolean;
    public errEndDate: boolean;
    private selectedReport: any;
    selectedroute: any;
  userSelected: boolean;
    framedApiHeader: any;
    public class:any=[];
    public section:any=[];
    public choosedClass:string="";
    public choosedSection:string="";
    public choosedStatus:string="";
    selectedMember: any;
    selectedRouteTransmit: any;
    dateRange: boolean;
    public errResponse:any;

    constructor(private chosenUtility: chosenUtility, private apiService: ApiService,
        private bootstrapDateTime: bootstrapDateTime, private globalUtils: GlobalUtils,
        public storage:StorageService,public dateChange: ApiCallDateFormatService) {

        this.datesMisMatchError = false;
        this.errStartDate = false;
        this.errEndDate = false;
        this.class=this.storage.get('classandsection');
        this.section =  this.class.listofsections;
        this.class =  this.class.listofclasses;
        this.selectedroute=[];
        this.selectedMember=[];

    }

    // get all configured attendance report objects
    @Input('availableReports')
    set availableReports(para: any) {
        this.parameter = para;
    }
    // get all reports objects
    @Input('AlertReports')
    set AlertReports(data: any) {
        if (data !== undefined) this.AlertControls(data.attendance);
    }

    ngOnInit() {
        this.pickathpluginInit(); //inititaiating picakth plugin
        this.initChosen();
       
    }
  
    // check and route based on user controls
    AlertControls(obj: any) {
        let report
        if (localStorage.getItem('userInfo')) {
            report = JSON.parse(localStorage.getItem('userInfo'));
        }

        let uLevel = JSON.parse(localStorage.getItem('userLevel'));

        if (uLevel === 1) {
            let lone = Object.assign(this.parameter);
            this.generateOptions(lone);
        } else if (uLevel === 2) {
            let alertControl = Object.assign(this.parameter, report.reports.attendance);
            this.generateOptions(alertControl);
        }

    }
    generateOptions(param: any) {

        for (let obj in param) {
            let data = {};
            if (param[obj]) {
                data['reportName'] = this.reportLookUpTitle[obj];
                data['value'] = obj;
                this.reportOptions.push(data);
            }
        }
    }


    initReportSection({
        memberwiseAttendance = false,
        routewiseAttendance = false,
        datewiseAttendance = false
    } = {}) {
        let providedParam = Object.getOwnPropertyNames(arguments[0]);
        let calleFunction = this.reportLookUpFunction[providedParam[0]];
        this.reportSections.memberwiseAttendance = memberwiseAttendance;
        this.reportSections.routewiseAttendance = routewiseAttendance;
        this.reportSections.datewiseAttendance = datewiseAttendance;
        this[`${calleFunction}`](calleFunction);

        this.initReportSectionContent({});
        this.resetAllValues();
    }

    initReportSectionContent({
        memberwiseAttendance = false,
        routewiseAttendance = false,
        datewiseAttendance = false
    } = {}) {
        this.reportSectionsContent.memberwiseAttendance = memberwiseAttendance;
        this.reportSectionsContent.routewiseAttendance = routewiseAttendance;
        this.reportSectionsContent.datewiseAttendance = datewiseAttendance;
    }


    resetAllValues() {
      
        this.errStartDate = false;
        this.errEndDate = false;
        this.datesMisMatchError = false;
        this.userSelected = false;
        // this.choosedClass="";
        // this.choosedSection="";
        this.dateRange = false;
        this.displayErrorMessage = '';
    }

    initMemberwiseAttendanceReport() {
        this.initJqUtils(undefined, 'memStartDate', 'memEndDate', undefined);
        this.resetAllValues();
        return true;
    }
    initRoutewiseAttendanceReport() {


        this.oneTimeInitJqUtils(undefined, 'routeHistoryStartDate', undefined, undefined);
        this.resetAllValues();

        return true;
    }
    initDatewiseAttendanceReport() {
        this.oneTimeInitJqUtils(undefined, 'datewiseStartDate',undefined, undefined);
        this.resetAllValues();        
        return true;
    }
    initChosen() {
        this.bindJqChangeEvt('demo-select');
    }

    viewRoutewise(sectionContentId: any) {
        this.framedApiHeader = {};
        let valid = this.viewAttendanceReportValidator();
       
        if (valid) {
            let obj = {
                [sectionContentId]: true
            }
           
            // this.selectedRouteTransmit = this.selectedroute.length && this.selectedroute[0]['routeId'] ? this.selectedroute :false;
     
            this.framedApiHeader = {
                data:{
                key:localStorage.getItem('scbToken'),
               form:{
                fromDate :  $('#routeHistoryStartDate').val().split(' ')[0].split('-').reverse().join('-'),
               // fromTime :  $('#routeHistoryStartDate').val().split(' ')[1]+':00',
               fromTime :  '00:00:00',
                toDate :  $('#routeHistoryStartDate').val().split(' ')[0].split('-').reverse().join('-'),
               // toTime :  $('#routeHistoryStartDate').val().split(' ')[1]+':00',  
                toTime : '23:59:00'                     
               },
               filter:{
                reportType: "route",
                routeId : this.selectedroute[0]['routeId']   
               }
             }
            }
            // if(this.choosedStatus) this.framedApiHeader.data.filter['boardedstatus'] = this.choosedStatus;
            this.initReportSectionContent(obj);
           
        };
    }

   
    getErrorResponse(event:any){
        this.displayErrorMessage = event.hasOwnProperty('ec') ? this.globalUtils.getErrorMsg(event.ec) : '';
       
    }
    receiveDateObject() {
        let stDate = $('#routeHistoryStartDate').val();
        let endDate = $('#routeHistoryEndDate').val();
        return this.genericDateValidate(stDate, endDate);
    }


   

    viewAttendanceReportValidator() {
        this.resetAllValues();
        let isValid: boolean;
        isValid = true;
        let obj = this.receiveDateObject();
        if (obj.stTimeStampString.length <= 9) {
            this.errStartDate = true;
            isValid = false;
        }
        // if (obj.endTimeStampString.length <= 9) {
        //     this.errEndDate = true;
        //     isValid = false;
        // }

        // if (obj.endTimeStamp < obj.stTimeStamp) {
        //     this.datesMisMatchError = true;
        //     isValid = false;
        // }
   
        if(!this.selectedroute.length){
            this.userSelected = true;
            isValid = false;
        }

        return isValid;

    }


    setUserSelections(userSelectedValue: any) {
        this.selectedroute = userSelectedValue.length ? userSelectedValue : [];
    }
    setSelectedStudent(selectedItem: any) {
        this.selectedMember = selectedItem.length ? selectedItem : [];        
    }
    //Jquery util for change event start.
    bindJqChangeEvt(ele: any) {
        let parent = this;
        $('#' + ele).change(function(e: any) {
            parent.selectedReport = $(this).val();
            let obj = {};
            obj[parent.selectedReport] = true;

            parent.initReportSection(obj);
        });
    }


 oneTimeInitJqUtils(eleChosenId ? : string, eleReportStartTime ? : string, eleReportEndTime ? : string, pickerConfig ? : any) {
        let parent = this;
        let timePicker: boolean;
        let timeFormat: any;
        let closeOnDateSelect: boolean;
        // console.log(pickerConfig);
        if ((pickerConfig) && (Object.keys(pickerConfig).length)) {
            // timePicker = pickerConfig.timePicker;
            // timeFormat = pickerConfig.timeFormat;
            // closeOnDateSelect = true;
        // } else {
            timePicker = false;
            // timeFormat = 'Y-m-d H:i:s';
            timeFormat = 'd-m-Y ';

            closeOnDateSelect = true;
        }
        setTimeout(() => {
            if (eleChosenId) this.chosenUtility.initializeChosen(eleChosenId);
            if (eleReportStartTime) {
                parent.bootstrapDateTime.datetime({
                    component: eleReportStartTime,
                    timepicker: timePicker,
                    format: timeFormat,
                    closeOnDateSelect: closeOnDateSelect
                });
            }          
        }, 150);


    }


    initJqUtils(eleChosenId ? : string, eleReportStartTime ? : string, eleReportEndTime ? : string, pickerConfig ? : any) {
        let parent = this;
        let timePicker: boolean;
        let timeFormat: any;
        let closeOnDateSelect: boolean;
        
        if ((pickerConfig) && (Object.keys(pickerConfig).length)) {
            timePicker = pickerConfig.timePicker;
            timeFormat = pickerConfig.timeFormat;
            closeOnDateSelect = true;
        } else {
            timePicker = true;
            // timeFormat = 'Y-m-d H:i:s';
            timeFormat = 'd-m-Y H:i:s';

            closeOnDateSelect = true;
        }
        setTimeout(() => {
            if (eleChosenId) this.chosenUtility.initializeChosen(eleChosenId);
            if (eleReportStartTime) {
                parent.bootstrapDateTime.datetime({
                    component: eleReportStartTime,
                    timepicker: timePicker,
                    format: timeFormat,
                    closeOnDateSelect: closeOnDateSelect
                });
            }
            if (eleReportEndTime) {
                parent.bootstrapDateTime.datetime({
                    component: eleReportEndTime,
                    timepicker: timePicker,
                    format: timeFormat,
                    closeOnDateSelect: closeOnDateSelect
                });
            }
            $('#eventStartTime').datetimepicker({
                format: 'H:i',
                datepicker: false,
                defaultTime: '00:00',
                timepicker: true,
            });
            $('#eventEndTime').datetimepicker({
                format: 'H:i',
                datepicker: false,
                defaultTime: '23:59',
                timepicker: true,
            });
        }, 150);


    }
    //Jquery util for change event end.

    /*Jquery util to get control value without ngModel*/
    getControlValue(eleId: any) {
        return $('#' + eleId).val();
    }

   
    pickathpluginInit() {
        return new Promise(function(resolve, reject) {

            setTimeout(() =>

                (new Pickathing('demo-select', true, {
                    searchLabel: 'Search for options...'
                }), resolve(true)), 500);

        }).then(function(res1) {

            return res1 && new Promise((res2, reject) => {

                var srchIpBox: any = document.getElementsByClassName('Pickathing-dropdown');

                var span = document.createElement('span');
                var i = document.createElement('i');

                span.setAttribute('id', 'pickathSearchOption');

                i.setAttribute('class', 'fa fa-search');

                srchIpBox[0].appendChild(span);
                span.appendChild(i);

                span.style.position = 'absolute';
                span.style.top = '16px';
                span.style.right = '19px';
                span.style.zIndex = '999';
                res2(true);
            });

        }).then(function(result) {

            return result == true && new Promise((res3, reject) => {
                res3(true);
            });

        }).then(function(result) {
            var targetButton: any = document.getElementsByClassName('Pickathing-option');
            targetButton[0].style.display = "none";

        });
    }

    genericDateValidate(param1: string, param2 : string) {



       
        let stDate: any = param1 ? (param1).split('-') : false;
        stDate = Array.isArray(stDate) ? `${stDate[1]}/${stDate[0]}/${stDate[2]}` : false;
        // string is used for checking the length of date in unix timestamp
        let stTimeStamp = stDate ? new Date(stDate).getTime() : 'false';
        let stTimeStampString = stTimeStamp ? stTimeStamp.toString() : 'false';

        let endDate: any = param2 ? (param2).split('-') : false;
        endDate = Array.isArray(endDate) ? `${endDate[1]}/${endDate[0]}/${endDate[2]}` : false;
        // string is used for checking the length of date in unix timestamp
        let endTimeStamp = endDate ? new Date(endDate).getTime() : 'false';
        let endTimeStampString = endTimeStamp ? endTimeStamp.toString() : 'false';
        
        return {
            stTimeStamp: stTimeStamp,
            endTimeStamp: endTimeStamp,
            stTimeStampString: stTimeStampString,
            endTimeStampString: endTimeStampString,

        }
    }


    choosenClass($event:any){
        
        return $event.target.value;
     
    }
    choosenSection($event:any){
        this.choosedSection =$event.target.value;
    }

    receiveDateObjectForDatewise() {
        let stDate = $('#datewiseStartDate').val();
        let endDate = $('#datewiseEndDate').val();
        return this.genericDateValidate(stDate, endDate);
    }

    validateDatewise(){
        let isValid:boolean = true;
        let obj:any = this.receiveDateObjectForDatewise();
        if (obj.stTimeStampString.length <= 9) {
            this.errStartDate = true;
            isValid = false;
        }
        // if (obj.endTimeStampString.length <= 9) {
        //     this.errEndDate = true;
        //     isValid = false;
        // }

        // if (obj.endTimeStamp < obj.stTimeStamp) {
        //     this.datesMisMatchError = true;
        //     isValid = false;
        // }

        return isValid;
    }

    receiveDateObjectForMemWise(){
        let stDate = $('#memStartDate').val();
        let endDate = $('#memEndDate').val();
        return this.genericDateValidate(stDate, endDate);
    }
    validatorMemWise(){

        let isValid:boolean = true;
        let obj:any = this.receiveDateObjectForMemWise();
        if (obj.stTimeStampString.length <= 9) {
            this.errStartDate = true;
            isValid = false;
        }
        if (obj.endTimeStampString.length <= 9) {
            this.errEndDate = true;
            isValid = false;
        }

        if (obj.endTimeStamp < obj.stTimeStamp) {
            this.datesMisMatchError = true;
            isValid = false;
        }
        if(!this.selectedMember.length){
            this.userSelected = true;
            isValid = false;
        }

        if((obj.endTimeStamp-obj.stTimeStamp)/1000 >= (5*86400)){
            this.errStartDate = false;
            this.errEndDate = false;
            this.dateRange = true;
            isValid = false;
        }

        return isValid;
    }

    viewMemberwise(sectionContentId:any){
        this.resetAllValues();
        if(this.validatorMemWise()){
            this.framedApiHeader={};
            this.framedApiHeader = {
                data:{
                    key:localStorage.getItem('scbToken'),
                    form:{
                        fromDate :  $('#memStartDate').val().split(' ')[0].split('-').reverse().join('-'),
                        fromTime :  $('#memStartDate').val().split(' ')[1]+':00',
                        toDate :  $('#memEndDate').val().split(' ')[0].split('-').reverse().join('-'),
                        toTime :  $('#memEndDate').val().split(' ')[1]+':00',
                        
                    },
                    filter:{
                        reportType: "member",
                        // routeId : this.selectedroute.length && this.selectedroute[0]['routeId'] ?  this.selectedroute[0]['routeId'] : false,
                        memberId : this.selectedMember.length && this.selectedMember[0].memberId ? this.selectedMember[0].memberId : false,
                        // memberClass: this.choosedClass ? this.choosedClass :false,
                        // memberSection: this.choosedSection ? this.choosedSection :false

                    }
                }
            }
        
        let obj = {
            [sectionContentId]: true
        }
        this.initReportSectionContent(obj);
        }
    }

   
    viewDateWise(sectionContentId:any){
        this.resetAllValues();
        if(this.validateDatewise()){
            this.framedApiHeader = {};
            this.selectedRouteTransmit = [];
            
            let filter:any = {
                reportType: "date",
                routeId : this.selectedroute.length && this.selectedroute[0]['routeId'] ?  this.selectedroute[0]['routeId'] : '',
                memberId : this.selectedMember.length && this.selectedMember[0].memberId ? this.selectedMember[0].memberId : '',
                memberclass: this.choosedClass ? this.choosedClass :'',
                membersection: this.choosedSection ? this.choosedSection :'',
                boardedstatus:this.choosedStatus ? this.choosedStatus : ''
            }
          
            filter = this.sanitizeObject(filter);

            this.framedApiHeader = {
                data:{
                    key:localStorage.getItem('scbToken'),
                    form:{
                        fromDate :  $('#datewiseStartDate').val().split(' ')[0].split('-').reverse().join('-'),
                        fromTime : '00:00:00',
                        toDate :  $('#datewiseStartDate').val().split(' ')[0].split('-').reverse().join('-'),
                        //toTime :  $('#datewiseEndDate').val().split(' ')[1]+':00',  
                        toTime : '23:59:00'                      
                    },
                    filter:{ ...filter }
                }
            }

            let obj = {
                [sectionContentId]: true
            }
           
            this.initReportSectionContent(obj);
        }
    }

    getdate(value:any){ 
console.log(value);

    }
    sanitizeObject(obj:any){
        let tempObj = {}
        Object.keys(obj).forEach((item:any)=> {
            if(obj[item] !== ''){
                tempObj[item] = obj[item]
            }
        })        
        return tempObj;
    }
}