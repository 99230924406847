import { Pipe, PipeTransform } from '@angular/core';

import { StorageService } from './../services/storage.service';

@Pipe({
  name: 'routeTypeFilterPipe'
})

export class RouteTypeFilterPipe implements PipeTransform {
  public routeinfo: any = {}
  constructor(
    private storage: StorageService
  ) {

    Promise.resolve(this.storage.get('AllRouteInfo')).then((res: any) => {
      this.routeinfo = res;
    })

  }
  transform(arry: any, type: string) {
    type = type.toLowerCase();
    console.log('type ::::: ', type);
    if (['morning', 'afternoon', 'evening'].indexOf(type) > -1) {

      const routeInfo = this.routeinfo;

      const routeHead = {};

      Object.keys(routeInfo).map((key) => {
        const item = routeInfo[key];
        if ((item.tags).map((item: string) => item.toLowerCase()).indexOf(type) > -1) {
          if (item['vehNo']) routeHead[item.vehNo.trim()] = item;

        }
      });


      const vehRes: any = []; // new Array( arry.length );

      arry && arry.length && arry.map((item: any) => {

        if (routeHead[item.VehicleNo] !== undefined) {

          item['filterRoute'] = [routeHead[item.VehicleNo].routeName];
          item['orderBy'] = parseInt(routeHead[item.VehicleNo].routeName);
          //  vehRes[ parseInt( item['filterRoute'][0] ) ] = item;
          //  console.log('type ::::: ', parseInt(routeHead[item.VehicleNo].routeName));
          vehRes.push(item);
          return item;
        }
      });

      vehRes.sort((a: any, b: any) => a.orderBy - b.orderBy);

      // console.log('HELLOOOOOO',vehRes)
      return vehRes;
    } else {

      const routeAssignedTrue: any = [];
      const routeAssignedFalse: any = [];
      (arry || []).sort((a: any, b: any) => {
        if (a.hasOwnProperty('orderBy')) {
          a.orderBy - b.orderBy
        }
      }).map((item: any) => {
        // if(item.routeAssigned && item.routeAssigned.length){
        //   routeAssignedTrue.push(item);
        // }
        //  routeAssignedFalse.push(item)
        item.routeAssigned != undefined && item.routeAssigned.length ? routeAssignedTrue.push(item) : routeAssignedFalse.push(item);
      });

      return routeAssignedTrue.concat(routeAssignedFalse);


    }
  }
}
