/**
 * @Author: Deepak Pookkote <group10>
 * @Date:   2018-04-26T18:12:55+05:30
 * @Email:  deepak@groupten.com
 * @Project: School-Bus
 * @Filename: datewiseLog.dir.ts
 * @Last modified by:   group10
 * @Last modified time: 2018-04-26T18:19:35+05:30
 * @Copyright: Group10 Technologies
 */



/*Auther:Deepak */
import { Component,Input, Output, EventEmitter } from '@angular/core';
import { RouterModule, Routes, Router } from '@angular/router';
import { globalService } from './../../services/global.service';
import { ApiService } from './../../services/ApiService';
import { bootstrapDateTime } from './../../services/bootstrapDateTime/datePicker';

declare var swal: any;
declare var $:any;
@Component({
  selector: 'view-histotyLog-datewise',
  template: `
  <div class="masonry-item appTable animated fadeIn" style="padding-top:15px;">
    <div class="bd bgc-white">
      <div class="layers">
        <div class="layer w-100 p-20"><h4 class="lh-1">App Registration Log Datewise View</h4>
        <div class="row">
          <div class="form-group col-md-3" style="padding-top: 10px;">
              <label class="control-label">Filter by Date:</label>
              <input class="custom-form-control" id="selectedLogDate" placeholder="Choose date">
              <span class="text-danger" *ngIf="fromDateError">Choose the Date</span>
          </div>
          <div class="col-md-4" style="margin: 34px auto;">
            <button class="btn btn-small btn-primary" (click)="filterFunction();">Filter</button>
          </div>
          <div class="col-md-4 pull-right" style="padding-top: 35px;" *ngIf="daywiseDetailedData">
            <span style="padding: 15px 45px 15px 45px;"><i style="color: #3f819e;" class="peer fa fa-apple mr5" aria-hidden="true"></i>: {{daywiseDetailedData.iosCount}}</span>
            <span style="padding: 15px 45px 15px 45px;"><i style="color:green" class="peer fa fa-android mr5" aria-hidden="true"></i> :  {{daywiseDetailedData.androidCount}}</span>
          </div>
        </div>
        </div>
        <div class="layer w-100">
          <div class="table-responsive p-20" *ngIf="historyLog?.length>0; else falsyTemplate">
            <!--<div *ngIf="displayLoaderText==true">
               <h4 class="animated pulse text-center"  style="animation-duration: 1s;color:#999FBF"><i class="fa fa-smile-o"></i>&nbsp;Loading....</h4>
            </div>-->
            <table class="table" *ngIf="displayLoaderText==false">
              <thead>
                <tr>
                  <th class="bdwT-0">#</th>
                  <th class="bdwT-0">Date and Time</th>
                  <th class="bdwT-0">Mobile</th>
                  <th class="bdwT-0">OS Type</th>
                  <th class="bdwT-0">Version/Model</th>
                  <th class="bdwT-0">App Version</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let log of historyLog;let i=index;">
                  <td>{{ i + 1 }}</td>
                  <td *ngIf="!log.installedDate">----</td>
                  <td *ngIf="log.installedDate">{{log.installedDate}}</td>
                  <td>{{log.mobileno}}</td>
                  <td class="fw-600">{{log.ostype}}</td>
                  <td><span class="badge bgc-red-50 c-red-700 p-10 lh-0 tt-c badge-pill">{{log.versionnum}}</span></td>
                  <td><span class="text-success">{{log.appversion}}</span></td>
                </tr>
              </tbody>
            </table>
          </div>
          <ng-template #falsyTemplate>
             <h4 class="animated pulse text-center"  style="animation-duration: 1s;color:#999FBF"><i class="fa fa-frown-o"></i>&nbsp;No Logs to Display</h4>
          </ng-template>
        </div>
      </div>
      <div class="pagination pull-right" style="margin-right:15px;cursor:pointer;">
        <a data-toggle="tooltip" title="Previous" *ngIf="pageJump<1">&nbsp;<i class="fa fa-angle-double-left" aria-hidden="true" class="disabled"></i></a>
          <a  data-toggle="tooltip" title="Previous" *ngIf="pageJump>0" (click)="pageJump=pageJump-1;getParentAppInfo()">&nbsp;<i class="fa fa-angle-double-left" aria-hidden="true"></i></a>
        <a class="active" data-toggle="tooltip" title="Current Page">{{pageJump+1}}</a>
        <a  data-toggle="tooltip" title="Next" *ngIf="historyLog?.length > 0" (click)="pageJump=pageJump+1;getParentAppInfo()" >&nbsp;<i class="fa fa-angle-double-right" aria-hidden="true"></i></a>
          <a  data-toggle="tooltip" title="Next" *ngIf="historyLog?.length < 1" class="disabled" >&nbsp;<i class="fa fa-angle-double-right" aria-hidden="true"></i></a>
      </div>

    </div>
  </div>
  `,
  styles: [`

    .gap-20> {
      padding: 10px!important;
    }
    .w-100 {
      width: 100%!important;
    }
    .bgc-white, .bgcH-white:hover {
      background-color: #fff!important;
    }
    .bd {
      border: 1px solid rgba(0,0,0,.0625)!important;
    }
    .p-20 {
      padding: 20px!important;
    }
    .layers {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
    }
    .layers {
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -ms-flex-flow: column nowrap;
      flex-flow: column nowrap;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      box-shadow: 1px 1px 0px 0px lightgrey;
    }
    .mB-10 {
      margin-bottom: 10px!important;
    }
    .lh-1 {
      line-height: 1!important;
    }
    h6 {
      letter-spacing: .5px;
      font-size: 14px;
    }
    .peer{
      font-size:25px;
    }
    .peerText {
      font-size:20px;
      background: #f7f7f7;
    }
    .pX-15 {
      padding-left: 15px!important;
      padding-right: 15px!important;
    }
    .pY-15 {
      padding-top:5px!important;
      padding-bottom:5px!important;
    }
    .bdrs-10em {
      border-radius: 10em!important;
    }

    .ViewDateLogButton{
        font-size: 1.6em;
        width: 1.6em;
        text-align: center;
        line-height: 1.6em;
        background: #2396b9;
        color: #fff;
        border-radius: 30px;
        width: 45px;
        height: 45px;
        position: fixed;
        z-index: 999;
        right: 20px;
        bottom: 20px;
        cursor:pointer
      }
      .ViewDateLogButton > i {
        margin:12px auto;
      }

`  ],
})
export class DateWiseHistoryLogDir {

  private currentOrganization:any=this.globalService.getData().currentOrganization;
  private apiToken:any=localStorage.getItem("adminToken");
  public historyLog : any = [];
  public pageJump:number=0;
  public srchDateLog:any="";
  public displayLoaderText:boolean=true;
  public fromDateError:boolean;
  public daywiseDetailedData:any={};

  constructor(
    public router: Router,
    public globalService: globalService,
    private apiService: ApiService,
    public bootstrapDate: bootstrapDateTime
  ) {

  }

  ngOnInit(){
    this.getCalendar();
    //this.getParentAppInfo();
  }

  getCalendar() {
    this.bootstrapDate.datetime({
      component: 'selectedLogDate'
    });

  }

  filterFunction(){
     this.pageJump=0;
     this.getParentAppInfo();
     this.srchDateLog="";
  }

  initiateEmptyDateValidator(){
    let isValid: boolean = true;
    let fromdate = $('#selectedLogDate').val();

    if(!fromdate){
      this.fromDateError = true;
      isValid = false;
    }else {
      this.fromDateError = false;
    }
    return isValid;
  }


  getParentAppInfo=()=>{
    if(this.initiateEmptyDateValidator()){
      this.displayLoaderText = false;
      let parent:any =this;
      let fromdate = $('#selectedLogDate').val();
      console.log('FROM DATE : ',fromdate);
      let endDateeee = ($('#selectedLogDate').val()).split('-');
        let endDate1 = endDateeee[2].replace(/\s/g, "")+'-'+endDateeee[1]+'-'+endDateeee[0];
        console.log('endDate1 : ',endDate1);
      this.srchDateLog = endDate1;
      var infoObj = {
        data: {
          key: this.apiToken,
          form:{
            fromDate:this.srchDateLog.trim()
                    },
          extra:{
            pageJump:this.pageJump
          }
        }
      };
      this.apiService.getparentAppDetails(infoObj).then(response => {
        if (response) {
          if(response!='' && response!=undefined && response!=null)  {
            this.historyLog = response.response.historyLog;

            if(response.response.detailedData){
              this.daywiseDetailedData = response.response.detailedData
              console.log(this.daywiseDetailedData);
            }
          }
        };
      }).catch(error => { });
    }

  }






}
/*
Notes : npm install node-sass -g
*/
