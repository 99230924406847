import { Injectable } from '@angular/core';
import { RouterModule, Routes, Router } from '@angular/router';
import { Subject } from 'rxjs';
import { ApiService } from './ApiService';

@Injectable()

export class UtilityServices {

  public gpsDateTime: any;
  public convertedFormat: any;
  public _subject = new Subject;

  constructor(public router: Router, public apiService: ApiService) {

  }

  convertDateTime(time: any) {
    this.convertedFormat = new Date(time);
    let readableDate = this.convertedFormat.toLocaleString([], { hour12: true });
    //console.log(readableDate);
    return readableDate;
  }
  findSum(array: any) {
    return array.reduce(function (a: any, b: any) {
      return a + b;
    }, 0);
  }
  getAverageTime(times: any) {
    var count = times.length
    var timesInSeconds = [];
    // loop through times
    for (var i = 0; i < count; i++) {
      // parse
      var pieces = times[i].split(':');
      var ampm = pieces[2].split(' ');
      var hrs = Number(pieces[0]);
      var mins = Number(pieces[1]);
      var secs = Number(ampm[0]);
      var ampm = ampm[1];
      // convert to 24 hr format (military time)
      if (ampm == 'PM') hrs = hrs + 12;
      // find value in seconds of time
      var totalSecs = hrs * 60 * 60;
      totalSecs += mins * 60;
      totalSecs += secs;
      // add to array
      timesInSeconds[i] = totalSecs;
    }
    // find average timesInSeconds
    var total = 0;

    for (var j = 0; j < count; j++) {
      total = total + Number(timesInSeconds[j]);
    }
    var avg = Math.round(total / count);

    // turn seconds back into a time
    var avgMins = Math.floor(avg / 60);

    var avgSecs = avg - (60 * avgMins);
    var avgHrs = Math.floor(avgMins / 60);

    avgMins = avgMins - (60 * avgHrs);
    // convert back to 12 hr. format
    var avgAmpm = 'AM';
    if (avgHrs > 12) {
      avgAmpm = 'PM';
      avgHrs = avgHrs - 12;
    }
    // add leading zeros for seconds, minutes
    let secondsNo: any = '';
    let minutesNo: any = '';
    secondsNo = ('0' + avgSecs).slice(-2);
    minutesNo = ('0' + avgMins).slice(-2);
    if (avgHrs < 10) {
      return ('0' + avgHrs) + ':' + minutesNo + ':' + secondsNo;
    } else {
      return avgHrs + ':' + minutesNo + ':' + secondsNo;
    }
  }

  getRouteBasedPercentage(obj: any) {
    return new Promise((res, rej) => {
      let objectValues = Object['values'];
      let objKeys = Object.keys;
      let finalObj: any = [];
      let keys: any = objKeys(obj);
      for (let route in obj) {
        let values = objectValues(obj[route]);
        let badCnt: number = 0, len = 0;
        for (let pp of values) {
          let key: any = objKeys(pp);
          let bad: any = pp[key];
          if (bad['bad'] == 1) badCnt += 1;//finalObj[route]+=1;
          if (values.length - 1 == len) {
            let temp = {};
            temp[route] = Math.round((badCnt / values.length) * 100);
            finalObj.push(temp);
          }
          len++;
        }
        let index = keys.indexOf(route);
        if (index == keys.length - 1) {
          let sort = finalObj.sort((a: any, b: any) => {
            let ak = Object.keys(a)[0];
            let bk = Object.keys(b)[0];
            return b[bk] - a[ak];
          });
          res(sort);
        }
      }
    });
  }
  findGrade(avgReachedTriggeres: number) {
    // Start 
    let giveGrade = 0, tableGrades = '';
    if ((avgReachedTriggeres >= 5) && (avgReachedTriggeres <= 10)) {
      giveGrade = (0 / 10) * 100;
      tableGrades = 'GOOD';
    }
    if ((avgReachedTriggeres === 4) || (avgReachedTriggeres === 11)) {
      giveGrade = (1 / 10) * 100;
      tableGrades = 'GOOD';
    }

    if ((avgReachedTriggeres === 3) || (avgReachedTriggeres === 12)) {
      giveGrade = (2 / 10) * 100;
      tableGrades = 'GOOD';
    }
    //GOOD ENDS HERE

    //AVERAGE STARTS HERE

    if ((avgReachedTriggeres === 2) || (avgReachedTriggeres === 13)) {
      giveGrade = (3 / 10) * 100;
      tableGrades = 'AVG';
    }
    if (avgReachedTriggeres === 14) {
      giveGrade = (4 / 10) * 100;
      tableGrades = 'AVG';
    }
    if (avgReachedTriggeres === 15) {
      giveGrade = (5 / 10) * 100;
      tableGrades = 'AVG';
    }

    //BAD STARTS HERE
    if (avgReachedTriggeres === 16) {
      giveGrade = (6 / 10) * 100;
      tableGrades = 'BAD';
    }

    if (avgReachedTriggeres === 17) {
      giveGrade = (7 / 10) * 100;
      tableGrades = 'BAD';
    }
    if (avgReachedTriggeres === 18) {
      giveGrade = (8 / 10) * 100;
      tableGrades = 'BAD';
    }
    if ((avgReachedTriggeres === 19)) {
      giveGrade = (9 / 10) * 100;
      tableGrades = 'BAD';
    }
    if ((avgReachedTriggeres < 2)) {
      giveGrade = (10 / 10) * 100;
      tableGrades = 'BAD';
    }
    if ((avgReachedTriggeres >= 20)) {
      giveGrade = (10 / 10) * 100;
      tableGrades = 'BAD';
    }

    return ({ giveGrade: giveGrade, tableGrades: tableGrades });
  }
  findArraySum(array: any) {
    return new Promise((res, rej) => {
      let sum = array.reduce(function (a: any, b: any) {
        return a + b;
      }, 0);
      res(sum);
    });
  }

  getDays(val: any) {
    return new Date(val)
      .toLocaleString(window.navigator.language, {
        weekday: "long"
      })
      .toLowerCase();
  }
  checkWeekEnd(arr: any) {
    let lastVal = arr[arr.length - 1];
    let day = this.getDays(lastVal);
    let getIndex = 0;
    if (day == "saturday") {
      getIndex = 2;
    } else if (day == "sunday") {
      getIndex = arr.length >= 2 ? 3 : 0;
    }
    let index = getIndex > 0 ? arr.length - getIndex : arr.length - 1;
    index = index <= -1 ? 0 : index;
    let finalDate = this.getDays(arr[index]);
    return (finalDate == "saturday" || finalDate == "sunday")
      ? "No Movement Data"
      : arr[index];
  }

  getApiLoopData(apiMethod: any, paramData: any, subType: string, totalCountName: string) {
    return new Promise((res, rej) => {
      try {
        let ping = 1, i = 0;
        (async()=>{
          while (ping) {
            paramData['data']['extra'] = {
              pageJump: i,
            };
            let resData:any = await this.getAPIcall(apiMethod, paramData, totalCountName).catch((err) => { console.log('err', err); ping = 0; });
            if(resData && resData['data'] && resData['data'].length > 0){
              this._subject.next({
                type: subType,
                data: resData['data'],
                total: resData['totalCount'],
              })
            }else ping = 0;
            i++;
          }
        })();
      }
      catch (err) {
        console.log(err);
        rej()
      }
    })
  }

  getAPIcall(apiMethod: any, paramData: any, totalCountName:string) {
    return new Promise((response, reject) => {
      let returnData: any = {
        data: '',
        totalCount: 0,
      }
      try {
        this.apiService[apiMethod](paramData).then((res: any) => {
          if (res.status == 'success' && res.response) {
            returnData.data = res['response'];
            returnData.totalCount = res[totalCountName];
            response(returnData);
          } else {
            reject()
          }
        }).catch((err: any) => {
          console.log(err);
          reject();
        });
      } catch (err) {
        console.log(err);
        reject();
      }
    })
  }



}
