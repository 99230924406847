import { Observable, Subscription } from "rxjs";
import { NgRedux, select } from "@angular-redux/store";
import { AppState } from "../../../../store/index";
import { Component, Input, Output, EventEmitter } from "@angular/core";
import { MessageService } from "../../../../services/message.service";
import { ApiService } from "../../../../services/ApiService";
import { authGaurd } from "../../../../services/auth-guard.service";


import { animateMarker } from "../../../../services/markerAnimate";
import { IfStmt } from "@angular/compiler";
// import swal from "sweetalert";
const swal = require('sweetalert');
// subscription: Subscription;

declare var google: any;

@Component({
    selector: "route-plotting",
    template: `
        <div class="row text-white" [ngClass]=" bg_flag ? 'bg-primary' : 'bg-red'" style="padding:5px;">
            <div class="col-md-6 pull-left">Route Name : {{__routeInfo.routeName}}</div>

            <div class="col-md-1 pull-right pointer"><i class="fa fa-window-close fa-2x" aria-hidden="true" (click)="closebtnaction($event);"></i>
            </div>

            <div class="col-md-5 pull-right">Vehicle Number :{{__routeInfo.routeVehicleNumber}}</div>

            <div class="col-md-6 pull-left">Route Start Time : {{__routeInfo.routeStartTime}}</div>

            <div class="col-md-6 pull-right">Route Start Time : {{__routeInfo.routeEndTime}}</div>

        </div>
      <div class="row map route-map-div-align" style="height:81vh" id="mapRouteContainer"></div>
       
  `,
    styles: [
        `
      .route-map-div-align {
      }
      .map {
        background-color: lightgrey;
      }
      .pointer {
        cursor: pointer;
      }
      .bg-red{
          background:#95a5a6;
          color:#fff;
      }
    `,
    ],
})
export class RoutePlottingClientDashboard {
    public dataRequest: any = [];
    public bg_flag:boolean =  true;
    public __routeInfo: any;
    public locationArray: any = [];
    public latLngSplitArr: any = [];
    public globalMapObj: any;
    public countVal: any = [];
    public mapFlag: number = 0;
    public mapBounds: any = new google.maps.LatLngBounds();
    public routePoly: any;
    public polyOptions: any;
    public currentPlottedRecordPointer: number;
    public movingMarker: any;
    public showingRoute: boolean;
    public clearOut: any;
    public apiHeadersManipulation: any = {};
    public beatResponse: any = [];
    public lat_lng_check: any = [];
    public lasttimestamp: any;
    public globalView: number = 0;

    @Input("routeInfo")
    set newVehdata(routeobj: any) {
        this.locationArray = [];

        this.__routeInfo = routeobj;

        this.initMap("movement");
    }

    @Output()
    closebtn: EventEmitter<string> = new EventEmitter();

    @select("vehicleData")
    public vehicleData$: Observable<any>;

    constructor(
        private apiService: ApiService,
        public animate: animateMarker,
        private authtoken: authGaurd,
        private ngRedux: NgRedux<AppState>,
        public mess:MessageService,
    ) {
        this.currentPlottedRecordPointer = 0;
        // this.vehicleData$.subscribe(val => {
        //   // console.log(val);
        // });
    }

    // vehicledataid(routeobj: any) {
    //   this.reduxLastDataUpdate(routeobj);
    // }
    reduxLastDataUpdate(routeobj: any) {
        this.ngRedux.getState().vehicleData.filter((item: any) => {
            // console.log(item.deviceId === routeobj);

            if (item.deviceId === routeobj) {
                // console.log(item.location);

                // this.lat_lng_check.push(item.location);

                // console.log(this.lat_lng_check.length);
                // if (this.lat_lng_check.length > 2) {
                //   let prevLocation = this.lat_lng_check[0];
                //   let currLocation = this.lat_lng_check[1];

                //   console.log(prevLocation, currLocation);

                //   if (
                //     prevLocation.lat.toString() != currLocation.lat.toString() &&
                //     prevLocation.lng.toString() != currLocation.lng.toString()
                //   ) {
                //     new google.maps.Marker({
                //       position: item.location,
                //       icon: this.getRouteMarkerIcon("orange"),
                //       map: this.globalMapObj,
                //       // zIndex: path.getLength(),
                //     });
                //   }
                //   this.lat_lng_check = [];
                // }
                new google.maps.Marker({
                    position: item.location,
                    icon: this.getRouteMarkerIcon("orange"),
                    map: this.globalMapObj,
                    // zIndex: path.getLength(),
                });
                this.globalMapObj.panTo(item.location);

                this.globalMapObj.addListener("zoom_changed", () => {
                    this.globalView = this.globalMapObj.getZoom();
                });
                if (this.globalView != 0) {
                    this.globalMapObj.setZoom(this.globalView);
                } else {
                    this.globalMapObj.setZoom(13);
                }
            }
            return item.deviceId === routeobj;
        });
    }

    closebtnaction(closeEvent: any) {
        this.closebtn.emit("");
        
    }

    ngOnInit() {
        this.authtoken.checkToken();
        // this.get_data(this.__routeInfo);
    }

    initMap(mapCallBackType: string): void {
        const mapOptions = {
            scrollwheel: true,
            zoom: 12,
            maxZoom: 17,
            center: new google.maps.LatLng(28.7041, 77.1025),
            disableDefaultUI: true,
            zoomControl: true,
        };
        this.polyOptions = { visible: false };
        this.globalMapObj = new google.maps.Map(
            document.getElementById("mapRouteContainer"),
            mapOptions,
        );
        this.routePoly = new google.maps.Polyline(this.polyOptions);
        this.routePoly.setMap(this.globalMapObj);
        if (mapCallBackType == "movement") this.get_data(this.__routeInfo, 0);
    }

    binding(latlng: any) {
        this.mapBounds = new google.maps.LatLngBounds();
        for (let val of latlng) {
            const mLatlng = new google.maps.LatLng(val.lat, val.lng);
            this.mapBounds.extend(mLatlng);
        }
        // console.log('boundval setted');
        return this.globalMapObj.fitBounds(this.mapBounds);
    }

    get_data(routeobj: any, i: number = 0): void {
        let dateobj = new Date();
        let currentMonth: any = dateobj.getMonth() + 1;
        let currentDate: any = dateobj.getDate();
        if (currentMonth < 10) {
            currentMonth = "0" + currentMonth;
        } else {
            currentMonth = currentMonth;
        }
        if (currentDate < 10) {
            currentDate = "0" + currentDate;
        } else {
            currentDate = currentDate;
        }
        let today = `${dateobj.getFullYear()}-${currentMonth}-${currentDate}`;

        let apiHeader2 = {
            data: {
                key: localStorage.getItem("scbToken"),
                form: {
                    vehId: [routeobj.vehId],
                    fromDate: today,
                    // fromDate: '2018-10-10',
                    fromTime: routeobj.routeStartTime + ":00",
                    toDate: today,
                    // toDate: '2018-10-10',
                    toTime: routeobj.routeEndTime + ":00",
                    speed: {
                        movement: {
                            value: 5,
                        },
                    },
                },
                extra: {
                    pageJump: i,
                },
            },
        };

        this.apiService
            .getCommonReport(apiHeader2)
            .then(response => {
                if (response.response == null || response.response == []) {
                    this.plotDeviceRoute(this.locationArray);
                    // console.log(this.locationArray);
                    this.apiHeadersManipulation = Object.assign({}, apiHeader2);

                    // console.log(this.lasttimestamp);
                    this.livebeat();
                } else {
                    response.response.filter((item: any) => {
                        // console.log(response.response.length);
                        this.locationArray.push(item.location);
                        this.lasttimestamp = item.unicTimeStamp;
                    });
                    // this.dataRequest = [apiHeader2, Date.now()];
                    this.get_data(routeobj, i + 1);
                    // console.log(this.dataRequest);
                }
            })
            .catch(err => {
                // console.error(err);
                if(err){
                
                 
                
                swal({
                    title: "Available only when "+this.__routeInfo.routeName+" started",
                    // text: "You clicked the button!",
                    icon: "warning",
                  });
                  this.__routeInfo.routeName = ' No records found for '+this.__routeInfo.routeName; 
                  this.bg_flag =  false;
                }
            });
    }

    plotDeviceRoute(records: any): void {
        let bounds = new google.maps.LatLngBounds();
        let len = records.length;

        let $record = records[this.currentPlottedRecordPointer];

        let path = this.routePoly.getPath();

        if ($record.lat && $record.lng) {

            let mLatlng = new google.maps.LatLng(
                parseFloat($record.lat),
                parseFloat($record.lng),
            );
            let routeIcon: any;
            path.push(mLatlng);
            bounds.extend(mLatlng);
            if (this.currentPlottedRecordPointer == 0) {
                // console.log(this.currentPlottedRecordPointer);
                routeIcon = "../../../../assets/webroot/images/map/start.png";
            } else if (
                this.currentPlottedRecordPointer ==
                this.locationArray.length - 1
            ) {
                if (
                    this.getCurrentTimeStamp(this.apiHeadersManipulation, "true") == "true"
                ) {
                    routeIcon = this.getRouteMarkerIcon("navyblue");
                } else {
                    routeIcon = "../../../../assets/webroot/images/map/finish.png";
                }
            } else {
                routeIcon = this.getRouteMarkerIcon("navyblue");
            }
            let routeMarker = new google.maps.Marker({
                position: mLatlng,
                icon: routeIcon,
                map: this.globalMapObj,
                zIndex: path.getLength(),
            });
            if (this.currentPlottedRecordPointer == 0) routeMarker.setZIndex(9000);
            else if (
                this.currentPlottedRecordPointer ==
                this.locationArray.length - 1
            ) {
                routeMarker.setZIndex(9500);
            }
            if (this.movingMarker == undefined) {
                this.movingMarker = new google.maps.Marker({
                    position: new google.maps.LatLng(0, 0),
                    map: this.globalMapObj,
                    icon: "./.../../assets/webroot/images/map/bus_main.png",
                });
            } else {
                if (
                    !this.globalMapObj.getBounds().contains(this.movingMarker.getPosition())
                ) {
                    this.globalMapObj.panTo(mLatlng);
                }
                if (this.currentPlottedRecordPointer === this.locationArray.length - 1) {
                    this.movingMarker.setMap(null); //hide moving marker after plotting last record.
                }
            }

            // let parent = this;
            this.animate.animateTo(this.movingMarker, mLatlng, {
                easing: "linear",
                duration: 10,
                complete: () => {
                    if (this.locationArray.length > 0) {
                        // console.log(this.currentPlottedRecordPointer, this.locationArray.length);
                        this.showingRoute = true;
                        this.currentPlottedRecordPointer++;
                        if (this.currentPlottedRecordPointer < this.locationArray.length) {
                            this.plotDeviceRoute(this.locationArray);
                        } else {
                            return false;
                        }
                    } else {
                    }
                },
            });
        }
    }
    getRouteMarkerIcon(color: string): any {
        if (
            color == "red" ||
            color == "green" ||
            color == "orange" ||
            color == "blue" ||
            color == "navyblue"
        ) {
            return {
                url: "../../../../assets/webroot/images/map/map_" + color + ".png",
                size: new google.maps.Size(7, 7),
                origin: new google.maps.Point(0, 0),
                anchor: new google.maps.Point(3.5, 3.5),
            };
        } else {
            // console.error("color code error");
            return false;
        }
    }

    getCurrentTimeStamp(apiHeadersManipulation: any, flag: string) {
        let apiHeaderUnixTimeStampConvertion =
            `${this.apiHeadersManipulation.data.form.toDate}` +
            " " +
            `${this.apiHeadersManipulation.data.form.toTime}`;

        let apiHeaderLastTimeStamp = Math.round(
            new Date(apiHeaderUnixTimeStampConvertion).getTime() / 1000,
        );

        let currentTimeStamp = Math.round(Date.now() / 1000);

        if (flag === "passvehicleid") {
        
            return this.apiHeadersManipulation.data.form.vehId[0];
        }

        // if (flag === "assignDatestoResponse") {
        //   let currenttimestamp = Date.now();
        //   let lastupdatedtimestamp = this.lasttimestamp;

        //   // console.log(currenttimestamp, lastupdatedtimestamp);

        //   let currentTimeinStr = new Date().toLocaleTimeString("en-US", {
        //     hour12: false,
        //     hour: "numeric",
        //     minute: "numeric",
        //     second: "numeric",
        //   });
        //   let lastupdateTimeinStr = new Date(
        //     lastupdatedtimestamp,
        //   ).toLocaleTimeString("en-US", {
        //     hour12: false,
        //     hour: "numeric",
        //     minute: "numeric",
        //     second: "numeric",
        //   });

        //   if (currenttimestamp <= lastupdatedtimestamp) {
        //     console.log(currenttimestamp, lastupdatedtimestamp);
        //   }

        //   this.apiHeadersManipulation.data.form.fromTime = currentTimeinStr;

        //   delete this.apiHeadersManipulation.data.extra;

        //   console.log(this.apiHeadersManipulation);

        //   return this.apiHeadersManipulation;
        // }
        if (flag === "true") {
        
            return (apiHeaderLastTimeStamp >= currentTimeStamp).toString();
        }

        if (flag === "currentTimeStamp") {
     
            return currentTimeStamp;
        }
    }

    livebeat() {
        let urlString = window.location.href;

        var n = urlString.includes("client-dashboard");

        if (n === true) {
            if (
                this.getCurrentTimeStamp(this.apiHeadersManipulation, "true") == "true"
            ) {
                let getvehid = this.getCurrentTimeStamp(
                    this.apiHeadersManipulation,
                    "passvehicleid",
                );

                this.reduxLastDataUpdate(getvehid);
                // console.log(this.reduxLastDataUpdate(getvehid));
                // let apiHeaderResponseAssigned = this.getCurrentTimeStamp(
                //   this.apiHeadersManipulation,
                //   "assignDatestoResponse",
                // );
                // this.internalApiService(apiHeaderResponseAssigned);
            }

            this.clearOut = setTimeout(() => {
                this.livebeat();
            }, 5000);
        }
    }

    // internalApiService(apiBeatheaders: any = {}) {
    //   this.apiService
    //     .getCommonReport(apiBeatheaders)
    //     .then(response => {
    //       if (response.response == null || response.response == []) {
    //       } else {
    //         if (response.response) {
    //           response.response.filter((item: any) => {
    //             this.beatResponse.push(item.location);
    //             console.log(this.beatResponse);
    //           });
    //         }
    //       }
    //     })
    //     .catch(e => {
    //       console.error(e);
    //     });
    // }
}
