import { Injectable } from '@angular/core';
import { DatePipe } from '@angular/common'
import { async } from 'rxjs/internal/scheduler/async';
import {  ApiService } from './ApiService';
const objkeys = Object.keys;
@Injectable()

export class ChartControllerService{

    constructor( 
        private datepipe: DatePipe,
        private api: ApiService
        ){
          
    }

    getRouteVehicleInfo(obj:any){
      return new Promise((res,rej)=>{
          this.api.getCallAlertLog(obj).then((response:any)=>{
            let keys = Object.keys(response['response']);
            let vehID = response['response'][keys[0]][0].vehId;
            res(vehID);
          }).catch((error)=>{
            rej(error);
          })
      })
    }
    findArraySum(array: any){    
        return new Promise((res,rej)=>{
          let sum = array.reduce(function(a: any, b: any){
            return a + b;
        }, 0);
         res(sum);
        });
    }
    findGrade(avgReachedTriggeres:number){
        // Start 
        let giveGrade=0,tableGrades='';
        if((avgReachedTriggeres>=5) && (avgReachedTriggeres<=10) ){
          giveGrade=(0/10)*100;
          tableGrades='GOOD';
          }          
          if((avgReachedTriggeres===4) || (avgReachedTriggeres===11) ){          
          giveGrade=(1/10)*100;
          tableGrades='GOOD';
          }
          
          if((avgReachedTriggeres===3) || (avgReachedTriggeres===12) ){
           giveGrade=(2/10)*100;
          tableGrades='GOOD';
          }
          //GOOD ENDS HERE
          
          //AVERAGE STARTS HERE
          
          if((avgReachedTriggeres===2) || (avgReachedTriggeres===13) ){
            giveGrade=(3/10)*100;
            tableGrades='AVG';
          }
          if(avgReachedTriggeres===14){                  
            giveGrade=(4/10)*100;
            tableGrades='AVG';
          }
          if(avgReachedTriggeres===15){                  
            giveGrade=(5/10)*100;
            tableGrades='AVG';
          }
          
          //BAD STARTS HERE
          if(avgReachedTriggeres===16){
            giveGrade=(6/10)*100;
            tableGrades='BAD';
          }
          
          if(avgReachedTriggeres===17){                  
                giveGrade=(7/10)*100;
                tableGrades='BAD';
          }
          if(avgReachedTriggeres===18){                  
              giveGrade=(8/10)*100;
              tableGrades='BAD';
          }
          if((avgReachedTriggeres===19) ){                  
              giveGrade=(9/10)*100;
              tableGrades='BAD';
          }
          if((avgReachedTriggeres<2) ) {                  
              giveGrade=(10/10)*100;
              tableGrades='BAD';
          }
          if((avgReachedTriggeres>=20)) {                  
              giveGrade=(10/10)*100;
              tableGrades='BAD';
          }
        
        return({giveGrade:giveGrade,tableGrades:tableGrades});
    }
    getAverageTime(times:any) {
        var count = times.length
        var timesInSeconds = [];
        // loop through times
        for (var i =0; i < count; i++) {
            // parse
            var pieces = times[i].split(':');
            var ampm = pieces[2].split(' ');
            var hrs = Number(pieces[0]);
            var mins = Number(pieces[1]);
            var secs = Number(ampm[0]);
            var ampm = ampm[1];
            // convert to 24 hr format (military time)
            if (ampm == 'PM') hrs = hrs + 12;   
            // find value in seconds of time
            var totalSecs = hrs * 60 * 60;
            totalSecs += mins * 60;
            totalSecs += secs;
            // add to array
            timesInSeconds[i] = totalSecs;
        }
        // find average timesInSeconds
        var total = 0;
        
        for (var j =0; j < count; j++) {
            total = total + Number(timesInSeconds[j]);
        }
        var avg = Math.round(total / count);
      
        // turn seconds back into a time
        var avgMins = Math.floor(avg/60);
        
        var avgSecs = avg - (60 * avgMins);
        var avgHrs = Math.floor(avgMins/60);
      
        avgMins = avgMins - (60 * avgHrs);
        // convert back to 12 hr. format
        var avgAmpm = 'AM';
        if (avgHrs > 12) {
            avgAmpm = 'PM';
            avgHrs = avgHrs - 12;
        }
        // add leading zeros for seconds, minutes
        let secondsNo:any='';
        let minutesNo:any='';
        secondsNo = ('0' + avgSecs).slice(-2);
        minutesNo = ('0' + avgMins).slice(-2);
        if(avgHrs<10){
            return ('0'+avgHrs)+':'+minutesNo+':'+secondsNo;
            }else{ 
            return avgHrs+':'+minutesNo+':'+secondsNo;
            }
    } 
    timeStampToHumanRead(TMS:number){
      let pad = (num: number) => {
        return num > 9 ? num : "0" + num;
      };
        let date = new Date(TMS);
        return pad(date.getHours())+':'+pad(date.getMinutes())+':'+pad(date.getSeconds());
      }
    
    getTimeAvgValue(dateValue: any){      
        let splitcallMadeAvg = dateValue.split(':');
        var mergeHourMins = ' '+splitcallMadeAvg[0] + ':' + splitcallMadeAvg[1]+ ':' + splitcallMadeAvg[2];
        let dummyDate='02/06/2020';
        let etaTimeDate = dummyDate.concat(mergeHourMins);
        var date = new Date(etaTimeDate);           
        var etamilliseconds = date.getTime();
        return etamilliseconds;
      }
    async filterSearchKeyAreaChart(data: any,allPP:any,serchKey:any){
       return new Promise(async(res,rej)=>{
        let obj = data['data'];
        let responce = data['response'];
        let callMadeAvg={};
        let reachedTMAvg ={};
        let expectedTMS ={};
        let finalData: any=[];
        let pickupPointInfo:any={};
        let routePickupPoints={};
        let keys: any = objkeys(obj);
        let avgPickupPoint: any={};
        for(let date in obj){
          // if(callMadeAvg[date]==undefined) callMadeAvg[date]={};
            for(let p in obj[date]){
                let data = obj[date][p].split('#$#');
                let callMadeTM = data[2];
                let expectedTM = data[1];
                let reachedTM = data[0];
                let rname = data[data.length-1];
                let pickupPoints: any= responce[p]; // console.log('responce[p]',responce[p]);
                let pickPointSum: any = await this.findArraySum(Object["values"](pickupPoints));
                let totKeysLen = objkeys(pickupPoints).length;
                if(routePickupPoints[rname]==undefined) routePickupPoints[rname]=[];
                if(avgPickupPoint[rname]==undefined) avgPickupPoint[rname] ={};
                if(avgPickupPoint[rname][p]==undefined) avgPickupPoint[rname][p] ={};
                let avgReachedTriggeres = Math.round(pickPointSum/totKeysLen);
                avgPickupPoint[rname][p] ={
                    avg: avgReachedTriggeres,
                    data: data
                  };
                if(pickupPointInfo[p]==undefined) pickupPointInfo[p]={};
                if(pickupPointInfo[p][date]==undefined) pickupPointInfo[p][date]=[];                       
                if(callMadeAvg[rname]==undefined) callMadeAvg[rname]={};
                if(reachedTMAvg[rname]== undefined) reachedTMAvg[rname]={};
                if(expectedTMS[rname]== undefined) expectedTMS[rname]={};
                if(callMadeAvg[rname][p]==undefined) callMadeAvg[rname][p]=[];
                if(reachedTMAvg[rname][p]== undefined) reachedTMAvg[rname][p]=[];
                if(expectedTMS[rname][p]== undefined) expectedTMS[rname][p]=[];
                var callMadeMSDate = new Date(parseInt(callMadeTM, 10)); 
                let callMadeDateFormatted =this.datepipe.transform(callMadeMSDate.toString(), 'HH:mm:ss');
                var reachedMSDate = new Date(parseInt(reachedTM, 10)); 
                let reachedDateFormatted =this.datepipe.transform(reachedMSDate.toString(), 'HH:mm:ss');
                reachedTMAvg[rname][p].push(reachedDateFormatted);
                callMadeAvg[rname][p].push(callMadeDateFormatted);
                expectedTMS[rname][p].push(expectedTM.slice(0,2)+':'+expectedTM.slice(2)+':00');
                pickupPointInfo[p][date].push({
                        "callMadeTM":callMadeTM,
                        "expectedTMS": expectedTM,
                        "reachedTMS": reachedTM,
                        "RouteName":  avgPickupPoint[rname][p]['data'][4],
                        "pickupName":allPP[p]
                })
              }
          if((keys.indexOf(date)) == (keys.length-1)){
             if(serchKey=='pp') res(pickupPointInfo);
            let routes = objkeys(callMadeAvg);
            for(let r=0; r<routes.length; r++){
                    let rname = routes[r];
                    if(finalData[rname]==undefined) finalData[rname]=[];
                    let pickup = objkeys(callMadeAvg[rname]);
                   for(let p=0;p<pickup.length;p++){
                            let pID = pickup[p];
                            // if(pickupPointInfo[pID]==undefined) pickupPointInfo[pID]={};
                            routePickupPoints[rname].push(pID);
                            let callMade = callMadeAvg[rname][pID]; 
                            let calMadeAvgGrade = this.findGrade(avgPickupPoint[rname][pID]['avg']);
                            let callMadeAvgVal = this.getTimeAvgValue(this.getAverageTime(callMade));
                            let reachedTimeDate = this.getAverageTime(reachedTMAvg[rname][pID]);
                            let expectedTimeDate = this.getAverageTime(expectedTMS[rname][pID]);
                            let callMadHM= this.timeStampToHumanRead(callMadeAvgVal);
                            let tempData={
                              date: Number(callMadeAvgVal),
                              value: Number(calMadeAvgGrade['giveGrade']),
                              pickupName:allPP[pID],
                              callMade: callMadHM,
                              reachedTM: reachedTimeDate,
                              expectedTM:expectedTimeDate,
                              diff: avgPickupPoint[rname][pID]['avg'],
                              RouteName:  avgPickupPoint[rname][pID]['data'][4]
                            };
                            // let dates = new Date(Number(callMade)).toLocaleString().split(',')[0];
                            // if(pickupPointInfo[pID][dates]==undefined) pickupPointInfo[pID][dates]=[];
                            finalData[rname].push(tempData);
                            // pickupPointInfo[pID][dates].push(tempData);

                   }
                   if(r==routes.length-1){
                       let resData = {'areaChart':finalData,'routePp':routePickupPoints};
                       res(resData);
                   }
            }
    
                            
          }
        }
    })
    }
    findPickupPointPerformance(obj: any) {
        return new Promise((res, rej) => {
          let retObj = {}
          let good1 = 0, avg1 = 0, bad1 = 0;
          let i = 1;
          for (let key in obj) {
            if (retObj[key] == undefined) retObj[key] = {};

              let good = 0, avg = 0, bad = 0;
              let timeDiff = obj[key]; 
              if (timeDiff >= -2 && timeDiff <= 2) good++;
              if ((timeDiff > -5 && timeDiff <= -3) || (timeDiff >= 3 && timeDiff < 5)) avg++;
              if (timeDiff <= -5 || timeDiff >= 5) bad++;
              if(good==1) good1 += 1;
              if(avg==1) avg1 += 1;
              if(bad==1) bad1 += 1;
              retObj[key] = { good: good, avg: avg, bad: bad };             
              if (Object.keys(obj).length == i) { 
                let retVal = {
                  "eachPickPoint": retObj,
                  "overallPickPoint": {
                    "good": good1,
                    "avg": avg1,
                    "bad": bad1
                  }
                };
                res(retVal);
              }
              i++;
          }
    
        });
      }
    async filterSearchKeyDonutChart(filterObj:any,searchKey:any,obj:any){
        return new Promise(async(res,rej)=>{
                let pickupPoints = filterObj['routePp'][searchKey];
                let pickupPointData = obj['response'];
                let avgPickupPoint: any = {};
                for(let pp=0;pp<pickupPoints.length;pp++){
                    let pickupID = pickupPoints[pp];
                    let ppData = pickupPointData[pickupID];
                    let pickPointSum: any = await this.findArraySum(Object["values"](ppData));
                    let totKeysLen = objkeys(ppData).length;
                    if (avgPickupPoint[pickupID] == undefined) avgPickupPoint[pickupID] = {}
                    avgPickupPoint[pickupID] = Math.round(pickPointSum / totKeysLen);
                    if (pickupPoints.length - 1 == pp) {
                       let performanceResult = await this.findPickupPointPerformance(avgPickupPoint);
                        console.log('performanceResult',performanceResult)
                       let donutChart = [
                         {
                           name: 'GOOD',
                           y: Math.round(performanceResult['overallPickPoint'].good / pickupPoints.length * 100),
                           totCnt: performanceResult['overallPickPoint'].good,
                           color: '#a1b126',
                           sliced: false
                         },
                         {
                           name: 'AVG',
                           y: Math.round(performanceResult['overallPickPoint'].avg / pickupPoints.length * 100),
                           totCnt: performanceResult['overallPickPoint'].avg,
                           color: '#ef870d',
                           sliced: false
                         },
                         {
                           name: 'BAD',
                           y: Math.round(performanceResult['overallPickPoint'].bad / pickupPoints.length * 100),
                           totCnt: performanceResult['overallPickPoint'].bad,
                           color: '#e03e03',
                           sliced: true
                         }
                       ];

                   
                       res(donutChart);
                       
                }
            }
        })  
    }
    difftimestamp(time1: number, time2: number, flag: string) {
        let time;
    
        time = Math.abs((time1 / 1000) - (time2 / 1000));
        time = Math.floor(Math.floor(time) / 60);
        if (flag === 'grade') {
          if (time <= 4) {
            return " C ";
          }
          if (time >= 5 && time <= 10) {
            return " A ";
          }
          if (time >= 11 && time <= 15) {
            return " B ";
          }
          if (time > 15) {
            return " D ";
          }
    
        }
        if (flag === 'time') {
          return time;
        }
        if (flag === 'time_str') {
    
          return time <= 1 ? time + ' min' : time + ' mins';
    
        }
        if (flag === 'time_str_no_mins') {
    
          return time;
        }
    
    }
    sortValue(arr: any, type: string, key: string) {
        let obj = {};
        let res = arr.sort((a: any, b: any) => {
          let k1: any = Object.keys(a);
          let k2: any = Object.keys(b);
          if (type == "asc") return a[k1][key] > b[k2][key] ? 1 : -1;
          else return a[k1][key] < b[k2][key] ? 1 : -1;
        });
        return res;
    }
    async filterSearchKeyColumnChart(searchKey:any,response:any,filterObj:any){
         return new Promise((res,rej)=>{
             try{
                let keys = objkeys(response.data);
                let pickupPoints = filterObj['routePp'][searchKey];
                let obj = response['data'];
                let TMSObj = {};
                let roundTime = (time: any, minutesToRound: number) => {            
                  let [hours, minutes] = time.split(':');
                  hours = parseInt(hours);
                  minutes = parseInt(minutes);
                  time = (hours * 60) + minutes;
                  let rounded = Math.round(time / minutesToRound) * minutesToRound;
                  let rHr: any = '' + Math.floor(rounded / 60)
                  let rMin: any = '' + rounded % 60
                  return rHr.padStart(2, '0') + ':' + rMin.padStart(2, '0')
                };
                let dateTMS = (tms:any,Rtms:any) => { 
                  let d:any =new Date(Number(Rtms)); 
                  let date =
                  d.getFullYear() + "/" + parseInt(d.getMonth() + 1) + "/" + d.getDate();
                  return new Date(date + " " + tms).getTime();
                };
                // console.log('boj-pip',response,'res',obj,pickupPoints);
                for (let date in obj) {
                        for(let p=0;p<pickupPoints.length;p++){
                            let pp = pickupPoints[p];
                            let good = 0, avg = 0, bad = 0;
                            let data = obj[date][pp].split('#$#');
                            let callMadeTM = data[2];
                            let expectedTM = data[1];
                            let exTMS = roundTime(expectedTM.slice(0, 2) + ':' + expectedTM.slice(2, 4), 5);
                            let expectedTime = dateTMS(exTMS,callMadeTM); 
                            let reachedTM = data[0];
                            if (TMSObj[exTMS] == undefined) TMSObj[exTMS] = {};
                            let timeDiff = this.difftimestamp(expectedTime, Number(reachedTM), 'time_str_no_mins');
                            if (TMSObj[exTMS]['good'] == undefined) TMSObj[exTMS]['good'] = 0;
                            if (TMSObj[exTMS]['bad'] == undefined) TMSObj[exTMS]['bad'] = 0;
                            if (TMSObj[exTMS]['avg'] == undefined) TMSObj[exTMS]['avg'] = 0;
                            if (timeDiff >= -2 && timeDiff <= 2) good++;
                            if ((timeDiff > -5 && timeDiff <= -3) || (timeDiff >= 3 && timeDiff < 5)) avg++;
                            if (timeDiff <= -5 || timeDiff >= 5) bad++;
                            if (bad == 1) TMSObj[exTMS]['bad'] += 1;
                            if (good == 1) TMSObj[exTMS]['good'] += 1;
                            if (avg == 1) TMSObj[exTMS]['avg'] += 1;
                        }
                        let pad = (num: number) => {
                            return num > 9 ? num : "0" + num;
                          };
                          if (keys.indexOf(date) == keys.length - 1) {
                            let objKeys = Object.keys(TMSObj);
                            let tms = (arr: any) => {
                              return arr.map((itm: any, i: any) => {
                                let m: any = Number(itm.slice(3, 5));
                                let h: any = Number(itm.slice(0, 2));
                                let temp: any = 0;
                                if (m == 0)
                                  temp = pad(parseInt(h) - 1) + ":" + pad(60 - 2) + " - " + pad(h) + ":" + pad(2);
                                else
                                  temp = pad(h) + ":" + pad(m - 2) + " - " + pad(h) + ":" + pad(parseInt(m + 2));
                                //  console.log('retArray',itm);
                                let tmpObj = {};
                                tmpObj[temp] = TMSObj[itm];
                                return tmpObj;
                              });
                            }
                            let finalArray = tms(objKeys);
                            let sortedVal: any = this.sortValue(finalArray, 'desc', 'bad');
                           
                            let columnChartObj = {};
                            let category = [];
                            let bad = [], avg = [], good = [];
                            for (let i in sortedVal) {
                              let int: any = i;
                              let key = Object.keys(sortedVal[i])[0];
                              category.push(key);
                              good.push(sortedVal[i][key]['good']);
                              avg.push(sortedVal[i][key]['avg']);
                              bad.push(sortedVal[i][key]['bad']);
                              //  console.log((sortedVal.length-1)==int)
                              if ((sortedVal.length - 1) == int) {
                                if (columnChartObj['category'] == undefined)
                                    columnChartObj['category'] = [];
                                if (columnChartObj['series'] == undefined)
                                     columnChartObj['series'] = [];
                    
                                columnChartObj['category'] = category;
                                columnChartObj['series'].push(
                                  {
                                    name: 'Bad',
                                    data: bad,
                                    color: '#e03e03'
                                  });
                                columnChartObj['series'].push({
                                  name: 'Good',
                                  data: good,
                                  color: '#a1b126'
                                });
                                    columnChartObj['series'].push({
                                  name: 'Average',
                                  data: avg,
                                  color: '#ef870d'
                                });
                                res(columnChartObj);
                              }
                            }
                          }
                }

             }catch(error){
                 rej({});
             }
         })
    }

     areaChartFilterPickupPoint(searchKey:any,response:any,pickupPoints:any){
        return new Promise(async(res,rej)=>{
          try{
          let calculate = await this.filterSearchKeyAreaChart(response,pickupPoints,'pp');
          let calenderViewData={};
          let dateTMS = (tms:any,Rtms:any) => { 
            let d:any =new Date(Number(Rtms)); 
            let date =
            d.getFullYear() + "/" + parseInt(d.getMonth() + 1) + "/" + d.getDate();
            return new Date(date + " " + tms).getTime();
          };
          let keys = objkeys(calculate);
          // let lineChart1 =[];
          let lineChartData:any ={};
          for(let p=0; p<keys.length;p++){
             let pp:any = keys[p];            
              if(pp==searchKey){
                  let dates = objkeys(calculate[searchKey]);
                  for(let d=0; d<dates.length;d++){
                       let date = dates[d];
                      //  if(calenderViewData[date]==undefined) calenderViewData[date]={}
                       let eta = calculate[searchKey][date][0].expectedTMS;
                       let reachedTM = calculate[searchKey][date][0].reachedTMS;
                       let etaTM = eta.slice(0,2)+':'+eta.slice(2, 4);
                       let etaTMS = dateTMS(etaTM,reachedTM);
                       let timeDiff =this.difftimestamp(Number(etaTMS ), Number(reachedTM), 'time_str_no_mins');
                       calculate[searchKey][date][0]['timeDiff'] = timeDiff;
                       if(lineChartData[date]==undefined) lineChartData[date]={};    
                       let tempType='';
                       if (timeDiff >= -2 && timeDiff <= 2) tempType = "good";
                       if ((timeDiff > -5 && timeDiff <= -3) || (timeDiff >= 3 && timeDiff < 5)) tempType = "avg";
                       if (timeDiff <= -5 || timeDiff >= 5) tempType = "bad";
                       
                       calenderViewData[date.split('-').join('')]=tempType;                  
                       let reachedTimes:any = this.timeStampToHumanRead(Number(reachedTM))
                       let reachedTime:any = reachedTimes.split(":");
                       let temp={
                        callMadeTM:  calculate[searchKey][date][0].callMadeTM,
                        callMadeTime: this.timeStampToHumanRead(Number(calculate[searchKey][date][0].callMadeTM)),
                        expectedTMS: etaTM,
                        expectedTime: this.timeStampToHumanRead(Number(eta)),
                        reachedTMS: reachedTM,
                        RouteName: calculate[searchKey][date][0].RouteName,
                        pickupName: calculate[searchKey][date][0].pickupName,
                        timeDiff: timeDiff,
                        reachedTime: reachedTime[0]+':'+reachedTime[1]
                       }
                       lineChartData[date]=temp;
                       if(d==dates.length-1){
                         res({'lineChar':lineChartData,'calender':calenderViewData});
                      }
                  }
                 
                  
              }
          }
        }catch(error){ 
          console.log('Error',error);
          rej([]);
        }
        });
    }
}



