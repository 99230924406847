import { Component } from '@angular/core';
 
 let incr=1;

@Component({

    selector:'smslog',
    template:`
    
    <div>
  
          <td>{{inc}}</td>
          <td> <ng-content select="[studName]"></ng-content></td>
          <td> <ng-content select="[studId]"></ng-content></td>
          <td> <ng-content select="[studClass]"></ng-content></td>
          <td> <ng-content select="[studSection]"></ng-content></td>
          <td> <ng-content select="[studTime]"></ng-content></td>
          <td> <ng-content select="[studMobile]"></ng-content></td>
           
    </div>
       
    
    
    `,
    styles:[`

    div td:first-child{
        text-align:right;
        width:2%;
    }
    
      div td{
        min-width: 94px;
    width: 126px;
    max-width: 126px;
    column-width: 100px;
    padding: 6px;
    border-bottom: 1px solid #ddd;
    border-right: 1px solid #ddd;
    border-left: 1px solid #ddd;
    overflow-y: hidden;
    vertical-align: middle;
    }
  
    `]

})


export class RfidSMSLog {
public inc:number;
   constructor(){
        this.inc=incr++;
   }

   ngOnDestroy(){
       this.inc=1;
       incr=1;
   }

}