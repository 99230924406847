export const colorLine: any = {

    bad: {
        line: { width: '167px', height: '2px', background: 'red', position: 'absolute', left: '44px', top: '67px' },
        m1: { position: 'absolute', left: '50px', top: '50px', },
        m2: { position: 'absolute', left: '215px', top: '50px', },
        mapMarker: '<i class="fa fa-dot-circle-o" aria-hidden="true" style="color:red"></i>',
        color: 'tomato',
        reason: 'Bus reaches the stop consistently much earlier/later than ETA. Change in ETA strongly recommended',

    },

    avg: {
        line: { width: '117px', height: '2px', background: '#ef870d', position: 'absolute', left: '65px', top: '67px' },
        m1: { position: 'absolute', left: '70px', top: '50px', },
        m2: { position: 'absolute', left: '185px', top: '50px', },
        mapMarker: '<i class="fa fa-dot-circle-o" aria-hidden="true" style="color:#ef870d"></i>',
        color: '#ef870d',
        reason: 'Bus reaches the stop a little earlier/later than the ETA. Consider revising ETA if the trend continues'
    },

    good: {
        line: { width: '62px', height: '2px', background: '#4caf50', position: 'absolute', left: '94px', top: '67px' },
        m1: { position: 'absolute', left: '100px', top: '50px', },
        m2: { position: 'absolute', left: '160px', top: '50px', },
        mapMarker: '<i class="fa fa-dot-circle-o" aria-hidden="true" style="color:#4caf50"></i>',
        color: '#4caf50',
        reason: 'Bus reaches the stop on time. No change in ETA required'
    }
}