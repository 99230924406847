import { Component, OnInit, Input, Output, OnChanges, EventEmitter, } from '@angular/core';
import {
  trigger,
  state,
  style,
  animate,
  transition
} from '@angular/animations';
@Component({
  selector: 'push-alerts',
  styles: [`
         ul.notifications-list{
         position: absolute;
         bottom: 0;
         right: 0;
      }
         ul.notifications-list li{
          background: none;
          border: none;
      }
        .notif-container {
            padding: 10px;
            color: white;
            border-radius: 3px;
            right: 0px;
            max-width: 350px;
            min-height:50px;
          }

        .closebtn {
            margin-left: 15px;
            color: white;
            font-weight: bold;
            float: right;
            font-size: 22px;
            line-height: 20px;
            cursor: pointer;
            transition: 0.3s;
        }

        .closebtn:hover {
          color: black;
        }

        .overspeed{
          background-color: #ff7702e8;
        }
        .sos{
          background-color: #e91e63fc;
        }

    `],
  template: `
        <ul class="list-group notifications-list">
            <li class="list-group-item" *ngFor="let notif of notificationsList">
                <div class="notif-container" [ngClass]="notif.data.category">
                    <span class="closebtn" onclick="this.parentElement.style.display='none';">&times;</span>
                    <div class="text-uppercase mb5" style="font-weight:500;font-size: 16px;">{{notif.data.category}}</div>
                    <div class="notif-message">{{notif.data.message}}</div>
                </div>
            </li> 
        </ul>
    `,
  animations: [
    trigger('dialog', [
      transition('void => *', [
        style({ transform: 'scale3d(.3, .3, .3)' }),
        animate(100)
      ]),
      transition('* => void', [
        animate(100, style({ transform: 'scale3d(.0, .0, .0)' }))
      ])
    ])
  ]
})

export class pushAlertsComponent  {
      public notificationsList:any = [];
      private notifTypeLookUp = {
          sos:'notif-sos',
          overspeed:'notif-overspeed'
      }

      @Input('socketNotifs')
        set liveNotidfs(notif:any){
          this.pushLIveNotifs(notif);
        }

      constructor() { }
      ngOnInit(){
          let notifDummy1 = {
              type:"pushNotifications",
              data:{
                category:"sos",
                gpsTime:1519813359000,
                message:"SOS triggered for VEHICLE at 2018-02-28 15:52:39"
              },
          }

          let notifDummy2 = {
              type:"pushNotifications",
              data:{
                category:"overspeed",
                gpsTime:1519813359000,
                message:"VEHICLE is overspeeding at 2018-02-28 15:52:39,currently moving at 80Km/h"
              },
          }
          this.notificationsList.push(notifDummy1);
          this.notificationsList.push(notifDummy2);

      }

      pushLIveNotifs(notif:any){
          if(notif) {
              if(this.notificationsList.length >4){
                  this.notificationsList.splice(0,1);
                  this.notificationsList.push(notif);
              }
          }
      }
  }
