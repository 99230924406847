import { Component, Output, EventEmitter } from "@angular/core";

let serialNumberIncrement = 0;

@Component({

    selector: 'serial-number',
    template: `
            <ng-content select="[callproceesedrepo]">
             </ng-content>
             <ng-container *ngIf="falsytemplate">
            <ng-content select="[notFound]">
            </ng-content>
            </ng-container>
           
           `

})

export class SerialNumberComponent {
    serial_number: number;
    falsytemplate:boolean =false;

  
    // @Output() public falsytemplate: EventEmitter<boolean> = new EventEmitter();
    constructor() {
        this.serial_number = ++serialNumberIncrement;
    }
    ngOnInit() {
               
        if (serialNumberIncrement<=1) { 
                this.falsytemplate = true; 
            }else {
                this.falsytemplate = false; 
            }

          
    }
    ngOnDestroy() {
        this.serial_number = 0;
        serialNumberIncrement = 0;
    }
}