
import { Component, OnInit, Input, EventEmitter } from '@angular/core';
import { ApiService } from './../../../services/ApiService';
import { chosenUtility } from './../../../services/chosen-utility/jq-chosen-utility.js';
import { bootstrapDateTime } from './../../../services/bootstrapDateTime/datePicker';
import { GlobalUtils } from './../../../services/global-utils';
import { COM } from './../../../services/com.services';
import { ApiCallDateFormatService } from "../../../services/api-call-date-format.service";

//import { } from '@types/googlemaps';

declare const $: any;
declare const google: any;
@Component({
    providers: [bootstrapDateTime],
    selector: 'breakdown-reports',
    template: `
    <div class="reportHeading text-center">Breakdown Message Log</div> 
    <div *ngIf="breakdownMsgObj !== 'null' && breakdownMsgObj?.length > 0 ">
      <div class="row" style="margin: 0; margin-bottom: 12px;">
          <span class="countSpan"> Total Count : {{totBreakdownMsgCount}}</span>
      </div>
      <table class="table table-condensed table-bordered animated fade-in">
          <thead>
              <tr>
                  <th> S.No</th>
                  <th> Route Name </th>
                  <th> Pickup Name </th>
                  <th> Message Type</th>
                  <th> Log Time </th>
                  <th> Mobile Number</th>
                  <th> Message</th>
              </tr>
          </thead>
          <tbody>
              <ng-container *ngFor="let msgObj of breakdownMsgObj|objectkeys; let sno = index;">
              <tr>
                  <td>{{sno +1}}</td>
                  <td> {{breakdownMsgObj[msgObj].routeName}} </td>
                  <td >
                   <span *ngIf="breakdownMsgObj[msgObj].pickupName && breakdownMsgObj[msgObj].pickupName?.length > 0; "  title="{{breakdownMsgObj[msgObj].pickupName}}" > {{ ((breakdownMsgObj[msgObj].pickupName?.length > 15) ? (breakdownMsgObj[msgObj].pickupName| slice:0:15) + '...' : breakdownMsgObj[msgObj].pickupName )}}
                  </span>
                  <span *ngIf="!breakdownMsgObj[msgObj].pickupName">
                       --
                  </span>
                  </td>
                  <td>
                      <span *ngIf="breakdownMsgObj[msgObj].breakdownType === '1'">
                           Breakdown
                      </span>
                      <span *ngIf="breakdownMsgObj[msgObj].breakdownType === '0'">
                          Resolve
                      </span>
                  </td>
                  <td>
                      {{breakdownMsgObj[msgObj].logTimeMS|date:'dd-LL-yyyy hh:mm:ss'}}
                  </td>
                  <td>
                      {{breakdownMsgObj[msgObj].mobileno1}}
                  </td>
                  <td>
                           <span title="{{breakdownMsgObj[msgObj].messages}}">
                  {{ ((breakdownMsgObj[msgObj].messages?.length > 15) ? (breakdownMsgObj[msgObj].messages| slice:0:15) + '...' : breakdownMsgObj[msgObj].messages )}}
                            </span>
                  </td>

              </tr>
          </ng-container>
          </tbody>
      </table>
      </div> 
  <div *ngIf="loadCompo === true && breakdownMsgObj !== 'null' "  class="pagination pull-right" style="margin-right:15px;cursor:pointer;" >
      <a data-toggle="tooltip" title="Previous" *ngIf="pageJump<1">&nbsp;<i class="fa fa-angle-double-left" aria-hidden="true" class="disabled"></i></a>
      <a  data-toggle="tooltip" title="Previous" *ngIf="pageJump>0" (click)="pageJump=pageJump-1;breakDownReport(pageJump)">&nbsp;<i class="fa fa-angle-double-left" aria-hidden="true"></i></a>
      <a class="active" data-toggle="tooltip" title="Current Page">{{pageJump+1}}</a>
      <a  data-toggle="tooltip" title="Next" *ngIf="breakdownMsgObj?.length >= 100" (click)="pageJump=pageJump+1;breakDownReport(pageJump)" >&nbsp;<i class="fa fa-angle-double-right" aria-hidden="true"></i></a>
      <!--<a  data-toggle="tooltip" title="Next" *ngIf="breakdownMsgObj?.length < 100" class="disabled" >&nbsp;<i class="fa fa-angle-double-right" aria-hidden="true"></i></a>-->
  </div>  


  
  <div class="" *ngIf="loadCompo === true && breakdownMsgObj === 'null' ">
       <h4 class="animated pulse text-center"  style="animation-duration: 1s;color:#999FBF; margin:70px;"><i class="fa fa-frown-o"></i>&nbsp;
                              No Data to Display</h4>
   </div>

   <div class="loaderContainer" *ngIf="pageLoader">
   <loader></loader>
</div>
   
    `,
    styles: [`
    .availableReportOptions{
        border-right:1px solid #9e9e9e91;
        padding: 0.9em;
    }
    .reportHeading{
        font-size:1.7em;
    }
    div.fixedHeight{
        height: 605px;
        overflow-y:auto;
        overflow-x:hidden;
    }
    .applyFilterBtn, .viewReportBtn{
        color: #fff;
        background-color: #64c7e9;
        border-color: #64c7e9;
        padding: 12px 10px 12px 10px !important;
        font-size: 11px;
    }

    td.moving{
        color:#2EA737;
    }

    td.offline{
        color:#696969;
    }

    td.halt{
        color:red;
    }



    label.basicDetailsLabel {
        font-weight: 600;
        font-size: 14px;
    }
    .reportExcelDownloadLink{
        text-decoration: none;
        cursor: pointer;
    }
    .fixedHeightPanelBody{
        max-height:472px;
        overflow:auto;
    }
    li.selected{
        background-color:#f9f9f9;
    }

    .haltLocation{
        cursor:pointer;
        color: #777 !important;
     }
     .countSpan{
        background: gainsboro;
        padding: 5px 6px 2px 4px;
        border-radius: 5px;
    }
     .routeTile{
        height: 10px;
        width: 10px;
        background: #9e9e9e21;
        padding: 5px;
     }

    .custom-table tbody {
        display:block;
        height:440px;
        overflow:auto;
      }
    .custom-table thead, .custom-table tbody tr {
        display:table;
        width:100%;
        table-layout:fixed;
    }
      
    .custom-table th {
    color: rgba(0, 0, 0, 0.54);
    font-size: 15px;
    font-weight: 500;
    white-space: nowrap;
    }
    .custom-table tbody td{
    vertical-align:middle !important;
    font-size: 13px !important;
    border-top: 1px rgba(0, 0, 0, 0.12) solid !important;
    padding: 0 24px !important;
    }
    .loaderContainer{
        margin: 20% 0 0 30%;
    }
    .vehicleInfo.vInfo{
        border: 1px solid gray;
    }

     .report-bord{
        border: 1px solid gainsboro;
        padding: 2px 3px 4px 3px;
        margin: 3px 2px 7px 4px;
     }
     .title-icon{
        padding: 0 13px 0px 0px;
     }
     .card{margin-top:20px;border-right:0.5px solid #dcdcdc;}
     span.style-css {
        float: right;
        /* font-size: 14px; */
        padding: 8px 0 0px 3px;
        font-weight: 900;
    }
    .budge{
        display: inline-block;
        padding: 1px 6px 1px 1px;
        background: #00a8e6;
        line-height: 16px;
        color: #fff;
        line-height: 15px;
        /* vertical-align: middle; */
        /* text-transform: none; */
        border-radius: 2px;
        text-shadow: 0 1px 0 rgba(0,0,0,.1);
    }

    `]

})

export class BreakdownMsgLog {
    public breakdownMsgObj: any;
    public breakDownMobileNumber: any;
    public totBreakdownMsgCount: number;
    public loadCompo: boolean;
    public pageJump: number = 0;
    public pageLoader: boolean = false;

    constructor(private apiService: ApiService, 
                private chosenUtility: chosenUtility, 
                private bootstrapDateTime: bootstrapDateTime,
                private globalUtils: GlobalUtils,
                public dateChange:ApiCallDateFormatService,
                private com: COM) {

        this.com.comAction.subscribe((e: any) => {
            this.pageLoader = true;
            if (e.type === 'breakdownMsg') {
                this.loadData(e.data);
            }
        });
    }


    loadData(obj: any) {
        // console.log('@@@@@@@', obj);
        this.loadCompo = true;
        this.apiService.getBreakDownMsg(obj).then((res: any) => {

            if (res.status === 'success') {
                const responce = res.response;
                //  console.log(responce);
                if (responce.length > 0) {
                    this.totBreakdownMsgCount = res.alertCnt;
                    this.breakdownMsgObj = responce;
                } else {
                    this.breakdownMsgObj = 'null';
                }

            }
            this.pageLoader = false;
            /// console.log(this.totBreakdownMsgCount, this.breakdownMsgObj);

        });
    }

    breakDownReport(pageJump: number) {
        this.com.comAction.next({
            type: 'pagenation',
            data: pageJump
        });
    }

}