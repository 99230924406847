import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { catchError, tap } from "rxjs/operators";
import { WayPoint, Location, Singlewaypoint, LatLong, WayPointDataFormat } from "./map.interface";
import { Observable, bindCallback } from 'rxjs';
declare const google: any;
@Injectable({
  providedIn: 'root'
})
export class MapService {

  constructor(public http: HttpClient) {
    // console.log(http)

  }
  retriveLatLongFromMovementReport(arr: any) {
    return Promise.resolve(arr.map((item: any) => item.location && [parseFloat(item.location.lat), parseFloat(item.location.lng)]));

    // return Promise.resolve(arr.map((item: any) => item.location && item.nearestLocation && { location: [parseFloat(item.location.lat), parseFloat(item.location.lng)], nearlocation: item.nearestLocation }));
  }

  getWayPoints(directionsService:any,data: any) {
    // const directionsService = new google.maps.DirectionsService();
    // console.log(data);
    // const directionsRenderer = new google.maps.DirectionsRenderer();
  
    if (data.length >= 23) {
      throw new Error("Google Maps API provides 24, plus the origin and destination. ");

    }


    if (data.length < 2) {
      throw new Error("Google Maps API provides 24, plus the origin and destination. ");
    }
    let param: Array<LatLong> = data;


    let route = {};
    // console.log(param);

    if (param.length == 2) {
      route['origin'] = new google.maps.LatLng(param[0][0], param[0][1]);
      route['destination'] = new google.maps.LatLng(param[1][0], param[1][1]);
    }
    if (param.length > 2) {
      route['origin'] = new google.maps.LatLng(param[0][0], param[0][1]);
      route['destination'] = new google.maps.LatLng(param[(param.length) - 1][0], param[(param.length) - 1][1]);
      route['waypoints'] = param.map((item, index) => {
        if (index != 0 && index != (param.length - 1))
          return <Singlewaypoint>{
            location: {
              lat: item[0],
              lng: item[1]
            },
            stopover: true
          }
      }).filter(item => item != null && item);
    }
  // console.log("route['origin']",route['origin'],route)value
    if (route['origin'] != undefined && route['destination'] != undefined) {
      // route['optimizeWaypoints'] = true;
      route['travelMode'] = google.maps.TravelMode.DRIVING;



      return new Promise((res, rej) => {
        directionsService.route(route, (response:any, status:any) => {
          if (status === 'OK') {
            const route = response.routes[0]

            res(route)
          } else {
            res(null)
          }
        });
      });
    }
  }

  retriveLatLongsOnly = (leg: any) => {
    let ar: any = [];
    for (let item of leg) {
      ar.push(item.start_location);

      if (item.steps.length) {
        for (let step of item.steps) {
          ar.push(step.start_location);
          if (step.path.length) for (let p of step.path) ar.push(p);
          ar.push(step.end_location)
        }
      }
      ar.push(item.end_location);
    }
    // console.log(ar.map((item: any) => [item.lat(), item.lng()]));
    // for simulate response
    // return Promise.resolve(ar.length ? ar.map((item:any) => [item.lat, item.lng]) : null);
    // for api response
    return Promise.resolve(ar.length ? ar.map((item: any) => [item.lat(), item.lng()]) : null);
  }
  getWayPoints2(param: WayPointDataFormat) {
    const directionsService = new google.maps.DirectionsService();
    const directionsRenderer = new google.maps.DirectionsRenderer();

    if (param.waypoints.length >= 22) {
      throw new Error("Google Maps API provides 24, plus the origin and destination. ");
    }

    let wayPointData = param.waypoints.map((item: any, index: number) => {
      return <Singlewaypoint>{
        location: {
          lat: item[0],
          lng: item[1]
        },
        stopover: true
      }
    }).filter((item: any) => item != null && item);

    let wayPointRequest = {
      origin: new google.maps.LatLng(param.origin[0], param.origin[1]),
      destination: new google.maps.LatLng(param.destination[0], param.destination[1]),
      waypoints: wayPointData,
      optimizeWaypoints: true,
      travelMode: google.maps.TravelMode.DRIVING,
    }
    // const directionsServiceRoute = (wayPointRequest,cb);

    const directionsService$ = bindCallback(directionsService.route).bind(directionsService);
    return directionsService$(wayPointRequest).asObservable();

    // directionsService.route({
    //   origin: new google.maps.LatLng(param.origin[0], param.origin[1]),
    //   destination: new google.maps.LatLng(param.destination[0], param.destination[1]),
    //   waypoints: wayPointData,
    //   optimizeWaypoints: true,
    //   travelMode: google.maps.TravelMode.DRIVING,
    // }, cb({ status, response }))

    // return new Promise((res, rej) => {
    //   directionsService.route({
    //     origin: new google.maps.LatLng(param.origin[0], param.origin[1]),
    //     destination: new google.maps.LatLng(param.destination[0], param.destination[1]),
    //     waypoints: wayPointData,
    //     optimizeWaypoints: true,
    //     travelMode: google.maps.TravelMode.DRIVING,
    //   }, (response, status) => {
    //     if (status === 'OK') {
    //       const route = response.routes[0]
    //       console.log(route);
    //       res(route)
    //     } else {
    //       res([])
    //     }
    //   });
    // });
  }

  customLegFormat = (arr: any) => arr.map((i: any) => {
    return {
      distance: i.distance,
      duration: i.duration,
      start_address: i.start_address,
      end_address: i.end_address,
      start_location: [i.start_location.lat(), i.start_location.lng()],
      end_location: [i.end_location.lat(), i.end_location.lng()]
    }
  });




  getDistance() {
    let b = [

      12.965144984822656, 80.19802779356813,
      12.96366269977247, 80.21555042117825,
      12.984477366326212, 80.21781876337091,
      12.999948165492686, 80.21406002288562,
      12.996881777572035, 80.20986506838237,
      12.998467620065599, 80.20739595058365,
      12.993650403896995, 80.19991729812806,
      12.983120508724, 80.1964396120497,
      12.97503030914022, 80.19239873460396,
      12.969760208103008, 80.19614032413075,
      12.964808587578915, 80.19613345777435,
      12.965144984822656, 80.19802779356813,
    ];

    let origin = [12.965144984822656, 80.19802779356813];
    let detination = [12.965144984822656, 80.19802779356813];
    // let units = 'metrics';
    // let key = `AIzaSyDkHF7V5Dys0tnN33vWnBxlVJdV14sWt5k`; //placer key
    // key = `AIzaSyC5vaoRsWt68EMnZQ-MaZtw9RbPzew-1GE`;


  }

  errorCatch(item: any) {

    console.log(item);

  }
  byPass(item: any) {
    console.log(item);
  }


  simulteResponse() {
    let d: any = {
      "bounds": {
        "south": 28.510720000000003,
        "west": 77.15167000000001,
        "north": 28.534850000000002,
        "east": 77.16847
      },
      "copyrights": "Map data ©2020",
      "legs": [
        {
          "distance": {
            "text": "0.4 km",
            "value": 425
          },
          "duration": {
            "text": "2 mins",
            "value": 104
          },
          "end_address": "8003, Bhagwan Mahaveer Rd, Pocket 11, Sector B, Vasant Kunj, New Delhi, Delhi 110070, India",
          "end_location": {
            "lat": 28.532057,
            "lng": 77.1532361
          },
          "start_address": "Nelson Mandela Marg, Pocket 4, Sector C, Vasant Kunj, New Delhi, Delhi 110070, India",
          "start_location": {
            "lat": 28.5348456,
            "lng": 77.1518968
          },
          "steps": [
            {
              "distance": {
                "text": "31 m",
                "value": 31
              },
              "duration": {
                "text": "1 min",
                "value": 8
              },
              "end_location": {
                "lat": 28.5346935,
                "lng": 77.1521604
              },
              "polyline": {
                "points": "yedmDkv{uMJQRa@"
              },
              "start_location": {
                "lat": 28.5348456,
                "lng": 77.1518968
              },
              "travel_mode": "DRIVING",
              "encoded_lat_lngs": "yedmDkv{uMJQRa@",
              "path": [
                {
                  "lat": 28.534850000000002,
                  "lng": 77.15190000000001
                },
                {
                  "lat": 28.53479,
                  "lng": 77.15199000000001
                },
                {
                  "lat": 28.53469,
                  "lng": 77.15216000000001
                }
              ],
              "lat_lngs": [
                {
                  "lat": 28.534850000000002,
                  "lng": 77.15190000000001
                },
                {
                  "lat": 28.53479,
                  "lng": 77.15199000000001
                },
                {
                  "lat": 28.53469,
                  "lng": 77.15216000000001
                }
              ],
              "instructions": "Head <b>southeast</b>",
              "maneuver": "",
              "start_point": {
                "lat": 28.5348456,
                "lng": 77.1518968
              },
              "end_point": {
                "lat": 28.5346935,
                "lng": 77.1521604
              }
            },
            {
              "distance": {
                "text": "0.2 km",
                "value": 152
              },
              "duration": {
                "text": "1 min",
                "value": 45
              },
              "end_location": {
                "lat": 28.5336412,
                "lng": 77.1516686
              },
              "maneuver": "turn-right",
              "polyline": {
                "points": "yddmD_x{uMHQN]NJt@h@t@l@z@l@"
              },
              "start_location": {
                "lat": 28.5346935,
                "lng": 77.1521604
              },
              "travel_mode": "DRIVING",
              "encoded_lat_lngs": "yddmD_x{uMHQN]NJt@h@t@l@z@l@",
              "path": [
                {
                  "lat": 28.53469,
                  "lng": 77.15216000000001
                },
                {
                  "lat": 28.534640000000003,
                  "lng": 77.15225000000001
                },
                {
                  "lat": 28.534560000000003,
                  "lng": 77.1524
                },
                {
                  "lat": 28.534480000000002,
                  "lng": 77.15234000000001
                },
                {
                  "lat": 28.53421,
                  "lng": 77.15213
                },
                {
                  "lat": 28.53394,
                  "lng": 77.15190000000001
                },
                {
                  "lat": 28.533640000000002,
                  "lng": 77.15167000000001
                }
              ],
              "lat_lngs": [
                {
                  "lat": 28.53469,
                  "lng": 77.15216000000001
                },
                {
                  "lat": 28.534640000000003,
                  "lng": 77.15225000000001
                },
                {
                  "lat": 28.534560000000003,
                  "lng": 77.1524
                },
                {
                  "lat": 28.534480000000002,
                  "lng": 77.15234000000001
                },
                {
                  "lat": 28.53421,
                  "lng": 77.15213
                },
                {
                  "lat": 28.53394,
                  "lng": 77.15190000000001
                },
                {
                  "lat": 28.533640000000002,
                  "lng": 77.15167000000001
                }
              ],
              "instructions": "Turn <b>right</b> onto <b>Nelson Mandela Marg</b><div style=\"font-size:0.9em\">Pass by China Bowl (on the left)</div>",
              "start_point": {
                "lat": 28.5346935,
                "lng": 77.1521604
              },
              "end_point": {
                "lat": 28.5336412,
                "lng": 77.1516686
              }
            },
            {
              "distance": {
                "text": "0.1 km",
                "value": 102
              },
              "duration": {
                "text": "1 min",
                "value": 23
              },
              "end_location": {
                "lat": 28.5329243,
                "lng": 77.15221670000001
              },
              "maneuver": "turn-left",
              "polyline": {
                "points": "g~cmD}t{uM`@ELC\\I`AyA"
              },
              "start_location": {
                "lat": 28.5336412,
                "lng": 77.1516686
              },
              "travel_mode": "DRIVING",
              "encoded_lat_lngs": "g~cmD}t{uM`@ELC\\I`AyA",
              "path": [
                {
                  "lat": 28.533640000000002,
                  "lng": 77.15167000000001
                },
                {
                  "lat": 28.53347,
                  "lng": 77.1517
                },
                {
                  "lat": 28.533400000000004,
                  "lng": 77.15172000000001
                },
                {
                  "lat": 28.533250000000002,
                  "lng": 77.15177000000001
                },
                {
                  "lat": 28.53292,
                  "lng": 77.15222
                }
              ],
              "lat_lngs": [
                {
                  "lat": 28.533640000000002,
                  "lng": 77.15167000000001
                },
                {
                  "lat": 28.53347,
                  "lng": 77.1517
                },
                {
                  "lat": 28.533400000000004,
                  "lng": 77.15172000000001
                },
                {
                  "lat": 28.533250000000002,
                  "lng": 77.15177000000001
                },
                {
                  "lat": 28.53292,
                  "lng": 77.15222
                }
              ],
              "instructions": "Turn <b>left</b> after ICICI Bank ATM (on the left)",
              "start_point": {
                "lat": 28.5336412,
                "lng": 77.1516686
              },
              "end_point": {
                "lat": 28.5329243,
                "lng": 77.15221670000001
              }
            },
            {
              "distance": {
                "text": "0.1 km",
                "value": 140
              },
              "duration": {
                "text": "1 min",
                "value": 28
              },
              "end_location": {
                "lat": 28.532057,
                "lng": 77.1532361
              },
              "maneuver": "straight",
              "polyline": {
                "points": "wycmDkx{uMRe@LSLWHODGFIJKLMJINKl@g@"
              },
              "start_location": {
                "lat": 28.5329243,
                "lng": 77.15221670000001
              },
              "travel_mode": "DRIVING",
              "encoded_lat_lngs": "wycmDkx{uMRe@LSLWHODGFIJKLMJINKl@g@",
              "path": [
                {
                  "lat": 28.53292,
                  "lng": 77.15222
                },
                {
                  "lat": 28.53282,
                  "lng": 77.15241
                },
                {
                  "lat": 28.532750000000004,
                  "lng": 77.15251
                },
                {
                  "lat": 28.532680000000003,
                  "lng": 77.15263
                },
                {
                  "lat": 28.53263,
                  "lng": 77.15271000000001
                },
                {
                  "lat": 28.532600000000002,
                  "lng": 77.15275000000001
                },
                {
                  "lat": 28.532560000000004,
                  "lng": 77.15280000000001
                },
                {
                  "lat": 28.532500000000002,
                  "lng": 77.15286
                },
                {
                  "lat": 28.53243,
                  "lng": 77.15293000000001
                },
                {
                  "lat": 28.532370000000004,
                  "lng": 77.15298
                },
                {
                  "lat": 28.532290000000003,
                  "lng": 77.15304
                },
                {
                  "lat": 28.53206,
                  "lng": 77.15324000000001
                }
              ],
              "lat_lngs": [
                {
                  "lat": 28.53292,
                  "lng": 77.15222
                },
                {
                  "lat": 28.53282,
                  "lng": 77.15241
                },
                {
                  "lat": 28.532750000000004,
                  "lng": 77.15251
                },
                {
                  "lat": 28.532680000000003,
                  "lng": 77.15263
                },
                {
                  "lat": 28.53263,
                  "lng": 77.15271000000001
                },
                {
                  "lat": 28.532600000000002,
                  "lng": 77.15275000000001
                },
                {
                  "lat": 28.532560000000004,
                  "lng": 77.15280000000001
                },
                {
                  "lat": 28.532500000000002,
                  "lng": 77.15286
                },
                {
                  "lat": 28.53243,
                  "lng": 77.15293000000001
                },
                {
                  "lat": 28.532370000000004,
                  "lng": 77.15298
                },
                {
                  "lat": 28.532290000000003,
                  "lng": 77.15304
                },
                {
                  "lat": 28.53206,
                  "lng": 77.15324000000001
                }
              ],
              "instructions": "Continue straight to stay on <b>Bhagwan Mahaveer Rd</b><div style=\"font-size:0.9em\">Destination will be on the right</div>",
              "start_point": {
                "lat": 28.5329243,
                "lng": 77.15221670000001
              },
              "end_point": {
                "lat": 28.532057,
                "lng": 77.1532361
              }
            }
          ],
          "traffic_speed_entry": [],
          "via_waypoint": [],
          "via_waypoints": []
        },
        {
          "distance": {
            "text": "0.8 km",
            "value": 778
          },
          "duration": {
            "text": "2 mins",
            "value": 127
          },
          "end_address": "6162, Pocket 8, Sector B, Vasant Kunj, New Delhi, Delhi 110070, India",
          "end_location": {
            "lat": 28.5291452,
            "lng": 77.1575229
          },
          "start_address": "8003, Bhagwan Mahaveer Rd, Pocket 11, Sector B, Vasant Kunj, New Delhi, Delhi 110070, India",
          "start_location": {
            "lat": 28.532057,
            "lng": 77.1532361
          },
          "steps": [
            {
              "distance": {
                "text": "0.6 km",
                "value": 594
              },
              "duration": {
                "text": "1 min",
                "value": 84
              },
              "end_location": {
                "lat": 28.5278294,
                "lng": 77.1567124
              },
              "polyline": {
                "points": "ktcmDw~{uMHEfDeBbD_BrAu@LEx@e@x@e@RKJIBAXUXM^SPMDEBE@CNYP]HQXo@n@wABEDGJS"
              },
              "start_location": {
                "lat": 28.532057,
                "lng": 77.1532361
              },
              "travel_mode": "DRIVING",
              "encoded_lat_lngs": "ktcmDw~{uMHEfDeBbD_BrAu@LEx@e@x@e@RKJIBAXUXM^SPMDEBE@CNYP]HQXo@n@wABEDGJS",
              "path": [
                {
                  "lat": 28.53206,
                  "lng": 77.15324000000001
                },
                {
                  "lat": 28.532010000000003,
                  "lng": 77.15327
                },
                {
                  "lat": 28.531170000000003,
                  "lng": 77.15378000000001
                },
                {
                  "lat": 28.530350000000002,
                  "lng": 77.15426000000001
                },
                {
                  "lat": 28.529930000000004,
                  "lng": 77.15453000000001
                },
                {
                  "lat": 28.529860000000003,
                  "lng": 77.15456
                },
                {
                  "lat": 28.529570000000003,
                  "lng": 77.15475
                },
                {
                  "lat": 28.529280000000004,
                  "lng": 77.15494000000001
                },
                {
                  "lat": 28.529180000000004,
                  "lng": 77.155
                },
                {
                  "lat": 28.529120000000002,
                  "lng": 77.15505
                },
                {
                  "lat": 28.529100000000003,
                  "lng": 77.15506
                },
                {
                  "lat": 28.52897,
                  "lng": 77.15517000000001
                },
                {
                  "lat": 28.528840000000002,
                  "lng": 77.15524
                },
                {
                  "lat": 28.52868,
                  "lng": 77.15534000000001
                },
                {
                  "lat": 28.52859,
                  "lng": 77.15541
                },
                {
                  "lat": 28.528560000000002,
                  "lng": 77.15544000000001
                },
                {
                  "lat": 28.528540000000003,
                  "lng": 77.15547000000001
                },
                {
                  "lat": 28.528530000000003,
                  "lng": 77.15549
                },
                {
                  "lat": 28.528450000000003,
                  "lng": 77.15562000000001
                },
                {
                  "lat": 28.528360000000003,
                  "lng": 77.15577
                },
                {
                  "lat": 28.52831,
                  "lng": 77.15586
                },
                {
                  "lat": 28.528180000000003,
                  "lng": 77.15610000000001
                },
                {
                  "lat": 28.52794,
                  "lng": 77.15654
                },
                {
                  "lat": 28.52792,
                  "lng": 77.15657
                },
                {
                  "lat": 28.527890000000003,
                  "lng": 77.15661
                },
                {
                  "lat": 28.52783,
                  "lng": 77.15671
                }
              ],
              "lat_lngs": [
                {
                  "lat": 28.53206,
                  "lng": 77.15324000000001
                },
                {
                  "lat": 28.532010000000003,
                  "lng": 77.15327
                },
                {
                  "lat": 28.531170000000003,
                  "lng": 77.15378000000001
                },
                {
                  "lat": 28.530350000000002,
                  "lng": 77.15426000000001
                },
                {
                  "lat": 28.529930000000004,
                  "lng": 77.15453000000001
                },
                {
                  "lat": 28.529860000000003,
                  "lng": 77.15456
                },
                {
                  "lat": 28.529570000000003,
                  "lng": 77.15475
                },
                {
                  "lat": 28.529280000000004,
                  "lng": 77.15494000000001
                },
                {
                  "lat": 28.529180000000004,
                  "lng": 77.155
                },
                {
                  "lat": 28.529120000000002,
                  "lng": 77.15505
                },
                {
                  "lat": 28.529100000000003,
                  "lng": 77.15506
                },
                {
                  "lat": 28.52897,
                  "lng": 77.15517000000001
                },
                {
                  "lat": 28.528840000000002,
                  "lng": 77.15524
                },
                {
                  "lat": 28.52868,
                  "lng": 77.15534000000001
                },
                {
                  "lat": 28.52859,
                  "lng": 77.15541
                },
                {
                  "lat": 28.528560000000002,
                  "lng": 77.15544000000001
                },
                {
                  "lat": 28.528540000000003,
                  "lng": 77.15547000000001
                },
                {
                  "lat": 28.528530000000003,
                  "lng": 77.15549
                },
                {
                  "lat": 28.528450000000003,
                  "lng": 77.15562000000001
                },
                {
                  "lat": 28.528360000000003,
                  "lng": 77.15577
                },
                {
                  "lat": 28.52831,
                  "lng": 77.15586
                },
                {
                  "lat": 28.528180000000003,
                  "lng": 77.15610000000001
                },
                {
                  "lat": 28.52794,
                  "lng": 77.15654
                },
                {
                  "lat": 28.52792,
                  "lng": 77.15657
                },
                {
                  "lat": 28.527890000000003,
                  "lng": 77.15661
                },
                {
                  "lat": 28.52783,
                  "lng": 77.15671
                }
              ],
              "instructions": "Head <b>southeast</b> on <b>Bhagwan Mahaveer Rd</b> toward <b>Sector B Rd</b><div style=\"font-size:0.9em\">Pass by GD Goenka Public School (on the right in 450&nbsp;m)</div>",
              "maneuver": "",
              "start_point": {
                "lat": 28.532057,
                "lng": 77.1532361
              },
              "end_point": {
                "lat": 28.5278294,
                "lng": 77.1567124
              }
            },
            {
              "distance": {
                "text": "0.2 km",
                "value": 166
              },
              "duration": {
                "text": "1 min",
                "value": 30
              },
              "end_location": {
                "lat": 28.5290575,
                "lng": 77.15767579999999
              },
              "maneuver": "turn-left",
              "polyline": {
                "points": "}ybmDmt|uMgCkBmBuA"
              },
              "start_location": {
                "lat": 28.5278294,
                "lng": 77.1567124
              },
              "travel_mode": "DRIVING",
              "encoded_lat_lngs": "}ybmDmt|uMgCkBmBuA",
              "path": [
                {
                  "lat": 28.52783,
                  "lng": 77.15671
                },
                {
                  "lat": 28.52851,
                  "lng": 77.15725
                },
                {
                  "lat": 28.52906,
                  "lng": 77.15768000000001
                }
              ],
              "lat_lngs": [
                {
                  "lat": 28.52783,
                  "lng": 77.15671
                },
                {
                  "lat": 28.52851,
                  "lng": 77.15725
                },
                {
                  "lat": 28.52906,
                  "lng": 77.15768000000001
                }
              ],
              "instructions": "Turn <b>left</b> onto <b>Bhagwan Mahaveer Rd</b>/<wbr/><b>Sector B Rd</b>",
              "start_point": {
                "lat": 28.5278294,
                "lng": 77.1567124
              },
              "end_point": {
                "lat": 28.5290575,
                "lng": 77.15767579999999
              }
            },
            {
              "distance": {
                "text": "18 m",
                "value": 18
              },
              "duration": {
                "text": "1 min",
                "value": 13
              },
              "end_location": {
                "lat": 28.5291452,
                "lng": 77.1575229
              },
              "maneuver": "turn-left",
              "polyline": {
                "points": "sacmDoz|uMQ^"
              },
              "start_location": {
                "lat": 28.5290575,
                "lng": 77.15767579999999
              },
              "travel_mode": "DRIVING",
              "encoded_lat_lngs": "sacmDoz|uMQ^",
              "path": [
                {
                  "lat": 28.52906,
                  "lng": 77.15768000000001
                },
                {
                  "lat": 28.52915,
                  "lng": 77.15752
                }
              ],
              "lat_lngs": [
                {
                  "lat": 28.52906,
                  "lng": 77.15768000000001
                },
                {
                  "lat": 28.52915,
                  "lng": 77.15752
                }
              ],
              "instructions": "Turn <b>left</b><div style=\"font-size:0.9em\">Destination will be on the left</div>",
              "start_point": {
                "lat": 28.5290575,
                "lng": 77.15767579999999
              },
              "end_point": {
                "lat": 28.5291452,
                "lng": 77.1575229
              }
            }
          ],
          "traffic_speed_entry": [],
          "via_waypoint": [],
          "via_waypoints": []
        },
        {
          "distance": {
            "text": "2.0 km",
            "value": 2009
          },
          "duration": {
            "text": "6 mins",
            "value": 332
          },
          "end_address": "6352, Sector B Rd, Pocket 9, Sector B, Vasant Kunj, New Delhi, Delhi 110070, India",
          "end_location": {
            "lat": 28.5273824,
            "lng": 77.16057719999999
          },
          "start_address": "6162, Pocket 8, Sector B, Vasant Kunj, New Delhi, Delhi 110070, India",
          "start_location": {
            "lat": 28.5291452,
            "lng": 77.1575229
          },
          "steps": [
            {
              "distance": {
                "text": "18 m",
                "value": 18
              },
              "duration": {
                "text": "1 min",
                "value": 5
              },
              "end_location": {
                "lat": 28.5290575,
                "lng": 77.15767579999999
              },
              "polyline": {
                "points": "ebcmDoy|uMP_@"
              },
              "start_location": {
                "lat": 28.5291452,
                "lng": 77.1575229
              },
              "travel_mode": "DRIVING",
              "encoded_lat_lngs": "ebcmDoy|uMP_@",
              "path": [
                {
                  "lat": 28.52915,
                  "lng": 77.15752
                },
                {
                  "lat": 28.52906,
                  "lng": 77.15768000000001
                }
              ],
              "lat_lngs": [
                {
                  "lat": 28.52915,
                  "lng": 77.15752
                },
                {
                  "lat": 28.52906,
                  "lng": 77.15768000000001
                }
              ],
              "instructions": "Head <b>southeast</b> toward <b>Bhagwan Mahaveer Rd</b>/<wbr/><b>Sector B Rd</b>",
              "maneuver": "",
              "start_point": {
                "lat": 28.5291452,
                "lng": 77.1575229
              },
              "end_point": {
                "lat": 28.5290575,
                "lng": 77.15767579999999
              }
            },
            {
              "distance": {
                "text": "0.4 km",
                "value": 382
              },
              "duration": {
                "text": "1 min",
                "value": 57
              },
              "end_location": {
                "lat": 28.5275395,
                "lng": 77.16046589999999
              },
              "maneuver": "turn-left",
              "polyline": {
                "points": "sacmDoz|uM_@WOICECEAEAC?C?G?GZ_AFQ^}AFWHUHKJG^QTKbASJCFEJMJQBE^y@Zg@Xk@"
              },
              "start_location": {
                "lat": 28.5290575,
                "lng": 77.15767579999999
              },
              "travel_mode": "DRIVING",
              "encoded_lat_lngs": "sacmDoz|uM_@WOICECEAEAC?C?G?GZ_AFQ^}AFWHUHKJG^QTKbASJCFEJMJQBE^y@Zg@Xk@",
              "path": [
                {
                  "lat": 28.52906,
                  "lng": 77.15768000000001
                },
                {
                  "lat": 28.529220000000002,
                  "lng": 77.15780000000001
                },
                {
                  "lat": 28.529300000000003,
                  "lng": 77.15785000000001
                },
                {
                  "lat": 28.529320000000002,
                  "lng": 77.15788
                },
                {
                  "lat": 28.52934,
                  "lng": 77.15791
                },
                {
                  "lat": 28.52935,
                  "lng": 77.15794000000001
                },
                {
                  "lat": 28.529360000000004,
                  "lng": 77.15796
                },
                {
                  "lat": 28.529360000000004,
                  "lng": 77.15798000000001
                },
                {
                  "lat": 28.529360000000004,
                  "lng": 77.15802000000001
                },
                {
                  "lat": 28.529360000000004,
                  "lng": 77.15806
                },
                {
                  "lat": 28.529220000000002,
                  "lng": 77.15838000000001
                },
                {
                  "lat": 28.529180000000004,
                  "lng": 77.15847000000001
                },
                {
                  "lat": 28.529020000000003,
                  "lng": 77.15894
                },
                {
                  "lat": 28.52898,
                  "lng": 77.15906000000001
                },
                {
                  "lat": 28.528930000000003,
                  "lng": 77.15917
                },
                {
                  "lat": 28.52888,
                  "lng": 77.15923000000001
                },
                {
                  "lat": 28.528820000000003,
                  "lng": 77.15927
                },
                {
                  "lat": 28.528660000000002,
                  "lng": 77.15936
                },
                {
                  "lat": 28.528550000000003,
                  "lng": 77.15942000000001
                },
                {
                  "lat": 28.52821,
                  "lng": 77.15952
                },
                {
                  "lat": 28.528150000000004,
                  "lng": 77.15954
                },
                {
                  "lat": 28.52811,
                  "lng": 77.15957
                },
                {
                  "lat": 28.528050000000004,
                  "lng": 77.15964000000001
                },
                {
                  "lat": 28.527990000000003,
                  "lng": 77.15973000000001
                },
                {
                  "lat": 28.527970000000003,
                  "lng": 77.15976
                },
                {
                  "lat": 28.527810000000002,
                  "lng": 77.16005000000001
                },
                {
                  "lat": 28.527670000000004,
                  "lng": 77.16025
                },
                {
                  "lat": 28.527540000000002,
                  "lng": 77.16047
                }
              ],
              "lat_lngs": [
                {
                  "lat": 28.52906,
                  "lng": 77.15768000000001
                },
                {
                  "lat": 28.529220000000002,
                  "lng": 77.15780000000001
                },
                {
                  "lat": 28.529300000000003,
                  "lng": 77.15785000000001
                },
                {
                  "lat": 28.529320000000002,
                  "lng": 77.15788
                },
                {
                  "lat": 28.52934,
                  "lng": 77.15791
                },
                {
                  "lat": 28.52935,
                  "lng": 77.15794000000001
                },
                {
                  "lat": 28.529360000000004,
                  "lng": 77.15796
                },
                {
                  "lat": 28.529360000000004,
                  "lng": 77.15798000000001
                },
                {
                  "lat": 28.529360000000004,
                  "lng": 77.15802000000001
                },
                {
                  "lat": 28.529360000000004,
                  "lng": 77.15806
                },
                {
                  "lat": 28.529220000000002,
                  "lng": 77.15838000000001
                },
                {
                  "lat": 28.529180000000004,
                  "lng": 77.15847000000001
                },
                {
                  "lat": 28.529020000000003,
                  "lng": 77.15894
                },
                {
                  "lat": 28.52898,
                  "lng": 77.15906000000001
                },
                {
                  "lat": 28.528930000000003,
                  "lng": 77.15917
                },
                {
                  "lat": 28.52888,
                  "lng": 77.15923000000001
                },
                {
                  "lat": 28.528820000000003,
                  "lng": 77.15927
                },
                {
                  "lat": 28.528660000000002,
                  "lng": 77.15936
                },
                {
                  "lat": 28.528550000000003,
                  "lng": 77.15942000000001
                },
                {
                  "lat": 28.52821,
                  "lng": 77.15952
                },
                {
                  "lat": 28.528150000000004,
                  "lng": 77.15954
                },
                {
                  "lat": 28.52811,
                  "lng": 77.15957
                },
                {
                  "lat": 28.528050000000004,
                  "lng": 77.15964000000001
                },
                {
                  "lat": 28.527990000000003,
                  "lng": 77.15973000000001
                },
                {
                  "lat": 28.527970000000003,
                  "lng": 77.15976
                },
                {
                  "lat": 28.527810000000002,
                  "lng": 77.16005000000001
                },
                {
                  "lat": 28.527670000000004,
                  "lng": 77.16025
                },
                {
                  "lat": 28.527540000000002,
                  "lng": 77.16047
                }
              ],
              "instructions": "Turn <b>left</b> at the 1st cross street onto <b>Bhagwan Mahaveer Rd</b>/<wbr/><b>Sector B Rd</b>",
              "start_point": {
                "lat": 28.5290575,
                "lng": 77.15767579999999
              },
              "end_point": {
                "lat": 28.5275395,
                "lng": 77.16046589999999
              }
            },
            {
              "distance": {
                "text": "0.3 km",
                "value": 252
              },
              "duration": {
                "text": "1 min",
                "value": 42
              },
              "end_location": {
                "lat": 28.5254747,
                "lng": 77.1594159
              },
              "maneuver": "turn-right",
              "polyline": {
                "points": "cxbmD}k}uMLFr@Rf@NDBdFtBfAj@"
              },
              "start_location": {
                "lat": 28.5275395,
                "lng": 77.16046589999999
              },
              "travel_mode": "DRIVING",
              "encoded_lat_lngs": "cxbmD}k}uMLFr@Rf@NDBdFtBfAj@",
              "path": [
                {
                  "lat": 28.527540000000002,
                  "lng": 77.16047
                },
                {
                  "lat": 28.52747,
                  "lng": 77.16043
                },
                {
                  "lat": 28.527210000000004,
                  "lng": 77.16033
                },
                {
                  "lat": 28.52701,
                  "lng": 77.16025
                },
                {
                  "lat": 28.526980000000002,
                  "lng": 77.16023000000001
                },
                {
                  "lat": 28.525830000000003,
                  "lng": 77.15964000000001
                },
                {
                  "lat": 28.525470000000002,
                  "lng": 77.15942000000001
                }
              ],
              "lat_lngs": [
                {
                  "lat": 28.527540000000002,
                  "lng": 77.16047
                },
                {
                  "lat": 28.52747,
                  "lng": 77.16043
                },
                {
                  "lat": 28.527210000000004,
                  "lng": 77.16033
                },
                {
                  "lat": 28.52701,
                  "lng": 77.16025
                },
                {
                  "lat": 28.526980000000002,
                  "lng": 77.16023000000001
                },
                {
                  "lat": 28.525830000000003,
                  "lng": 77.15964000000001
                },
                {
                  "lat": 28.525470000000002,
                  "lng": 77.15942000000001
                }
              ],
              "instructions": "Turn <b>right</b> onto <b>Delhi Jal Board Rd</b>",
              "start_point": {
                "lat": 28.5275395,
                "lng": 77.16046589999999
              },
              "end_point": {
                "lat": 28.5254747,
                "lng": 77.1594159
              }
            },
            {
              "distance": {
                "text": "0.4 km",
                "value": 450
              },
              "duration": {
                "text": "1 min",
                "value": 79
              },
              "end_location": {
                "lat": 28.5223043,
                "lng": 77.1622123
              },
              "maneuver": "turn-left",
              "polyline": {
                "points": "ekbmDke}uMHMv@mAh@}@LQNOFIFGb@[hAo@fAq@lAy@h@]pDiC"
              },
              "start_location": {
                "lat": 28.5254747,
                "lng": 77.1594159
              },
              "travel_mode": "DRIVING",
              "encoded_lat_lngs": "ekbmDke}uMHMv@mAh@}@LQNOFIFGb@[hAo@fAq@lAy@h@]pDiC",
              "path": [
                {
                  "lat": 28.525470000000002,
                  "lng": 77.15942000000001
                },
                {
                  "lat": 28.525420000000004,
                  "lng": 77.15949
                },
                {
                  "lat": 28.525140000000004,
                  "lng": 77.15988
                },
                {
                  "lat": 28.52493,
                  "lng": 77.16019
                },
                {
                  "lat": 28.524860000000004,
                  "lng": 77.16028
                },
                {
                  "lat": 28.524780000000003,
                  "lng": 77.16036000000001
                },
                {
                  "lat": 28.52474,
                  "lng": 77.16041000000001
                },
                {
                  "lat": 28.524700000000003,
                  "lng": 77.16045000000001
                },
                {
                  "lat": 28.524520000000003,
                  "lng": 77.16059000000001
                },
                {
                  "lat": 28.524150000000002,
                  "lng": 77.16083
                },
                {
                  "lat": 28.52379,
                  "lng": 77.16108000000001
                },
                {
                  "lat": 28.523400000000002,
                  "lng": 77.16137
                },
                {
                  "lat": 28.523190000000003,
                  "lng": 77.16152000000001
                },
                {
                  "lat": 28.5223,
                  "lng": 77.16221
                }
              ],
              "lat_lngs": [
                {
                  "lat": 28.525470000000002,
                  "lng": 77.15942000000001
                },
                {
                  "lat": 28.525420000000004,
                  "lng": 77.15949
                },
                {
                  "lat": 28.525140000000004,
                  "lng": 77.15988
                },
                {
                  "lat": 28.52493,
                  "lng": 77.16019
                },
                {
                  "lat": 28.524860000000004,
                  "lng": 77.16028
                },
                {
                  "lat": 28.524780000000003,
                  "lng": 77.16036000000001
                },
                {
                  "lat": 28.52474,
                  "lng": 77.16041000000001
                },
                {
                  "lat": 28.524700000000003,
                  "lng": 77.16045000000001
                },
                {
                  "lat": 28.524520000000003,
                  "lng": 77.16059000000001
                },
                {
                  "lat": 28.524150000000002,
                  "lng": 77.16083
                },
                {
                  "lat": 28.52379,
                  "lng": 77.16108000000001
                },
                {
                  "lat": 28.523400000000002,
                  "lng": 77.16137
                },
                {
                  "lat": 28.523190000000003,
                  "lng": 77.16152000000001
                },
                {
                  "lat": 28.5223,
                  "lng": 77.16221
                }
              ],
              "instructions": "Turn <b>left</b><div style=\"font-size:0.9em\">Drive along B-2 (on the left for 110&nbsp;m)</div>",
              "start_point": {
                "lat": 28.5254747,
                "lng": 77.1594159
              },
              "end_point": {
                "lat": 28.5223043,
                "lng": 77.1622123
              }
            },
            {
              "distance": {
                "text": "0.4 km",
                "value": 395
              },
              "duration": {
                "text": "1 min",
                "value": 67
              },
              "end_location": {
                "lat": 28.5248381,
                "lng": 77.1649329
              },
              "maneuver": "turn-left",
              "polyline": {
                "points": "kwamDyv}uMg@cAe@cAoAiCIMKKKMQKq@a@_Am@s@c@cCeB"
              },
              "start_location": {
                "lat": 28.5223043,
                "lng": 77.1622123
              },
              "travel_mode": "DRIVING",
              "encoded_lat_lngs": "kwamDyv}uMg@cAe@cAoAiCIMKKKMQKq@a@_Am@s@c@cCeB",
              "path": [
                {
                  "lat": 28.5223,
                  "lng": 77.16221
                },
                {
                  "lat": 28.5225,
                  "lng": 77.16255000000001
                },
                {
                  "lat": 28.52269,
                  "lng": 77.16289
                },
                {
                  "lat": 28.523090000000003,
                  "lng": 77.16358000000001
                },
                {
                  "lat": 28.52314,
                  "lng": 77.16365
                },
                {
                  "lat": 28.523200000000003,
                  "lng": 77.16371000000001
                },
                {
                  "lat": 28.523260000000004,
                  "lng": 77.16378
                },
                {
                  "lat": 28.52335,
                  "lng": 77.16384000000001
                },
                {
                  "lat": 28.523600000000002,
                  "lng": 77.16401
                },
                {
                  "lat": 28.523920000000004,
                  "lng": 77.16424
                },
                {
                  "lat": 28.52418,
                  "lng": 77.16442
                },
                {
                  "lat": 28.52484,
                  "lng": 77.16493000000001
                }
              ],
              "lat_lngs": [
                {
                  "lat": 28.5223,
                  "lng": 77.16221
                },
                {
                  "lat": 28.5225,
                  "lng": 77.16255000000001
                },
                {
                  "lat": 28.52269,
                  "lng": 77.16289
                },
                {
                  "lat": 28.523090000000003,
                  "lng": 77.16358000000001
                },
                {
                  "lat": 28.52314,
                  "lng": 77.16365
                },
                {
                  "lat": 28.523200000000003,
                  "lng": 77.16371000000001
                },
                {
                  "lat": 28.523260000000004,
                  "lng": 77.16378
                },
                {
                  "lat": 28.52335,
                  "lng": 77.16384000000001
                },
                {
                  "lat": 28.523600000000002,
                  "lng": 77.16401
                },
                {
                  "lat": 28.523920000000004,
                  "lng": 77.16424
                },
                {
                  "lat": 28.52418,
                  "lng": 77.16442
                },
                {
                  "lat": 28.52484,
                  "lng": 77.16493000000001
                }
              ],
              "instructions": "Turn <b>left</b>",
              "start_point": {
                "lat": 28.5223043,
                "lng": 77.1622123
              },
              "end_point": {
                "lat": 28.5248381,
                "lng": 77.1649329
              }
            },
            {
              "distance": {
                "text": "0.5 km",
                "value": 512
              },
              "duration": {
                "text": "1 min",
                "value": 82
              },
              "end_location": {
                "lat": 28.5273824,
                "lng": 77.16057719999999
              },
              "maneuver": "turn-left",
              "polyline": {
                "points": "ggbmDyg~uM_BbDCDsAlCkAvBYh@]j@c@|@g@zA{@hBw@zA"
              },
              "start_location": {
                "lat": 28.5248381,
                "lng": 77.1649329
              },
              "travel_mode": "DRIVING",
              "encoded_lat_lngs": "ggbmDyg~uM_BbDCDsAlCkAvBYh@]j@c@|@g@zA{@hBw@zA",
              "path": [
                {
                  "lat": 28.52484,
                  "lng": 77.16493000000001
                },
                {
                  "lat": 28.52532,
                  "lng": 77.16411000000001
                },
                {
                  "lat": 28.525340000000003,
                  "lng": 77.16408000000001
                },
                {
                  "lat": 28.525760000000002,
                  "lng": 77.16337
                },
                {
                  "lat": 28.52614,
                  "lng": 77.16277000000001
                },
                {
                  "lat": 28.526270000000004,
                  "lng": 77.16256000000001
                },
                {
                  "lat": 28.52642,
                  "lng": 77.16234
                },
                {
                  "lat": 28.526600000000002,
                  "lng": 77.16203
                },
                {
                  "lat": 28.5268,
                  "lng": 77.16157000000001
                },
                {
                  "lat": 28.5271,
                  "lng": 77.16104
                },
                {
                  "lat": 28.52738,
                  "lng": 77.16058000000001
                }
              ],
              "lat_lngs": [
                {
                  "lat": 28.52484,
                  "lng": 77.16493000000001
                },
                {
                  "lat": 28.52532,
                  "lng": 77.16411000000001
                },
                {
                  "lat": 28.525340000000003,
                  "lng": 77.16408000000001
                },
                {
                  "lat": 28.525760000000002,
                  "lng": 77.16337
                },
                {
                  "lat": 28.52614,
                  "lng": 77.16277000000001
                },
                {
                  "lat": 28.526270000000004,
                  "lng": 77.16256000000001
                },
                {
                  "lat": 28.52642,
                  "lng": 77.16234
                },
                {
                  "lat": 28.526600000000002,
                  "lng": 77.16203
                },
                {
                  "lat": 28.5268,
                  "lng": 77.16157000000001
                },
                {
                  "lat": 28.5271,
                  "lng": 77.16104
                },
                {
                  "lat": 28.52738,
                  "lng": 77.16058000000001
                }
              ],
              "instructions": "Turn <b>left</b> onto <b>Bhagwan Mahaveer Rd</b>/<wbr/><b>Sector B Rd</b>",
              "start_point": {
                "lat": 28.5248381,
                "lng": 77.1649329
              },
              "end_point": {
                "lat": 28.5273824,
                "lng": 77.16057719999999
              }
            }
          ],
          "traffic_speed_entry": [],
          "via_waypoint": [],
          "via_waypoints": []
        },
        {
          "distance": {
            "text": "0.4 km",
            "value": 359
          },
          "duration": {
            "text": "2 mins",
            "value": 111
          },
          "end_address": "Agarwal complex, Delhi Jal Board Rd, Pocket 1, Sector B, Vasant Kunj, New Delhi, Delhi 110067, India",
          "end_location": {
            "lat": 28.5247232,
            "lng": 77.1589916
          },
          "start_address": "6352, Sector B Rd, Pocket 9, Sector B, Vasant Kunj, New Delhi, Delhi 110070, India",
          "start_location": {
            "lat": 28.5273824,
            "lng": 77.16057719999999
          },
          "steps": [
            {
              "distance": {
                "text": "17 m",
                "value": 17
              },
              "duration": {
                "text": "1 min",
                "value": 3
              },
              "end_location": {
                "lat": 28.5274684,
                "lng": 77.16043359999999
              },
              "polyline": {
                "points": "cwbmDsl}uMQ\\"
              },
              "start_location": {
                "lat": 28.5273824,
                "lng": 77.16057719999999
              },
              "travel_mode": "DRIVING",
              "encoded_lat_lngs": "cwbmDsl}uMQ\\",
              "path": [
                {
                  "lat": 28.52738,
                  "lng": 77.16058000000001
                },
                {
                  "lat": 28.52747,
                  "lng": 77.16043
                }
              ],
              "lat_lngs": [
                {
                  "lat": 28.52738,
                  "lng": 77.16058000000001
                },
                {
                  "lat": 28.52747,
                  "lng": 77.16043
                }
              ],
              "instructions": "Head <b>northwest</b> on <b>Bhagwan Mahaveer Rd</b>/<wbr/><b>Sector B Rd</b> toward <b>Delhi Jal Board Rd</b>",
              "maneuver": "",
              "start_point": {
                "lat": 28.5273824,
                "lng": 77.16057719999999
              },
              "end_point": {
                "lat": 28.5274684,
                "lng": 77.16043359999999
              }
            },
            {
              "distance": {
                "text": "0.3 km",
                "value": 337
              },
              "duration": {
                "text": "1 min",
                "value": 64
              },
              "end_location": {
                "lat": 28.524746,
                "lng": 77.15894209999999
              },
              "maneuver": "turn-left",
              "polyline": {
                "points": "uwbmDuk}uMr@Rf@NDBdFtBfAj@LH`CtA"
              },
              "start_location": {
                "lat": 28.5274684,
                "lng": 77.16043359999999
              },
              "travel_mode": "DRIVING",
              "encoded_lat_lngs": "uwbmDuk}uMr@Rf@NDBdFtBfAj@LH`CtA",
              "path": [
                {
                  "lat": 28.52747,
                  "lng": 77.16043
                },
                {
                  "lat": 28.527210000000004,
                  "lng": 77.16033
                },
                {
                  "lat": 28.52701,
                  "lng": 77.16025
                },
                {
                  "lat": 28.526980000000002,
                  "lng": 77.16023000000001
                },
                {
                  "lat": 28.525830000000003,
                  "lng": 77.15964000000001
                },
                {
                  "lat": 28.525470000000002,
                  "lng": 77.15942000000001
                },
                {
                  "lat": 28.5254,
                  "lng": 77.15937000000001
                },
                {
                  "lat": 28.52475,
                  "lng": 77.15894
                }
              ],
              "lat_lngs": [
                {
                  "lat": 28.52747,
                  "lng": 77.16043
                },
                {
                  "lat": 28.527210000000004,
                  "lng": 77.16033
                },
                {
                  "lat": 28.52701,
                  "lng": 77.16025
                },
                {
                  "lat": 28.526980000000002,
                  "lng": 77.16023000000001
                },
                {
                  "lat": 28.525830000000003,
                  "lng": 77.15964000000001
                },
                {
                  "lat": 28.525470000000002,
                  "lng": 77.15942000000001
                },
                {
                  "lat": 28.5254,
                  "lng": 77.15937000000001
                },
                {
                  "lat": 28.52475,
                  "lng": 77.15894
                }
              ],
              "instructions": "Turn <b>left</b> onto <b>Delhi Jal Board Rd</b>",
              "start_point": {
                "lat": 28.5274684,
                "lng": 77.16043359999999
              },
              "end_point": {
                "lat": 28.524746,
                "lng": 77.15894209999999
              }
            },
            {
              "distance": {
                "text": "5 m",
                "value": 5
              },
              "duration": {
                "text": "1 min",
                "value": 44
              },
              "end_location": {
                "lat": 28.5247232,
                "lng": 77.1589916
              },
              "maneuver": "turn-left",
              "polyline": {
                "points": "ufbmDkb}uMDI"
              },
              "start_location": {
                "lat": 28.524746,
                "lng": 77.15894209999999
              },
              "travel_mode": "DRIVING",
              "encoded_lat_lngs": "ufbmDkb}uMDI",
              "path": [
                {
                  "lat": 28.52475,
                  "lng": 77.15894
                },
                {
                  "lat": 28.524720000000002,
                  "lng": 77.15899
                }
              ],
              "lat_lngs": [
                {
                  "lat": 28.52475,
                  "lng": 77.15894
                },
                {
                  "lat": 28.524720000000002,
                  "lng": 77.15899
                }
              ],
              "instructions": "Turn <b>left</b> after Delhi Jal Board Office (on the right)",
              "start_point": {
                "lat": 28.524746,
                "lng": 77.15894209999999
              },
              "end_point": {
                "lat": 28.5247232,
                "lng": 77.1589916
              }
            }
          ],
          "traffic_speed_entry": [],
          "via_waypoint": [],
          "via_waypoints": []
        },
        {
          "distance": {
            "text": "0.3 km",
            "value": 280
          },
          "duration": {
            "text": "1 min",
            "value": 62
          },
          "end_address": "1224, Pocket 1, Sector B, Vasant Kunj, New Delhi, Delhi 110070, India",
          "end_location": {
            "lat": 28.5229586,
            "lng": 77.1571051
          },
          "start_address": "Agarwal complex, Delhi Jal Board Rd, Pocket 1, Sector B, Vasant Kunj, New Delhi, Delhi 110067, India",
          "start_location": {
            "lat": 28.5247232,
            "lng": 77.1589916
          },
          "steps": [
            {
              "distance": {
                "text": "5 m",
                "value": 5
              },
              "duration": {
                "text": "1 min",
                "value": 1
              },
              "end_location": {
                "lat": 28.524746,
                "lng": 77.15894209999999
              },
              "polyline": {
                "points": "ofbmDub}uMEH"
              },
              "start_location": {
                "lat": 28.5247232,
                "lng": 77.1589916
              },
              "travel_mode": "DRIVING",
              "encoded_lat_lngs": "ofbmDub}uMEH",
              "path": [
                {
                  "lat": 28.524720000000002,
                  "lng": 77.15899
                },
                {
                  "lat": 28.52475,
                  "lng": 77.15894
                }
              ],
              "lat_lngs": [
                {
                  "lat": 28.524720000000002,
                  "lng": 77.15899
                },
                {
                  "lat": 28.52475,
                  "lng": 77.15894
                }
              ],
              "instructions": "Head <b>northwest</b> toward <b>Delhi Jal Board Rd</b>",
              "maneuver": "",
              "start_point": {
                "lat": 28.5247232,
                "lng": 77.1589916
              },
              "end_point": {
                "lat": 28.524746,
                "lng": 77.15894209999999
              }
            },
            {
              "distance": {
                "text": "0.3 km",
                "value": 275
              },
              "duration": {
                "text": "1 min",
                "value": 61
              },
              "end_location": {
                "lat": 28.5229586,
                "lng": 77.1571051
              },
              "maneuver": "turn-left",
              "polyline": {
                "points": "ufbmDkb}uMJDNHnAn@tAr@RPRXP\\Zl@j@hAR`@LT"
              },
              "start_location": {
                "lat": 28.524746,
                "lng": 77.15894209999999
              },
              "travel_mode": "DRIVING",
              "encoded_lat_lngs": "ufbmDkb}uMJDNHnAn@tAr@RPRXP\\Zl@j@hAR`@LT",
              "path": [
                {
                  "lat": 28.52475,
                  "lng": 77.15894
                },
                {
                  "lat": 28.524690000000003,
                  "lng": 77.15891
                },
                {
                  "lat": 28.524610000000003,
                  "lng": 77.15886
                },
                {
                  "lat": 28.524210000000004,
                  "lng": 77.15862000000001
                },
                {
                  "lat": 28.523780000000002,
                  "lng": 77.15836
                },
                {
                  "lat": 28.523680000000002,
                  "lng": 77.15827
                },
                {
                  "lat": 28.523580000000003,
                  "lng": 77.15814
                },
                {
                  "lat": 28.523490000000002,
                  "lng": 77.15799000000001
                },
                {
                  "lat": 28.52335,
                  "lng": 77.15776000000001
                },
                {
                  "lat": 28.523130000000002,
                  "lng": 77.15739
                },
                {
                  "lat": 28.523030000000002,
                  "lng": 77.15722000000001
                },
                {
                  "lat": 28.52296,
                  "lng": 77.15711
                }
              ],
              "lat_lngs": [
                {
                  "lat": 28.52475,
                  "lng": 77.15894
                },
                {
                  "lat": 28.524690000000003,
                  "lng": 77.15891
                },
                {
                  "lat": 28.524610000000003,
                  "lng": 77.15886
                },
                {
                  "lat": 28.524210000000004,
                  "lng": 77.15862000000001
                },
                {
                  "lat": 28.523780000000002,
                  "lng": 77.15836
                },
                {
                  "lat": 28.523680000000002,
                  "lng": 77.15827
                },
                {
                  "lat": 28.523580000000003,
                  "lng": 77.15814
                },
                {
                  "lat": 28.523490000000002,
                  "lng": 77.15799000000001
                },
                {
                  "lat": 28.52335,
                  "lng": 77.15776000000001
                },
                {
                  "lat": 28.523130000000002,
                  "lng": 77.15739
                },
                {
                  "lat": 28.523030000000002,
                  "lng": 77.15722000000001
                },
                {
                  "lat": 28.52296,
                  "lng": 77.15711
                }
              ],
              "instructions": "Turn <b>left</b> at Manoj Oil Stoer onto <b>Delhi Jal Board Rd</b><div style=\"font-size:0.9em\">Pass by Desmoderm - Skin, Laser &amp; Hair Care Clinic - Dr Ranjan Upadhyay (on the right)</div><div style=\"font-size:0.9em\">Destination will be on the right</div>",
              "start_point": {
                "lat": 28.524746,
                "lng": 77.15894209999999
              },
              "end_point": {
                "lat": 28.5229586,
                "lng": 77.1571051
              }
            }
          ],
          "traffic_speed_entry": [],
          "via_waypoint": [],
          "via_waypoints": []
        },
        {
          "distance": {
            "text": "1.8 km",
            "value": 1788
          },
          "duration": {
            "text": "5 mins",
            "value": 318
          },
          "end_address": "136/9, Kishangarh,, Vasant Kunj, Kishangarh Village, Vasant Kunj, New Delhi, Delhi 110070, India",
          "end_location": {
            "lat": 28.5214783,
            "lng": 77.1670525
          },
          "start_address": "1224, Pocket 1, Sector B, Vasant Kunj, New Delhi, Delhi 110070, India",
          "start_location": {
            "lat": 28.5229586,
            "lng": 77.1571051
          },
          "steps": [
            {
              "distance": {
                "text": "0.6 km",
                "value": 612
              },
              "duration": {
                "text": "2 mins",
                "value": 117
              },
              "end_location": {
                "lat": 28.5274684,
                "lng": 77.16043359999999
              },
              "polyline": {
                "points": "o{amD}v|uMMUSa@k@iA[m@Q]SYSQuAs@oAo@OIKEaCuAMIgAk@eFuBECg@Os@S"
              },
              "start_location": {
                "lat": 28.5229586,
                "lng": 77.1571051
              },
              "travel_mode": "DRIVING",
              "encoded_lat_lngs": "o{amD}v|uMMUSa@k@iA[m@Q]SYSQuAs@oAo@OIKEaCuAMIgAk@eFuBECg@Os@S",
              "path": [
                {
                  "lat": 28.52296,
                  "lng": 77.15711
                },
                {
                  "lat": 28.523030000000002,
                  "lng": 77.15722000000001
                },
                {
                  "lat": 28.523130000000002,
                  "lng": 77.15739
                },
                {
                  "lat": 28.52335,
                  "lng": 77.15776000000001
                },
                {
                  "lat": 28.523490000000002,
                  "lng": 77.15799000000001
                },
                {
                  "lat": 28.523580000000003,
                  "lng": 77.15814
                },
                {
                  "lat": 28.523680000000002,
                  "lng": 77.15827
                },
                {
                  "lat": 28.523780000000002,
                  "lng": 77.15836
                },
                {
                  "lat": 28.524210000000004,
                  "lng": 77.15862000000001
                },
                {
                  "lat": 28.524610000000003,
                  "lng": 77.15886
                },
                {
                  "lat": 28.524690000000003,
                  "lng": 77.15891
                },
                {
                  "lat": 28.52475,
                  "lng": 77.15894
                },
                {
                  "lat": 28.5254,
                  "lng": 77.15937000000001
                },
                {
                  "lat": 28.525470000000002,
                  "lng": 77.15942000000001
                },
                {
                  "lat": 28.525830000000003,
                  "lng": 77.15964000000001
                },
                {
                  "lat": 28.526980000000002,
                  "lng": 77.16023000000001
                },
                {
                  "lat": 28.52701,
                  "lng": 77.16025
                },
                {
                  "lat": 28.527210000000004,
                  "lng": 77.16033
                },
                {
                  "lat": 28.52747,
                  "lng": 77.16043
                }
              ],
              "lat_lngs": [
                {
                  "lat": 28.52296,
                  "lng": 77.15711
                },
                {
                  "lat": 28.523030000000002,
                  "lng": 77.15722000000001
                },
                {
                  "lat": 28.523130000000002,
                  "lng": 77.15739
                },
                {
                  "lat": 28.52335,
                  "lng": 77.15776000000001
                },
                {
                  "lat": 28.523490000000002,
                  "lng": 77.15799000000001
                },
                {
                  "lat": 28.523580000000003,
                  "lng": 77.15814
                },
                {
                  "lat": 28.523680000000002,
                  "lng": 77.15827
                },
                {
                  "lat": 28.523780000000002,
                  "lng": 77.15836
                },
                {
                  "lat": 28.524210000000004,
                  "lng": 77.15862000000001
                },
                {
                  "lat": 28.524610000000003,
                  "lng": 77.15886
                },
                {
                  "lat": 28.524690000000003,
                  "lng": 77.15891
                },
                {
                  "lat": 28.52475,
                  "lng": 77.15894
                },
                {
                  "lat": 28.5254,
                  "lng": 77.15937000000001
                },
                {
                  "lat": 28.525470000000002,
                  "lng": 77.15942000000001
                },
                {
                  "lat": 28.525830000000003,
                  "lng": 77.15964000000001
                },
                {
                  "lat": 28.526980000000002,
                  "lng": 77.16023000000001
                },
                {
                  "lat": 28.52701,
                  "lng": 77.16025
                },
                {
                  "lat": 28.527210000000004,
                  "lng": 77.16033
                },
                {
                  "lat": 28.52747,
                  "lng": 77.16043
                }
              ],
              "instructions": "Head <b>northeast</b> on <b>Delhi Jal Board Rd</b><div style=\"font-size:0.9em\">Pass by Delhi Jal Board Office (on the left in 300&nbsp;m)</div>",
              "maneuver": "",
              "start_point": {
                "lat": 28.5229586,
                "lng": 77.1571051
              },
              "end_point": {
                "lat": 28.5274684,
                "lng": 77.16043359999999
              }
            },
            {
              "distance": {
                "text": "0.9 km",
                "value": 925
              },
              "duration": {
                "text": "2 mins",
                "value": 148
              },
              "end_location": {
                "lat": 28.5231592,
                "lng": 77.1683671
              },
              "maneuver": "turn-right",
              "polyline": {
                "points": "uwbmDuk}uMMGjA}Bz@eBl@wAjAeCv@cBlBuDdBkDVi@xBwE`AqBDItAkCDIHSL_@D_@H{@"
              },
              "start_location": {
                "lat": 28.5274684,
                "lng": 77.16043359999999
              },
              "travel_mode": "DRIVING",
              "encoded_lat_lngs": "uwbmDuk}uMMGjA}Bz@eBl@wAjAeCv@cBlBuDdBkDVi@xBwE`AqBDItAkCDIHSL_@D_@H{@",
              "path": [
                {
                  "lat": 28.52747,
                  "lng": 77.16043
                },
                {
                  "lat": 28.527540000000002,
                  "lng": 77.16047
                },
                {
                  "lat": 28.527160000000002,
                  "lng": 77.1611
                },
                {
                  "lat": 28.526860000000003,
                  "lng": 77.16161000000001
                },
                {
                  "lat": 28.52663,
                  "lng": 77.16205000000001
                },
                {
                  "lat": 28.52625,
                  "lng": 77.16272000000001
                },
                {
                  "lat": 28.52597,
                  "lng": 77.16322000000001
                },
                {
                  "lat": 28.525420000000004,
                  "lng": 77.16413
                },
                {
                  "lat": 28.524910000000002,
                  "lng": 77.16499
                },
                {
                  "lat": 28.524790000000003,
                  "lng": 77.16520000000001
                },
                {
                  "lat": 28.52418,
                  "lng": 77.16628
                },
                {
                  "lat": 28.523850000000003,
                  "lng": 77.16685000000001
                },
                {
                  "lat": 28.52382,
                  "lng": 77.16690000000001
                },
                {
                  "lat": 28.523390000000003,
                  "lng": 77.16760000000001
                },
                {
                  "lat": 28.523360000000004,
                  "lng": 77.16765000000001
                },
                {
                  "lat": 28.523310000000002,
                  "lng": 77.16775000000001
                },
                {
                  "lat": 28.52324,
                  "lng": 77.16791
                },
                {
                  "lat": 28.523210000000002,
                  "lng": 77.16807
                },
                {
                  "lat": 28.52316,
                  "lng": 77.16837000000001
                }
              ],
              "lat_lngs": [
                {
                  "lat": 28.52747,
                  "lng": 77.16043
                },
                {
                  "lat": 28.527540000000002,
                  "lng": 77.16047
                },
                {
                  "lat": 28.527160000000002,
                  "lng": 77.1611
                },
                {
                  "lat": 28.526860000000003,
                  "lng": 77.16161000000001
                },
                {
                  "lat": 28.52663,
                  "lng": 77.16205000000001
                },
                {
                  "lat": 28.52625,
                  "lng": 77.16272000000001
                },
                {
                  "lat": 28.52597,
                  "lng": 77.16322000000001
                },
                {
                  "lat": 28.525420000000004,
                  "lng": 77.16413
                },
                {
                  "lat": 28.524910000000002,
                  "lng": 77.16499
                },
                {
                  "lat": 28.524790000000003,
                  "lng": 77.16520000000001
                },
                {
                  "lat": 28.52418,
                  "lng": 77.16628
                },
                {
                  "lat": 28.523850000000003,
                  "lng": 77.16685000000001
                },
                {
                  "lat": 28.52382,
                  "lng": 77.16690000000001
                },
                {
                  "lat": 28.523390000000003,
                  "lng": 77.16760000000001
                },
                {
                  "lat": 28.523360000000004,
                  "lng": 77.16765000000001
                },
                {
                  "lat": 28.523310000000002,
                  "lng": 77.16775000000001
                },
                {
                  "lat": 28.52324,
                  "lng": 77.16791
                },
                {
                  "lat": 28.523210000000002,
                  "lng": 77.16807
                },
                {
                  "lat": 28.52316,
                  "lng": 77.16837000000001
                }
              ],
              "instructions": "Turn <b>right</b> onto <b>Bhagwan Mahaveer Rd</b>/<wbr/><b>Sector B Rd</b>",
              "start_point": {
                "lat": 28.5274684,
                "lng": 77.16043359999999
              },
              "end_point": {
                "lat": 28.5231592,
                "lng": 77.1683671
              }
            },
            {
              "distance": {
                "text": "0.3 km",
                "value": 251
              },
              "duration": {
                "text": "1 min",
                "value": 53
              },
              "end_location": {
                "lat": 28.5214783,
                "lng": 77.1670525
              },
              "maneuver": "turn-right",
              "polyline": {
                "points": "w|amDi}~uMHS`@Jz@Rl@Rf@RLJXVLLV\\\\t@P\\L`@DL"
              },
              "start_location": {
                "lat": 28.5231592,
                "lng": 77.1683671
              },
              "travel_mode": "DRIVING",
              "encoded_lat_lngs": "w|amDi}~uMHS`@Jz@Rl@Rf@RLJXVLLV\\\\t@P\\L`@DL",
              "path": [
                {
                  "lat": 28.52316,
                  "lng": 77.16837000000001
                },
                {
                  "lat": 28.523110000000003,
                  "lng": 77.16847
                },
                {
                  "lat": 28.522940000000002,
                  "lng": 77.16841000000001
                },
                {
                  "lat": 28.522640000000003,
                  "lng": 77.16831
                },
                {
                  "lat": 28.52241,
                  "lng": 77.16821
                },
                {
                  "lat": 28.52221,
                  "lng": 77.16811000000001
                },
                {
                  "lat": 28.522140000000004,
                  "lng": 77.16805000000001
                },
                {
                  "lat": 28.52201,
                  "lng": 77.16793000000001
                },
                {
                  "lat": 28.52194,
                  "lng": 77.16786
                },
                {
                  "lat": 28.52182,
                  "lng": 77.16771
                },
                {
                  "lat": 28.521670000000004,
                  "lng": 77.16744000000001
                },
                {
                  "lat": 28.521580000000004,
                  "lng": 77.16729000000001
                },
                {
                  "lat": 28.521510000000003,
                  "lng": 77.16712000000001
                },
                {
                  "lat": 28.521480000000004,
                  "lng": 77.16705
                }
              ],
              "lat_lngs": [
                {
                  "lat": 28.52316,
                  "lng": 77.16837000000001
                },
                {
                  "lat": 28.523110000000003,
                  "lng": 77.16847
                },
                {
                  "lat": 28.522940000000002,
                  "lng": 77.16841000000001
                },
                {
                  "lat": 28.522640000000003,
                  "lng": 77.16831
                },
                {
                  "lat": 28.52241,
                  "lng": 77.16821
                },
                {
                  "lat": 28.52221,
                  "lng": 77.16811000000001
                },
                {
                  "lat": 28.522140000000004,
                  "lng": 77.16805000000001
                },
                {
                  "lat": 28.52201,
                  "lng": 77.16793000000001
                },
                {
                  "lat": 28.52194,
                  "lng": 77.16786
                },
                {
                  "lat": 28.52182,
                  "lng": 77.16771
                },
                {
                  "lat": 28.521670000000004,
                  "lng": 77.16744000000001
                },
                {
                  "lat": 28.521580000000004,
                  "lng": 77.16729000000001
                },
                {
                  "lat": 28.521510000000003,
                  "lng": 77.16712000000001
                },
                {
                  "lat": 28.521480000000004,
                  "lng": 77.16705
                }
              ],
              "instructions": "Turn <b>right</b> onto <b>Aruna Asaf Ali Marg</b><div style=\"font-size:0.9em\">Destination will be on the left</div>",
              "start_point": {
                "lat": 28.5231592,
                "lng": 77.1683671
              },
              "end_point": {
                "lat": 28.5214783,
                "lng": 77.1670525
              }
            }
          ],
          "traffic_speed_entry": [],
          "via_waypoint": [],
          "via_waypoints": []
        },
        {
          "distance": {
            "text": "1.2 km",
            "value": 1228
          },
          "duration": {
            "text": "5 mins",
            "value": 295
          },
          "end_address": "3645, Sector D 3-4, Sector D, Vasant Kunj, New Delhi, Delhi 110070, India",
          "end_location": {
            "lat": 28.5162996,
            "lng": 77.1588902
          },
          "start_address": "136/9, Kishangarh,, Vasant Kunj, Kishangarh Village, Vasant Kunj, New Delhi, Delhi 110070, India",
          "start_location": {
            "lat": 28.5214783,
            "lng": 77.1670525
          },
          "steps": [
            {
              "distance": {
                "text": "0.8 km",
                "value": 793
              },
              "duration": {
                "text": "2 mins",
                "value": 109
              },
              "end_location": {
                "lat": 28.51838,
                "lng": 77.1598599
              },
              "polyline": {
                "points": "gramDau~uMp@vBt@`CHVHr@BZF|@@n@BZDp@Ff@BRDNDNFLN\\BFnCxFL^vAxCf@bAzAlDTb@Rd@"
              },
              "start_location": {
                "lat": 28.5214783,
                "lng": 77.1670525
              },
              "travel_mode": "DRIVING",
              "encoded_lat_lngs": "gramDau~uMp@vBt@`CHVHr@BZF|@@n@BZDp@Ff@BRDNDNFLN\\BFnCxFL^vAxCf@bAzAlDTb@Rd@",
              "path": [
                {
                  "lat": 28.521480000000004,
                  "lng": 77.16705
                },
                {
                  "lat": 28.521230000000003,
                  "lng": 77.16645000000001
                },
                {
                  "lat": 28.520960000000002,
                  "lng": 77.1658
                },
                {
                  "lat": 28.52091,
                  "lng": 77.16568000000001
                },
                {
                  "lat": 28.520860000000003,
                  "lng": 77.16542000000001
                },
                {
                  "lat": 28.520840000000003,
                  "lng": 77.16528000000001
                },
                {
                  "lat": 28.5208,
                  "lng": 77.16497000000001
                },
                {
                  "lat": 28.52079,
                  "lng": 77.16473
                },
                {
                  "lat": 28.520770000000002,
                  "lng": 77.16459
                },
                {
                  "lat": 28.520740000000004,
                  "lng": 77.16434000000001
                },
                {
                  "lat": 28.5207,
                  "lng": 77.16414
                },
                {
                  "lat": 28.520680000000002,
                  "lng": 77.16404
                },
                {
                  "lat": 28.520650000000003,
                  "lng": 77.16396
                },
                {
                  "lat": 28.52062,
                  "lng": 77.16388
                },
                {
                  "lat": 28.520580000000002,
                  "lng": 77.16381000000001
                },
                {
                  "lat": 28.520500000000002,
                  "lng": 77.16366000000001
                },
                {
                  "lat": 28.520480000000003,
                  "lng": 77.16362000000001
                },
                {
                  "lat": 28.51976,
                  "lng": 77.16237000000001
                },
                {
                  "lat": 28.51969,
                  "lng": 77.16221
                },
                {
                  "lat": 28.519250000000003,
                  "lng": 77.16144000000001
                },
                {
                  "lat": 28.519050000000004,
                  "lng": 77.1611
                },
                {
                  "lat": 28.518590000000003,
                  "lng": 77.16023000000001
                },
                {
                  "lat": 28.518480000000004,
                  "lng": 77.16005000000001
                },
                {
                  "lat": 28.518380000000004,
                  "lng": 77.15986000000001
                }
              ],
              "lat_lngs": [
                {
                  "lat": 28.521480000000004,
                  "lng": 77.16705
                },
                {
                  "lat": 28.521230000000003,
                  "lng": 77.16645000000001
                },
                {
                  "lat": 28.520960000000002,
                  "lng": 77.1658
                },
                {
                  "lat": 28.52091,
                  "lng": 77.16568000000001
                },
                {
                  "lat": 28.520860000000003,
                  "lng": 77.16542000000001
                },
                {
                  "lat": 28.520840000000003,
                  "lng": 77.16528000000001
                },
                {
                  "lat": 28.5208,
                  "lng": 77.16497000000001
                },
                {
                  "lat": 28.52079,
                  "lng": 77.16473
                },
                {
                  "lat": 28.520770000000002,
                  "lng": 77.16459
                },
                {
                  "lat": 28.520740000000004,
                  "lng": 77.16434000000001
                },
                {
                  "lat": 28.5207,
                  "lng": 77.16414
                },
                {
                  "lat": 28.520680000000002,
                  "lng": 77.16404
                },
                {
                  "lat": 28.520650000000003,
                  "lng": 77.16396
                },
                {
                  "lat": 28.52062,
                  "lng": 77.16388
                },
                {
                  "lat": 28.520580000000002,
                  "lng": 77.16381000000001
                },
                {
                  "lat": 28.520500000000002,
                  "lng": 77.16366000000001
                },
                {
                  "lat": 28.520480000000003,
                  "lng": 77.16362000000001
                },
                {
                  "lat": 28.51976,
                  "lng": 77.16237000000001
                },
                {
                  "lat": 28.51969,
                  "lng": 77.16221
                },
                {
                  "lat": 28.519250000000003,
                  "lng": 77.16144000000001
                },
                {
                  "lat": 28.519050000000004,
                  "lng": 77.1611
                },
                {
                  "lat": 28.518590000000003,
                  "lng": 77.16023000000001
                },
                {
                  "lat": 28.518480000000004,
                  "lng": 77.16005000000001
                },
                {
                  "lat": 28.518380000000004,
                  "lng": 77.15986000000001
                }
              ],
              "instructions": "Head <b>southwest</b> on <b>Aruna Asaf Ali Marg</b> toward <b>Kishangarh - Mehrauli Rd</b><div style=\"font-size:0.9em\">Pass by IGL CNG Pump (on the right)</div>",
              "maneuver": "",
              "start_point": {
                "lat": 28.5214783,
                "lng": 77.1670525
              },
              "end_point": {
                "lat": 28.51838,
                "lng": 77.1598599
              }
            },
            {
              "distance": {
                "text": "44 m",
                "value": 44
              },
              "duration": {
                "text": "1 min",
                "value": 37
              },
              "end_location": {
                "lat": 28.5186116,
                "lng": 77.1595907
              },
              "maneuver": "turn-right",
              "polyline": {
                "points": "{~`mDch}uMFNMFg@\\"
              },
              "start_location": {
                "lat": 28.51838,
                "lng": 77.1598599
              },
              "travel_mode": "DRIVING",
              "encoded_lat_lngs": "{~`mDch}uMFNMFg@\\",
              "path": [
                {
                  "lat": 28.518380000000004,
                  "lng": 77.15986000000001
                },
                {
                  "lat": 28.518340000000002,
                  "lng": 77.15978000000001
                },
                {
                  "lat": 28.518410000000003,
                  "lng": 77.15974
                },
                {
                  "lat": 28.518610000000002,
                  "lng": 77.15959000000001
                }
              ],
              "lat_lngs": [
                {
                  "lat": 28.518380000000004,
                  "lng": 77.15986000000001
                },
                {
                  "lat": 28.518340000000002,
                  "lng": 77.15978000000001
                },
                {
                  "lat": 28.518410000000003,
                  "lng": 77.15974
                },
                {
                  "lat": 28.518610000000002,
                  "lng": 77.15959000000001
                }
              ],
              "instructions": "Turn <b>right</b> onto <b>Abdul Gaffar Khan Marg</b>/<wbr/><b>Vasant Kunj Marg</b>",
              "start_point": {
                "lat": 28.51838,
                "lng": 77.1598599
              },
              "end_point": {
                "lat": 28.5186116,
                "lng": 77.1595907
              }
            },
            {
              "distance": {
                "text": "0.1 km",
                "value": 111
              },
              "duration": {
                "text": "1 min",
                "value": 51
              },
              "end_location": {
                "lat": 28.5181768,
                "lng": 77.1585695
              },
              "maneuver": "turn-left",
              "polyline": {
                "points": "i`amDmf}uMtAjE"
              },
              "start_location": {
                "lat": 28.5186116,
                "lng": 77.1595907
              },
              "travel_mode": "DRIVING",
              "encoded_lat_lngs": "i`amDmf}uMtAjE",
              "path": [
                {
                  "lat": 28.518610000000002,
                  "lng": 77.15959000000001
                },
                {
                  "lat": 28.51818,
                  "lng": 77.15857000000001
                }
              ],
              "lat_lngs": [
                {
                  "lat": 28.518610000000002,
                  "lng": 77.15959000000001
                },
                {
                  "lat": 28.51818,
                  "lng": 77.15857000000001
                }
              ],
              "instructions": "Turn <b>left</b> onto <b>Vasant Kunj Entry Rd</b>",
              "start_point": {
                "lat": 28.5186116,
                "lng": 77.1595907
              },
              "end_point": {
                "lat": 28.5181768,
                "lng": 77.1585695
              }
            },
            {
              "distance": {
                "text": "0.2 km",
                "value": 175
              },
              "duration": {
                "text": "1 min",
                "value": 60
              },
              "end_location": {
                "lat": 28.5168255,
                "lng": 77.1594782
              },
              "maneuver": "turn-left",
              "polyline": {
                "points": "s}`mDa`}uMTKrAy@`DmB@A"
              },
              "start_location": {
                "lat": 28.5181768,
                "lng": 77.1585695
              },
              "travel_mode": "DRIVING",
              "encoded_lat_lngs": "s}`mDa`}uMTKrAy@`DmB@A",
              "path": [
                {
                  "lat": 28.51818,
                  "lng": 77.15857000000001
                },
                {
                  "lat": 28.51807,
                  "lng": 77.15863
                },
                {
                  "lat": 28.517650000000003,
                  "lng": 77.15892000000001
                },
                {
                  "lat": 28.516840000000002,
                  "lng": 77.15947000000001
                },
                {
                  "lat": 28.516830000000002,
                  "lng": 77.15948
                }
              ],
              "lat_lngs": [
                {
                  "lat": 28.51818,
                  "lng": 77.15857000000001
                },
                {
                  "lat": 28.51807,
                  "lng": 77.15863
                },
                {
                  "lat": 28.517650000000003,
                  "lng": 77.15892000000001
                },
                {
                  "lat": 28.516840000000002,
                  "lng": 77.15947000000001
                },
                {
                  "lat": 28.516830000000002,
                  "lng": 77.15948
                }
              ],
              "instructions": "Turn <b>left</b> toward <b>Vasant Kunj Exit Rd</b>",
              "start_point": {
                "lat": 28.5181768,
                "lng": 77.1585695
              },
              "end_point": {
                "lat": 28.5168255,
                "lng": 77.1594782
              }
            },
            {
              "distance": {
                "text": "81 m",
                "value": 81
              },
              "duration": {
                "text": "1 min",
                "value": 26
              },
              "end_location": {
                "lat": 28.516474,
                "lng": 77.1587541
              },
              "maneuver": "turn-right",
              "polyline": {
                "points": "eu`mDwe}uMPb@Pb@b@hA"
              },
              "start_location": {
                "lat": 28.5168255,
                "lng": 77.1594782
              },
              "travel_mode": "DRIVING",
              "encoded_lat_lngs": "eu`mDwe}uMPb@Pb@b@hA",
              "path": [
                {
                  "lat": 28.516830000000002,
                  "lng": 77.15948
                },
                {
                  "lat": 28.516740000000002,
                  "lng": 77.1593
                },
                {
                  "lat": 28.516650000000002,
                  "lng": 77.15912
                },
                {
                  "lat": 28.51647,
                  "lng": 77.15875000000001
                }
              ],
              "lat_lngs": [
                {
                  "lat": 28.516830000000002,
                  "lng": 77.15948
                },
                {
                  "lat": 28.516740000000002,
                  "lng": 77.1593
                },
                {
                  "lat": 28.516650000000002,
                  "lng": 77.15912
                },
                {
                  "lat": 28.51647,
                  "lng": 77.15875000000001
                }
              ],
              "instructions": "Turn <b>right</b> onto <b>Vasant Kunj Exit Rd</b>",
              "start_point": {
                "lat": 28.5168255,
                "lng": 77.1594782
              },
              "end_point": {
                "lat": 28.516474,
                "lng": 77.1587541
              }
            },
            {
              "distance": {
                "text": "24 m",
                "value": 24
              },
              "duration": {
                "text": "1 min",
                "value": 12
              },
              "end_location": {
                "lat": 28.5162996,
                "lng": 77.1588902
              },
              "maneuver": "turn-left",
              "polyline": {
                "points": "}r`mDea}uM`@["
              },
              "start_location": {
                "lat": 28.516474,
                "lng": 77.1587541
              },
              "travel_mode": "DRIVING",
              "encoded_lat_lngs": "}r`mDea}uM`@[",
              "path": [
                {
                  "lat": 28.51647,
                  "lng": 77.15875000000001
                },
                {
                  "lat": 28.5163,
                  "lng": 77.15889
                }
              ],
              "lat_lngs": [
                {
                  "lat": 28.51647,
                  "lng": 77.15875000000001
                },
                {
                  "lat": 28.5163,
                  "lng": 77.15889
                }
              ],
              "instructions": "Turn <b>left</b><div style=\"font-size:0.9em\">Destination will be on the left</div>",
              "start_point": {
                "lat": 28.516474,
                "lng": 77.1587541
              },
              "end_point": {
                "lat": 28.5162996,
                "lng": 77.1588902
              }
            }
          ],
          "traffic_speed_entry": [],
          "via_waypoint": [],
          "via_waypoints": []
        },
        {
          "distance": {
            "text": "2.4 km",
            "value": 2369
          },
          "duration": {
            "text": "7 mins",
            "value": 406
          },
          "end_address": "Opp. Church Road, Church Red Light D-3, Vasant Kunj, New Delhi, Delhi 110070, India",
          "end_location": {
            "lat": 28.5161976,
            "lng": 77.16217739999999
          },
          "start_address": "3645, Sector D 3-4, Sector D, Vasant Kunj, New Delhi, Delhi 110070, India",
          "start_location": {
            "lat": 28.5162996,
            "lng": 77.1588902
          },
          "steps": [
            {
              "distance": {
                "text": "24 m",
                "value": 24
              },
              "duration": {
                "text": "1 min",
                "value": 8
              },
              "end_location": {
                "lat": 28.516474,
                "lng": 77.1587541
              },
              "polyline": {
                "points": "{q`mDab}uMa@Z"
              },
              "start_location": {
                "lat": 28.5162996,
                "lng": 77.1588902
              },
              "travel_mode": "DRIVING",
              "encoded_lat_lngs": "{q`mDab}uMa@Z",
              "path": [
                {
                  "lat": 28.5163,
                  "lng": 77.15889
                },
                {
                  "lat": 28.51647,
                  "lng": 77.15875000000001
                }
              ],
              "lat_lngs": [
                {
                  "lat": 28.5163,
                  "lng": 77.15889
                },
                {
                  "lat": 28.51647,
                  "lng": 77.15875000000001
                }
              ],
              "instructions": "Head <b>northwest</b> toward <b>Vasant Kunj Exit Rd</b>",
              "maneuver": "",
              "start_point": {
                "lat": 28.5162996,
                "lng": 77.1588902
              },
              "end_point": {
                "lat": 28.516474,
                "lng": 77.1587541
              }
            },
            {
              "distance": {
                "text": "0.2 km",
                "value": 204
              },
              "duration": {
                "text": "1 min",
                "value": 81
              },
              "end_location": {
                "lat": 28.5174346,
                "lng": 77.16052549999999
              },
              "maneuver": "turn-right",
              "polyline": {
                "points": "}r`mDea}uMc@iAQc@Qc@_@cAGM]s@IQg@y@"
              },
              "start_location": {
                "lat": 28.516474,
                "lng": 77.1587541
              },
              "travel_mode": "DRIVING",
              "encoded_lat_lngs": "}r`mDea}uMc@iAQc@Qc@_@cAGM]s@IQg@y@",
              "path": [
                {
                  "lat": 28.51647,
                  "lng": 77.15875000000001
                },
                {
                  "lat": 28.516650000000002,
                  "lng": 77.15912
                },
                {
                  "lat": 28.516740000000002,
                  "lng": 77.1593
                },
                {
                  "lat": 28.516830000000002,
                  "lng": 77.15948
                },
                {
                  "lat": 28.516990000000003,
                  "lng": 77.15982000000001
                },
                {
                  "lat": 28.517030000000002,
                  "lng": 77.15989
                },
                {
                  "lat": 28.517180000000003,
                  "lng": 77.16015
                },
                {
                  "lat": 28.51723,
                  "lng": 77.16024
                },
                {
                  "lat": 28.51743,
                  "lng": 77.16053000000001
                }
              ],
              "lat_lngs": [
                {
                  "lat": 28.51647,
                  "lng": 77.15875000000001
                },
                {
                  "lat": 28.516650000000002,
                  "lng": 77.15912
                },
                {
                  "lat": 28.516740000000002,
                  "lng": 77.1593
                },
                {
                  "lat": 28.516830000000002,
                  "lng": 77.15948
                },
                {
                  "lat": 28.516990000000003,
                  "lng": 77.15982000000001
                },
                {
                  "lat": 28.517030000000002,
                  "lng": 77.15989
                },
                {
                  "lat": 28.517180000000003,
                  "lng": 77.16015
                },
                {
                  "lat": 28.51723,
                  "lng": 77.16024
                },
                {
                  "lat": 28.51743,
                  "lng": 77.16053000000001
                }
              ],
              "instructions": "Turn <b>right</b> onto <b>Vasant Kunj Exit Rd</b>",
              "start_point": {
                "lat": 28.516474,
                "lng": 77.1587541
              },
              "end_point": {
                "lat": 28.5174346,
                "lng": 77.16052549999999
              }
            },
            {
              "distance": {
                "text": "0.1 km",
                "value": 125
              },
              "duration": {
                "text": "1 min",
                "value": 35
              },
              "end_location": {
                "lat": 28.518342,
                "lng": 77.1597821
              },
              "maneuver": "turn-left",
              "polyline": {
                "points": "}x`mDil}uMyBlB{@f@"
              },
              "start_location": {
                "lat": 28.5174346,
                "lng": 77.16052549999999
              },
              "travel_mode": "DRIVING",
              "encoded_lat_lngs": "}x`mDil}uMyBlB{@f@",
              "path": [
                {
                  "lat": 28.51743,
                  "lng": 77.16053000000001
                },
                {
                  "lat": 28.518040000000003,
                  "lng": 77.15998
                },
                {
                  "lat": 28.518340000000002,
                  "lng": 77.15978000000001
                }
              ],
              "lat_lngs": [
                {
                  "lat": 28.51743,
                  "lng": 77.16053000000001
                },
                {
                  "lat": 28.518040000000003,
                  "lng": 77.15998
                },
                {
                  "lat": 28.518340000000002,
                  "lng": 77.15978000000001
                }
              ],
              "instructions": "Turn <b>left</b> onto <b>Abdul Gaffar Khan Marg</b>/<wbr/><b>Vasant Kunj Marg</b>",
              "start_point": {
                "lat": 28.5174346,
                "lng": 77.16052549999999
              },
              "end_point": {
                "lat": 28.518342,
                "lng": 77.1597821
              }
            },
            {
              "distance": {
                "text": "1.2 km",
                "value": 1177
              },
              "duration": {
                "text": "3 mins",
                "value": 166
              },
              "end_location": {
                "lat": 28.5108562,
                "lng": 77.16783649999999
              },
              "maneuver": "uturn-right",
              "polyline": {
                "points": "s~`mDsg}uMMFGOLGlBgB~BsClCsDV]h@s@f@u@fAwAlA{A^a@bAkALQhAiAp@q@^[l@e@nAcA~@q@xAeAvA_APMp@i@l@g@RONQNWJUNa@Xq@HQ"
              },
              "start_location": {
                "lat": 28.518342,
                "lng": 77.1597821
              },
              "travel_mode": "DRIVING",
              "encoded_lat_lngs": "s~`mDsg}uMMFGOLGlBgB~BsClCsDV]h@s@f@u@fAwAlA{A^a@bAkALQhAiAp@q@^[l@e@nAcA~@q@xAeAvA_APMp@i@l@g@RONQNWJUNa@Xq@HQ",
              "path": [
                {
                  "lat": 28.518340000000002,
                  "lng": 77.15978000000001
                },
                {
                  "lat": 28.518410000000003,
                  "lng": 77.15974
                },
                {
                  "lat": 28.51845,
                  "lng": 77.15982000000001
                },
                {
                  "lat": 28.518380000000004,
                  "lng": 77.15986000000001
                },
                {
                  "lat": 28.517830000000004,
                  "lng": 77.16038
                },
                {
                  "lat": 28.517190000000003,
                  "lng": 77.16112000000001
                },
                {
                  "lat": 28.51648,
                  "lng": 77.16202000000001
                },
                {
                  "lat": 28.516360000000002,
                  "lng": 77.16217
                },
                {
                  "lat": 28.516150000000003,
                  "lng": 77.16243
                },
                {
                  "lat": 28.515950000000004,
                  "lng": 77.1627
                },
                {
                  "lat": 28.515590000000003,
                  "lng": 77.16314000000001
                },
                {
                  "lat": 28.515200000000004,
                  "lng": 77.1636
                },
                {
                  "lat": 28.515040000000003,
                  "lng": 77.16377
                },
                {
                  "lat": 28.5147,
                  "lng": 77.16415
                },
                {
                  "lat": 28.514630000000004,
                  "lng": 77.16424
                },
                {
                  "lat": 28.514260000000004,
                  "lng": 77.16461000000001
                },
                {
                  "lat": 28.514010000000003,
                  "lng": 77.16486
                },
                {
                  "lat": 28.51385,
                  "lng": 77.165
                },
                {
                  "lat": 28.513620000000003,
                  "lng": 77.16519000000001
                },
                {
                  "lat": 28.513220000000004,
                  "lng": 77.16553
                },
                {
                  "lat": 28.512900000000002,
                  "lng": 77.16578000000001
                },
                {
                  "lat": 28.51245,
                  "lng": 77.16613000000001
                },
                {
                  "lat": 28.512010000000004,
                  "lng": 77.16645000000001
                },
                {
                  "lat": 28.511920000000003,
                  "lng": 77.16652
                },
                {
                  "lat": 28.511670000000002,
                  "lng": 77.16673
                },
                {
                  "lat": 28.511440000000004,
                  "lng": 77.16693000000001
                },
                {
                  "lat": 28.51134,
                  "lng": 77.16701
                },
                {
                  "lat": 28.511260000000004,
                  "lng": 77.1671
                },
                {
                  "lat": 28.511180000000003,
                  "lng": 77.16722
                },
                {
                  "lat": 28.511120000000002,
                  "lng": 77.16733
                },
                {
                  "lat": 28.51104,
                  "lng": 77.1675
                },
                {
                  "lat": 28.510910000000003,
                  "lng": 77.16775000000001
                },
                {
                  "lat": 28.51086,
                  "lng": 77.16784000000001
                }
              ],
              "lat_lngs": [
                {
                  "lat": 28.518340000000002,
                  "lng": 77.15978000000001
                },
                {
                  "lat": 28.518410000000003,
                  "lng": 77.15974
                },
                {
                  "lat": 28.51845,
                  "lng": 77.15982000000001
                },
                {
                  "lat": 28.518380000000004,
                  "lng": 77.15986000000001
                },
                {
                  "lat": 28.517830000000004,
                  "lng": 77.16038
                },
                {
                  "lat": 28.517190000000003,
                  "lng": 77.16112000000001
                },
                {
                  "lat": 28.51648,
                  "lng": 77.16202000000001
                },
                {
                  "lat": 28.516360000000002,
                  "lng": 77.16217
                },
                {
                  "lat": 28.516150000000003,
                  "lng": 77.16243
                },
                {
                  "lat": 28.515950000000004,
                  "lng": 77.1627
                },
                {
                  "lat": 28.515590000000003,
                  "lng": 77.16314000000001
                },
                {
                  "lat": 28.515200000000004,
                  "lng": 77.1636
                },
                {
                  "lat": 28.515040000000003,
                  "lng": 77.16377
                },
                {
                  "lat": 28.5147,
                  "lng": 77.16415
                },
                {
                  "lat": 28.514630000000004,
                  "lng": 77.16424
                },
                {
                  "lat": 28.514260000000004,
                  "lng": 77.16461000000001
                },
                {
                  "lat": 28.514010000000003,
                  "lng": 77.16486
                },
                {
                  "lat": 28.51385,
                  "lng": 77.165
                },
                {
                  "lat": 28.513620000000003,
                  "lng": 77.16519000000001
                },
                {
                  "lat": 28.513220000000004,
                  "lng": 77.16553
                },
                {
                  "lat": 28.512900000000002,
                  "lng": 77.16578000000001
                },
                {
                  "lat": 28.51245,
                  "lng": 77.16613000000001
                },
                {
                  "lat": 28.512010000000004,
                  "lng": 77.16645000000001
                },
                {
                  "lat": 28.511920000000003,
                  "lng": 77.16652
                },
                {
                  "lat": 28.511670000000002,
                  "lng": 77.16673
                },
                {
                  "lat": 28.511440000000004,
                  "lng": 77.16693000000001
                },
                {
                  "lat": 28.51134,
                  "lng": 77.16701
                },
                {
                  "lat": 28.511260000000004,
                  "lng": 77.1671
                },
                {
                  "lat": 28.511180000000003,
                  "lng": 77.16722
                },
                {
                  "lat": 28.511120000000002,
                  "lng": 77.16733
                },
                {
                  "lat": 28.51104,
                  "lng": 77.1675
                },
                {
                  "lat": 28.510910000000003,
                  "lng": 77.16775000000001
                },
                {
                  "lat": 28.51086,
                  "lng": 77.16784000000001
                }
              ],
              "instructions": "Make a <b>U-turn</b> at <b>Aruna Asaf Ali Marg</b><div style=\"font-size:0.9em\">Pass by Cafe Connect (on the left in 700&nbsp;m)</div>",
              "start_point": {
                "lat": 28.518342,
                "lng": 77.1597821
              },
              "end_point": {
                "lat": 28.5108562,
                "lng": 77.16783649999999
              }
            },
            {
              "distance": {
                "text": "0.8 km",
                "value": 839
              },
              "duration": {
                "text": "2 mins",
                "value": 116
              },
              "end_location": {
                "lat": 28.5161976,
                "lng": 77.16217739999999
              },
              "maneuver": "uturn-right",
              "polyline": {
                "points": "{o_mD_z~uMZTEL]p@c@~@a@`@KJQLWROJgEzCc@\\gAz@e@`@o@h@UT]Xy@v@_@`@YVyBbC[\\WZs@z@aBpBa@h@"
              },
              "start_location": {
                "lat": 28.5108562,
                "lng": 77.16783649999999
              },
              "travel_mode": "DRIVING",
              "encoded_lat_lngs": "{o_mD_z~uMZTEL]p@c@~@a@`@KJQLWROJgEzCc@\\gAz@e@`@o@h@UT]Xy@v@_@`@YVyBbC[\\WZs@z@aBpBa@h@",
              "path": [
                {
                  "lat": 28.51086,
                  "lng": 77.16784000000001
                },
                {
                  "lat": 28.510720000000003,
                  "lng": 77.16773
                },
                {
                  "lat": 28.51075,
                  "lng": 77.16766000000001
                },
                {
                  "lat": 28.510900000000003,
                  "lng": 77.16741
                },
                {
                  "lat": 28.511080000000003,
                  "lng": 77.16709
                },
                {
                  "lat": 28.511250000000004,
                  "lng": 77.16692
                },
                {
                  "lat": 28.51131,
                  "lng": 77.16686
                },
                {
                  "lat": 28.511400000000002,
                  "lng": 77.16679
                },
                {
                  "lat": 28.51152,
                  "lng": 77.16669
                },
                {
                  "lat": 28.5116,
                  "lng": 77.16663000000001
                },
                {
                  "lat": 28.512600000000003,
                  "lng": 77.16585
                },
                {
                  "lat": 28.512780000000003,
                  "lng": 77.1657
                },
                {
                  "lat": 28.513140000000003,
                  "lng": 77.1654
                },
                {
                  "lat": 28.513330000000003,
                  "lng": 77.16523000000001
                },
                {
                  "lat": 28.51357,
                  "lng": 77.16502000000001
                },
                {
                  "lat": 28.51368,
                  "lng": 77.16491
                },
                {
                  "lat": 28.513830000000002,
                  "lng": 77.16478000000001
                },
                {
                  "lat": 28.514120000000002,
                  "lng": 77.1645
                },
                {
                  "lat": 28.514280000000003,
                  "lng": 77.16433
                },
                {
                  "lat": 28.51441,
                  "lng": 77.16421000000001
                },
                {
                  "lat": 28.515020000000003,
                  "lng": 77.16355
                },
                {
                  "lat": 28.51516,
                  "lng": 77.16340000000001
                },
                {
                  "lat": 28.51528,
                  "lng": 77.16326000000001
                },
                {
                  "lat": 28.51554,
                  "lng": 77.16296000000001
                },
                {
                  "lat": 28.51603,
                  "lng": 77.16239
                },
                {
                  "lat": 28.5162,
                  "lng": 77.16218
                }
              ],
              "lat_lngs": [
                {
                  "lat": 28.51086,
                  "lng": 77.16784000000001
                },
                {
                  "lat": 28.510720000000003,
                  "lng": 77.16773
                },
                {
                  "lat": 28.51075,
                  "lng": 77.16766000000001
                },
                {
                  "lat": 28.510900000000003,
                  "lng": 77.16741
                },
                {
                  "lat": 28.511080000000003,
                  "lng": 77.16709
                },
                {
                  "lat": 28.511250000000004,
                  "lng": 77.16692
                },
                {
                  "lat": 28.51131,
                  "lng": 77.16686
                },
                {
                  "lat": 28.511400000000002,
                  "lng": 77.16679
                },
                {
                  "lat": 28.51152,
                  "lng": 77.16669
                },
                {
                  "lat": 28.5116,
                  "lng": 77.16663000000001
                },
                {
                  "lat": 28.512600000000003,
                  "lng": 77.16585
                },
                {
                  "lat": 28.512780000000003,
                  "lng": 77.1657
                },
                {
                  "lat": 28.513140000000003,
                  "lng": 77.1654
                },
                {
                  "lat": 28.513330000000003,
                  "lng": 77.16523000000001
                },
                {
                  "lat": 28.51357,
                  "lng": 77.16502000000001
                },
                {
                  "lat": 28.51368,
                  "lng": 77.16491
                },
                {
                  "lat": 28.513830000000002,
                  "lng": 77.16478000000001
                },
                {
                  "lat": 28.514120000000002,
                  "lng": 77.1645
                },
                {
                  "lat": 28.514280000000003,
                  "lng": 77.16433
                },
                {
                  "lat": 28.51441,
                  "lng": 77.16421000000001
                },
                {
                  "lat": 28.515020000000003,
                  "lng": 77.16355
                },
                {
                  "lat": 28.51516,
                  "lng": 77.16340000000001
                },
                {
                  "lat": 28.51528,
                  "lng": 77.16326000000001
                },
                {
                  "lat": 28.51554,
                  "lng": 77.16296000000001
                },
                {
                  "lat": 28.51603,
                  "lng": 77.16239
                },
                {
                  "lat": 28.5162,
                  "lng": 77.16218
                }
              ],
              "instructions": "Make a <b>U-turn</b>",
              "start_point": {
                "lat": 28.5108562,
                "lng": 77.16783649999999
              },
              "end_point": {
                "lat": 28.5161976,
                "lng": 77.16217739999999
              }
            }
          ],
          "traffic_speed_entry": [],
          "via_waypoint": [],
          "via_waypoints": []
        },
        {
          "distance": {
            "text": "0.7 km",
            "value": 708
          },
          "duration": {
            "text": "1 min",
            "value": 89
          },
          "end_address": "B 1/1569-A, Vasant Kunj, Pocket 2, Sector D, Vasant Kunj, New Delhi, Delhi 110070, India",
          "end_location": {
            "lat": 28.5120095,
            "lng": 77.1664459
          },
          "start_address": "Opp. Church Road, Church Red Light D-3, Vasant Kunj, New Delhi, Delhi 110070, India",
          "start_location": {
            "lat": 28.5161976,
            "lng": 77.16217739999999
          },
          "steps": [
            {
              "distance": {
                "text": "33 m",
                "value": 33
              },
              "duration": {
                "text": "1 min",
                "value": 4
              },
              "end_location": {
                "lat": 28.5163987,
                "lng": 77.1619264
              },
              "polyline": {
                "points": "gq`mDsv}uMg@p@"
              },
              "start_location": {
                "lat": 28.5161976,
                "lng": 77.16217739999999
              },
              "travel_mode": "DRIVING",
              "encoded_lat_lngs": "gq`mDsv}uMg@p@",
              "path": [
                {
                  "lat": 28.5162,
                  "lng": 77.16218
                },
                {
                  "lat": 28.5164,
                  "lng": 77.16193000000001
                }
              ],
              "lat_lngs": [
                {
                  "lat": 28.5162,
                  "lng": 77.16218
                },
                {
                  "lat": 28.5164,
                  "lng": 77.16193000000001
                }
              ],
              "instructions": "Head <b>northwest</b> on <b>Abdul Gaffar Khan Marg</b>/<wbr/><b>Vasant Kunj Marg</b> toward <b>Church Rd</b>",
              "maneuver": "",
              "start_point": {
                "lat": 28.5161976,
                "lng": 77.16217739999999
              },
              "end_point": {
                "lat": 28.5163987,
                "lng": 77.1619264
              }
            },
            {
              "distance": {
                "text": "0.7 km",
                "value": 675
              },
              "duration": {
                "text": "1 min",
                "value": 85
              },
              "end_location": {
                "lat": 28.5120095,
                "lng": 77.1664459
              },
              "maneuver": "uturn-right",
              "polyline": {
                "points": "or`mDau}uMOQV]h@s@f@u@fAwAlA{A^a@bAkALQhAiAp@q@^[l@e@nAcA~@q@xAeAvA_A"
              },
              "start_location": {
                "lat": 28.5163987,
                "lng": 77.1619264
              },
              "travel_mode": "DRIVING",
              "encoded_lat_lngs": "or`mDau}uMOQV]h@s@f@u@fAwAlA{A^a@bAkALQhAiAp@q@^[l@e@nAcA~@q@xAeAvA_A",
              "path": [
                {
                  "lat": 28.5164,
                  "lng": 77.16193000000001
                },
                {
                  "lat": 28.51648,
                  "lng": 77.16202000000001
                },
                {
                  "lat": 28.516360000000002,
                  "lng": 77.16217
                },
                {
                  "lat": 28.516150000000003,
                  "lng": 77.16243
                },
                {
                  "lat": 28.515950000000004,
                  "lng": 77.1627
                },
                {
                  "lat": 28.515590000000003,
                  "lng": 77.16314000000001
                },
                {
                  "lat": 28.515200000000004,
                  "lng": 77.1636
                },
                {
                  "lat": 28.515040000000003,
                  "lng": 77.16377
                },
                {
                  "lat": 28.5147,
                  "lng": 77.16415
                },
                {
                  "lat": 28.514630000000004,
                  "lng": 77.16424
                },
                {
                  "lat": 28.514260000000004,
                  "lng": 77.16461000000001
                },
                {
                  "lat": 28.514010000000003,
                  "lng": 77.16486
                },
                {
                  "lat": 28.51385,
                  "lng": 77.165
                },
                {
                  "lat": 28.513620000000003,
                  "lng": 77.16519000000001
                },
                {
                  "lat": 28.513220000000004,
                  "lng": 77.16553
                },
                {
                  "lat": 28.512900000000002,
                  "lng": 77.16578000000001
                },
                {
                  "lat": 28.51245,
                  "lng": 77.16613000000001
                },
                {
                  "lat": 28.512010000000004,
                  "lng": 77.16645000000001
                }
              ],
              "lat_lngs": [
                {
                  "lat": 28.5164,
                  "lng": 77.16193000000001
                },
                {
                  "lat": 28.51648,
                  "lng": 77.16202000000001
                },
                {
                  "lat": 28.516360000000002,
                  "lng": 77.16217
                },
                {
                  "lat": 28.516150000000003,
                  "lng": 77.16243
                },
                {
                  "lat": 28.515950000000004,
                  "lng": 77.1627
                },
                {
                  "lat": 28.515590000000003,
                  "lng": 77.16314000000001
                },
                {
                  "lat": 28.515200000000004,
                  "lng": 77.1636
                },
                {
                  "lat": 28.515040000000003,
                  "lng": 77.16377
                },
                {
                  "lat": 28.5147,
                  "lng": 77.16415
                },
                {
                  "lat": 28.514630000000004,
                  "lng": 77.16424
                },
                {
                  "lat": 28.514260000000004,
                  "lng": 77.16461000000001
                },
                {
                  "lat": 28.514010000000003,
                  "lng": 77.16486
                },
                {
                  "lat": 28.51385,
                  "lng": 77.165
                },
                {
                  "lat": 28.513620000000003,
                  "lng": 77.16519000000001
                },
                {
                  "lat": 28.513220000000004,
                  "lng": 77.16553
                },
                {
                  "lat": 28.512900000000002,
                  "lng": 77.16578000000001
                },
                {
                  "lat": 28.51245,
                  "lng": 77.16613000000001
                },
                {
                  "lat": 28.512010000000004,
                  "lng": 77.16645000000001
                }
              ],
              "instructions": "Make a <b>U-turn</b> at <b>Church Rd</b><div style=\"font-size:0.9em\">Pass by Cafe Connect (on the left in 400&nbsp;m)</div>",
              "start_point": {
                "lat": 28.5163987,
                "lng": 77.1619264
              },
              "end_point": {
                "lat": 28.5120095,
                "lng": 77.1664459
              }
            }
          ],
          "traffic_speed_entry": [],
          "via_waypoint": [],
          "via_waypoints": []
        }
      ],
      "overview_polyline": "yedmDkv{uMx@cBvDpCn@I\\I`AyA`@y@Vg@f@k@rAcAlLaGrCaBvAy@VSDIdAyBdAyBuFaEQ^P_@o@a@GKCUbAwDPm@TSt@]nAWRSNWz@aBXk@LFzAb@jFxBfAj@HM`BkCl@s@b@[hAo@tCkBzEgDmAgCyAwCWYwD_CcCeB_BbDwArCgDjGg@zA{@hBiAxBzAb@jFxBtAt@`CtADIEHJD~Ax@tAr@RPRXlBvDLTMU_AkBaAeBSQuAs@_By@cFqCkFyB{Ac@MGjA}BhB}DbCiFrEaJxE}JzAuCVs@N{AHS`@JhBf@t@^f@d@t@rA^~@v@dC~@xCLnARzDJz@J^jDlHhFjLh@hAFNMFg@\\tAjEhBeAbDoBb@fAb@hA`@[a@Zc@iAc@gAg@qAg@eAg@y@yBlBiAn@GOLGlBgB~BsCdDqEpAiBtCsDbBmBvA{ApAmA|BiBbGeE~AqAb@a@Zm@h@sAHQZTc@~@c@~@a@`@]XsGxEsD|CwApAy@x@aFxFcCzCg@p@OQ`AqAnBmC~D{EzB{BlAaAnCuBpDeC",
      "summary": "Nelson Mandela Marg and Bhagwan Mahaveer Rd",
      "warnings": [],
      "waypoint_order": [
        0,
        1,
        2,
        3,
        4,
        5,
        6,
        7
      ],
      "overview_path": [
        {
          "lat": 28.534850000000002,
          "lng": 77.15190000000001
        },
        {
          "lat": 28.534560000000003,
          "lng": 77.1524
        },
        {
          "lat": 28.533640000000002,
          "lng": 77.15167000000001
        },
        {
          "lat": 28.533400000000004,
          "lng": 77.15172000000001
        },
        {
          "lat": 28.533250000000002,
          "lng": 77.15177000000001
        },
        {
          "lat": 28.53292,
          "lng": 77.15222
        },
        {
          "lat": 28.532750000000004,
          "lng": 77.15251
        },
        {
          "lat": 28.53263,
          "lng": 77.15271000000001
        },
        {
          "lat": 28.53243,
          "lng": 77.15293000000001
        },
        {
          "lat": 28.532010000000003,
          "lng": 77.15327
        },
        {
          "lat": 28.529860000000003,
          "lng": 77.15456
        },
        {
          "lat": 28.529120000000002,
          "lng": 77.15505
        },
        {
          "lat": 28.52868,
          "lng": 77.15534000000001
        },
        {
          "lat": 28.528560000000002,
          "lng": 77.15544000000001
        },
        {
          "lat": 28.528530000000003,
          "lng": 77.15549
        },
        {
          "lat": 28.528180000000003,
          "lng": 77.15610000000001
        },
        {
          "lat": 28.52783,
          "lng": 77.15671
        },
        {
          "lat": 28.52906,
          "lng": 77.15768000000001
        },
        {
          "lat": 28.52915,
          "lng": 77.15752
        },
        {
          "lat": 28.52906,
          "lng": 77.15768000000001
        },
        {
          "lat": 28.529300000000003,
          "lng": 77.15785000000001
        },
        {
          "lat": 28.52934,
          "lng": 77.15791
        },
        {
          "lat": 28.529360000000004,
          "lng": 77.15802000000001
        },
        {
          "lat": 28.529020000000003,
          "lng": 77.15894
        },
        {
          "lat": 28.528930000000003,
          "lng": 77.15917
        },
        {
          "lat": 28.528820000000003,
          "lng": 77.15927
        },
        {
          "lat": 28.528550000000003,
          "lng": 77.15942000000001
        },
        {
          "lat": 28.528150000000004,
          "lng": 77.15954
        },
        {
          "lat": 28.528050000000004,
          "lng": 77.15964000000001
        },
        {
          "lat": 28.527970000000003,
          "lng": 77.15976
        },
        {
          "lat": 28.527670000000004,
          "lng": 77.16025
        },
        {
          "lat": 28.527540000000002,
          "lng": 77.16047
        },
        {
          "lat": 28.52747,
          "lng": 77.16043
        },
        {
          "lat": 28.52701,
          "lng": 77.16025
        },
        {
          "lat": 28.525830000000003,
          "lng": 77.15964000000001
        },
        {
          "lat": 28.525470000000002,
          "lng": 77.15942000000001
        },
        {
          "lat": 28.525420000000004,
          "lng": 77.15949
        },
        {
          "lat": 28.52493,
          "lng": 77.16019
        },
        {
          "lat": 28.524700000000003,
          "lng": 77.16045000000001
        },
        {
          "lat": 28.524520000000003,
          "lng": 77.16059000000001
        },
        {
          "lat": 28.524150000000002,
          "lng": 77.16083
        },
        {
          "lat": 28.523400000000002,
          "lng": 77.16137
        },
        {
          "lat": 28.5223,
          "lng": 77.16221
        },
        {
          "lat": 28.52269,
          "lng": 77.16289
        },
        {
          "lat": 28.52314,
          "lng": 77.16365
        },
        {
          "lat": 28.523260000000004,
          "lng": 77.16378
        },
        {
          "lat": 28.52418,
          "lng": 77.16442
        },
        {
          "lat": 28.52484,
          "lng": 77.16493000000001
        },
        {
          "lat": 28.52532,
          "lng": 77.16411000000001
        },
        {
          "lat": 28.525760000000002,
          "lng": 77.16337
        },
        {
          "lat": 28.526600000000002,
          "lng": 77.16203
        },
        {
          "lat": 28.5268,
          "lng": 77.16157000000001
        },
        {
          "lat": 28.5271,
          "lng": 77.16104
        },
        {
          "lat": 28.52747,
          "lng": 77.16043
        },
        {
          "lat": 28.52701,
          "lng": 77.16025
        },
        {
          "lat": 28.525830000000003,
          "lng": 77.15964000000001
        },
        {
          "lat": 28.5254,
          "lng": 77.15937000000001
        },
        {
          "lat": 28.52475,
          "lng": 77.15894
        },
        {
          "lat": 28.524720000000002,
          "lng": 77.15899
        },
        {
          "lat": 28.52475,
          "lng": 77.15894
        },
        {
          "lat": 28.524690000000003,
          "lng": 77.15891
        },
        {
          "lat": 28.524210000000004,
          "lng": 77.15862000000001
        },
        {
          "lat": 28.523780000000002,
          "lng": 77.15836
        },
        {
          "lat": 28.523680000000002,
          "lng": 77.15827
        },
        {
          "lat": 28.523580000000003,
          "lng": 77.15814
        },
        {
          "lat": 28.523030000000002,
          "lng": 77.15722000000001
        },
        {
          "lat": 28.52296,
          "lng": 77.15711
        },
        {
          "lat": 28.523030000000002,
          "lng": 77.15722000000001
        },
        {
          "lat": 28.52335,
          "lng": 77.15776000000001
        },
        {
          "lat": 28.523680000000002,
          "lng": 77.15827
        },
        {
          "lat": 28.523780000000002,
          "lng": 77.15836
        },
        {
          "lat": 28.524210000000004,
          "lng": 77.15862000000001
        },
        {
          "lat": 28.524690000000003,
          "lng": 77.15891
        },
        {
          "lat": 28.525830000000003,
          "lng": 77.15964000000001
        },
        {
          "lat": 28.52701,
          "lng": 77.16025
        },
        {
          "lat": 28.52747,
          "lng": 77.16043
        },
        {
          "lat": 28.527540000000002,
          "lng": 77.16047
        },
        {
          "lat": 28.527160000000002,
          "lng": 77.1611
        },
        {
          "lat": 28.52663,
          "lng": 77.16205000000001
        },
        {
          "lat": 28.52597,
          "lng": 77.16322000000001
        },
        {
          "lat": 28.524910000000002,
          "lng": 77.16499
        },
        {
          "lat": 28.52382,
          "lng": 77.16690000000001
        },
        {
          "lat": 28.523360000000004,
          "lng": 77.16765000000001
        },
        {
          "lat": 28.52324,
          "lng": 77.16791
        },
        {
          "lat": 28.52316,
          "lng": 77.16837000000001
        },
        {
          "lat": 28.523110000000003,
          "lng": 77.16847
        },
        {
          "lat": 28.522940000000002,
          "lng": 77.16841000000001
        },
        {
          "lat": 28.52241,
          "lng": 77.16821
        },
        {
          "lat": 28.522140000000004,
          "lng": 77.16805000000001
        },
        {
          "lat": 28.52194,
          "lng": 77.16786
        },
        {
          "lat": 28.521670000000004,
          "lng": 77.16744000000001
        },
        {
          "lat": 28.521510000000003,
          "lng": 77.16712000000001
        },
        {
          "lat": 28.521230000000003,
          "lng": 77.16645000000001
        },
        {
          "lat": 28.52091,
          "lng": 77.16568000000001
        },
        {
          "lat": 28.520840000000003,
          "lng": 77.16528000000001
        },
        {
          "lat": 28.520740000000004,
          "lng": 77.16434000000001
        },
        {
          "lat": 28.520680000000002,
          "lng": 77.16404
        },
        {
          "lat": 28.52062,
          "lng": 77.16388
        },
        {
          "lat": 28.51976,
          "lng": 77.16237000000001
        },
        {
          "lat": 28.518590000000003,
          "lng": 77.16023000000001
        },
        {
          "lat": 28.518380000000004,
          "lng": 77.15986000000001
        },
        {
          "lat": 28.518340000000002,
          "lng": 77.15978000000001
        },
        {
          "lat": 28.518410000000003,
          "lng": 77.15974
        },
        {
          "lat": 28.518610000000002,
          "lng": 77.15959000000001
        },
        {
          "lat": 28.51818,
          "lng": 77.15857000000001
        },
        {
          "lat": 28.517650000000003,
          "lng": 77.15892000000001
        },
        {
          "lat": 28.516830000000002,
          "lng": 77.15948
        },
        {
          "lat": 28.516650000000002,
          "lng": 77.15912
        },
        {
          "lat": 28.51647,
          "lng": 77.15875000000001
        },
        {
          "lat": 28.5163,
          "lng": 77.15889
        },
        {
          "lat": 28.51647,
          "lng": 77.15875000000001
        },
        {
          "lat": 28.516650000000002,
          "lng": 77.15912
        },
        {
          "lat": 28.516830000000002,
          "lng": 77.15948
        },
        {
          "lat": 28.517030000000002,
          "lng": 77.15989
        },
        {
          "lat": 28.51723,
          "lng": 77.16024
        },
        {
          "lat": 28.51743,
          "lng": 77.16053000000001
        },
        {
          "lat": 28.518040000000003,
          "lng": 77.15998
        },
        {
          "lat": 28.518410000000003,
          "lng": 77.15974
        },
        {
          "lat": 28.51845,
          "lng": 77.15982000000001
        },
        {
          "lat": 28.518380000000004,
          "lng": 77.15986000000001
        },
        {
          "lat": 28.517830000000004,
          "lng": 77.16038
        },
        {
          "lat": 28.517190000000003,
          "lng": 77.16112000000001
        },
        {
          "lat": 28.516360000000002,
          "lng": 77.16217
        },
        {
          "lat": 28.515950000000004,
          "lng": 77.1627
        },
        {
          "lat": 28.515200000000004,
          "lng": 77.1636
        },
        {
          "lat": 28.5147,
          "lng": 77.16415
        },
        {
          "lat": 28.514260000000004,
          "lng": 77.16461000000001
        },
        {
          "lat": 28.51385,
          "lng": 77.165
        },
        {
          "lat": 28.513220000000004,
          "lng": 77.16553
        },
        {
          "lat": 28.511920000000003,
          "lng": 77.16652
        },
        {
          "lat": 28.511440000000004,
          "lng": 77.16693000000001
        },
        {
          "lat": 28.511260000000004,
          "lng": 77.1671
        },
        {
          "lat": 28.511120000000002,
          "lng": 77.16733
        },
        {
          "lat": 28.510910000000003,
          "lng": 77.16775000000001
        },
        {
          "lat": 28.51086,
          "lng": 77.16784000000001
        },
        {
          "lat": 28.510720000000003,
          "lng": 77.16773
        },
        {
          "lat": 28.510900000000003,
          "lng": 77.16741
        },
        {
          "lat": 28.511080000000003,
          "lng": 77.16709
        },
        {
          "lat": 28.511250000000004,
          "lng": 77.16692
        },
        {
          "lat": 28.511400000000002,
          "lng": 77.16679
        },
        {
          "lat": 28.512780000000003,
          "lng": 77.1657
        },
        {
          "lat": 28.51368,
          "lng": 77.16491
        },
        {
          "lat": 28.514120000000002,
          "lng": 77.1645
        },
        {
          "lat": 28.51441,
          "lng": 77.16421000000001
        },
        {
          "lat": 28.51554,
          "lng": 77.16296000000001
        },
        {
          "lat": 28.5162,
          "lng": 77.16218
        },
        {
          "lat": 28.5164,
          "lng": 77.16193000000001
        },
        {
          "lat": 28.51648,
          "lng": 77.16202000000001
        },
        {
          "lat": 28.516150000000003,
          "lng": 77.16243
        },
        {
          "lat": 28.515590000000003,
          "lng": 77.16314000000001
        },
        {
          "lat": 28.514630000000004,
          "lng": 77.16424
        },
        {
          "lat": 28.514010000000003,
          "lng": 77.16486
        },
        {
          "lat": 28.513620000000003,
          "lng": 77.16519000000001
        },
        {
          "lat": 28.512900000000002,
          "lng": 77.16578000000001
        },
        {
          "lat": 28.512010000000004,
          "lng": 77.16645000000001
        }
      ]
    }
    return d;
  }

}
