import { Component, OnInit, Input, Output, EventEmitter,SimpleChange } from '@angular/core';

@Component({
  selector: 'input-tag-email',
  templateUrl: './input-tag-email.component.html',
  styleUrls: ['./input-tag-email.component.css']
})
export class InputTagEmailComponent implements OnInit {
  private iterable:any= null;
  public tempCollection:any ={};
  public  keyed:any = Object.keys;
  public queryString:string='all';
  public checkTrack:string='';
  public showDropMenu:boolean = false;
  public hidespan = false;

  constructor() { }


  @Input()
  set getIterable(name: any) {
    this.iterable = name;
    Promise.resolve(this.iterable).then((val:any)=>  this.iterable = val);   
  }
 
  get getIterable(): any {     
    return this.iterable; 
  }

  @Output() sendRes = new EventEmitter<any>(); 

  ngOnInit() {
    setTimeout(() => this.formlists(), 300);
  }

  formlists(){
    if(Object.keys(this.iterable).length && this.iterable.hasOwnProperty('sub')){
      // console.log(this.iterable);
      this.iterable.main
      .forEach((item:any) => {
        // console.log(item);
        this.iterable.sub.includes(item.useremail) && ( this.tempCollection[item.useremail.toString()] = item )
       
      });
     return this.tempCollection;
    }  
  }

  dropDown(){
    this.showDropMenu = !this.showDropMenu;
  }
  selectedElem(val:any){
    this.tempCollection[val.useremail.toString()] = val;
    return this.tempCollection && this.sendRes.emit(this.tempCollection);
  }

  removeItem(index:any){    
    this.tempCollection[index] = null;
    delete this.tempCollection[index];
    return this.tempCollection && setTimeout(() => this.sendRes.emit(this.tempCollection), 50);
  }


  getChkEvent(event:any,item:any){  
    
    let rmno = item.useremail.toString();
    this.keyed(this.tempCollection).includes(item.useremail.toString()) ? event.target.checked = false : event.target.checked=true; 
    event.target.checked ? this.selectedElem(item) : this.removeItem(rmno);    
  }

  chekEvent(id:string){    
   
    return this.tempCollection[id] && this.keyed(this.tempCollection[id]).length && true || false;
  }

}
