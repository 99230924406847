/**
 * @Author: Deepak Pookkote <group10>
 * @Date:   2018-04-17T11:31:39+05:30
 * @Email:  deepak@groupten.com
 * @Project: School-Bus
 * @Filename: trackerForm.dir.ts
 * @Last modified by:   group10
 * @Last modified time: 2018-05-22T15:19:43+05:30
 * @Copyright: Group10 Technologies
 */



/*Auther:Deepak */
import { Component, Input, Output, EventEmitter } from '@angular/core';
import { globalService } from './../../../services/global.service';
import { ApiService } from './../../../services/ApiService';
import { RouterModule, Routes, Router } from '@angular/router';
import { ActivatedRoute, Params, NavigationEnd } from '@angular/router';
import { GlobalUtils } from './../../../services/global-utils';

const swal = require('sweetalert');

@Component({
  selector: 'tracker-form',
  templateUrl: './trackerFormTemplate.html',
  styles: [`

    .TrackerInfo-Panel{
      padding:20px;
    }

    .formContent{
      padding-left: 16px;
      padding-right: 16px;
      padding-bottom: 8px;
    }

    `  ],
})
export class OrgTrackerFormDir {

  public formError: any = {};
  public showForm: boolean = false;
  public trackerInfo: any = [];
  private currentTrackerId: string = '';
  private errorMesages: any = [];
  public clickMessage: any;
  public simCard1_provider:any;
  public simCard2_provider:any;
  private currentOrganization: any = this.globalService.getData().currentOrganization;
  private apiToken: any = localStorage.getItem("adminToken");


  constructor(
    //public router: Router,

    public globalService: globalService,
    public apiService: ApiService,
    private router: Router,
    private globalUtils: GlobalUtils
  ) {


    this.trackerInfo.vehicleInformation = [];


  }

  @Output() hideTrackerFormComponent: EventEmitter<any> = new EventEmitter<any>();

  ngOnInit() {

  }

  hideForm() {
    this.hideTrackerFormComponent.emit(false);
  }

  /* funtion used to refine the date for general forms */
  dateRefine(arg: any) {
    if (arg != undefined) {
      let date = arg.date;
      return date;
    }
  }

  MobileNumberValidator(event: any) {
    event.srcElement.value = event.srcElement.value.replace(/[^\d].+/, "");

    if (event.srcElement.value != '') {
      //  console.log('t',event.srcElement.value);
      if ((event.which < 48 || event.which > 57)) {
        event.preventDefault();
      }
    } else {
      // event.srcElement.value=0;
    }
  }




  //save tracker validator

  saveTrackerValidator() {

    let errorFlag = false; this.errorMesages = [];

    //tracker Information

    //set tracker
    this.formError.organizationTrackerIMEI = false;
    if (this.trackerInfo.imei == "" || this.trackerInfo.imei == undefined) {
      this.formError.organizationTrackerIMEI = true;
      errorFlag = true; (this.errorMesages).push("IMEI-1 should not be empty");
    }

    // this.formError.organizationTrackerIMEI2 = false;
    // if (this.trackerInfo.imei2 == "" || this.trackerInfo.imei2 == undefined) {
    //   this.formError.organizationTrackerIMEI2 = true;
    //   errorFlag = true; (this.errorMesages).push("IMEI-2 should not be empty");
    // }

    //set BoxId
    this.formError.organizationTrackerBoxid1 = false;
    if (this.trackerInfo.BoxId == "" || this.trackerInfo.boxid == undefined) {
      this.formError.organizationTrackerBoxid1 = true;
      errorFlag = true; (this.errorMesages).push("BoxId-1 should not be empty");
    }

    // this.formError.organizationTrackerBoxid2 = false;
    // if (this.trackerInfo.boxid2 == "" || this.trackerInfo.boxid2 == undefined) {
    //   this.formError.organizationTrackerBoxid2 = true;
    //   errorFlag = true; (this.errorMesages).push("BoxId-2 should not be empty");
    // }

    //set Vehiclename
    this.formError.organizationTrackerVehiclename = false;
    if (this.trackerInfo.vehicleInformation.name == "" || this.trackerInfo.vehicleInformation.name == undefined) {
      this.formError.organizationTrackerVehiclename = true;
      errorFlag = true;
      (this.errorMesages).push("Vehiclename should not be empty");
    }

    //set Vehiclename
    this.formError.organizationTrackerType = false;
    if (this.trackerInfo.vehicleInformation.type == "" || this.trackerInfo.vehicleInformation.type == undefined) {
      this.formError.organizationTrackerType = true;
      errorFlag = true;
      (this.errorMesages).push("Type should not be empty");
    }


    //set Vehicle Registration Number
    this.formError.organizationTrackerRegistrationnumber = false;
    if (this.trackerInfo.vehicleInformation.regno == "" || this.trackerInfo.vehicleInformation.regno == undefined) {
      this.formError.organizationTrackerRegistrationnumber = true;
      errorFlag = true; (this.errorMesages).push("Registration number should not be empty");
    }

    if (errorFlag == true) { return false; } else { return true; }


  }


  /*tracker form save */

  trackerFormSave() {
    let parent: any = this;
    if (this.saveTrackerValidator() == true) {


      this.apiService.orgTrackerCreate({
        data: {
          key: this.apiToken,
          form: {
            imei: this.trackerInfo.imei,
            imei2: this.trackerInfo.imei2,
            boxid: this.trackerInfo.boxid,
            boxid2: this.trackerInfo.boxid2,
            simCard: this.trackerInfo.simCard,
            simvendor: this.simCard1_provider,
            simvendor2:this.simCard2_provider,
            simCard2: this.trackerInfo.simCard2,
            vehicleInformation: {
              name: this.trackerInfo.vehicleInformation.name,
              type: this.trackerInfo.vehicleInformation.type,
              make: this.trackerInfo.vehicleInformation.make,
              regno: this.trackerInfo.vehicleInformation.regno,
              ownerName: this.trackerInfo.vehicleInformation.ownerName,
              ownerPhone: this.trackerInfo.vehicleInformation.ownerPhone,
              ownerAddress: this.trackerInfo.vehicleInformation.ownerAddress,
              model: this.trackerInfo.vehicleInformation.model,
              manufactureYear: this.trackerInfo.vehicleInformation.manufactureYear,
              purchasedYear: this.trackerInfo.vehicleInformation.purchasedYear,
              color: this.trackerInfo.vehicleInformation.color,
              fuel: this.trackerInfo.vehicleInformation.fuel,
              engineNumber: this.trackerInfo.vehicleInformation.engineNumber,
              chasisNumber: this.trackerInfo.vehicleInformation.chasisNumber,
              insuranceCompany: this.trackerInfo.vehicleInformation.insuranceCompany,
              insurancePolicyNumber: this.trackerInfo.vehicleInformation.insurancePolicyNumber,
              // insuranceExpiryDate:this.trackerInfo.vehicleInformation.insuranceExpiryDate,
              driverName: this.trackerInfo.vehicleInformation.driverName,
              driverPhone: this.trackerInfo.vehicleInformation.driverPhone,
              driverAddress: this.trackerInfo.vehicleInformation.driverAddress
            },
            status: 'active',
            organizationId: this.currentOrganization

          }
        }

      }).then(response => {
        let result = response;
        if (result.status == "success") {
          swal("Success", "Tracker Added Successfully", "success");
          setTimeout(() => {
            swal.close();
          }, 2000);
          this.hideForm();
        } else {
          swal("Failure", this.globalUtils.getErrorMsg(response.ec), "error");
        }
      })
        .catch(error => {
          console.log('Error while saving the data', error);
        });
    }
  }

  /*tracker form save ends here */



}

/*
Notes : npm install node-sass -g
*/
