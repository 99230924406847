
import { Component} from '@angular/core';


@Component({
  selector: 'loader',
  template: `
     <section class="examples bg3">
        <div class=" bgWhite"><div class="mnml-spinner dark mx-auto"></div></div>
    </section>
  `,
  styles: [`
  .mnml-spinner {
    border-radius: 100%;
    border-style: solid;
    border-width: 0.25rem;
    height: 5rem;
    width: 5rem;
  
    animation: basic 1s infinite linear;
  }
   
  .mnml-spinner.dark {
    border-color: rgba(0, 0, 0, 0.2);
    border-top-color: rgba(0, 0, 0, 1);
  }
  @keyframes basic {
    0% {
      transform: rotate(0);
    }
    100% {
      transform: rotate(359.9deg);
    }
  }
  
  / ##### DEMO ##### /
  * {
    box-sizing: border-box;
  }
  :after,
  :before {
    box-sizing: inherit;
  }
   
  .mx-auto {
    margin-right: auto;
    margin-left: auto;
  }
  .examples {
    font-size: 0;
    width: 100%;
  }
   
  .bg3 {
    background-color: #b6d5e1;
  }
  .ex {
    display: inline-block;
    padding-top: 15%;
    padding-bottom: 15%;
    width: 50%;
  }
  @media (min-width: 768px) {
    .ex {
      padding-top: 7.5%;
      padding-bottom: 7.5%;
    }
  }
  .ex--light {
    background-color: rgba(255, 255, 255, 0.5);
  }
  .bgWhite{
    background-color:#fff;
  }


`  ],
})
export class LoaderDirectiveClass {



  constructor(
  
  ) {

  }

  ngOnInit(){
   
  }








}
/*
Notes : npm install node-sass -g
*/
