import { Component, OnInit,Inject, HostListener } from '@angular/core';
import { ApiService } from '../../services/ApiService';
import { bootstrapDateTime } from './../../services/bootstrapDateTime/datePicker';

import { DOCUMENT } from "@angular/platform-browser";

declare var $: any;

interface routeInfo {
  routeName: string;
}

@Component({
  selector: 'app-call-made',
  templateUrl: './call-made.component.html',
  styleUrls: ['./call-made.component.css']
})
export class CallMadeComponent implements OnInit, routeInfo {

  public routeName: string;

  //public srchDateLog:any="";
  public displayLoaderText: boolean = true;
  public fromDateError: boolean;
  public routeType: string;

  public displaySelectedDate:any;

  public RouteListInfo: any = [];
  private dispRouteInformationList: any = [];
  public isLoading: boolean;
  public todayDate = new Date().toISOString().slice(0, 10);
  public noMoreData: boolean = false;
  navIsFixed: boolean;
  public ovralCount:number;

  public expendRouteName:any={};
  public expendedIndex:any={};
  public falseTemp:boolean;
  constructor(@Inject(DOCUMENT) private document: Document, private apiservice: ApiService, public bootstrapDate: bootstrapDateTime) {
    //this.routeName = "DemoRoute"
    //this.isLoading = true;
  }

  @HostListener("window:scroll", [])
  onWindowScroll() {
      if (window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop > 100) {
          this.navIsFixed = true;
      } else if (this.navIsFixed && window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop < 10) { this.navIsFixed = false; } } scrollToTop() { (function smoothscroll() { var currentScroll = document.documentElement.scrollTop || document.body.scrollTop; if (currentScroll > 0) {
              window.requestAnimationFrame(smoothscroll);
              window.scrollTo(0, currentScroll - (currentScroll / 5));
          }
      })();
  }

  ngOnInit() {
  //  this.initiateCallMadeReport();
  
    this.getCalendar();
    setTimeout(() => {
      $('#eventStartTime').datetimepicker({
        format: 'H:i',
        datepicker: false,
        defaultTime: '00:00',
        timepicker: true,
      });
      $('#eventEndTime').datetimepicker({
        format: 'H:i',
        datepicker: false,
        defaultTime: '23:59',
        timepicker: true,
      });
    }, 200);
  }


  getCalendar() {
    this.bootstrapDate.datetime({
      component: 'selectedLogDate'
    });



  }

  fromEndTimeError:boolean;
  fromStartTimeError:boolean;

  initiateEmptyDateValidator() {
    let isValid: boolean = true;
    let fromdate = $('#selectedLogDate').val();
    this.displaySelectedDate = fromdate;
    //console.log(this.displaySelectedDate);
    let StartDate = this.bootstrapDate.getInputvalue('eventStartTime');
    let Enddate = this.bootstrapDate.getInputvalue('eventEndTime');

    if (!fromdate) {
      this.fromDateError = true;
      isValid = false;
    } else {
      this.fromDateError = false;
    }

    if (!StartDate) {
      this.fromStartTimeError = true;
      isValid = false;
    } else {
      this.fromStartTimeError = false;
    }

    if (!Enddate) {
      this.fromEndTimeError = true;
      isValid = false;
    } else {
      this.fromEndTimeError = false;
    }
    return isValid;
  }


  filterFunction() {
    //this.pageJump=0;
    let fromdate = $('#selectedLogDate').val();



    if (fromdate) this.todayDate = fromdate;
    else this.todayDate = new Date().toISOString().slice(0, 10);
    if(this.initiateEmptyDateValidator()){
      this.initiateCallMadeReport();
    }
   
    //this.todayDate="";
  }

  FilterObj: any = {};

  initiateCallMadeReport() {
    this.isLoading = true;



    let StartDate = this.bootstrapDate.getInputvalue('eventStartTime');
    let Enddate = this.bootstrapDate.getInputvalue('eventEndTime');

    this.FilterObj = {};
   
    /* let fromdate = $('#selectedLogDate').val();
     if(fromdate) this.todayDate = fromdate;
     else fromdate = this.todayDate; */
    let apiHeader = {
      data: {
        key: localStorage.getItem('scbToken'),
        form: {
          fromDate: this.todayDate.trim(),
          routeType: this.routeType,
        },
        extra: {
          orderByDateCreated: -1
        }
      }
    };

    if (StartDate) {
      apiHeader.data.form['fromTime'] = StartDate+':00';
    }

    if (Enddate) {
      apiHeader.data.form['toTime'] = Enddate+':00';
    }

   // console.log(apiHeader);
    this.apiservice.viewRouteWiseCallReport(apiHeader).then(response => {
      if (response.response) { // console.log(response.response.length);

          //  if(response.response.length==0){
          //     this.falseTemp=true;
          //  }
        this.noMoreData = false;
        this.ovralCount = response.TotalCallCount;
        this.RouteListInfo = response.response;

        let info = [];
        let pckpt: any = [];
        for (let routeId in this.RouteListInfo) {
          let routeInfo = this.RouteListInfo[routeId];
          pckpt = [];
          for (let pck in routeInfo.pickupPoint) {
            let pckInfo = routeInfo.pickupPoint[pck];
            pckpt.push(pckInfo);
          }
          let obj = {
            routeType: routeInfo.routeType,
            routeName: routeInfo.routeName,
            totalRouteCallCount :routeInfo.totalRouteCallCount,
            totalmobNoCount:routeInfo.totalmobNoCount,
            pickupPoint: pckpt
          }
          info.push(obj);
        }
        this.dispRouteInformationList = info;
        
      //  console.log(this.dispRouteInformationList);
      }

      this.isLoading = false;
    });



  }
  public routeNameExpende:any={};
  public expendIcon:boolean=true;
  showRouteTable(index:any,routeName:any){

     
      if(this.expendRouteName==routeName && this.expendedIndex==index){
        this.expendRouteName = '';
        this.expendedIndex= '';
        this.expendIcon=true;
      }else{
        this.expendRouteName = routeName;
        this.expendedIndex= index;
        this.expendIcon=false;

      }
      // if(index !=){

      // }
      
  }

}
