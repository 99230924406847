import { Component,OnInit,Input,Output } from '@angular/core';

declare const Highcharts: any;
@Component({
    selector:'routewiserfidreport',
    styleUrls:['./rfidreport.css'],
    template:`


    <ng-container *ngIf="!routeWiseMemberInfo.length ">
    <div class="panel panel-default"> 
    <div class="panel-heading">
        <div class="row mb5">
            <div class="reportHeading text-center mb5">Route RFID Report</div>
        </div>
    </div>
    <div class="panel-body">

    <h4 class="animated pulse text-center" style="animation-duration: 1s;color:#999FBF">
    <i class="fa fa-frown-o"></i>&nbsp;<span class="meager-e2e-route-rfid-report-no-records">No records found</span></h4> 

    </div>
    </div>

    </ng-container>

    

    

   
    <ng-container *ngIf="routeWiseMemberInfo.length ">

   
    <div class="panel panel-default"> 
    <div class="panel-heading">
        <div class="row mb5">
            <div class="reportHeading text-center mb5">Route RFID Report</div>
        </div>
        <div class="row mb5">
            <div class="col-md-6 text-left">
                <label class="control-label">Start Date : </label>
                <span class="reportStartDate">{{intermediateObj.reportStartTime}}</span>
            </div>
            <div class="col-md-6 text-right">
                <label class="control-label">End Date : </label>
                <span class="reportEndDate">{{intermediateObj.reportEndTime}}</span>
            </div>
        </div>
        <div class="row">
            <div class="col-md-6 form-group text-left">
                <label class="control-label">Route Name : </label>
                <span class="reportDetails routeName">{{intermediateObj.routeName}}</span>
            </div>
            <div class="col-md-6 reportExcelDownloadLink  text-right mb5" *ngIf="routeWiseMemberInfo?.length">
                <a href="{{routeReportExcelDownloadLsink}}" title="Download" class="fa fa-file-excel-o fa-2x" target="_blank"></a>
<!--                <i class="fa fa-file-excel-o" aria-hidden="true"></i>-->

            </div>
        </div> 
        
    </div>
    
   

   
    <div>
    <ng-container >
    <div class="col-md-12 ChartContainer" id="containerBar" style="min-width: 400px; height: 400px; margin: 0 auto;margin-bottom: 12px;padding-bottom: 13px;border-bottom: 1px solid #ddd;"></div>
    
    </ng-container>
   </div>
<hr>

<!-- <div class="row" *ngFor="let item of dispRouteInformationList; let $index=index">-->
<div class="row" style="margin-top: 20px;">
<div class="nav-tabs-custom col-md-6 text-left" *ngIf="selectedDateObjs" style="width:75%">
     <ul class="nav nav-tabs">
         <ng-container >
             <li *ngFor="let tab of selectedDateObjs; let $index=index;" [ngClass]="{'active': $index === 0}" ><a href="#" data-target="#tab_1"
                     data-toggle="tab" (click)="expandRouteWiseReport($index,tab.dateWiseRFID,tab.dateWiseRFID)" class="text-uppercase"><b>{{tab.alignDateWiseRFID}}</b></a></li>
         </ng-container>
     </ul>
 </div>
</div>
 <div class="row" style="margin-top: 20px;">
 <div class="tab-content" *ngIf="showTabData" >
<!-- 2027 starts -->

<div id="divID1" class="row" *ngFor="let item of selectedDateObjs; let $index=index">

<div class="col-md-12" style=" padding: 3px 6px 10px 0px;;" *ngIf="expendRouteName==item.dateWiseRFID ">


<!--<div class="col-md-2" style="text-align:center" >
    {{item.alignDateWiseRFID}} 
</div> -->
<div class="row" >


<div class="col-md-12" style=" font-weight: 600; padding: 3px 6px 10px 0px;">
   
    <div class="col-md-2" style="text-align:center">
       Total : {{item.totalStudent}} 
    </div>
    <div class="col-md-3" style="text-align:center;color:green">
       Swiped: {{item.swipedStu}} 
    </div>
    <div class="col-md-3" style="text-align:center;color:red">
       Not Swiped : {{item.notSwipedStu}} 
    </div>
    
</div>
</div>

   <!-- <div class="col-md-2" style="cursor: pointer;text-align:center" title="Click to Expend">
    <i *ngIf="expendedIndex==$index && expendRouteName==item.dateWiseRFID" class="fa fa-minus" style="color: #429db5;" (click)="showRouteTable($index,item.dateWiseRFID)"></i> 
        <i  *ngIf="expendedIndex!=$index || expendedIndex=='' && expendRouteName!=item.dateWiseRFID" class="fa fa-plus" style="color: #429db5;" (click)="showRouteTable($index,item.dateWiseRFID)"></i>

    </div> -->

<div class="container-fluid" style="margin-top: 3px;">
<div class="row" style="margin-top: 3px;">
    <table *ngIf="expendRouteName==item.dateWiseRFID" class="table table-striped" style="width:93%">

        <thead>
        <tr class="row-name">
        <th class="text-center  v-center slNumber-report">#</th>
        <th class="text-center v-center" style="width:20%;">Name</th>
        <th class="text-center v-center" style="width:2%;">Class</th>
        <th class="text-center v-center" style="width:7%;">Section</th>
        
        <th class="text-center v-center" style="width:15%;">Swiped Time</th>
        <th class="text-center v-center" style="width:15%;">Location / Vehicle</th>
            
        </tr>
        </thead>
        <tbody>
        <ng-container *ngFor="let memberInfo of routeWiseMemberInfo;let i = index">
                        <tr *ngIf="memberInfo.swipedDate===item.dateWiseRFID"  class="row-content">
                        
                            <td class="text-right v-center" >{{i+1}}</td>
                            <td class="text-left" style="width:20%;" >{{memberInfo.memberName}}<br>
                            
                            </td>
                            <td class="text-center" style="width:2%;" >{{memberInfo.memberClass}}</td>
                            <td class="text-left" style="width:7%;" >{{memberInfo.memberSection}}</td>
                            
                            <td class="text-center" style="width:15%;" >
                                     {{memberInfo.rfidSwipTime }}
                                    
                                   
                                     
                                    
                            </td>
                           
                            <td class="text-center" style="width:15%;">
                                                       <!-- <i *ngIf="memberInfo.swipeFlag==='IN'"
                                                            class="correct fa fa-check" style="color:green;"
                                                            aria-hidden="true">
                                                        </i>-->
                                                        <i *ngIf="memberInfo.swipeFlag==='IN'" style="color:green;" title=" {{memberInfo.rfidswipNearLocation }}" class="fa fa-map-pin">&nbsp; /</i>
                                                        &nbsp;&nbsp;<i *ngIf="memberInfo.swipeFlag==='IN'" style="color:green;" title=" {{memberInfo.vehicleName }}" class="fa fa-bus"></i>
                                                      <i *ngIf="memberInfo.swipeFlag==='OUT'"
                                                            class="" style="color:red;"
                                                            aria-hidden="true">---</i>
                                                    </td>
                                                  
                        </tr>
                        
                        </ng-container>

        </tbody>

</table>
</div>
 </div>






</div>
</div>
<!-- 2027 ends -->
 </div>

</div>




    <div class="panel-body memberRfidPanelBody" style="max-height:444px" *ngFor="let dateSelectedInfo of selectedDateObjs;">
        <div class="row" >

        

    


            <div class="col-md-12 table-responsive"  >

             
                <table class="table table-stripped table-bordered" *ngIf="!noRouteRfidRecords">
                    
                   
                </table>
               <!--- <h4 class="animated pulse text-center" *ngIf="noRouteRfidRecords" style="animation-duration: 1s;color:#999FBF">
                   <i class="fa fa-frown-o"></i>&nbsp;No records found</h4> -->
            </div>
        </div>
    </div>
</div>
</ng-container>
    
    `,
})

export class RoutewiseRfidreportComponent{


    public routeWiseMemberInfo:any=[];
    public selectedDateObjs:any=[];
    public intermediateObj:any = {};
    public routeReportExcelDownloadLink:any;
    public increment:number ;
    
    @Input('routewiserfid')
    set routewiserfid(val:any){
        this.routeWiseMemberInfo='';
        //console.log('QQQQQQ ',val);
        this.routeWiseMemberInfo = val;
    }
    @Input('headerData')
    set headerData(val:any){
        this.intermediateObj='';
        this.intermediateObj =val;
    }

    @Input('excel')
    set excel(val:any){
        this.routeReportExcelDownloadLink = val;
    }
    //selectedDateObj
    @Input('selectedDateObj')
    set selectedDateObj(val:any){
        this.selectedDateObjs = val;
        for(let i = 0; i < this.selectedDateObjs.length; i++) { 
           
           if(i===0){ 

            
            this.expendRouteName = this.selectedDateObjs[i].dateWiseRFID;
            
             this.showTabData= true;
           }
            this.chartDate.push(this.selectedDateObjs[i].alignDateWiseRFID);
            this.chartTotalCnt.push(this.selectedDateObjs[i].totalStudent);
            this.chartSwipedCnt.push(this.selectedDateObjs[i].swipedStu);
            this.chartNotSwipedCnt.push(this.selectedDateObjs[i].notSwipedStu);
            
                    }

                    this.viewBarChart();
    }
    public showhide:boolean;//krish
    public renderTable:boolean=true;//krish
    public showTabData:boolean=false;//krish
    public renderChart:boolean=false;//krish
    public renderBarChart:boolean=false;//krish
    public flagChart:boolean=false;//krish routeNameExpende: any = {};
    public expendIcon: boolean = true;
    
    public setRouteNameFlag: any = {};
    public expendedIndex: any = {};
    public expendRouteName: any = {};
    public chartDate: any=[] ;
    public chartTotalCnt: any=[] ;
    public chartSwipedCnt: any=[] ;
    public chartNotSwipedCnt: any=[] ;
    totalHide:boolean = false;
    showRouteTable(index: any, routeName: any) {


        if (this.expendRouteName == routeName && this.expendedIndex == index) {
            this.expendRouteName = '';
            this.expendedIndex = '';
            this.expendIcon = true;
        } else {
            this.expendRouteName = routeName;
            this.expendedIndex = index;
            this.expendIcon = false;

        }
        // if(index !=){

        // }

    }
    expandRouteWiseReport(index: number,routeDate:any,routeDateAnother:any):void{ 
        //this.expendRouteName='';
        //this.showTabData= false;
        this.increment=0;
        this.expendRouteName = routeDateAnother;
       
         this.showTabData= true;
         //for(let i = 0; i < this.selectedDateObjs.length; i++) { }

    }
   

   
    viewBarChart(): void {
        //this.showhide=false;
        this.renderBarChart = true;
        this.renderTable = false;
        
        

        setTimeout(() => {
            this.higchartBar(this.chartTotalCnt,this.chartSwipedCnt,this.chartDate,this.chartNotSwipedCnt);
        }, 100);///here already 300
    }
    higchartBar(totalRfidCnt: any,totalRfidSwipedCnt: any,allDates: any,totalRfidNotSwipedCnt:any){ 



        Highcharts.chart('containerBar', {
            chart: {
                type: 'column'
            },
            title: {
                text: ''
            },
            subtitle: {
                text: ''
                
            },
            xAxis: {
                categories:allDates,
            },
            yAxis: {
                min: 0,
                title: {
                    text: 'STUDENT COUNTS'
                },
                stackLabels: {
                    enabled: true,
                    style: {
                        fontWeight: 'bold',
                        color: ( // theme
                            Highcharts.defaultOptions.title.style &&
                            Highcharts.defaultOptions.title.style.color
                        ) || 'gray'
                    }
                }
            },
            legend: {
                align: 'left',
                x: 0,
                verticalAlign: 'top',
                y: 25,
                floating: false,
                backgroundColor:
                    Highcharts.defaultOptions.legend.backgroundColor || 'white',
                borderColor: '#CCC',
                borderWidth: 1,
                shadow: false
            },
            tooltip: {
                headerFormat: '<b>{point.x}</b><br/>',
                pointFormat: '{series.name}: {point.y}<br/>Total: {point.stackTotal}'
            },
            plotOptions: {
                column: {
                    stacking: 'normal',
                    dataLabels: {
                        enabled: true
                    }
                },
                series: {
                pointWidth: 20
            }
            },
            series: [{
                name: 'Swiped',
                color: '#69C6CE',
               data:totalRfidSwipedCnt
                
            }, {
                name: 'Not Swiped',
                color: '#26428C',
             data:totalRfidNotSwipedCnt
            }]
        });


    }
    

}
