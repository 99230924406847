/**
 * @Project: School-Bus
 * @Filename: mobileDashboard.ts
 * @Last modified by:   group10
 * @Last modified time: 2018-05-09T12:51:36+05:30
 * @Copyright: Group10 Technologies
 */

import {
    Component,
} from '@angular/core';
import { Router } from '@angular/router';
import { globalService } from './../../services/global.service';
import { ApiService } from './../../services/ApiService';
import { authGaurd } from './../../services/auth-guard.service';
import { bootstrapDateTime } from './../../services/bootstrapDateTime/datePicker';
import { StorageService } from './../../services/storage.service';

declare var $: any;



@Component({
    //template: ``,
    templateUrl: './appAlertSettings.html',
    styles: [`
      
        tbody {
            display:block;
            height:300px;
            overflow:auto;
        }
        thead, tbody tr {
            display:table;
            width:100%;
            table-layout:fixed;
        }
        thead {
            width: calc( 100% - 1em )
        }

        thead > tr {
            font-weight : 600;
        }

        .table>tbody>tr>td,.table>tfoot>tr>td, .table>tfoot>tr>th, .table>thead>tr>td {
            border-top: 1px solid #f7f5f5;
        }
        .tableContainer{
            padding: 10px;
            border: 1px solid #f7f7f7;
            box-shadow: -3px 2px 2px 1px #f1f1f1;
        }

        .table> thead > tr > td {
            border-top: none;
        }

        .layers {
            -webkit-box-orient: vertical;
            -webkit-box-direction: normal;
            -ms-flex-flow: column nowrap;
            flex-flow: column nowrap;
            -webkit-box-align: center;
            -ms-flex-align: center;
            align-items: center;
            box-shadow: 1px 1px 0px 0px lightgrey;
          }
          .mB-10 {
            margin-bottom: 10px!important;
          }
          .lh-1 {
            line-height: 1!important;
        }
        .field-icon {
            float: right;
            margin-left: -25px;
            margin-top: -25px;
            margin-right:5px;
            position: relative;
            z-index: 2;
            cursor:pointer;
        }

        .section-title-border {
            width: 8%;
            margin-bottom: 20px;
            border-bottom: 1px solid #00BCD4;
            border-bottom-width: medium;
            border-spacing: 1px;
        }


    `],
})
export class AlertAppSettingsComponent {

   
    private apiToken: any = localStorage.getItem("adminToken");
    public appAlertLog: any = [];
    private showFilter: boolean = false;
    // invokedOperators: boolean = false;
    // defaultOperators: boolean = true;

    public srchMobilenum: any = "";
  //  public srchDateLog: any = "";
    public deviceFilterObj: any = {};

    public noMoreData: boolean;







    /*Report Selection variables Ends */

    constructor(public globalService: globalService,
        public apiService: ApiService,
        public authGaurd: authGaurd,
        public bootstrapDate: bootstrapDateTime,
        private storage: StorageService,
        public router: Router) {


    }
    private currentOrganization: any = this.storage.get('orgID');
    ngOnInit() {
        this.getAppAlertInfo();
        //this.initJqUtils();
    }

    clearFilters(value:any){
        this.srchMobilenum = "";
        this.deviceFilterObj= {};
        this.getAppAlertInfo();
    }



    filterFunction() {

        //let fromdate = $('#selectedLogDate').val();
        //this.srchDateLog = fromdate.trim();

        if (this.srchMobilenum) Reflect.set(this.deviceFilterObj, 'mobileno', this.srchMobilenum);
       // if (this.srchDateLog) Reflect.set(this.deviceFilterObj, 'fromDate', this.srchDateLog);
        this.getAppAlertInfo();
    }


    // getCalendar() {
    //     this.bootstrapDate.datetime({
    //         component: 'selectedLogDate'
    //     });

    // }

    // initJqUtils(eleReportStartTime?:string,eleReportEndTime?:string,pickerConfig?:any){
    //     let parent = this;
    //     let timePicker:boolean;
    //     let timeFormat:any;
    //     if((pickerConfig)&&(Object.keys(pickerConfig).length)){
    //         timePicker = pickerConfig.timePicker;
    //         timeFormat = pickerConfig.timeFormat;
    //     }else{
    //         timePicker = true;
    //         timeFormat = 'Y-m-d H:i:s';
    //     }
    // }



    getAppAlertInfo = () => {
        let parent: any = this;
        var infoObj = {
            data: {
                key: this.apiToken,
                // "form": {
                //     "fromDate": "2018-07-26"
                // }
            }
        };

        if (Object.keys(this.deviceFilterObj).length) {
            infoObj['data']['form'] = {};
            infoObj['data']['form'] = this.deviceFilterObj;
        }
        this.noMoreData = false;
        this.apiService.mobileAppAlertSettings(infoObj).then(response => {
            if (response) {
                if (response.response != '' && response.response != undefined && response.response != null) {
                    this.appAlertLog = (response.response).map((index: any) => { index['showActionsButton'] = false; index['showOTPCount'] = false; index['showOTPFailField'] = false; return index; });

                }else{
                    this.noMoreData = true;
                }
            };
        }).catch(error => { });
    }

    passId(index: any) {
        // this.invokedOperators = true;
        // this.defaultOperators = false;
        this.appAlertLog[index]['showOTPCount'] = true;
        this.appAlertLog[index]['showOTPFailField'] = true;
        this.appAlertLog[index]['showActionsButton'] = true;
    }

    cancel(index: any) {
        // this.invokedOperators = false;
        // this.defaultOperators = true;
        this.appAlertLog[index]['showOTPCount'] = false;
        this.appAlertLog[index]['showOTPFailField'] = false;
        this.appAlertLog[index]['showActionsButton'] = false;

    }

    saveAlertSettings(appId: any) {
        let data = this.appAlertLog[appId];
        //console.log(data);
        let apiHeader = {
            data: {
                key: this.apiToken,
                form: {

                    otpfailcount: data.OTPFailcount,
                    otplimitchange: data.OTPcount,
                    apphistoryId: data.apphistoryId
                }

            }
        }
        this.apiService.mobileAppAlertSettings(apiHeader).then(response => {
            let result = response;
           // console.log(response);
            this.getAppAlertInfo();
        })
            .catch(error => {
                console.log('Error while editing member', error);
            });

    }





}
