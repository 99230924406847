import { Component, Input, Output, EventEmitter } from '@angular/core';
import { ApiService } from "../../../services/ApiService";
import { StorageService } from "../../../services/storage.service";
import { COM } from './../../../services/com.services';
declare var $: any;
declare var google: any;
declare var MarkerWithLabel: any;
@Component({
  selector: 'appMapExtraFilterDirective',
  styles: [`

  .shadow	{
    box-shadow: 0 2px 4px 0 rgba(0,0,0,0.10);
  }
  .text-grey	{color: #b8c2cc;}
  .cursor-pointer	{cursor: pointer;}
  .my-card{
    transition: 1s;
    margin: 6px 0px;
    width: 100%;
    padding: 6px 0px;
  }
  .border-bottom{
    border-bottom:1px solid #d4d4d4
  }
  .p-28percent{
    padding-left: 18%;
  }
  #dynamicContent {
    position: absolute;
    top: 51px;
    left: 8%;
    min-width: 85%;
    max-width: 85%;
    z-index: 99;
    transition: 0.3s;
    background-color: rgba(255, 255, 255, 1.0);
    border: 1px solid #ccc;
    height: 66px;
    box-shadow: 0 2px 4px 0 rgba(0,0,0,0.10);
  }
  #snackbar {    
    display: none; /* Hidden by default. Visible on click */
    min-width: 250px; /* Set a default minimum width */
    margin-left: -125px; /* Divide value of min-width by 2 */
    background-color: #34495e; /* wetasphalt color background color */
    color: #fff; /* White text color */
    text-align: center; /* Centered text */
    border-radius: 2px; /* Rounded borders */
    padding: 16px; /* Padding */
    position: fixed; /* Sit on top of the screen */
    z-index: 1; /* Add a z-index if needed */
    left: 64%; /* Center the snackbar */
    top: 143px; /* 30px from the bottom */
    box-shadow: 0 15px 30px 0 rgba(0,0,0,0.11),
          0 5px 15px 0 rgba(0,0,0,0.08);
  }
  
  /* Show the snackbar when clicking on a button (class added with JavaScript) */
  #snackbar.show {
    display: inline-block; /* Show the snackbar */
    /* Add animation: Take 0.5 seconds to fade in and out the snackbar. 
    However, delay the fade out process for 2.5 seconds */
    -webkit-animation: fadein 0.5s, fadeout 0.5s 2.5s;
    animation: fadein 0.5s, fadeout 0.5s 2.5s;
  }
  
  /* Animations to fade the snackbar in and out */
  @-webkit-keyframes fadein {
    from {top: 0; opacity: 0;} 
    to {top: 143px; opacity: 1;}
  }
  
  @keyframes fadein {
    from {top: 0; opacity: 0;}
    to {top: 143px; opacity: 1;}
  }
  
  @-webkit-keyframes fadeout {
    from {top: 143px; opacity: 1;} 
    to {top: 0; opacity: 0;}
  }
  
  @keyframes fadeout {
    from {top: 143px; opacity: 1;}
    to {top: 0; opacity: 0;}
  }
  .btn-primary{
    border-radius:0px;
  }
  .circle-dot{
  width: 10px;
  padding-bottom: 10px;
  border-radius: 100%;  
  margin: 5px 10px;
  position: absolute;
  
  }

  .bg-cus-danger{
    background:#e74c3c !important;
  }
  .bg-cus-primary{
    background:#2980b9 !important;
  }
  .bg-cus-info{
    background:#74b9ff !important;
  }
`],
  template: `

  <div id="dynamicContent" *ngIf="list">
     
      <div class="row bg-secondary-border-bottom-0" style="padding: 0px;">
        <div class="col-sm-8">
      
        <inline-date-time-picker (datevalue)="emmitedvalueHandling($event)" 
        [OnlySingleDate]='false' [setMyClass]="['col-sm-6']"></inline-date-time-picker>
        </div>
          <div class="col-sm-2" style="margin-top:2.8%">
                <span id="submitData" class="">
                <button id="submit" class="btn btn-primary meager-e2e-extrafilter-halt-submit" (click)="myData()">Submit</button></span>
                <span title="Close" class="p-28percent cursor-pointer"><i class="fa fa-close" (click)="clearAll();close.emit(false)"></i></span>

          </div>
         <div class="col-sm-2" *ngIf="dateLength>=0 && dateLength<4">
          <ng-container *ngFor="let item of uiSetofDates;let i = index">
          <div><small>{{item}}</small><span  class="circle-dot bg-cus-{{circleFilter[i]}} "></span></div>
          </ng-container>
          </div>
         
      </div>

</div>
<div id="snackbar" class="">{{errorData}}</div>
`


})
export class MapExtraFilterDirective {

  public popoverObj: any;
  public errorData: string = '';
  public setOfDates: any = [];
  public dateLength: number = 0;
  public startDateValidator: any;
  public endDateValidator: any;
  public error = "rgba(231, 76, 60,0.88)";
  public success = "rgba(39, 174, 96,0.88)";
  public circleFilter: any = [];
  public uiSetofDates: any = [];
  public dateMapper: any = {};
  haltLogCollection: any = [];
  public mapObj: any;
  svgCircle: string[];
  // mapMarkerwithlabel:any=[];
  mapMarkerwithlabel: any = {};
  haltLogCollectionObj: any = {};
  mapBounds: any;
  @Input() list: boolean;
  @Input() type: string;
  @Output() close: EventEmitter<boolean> = new EventEmitter();
  @Input('mapObj')
  set map(map: any) {
    this.mapObj = map;

  }

  public initialSubmit:boolean = true;

  constructor(
    private storage: StorageService,
    private apiService: ApiService,
    private com: COM
  ) {

    this.circleFilter = ['danger', 'primary', 'info'];
    this.svgCircle = ['#e74c3c', '#2980b9', '#74b9ff'];
    this.setOfDates = [];
    this.mapBounds = new google.maps.LatLngBounds();



  }



  ngOnInit() {

  }
  ngOnDestroy() {

  }

  //collection of dates from given two dates 
  getDaysArray = (start: any, end: any) => {
    for (var arr = [], dt = start; dt <= end; dt.setDate(dt.getDate() + 1)) {
      arr.push(new Date(dt));
    }
    return arr;
  };
  // event handler datetimepicker and assigning to current var
  emmitedvalueHandling(e: any) {
    this.clearMarkers();
    this.uiSetofDates = [];
    this.setOfDates = [];
    // this.storage.set({ 'flag': 'route' })
    // console.log(e);
    // this.setOfDates=[];
    let dateobj = e;
    this.startDateValidator = null;
    this.endDateValidator = null;
    this.startDateValidator = new Date(dateobj.startDate).getTime();
    this.endDateValidator = new Date(dateobj.endDate).getTime();
    const daylist = this.getDaysArray(new Date(dateobj.startDate), new Date(dateobj.endDate));
    this.setOfDates = [];
    daylist.map((v) => {
      // console.log(v)
      let d = v.toISOString().slice(0, 10)
      this.setOfDates.push(d);
    })

  }
  getSetofDates = () => {
    return this.setOfDates;
  }
  getMarkerLength = () => {
    return Object.keys(this.mapMarkerwithlabel).length;
  }
  myData() {

    try {
      setTimeout(() => {
        this.haltLogCollectionObj = {}
        this.dateLength = 0;
        this.uiSetofDates = [];
        this.haltLogCollection = [];
        //  console.log(this.getMarkerLength())
        //  if(this.getMarkerLength() !=0){
        // this.clearMarkers()
        //  }
        this.dateLength = this.getSetofDates().length;

        // console.log(this.dateLength);
        if (this.setOfDates.length < 0) {
          this.errorData = "Kindly enter Date";
          this.snackBar();
          this.setOfDates = []; return false;

        }
        if (this.setOfDates.length <= 3) {

          // console.log(this.mapMarkerwithlabel);

          if (this.startDateValidator > this.endDateValidator) {

            this.errorData = "Start Date is greater than end date";
            this.snackBar(this.error);
            this.setOfDates = []; return false;
          } else {
            // this.errorData = "Success";
            // this.snackBar(this.success);
            this.uiSetofDates = [...this.setOfDates];
            let data = {};

            data = this.storage.get('routeInfo');
            // console.log(data)

            if (data) {
              this.dateMapper = {}
              let val = 0;
              this.initialSubmit = true;
              for (let i = 0; i < this.setOfDates.length; i++) {
                this.dateMapper[this.setOfDates[i]] = this.svgCircle[i];
                this.apiTriggerOnTimeout(data, this.setOfDates[i], i * 0.5);
                val = i;
              }

              this.zoom()
              setTimeout(() => {
                // console.log( this.haltLogCollection )
                this.errorData = "Success...";
                this.snackBar(this.success);
              }, val * 1000);

            } else {
              this.uiSetofDates = [];
              this.errorData = "Click on Route Name";
              this.snackBar(this.error);
            }
            // this.setOfDates=[];
            // console.log(this.setOfDates);
            // this.setOfDates=[];

          }


        }
        if (this.setOfDates.length > 3) {

          this.errorData = "Record can be queried only for 3 days";
          this.snackBar(this.error);
          this.setOfDates = []; return false;
        }


      }, 300);
    } catch (e) {

    }

  }
  clearMarkers() {
    this.setOfDates.forEach((date: any, index: number) => {

      if (this.mapMarkerwithlabel['_' + date] != undefined) {
        this.mapMarkerwithlabel['_' + date].forEach((marker: any) => {
          marker.setMap(null);
        });
      }
    });

  }

  apiTriggerOnTimeout(data: any, date: string, intervaldelay: number) {


    setTimeout(() => {
      this.getHaltLog(data, 0, date);
      // this.plotRoutes(colorCode,date);
    }, intervaldelay * 100);

  }



  plotRoutes(date: string) {
    // let plotColor:any='';
    setTimeout(() => {

      if (Object.keys(this.haltLogCollectionObj).length) {

        for (let haltRec in this.haltLogCollectionObj[date]) {
          let val = this.haltLogCollectionObj[date][haltRec];
          let mlatLng = new google.maps.LatLng(val.location.lat, val.location.lng);
          let timeOnly = val.unicTimeStamp;
          // let d = new Date(val.unicTimeStamp);
          // console.log(d.getDate()+'/'+(d.getMonth()+1)+'/'+d.getFullYear() +' '+d.getHours()+':'+d.getMinutes()+':'+d.getSeconds(),'--', val.gpsTimeStamp)
          let timeOnlyConfig:any = {
            hour: "numeric",
            minute: "numeric"
          }
          timeOnly = new Date(timeOnly).toLocaleTimeString('en-GB', timeOnlyConfig);

          var iconSize = 0.2;
          if (this.mapMarkerwithlabel['_' + date] == undefined) this.mapMarkerwithlabel['_' + date] = [];
          // console.log(val.gpsTimeStamp,val.location.lat, val.location.lng)
          const marker = new MarkerWithLabel({

            position: mlatLng,

            icon: {
              path: "M-20,0a20,20 0 1,0 40,0a20,20 0 1,0 -40,0",
              fillColor: this.dateMapper[date],
              fillOpacity: .9,
              anchor: new google.maps.Point(0, 0),
              strokeWeight: 0,
              scale: iconSize
            },
            labelContent: timeOnly,
            labelClone: timeOnly,
            // labelContent:val.gpsTimeStamp,
            // labelClone : val.gpsTimeStamp,
            labelAnchor: new google.maps.Point(17, 15),
            labelClass: "map-marker-label",



            mLatlng: mlatLng,
            map: this.mapObj,
          });
          marker.setMap(this.mapObj); //console.log('this.mapObj ', marker)
          this.mapBounds.extend(mlatLng); //console.log(val.gpsTimeStamp ,this.mapBounds);

          this.mapMarkerwithlabel['_' + date].push(marker);
          

        }


      } else {

      }
    }, 500);
  }
  zoom() {


    let parent = this;
    this.mapObj.addListener('zoom_changed', () => {

      let zoomValue = parent.mapObj.getZoom();

      this.setOfDates.forEach((date: any, index: number) => {

        if (this.mapMarkerwithlabel['_' + date] != undefined) {
          this.mapMarkerwithlabel['_' + date].forEach((m: any) => {
            if (zoomValue > 16) {
              m.labelContent = m.labelClone;
              m.labelClass = "map-marker-label"
            } else {
              m.labelContent = "";
              m.labelClass = "";
            }
          });
        }
      });


    });
  }
  clearAll() {

    this.clearMarkers();

    this.setOfDates = [];
    this.startDateValidator = null;
    this.endDateValidator = null;
    this.mapMarkerwithlabel = {};


  }


  snackBar(str: string = '') {
    let x = document.getElementById("snackbar");
    let submitbtn = document.getElementById("submit");
    if (str !== '') {
      x.style.backgroundColor = str;
    }
    x.className = "show";


    submitbtn.setAttribute('disabled', 'true');
    setTimeout(() => {

      x.className = x.className.replace("show", "");

      submitbtn.removeAttribute('disabled');
    }, 3000);
  }

  getHaltLog(routedata: any, i: number = 0, date: string) {
    try {
      let apiHeader2 = {
        data: {
          key: localStorage.getItem('scbToken'),
          form: {
            vehId: [
              routedata.vehId
            ],
            fromDate: date,
            fromTime: routedata.routeStartTime + ':00',
            toDate: date,
            toTime: routedata.routeEndTime + ':00',
            speed: {
              halt: {
                value: 0
              }
            }
          },
          extra: {
            pageJump: i
          }
        }

      }

      this.apiService
        .getCommonReport(apiHeader2)
        .then(response => {
          let res = response.response;

          if (res == null || res == []) {
            // this.haltLogCollectionObj[date].push(res);
            // console.log('### ', this.haltLogCollectionObj);
            this.com.comAction.next({
              type: 'haltLogReport',
              data: this.haltLogCollectionObj,
              date: date,
              initialCall: this.initialSubmit,
            })
            this.initialSubmit = false;
            //  this.haltLogCollection=[];
            this.plotRoutes(date)
            this.isEmpty(this.haltLogCollection);

          } else {

            res.map((item: any) => {
              // this.haltLogCollection.push(item);
              // this.haltLogCollection[this.haltLogCollection.length] = item;
              if (this.haltLogCollectionObj[date] == undefined) this.haltLogCollectionObj[date] = [];
              this.haltLogCollectionObj[date].push(item);
            });
            this.getHaltLog(routedata, i + 1, date);
          }
        })

    } catch (error) {

    }

  }
  isEmpty(obj: any) {
    for (var prop in obj) {
      console.log(prop);
      return false;
    }
    return true;
  }


}
