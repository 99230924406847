import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
@Component({
    selector: 'user-management-control',
    template:`
    
            <!--    <h4>User Management Component</h4>-->
    
    
    
    `
})

export class UserManagement implements OnInit {
    public curOrgId: any;
    public userLevel: any;
    public userControl: any;

    private refeMenu:any = {
        "/school-routes-list": "Routes",
        "/movementReport": 'Reports',
        "/school-students-list": 'Students',
        "/campaigns": 'Campaigns',        
    }

    private refeSetting: any = {
        "/org-settings": 'Organization Setting',
        "/school-vehicle-list": 'Vehicle Over View',
        "/orgGlobalConfiguration": 'Global Configuration',
        "/role-management": 'Role Management',
        "/user-management": 'User Management'
    }
    
    constructor(
        private router: Router
    ) { 
       
        let uLevel = localStorage.getItem('userLevel');
        this.userLevel = uLevel;
        let uInfo = localStorage.getItem('userInfo');
        if( (uInfo) && ( uLevel === '2' )){
          this.userControl = uInfo && JSON.parse(uInfo);
        }else{
          this.userControl =1;
        }
    }

    ngOnInit() {
    
         // console.log('#####',this.userControl);
        //  console.log(this.refeSetting[this.router.url]);
         if(  this.userControl &&  this.userControl['menu'] && this.refeMenu[this.router.url] && this.userControl['menu'][this.refeMenu[this.router.url]] !== true ){
               this.router.navigate(['permission-not-allowed']);
                //console.log('menu', this.userControl['menu'][this.refeMenu[this.router.url]]);
         }

         if(  this.userControl &&  this.userControl['settings'] && this.refeSetting[this.router.url] && this.userControl['settings'][this.refeSetting[this.router.url]] !== true ){
            this.router.navigate(['permission-not-allowed']);
            //console.log(this.userControl['settings'][this.refeSetting[this.router.url]]);
        }
     }

    
} 