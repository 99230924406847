import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'strsort'
})

export class StringSortPipe implements PipeTransform {
  transform(array: Array<string>, sortType: string): Array<string> {
      if ( sortType === 'asc' ) {
    array.sort((a: any, b: any) => {
      if (a < b) {
        return -1;
      } else if (a > b) {
        return 1;
      } else {
        return 0;
      }
    });
    return array;
    } else {
        return array;
    }
  }
}
