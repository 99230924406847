import { globalService } from './../../../../services/global.service';
import { Component } from "@angular/core";
import { ChannelPartnersConfig } from "../channel-partners.config";
import { excelUpload } from "../../../../services/excelUploadService"

import { ApiService } from '../../../../services/ApiService';

import { authGaurd } from '../../../../services/auth-guard.service'


declare var $: any;
declare var require: any;
var filePath: any;
const swal = require('sweetalert');

@Component({
    selector: "app-channelpartnervendor-unicel",
    templateUrl: "./channel-partner-vendor-unicel.component.html",
    styleUrls: ["./channel-partner-vendor-unicel.component.css"],
})
export class UnicelVendorComponent {

    public channelpartnerconfig: any;
    public strReplaced: any;

    constructor(public excelUpload: excelUpload, public globalService: globalService,
        public apiService: ApiService,
        public authGaurd: authGaurd) {

    }
    ngOnInit() {
        this.channelpartnerconfig = ChannelPartnersConfig[0];
        let strReplace = this.channelpartnerconfig.fa_icon_include_size;
        this.strReplaced = strReplace.replace("fa-3x", "fa-1x");
        // console.log(this.strReplaced);
    }

    browse() {
        this.bs_input_file();
    }



    onFileSubmit(event: any) {

        let reader = new FileReader();
        let parent: any = this;

        event.target.files = filePath;

        // console.log(event.target.files);

        filePath = "";
        if (event.target.files && event.target.files.length > 0) {

            let file = event.target.files[0];
            var apiKey = localStorage.getItem('adminToken');
            this.excelUpload.callDataUpload(apiKey, file, 'send-input', function (apiResponse: any) {
                if (apiResponse.status == 'success') {
                    // parent.showUploadSuccess = true;
                    // parent.showUploadFailure = false;
                    swal("Success", "Excel Uploaded Successfully", "success", {
                        timer: 2000
                    });
                    setTimeout(() => {
                        swal.close();
                    }, 2000);
                } else {
                    if (apiResponse.ec == 'SCB29') {
                        swal("Failure", "invalid excel Data", "error", {
                            timer: 2000
                        });
                    }
                }

            });

        } else {
            console.error('invalid file submitted');
        }
    }


    bs_input_file() {
        $(".input-file").before(
            function () {
                if (!$(this).prev().hasClass('input-ghost')) {
                    var element = $("<input type='file' class='input-ghost' style='visibility:hidden; height:0'>");
                    element.attr("name", $(this).attr("name"));
                    element.change(() => {
                        element.next(element).find('input').val(element.val());
                        filePath = element[0].files;
                        console.log(filePath);
                    });
                    $(this).find("button.btn-choose").click(function () {
                        element.click();
                    });
                    $(this).find("button.btn-reset").click(function () {
                        element.val(null);
                        $(this).parents(".input-file").find('input').val('');
                    });
                    $(this).find('input').css("cursor", "pointer");
                    $(this).find('input').mousedown(function () {
                        $(this).parents('.input-file').prev().click();
                        return false;
                    });
                    return element;
                }
            }
        );
    }


}
