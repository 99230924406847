/**
    @Author: Rakesh Roushan.
 * @Date:   2018-04-09T13:20:05+05:30
 * @Last modified time: 2018-04-10T11:06:23+05:30
 */


import {Pipe,PipeTransform} from '@angular/core';
@Pipe({name:'unixTimestampFormatter'})
export class UnixTimeStampFormatter implements PipeTransform{
    transform(unixTimeStamp:number):any{
        return this.parseUnixTimestamp(unixTimeStamp);
    }

    parseUnixTimestamp(timeStamp:number){
        let months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
        let parsedDate = new Date(timeStamp);
        let meriden = (parsedDate.getHours() >= 12)?'PM':'AM';
        let hrs     = (parsedDate.getHours() > 12)? parsedDate.getHours() - 12 : parsedDate.getHours();
        let formattedTime = `${this.padString(hrs,2)}:${this.padString(parsedDate.getMinutes(),2)}:${this.padString(parsedDate.getSeconds(),2)} ${meriden}`;
        let formattedDate = `${this.padString(parsedDate.getDate(),2)} ${months[parsedDate.getMonth()]}, ${parsedDate.getFullYear()}`;
       	let dummY         = new Date();
       	let currentDate      = `${this.padString(dummY.getDate(),2)} ${months[dummY.getMonth()]}, ${dummY.getFullYear()}`;
       	if(formattedDate == currentDate) {
       		return "Today at " +formattedTime;
       	} else {
       		return formattedDate + " at " + formattedTime;
       	}
    }

    padString(text:any,max:number){
        text = text.toString();
        return text.length < max ? '0'+text : text;
    }
}
