import {
  Component,
  ElementRef,
  AfterViewInit,
  OnDestroy,
  ViewChild,
} from '@angular/core';
import { RouterModule, Routes, Router } from '@angular/router';
import { AppComponent } from './../../app.component';

import { globalService } from './../../services/global.service';

import { ApiService } from './../../services/ApiService';
import { authGaurd } from './../../services/auth-guard.service';
import { NG_VALIDATORS, Validator, Validators, AbstractControl, ValidatorFn,FormBuilder,FormArray,FormGroup,FormControl } from '@angular/forms';
import { GlobalUtils } from './../../services/global-utils';
import { decodeAddress } from './../../services/decodeAddress.service';
import { AmazingTimePickerService } from 'amazing-time-picker';

const swal = require('sweetalert');

declare var $: any;
declare var google: any;
declare var flatpickr:any;

@Component({
  // template: ``,
  templateUrl: './new.component.html',
  styles: [`

    `],
  styleUrls: ['./only_neworg.min.css']
})
export class NewOrgPageComponent {
  public defineSessions:any;
  public sessionForm:any;
  private addUser: boolean = false;
  private addRemarks: boolean = false;
  private addTracker: boolean = false;
  public organization: any = {};
  public formError: any = {};
  public errorMesages: any = [];
  public clickMessage: any;
  private modelService: any;
  private currentOrganization: any = this.globalService.getData().currentOrganization;
  private apiToken: any = localStorage.getItem('adminToken');
  public ShowReportConfiguration: boolean = true;
  public ShowHolidayConfigutation: boolean = false;
  public ShowSessionConfiguration:boolean=false;
  public ShowClassSectionConfiguration: boolean = false;
  private apiResponseMesage: string;
  private orgPlaceMarker: any;
  /*Report Selection variables */

  private rfidReport: boolean;
  private classrfidReport: boolean;
  private memberrfidReport: boolean;
  private routerfidReport: boolean;
  private attendencerfidReport:boolean;
  private haltReport: boolean;
  private haltDurationReport: boolean;
  private movementReport: boolean;
  private movementandhaltReport: boolean;
  private overspeedReport: boolean;
  private lowspeedReport: boolean;
  private betweentwospeedReport: boolean;
  private daysummaryReport: boolean;
  private vehcoordinateReport: boolean;
  private callalertmadelogReport: boolean;
  // private smsalertmadelogReport: boolean;
  // private appalertmadelogReport: boolean;
  // private alertlogsummaryReport: boolean;
  private routehistorylogReport: boolean;
  private memberhistorylogReport: boolean;
  private callLogCount: boolean;
  private panicReport: boolean;
  private vehiclelastupdateReport: boolean;
  private engineReport: boolean;
  private acReport: boolean;
  private accanddeccReport: boolean;
  private routevehiclemappedReport: boolean;
  private geofenceReport: boolean;
  private _map: any;



  public studentClassList: any = [];
  public classItems = '';
  public studentSectionList: any = [];
  public sectionItems = '';
  public parentAppAlert: boolean;
  public parentETAAlert:boolean;
  public cameraModuleView: boolean;

  public data:any=[];
  public sessionStartTimeListener:any={};
  public sessionEndTimeListener:any={};
  public sessionTitle : string = '';
  public sessionSelection : any=[];

  /*Report Selection variables Ends */


  /*Weekdays Section Instances */
  private Wsunday: boolean = false;
  private Wmonday: boolean = true;
  private Wtuesday: boolean = true;
  private Wwednesday: boolean = true;
  private Wthursday: boolean = true;
  private Wfriday: boolean = true;
  private Wsaturday: boolean = false;
  /*Weekdays Section Instances */

 
  constructor(public globalService: globalService,
    public apiService: ApiService,
    public authGaurd: authGaurd,
    public router: Router,
    private globalUtils: GlobalUtils, 
    private fb:FormBuilder,
    private decodeAddress: decodeAddress) {

  }

  ngOnInit() {
    this.authGaurd.checkAdminToken();
    this.organization.contact = {};
    this.organization.user = {};
    this.organization.tracker = {};
    // this.validator();
    
    setTimeout(() => { this.initMap() }, 200);
    
    // this.loadForm();
  
  }
  storeClass() {
    if (this.studentClassList.indexOf(this.classItems) == -1) {
      this.studentClassList.push(this.classItems);
    }
    this.classItems = '';
  }
  storeSection() {
    if (this.studentSectionList.indexOf(this.sectionItems) == -1) {
      this, this.studentSectionList.push(this.sectionItems);
    }
    this.sectionItems = '';
  }

  //function to remove the contact information from temporary array
  removeTag(i: number) {
    this.studentClassList.splice(i, 1);
  }
  removeSectionTag(i: number) {
    this.studentSectionList.splice(i, 1);
  }

  initMap(): void {
    let mapOptions = {
      scrollwheel: true,
      zoom: 14,
      center: new google.maps.LatLng(28.7041, 77.1025),
      disableDefaultUI: false // disables default controls
    };
    this._map = new google.maps.Map(document.getElementById('mapContainer'), mapOptions);
    this.orgPlaceMarker = new google.maps.Marker({
      position: new google.maps.LatLng(28.7041, 77.1025),
      map: this._map,
      draggable: true,
      animation: google.maps.Animation.DROP,
      icon: {
        url: './.../../assets/webroot/images/map/schoolMarker1.png',
        //size: new google.maps.Size(64, 64),
        scaledSize: new google.maps.Size(45, 45),
      },
    });
    //add events for marker.
    let parent = this;
    this.orgPlaceMarker.addListener('dragend', function (e: any) {
      parent.reverseGeocodeAddress(new google.maps.LatLng(e.latLng.lat(), e.latLng.lng()))
    });
    this.bindMapClickEvt();
  }

  bindMapClickEvt() {
    let parent: any = this;
    this._map.addListener('click', function (e: any) {
      var clickedLatLng = new google.maps.LatLng(e.latLng.lat(), e.latLng.lng());
      parent.orgPlaceMarker.setPosition(clickedLatLng);
      google.maps.event.trigger(parent.orgPlaceMarker, 'dragend', e);
    });
  }

  geocodeAddress() {
    let address = this.organization.orgLocation;
    //geocoding using custom service.
    var config = {
      'address': address
    };
    this.decodeAddress.geocode(config).then(response => {
      this.orgPlaceMarker.setPosition(response['geometry'].location);
      this._map.panTo(response['geometry'].location);

    }).catch(error => { });
  }
  reverseGeocodeAddress($event: any) {
    let geocoder = new google.maps.Geocoder();
    let address = $event;
    let parent: any = this;
    //reverse geocoding using custom service.
    var config = {
      'latLng': $event
    }
    this.decodeAddress.geocode(config).then(response => {
      this.organization.orgLocation = response['formatted_address'];
      $('#locationField').focus();
      $('#locationField').blur();
    }).catch(error => {
      console.log('Error - ', error);
    });
  }

  submitted = false;
  onSubmit() { this.submitted = true; }


  initiateFormValidation() {

    let isValid: boolean = true;
    let errorFlag = false;
    this.errorMesages = [];
    //Org Name
    if (!this.organization.name) {
      this.formError.OrganizationName = true;
      isValid = false;
      //errorFlag=true; (this.errorMesages).push("Name Should not be empty");
    } else {
      this.formError.OrganizationName = false;
    }

    //Set Category ------

    if (!this.organization.category) {
      this.formError.OrganizationCategory = true;
      isValid = false;
    } else {
      this.formError.OrganizationCategory = false;
    }

    //Set Address ------
    if (!this.organization.address) {
      this.formError.OrganizationAddress = true;
      isValid = false;
    } else {
      this.formError.OrganizationAddress = false;
    }

    //Set Area ------

    if (!this.organization.area) {
      this.formError.OrganizationArea = true;
      isValid = false;
    } else {
      this.formError.OrganizationArea = false;
    }

    //Set City

    if (!this.organization.city) {
      this.formError.OrganizationCity = true;
      isValid = false;
    } else {
      this.formError.OrganizationCity = false;
    }

    //set state

    if (!this.organization.state) {
      this.formError.OrganizationState = true;
      isValid = false;
    } else {
      this.formError.OrganizationState = false;
    }

    //set Country

    if (!this.organization.country) {
      this.formError.OrganizationCountry = true;
      isValid = false;
    } else {
      this.formError.OrganizationCountry = false;
    }

    //set Email

    if (!this.organization.email) {
      this.formError.OrganizationEmail = true;
      isValid = false;
    } else {
      this.formError.OrganizationEmail = false;
    }

    //Contact Information

    //set contact name
    if (!this.organization.contact.name) {
      this.formError.OrganizationContactName = true;
      isValid = false;
    } else {
      this.formError.OrganizationContactName = false;
    }

    //set contact phone

    if (!this.organization.contact.phone) {
      this.formError.OrganizationContactPhone = true;
      isValid = false;
    } else {
      this.formError.OrganizationContactPhone = false;
    }

    //set contact Email
    if (!this.organization.contact.email) {
      this.formError.OrganizationContactEmail = true
      isValid = false;
    } else {
      this.formError.OrganizationContactEmail = false;
    }

    if (!this.organization.orgLocation) {
      this.formError.OrganizationLocation = true
      isValid = false;
    } else {
      this.formError.OrganizationLocation = false;
    }

    if (!this.organization.orgStartTime) {
      this.formError.OrganizationStartTime = true
      isValid = false;
    } else {
      this.formError.OrganizationStartTime = false;
    }
    if (!this.organization.orgEndTime) {
      this.formError.OrganizationEndTime = true
      isValid = false;
    } else {
      this.formError.OrganizationEndTime = false;
    }

    if (this.organization.orgEndTime && this.organization.orgStartTime) {
      if (this.organization.orgStartTime > this.organization.orgEndTime) {
        this.formError.OrganizationTimingMismatch = true
        isValid = false;
      } else {
        this.formError.OrganizationTimingMismatch = false;
      }
    }
   
    return isValid;
  }


  formSubmit() {
    if (this.initiateFormValidation()) {
      this.apiService.orgCreate({
        data: {
          key: this.apiToken,
          form: {
            name: this.organization.name,
            category: this.organization.category,
            address: this.organization.address,
            area: this.organization.area,
            city: this.organization.city,
            state: this.organization.state,
            country: this.organization.country,
            website: this.organization.website,
            email: this.organization.email,
            description: this.organization.description,
            orgStartTime: this.organization.orgStartTime,
            orgEndTime: this.organization.orgEndTime,
            status: 'active',
            callingURL: this.organization.callingURL,
            alertlock:this.parentAppAlert,
            etaAlert:this.parentETAAlert,
            cameraModuleView:this.cameraModuleView,
            contactInformation: {
              0: {
                name: this.organization.contact.name,
                phone: this.organization.contact.phone,
                email: this.organization.contact.email,
                designation: this.organization.contact.designation
              }
            },
            location: {
              lattitude: this.orgPlaceMarker.getPosition().lat(),
              longitude: this.orgPlaceMarker.getPosition().lng(),
              locationAddress: this.organization.orgLocation
            },
            classLists: this.studentClassList,
            SectionLists: this.studentSectionList,
            reports: {
              rfid: {
                general: this.rfidReport,
                RFIDWithClass: this.classrfidReport,
                RFIDWithMember: this.memberrfidReport,
                RFIDWithRoute: this.routerfidReport,
                RFIDWithAttendance:this.attendencerfidReport
              },
              tracking: {
                movement: this.movementReport,
                halt: this.haltReport,
                movementandhalt: this.movementandhaltReport,
                overspeed: this.overspeedReport,
                lowspeed: this.lowspeedReport,
                betweentwospeed: this.betweentwospeedReport,
                daysummary: this.daysummaryReport,
                vehcoordinate: this.vehcoordinateReport,
                haltDurationReport: this.haltDurationReport
              },
              alertlog: {
                callalertmadelog: this.callalertmadelogReport,
                // smsalertmadelog: this.smsalertmadelogReport,
                // appalertmadelog: this.appalertmadelogReport,
                // alertlogsummary: this.alertlogsummaryReport,
                routehistorylog: this.routehistorylogReport,
                memberhistorylog: this.memberhistorylogReport,
                callLogCount: this.callLogCount,
              },
              others: {
                panic: this.panicReport,
                vehiclelastupdate: this.vehiclelastupdateReport,
                engine: this.engineReport,
                ac: this.acReport,
                accanddecc: this.accanddeccReport,
                routevehiclemapped: this.routevehiclemappedReport,
                geofence: this.geofenceReport
              }
            },
            weekdays: {
              sunday: this.Wsunday,
              monday: this.Wmonday,
              tuesday: this.Wtuesday,
              wednesday: this.Wwednesday,
              thursday: this.Wthursday,
              friday: this.Wfriday,
              saturday: this.Wsaturday
            }
          },
          schoolsessionLists:this.defineSessions
        }
      }).then(response => {
        let result = response;
        if (result.status == 'success') {

          swal('Success', 'Oragnization Created Successfully', 'success', {
            timer: 2000
          });

          this.router.navigate(['/sysAdmin/view-admin-organization']);

        } else {
          swal('Failure', this.globalUtils.getErrorMsg(response.ec), 'error');
          setTimeout(() => { swal.close(); }, 2000);
        }
      })
        .catch(error => {
          console.log('Error while creating organization', error);
        });
    }

  }



}

