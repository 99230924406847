import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params, NavigationEnd,Router } from '@angular/router';
import { ApiService } from './../../../services/ApiService';
import { roleFormModule } from './../../../model/role-model';

@Component({
  selector: 'app-edit-users',
  templateUrl: './edit-users.component.html',
  styleUrls: ['./edit-users.component.css']
})
export class EditUsersComponent implements OnInit {

  public userData: any = {};
  public formError: any = {};
  public userInfo:any = {};
  public roleInfo: any = [];
  public form: any = {};
  constructor(
    private router: Router,
    private api: ApiService,
    private route: ActivatedRoute,
    private modelDate: roleFormModule
  ) { }

  ngOnInit() {
    this.route.params.subscribe((params: Params): void => {
      this.getUserInfo(params);
  });

  }
  bindObj(val: any, key: string){
     this.form[key] = val.target.value;
     this.form.levels = 2;
     this.form.orguserid = this.userInfo.orgUserId;
    // console.log(this.form);
  }

  getUserInfo(filter: any){
   
       let apiData = {
          data: {
             key: localStorage.getItem('scbToken'),
             filter
          }
       }

        this.api.listUsers(apiData).then( (res: any)=>{ 
                if(res.status === 'success'){
                     
                     this.userInfo = res.response[0];
                      if(this.userInfo != null && this.userInfo != undefined && this.userInfo != '' ){

                        this.userInfo.dob = this.userInfo.dob && this.userInfo.dob.date ? this.userInfo.dob.date : '';
                      }
                     
                     
                     
                }
        });

  
    
    let apiData2 = {         
      data:{
          key: localStorage.getItem("scbToken"),
          // extra:{
          //   pageJump: this.pagejump
          // }
        }    
      }
      this.api.listRoles(apiData2).then( (res: any)=>{
            if(res.status === 'success'){
                  // this.roleInfo = res.response;
                  this.roleInfo = res.response;
        
            }
      });
  }
  updateUser(formData: any){
   // let form: any = {};
    this.formError.uname = false;
    this.formError.loginname = false;
    this.formError.email = false;
    this.formError.userPass = false;
    this.formError.userPhone = false;
    this.formError.gender = false;
    this.formError.designation = false;
    this.formError.dob = false;
    this.formError.address = false;
    this.formError.area = false;
    this.formError.city = false;
    this.formError.statue = false;
    this.formError.country = false;
    this.formError.status = false;
    this.formError.role = false;
        if(Object.keys(this.form).length >0 ){
            if(formData.valid){
              

              let form = this.form;
                let apiData = { 
                  data: {
                      key: localStorage.getItem('scbToken'),
                      form
                  }
                }

                this.api.updateUser(apiData).then( (res: any)=>{
                      if(res.status === 'success'){
                            
                            swal('User details updated successfuly...!');

                            setTimeout( ()=>{
                                this.router.navigate(['/user-management', {}]); 
                            },300);

                      }
                })

            }else{

              if(formData.value.uname == '' || formData.value.uname == undefined){
                this.formError.uname = true;
              }
              if(formData.value.loginname == '' || formData.value.loginname == undefined){
                this.formError.loginname = true;
              }
              if(formData.value.email == '' || formData.value.email == undefined){
                this.formError.email = true;
              }
              if(formData.value.userPass == '' || formData.value.userPass == undefined){
                this.formError.userPass = true;
              }
              if(formData.value.userPhone == '' || formData.value.userPhone == undefined){
                this.formError.userPhone = true;
              }
              if(formData.value.gender == '' || formData.value.gender == undefined){
                this.formError.gender = true;
              }
              // if(formData.value.designation == '' || formData.value.designation == undefined){
              //   this.formError.designation = true;
              // }
              if(formData.value.roleName == '' || formData.value.roleName == undefined){
                this.formError.role = true;
              }
              if(formData.value.address == '' || formData.value.address == undefined){
                this.formError.address = true;
              }
              if(formData.value.area == '' || formData.value.area == undefined){
                this.formError.area = true;
              }
              if(formData.value.city == '' || formData.value.city == undefined){
                this.formError.city = true;
              }
              if(formData.value.statue == '' || formData.value.statue == undefined){
                this.formError.statue = true;
              }
              if(formData.value.country == '' || formData.value.country == undefined){
                this.formError.country = true;
              }
              if(formData.value.status == '' || formData.value.status == undefined){
                this.formError.status = true;
              }
                
            }
          }else{
            swal({
              title: 'No changes happened...!',
              text: 'Nothing to update..!',
              icon: 'warning',
            });
          }
  }

  tooglepassword() {
    let x: any = document.getElementById("uPassword");
    if (x.type === "password") {
      x.type = "text";
    } else {
      x.type = "password";
    }
  }
  navigate(url: string){
    this.router.navigate(['/' + url , {}]);
}

changeRole(event: any){
    console.log(event);
}
}
