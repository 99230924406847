import { Component, OnInit, Input, Output } from '@angular/core';
import { ApiService } from './../../../services/ApiService';
import { RouterModule, Routes, Router } from '@angular/router';

import { authGaurd } from './../../../services/auth-guard.service';
import { GlobalUtils } from './../../../services/global-utils';
import { globalService } from "./../../../services/global.service";
import { StorageService } from "./../../../services/storage.service";
import { COM } from './../../../services/com.services';
import { concat } from 'rxjs';
interface availableReportGroups {
  commonReportsGroup?: boolean;
  movementReportsGroup?: boolean;
  callReportsGroup?: boolean;
  otherReportsGroup?: boolean;
  attendanceReportsGroup?: boolean;
  analyticsReportGroup?: boolean;
}

// If any new report added, look-up must b e updated accordingly.
const APIKeysLookup = {
  tracking: 'movementReportsGroup',
  rfid: 'commonReportsGroup',
  alertlog: 'callReportsGroup',
  others: 'otherReportsGroup',
  attendance: 'attendanceReportsGroup',
  analytics: 'analyticsReportGroup'
}

const reportIconLookup = {
  alertlog: 'glyphicon glyphicon-phone',
  tracking: 'glyphicon glyphicon-road',
  others: 'glyphicon glyphicon-info-sign',
  rfid: 'fa fa-id-card',
  attendance: 'fa fa-id-badge',
  analytics: 'fa fa-area-chart'
}

const reportNameLookUp = {
  tracking: 'Tracking',
  rfid: 'RFID',
  alertlog: 'Calls',
  others: 'Others',
  attendance: 'Attendance',
  analytics: 'Analytics'

}

const reportGroupLookup = {
  movementReportsGroup: 'tracking',
  commonReportsGroup: 'rfid',
  callReportsGroup: 'alertlog',
  otherReportsGroup: 'others',
  attendanceReportsGroup: 'attendance',
  analyticsReportGroup: 'analytics'

}

const reportGroupCallbackFunc = {
  tracking: 'initMovementReportGroup',
  rfid: 'initCommonReportGroups',
  alertlog: 'initCallReportGroup',
  others: 'initOtherReportGroup',
  attendance: 'initAttendanceReportGroup',
  analytics: 'initAnalyticsReportGroup'
}

@Component({
  selector: 'notReachedSelector',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.css']
})
export class MenuComponent implements OnInit {
  public availableOrgReports: any;
  public isLoading: boolean;
  private loggedMemberInfo: any; private avaibaleReportsInGroup: any;
  public availableClasses: any;
  public userReportControls: any;
  public reportSelected: boolean;
  public reportGroups: availableReportGroups = {
    commonReportsGroup: false,
    movementReportsGroup: false,
    callReportsGroup: false,
    otherReportsGroup: false,
    attendanceReportsGroup: false,
    analyticsReportGroup: false,
  }
  constructor(private apiService: ApiService, public com: COM, public storageservice: StorageService, public router: Router,) {

    this.availableOrgReports = [];
    let userInfo = localStorage.getItem('userInfo') ? JSON.parse(localStorage.getItem('userInfo')) : {};
    this.com.comAction.subscribe((e: any) => {
      if (e.lable === 'OrgReports') {

        this.userReportControls = Object.assign(e.data, userInfo.reports);
      }
    })
  }

  ngOnInit() {
    this.getConfiguredReports();
    this.initReportSection(this.storageservice.get('selectedReport'));
    this.initReports('initAnalyticsReportGroup');
  }
  public currrentUserInfo: any = [];
  getConfiguredReports() {
    this.isLoading = true;
    let apiHeader = {
      data: {
        key: localStorage.getItem('scbToken')
      }
    }
    let userIn = localStorage.getItem('userInfo')
    if (userIn) {
      let userInfo = JSON.parse(userIn);
      if (userInfo && userInfo.reports) {
        Object.keys(userInfo && userInfo.reports).forEach((key) => {
          Object.keys(userInfo.reports[key]).forEach((key2) => {
            if (this.currrentUserInfo[key2] === undefined) {
              this.currrentUserInfo[key2] = [];
            }
            this.currrentUserInfo[key2].push(userInfo.reports[key][key2]);
          });
        });
      }
    }
    // console.log(this.currrentUserInfo);
    this.apiService.getCurrentOrgDetails(apiHeader).then(response => { //console.log(response);

      let ifTbs = response.response.name.includes('The British School');
      this.storageservice.set({ 'schoolLatLng': response.response.orgLocation });
      this.storageservice.set({ stbs: false });
      if (ifTbs) {
        this.storageservice.set({ stbs: ifTbs });
      }
      this.loggedMemberInfo = { ...response.response };
      let availableReports = this.loggedMemberInfo.reports;
      if ('classLists' in this.loggedMemberInfo) {
        this.availableClasses = {
          classes: [...this.loggedMemberInfo.classLists],
          sections: [... this.loggedMemberInfo.SectionLists]
        };
      }

      for (let reportInfo in availableReports) {
        let reportsPerm = availableReports[reportInfo];
        let isReportSubscribed: boolean;

        if (typeof (reportsPerm) === 'object') {
          isReportSubscribed = this.isReportSubscribed(reportsPerm);
        } else {
          isReportSubscribed = availableReports[reportInfo];
        }


        if (isReportSubscribed) {
          let obj = {
            reportDispname: reportNameLookUp[reportInfo],
            reportName: reportInfo,
            reportGroupIcon: reportIconLookup[reportInfo],
            reportGroupName: APIKeysLookup[reportInfo],
            reportGroupInitFunc: reportGroupCallbackFunc[reportInfo]
          }
          Object.assign(obj, { isReportSubscribed: isReportSubscribed });
          this.availableOrgReports.push(obj);
        }
      }

      this.isLoading = false;


      // this.initCommonReportGroups();

    }).catch(error => { })
  }
  isReportSubscribed(param: any): boolean {
    let subscriptionStatus = false;
    for (let report in param) { //console.log(this.currrentUserInfo[report][0]);   
      if (param[report] || this.currrentUserInfo && this.currrentUserInfo[report] && this.currrentUserInfo[report][0]) {
        subscriptionStatus = true;
      }
    }

    return subscriptionStatus;
  }
  displaySum(sum: any) {

    this.reportSelected = sum;
  }
  initReports(str: string) {

    return this[`${str}`]();
  }
  initCommonReportGroups() {
    // console.log(' this.loggedMemberInfo', this.loggedMemberInfo)
    // this.avaibaleReportsInGroup = Object.assign({}, Reflect.get(this.loggedMemberInfo.reports,reportGroupLookup['commonReportsGroup']))
    this.avaibaleReportsInGroup = this.loggedMemberInfo.reports[reportGroupLookup['analyticsReportGroup']];
    this._initReportGroups({ analyticsReportGroup: true });
    // console.log('TEST',this.avaibaleReportsInGroup);
  }

  _initReportGroups({ commonReportsGroup = false, movementReportsGroup = false, analyticsReportGroup = false, callReportsGroup = false, otherReportsGroup = false, attendanceReportsGroup = false }: availableReportGroups = {}): void {
    //console.log('cccccccccccccccccccccccccccccccc.........');
    this.reportGroups.commonReportsGroup = commonReportsGroup;
    this.reportGroups.movementReportsGroup = movementReportsGroup;
    this.reportGroups.callReportsGroup = callReportsGroup;
    this.reportGroups.otherReportsGroup = otherReportsGroup;
    this.reportGroups.attendanceReportsGroup = attendanceReportsGroup;
    this.reportGroups.analyticsReportGroup = analyticsReportGroup;
    //console.log('this.reportGroups.analyticsReportGroup ',this.reportGroups.analyticsReportGroup);
  }

  initReportSection(reportFunc: string) {
    if (reportFunc === 'initAnalyticsReportGroup') {
      return 0;
    } else {
      this.storageservice.set({
        'selectedReport': reportFunc,

      })
      this.router.navigate(['/movementReport']);

    }

    //this[`${reportFunc}`]();
  }
  initAnalyticsReportGroup() {
    //console.log('enters.........');
    // this.avaibaleReportsInGroup = Object.assign({}, Reflect.get(this.loggedMemberInfo.reports, reportGroupLookup['analyticsReportGroup']))
    this.avaibaleReportsInGroup = { notReachedReport: true }
    this._initReportGroups({ analyticsReportGroup: true });
    //  console.log('endssssssssssssss.........');

  }

}
