

import { Component } from '@angular/core';
import { RouterModule, Routes, Router } from '@angular/router';
import { NgForm, AbstractControl } from '@angular/forms';
import { ApiService } from './../../services/ApiService';
import { GlobalUtils } from './../../services/global-utils';

declare var swal: any;
declare var google: any;

@Component({
    templateUrl: './liveLocation.component.html',
    styles: [`

 

  .topDiv{
    min-height: 50px;
    background: rgb(77,106,121);
  }
  .InfoDiv{
      min-height: 17vh;
      background: #ffffff;
      box-shadow: -4px 1px 3px 2px #6b6967;
  }
  .headerText{
    letter-spacing: 2px;
    color: #fff;
    font-family: inherit;
    font-weight: 700;
    padding-left: 20px;
  }



  .dropdownContent { 
    background: #ffffff;
    width: 200px;
    top: 49px;
    z-index: 999999999999999999999999;
    position: fixed;
    margin-left: 77%;
    border: 1px solid #afafaf;
    border-radius: 3px;
  }

  .socialIcon {
    text-decoration: none;
    color: black;
    cursor:pointer;
  }



ul{
    list-style: none;
    margin: 0;
    padding: 0;
}

li{
    background: #fff;
    min-height: 40px;
    color: #1d2124 ;
    line-height: 40px;
    padding-left: 20px;
    word-break: break-all

}
li{
    background: #fff;
    min-height: 40px;
    color: #1d2124 ;
    line-height: 40px;
    padding-left: 20px;
    word-break: break-all

}
li: hover {
    background : lightgrey;
    cursor:pointer;
}
li:nth-child(2n){
    background: #f7f7f7;
}

li:hover span{
    width: 40px;
    opacity: 1.0;

}


h1{
    background: #2980b9;
    color: white;
    margin: 0;
    padding: 10px 20px;
    text-transform: uppercase;
    font-size: 24px;
    font-weight: normal;

}

@media (min-width: 350px) and (max-width: 767px) {
    .dropdownContent { 
        background: #ffffff;
        width: 200px;
        top: 49px;
        z-index: 999999999999999999999999;
        position: fixed;
        margin-left: 38%;
        border: 1px solid #afafaf;
        border-radius: 3px;
    }
    
}

.pickupInfo{
    font-size: 16px;
    padding: 30px;
    font-weight: 400;
}

  
   

  `],

})

export class LiveLocationClass {

    private _map: any;
    private orgPlaceMarker: any;
    public showDropDown: boolean = false;

    constructor(private apiService: ApiService, public router: Router, private globalUtils: GlobalUtils) {

    }


    ngOnInit() {
        this.initMap();
    }

    hello() {
        console.log('eeee');
    }


    initMap(): void {
        let mapOptions = {
            scrollwheel: true,
            zoom: 14,
            center: new google.maps.LatLng(28.7041, 77.1025),
            disableDefaultUI: false // disables default controls
        };
        this._map = new google.maps.Map(document.getElementById('mapContainer'), mapOptions);
        this.orgPlaceMarker = new google.maps.Marker({
            position: new google.maps.LatLng(28.7041, 77.1025),
            map: this._map,
            draggable: true,
            animation: google.maps.Animation.DROP,
            icon: {
                url: './.../../assets/webroot/images/map/finish.png',
                //size: new google.maps.Size(64, 64),
                scaledSize: new google.maps.Size(45, 45),
            },
        });
        //add events for marker.
    }


    shareTwitter(){
        let link = document.getElementById('tweetShare');
        let url = window.location;

         window.open("https://twitter.com/share?url="+encodeURIComponent('url'));
    }



}



// export function nameValidation(control:AbstractControl) : { [key:string]:boolean } | null {
//    if(control.value!="admin"){
//        return { name : true }
//    }else{
//        return null
//        }
// }
