import { Injectable } from '@angular/core';
declare var google:any;
declare var jquery:any;
@Injectable()

export class animateMarker{
  //markerAnimate.js content start
  animateTo(assocMarker:any,newPosition:any, options:any) {
const defaultOptions:any = {
   duration: 1000,
   easing: 'linear',
   complete: null
 }
 options = options || {};

 // complete missing options
for (let key in defaultOptions) {
  options[key] = options[key] || defaultOptions[key];
}

 // defaultOptions.forEach(key => {
 //       options[key] = options[key] || defaultOptions[key];
 // })



 //window.requestAnimationFrame = window.requestAnimationFrame || window.mozRequestAnimationFrame || window.webkitRequestAnimationFrame || window.msRequestAnimationFrame;
 //window.cancelAnimationFrame = window.cancelAnimationFrame || window.mozCancelAnimationFrame;

 // save current position. prefixed to avoid name collisions. separate for lat/lng to avoid calling lat()/lng() in every frame
 assocMarker.AT_startPosition_lat = assocMarker.getPosition().lat();
 assocMarker.AT_startPosition_lng = assocMarker.getPosition().lng();
 var newPosition_lat = newPosition.lat();
 var newPosition_lng = newPosition.lng();

 // crossing the 180° meridian and going the long way around the earth?
 if (Math.abs(newPosition_lng - assocMarker.AT_startPosition_lng) > 180) {
   if (newPosition_lng > assocMarker.AT_startPosition_lng) {
     newPosition_lng -= 360;
   } else {
     newPosition_lng += 360;
   }
 }

 var animateStep = function(marker:any, startTime:any) {
   var ellapsedTime = (new Date()).getTime() - startTime;
   var durationRatio = ellapsedTime / options.duration; // 0 - 1
   var easingDurationRatio = durationRatio;

   // use jQuery easing if it's not linear
   // if (options.easing !== 'linear') {
   //   easingDurationRatio = jQuery.easing[options.easing](durationRatio, ellapsedTime, 0, 1, options.duration);
   // }

   if (durationRatio < 1) {
     var deltaPosition = new google.maps.LatLng( marker.AT_startPosition_lat + (newPosition_lat - marker.AT_startPosition_lat)*easingDurationRatio,
                                                 marker.AT_startPosition_lng + (newPosition_lng - marker.AT_startPosition_lng)*easingDurationRatio);
     marker.setPosition(deltaPosition);

     // use requestAnimationFrame if it exists on assocMarker browser. If not, use setTimeout with ~60 fps
     if (window.requestAnimationFrame) {
       marker.AT_animationHandler = window.requestAnimationFrame(function() {animateStep(marker, startTime)});
     } else {
       marker.AT_animationHandler = setTimeout(function() {animateStep(marker, startTime)}, 17);
     }

   } else {

     marker.setPosition(newPosition);

     if (typeof options.complete === 'function') {
       options.complete();
     }

   }
 }

 // stop possibly running animation
 if (window.cancelAnimationFrame) {
   window.cancelAnimationFrame(assocMarker.AT_animationHandler);
 } else {
   clearTimeout(assocMarker.AT_animationHandler);
 }

 animateStep(assocMarker, (new Date()).getTime());
};

//markerAnimate.js content end
}
