import { ChannelPartnersConfig } from "./channel-partners.config";
import { Component } from "@angular/core";
import { Router } from '@angular/router';

@Component({
  selector: "app-channel-selector",
  templateUrl: "./channel-partners.component.html",
  styleUrls: ["./channel-partners.component.css"],
})


export class ChannelPartnersConfigComponent {
  
  public channelpartnerconfigs: any;
  constructor(public router:Router) {
    this.channelpartnerconfigs = ChannelPartnersConfig;
  }
 
  route(vendorname:string){

    // console.log(vendorname);

    let navigationlink = '/sysAdmin/channel-partner/'+vendorname;

    // console.log(navigationlink);

    this.router.navigate([navigationlink]);

  }

}
