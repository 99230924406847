import { Component, Input, EventEmitter, Output } from "@angular/core";
import { ApiService } from "../../../services/ApiService";

@Component({
   selector: 'mem-wise-attendance',
   template: `
   
   <style>
   [data-tooltip]:before{
      left: 5% !important;
   }
   </style>




   <ng-container *ngIf="memwiseAttendance.length">
	<div class="panel panel-default">
		<div class="panel-heading">
			<div class="row mb5">
				<div class="reportHeading text-center mb5">Memberwise Attendance Report</div>
			</div>
			<div class="row mb5">
				<div class="col-md-6 text-left">
					<label class="control-label">MemberName : </label>
					<span class="reportStartDate"> {{selectedMember[0]['memberName']}}</span>
				</div>
				<div class="col-md-6 text-right">
					<label class="control-label">MemberId : </label>
					<span class="reportEndDate"> {{selectedMember[0]['memberUID']}}</span>
				</div>
			</div>
			<div class="row mb5">
				<div class="col-md-6 text-left">
					<label class="control-label">MemberClass : </label>
					<span class="reportStartDate"> {{selectedMember[0]['memberClass']}}</span>
				</div>
				<div class="col-md-6 text-right">
					<label class="control-label">Member Section : </label>
					<span class="reportEndDate"> {{selectedMember[0]['memberSection']}}</span>
				</div>
			</div>
		</div>
		<div class="panel-body" style="max-height:444px">
			<div class="row">
				<div class="col-sm-12">
					<table class="table table-bordered">
						<thead>
							<tr class="text-center">
								<!-- <th class="text-left">Sl.No</th> -->
                        <th class="text-left">Date</th>
                        <th class="text-center">Boarded</th>
                        <th class="text-center">Deboarded</th>
								<!--<th colspan="2" class="text-center">
									<tr class="text-center">
										<td class="present">Present
											<td>
											</tr>
											<tr class="text-center">
												<td>Boarded</td>
												<td style="position:relative;left:130%">Deboarded</td>
											</tr>
										</th> -->
										<th class="text-center">Not Boarded</th>
									</tr>
								</thead>
								<tbody id="memtable">
                           <ng-container *ngFor="let item of memwiseAttendance;let i =index;">
                           
                           
                       
                              <ng-container *ngFor="let item2 of keying(item);">
         <!-- <tr>{{item[item2] | json}}</tr> -->
        <tr>
           
       
        <!-- <td class="v-align" [attr.rowspan]="keying(item).length">{{i+1}}</td>                      -->
       
                    
       
                   
                     <td class="v-align">{{item[item2].date}}</td>
                    
                     <td>
                        <ng-container *ngIf="item[item2].status=='present' && item[item2].hasOwnProperty('boarded') && item[item2].boarded.boarded_ts;else invalidData">
                           <!-- <span class="text-center"><i class="fa fa-check text-success" aria-hidden="true"></i></span><br><span>{{item.boarded_ts}}</span> -->
                           <div class="text-center" [attr.data-tooltip]="item[item2].boarded.boarded_pickup">
                              <span>
                                 <i class="fa fa-check-circle greenText" aria-hidden="true"></i>
                              </span>
                              <span style="margin-left: 16px;color: #6d6d6d;">
                                 <i class="fa fa-map-marker" aria-hidden="true"></i>
                              </span>
                           </div>
                           <div class="text-center" [attr.data-tooltip]="item[item2].boarded.boarded_pickup">{{item[item2].boarded.boarded_ts |  date:'HH:mm'}}</div>
                           <div class="text-center" [attr.data-tooltip]="item[item2].boarded.boarded_pickup">{{item[item2].boarded.board_routeName}}</div>
                        </ng-container>
                     </td>
                     <td>
                        <ng-container *ngIf="item[item2].status=='present' && item[item2].hasOwnProperty('deboarded') && item[item2].deboarded.deboarded_ts;else invalidData">
                           <!-- <span class="text-center"><i class="fa fa-check text-success" aria-hidden="true"></i></span><br><span>{{item.deboarded_ts}}</span> -->
                           <div class="text-center" [attr.data-tooltip]="item[item2].deboarded.deboarded_pickup">
                              <span>
                                 <i class="fa fa-check-circle greenText" aria-hidden="true"></i>
                              </span>
                              <span style="margin-left: 16px;color: #6d6d6d;">
                                 <i class="fa fa-map-marker" aria-hidden="true"></i>
                              </span>
                           </div>
                           <div class="text-center" [attr.data-tooltip]="item[item2].deboarded.deboarded_pickup">{{item[item2].deboarded.deboarded_ts |  date:'HH:mm'}}</div>
                           <div class="text-center" [attr.data-tooltip]="item[item2].deboarded.deboarded_pickup">{{item[item2].deboarded.deboard_routeName}}</div>
                        </ng-container>
                     </td>
                     <td>
                        <ng-container *ngIf="item[item2].status=='absent';else invalidData">
                           <!-- <span class="text-center"><i class="fa fa-check text-success" aria-hidden="true"></i></span><br><span>{{item.notboarded_ts}}</span> -->
                           <div class="text-center" style="color:white">dummy</div>
                           <div class="text-center">
                              <i class="fa fa-times-circle redText"></i>
                           </div>
                           <div class="text-center" style="color:white">dummy</div>
                        </ng-container>
                     </td>
                 



                     </tr>
                              </ng-container>

                              </ng-container>
                          
								</tbody>
							</table>
						</div>
					</div>
					<!--    <ng-container *ngFor="let item of memwiseAttendance"><div class="col-sm-3 card bordered box-shad"><div class="text-center memDate">{{item.date}}</div><ng-container *ngIf="item.mess != 'noData';else invalidCard"><table class="table table-bordered table-condensed"><tr><th>Present</th><th>Absent</th></tr><tbody><tr><td><div class="text-center"><i class="fa fa-check-circle greenText" aria-hidden="true"></i></div></td><td><div class="text-center"><i class="fa fa-check-circle greenText" aria-hidden="true"></i></div></td></tr><tr><td><table><tr><td><i class="fa fa-clock-o">{{item.mess.boarded_ts | date:'HH:mm'}}</i></td><td class="cut-text" style="padding-left:15px;">{{item.mess.board_routeName}}</td></tr></table></td><td><table><tr><td><i class="fa fa-clock-o">{{item.mess.deboarded_ts | date:'HH:mm'}}</i></td><td class="cut-text" style="padding-left:15px;">{{item.mess.deboard_routeName}}</td></tr></table></td></tr><tr><td class="cut-pickup-point">{{item.mess.boarded_pickup}}</td><td class="cut-pickup-point">{{item.mess.deboarded_pickup}}</td></tr></tbody></table></ng-container></div></ng-container></div> -->
				</div>
			</div>
		</ng-container>
		<ng-template #invalidData>
			<!--<span class="text-center"><i class="fa fa-times text-danger" aria-hidden="true"></i></span><br><span><br> - </span>       -->
			<div class="text-center" style="color:white">dummy</div>
			<div class="text-center">-</div>
			<div class="text-center" style="color:white">dummy</div>
		</ng-template>
		<ng-container *ngIf="!memwiseAttendance.length">
			<div class="panel panel-default">
				<div class="panel-heading">
					<div class="row mb5">
						<div class="reportHeading text-center mb5">Memberwise Attendance Report</div>
					</div>
				</div>
				<div class="panel-body">
					<h4 class="animated pulse text-center" style="animation-duration: 1s;color:#999FBF">
						<i class="fa fa-frown-o"></i>&nbsp;No records found
					</h4>
				</div>
			</div>
		</ng-container>












   
    `,
   styleUrls: ['../attendance-reports/attendance-reports.component.css'],
})

export class MemberwiseAttendanceComponent {
   // variable declarations

   apiHeader: any;
   selectedDate: any;
   memwiseAttendance: any[];
   keying:any = Object.keys;

   rowSpanFlag:false;

   // end of variable declarations
   constructor(public api: ApiService) {

   }



   //business logics for display

   @Input('getRouteWiseData') // get api response in this variable
   set getRouteWiseData(val: any) {
      try {
         this.valueResetter();
         this.apiHeader = val;
         this.selectedDate = val.data.form.fromDate.split('-').reverse().join('-');
         this.getMemAttendance2();
      } catch (e) { }
   }
   @Output() errorDetected: EventEmitter<any> = new EventEmitter();
   @Input() selectedMember: any = {};
   ngOnInit() {
      // console.log(this.selectedMember);
   }

   ngAfterViewInit(){
      
   }



   valueResetter() {
      this.memwiseAttendance = [];
      this.apiHeader = {};
   }



   getMemAttendance2_bck(){

      let tempobj ={};
      this.api.getTabAttendanceReport(this.apiHeader).then((res: any) => {

         let routeRepo = res.hasOwnProperty('response') ? res.response : res;
         if (!routeRepo.ec) {
            let arr: any = [];
            Object.keys(routeRepo).forEach((date: any, index) => {
               let validFormat = Array.isArray(routeRepo[date]);
               let obj = {};
               tempobj={};
               if (!validFormat) {
                 
                  Object.keys(routeRepo[date]).forEach((val: any, key: any) => {
                  
                     
                     obj={};
                     
                     if (routeRepo[date][val].hasOwnProperty('deboarding')) {
                        obj['status'] = 'present';
                        obj['boarded'] = {}
                        obj['boarded']['boarded_ts'] = routeRepo[date][val]['boarding']['logTimeMS'];
                        obj['boarded']['boardedstatus'] = routeRepo[date][val]['boarding']['boardedstatus'];
                        obj['boarded']['boarded_pickup'] = routeRepo[date][val]['boarding']['pickuppointName'];
                        obj['boarded']['board_routeName'] = routeRepo[date][val]['boarding']['routeName'];
                        obj['boarded']['board_routeType'] = routeRepo[date][val]['boarding']['routeType'];
                        obj['boarded']['board_vehicleName'] = routeRepo[date][val]['boarding']['vehicleName'];
                        tempobj[val] = obj;
                     }else;
                     if (routeRepo[date][val].hasOwnProperty('deboarding')) {
                        obj['status'] = 'present';
                        obj['deboarded'] = {};
                        obj['deboarded']['deboarded_ts'] = routeRepo[date][val]['deboarding']['logTimeMS'];
                        obj['deboarded']['deboardedstatus'] = routeRepo[date][val]['deboarding']['boardedstatus'];
                        obj['deboarded']['deboarded_pickup'] = routeRepo[date][val]['deboarding']['pickuppointName'];
                        obj['deboarded']['deboard_routeName'] = routeRepo[date][val]['deboarding']['routeName'];
                        obj['deboarded']['deboard_routeType'] = routeRepo[date][val]['deboarding']['routeType'];
                        obj['deboarded']['deboard_vehicleName'] = routeRepo[date][val]['deboarding']['vehicleName'];
                     
                        tempobj[val] = obj;
                     }else;
                     if (routeRepo[date][val].hasOwnProperty('notboarding')) {
                        obj['status'] = 'absent';
                        tempobj[val] = obj;
                     } else;
                  });
               } else {
                  obj['status'] = 'absent';
                  tempobj['status'] = obj;
               }

              
               arr.push(tempobj);

             
            });

         

            this.errorDetected.emit({
               ec: ''
            });
         }else{
            this.errorDetected.emit(routeRepo);
         }
      });
   }
   getMemAttendance2() {
      let tempobj ={};
      this.api.getTabAttendanceReport(this.apiHeader).then((res: any) => {

         let routeRepo = res.hasOwnProperty('response') ? res.response : res;
         if (!routeRepo.ec) {
            let arr: any = [];
            let obj = {};
            Object.keys(routeRepo).forEach((item: any, index) => {
               let validFormat = Array.isArray(routeRepo[item]);
               obj = {};
               tempobj={};
               
               if (!validFormat) {
                 
                  Object.keys(routeRepo[item]).forEach((val: any, key: any) => {
                     let obj = {};
                     obj['date'] = item;
                     obj['rType'] = routeRepo[item][val]['routeType'];
                     if (routeRepo[item][val].hasOwnProperty('boarding')) {
                        obj['status'] = 'present';
                        obj['boarded'] = {}
                        obj['boarded']['boarded_ts'] = routeRepo[item][val]['boarding']['logTimeMS'];
                        obj['boarded']['boardedstatus'] = routeRepo[item][val]['boarding']['boardedstatus'];
                        obj['boarded']['boarded_pickup'] = routeRepo[item][val]['boarding']['pickuppointName'];
                        obj['boarded']['board_routeName'] = routeRepo[item][val]['boarding']['routeName'];
                        obj['boarded']['board_routeType'] = routeRepo[item][val]['boarding']['routeType'];
                        obj['boarded']['board_vehicleName'] = routeRepo[item][val]['boarding']['vehicleName'];
                        tempobj[val] = obj;
                        
                     }
                     if (routeRepo[item][val].hasOwnProperty('deboarding')) {
                        obj['status'] = 'present';
                        obj['deboarded'] = {};
                        obj['deboarded']['deboarded_ts'] = routeRepo[item][val]['deboarding']['logTimeMS'];
                        obj['deboarded']['deboardedstatus'] = routeRepo[item][val]['deboarding']['boardedstatus'];
                        obj['deboarded']['deboarded_pickup'] = routeRepo[item][val]['deboarding']['pickuppointName'];
                        obj['deboarded']['deboard_routeName'] = routeRepo[item][val]['deboarding']['routeName'];
                        obj['deboarded']['deboard_routeType'] = routeRepo[item][val]['deboarding']['routeType'];
                        obj['deboarded']['deboard_vehicleName'] = routeRepo[item][val]['deboarding']['vehicleName'];
                        tempobj[val] = obj;
                     } else;
                     if (routeRepo[item][val].hasOwnProperty('notboarding')) {
                        obj['status'] = 'absent';
                        tempobj[val] = obj;
                     } else;

                   

                   
                     
                  });

               } else {
                  obj['date'] = item;
                  obj['status'] = 'absent';
                  tempobj['route']= obj;
                 
               }
               //arr.push(obj);.


               /*
function compare(a, b) {
  if (a > b) return 1; // if the first value is greater than the second
  if (a == b) return 0; // if values are equal
  if (a < b) return -1; // if the first value is less than the second
}

               */

            //   let temp = Object.keys(tempobj).length && Object.keys(tempobj).sort((a:any,b:any)=>{
            //    if(tempobj[a]['rType'] > tempobj[b]['rType']) return -1;
            //    if(tempobj[a]['rType'] === tempobj[b]['rType']) return 0;
            //    if(tempobj[a]['rType'] < tempobj[b]['rType']) return 1;
            //    });

            //    let temp2 = temp.length && temp.map((item:any) => tempobj[item]);

             
               arr.push(tempobj);
            });
          
            this.memwiseAttendance = JSON.parse(JSON.stringify(arr));
           
            
            this.errorDetected.emit({
               ec: ''
            });

         } else {
            this.errorDetected.emit(routeRepo);
         }
      });

   }



}