import { Component, Input, Output, EventEmitter } from '@angular/core';
import { COM } from './../../services/com.services';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

@Component({
    selector: 'g10-search-box',
    template: `
    <div class="row">
    <div class="col-md-6">
    <div class="bar animated" [ngClass]="showIcon==true?'slideInRight':'slideOutRight'" *ngIf="showIcon"> 
    <input class="searchbar" type="text" 
    title="Search by Route / Pickup / Student name or mobile no."
    placeholder="Search by Route / Pickup / Student name or mobile no."
    autocomplete="off"
    [(ngModel)]="searchValue"
    name="userQuestion"
    id="userQuestions"
    (ngModelChange)="this.subjectObj.next($event)" title="Search">
    <i class="fa fa-times close-icon" (click)="clear('clear');searchContent=[];searchValue='';"></i>
    <!--showIcon=false;-->
    <!--<div class="clear-button" *ngIf="searchContent.length>0" >
     <i class="fa fa-times" style="cursor: pointer;" aria-hidden="true"
     (click)="clear('clear');searchContent=[];searchValue='';"></i>
    </div> -->
    <div *ngIf="searchContent.length>0" class="xtSCL"></div>
	<div class="aajZCb" *ngIf="searchContent.length>0">
        <ul class="erkvQe" jsname="erkvQe" role="listbox" 
        >
            <li data-view-type="1" class="sbct" style="list-style:none;margin-left: -28px;">
				<div class="">
                    <div class="sbic sb27" [ngStyle]="searchContent.length>10?{'height':'150px','overflow-y':'scroll','overflow-x': 'hidden'}:{}">
                    <div class="row" *ngFor="let sug of searchContent">
                    <small (click)="selectItem(sug.rname,sug)" class="custum-style flot-left" *ngIf="sug && sug.memberName">
                        <i class="fa fa-user" aria-hidden="true"></i>
                         {{sug.memberName}} 
                    </small>
                    <small (click)="selectItem(sug.rname,sug)" class="custum-style flot-left" *ngIf="sug && sug.rname">
                        <i class="fa fa-bus" aria-hidden="true"></i>
                         {{sug.rname}}
                    </small>
                    <small (click)="selectItem(sug.pickupID,sug)" class="custum-style flot-left" *ngIf="sug && sug.pickup">
                        <i class="fa fa-map-pin" aria-hidden="true"></i>
                        {{sug.pickup}} 
                    </small>
                    <div class="grid-item" *ngIf="sug && sug.temp">
                      
                    </div> 
                    <div class="grid-item" *ngIf="sug && sug.info">
                       <small style="float: right;margin-right: 30px;color: #757070;"> {{sug.info}}</small>
                    </div>  
                    </div>
                    </div>
					
				</div>
			</li>
		</ul>
	</div>
    </div>
  </div>
    <div class="col-md-6">
            <button class='submit' title="Search by Route / Pickup / Student name or mobile no." style="margin-right: 40px;" *ngIf="!showIcon" (click)="showIcon=true;">
            <i class='fa fa-search'></i>
            </button>
            
    </div>
  </div>
    `,
    styles: [`
    ul.sbct {
        list-style: none
    }
    .close-icon{
            float: right;
            margin-top: 8px;
            margin-right: 11px;
            color: #999999;
            padding: 0px 0px 2px 0px;
            cursor: pointer;
    }
    .grid-container{
        display: grid;
        grid-template-columns: auto auto auto; 
        padding: 1px;
    }
    .grid-item {
        background-color: rgba(255, 255, 255, 0.8);
        
      }
      .flot-left{
          float: left;
          margin-left: 18px;
          cursor: pointer;
          line-height: 24px;
      }
   
    .clear-button {
        width: 10px;
        float: right;
        padding: 15px;
        margin-right: 13px;
        top: 9px;    
        color: #80868b;
    }
    .bar {
        width: 386px;
        border-radius: 5px;
        border-bottom: 1px solid #dcdcdc;
        border:none;
        margin: 0 auto;
        margin-top: 5px;
        float: left;
      }
      .bar:hover {
        box-shadow: 1px 1px 8px 1px #dcdcdc;
      }
      .bar:focus-within {
        box-shadow: 1px 1px 8px 1px #dcdcdc;
        outline: none;
      }
      .searchbar {
        height: 31px;
        border: none;
        border-bottom: 1px solid #dadada;
        border-top-left-radius: 30px;
        border-bottom-left-radius: 3px;
        width: 345px;       
        margin-left: 0px;
        font-size: 16px;
        outline: none;
        border-top-radius: 3px;
        padding-left: 0.5rem;

      } 
      .search-icon{
        width: 270px;
        float: left;
      }     
    .aajZCb {
        background: #fff;
        box-shadow: 0 4px 6px rgba(32, 33, 36, 0.28);
        /* display: flex; */
        flex-direction: column;
        list-style-type: none;
        margin: 0;
        padding: 0;
        border: 0;
        border-radius: 0 0 10px 10px;
        /* padding-bottom: 4px; */
    }
    .xtSCL {
        border-top: 1px solid #e8eaed;
        margin: 0 20px 0 14px;
        padding-bottom: 4px;
    }
    .erkvQe {
        flex: auto;
        /*padding-bottom: 8px;*/
    }
    .sbtc {
        display: flex;
        flex: auto;
        flex-direction: column;
        min-width: 0;
        padding: 6px 0;
    }
    .sbl1 {
        display: flex;
        font-size: 16px;
        color: #212121;
        flex: auto;
        align-items: center;
        word-break: break-word;
        padding-right: 8px;
    }
    .sbl2 {
        line-height: 12px;
        font-size: 13px;
        color: #80868b;
        margin-top: 2px;
    }
    .result-text{
        float: right;
    margin-right: 25px;
    color: #a5a3a3;
    }
    .submit {
        border: none;
        margin-top: 10px;
        font-size: 1.2em;
       background-color: #fff;
      }
    `]
})
/*
  padding: 20px;
        font-size: 30px;
        text-align: center;
*/
export class SearchBoxComponent {

    @Output() emit: EventEmitter<any> = new EventEmitter();
    public searchValue: string;
    public subjectObj = new Subject();
    public searchContent: any = [];
    public ngStyle: string;
    public showIcon: boolean;
    constructor(
        private com: COM
    ) {
        this.subjectObj.pipe(
            debounceTime(1000)
        ).subscribe((searchVal: any) => {
            this.searchValue = searchVal;
            this.com.comAction.next({
                type: 'searchBubble', data: this.searchValue
            });
        });

        this.com.comAction.subscribe((e: any) => {
            if (e.type == "searchSuggestion") {
                this.searchContent = e.data;
                // console.log(this.searchContent)
                // console.log('this.searchContent',this.searchContent)
                // if(this.searchContent.length>1) this.ngStyle ='{height: 200px;overflow-y: scroll;}';
                // else this.ngStyle='';
            }
        });
    }

    ngOnInit() { }
    clear(str:string){

        if(str=='clear'){
           if(this.searchValue) this.emit.emit('reload');
        }
    }
    selectItem(item: any, obj: any) {
        // this.searchValue='';
        // console.log('selectedItem', item,obj)
        try{
        this.searchContent=[];
        this.searchValue = obj.rname !=undefined?obj.rname:obj.pickup;
        this.com.comAction.next({ type: 'searchSelected', 
        data: item,
        searchType: obj.type,
        routeName: obj.routeName? obj.routeName:'' });
    }catch(error){ console.log(error)}
    }
}