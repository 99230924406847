import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'datestringtounix'
})
export class DatestringtounixPipe implements PipeTransform {

  transform(value: any, args?: any): any {
    if(value){
    let val = value.toString();
    return new Date(val).getTime();
    }
    
    if(args==='m-d-y-to-y-m-d'){
      if(value){

        let val = value.toString();
        val = val.split('-');
        val = `${val[1]}-${val[2]}-${0}`
        val = val.toString();
        return new Date(val).getTime();

      }

    }
  }

}
