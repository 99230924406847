/**
 * @Date:   2018-04-06T10:19:30+05:30
 * @Last modified time: 2018-04-09T10:31:48+05:30
 */


import { Injectable } from '@angular/core';
import { ApiService } from './ApiService';

@Injectable()

export class ImeiFinderServices {

  public data:any=[];
  constructor( public apiService : ApiService){
      this. getImeiInformation();
  }

    getImeiInformation(){
      let orgImeiObj= {
        data: {
         key:localStorage.getItem('scbToken')
       }
     };
     this.apiService.getVehicleId(orgImeiObj).then(response => {
       let result=response;
       if(result.status=="success"){
         if (result.response!=null && result.response!=undefined && result.response!='') {

         this.data = response.response;
        // console.log(this.data) ;     
         }
       }
     }).catch(error => { });
    }

  


}
