import { VehicleListLoad, VehicleListUpdate } from './../actions/vehicle-data';

interface VehData {
    deviceId: string;
    VehicleNo: string;
    gpsTimestamp: number;
    speed: number;
    routeAssigned: any;
    nearestLocation: string;
    location: {
        lat: number,
        lng: number
    };
    serverDate: string;
    serverTime:string;
    currentRouteAssigned:string;
}

export function reducer( state: any = [] , action: any ) {
    switch (action.type) {
        case VehicleListLoad: {
            state = action.payload;
//           (action.payload).map( (data: any) => {
//             state[data.VehicleNo] = data;
//           } );
// console.log(state.length);
            return state;
        }
        case VehicleListUpdate: {

            (state).map( (data: VehData) => {
                  // vehNo
                if ( data !== undefined && data.VehicleNo === action.payload.VehicleNo ) {
                    // alert("d1 < d2" + (Number(obj.no) >=0 || 0) );
                //     const d1: number =   data.gpsTimestamp || 0 ;
                //     const d2: number =   action.payload.gpsTimestamp  ;
                //    // console.log(d1, d2);
                //     if ((d1 - d2) === 0) {
                //       // alert("equal");
                //     } else if (d1 > d2) {
                //       // alert("d1 > d2");
                //     } else { // console.log('d1 < d2');
                //         data.gpsTimestamp     = action.payload.gpsTimestamp;
                //         data.nearestLocation  = action.payload.nearestLocation;
                //         data.location         = action.payload.location;
                //         data.speed            = action.payload.speed;
                //         if (action.payload.routeAssigned !== undefined ) {
                //         data.routeAssigned    = action.payload.routeAssigned;
                //         }
                //       // alert("d1 < d2");
                //     }
                
                data.gpsTimestamp     = action.payload.gpsTimestamp;
                data.nearestLocation  = action.payload.nearestLocation;
                data.location         = action.payload.location;
                data.speed            = action.payload.speed;
                data.serverDate       = action.payload.serverDate;
                data.serverTime       = action.payload.serverTime;


                if (action.payload.routeAssigned !== undefined ) {
                    data.routeAssigned    = action.payload.routeAssigned;
                }
                if(action.payload.currentRouteAssigned!==undefined){ 
                    data.currentRouteAssigned=action.payload.currentRouteAssigned;
                }



                }
            } );
   return state;
        }
        default: {
            return state;
        }
    }
}

/*

gpsTimestamp : 1535096250000

*/
