/**
 * @Author: Deepak Pookkote <Deepak>
 * @Date:   2018-04-18T15:22:17+05:30
 * @Email:  deepak@groupten.com
 * @Project: School-Bus
 * @Filename: addNotificationSettings.dir.ts
 * @Last modified by:   group10
 * @Last modified time: 2018-05-08T12:18:25+05:30
 * @Copyright: Group10 Technologies
 */



/*Auther:Deepak */
import { Component, Input, } from '@angular/core';
import { RouterModule, Routes, Router } from '@angular/router';
import { globalService } from './../../services/global.service';
import { ApiService } from './../../services/ApiService';
import { chosenUtility } from './../../services/chosen-utility/jq-chosen-utility.js';
import { NG_VALIDATORS,Validator,Validators,AbstractControl,ValidatorFn } from '@angular/forms';


declare var $: any;
const swal = require('sweetalert');
@Component({
  selector: 'add-notification',
  templateUrl:'./addNotification.template.html',
  styles: [`

    .itemContainer {
      max-height: 45px;
      overflow-y: auto;
      margin: -35px auto;
      min-height: 50px;
      position: absolute;
    }
    .animation-bottom10{
    animation: animationBottom10 2s;
    }
    @keyframes animationBottom10 {
      0% {   transform:translateY(10px); }
      100%{  transform:translateY(0px); }
    }

  .border-deda {
    border: 1px solid #dedada;
    -webkit-box-shadow: 0 6px 4px -4px grey;
    -moz-box-shadow: 0 6px 4px -4px grey;
    box-shadow: 0 6px 4px -4px grey;
    height: 144px;
    overflow-y: auto;
    margin-left:-15px;
    margin-right:-15px;
  }

  .searchbar {
      padding-bottom: 20px;
  }
  .border-deda::-webkit-scrollbar {
      background-color: white;
      width: 2px;
  }

  .border-deda::-moz-scrollbar {
      background-color: white;
      width: 2px;
  }

  .border-deda::-webkit-scrollbar-thumb {
      background-color: #3498db;
  }

  .border-deda::-moz-scrollbar-thumb {
      background-color: #3498db;
  }

  input[type=search]::-webkit-search-cancel-button {
  -webkit-appearance: searchfield-cancel-button;
  }

  .panel-default>.panel-heading {
    color: #333;
    background-color: #ffff;
    border-color: #f0f0f0;
   }
   .panel {
    box-shadow: 1px 2px 4px 0px lightgrey;
   }
   .panel-default {
    border-color: #fdfafa;
   }

`  ],
})
export class addNotificationSettingsDir {

  public vehcileList: any = [];
  public  VehicleName: any = "";
  public selectedNotification: any
  public selectedNotificationType: any;
  public geofence: boolean = false;
  public overSpeed: boolean = false;
  public panic: boolean = false;
  public halt: boolean = false;
  public powerDisconnect: boolean = false;
  public engineOn: boolean = false;


  public sms: boolean = false
  public email: boolean = false;
  public mobileapp: boolean = false;
  public webapp:boolean = false;

  public mobileNumberList: any = [null];
  public mobile: any = '';
  public emailList: any = [];
  public emailData: any = '';
  public speedLimit: any;
  public startTime: any="00:01";
  public endTime: any="23:59";

  public notificationForError: boolean;
  public NotificationTypeError: boolean;
  private trackerSelectError: boolean;
  private speedLimitError: boolean;
  private mobileNumberError: boolean;
  private emailError: boolean;
  public NotificationStartTimeError:boolean;
  public NotificationEndTimeError:boolean;
  public NotificationVehicleError:boolean;
  public timeMismatchError:boolean;
  private selectedAll:string="";
  private selectedVehicles:any=[];
  private availableOrgMobiles:any[];
  private newMobileNumber:string;
  private newUserName:string;
  public sharehintContainer:any=false;
  public srchMobileno:any="";

  public OrgEmail:any[];
  public emailFlag: boolean=false;
  public smsFlag: boolean=false;

  private notifications = {
    'geofence': 'geofence',
    'overspeed': 'overSpeed',
    'panic': 'panic',
    'halt': 'halt'
  }

  private notificationType = {
    'sms': 'sms',
    'email': 'email',
    'mobileapp': 'mobileapp',
    'webapp' : 'webapp'
  }

  public mobileContact:any={}
  collectedData: any;
  userRegisteredMobile: any={};
  selectNumbersfromlist: boolean=false;
  public keyed = Object.keys;
  userRegisteredEmail: any={};
  constructor(
    public router: Router,
    public globalService: globalService,
    private apiService: ApiService,
    public chosenUtility: chosenUtility

  ) {

    this.OrgEmail = [];

  }

  ngOnInit() {
    this.getOrgVehcileList();
    this.getRegUserMobile();
    //this.getAvailableClasses();
  }

  submitted = false;
  onSubmit() { this.submitted = true; }



//   getAvailableClasses(){
//     let apiHeader = {
//         data:{
//             key:localStorage.getItem('scbToken')
//         }
//     }
//     this.apiService.getCurrentOrgDetails(apiHeader).then(response => {
//         if(response.response){
//             this.OrgEmail = response.response.contactInformation[0].email
//         }
//     });
// }


  getOrgVehcileList() {
    let parent = this;
    var vehObj = {
      data: {
        key: localStorage.getItem('scbToken'),
        filter: {
          regNo: this.VehicleName
        }
      }
    }
    this.apiService.getOrgVehicles(vehObj).then(response => {
      if (response.status = "success") {
        if (response.response != null && response.response != undefined && response.response != '') {
          this.vehcileList = response.response;
          setTimeout(() => {
            this.transFormSelectToChosen();
          }, 20) // Initialize chooosen after 2 seconds.
        }
      }
    }).catch(error => { });
  }

  transFormSelectToChosen() {
    this.chosenUtility.initializeChosen('devicesDropdown', '', true);
  }

  // function to choose which notification is selected and apply custom filter items for notifications
  onSelect(notif_name: any) {
    this.geofence = false;
    this.overSpeed = false;
    this.panic = false;
    this.halt = false;
    this.emailFlag=false;
    this.smsFlag=false;
    this.mobileapp = false;
    this.selectedNotification = notif_name;



if(notif_name == 'calendar'){ 
this.emailFlag=true;
this.email = false;
this.sms = false;
this.smsFlag=false;

}else if(notif_name=='vehiclenotmove'){  
  this.smsFlag=true;
  this.email = false;
this.sms = false;
  this.emailFlag=false;
}
else{ 
  this.emailFlag=false; 
  this.smsFlag=false;
  this.email = false;
this.sms = false;
  
}


    if (notif_name == 'geofence') this.geofence = true;
    else if (notif_name == 'overspeed') this.overSpeed = true;
    else if (notif_name == 'panic') this.panic = true;
    else if (notif_name == 'halt') this.halt = true;
  }

  funSelectType(notif_type: any) {
    this.sms = false;
    this.mobileapp = false;
    this.email = false;
    this.webapp = false;
    this.selectedNotificationType = notif_type;

    if (notif_type == 'sms') this.sms = true;
    else if (notif_type == 'mobileapp') this.mobileapp = true;
    else if (notif_type == 'email') this.email = true;
    else if (notif_type == 'webapp') this.webapp = true;
    
  }

  // store(mobilenum:number) {
  //   if(this.mobile.length >=10){
  //     this.mobileNumberList.push(this.mobile);
  //   }
  //   this.mobile= mobilenum;
  //   this.mobile = '';
  //
  // }
  // //function to remove the contact information from temporary array
  // removeMobile(i: number) {
  //   this.mobileNumberList.splice(i, 1);
  // }

  storeEmail() {
    this.emailList.push(this.emailData);
    this.emailData = '';
  }
  //function to remove the contact information from temporary array
  removeEmail(i: number) {
    this.emailList.splice(i, 1);
  }

  MobileNumberValidator(event: any) {
    event.srcElement.value = event.srcElement.value.replace(/[^\d].+/, "");

    if (event.srcElement.value != '') {
      //  console.log('t',event.srcElement.value);
      if ((event.which < 48 || event.which > 57)) {
        event.preventDefault();
      }
    } else {
      // event.srcElement.value=0;
    }
  }

//function to check whether user selected All vehicle or individual vehicles
  checkChoosenVehicles(){
    if(this.chosenUtility.getControlvalue('devicesDropdown')=='all'){
      this.selectedAll=this.chosenUtility.getControlvalue('devicesDropdown');
    }else{
      this.selectedVehicles=this.chosenUtility.getControlvalue('devicesDropdown');

    }
  }

  addNotificationSetting_bck(){
    this.checkChoosenVehicles();
    let form= {
      notificationFor: this.selectedNotification,
      notificationType: this.selectedNotificationType,
      overSpeedval: this.speedLimit,
      mobileNo: this.mobileNumberList,
      email: this.emailList,
      allVehicles: this.selectedAll+"",
      vehicleLists: this.selectedVehicles,
      startTime: this.startTime,
      endTime: this.endTime,
      mobileContact:this.mobileContact,
    }
 
  }
//to differentiate the two button functions 
  addNotificationSetting_email() {
  
    if(this.emailFlag==true && this.smsFlag==false){  
      
    
        this.apiService.createNotification( {
          data: {
            key: localStorage.getItem('scbToken'),
            form: {
              notificationFor: this.selectedNotification,
              notificationType: this.selectedNotificationType,
              // overSpeedval: this.speedLimit,
              // mobileNo: this.mobileNumberList,
              email: this.emailList,
              // allVehicles: this.selectedAll+"",
              // vehicleLists: this.selectedVehicles,
              startTime: this.startTime,
              endTime: this.endTime,
              // mobileContact:this.mobileContact,
            }
          }
        }).then(response => {
          let result=response;
          if(result.status=="success"){
            swal("Success", "Notification Added Successfully", "success");
            setTimeout( ()=>{ swal.close();
            }, 2000);
  
          }else if(result.status=='failure'){
            let apiRepsponceArray:any =[];
            let errorArray:any=[];
            let orgError:any=[];
            apiRepsponceArray  =  result.response.duplicated;
            Object.keys(apiRepsponceArray).forEach(function(key:any) {
              errorArray = apiRepsponceArray[key];
  
              for(var index in errorArray){
                orgError.push(errorArray[index]);
              }
               let div = document.createElement("div");
               div.innerHTML = "<div style='overflow:auto'><b>Following configuration exist</b><br>"+ orgError +"</div>";
  
                swal({
                  title: 'Failure',
                  content: div ,
                  icon: 'error',
                });
            });
          //  swal("Failure",response.message, "error").done();
          }else if(result.status=='partial'){
          let apiRepsponceArray:any =[] =  result.response.succeed;
          let DuplicatedArray:any=[]=result.response.duplicated
          let errorArray:any=[];
          let duparray:any=[];
          let orgError:any=[];
          let dupValues:any=[];
           Object.keys(apiRepsponceArray).forEach(function(key:any) {
             errorArray = apiRepsponceArray[key];
  
             for(var index in errorArray){
               orgError.push(errorArray[index]);
             }
           });
           Object.keys(DuplicatedArray).forEach(function(key:any) {
             duparray = DuplicatedArray[key];
  
             for(var index in duparray){
               dupValues.push(duparray[index]);
             }
           });
           let div = document.createElement("div");
           div.innerHTML = "<div style='overflow:auto'><b>Success</b> :-"+orgError + " <br><hr><b>Following configurations alreday exist for</b> :-"+dupValues+"</div>";
           swal({
             title: 'Partially Uploaded',
             content: div ,
             icon: 'warning',
           });
          }
        else{
          swal("Failure","Please try again", "error");
        }
        })
        .catch(error => {
          console.log('Error while saving the data', error);
          //swal("Failure","Error while adding Notification for Organization", "success").done();
        });
  
     
    }else if(this.emailFlag==false && this.smsFlag==true){  


      this.apiService.createNotification( {
        data: {
          key: localStorage.getItem('scbToken'),
          form: {
            notificationFor: this.selectedNotification,
            notificationType: this.selectedNotificationType,
            // overSpeedval: this.speedLimit,
             mobileNo: this.mobileNumberList,
           // email: this.emailList,
            // allVehicles: this.selectedAll+"",
            // vehicleLists: this.selectedVehicles,
            startTime: this.startTime,
            endTime: this.endTime,
            // mobileContact:this.mobileContact,
          }
        }
      }).then(response => {
        let result=response;
        if(result.status=="success"){
          swal("Success", "Notification Added Successfully", "success");
          setTimeout( ()=>{ swal.close();
          }, 2000);

        }else if(result.status=='failure'){
          let apiRepsponceArray:any =[];
          let errorArray:any=[];
          let orgError:any=[];
          apiRepsponceArray  =  result.response.duplicated;
          Object.keys(apiRepsponceArray).forEach(function(key:any) {
            errorArray = apiRepsponceArray[key];

            for(var index in errorArray){
              orgError.push(errorArray[index]);
            }
             let div = document.createElement("div");
             div.innerHTML = "<div style='overflow:auto'><b>Following configuration exist</b><br>"+ orgError +"</div>";

              swal({
                title: 'Failure',
                content: div ,
                icon: 'error',
              });
          });
        //  swal("Failure",response.message, "error").done();
        }else if(result.status=='partial'){
        let apiRepsponceArray:any =[] =  result.response.succeed;
        let DuplicatedArray:any=[]=result.response.duplicated
        let errorArray:any=[];
        let duparray:any=[];
        let orgError:any=[];
        let dupValues:any=[];
         Object.keys(apiRepsponceArray).forEach(function(key:any) {
           errorArray = apiRepsponceArray[key];

           for(var index in errorArray){
             orgError.push(errorArray[index]);
           }
         });
         Object.keys(DuplicatedArray).forEach(function(key:any) {
           duparray = DuplicatedArray[key];

           for(var index in duparray){
             dupValues.push(duparray[index]);
           }
         });
         let div = document.createElement("div");
         div.innerHTML = "<div style='overflow:auto'><b>Success</b> :-"+orgError + " <br><hr><b>Following configurations alreday exist for</b> :-"+dupValues+"</div>";
         swal({
           title: 'Partially Uploaded',
           content: div ,
           icon: 'warning',
         });
        }
      else{
        swal("Failure","Please try again", "error");
      }
      })
      .catch(error => {
        console.log('Error while saving the data', error);
        //swal("Failure","Error while adding Notification for Organization", "success").done();
      });


    }    else if(this.emailFlag==false && this.smsFlag==false)
    { 
      this.checkChoosenVehicles();
    
      if (this.validateUserInputs_email()) {
        this.apiService.createNotification( {
          data: {
            key: localStorage.getItem('scbToken'),
            form: {
              notificationFor: this.selectedNotification,
              notificationType: this.selectedNotificationType,
              overSpeedval: this.speedLimit,
              mobileNo: this.mobileNumberList,
              email: this.emailList,
              allVehicles: this.selectedAll+"",
              vehicleLists: this.selectedVehicles,
              startTime: this.startTime,
              endTime: this.endTime,
              mobileContact:this.mobileContact,
            }
          }
        }).then(response => {
          let result=response;
          if(result.status=="success"){
            swal("Success", "Notification Added Successfully", "success");
            setTimeout( ()=>{ swal.close();
            }, 2000);
  
          }else if(result.status=='failure'){
            let apiRepsponceArray:any =[];
            let errorArray:any=[];
            let orgError:any=[];
            apiRepsponceArray  =  result.response.duplicated;
            Object.keys(apiRepsponceArray).forEach(function(key:any) {
              errorArray = apiRepsponceArray[key];
  
              for(var index in errorArray){
                orgError.push(errorArray[index]);
              }
               let div = document.createElement("div");
               div.innerHTML = "<div style='overflow:auto'><b>Following configuration exist</b><br>"+ orgError +"</div>";
  
                swal({
                  title: 'Failure',
                  content: div ,
                  icon: 'error',
                });
            });
          //  swal("Failure",response.message, "error").done();
          }else if(result.status=='partial'){
          let apiRepsponceArray:any =[] =  result.response.succeed;
          let DuplicatedArray:any=[]=result.response.duplicated
          let errorArray:any=[];
          let duparray:any=[];
          let orgError:any=[];
          let dupValues:any=[];
           Object.keys(apiRepsponceArray).forEach(function(key:any) {
             errorArray = apiRepsponceArray[key];
  
             for(var index in errorArray){
               orgError.push(errorArray[index]);
             }
           });
           Object.keys(DuplicatedArray).forEach(function(key:any) {
             duparray = DuplicatedArray[key];
  
             for(var index in duparray){
               dupValues.push(duparray[index]);
             }
           });
           let div = document.createElement("div");
           div.innerHTML = "<div style='overflow:auto'><b>Success</b> :-"+orgError + " <br><hr><b>Following configurations alreday exist for</b> :-"+dupValues+"</div>";
           swal({
             title: 'Partially Uploaded',
             content: div ,
             icon: 'warning',
           });
          }
        else{
          swal("Failure","Please try again", "error");
        }
        })
        .catch(error => {
          console.log('Error while saving the data', error);
          //swal("Failure","Error while adding Notification for Organization", "success").done();
        });
  
      }
    }
    

  }
  addNotificationSetting() {
    this.checkChoosenVehicles();
  //  console.log('11111 ',this.emailFlag,' ',this.smsFlag);

if(this.emailFlag==false && this.smsFlag==true){ 
  this.apiService.createNotification( {
    data: {
      key: localStorage.getItem('scbToken'),
      form: {
        notificationFor: this.selectedNotification,
        notificationType: this.selectedNotificationType,
        // overSpeedval: this.speedLimit,
        mobileNo: this.mobileNumberList,
        // email: this.emailList,
        // allVehicles: this.selectedAll+"",
        // vehicleLists: this.selectedVehicles,
        startTime: this.startTime,
        endTime: this.endTime,
        // mobileContact:this.mobileContact,
      }
    }
  }).then(response => {
    let result=response;
    if(result.status=="success"){
      swal("Success", "Notification Added Successfully", "success");
      setTimeout( ()=>{ swal.close();
      }, 2000);

    }else if(result.status=='failure'){
      let apiRepsponceArray:any =[];
      let errorArray:any=[];
      let orgError:any=[];
      apiRepsponceArray  =  result.response.duplicated;
      Object.keys(apiRepsponceArray).forEach(function(key:any) {
        errorArray = apiRepsponceArray[key];

        for(var index in errorArray){
          orgError.push(errorArray[index]);
        }
         let div = document.createElement("div");
         div.innerHTML = "<div style='overflow:auto'><b>Following configuration exist</b><br>"+ orgError +"</div>";

          swal({
            title: 'Failure',
            content: div ,
            icon: 'error',
          });
      });
    //  swal("Failure",response.message, "error").done();
    }else if(result.status=='partial'){
    let apiRepsponceArray:any =[] =  result.response.succeed;
    let DuplicatedArray:any=[]=result.response.duplicated
    let errorArray:any=[];
    let duparray:any=[];
    let orgError:any=[];
    let dupValues:any=[];
     Object.keys(apiRepsponceArray).forEach(function(key:any) {
       errorArray = apiRepsponceArray[key];

       for(var index in errorArray){
         orgError.push(errorArray[index]);
       }
     });
     Object.keys(DuplicatedArray).forEach(function(key:any) {
       duparray = DuplicatedArray[key];

       for(var index in duparray){
         dupValues.push(duparray[index]);
       }
     });
     let div = document.createElement("div");
     div.innerHTML = "<div style='overflow:auto'><b>Success</b> :-"+orgError + " <br><hr><b>Following configurations alreday exist for</b> :-"+dupValues+"</div>";
     swal({
       title: 'Partially Uploaded',
       content: div ,
       icon: 'warning',
     });
    }
  else{
    swal("Failure","Please try again", "error");
  }
  })
  .catch(error => {
    console.log('Error while saving the data', error);
    //swal("Failure","Error while adding Notification for Organization", "success").done();
  });

}else{

    if (this.validateUserInputs()) {
      this.apiService.createNotification( {
        data: {
          key: localStorage.getItem('scbToken'),
          form: {
            notificationFor: this.selectedNotification,
            notificationType: this.selectedNotificationType,
            overSpeedval: this.speedLimit,
            mobileNo: this.mobileNumberList,
            email: this.emailList,
            allVehicles: this.selectedAll+"",
            vehicleLists: this.selectedVehicles,
            startTime: this.startTime,
            endTime: this.endTime,
            mobileContact:this.mobileContact,
          }
        }
      }).then(response => {
        let result=response;
        if(result.status=="success"){
          swal("Success", "Notification Added Successfully", "success");
          setTimeout( ()=>{ swal.close();
          }, 2000);

        }else if(result.status=='failure'){
          let apiRepsponceArray:any =[];
          let errorArray:any=[];
          let orgError:any=[];
          apiRepsponceArray  =  result.response.duplicated;
          Object.keys(apiRepsponceArray).forEach(function(key:any) {
            errorArray = apiRepsponceArray[key];

            for(var index in errorArray){
              orgError.push(errorArray[index]);
            }
             let div = document.createElement("div");
             div.innerHTML = "<div style='overflow:auto'><b>Following configuration exist</b><br>"+ orgError +"</div>";

              swal({
                title: 'Failure',
                content: div ,
                icon: 'error',
              });
          });
        //  swal("Failure",response.message, "error").done();
        }else if(result.status=='partial'){
        let apiRepsponceArray:any =[] =  result.response.succeed;
        let DuplicatedArray:any=[]=result.response.duplicated
        let errorArray:any=[];
        let duparray:any=[];
        let orgError:any=[];
        let dupValues:any=[];
         Object.keys(apiRepsponceArray).forEach(function(key:any) {
           errorArray = apiRepsponceArray[key];

           for(var index in errorArray){
             orgError.push(errorArray[index]);
           }
         });
         Object.keys(DuplicatedArray).forEach(function(key:any) {
           duparray = DuplicatedArray[key];

           for(var index in duparray){
             dupValues.push(duparray[index]);
           }
         });
         let div = document.createElement("div");
         div.innerHTML = "<div style='overflow:auto'><b>Success</b> :-"+orgError + " <br><hr><b>Following configurations alreday exist for</b> :-"+dupValues+"</div>";
         swal({
           title: 'Partially Uploaded',
           content: div ,
           icon: 'warning',
         });
        }
      else{
        swal("Failure","Please try again", "error");
      }
      })
      .catch(error => {
        console.log('Error while saving the data', error);
        //swal("Failure","Error while adding Notification for Organization", "success").done();
      });

    }
  }
  }

  //Validate addNotificationSetting

  validateUserInputs_email(){
    let isValid: boolean = true;
    if (!this.selectedNotification) {
      this.notificationForError = true;
      isValid = false;
    } else {
      this.notificationForError = false;
    }

    if (!this.selectedNotificationType) {
      this.NotificationTypeError = true;
      isValid = false;
    } else {
      this.NotificationTypeError = false;
    }

    if (!this.startTime) {
      this.NotificationStartTimeError = true;
      isValid = false;
    } else {
      this.NotificationStartTimeError = false;
    }

    if (!this.endTime) {
      this.NotificationEndTimeError = true;
      isValid = false;
    } else {
      this.NotificationEndTimeError = false;
    }

    if(this.startTime > this.endTime){
      this.timeMismatchError = true;
      isValid = false;
    }else{
      this.timeMismatchError = false;
    }

    if(Object.keys(this.emailList).length<=0){      
     

      if(this.webapp==true || this.mobileapp==true ){
       isValid =true;
      }else{
        isValid=false;
      }

    }else{

     
      isValid =true;



    }

    
    
    if (!this.chosenUtility.getControlvalue('devicesDropdown').length) {
      this.NotificationVehicleError = true;
      isValid = false;
    } else {
      this.NotificationVehicleError = false;
    }
  
    return isValid;
  }

  

  validateUserInputs() {
    let isValid: boolean = true;
    if (!this.selectedNotification) {
      this.notificationForError = true;
      isValid = false;
    } else {
      this.notificationForError = false;
    }

    if (!this.selectedNotificationType) {
      this.NotificationTypeError = true;
      isValid = false;
    } else {
      this.NotificationTypeError = false;
    }

    if (!this.startTime) {
      this.NotificationStartTimeError = true;
      isValid = false;
    } else {
      this.NotificationStartTimeError = false;
    }

    if (!this.endTime) {
      this.NotificationEndTimeError = true;
      isValid = false;
    } else {
      this.NotificationEndTimeError = false;
    }

    if(this.startTime > this.endTime){
      this.timeMismatchError = true;
      isValid = false;
    }else{
      this.timeMismatchError = false;
    }

    if(this.selectNumbersfromlist ==false){      
      isValid=false;
    }else{
      isValid =true;
    }

    
    if (!this.chosenUtility.getControlvalue('devicesDropdown').length) {
      this.NotificationVehicleError = true;
      isValid = false;
    } else {
      this.NotificationVehicleError = false;
    }
  
    return isValid;
  }
/*
setUserSelections(param:any){
    this.mobileNumberList = [];
    this.mobileContact={}
    this.mobileNumberList = param.map(function(index:any){
      // console.log(index);
   
        return index.UserMobileNo
    });
    let obj={}
    this.mobileContact =  param.map(function(index:any){
    let mobKey = index.UserMobileNo.toString();
    obj[mobKey] = index.UserName;
    });
    this.mobileContact = obj;

    // console.log(this.mobileContact);


    this.emailList = [];
    this.emailList = param.map(function(index:any){
      return index.useremail
    });

    //console.log(this.emailList);
} */

receivedCollect($event:any){
  this.collectedData = $event;

  this.selectNumbersfromlist = Object.keys(this.collectedData).length ? true : false;

  this.mobileNumberList = Object.keys(this.collectedData).map(item=>+item);

  let obj ={}
  Object.keys(this.collectedData).forEach((item:any,index:any)=>{   
    obj[this.collectedData[item].UserMobileNo] = this.collectedData[item].UserName
  })
  this.mobileContact = obj; 
}
receivedCollectEmail($event:any){
  this.collectedData = $event;
  this.emailList = Object.keys(this.collectedData)
}

getRegUserMobile(){
  this.apiService.getOrgMobiles({
    data:{
      key: localStorage.getItem('scbToken'),
      extra: {
        orderByDateCreated: -1
      }
    }
  }).then(res=>{
    this.userRegisteredMobile['main'] = res.response;
   
  });

  this.apiService.getOrgEmails({
    data:{
      key: localStorage.getItem('scbToken'),
      extra: {
        orderByDateCreated: -1
      }
    }
  }).then(res=>{
    this.userRegisteredEmail['main'] = res.response;
   
  });


}



}
/*
Notes : npm install node-sass -g
*/
