/*Auther:Deepak */
import { Component, Input } from '@angular/core';
import { globalService } from './../../services/global.service';
import { ApiService } from './../../services/ApiService';
import { RouterModule, Routes, Router } from '@angular/router';
import { StorageService } from './../../services/storage.service';

const swal = require('sweetalert');

@Component({
  selector: 'student-form',
  styles: [`
    div.section-title{
      font-size:20px;
    }
    div.section-title-border{
      margin-bottom: 20px;
      border-bottom: 1px solid #00BCD4;
      border-bottom-width: medium;
      border-spacing: 1px;
      width:5%;
    }
    .student-basic-info,.student-pickup-info,.student-drop-info,.student-notif-status{
      margin-bottom:10px;
    }
    .customHeight{
      height:42px;
      box-shadow:none;
    }
    .alertLabel{
      margin-left:3px;
    }
    input[type=checkbox], input[type=radio] {
      margin: 4px 4px -3px;
      line-height: normal;
    }

    `],
  template: `
            <form>
              <section class="student-basic-info">
                  <div class="row">
                      <div class="section-title">Student Basic Info</div>
                      <div class="section-title-border"></div>
                  </div>
                  <div class="row">
                    <div class="form-group col-md-4">
                      <label class="control-label">Student ID<sup>*</sup></label>
                      <input  oninput="this.value = this.value.toUpperCase()"  class="custom-form-control meager-e2e-add-stu-id" name="memberId" [(ngModel)]="memberId" required appAutofocus>
                        <div *ngIf="formError.StudentID==true"  class="text-danger">*Student ID should not be empty</div>
                    </div>
                    <div class="form-group col-md-4">
                      <label class="control-label">Student Name<sup>*</sup></label>
                      <input (keyup)="passwordValidator($event);" class="custom-form-control meager-e2e-add-stu-name" name="memberName" [(ngModel)]="memberName" required>
                      <div *ngIf="formError.StudentName==true"  class="text-danger">*Student name should not be empty</div>
                    </div>

                    <div class="form-group col-md-4">
                      <label class="control-label">Class<sup>*</sup></label>
                      <!--<input (keyup)="characterValidator($event)" class="custom-form-control" name="memberClass" [(ngModel)]="memberClass" required>-->
                      <select (change)="characterValidator($event)" class="custom-form-control meager-e2e-add-stu-class" name="memberClass"  [(ngModel)]="memberClass" required>
                      <!-- <option value="">Select class</option> -->
                      <option *ngFor="let item of classAndSessions.listofclasses" value="{{item}}">{{item}}</option>
                      </select>
                      <div *ngIf="formError.StudentClass==true"  class="text-danger">*Student Class should not be empty</div>
                    </div>
                  </div>
                  
                  <div class="row">

                    <div class="col-md-4 form-group">
                      <label class="control-label">Section<sup>*</sup></label>
                     <!-- <input class="custom-form-control" name="memberSection" [(ngModel)]="memberSection" required> -->
                     <select class="custom-form-control meager-e2e-add-stu-section"  name="memberSection" [(ngModel)]="memberSection" required>
                     <option *ngFor="let item of classAndSessions.listofsections" value="{{item}}">{{item}}</option>
                     </select>
                      <div *ngIf="formError.StudentSection==true"  class="text-danger">*Student Section should not be empty</div>
                    </div>
                    <div class="col-md-4 form-group">
                      <label class="control-label">Parent Name<sup>*</sup></label>
                      <input (keyup)="passwordValidator($event);" class="custom-form-control meager-e2e-add-stu-parent-name" name="memberParentName" [(ngModel)]="memberParentName" required>
                      <div *ngIf="formError.ParentName==true"  class="text-danger">*Parent name should not be empty</div>
                    </div>
                    <div class="col-md-4 form-group">
                      <label class="control-label">Primary Contact Number<sup>*</sup></label>
                      <input   class="custom-form-control meager-e2e-add-stu-primary-number" name="memberContact" (keyup)="MobileNumberValidator($event)" minlength="10" maxlength="13"
                        [(ngModel)]="memberContact" type="text" required >
                      <div *ngIf="formError.Contact==true"  class="text-danger">*Contact Number should not be empty</div>
                    </div>
                  </div>
                  <div class="row contact-details">
                    <div class="col-md-4 form-group">
                      <label class="control-label">Secondary Contact Number</label>
                      <input class="custom-form-control meager-e2e-add-stu-secondary-number" name="memberSecContact" (keyup)="MobileNumberValidator($event)" minlength="10" maxlength="13"
                       [(ngModel)]="memberSecContact">
                    </div>
                    <div class="col-md-4 form-group">
                      <label class="control-label">Student RFID</label>
                      <input class="custom-form-control meager-e2e-add-stu-rfid-number" name="memberRfid" (keyup)="MobileNumberValidator($event)" minlength="5" maxlength="15"
                       [(ngModel)]="memberRfid">
                    </div>
                  </div>
              </section>
              <section class="student-pickup-info">
                <div class="row ">
                    <!--  <span  (click)="validator('pickupPoint');" class="mr5" style="float:right;cursor: pointer;color: #429db5;padding: 0px 0px 0px 0px;"> Add Pickup Point  </span>
                      <span class="mr5" style="float:right;cursor: pointer;color: #429db5;padding: 0px 0px 0px 0px;"> /</span>
                       <span  (click)="validator('route');" class="mr5" style="float:right;cursor: pointer;color: #429db5;" > <i class="fa fa-plus"></i> Add Route </span>
                    -->
                    <div class="section-title">Student Route1 Info</div>
                    <div class="section-title-border"></div>
                </div>
                  <div class="row route-details">
                    <div class="col-md-4 form-group">
                      <label class="control-label">Route Name</label>
                      <single-search-select class="meager-e2e-add-stu-route1"
                      [searchType]="'onlyRoute'"
                      [searchAPIUrl]="'getRoutes'"
                      [otherParams]="'resetSearchUtility'"
                      (emitUserSelections)="setSelectedRoute($event)">
                      </single-search-select>
                    </div>
                    <div class="col-md-4 form-group">
                       <label class="control-label">Pickup Point</label>
                       <select class="custom-form-control customHeight meager-e2e-add-stu-pp1" id="pickupPoint" name="pickupPoint" [(ngModel)]="selectedPickupPoint" (change)="estimateEta(this.selectedRoute,selectedPickupPoint)">
                          <option *ngIf="selectedRoute" value="addpickupPoint" >Add pickup Point</option>
                          <option *ngFor="let point of routePickUpPoints" value="{{point.pickupId}}">{{point.pickuppoint}}</option>
                       </select>
                    </div>
                    <div class="col-md-4 form-group">
                      <label class="control-label">Estimated Arrival Time</label>
                       <input class="custom-form-control customHeight meager-e2e-add-stu-pp1-exp-time" name="pointEta" [(ngModel)]="selectedPointETA" disabled>
                    </div>
                  </div>
                  <div class="row">


                  </div>
              </section>
              <section class="student-drop-info">
                <div class="row">
                    <div class="section-title">Student Route2 Info</div>
                    <div class="section-title-border"></div>
                </div>
                  <div class="row">
                    <div class="col-md-4 form-group">
                      <label class="control-label">Route Name</label>
                      <!--<select class="custom-form-control" id="routeNameReturn" name="routeNameReturn" [(ngModel)]="selectedRouteReturn"  (change)="populateRouteStoppagesReturn()">
                        <option *ngFor="let route of schoolRoutes" value="{{route.routeId}}">{{route.routeName}}</option>
                      </select>-->
                      <single-search-select class="meager-e2e-add-stu-route2"
                      [searchType]="'onlyRoute'"
                      [searchAPIUrl]="'getRoutes'"
                      [otherParams]="'resetSearchUtility'"
                      (emitUserSelections)="setSelectedRoute2($event)">
                      </single-search-select>
                    </div>
                    <div class="col-md-4 form-group">
                      <label class="control-label ">Pickup Point</label>
                      <select class="custom-form-control customHeight meager-e2e-add-stu-pp2" id="pickupPoint" name="pickupPoint" [(ngModel)]="studentDropLocation" (change)="estimateEtaReturnRoute(this.selectedRouteReturn,studentDropLocation)">
                          <option *ngIf="selectedRouteReturn" value="adddropPoint" >Add Drop Point</option>
                          <option *ngFor="let point of studentReturnRoutePickup" value="{{point.pickupId}}">{{point.pickuppoint}}</option>
                      </select>
                    </div>
                    <div class="col-md-4 form-group">
                      <label class="control-label">Estimated Time Of arrival</label>
                      <input class="custom-form-control customHeight meager-e2e-add-stu-pp2-exp-time" [(ngModel)]="routeReturnETA" [ngModelOptions]="{standalone: true}" disabled>
                    </div>
                  </div>
              </section>
              <section class="student-notif-status">
                <div class="row">
                    <div class="section-title">Student Notification Settings</div>
                    <div class="section-title-border"></div>
                </div>
                <div class="row">
                  <div class="form-group col-md-2">
                    Get Notifications:
                  </div>
                  <div class="form-group col-md-2">
                    <input class="chk regular-checkbox meager-e2e-add-stu-notification-email" type="checkbox"  >
                    <label class="control-label alertLabel">EMAIL</label>
                  </div>
                  <div class="form-group col-md-2">
                    <input class="chk regular-checkbox meager-e2e-add-stu-notification-sms" type="checkbox" >
                    <label class="control-label alertLabel">SMS</label>
                  </div>
                  <div class="form-group col-md-2">
                    <input class="chk regular-checkbox meager-e2e-add-stu-notification-app" type="checkbox"  >
                    <label class="control-label alertLabel">APP </label>
                  </div>
                  <div class="form-group col-md-2">
                    <input class="chk regular-checkbox meager-e2e-add-stu-notification-call" type="checkbox"  >
                    <label class="control-label alertLabel">CALL</label>
                  </div>
                </div>
              </section>
              <div class="row form-actions">
                <button style="color:#fff !important;margin-right:15px;" (click)="validator('submit');"
                class="webform-submit button-primary btn btn-primary form-submit pull-right meager-e2e-add-submit-btn">SUBMIT</button>
              </div>
                <div *ngIf="formError.checkVal==true" class="text-danger pull-right">*Please Fill All Required Fields</div>
            </form>
           `
})

export class studentFormDir {
  public schoolRoutes: any[];
  public routePickUpPoints: any[];
  public selectedRoute: any;
  public selectedPickupPoint: any;
  public selectedPointETA: any
  public memberId: any;
  public memberName: any;
  public memberClass: any;
  public memberSection: any;
  public memberParentName: any;
  public memberContact: any;
  public memberSecContact: any;
  public memberRfid: any;
  public selectedRouteReturn: any = [];
  public studentDropLocation: any = [];
  public studentReturnRoutePickup: any = [];
  public routeReturnETA: any;
  public MemberInfo: any = [];
  public classAndSessions: any = {};

  public formError: any = {};//validator instance
  public errorMesages: any = [];//validator instance

  constructor(private apiService: ApiService, private router: Router, public storage: StorageService) {
    this.getRoutes();

  }

  ngOnInit() {
    this.getClassAndSessions();
  }

  private apiToken: any = localStorage.getItem("scbToken");


  MobileNumberValidator(event: any) {
    event.srcElement.value = event.srcElement.value.replace(/[^\d].+/, "");

    if (event.srcElement.value != '') {
      //  console.log('t',event.srcElement.value);
      if ((event.which < 48 || event.which > 57)) {
        event.preventDefault();
      }
    } else {
      // event.srcElement.value=0;
    }
  }

  characterValidator(event: any) {
    event.srcElement.value = event.srcElement.value.replace(/^[0-9]*$/g, "");
    if (event.srcElement.value != '') {
      //  console.log('t',event.srcElement.value);
      if ((event.which < 48 || event.which > 57)) {
        event.preventDefault();
      }
    } else {
      // event.srcElement.value=0;
    }

  }


  passwordValidator(event: any) {
    event.srcElement.value = event.srcElement.value.replace(/[()\-\[\];':"\\|,+<>\/]/gi, '');
    if (event.srcElement.value != '') {
      //  console.log('t',event.srcElement.value);
      if ((event.which < 48 || event.which > 57)) {
        event.preventDefault();
      }
    } else {
      // event.srcElement.value=0;
    }
  }



  getRoutes() {
    let parent: any = this;
    var routeObj = {
      data: {
        key: localStorage.getItem('scbToken')
      }
    };
    this.apiService.getRoutes(routeObj).then(response => {
      var parent: any = this;
      if (response) {
        this.schoolRoutes = response.response;
      };
    }).catch(error => { });
  }

  populateRouteStoppages() {
    this.routePickUpPoints = [];
    this.selectedPointETA = '';
    this.populatePickUpPoints(this.selectedRoute);
  }

  populateRouteStoppagesReturn(selectedRoute2: any) {
    // console.log(selectedRoute2);
    var stoppageObj = {
      data: {
        key: localStorage.getItem('scbToken'),
        "filter": {
          "routeId": this.selectedRouteReturn,
        }
      }
    }
    this.apiService.getStoppageDetails(stoppageObj).then(response => {
      this.studentReturnRoutePickup = response.response.pickuppoints;
    });
  }


  estimateEtaReturnRoute(dropRouteId: any, studentDropLocation: any) {


    let parent = this;
    if (studentDropLocation == 'adddropPoint') {

      if (this.validator('redirectRoute')) {

        swal({
          title: 'Are you sure?',
          text: 'Do you want to add new drop point to selected route',
          icon: 'warning',
          buttons: [
            'No, cancel it!',
            'Yes, I am sure!'
          ],
          dangerMode: false,
        }).then(function (isConfirm: any) {
          if (isConfirm) {
            parent.storage.set({
              // 'sudentID':parent.memberId,
              // 'studentName':parent.memberName,
              'navigationFrom': 'editStudent',
              'navigateType': 'routeList',
              'selectedRouteId': dropRouteId
            });

            //localStorage.setItem();
            //parent.addMember('routeList');

            setTimeout(() => {
              parent.addMember('routeList');
            }, 1000);
            // parent.router.navigate(['/school-routes-list']);
          }
        })
      }

    } else {
      let point = this.studentReturnRoutePickup.filter(function (index: any, value: any) {
        return index.pickupId == studentDropLocation
      });
      this.routeReturnETA = point[0].expectedTime;
      //this.selectedPointETA = point[0].expectedTime;
    }
  }

  populatePickUpPoints(selectedRoute: any) {
    var stoppageObj = {
      data: {
        key: localStorage.getItem('scbToken'),
        "filter": {
          "routeId": this.selectedRoute,
        }
      }
    }
    this.apiService.getStoppageDetails(stoppageObj).then(response => {
      this.routePickUpPoints = response.response.pickuppoints;
    });
  }

  estimateEta(pickupRoute: any, pointId: any) {
    let parent = this;
    if (pointId == 'addpickupPoint') {
      // console.log(this.memberId);
      if (this.validator('redirectRoute')) {

        swal({
          title: 'Are you sure?',
          text: 'Do you want to add new pickup point to selected route',
          icon: 'warning',
          buttons: [
            'No, cancel it!',
            'Yes, I am sure!'
          ],
          dangerMode: false,
        }).then(function (isConfirm: any) {
          if (isConfirm) {   // console.log(parent.memberId);
            parent.storage.set({
              // 'sudentID':parent.memberId,
              // 'studentName':parent.memberName,
              'navigationFrom': 'editStudent',
              'navigateType': 'routeList',
              'selectedRouteId': pickupRoute
            });

            //localStorage.setItem();


            setTimeout(() => {
              parent.addMember('routeList');
            }, 1000);

          }
        })


      }

    } else {
      let point = this.routePickUpPoints.filter(route => route.pickupId == pointId);
      this.selectedPointETA = point[0].expectedTime;
    }
  }


  validator(submitType: any) {

    let errorFlag = false; this.errorMesages = [];

    this.formError.StudentName = false;
    if (this.memberName == "" || this.memberName == undefined) {
      this.formError.StudentName = true;
      errorFlag = true; (this.errorMesages).push("Student name Should not be empty");
    }
    this.formError.StudentID = false;
    if (this.memberId == "" || this.memberId == undefined) {
      this.formError.StudentID = true;
      errorFlag = true; (this.errorMesages).push("Student ID Should not be empty");
    }

    this.formError.StudentClass = false;
    if (this.memberClass == "" || this.memberClass == undefined) {
      this.formError.StudentClass = true;
      errorFlag = true; (this.errorMesages).push("Student Class Should not be empty");
    }

    this.formError.StudentSection = false;
    if (this.memberSection == "" || this.memberSection == undefined) {
      this.formError.StudentSection = true;
      errorFlag = true; (this.errorMesages).push("Student Section Should not be empty");
    }

    this.formError.ParentName = false;
    if (this.memberParentName == "" || this.memberParentName == undefined) {
      this.formError.ParentName = true;
      errorFlag = true; (this.errorMesages).push("Parent name Should not be empty");
    }

    this.formError.Contact = false;
    if (this.memberContact == "" || this.memberContact == undefined) {
      this.formError.Contact = true;
      errorFlag = true; (this.errorMesages).push("Contact Should not be empty");
    }

    //  this.formError.checkVal=false;
    //   if(this.memberContact=="" || this.memberContact==undefined || this.memberParentName=="" || this.memberParentName==undefined ||this.memberSection=="" || this.memberSection==undefined ||
    //   this.memberClass=="" || this.memberClass==undefined || this.memberId=="" || this.memberId==undefined || this.memberName=="" || this.memberName==undefined) {
    //     this.formError.checkVal=true;
    //     errorFlag=true; (this.errorMesages).push("Fields name Should not be empty");
    //   }
    if (submitType == 'redirectRoute') {

      if (errorFlag == false) { return true; } else { this.selectedPickupPoint = ''; this.studentDropLocation = ''; }

    } else {
      if (errorFlag == false) { this.addMember(submitType); }
    }

  }

  addMember(subType: any) {

    let membersList: any = {
      MemberUID: this.memberId,
      MemberName: this.memberName,
      MemberClass: this.memberClass,
      MemberSection: this.memberSection,
      MemberParentName: this.memberParentName,
      MemberPrimaryMobileNo: this.memberContact,
      MemberSecondaryMobileNo: this.memberSecContact,
      rfid: this.memberRfid
    };
    let studentInfo: any = []
    studentInfo.push(membersList);
    this.apiService.createMember({
      data: {
        key: this.apiToken,
        form: {
          membersList: studentInfo
        }
      }
    }).then(response => {
      let result = response;
      if (result.status == "success") {
        ///console.log(result);

        this.MemberInfo = result.response.succeed['1'];
        // console.log('MemberInfo',this.MemberInfo.memberId);
        if (this.MemberInfo.memberId != '' && this.MemberInfo.memberId != undefined && this.MemberInfo.memberId != null) {
          //  console.log('dddd',this.selectedRoute,this.selectedPickupPoint)
          if (this.selectedRoute != '' && this.selectedRoute != undefined && this.selectedRoute != null && this.selectedPickupPoint != '' && this.selectedPickupPoint != undefined && this.selectedPickupPoint != null) {
            //console.log('Flag value exists for pickup');

            this.pickupSetFlag(this.MemberInfo.memberId);
          }

          if (this.selectedRouteReturn != '' && this.selectedRouteReturn != undefined && this.selectedRouteReturn != null && this.studentDropLocation != '' && this.studentDropLocation != undefined && this.studentDropLocation != null) {
            //console.log('Flag value exists for drop');
            this.dropSetFlag(this.MemberInfo.memberId);
          }
          swal({
            title: 'Added',
            text: "Student has been added successfully",
            icon: 'success',
            timer: 1500
          });
          // console.log(this.MemberInfo);
          // if(subType=='route'){

          this.storage.set({
            'sudentID': this.MemberInfo.memberId,
            'studentName': this.MemberInfo.name
          });

          //   //localStorage.setItem();
          // setTimeout( ()=>{ swal.close();

          //   this.router.navigate(['/school-routes-list']);

          //   }, 3000);

          // }else if(subType=='pickupPoint'){

          //     this.storage.set({
          //     'sudentID':this.MemberInfo.memberId,
          //     'studentName':this.MemberInfo.name,
          //     'navigationFrom':'editStudent',
          //     'navigateType':'routeList'
          //   });

          //   setTimeout( ()=>{ swal.close();
          //        this.router.navigate(['/school-routes-list']);
          //   }, 3000);
          // }

          if (subType == 'routeList') {

            this.router.navigate(['/school-routes-list']);
          }

        }

        if (subType == 'submit') {

          setTimeout(() => {
            swal.close();
            this.router.navigate(['/school-students-list', {}]);
          }, 3000);

        }

      } else {
        let getErrorMsg = response.response;
        //console.log(getErrorMsg.duplicated[1]);
        if (getErrorMsg.duplicated[1].rfid) {
          swal({
            title: 'Failure',
            text: "RFID already exists " + getErrorMsg.duplicated[1].rfid,
            icon: 'error'
          });
        } else {
          let apiResponseMessage = getErrorMsg.duplicated[1].name;
          swal({
            title: 'Failure',
            text: "Student already exists " + getErrorMsg.duplicated[1].name,
            icon: 'error'
          });
        }

      }
    })
      .catch(error => {
        console.log('Error while creating member', error);
      });
  }

  pickupSetFlag(memId: any) {

    //console.log('memIDPick',memId);
    let selectedRoute = this.selectedRoute;
    let selectedPickupPoint = this.selectedPickupPoint;

    //console.log('check',this.selectedRoute);
    let arrayValues: any = [];

    arrayValues['routes'] = {};
    arrayValues['routes'][this.selectedRoute] = {};
    //console.log('2',arrayValues);
    arrayValues['routes'][this.selectedRoute][this.selectedPickupPoint] = [memId];
    //console.log('values', arrayValues['routes'] );

    let localArray = JSON.stringify(arrayValues['routes']);

    //console.log('-----',localArray);

    this.apiService.AssignMember({
      data: {
        key: localStorage.getItem('scbToken'),
        form: {
          routes: arrayValues['routes']
        }
      }
    }).then(response => {
      let result = response;
      if (result.status == "success") {
        //console.log('udpatedPickup',result);
        //alert("Student PickUp Route Details Added");
        //this.redirect('/view-admin-organization',{});
      }
    })

      .catch(error => {
        console.log('Error while Adding pickup to member', error);
      });

  }



  dropSetFlag(memid: any) {
    //  console.log('memidDrop',memid);
    let selectedRouteReturn = this.selectedRouteReturn;
    let studentDropLocation = this.studentDropLocation;

    //console.log('check',this.selectedRouteReturn);
    let arrayValues: any = [];

    arrayValues['routes'] = {};
    arrayValues['routes'][this.selectedRouteReturn] = {};
    //console.log('2',arrayValues);
    arrayValues['routes'][this.selectedRouteReturn][this.studentDropLocation] = [memid];
    //console.log('values', arrayValues['routes'] );

    let localArray = JSON.stringify(arrayValues['routes']);

    //console.log('-----',localArray);

    this.apiService.AssignMember({
      data: {
        key: localStorage.getItem('scbToken'),
        form: {
          routes: arrayValues['routes']
        }
      }
    }).then(response => {
      let result = response;
      if (result.status == "success") {
        //console.log('udpatedDrop',result);
        //   alert("Student Drop  Details Added");
        //this.redirect('/view-admin-organization',{});
      }
    })

      .catch(error => {
        console.log('Error while addding Drop to  member', error);
      });

  }

  public checkrouteType: string;

  setSelectedRoute(selectedRoute: any) {
    // console.log('selectedRoute', selectedRoute, selectedRoute[0] && selectedRoute[0].routeType)
    if (selectedRoute.length) {
      // if (selectedRoute[0] && selectedRoute[0].routeType === 'pickup') {
      this.checkrouteType = selectedRoute[0].routeType;
      this.selectedPickupPoint = '';
      this.selectedRoute = selectedRoute[0].routeId; //carrying forward  previously declared global var strategy.
      this.populatePickUpPoints(this.selectedRoute);

    } else {
      this.selectedRoute = '';
    }

  }

  setSelectedRoute2(selectedRoute2: any) {

    if (selectedRoute2.length) {
      if (selectedRoute2[0] && selectedRoute2[0].routeType === this.checkrouteType) {

        swal({
          title: 'Warning',
          text: "Selected route type same as pick route type..!",
          icon: 'error'
        });
      } else {
        this.studentDropLocation = '';
        this.selectedRouteReturn = selectedRoute2[0].routeId; //carrying forward  previously declared global var strategy.
        //console.log(this.selectedRouteReturn);
        this.populateRouteStoppagesReturn(this.selectedRouteReturn);
      }

    } else {
      this.selectedRouteReturn = '';
    }
  }

  getClassAndSessions() {


    this.classAndSessions = this.storage.get('classandsection');
  }

}
