/**
 * @Author: Rakesh Roushan
 * @Date:   2017-11-15T11:10:06+05:30
 * @Email:  rakesh@groupten.com
 * @Project: SChool-Bus
 * @Filename: holidays.list.ts
 * @Last modified by:   group10
 * @Last modified time: 2018-05-11T11:50:25+05:30
 * @Copyright: Group10 Technologies
 */



import { ViewEncapsulation, HostListener } from '@angular/core';
import { Component } from '@angular/core';
import { RouterModule, Routes, Router } from '@angular/router';
import { AppComponent } from './../../app.component';
import { ApiService } from './../../services/ApiService';
import { bootstrapDateTime } from './../../services/bootstrapDateTime/datePicker';
import { authGaurd } from './../../services/auth-guard.service'
import { ApiCallDateFormatService } from "../../services/api-call-date-format.service";

declare var $: any;
const swal = require('sweetalert');

@Component({
  // template: ``,
  templateUrl: './holidays.component.html',
  styles: [`


  .modal {
    position: fixed;
    top: 150px;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1050;
    display: none;
    overflow: hidden;
    -webkit-overflow-scrolling: touch;
    outline: 0;
  }

  .months-list {
      display: table;
      list-style: none;
      padding: 0;
  }

  .months-list li {
        display: inline-block;
        padding: 0px;
        padding-right:15px;
        padding-left:5px;
  }
  .month-item {
      border-right:1px solid #e2e2e2;
      color:#1595b8;
      cursor:pointer;
  }
  .months-list > li:last-child {
      border-right:none;
  }
  .months-list > li:hover {
      color:#1595b8;
  }
  .holiday-list {
    list-style: none;
    padding: 10px 0 0;
  }

  .holiday-list .holiday-details {
    float: left;
    padding: 0 20px 0 68px;
    margin: 28px 0;
    width: 50%;
    min-height: 44px;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    position: relative;
  }

  .holiday-details .date-box {
    width: 43px;
    float: left;
    position: absolute;
    left: 0;
    top: 0;
    text-align: center;
    border: 1px solid #e2e2e2;
  }
  .holiday-details .date-box .month {
    background: #e2e2e2;
  }

  .holiday-details .date-box p {
      padding: 1px 0 4px;
      font-size: 10px;
      color: #999;
  }
  .holiday-details .date-box .date {
      font-size: 16px;
      color: #666;
      padding-bottom: 0;
  }
  p {
      margin: 0 0 0px;
  }

  .HolidayItem{
    height: 5px;
    width: 5px;
    border-radius: 50%;
    margin-left: 46%;
  }

  .eventDetails{
    display:inline-block;
    width:100%;
    margin-bottom: 10px;
    padding: 10px;
  }
  .event-basic-info{
    padding: 6px 0 2px 6px;
    line-height: 1.5;
    display: inline-block;
    width: 100%;
    font-size:15px;
  }
  .eventDetails::-webkit-scrollbar-track
  {
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
     background-color: #F5F5F5;
  }

  .eventDetails::-webkit-scrollbar
  {
    width: 2px;
    background-color: #F5F5F5;
  }

  .eventDetails::-webkit-scrollbar-thumb
  {
    background-color: #03A9F4;
    border: 2px solid #03A9F4;
  }

  @-webkit-keyframes pulse {
    0% { -webkit-transform: scale(0.9); opacity: 0.7; }
    50% { -webkit-transform: scale(1); opacity: 1; }
    100% { -webkit-transform: scale(0.9); opacity: 0.7; }
  }

  @keyframes pulse {
    0% { transform: scale(0.9); opacity: 0.7; }
    50% { transform: scale(1); opacity: 1; }
    100% { transform: scale(0.9); opacity: 0.7; }
  }

.HolidayItem {
    -webkit-animation: pulse 3s infinite ease-in-out;
    -o-animation: pulse 3s infinite ease-in-out;
    -ms-animation: pulse 3s infinite ease-in-out;
    -moz-animation: pulse 3s infinite ease-in-out;
    animation: pulse 3s infinite ease-in-out;
}

#calendar{
  margin-left: auto;
  margin-right: auto;
  //width: 100% !important;
}
#calendar_weekdays div{
  display:inline-block;
  vertical-align:top;
  //width: 100% !important;
}
#calendar_content, #calendar_weekdays, #calendar_header{
  position: relative;
  //width: 100% !important;
  overflow: hidden;
  float: left;
  //z-index: 10;
}
#calendar_weekdays div, #calendar_content div{
  width:50px;
  height: 40px;
  overflow: hidden;
  text-align: center;
  background-color: #FFFFFF;
  color: #787878;
}
#calendar_content{
  -webkit-border-radius: 0px 0px 12px 12px;
  -moz-border-radius: 0px 0px 12px 12px;
  border-radius: 0px 0px 12px 12px;
}
#calendar_content div{
  float: left;
}
#calendar_content div:hover{
  background-color: #F8F8F8;
}
 
#calendar_header, #calendar_content div.today{
  zoom: 1;
  filter: alpha(opacity=70);
  opacity: 0.7;
}
#calendar_content div.today{
  color: #FFFFFF;
}
#calendar_header{
  width: 100%;
  height: 37px;
  text-align: center;
  background-color: #FF6860;
  padding: 18px 0;
  // -webkit-border-radius: 12px 12px 0px 0px;
  // -moz-border-radius: 12px 12px 0px 0px;
  // border-radius: 12px 12px 0px 0px;
}
#calendar_header h1{
  font-size: 1.5em;
  color: #FFFFFF;
  float:left;
  width:70%;
}

i[class^=icon-chevron]{
  color: #fff;
  float: left;
  width:15%;
  //border-radius: 50%;
  margin-top: -4px;
  cursor: pointer;
}
.HolidayItem {
  font-size: 11px;
  color: red;
}

.eventsPrint {
  width: 100% !important;
}


div.eventDetails{
    height:430px;border-left: 2px solid #9E9E9E;
}
li.list-group-item.mb10.mr5 {
    border-radius: 0;
    background: #f7f6f6;
    border: none;
    padding: 10px;
}
.action-icon-container{
    cursor:pointer;
}
.selectedActive {
  border-top: 3px solid #0095b9;
  padding-top: 7px;
}
.viewHolidaySideBar{
  max-height: 440px;
  min-height: 435px;
  border-right: 1px solid #9e9e9e36
}



  `],
  encapsulation: ViewEncapsulation.None
})
export class HolidaysPageComponent {


  @HostListener('click', ['$event']) onClick(e: any) {
    var targetEle = e.target.id;
    targetEle = targetEle.split('-');
    if (targetEle[0] == "eventControl") {
      let holidayId = targetEle[2];
      let eventAction = targetEle[1];
      this.eventActionControl = {
        holidayId: holidayId
      }
      if (eventAction == 'del') {
        this.deleteEvent();
      } else if (eventAction == 'edit') {
        this.getEventdata(this.eventActionControl); //edit action is initiated.
      } else if (eventAction == 'add') {
        this.bootstrapDate.datetime({
          component: 'eventCreatedate'
        });
        $('#addEventModal').modal('show');
      } else if (eventAction == 'clickAdd') {
        this.showAddNewHoliday = true;
        this.showAddNewclassHoliday = false;
        this.viewClassHoliday = false;
        this.initNewCalendar();
      } else if (eventAction == 'clickViewClass') {
        this.showAddNewHoliday = false;
        this.showAddNewclassHoliday = false;
        this.viewClassHoliday = true;
        setTimeout(() => {
          $('#filterHolidayDate').datetimepicker({
            // format: 'Y-m-d',
            format: 'd-m-Y',

            closeOnDateSelect: true,
            timepicker: false
          });
        }, 200)

        this.getClassHolidayList();
      } else if (eventAction == 'clickAddClass') {
        this.showAddNewclassHoliday = true;
        this.showAddNewHoliday = false;
        this.viewClassHoliday = false;
        setTimeout(() => {
          $('#eventStartdate').datetimepicker({
            format: 'H:i',
            datepicker: false,
            defaultTime: '00:00',
            timepicker: true,
          });
          $('#eventEnddate').datetimepicker({
            format: 'H:i',
            datepicker: false,
            defaultTime: '23:59',
            timepicker: true,
          });
        }, 200);
        this.initNewCalendar();
      }
    }
  }


  private apiToken: any = localStorage.getItem('scbToken');
  public eventList: any = {};
  public activeHolidays: any = {}; //for current month holidays.
  public eventActionControl: any = {}; //handles event delete & edit obj.
  public editEventName: any;
  public editEventSummary: any;
  public editEventDate: any;
  public editEventSuccess: boolean;
  public addEventSuccess: boolean;
  public editEventError: boolean;
  public addEventError: boolean;
  public addEventName: any;
  public UniversalHolidayDetails: any[];
  public showAddNewclassHoliday: boolean;
  public viewClassHoliday: boolean;
  public holidayClass: string;
  public classHolidayList: any[];
  public filterMemberClass: string;
  public globalFilterObj: any;
  public showDateError: boolean;
  /* Varibles for new event add module start */
  public showAddNewHoliday: boolean = false;
  public olderDates: boolean = false;
  public selectedDates: any[];
  public holidaySummary: string;
  public holidaySummaryError: boolean;
  public chooseClassError: boolean;

  public OrgClasses: any[];

  /* Varibles for new event add module end */



  ngOnInit() {
    this.authGaurd.checkToken();
    this.getAvailableClasses();

  }


  getAvailableClasses() {
    let apiHeader = {
      data: {
        key: localStorage.getItem('scbToken')
      }
    }
    this.apiService.getCurrentOrgDetails(apiHeader).then(response => {
      if (response.response) {
        this.OrgClasses = response.response.classLists;
        // this.orgSections = response.response.SectionLists;
      }
    });
  }

  constructor(public apiService: ApiService, public bootstrapDate: bootstrapDateTime, public authGaurd: authGaurd,public dateChange:ApiCallDateFormatService) {
    this.showAddNewclassHoliday = false;
    this.viewClassHoliday = false;
    this.classHolidayList = [];
    this.filterMemberClass = null;
    this.showDateError = false;
    this.globalFilterObj = {};
    this.getHolidaysList(); //fetching org holidays list.
  }

  getHolidaysList() {
    let dt = new Date();
    let apiHeader = {
      data: {
        key: this.apiToken,
        filter: (dt.getMonth() + 1) + "-" + dt.getFullYear()
      }
    };
    this.apiService.getOrgHolidays(apiHeader).then(response => {
      this.activeHolidays = response.holidays;
 
      //Initialize calendar.
      this.initCalendar();

    }).catch(error => { });
  }

  initCalendar() {
    let parent: any = this;
    $(function () {
      function c() {
        p();
        var e = h();
        var r = 0;
        var u = false;
        l.empty();
        while (!u) {
          if (s[r] == e[0].weekday) {
            u = true
          } else {
            l.append('<div class="blank"></div>');
            r++
          }
        }
        for (var c = 0; c < 42 - r; c++) {
          if (c >= e.length) {
            l.append('<div class="blank"></div>')
          } else {
            var v: any = e[c].day;
            var month: any = new Date(t, n - 1, v).getMonth();
            var year = new Date(t, n - 1, v).getFullYear();
            var m = g(new Date(t, n - 1, v)) ? '<div class="today">' : "<div>";
            month += 1;
            if (month < 10) month = '0' + month;
            if (v < 10) v = '0' + v;
            var concat = year + "-" + month + "-" + v;
            l.append(m + "" + v + "<div id=" + concat + " data-id=" + concat + " class='monthDay eventsPrint e-day-" + concat + "'></div></div>");
          }
        }
        var y = o[n - 1];
        a.css("background-color", y).find("h1").text(i[n - 1] + " " + t);
        f.find("div").css("color", y);
        l.find(".today").css("background-color", y);
        d()
      }

      function h() {
        var e = [];
        for (var r = 1; r < v(t, n) + 1; r++) {
          e.push({
            day: r,
            weekday: s[m(t, n, r)]
          })
        }
        return e
      }

      function p() {
        f.empty();
        for (var e = 0; e < 7; e++) {
          f.append("<div>" + s[e].substring(0, 3) + "</div>")
        }
      }

      function d() {
        var t;
        var n = $("#calendar").css("width", e + "%");
        n.find(t = "#calendar_weekdays, #calendar_content").css("width", e + "%").find("div").css({
          width: e / 7 + "%",
          height: e / 2 + "px",
          "line-height": e / 3.5 + "px"
        });
        n.find("#calendar_header").css({
          height: 75 + "px"
        }).find('i[class^="icon-chevron"]').css("line-height", e * (1 / 7) + "px")
      }

      function v(e: any, t: any) {
        return (new Date(e, t, 0)).getDate()
      }

      function m(e: any, t: any, n: any) {
        return (new Date(e, t - 1, n)).getDay()
      }

      function g(e: any) {
        return y(new Date) == y(e)
      }

      function y(e: any) {
        return e.getFullYear() + "/" + (e.getMonth() + 1) + "/" + e.getDate()
      }

      function b() {
        var e = new Date;
        t = e.getFullYear();
        n = e.getMonth() + 1
      }
      var e = 100;
      var t = 2013;
      var n = 9;
      var r = [];
      var i = ["JANUARY", "FEBRUARY", "MARCH", "APRIL", "MAY", "JUNE", "JULY", "AUGUST", "SEPTEMBER", "OCTOBER", "NOVEMBER", "DECEMBER"];
      var s = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
      var o = ["#16a085", "#1abc9c", "#c0392b", "#27ae60", "#FF6860", "#f39c12", "#f1c40f", "#e67e22", "#2ecc71", "#e74c3c", "#d35400", "#2c3e50"];
      var u = $("#calendar");
      var a = u.find("#calendar_header");
     
      ///=============================================================================================================================

      eventUpdater(parent.activeHolidays);

      //==============================================================================================================================

      var f = u.find("#calendar_weekdays");
      var l = u.find("#calendar_content");
      b();
      c();

      a.find('i[class^="icon-chevron"]').on("click", function () {
        var e = $(this);
        var r = function (e: any) {
          n = e == "next" ? n + 1 : n - 1;
          if (n < 1) {
            n = 12;
            t--
          } else if (n > 12) {
            n = 1;
            t++
          }
          c()
        };
        if (e.attr("class").indexOf("left") != -1) {
          r("previous")
        } else {
          r("next")
        }
        ///=============================================================================================================================

        eventUpdater(parent.activeHolidays);

        //==============================================================================================================================
      });

      $('.targetHoliday').on('click', function () {
        $(this).attr('data-id');
      });
    })

    function eventUpdater(activeHolidays: any) {
      let iM = ["empty", "JANUARY", "FEBRUARY", "MARCH", "APRIL", "MAY", "JUNE", "JULY", "AUGUST", "SEPTEMBER", "OCTOBER", "NOVEMBER", "DECEMBER"];
      setTimeout(() => {
        let cald = $('#calendar_header h1').html();
        cald = cald.split(" ");
        let MM = cald[0]; let YY = cald[1];
        let curMonth = iM.indexOf(MM);
        let dateObj = new Date();
        let currentMonth = (dateObj.getMonth() + 1);
        if ((curMonth) && (curMonth == currentMonth)) {
          
          $(".eventsPrint").html(''); //clearing all event display
          if (cald[0] && YY == YY) {
            createEvtsOnCalendar(activeHolidays);
          }
        } else {
          let nextDate = `${curMonth}-${YY}`;
          let apiHeader = {
            data: {
              key: parent.apiToken,
              filter: nextDate
            }
          };
          parent.apiService.getOrgHolidays(apiHeader).then((response: any) => {
            parent.activeHolidays = response.holidays;
            createEvtsOnCalendar(parent.activeHolidays);
          }).catch((error: any) => { });

        }
        parent.intializeEventsOverLay(activeHolidays);
      });
    }

    function createEvtsOnCalendar(activeHolidays: any) {
      for (let id in activeHolidays) {
        var eventColor = 'rgb(' + (Math.floor(Math.random() * 256)) + ',' + (Math.floor(Math.random() * 256)) + ',' + (Math.floor(Math.random() * 256)) + ')';
        var colorJson = {
          eventColor: eventColor
        }
        var eventDate = activeHolidays[id].date.date.split(' ');
        Object.assign(activeHolidays[id], colorJson);
        $(".e-day-" + eventDate[0]).html("<ul class='list-unstyled'><li class='HolidayItem' style='border:6px solid " + colorJson.eventColor + "'></li></ul>")
      }
    }
  }

  intializeEventsOverLay(events: any) {
    var eventsExists = false;
    $('.events-list').html('');
    for (let id in events) {
      var eventDate = events[id].date.date.split(' ');
      if ($('.e-day-' + eventDate[0]).length) { //If there are events for a month then display in sidebar.
        var list = '<li class="eventDetails" style="border-left:4px solid ' + events[id].eventColor + '">'
          + '<div class="pull-left eventName text-left" style="font-size:18px;text-transform: uppercase;">' + events[id].summary + '</div>'
          + '<div class="event-basic-info">'
          + '<div class="eventInfo pull-left"><i class="fa fa-clock-o mr5" aria-hidden="true"></i>' + this.dateChange.dateConversionForApicalls(eventDate[0],'onlydate') + '</div></div>'
          + '<div class="event-controls" style="padding-right:20%"><div class="event-delete pull-right" style="cursor:pointer"><i class="fa fa-trash mr5 delete" title="delete" id="eventControl-del-' + events[id].holidayId + '" aria-hidden="true"></i></div><div class="event-edit pull-right" style="margin-right:5%;cursor:pointer" data-id="' + events[id].holidayId + '"><i title="edit" class="fa fa-pencil mr5 edit" id="eventControl-edit-' + events[id].holidayId + '" aria-hidden="true"></i></div></div></li>';
        $('.events-list').append(list);
        eventsExists = true;
      }
    }
    if (!eventsExists) {
      let eleHtml = `<li class="list-group-item text-center"><h4 class="animated fadeIn text-center"  style="animation-duration: 1s;color:#999FBF"><i class="fa fa-frown-o"></i>&nbsp;No Events to Display</h4></li>`
      $('.events-list').append(eleHtml);
    }
  }

 
  addEvent() {
   

 
    var apiHeader = {
      "data": {
        key: this.apiToken,
        form: {
          "holidayLists": {
            0: {
              "date": $('#eventCreatedate').val(),
              "summary": this.addEventName
            }
          }
        }
      }
    }
  
    this.apiService.addOrgHoliday(apiHeader).then(response => {
     
      this.addEventSuccess = true;
      this.getHolidaysList(); //updating the entire holiday list.
      setTimeout(() => {
        $('#eventCreatedate').modal('hide');
        this.addEventSuccess = false;
      }, 1500)
    }).catch(error => { });
  }

  // deleteEvent() {
  //   var eventObj = {
  //     data: {
  //       key: this.apiToken,
  //       form: {
  //         holidayId: this.eventActionControl.holidayId
  //       }
  //     }
  //   };
  //   swal({
  //     title: 'Are you sure?',
  //     text: "The Selected Event Will be removed",
  //     type: 'warning',
  //     timer: 10000,
  //     reverseButtons:true,
  //     showCancelButton: true,
  //     confirmButtonColor: '#3085d6',
  //     cancelButtonColor: '#e04d76',
  //     confirmButtonText: 'Yes, Remove it!',
  //     cancelButtonText: 'No, keep it'
  //   }).then(()=>{
  //   this.apiService.deleteOrgEvent(eventObj).then(response => {
  //     var parent: any = this;
  //     if (response) {
  //       swal(
  //         'Removed!',
  //         'Event has been Removed',
  //         'success'
  //       ).done();
  //       setTimeout( ()=>{ swal.close();}, 1000);
  //       this.getHolidaysList(); //updating the entire holiday list.
  //     };
  //   //  $('#deleteEventModal').modal('hide');
  //   }).catch(error => { });
  // },    function(dismiss:any) {
  //     if (dismiss === 'cancel') {
  //       swal(
  //         'Cancelled',
  //         'Cancelled :)',
  //         'error'
  //       ).done();
  //       setTimeout( ()=>{ swal.close();}, 1000);
  //     }
  //   });
  // }

  deleteEvent() {
    const parent = this;
    let eventObj = {
      data: {
        key: this.apiToken,
        form: {
          holidayId: this.eventActionControl.holidayId
        }
      }
    };
    swal({
      title: 'Are you sure?',
      text: 'The Selected Event Will be removed',
      icon: 'warning',
      buttons: [
        'No, cancel it!',
        'Yes, I am sure!'
      ],
      dangerMode: true,
    }).then(function (isConfirm: any) {
      if (isConfirm) {
        parent.apiService.deleteOrgEvent(eventObj).then(response => {
          if (response) {
            parent.getHolidaysList(); // updating the entire holiday list.
          };
        }).catch(error => { });
      } else {
      }
    });
  }

  getEventdata(eventObj: any) {
    let apiHeader = {
      data: {
        key: this.apiToken,
        filter: {
          holidayId: eventObj.holidayId
        }
      }
    };
    this.apiService.getOrgHolidays(apiHeader).then(response => {
      let responseEvnt = response.holidays;
      let filteredEvt = responseEvnt.filter(function (el: any) { return el.holidayId == eventObj.holidayId });
      this.editEventName = filteredEvt[0].summary;
      this.editEventDate = filteredEvt[0].date.date.split(' ')[0];
      this.editEventDate = this.editEventDate.split('-').reverse().join('-');
     
      this.bootstrapDate.datetime({
        component: 'eventEditdate',
        // maxDate: 1
        closeOnDateSelect: true,
        minDate:true,
        maxDate:0,
      });
     
      $('#editEventModal').modal('show');
    }).catch(error => { 
      console.error(error);
    });
  }

  restructureDate(){
    let date = this.dateChange.dateConversionForApicalls($('#eventEditdate').val(),'onlydate');
    date = date.split('-');
    date = `${date[1]}-${date[0]}-${date[2]}`;
  }

  editEvent() {



    let dateRestruct = this.dateChange.dateConversionForApicalls($('#eventEditdate').val(),'onlydate');
    dateRestruct = dateRestruct.split('-');
    dateRestruct = `${dateRestruct[1]}-${dateRestruct[2]}-${dateRestruct[0]}`;

    let apiHeader = {
      data: {
        key: this.apiToken,
        form: {
          holidayId: this.eventActionControl.holidayId,
          // date: this.dateChange.dateConversionForApicalls($('#eventEditdate').val(),'onlydate'),
          date:dateRestruct,
          summary: this.editEventName
        }
      }
    }

    
   
    this.apiService.editOrgHoliday(apiHeader).then(response => {
      this.eventActionControl = {};
      this.activeHolidays = {};
      this.editEventSuccess = true;
      this.getHolidaysList(); //updating the entire holiday list.
      setTimeout(() => {
        $('#editEventModal').modal('hide');
        this.editEventSuccess = false;
      }, 1500)
    }).catch(error => { });
  }

  openModel() {

  }


  initNewCalendar() {
    let parent: any = this;
    $(function () {
      function c() {
        p();
        var e = h();
        var r = 0;
        var u = false;
        l.empty();
        while (!u) {
          if (s[r] == e[0].weekday) {
            u = true
          } else {
            l.append('<div class="blank"></div>');
            r++
          }
        }
        for (var c = 0; c < 42 - r; c++) {
          if (c >= e.length) {
            l.append('<div class="blank"></div>')
          } else {
            var v: any = e[c].day;
            var month: any = new Date(t, n - 1, v).getMonth();
            var year = new Date(t, n - 1, v).getFullYear();
            var m = g(new Date(t, n - 1, v)) ? '<div class="today">' : "<div>";
            month += 1;
            if (month < 10) month = '0' + month;
            if (v < 10) v = '0' + v;
            var concat = year + "-" + month + "-" + v;
           
            let dataId = concat;
            l.append(m + "" + v + "<div id=" + concat + " data-id=" + concat + " class='monthDay eventsPrint e-day-" + concat + "'></div></div>");
            $('#' + concat).parent().attr('data-id', concat);
            $('.eventsPrint').parent().addClass('targetHoliday').css('cursor', 'pointer');
          }
        }
        var y = o[n - 1];
        a.css("background-color", y).find("h1").text(i[n - 1] + " " + t);
        f.find("div").css("color", y);
        l.find(".today").css("background-color", y);
        d()
      }

      function h() {
        var e = [];
        for (var r = 1; r < v(t, n) + 1; r++) {
          e.push({
            day: r,
            weekday: s[m(t, n, r)]
          })
        }
        return e
      }

      function p() {
        f.empty();
        for (var e = 0; e < 7; e++) {
          f.append("<div>" + s[e].substring(0, 3) + "</div>")
        }
      }

      function d() {
        var t;
        var n = $("#calendar").css("width", e + "%");
        n.find(t = "#calendar_weekdays, #calendar_content").css("width", e + "%").find("div").css({
          width: e / 7 + "%",
          height: e / 2 + "px",
          "line-height": e / 3.5 + "px"
        });
        n.find("#calendar_header").css({
          height: 75 + "px"
        }).find('i[class^="icon-chevron"]').css("line-height", e * (1 / 7) + "px")
      }

      function v(e: any, t: any) {
        return (new Date(e, t, 0)).getDate()
      }

      function m(e: any, t: any, n: any) {
        return (new Date(e, t - 1, n)).getDay()
      }

      function g(e: any) {
        return y(new Date) == y(e)
      }

      function y(e: any) {
        return e.getFullYear() + "/" + (e.getMonth() + 1) + "/" + e.getDate()
      }

      function b() {
        var e = new Date;
        t = e.getFullYear();
        n = e.getMonth() + 1
      }
      var e = 100;
      var t = 2013;
      var n = 9;
      var r = [];
      var i = ["JANUARY", "FEBRUARY", "MARCH", "APRIL", "MAY", "JUNE", "JULY", "AUGUST", "SEPTEMBER", "OCTOBER", "NOVEMBER", "DECEMBER"];
      var s = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
      var o = ["#16a085", "#1abc9c", "#c0392b", "#27ae60", "#FF6860", "#f39c12", "#f1c40f", "#e67e22", "#2ecc71", "#e74c3c", "#d35400", "#2c3e50"];
      var u = $("#calendar");
      var a = u.find("#calendar_header");
      
      ///=============================================================================================================================

      // eventUpdater(parent.activeHolidays);

      //==============================================================================================================================

      var f = u.find("#calendar_weekdays");
      var l = u.find("#calendar_content");
      b();
      c();

      a.find('i[class^="icon-chevron"]').on("click", function () {
        var e = $(this);
        var r = function (e: any) {
          n = e == "next" ? n + 1 : n - 1;
          if (n < 1) {
            n = 12;
            t--
          } else if (n > 12) {
            n = 1;
            t++
          }
          c()
        };
        if (e.attr("class").indexOf("left") != -1) {
          r("previous")
        } else {
          r("next")
        }
        bindChangeEvt();
        ///=============================================================================================================================

        //eventUpdater(parent.activeHolidays);

        //==============================================================================================================================
      });
      bindChangeEvt();
      function bindChangeEvt() {
        parent.selectedDates = [];
        parent.olderDates=false;
        $('.targetHoliday').on('click', function () {
          let eventDate = $(this).attr('data-id');
          var todayDate = new Date();
var todayDateFormat = todayDate.getFullYear() + "-" + (todayDate.getMonth()+1) + "-" + todayDate.getDate();
//alert(eventDate +" ## "+todayDateFormat);

if(Date.parse(eventDate) < Date.parse(todayDateFormat)){
  
  parent.olderDates=true;
  setTimeout(function(){ $("#olderDates").fadeOut();parent.olderDates=false }, 3000);
 //alert(parent.olderDates);
///  $("#olderDates").fadeOut(3000);
}else{ 
  parent.olderDates=false;
  //this.olderDates=false; 
  if ($(this).hasClass('itemSelected')) { // handler too de-select date for holiday.
    $(this).removeClass('itemSelected');
    $(this).children(".e-day-" + eventDate).children().remove('ul.list-unstyled');
    parent.selectedDates.splice(parent.selectedDates.indexOf(parseDate(eventDate)), 1);
  } else { //handler to select date for holiday.
    $(this).addClass('itemSelected');
    $(this).children(".e-day-" + eventDate).html("<ul class='list-unstyled'><li class='HolidayItem' style='border:6px solid #e91e63'></li></ul>");
    parent.selectedDates.push(parseDate(eventDate));
    
  }
}
         
          
        });
      }
      function parseDate(param: any) {
        let date = param.split('-');
        return `${date[2]}-${date[1]}-${date[0]}`;
        // return `${date[1]}-${date[2]}-${date[0]}`;
      }
    })

    function eventUpdater(activeHolidays: any) {
      let iM = ["empty", "JANUARY", "FEBRUARY", "MARCH", "APRIL", "MAY", "JUNE", "JULY", "AUGUST", "SEPTEMBER", "OCTOBER", "NOVEMBER", "DECEMBER"];
      setTimeout(() => {
        let cald = $('#calendar_header h1').html();
        cald = cald.split(" ");
        let MM = cald[0]; let YY = cald[1];
        let curMonth = iM.indexOf(MM);
        let dateObj = new Date();
        let currentMonth = (dateObj.getMonth() + 1);
        if ((curMonth)) {
          $(".eventsPrint").html(''); //clearing all event display
          if (cald[0] && YY == YY) {
            for (let id in activeHolidays) {
              var eventColor = 'rgb(' + (Math.floor(Math.random() * 256)) + ',' + (Math.floor(Math.random() * 256)) + ',' + (Math.floor(Math.random() * 256)) + ')';
              var colorJson = {
                eventColor: eventColor
              }
              var eventDate = activeHolidays[id].date.date.split(' ');
              Object.assign(activeHolidays[id], colorJson);
              $(".e-day-" + eventDate[0]).html("<ul class='list-unstyled'><li class='HolidayItem' style='border:6px solid " + colorJson.eventColor + "'></li></ul>")
            }
          }
        }
        parent.intializeEventsOverLay(activeHolidays);
      });
    }


  }

  createNewHoliday() {
    if (this.initiateValidator()) {
     
      let evetObj = [];
      for (let dateIndex of this.selectedDates) { 
        let date = dateIndex.split('-');
        date = `${date[1]}-${date[0]}-${date[2]}`;
        dateIndex = date;
     
        let obj = {
          date: dateIndex,
          summary: this.holidaySummary
        }
        evetObj.push(obj);
      }

      let apiHeader = {
        data: {
          key: localStorage.getItem('scbToken'),
          form: {
            holidayLists: Object.assign({}, evetObj)
          }
        }
      }
   
      this.apiService.addOrgHoliday(apiHeader).then(response => {
        if (response.status == "success") {
          swal("Success", "Events Added Successfully", "success", {
            timer: 2000
          });
        } else {
          swal("Failure", 'Error while adding event', "error");
        }
        this.resetFormControls();
      }).catch(error => {

      })
    }

  }

  initiateClasswiseValidator() { //Validatior by Deepak
    let isValid: boolean = true;
    let startDate = $('#eventStartdate').val();
    let endDate = $('#eventEnddate').val();
    this.showDateError = false;
    if (!this.holidayClass) {
      this.chooseClassError = true;
      isValid = false;
    } else {
      this.chooseClassError = false;
    }
    if ((startDate) && (endDate)) {
      if (startDate > endDate) {
        this.showDateError = true;
        isValid = false;
      } else {
        this.showDateError = false;
      }
    } else {
      this.showDateError = true;
      isValid = false;
    }

    return isValid;
  }

  createClassHoliday() { //by rakesh
    if (this.initiateClasswiseValidator()) {
      let eventStartDate = this.bootstrapDate.getInputvalue('eventStartdate');
      let eventEnddate = this.bootstrapDate.getInputvalue('eventEnddate');
      let holidayDatesInfo = this.selectedDates.map((index) => {
        index = index.split('-').reverse().join('-');
        
      
        // let d = this.dateChange.dateConversionForApicalls(index,'onlydate');
       
        // let str = d.getFullYear() + "-" + ('0' + (d.getMonth() + 1)).slice(-2) + "-" + ('0' + d.getDate()).slice(-2);
       
        let obj = {
          date: index,
          // date:'2019-03-14',
          startTime: eventStartDate,
          endTime: eventEnddate
        }
      
        return obj;
      });
      let apiHeader = {
        data: {
          key: localStorage.getItem('scbToken'),
          form: {
            holidayDates: holidayDatesInfo,
            memberClass: this.holidayClass
          }
        }
      }
      this.apiService.addClassHoliday(apiHeader).then(response => {
        if (response.status == "success") {
          swal("Success", "Holiday Added Successfully", "success", {
            timer: 2000
          });
        } else {
          swal("Failure", 'Error while adding Holiday', "error");
        }
      }).catch(error => { })
    }

  }
  getClassHolidayList() {
   
    
    let apiHeader = {
      data: {
        key: localStorage.getItem('scbToken'),
      }
    }
    let isFilterApplied = Object.keys(this.globalFilterObj).length;
    if (isFilterApplied) {
    
      apiHeader['data']['filter'] = this.globalFilterObj;
    }
    this.apiService.viewClassHoliday(apiHeader).then(response => {
      if (response.classholidays) {
        this.classHolidayList = response.classholidays;
        this.globalFilterObj = {};
      } else {this.classHolidayList = []; 
        this.globalFilterObj = {};
      }
    }).catch(error => {this.globalFilterObj = {}; })

  }
  setClassHolidayFilters() {
    this.globalFilterObj = {};
    let selecteddate = this.bootstrapDate.getInputvalue('filterHolidayDate');
    if (this.filterMemberClass) this.globalFilterObj['memberClass'] = this.filterMemberClass;
    if (selecteddate) this.globalFilterObj['date'] = this.dateChange.dateConversionForApicalls(selecteddate,'onlydate');
    
    this.getClassHolidayList();
  }

  // removeClassHoliday(classHolidayId:any){
  //     let apiHeader = {
  //         data:{
  //             key:localStorage.getItem('scbToken'),
  //             form:{
  //                 classholidayId:Object.assign([],[classHolidayId])
  //             }
  //         }
  //     };
  //     swal({
  //       title: 'Are you sure?',
  //       text: "The Selected Event Will be removed",
  //       type: 'warning',
  //       timer: 10000,
  //       reverseButtons:true,
  //       showCancelButton: true,
  //       confirmButtonColor: '#3085d6',
  //       cancelButtonColor: '#e04d76',
  //       confirmButtonText: 'Yes, Remove it!',
  //       cancelButtonText: 'No, keep it'
  //     }).then(()=>{
  //     this.apiService.deleteClassHoliday(apiHeader).then(response => {

  //         var parent: any = this;
  //         if (response) {
  //           swal(
  //             'Removed!',
  //             'Event has been Removed',
  //             'success'
  //           ).done();
  //           setTimeout( ()=>{ swal.close();}, 1000);
  //           this.getClassHolidayList(); //updating the entire holiday list.
  //         };
  //       //  $('#deleteEventModal').modal('hide');
  //       }).catch(error => { });
  //       },    function(dismiss:any) {
  //         if (dismiss === 'cancel') {
  //           swal(
  //             'Cancelled',
  //             'Cancelled :)',
  //             'error'
  //           ).done();
  //           setTimeout( ()=>{ swal.close();}, 1000);
  //         }
  //       });
  // }

  removeClassHoliday(classHolidayId: any) {
    const parent = this;
    let apiHeader = {
      data: {
        key: localStorage.getItem('scbToken'),
        form: {
          classholidayId: Object.assign([], [classHolidayId])
        }
      }
    };

    swal({
      title: 'Are you sure?',
      text: 'The Selected Event Will be removed',
      icon: 'warning',
      buttons: [
        'No, cancel it!',
        'Yes, I am sure!'
      ],
      dangerMode: true,
    }).then(function (isConfirm: any) {
      if (isConfirm) {
        parent.apiService.deleteClassHoliday(apiHeader).then(response => {
          if (response) {
            parent.getClassHolidayList(); // updating the entire holiday list.
          };
        }).catch(error => { });
      } else {
      }
    });

  }

  resetFormControls() { // By Deepak
    this.holidaySummary = '';
    this.selectedDates = [];
  }

  initiateValidator() { // Validatior by Deepak
    let isValid: boolean = true;
    if (!this.holidaySummary) {
      this.holidaySummaryError = true;
      isValid = false;
    } else {
      this.holidaySummaryError = false;
    }
    return isValid;
  }

}
