/**
 * @Author: Deepak Pookkote <group10>
 * @Date:   2018-04-24T11:51:53+05:30
 * @Email:  deepak@groupten.com
 * @Project: School-Bus
 * @Filename: orgSettings.ts
 * @Last modified by:   group10
 * @Last modified time: 2018-05-09T12:54:45+05:30
 * @Copyright: Group10 Technologies
 */



/*Auther:-Deepak */
import { Component } from '@angular/core';
import {Input, Output, EventEmitter,
     ElementRef,
     AfterViewInit,
     OnDestroy,
     ViewChild,
 } from '@angular/core';
  import { RouterModule, Routes, Router } from '@angular/router';
  import { AppComponent } from './../../../app.component';

  import { globalService } from './../../../services/global.service';
  import { ApiService } from './../../../services/ApiService';
  import { authGaurd } from './../../../services/auth-guard.service';

  declare var swal: any;
  declare var $: any;

@Component({
  // template: ``,
   templateUrl: './orgSettings.component.html',

   styles: [
     `

    .Windowactive {
      border-top: 3px solid #0095b9;
      padding-top: 7px;
    }

    `],

})
export class OrgSettings {


  constructor(public authGaurd :authGaurd,private apiService: ApiService) {
      this.showMobileConfigurations = false;
      this.showEmailConfigurations = false;
  }


  public showNotificationSettings: boolean = false;
  public showAlertSettinsgs: boolean = false;
  public viewNotifications:boolean = true;
  public editNotification:boolean = false;
  public showEditNotificationForm: boolean = false;
  public showMobileConfigurations:boolean;
  public showEmailConfigurations:boolean;
  public clickedNotifId:any;

  ngOnInit() {
    this.authGaurd.checkToken();
  }

  showEditNotificationFormDir($event: any):void {
    this.viewNotifications = false;
    this.clickedNotifId = $event.notifId;
    this.showEditNotificationForm = $event.showEditDirectivePerm;
  }

}
