import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'FilterGate'
})

export class FilterGate implements PipeTransform {
    transform(value: string,from:string, to: string = '', type:boolean=false): string {
   
        if(type==false){
           return value.replace(from, to);
        }

        if(type==true){ 
            return value.replace(new RegExp(from, 'g'), to);
        }
            
        }
    }