import { Component } from '@angular/core';

let incre=0;

@Component({

    selector:'inc',
    template:`

    {{inc}} 

    
    `
})

export class IncrementDir{
   inc:number;
   constructor(){
       this.inc=++incre;
   }    

   ngOnInit(){

   }

   ngOnDestroy(){
     this.inc=0;
     incre=0;
   }
}