
/*Auther:Deepak */
import {
    Component,
} from '@angular/core';
import { ApiService } from './../../../services/ApiService';

import { StorageService } from './../../../services/storage.service';

@Component({
    templateUrl: './tracker-info.html',
    styles: [`
    .field-icon {
            float: right;
            margin-left: -25px;
            margin-top: -25px;
            margin-right:5px;
            position: relative;
            z-index: 2;
            cursor:pointer;
        }
   `],
})
export class ViewOrgTrackerInfoComponent {

    private apiToken: any = localStorage.getItem("adminToken");
    public srchPrimaryImei: any = "";
    public pageJump: number = 0;
    trackerInfo: any;
    public PImei: string = '';
    srchBoxId: any;
    srchSimNum: any;
    srchVehicleName: any;
    isPImei = true;
    searchResults: any;

    constructor(public apiService: ApiService,
        private storage: StorageService
    ) { }

    ngOnInit() {

        this.viewTrackers();

    }
    filterFunction() {

        this.viewTrackers();

    }
    public TBoxId: any;
    public simNumber: any;
    public vehName: any;
    clearFilterval() {
        this.PImei = '';
        this.clearFilter();
    }

    clearFilter() {
        this.srchPrimaryImei = "";
        this.viewTrackers();
    }
    viewTrackers() {
        this.apiService.orgTrackerView({
            data: {
                key: this.apiToken,
                filter: {
                    imei: this.srchPrimaryImei,
                    boxid: this.srchBoxId,
                    simCard: this.srchSimNum,

                    regNo: this.srchVehicleName,

                },
                extra: {
                    orderByDateCreated: -1,
                    pageJump: this.pageJump
                }
            }
        }).then(response => {
            let result = response;
            if (result.status = "success") {
                this.trackerInfo = response.response;
            }
        }).catch(error => {
            console.log('Error while logging in', error);
        });
    }
}



