/**
 * @Author: Deepak Pookkote <group10>
 * @Date:   2018-04-03T15:32:27+05:30
 * @Email:  deepak@groupten.com
 * @Project: School-Bus
 * @Filename: excelUploadService.ts
 * @Last modified by:   group10
 * @Last modified time: 2018-05-08T14:35:17+05:30
 * @Copyright: Group10 Technologies
 */



import { Injectable } from '@angular/core';
import { ApiService } from './ApiService';
declare var $: any;
@Injectable()

export class excelUpload {
  getStudentRouteExcelUpload(apiKey: string, file: any, arg2: string, arg3: (apiResponse: any) => void) {
    throw new Error('Method not implemented.');
  }
  constructor(public apiService: ApiService) {

  }

  uploadFile(apiKey: any, formDataUpload: any, inputId: any, callback: any) {
    $('#' + inputId).val(''); //clear the input value for further uploads.
    const formData = new FormData();
    formData.append('key', apiKey);
    formData.append('image1', formDataUpload);
    $.ajax({
      url: this.apiService.base + 'memberdetails/excel_upload',//'upload.php',
      type: 'POST', data: formData,   // Tell jQuery not to process data or worry about content-type
      cache: false, contentType: false, processData: false, // You *must* include these options!
      complete: function (res: any) {
        if ($.isFunction(callback)) {
          callback(res.responseJSON)
        }
      },
    });
  }

  pickupPointsExcelUpload(apiKey: any, routeId: any, formDataUpload: any, inputId: any, callback: any) {
    $('#' + inputId).val(''); //clear the input value for further uploads.
    const formData = new FormData();
    formData.append('key', apiKey);
    formData.append('routeId', routeId);
    formData.append('image1', formDataUpload);
    $.ajax({
      url: this.apiService.base + 'memberdetails/pickup_excel_upload',//'upload.php',
      type: 'POST', data: formData,   // Tell jQuery not to process data or worry about content-type
      cache: false, contentType: false, processData: false, // You *must* include these options!
      complete: function (res: any) {
        if ($.isFunction(callback)) {
          callback(res.responseJSON);
        }
      }
    });
  }


  uploadRFIDfile(apiKey: any, formDataUpload: any, inputId: any, callback: any) {
    $('#' + inputId).val(''); //clear the input value for further uploads.
    const formData = new FormData();
    formData.append('key', apiKey);
    formData.append('image1', formDataUpload);
    $.ajax({
      url: this.apiService.base + 'memberdetails/member_rfid_upload',//'upload.php',
      type: 'POST', data: formData,   // Tell jQuery not to process data or worry about content-type
      cache: false, contentType: false, processData: false, // You *must* include these options!
      complete: function (res: any) {
        if ($.isFunction(callback)) {
          callback(res.responseJSON)
        }
      },
    });
  }

  UpdateStudentInfo(apiKey: any, formDataUpload: any, inputId: any, callback: any) {
    $('#' + inputId).val(''); //clear the input value for further uploads.
    const formData = new FormData();
    formData.append('key', apiKey);
    formData.append('image1', formDataUpload);
    $.ajax({
      url: this.apiService.base + 'memberdetails/member_detailsupdate_upload',//'upload.php',
      type: 'POST', data: formData,   // Tell jQuery not to process data or worry about content-type
      cache: false, contentType: false, processData: false, // You *must* include these options!
      complete: function (res: any) {
        if ($.isFunction(callback)) {
          callback(res.responseJSON)
        }
      },
    });
  }

  memberAssignUpload(apiKey: any, routeId: any, formDataUpload: any, inputId: any, callback: any) {

    $('#' + inputId).val(''); //clear the input value for further uploads.
    const formData = new FormData();
    formData.append('key', apiKey);
    formData.append('routeId', routeId);
    formData.append('image1', formDataUpload);
    $.ajax({
      url: this.apiService.base + 'memberdetails/memberassign_excel_upload',//'upload.php',
      type: 'POST', data: formData,   // Tell jQuery not to process data or worry about content-type
      cache: false, contentType: false, processData: false, // You *must* include these options!
      complete: function (res: any) {
        if ($.isFunction(callback)) {
          callback(res.responseJSON)
        }
      },
    });
  }

  callDataUpload(apiKey: any, formDataUpload: any, inputId: any, callback: any) {
    $('#' + inputId).val(''); //clear the input value for further uploads.
    console.log($('#' + inputId).val());
    const formData = new FormData();
    formData.append('key', apiKey);
    formData.append('image1', formDataUpload);
    $.ajax({
      url: this.apiService.base + 'report/unicel_response_upload',//'upload.php',
      type: 'POST', data: formData,   // Tell jQuery not to process data or worry about content-type
      cache: false, contentType: false, processData: false, // You *must* include these options!
      complete: function (res: any) {
        if ($.isFunction(callback)) {
          callback(res.responseJSON)
        }
      },
    });
  }
  studentRouteMappingUpload(apiKey: any, formDataUpload: any, inputId: any, callback: any) {

    $('#' + inputId).val(''); //clear the input value for further uploads.
    const formData = new FormData();
    formData.append('key', apiKey);
    formData.append('file', formDataUpload);
    $.ajax({
      url: this.apiService.base + 'Organization/student_route_excel_upload',//'upload.php',
      type: 'POST', data: formData,   // Tell jQuery not to process data or worry about content-type
      cache: false, contentType: false, processData: false, // You *must* include these options!
      complete: function (res: any) {
        if ($.isFunction(callback)) {
          callback(res.responseJSON)
        }
      },
    });
  }
}
