/**
 * @Author: Deepak Pookkote <group10>
 * @Date:   2018-04-24T11:51:53+05:30
 * @Email:  deepak@groupten.com
 * @Project: School-Bus
 * @Filename: filter.pipe.ts
 * @Last modified by:   group10
 * @Last modified time: 2018-05-22T12:38:30+05:30
 * @Copyright: Group10 Technologies
 */



/*Auther:Deepak */
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'FilterPipe',
})
export class FilterPipe implements PipeTransform {
    transform(value: any, input: string, speedFilterInput: number) {

      if ( value ) {
          return value.filter(function (el: any) {
            if (input && el !== undefined && el.VehicleNo !== undefined) {
              input = input.toLowerCase();
              const currentTime = Math.floor(Date.now() / 1000);
              const gpsTimeTemp = el.gpsTimestamp / 1000;
/*
              if(el.vehNo.toString().toLowerCase().indexOf(input) > -1)
                return el;
              else if(el.speed.toString().toLowerCase().indexOf(input) > -1)
                return el;
              else if(el.routeAssigned[0])
                if( el.routeAssigned[0].toString().toLowerCase().indexOf(input) > -1)
              // el.routeAssigned[0].toString().toLowerCase().indexOf(input) > -1
                return el;
              else if(el.routeAssigned[1])
                if(el.routeAssigned[1].toString().toLowerCase().indexOf(input) > -1)
                return el;
            */
          
           if ( el.VehicleNo.toLowerCase().indexOf(input) > -1)
           return el;
         else if( el && el.speed == value)
           return el;
         else if(el && el.routeAssigned !== undefined && el.routeAssigned[0])
           if( el && el.routeAssigned[0].toLowerCase().indexOf(input) > -1)
         // el.routeAssigned[0].toString().toLowerCase().indexOf(input) > -1
           return el;
         else if(el && el.routeAssigned !== undefined && el.routeAssigned[1])
           if(el.routeAssigned[1].toLowerCase().indexOf(input) > -1)
           return el;
              else if(input === 'offline'){
                
                if((currentTime - gpsTimeTemp) >= 1800) {
                  return  el ;
                }
              }
              else if( input === 'halt'){
                if(el.speed!=null && el.speed!=undefined && el.speed <= 5 && ((currentTime - gpsTimeTemp) < 1800) )
                  return el;
              }
              else if( input === 'moving'){
                if(el.speed!=null && el.speed!=undefined && el.speed > 6 && ((currentTime - gpsTimeTemp) < 1800) )
                  return el;
              }
            } else if(speedFilterInput > 0){
                if(el.speed!=null && el.speed!=undefined && el.speed >= speedFilterInput )
                  return el;
            } else {
              return el;
            }
          })
        }
      return value;
    }
}
