import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-terms-and-conditions',
  templateUrl: './terms-and-conditions.component.html',
  styleUrls: ['./terms-and-conditions.component.css']
})
export class TermsAndConditionsComponent implements OnInit {

  public disableAgreeBtn:boolean = true;
  constructor() { }

  
  ngOnInit() {
      // this.termsScroll();
  }

  termsScroll(){
      let cardEl:any = document.getElementById('terms-card');
      cardEl.addEventListener('scroll',(e:any)=>{
          let scrollTop = e.target.scrollTop;
          let clientHeight = e.target.clientHeight;
          let scrollHeight = e.target.scrollHeight;
          if(scrollTop >= (scrollHeight-clientHeight)) this.disableAgreeBtn = false; 
      })
  }
}
