import { TrackerData_Add, TrackerData_Load } from './../actions/tracker-data';


export function reducer( state: any = [], action: any ) {
    switch (action.type) {
        case TrackerData_Add: {
//          return [ ...state, action.payload.data ];
           // state[ action.payload.data.imei ] = action.payload.data;
           let brkFlag: boolean;
        (state).map( (itm: any, index: number) => {
        //  console.log('item',itm);
        //  console.log('action',action);
            if ( itm.imei === action.payload.data.imei ) {
               // state.splice(index, 1 );
               Object.keys( action.payload.data ).forEach( (key) => {
               //  console.log('key',key);
               //  console.log('...',state[index][key]);
                state[index][key] = action.payload.data[key];
               } );
               // state[index] = action.payload.data;
                brkFlag = true;
            }
      }  );
      if ( brkFlag ) { break; } else {
       // state.push( action.payload.data.iemi );
     // state[ state.length ][] = action.payload.data[key];
      }

     // state.push( action.payload.data );
        break;
          }
          case TrackerData_Load: {

               state = [];
              (action.payload.data).map( (itm: any) => {
                  state.push( itm );
              } );
            return state;
          }
          default: {
            return state;
          }
    }
}
