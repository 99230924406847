import { Injectable } from '@angular/core';
declare var google:any;
@Injectable()

export class googleMapService{
  public map:any;
  constructor(){
  }

  initializeGoogleMap(){
    //var mapStyle = this.globalService.defaultMapStyle();
    var styledMapType = new google.maps.StyledMapType(
         [
           {
             "elementType": "geometry",
             "stylers": [
               {
                 "color": "#f5f5f5"
               }
             ]
           },
           {
             "elementType": "labels.icon",
             "stylers": [
               {
                 "visibility": "off"
               }
             ]
           },
           {
             "elementType": "labels.text.fill",
             "stylers": [
               {
                 "color": "#616161"
               }
             ]
           },
           {
             "elementType": "labels.text.stroke",
             "stylers": [
               {
                 "color": "#f5f5f5"
               }
             ]
           },
           {
             "featureType": "administrative.land_parcel",
             "elementType": "labels.text.fill",
             "stylers": [
               {
                 "color": "#bdbdbd"
               }
             ]
           },
           {
             "featureType": "poi",
             "elementType": "geometry",
             "stylers": [
               {
                 "color": "#eeeeee"
               }
             ]
           },
           {
             "featureType": "poi",
             "elementType": "labels.text.fill",
             "stylers": [
               {
                 "color": "#757575"
               }
             ]
           },
           {
             "featureType": "poi.park",
             "elementType": "geometry",
             "stylers": [
               {
                 "color": "#e5e5e5"
               }
             ]
           },
           {
             "featureType": "poi.park",
             "elementType": "labels.text.fill",
             "stylers": [
               {
                 "color": "#9e9e9e"
               }
             ]
           },
           {
             "featureType": "road",
             "elementType": "geometry",
             "stylers": [
               {
                 "color": "#ffffff"
               }
             ]
           },
           {
             "featureType": "road.arterial",
             "elementType": "labels.text.fill",
             "stylers": [
               {
                 "color": "#757575"
               }
             ]
           },
           {
             "featureType": "road.highway",
             "elementType": "geometry",
             "stylers": [
               {
                 "color": "#dadada"
               }
             ]
           },
           {
             "featureType": "road.highway",
             "elementType": "labels.text.fill",
             "stylers": [
               {
                 "color": "#616161"
               }
             ]
           },
           {
             "featureType": "road.local",
             "elementType": "labels.text.fill",
             "stylers": [
               {
                 "color": "#9e9e9e"
               }
             ]
           },
           {
             "featureType": "transit.line",
             "elementType": "geometry",
             "stylers": [
               {
                 "color": "#e5e5e5"
               }
             ]
           },
           {
             "featureType": "transit.station",
             "elementType": "geometry",
             "stylers": [
               {
                 "color": "#eeeeee"
               }
             ]
           },
           {
             "featureType": "water",
             "elementType": "geometry",
             "stylers": [
               {
                 "color": "#c9c9c9"
               }
             ]
           },
           {
             "featureType": "water",
             "elementType": "labels.text.fill",
             "stylers": [
               {
                 "color": "#9e9e9e"
               }
             ]
           }
         ],{name: 'Styled Map'});

    // Create a map object, and include the MapTypeId to add
    // to the map type control.
     this.map = new google.maps.Map(document.getElementById('gMap'), {
       center: {lat: 55.647, lng: 37.581},
       zoom: 11,
       mapTypeControlOptions: {
         mapTypeIds: ['roadmap', 'satellite', 'hybrid', 'terrain',
                 'styled_map']
       }
    });

    //Associate the styled map with the MapTypeId and set it to display.
    this.map.mapTypes.set('styled_map', styledMapType);
    this.map.setMapTypeId('styled_map');
  }
}
