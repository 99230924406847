import { Injectable } from '@angular/core';
declare var $: any;
@Injectable()
export class bootstrapDateTime {
  datetime(args?: any){
    let component  = (args['component'] == '') ? 'datatime' : args['component'];
    let timepicker = (args['timepicker']) ? true : false;
    let format     = (args['format']) ? 'd-m-Y H:i' : 'd-m-Y ';
    let closeOnDateSelect = (args['closeOnDateSelect'] ? true : false);
    let minDate = (args['minDate']==true ? new Date():false);
 
    
    let pickerObj = {
        format: format,
        maxDate: 0,
        closeOnDateSelect: closeOnDateSelect,
        timepicker: timepicker,
        scrollMonth : false,
        scrollInput : false,
        minDate: minDate
    }

    //if mindate set maxdate property is deleted
    minDate && (pickerObj['maxDate'] = null,delete pickerObj['maxDate']);
    
  
   // if(!('maxDate'in args)) Reflect.set(pickerObj,'maxDate',0);
    $('#' + component).datetimepicker(pickerObj);
  }

  getInputvalue(ele: any) {
     return ($('#' + ele).val());
  }
}
