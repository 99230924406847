import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ApiCallDateFormatService {

  constructor() { 

  }
  dateConversionForApicalls(param1:string,format:string){
   
    
    let param:any="";
   
    if(param1.includes(' ') && param1.length<=10){
      if(format == 'onlydate'){
        param = param1.split('-').reverse().join('-');
        return param;
      }
      

    }else{
     
      param =  param1.split(' ');
    if(format == "onlydate"){
      let dateFormation = param[0].split('-').reverse().join('-')
      return dateFormation;
    }
    if(format == "date_time"){
      let dateFormation = param[0].split('-').reverse().join('-');
      let timeFormation = param[1].length>5 ? param[1] : param[1]+':00';

      return{
        date:dateFormation,
        time:timeFormation
      }
    }
   }
    
    
  }
}
