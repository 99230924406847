/**
 * @Author: Deepak Pookkote <group10>
 * @Date:   2018-05-08T17:15:45+05:30
 * @Email:  deepak@groupten.com
 * @Project: School-Bus
 * @Filename: mobileDashboard.ts
 * @Last modified by:   group10
 * @Last modified time: 2018-05-09T12:51:36+05:30
 * @Copyright: Group10 Technologies
 */



/*Auther:Deepak */
import {
  Component,
  ElementRef,
  AfterViewInit,
  OnDestroy,
  ViewChild,
} from '@angular/core';
import { RouterModule, Routes, Router } from '@angular/router';
import { AppComponent } from './../../app.component';

import { globalService } from './../../services/global.service';
import { ApiService } from './../../services/ApiService';
import { authGaurd } from './../../services/auth-guard.service'
import { StorageService } from './../../services/storage.service';
import { NG_VALIDATORS, Validator, Validators, AbstractControl, ValidatorFn } from '@angular/forms';

declare var swal: any

@Component({
  //template: ``,
  templateUrl: './mobileAppDashboard.component.html',
  styles: [`
    .gap-20> {
      padding: 10px!important;
    }
    .w-100 {
      width: 100%!important;
    }
    .bgc-white, .bgcH-white:hover {
      background-color: #fff!important;
    }
    .bd {
      border: 1px solid rgba(0,0,0,.0625)!important;
    }
    .p-20 {
      padding: 20px!important;
    }
    .layers {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
    }
    .layers {
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -ms-flex-flow: column nowrap;
      flex-flow: column nowrap;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      box-shadow: 1px 1px 0px 0px lightgrey;
    }
    .mB-10 {
      margin-bottom: 10px!important;
    }
    .lh-1 {
      line-height: 1!important;
    }
    h6 {
      letter-spacing: .5px;
      font-size: 14px;
    }
    .peer{
      font-size:25px;
    }
    .peerText {
      font-size:20px;
      background: #f7f7f7;
    }
    .pX-15 {
      padding-left: 15px!important;
      padding-right: 15px!important;
    }
    .pY-15 {
      padding-top:5px!important;
      padding-bottom:5px!important;
    }
    .bdrs-10em {
      border-radius: 10em!important;
    }

    .ViewDateLogButton{
        font-size: 1.6em;
        width: 1.6em;
        text-align: center;
        line-height: 1.6em;
        background: #2396b9;
        color: #fff;
        border-radius: 30px;
        width: 45px;
        height: 45px;
        position: fixed;
        z-index: 999;
        right: 20px;
        bottom: 20px;
        cursor:pointer
      }
    .ViewDateLogButton > i {
        margin:12px auto;
    }
    `],
})
export class MobileDashboardComponent {


  private apiToken: any = localStorage.getItem("adminToken");
  public pageJump: number = 0;
  public limitOfRecords: number;
  public appUsageCount: any = {};
  public historyLog: any = [];
  private showFilter: boolean = false;
  public showHostoryLog: boolean = true;
  public showDateWiseLog: boolean = false;
  public ostype: any = "";
  public srchMobilenum: any = "";
  public deviceFilterObj: any = {};




  /*Report Selection variables Ends */

  constructor(public globalService: globalService,
    public apiService: ApiService,
    public authGaurd: authGaurd,
    private storage: StorageService,
    public router: Router) {


  }
  private currentOrganization: any = this.storage.get('orgID');

  ngOnInit() {
    this.getParentAppInfo();
  }

  // filterFunction() {
  //   this.pageJump = 0;
  //   this.getParentAppInfo();
  //   this.ostype = "";
  //   this.srchMobilenum = "";
  // }

  filterFunction() {
    this.deviceFilterObj = {};
    if (this.ostype) {
      Reflect.set(this.deviceFilterObj, 'ostype', this.ostype);
    }

    if (this.srchMobilenum) Reflect.set(this.deviceFilterObj, 'mobileNo', this.srchMobilenum);
    this.getParentAppInfo();

  }


  getParentAppInfo = () => {
    let parent: any = this;
    var infoObj = {
      data: {
        key: this.apiToken,
        extra: {
          pageJump: this.pageJump
        }
      }
    };
    if (Object.keys(this.deviceFilterObj).length) {
      infoObj['data']['filter'] = {};
      infoObj['data']['filter'] = this.deviceFilterObj;
    }
    this.apiService.getparentAppDetails(infoObj).then(response => {
      if (response) {
        if (response != '' && response != undefined && response != null) {
          this.appUsageCount = response.response.orgAppUsageCounts;
          //console.log(response.response);
          this.historyLog = response.response.historyLog;
          // Cheking no of records
          if (this.pageJump == 0) {
            this.limitOfRecords = this.historyLog.length;
          }
        }
      };
    }).catch(error => { });
  }





}
