/**
 * @Author: Rakesh Roushan
 * @Date:   2018-03-21T15:59:00+05:30
 * @Email:  rakesh@groupten.com
 * @Project: SChool-Bus
 * @Filename: trackingReports.ts
 * @Last modified by:   group10
 * @Last modified time: 2018-05-11T15:39:24+05:30
 * @Copyright: Group10 Technologies
 */


import { Component, Input, Output } from '@angular/core';
import { ApiService } from './../../../services/ApiService';
import { chosenUtility } from './../../../services/chosen-utility/jq-chosen-utility.js';
import { bootstrapDateTime } from './../../../services/bootstrapDateTime/datePicker';
import './../../../../assets/webroot/js/markerClusterer.js';
//import { } from '@types/googlemaps';
import { animateMarker } from './../../../services/markerAnimate';
import { GlobalUtils } from './../../../services/global-utils';
import { globalService } from "./../../../services/global.service";
import { StorageService } from "./../../../services/storage.service";
import { VehicleServices } from "./../../../services/Vehicle-services";
import { UnixTimeStampFormatter } from '../../../custom-pipes/unixTimestamp-formatter';
import { ReturnStatement } from '@angular/compiler';
import { concatAll } from 'rxjs/operators';
import { COM } from './../../../services/com.services';
import { ApiCallDateFormatService } from '../../../services/api-call-date-format.service';

declare const $: any;
declare const Highcharts: any;
declare const MarkerClusterer: any;
declare const google: any;
declare const Pickathing: any;
@Component({
    providers: [bootstrapDateTime],
    selector: 'tracking-report-section',
    template: `
    <section class="tracking-report-section">
    <div class="row">
       <div class="col-lg-3 col-md-3 col-sm-5 col-xs-5 pull-left fixedHeight availableReportOptions">
           <div class="divHeading" style="margin-bottom: 2em;padding-top: 4%;">
               <span class="">Available Report</span><br>
               <div style="height: 1px;border: 1px solid #64c7e9;width: 3em;"></div>
           </div>
        
        <!-- <div *ngSwitchCase="'showSearch'"> -->
         <!--<span>#{{searchSelection}}</span><br> -->
         <div *ngIf="searchSelection=='showSearch'">
         <div class="form-group">
                       <label class="control-label">Report Types:</label>
                       <select class="custom-form-control" name="demo-select" id="demo-select">
                       <option style="display:none">Select an Option</option> 
                <!-- <option *ngIf="this.movementreportSet==true" value="movement" selected>Movement Log</option>
                   <option *ngIf="this.haltereportSet==true" value="halt" selected>Halt Log</option>--->
                   <option *ngFor="let option of reportOptions" value="{{option.value}}">{{option.reportName}}</option>
               </select>
                           

                   </div>
         <!--<div class="form-group" *ngIf="searchSelection=='showSearch'">
               <label class="control-label">Select Reporttttt </label>
               <select class="custom-form-control chosen-select" id="availableReportsDropdown">
                   <option value=""></option>
              
                   <option *ngFor="let option of reportOptions" value="{{option.value}}">{{option.reportName}}</option>
               </select>
           </div>-->
         </div>
         <div  *ngIf="searchSelection=='hideSearch'"> 
         <input
         
         class="custom-form-control" type="text" value="{{report}}"><span style="right: 2%;top: -27px;position:relative;cursor:pointer" class="pull-right" (click)="searchSelected($event);initChosen();"><i class="fa fa-close"></i></span>
         </div>
         
         

          <!-- <div class="form-group"><span>-{{searchSelection}}</span><br>
               <label class="control-label">Select Report </label>
               <select class="custom-form-control chosen-select" id="availableReportsDropdown">
                   <option value=""></option>
                 <option *ngIf="this.movementreportSet==true" value="movement" selected>Movement Log</option>
                   <option *ngIf="this.haltereportSet==true" value="halt" selected>Halt Log</option>
                   <option *ngFor="let option of reportOptions" value="{{option.value}}">{{option.reportName}}</option>
               </select>
           </div>-->
           <!-- <div class="form-group" *ngIf="this.searchSelection='hideSearch'">
           <input class="custom-form-control" type="text" value={{vehName}}><span style="right: 2%;top: -27px;position:relative;cursor:pointer" class="pull-right" (click)="searchSelected($event);"><i class="fa fa-close"></i></span></div> -->
           
           <!-- krish code starts here -->
           <section class="movementReportSection" *ngIf="reportSections.vehcoordinate">
               <div class="divHeading" style="margin-bottom: 1.5em;padding-top: 2.5%;">
                   <span class="">Available Options</span>
                   <div style="height: 1px;border: 1px solid #64c7e9;width: 3em;"></div>
               </div>

<!-- toggle of search and textbox -->        
                <div [ngSwitch]="this.searchSelection">
                    <div *ngSwitchCase="'showSearch'">
                        <label class="control-label">Vehicle Name<sup>*</sup></label>
                       
                        <div class="meager-e2e-tracking-report-movement-veh-name">
                            <single-search-select class="meager-e2e-tracking-report-movement-veh-name"
                            [searchType]="'device'"
                            [searchAPIUrl]="'getAllOrgDevices'"
                            [otherParams]="'resetSearchUtility'"
                            (emitUserSelections)="setUserSelection($event)">
                            </single-search-select>
                            <span class="text-danger" *ngIf="emptyVehicleError">Choose Vehicle </span>
                         </div>
                    </div>
                    <div *ngSwitchCase="'hideSearch'">
                       <div>    
                       <label class="control-label">Vehicle Name<sup>*</sup></label>
                        <input class="custom-form-control meager-e2e-tracking-report-movement-veh-name" type="text" value={{vehName}}><span style="right: 2%;top: -27px;position:relative;cursor:pointer" class="pull-right" (click)="searchSelected($event);"><i class="fa fa-close"></i></span>
                       </div>
                    </div>                   
                </div>
<!-- end of toggle of search and textbox -->     

                <div class="form-group" *ngIf="ifTbs">
                <label class="control-label">Choose Type</label>
                <select class="custom-form-control" (change)="choosenMovementFilter($event.target.value);">
                    <option value="box">Device</option>
                    <option value="tab">Tab</option>
                </select>
                </div>

               <div class="form-group">
                   <label class="control-label">Start Date:<sup>*</sup></label>
                   <input class="custom-form-control meager-e2e-tracking-report-movement-start-date" id="routeHistoryStartDate">
                   <span class="text-danger" *ngIf="movementstartDateError">Choose Start Date</span>
               <!--    <span class="text-danger" *ngIf="routesDatesMisMatchError">End Date should be greater than Start Date</span> -->
               </div>
               <div class="form-group">
                   <label class="control-label">End Date:<sup>*</sup></label>
                   <input class="custom-form-control meager-e2e-tracking-report-movement-end-date" id="routeHistoryEndDate">
                     <span class="text-danger" *ngIf="movementEndDateError">Choose End Date</span>
                     <span class="text-danger" *ngIf="routesDatesMisMatchError">End Date should be greater than Start Date</span>
               </div>
               <div class="form-group form-actions">
                   <button class="webform-submit button-primary btn btn-primary form-submit pull-right viewReportBtn meager-e2e-tracking-report-movement-view-btn" (click)="viewMovementLog('vehcoordinate',true)">View</button>
                   <span class="text-danger">{{displayErrorMessage}}</span>
               </div>
           </section>

<!-- krish code ends here -->

           <section class="daySummarySection" *ngIf="reportSections.daysummary">
               <div class="divHeading" style="margin-bottom: 1.5em;padding-top: 2.5%;">
                   <span class="">Available Options</span>
                   <div style="height: 1px;border: 1px solid #64c7e9;width: 3em;"></div>
               </div>
               <!-- toggle of search and textbox         
               <div [ngSwitch]="this.searchSelection">
               <div *ngSwitchCase="'showSearch'">
                   
                   <div class="">
                       <single-search-select
                       [searchType]="'orgDevices'"
                       [searchAPIUrl]="'getAllOrgDevices'"
                       [otherParams]="'resetSearchUtility'"
                       (emitUserSelections)="setUserSelection($event)">
                       </single-search-select>
                       <span class="text-danger" *ngIf="emptyVehicleError">Choose Vehicle </span>
                    </div>
               </div>
               <div *ngSwitchCase="'hideSearch'">
                  <div>    
                   <input class="custom-form-control" type="text" value={{vehName}}><span style="right: 2%;top: -27px;position:relative;cursor:pointer" class="pull-right" (click)="searchSelected($event);"><i class="fa fa-close"></i></span>
                  </div>
               </div>                   
           </div>
   end of toggle of search and textbox -->                     
            <div class="form-group">
                <label class="control-label">Select Vehicle</label>
                <select class="custom-form-control meager-e2e-tracking-report-day-summary-select-vehicle" [(ngModel)]="selectVeh_daySummary" name="selectVeh_daySummary">
                    <option value="all">All</option>
                    <option value="chooseSpecificVeh">Choose Vehicle</option>
                </select>
            </div>
             <div class="form-group" *ngIf="selectVeh_daySummary =='chooseSpecificVeh'">
                   <label class="control-label">Choose Vehicles:<sup>*</sup></label>
                 <search-select class="meager-e2e-tracking-report-day-summary-choose-vehicle"
                   [searchType]="'orgDevices'"
                   [searchAPIUrl]="'getAllOrgDevices'"
                   [otherParams]="'resetSearchUtility'"
                   (emitUserSelections)="setUserSelections($event)">
                   </search-select>
                   <span class = "text-danger" *ngIf="emptyVehicleError">Choose Vehicles</span> 
             </div> 
               
               <div class="form-group">
                   <label class="control-label">Start Date:<sup>*</sup></label>
                   <input class="custom-form-control meager-e2e-tracking-report-day-summary-start-date" id="routeHistoryStartDate">
                   <span class="text-danger" *ngIf="daySummaryStartDateError">Choose Start Date</span>
                   <!--<span class="text-danger" *ngIf="daySummaryDatesMisMatchError">End Date should be greater than Start Date</span>-->
               </div>
               <div class="form-group">
                   <label class="control-label">End Date:<sup>*</sup></label>
                   <input class="custom-form-control meager-e2e-tracking-report- day-summary -end-date" id="routeHistoryEndDate">
                   <span class="text-danger" *ngIf="daySummaryEndDateError">Choose End Date</span><br>
                     <span class="text-danger" *ngIf="daySummaryDatesMisMatchError">End Date should be greater than Start Date</span>
               </div>
               <div class="form-group form-actions">
                   <button class="webform-submit button-primary btn btn-primary form-submit pull-right viewReportBtn meager-e2e-tracking-report-day-summary-view-btn" [disabled]="disableViewBtn ? 'true' : null" (click)="viewDaySummary('daysummary')">View</button>
                   <span class="text-danger">{{displayErrorMessage}}</span>
               </div>
                <span class="text-danger" *ngIf="noMoreDataError">The report can generate only for a 3 days</span>
           </section>
           <section class="haltReportSection" *ngIf="reportSections.halt">
               <div class="divHeading" style="margin-bottom: 1.5em;padding-top: 2.5%;">
                   <span class="">Available Options</span>
                   <div style="height: 1px;border: 1px solid #64c7e9;width: 3em;"></div>
               </div>
               <div class="form-group">
                   <label class="control-label">Select Vehicle:<sup>*</sup></label>

                   <!-- toggle of search and textbox -->        
                <div [ngSwitch]="this.searchSelection">
                    <div *ngSwitchCase="'showSearch'">
                        
                        <div class="">
                            <single-search-select
                            [searchType]="'device'"
                            [searchAPIUrl]="'getAllOrgDevices'"
                            [otherParams]="'resetSearchUtility'"
                            (emitUserSelections)="setUserSelection($event)">
                            </single-search-select>
                            <span class="text-danger" *ngIf="emptyVehicleError">Choose Vehicle </span>
                         </div>
                    </div>
                    <div *ngSwitchCase="'hideSearch'">
                       <div>    
                        <input class="custom-form-control" type="text" value={{vehName}}><span style="right: 2%;top: -27px;position:relative;cursor:pointer" class="pull-right" (click)="searchSelected($event);"><i class="fa fa-close"></i></span>
                       </div>
                    </div>                   
                </div>
<!-- end of toggle of search and textbox -->                     

                  <!-- <single-search-select
                   [searchType]="'device'"
                   [searchAPIUrl]="'getAllOrgDevices'"
                   [otherParams]="'resetSearchUtility'"
                   (emitUserSelections)="setUserSelection($event)">
                   </single-search-select> 
                   <span class="text-danger" *ngIf="emptyVehicleError">Choose Vehicle </span>-->
               </div>
               <div class="form-group">
                   <label class="control-label">Start Date:<sup>*</sup></label>
                   <input class="custom-form-control meager-e2e-tracking-report-halt-start-date" id="routeHistoryStartDate">
                   <span class="text-danger" *ngIf="movementstartDateError">Choose Start Date</span>
                   <!--<span class="text-danger" *ngIf="routesDatesMisMatchError">End Date should be greater than Start Date</span>-->
               </div>
               <div class="form-group">
                   <label class="control-label">End Date:<sup>*</sup></label>
                   <input class="custom-form-control meager-e2e-tracking-report-halt-end-date" id="routeHistoryEndDate">
                   <span class="text-danger" *ngIf="movementEndDateError">Choose End Date</span>
                     <span class="text-danger" *ngIf="routesDatesMisMatchError">End Date should be greater than Start Date</span>
               </div>
               <div class="form-group form-actions">
                   <button class="webform-submit button-primary btn btn-primary form-submit pull-right viewReportBtn meager-e2e-tracking-report-halt-view-btn" (click)="viewMovementLog('halt',true)">View</button>
                   <span class="text-danger">{{displayErrorMessage}}</span>
               </div>
           </section>
           <section class="haltCountReportSection" *ngIf="reportSections.haltDurationReport">
               <div class="divHeading" style="margin-bottom: 1.5em;padding-top: 2.5%;">
                   <span class="">Available Options</span>
                   <div style="height: 1px;border: 1px solid #64c7e9;width: 3em;"></div>
               </div>
               <div class="form-group">
                   <label class="control-label">Select Vehicle:<sup>*</sup></label>
                   <single-search-select
                   [searchType]="'device'"
                   [searchAPIUrl]="'getAllOrgDevices'"
                   [otherParams]="'resetSearchUtility'"
                   (emitUserSelections)="setUserSelection($event)">
                   </single-search-select>
                     <span class="text-danger" *ngIf="haltDurationVehilError">Choose Vehicle </span>
               </div>

               <div class="form-group">
                   <!--<label class="control-label">Start Date:</label>-->
                   <label class="control-label ">Date:<sup>*</sup></label>
                   <input class="custom-form-control meager-e2e-tracking-report-halt-duration-start-date" id="routeHistoryStartDate">
                   <span class="text-danger" *ngIf="haltDurationDateError">Choose Date</span>
               
               </div>
              
              <!-- <div class="form-group">
                   <label class="control-label">End Date:<sup>*</sup></label>
                   <input class="custom-form-control meager-e2e-tracking-report-halt- duration-end-date" id="routeHistoryEndDate">
                   <span class="text-danger" *ngIf="movementEndDateError">Choose Start Date</span>
                     <span class="text-danger" *ngIf="routesDatesMisMatchError">End Date should be greater than Start Date</span>
               </div> -->

               <div class="form-group">
                
          
                   <label class="control-label p-15">Halt Duration(in minutes): <sup>*</sup></label>
                   <input class="custom-form-control meager-e2e-tracking-report-halt- duration-in-minutes" type="number" [(ngModel)]="haltDurationMinutes" min="1" max="60">
                   <small class="text-muted">*minium 1 min - maximum 60 mins</small>
                   <span class="text-danger" *ngIf="movementEndDateError">Choose Start Date</span>
                  
               </div>

               <div class="form-group form-actions">
                   <button class="webform-submit button-primary btn btn-primary form-submit pull-right viewReportBtn meager-e2e-tracking-report-halt- duration-view-btn" (click)="viewHaltCountReport('haltDurationReport')">View</button>
                   <span class="text-danger">{{displayErrorMessage}}</span>
               </div>
           </section>
           <section class="movementReportSection" *ngIf="reportSections.movement">
               <div class="divHeading" style="margin-bottom: 1.5em;padding-top: 2.5%;">
                   <span class="">Available Options</span>
                   <div style="height: 1px;border: 1px solid #64c7e9;width: 3em;"></div>
               </div>

<!-- toggle of search and textbox -->        
                <div [ngSwitch]="this.searchSelection">
                    <div *ngSwitchCase="'showSearch'">
                        <label class="control-label">Vehicle Name<sup>*</sup></label>
                       
                        <div class="meager-e2e-tracking-report-movement-veh-name">
                            <single-search-select class="meager-e2e-tracking-report-movement-veh-name"
                            [searchType]="'device'"
                            [searchAPIUrl]="'getAllOrgDevices'"
                            [otherParams]="'resetSearchUtility'"
                            (emitUserSelections)="setUserSelection($event)">
                            </single-search-select>
                            <span class="text-danger" *ngIf="emptyVehicleError">Choose Vehicle </span>
                         </div>
                    </div>
                    <div *ngSwitchCase="'hideSearch'">
                       <div>    
                       <label class="control-label">Vehicle Name<sup>*</sup></label>
                        <input class="custom-form-control meager-e2e-tracking-report-movement-veh-name" type="text" value={{vehName}}><span style="right: 2%;top: -27px;position:relative;cursor:pointer" class="pull-right" (click)="searchSelected($event);"><i class="fa fa-close"></i></span>
                       </div>
                    </div>                   
                </div>
<!-- end of toggle of search and textbox -->     

                <div class="form-group" *ngIf="ifTbs">
                <label class="control-label">Choose Type</label>
                <select class="custom-form-control" (change)="choosenMovementFilter($event.target.value);">
                    <option value="box">Device</option>
                    <option value="tab">Tab</option>
                </select>
                </div>

               <div class="form-group">
                   <label class="control-label">Start Date:<sup>*</sup></label>
                   <input class="custom-form-control meager-e2e-tracking-report-movement-start-date" id="routeHistoryStartDate">
                   <span class="text-danger" *ngIf="movementstartDateError">Choose Start Date</span>
               <!--    <span class="text-danger" *ngIf="routesDatesMisMatchError">End Date should be greater than Start Date</span> -->
               </div>
               <div class="form-group">
                   <label class="control-label">End Date:<sup>*</sup></label>
                   <input class="custom-form-control meager-e2e-tracking-report-movement-end-date" id="routeHistoryEndDate">
                     <span class="text-danger" *ngIf="movementEndDateError">Choose End Date</span>
                     <span class="text-danger" *ngIf="routesDatesMisMatchError">End Date should be greater than Start Date</span>
               </div>
               <div class="form-group form-actions">
                   <button class="webform-submit button-primary btn btn-primary form-submit pull-right viewReportBtn meager-e2e-tracking-report-movement-view-btn" (click)="viewMovementLog('movement',true)">View</button>
                   <span class="text-danger">{{displayErrorMessage}}</span>
               </div>
           </section>
           <section class="movementAndHaltSection" *ngIf="reportSections.movementandhalt">
               <div class="divHeading" style="margin-bottom: 1.5em;padding-top: 2.5%;">
                   <span class="">Available Options</span>
                   <div style="height: 1px;border: 1px solid #64c7e9;width: 3em;"></div>
               </div>
               <div class="form-group">
             <!-- toggle of search and textbox -->        
                <div [ngSwitch]="this.searchSelection">
                    <div *ngSwitchCase="'showSearch'">
                        <label class="control-label">Vehicle Name<sup>*</sup></label>
                       
                        <div class="">
                            <single-search-select
                            [searchType]="'device'"
                            [searchAPIUrl]="'getAllOrgDevices'"
                            [otherParams]="'resetSearchUtility'"
                            (emitUserSelections)="setUserSelection($event)">
                            </single-search-select>
                            <span class="text-danger" *ngIf="emptyVehicleError">Choose Vehicle </span>
                         </div>
                    </div>
                    <div *ngSwitchCase="'hideSearch'">
                       <div>    
                       <label class="control-label">Vehicle Name<sup>*</sup></label>
                        <input class="custom-form-control" type="text" value={{vehName}}><span style="right: 2%;top: -27px;position:relative;cursor:pointer" class="pull-right" (click)="searchSelected($event);"><i class="fa fa-close"></i></span>
                       </div>
                    </div>                   
                </div>
                <!-- end of toggle of search and textbox -->     
                  <!-- <label class="control-label">Select Vehicle:<sup>*</sup></label>
                   <single-search-select
                   [searchType]="'device'"
                   [searchAPIUrl]="'getAllOrgDevices'"
                   [otherParams]="'resetSearchUtility'"
                   (emitUserSelections)="setUserSelection($event)">
                   </single-search-select>
                   <span class="text-danger" *ngIf="emptyVehicleError">Choose Vehicle </span> -->
               </div> 
               <div class="form-group">
                   <label class="control-label">Start Date:<sup>*</sup></label>
                   <input class="custom-form-control meager-e2e-tracking-report-movement-halt-start-date" id="routeHistoryStartDate">
                   <span class="text-danger" *ngIf="movementstartDateError">Choose Start Date</span>
                   <!--<span class="text-danger" *ngIf="routesDatesMisMatchError">End Date should be greater than Start Date</span>-->
               </div>
               <div class="form-group">
                   <label class="control-label">End Date:<sup>*</sup></label>
                   <input class="custom-form-control meager-e2e-tracking-report-movement-halt-end-date" id="routeHistoryEndDate">
                     <span class="text-danger" *ngIf="movementEndDateError">Choose End Date</span>
                     <span class="text-danger" *ngIf="routesDatesMisMatchError">End Date should be greater than Start Date</span>
               </div>
               <div class="form-group form-actions">
                   <button class="webform-submit button-primary btn btn-primary form-submit pull-right viewReportBtn meager-e2e-tracking-report-movement-halt-view-btn" (click)="viewMovementLog('movementandhalt',true)">View</button>
                   <span class="text-danger">{{displayErrorMessage}}</span>
               </div>
           </section>
           <section class="overspeedReportSection" *ngIf="reportSections.overspeed">
               <div class="divHeading" style="margin-bottom: 1.5em;padding-top: 2.5%;">
                   <span class="">Available Options</span>
                   <div style="height: 1px;border: 1px solid #64c7e9;width: 3em;"></div>
               </div>
               <div class="form-group">
                   <label class="control-label">Select Vehicle:<sup>*</sup></label>
                   <single-search-select
                   [searchType]="'device'"
                   [searchAPIUrl]="'getAllOrgDevices'"
                   [otherParams]="'resetSearchUtility'"
                   (emitUserSelections)="setUserSelection($event)">
                   </single-search-select>
                     <span class="text-danger" *ngIf="emptyVehicleError">Choose Vehicle </span>
               </div>
               <div class="form-group">
                   <label class="control-label">Start Date:<sup>*</sup></label>
                   <input class="custom-form-control meager-e2e-tracking-report-over-speed-start-date" id="routeHistoryStartDate">
                   <span class="text-danger" *ngIf="movementstartDateError">Choose Start Date</span>
                   <!--<span class="text-danger" *ngIf="routesDatesMisMatchError">End Date should be greater than Start Date</span>-->
               </div>
               <div class="form-group">
                   <label class="control-label">End Date:<sup>*</sup></label>
                   <input class="custom-form-control meager-e2e-tracking-report-over-speed-end-date" id="routeHistoryEndDate">
                   <span class="text-danger" *ngIf="movementEndDateError">Choose End Date</span>
                     <span class="text-danger" *ngIf="routesDatesMisMatchError">End Date should be greater than Start Date</span>
               </div>
               <div class="form-group">
                   <label class="control-label">Speed Limit:<sup>*</sup></label>
                   <input class="custom-form-control meager-e2e-tracking-report-over-speed-speed-limit" type="number" min="0" [(ngModel)]="reportTypeKeyLookup.overspeed.overspeed.value">
                   <span class ="text-danger" *ngIf="overSpeedLimitError">Enter Speed limit in Digits</span>
               </div>
               <div class="form-group form-actions">
                   <button class="webform-submit button-primary btn btn-primary form-submit pull-right viewReportBtn meager-e2e-tracking-report-over-speed-view-btn" (click)="viewMovementLog('overspeed',true)">View</button>
                   <span class="text-danger">{{displayErrorMessage}}</span>
               </div>
           </section>
           <section class="lowSpeedSection" *ngIf="reportSections.lowspeed">
               <div class="divHeading" style="margin-bottom: 1.5em;padding-top: 2.5%;">
                   <span class="">Available Options</span>
                   <div style="height: 1px;border: 1px solid #64c7e9;width: 3em;"></div>
               </div>
               <div class="form-group">
                   <label class="control-label">Select Vehicle:<sup>*</sup></label>
                   <single-search-select
                   [searchType]="'device'"
                   [searchAPIUrl]="'getAllOrgDevices'"
                   [otherParams]="'resetSearchUtility'"
                   (emitUserSelections)="setUserSelection($event)">
                   </single-search-select>
                   <span class="text-danger" *ngIf="emptyVehicleError">Choose Vehicle </span>
               </div>
               <div class="form-group">
                   <label class="control-label">Start Date:<sup>*</sup></label>
                   <input class="custom-form-control meager-e2e-tracking-report-low-speed-start-date" id="routeHistoryStartDate">
                   <span class="text-danger" *ngIf="movementstartDateError">Choose Start Date</span>
                   <!-- <span class="text-danger" *ngIf="routesDatesMisMatchError">End Date should be greater than Start Date</span> -->
               </div>
               <div class="form-group">
                   <label class="control-label">End Date:<sup>*</sup></label>
                   <input class="custom-form-control meager-e2e-tracking-report-low-speed-end-date" id="routeHistoryEndDate">
                   <span class="text-danger" *ngIf="movementEndDateError">Choose End Date</span>
                     <span class="text-danger" *ngIf="routesDatesMisMatchError">End Date should be greater than Start Date</span>
               </div>
               <div class="form-group">
                   <label class="control-label">Speed Limit:<sup>*</sup></label>
                   <input class="custom-form-control meager-e2e-tracking-report-low-speed-speed-limit" type="number" min="0" [(ngModel)]="reportTypeKeyLookup.lowspeed.lowspeed.value">
                   <span class ="text-danger" *ngIf="lowSpeedLimitError">Enter Speed limit in Digits</span>
               </div>
               <div class="form-group form-actions">
                   <button class="webform-submit button-primary btn btn-primary form-submit pull-right viewReportBtn meager-e2e-tracking-report-low-speed-view-btn" (click)="viewMovementLog('lowspeed',true)">View</button>
                   <span class="text-danger">{{displayErrorMessage}}</span>
               </div>
           </section>
           <section class="betweenTwoSpeedSection" *ngIf="reportSections.betweentwospeed">
               <div class="divHeading" style="margin-bottom: 1.5em;padding-top: 2.5%;">
                   <span class="">Available Options</span>
                   <div style="height: 1px;border: 1px solid #64c7e9;width: 3em;"></div>
               </div>
               <div class="form-group">
                   <label class="control-label">Select Vehicle:<sup>*</sup></label>
                   <single-search-select
                   [searchType]="'device'"
                   [searchAPIUrl]="'getAllOrgDevices'"
                   [otherParams]="'resetSearchUtility'"
                   (emitUserSelections)="setUserSelection($event)">
                   </single-search-select>
                   <span class="text-danger" *ngIf="emptyVehicleError">Choose Vehicle </span>
               </div>
               <div class="form-group">
                   <label class="control-label">Start Date:<sup>*</sup></label>
                   <input class="custom-form-control meager-e2e-tracking-report-speed-range-start-date" id="routeHistoryStartDate">
                   <span class="text-danger" *ngIf="movementstartDateError">Choose Start Date</span>
                   <!--<span class="text-danger" *ngIf="routesDatesMisMatchError">End Date should be greater than Start Date</span>-->
               </div>
               <div class="form-group">
                   <label class="control-label">End Date:<sup>*</sup></label>
                   <input class="custom-form-control meager-e2e-tracking-report-speed-range-end-date" id="routeHistoryEndDate">
                   <span class="text-danger" *ngIf="movementEndDateError">Choose End Date</span>
                     <span class="text-danger" *ngIf="routesDatesMisMatchError">End Date should be greater than Start Date</span>
               </div>
               <div class="form-group">
                   <label class="control-label">Speed Upper Limit:<sup>*</sup></label>
                   <input class="custom-form-control meager-e2e-tracking-report-speed-range-speed-upper-limit" type="number" min="0" [(ngModel)]="reportTypeKeyLookup.betweentwospeed.twospeed.highValue">
                   <span class ="text-danger" *ngIf="HighSpeedLimitError">Enter high speed limit in Digits</span>
                   <span class ="text-danger" *ngIf="speedMismatchError">Upper limit is less than lower limit</span>
               </div>
               <div class="form-group">
                   <label class="control-label">Speed Lower Limit:<sup>*</sup></label>
                   <input class="custom-form-control meager-e2e-tracking-report-speed-range-speed-lower-limit" type="number" min="0" [(ngModel)]="reportTypeKeyLookup.betweentwospeed.twospeed.lowValue">
                   <span class ="text-danger" *ngIf="lowerSpeedLimitError">Enter low speed limit in Digits</span>
                   <!--<span class ="text-danger" *ngIf="speedMismatchError">Speed Mismatch</span>-->
               </div>

               <div class="form-group form-actions">
                   <button class="webform-submit button-primary btn btn-primary form-submit pull-right viewReportBtn meager-e2e-tracking-report-speed-range-view-btn" (click)="viewMovementLog('betweentwospeed',true)">View</button>
                   <span class="text-danger">{{displayErrorMessage}}</span>
               </div>
           </section>
       </div>
       <div class="col-lg-9 col-md-9 col-sm-7 col-xs-7 pull-right" *ngIf="!isLoading; else pageLoader ">
           <section class="betweenTwoSpeedSectionContent" *ngIf="reportSections.betweentwospeed && reportSectionsContent.betweentwospeed">
               <div class="panel panel-default">
                   <div class="panel-heading">
                       <div class="row memberBasicDetails mb5">
                           <div class="reportHeading text-center mb5">In Range Speed Report</div>
                       </div>
                       <div class="row mb5" *ngIf="vehicleMovementLog?.length">
                           <div class="col-md-12 reportExcelDownloadLink text-right mb5">
                               <a href="{{movementReportExcelDownloadLink}}" class="reportExcelDownloadLink" target="_blank">Download excel Report</a>
                           </div>
                       </div>
                   </div>
                   <div class="panel-body" style="height:475px">
                       <div class="row" *ngIf="vehicleMovementLog?.length;else noDataFound">
                           <div class="col-md-12 ChartContainer" id="movementLogChartContainer" style="min-width: 310px;height: 400px; margin: 0 auto"></div>
                           <div class="col-md-12  reportTableContainer">
                               <div class="table-header">
                                   <table class='custom-table' cellpadding="0" cellspacing="0" border="0">
                                       <thead>
                                           <tr>
                                               <th  style="width:10%">#</th>
                                               <th >Date</th>
                                               <th>Location</th>
                                               <th >Speed(in Km/h)</th>
                                           </tr>
                                       </thead>
                                   </table>
                               </div>
                               <div class="table-content" style="height:415px;overflow:auto;" (scroll)="onScroll($event)">
                                   <table class='custom-table' cellpadding="0" cellspacing="0" border="0">
                                       <tbody>
                                           <tr  *ngFor="let info of vehicleMovementLog;let i = index">
                                               <td  style="width:10%">{{i+1}}</td>
                                               <!-- <td>{{info.unicTimeStamp | unixTimestampFormatter}}</td>-->
                                               <td style="text-align:left" >{{info.unicTimeStamp | date:'dd-LL-yyyy h:mm aa'}}</td>
                                               <td class="reportLocationDetails movementLocation" style="text-align:left" *ngIf="info.nearestLocation; else showBlank" style="width:40%" (click)="plotMovementLocation(info)">{{info.nearestLocation}}</td>
                                               <td style="text-align:left">{{info.speed}}</td>
                                               <ng-template #showBlank><td class="text-center">-</td></ng-template>
                                           </tr>
                                       </tbody>
                                   </table>
                                    <div class="table-loader-container text-center" *ngIf="showTableRowLoader">
                                        <i class="fa fa-spinner fa-spin" style="font-size:24px" style="margin-right:5px"></i> Loading...
                                    </div>
                               </div>
                           </div>
                       </div>
                       <div class="pagination pull-right" style="margin-right:15px;cursor:pointer;">

<a data-toggle="tooltip" title="Previous">&nbsp;
  <i class="fa fa-angle-double-left" aria-hidden="true" class="disabled"></i>
</a>
<a data-toggle="tooltip" title="Previous" *ngIf="pageJump>0" (click)="pagination('prev')">&nbsp;
  <i class="fa fa-angle-double-left" aria-hidden="true"></i>
</a>
<a class="active" data-toggle="tooltip" title="Current Page">{{pageJump+1}}</a>
<a data-toggle="tooltip" title="Next" *ngIf="vehicleMovementLog && vehicleMovementLog.length > 0"
  (click)="pagination('next')">&nbsp;
  <i class="fa fa-angle-double-right" aria-hidden="true"></i>
</a>
<a data-toggle="tooltip" title="Next" *ngIf="vehicleMovementLog?.length < 1" class="disabled">&nbsp;
  <i class="fa fa-angle-double-right" aria-hidden="true"></i>
</a>

</div>
                   </div>
               </div>
           </section>
           <section class="daySummarySectionContent" *ngIf="reportSections.daysummary && reportSectionsContent.daysummary">
               <div class="panel panel-default">
                   <div class="panel-heading">
                       <div class="row memberBasicDetails mb5">
                           <div class="reportHeading text-center mb5">Productivity Report</div>
                       </div>
                       <div class="row memberBasicDetails mb5">
                           <div class="col-md-6 form-group text-left">
                               <label class="control-label">Start Date:</label>
                               <span class="daySummaryStartDate">{{daySummaryStartDate | datestringtounix | date:'dd-LL-yyyy'}}</span>
                           </div>
                           <div class="col-md-6 form-group text-right">
                               <label class="control-label">End Date:</label>
                               <span class="daySummaryEndDate">{{daySummaryEndDate | datestringtounix | date:'dd-LL-yyyy'}}</span>
                           </div>
                       </div>
                       <div class="row" *ngIf="!noDaySummaryRecords">
                           <div class="col-md-12 reportExcelDownloadLink text-right mb5">
                           <ng-container *ngIf="selectVeh_daySummary =='chooseSpecificVeh'">
                               <a href="{{daySummaryReportExcelDownloadLink}}" class="reportExcelDownloadLink" target="_blank">Download excel Report</a>
                            </ng-container>
                            <ng-container *ngIf="selectVeh_daySummary =='all'">
                               <a href="{{daySummaryReportExcelDownloadLink2}}" class="reportExcelDownloadLink" target="_blank">Download excel Report</a>
                            </ng-container>
                           </div>
                       </div>
                   </div>
                   <div class="panel-body">
                       <div class="row">
                           <div class="col-md-12 table-responsive">
                               <table class="table table-bordered table-stripped productivityReportTable"  *ngIf="!noDaySummaryRecords">

                               <ng-container *ngIf="selectVeh_daySummary =='chooseSpecificVeh'">

                                                <thead>
                                                    <tr>
                                                        <th class="text-center">Vehicle Name:</th>
                                                        <th *ngFor="let date of daySummaryDateSelections" class="text-center">{{date | datestringtounix | date:'dd-LL-yyyy'}}</th>
                                                        <th class="text-center">Total Distance(in Kms):</th>
                                                    </tr>
                                                </thead>
                                                
                                                <tbody>
                                                    <tr *ngFor="let idIndex of vehicleDaySummary">
                                                        <td class="text-left">{{idIndex.vehNo}}</td>
                                                        <td class="text-right" *ngFor="let data of idIndex.vehInfo">{{data.totalDistance}}</td>
                                                        <td class="text-right">{{idIndex.totalKmsTravelled}}</td>
                                                    </tr>
                                                </tbody>
                                   </ng-container>
                                   <ng-container *ngIf="selectVeh_daySummary =='all'">
                                        <thead>
                                            <tr>
                                                <th class="text-center">Vehicle Name:</th>
                                                <th class="text-center">{{daySummaryEndDate | datestringtounix | date:'dd-LL-yyyy'}}</th>
                                                <th class="text-center">Total Distance(in Kms):</th>
                                            </tr>
                                        </thead>
                                         <tbody>
                                            <tr *ngFor="let idIndex of vehicleDaySummary2">
                                            <td class="text-left">{{idIndex.vehNo}}</td>
                                            <td class="text-right">{{idIndex.totalDistance}}</td>
                                            <td class="text-right">{{idIndex.totalDistance}}</td>
                                            </tr>
                                         </tbody>
                                   </ng-container>
                               </table>
                               <div class="pagination pull-right" style="margin-right:15px;cursor:pointer;">

<a data-toggle="tooltip" title="Previous">&nbsp;
  <i class="fa fa-angle-double-left" aria-hidden="true" class="disabled"></i>
</a>
<a data-toggle="tooltip" title="Previous" *ngIf="pageJump>0" (click)="pagination('prev')">&nbsp;
  <i class="fa fa-angle-double-left" aria-hidden="true"></i>
</a>
<a class="active" data-toggle="tooltip" title="Current Page">{{pageJump+1}}</a>
<a data-toggle="tooltip" title="Next" *ngIf="vehicleMovementLog && vehicleMovementLog.length > 0"
  (click)="pagination('next')">&nbsp;
  <i class="fa fa-angle-double-right" aria-hidden="true"></i>
</a>
<a data-toggle="tooltip" title="Next" *ngIf="vehicleMovementLog?.length < 1" class="disabled">&nbsp;
  <i class="fa fa-angle-double-right" aria-hidden="true"></i>
</a>

</div>
                               <h4 class="animated pulse text-center" *ngIf="noDaySummaryRecords" style="animation-duration: 1s;color:#999FBF"><i class="fa fa-frown-o"></i>&nbsp;<span class="meager-e2e-route-day-summary-no-records">No records found</span></h4>
                           </div>
                       </div>
                   </div>
                  
               </div>
           </section>



           <section class="haltReportSectionContent" *ngIf="reportSections.halt && reportSectionsContent.halt">
               <div class="panel panel-default">
                   <div class="panel-heading">
                       <div class="row memberBasicDetails mb5">
                           <div class="reportHeading text-center mb5">Halt Report</div>
                       </div>
                       <div class="row mb5" *ngIf="vehicleMovementLog?.length">
                           <div class="col-md-6 reportExcelDownloadLink text-left mb5">
                               <a href="{{movementReportExcelDownloadLink}}" class="reportExcelDownloadLink" target="_blank">Download excel Report</a>
                           </div>
                           <div class="col-md-6">
                               <button class="btn btn-secondary calenderview pull-right" style="border-top: 3px solid #0095b9;padding-top: 7px;" type="button" (click)="showHaltPointsOnMap()" *ngIf="!showingMapMovement"><i aria-hidden="true" class="fa fa-map"></i>&nbsp;View on map</button>
                               <button class="btn btn-secondary calenderview pull-right" style="border-top: 3px solid #0095b9;padding-top: 7px;" type="button" (click)="hideHaltPointsOnMap()" *ngIf="showingMapMovement"><i aria-hidden="true" class="fa fa-table"></i>&nbsp;Tabular View</button>
                           </div>
                       </div>
                   </div>
                   <div class="panel-body" *ngIf="!showingMapMovement" style="height:475px">
                       <div class="row" *ngIf="vehicleMovementLog?.length;else noDataFound">
                           <div class="col-md-12 ChartContainer" id="movementLogChartContainer" style="min-width: 310px;height: 400px; margin: 0 auto"></div>
                           <div class="col-md-12  reportTableContainer">
                           <div class="table-header">
                               <table class='custom-table' cellpadding="0" cellspacing="0" border="0">
                                   <thead>
                                       <tr>
                                           <th style="width:6%;">#</th>
                                           <th style="">Date</th>
                                           <th style="">Location</th>
                                           <th style="">Speed(in Km/h)</th>
                                       </tr>
                                   </thead>
                               </table>
                           </div>
                           <div class="table-content" style="height:415px;overflow:auto" (scroll)="onScroll($event)">
                               <table class="custom-table" cellpadding="0" cellspacing="0" border="0">
                                   <tbody>
                                       <tr  *ngFor="let info of vehicleMovementLog;let i = index">
                                           <td style="width:6%;text-align:right">{{i+1}}</td>
                                           <!-- <td>{{info.unicTimeStamp|unixTimestampFormatter}}</td> -->
                                           <td style="width:22%">{{info.unicTimeStamp|date:'dd-LL-yyyy h:mm aa'}}</td>
                                           <td class=" reportLocationDetails movementLocation" style="text-align:left;width:45%" *ngIf="info.nearestLocation; else showBlank" (click)="plotMovementLocation(info)">{{info.nearestLocation}}</td>
                                           <td style="text-align:left">{{info.speed}}</td>
                                           <ng-template #showBlank><td class="text-center">-</td></ng-template>
                                       </tr>
                                   </tbody>
                               </table>
                               <div class="table-loader-container text-center" *ngIf="showTableRowLoader">
                                    <i class="fa fa-spinner fa-spin" style="font-size:24px" style="margin-right:5px"></i> Loading...
                                </div>
                            </div>
                           </div>
                       </div>
                   </div>
                   <div class="panel-body" *ngIf="showingMapMovement">
                       <div class="row" id="mapContainer" style="height:100%"></div>
                   </div>
                   <div class="pagination pull-right" style="margin-right:15px;cursor:pointer;">

<a data-toggle="tooltip" title="Previous">&nbsp;
  <i class="fa fa-angle-double-left" aria-hidden="true" class="disabled"></i>
</a>
<a data-toggle="tooltip" title="Previous" *ngIf="pageJump>0" (click)="pagination('prev')">&nbsp;
  <i class="fa fa-angle-double-left" aria-hidden="true"></i>
</a>
<a class="active" data-toggle="tooltip" title="Current Page">{{pageJump+1}}</a>
<a data-toggle="tooltip" title="Next" *ngIf="vehicleMovementLog && vehicleMovementLog.length > 0"
  (click)="pagination('next')">&nbsp;
  <i class="fa fa-angle-double-right" aria-hidden="true"></i>
</a>
<a data-toggle="tooltip" title="Next" *ngIf="vehicleMovementLog?.length < 1" class="disabled">&nbsp;
  <i class="fa fa-angle-double-right" aria-hidden="true"></i>
</a>

</div>
               </div>
           </section>
           <section class="haltReportDurationSectionContent" *ngIf="reportSections.haltDurationReport && reportSectionsContent.haltDurationReport">
               <div class="panel panel-default">
                   <div class="panel-heading">
                       <div class="row memberBasicDetails mb5">
                           <div class="reportHeading text-center mb5">Halt Duration Report</div>
                       </div>
                       <div class="row">
                           <div class="col-md-6 form-group">
                               <label class="control-label">Start Date:</label>
                               <span>{{daySummaryStartDate | datestringtounix | date:'dd-LL-yyyy'}}</span>
                           </div>
                           <div class="col-md-6 text-right">
                               <label class="control-label">End Date:</label>
                               <span>{{daySummaryEndDate | datestringtounix | date:'dd-LL-yyyy'}}</span>
                           </div>
                       </div>
                       <div class="row mb5" *ngIf="vehicleMovementLog?.length">
                           <div class="col-md-6 reportExcelDownloadLink text-left mb5">
                               <a href="{{movementReportExcelDownloadLink}}" class="reportExcelDownloadLink" target="_blank">Download excel Report</a>
                           </div>
                           <div class="col-md-6">
                                <button class="btn btn-secondary calenderview pull-right" style="border-top: 3px solid #0095b9;padding-top: 7px;" type="button" (click)="showHaltPointsWithDurationOnMap()" *ngIf="!showingMapMovement"><i aria-hidden="true" class="fa fa-map"></i>&nbsp;View on map</button>
                                <button class="btn btn-secondary calenderview pull-right" style="border-top: 3px solid #0095b9;padding-top: 7px;" type="button" (click)="hideHaltPointsWithDurationOnMap()" *ngIf="showingMapMovement"><i aria-hidden="true" class="fa fa-table"></i>&nbsp;Tabular View</button>
                            </div>
                       </div>
                   </div>
                   <div class="panel-body" style="padding:5px;overflow-x:hidden;height: 445px;" *ngIf="!showingMapMovement">
                       <div class="row">
                           <div class="col-md-12">
                               <section *ngIf="vehicleMovementLog?.length;else noDataFound">
                                   <div class="table-header">
                                       <table class='custom-table' cellpadding="0" cellspacing="0" border="0">
                                        <thead>
                                          <tr>
                                            <th style="width:6%;">#</th>
                                            <th style="">Start Time</th>
                                            <th style="">End Time</th>
                                            <th style="">Location</th>
                                            <th style="">Halt Duration</th>
                                          </tr>
                                        </thead>
                                       </table>
                                   </div>
                                   <div class="table-content" style="height:378px">
                                       <table class="custom-table" cellpadding="0" cellspacing="0" border="0">
                                            <tbody>
                                            <tr *ngFor="let haltInfo of vehicleMovementLog;let i = index">
                                                <td style="width:6%;text-align:right">{{i+1}}</td>
                                               <!--<td>{{haltInfo.haltstartTime | unixTimestampFormatter}}</td><td>{{haltInfo.haltendTime | unixTimestampFormatter}}</td>-->
                                                <td >{{haltInfo.haltstartTime | date :'dd-LL-yyyy h:mm aa'}}</td>
                                                <td >{{haltInfo.haltendTime | date :'dd-LL-yyyy h:mm aa'}}</td>
                                                <td class="haltLocation" (click)="plotHaltLocation(haltInfo)">{{haltInfo.nearestLocation}}</td>
                                                <td>{{haltInfo.haltduration}}</td>
                                            </tr>
                                            </tbody>
                                       </table>
                                   </div>
                               </section>
                               
                           </div>
                       </div>
                   </div>
                   <div class="pagination pull-right" style="margin-right:15px;cursor:pointer;">

<a data-toggle="tooltip" title="Previous">&nbsp;
  <i class="fa fa-angle-double-left" aria-hidden="true" class="disabled"></i>
</a>
<a data-toggle="tooltip" title="Previous" *ngIf="pageJump>0" (click)="pagination('prev')">&nbsp;
  <i class="fa fa-angle-double-left" aria-hidden="true"></i>
</a>
<a class="active" data-toggle="tooltip" title="Current Page">{{pageJump+1}}</a>
<a data-toggle="tooltip" title="Next" *ngIf="vehicleMovementLog && vehicleMovementLog.length > 0"
  (click)="pagination('next')">&nbsp;
  <i class="fa fa-angle-double-right" aria-hidden="true"></i>
</a>
<a data-toggle="tooltip" title="Next" *ngIf="vehicleMovementLog?.length < 1" class="disabled">&nbsp;
  <i class="fa fa-angle-double-right" aria-hidden="true"></i>
</a>

</div>
                   <div class="panel-body" *ngIf="showingMapMovement">
                        <div class="row" id="mapContainer" style="height:100%"></div>
                    </div>
               </div>
           </section>
           <section class="lowSpeedSectionContent" *ngIf="reportSections.lowspeed && reportSectionsContent.lowspeed">
               <div class="panel panel-default">
                   <div class="panel-heading">
                       <div class="row memberBasicDetails mb5">
                           <div class="reportHeading text-center mb5">Low Speed Report</div>
                       </div>
                       <div class="row mb5" *ngIf="vehicleMovementLog?.length">
                           <div class="col-md-12 reportExcelDownloadLink text-right mb5">
                               <a href="{{movementReportExcelDownloadLink}}" class="reportExcelDownloadLink" target="_blank">Download excel Report</a>
                           </div>
                       </div>
                   </div>
                   <div class="panel-body" style="height:475px">
                       <div class="row" *ngIf="vehicleMovementLog?.length;else noDataFound">
                           <div class="col-md-12 ChartContainer" id="movementLogChartContainer" style="min-width: 310px;height: 400px; margin: 0 auto"></div>
                           <div class="col-md-12  reportTableContainer">
                               <div class='table-header'>
                                   <table class='custom-table' cellpadding="0" cellspacing="0" border="0">
                                       <thead>
                                           <tr>
                                               <th style="width:19%;">#</th>
                                               <th >Date</th>
                                               <th >Location</th>
                                               <th>Speed(in Km/h)</th>
                                           </tr>
                                       </thead>
                                   </table>
                               </div>
                               <div class="table-content" style="height:415px;overflow:auto" (scroll)="onScroll($event)">
                                   <table class='custom-table' cellpadding="0" cellspacing="0" border="0">
                                       <tbody>
                                           <tr  *ngFor="let info of vehicleMovementLog;let i = index">
                                               <td style="width:19%;" >{{i+1}}</td>
                                               <!-- <td class="text-center">{{info.unicTimeStamp | unixTimestampFormatter}}</td> -->
                                               <td >{{info.unicTimeStamp | date:'dd-LL-yyyy h:mm aa'}}</td>
                                               <td class="reportLocationDetails movementLocation" style="text-align:left" *ngIf="info.nearestLocation; else showTableHyphen" (click)="plotMovementLocation(info)" style="width:38%">{{info.nearestLocation}}</td>
                                               <td >{{info.speed}}</td>
                                           </tr>
                                       </tbody>
                                   </table>
                                    <div class="table-loader-container text-center" *ngIf="showTableRowLoader">
                                        <i class="fa fa-spinner fa-spin" style="font-size:24px" style="margin-right:5px"></i> Loading...
                                    </div>
                               </div>
                           </div>
                       </div>
                       <div class="pagination pull-right" style="margin-right:15px;cursor:pointer;">

<a data-toggle="tooltip" title="Previous">&nbsp;
  <i class="fa fa-angle-double-left" aria-hidden="true" class="disabled"></i>
</a>
<a data-toggle="tooltip" title="Previous" *ngIf="pageJump>0" (click)="pagination('prev')">&nbsp;
  <i class="fa fa-angle-double-left" aria-hidden="true"></i>
</a>
<a class="active" data-toggle="tooltip" title="Current Page">{{pageJump+1}}</a>
<a data-toggle="tooltip" title="Next" *ngIf="vehicleMovementLog && vehicleMovementLog.length > 0"
  (click)="pagination('next')">&nbsp;
  <i class="fa fa-angle-double-right" aria-hidden="true"></i>
</a>
<a data-toggle="tooltip" title="Next" *ngIf="vehicleMovementLog?.length < 1" class="disabled">&nbsp;
  <i class="fa fa-angle-double-right" aria-hidden="true"></i>
</a>

</div>
                   </div>
               </div>
           </section>
<!-- KRISHNAN CO-ORDINATE CODE STARTS HERE-->
<section class="movementReportSectionContent" *ngIf="reportSections.vehcoordinate && reportSectionsContent.vehcoordinate">
<div class="panel panel-default">
    <div class="panel-heading">
        <div class="row memberBasicDetails mb5">
            <div class="reportHeading text-center mb5">Vehicle Co-Ordinate Report for {{selectedVehicleName}}</div>
        </div>

        <div class="row mb5" *ngIf="vehicleMovementLog?.length">
            <div class="col-md-6 reportExcelDownloadLink mb5 pull-left" style="padding-top:1.3%">
                <a href="{{movementReportExcelDownloadLink}}" class="reportExcelDownloadLink" target="_blank">Download excel Report</a>
            </div>
         <!--   <div class="col-md-6 pull-right">
                <button class="btn btn-secondary calenderview pull-right" style="border-top: 3px solid #0095b9;padding-top: 7px;" type="button" (click)="showMovementOnMap()" *ngIf="!showingMapMovement"><i aria-hidden="true" class="fa fa-map"></i>&nbsp;View on map</button>
                <button class="btn btn-secondary calenderview pull-right" style="border-top: 3px solid #0095b9;padding-top: 7px;" type="button" (click)="hideMovementOnMap()" *ngIf="showingMapMovement"><i aria-hidden="true" class="fa fa-table"></i>&nbsp;Tabular View</button>
            </div> -->
        </div>
    </div>
    <section class="table-map-container" *ngIf="vehicleMovementLog?.length;else noDataFound">
        <div class="panel-body"  *ngIf="!showingMapMovement" style="height:475px">
            <div class="row">
                <div class="col-md-12 ChartContainer" id="movementLogChartContainer" style="min-width: 310px;height: 400px; margin: 0 auto"></div>
                <div class="col-md-12  reportTableContainer">
                    <div class="table-header">
                        <table class='custom-table' cellpadding="0" cellspacing="0" border="0">
                            <thead>
                                <tr>
                                    <th style="width:10%;text-align:center">#</th>
                                    <th style="width:22%;text-align:center">Device Date & Time</th>
                                    <th style="width:22%;text-align:center">Server Date & Time</th>
                                    <th style="width:30%;text-align:center">Location</th>
                                    <th style="width:10%;text-align:center" >Speed</th>
                                    <th style="width:10%;text-align:center">Distance</th>
                                    <th style="width:20%;text-align:center">Time Difference</th>
                                </tr>
                            </thead>
                        </table>
                    </div>
                    <div class="table-content" style="height:415px;overflow:auto;" (scroll)="onScroll($event)">
                        <table class="custom-table" cellpadding="0" cellspacing="0" border="0">
                            <tbody>
                                <tr  *ngFor="let info of vehicleMovementLog;let i = index">
                                    <td style="width:10%;text-align:right">{{i+1}}</td>
                                    <!-- <td>{{info.unicTimeStamp | unixTimestampFormatter}}</td> -->
                                    <td style="width:22%;text-align:right">{{info.unicTimeStamp | date:'dd-LL-yyyy h:mm:ss aa'}}</td>
                                    <td style="width:22%;text-align:right">{{info.st | date:'dd-LL-yyyy h:mm:ss aa'}}</td>

                                    <td class="reportLocationDetails movementLocation" style="width:30%;text-align:left" *ngIf="info.nearestLocation; else showBlank" (click)="plotMovementLocation(info)">{{info.nearestLocation}}</td>
                                    <td style="padding-left:3%;width:10%;text-align:right">{{info.speed}}</td>
                                    <td style="padding-left:3%;width:10%;text-align:right">{{info.distanceTravelled}}</td>
                                    <td style="padding-left:3%;width:20%;text-align:left">{{info.DiffInTime}}</td>
                                    <ng-template #showBlank><td class="text-center">-</td></ng-template>
                                </tr>
                            </tbody>
                        </table>
                         <div class="table-loader-container text-center" *ngIf="showTableRowLoader">
                             <i class="fa fa-spinner fa-spin" style="font-size:24px" style="margin-right:5px"></i> Loading...
                         </div>
                     </div>
                </div>
            </div>
        </div>
        <div class="pagination pull-right" style="margin-right:15px;cursor:pointer;">

<a data-toggle="tooltip" title="Previous">&nbsp;
  <i class="fa fa-angle-double-left" aria-hidden="true" class="disabled"></i>
</a>
<a data-toggle="tooltip" title="Previous" *ngIf="pageJump>0" (click)="pagination('prev')">&nbsp;
  <i class="fa fa-angle-double-left" aria-hidden="true"></i>
</a>
<a class="active" data-toggle="tooltip" title="Current Page">{{pageJump+1}}</a>
<a data-toggle="tooltip" title="Next" *ngIf="vehicleMovementLog && vehicleMovementLog.length > 0"
  (click)="pagination('next')">&nbsp;
  <i class="fa fa-angle-double-right" aria-hidden="true"></i>
</a>
<a data-toggle="tooltip" title="Next" *ngIf="vehicleMovementLog?.length < 1" class="disabled">&nbsp;
  <i class="fa fa-angle-double-right" aria-hidden="true"></i>
</a>

</div>
        <div class="panel-body" *ngIf="showingMapMovement">
            <div class="row" id="mapContainer" style="height:100%"></div>
        </div>
    </section>
</div>
</section>
<!-- KRISHNAN CO-ORIDINATE CODE ENDS HERE -->

           <section class="movementReportSectionContent" *ngIf="reportSections.movement && reportSectionsContent.movement">
           <div class="panel panel-default">
               <div class="panel-heading">
                   <div class="row memberBasicDetails mb5">
                       <div class="reportHeading text-center mb5">Movement Report for {{selectedVehicleName}}</div>
                   </div>

                   <div class="row mb5" *ngIf="vehicleMovementLog?.length">
                       <div class="col-md-6 reportExcelDownloadLink mb5 pull-left" style="padding-top:1.3%">
                           <a href="{{movementReportExcelDownloadLink}}" class="reportExcelDownloadLink" target="_blank">Download excel Report</a>
                       </div>
                       <div class="col-md-6 pull-right">
                           <button class="btn btn-secondary calenderview pull-right" style="border-top: 3px solid #0095b9;padding-top: 7px;" type="button" (click)="showMovementOnMap()" *ngIf="!showingMapMovement"><i aria-hidden="true" class="fa fa-map"></i>&nbsp;View on map</button>
                           <button class="btn btn-secondary calenderview pull-right" style="border-top: 3px solid #0095b9;padding-top: 7px;" type="button" (click)="hideMovementOnMap()" *ngIf="showingMapMovement"><i aria-hidden="true" class="fa fa-table"></i>&nbsp;Tabular View</button>
                       </div>
                   </div>
               </div>
               <section class="table-map-container" *ngIf="vehicleMovementLog?.length;else noDataFound">
                   <div class="panel-body"  *ngIf="!showingMapMovement" style="height:475px">
                       <div class="row">
                           <div class="col-md-12 ChartContainer" id="movementLogChartContainer" style="min-width: 310px;height: 400px; margin: 0 auto"></div>
                           <div class="col-md-12  reportTableContainer">
                               <div class="table-header">
                                   <table class='custom-table' cellpadding="0" cellspacing="0" border="0">
                                       <thead>
                                           <tr>
                                               <th style="width:6%">#</th>
                                               <th>Date</th>
                                               <th>Location</th>
                                               <th>Speed(in Km/h)</th>
                                               <th>Distance(in Kms)</th>
                                           </tr>
                                       </thead>
                                   </table>
                               </div>
                               <div class="table-content" style="height:415px;overflow:auto;" >
                                   <table class="custom-table" cellpadding="0" cellspacing="0" border="0">
                                       <tbody>
                                           <tr  *ngFor="let info of vehicleMovementLog;let i = index">
                                               <td style="width:6%;text-align:right">{{i+1}}</td>
                                               <!-- <td>{{info.unicTimeStamp | unixTimestampFormatter}}</td> -->
                                               <td >{{info.unicTimeStamp | date:'dd-LL-yyyy h:mm aa'}}</td>

                                               <td class="reportLocationDetails movementLocation" style="width:30%" *ngIf="info.nearestLocation; else showBlank" (click)="plotMovementLocation(info)">{{info.nearestLocation}}</td>
                                               <td style="padding-left:3%;text-align:left">{{info.speed}}</td>
                                               <td style="padding-left:3%;text-align:left">{{info.distanceTravelled}}</td>
                                               <ng-template #showBlank><td class="text-center">-</td></ng-template>
                                           </tr>
                                       </tbody>
                                   </table>
                                    <div class="table-loader-container text-center" *ngIf="showTableRowLoader">
                                        <i class="fa fa-spinner fa-spin" style="font-size:24px" style="margin-right:5px"></i> Loading...
                                    </div>
                                </div>
                           </div>
                       </div>
                       <div class="pagination pull-right" style="margin-right:15px;cursor:pointer;">

            <a data-toggle="tooltip" title="Previous">&nbsp;
              <i class="fa fa-angle-double-left" aria-hidden="true" class="disabled"></i>
            </a>
            <a data-toggle="tooltip" title="Previous" *ngIf="pageJump>0" (click)="pagination('prev')">&nbsp;
              <i class="fa fa-angle-double-left" aria-hidden="true"></i>
            </a>
            <a class="active" data-toggle="tooltip" title="Current Page">{{pageJump+1}}</a>
            <a data-toggle="tooltip" title="Next" *ngIf="vehicleMovementLog && vehicleMovementLog.length > 0"
              (click)="pagination('next')">&nbsp;
              <i class="fa fa-angle-double-right" aria-hidden="true"></i>
            </a>
            <a data-toggle="tooltip" title="Next" *ngIf="vehicleMovementLog?.length < 1" class="disabled">&nbsp;
              <i class="fa fa-angle-double-right" aria-hidden="true"></i>
            </a>

          </div>
                   </div>
                   <div class="panel-body" *ngIf="showingMapMovement">
                       <div class="row" id="mapContainer" style="height:100%"></div>
                   </div>
               </section>
           </div>
       </section>

<!---->
           <section class="movementAndHaltSectionContent" *ngIf="reportSections.movementandhalt && reportSectionsContent.movementandhalt">
               <div class="panel panel-default">
                   <div class="panel-heading">
                       <div class="row memberBasicDetails mb5">
                           <div class="reportHeading text-center mb5">Movement & Halt Report</div>
                       </div>
                       <div class="row mb5" *ngIf="vehicleMovementLog?.length">
                           <div class="col-md-6 reportExcelDownloadLink text-left mb5">
                               <a href="{{movementReportExcelDownloadLink}}" class="reportExcelDownloadLink" target="_blank">Download excel Report</a>
                           </div>
                           <div class="col-md-6">
                               <button class="btn btn-secondary calenderview pull-right" style="border-top: 3px solid #0095b9;padding-top: 7px;" type="button" (click)="showMovementWithHaltOnMap()" *ngIf="!showingMapMovement"><i aria-hidden="true" class="fa fa-map"></i>&nbsp;View on map</button>
                               <button class="btn btn-secondary calenderview pull-right" style="border-top: 3px solid #0095b9;padding-top: 7px;" type="button" (click)="hideMovementWithHaltOnMap()" *ngIf="showingMapMovement"><i aria-hidden="true" class="fa fa-table"></i>&nbsp;Tabular View</button>
                           </div>
                       </div>
                   </div>
                   <section class="table-map-container" *ngIf="vehicleMovementLog?.length;else noDataFound">
                       <div class="panel-body" *ngIf="!showingMapMovement" style="height:475px">
                           <div class="row">
                               <div class="col-md-12 ChartContainer" id="movementLogChartContainer" style="min-width: 310px;height: 400px; margin: 0 auto"></div>
                               <div class="col-md-12  reportTableContainer">
                               <div class="table-header">
                                   <table class='custom-table' cellpadding="0" cellspacing="0" border="0">
                                       <thead>
                                           <tr>
                                               <th style="width:6%;">#</th>
                                               <th style="">Date</th>
                                               <th>Location</th>
                                               <th >Speed(in Km/h)</th>
                                               <th >Distance(in Kms)</th>
                                           </tr>
                                       </thead>
                                   </table>
                               </div>
                               <div class="table-content" style="height:415px;overflow:auto" (scroll)="onScroll($event)">
                                   <table class="custom-table" cellpadding="0" cellspacing="0" border="0">
                                       <tbody>
                                           <tr  *ngFor="let info of vehicleMovementLog;let i = index">
                                               <td style="width:6%;">{{i+1}}</td>
                                               <!-- <td>{{info.unicTimeStamp | unixTimestampFormatter}}</td> -->
                                               <td >{{info.unicTimeStamp | date:'dd-LL-yyyy h:mm aa'}}</td>

                                               <td class="reportLocationDetails movementLocation" style="text-align:left" *ngIf="info.nearestLocation; else showTableHyphen" (click)="plotMovementLocation(info)">{{info.nearestLocation}}</td>
                                               <td style="padding-left:3%;text-align:left">{{info.speed}}</td>
                                               <td style="padding-left:3%;text-align:left">{{info.distanceTravelled}}</td>
                                           </tr>
                                       </tbody>
                                   </table>
                                    <div class="table-loader-container text-center" *ngIf="showTableRowLoader">
                                        <i class="fa fa-spinner fa-spin" style="font-size:24px" style="margin-right:5px"></i> Loading...
                                    </div>
                                </div>
                               </div>
                           </div>
                           <div class="pagination pull-right" style="margin-right:15px;cursor:pointer;">

<a data-toggle="tooltip" title="Previous">&nbsp;
  <i class="fa fa-angle-double-left" aria-hidden="true" class="disabled"></i>
</a>
<a data-toggle="tooltip" title="Previous" *ngIf="pageJump>0" (click)="pagination('prev')">&nbsp;
  <i class="fa fa-angle-double-left" aria-hidden="true"></i>
</a>
<a class="active" data-toggle="tooltip" title="Current Page">{{pageJump+1}}</a>
<a data-toggle="tooltip" title="Next" *ngIf="vehicleMovementLog && vehicleMovementLog.length > 0"
  (click)="pagination('next')">&nbsp;
  <i class="fa fa-angle-double-right" aria-hidden="true"></i>
</a>
<a data-toggle="tooltip" title="Next" *ngIf="vehicleMovementLog?.length < 1" class="disabled">&nbsp;
  <i class="fa fa-angle-double-right" aria-hidden="true"></i>
</a>

</div>
                       </div>
                       <div class="panel-body" *ngIf="showingMapMovement">
                           <div class="row" id="mapContainer" style="height:100%"></div>
                       </div>
                   </section>
               </div>
           </section>
           <section class="overspeedReportSectionContent" *ngIf="reportSections.overspeed && reportSectionsContent.overspeed">
               <div class="panel panel-default">
                   <div class="panel-heading">
                       <div class="row memberBasicDetails mb5">
                           <div class="reportHeading text-center mb5">Overspeeding Report</div>
                       </div>
                       <div class="row mb5" *ngIf="vehicleMovementLog?.length">
                           <div class="col-md-12 reportExcelDownloadLink text-right mb5">
                               <a href="{{movementReportExcelDownloadLink}}" class="reportExcelDownloadLink" target="_blank">Download excel Report</a>
                           </div>
                       </div>
                   </div>
                   <div class="panel-body" *ngIf="vehicleMovementLog?.length;else noDataFound" style="height:475px">
                       <div class="row">
                           <div class="col-md-12 ChartContainer" id="movementLogChartContainer" style="min-width: 310px;height: 400px; margin: 0 auto"></div>
                           <div class="col-md-12  reportTableContainer">
                               <div class="table-header">
                                   <table class='custom-table' cellpadding="0" cellspacing="0" border="0">
                                       <thead>
                                           <tr>
                                               <th style="width:6%;">#</th>
                                               <th >Date</th>
                                               <th >Location</th>
                                               <th >Speed(in Km/h)</th>
                                           </tr>
                                       </thead>
                                   </table>
                               </div>
                               <div class="table-content" style="height:415px;overflow:auto" (scroll)="onScroll($event)">
                                   <table class="custom-table" cellpadding="0" cellspacing="0" border="0">
                                       <tbody>
                                           <tr  *ngFor="let info of vehicleMovementLog;let i = index">
                                               <td style="width:6%;">{{i+1}}</td>
                                               <!-- <td class="text-center">{{info.unicTimeStamp | unixTimestampFormatter}}</td> -->
                                               <td >{{info.unicTimeStamp | date:'dd-LL-yyyy h:mm aa'}}</td>

                                               <td class="reportLocationDetails movementLocation" style="text-align:left" *ngIf="info.nearestLocation; else showTableHyphen" (click)="plotMovementLocation(info)" style="width:40%">{{info.nearestLocation}}</td>
                                               <td style="text-align:left">{{info.speed}}</td>
                                           </tr>
                                       </tbody>
                                   </table>
                                    <div class="table-loader-container text-center" *ngIf="showTableRowLoader">
                                        <i class="fa fa-spinner fa-spin" style="font-size:24px" style="margin-right:5px"></i> Loading...
                                    </div>
                                </div>
                               </div>
                           </div>
                           
                      </div>
                      <div class="pagination pull-right" style="margin-right:15px;cursor:pointer;">

<a data-toggle="tooltip" title="Previous">&nbsp;
  <i class="fa fa-angle-double-left" aria-hidden="true" class="disabled"></i>
</a>
<a data-toggle="tooltip" title="Previous" *ngIf="pageJump>0" (click)="pagination('prev')">&nbsp;
  <i class="fa fa-angle-double-left" aria-hidden="true"></i>
</a>
<a class="active" data-toggle="tooltip" title="Current Page">{{pageJump+1}}</a>
<a data-toggle="tooltip" title="Next" *ngIf="vehicleMovementLog && vehicleMovementLog.length > 0"
  (click)="pagination('next')">&nbsp;
  <i class="fa fa-angle-double-right" aria-hidden="true"></i>
</a>
<a data-toggle="tooltip" title="Next" *ngIf="vehicleMovementLog?.length < 1" class="disabled">&nbsp;
  <i class="fa fa-angle-double-right" aria-hidden="true"></i>
</a>

</div>
               </div>
           </section>
       </div>
    </div>
</section>

<div id="myModal" class="modal fade" style="padding:7%;">
   <div class="modal-dialog">
       <div class="modal-content">
           <div class="modal-header">
               <button type="button" class="close" data-dismiss="modal" aria-hidden="true">&times;</button>
               <h4 class="modal-title">Vehicle Location</h4>
           </div>
           <div class="modal-body">
               <div id="pointLocationMap" style="height:300px"></div>
           </div>
       </div>
   </div>
</div>
<ng-template #noDataFound>
    <h4 class="animated pulse text-center" *ngIf="NoRecordsFoundHideMessage" style="animation-duration: 1s;color:#999FBF"><i class="fa fa-frown-o"></i>&nbsp;<span class="meager-e2e-movement-log-no-records">No records found</span></h4>
</ng-template>
<ng-template #pageLoader>
    <div class="loaderContainer">
        <loader></loader>
    </div>
</ng-template>
<ng-template #showTableHyphen><td class="text-center">-</td></ng-template>

    `,
    styles: [`

        .p-15{
            padding-top: 15px;
        }
        .availableReportOptions{
            border-right:1px solid #9e9e9e91;
            padding: 0.9em;
        }
        .reportHeading{
            font-size:1.7em;
        }
        div.fixedHeight{
            height: 582px;
            overflow-y:auto;
        }
        .applyFilterBtn,.viewReportBtn{
            color: #fff;
            background-color: #64c7e9;
            border-color: #64c7e9;
            padding: 12px 10px 12px 10px !important;
            font-size: 11px;
        }

        label.basicDetailsLabel {
            font-weight: 600;
            font-size: 14px;
        }
        .reportExcelDownloadLink{
            text-decoration: none;
            cursor: pointer;
        }
        .panel-heading{
            background:none;
            position:sticky;
        }
        .panel-body{
            height: 522px;
            overflow-y: auto;
        }
        .animation-bottom10{
        animation: animationBottom10 2s;
        }
        @keyframes animationBottom10 {
          0% {   transform:translateY(10px); }
          100%{  transform:translateY(0px); }
        }

      .border-deda {
        border: 1px solid #dedada;
        -webkit-box-shadow: 0 6px 4px -4px grey;
        -moz-box-shadow: 0 6px 4px -4px grey;
        box-shadow: 0 6px 4px -4px grey;
        height: 144px;
        overflow-y: auto;
        margin-left:-15px;
        margin-right:-15px;
      }

      .searchbar {
          padding-bottom: 20px;
      }
      .border-deda::-webkit-scrollbar {
          background-color: white;
          width: 2px;
      }

      .border-deda::-moz-scrollbar {
          background-color: white;
          width: 2px;
      }

      .border-deda::-webkit-scrollbar-thumb {
          background-color: #3498db;
      }

      .border-deda::-moz-scrollbar-thumb {
          background-color: #3498db;
      }

      input[type=search]::-webkit-search-cancel-button {
      -webkit-appearance: searchfield-cancel-button;
      }
       .productivityReportTable{
           overflow-x: auto;
           white-space: nowrap;
       }
       .reportExcelDownloadLink{
           text-decoration: none;
           cursor: pointer;
       }
       .v-center{
           vertical-align:inherit;
       }


       .custom-table{
        width:100%;
        table-layout: fixed;
      }
       .tbl-header{
           background-color: rgb(250, 249, 249);
       }
       .tbl-content{
        height:300px;
        overflow-x:auto;
        margin-top: 0px;
        border: 1px solid rgba(255,255,255,0.3);
      }
      .custom-table th{
        padding: 20px 15px;
        text-align: left;
        font-weight: 500;
        font-size: 12px;
        color: #black;
        text-transform: uppercase;
      }
      .custom-table td{
        padding: 15px;
        text-align: left;
        vertical-align:middle;
        font-weight: 300;
        font-size: 12px;
        color: black;
        border-top: 1px rgba(0, 0, 0, 0.12) solid !important;
      }

      .haltLocation,.movementLocation{
         cursor:pointer;
         color: #777 !important;
      }

    .loaderContainer{
        margin: 20% 0 0 30%;
    }

         @-moz-document url-prefix() {
              #availableReportsDropdown{
                    padding-right: 0px;
                    background-image: url("data:image/svg+xml,<svg version='1.1' xmlns='http://www.w3.org/2000/svg' width='14px' height='14px' viewBox='0 0 1200 1000' fill='rgb(51,51,51)'> <path d='M1100 411l-198 -199l-353 353l-353 -353l-197 199l551 551z'/> </svg>") !important;
                    background-repeat: no-repeat;
                    background-position: calc(100% - 7px) 50%;
                    -moz-appearance: none;
                    appearance: none;
                }
            
            }

        `]
})

export class trackingReportGroup {
    private allOrgVehicles: any[];
    private setSingleSelectedItem: any[];
    public reportOptions: any[] = [];
    public isLoading: boolean;
    public showTableRowLoader: boolean;
    private vehicleMovementLog: any[];
    private movementLogChartData: any[];
    private routePlotRecords: any[];
    private routePlotHeaderConf: any;
    private globalMapObj: any;
    private disableViewBtn: boolean;
    private vehicleHaltLog: any[];
    private vehicleMovementWithHaltLog: any[];
    private vehicleOverSpeedingLog: any[];
    private vehicleDaySummary: any[];
    private daySummaryVehSelection: any[];
    private daySummaryDateSelections: any[];
    private daySummaryStartDate: any;
    private daySummaryEndDate: any;
    private daySummaryReportExcelDownloadLink: any
    private noDaySummaryRecords: boolean;
    private trackingReportOverSpeedTo: number;
    private pageJump: number;
    private selectedReport: any;
    private noMoredata: boolean;
    private isScrolled: boolean;
    private isDataProcessed: boolean; //var for show/hide halt duration.
    private haltDurationMinutes: number = 1;
    private showingMapMovement: boolean;
    private showingRoute: boolean;
    private movingMarker: any;
    private plottedRouteMarker: any;
    private currentPlottedRecordPointer: number;
    private noMoreRecodsToBePlotted: boolean;
    private routePoly: any;
    private infoWindow: any;
    private haltMarkerClusters: any;
    private movementReportExcelDownloadLink: any;
    private displayErrorMessage: string;
    private NoRecordsFoundHideMessage: boolean;

    private daySummaryStartDateError: boolean;
    private daySummaryEndDateError: boolean;
    private daySummaryDatesMisMatchError: boolean = false;
    private emptyVehicleError: boolean;
    private polyOptions = {
        strokeColor: '#444',
        strokeOpacity: 0.9,
        strokeWeight: 3
    };
    public selectVeh_daySummary: string = "chooseSpecificVeh";
    public haltDurationDateError: boolean;
    public haltDurationVehilError: boolean;

    public selectedVehicleName: string;

    public reportSections = {
        betweentwospeed: false,
        daysummary: false,
        vehcoordinate: false,
        halt: false,
        haltDurationReport: false,
        lowspeed: false,
        movement: false,
        movementandhalt: false,
        overspeed: false
    }
    public reportSectionsContent = {
        betweentwospeed: false,
        daysummary: false,
        vehcoordinate: false,
        halt: false,
        haltDurationReport: false,
        lowspeed: false,
        movement: false,
        movementandhalt: false,
        overspeed: false
    }
    private reportLookUpTitle = {
        betweentwospeed: 'Speed range report',
        daysummary: 'Day summary',
        vehcoordinate: 'Vehicle Co-ordinate Report',
        halt: 'Halt Log',
        haltDurationReport: 'Halt Duration report',
        lowspeed: 'Low speed log',
        movement: 'Movement log',
        movementandhalt: 'Movement & Halt log',
        overspeed: 'Overspeeding log'
    }

    private reportLookUpFunction = {
        betweentwospeed: 'initOverspeedInRangeReport',
        daysummary: 'initProductivityReport',
        vehcoordinate: 'intVehicleCoordinateReport',
        halt: 'initHaltReport',
        haltDurationReport: 'initHaltCountReport',
        lowspeed: 'initLowSpeedReport',
        movement: 'initMovementReport',
        movementandhalt: 'initMovementWithHaltReport',
        overspeed: 'initOverspeedReport'
    }

    /* global service data */

    public vehid: string = this.storageservice.get('vehicleId');
    public vehName: string = this.storageservice.get('vehicleName');
    public report: string = this.storageservice.get('report');

    public haltereportSet = false;
    public movementreportSet = false;
    public vehicleName = false

    public searchSelection: string = "showSearch";


    /* end of declaration */
    public parameter: any;

    private reportTypeKeyLookup: any;
    routesDatesMisMatchError: boolean;
    vehicleDaySummary2: any[];
    daySummaryReportExcelDownloadLink2: string;
    trackingDevice: string | boolean;
    ifTbs: any;

    public globalRoutePPobj: any = {};

    public startTime: number;
    public endTime: number;

    @Input('availableReports')
    set availableReports(para: any) {
        this.parameter = para;
    }


    @Input('TrackingReports')
    set TrackingReports(data: any) {
        if (data !== undefined) {
            this.trackControl(data.tracking);
        }
    }

    trackControl(obj: any) {
        let report;
        if (localStorage.getItem('userInfo')) {
            report = JSON.parse(localStorage.getItem('userInfo'));
        }

        let uLevel = JSON.parse(localStorage.getItem('userLevel'));

        if (uLevel === 1) {
            // let trackOne = Object.assign(this.parameter); 
            this.generateOptions(this.parameter);
        } else if (uLevel === 2) {
            let tracking = Object.assign(this.parameter, report.reports.tracking);
            this.generateOptions(tracking);
        }
    }


    constructor(private chosenUtility: chosenUtility,
        private bootstrapDateTime: bootstrapDateTime,
        private apiService: ApiService,
        private animateMarker: animateMarker,
        public globalService: globalService,
        public storageservice: StorageService,
        public vehicleService: VehicleServices,
        private globalUtils: GlobalUtils,
        private com: COM,
        public dateChange: ApiCallDateFormatService,
    ) {
        this.allOrgVehicles = [];
        this.setSingleSelectedItem = [];
        this.vehicleMovementLog = [];
        this.vehicleHaltLog = [];
        this.vehicleMovementWithHaltLog = [];
        this.vehicleOverSpeedingLog = [];
        this.vehicleDaySummary = [];
        this.daySummaryDateSelections = [];
        this.daySummaryVehSelection = []
        this.trackingReportOverSpeedTo = null;
        this.movementLogChartData = [];
        this.pageJump = 0;
        this.showingMapMovement = false;
        this.routePlotRecords = [];
        this.showingRoute = false;
        this.currentPlottedRecordPointer = 0;
        this.noMoreRecodsToBePlotted = true;
        this.infoWindow = null;
        this.isLoading = false;
        this.showTableRowLoader = false;
        //this.disableViewBtn = false;
        this.ifTbs = this.storageservice.get('stbs');
    }
    ngOnInit() {

        this.reportTypeKeyLookup = {
            movement: {
                movement: {
                    value: 5
                }
            },
            halt: {
                halt: {
                    value: 0
                },
            },
            overspeed: {
                overspeed: {
                    value: null
                }
            },
            lowspeed: {
                lowspeed: {
                    value: null
                }
            },
            betweentwospeed: {
                twospeed: {
                    lowValue: null,
                    highValue: null
                }
            }
        }

        this.initChosen();
        this.getAllDevices();

        this.searchSelection = "showSearch";
        // this.setUserSelection(event); Movement & Halt log

        if (this.report === 'Halt Log') {
            this.initReportSection({ halt: true });
            this.haltereportSet = true;
            this.vehicleName = true;
            if (this.vehid != '') {
                this.setUserSelection(this.vehid);
            }

            this.searchSelection = "hideSearch";
        }

        if (this.report === 'Movement Log') {
            this.initReportSection({ movement: true });
            this.movementreportSet = true;
            this.vehicleName = true;

            if (this.vehid != '') {
                this.setUserSelection(this.vehid);
            }

            this.searchSelection = "hideSearch";
        }
        if (this.report === 'Movement & Halt log') {
            this.initReportSection({ movement: true });
            this.movementreportSet = true;
            this.vehicleName = true;

            if (this.vehid != '') {
                this.setUserSelection(this.vehid);
            }

            this.searchSelection = "hideSearch";
        }
        // this.loadCSS();
        this.pickathpluginInit();
    }
    pickathpluginInit() {
        return new Promise(function (resolve, reject) {

            setTimeout(() =>


                (new Pickathing('demo-select', true, { searchLabel: 'Search for options...' }), resolve(true)), 500);

        }).then(function (res1) {



            return res1 && new Promise((res2, reject) => { // (*)
                // res2('Second')
                var srchIpBox: any = document.getElementsByClassName('Pickathing-dropdown');


                // var PickathingList:any = document.getElementsByClassName('Pickathing-list');
                var span = document.createElement('span');
                var i = document.createElement('i');

                span.setAttribute('id', 'pickathSearchOption');

                i.setAttribute('class', 'fa fa-search');

                srchIpBox[0].appendChild(span);
                span.appendChild(i);

                // PickathingList[0].style.display = 'none';

                span.style.position = 'absolute';
                span.style.top = '16px';
                span.style.right = '19px';
                span.style.zIndex = '999';
                res2(true);
            });

        }).then(function (result) { // (**)



            return result == true && new Promise((res3, reject) => {
                res3(true);
            });

        }).then(function (result) {



            var targetButton: any = document.getElementsByClassName('Pickathing-option');

            /// targetButton[0].innerHTML = '';
            targetButton[0].style.display = "none";

        });
    }
    // loadCSS(){
    //     setTimeout(() => {



    //         var demoSelect = new Pickathing('demo-select', true, {searchLabel: 'Search for options...'});

    //     }, 200);
    //    // this is to dynamically create search box for pickath


    //         // this.readytoDisplay = true;

    // }
    ngAfterViewInit() {
        //this.dynamicallyinjectSearch();
        // this.defaultSelectOptionRemoval('Pickathing-option');
    }
    // dynamicallyinjectSearch(){
    //     setTimeout(() => {
    //         var srchIpBox:any = document.getElementsByClassName('Pickathing-dropdown');


    //         // var PickathingList:any = document.getElementsByClassName('Pickathing-list');
    //         var span = document.createElement('span');
    //         var i = document.createElement('i');

    //         span.setAttribute('id','pickathSearchOption');

    //         i.setAttribute('class','fa fa-search');

    //         srchIpBox[0].appendChild(span);
    //         span.appendChild(i);

    //         // PickathingList[0].style.display = 'none';

    //         span.style.position = 'absolute';
    //         span.style.top = '16px';
    //         span.style.right = '19px';
    //         span.style.zIndex = '999';

    //     }, 600);
    // }
    // defaultSelectOptionRemoval(str:string){
    //     setTimeout(() => {
    //         var targetButton:any = document.getElementsByClassName(str);
    //         // targetButton[0].innerHTML = '';
    //         targetButton[0].style.display = "none";

    //     }, 700);

    // }
    searchSelected(event: any) {

        this.movementreportSet == false;
        this.haltereportSet == false;
        this.vehicleName == false;
        this.storageservice.set({ report: '' });
        this.storageservice.set({ vehicleName: '' });
        this.storageservice.set({ vehicleId: '' });
        this.vehid = '';
        this.vehName = '';
        this.report = '';

        this.searchSelection = "showSearch";
    }
    getAllDevices(): void {
        let apiHeader = {
            data: {
                key: localStorage.getItem('scbToken')
            }
        }
        this.apiService.getAllOrgDevices(apiHeader).then(response => {
            this.allOrgVehicles = response.response;
        }).catch(error => { })
    }

    generateOptions(param: any): void {
        for (let obj in param) {
            let data = {};
            if (param[obj]) {
                data['reportName'] = this.reportLookUpTitle[obj];
                data['value'] = obj;
                this.reportOptions.push(data);
            }
        }
    }

    initReportSection({ betweentwospeed = false, daysummary = false, halt = false, haltDurationReport = false, lowspeed = false, movement = false, movementandhalt = false, overspeed = false, vehcoordinate = false } = {}): void {

        let providedParam = Object.getOwnPropertyNames(arguments[0]);
        let calleFunction = this.reportLookUpFunction[providedParam[0]];
        this.reportSections.betweentwospeed = betweentwospeed;
        this.reportSections.daysummary = daysummary;
        this.reportSections.vehcoordinate = vehcoordinate;
        this.reportSections.halt = halt;
        this.reportSections.haltDurationReport = haltDurationReport;
        this.reportSections.lowspeed = lowspeed;
        this.reportSections.movement = movement;
        this.reportSections.movementandhalt = movementandhalt;
        this.reportSections.overspeed = overspeed;
        this[`${calleFunction}`](calleFunction);
        this.initReportSectionContent({});
        this.resetAllValues();
    }

    initReportSectionContent({ betweentwospeed = false, daysummary = false, halt = false, haltDurationReport = false, lowspeed = false, movement = false, movementandhalt = false, overspeed = false, vehcoordinate = false } = {}): void {

        this.reportSectionsContent.betweentwospeed = betweentwospeed;
        this.reportSectionsContent.daysummary = daysummary;
        this.reportSectionsContent.vehcoordinate = vehcoordinate;
        this.reportSectionsContent.halt = halt;
        this.reportSectionsContent.haltDurationReport = haltDurationReport;
        this.reportSectionsContent.lowspeed = lowspeed;
        this.reportSectionsContent.movement = movement;
        this.reportSectionsContent.movementandhalt = movementandhalt;
        this.reportSectionsContent.overspeed = overspeed;
    }

    resetAllValues(): void {
        this.vehicleMovementLog = [];
        this.movementLogChartData = [];
        this.vehicleDaySummary = [];
        this.daySummaryDateSelections = [];
        this.daySummaryStartDate = null;
        this.daySummaryEndDate = null;
        this.noDaySummaryRecords = false;
        this.trackingReportOverSpeedTo = null;
        this.movementReportExcelDownloadLink = null;
        this.setSingleSelectedItem = [];
        this.reportTypeKeyLookup.overspeed.overspeed.value = null;
        this.reportTypeKeyLookup.lowspeed.lowspeed.value = null;
        this.reportTypeKeyLookup.betweentwospeed.twospeed.lowValue = null;
        this.reportTypeKeyLookup.betweentwospeed.twospeed.highValue = null;
        this.disableViewBtn = false;
        this.isLoading = false;
        this.showTableRowLoader = false;
    }

    initOverspeedInRangeReport(): void {
        this.initJqUtils('devicesDropDown', 'routeHistoryStartDate', 'routeHistoryEndDate', undefined);

        setTimeout(() => { this.resetTrackingTemplateError() }, 200);
    }

    initProductivityReport(): void {
        let pickerConfig: any = {
            timePicker: false,
            timeFormat: null,
            closePickerOnDateSelect: true
        }
        this.disableViewBtn = false;

        this.selectVeh_daySummary = 'chooseSpecificVeh';
        this.initJqUtils(undefined, 'routeHistoryStartDate', 'routeHistoryEndDate', pickerConfig);
        setTimeout(() => { this.resetTrackingTemplateError() }, 200);

    }
    intVehicleCoordinateReport(): void {
        this.initJqUtils('devicesDropDown', 'routeHistoryStartDate', 'routeHistoryEndDate', undefined);
        setTimeout(() => { this.resetTrackingTemplateError() }, 200);

    }

    initHaltReport(): void {
        this.initJqUtils('devicesDropDown', 'routeHistoryStartDate', 'routeHistoryEndDate', undefined);
        setTimeout(() => { this.resetTrackingTemplateError() }, 200);

    }

    initHaltCountReport(): void {
        let pickerConfig: any = {
            timePicker: false,
            timeFormat: null,
            closePickerOnDateSelect: true
        }
        //this.initJqUtils('devicesDropDown',undefined,undefined,undefined);
        this.initHaltDurationPickers('routeHistoryStartDate', 'routeHistoryEndDate', pickerConfig);
        setTimeout(() => { this.resetTrackingTemplateError() }, 200);

    }


    initLowSpeedReport(): void {
        this.initJqUtils('devicesDropDown', 'routeHistoryStartDate', 'routeHistoryEndDate', undefined);
        setTimeout(() => { this.resetTrackingTemplateError() }, 200);

    }

    initMovementReport(): void {
        this.initJqUtils('devicesDropDown', 'routeHistoryStartDate', 'routeHistoryEndDate', undefined);
        setTimeout(() => { this.resetTrackingTemplateError() }, 200);

    }

    initMovementWithHaltReport(): void {
        this.initJqUtils('devicesDropDown', 'routeHistoryStartDate', 'routeHistoryEndDate', undefined);
        setTimeout(() => { this.resetTrackingTemplateError() }, 200);

    }

    initOverspeedReport(): void {
        this.initJqUtils('devicesDropDown', 'routeHistoryStartDate', 'routeHistoryEndDate', undefined);
        setTimeout(() => { this.resetTrackingTemplateError() }, 200);

    }

    initializeReportContent(sectionContentId: string): void {
        let obj = {};

        obj['' + sectionContentId] = true;
        this.initReportSectionContent(obj);
    }

    setUserSelection(selectedValue: any) { // setting single selected item
        if (selectedValue) this.setSingleSelectedItem = selectedValue;
        if (this.isDataProcessed) this.isDataProcessed = false;
    }

    public movementstartDateError: boolean;
    public movementEndDateError: boolean;
    public overSpeedLimitError: boolean;
    public lowSpeedLimitError: boolean;
    public HighSpeedLimitError: boolean;
    public lowerSpeedLimitError: boolean;
    public speedMismatchError: boolean;
    public selected_sectionContentId: string = '';

    initTrackingReportInputValidaiton() {  //Validator by Deepak
        let isValid: boolean = true;

        if (this.reportSections.movement == true || this.reportSections.halt == true || this.reportSections.movementandhalt == true || this.reportSections.vehcoordinate == true) {
            if (!this.setSingleSelectedItem.length) {
                this.emptyVehicleError = true;
                isValid = false;
            } else {
                this.emptyVehicleError = false;
            }
            let movementLogStartDate = $('#routeHistoryStartDate').val();
            let movementLogEndDate = $('#routeHistoryEndDate').val();
            let dateObj: any = this.genericDateValidate(movementLogStartDate, movementLogEndDate);


            if (dateObj.stTimeStampString.length <= 10) {
                this.movementstartDateError = true;
                isValid = false;
            } else {
                this.movementstartDateError = false;
            }
            // if string conversion of null NAN returns with length 3
            if (dateObj.endTimeStampString.length <= 10) {
                // if (!movementLogEndDate) {
                this.movementEndDateError = true;
                isValid = false;
            } else {
                this.movementEndDateError = false;
            }

            if (dateObj.endTimeStampString.length && dateObj.endTimeStampString.length) {

                if (dateObj.stTimeStamp > dateObj.endTimeStamp) {

                    // if ($('#routeHistoryStartDate').val() > $('#routeHistoryEndDate').val()) {
                    this.routesDatesMisMatchError = true;
                    // this.daySummaryDatesMisMatchError = true;
                    isValid = false;
                } else {
                    // this.daySummaryDatesMisMatchError = false;
                    this.routesDatesMisMatchError = false;

                }
                // console.log(this.daySummaryDatesMisMatchError)
            }
        } else if (this.reportSections.overspeed == true) {
            if (!this.setSingleSelectedItem) {
                this.emptyVehicleError = true;
                isValid = false;
            } else {
                this.emptyVehicleError = false;
            }

            let movementLogStartDate = $('#routeHistoryStartDate').val();
            let movementLogEndDate = $('#routeHistoryEndDate').val();

            let dateObj: any = this.genericDateValidate(movementLogStartDate, movementLogEndDate);


            if (dateObj.stTimeStampString.length <= 10) {
                this.movementstartDateError = true;
                isValid = false;
            } else {
                this.movementstartDateError = false;
            }
            if (dateObj.endTimeStampString.length <= 10) {
                // if (!movementLogEndDate) {
                this.movementEndDateError = true;
                isValid = false;
            } else {
                this.movementEndDateError = false;
            }
            if (dateObj.stTimeStampString && dateObj.endTimeStampString) {
                // if (movementLogEndDate && movementLogStartDate) {
                if (dateObj.stTimeStamp > dateObj.endTimeStamp) {
                    // if ($('#routeHistoryStartDate').val() > $('#routeHistoryEndDate').val()) {
                    // this.daySummaryDatesMisMatchError = true;
                    this.routesDatesMisMatchError = true;

                    isValid = false;
                } else {
                    // this.daySummaryDatesMisMatchError = false;
                    this.routesDatesMisMatchError = false;

                }
            }

            if (this.reportTypeKeyLookup.overspeed.overspeed.value == null) {
                this.overSpeedLimitError = true;
                isValid = false;
            } else {
                this.overSpeedLimitError = false;
            }
        } else if (this.reportSections.lowspeed == true) {
            if (!this.setSingleSelectedItem.length) {
                this.emptyVehicleError = true;
                isValid = false;
            } else {
                this.emptyVehicleError = false;
            }

            let movementLogStartDate = $('#routeHistoryStartDate').val();
            let movementLogEndDate = $('#routeHistoryEndDate').val();
            let dateObj: any = this.genericDateValidate(movementLogStartDate, movementLogEndDate);

            if (dateObj.stTimeStampString.length <= 10) {

                // if (!movementLogStartDate) {
                this.movementstartDateError = true;
                isValid = false;
            } else {
                this.movementstartDateError = false;
            }
            if (dateObj.endTimeStampString.length <= 10) {
                // if (!movementLogEndDate) {
                this.movementEndDateError = true;
                isValid = false;
            } else {
                this.movementEndDateError = false;
            }
            if (dateObj.stTimeStampString && dateObj.endTimeStampString) {
                // if (movementLogEndDate && movementLogStartDate) {
                if (dateObj.stTimeStamp > dateObj.endTimeStamp) {
                    // if ($('#routeHistoryStartDate').val() > $('#routeHistoryEndDate').val()) {
                    // this.daySummaryDatesMisMatchError = true;
                    this.routesDatesMisMatchError = true;

                    isValid = false;
                } else {
                    this.routesDatesMisMatchError = false;
                }
            }
            // console.log(this.routesDatesMisMatchError);

            if (this.reportTypeKeyLookup.lowspeed.lowspeed.value == null) {
                this.lowSpeedLimitError = true;
                isValid = false;
            } else {
                this.lowSpeedLimitError = false;
            }
        } else if (this.reportSections.betweentwospeed == true) {
            if (!this.setSingleSelectedItem.length) {
                this.emptyVehicleError = true;
                isValid = false;
            } else {
                this.emptyVehicleError = false;
            }

            let movementLogStartDate = $('#routeHistoryStartDate').val();
            let movementLogEndDate = $('#routeHistoryEndDate').val();
            let dateObj: any = this.genericDateValidate(movementLogStartDate, movementLogEndDate);

            if (dateObj.stTimeStampString.length <= 10) {
                // if (!movementLogStartDate) {
                this.movementstartDateError = true;
                isValid = false;
            } else {
                this.movementstartDateError = false;
            }
            if (dateObj.endTimeStampString.length <= 10) {
                // if (!movementLogEndDate) {
                this.movementEndDateError = true;
                isValid = false;
            } else {
                this.movementEndDateError = false;
            }
            if (dateObj.stTimeStampString && dateObj.endTimeStampString) {
                // if (movementLogEndDate && movementLogStartDate) {
                if (dateObj.stTimeStampString > dateObj.endTimeStampString) {
                    // if ($('#routeHistoryStartDate').val() > $('#routeHistoryEndDate').val()) {
                    this.routesDatesMisMatchError = true;
                    // this.daySummaryDatesMisMatchError = true;
                    isValid = false;
                } else {
                    // this.daySummaryDatesMisMatchError = false;
                    this.routesDatesMisMatchError = false;
                }
            }

            if (this.reportTypeKeyLookup.betweentwospeed.twospeed.highValue == null) {
                this.HighSpeedLimitError = true;
                isValid = false;
            } else {
                this.HighSpeedLimitError = false;
            }

            if (this.reportTypeKeyLookup.betweentwospeed.twospeed.lowValue == null) {
                this.lowerSpeedLimitError = true;
                isValid = false;
            } else {
                this.lowerSpeedLimitError = false;
            }

            if (this.reportTypeKeyLookup.betweentwospeed.twospeed.highValue && this.reportTypeKeyLookup.betweentwospeed.twospeed.lowValue) {
                if (this.reportTypeKeyLookup.betweentwospeed.twospeed.lowValue > this.reportTypeKeyLookup.betweentwospeed.twospeed.highValue) {
                    this.speedMismatchError = true;
                    isValid = false;
                } else {
                    this.speedMismatchError = false;
                }
            }
        }
        else {
            //Do nothing
        }

        return isValid;
    }
    convertTimeToDecimal(time: string) {
        let convertedTime = Number(time.replace(':', '.'));
        return convertedTime;
    }
    /*Movement report function start*/
    viewMovementLog(sectionContentId: string, isFirstAPIcall: boolean): void {

        this.selected_sectionContentId = sectionContentId;
        if (this.initTrackingReportInputValidaiton()) {
            let devicesArr = [];
            this.displayErrorMessage = '';
            let selectedDevice = this.setSingleSelectedItem[0]['vehId'];
            let selectedStartTime = this.bootstrapDateTime.getInputvalue('routeHistoryStartDate').split(' ');
            let selectedEndTime = this.bootstrapDateTime.getInputvalue('routeHistoryEndDate').split(' ');
            let startDate = this.bootstrapDateTime.getInputvalue('routeHistoryStartDate');
            let endDate = this.bootstrapDateTime.getInputvalue('routeHistoryEndDate');

            let startData = startDate.split(' ');
            this.startTime = this.convertTimeToDecimal(startData[1]);
            let endData = endDate.split(' ');
            this.endTime = this.convertTimeToDecimal(endData[1]);

            let startDateService: any, endDateService: any;
            startDateService = this.dateChange.dateConversionForApicalls(startDate, 'date_time');
            endDateService = this.dateChange.dateConversionForApicalls(endDate, 'date_time');
            let chart = $('#movementLogChartContainer').highcharts();
            this.showingMapMovement = false; //hide route plotting on map
            devicesArr.push(selectedDevice);


            this.movementLogChartData = [];
            if (isFirstAPIcall) {
                this.vehicleMovementLog = [];
                this.movementLogChartData = [];
                this.pageJump = 0;
                this.routePlotHeaderConf = {};
                this.isLoading = true; //show div loader in first API call.
                this.showTableRowLoader = false; //hide table rows loader.
            } else {
                this.showTableRowLoader = true;
            }

            if (this.report == 'Movement Log' || this.report == 'Halt Log' || this.report == 'Movement & Halt log') {

                devicesArr = [];

                devicesArr = [this.vehid];

            } else {
                devicesArr;
            }


            let apiHeader: any;
            if (sectionContentId === 'vehcoordinate') {
                apiHeader = {
                    data: {
                        key: localStorage.getItem('scbToken'),
                        // form: {
                        //     vehId: devicesArr,
                        //     fromDate: selectedStartTime[0],
                        //     fromTime: selectedStartTime[1] + ':00',
                        //     toDate: selectedEndTime[0],
                        //     toTime: selectedEndTime[1] + ':00',
                        // },
                        form: {
                            vehId: devicesArr,
                            fromDate: startDateService.date,
                            fromTime: startDateService.time,
                            toDate: endDateService.date,
                            toTime: endDateService.time,
                            coordinates: 'yes',
                            // trackingDev:'tab',
                        },
                        extra: {
                            pageJump: this.pageJump
                        }
                    }
                }
            } else {
                apiHeader = {
                    data: {
                        key: localStorage.getItem('scbToken'),
                        // form: {
                        //     vehId: devicesArr,
                        //     fromDate: selectedStartTime[0],
                        //     fromTime: selectedStartTime[1] + ':00',
                        //     toDate: selectedEndTime[0],
                        //     toTime: selectedEndTime[1] + ':00',
                        // },
                        form: {
                            vehId: devicesArr,
                            fromDate: startDateService.date,
                            fromTime: startDateService.time,
                            toDate: endDateService.date,
                            toTime: endDateService.time,

                            // trackingDev:'tab',
                        },
                        extra: {
                            pageJump: this.pageJump
                        }
                    }
                }

            }

            if (this.trackingDevice === 'tab') {
                apiHeader.data.form['trackingDev'] = this.trackingDevice;
            }


            let vehIdtoFind = apiHeader.data.form.vehId[0];
            let vehNameFound = this.vehicleService.findVehicle(vehIdtoFind);
            this.selectedVehicleName = vehNameFound.vehNo
            if (Reflect.get(this.reportTypeKeyLookup, sectionContentId)) {
                apiHeader.data.form['speed'] = Reflect.get(this.reportTypeKeyLookup, sectionContentId)
            }

            this.routePlotHeaderConf = apiHeader;

            this.apiService.getMovementReport(apiHeader).then(response => {
                if (response.status === 'success') {

                    this.NoRecordsFoundHideMessage = false;
                    if (response.response) {
                        if (!this.vehicleMovementLog.length) {

                            this.vehicleMovementLog = response.response;

                        } else {

                            this.vehicleMovementLog = [...this.vehicleMovementLog, ...response.response];
                        }

                        for (let info of response.response) {
                            let obj = {
                                x: parseInt(info.unicTimeStamp),
                                y: info.speed
                            }
                            this.movementLogChartData.push(obj);
                        }

                        setTimeout(() => {
                            this.initMovementLogChart();
                        }, 100);
                        if (isFirstAPIcall) {

                            let desiredSpeed;
                            if (apiHeader.data.form['speed']) {
                                desiredSpeed = JSON.stringify(apiHeader.data.form['speed']);
                            } else {
                                desiredSpeed = JSON.stringify({
                                    movementhalt: { value: 0 }
                                });
                            }

                            if (sectionContentId == 'vehcoordinate') {
                                this.movementReportExcelDownloadLink = `${this.apiService.base}tracking/downloadtrackingdata&key=${localStorage.getItem('scbToken')}&vehId=${JSON.stringify(apiHeader.data.form.vehId)}&speed=${desiredSpeed}&fromDate=${apiHeader.data.form.fromDate}&fromTime=${apiHeader.data.form.fromTime}&toDate=${apiHeader.data.form.toDate}&toTime=${apiHeader.data.form.toTime}&coordinates='yes'`;
                            } else {

                                this.movementReportExcelDownloadLink = `${this.apiService.base}tracking/downloadtrackingdata&key=${localStorage.getItem('scbToken')}&vehId=${JSON.stringify(apiHeader.data.form.vehId)}&speed=${desiredSpeed}&fromDate=${apiHeader.data.form.fromDate}&fromTime=${apiHeader.data.form.fromTime}&toDate=${apiHeader.data.form.toDate}&toTime=${apiHeader.data.form.toTime}`;
                                if (this.trackingDevice === 'tab') {
                                    this.movementReportExcelDownloadLink = `${this.apiService.base}tracking/downloadtrackingdata&key=${localStorage.getItem('scbToken')}&vehId=${JSON.stringify(apiHeader.data.form.vehId)}&speed=${desiredSpeed}&fromDate=${apiHeader.data.form.fromDate}&fromTime=${apiHeader.data.form.fromTime}&toDate=${apiHeader.data.form.toDate}&toTime=${apiHeader.data.form.toTime}&trackingDev='tab'`;
                                }
                            }

                        } else {
                            chart.series[0].update({});
                        }
                        this.noMoredata = false;
                        this.showTableRowLoader = false;

                    } else {
                        this.noMoredata = true;
                        this.pageJump = 0;
                        this.showTableRowLoader = false;
                        this.NoRecordsFoundHideMessage = true;
                    }


                } else {
                    this.displayErrorMessage = this.globalUtils.getErrorMsg(response.ec);
                    // console.log("sectionContentId : "+sectionContentId);
                    this.NoRecordsFoundHideMessage = false;
                }

                if (isFirstAPIcall) {
                    this.isLoading = false;
                    this.initializeReportContent(sectionContentId);
                }
            }).catch(error => { })

        }

    }
    pagination(type: string) {
        this.vehicleMovementLog = [];
        if (type == 'next') {

            this.pageJump++;
            console.log(this.pageJump)
        } else if (type == 'prev') {

            this.pageJump--;
        }

        this.viewMovementLog(this.selected_sectionContentId, false);



    }


    initMovementLogChart(): void {

        //console.log('tttttttttttt ', this.movementLogChartData);
        Highcharts.setOptions({
            global: {
                useUTC: false
            }
        });
        Highcharts.chart('movementLogChartContainer', {
            chart: {
                zoomType: 'x'
            },
            title: {
                text: ''
            },
            credits: {
                enabled: false
            },
            xAxis: {
                type: 'datetime',
                dateTimeLabelFormats: {
                    second: '%H:%M:%S'
                },
                title: {
                    text: null
                },
                showFirstLabel: true,
                showLastLabel: true
            },
            yAxis: {
                title: {
                    text: 'Speed',
                    margin: 10,
                    align: 'middle'
                },
                min: 0,
                labels: {
                    formatter: function () {
                        if (this.value >= 60) {
                            return '<span style="fill: #2E77E8;">' + this.value + '</span>';
                        } else if (this.value == 0) {
                            return '<span style="fill: red;">' + this.value + '</span>';
                        } else {
                            return '<span style="fill: #2EA737;">' + this.value + '</span>';
                        }
                    }
                },
            },
            plotOptions: {
                series: {
                    marker: {
                        radius: 2
                    }
                }
            },
            legend: {
                enabled: false
            },
            series: [{
                type: 'line',
                name: 'Speed Trends',
                data: this.movementLogChartData,
                turboThreshold: 0,
                zones: [{
                    value: 3,
                    color: '#FF2319'
                }, {
                    value: 60,
                    color: '#90ED7D'
                }, {
                    color: '#3CB3FF'
                },
                ],
            }]
        });

    }

    processLocationDetails(details: any) {
        return ('nearestLocation' in details) ? details.nearestLocation : '-';
    }

    showMovementOnMap(): void {
        this.showingMapMovement = true;
        this.routePlotRecords = [];
        this.showingRoute = false;
        this.movingMarker = undefined;
        this.currentPlottedRecordPointer = 0;
        this.noMoreRecodsToBePlotted = true;
        let parent = this;
        setTimeout(async function () {
            parent.initMap('movement');

            if (parent.setSingleSelectedItem && parent.setSingleSelectedItem.length > 0) {
                await parent.fetchPPlist(parent.setSingleSelectedItem[0]['vehId']).then((res: any) => {
                    if (parent.globalRoutePPobj && Object.keys(parent.globalRoutePPobj).length > 0) {
                        Object.keys(parent.globalRoutePPobj).map((routeKey, index) => {
                            parent.getPPmarker(parent.globalRoutePPobj[routeKey], index);
                        })
                    }
                })
            }

        }, 200)
    }

    hideMovementOnMap(): void {
        this.showingMapMovement = false;
        this.showingRoute = false;
        this.movingMarker = undefined;
        this.routePlotRecords = [];
        this.currentPlottedRecordPointer = 0;
        this.noMoreRecodsToBePlotted = true;
        this.viewMovementLog('movement', true);
    }

    showHaltPointsOnMap(): void {
        this.showingMapMovement = true;
        this.currentPlottedRecordPointer = 0;
        let parent = this;
        setTimeout(function () {
            parent.initMap('halt');
        }, 200)
    }

    hideHaltPointsOnMap(): void {
        this.showingMapMovement = false;
        this.movingMarker = undefined;
        this.currentPlottedRecordPointer = 0;
        this.viewMovementLog('halt', true);
    }


    showMovementWithHaltOnMap(): void {
        this.showingMapMovement = true;
        this.routePlotRecords = [];
        this.showingRoute = false;
        this.movingMarker = undefined;
        this.currentPlottedRecordPointer = 0;
        this.noMoreRecodsToBePlotted = true;
        let parent = this;
        setTimeout(function () {
            parent.initMap('movementAndHalt');
        }, 200);
    }

    hideMovementWithHaltOnMap(): void {
        this.showingMapMovement = false;
        this.showingRoute = false;
        this.movingMarker = undefined;
        this.routePlotRecords = [];
        this.currentPlottedRecordPointer = 0;
        this.noMoreRecodsToBePlotted = true;
        this.viewMovementLog('movementandhalt', true);
    }

    initMap(mapCallBackType: string): void {
        let mapOptions = {
            scrollwheel: true,
            zoom: 13,
            center: new google.maps.LatLng(28.7041, 77.1025),
            disableDefaultUI: false
        }
        this.globalMapObj = new google.maps.Map(document.getElementById('mapContainer'), mapOptions);
        this.routePoly = new google.maps.Polyline(this.polyOptions);
        this.routePoly.setMap(this.globalMapObj);
        if (mapCallBackType == 'movement') this.fetchRouteData(true);
        else if (mapCallBackType == 'halt') this.fetchHaltData(true);
        else if (mapCallBackType == 'movementAndHalt') this.fetchRouteData(true); //re-using the same function.
        else if (mapCallBackType == 'haltDuration') this.plotHaltPointsWithDuration();   //no page jumnp , so no parameters for tracking first call in function.
    }

    fetchHaltData(isFirstCall: boolean): void {
        this.routePlotRecords = [...this.vehicleMovementLog];
        this.plotHaltedRoute(this.routePlotRecords);
    }

    plotHaltedRoute(records: any) {
        let bounds = new google.maps.LatLngBounds();
        let haltMarkers: any = [];
        for (let record of records) {
            let mLatlng = new google.maps.LatLng(parseFloat(record.location.lat), parseFloat(record.location.lng));
            let routeIcon: any;
            let parent = this;
            bounds.extend(mLatlng);
            let routeMarker = new google.maps.Marker({
                position: mLatlng,
                icon: './.../../assets/webroot/images/map/geo-2.png',
                map: this.globalMapObj,
            });
            let infoWindow = new google.maps.InfoWindow();

            routeMarker.addListener('mouseover', function () {
                let content = '<div><b>Location:</b>' + record.nearestLocation + '<br/><b>Speed:</b>' + (record['speed'] ? record['speed'] : 0) + '<br/><b>Date:</b> ' + record.gpsTimeStamp + '</div>'
                infoWindow.setContent(content);
                infoWindow.open(parent.globalMapObj, this);
            });

            routeMarker.addListener('mouseout', function () {
                infoWindow.close();
            });
            haltMarkers.push(routeMarker);
            this.currentPlottedRecordPointer++;

        }
        let clusteringOptions = {
            styles: [{
                url: "https://googlemaps.github.io/js-marker-clusterer/images/m1.png",
                width: 53,
                height: 53,
                textSize: 12,
                textColor: "white",
            }],
            minimumClusterSize: 3,
            maxZoom: 15
        };
        this.haltMarkerClusters = new MarkerClusterer(this.globalMapObj, haltMarkers, clusteringOptions);
        this.globalMapObj.fitBounds(bounds);
    }

    fetchRouteData(isFirstCall: boolean): void {
        if (!isFirstCall) {
            let curJump = this.routePlotHeaderConf.data.extra.pageJump;
            this.routePlotHeaderConf.data.extra.pageJump = ++curJump;
        } else {
            this.routePlotHeaderConf.data.extra.pageJump = 0;
        }

        // console.log(this.routePlotHeaderConf);
        //  if(this.trackingDevice === 'tab'){
        this.apiService.getMovementReport(this.routePlotHeaderConf).then(response => {
            if (response.response) { // data exists increase pagejump and hit API again.
                //let curJump = this.routePlotHeaderConf.data.extra.pageJump;
                //this.routePlotHeaderConf.data.extra.pageJump = ++curJump;
                if (!this.routePlotRecords.length) {
                    this.routePlotRecords = response.response;
                } else {
                    this.routePlotRecords = [...this.routePlotRecords, ...response.response];
                }
                this.noMoreRecodsToBePlotted = true;
                setTimeout(() => this.fetchRouteData(false), 500);
            } else {
                this.noMoreRecodsToBePlotted = true;
                this.plotDeviceRoute(this.routePlotRecords);
            }
        }).catch(error => { })
    }
    fetchPPlist(vehicle: any) {
        return new Promise(async (resolve, reject) => {
            try {
                let routeList: any = []
                this.globalRoutePPobj = {};

                await this.fetchVehRouteData(vehicle)
                    .then((res) => routeList = res)
                    .catch((err) => reject(err));

                if (routeList.length <= 0) reject('no routes available');
                let routeIDs = routeList.map((item: any) => {
                    if (this.convertTimeToDecimal(item.routeStartTime) >= this.startTime && this.convertTimeToDecimal(item.routeStartTime) <= this.endTime) {
                        this.globalRoutePPobj[item.routeId] = item;
                        return item.routeId
                    }
                }).filter((item: any) => item != undefined);

                let ppHeader: any = {
                    data: {
                        key: localStorage.getItem('scbToken'),
                        form: {
                            routeList: routeIDs,
                        }
                    }
                }
                await this.getPPlist(ppHeader)
                    .then((res: any) => {
                        if (res.status == 'success') {
                            if (res.response && Object.keys(res.response).length > 0) {
                                Object.keys(res.response).map((key: string) => {
                                    this.globalRoutePPobj[key] = { ...this.globalRoutePPobj[key], pickuppoints: res.response[key] }
                                })
                            } else reject('No pickuppoints');
                        } else reject('Failure on getting pickup points')
                    })
                    .catch((err) => reject(err));

                resolve(1);
            } catch (err) {
                reject(err);
            }
        })
    }

    getPPlist(obj: any) {
        return new Promise((resolve, reject) => {
            this.apiService.getAllPPList(obj).then((response) => {
                resolve(response);
            }).catch((err) => {
                reject(err);
            })

        })
    }
    fetchVehRouteData(vehicle: any) {
        return new Promise((resolve, reject) => {

            try {
                let results = [];
                let apiHeader: any = {
                    data: {
                        key: localStorage.getItem('scbToken'),
                        extra: {
                            pageJump: this.pageJump
                        },
                        //   filter:{
                        //     regNo: vehicle
                        //   }
                    }
                }
                //    if(vehicle){
                //        apiHeader.data['filter']['regNo'] = vehicle;
                //    }

                this.apiService.getAllVehicleAllRouteReport(apiHeader).then(response => {
                    if (response.response) {
                        if (response.response['vehDetails'] && response.response['vehDetails'][vehicle] &&
                            response.response['vehDetails'][vehicle]['RouteInfo'] && Object.keys(response.response['vehDetails'][vehicle]['RouteInfo']).length > 0) {
                            results = Object.keys(response.response['vehDetails'][vehicle]['RouteInfo']).map((item) => response.response['vehDetails'][vehicle]['RouteInfo'][item]);
                            resolve(results);
                        } else {
                            reject('no data found for vehicle');
                        }
                    } else {
                        reject('failure response');
                    }
                }).catch((err) => {
                    reject(err);
                })
            } catch (err) {
                reject(err);
            }
        })
    }

    getPPmarker(route: any, routeIndex: number = 0) {

        let markerLabel = 'PP';
        if (route.routeType && route.routeType.length > 0) {
            markerLabel = String(String(route.routeType).charAt(0)).toUpperCase();
        } else if (route.routeName && route.routeName.length > 0) {
            markerLabel = String(String(route.routeName).charAt(route.routeName.length - 1)).toUpperCase();
        }
        if (route.pickuppoints && route.pickuppoints.length > 0) {
            for (let p = 0; p < route.pickuppoints.length - 1; p++) {
                let pickuppoint = route.pickuppoints[p];
                let mLatlng = new google.maps.LatLng(parseFloat(pickuppoint.pickuppointLocation.lat), parseFloat(pickuppoint.pickuppointLocation.lng));
                let marker = new google.maps.Marker({
                    position: mLatlng,
                    map: this.globalMapObj,
                    //animation: google.maps.Animation.DROP,
                    label: {
                        text: markerLabel,
                        color: 'white',
                    },
                    icon: {
                        url: "./.../../assets/webroot/images/map/pin-red.png",
                        //size: new google.maps.Size(64, 64),
                        scaledSize: new google.maps.Size(65, 65),
                        origin: new google.maps.Point(0, 0),
                        anchor: new google.maps.Point(20, 40),
                        labelOrigin: new google.maps.Point(32, 14)
                    },
                    zIndex: 9999 + routeIndex + p
                });

                const html = `<p style="padding-right:10px;">
                <b>Route : </b>${route.routeName}<br/>
                <b>Pickup point : </b>${pickuppoint.pickuppoint}<br/>    
                <b>ETA : </b> ${pickuppoint.expectedTime}</br>          
                </p>
                `;

                let infoWindow = new google.maps.InfoWindow({
                    maxWidth: 300,
                });
                let parent = this;
                marker.addListener('mouseover', function () {
                    let content = html;
                    infoWindow.setContent(content);
                    infoWindow.open(parent.globalMapObj, this);
                });

                marker.addListener('mouseout', function () {
                    infoWindow.close();
                });
            }
        }
    }

    plotDeviceRoute(records: any): void {
        let bounds = new google.maps.LatLngBounds();
        let len = records.length;
        let $record = records[this.currentPlottedRecordPointer];
        let path = this.routePoly.getPath();
        let mLatlng = new google.maps.LatLng(parseFloat($record.location.lat), parseFloat($record.location.lng));
        let routeIcon: any;
        path.push(mLatlng);
        bounds.extend(mLatlng);
        if (this.currentPlottedRecordPointer == 0) routeIcon = './.../../assets/webroot/images/map/start.png';
        else if (this.currentPlottedRecordPointer == this.routePlotRecords.length - 1) {
            routeIcon = './.../../assets/webroot/images/map/finish.png';
        } else {
            routeIcon = this.getRouteMarkerIcon(null, $record.speed);
        }
        let routeMarker = new google.maps.Marker({
            position: mLatlng,
            icon: routeIcon,
            map: this.globalMapObj,
            zIndex: path.getLength()
        });

        let infoWindow = new google.maps.InfoWindow({ maxWidth: 320 });
        routeMarker.addListener('mouseover', function () {
            let content = `<div><b>Location:</b><span class="locationText" style="">${$record.nearestLocation}</span><br/><b>Speed:</b>${$record['speed'] ? $record['speed'] : 0}<br/><b>Updated On:</b>${$record.gpsTimeStamp}</div>`
            infoWindow.setContent(content);
            infoWindow.open(this.globalMapObj, this);
        });

        routeMarker.addListener('mouseout', function () {
            infoWindow.close();
        });

        if (this.currentPlottedRecordPointer == 0) routeMarker.setZIndex(9000);
        else if (this.currentPlottedRecordPointer == this.routePlotRecords.length - 1) {
            routeMarker.setZIndex(9500);
        }

        if (this.movingMarker == undefined) {
            this.movingMarker = new google.maps.Marker({
                position: new google.maps.LatLng(0, 0),
                map: this.globalMapObj,
                icon: './.../../assets/webroot/images/map/bus_main.png'
            });
        } else {
            if (!this.globalMapObj.getBounds().contains(this.movingMarker.getPosition())) {
                this.globalMapObj.panTo(mLatlng);
            }
            if (this.currentPlottedRecordPointer == this.routePlotRecords.length - 1) {
                this.movingMarker.setMap(null); //hide moving marker after plotting last record.
            }
        }
        //var movingMarker = new google.maps.Marker({position: new google.maps.LatLng(0,0), map: this.globalMapObj, title: 'Hello World!'});
        //var newPosition = new google.maps.LatLng(records[0].location.lat,records[1].location.lng);
        let parent = this;
        this.animateMarker.animateTo(this.movingMarker, mLatlng, {
            easing: "linear",
            duration: 30,
            complete: function () {
                parent.showingRoute = true;
                parent.currentPlottedRecordPointer++;
                if (parent.currentPlottedRecordPointer < parent.routePlotRecords.length) {
                    parent.plotDeviceRoute(parent.routePlotRecords);
                } else {
                    return false;
                }
            }
        });
    }

    getRouteMarkerIcon(deviceType: string, speed: number) {
        let userName = localStorage.getItem('orguserName');
        if (userName && userName == 'snsn1' || userName == 'snsng10' || userName == 'snsnco' || userName == 'snsn') {
            if (speed >= 0 && speed <= 5) {
                return { url: "./..../../assets/webroot/images/map/map_orange.png", size: new google.maps.Size(7, 7), origin: new google.maps.Point(0, 0), anchor: new google.maps.Point(3.5, 3.5) };
            } else if (speed >= 6 && speed <= 60) {
                return { url: "./..../../assets/webroot/images/map/map_green.png", size: new google.maps.Size(7, 7), origin: new google.maps.Point(0, 0), anchor: new google.maps.Point(3.5, 3.5) };
            } else if (speed >= 61 && speed <= 70) {
                return { url: "./..../../assets/webroot/images/map/map_blue.png", size: new google.maps.Size(7, 7), origin: new google.maps.Point(0, 0), anchor: new google.maps.Point(3.5, 3.5) };
            } else if (speed >= 71) {
                return { url: "./..../../assets/webroot/images/map/map_red.png", size: new google.maps.Size(7, 7), origin: new google.maps.Point(0, 0), anchor: new google.maps.Point(3.5, 3.5) };
            }
        } else {
            if (speed > 50) {
                return { url: "./..../../assets/webroot/images/map/map_blue.png", size: new google.maps.Size(7, 7), origin: new google.maps.Point(0, 0), anchor: new google.maps.Point(3.5, 3.5) };
            } else if (speed <= 5) {
                return { url: "./..../../assets/webroot/images/map/map_red.png", size: new google.maps.Size(7, 7), origin: new google.maps.Point(0, 0), anchor: new google.maps.Point(3.5, 3.5) };
            } else if (speed > 5) {
                return { url: "./..../../assets/webroot/images/map/map_green.png", size: new google.maps.Size(7, 7), origin: new google.maps.Point(0, 0), anchor: new google.maps.Point(3.5, 3.5) };
            } else {
                return { url: "./..../../assets/webroot/images/map/map_green.png", size: new google.maps.Size(7, 7), origin: new google.maps.Point(0, 0), anchor: new google.maps.Point(3.5, 3.5) };
            }
        }
    }

    plotMovementLocation(info: any) {
        if (info.nearestLocation) {
            $('#myModal').modal('show');

            //map initialization.
            let mapOptions = {
                scrollwheel: true,
                zoom: 17,
                maxZoom: 18,
                center: new google.maps.LatLng(info.location.lat, info.location.lng),
                disableDefaultUI: false
            }
            let haltedMap = new google.maps.Map(document.getElementById('pointLocationMap'), mapOptions);
            let mLatlng = new google.maps.LatLng(parseFloat(info.location.lat), parseFloat(info.location.lng));
            let haltLocationMarker = new google.maps.Marker({
                position: mLatlng,
                icon: './..../../assets/webroot/images/map/geo-2.png',
                map: haltedMap,
            });

            let infoWindow = new google.maps.InfoWindow({ maxWidth: 320 });

            haltLocationMarker.addListener('mouseover', function () {
                let content = `<div><b>Location:</b><span class="locationText" style="">${info.nearestLocation}</span><br/><b>Speed:</b>${info.speed} Km/h</div>`
                infoWindow.setContent(content);
                infoWindow.open(haltedMap, this);
            });

            haltLocationMarker.addListener('mouseout', function () {
                infoWindow.close();
            });
        } else {
            $('#myModal').modal('hide');
        }
    }
    /*Movement report function end*/
    initiateDaysummaryInputValidation() {
        let isValid: boolean = true;

        if (this.selectVeh_daySummary != 'chooseSpecificVeh') {
            this.emptyVehicleError = false;
        } else {
            if (!this.daySummaryVehSelection.length) {
                this.emptyVehicleError = true;
                isValid = false;
            } else {
                this.emptyVehicleError = false;
            }
        }

        let daySummaryStartDate = $('#routeHistoryStartDate').val();
        let daySummaryEndDate = $('#routeHistoryEndDate').val();

        let dateObj = this.genericDateValidate(daySummaryStartDate, daySummaryEndDate);

        if (dateObj.stTimeStampString.length <= 10) {
            // if (!daySummaryStartDate) {
            this.daySummaryStartDateError = true;
            isValid = false;
        } else {
            this.daySummaryStartDateError = false;
        }
        if (dateObj.endTimeStampString.length <= 10) {
            // if (!daySummaryEndDate) {
            this.daySummaryEndDateError = true;
            isValid = false;
        } else {
            this.daySummaryEndDateError = false;
        }
        if (dateObj.stTimeStamp && dateObj.endTimeStamp) {
            // if ($('#routeHistoryStartDate').val() > $('#routeHistoryEndDate').val()) {
            if (dateObj.stTimeStamp > dateObj.endTimeStamp) {
                this.daySummaryDatesMisMatchError = true;
                isValid = false;
            } else {
                this.daySummaryDatesMisMatchError = false;
            }
        }

        return isValid;
    }

    /*Day summary report funcitonality starts*/
    viewDaySummary(sectionContentId: string): void {

        // this.disableViewBtn = true;
        if (this.initiateDaysummaryInputValidation()) {
            this.displayErrorMessage = '';
            let apiHeader = {
                data: {
                    key: localStorage.getItem('scbToken'),
                    form: {
                        vehId: this.selectVeh_daySummary == 'chooseSpecificVeh' ? this.daySummaryVehSelection : 'all',
                        fromDate: this.dateChange.dateConversionForApicalls(this.bootstrapDateTime && this.bootstrapDateTime.getInputvalue('routeHistoryStartDate') ? this.bootstrapDateTime.getInputvalue('routeHistoryStartDate').trim() : '', 'onlydate'),
                        toDate: this.dateChange.dateConversionForApicalls(this.bootstrapDateTime.getInputvalue('routeHistoryEndDate') ? this.bootstrapDateTime.getInputvalue('routeHistoryEndDate').trim() : '', 'onlydate')
                    }
                }
            }
            this.vehicleDaySummary = [];
            this.isLoading = true; //show div loader in first API call.
            this.noDaySummaryRecords = false;
            this.daySummaryStartDate = this.bootstrapDateTime.getInputvalue('routeHistoryStartDate') ? this.bootstrapDateTime.getInputvalue('routeHistoryStartDate').trim() : '';
            this.daySummaryStartDate = this.dateChange.dateConversionForApicalls(this.daySummaryStartDate, 'onlydate');
            this.daySummaryEndDate = this.bootstrapDateTime.getInputvalue('routeHistoryEndDate') ? this.bootstrapDateTime.getInputvalue('routeHistoryEndDate').trim() : '';
            this.daySummaryEndDate = this.dateChange.dateConversionForApicalls(this.daySummaryEndDate, 'onlydate');
            this.apiService.getDaySummary(apiHeader).then(response => {
                if (response.status === 'success') {
                    this.displayErrorMessage = '';
                    this.noDaySummaryRecords = false;
                    this.NoRecordsFoundHideMessage = false;
                    if (response.response) {
                        let apiRes = response.response;
                        if (this.selectVeh_daySummary == "all") {

                            this.vehicleDaySummary2 = [];

                            Object.keys(apiRes).forEach((item: any) =>
                                Object.keys(apiRes[item]).map(itm => this.vehicleDaySummary2.push(apiRes[item][itm])));


                        } else {




                            let selDevices = this.daySummaryVehSelection;
                            this.daySummaryDateSelections = Object.keys(apiRes);
                            for (let veh of selDevices) {
                                let arr = [];
                                let obj = {};
                                let kmsTravelled: any = 0;
                                for (let dateIn of this.daySummaryDateSelections) {
                                    let dummyObj = {};
                                    if (apiRes[dateIn][veh]) {
                                        kmsTravelled += parseFloat(apiRes[dateIn][veh]['totalDistance']);
                                        dummyObj = apiRes[dateIn][veh];
                                    } else {
                                        dummyObj = {
                                            totalDistance: '-'
                                        }
                                    }
                                    arr.push(dummyObj);
                                }

                                if (kmsTravelled) {
                                    kmsTravelled = kmsTravelled.toFixed(2);
                                } else {
                                    kmsTravelled = '-';
                                }
                                let currentDevice = this.allOrgVehicles.filter(function (index) { return index.vehId == veh })[0];
                                this.vehicleDaySummary.push({ vehInfo: arr, vehNo: currentDevice['vehNo'], totalKmsTravelled: kmsTravelled });
                            }

                        }


                    } else {
                        this.noDaySummaryRecords = true;
                        this.isLoading = false;
                        this.NoRecordsFoundHideMessage = true;//
                    }
                    if (this.selectVeh_daySummary == "all") {
                        this.daySummaryReportExcelDownloadLink2 = `${this.apiService.base}tracking/download_daysummary/&key=${localStorage.getItem('scbToken')}/&vehId=all&fromDate=${this.daySummaryStartDate}&toDate=${this.daySummaryEndDate}`;
                    } else {
                        this.daySummaryReportExcelDownloadLink = `${this.apiService.base}tracking/download_daysummary/&key=${localStorage.getItem('scbToken')}/&vehId=${JSON.stringify(this.daySummaryVehSelection)}&fromDate=${this.daySummaryStartDate}&toDate=${this.daySummaryEndDate}`;
                    }
                    // this.disableViewBtn = false;
                    this.isLoading = false;
                    this.initializeReportContent(sectionContentId);
                } else {
                    this.NoRecordsFoundHideMessage = false;
                    // alert(this.isLoading);
                    this.isLoading = false;
                    this.displayErrorMessage = this.globalUtils.getErrorMsg(response.ec);
                }

            }).catch(error => { })
        }

    }

    setUserSelections(devices: any) {
        this.daySummaryVehSelection = devices.map((vehInfo: any) => { return vehInfo.vehId });
        return this.daySummaryVehSelection;
    }
    /*Day summary report funcitonality ends*/


    /*Halt duration count function starts here*/
    viewHaltCoutReportValidation() {
        let isValid = true;

        if (this.setSingleSelectedItem.length == 0 || this.setSingleSelectedItem[0]['vehId'] == '') {

            isValid = false;
            this.haltDurationVehilError = true;
        } else {


            this.haltDurationVehilError = false;

        }

        if (!this.bootstrapDateTime.getInputvalue('routeHistoryStartDate')) {
            isValid = false;
            this.haltDurationDateError = true;

        } else {
            this.haltDurationDateError = false;
        }

        return isValid;
    }
    viewHaltCountReport(sectionContentId: string): void {
        // halt duration report apicalls not uses datechange services
        if (this.viewHaltCoutReportValidation()) {
            this.haltDurationMinutes = this.haltDurationMinutes === null ? 1 : this.haltDurationMinutes > 60 ? 60 : this.haltDurationMinutes;
            if (this.haltDurationMinutes <= 60 && this.haltDurationMinutes >= 1) {
                let selectedDevice = this.setSingleSelectedItem[0]['vehId'];
                let selectedStartTime = this.bootstrapDateTime.getInputvalue('routeHistoryStartDate') ? this.bootstrapDateTime.getInputvalue('routeHistoryStartDate').trim() : '';
                selectedStartTime = this.dateReverser(selectedStartTime);
                let selectedEndTime = selectedStartTime; //this.bootstrapDateTime.getInputvalue('routeHistoryEndDate') ? this.bootstrapDateTime.getInputvalue('routeHistoryEndDate').trim(): '';
                let vehArr: any = [];
                vehArr.push(selectedDevice);



                // let minutes = (this.haltDurationMinutes!=''||)?;

                let apiHeader = {
                    data: {
                        key: localStorage.getItem('scbToken'),
                        form: {
                            vehId: vehArr,
                            toDate: selectedStartTime,
                            fromDate: selectedStartTime,
                            haltMin: this.haltDurationMinutes
                        }
                    }
                }
                this.isLoading = true; //show div loader in first API call.
                this.vehicleMovementLog = [];
                this.showingMapMovement = false;
                this.daySummaryStartDate = selectedStartTime;
                this.daySummaryEndDate = selectedEndTime;
                this.apiService.getHaltDurationLog(apiHeader).then(response => {
                    if (response.response.haltdatas) {
                        this.vehicleMovementLog = [...response.response.haltdatas];
                        this.movementReportExcelDownloadLink = `${this.apiService.base}tracking/download_haltsummary&key=${localStorage.getItem('scbToken')}&vehId=${JSON.stringify(vehArr)}&fromDate=${selectedStartTime}&toDate=${selectedEndTime}`;
                    }
                    this.isLoading = false;
                    this.initializeReportContent(sectionContentId);
                }).catch(error => { })
            }
        }
    }

    plotHaltLocation(haltInfo: any) {
        $('#myModal').modal('show');

        //map initialization.
        let mapOptions = {
            scrollwheel: true,
            zoom: 17,
            maxZoom: 18,
            center: new google.maps.LatLng(haltInfo.location.lat, haltInfo.location.lng),
            disableDefaultUI: false
        }
        let haltedMap = new google.maps.Map(document.getElementById('pointLocationMap'), mapOptions);
        let mLatlng = new google.maps.LatLng(parseFloat(haltInfo.location.lat), parseFloat(haltInfo.location.lng));
        let haltLocationMarker = new google.maps.Marker({
            position: mLatlng,
            icon: './..../../assets/webroot/images/map/geo-2.png',
            map: haltedMap,

        });

        let infoWindow = new google.maps.InfoWindow();

        haltLocationMarker.addListener('mouseover', function () {
            let content = '<div><b>Location:</b>' + haltInfo.nearestLocation + '<br/><b>Halt Duration:</b> ' + haltInfo.haltduration + '</div>'
            infoWindow.setContent(content);
            infoWindow.open(haltedMap, this);
        });

        haltLocationMarker.addListener('mouseout', function () {
            infoWindow.close();
        });
    }

    showHaltPointsWithDurationOnMap(): void {
        this.showingMapMovement = true;
        this.currentPlottedRecordPointer = 0;
        let parent = this;
        setTimeout(function () {
            parent.initMap('haltDuration');
        }, 200)
    }

    hideHaltPointsWithDurationOnMap(): void {
        this.showingMapMovement = false;
        this.movingMarker = undefined;
        this.currentPlottedRecordPointer = 0;
        this.viewHaltCountReport('haltDurationReport');
    }

    plotHaltPointsWithDuration() {
        let bounds = new google.maps.LatLngBounds();
        let haltMarkers: any = [];
        for (let record of this.vehicleMovementLog) {
            let mLatlng = new google.maps.LatLng(parseFloat(record.location.lat), parseFloat(record.location.lng));
            let routeIcon: any;
            let parent = this;
            bounds.extend(mLatlng);
            let routeMarker = new google.maps.Marker({
                position: mLatlng,
                icon: './.../../assets/webroot/images/map/geo-2.png',
                map: this.globalMapObj,
            });
            let infoWindow = new google.maps.InfoWindow({ maxWidth: 400 });
            let formattedStartTime = new UnixTimeStampFormatter().transform(record.haltstartTime);
            let formattedEndTime = new UnixTimeStampFormatter().transform(record.haltendTime);
            routeMarker.addListener('mouseover', function () {
                let content = '<div><b>Location:</b>' + record.nearestLocation + '<br/><b>Halt start time:</b> ' + formattedStartTime + '<br/><b>Halt End Time:</b>' + formattedEndTime + '<br/><b>Halt Duration:</b>' + record.haltduration + '</div>'
                infoWindow.setContent(content);
                infoWindow.open(parent.globalMapObj, this);
            });

            routeMarker.addListener('mouseout', function () {
                infoWindow.close();
            });
            haltMarkers.push(routeMarker);
            this.currentPlottedRecordPointer++;

        }
        let clusteringOptions = {
            styles: [{
                url: "https://googlemaps.github.io/js-marker-clusterer/images/m1.png",
                width: 53,
                height: 53,
                textSize: 12,
                textColor: "white",
            }],
            minimumClusterSize: 3,
            maxZoom: 15
        };
        this.haltMarkerClusters = new MarkerClusterer(this.globalMapObj, haltMarkers, clusteringOptions);
        this.globalMapObj.fitBounds(bounds);
    }



    /*Halt duration count function ends here*/

    initChosen() {
        setTimeout(() => {
            //this.chosenUtility.initializeChosen('availableReportsDropdown');
        }, 100);
        this.bindJqChangeEvt('demo-select');
    }

    initJqUtils(eleChosenId?: string, eleReportStartTime?: string, eleReportEndTime?: string, pickerConfig?: any) {
        let parent = this;
        let timePicker: boolean;
        let closePickerOnDateSelect: boolean;
        let timeFormat: any;
        if ((pickerConfig) && (Object.keys(pickerConfig).length)) {
            timePicker = pickerConfig.timePicker;
            timeFormat = pickerConfig.timeFormat;
            closePickerOnDateSelect = ('closePickerOnDateSelect' in pickerConfig) ? pickerConfig.closePickerOnDateSelect : false;
        } else {
            timePicker = true;
            timeFormat = 'Y-m-d H:i:s';
            closePickerOnDateSelect = false;
        }
        setTimeout(() => {
            if (eleChosenId) this.chosenUtility.initializeChosen(eleChosenId);
            if (eleReportStartTime) {
                parent.bootstrapDateTime.datetime({
                    component: eleReportStartTime,
                    timepicker: timePicker,
                    format: timeFormat,
                    closeOnDateSelect: closePickerOnDateSelect
                });
            }
            if (eleReportEndTime) {
                parent.bootstrapDateTime.datetime({
                    component: eleReportEndTime,
                    timepicker: timePicker,
                    format: timeFormat,
                    closeOnDateSelect: closePickerOnDateSelect
                });
            }
        }, 150);
    }


    //Jquery util for change event start.
    bindJqChangeEvt(ele: any) {

        setTimeout(() => {
            let parent = this;

            $('#' + ele).change(function (e: any) {

                parent.selectedReport = $(this).val();

                let obj = {};

                obj[parent.selectedReport] = true;

                parent.initReportSection(obj);

            });





        }, 1000);

    }

    onScroll($event: any) {
        if (((($event.target.scrollTop + $event.target.offsetHeight)) == ($event.target.scrollHeight)) && (!this.showTableRowLoader)) {
            if (this.noMoredata == false) {
                this.pageJump++;
                this.isScrolled = true;
                this.viewMovementLog(undefined, false);
            }
        }

        /*if(($event.srcElement.scrollTop + $event.srcElement.offsetHeight) >= $event.srcElement.scrollHeight ){
            if(this.noMoredata == false){
            this.pageJump++;
            this.isScrolled = true;
            this.viewMovementLog(undefined,false);
          }
        } */
    }
    dateReverser(str: string) {
        return str.split("-").reverse().join("-");
    }

    initHaltDurationPickers(ele1: any, ele2: any, pickerConfig: any): void {
        let parent = this;

        setTimeout(() => {
            $('#' + ele1).datetimepicker({
                format: 'd-m-Y',
                // onChangeDateTime: function () {
                //     parent.isDataProcessed = false;
                // },
                timepicker: false,
                maxDate: 0,
                closeOnDateSelect: true
            });

            $('#' + ele2).datetimepicker({
                format: 'd-m-Y',
                // onChangeDateTime: function () {
                //     parent.isDataProcessed = false;
                // },
                timepicker: false,
                maxDate: 0,
                closeOnDateSelect: true
            });
        }, 150);
    }



    genericDateValidate(param1: string, param2: string) {

        let stDate: any = (param1).split('-');
        stDate = `${stDate[1]}/${stDate[0]}/${stDate[2]}`;
        let stTimeStamp = new Date(stDate).getTime();


        let endDate: any = (param2).split('-');
        endDate = `${endDate[1]}/${endDate[0]}/${endDate[2]}`;
        let endTimeStamp = new Date(endDate).getTime();

        let stTimeStampString = stTimeStamp.toString();
        let endTimeStampString = endTimeStamp.toString();

        // if string conversion of null NAN returns with length 3

        return {
            stTimeStamp: stTimeStamp,
            endTimeStamp: endTimeStamp,
            stTimeStampString: stTimeStampString,
            endTimeStampString: endTimeStampString,

        }
    }
    resetTrackingTemplateError() {

        this.movementstartDateError = false;
        this.routesDatesMisMatchError = false;
        this.emptyVehicleError = false;
        this.overSpeedLimitError = false;
        this.lowSpeedLimitError = false;
        this.movementEndDateError = false;
        this.HighSpeedLimitError = false;
        this.lowerSpeedLimitError = false;
        this.speedMismatchError = false;
        this.daySummaryStartDateError = false;
        this.daySummaryEndDateError = false;
        this.daySummaryStartDateError = false;
        this.daySummaryDatesMisMatchError = false;
        this.haltDurationVehilError = false;
        this.haltDurationDateError = false;
        this.displayErrorMessage = '';
        this.trackingDevice = '';
    }


    choosenMovementFilter(val: string) {
        this.trackingDevice = val;
        return this.trackingDevice;

    }
}
