export const PUP_Listener =  class {
       public listener: boolean; public listener_alert: boolean;
       private pupurl: string;
       contructor() {
           this.listener = false;  this.listener_alert = false;
        //   this.pupurl = 'https://api-school.placer.in/stream/channel.php?token=';
        //  this.pupurl = 'https://findyourschoolbus.in/stream/channel.php?token=';
       this.pupurl = 'https://sky.placer.school/stream/channel.php?token=';
       }
        start(): any {
            if ( this.listener === false) {

            this.listener = true;
            return this.beat();
        }
     }

     start_alert(): any {
        if ( this.listener_alert === false) {

            this.listener_alert = true;
            return this.beat_alert();
        }
     }
     beat() {
       // return this.http();
     }

     beat_alert() {
        return this.http_notifications();
     }

     http() {
        let headers: any = new Headers();
        const apiToken = encodeURI(localStorage.getItem('scbToken') );
       // console.log('connecting...');
        headers = headers.set('Content-Type', 'application/json; charset=utf-8');
        return new Promise( (resolve, reject) => {

            // Reject Automatiically if no response more than 15min
            const clearOut = setTimeout( () => {  reject(true); } , (15 * 60 * 1000) ); // 15min


          const xhr: any = new XMLHttpRequest();
          const milliseconds = new Date().getTime();
          const url = this.pupurl + apiToken + '&type=geo';
           xhr.open('GET', url, true);
           xhr.onprogress = (e: any) => {
            const resData = e.currentTarget.responseText;

             try {
                clearTimeout(clearOut);
                this.listener = false;

             resolve( JSON.parse(  ( resData )   ) );

            } catch (e) {
                clearTimeout(clearOut);
                this.listener = false;

                setTimeout( () => { reject(true); }, 2 * 60 * 1000 );
            }

           };
           xhr.onreadystatechange = () => {
             if (xhr.readyState === 4) {
            //   console.log("Complete = " + xhr.responseText);
             }
           };
           xhr.send();

        });
     }


     http_notifications() {
        let headers: any = new Headers();
        const apiToken = encodeURI(localStorage.getItem('scbToken') );
       // console.log('connecting...');
        headers = headers.set('Content-Type', 'application/json; charset=utf-8');
        return new Promise( (resolve, reject) => {

            // Reject Automatiically if no response more than 15min
            const clearOut = setTimeout( () => {  reject(true); } , (15 * 60 * 1000) ); // 15min


          const xhr: any = new XMLHttpRequest();
          const milliseconds = new Date().getTime();
          const url = this.pupurl + apiToken + '&type=alert';
           xhr.open('GET', url, true);
           xhr.onprogress = (e: any) => {
            const resData = e.currentTarget.responseText;

             try {
                clearTimeout(clearOut);
                this.listener_alert = false;

             resolve( JSON.parse(  ( resData )   ) );

            } catch (e) {
                clearTimeout(clearOut);
                this.listener_alert = false;

                setTimeout( () => { reject(true); }, 2 * 60 * 1000 );
            }

           };
           xhr.onreadystatechange = () => {
             if (xhr.readyState === 4) {
            //   console.log("Complete = " + xhr.responseText);
             }
           };
           xhr.send();

        });
     }

};
