/**
 * @Author: Deepak Pookkote <group10>
 * @Date:   2018-04-25T16:03:47+05:30
 * @Email:  deepak@groupten.com
 * @Project: School-Bus
 * @Filename: bottom.dir.ts
 * @Last modified by:   group10
 * @Last modified time: 2018-05-08T15:34:47+05:30
 * @Copyright: Group10 Technologies
 */



/*Auther:Deepak */
import { Component,Input,Output,EventEmitter,ChangeDetectorRef } from '@angular/core';
import { RouterModule, Routes, Router } from '@angular/router';
import { globalService } from './../../../services/global.service';
import { ApiService } from './../../../services/ApiService';

declare var $:any

@Component({
  selector: 'bottom-panel',
  templateUrl:'./bottomPanelTemplate.html',
  styles: [ `
  button.close {
    -webkit-appearance: none;
    padding-top: 25px;
    padding-left: 0px;
    background: 0 0;
    border: 0;
}
.close {
    font-size: 14px !important;
    font-weight: 700;
    color: #443939 !important;
    opacity: 1;
    position: absolute !important;
    top: -6% !important;
    left: 97.11% !important;
    z-index: 99999;
}
.modal-backdrop.in {
  filter: alpha(opacity=50);
  opacity: 0;
}

    .head{

        border:1px solid #eaeaea;
    }
    /* Sample Route title stylings */
    .dashboard-right-title{
        font-size: 16px;
        padding: 5px 12px !important;
        font-weight: 400;
    }
    .dashboard-right-title-but{
        font-size: 16px;
        padding-top:2px;


    }
    .list-inline li.active {
      border-bottom: 2px solid #3498db;
    }

    /* styles for timeline and more */
    .timeline-but-pad{
        border-bottom: 2px solid white;
    }
    .timeline-but-pad:hover{
        border-bottom: 2px solid #3498db;
        background-color: white;
        cursor: pointer;
        width:50%;
     }
    /*.timeline-but-pad-default{
        border-bottom: 2px solid #3498db;
    } */
    .route-start-end-text{
    padding: 1% 2% !important;
    font-size: 16px;
    font-weight: 400;
    border:1px solid lightgrey;
    }
    /*right dashboard animation*/

    .btm-dashbrd-rt-side{
        color: #34495e;
        font-size: 12px;
        font-weight: 400;
    }
      .btm-time-pad-left{
        padding: 1px 14px !important;
    }
    .btm-time-pad-leftt{
      padding: 1px 14px !important;
  }
    .pad-top{
        padding-top: 17px;
    }
    .dashboard-R-timeline-more-table {
        padding-top:15px!important;

    }
    .table-border{
      border-bottom:1px solid #F1F0EE;
    }
    .tick{
        padding-top: 8px;
        color: #2ecc71;
        font-size: 17px;
    }
    .table-pad{
          padding: 1px 15px !important;
    }


    .borderless td, .borderless th {
        border: none!important;
    }

    .table-pading-route{
      width: 98% !important;
    max-width: 97% !important;
    }
    .table-tbody::-webkit-scrollbar {
        background-color: white;
        width: 9px;
    }

    .table-tbody::-moz-scrollbar {
        background-color: white;
        width: 10px;
    }

    .table-tbody::-webkit-scrollbar-thumb {
        background-color: #3498db;
    }

    .table-tbody::-moz-scrollbar-thumb {
        background-color: #3498db;
    }
    .dashboard-R-timeline-more-table>tabe-tbody>tbody>tr:nth-child(even) {
           background-color: red;
    }

    .progress {
        overflow: visible;
        margin-bottom: 26px;
        height: 4px;
    }

    .progress .progress-bar {
        background-color: #3498db;
        position: relative;
        border-radius: 4px;
    }

    .progress .progress-bar span {
        background-color: #3498db;
        position: absolute;
        bottom: -6px;
        font-size: 14px;
        line-height: 10px;
        padding: 3px 3px 2px 3px;
        right: -1.4em;
        border-radius: 2px;
    }

    .delayed{
      background: #e24444;
      color: #ffff;
      border-radius: 3px;
      font-weight: bold;
    }
    .advanced{
      background:#148aa5;
      color: #ffff;
      border-radius: 3px;
      font-weight: bold;
    }

    .onTime{
      background:#4fa553;
      color: #ffff;
      border-radius: 3px;
      font-weight: bold;
    }




`  ],
})
export class BottomPanelDir  {

  public showMore: boolean = true;
  public showTimeLine: boolean = false;
  public isLoading:boolean=false;
  public mapHeight:number=91;
  private clickedRouteId:any;
  private RouteInformation:any=[];
  private dispRouteInformation:any=[];
  private SingleRouteInfo:any=[];
  public dispRouteInformationData:any=[];
  public dispPickupInformationData:any=[];
  private pickupListInfo:any=[];
  public  clearTimeout:any;
  public expandedIndex:any = -1;
  public  falsyTemplate: boolean = false;
  public routeMembersList: any = [];
  public routememberID:any=[];
  public routeWiseMemberInfo: any = [];
  //public retrivedRouteId:any = localStorage.getItem("storeRouteId");
  public clearPanelId:any;
  public dateWiseRouteRfid: any = [];
  selectedPP: any;

  @Input('clickedRouteId')
  set routeId(obj:any){
    if(obj) this.processRouteId(obj.routeId);
   // console.log(obj);
  }

  @Input('popUpInformation') _dispyInformation:boolean;


  @Output() mapHeightEmitter:EventEmitter<any> = new EventEmitter<any>();
  //@Input() clickedRouteId:EventEmitter<any> = new EventEmitter<any>();

  constructor(
    public router: Router,
    public globalService: globalService,
    public apiService:ApiService,
    private cdr: ChangeDetectorRef

  ) {

    }

    ngAfterViewInit() {
      this.cdr.detectChanges();
    }

    ngOnInit(){
      setTimeout(()=>{
        this._dispyInformation =true;
      },1000);

    }


    processRouteId(routeId:any){
      if(this._dispyInformation){
        setTimeout(() => {
              this.EmitHeightUp();
          }, 1000);
      }

      this.clickedRouteId=routeId;
      if(routeId!=undefined && routeId!=null && routeId!=''){
      this.ActiveRouteInfo();
      }




    }


    EmitHeightDown(){
       this.mapHeight = this.mapHeight=91;
       this.mapHeightEmitter.emit( this.mapHeight );
    }
    EmitHeightUp(){
       this.mapHeight = this.mapHeight=46;
       this.mapHeightEmitter.emit( this.mapHeight );
    }
    expandRow(index: number,ppId:any,routeID:any,pickupName:any): void { 
     
this.selectedPP=pickupName;
      let currentDate = new Date();
let day = currentDate.getDate();
let month = currentDate.getMonth() + 1;
let year = currentDate.getFullYear();
let fullDate=year+'-'+month+'-'+day;
//console.log('TTTTTTTTT ',fullDate);
     let apiHeader = {
      data: {
          key: localStorage.getItem('scbToken'),
         
          form: {
              fromDate: '2020-02-06',
              fromTime: '00:00:01',
              toDate: '2020-02-06',
              toTime: '23:59:59',
              routeId: routeID,
              pickupId:ppId
              
              // routeId:'VlBQUTXCYnoqDKNctfFG2795631512628641'
          }
      }
  }
  //this.generalRfidLog = [];
  this.isLoading = true; 
  this.routeWiseMemberInfo = [];
  this.dateWiseRouteRfid=[];
  this.routeMembersList=[];
  let allStuDetails :any= [];
  this.routememberID=[];
  let allStuObjobj={};
  this.apiService.getCustomRFIDReport(apiHeader).then(response => {    

    if (response.status === 'success') { 

      if (response.response) { 

        if (response.response && response.response.MemberInfo) { 

          let tempdates: any = response.response.MemberInfo;

          let routemember:any=response.response.routeMember;
          let totalStudent:number=0;

          (Object.keys(routemember)).forEach((date1: string) => { 
          
            totalStudent++;
            allStuObjobj= {
                memberUID:routemember[date1].memberUID,
                memberName: routemember[date1].memberName,
                memberClass: routemember[date1].memberClass,
                memberSection: routemember[date1].memberSection,
                memberRFID:routemember[date1].memberRFID
            }
            //console.log('TOTAL ID : ',routemember[date1].memberUID);
            this.routeMembersList.push(allStuObjobj);
            this.routememberID.push(routemember[date1].memberUID);
          
          
          
          });



          (Object.keys(tempdates)).forEach((date: string) => {
            //console.log('88888888888888888888777777 ',Object.keys(tempdates[date]));
            
            let swipedStuDetails :any= [];
            let swipedStudent:number=0;
            let notSwipedStudent:number=0;
            
                                            Object.keys(tempdates[date]).forEach((member_key: any) => {
                                                
            
            
                                               
                                                    
                                                    if(this.routememberID.indexOf(tempdates[date][member_key].memberUID)===-1){ 
            console.log('11111111 ',tempdates[date][member_key].memberUID);
            
            
                                                    }
                                                    else{
                                                        console.log('ELSEEE11111111 ',tempdates[date][member_key].memberUID);
            
                                                        swipedStuDetails.push(tempdates[date][member_key].memberUID);
                                                        swipedStudent++;
                    
                                                        let createSwipedObj={ 
                                                            swipedDate:date,
                                                            memberName:tempdates[date][member_key].memberName,
                                                            memberUID:tempdates[date][member_key].memberUID,
                                                            memberClass:tempdates[date][member_key].memberClass,
                                                            memberSection:tempdates[date][member_key].memberSection,
                                                            rfidswipNearLocation:tempdates[date][member_key].rfidswipNearLocation,
                                                            rfidSwipTime:tempdates[date][member_key].rfidSwipTime,
                                                            vehicleName:tempdates[date][member_key].vehicleName,
                                                            swipeFlag:'IN',
                                                            assigned:tempdates[date][member_key].memberRFID
                    
                                                        };
                    
                                                        //console.log('SWIPED ID : ',tempdates[date][member_key].memberUID);
                                                         this.routeWiseMemberInfo.push(createSwipedObj); 
                                                         this.isLoading = false;    
                                                        
                                                }
            
                                                 
            
            
            
                                               
                                               
                                            });
                                            
                                            //console.log('SWIPED TOTAL STUDENT ',swipedStudent);
                                            let listNotSwipedId:any= [];
                                            for(let i = 0; i < this.routeMembersList.length; i++) { 
                      
                                                
                                                
                                                if(swipedStuDetails.indexOf(this.routeMembersList[i].memberUID)===-1){ 
             let rfidAssignedOrNot:any;     
             console.log('XXXXXXXXXXXX00000 ',this.routeMembersList[i].memberRFID);                                  
            if(this.routeMembersList[i].memberRFID===null){ 

              console.log('XXXXXXXXXXXX ');
             rfidAssignedOrNot='NA';

            }else{ 
              console.log('XXXXXXXXXXXX----11 ',this.routeMembersList[i].memberRFID);
              rfidAssignedOrNot=this.routeMembersList[i].memberRFID;
            }
            
            
            
                let createNotSwipedObj={ 
                    swipedDate:date,
                    memberName:this.routeMembersList[i].memberName,
                    memberUID:this.routeMembersList[i].memberUID,
                    memberClass:this.routeMembersList[i].memberClass,
                    memberSection:this.routeMembersList[i].memberSection,
                    rfidswipNearLocation:'---',
                    rfidSwipTime:'-',
                    vehicleName:'-',
                    swipeFlag:'OUT',
                    assigned:rfidAssignedOrNot
            
                };
                notSwipedStudent++;
                //console.log('NOT SWIPED ID : ',this.routeMembersList[i].memberUID);
                this.routeWiseMemberInfo.push(createNotSwipedObj);
            
             
            
            
            
                                                     
                                                   
                                                } else{ 
                                                    //console.log('ELSEEEEEE22222  ',this.routeMembersList[i].memberUID);
            
                                                }
                                                
                                            } 
                                            let  finalNotSwipedTotalStudent:number=0;
                                            finalNotSwipedTotalStudent=totalStudent-swipedStudent;
                                            console.log('NOT SWIPED TOTAL STUDENT ',finalNotSwipedTotalStudent);
                                            var fieldsDate = date.split(/-/);
                                            let dateWiseRFIDObj={ 
                                                dateWiseRFID:date,
                                                totalStudent:totalStudent,
                                                swipedStu:swipedStudent,
                                                notSwipedStu:finalNotSwipedTotalStudent,
                                                alignDateWiseRFID:fieldsDate[2]+"-"+fieldsDate[1]+"-"+fieldsDate[0],
                                            };
                                            this.dateWiseRouteRfid.push(dateWiseRFIDObj);  
                                            this.isLoading = false;
                                        });


          }else{ 
            this.isLoading = false;
          }


      }else{ 
        this.isLoading = false;
      }




    }else{ 

      this.isLoading = false;
    }



  }).catch(error => { })
  
    }
    ActiveRouteInfo() {
      let parent: any = this;
      var routeObj = {
        data: {
          key: localStorage.getItem('scbToken'),
          filter: {
            routeId: this.clickedRouteId
          }

        }
      };
      this.apiService.activeRouteDetails(routeObj).then(response => {
        let parent: any = this;
        if (response) {

          this.RouteInformation=response.response

          this.dispRouteInformation=$.map(this.RouteInformation,function(value:any,index:any){
                         return[value]});
         this.SingleRouteInfo = this.dispRouteInformation[1];

         this.dispRouteInformationData=$.map(this.SingleRouteInfo,function(value:any,index:any){
                        return[value]});

         this.pickupListInfo = this.dispRouteInformation[0];

         this.dispPickupInformationData =$.map(this.pickupListInfo,function(value:any,index:any){
                        return[value]});

        //console.log('val',this.dispPickupInformationData.expectedTime);

        };

        this.clearPanelId = setTimeout(function() {
            parent.ActiveRouteInfo();
        }, (100 * 1000) );


      }).catch(error => {

        setTimeout(function() {
            parent.ActiveRouteInfo();
        }, (500 * 1000) );

       });

      //this.ApiCallrecursiveFunction();

    }

    ngOnDestroy() {
        if (this.clearPanelId) {
            clearTimeout(this.clearPanelId);
        }
      }



 }

/*
Notes : npm install node-sass -g
*/
