/**
 * @Author: Deepak Pookkote <group10>
 * @Date:   2018-04-24T11:51:53+05:30
 * @Email:  deepak@groupten.com
 * @Project: School-Bus
 * @Filename: schoolVehicleList.ts
 * @Last modified by:   group10
 * @Last modified time: 2018-05-09T12:55:45+05:30
 * @Copyright: Group10 Technologies
 */



/*Auther:Deepak */
import { Component } from '@angular/core';
import {
     ElementRef,
     AfterViewInit,
     OnDestroy,
     ViewChild,
 } from '@angular/core';
  import { RouterModule, Routes, Router } from '@angular/router';
  import {ActivatedRoute} from '@angular/router';
  import { AppComponent } from './../../../app.component';

  import { globalService } from './../../../services/global.service';
  import { ApiService } from './../../../services/ApiService';
  import { chosenUtility } from './../../../services/chosen-utility/jq-chosen-utility.js';

@Component({
  //template: ``,
   templateUrl: './schoolVehicleList.component.html',

   styles: [`

     .clickRouteClass{
      cursor: pointer;
      min-width: 20vh;
      /* background: red; */
      display: inline-block;
      min-width: 10px;
      padding: 5px 10px;
      font-size: 12px;
      font-weight: 700;
      line-height: 1;
      color: #fff;
      text-align: center;
      white-space: nowrap;
      vertical-align: middle;
      background-color: #777;
      border-radius: 10px;
      margin-right: 15px;
     }
     .customized-form-control{
        height: 42px;
        border: 1px solid lightgray;
        display: block;
        width: 100%;
        padding: 6px 12px;
        font-size: 14px;
        line-height: 1.42857143;
        color: #000;
        background-color: #fff;
        background-image: none;
        border: 1px solid #ccc;
        border-radius:2px;
     }
     .Windowactive {
       border-top: 3px solid #0095b9;
       padding-top: 7px;
     }
     .pagination a {
       color: black;
       float: left;
       padding: 7px 16px;
       text-decoration: none;
       border: 1px solid #bcb9b9;
     }
     .panel-default>.panel-heading {
      color: #333;
      background-color: #ffff;
      border-color: #ffff;
     }
     .panel {
      box-shadow: 1px 2px 4px 0px lightgrey;
     }
     .panel-default {
      border-color: #fdfafa;
     }

     /*search box CSS starts */
     .search-box {
      display: inline-block;
      width: 100%;
      border-radius: 3px;
      padding: 4px 55px 4px 15px;
      position: relative;
      background: #fff;
      border: 1px solid #ddd;
      -webkit-transition: all 200ms ease-in-out;
      -moz-transition: all 200ms ease-in-out;
      transition: all 200ms ease-in-out;
     }
     .search-box.hovered, .search-box:hover, .search-box:active {
      border: 1px solid #aaa;
     }
     .search-box input[type=text] {
      border: none;
      box-shadow: none;
      display: inline-block;
      padding: 0;
      background: transparent;
     }
     .search-box input[type=text]:hover, .search-box input[type=text]:focus, .search-box input[type=text]:active {
      box-shadow: none;
     }
     .search-box .search-btn {
      position: absolute;
      right: 2px;
      top: 2px;
      color: #aaa;
      border-radius: 3px;
      font-size: 21px;
      padding: 5px 10px 1px;
      -webkit-transition: all 200ms ease-in-out;
      -moz-transition: all 200ms ease-in-out;
      transition: all 200ms ease-in-out;
     }
     .search-box .search-btn:hover {
      color: #fff;
      background-color: #8FBE00;
     }

     div.section-title{
       font-size:20px;
     }
     div.section-title-border{
       margin-bottom: 20px;
       border-bottom: 1px solid #00BCD4;
       border-bottom-width: medium;
       border-spacing: 1px;
       width:13%;
     }

     .report-basic-info,.student-pickup-info,.student-drop-info,.student-notif-status{
       margin-bottom:10px;
     }

     input[type=checkbox], input[type=radio] {
       margin: 0px 4px -3px !important;
       line-height: normal;
     }

     /*search box CSS ends */


    `],
})
export class schoolVehicleList{

  public  vehicleDetails: any[];
  public  apivalues:any={};
  private rawAllVehicleData:any[] = [];
  private allVehicleAllRouteData:any[] = [];
  private selectedRouteDetails:any[] = [];
  private orgBasicDetails:any = {};
  private routes:any=[];
  public  pageJump:number=0;
  public limitOfRecords:number;
  private vehicleInfoAvailable:any=[];
  private allOrgRoutes:any[] = [];
  private filterRouteId:any;
  private filterVehicleReg:any;
  private globalFilterObj:any = {};
  private dummy:any=[];
  public isLoading: boolean; //Loader Var

  public showLastUpdateStatus: boolean = false;
  public showVehicleList: boolean = true;
  public sortedArray:any=[];
  constructor(private router: Router,private apiService:ApiService,private chosenUtility:chosenUtility){}

  ngOnInit(){
    this.getReportData();
    this.getAllOrgRoutes();
  }


  getAllOrgRoutes():void{
      let apiHeader = {
          data:{
              key:localStorage.getItem('scbToken'),
          }
      }
      this.apiService.getRoutes(apiHeader).then(response => {
          this.allOrgRoutes = response.response;
          this.initializeChosenUtility();
      }).catch(error => {})
  }


  applyFilters():void{
      this.globalFilterObj = {};
      let isFilterRouteSelected = this.chosenUtility.getControlvalue('orgRoutesDropdown');
      if(this.filterVehicleReg) {
          Object.assign(this.globalFilterObj,{regNo:this.filterVehicleReg});
      }

      if(isFilterRouteSelected){
          Object.assign(this.globalFilterObj,{routeId:isFilterRouteSelected});
      }
      this.pageJump = 0;
      this.rawAllVehicleData = [];
      this.allVehicleAllRouteData = [];
      this.getReportData();
  }




  getReportData=():void=>{
    this.isLoading = true;
    let apiHeader = {
         data:{
             key:localStorage.getItem('scbToken'),
             extra: {
             pageJump: this.pageJump
           }
         }
    }
    if(Object.getOwnPropertyNames(this.globalFilterObj).length){
        apiHeader.data['filter'] = this.globalFilterObj;
    }else{
        delete apiHeader.data['filter'];
    }

    this.rawAllVehicleData = [];
    this.allVehicleAllRouteData = [];
    this.apiService.getAllVehicleAllRouteReport(apiHeader).then(response => {
      let result=response;
      if(result.status=="success"){
        this.isLoading = false;
        this.routes= [];
        let rawAllVehicleData = response.response;
        let availableRouteInfo = rawAllVehicleData['vehDetails'];
        let desiredRouteInfo:any[] = [];
        this.selectedRouteDetails = [];

//new code by krish starts here

let veh = rawAllVehicleData['vehDetails'];
    let gate:any=[];
    let nonGate:any=[];
    Object.keys(veh).map( item => {
      let str = item.toLowerCase();
       if(str.includes('gate')) gate.push(item);
       else nonGate.push(item)
      });
    
let temp = {};
    nonGate.forEach((item:any)=>{


      if('RouteInfo' in veh[item]){
        temp[item] = this.getOnlyRouteNameArray(veh[item]['RouteInfo'])
       
      }else gate.unshift(item);
     
    })
    
    let d:any = Object.keys(temp).sort((a,b) => temp[a]-temp[b]);
    

   this.sortedArray = JSON.parse(JSON.stringify(d));



    
    gate = this.uniqueItem(gate);

    gate.forEach((item:any)=>{
      this.sortedArray.push(item);
    });

    this.sortedArray = this.uniqueItem(this.sortedArray);
 
 for (let i = 0; i < this.sortedArray.length; i++) {
 
  Object.assign(availableRouteInfo[this.sortedArray[i]],{routeId:this.sortedArray[i]});
  this.selectedRouteDetails.push(availableRouteInfo[this.sortedArray[i]]);
}
//new code by krish ends here

        // for(let routeId in availableRouteInfo){ //this is previous code starts here hided by krish
           
        //     Object.assign(availableRouteInfo[routeId],{routeId:routeId});
        //     this.selectedRouteDetails.push(availableRouteInfo[routeId]);
        // }  //this is previous code ends  here hided by krish
        if(this.selectedRouteDetails){
         
            for(let data in this.selectedRouteDetails){
              let routeNames: any = [];
              let routeInfo = [];

              if(this.selectedRouteDetails[data] && this.selectedRouteDetails[data].RouteInfo &&  Object.keys(this.selectedRouteDetails[data].RouteInfo).length > 0){
                for(let key in this.selectedRouteDetails[data].RouteInfo){
                  routeInfo.push(this.selectedRouteDetails[data].RouteInfo[key]);
                }
              }
              
              if(routeInfo.length>0){
                try{
                    routeInfo = routeInfo.sort((a:any, b:any) => {
                      return (
                        parseFloat(a.routeStartTime.replace(':', '.')) -
                        parseFloat(b.routeStartTime.replace(':', '.'))
                      );
                    });
  
                }catch(err){
                  console.log('sorting array err',err);
                }

                for(let i=0;i<routeInfo.length;i++) {
                  
                  if(routeInfo[i].routeName){
                    let rInfo = {
                      routeName:routeInfo[i].routeName,
                      routeId:routeInfo[i].routeId,
                      vehReg:this.selectedRouteDetails[data].vehNo,
                    }
                    routeNames.push(rInfo);
                }
              }
            }
            this.routes.push({vehReg : this.selectedRouteDetails[data].vehNo, driverName: this.selectedRouteDetails[data]['driverName'], routeInfo: routeNames});
          }
        }
        if(this.pageJump == 0){
          this.limitOfRecords = this.routes.length;
        }
  }
}).catch(error => {});
}

  initializeChosenUtility(){
      setTimeout( () => {
          this.chosenUtility.initializeChosen('orgRoutesDropdown');
      },200)
  }
  uniqueItem(arr:any){
    return Array.from(new Set(arr));
  }
  getOnlyRouteNameArray(obj:any){
    let res = Object.keys(obj).map((val:any)=> obj[val]['routeName'] );
    res = res.map((item:any)=> parseInt(item));
    res = Array.from(new Set(res));
   
    return res[0];
  }
  passIdOnClick(clickedRouteId:any){ //function to pass id through url
    this.router.navigate(['/school-routes-list',clickedRouteId]);
  }
}
