import { Injectable } from '@angular/core';
import { RouterModule, Routes, Router } from '@angular/router';

@Injectable()

export class authGaurd {

  constructor(public router: Router) {

  }

  // ngOnInit(){
    
  //    let key1 = localStorage.getItem('scbToken');
  //    let key2 = localStorage.getItem('adminToken');
  //    if(key1=='' && key2==''){
  //         this.checkToken();
  //    }

  // }

  checkToken(){

    if(localStorage.getItem('scbToken') == '' || localStorage.getItem('scbToken')==null){
      this.router.navigate(['/login']);

    }else{

    }
  }

  checkAdminToken(){
    if(localStorage.getItem('adminToken') == '' || localStorage.getItem('adminToken')==null){
      this.router.navigate(['/admin-login']);
    }else{

    }
  }


}
