
import { Component} from '@angular/core';


@Component({
  selector: 'progress-loader',
  template: `
  <section >
    <div class="loader-4"></div>
  </section>
  `,
  styles: [`
  /** loader css **/
  section {
    position: relative;
    width: 100%;
    height: 80vh;
  }
  section:nth-child(2n) {
    background-color: #ffffff;
  }
  
  @keyframes animation {
    0% {
      width: 0;
    }
    25% {
      width: 30%;
      left: 15%;
    }
    50% {
      width: 70%;
      left: 40%;
    }
    100% {
      width: 15%;
      left: 100%;
    }
  }
  .loader-4 {
    position: relative;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 10%;
    height: 3px;
    border-radius: 50%;
    background-color: #fff;
    box-shadow: 0 0 40px 0 rgba(68, 16, 122, 0.4);
    overflow: hidden;
  }
  .loader-4::before, .loader-4::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 0;
    height: 4px;
    border-radius: 4px;
    z-index: 2;
  }
  .loader-4::before {
    background: linear-gradient(90deg, #ff1361, #fff800);
    animation: animation .8s linear infinite;
  }
  .loader-4::after {
    background: linear-gradient(90deg, #44107a, #ff1361);
    animation: animation .8s .4s linear infinite;
  }
  
  /** loader css **/
  


`  ],
})
export class ProgressLoaderClass {



  constructor(
  
  ) {

  }

  ngOnInit(){
   
  }








}
/*
Notes : npm install node-sass -g
*/
