import { Component, Input, Output } from '@angular/core';
import { ApiService } from './../../../services/ApiService';
import { chosenUtility } from './../../../services/chosen-utility/jq-chosen-utility.js';
import { bootstrapDateTime } from './../../../services/bootstrapDateTime/datePicker';
import { GlobalUtils } from './../../../services/global-utils';
declare const $: any;
import { DOCUMENT } from "@angular/platform-browser";
import { Objectkeys } from '../../../custom-pipes/objectkeys.pipe';
import { containsElement } from '@angular/animations/browser/src/render/shared';
import { IncrementDir } from './../../../pages/increment-index/inc';
import { setRootDomAdapter } from '@angular/platform-browser/src/dom/dom_adapter';
import { StorageService } from './../../../services/storage.service';
import { COM } from './../../../services/com.services';
import { Router } from '@angular/router';
import { RouteSwapConfig } from '../../configuration/route.swap.dir';
import { ApiCallDateFormatService } from "../../../services/api-call-date-format.service";
import { DatePipe } from '@angular/common';

declare const Pickathing: any;
interface IAvailableReports {
    general: any,
    RFIDWithClass: any,
    RFIDWithMember: any,
    RFIDWithRoute: any,
    RFIDWithAttendance: any,
    RFIDWithSmsLog: any,
    RFIDStatusCheck: any,
    RFIDRouteWiseCount: any,
    RFIDAppPush: any,
}
@Component({
    providers: [bootstrapDateTime],
    selector: 'rfid-report-section',
    templateUrl: './rfid-report.component.html',
    styleUrls: ["./rfidreport.css"]
})

export class RfidReports {

    public substring = 'welcome to substring lenth find';
    public selectedDaySection: any;
    public reportOptions: any = [];
    public OrgClasses: any[];
    public orgSections: any[];
    public walkerList: any[];
    private userSelectedOptions: any[];
    private selectedReport: any;
    private generalRfidLog: any;
    private selectedRfidDates: any[];
    private filterGeneralRfidNumber: any;
    private noMoredata: boolean;
    private noGeneralRfidRecords: boolean;
    private noRouteRfidRecords: boolean;
    private noRouteRfidAttendenceRecords: boolean;
    private isClassRequired: boolean;
    private noClassRfidRecords: boolean;
    private noMemberRfidRecords: boolean;
    private selectedClass: any;
    private selectedSection: any;
    private generalRfidPageJump: number;
    private enableGeneralRfidExcelDownload: boolean = true;
    private enableDisableRFIDPeriodicSubmitBtn: boolean = false;
    private enableDisableRFIDRouteWiseSubmitBtn: boolean = false;
    private enableDisableRFIDAttendanceSubmitBtn: boolean = false;
    private enableDisableRFIDSMSSubmitBtn: boolean = false;
    private enableDisableRFIDStatusCheckSubmitBtn: boolean = false;
    private enableDisableRFIDRouteWiseCntSubmitBtn: boolean = false;
    private GeneralReportExcelDownloadLink: string;
    private classReportExcelDownloadLink: string;
    private memberReportExcelDownloadLink: string;
    private routeReportExcelDownloadLink: string;
    private intermediateObj: any;
    private displayErrorMessage: string;
    public isLoading: boolean;
    public showTableRowLoader: boolean;
    public selectedClassAttendence: any;
    public attendenceReport: any;
    public selectedSectionAttendence: any;
    public getAttendenceReport: any = [];
    public selectedWalkerAttendence: any;
    public showMoreFilter: boolean;
    public attendenceDate: any = {};
    public noAttendenceRecore: boolean = true;
    public totalPrecentStudent: number;
    public smsTotCount: number;

    public vehicleOverspeedDownloadLink: any = {};

    public inCount: number = 0;
    public outCount: number = 0;
    public totCount: number = 0;
    public addType: string = 'both';
    public fromStartTimeError: boolean;
    public fromEndTimeError: boolean;
    //public walkerList:any={};
    public showMoreFilter1: boolean;
    public walkerListCount: boolean;
    public incVal: any = [];
    public primaryCount: any;
    public secondryCount: any;
    private isScrolled: boolean;
    private pageJump: number;
    public routeSection: any;
    public routeStudent: any = {};
    public routeStudentList: any = [];

    public incDiv: boolean = true;

    public selectedSMSType: any;
    public routeTypeErr: boolean;
    public studId: any;
    public studMobile: any;
    public smsRFIDResponse: any;
    public smsResponseNull: boolean;
    public rfidSectionErr: boolean;
    public walkerVehId: any = [];
    public rfidControl: any;
    public selectedWalkerDirective: boolean;
    public sessionTimeAvailablity: boolean;
    public sessionViewer: any;

    private reportLookUpTitle: IAvailableReports = {
        general: 'Periodic RFID report',
        RFIDWithClass: 'Classwise RFID report',
        RFIDWithMember: 'Memberwise RFID report',
        RFIDWithRoute: 'Routewise RFID report',
        RFIDWithAttendance: 'Attendance RFID report',
        RFIDWithSmsLog: 'SMS Log RFID report',
        RFIDStatusCheck: ' RFID Status Check',
        RFIDRouteWiseCount: ' RFID RouteWise Count',
        RFIDAppPush: ' RFID App Push'
    };
    public reportHeaderSection: IAvailableReports = {
        general: false,
        RFIDWithClass: false,
        RFIDWithMember: false,
        RFIDWithRoute: false,
        RFIDWithAttendance: false,
        RFIDWithSmsLog: false,
        RFIDStatusCheck: false,
        RFIDRouteWiseCount: false,
        RFIDAppPush: false,

    }

    public reportContentSection: IAvailableReports = {
        general: false,
        RFIDWithClass: false,
        RFIDWithMember: false,
        RFIDWithRoute: false,
        RFIDWithAttendance: false,
        RFIDWithSmsLog: false,
        RFIDStatusCheck: false,
        RFIDRouteWiseCount: false,
        RFIDAppPush: false
    }
    private reportLookUpFunction: IAvailableReports = {
        general: 'initGeneralRfidReport',
        RFIDWithClass: 'initRfidWithClassReport',
        RFIDWithMember: 'initRfidWithMemberReport',
        RFIDWithRoute: 'initRfidWithRouteReport',
        RFIDWithAttendance: 'initRfidWithAttendenceReport',
        RFIDWithSmsLog: 'initRfidWithSMSLogReport',
        RFIDStatusCheck: 'initRfidStatusCheck',
        RFIDRouteWiseCount: 'initRfidRouteWiseCount',
        RFIDAppPush: 'initRfidAppPush'
    }

    public routeWiseMemberInfo: any = [];
    public routeWiseMemberInfo1: any = [];
    public dateWiseRouteRfid: any = [];
    public routeWiseNotSwipedMemberInfo: any = [];
    public routeMembersList: any = [];
    public vehicleLists: any = [];
    public userReportControls: any;
    public routeSectionn: any;
    public rfidStatus: any;
    dateErr: boolean = false;
    rfidSMScheckdwnld: boolean = true;
    rfidSwipedMember: boolean = false;
    rfidSwipedClass: boolean = false;
    rfidSwipedSection: boolean = false;
    public routememberID: any = [];
    rfidAppPush: {}
    rfid_App_push_ngModel: any = {};
    readytoDisplay = false;
    //selectedUserName: any;
    private selectedUserName: any[];
    constructor(private chosenUtility: chosenUtility,
        private bootstrapDateTime: bootstrapDateTime,
        private apiService: ApiService,
        private globalUtils: GlobalUtils,
        private storage: StorageService,
        private com: COM,
        private router: Router,
        public dateChange: ApiCallDateFormatService,


    ) {
        this.OrgClasses = [];
        this.orgSections = [];
        this.walkerList = [];
        this.walkerVehId = [];
        this.generalRfidLog = [];
        this.userSelectedOptions = [];
        this.selectedRfidDates = [];
        this.noGeneralRfidRecords = false;
        this.noRouteRfidRecords = false;
        this.isClassRequired = true;
        this.isLoading = false;
        this.pageJump = 0;
        this.showTableRowLoader = false;
        this.noClassRfidRecords = false;
        this.noMemberRfidRecords = false;
        this.filterGeneralRfidNumber = null;
        this.GeneralReportExcelDownloadLink = null;
        this.classReportExcelDownloadLink = null;
        this.routeReportExcelDownloadLink = null;
        this.intermediateObj = {};



    }
    public parameter: any;
    @Input('availableReports')
    set availableReports(para: any) {

        this.parameter = para;
    }

    @Input('RFIDReports')
    set RFIDReports(data: any) {
        if (data !== undefined) {

            this.RFIDControls(data.rfid);

        }

    }



    RFIDControls(obj: any) {
        let report: any;
        if (localStorage.getItem('userInfo')) {
            report = JSON.parse(localStorage.getItem('userInfo'));
        }

        // let lone = this.parameter; 
        let uLevel = JSON.parse(localStorage.getItem('userLevel'));

        if (uLevel === 1) {
            let lone = Object.assign(obj, this.parameter);
            this.generateOptions(lone);
        } else if (uLevel === 2) {
            this.rfidControl = Object.assign(this.parameter, report.reports.rfid);
            this.generateOptions(this.rfidControl);
        }

    }



    ngOnInit() {
        this.getAvailableClasses();
        this.getRouteWalkerList();

        this.getCalendar();



        let time = this.storage.get('time');
        this.sessionTimeAvailablity = false;
        if (time != null) {
            this.sessionTimeAvailablity = true;
            this.sessionViewer = time;
        } else {
            this.sessionViewer = [];
            this.sessionTimeAvailablity = false;
        }

        setTimeout(() => {
            $('#eventStartTime').datetimepicker({
                format: 'H:i',
                datepicker: false,
                defaultTime: '00:00',
                timepicker: true,
            });
            $('#eventEndTime').datetimepicker({
                format: 'H:i',
                datepicker: false,
                defaultTime: '23:59',
                timepicker: true,
            });


            this.initChosen();

            this.pickathpluginInit();


        }, 200);


        // var keepsHisWord: boolean;
        // keepsHisWord = false;
        // var original = Promise.resolve(33);
        // var cast = Promise.resolve(original);
        // setTimeout(() => {
        //     cast.then(function(value) {

        //         keepsHisWord=true;



        //         var promise1 = new Promise(function(resolve, reject) {
        //             if (keepsHisWord) {


        //               resolve("The man likes to keep his wordd");
        //             } else {
        //               reject("The man doesnt want to keep his word");
        //             }
        //           });

        //       }); 
        //     }, 5000);






        //this.loadCSS();
        // this.pickathpluginInit();

    }

    emitFunction(str: boolean) {
        // console.log('str',str);
        this.enableDisableRFIDStatusCheckSubmitBtn = str;
    }
    emitFunctionRfidRouteWiseCnt(str: boolean) {
        // console.log('str',str);
        this.enableDisableRFIDRouteWiseCntSubmitBtn = str;
    }


    async pickathpluginInit() {
        const res1 = await new Promise(function (resolve, reject) {
            setTimeout(() => (new Pickathing('demo-select', true, { searchLabel: 'Search for options...' }), resolve(true)), 200);
        });
        const result = await (res1 && new Promise((res2, reject) => {
            // res2('Second')
            var srchIpBox: any = document.getElementsByClassName('Pickathing-dropdown');
            // var PickathingList:any = document.getElementsByClassName('Pickathing-list');
            var span = document.createElement('span');
            var i = document.createElement('i');
            span.setAttribute('id', 'pickathSearchOption');
            i.setAttribute('class', 'fa fa-search');
            srchIpBox[0].appendChild(span);
            span.appendChild(i);
            // PickathingList[0].style.display = 'none';
            span.style.position = 'absolute';
            span.style.top = '16px';
            span.style.right = '19px';
            span.style.zIndex = '999';
            res2(true);
        }));
        const result_1 = await (result == true && new Promise((res3, reject) => {
            res3(true);
        }));
        var targetButton: any = document.getElementsByClassName('Pickathing-option');
        // targetButton[0].innerHTML = '';
        targetButton[0].style.display = "none";
    }
    // loadCSS(){
    //     setTimeout(() => {



    //         var demoSelect = new Pickathing('demo-select', true, {searchLabel: 'Search for options...'});

    //     }, 200);
    //    // this is to dynamically create search box for pickath


    //         // this.readytoDisplay = true;

    // }
    // ngAfterViewInit(){


    //    // this.dynamicallyinjectSearch();


    //     //this.defaultSelectOptionRemoval('Pickathing-option');
    // }
    // dynamicallyinjectSearch(){
    //     setTimeout(() => {
    //         var srchIpBox:any = document.getElementsByClassName('Pickathing-dropdown');


    //         // var PickathingList:any = document.getElementsByClassName('Pickathing-list');
    //         var span = document.createElement('span');
    //         var i = document.createElement('i');

    //         span.setAttribute('id','pickathSearchOption');

    //         i.setAttribute('class','fa fa-search');

    //         srchIpBox[0].appendChild(span);
    //         span.appendChild(i);

    //         // PickathingList[0].style.display = 'none';

    //         span.style.position = 'absolute';
    //         span.style.top = '16px';
    //         span.style.right = '19px';
    //         span.style.zIndex = '999';

    //     }, 600);
    // }
    // defaultSelectOptionRemoval(str:string){
    //     setTimeout(() => {
    //         var targetButton:any = document.getElementsByClassName(str);

    //         // targetButton[0].innerHTML = '';
    //         targetButton[0].style.display = "none";

    //     }, 700);

    // }
    getCalendar() {
        this.bootstrapDateTime.datetime({
            component: 'selectedLogDate'
        });



    }



    initReportSection({ general = false, RFIDWithClass = false, RFIDWithMember = false,
        RFIDWithRoute = false, RFIDWithAttendance = false, RFIDWithSmsLog = false,
        RFIDStatusCheck = false, RFIDRouteWiseCount = false, RFIDAppPush = false } = {}) {
        let providedParam = Object.getOwnPropertyNames(arguments[0]);
        let calleFunction = this.reportLookUpFunction[providedParam[0]];
        this.reportHeaderSection.general = general;
        this.reportHeaderSection.RFIDWithClass = RFIDWithClass;
        this.reportHeaderSection.RFIDWithMember = RFIDWithMember;
        this.reportHeaderSection.RFIDWithRoute = RFIDWithRoute;
        this.reportHeaderSection.RFIDWithAttendance = RFIDWithAttendance;
        this.reportHeaderSection.RFIDWithSmsLog = RFIDWithSmsLog;
        this.reportHeaderSection.RFIDStatusCheck = RFIDStatusCheck;
        this.reportHeaderSection.RFIDRouteWiseCount = RFIDRouteWiseCount;
        this.reportHeaderSection.RFIDAppPush = RFIDAppPush;
        this[`${calleFunction}`](calleFunction);
        this.initReportSectionContent({});
        this.resetAllValues();

    }

    initReportSectionContent({ general = false, RFIDWithClass = false, RFIDWithMember = false,
        RFIDWithRoute = false, RFIDWithAttendance = false, RFIDWithSmsLog = false,
        RFIDStatusCheck = false, RFIDRouteWiseCount = false, RFIDAppPush = false } = {}) {
        this.reportContentSection.general = general;
        this.reportContentSection.RFIDWithClass = RFIDWithClass;
        this.reportContentSection.RFIDWithMember = RFIDWithMember;
        this.reportContentSection.RFIDWithRoute = RFIDWithRoute;
        this.reportContentSection.RFIDWithAttendance = RFIDWithAttendance;
        this.reportContentSection.RFIDWithSmsLog = RFIDWithSmsLog;
        this.reportContentSection.RFIDStatusCheck = RFIDStatusCheck;
        this.reportContentSection.RFIDRouteWiseCount = RFIDRouteWiseCount;
        this.reportContentSection.RFIDAppPush = RFIDAppPush;
    }

    initGeneralRfidReport(): void {
        this.initJqUtils(undefined, 'generalRfidStartDate', 'generalRfidEndDate', undefined);
        this.generalRfidPageJump = 0;
        this.noMoredata = false;
        /*
        Disabling default loading of report
        */
        // this.viewGeneralRfidLog('general',true);

    }

    initializeReportContent(sectionContentId: string): void {
        let obj = {};
        obj['' + sectionContentId] = true;
        this.initReportSectionContent(obj);
        setTimeout(() => { this.resetTemplateError() }, 100);
    }
    initRfidWithClassReport(): void {
        this.selectedClass = '';
        this.selectedSection = '';
        this.initJqUtils(undefined, 'routeHistoryStartDate', 'routeHistoryEndDate', undefined);
        setTimeout(() => { this.resetTemplateError() }, 100);

    }
    initRfidWithMemberReport(): void {
        this.initJqUtils(undefined, 'routeHistoryStartDate', 'routeHistoryEndDate', undefined);
        setTimeout(() => { this.resetTemplateError() }, 100);

    }
    initRfidWithRouteReport(): void {
        this.initJqUtils(undefined, 'routeHistoryStartDate', 'routeHistoryEndDate', undefined);
        setTimeout(() => { this.resetTemplateError() }, 100);

    }

    initRfidWithAttendenceReport(): void {
        this.selectedClassAttendence = '';
        this.attendenceinitJqUtils(undefined, 'routeHistoryStartDate', undefined);
        setTimeout(() => { this.resetTemplateError() }, 100);

    }
    initRfidWithSMSLogReport(): void {
        this.attendenceinitJqUtils(undefined, 'routeHistoryStartDate', undefined);
        setTimeout(() => { this.resetTemplateError() }, 100);

    }

    initRfidStatusCheck(): void {
        this.attendenceinitJqUtils(undefined, 'routeHistoryStartDate', undefined);
        setTimeout(() => { this.resetTemplateError() }, 100);

    }
    initRfidRouteWiseCount(): void {
        this.attendenceinitJqUtils(undefined, 'routeHistoryStartDate', undefined);
        setTimeout(() => { this.resetTemplateError() }, 100);

    }
    initRfidAppPush(): void {
        this.initJqUtils(undefined, 'routeHistoryStartDate', 'routeHistoryEndDate', undefined);
        setTimeout(() => { this.resetTemplateError() }, 100);
    }

    resetAllValues() {
        this.generalRfidLog = [];
        this.filterGeneralRfidNumber = null;
        this.noGeneralRfidRecords = false;
        this.noMoredata = false;
        this.noRouteRfidRecords = false;
        this.noClassRfidRecords = false;
        this.enableGeneralRfidExcelDownload = true;
        this.enableDisableRFIDPeriodicSubmitBtn = false;
        this.enableDisableRFIDRouteWiseSubmitBtn = false;
        this.enableDisableRFIDAttendanceSubmitBtn = false;
        this.enableDisableRFIDSMSSubmitBtn = false;
        this.enableDisableRFIDStatusCheckSubmitBtn = false;
        this.enableDisableRFIDRouteWiseCntSubmitBtn = false;
        this.isClassRequired = true;
        this.GeneralReportExcelDownloadLink = null;
        this.classReportExcelDownloadLink = null;
        this.memberReportExcelDownloadLink = null;
        this.intermediateObj = {};
    }

    getAvailableClasses() {
        let apiHeader = {
            data: {
                key: localStorage.getItem('scbToken')
            }
        }
        this.apiService.getCurrentOrgDetails(apiHeader).then(response => {
            if (response.response) {
                this.OrgClasses = response.response.classLists;
                this.orgSections = response.response.SectionLists;
            }
        });
    }
    public selectedRoute: any;
    public selectedRouteID: any;
    public selectRouteType: any;
    public vehInfo: any = {};
    setUserRouteSelections(selectedRoute: any) {

        this.selectedRoute = selectedRoute && selectedRoute[0] ? selectedRoute[0].vehId : '';

        this.selectRouteType = selectedRoute && selectedRoute[0] ? selectedRoute[0].genericName : '';
        this.selectedRouteID = selectedRoute && selectedRoute[0] ? selectedRoute[0].routeId : '';

    }

    getRouteWalkerList() {
        let apiHeader = {
            data: {
                key: localStorage.getItem('scbToken'),
                filter: {
                    type: 'gate'
                }
            }

        }
        this.apiService.getAllOrgDevices(apiHeader).then(response => {
            if (response.response) {


                let walker = response.response;

                (Object.keys(walker)).forEach(report => {

                    this.walkerList.push(walker[report].vehNo);
                    this.walkerVehId.push(walker[report].vehId);
                    if (this.vehInfo[walker[report].vehId] === undefined) {
                        this.vehInfo[walker[report].vehId] = [];
                    }

                    this.vehInfo[walker[report].vehId].push({
                        'vehNo': walker[report].vehNo
                    });
                });

                // this.orgSections = response.response.SectionLists;
            }
        });
    }

    initChosen() {
        setTimeout(() => {
            // this.chosenUtility.initializeChosen('demo-select');

        }, 100);

        this.bindJqChangeEvt('demo-select');

    }

    generateOptions(param: any): void {
        // 
        //  console.log('#############3', param)
        for (let obj in param) {
            let data = {};
            if (param[obj]) {
                data['reportName'] = this.reportLookUpTitle[obj];
                data['value'] = obj;

                this.reportOptions.push(data);

            }
        }

    }

    public rfidInfoEndDateError: boolean;
    public rfidInfoStartDateError: boolean;
    public routesDatesMisMatchError: boolean;

    initiateGeneralRifdInputValidator() { //validator by Deepak
        let isValid: boolean = true;

        let rfidInfoEndDate = $('#generalRfidEndDate').val();
        let rfidInfoStartDate = $('#generalRfidStartDate').val();

        let dateObj: any = this.genericDateValidate(rfidInfoStartDate, rfidInfoEndDate);

        if (dateObj.endTimeStampString.length <= 10) {
            // if (rfidInfoStartDate && !rfidInfoEndDate) {
            this.rfidInfoEndDateError = true;
            isValid = false;
        } else {
            this.rfidInfoEndDateError = false;
        }


        if (dateObj.stTimeStampString.length <= 10) {
            // if (!rfidInfoStartDate && rfidInfoEndDate) {
            this.rfidInfoStartDateError = true;
            isValid = false;
        } else {
            this.rfidInfoStartDateError = false;
        }

        if (dateObj.stTimeStamp && dateObj.endTimeStamp) {
            // if (rfidInfoEndDate && rfidInfoStartDate) {

            if (dateObj.stTimeStamp > dateObj.endTimeStamp) {


                // if ($('#generalRfidStartDate').val() > $('#generalRfidEndDate').val()) {
                this.routesDatesMisMatchError = true;
                isValid = false;
            } else {
                this.routesDatesMisMatchError = false;
            }
        }
        // isValid = false;
        return isValid;
    }

    viewGeneralRfidLog(sectionContentId: string, isFirstAPIcall: boolean): void {
        if (this.initiateGeneralRifdInputValidator()) {
            let filterObj = {};
            let startDateService: any, endDateService: any;
            this.enableDisableRFIDPeriodicSubmitBtn = true;// added by krish
            //checking for Filters  
            //this.enableGeneralRfidExcelDownload=true;//added by krish

            let startDate = this.bootstrapDateTime.getInputvalue('generalRfidStartDate');
            let endDate = this.bootstrapDateTime.getInputvalue('generalRfidEndDate');
            // this.datechanger.transformDate(startDate);
            // let startDateService:any,endDateService:any;
            startDateService = this.dateChange.dateConversionForApicalls(startDate, 'date_time');
            endDateService = this.dateChange.dateConversionForApicalls(endDate, 'date_time');


            if (startDate) {
                filterObj['fromDate'] = startDateService.date;
                filterObj['fromTime'] = startDateService.time;

            }
            if (endDate) {
                filterObj['toDate'] = endDateService.date;
                filterObj['toTime'] = endDateService.time;
            }
            // if (startDate) {
            //     startDate = startDate.split(' ');
            //     startDate[1] = startDate[1] + ':00';
            //     filterObj['fromDate'] = startDate[0];
            //     filterObj['fromTime'] = startDate[1];
            // }

            // if (endDate) {
            //     endDate = endDate.split(' ');
            //     endDate[1] = endDate[1] + ':00';
            //     filterObj['toDate'] = endDate[0];
            //     filterObj['toTime'] = endDate[1];
            // }

            if (this.filterGeneralRfidNumber) {
                filterObj['rfid'] = this.filterGeneralRfidNumber;
            }

            if (this.userSelectedOptions[0]) {
                filterObj['memberId'] = this.userSelectedOptions[0].memberId;
            }

            let apiHeader = {
                data: {
                    key: localStorage.getItem('scbToken'),
                    extra: {
                        pageJump: this.generalRfidPageJump,
                        orderByDateCreated: '-1'
                    }
                }
            }
            if ((filterObj) && (Object.keys(filterObj).length)) {
                apiHeader['data']['form'] = filterObj;
            }
            if (isFirstAPIcall) {
                this.generalRfidLog = [];
                this.isLoading = true;
                this.showTableRowLoader = false; //hide table rows loader.
            } else {
                this.showTableRowLoader = true;
            }

            this.apiService.getGeneralRFIDReport(apiHeader).then(response => {
                if (response.status === 'success') {
                    this.displayErrorMessage = '';
                    //this.enableGeneralRfidExcelDownload=true;//added by krish

                    if (response.response.rfidData) {
                        if (!this.generalRfidLog.length) {
                            Object.assign(this.generalRfidLog, response.response.rfidData);

                        } else {
                            this.generalRfidLog = [...this.generalRfidLog, ...response.response.rfidData];
                        }
                        this.noGeneralRfidRecords = false;
                        this.noMoredata = false;
                        this.enableGeneralRfidExcelDownload = true;


                    } else {

                        //this.enableGeneralRfidExcelDownload=true;//added by krish

                        if (isFirstAPIcall) this.noGeneralRfidRecords = true;
                        this.enableGeneralRfidExcelDownload = (!isFirstAPIcall && this.generalRfidPageJump >= 1) ? true : false;
                        this.noMoredata = true;
                        this.generalRfidPageJump = 0;
                        //this.enableGeneralRfidExcelDownload=true;

                        if (response.response.totalCnt == 0) { //added by krish

                            this.enableGeneralRfidExcelDownload = false;//added by krish
                        } else {
                            this.enableGeneralRfidExcelDownload = true;//added by krish
                        }


                        // this.enableGeneralRfidExcelDownload = false;ss

                    }
                    if (isFirstAPIcall) {
                        this.isLoading = false;
                        this.initializeReportContent(sectionContentId);
                        if (('fromDate' in filterObj) && ('fromTime' in filterObj) && ('toDate' in filterObj) && ('toTime' in filterObj)) {
                            this.generalRfidExcelDownload(apiHeader['data']['form']);
                        } else {

                            this.enableGeneralRfidExcelDownload = true;//added by krish - already here 'false'
                        }
                    }
                    this.enableDisableRFIDPeriodicSubmitBtn = false;// added by krishh 

                } else {
                    this.isLoading = false;
                    this.enableDisableRFIDPeriodicSubmitBtn = false;// added by krish  
                    this.displayErrorMessage = this.globalUtils.getErrorMsg(response.ec);
                }
                this.showTableRowLoader = false;
            }).catch(error => { })


        }

    }

    generalRfidExcelDownload(param: any): void {

        this.GeneralReportExcelDownloadLink = `${this.apiService.base}report/download_generalrfidreports&key=${localStorage.getItem('scbToken')}&fromDate=${param.fromDate}&fromTime=${param.fromTime}&toDate=${param.toDate}&toTime=${param.toTime}&rfid=${this.filterGeneralRfidNumber ? this.filterGeneralRfidNumber : ''}`;

        // this.enableGeneralRfidExcelDownload = true;
    }


    public chooseRouteError: boolean;
    initiateRoutewiseRifdInputValidator() { //validator by Deepak
        let isValid: boolean = true;

        if (!this.userSelectedOptions[0]) {
            this.chooseRouteError = true;
            isValid = false;
        } else {
            this.chooseRouteError = false;
        }


        let rfidRouteInfoEndDate = $('#routeHistoryEndDate').val();
        let rfidRouteInfoStartDate = $('#routeHistoryStartDate').val();


        let dateObj: any = this.genericDateValidate(rfidRouteInfoStartDate, rfidRouteInfoEndDate);


        if (dateObj.endTimeStampString.length <= 10) {

            // if (!rfidRouteInfoEndDate) {
            this.rfidInfoEndDateError = true;
            isValid = false;
        } else {
            this.rfidInfoEndDateError = false;
        }
        if (dateObj.stTimeStampString.length <= 10) {
            // if (!rfidRouteInfoStartDate) {
            this.rfidInfoStartDateError = true;
            isValid = false;
        } else {
            this.rfidInfoStartDateError = false;
        }

        if (dateObj.stTimeStampString.length >= 10 && dateObj.stTimeStampString.length >= 10) {
            if (dateObj.stTimeStamp > dateObj.endTimeStamp) {
                // if ($('#routeHistoryStartDate').val() > $('#routeHistoryEndDate').val()) {
                this.routesDatesMisMatchError = true;
                isValid = false;
            } else {
                this.routesDatesMisMatchError = false;
            }
        }

        return isValid;
    }



    viewRfidWithRoute(sectionContentId: string): void {
        if (this.initiateRoutewiseRifdInputValidator()) {
            // let startDate = this.bootstrapDateTime.getInputvalue('routeHistoryStartDate').split(' ');
            // let endDate = this.bootstrapDateTime.getInputvalue('routeHistoryEndDate').split(' ');
            this.enableDisableRFIDRouteWiseSubmitBtn = true;// added by krish
            let startDate = this.bootstrapDateTime.getInputvalue('routeHistoryStartDate')
            let endDate = this.bootstrapDateTime.getInputvalue('routeHistoryEndDate')
            let startDateService: any, endDateService: any;
            startDateService = this.dateChange.dateConversionForApicalls(startDate, 'date_time');
            endDateService = this.dateChange.dateConversionForApicalls(endDate, 'date_time');
            //this.vehicleLists:any  = [];
            this.vehicleLists.push("nwvEBcOZSAGhMbyzmLYs6281391527761101");
            this.vehicleLists.push("liAZIDxHfRXhVCFjKzbJ3269451527761256");
            this.vehicleLists.push("XZKtdofOJAcnWQVUiClq6147081527761334");
            this.vehicleLists.push("yAhBnfDsZlurPSvCWgHI8529361527760611");
            this.vehicleLists.push("LXSvJNIgVeGorlbpTQYB6194751527760686");
            let apiHeader = {
                data: {
                    key: localStorage.getItem('scbToken'),
                    // form: {
                    //     fromDate: startDate[0],
                    //     fromTime: startDate[1] + ':00',
                    //     toDate: endDate[0],
                    //     toTime: endDate[1] + ':00',
                    //     routeId: this.userSelectedOptions[0]['routeId']
                    //     // routeId:'VlBQUTXCYnoqDKNctfFG2795631512628641'
                    // }
                    form: {
                        fromDate: startDateService.date,
                        fromTime: startDateService.time,
                        toDate: endDateService.date,
                        toTime: endDateService.time,
                        routeId: this.userSelectedOptions[0]['routeId'],

                        // routeId:'VlBQUTXCYnoqDKNctfFG2795631512628641'
                    }
                }
            }

            if (this.selectedUserName.length > 0) {


                if (this.selectedUserName[0]['memberUID'] === '' || this.selectedUserName[0]['memberUID'] === undefined) {

                    this.rfidSwipedMember = false;

                } else {
                    this.rfidSwipedMember = true;


                }
            } else {

            }
            //rfid_App_push_ngModel.section
            //this.userSelectedOptions

            let apiHeader1 = {
                data: {
                    key: localStorage.getItem('scbToken'),
                    // form: {
                    //     fromDate: startDate[0],
                    //     fromTime: startDate[1] + ':00',
                    //     toDate: endDate[0],
                    //     toTime: endDate[1] + ':00',
                    //     routeId: this.userSelectedOptions[0]['routeId']
                    //     // routeId:'VlBQUTXCYnoqDKNctfFG2795631512628641'
                    // }
                    form: {
                        fromDate: startDateService.date,
                        fromTime: startDateService.time,
                        toDate: endDateService.date,
                        toTime: endDateService.time,
                        grouprouteId: this.vehicleLists,

                        // routeId:'VlBQUTXCYnoqDKNctfFG2795631512628641' 
                    }
                }
            }

            //console.log('RRRRRRR ',apiHeader);
            this.generalRfidLog = [];
            this.isLoading = true; this.routeWiseMemberInfo = [];
            this.dateWiseRouteRfid = [];
            this.routeMembersList = [];
            let allStuDetails: any = [];
            this.routememberID = [];
            let allStuObjobj = {};

            this.apiService.getCustomRFIDReport(apiHeader).then(response => {
                // console.log('RRRRR ,---- ',response.response);

                let routeDatas: any = response.response;

                // (Object.keys(routeDatas)).forEach((routes: string) => {
                //console.log('YYYYYYYYYY :',routeDatas[routes]);

                // });
                //console.log('YYYYYYYYYY :',response.status);
                if (response.status === 'success') {
                    this.displayErrorMessage = '';
                    // console.log('YYYYYYYYYYQQQQQQQQQQ :',response.status);
                    if (response.response) {
                        this.isLoading = false;
                        this.intermediateObj['swipeCount'] = "";
                        this.intermediateObj['totalMember'] = "";

                        this.intermediateObj['swipeCount'] = response.response.routeInfo.swipeCount;
                        this.intermediateObj['totalMember'] = response.response.routeInfo.totalMember;
                        for (let info in response.response.routeInfo) {

                            let rInfo = response.response[info];
                            for (let index in rInfo) {
                                this.generalRfidLog.push(...rInfo[index]);
                            }
                        }
                        this.noRouteRfidRecords = false;

                        //memberUID
                        if (response.response && response.response.MemberInfo) {

                            let tempdates: any = response.response.MemberInfo;

                            let routemember: any = response.response.routeMember;
                            let totalStudent: number = 0;
                            //console.log('ROUTECLASS ',this.rfid_App_push_ngModel.class); 

                            // console.log('ROUTECLASS ',this.rfid_App_push_ngModel.class);
                            // console.log('ROUTESECTION ',this.rfid_App_push_ngModel.section);
                            // console.log('ROUTEMEMBER ',this.selectedUserName);
                            //console.log('no : ',this.rfidStatus);

                            if (this.rfidStatus === undefined || this.rfidStatus === '') {
                                console.log('STATUS : ', this.rfidStatus);
                                this.rfidStatus = 'B';
                            }


                            if (this.rfid_App_push_ngModel.class === undefined || this.rfid_App_push_ngModel.class === '') {
                                this.rfidSwipedClass = false;

                            } else {
                                this.rfidSwipedClass = true;

                            }


                            if (this.rfid_App_push_ngModel.section === undefined || this.rfid_App_push_ngModel.section === '') {
                                this.rfidSwipedSection = false;

                            } else {
                                this.rfidSwipedSection = true;

                            }



                            (Object.keys(routemember)).forEach((date1: string) => {



                                if ((!this.rfidSwipedSection) && (!this.rfidSwipedClass) && (!this.rfidSwipedMember)) {

                                    totalStudent++;
                                    allStuObjobj = {
                                        memberUID: routemember[date1].memberUID,
                                        memberName: routemember[date1].memberName,
                                        memberClass: routemember[date1].memberClass,
                                        memberSection: routemember[date1].memberSection,
                                        memberRFID: routemember[date1].memberRFID
                                    }
                                    console.log('TOTAL ID : ', routemember[date1].memberUID);
                                    this.routeMembersList.push(allStuObjobj);
                                    this.routememberID.push(routemember[date1].memberUID);

                                } else {
                                    //console.log('111111 ',this.rfidSwipedClass);
                                    if (this.rfidSwipedClass) {
                                        //console.log('222222222 ',this.rfid_App_push_ngModel.class,' WWWW ',routemember[date1].memberClass);
                                        if (this.rfid_App_push_ngModel.class === routemember[date1].memberClass) {  //CLASS FILTER CODE STARTS HERE-COMPARE 
                                            totalStudent++;
                                            allStuObjobj = {
                                                memberUID: routemember[date1].memberUID,
                                                memberName: routemember[date1].memberName,
                                                memberClass: routemember[date1].memberClass,
                                                memberSection: routemember[date1].memberSection,
                                                memberRFID: routemember[date1].memberRFID
                                            }

                                            this.routeMembersList.push(allStuObjobj);
                                            this.routememberID.push(routemember[date1].memberUID);
                                            // console.log('3333344 ',totalStudent,'  JJJJ ',routemember[date1].memberUID);
                                        } //CLASS FILTER CODE ENDS HERE-COMPARE 

                                    }




                                    if (this.rfidSwipedSection) {

                                        if (this.rfid_App_push_ngModel.section === routemember[date1].memberSection) {  //SECTION FILTER CODE STARTS HERE-COMPARE 
                                            totalStudent++;
                                            allStuObjobj = {
                                                memberUID: routemember[date1].memberUID,
                                                memberName: routemember[date1].memberName,
                                                memberClass: routemember[date1].memberClass,
                                                memberSection: routemember[date1].memberSection,
                                                memberRFID: routemember[date1].memberRFID
                                            }

                                            this.routeMembersList.push(allStuObjobj);
                                            this.routememberID.push(routemember[date1].memberUID);

                                        } //SECTION FILTER CODE ENDS HERE-COMPARE 

                                    }

                                    //console.log('111111111 ',this.rfidSwipedMember);

                                    if (this.rfidSwipedMember) {

                                        if (this.selectedUserName[0]['memberUID'] === routemember[date1].memberUID) {  //MEMBER FILTER CODE STARTS HERE-COMPARE 
                                            totalStudent++;

                                            allStuObjobj = {
                                                memberUID: routemember[date1].memberUID,
                                                memberName: routemember[date1].memberName,
                                                memberClass: routemember[date1].memberClass,
                                                memberSection: routemember[date1].memberSection,
                                                memberRFID: routemember[date1].memberRFID
                                            }
                                            //console.log('2222225555 ',this.selectedUserName[0]['memberUID'],routemember[date1].memberUID);
                                            this.routeMembersList.push(allStuObjobj);
                                            this.routememberID.push(routemember[date1].memberUID);

                                        } //MEMBER FILTER CODE ENDS HERE-COMPARE 

                                    }



                                }


                                //private userSelectedOptions: any[];


                            });
                            //2027




                            (Object.keys(tempdates)).forEach((date: string) => {
                                //console.log('88888888888888888888777777 ',Object.keys(tempdates[date]));

                                let swipedStuDetails: any = [];
                                let swipedStudent: number = 0;
                                let notSwipedStudent: number = 0;

                                Object.keys(tempdates[date]).forEach((member_key: any) => {



                                    if ((!this.rfidSwipedSection) && (!this.rfidSwipedClass) && (!this.rfidSwipedMember) && (this.rfidStatus === 'B' || this.rfidStatus === 'S')) {

                                        if (this.routememberID.indexOf(tempdates[date][member_key].memberUID) === -1) {
                                            console.log('11111111 ', tempdates[date][member_key].memberUID);


                                        }
                                        else {
                                            console.log('ELSEEE11111111 ', tempdates[date][member_key].memberUID);

                                            swipedStuDetails.push(tempdates[date][member_key].memberUID);
                                            swipedStudent++;

                                            let createSwipedObj = {
                                                swipedDate: date,
                                                memberName: tempdates[date][member_key].memberName,
                                                memberClass: tempdates[date][member_key].memberClass,
                                                memberSection: tempdates[date][member_key].memberSection,
                                                rfidswipNearLocation: tempdates[date][member_key].rfidswipNearLocation,
                                                rfidSwipTime: tempdates[date][member_key].rfidSwipTime,
                                                vehicleName: tempdates[date][member_key].vehicleName,
                                                swipeFlag: 'IN'

                                            };

                                            //console.log('SWIPED ID : ',tempdates[date][member_key].memberUID);
                                            this.routeWiseMemberInfo.push(createSwipedObj);

                                        }

                                    } else {
                                        if (this.rfidSwipedClass && (this.rfidStatus === 'B' || this.rfidStatus === 'S')) {
                                            if (this.rfid_App_push_ngModel.class === tempdates[date][member_key].memberClass) { ////CLASS FILTER CODE STARTS HERE-COMPARE 

                                                swipedStuDetails.push(tempdates[date][member_key].memberUID);
                                                swipedStudent++;

                                                let createSwipedObj = {
                                                    swipedDate: date,
                                                    memberName: tempdates[date][member_key].memberName,
                                                    memberClass: tempdates[date][member_key].memberClass,
                                                    memberSection: tempdates[date][member_key].memberSection,
                                                    rfidswipNearLocation: tempdates[date][member_key].rfidswipNearLocation,
                                                    rfidSwipTime: tempdates[date][member_key].rfidSwipTime,
                                                    vehicleName: tempdates[date][member_key].vehicleName,
                                                    swipeFlag: 'IN'

                                                };


                                                this.routeWiseMemberInfo.push(createSwipedObj);

                                            }   //CLASS FILTER CODE ENDS HERE-COMPARE 
                                        }



                                        if (this.rfidSwipedSection && (this.rfidStatus === 'B' || this.rfidStatus === 'S')) {
                                            if (this.rfid_App_push_ngModel.section === tempdates[date][member_key].memberSection) { ////SECTION FILTER CODE STARTS HERE-COMPARE 

                                                swipedStuDetails.push(tempdates[date][member_key].memberUID);
                                                swipedStudent++;

                                                let createSwipedObj = {
                                                    swipedDate: date,
                                                    memberName: tempdates[date][member_key].memberName,
                                                    memberClass: tempdates[date][member_key].memberClass,
                                                    memberSection: tempdates[date][member_key].memberSection,
                                                    rfidswipNearLocation: tempdates[date][member_key].rfidswipNearLocation,
                                                    rfidSwipTime: tempdates[date][member_key].rfidSwipTime,
                                                    vehicleName: tempdates[date][member_key].vehicleName,
                                                    swipeFlag: 'IN'

                                                };


                                                this.routeWiseMemberInfo.push(createSwipedObj);

                                            }   //SECTION FILTER CODE ENDS HERE-COMPARE 
                                        }



                                        //this.userSelectedOptions[0]['memberUID']
                                        //console.log('222222 ',this.rfidSwipedMember);
                                        if (this.rfidSwipedMember && (this.rfidStatus === 'B' || this.rfidStatus === 'S')) {
                                            if (this.selectedUserName[0]['memberUID'] === tempdates[date][member_key].memberUID) { ////MEMBER FILTER CODE STARTS HERE-COMPARE 
                                                // console.log('33333333344444 ',this.selectedUserName[0]['memberUID'],tempdates[date][member_key].memberUID);
                                                swipedStuDetails.push(tempdates[date][member_key].memberUID);
                                                swipedStudent++;

                                                let createSwipedObj = {
                                                    swipedDate: date,
                                                    memberName: tempdates[date][member_key].memberName,
                                                    memberClass: tempdates[date][member_key].memberClass,
                                                    memberSection: tempdates[date][member_key].memberSection,
                                                    rfidswipNearLocation: tempdates[date][member_key].rfidswipNearLocation,
                                                    rfidSwipTime: tempdates[date][member_key].rfidSwipTime,
                                                    vehicleName: tempdates[date][member_key].vehicleName,
                                                    swipeFlag: 'IN'

                                                };


                                                this.routeWiseMemberInfo.push(createSwipedObj);

                                            }   //MEMBER FILTER CODE ENDS HERE-COMPARE 
                                        }



                                    }





                                });

                                //console.log('SWIPED TOTAL STUDENT ',swipedStudent);
                                let listNotSwipedId: any = [];
                                for (let i = 0; i < this.routeMembersList.length; i++) {



                                    if (swipedStuDetails.indexOf(this.routeMembersList[i].memberUID) === -1) {



                                        if ((!this.rfidSwipedSection) && (!this.rfidSwipedClass) && (!this.rfidSwipedMember) && (this.rfidStatus === 'B' || this.rfidStatus === 'NS')) {

                                            let createNotSwipedObj = {
                                                swipedDate: date,
                                                memberName: this.routeMembersList[i].memberName,
                                                memberClass: this.routeMembersList[i].memberClass,
                                                memberSection: this.routeMembersList[i].memberSection,
                                                rfidswipNearLocation: '---',
                                                rfidSwipTime: '-',
                                                vehicleName: '-',
                                                swipeFlag: 'OUT'

                                            };
                                            notSwipedStudent++;
                                            console.log('NOT SWIPED ID : ', this.routeMembersList[i].memberUID);
                                            this.routeWiseMemberInfo.push(createNotSwipedObj);

                                        } else {
                                            if (this.rfidSwipedClass && (this.rfidStatus === 'B' || this.rfidStatus === 'NS')) {
                                                if (this.rfid_App_push_ngModel.class === this.routeMembersList[i].memberClass) {   //CLASS FILTER CODE STARTS HERE-COMPARE 

                                                    let createNotSwipedObj = {
                                                        swipedDate: date,
                                                        memberName: this.routeMembersList[i].memberName,
                                                        memberClass: this.routeMembersList[i].memberClass,
                                                        memberSection: this.routeMembersList[i].memberSection,
                                                        rfidswipNearLocation: '---',
                                                        rfidSwipTime: '-',
                                                        vehicleName: '-',
                                                        swipeFlag: 'OUT'

                                                    };
                                                    notSwipedStudent++;

                                                    this.routeWiseMemberInfo.push(createNotSwipedObj);
                                                }   //CLASS FILTER CODE STARTS ENDS-COMPARE 
                                            }



                                            if (this.rfidSwipedSection && (this.rfidStatus === 'B' || this.rfidStatus === 'NS')) {
                                                if (this.rfid_App_push_ngModel.section === this.routeMembersList[i].memberSection) {   //SECTION FILTER CODE STARTS HERE-COMPARE 

                                                    let createNotSwipedObj = {
                                                        swipedDate: date,
                                                        memberName: this.routeMembersList[i].memberName,
                                                        memberClass: this.routeMembersList[i].memberClass,
                                                        memberSection: this.routeMembersList[i].memberSection,
                                                        rfidswipNearLocation: '---',
                                                        rfidSwipTime: '-',
                                                        vehicleName: '-',
                                                        swipeFlag: 'OUT'

                                                    };
                                                    notSwipedStudent++;

                                                    this.routeWiseMemberInfo.push(createNotSwipedObj);
                                                }   //SECTION FILTER CODE STARTS ENDS-COMPARE 
                                            }

                                            //this.userSelectedOptions[0]['memberUID']

                                            if (this.rfidSwipedMember && (this.rfidStatus === 'B' || this.rfidStatus === 'NS')) {
                                                if (this.selectedUserName[0]['memberUID'] === this.routeMembersList[i].memberUID) {   //MEMBER FILTER CODE STARTS HERE-COMPARE 

                                                    let createNotSwipedObj = {
                                                        swipedDate: date,
                                                        memberName: this.routeMembersList[i].memberName,
                                                        memberClass: this.routeMembersList[i].memberClass,
                                                        memberSection: this.routeMembersList[i].memberSection,
                                                        rfidswipNearLocation: '---',
                                                        rfidSwipTime: '-',
                                                        vehicleName: '-',
                                                        swipeFlag: 'OUT'

                                                    };
                                                    notSwipedStudent++;

                                                    this.routeWiseMemberInfo.push(createNotSwipedObj);
                                                }   //MEMBER FILTER CODE STARTS ENDS-COMPARE 
                                            }

                                        }





                                    } else {
                                        //console.log('ELSEEEEEE22222  ',this.routeMembersList[i].memberUID);

                                    }

                                }
                                let finalNotSwipedTotalStudent: number = 0;
                                finalNotSwipedTotalStudent = totalStudent - swipedStudent;
                                console.log('NOT SWIPED TOTAL STUDENT ', finalNotSwipedTotalStudent);
                                var fieldsDate = date.split(/-/);
                                let dateWiseRFIDObj = {
                                    dateWiseRFID: date,
                                    totalStudent: totalStudent,
                                    swipedStu: swipedStudent,
                                    notSwipedStu: finalNotSwipedTotalStudent,
                                    alignDateWiseRFID: fieldsDate[2] + "-" + fieldsDate[1] + "-" + fieldsDate[0],
                                };
                                this.dateWiseRouteRfid.push(dateWiseRFIDObj);

                            });

                        }

                    } else {
                        this.noRouteRfidRecords = true;
                    }

                    this.initializeReportContent(sectionContentId);
                    this.enableDisableRFIDRouteWiseSubmitBtn = false;//added by krish
                } else {

                    this.isLoading = false;
                    this.enableDisableRFIDRouteWiseSubmitBtn = false;//added by krish
                    this.displayErrorMessage = this.globalUtils.getErrorMsg(response.ec);
                    console.log('XXXXXX ', this.isLoading, this.enableDisableRFIDRouteWiseSubmitBtn, this.displayErrorMessage)
                }
            }).catch(error => { })
            // this.enableDisableRFIDRouteWiseSubmitBtn=false;//added by krishh
            this.intermediateObj = {
                reportStartTime: this.dateconvertor(startDateService.date, startDateService.time),
                reportEndTime: this.dateconvertor(endDateService.date, endDateService.time),
                routeName: this.userSelectedOptions[0]['routeName'],

            }
            // this.routeReportExcelDownloadLink = `${this.apiService.base}report/download_rfidreports&key=${localStorage.getItem('scbToken')}&routeId=${apiHeader.data.form.routeId}&fromDate=${startDate[0]}&fromTime=${startDate[1]}:00&toDate=${endDate[0]}&toTime=${endDate[1]}:00`;


            this.routeReportExcelDownloadLink = `${this.apiService.base}report/download_rfidreports&key=${localStorage.getItem('scbToken')}&routeId=${apiHeader.data.form.routeId}&fromDate=${startDateService.date}&fromTime=${startDateService.time}&toDate=${endDateService.date}&toTime=${endDateService.time}`;



        }

    }

    public chooseMemberError: boolean;
    initiateMemberwiseRifdInputValidator() { //validator by Deepak
        let isValid: boolean = true;

        if (!this.userSelectedOptions[0]) {
            this.chooseMemberError = true;
            isValid = false;
        } else {
            this.chooseMemberError = false;
        }


        let rfidMemberInfoEndDate = $('#routeHistoryEndDate').val();
        let rfidMemberInfoStartDate = $('#routeHistoryStartDate').val();

        let dateObj: any = this.genericDateValidate(rfidMemberInfoStartDate, rfidMemberInfoEndDate);

        if (dateObj.endTimeStampString.length <= 10) {
            this.rfidInfoEndDateError = true;
            isValid = false;
        } else {
            this.rfidInfoEndDateError = false;
        }

        if (dateObj.stTimeStampString.length <= 10) {
            // if (!rfidMemberInfoStartDate) {
            this.rfidInfoStartDateError = true;
            isValid = false;
        } else {
            this.rfidInfoStartDateError = false;
        }

        if (dateObj.stTimeStampString && dateObj.endTimeStampString) {
            // if ($('#routeHistoryStartDate').val() > $('#routeHistoryEndDate').val()) {
            if (dateObj.stTimeStamp > dateObj.endTimeStamp) {
                this.routesDatesMisMatchError = true;
                isValid = false;
            } else {
                this.routesDatesMisMatchError = false;
            }
        }

        return isValid;
    }


    viewRfidWithMember(sectionContentId: string): void {
        if (this.initiateMemberwiseRifdInputValidator()) {
            // let startDate = this.bootstrapDateTime.getInputvalue('routeHistoryStartDate').split(' ');
            // let endDate = this.bootstrapDateTime.getInputvalue('routeHistoryEndDate').split(' ');
            let startDate = this.bootstrapDateTime.getInputvalue('routeHistoryStartDate');
            let endDate = this.bootstrapDateTime.getInputvalue('routeHistoryEndDate');
            let startDateService: any, endDateService: any;
            startDateService = this.dateChange.dateConversionForApicalls(startDate, 'date_time');
            endDateService = this.dateChange.dateConversionForApicalls(endDate, 'date_time');
            let apiHeader = {
                data: {
                    key: localStorage.getItem('scbToken'),
                    // form: {
                    //     fromDate: startDate[0],
                    //     fromTime: startDate[1] + ':00',
                    //     toDate: endDate[0],
                    //     toTime: endDate[1] + ':00',
                    //     memberId: this.userSelectedOptions[0]['memberId']
                    // }
                    form: {
                        fromDate: startDateService.date,
                        fromTime: startDateService.time,
                        toDate: endDateService.date,
                        toTime: endDateService.time,
                        memberId: this.userSelectedOptions[0]['memberId']
                    }
                }
            }
            this.generalRfidLog = [];
            this.intermediateObj = {};
            this.isLoading = true;

            this.apiService.getCustomRFIDReport(apiHeader).then(response => {
                if (response.status === 'success') {

                    this.displayErrorMessage = ''; this.routeWiseMemberInfo = [];
                    if (response.response) {


                        this.isLoading = false;
                        for (let info in response.response.memberInfo) {
                            let rInfo = response.response[info];
                            for (let index in rInfo) {
                                this.generalRfidLog.push(...rInfo[index]);
                            }
                        }

                        this.noMemberRfidRecords = (this.generalRfidLog.length) ? false : true;
                        let memberInfo = response.response.memberInfo;
                        let routeInfo1 = memberInfo['memberRoute'] && memberInfo['memberRoute'][0] ? memberInfo['memberRoute'][0] : '';
                        let routeInfo2 = memberInfo['memberRoute'] && memberInfo['memberRoute'][1] ? memberInfo['memberRoute'][1] : '';
                        this.intermediateObj = {
                            memberName: response.response.memberInfo['memberName'],
                            memberClass: response.response.memberInfo['memberClass'],
                            memberSection: response.response.memberInfo['memberSection'],
                            memberID: response.response.memberInfo['memberUID'],
                            memberRFID: response.response.memberInfo['memberRFID'],
                            memberRoute: routeInfo1 + ',' + routeInfo2
                        }



                        if (response.response && response.response.rfidLog) {

                            let tempCnt: number = 0;

                            let tempdates: any = response.response.rfidLog;
                            (Object.keys(tempdates)).forEach((date: string) => {

                                tempCnt = Object.keys(tempdates[date]).length > 0 ? tempCnt + 1 : tempCnt;



                                Object.keys(tempdates[date]).forEach((member_key: any) => {

                                    this.routeWiseMemberInfo.push(tempdates[date][member_key]);

                                });

                            });

                            this.noRouteRfidRecords = (tempCnt <= 0) ? true : false;


                        }



                    }
                    // this.intermediateObj['reportStartTime'] = startDate;
                    this.intermediateObj['reportStartTime'] = this.dateconvertor(startDateService.date, startDateService.time),
                        this.intermediateObj['reportEndTime'] = this.dateconvertor(endDateService.date, endDateService.time),
                        // this.intermediateObj['reportEndTime'] = endDate;
                        // this.memberReportExcelDownloadLink = `${this.apiService.base}report/download_rfidreports&key=${localStorage.getItem('scbToken')}&
                        //                              memberId=${apiHeader.data.form.memberId}&fromDate=${startDate[0]}&fromTime=${startDate[1]}:00&toDate=${endDate[0]}&toTime=${endDate[1]}:00`;
                        this.memberReportExcelDownloadLink = `${this.apiService.base}report/download_rfidreports&key=${localStorage.getItem('scbToken')}&
                                                 memberId=${apiHeader.data.form.memberId}&fromDate=${startDateService.date}&fromTime=${startDateService.time}&toDate=${endDateService.date}&toTime=${endDateService.time}`;

                    this.isLoading = false;
                    this.initializeReportContent(sectionContentId);
                } else {
                    this.isLoading = false;
                    this.displayErrorMessage = this.globalUtils.getErrorMsg(response.ec);
                }
            }).catch(error => { });
        }

    }

    public chooseSectionError: boolean;
    public chooseClassError: boolean;
    public chooseAttendenceClassError: boolean;


    changeEventFired = (variablename: string, value: any) => {
        this[variablename] = value;
        //console.log("check :",value,this[variablename]);
    }

    initiateClasswiseRifdInputValidator() { //validator by Deepak.
        let isValid: boolean = true;
        //console.log("checkingggggggggggguuu :",this.selectedClass);
        if (!this.selectedClass) {
            this.chooseClassError = true;
            isValid = false;
        } else {
            this.chooseClassError = false;
        }


        /* if(!this.selectedSection){
           this.chooseSectionError = true;
           isValid = false;
         }else {
           this.chooseSectionError = false;
         } */

        // if(this.selectedSection && !this.selectedClass){.
        //     this.chooseClassError = true;
        //     this.chooseSectionError = false;
        //       isValid = false;
        //   }else {
        //     this.chooseClassError = false;
        // }
        // if(!this.selectedSection && this.selectedClass){
        //     this.chooseClassError = false;
        //     this.chooseSectionError = true;
        //       isValid = false;
        // }else {
        //     this.chooseClassError = false;
        // }


        let rfidClassInfoEndDate = $('#routeHistoryEndDate').val();
        let rfidClassInfoStartDate = $('#routeHistoryStartDate').val();

        let dateObj: any = this.genericDateValidate(rfidClassInfoStartDate, rfidClassInfoEndDate);
        if (dateObj.endTimeStampString.length <= 10) {
            // if (!rfidClassInfoEndDate) {
            this.rfidInfoEndDateError = true;
            isValid = false;
        } else {
            this.rfidInfoEndDateError = false;
        }
        if (dateObj.stTimeStampString.length <= 10) {
            // if (!rfidClassInfoStartDate) {
            this.rfidInfoStartDateError = true;
            isValid = false;
        } else {
            this.rfidInfoStartDateError = false;
        }

        if (dateObj.stTimeStampString.length && dateObj.endTimeStampString.length) {

            // if (rfidClassInfoEndDate && rfidClassInfoStartDate) {
            if (dateObj.stTimeStamp > dateObj.endTimeStamp) {
                // if ($('#routeHistoryStartDate').val() > $('#routeHistoryEndDate').val()) {
                this.routesDatesMisMatchError = true;
                isValid = false;
            } else {
                this.routesDatesMisMatchError = false;
            }
        }

        return isValid;
    }


    classAttendenceValidation() {
        let isValid = true;

        let rfidInfoStartDate = $('#routeHistoryStartDate').val();
        let StartDate = this.bootstrapDateTime.getInputvalue('eventStartTime');
        let Enddate = this.bootstrapDateTime.getInputvalue('eventEndTime');


        //let rfidInfoStartDate = $('#routeHistoryStartDate').val();


        // if (!this.userSelectedOptions[0] ) {
        //     this.chooseRouteError = true;
        //    /// this.chooseAttendenceClassError=true;
        //     isValid = false;
        // } else {
        //     //this.chooseAttendenceClassError=false;
        //     this.chooseRouteError = false;
        // }
        // if(!this.selectedClassAttendence){  
        //     this.chooseAttendenceClassError=true;
        //     isValid=false;
        // }else{
        //     this.chooseAttendenceClassError=false; 

        // }

        if (!rfidInfoStartDate) {
            this.rfidInfoStartDateError = true;
            isValid = false;
        } else {
            this.rfidInfoStartDateError = false;
        }

        //  if (!StartDate) {
        //     this.fromStartTimeError = true;
        //     isValid = false;
        //   } else {
        //     this.fromStartTimeError = false;
        //   }

        //   if (!Enddate) {
        //     this.fromEndTimeError = true;
        //     isValid = false;
        //   } else {
        //     this.fromEndTimeError = false;
        //   }his.fromEndTimeError = false;
        //   }


        // if (rfidInfoEndDate && rfidInfoStartDate) {
        //     if ($('#routeHistoryStartDate').val() > $('#routeHistoryStartDate').val()) {
        //         this.routesDatesMisMatchError = true;
        //         isValid = false;
        //     } else {
        //         this.routesDatesMisMatchError = false;
        //     }
        // }



        return isValid;
    }

    viewRfidWithClass(sectionContentId: string): void {
        if (this.initiateClasswiseRifdInputValidator()) {
            // let startDate = this.bootstrapDateTime.getInputvalue('routeHistoryStartDate').split(' ');
            // let endDate = this.bootstrapDateTime.getInputvalue('routeHistoryEndDate').split(' ');
            let startDate = this.bootstrapDateTime.getInputvalue('routeHistoryStartDate');
            let endDate = this.bootstrapDateTime.getInputvalue('routeHistoryEndDate');
            let startDateService: any, endDateService: any;
            startDateService = this.dateChange.dateConversionForApicalls(startDate, 'date_time');
            endDateService = this.dateChange.dateConversionForApicalls(endDate, 'date_time');
            let apiHeader = {
                data: {
                    key: localStorage.getItem('scbToken'),
                    // form: {
                    //     fromDate: startDate[0],
                    //     fromTime: startDate[1] + ':00',
                    //     toDate: endDate[0],
                    //     toTime: endDate[1] + ':00',
                    //     memberClass: this.selectedClass
                    // }
                    form: {
                        fromDate: startDateService.date,
                        fromTime: startDateService.time,
                        toDate: endDateService.date,
                        toTime: endDateService.time,
                        memberClass: this.selectedClass
                    }
                }
            }

            if (this.selectedSection) {
                apiHeader['data']['form']['memberSection'] = this.selectedSection;
                // this.classReportExcelDownloadLink = `${this.apiService.base}report/download_rfidreports&key=${localStorage.getItem('scbToken')}&
                //                                  memberClass=${this.selectedClass}&memberSection=${this.selectedSection}&fromDate=${startDate[0]}&fromTime=${startDate[1]}:00&toDate=${endDate[0]}&toTime=${endDate[1]}:00`;


                this.classReportExcelDownloadLink = `${this.apiService.base}report/download_rfidreports&key=${localStorage.getItem('scbToken')}&
                                              memberClass=${this.selectedClass}&memberSection=${this.selectedSection}&fromDate=${startDateService.date}&fromTime=${startDateService.time}&toDate=${endDateService.date}&toTime=${endDateService.time}`;

            } else {
                // this.classReportExcelDownloadLink = `${this.apiService.base}report/download_rfidreports&key=${localStorage.getItem('scbToken')}&
                //  memberClass=${this.selectedClass}&fromDate=${startDate[0]}&fromTime=${startDate[1]}:00&toDate=${endDate[0]}&toTime=${endDate[1]}:00`;

                this.classReportExcelDownloadLink = `${this.apiService.base}report/download_rfidreports&key=${localStorage.getItem('scbToken')}&
                                                memberClass=${this.selectedClass}&fromDate=${startDateService.date}&fromTime=${startDateService.time}&toDate=${endDateService.date}&toTime=${endDateService.time}`;
            }

            this.isLoading = true;
            this.generalRfidLog = [];
            this.intermediateObj = {};
            this.noClassRfidRecords = false;
            this.apiService.getCustomRFIDReport(apiHeader).then(response => {
                if (response.status === 'success') {
                    this.displayErrorMessage = '';
                    if (response.response) {
                        let keys = Object.keys(response.response);
                        let index = keys.indexOf('studentINFO');
                        let studentsInfo = response.response.studentINFO;
                        keys.splice(index, 1);
                        this.selectedRfidDates = keys;
                        //creating empty field.
                        for (let info in studentsInfo) studentsInfo[info]['log'] = [];

                        for (let index of keys) {
                            let rfidData = response.response[index];
                            for (let info in rfidData) {
                                let data = rfidData[info];
                                studentsInfo[info]['log'].push(data);
                            }
                        }
                        this.generalRfidLog = Object.keys(studentsInfo).reduce((acc, curr) => { acc.push(studentsInfo[curr]); return acc; }, [])
                    } else {
                        this.noClassRfidRecords = true;
                    }

                    this.intermediateObj = {
                        reportStartDate: startDate,
                        reportEndDate: endDate,
                        selectedClass: this.selectedClass
                    }
                    this.isLoading = false;
                    this.initializeReportContent(sectionContentId);
                } else {
                    this.isLoading = false;
                    this.displayErrorMessage = this.globalUtils.getErrorMsg(response.ec);
                }
            }).catch(error => { });
        }

    }

    // getRouteData(routeId: any, pageJump: number) {





    // }



    apiResp(obj: any) {
        return new Promise((resolve, reject) => {
            this.apiService.viewMember(obj).then((responce: any) => {

                if (responce.status === 'success') {

                    let res = responce.response;

                    try {
                        this.routeStudentList.push(res);
                    } catch (err) {

                    }

                    resolve(true);

                }

            }).catch((error: any) => {

            });
        });
    }

    MobileNumberValidator(event: any) {
        event.srcElement.value = event.srcElement.value.replace(/[^\d].+/, "");

        if (event.srcElement.value != '') {
            //  console.log('t',event.srcElement.value);
            if ((event.which < 48 || event.which > 57)) {
                event.preventDefault();
            }
        } else {
            // event.srcElement.value=0;
        }
    }

    attendAction(apiHeader: any, selectedClass: string, reportDate: any, routeId: any, pageJump: number) {

        this.routeStudentList = [];
        let obj = {
            data: {
                key: localStorage.getItem('scbToken'),
                filter: {
                    routeId: routeId
                },
                extra: {
                    customlimit: 1,
                    pageJump: 0
                }
            }
        };

        let overlReport: any = [];

        ((async () => {
            let count = (await this.apiService.viewMember(obj)).totalMember;

            for (let i = 0; i <= ((Math.ceil(count / 100)) - 1); i++) {
                let resData = await this.apiResp({
                    data: {
                        key: localStorage.getItem('scbToken'),
                        filter: {
                            routeId: routeId
                        },
                        extra: {
                            customlimit: 100,
                            pageJump: i
                        }
                    }
                });


            }

            let attendeceData = (await this.apiService.getCustomRFIDReport(apiHeader)).response;


            this.attendenceRouteObjectForm(this.routeStudentList, selectedClass, attendeceData, reportDate);


        })());



    }

    //  Attendance reports 
    rfidAttendanceDownload: any;
    viewattendenceRfidLog(sectionContentId: string): void {

        this.addType = 'both';
        if (this.classAttendenceValidation() && this.bootstrapDateTime.getInputvalue('routeHistoryStartDate') != undefined) {
            this.enableDisableRFIDAttendanceSubmitBtn = true;// added by krish
            this.isLoading = true;
            this.incDiv = false;
            setTimeout(() => {
                this.incDiv = true;
            }, 100);

            let startDate = this.bootstrapDateTime.getInputvalue('routeHistoryStartDate').trim();
            startDate = this.dateChange.dateConversionForApicalls(startDate, 'onlydate')
            let stundetRouteList: any = [];
            let StudentInfo: any = [];

            let rountList: any = [];
            let swifedList: any = [];

            if (this.selectedWalkerAttendence) {
                this.walkerListCount = true;
            } else {
                this.walkerListCount = false;
            }
            this.attendenceDate = startDate;
            this.getAttendenceReport = '';
            this.inCount = 0;
            this.outCount = 0;
            this.totCount = 0;
            let selectedClass = this.selectedClassAttendence ? this.selectedClassAttendence : '';

            // let endDate = this.bootstrapDateTime.getInputvalue('routeHistoryEndDate').split(' ');
            // let StartDate = this.bootstrapDateTime.getInputvalue('eventStartTime');
            // let Enddate = this.bootstrapDateTime.getInputvalue('eventEndTime'); 

            let routeId = this.userSelectedOptions[0] ? this.userSelectedOptions[0].routeId : '';
            let memberClass = this.selectedClassAttendence ? this.selectedClassAttendence : '';
            let memberSection = this.selectedSectionAttendence ? this.selectedSectionAttendence : '';

            this.rfidAttendanceDownload = `${this.apiService.base}report/download_attendance_reports&key=${localStorage.getItem('scbToken')}&
            fromDate=${startDate}&fromTime=${'00:01:00'}&toDate=${startDate}&toTime=${'23:59:00'}&memberClass=${memberClass}&memberSection=${memberSection}`;


            if (routeId) {

                // RFID Route wise report API calling 

                let apiHeader = {
                    data: {
                        key: localStorage.getItem('scbToken'),
                        form: {
                            fromDate: startDate,
                            fromTime: '00:01:00',
                            toDate: startDate,
                            toTime: '23:59:00',
                            routeId: this.userSelectedOptions[0]['routeId'],
                            vehicleName: this.selectedWalkerAttendence ? this.selectedWalkerAttendence : (this.userSelectedOptions[0] && this.userSelectedOptions[0].vehNo ? this.userSelectedOptions[0].vehNo : ''),
                        }
                    }
                }

                const rInfo = this.attendAction(apiHeader, selectedClass, startDate, routeId, 0);

                this.initializeReportContent(sectionContentId);


            } else {
                let cselectedCls: string = '';
                if (this.selectedClassAttendence == 'all') {
                    cselectedCls = '';
                } else {
                    cselectedCls = this.selectedClassAttendence;
                }

                let apiHeader = {
                    data: {
                        key: localStorage.getItem('scbToken'),
                        form: {
                            fromDate: startDate,
                            toDate: startDate,
                            fromTime: '00:01:00',
                            toTime: '23:59:00',
                            vehicleName: this.selectedWalkerAttendence ? this.selectedWalkerAttendence : (this.userSelectedOptions[0] && this.userSelectedOptions[0].vehNo ? this.userSelectedOptions[0].vehNo : ''),
                            memberClass: cselectedCls,//selectedClassAttendence,
                            memberSection: this.selectedSectionAttendence
                            // vehicleName:this.selectedWalkerAttendence
                        }
                    }
                }


                this.generalRfidLog = [];
                this.intermediateObj = {};
                //this.noClassRfidAttendenceRecords = false;

                this.apiService.getCustomRFIDAttendenceReport(apiHeader).then(response => {

                    if (response.status == 'success') {

                        // if(response.response[this.selectedClassAttendence][this.attendenceDate]==undefined || response.response[this.selectedClassAttendence][this.attendenceDate]==''){
                        //     this.noAttendenceRecore=false;  



                        // }else{   

                        this.getAttendenceReport = response.response;

                        if (this.getAttendenceReport) {
                            this.noAttendenceRecore = true;

                            (Object.keys(this.getAttendenceReport)).forEach((className) => {

                                (Object.keys(this.getAttendenceReport[className][this.attendenceDate])).forEach((date: any) => {

                                    if (this.getAttendenceReport[className][this.attendenceDate][date].in != null) {
                                        this.inCount++;
                                    } else {
                                        this.outCount++;
                                    }

                                    this.totCount++;
                                })
                            })
                            this.isLoading = false;
                        } else {
                            this.isLoading = false;
                            this.noAttendenceRecore = false;
                        }
                        this.enableDisableRFIDAttendanceSubmitBtn = false;//added by krish
                    } else {
                        this.enableDisableRFIDAttendanceSubmitBtn = false;//added by krish
                        this.isLoading = false;
                        this.noAttendenceRecore = false;

                    }

                }).catch(error => { });
                this.initializeReportContent(sectionContentId);
            }


        }

    }


    attendenceRouteObjectForm(rountList: any, selectedClass: string, swifedList: any, startDate: string) {

        let overlReport: any = [];
        for (let k = 0; k < rountList.length; k++) {

            Object.keys(rountList[k]).forEach(function (key) {

                let rmemberId = rountList[k][key].memberId;
                let rmemberClass = rountList[k][key].memberClass;
                let rmemberSection = rountList[k][key].memberSection;
                let rmemberName = rountList[k][key].memberName;
                let rmemberuid = rountList[k][key].memberUID;

                let r = Object.keys(swifedList['MemberInfo'][startDate]).filter(swifed => {

                    if (rmemberId == swifed) {
                        return swifed;
                    }

                });

                if (overlReport[rmemberClass] == undefined) {
                    overlReport[rmemberClass] = {};
                }
                if (overlReport[rmemberClass][startDate] == undefined) {
                    overlReport[rmemberClass][startDate] = {};
                }
                if (overlReport[rmemberClass]['studentINFO'] == undefined) {
                    overlReport[rmemberClass]['studentINFO'] = {};
                }



                if (r == rmemberId) {

                    const responseDate = swifedList['MemberInfo'][startDate][rmemberId].rfidSwipTime.split('-');
                    const changeDateformate = responseDate[1] + "/" + responseDate[0] + '/' + responseDate[2];
                    const timeStamp = new Date(changeDateformate);


                    if (selectedClass) {

                        if (selectedClass == rmemberClass) {

                            overlReport[rmemberClass][startDate][rmemberId] = {
                                'in': timeStamp,
                                'vehicleName': swifedList['MemberInfo'][startDate][rmemberId].vehicleName
                            }
                            overlReport[rmemberClass]['studentINFO'][rmemberId] = {
                                'memberName': rmemberName,
                                'memberClass': rmemberClass,
                                'memberSection': rmemberSection,
                                'memberUID': rmemberuid
                            }

                        }

                    } else {
                        overlReport[rmemberClass][startDate][rmemberId] = {
                            'in': timeStamp,
                            'vehicleName': swifedList['MemberInfo'][startDate][rmemberId].vehicleName
                        }
                        overlReport[rmemberClass]['studentINFO'][rmemberId] = {
                            'memberName': rmemberName,
                            'memberClass': rmemberClass,
                            'memberSection': rmemberSection,
                            'memberUID': rmemberuid
                        }
                    }


                } else {

                    if (selectedClass) {

                        if (selectedClass == rmemberClass) {

                            overlReport[rmemberClass][startDate][rmemberId] = {
                                'in': '',
                                'vehicleName': ''
                            }
                            overlReport[rmemberClass]['studentINFO'][rmemberId] = {
                                'memberName': rmemberName,
                                'memberClass': rmemberClass,
                                'memberSection': rmemberSection,
                                'memberUID': rmemberuid
                            }
                        }
                    } else {
                        overlReport[rmemberClass][startDate][rmemberId] = {
                            'in': '',
                            'vehicleName': ''
                        }
                        overlReport[rmemberClass]['studentINFO'][rmemberId] = {
                            'memberName': rmemberName,
                            'memberClass': rmemberClass,
                            'memberSection': rmemberSection,
                            'memberUID': rmemberuid
                        }
                    }

                }


            });
        }

        this.getAttendenceReport = overlReport;


        if (this.getAttendenceReport) {

            this.noAttendenceRecore = true;




            (Object.keys(this.getAttendenceReport)).forEach((className) => {

                (Object.keys(this.getAttendenceReport[className][this.attendenceDate])).forEach((date: any) => {

                    if (this.getAttendenceReport[className][this.attendenceDate][date].in != null && this.getAttendenceReport[className][this.attendenceDate][date].in != '') {
                        this.inCount++;
                    } else {
                        this.outCount++;
                    }

                    if (this.incVal[date] == undefined) {
                        this.incVal[date] = [];
                    }
                    this.incVal[date] = this.incVal[date] + 1;
                    this.totCount++;

                })
            })

            this.isLoading = false;

        } else {
            this.isLoading = false;
            this.noAttendenceRecore = false;
        }
        this.isLoading = false;

    }




    // SMS LOG 
    validateSMSLog() {

        let isValid = true;

        let rfidInfoStartDate = $('#routeHistoryStartDate').val();
        let StartDate = this.bootstrapDateTime.getInputvalue('eventStartTime');
        let Enddate = this.bootstrapDateTime.getInputvalue('eventEndTime');
        let dateArr = rfidInfoStartDate.split('-');
        let stTimeArr = StartDate.split(':');
        let EndTimeArr = Enddate.split(':');


        let StTimeStamp = new Date(+dateArr[2], +dateArr[1], +dateArr[0], +stTimeArr[0], +stTimeArr[1], 0, 0).getTime();
        let EndTimeStamp = new Date(+dateArr[2], +dateArr[1], +dateArr[0], +EndTimeArr[0], +EndTimeArr[1], 0, 0).getTime();

        let stTimeString = StTimeStamp.toString();
        let endTimeString = EndTimeStamp.toString();


        if (!rfidInfoStartDate) {
            this.rfidInfoStartDateError = true;
            isValid = false;
        } else {
            this.rfidInfoStartDateError = false;
        }

        if (!StartDate) {
            this.fromStartTimeError = true;
            isValid = false;
        } else {
            this.fromStartTimeError = false;
        }

        if (!Enddate) {
            this.fromEndTimeError = true;
            isValid = false;
        } else {
            this.fromEndTimeError = false;
        }

        if (StTimeStamp > EndTimeStamp) {
            this.dateErr = true;
            isValid = false;


        } else {
            this.dateErr = false;
        }

        if (!this.selectedSMSType) {
            this.routeTypeErr = true;
            isValid = false;
        } else {
            this.routeTypeErr = false;
        }

        return isValid;

    }

    viewsmsLogRfidLog(smsLog: any, isFirstAPIcall: boolean): void {

        if (this.validateSMSLog() && this.bootstrapDateTime.getInputvalue('routeHistoryStartDate') != undefined) {
            this.enableDisableRFIDSMSSubmitBtn = true;// added by krish
            let filter: any = {};
            let form: any = {};
            let rType: string;
            let wType: string;

            if (isFirstAPIcall) {

                this.pageJump = 0;
                this.smsRFIDResponse = [];
                this.isLoading = true; //show div loader in first API call.
                this.showTableRowLoader = false; //hide table rows loader.

            } else {
                this.showTableRowLoader = true;


            }

            let rfidSMSDate = $('#routeHistoryStartDate').val().trim();
            // let startDateService:any,endDateService:any;

            rfidSMSDate = this.dateChange.dateConversionForApicalls(rfidSMSDate, 'onlydate');


            let StartTime = this.bootstrapDateTime.getInputvalue('eventStartTime');
            let EndTime = this.bootstrapDateTime.getInputvalue('eventEndTime');


            form.fromDate = rfidSMSDate;
            form.fromTime = StartTime + ':00';
            form.toDate = rfidSMSDate;
            form.toTime = EndTime + ':00';

            filter.memberclass = this.selectedClass ? this.selectedClass : '';
            filter.memberId = this.userSelectedOptions && this.userSelectedOptions[0] ? this.userSelectedOptions[0].memberId : '';
            filter.memberuid = this.studId ? this.studId : '';
            filter.mobileNo = this.studMobile ? this.studMobile : '';

            let mID = this.userSelectedOptions && this.userSelectedOptions[0] ? this.userSelectedOptions[0].memberId : '';

            if (this.selectedSMSType == 'walkerIn') {
                form.walkerType = 'in';
                form.routeType = 'walker';
                rType = 'walker';
                wType = 'in';

            } else if (this.selectedSMSType == 'walkerOut') {
                form.walkerType = 'out';
                form.routeType = 'walker';
                rType = 'walker';
                wType = 'out';
            } else {
                form.routeType = this.selectedSMSType;
                rType = this.selectedSMSType;
            }

            let apiObj = {
                data: {
                    key: localStorage.getItem('scbToken'),
                    form,
                    filter,
                    extra: {
                        pageJump: this.pageJump
                    }

                }
            }

            this.apiService.rfidSMSLogReports(apiObj).then((responce) => {
                if (responce.status == 'success') {
                    this.enableDisableRFIDSMSSubmitBtn = false;// added by krish
                    this.rfidSMScheckdwnld = responce.respose == null ? false : true;

                } else {
                    this.isLoading = false;
                }
                if (responce.status == 'success') {
                    if (responce.response == null || responce.response == undefined || responce.response == '') {
                        this.smsResponseNull = true;
                        this.noMoredata = true;
                        this.showTableRowLoader = false;
                    } else {

                        if (!this.smsRFIDResponse.length) {
                            this.smsRFIDResponse = responce.response;

                        } else {
                            this.smsRFIDResponse = [...this.smsRFIDResponse, ...responce.response];
                        }

                        this.vehicleOverspeedDownloadLink = `${this.apiService.base}report/download_rfidsms_reports&key=${localStorage.getItem('scbToken')}&fromDate=${rfidSMSDate}&fromTime=${StartTime + ':00'}&toDate=${rfidSMSDate}&toTime=${EndTime + ':00'}&memberclass=${this.selectedClass ? this.selectedClass : ''}&routeType=${rType ? rType : ''}&walkerType=${wType ? wType : ''}&memberId=${mID}&memberuid=${this.studId ? this.studId : ''}&mobileNo=${this.studMobile ? this.studMobile : ''}`;

                        this.smsTotCount = responce.counts.total;
                        this.primaryCount = responce.counts.primary;
                        this.secondryCount = responce.counts.secondary;

                        this.smsResponseNull = false;
                        this.noMoredata = false;
                        this.showTableRowLoader = false;
                    }
                    this.enableDisableRFIDSMSSubmitBtn = false;// added by krish
                } else { this.isLoading = false; }

            });

            this.initializeReportContent(smsLog);

            this.isLoading = false;

        }

    }




    /// Attendance report end 

    setUserSelections(userSelections: any) {
        this.userSelectedOptions = userSelections;
    }
    setUserSelectionName(userSelections: any) {
        this.selectedUserName = userSelections;
        console.log('this.selectedUserName', this.selectedUserName);
    }

    initJqUtils(eleChosenId?: string, eleReportStartTime?: string, eleReportEndTime?: string, pickerConfig?: any) {
        let parent = this;
        let timePicker: boolean;
        let timeFormat: any;
        if ((pickerConfig) && (Object.keys(pickerConfig).length)) {
            timePicker = pickerConfig.timePicker;
            timeFormat = pickerConfig.timeFormat;
        } else {
            timePicker = true;
            timeFormat = 'Y-m-d H:i:s';
        }
        setTimeout(() => {
            if (eleChosenId) this.chosenUtility.initializeChosen(eleChosenId);
            if (eleReportStartTime) {
                parent.bootstrapDateTime.datetime({
                    component: eleReportStartTime,
                    timepicker: timePicker,
                    format: timeFormat,
                    closeOnDateSelect: false
                });
            }
            if (eleReportEndTime) {
                parent.bootstrapDateTime.datetime({
                    component: eleReportEndTime,
                    timepicker: timePicker,
                    format: timeFormat,
                    closeOnDateSelect: false
                });
            }
        }, 150);
    }

    attendenceinitJqUtils(eleChosenId?: string, eleReportStartTime?: string, pickerConfig?: any) {
        let parent = this;
        let timePicker: boolean;
        let timeFormat: any;
        if ((pickerConfig) && (Object.keys(pickerConfig).length)) {
            timePicker = pickerConfig.timePicker;
            timeFormat = pickerConfig.timeFormat;
        } else {
            timePicker = true;
            timeFormat = 'd-m-Y';
        }
        setTimeout(() => {
            if (eleChosenId) this.chosenUtility.initializeChosen(eleChosenId);
            if (eleReportStartTime) {
                parent.bootstrapDateTime.datetime({
                    component: eleReportStartTime,
                    closeOnDateSelect: true
                });
            }
            $('#eventStartTime').datetimepicker({
                format: 'H:i',
                datepicker: false,
                defaultTime: '00:00',
                timepicker: true,
            });
            $('#eventEndTime').datetimepicker({
                format: 'H:i',
                datepicker: false,
                defaultTime: '23:59',
                timepicker: true,
            });
        }, 150);
    }

    //Jquery util for change event start.
    bindJqChangeEvt(ele: any) {
        let parent = this;
        $('#' + ele).change(function (e: any) {
            parent.selectedReport = $(this).val();
            let obj = {};
            obj[parent.selectedReport] = true;
            parent.initReportSection(obj);


        });
    }
    onGeneralRfidPanelScroll($event: any) {
        if (($event.target.scrollTop + $event.target.offsetHeight + 1) >= $event.target.scrollHeight) {

            if (this.noMoredata == false) {

                this.generalRfidPageJump++;
                /// this.isScrolled = true;
                this.viewGeneralRfidLog(undefined, false);
            }
        }
    }

    timeConverter(unix_timestamp: number): string {
        let date = new Date(unix_timestamp);
        let hours = date.getHours();
        let minutes = "0" + date.getMinutes();
        let seconds = "0" + date.getSeconds();
        let formattedTime = hours + ':' + minutes.substr(-2) + ':' + seconds.substr(-2);
        return formattedTime;
    }
    timeConverterwithoutSec(unix_timestamp: number): string {
        let date = new Date(unix_timestamp);
        let hours = date.getHours();
        let minutes = "0" + date.getMinutes();
        // let seconds = "0" + date.getSeconds();
        let formattedTime = hours + ':' + minutes.substr(-2);
        return formattedTime;
    }


    filterAttendence(event: any) {


        this.addType = event;
        this.incDiv = false;
        setTimeout(() => {
            this.incDiv = true;
        }, 100);



    }

    onScroll($event: any) {

        if (((($event.target.scrollTop + $event.target.offsetHeight)) >= ($event.target.scrollHeight)) && (!this.showTableRowLoader)) {

            if (this.noMoredata == false) {
                this.pageJump++;
                this.isScrolled = true;
                this.viewsmsLogRfidLog('RFIDWithSmsLog', false);
            }
        }
    }
    validateRFIDStatus() {
        let isValid = true;

        let startDate = $('#routeHistoryStartDate').val();

        if (!startDate) {
            isValid = false;
            this.rfidInfoStartDateError = true;
        } else {
            this.rfidInfoStartDateError = false;
        }

        if (!this.selectedDaySection) {
            this.rfidSectionErr = true;
            isValid = false;
        } else {
            this.rfidSectionErr = false;
        }


        return isValid;
    }

    public rfidStatusReports: any;
    public rfidRouteDate: any;
    public currentDate = new Date().toISOString().slice(0, 10).split('-').reverse().join('-');


    public routeNameNull: any;
    viewRFIDStatus(str: any) {
        if (this.validateRFIDStatus()) {
            // this.routeNameNull = 1;

            this.enableDisableRFIDStatusCheckSubmitBtn = true;// added by krish
            this.enableDisableRFIDRouteWiseCntSubmitBtn = true;

            let startDate = this.bootstrapDateTime.getInputvalue('routeHistoryStartDate').trim();
            startDate = this.dateChange.dateConversionForApicalls(startDate, 'onlydate')

            this.com.comAction.next({
                type: 'rfidstatuscheck',
                sdate: startDate,
                cdate: this.currentDate,
                session: this.selectedDaySection,
                route: this.selectedRoute,
                routeType: this.selectRouteType,
                routeID: this.selectedRouteID,
                vehInfo: this.vehInfo,
                walkerVehId: this.walkerVehId

            });

            // let sTime;
            // let eTime;
            // let selectedRoute: any = [];
            // let routeInfo = this.storage.get('AllRouteInfo');
            // let selectedVehInfo = [];

            // let routeData: any = {};

            // (Object.keys(routeInfo)).forEach((routeId) => {

            //     if (routeData[routeInfo[routeId].vehId] === undefined) {
            //         routeData[routeInfo[routeId].vehId] = [];
            //     }

            //     if (this.selectedDaySection == 'fn' && routeInfo[routeId].tags[0] == 'Morning') {
            //         sTime = '06:00:00';
            //         eTime = '11:00:00';

            //         selectedRoute.push(routeInfo[routeId].vehId);
            //         routeData[routeInfo[routeId].vehId].push({
            //             routeName: routeInfo[routeId].routeName,
            //             vehNo: routeInfo[routeId].vehNo
            //         });


            //     } else if (this.selectedDaySection == 'an' && routeInfo[routeId].tags[0] == 'Afternoon') {

            //         sTime = '11:00:01';
            //         eTime = '14:00:00';
            //         selectedRoute.push(routeInfo[routeId].vehId);
            //         routeData[routeInfo[routeId].vehId].push({
            //             routeName: routeInfo[routeId].routeName,
            //             vehNo: routeInfo[routeId].vehNo
            //         });

            //     } else if (this.selectedDaySection == 'en' && routeInfo[routeId].tags[0] == 'Evening') {

            //         sTime = '14:00:01';
            //         eTime = '21:00:00';
            //         selectedRoute.push(routeInfo[routeId].vehId);
            //         routeData[routeInfo[routeId].vehId].push({
            //             routeName: routeInfo[routeId].routeName,
            //             vehNo: routeInfo[routeId].vehNo
            //         });
            //     }
            // });

            // this.rfidRouteDate = routeData;

            // let vehIds: any = [];

            // if (this.selectedRoute || this.selectRouteType) {
            //     if (this.selectRouteType === 'Walkers_M' || this.selectRouteType === 'Walkers_N' || this.selectRouteType === 'Walkers_A') {
            //         //  vehIds.push(this.selectedRoute);
            //         this.walkerVehId.concat(vehIds);
            //         selectedVehInfo = this.walkerVehId;
            //     } else {
            //         vehIds.push(this.selectedRoute);
            //         selectedVehInfo = vehIds;
            //     }



            // } else {
            //     vehIds = selectedRoute;
            //     selectedVehInfo = this.walkerVehId.concat(vehIds);
            // }


            // if (startDate == this.currentDate) {
            //     this.routeNameNull = true;
            // } else {
            //     this.routeNameNull = false;
            // }



            // let apiData = {
            //     data: {
            //         key: localStorage.getItem('scbToken'),
            //         form: {
            //             fromDate: startDate,
            //             fromTime: sTime,
            //             toDate: startDate,
            //             toTime: eTime,
            //             vehList: selectedVehInfo
            //         }
            //     }

            // }
            // this.apiService.rfidStatusCheck(apiData).then((status) => {

            //     if (status.status === 'success') {

            //         this.rfidStatusReports = status.response;
            //         this.isLoading = false;
            //     }

            // });
            this.initializeReportContent(str);

        }

    }
    //srini val
    ValidateRfidAppPush() {
        let isValid = true;
        let rfidAppPushenddate = $('#routeHistoryEndDate').val();
        let rfidAppPushstdate = $('#routeHistoryStartDate').val();

        let dateObj: any = this.genericDateValidate(rfidAppPushstdate, rfidAppPushenddate);

        if (dateObj.endTimeStampString.length <= 10) {
            this.rfidInfoEndDateError = true;
            isValid = false;
        } else {
            this.rfidInfoEndDateError = false;
        }

        if (dateObj.stTimeStampString.length <= 10) {
            // if (!rfidMemberInfoStartDate) {
            this.rfidInfoStartDateError = true;
            isValid = false;
        } else {
            this.rfidInfoStartDateError = false;
        }

        if (dateObj.stTimeStampString && dateObj.endTimeStampString) {
            // if ($('#routeHistoryStartDate').val() > $('#routeHistoryEndDate').val()) {
            if (dateObj.stTimeStamp > dateObj.endTimeStamp) {
                this.routesDatesMisMatchError = true;
                isValid = false;
            } else {
                this.routesDatesMisMatchError = false;
            }
        }

        return {
            valid: isValid,
            date: dateObj
        };
    }


    ViewrfidappPush(str: string) {

        let validatedRfid: any = {};
        validatedRfid = JSON.parse(JSON.stringify(this.ValidateRfidAppPush()));

        if (validatedRfid.valid) {

            let stdate = new DatePipe('en').transform(validatedRfid.date.stTimeStamp, 'yyyy-MM-dd');
            let endDate = new DatePipe('en').transform(validatedRfid.date.endTimeStamp, 'yyyy-MM-dd');
            let stTime = new DatePipe('en').transform(validatedRfid.date.stTimeStamp, 'HH:mm:ss')
            let endTime = new DatePipe('en').transform(validatedRfid.date.endTimeStamp, 'HH:mm:ss');

            // this.rfid_App_push_ngModel.memberId = ( this.userSelectedOptions.length && this.userSelectedOptions[0]['memberId'] ) || false;

            this.rfid_App_push_ngModel.memberId = this.userSelectedOptions.length &&
                this.userSelectedOptions[0]['memberId'];



            let apiHeader = {
                data: {
                    key: localStorage.getItem('scbToken'),
                    form: {
                        fromDate: stdate,
                        toDate: endDate,
                        fromTime: stTime,
                        toTime: endTime,
                    },
                    filter: {
                        memberclass: this.rfid_App_push_ngModel.class ? this.rfid_App_push_ngModel.class : false,
                        membersection: this.rfid_App_push_ngModel.section ? this.rfid_App_push_ngModel.section : false,
                        memberId: this.rfid_App_push_ngModel.memberId ? this.rfid_App_push_ngModel.memberId : false,
                        routeType: this.rfid_App_push_ngModel.routetype ? this.rfid_App_push_ngModel.routetype : false,

                    }
                }
            }

            setTimeout(() => {
                this.rfidAppPush = apiHeader

            }, 300);


            this.initializeReportContent(str);
        }



    }

    getSelectedRouteList(section: string) {
        this.routeSection = section;
    }

    dateconvertor(param1: string, param2: string) {
        let data = new Date(param1).toISOString().split('T')[0];
        data = data.split('-').reverse().join('-')
        return data + ' ' + param2;
    }

    genericDateValidate(param1: string, param2: string) {
        let stTimeStamp = 0; let endTimeStamp = 0;
        let stDate: any = (param1).split('-');
        stDate = `${stDate[1]}/${stDate[0]}/${stDate[2]}`;
        stTimeStamp = new Date(stDate).getTime();


        let endDate: any = (param2).split('-');
        endDate = `${endDate[1]}/${endDate[0]}/${endDate[2]}`;
        endTimeStamp = new Date(endDate).getTime();

        let stTimeStampString = stTimeStamp.toString();
        let endTimeStampString = endTimeStamp.toString();


        return {
            stTimeStamp: stTimeStamp,
            endTimeStamp: endTimeStamp,
            stTimeStampString: stTimeStampString,
            endTimeStampString: endTimeStampString,

        }
    }
    resetTemplateError() {
        this.routesDatesMisMatchError = false;
        this.rfidInfoStartDateError = false;
        this.rfidInfoEndDateError = false;
        this.chooseRouteError = false;
        this.chooseMemberError = false;
        this.displayErrorMessage = '';

    }


}