/**
 * @Author: Deepak Pookkote <Deepak>
 * @Date:   2018-04-03T15:32:27+05:30
 * @Email:  deepak@groupten.com
 * @Project: School-Bus
 * @Filename: orgMobileConfiguration.ts
 * @Last modified by:   group10
 * @Last modified time: 2018-05-15T13:03:48+05:30
 * @Copyright: Group10 Technologies
 */



import {Component} from '@angular/core';
import { ApiService } from './../../services/ApiService';
import { GlobalUtils } from './../../services/global-utils';
import { NG_VALIDATORS,Validator,Validators,AbstractControl,ValidatorFn } from '@angular/forms';

const swal = require('sweetalert');

@Component({
    selector:'org-mobile-configuration',
    styles:[`
                .configurationContainer{
                    padding-top:1.85%
                }
                .panel-title{
                    font-size: 20px;
                    color: #454545;
                }
                .panel-title-filter{
                    font-size: 14px;
                    border: 1px solid lightgray;
                    padding: 4px;
                    border-radius: 4px;
                    cursor: pointer;
                }
                .addMobileBtn{
                    font-size: 1.6em;
                    line-height: 1.6em;
                    background: #2396b9;
                    position: fixed;
                    z-index: 999;
                    right: 20px;
                    bottom: 20px;
                    cursor: pointer;
                }

                .btn-circle.btn-xl {
                    width: 70px;
                    height: 70px;
                    padding: 10px 16px;
                    border-radius: 35px;
                    font-size: 24px;
                    line-height: 1.33;
                }

                .btn-circle {
                    width: 45px;
                    height: 45px;
                    padding: 6px 0px;
                    border-radius: 50%;
                    text-align: center;
                    line-height: 1.42857;
                    background: #2396b9;
                    outline:none !Important;
                }

                .panel-default>.panel-heading {
                    color: #333;
                    background-color: #ffff;
                    border-color: #f0f0f0;
                }
                .panel {
                    box-shadow: 1px 2px 4px 0px lightgrey;
                }
                .panel-default {
                    border-color: #fdfafa;
                }

        `],
    template:`
    <section class="mobile-configuration-section" *ngIf="!showMobileAddSection">
        <div class="container configurationContainer" >
            <div class="panel panel-default">
              <div class="panel-heading">
                    <div class="panel-title" style="display:inline">
                        <span>
                          Mobile Configurations
                        </span>
                     </div>
                     <div class="panel-title-filter pull-right meager-e2e-mobile-configure-apply-filter-btn" (click)="showFilterOptions = !showFilterOptions">
                         <span>
                             <i aria-hidden="true" class="fa fa-filter mr5 meager-e2e-mobile-configure-apply-filter-btn" style="color:orange;"></i>
                             Apply Filters
                         </span>
                     </div>
              </div>
              <div class="panel-body">
                <div class="row filterOptions mb5 animate fade-in" *ngIf="showFilterOptions">
                    <div class="col-md-12 col-sm-12 col-xs-12">
                        <div class="col-md-4 col-sm-4 col-xs-4 form-group">
                            <label class="control-label">Name</label>
                            <input class="custom-form-control meager-e2e-mobile-configure-filter-name" type="search"
                            (change)="newUserName=$event.target.value;filterFunction();">
                        </div>
                        <div class="col-md-4 col-sm-4 col-xs-4 form-group">

                            <label class="control-label">Mobile No</label>
                            <input class="custom-form-control meager-e2e-mobile-configure-filter-mobileno" type="search"
                            (change)="newMobileNumber=$event.target.value;filterFunction();">
                        </div>
                        <!--<div class="col-md-4 col-sm-4 col-xs-4 form-group">
                            <label class="control-label"><i aria-hidden="true" class="fa fa-search mr5"></i>User Reg.:</label>
                            <input class="custom-form-control" type="search">
                        </div>-->
                    </div>
                </div>
                <div class="orgMobileTableContainer table-responsive" *ngIf="availableOrgMobiles?.length > 0;else falsytemplate">
                    <table class="table table-stripped table-bordered">
                        <thead>
                            <tr>
                                <td class="text-center">#</td>
                                <td class="text-center">Name</td>
                                <td class="text-center">Mobile Number</td>
                                <td class="text-center">Actions</td>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let info of availableOrgMobiles ;let i=index">
                                <td class="text-center">{{i+1}}</td>
                                <td class="text-center">{{info.UserName}}</td>
                                <td class="text-center">{{info.UserMobileNo}}</td>
                                <td class="text-center text-capitalize">
                                      <!--<span  class="action-items " (click)="editOrgMobileInfo(info.userRegMobileId)"><i aria-hidden="true" class="fa fa-pencil grow edit" style="margin-left:10px;margin-right:10px;"></i></span>-->
                                      <span  class="action-items meager-e2e-mobile-configure-delete-btn-{{i}} " (click)="deleteRegisteredMobileInfo(info.userRegMobileId)"><i aria-hidden="true" class="fa fa-trash grow delete meager-e2e-mobile-configure-delete-btn-{{i}}" title="Delete"></i></span>
                                </td>
                            <tr>
                        </tbody>
                    </table>
                </div>
                <ng-template #falsytemplate>
                 <h4 class="animated pulse text-center"  style="animation-duration: 1s;color:#999FBF"><i class="fa fa-frown-o"></i>&nbsp;No mobile numbers to display</h4>
                </ng-template>
                <!--<div class="noDataAlert" *ngIf="availableOrgMobiles?.length == 0">
                    <h4 class="animated pulse text-center" style="animation-duration: 1s;color:#999FBF" ><i class="fa fa-frown-o"></i>&nbsp;No records to Display</h4>
                </div>-->
              </div>
            </div>
        </div>
        <button title="Add new Configuration" type="button" class="btn btn-info btn-circle btn-lg pull-right addMobileBtn" (click)="initAddMobileSection()"><i class="fa fa-plus"></i>
        </button>
    </section>
    <section class="add-new-mobile-section" *ngIf="showMobileAddSection">
        <div class="container configurationContainer" >
            <div class="panel panel-default">
                <div class="panel-heading">
                  <h2 class="panel-title">Mobile Configuration</h2>
                  <p  class="small">
                    Add new mobile below
                  </p>
                </div>
                <div class="panel-body">
                  <form  #loginForm="ngForm">
                    <div class="row" *ngIf="!showOTPValidationFields">
                        <div class="form-group col-md-6">
                            <label class="control-label">User Name<sup>*</sup></label>
                            <input class="custom-form-control meager-e2e-mobile-configure-add-user-name" type="text" [(ngModel)]="newUserName" name="mobuseName" appAutofocus>
                            <span class="text-danger" *ngIf="userNameError">Enter Name</span>
                        </div>
                        <div class="form-group col-md-6">
                            <label class="control-label">Mobile Number<sup>*</sup></label>
                            <input class="custom-form-control meager-e2e-mobile-configure-add-mobileno" type="text" name="mobilenum"
                            pattern="[0-9]*"
                            minlength="10"
                            maxlength="10"
                            #mobilenum="ngModel"
                            (keyup)="MobileNumberValidator($event)"
                            [(ngModel)]="newMobileNumber" required>
                            <span class="text-danger" *ngIf="mobileNumberError">Enter Mobile Number</span>
                            <div [hidden]="mobilenum.valid || mobilenum.pristine" class="text-danger">
                              <div [hidden]="!mobilenum.hasError('minlength')">Mobile should be 10 digit</div>
                              <!--<div [hidden]="!mobilenum.hasError('required')">at least one Mobile is required</div>-->
                              <div [hidden]="!mobilenum.hasError('pattern')">Mobile number should be only numbers</div>
                            </div>
                        </div>
                    </div>
                    <div class="row validateOtpFields" *ngIf= "showOTPValidationFields">
                        <div class="col-md-6">
                            <label class="control-label">Enter OTP Recived in your phone <sup>*</sup></label>
                            <input class="custom-form-control" type="text" [(ngModel)]="userReceivedPassword" name="otprecived">
                            <span class="text-danger" *ngIf="otpError">Enter Otp to verify the mobile number</span>
                        </div>
                    </div>
                    <div class="row form-actions" *ngIf= "showOTPValidationFields">
                        <button  class="webform-submit button-primary btn btn-primary form-submit pull-right" style="color:#fff !important;margin-right:15px;" (click)="verifyUserReceivedOTP()">Validate OTP</button>
                        <button  class="webform-submit button-primary btn btn-primary form-submit pull-right mr5" style="color:#fff !important;margin-right:15px;" (click)='hideAddMobileSection()'>CANCEL</button>
                    </div>
                    <div class="row form-actions" *ngIf="!showOTPValidationFields">
                        <button  [disabled]="!loginForm.form.valid" class="webform-submit button-primary btn btn-primary form-submit pull-right meager-e2e-mobile-configure-save-btn" style="color:#fff !important;margin-right:15px;" (click)="generateOtp()">SAVE</button>
                        <button  class="webform-submit button-primary btn btn-primary form-submit pull-right mr5 meager-e2e-mobile-configure-save-btn" style="color:#fff !important;margin-right:15px;" (click)='hideAddMobileSection()'>CANCEL</button>
                    </div>
                  </form>
                </div>
            </div>
        </div>
    </section>
    `
})

export class orgMobileConfig {
    private availableOrgMobiles:any[];
    public showFilterOptions:boolean;
    public showMobileAddSection:boolean;
    private newMobileNumber:string;
    private newUserName:string;
    private showOTPValidationFields:boolean;
    private userReceivedPassword:any;
    private apiGeneratedOTPToken:any;
    private mobileNumberError:boolean;
    private userNameError:boolean;
    private otpError:boolean;

    constructor(private apiService:ApiService,private globalUtils:GlobalUtils){
        this.availableOrgMobiles = [];
        this.showFilterOptions = false;
        this.showMobileAddSection = false;
        this.showOTPValidationFields = false;
    }

    ngOnInit(){
         this.getOrgMobiles();
    }

    filterFunction(){
       this.getOrgMobiles();
       this.newMobileNumber="";
       this.newUserName="";
    }

    getOrgMobiles(){
        let apiHeader = {
            data : {
                key:localStorage.getItem('scbToken'),
                filter: {
                  username:this.newUserName,
                  mobileno:this.newMobileNumber
                },
                extra: {
                  orderByDateCreated: -1
                }
            }
        }
        this.availableOrgMobiles = [];
        this.apiService.getOrgMobiles(apiHeader).then(response => {
            this.availableOrgMobiles = response.response;
        }).catch(error => {})
    }

    initAddMobileSection(){
        this.showMobileAddSection = true;
        this.showOTPValidationFields = false;
        this.userReceivedPassword = null;
        this.apiGeneratedOTPToken = null;
    }

    hideAddMobileSection(){
        this.showMobileAddSection = false;
    }

    //Validate addNotificationSetting

    validateUserInputs() {
      let isValid: boolean = true;
      if (!this.newMobileNumber) {
        this.mobileNumberError = true;
        isValid = false;
      } else {
        this.mobileNumberError = false;
      }

      if (!this.newUserName) {
        this.userNameError = true;
        isValid = false;
      } else {
        this.userNameError = false;
      }

      return isValid;
    }

    MobileNumberValidator(event: any) {
      event.srcElement.value = event.srcElement.value.replace(/[^\d].+/, "");

      if (event.srcElement.value != '') {
        //  console.log('t',event.srcElement.value);
        if ((event.which < 48 || event.which > 57)) {
          event.preventDefault();
        }
      } else {
        // event.srcElement.value=0;
      }
    }


    generateOtp(){
      if(this.validateUserInputs()){
        let apiHeader = {
            data:{
                key:localStorage.getItem('scbToken'),
                form:{
                    mobileno: this.newMobileNumber,
                    username:this.newUserName
                }
            }
        }
        this.apiService.validateMobileNumber(apiHeader).then(response => {
             if(response.status=="success"){
                // swal("Success", "Mobile number Added Successfully", "success").done();
                  this.showOTPValidationFields = true;
             }else{
              swal("Failure",this.globalUtils.getErrorMsg(response.ec), "error");
             }
             if(response.otptoken){
                 this.apiGeneratedOTPToken = response.otptoken;
             }
        }).catch(error => {});
      }
    }

    initiateOtpValidator(){
      let isValid: boolean = true;
      if (!this.userReceivedPassword) {
        this.otpError = true;
        isValid = false;
      } else {
        this.otpError = false;
      }

      return isValid;
    }

    verifyUserReceivedOTP(){
      if(this.initiateOtpValidator()){
        let apiHeader = {
            data:{
                key:localStorage.getItem('scbToken'),
                form: {
                    otptoken: this.apiGeneratedOTPToken,
                    otp: this.userReceivedPassword
                }
            }
        }
        this.apiService.verifyuserEnteredOtp(apiHeader).then(response => {
            if(response.status == 'success'){
              swal("Success", "OTP Verified Successfully", "success");
                this.hideAddMobileSection();
                this.getOrgMobiles();
            }else{
              swal("Failure",this.globalUtils.getErrorMsg(response.ec), "error");
            }
        }).catch(error => {});
      }

    }

    editOrgMobileInfo(mobileId:any){
        let apiHeader = {
            data:{
                key:localStorage.getItem('scbToken')
            },
        }
    }

//     deleteRegisteredMobileInfo(mobileId:any){
//         let apiHeader = {
//             data:{
//                 key:localStorage.getItem('scbToken'),
//                 form:{
//                     userRegMobileId:mobileId
//                 }
//             }
//         };
//         swal({
//           title: 'Are you sure?',
//           text: "The Selected Mobile number Will be removed",
//           type: 'warning',
//           timer: 10000,
//           reverseButtons:true,
//           showCancelButton: true,
//           confirmButtonColor: '#3085d6',
//           cancelButtonColor: '#e04d76',
//           confirmButtonText: 'Yes, Remove it!',
//           cancelButtonText: 'No, keep it'
//         }).then(()=>{
//         this.apiService.deleteRegisteredMobile(apiHeader).then(response => {
//           if (response) {
//             swal(
//               'Removed!',
//               'Mobile number has been Removed',
//               'success'
//             ).done();
//             setTimeout( ()=>{ swal.close();}, 1000);
//               this.getOrgMobiles();
//             };
//         }).catch(error => { });
//       },
//       function(dismiss:any) {
//       if (dismiss === 'cancel') {
//         swal(
//           'Cancelled',
//           'Cancelled :)',
//           'error'
//         ).done();
//         setTimeout( ()=>{ swal.close();}, 1000);
//       }
//     });
//   }


  deleteRegisteredMobileInfo(mobileId:any){
    const parent = this ;
    let apiHeader = {
        data:{
            key:localStorage.getItem('scbToken'),
            form:{
                userRegMobileId:mobileId
            }
        }
    };
    swal({
        title: 'Are you sure?',
        text: 'This mobile number will be removed',
        icon: 'warning',
        buttons: [
          'No, cancel it!',
          'Yes, I am sure!'
        ],
        dangerMode: true,
      }).then(function(isConfirm: any) {
        if ( isConfirm ) {
          swal({
            title: 'Success!',
            text: 'Mobile number removed',
            icon: 'success'
          }).then(function() {
            parent.apiService.deleteRegisteredMobile(apiHeader).then(response => {
                if (response) {
                  parent.getOrgMobiles();
                }
                }).catch(error => { });
          });
        } else {
          swal('Cancelled', 'Continue the session :)', 'error');
        }
      });
    }


}
