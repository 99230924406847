import { ClockChartComponent } from './clock-chart/clock-chart.component';
import { ColumnChartComponent } from './column-chart/column-chart.component';
import { DonutChartComponent } from './donut-chart/donut-chart.component';
import { LineChartComponent } from './line-chart/linechart.component';
import { BubbleChartComponent } from "./bubble-chart/bubble-chart.component";
import { PickupPointComponent } from './pickupPointTrend/pickup.point.trend.component';
import { FlatPickerComponent } from './calender-chart/calender.chart.component';
import { LoaderComponent } from './loader/loader.component';
export const chartPageDeclaration: any = [
    ClockChartComponent,ColumnChartComponent,DonutChartComponent,
    LineChartComponent,BubbleChartComponent,PickupPointComponent,
    FlatPickerComponent,LoaderComponent 
]