
/*Auther:Deepak */
import { Component } from '@angular/core';
import {
  ElementRef,
  AfterViewInit,
  OnDestroy,
  ViewChild,
} from '@angular/core';
import { RouterModule, Routes, Router } from '@angular/router';
import { AppComponent } from './../../../app.component';

import { globalService } from './../../../services/global.service';
import { ApiService } from './../../../services/ApiService';
import { authGaurd } from './../../../services/auth-guard.service';
import { GlobalUtils } from './../../../services/global-utils';
import { decodeAddress } from './../../../services/decodeAddress.service';

declare var $: any;
const swal = require('sweetalert');
declare var google: any;
// declare var type:any;

@Component({
  // template: ``,
  templateUrl: './viewSchoolProfile.component.html',

  styles: [`

     .step1  {
      border: 1px solid #fbf5f5;
      /* border-radius: 5px; */
      padding: 10px;
      box-shadow: 1px 1px 3px 1px #f1f0f0;
     }

     .row {
       padding:4px;
     }

     div.section-title{
       font-size:20px;
     }
     div.section-title-border{
       margin-bottom: 20px;
       border-bottom: 1px solid #00BCD4;
       border-bottom-width: medium;
       border-spacing: 1px;
       width:5%;
     }
     .field-icon {
      float: right;
      margin-left: -25px;
      margin-top: -25px;
      margin-right:3px;
      position: relative;
      z-index: 2;
    }
    .modal-header .close {
      margin-top: -25px;
    }



    `],

})
export class ViewSchoolProfilePageComponent {


  public profileInformation: any = {};
  public orgContactInformation: any = [];
  public orgContactInformationDetails: any = [];
  public oldPassowrd: string;
  public newPassword: string;
  public confirmPassword: string;
  public showoldPasswordError: boolean;
  public showNewPasswordError: boolean;
  public showConfirmPasswordError: boolean;
  public passwordChangeSucces: boolean;
  public passwordMismastchError: boolean;
  public oldPasswordMismatchError: string;
  public showLoading: boolean = false;
  public assitanceForm: boolean = true;
  public passType1: string = 'password';
  public passType2: string = 'password';
  public passType3: string = 'password';
  public passClass1: string = 'fa fa-eye-slash field-icon toggle-password';
  public passClass2: string = 'fa fa-eye-slash field-icon toggle-password';
  public passClass3: string = 'fa fa-eye-slash field-icon toggle-password';

  private orgPlaceMarker: any;
  private _map: any;

  constructor(private apiService: ApiService,
    public authGaurd: authGaurd,
    private router: Router,
    private globalUtils: GlobalUtils,
    private decodeAddress: decodeAddress) {

  }

  ngOnInit() {
    this.authGaurd.checkToken();
    this.viewOrgProfile();
    setTimeout(() => { this.initMap() }, 700);

  }


  initMap(): void {
    if (this.profileInformation.orgLocation) {
      let plotLat = parseFloat(this.profileInformation.orgLocation.lattitude);
      let plotLong = parseFloat(this.profileInformation.orgLocation.longitude);
      let mapOptions = {
        scrollwheel: true,
        zoom: 14,
        draggable: true,
        // center: new google.maps.LatLng(28.7041, 77.1025),
        center: new google.maps.LatLng(plotLat, plotLong),
        disableDefaultUI: false //disables default controls
      };
      this._map = new google.maps.Map(document.getElementById('mapContainer'), mapOptions);
      this.orgPlaceMarker = new google.maps.Marker({
        position: new google.maps.LatLng(plotLat, plotLong),
        map: this._map,
        draggable: false,
        animation: google.maps.Animation.DROP,
        icon: {
          url: './.../../assets/webroot/images/map/schoolMarker1.png',
          //size: new google.maps.Size(64, 64),
          scaledSize: new google.maps.Size(45, 45),
        },
      });
      this._map.panTo(new google.maps.LatLng(plotLat, plotLong))
      //add events for marker.
      let parent = this;
      this.orgPlaceMarker.addListener('dragend', function (e: any) {
        parent.reverseGeocodeAddress(new google.maps.LatLng(e.latLng.lat(), e.latLng.lng()))
      });
      this.bindMapClickEvt();
    } else {

      let mapOptions = {
        scrollwheel: true,
        zoom: 14,
        center: new google.maps.LatLng(28.7041, 77.1025),
        disableDefaultUI: true //disables default controls
      };
      this._map = new google.maps.Map(document.getElementById('mapContainer'), mapOptions);
      this.orgPlaceMarker = new google.maps.Marker({
        position: new google.maps.LatLng(28.7041, 77.1025),
        map: this._map,
        draggable: true,
      });
      this._map.panTo(new google.maps.LatLng(28.7041, 77.1025))
      //add events for marker.
      let parent = this;
      this.orgPlaceMarker.addListener('dragend', function (e: any) {
        parent.reverseGeocodeAddress(new google.maps.LatLng(e.latLng.lat(), e.latLng.lng()))
      });
      this.bindMapClickEvt();
    }

  }

  bindMapClickEvt() {
    let parent: any = this;
    this._map.addListener('click', function (e: any) {
      var clickedLatLng = new google.maps.LatLng(e.latLng.lat(), e.latLng.lng());
      parent.orgPlaceMarker.setPosition(clickedLatLng);
      google.maps.event.trigger(parent.orgPlaceMarker, 'dragend', e);
    });
  }

  geocodeAddress() {
    let address = this.profileInformation.dropPointName;
    //geocoding using custom service.
    var config = {
      'address': address
    };
    this.decodeAddress.geocode(config).then(response => {
      this.orgPlaceMarker.setPosition(response['geometry'].location);
      this._map.panTo(response['geometry'].location);

    }).catch(error => { });
  }
  reverseGeocodeAddress($event: any) {
    let geocoder = new google.maps.Geocoder();
    let address = $event;
    let parent: any = this;
    //reverse geocoding using custom service.
    var config = {
      'latLng': $event
    }
    this.decodeAddress.geocode(config).then(response => {
      this.profileInformation.dropPointName = response['formatted_address'];
      $('#locationField').focus();
      $('#locationField').blur();
    }).catch(error => {
      console.log('Error - ', error);
    });
  }


  viewOrgProfile() {

    this.apiService.getCurrentOrgDetails({
      data: {
        key: localStorage.getItem('scbToken'),
        filter: {}
      }
    }).then(response => {
      let result = response;
      if (result.status === 'success') {

        this.profileInformation = result.response;
        if (this.profileInformation.orgLocation) {
          this.profileInformation.dropPointName = this.profileInformation.orgLocation.locationAddress;
        }
        this.orgContactInformation = this.profileInformation.contactInformation[0];


        // this.orgContactInformationDetails=$.map(this.orgContactInformation,function(value:any,index:any){
        //                return[value]});
      }
    }).catch(error => {
      console.log('Error while logging in', error);
    });


  }



  passwordValidator(event: any) {
    event.srcElement.value = event.srcElement.value.replace(/[()\-\[\];':"\\|,+<>\/\s]/gi, '');
    if (event.srcElement.value != '') {
      //  console.log('t',event.srcElement.value);
      if ((event.which < 48 || event.which > 57)) {
        event.preventDefault();
      }
    } else {
      // event.srcElement.value=0;
    }
  }



  editOrgProfile() {
    this.showLoading = true;
    let editObj = {
      data: {
        'key': localStorage.getItem('scbToken'),
        form: {
          name: this.profileInformation.name,
          category: this.profileInformation.category,
          address: this.profileInformation.address,
          area: this.profileInformation.area,
          city: this.profileInformation.city,
          state: this.profileInformation.state,
          country: this.profileInformation.country,
          website: this.profileInformation.website,
          email: this.profileInformation.email,
          description: this.profileInformation.description,
          contactInformation: {
            0: {
              name: this.orgContactInformation.name,
              phone: this.orgContactInformation.phone,
              email: this.orgContactInformation.email,
              designation: this.orgContactInformation.designation
            }
          },
          orgLocation: {
            lattitude: this.orgPlaceMarker.getPosition().lat(),
            longitude: this.orgPlaceMarker.getPosition().lng(),
            locationAddress: this.profileInformation.dropPointName
          },
        }
      }
    };
    this.apiService.editOrgDetails(editObj).then(response => {
      if (response) {
        swal('Success', 'Profile Information Updated', 'success');
        this.showLoading = false;
        setTimeout(() => { swal.close(); }, 2000);
      }
      this.viewOrgProfile();
    }).catch(error => {
      console.log('Error while logging in', error);
    });

  }

  updatePassword() {
    if (this.initiatePasswordValidator()) {
      let passwordObj = {
        data: {
          key: localStorage.getItem('scbToken'),
          form: {
            loginname: localStorage.getItem('orguserName'),
            oldpassword: this.oldPassowrd,
            newpassord: this.newPassword
          }
        }
      }
      this.apiService.updateorgPassword(passwordObj).then(response => {
        if (response.status === 'success') {
          setTimeout(() => {
            this.passwordChangeSucces = true;
          }, 1000);
          this.passwordChangeSucces = false;
          setTimeout(() => {
            $('#basicModal').modal('hide');
            this.logoutOrg();
          }, 3500);
          setTimeout(() => {
            this.logoutOrg();
          }, 4000)
          this.oldPassowrd = '';
          this.newPassword = '';
          this.confirmPassword = '';
          this.oldPasswordMismatchError = '';
        } else {
          this.oldPasswordMismatchError = this.globalUtils.getErrorMsg(response.ec);
          this.passwordChangeSucces = false;
        }
      }).catch(error => {
        console.log('Error while logging in', error);
      });
    }

  }

  initiatePasswordValidator() {
    let isValid: boolean = true;

    if (!this.oldPassowrd) {
      this.showoldPasswordError = true;
      isValid = false;
    } else {
      this.showoldPasswordError = false;
    }

    if (!this.newPassword) {
      this.showNewPasswordError = true;
      isValid = false;
    } else {
      this.showNewPasswordError = false;
    }

    if (!this.confirmPassword) {
      this.showConfirmPasswordError = true;
      isValid = false;
    } else {
      this.showConfirmPasswordError = false;
    }

    if (this.newPassword != this.confirmPassword) {
      this.passwordMismastchError = true;
      isValid = false;
    } else {
      this.passwordMismastchError = false;
    }

    return isValid;
  }


  toggleIcon(control: any) {
    if (control === 'oldpassword') {
      if (this.passType1 == 'password') {
        this.passType1 = 'text';
        this.passClass1 = 'fa fa-eye field-icon toggle-password';
      } else {
        this.passType1 = 'password';
        this.passClass1 = 'fa fa-eye-slash field-icon toggle-password';
      }
    } else if (control === 'newpassword') {
      if (this.passType2 == 'password') {
        this.passType2 = 'text';
        this.passClass2 = 'fa fa-eye field-icon toggle-password';
      } else {
        this.passType2 = 'password';
        this.passClass2 = 'fa fa-eye-slash field-icon toggle-password';
      }
    } else if (control === 'confirmpassword') {
      if (this.passType3 == 'password') {
        this.passType3 = 'text';
        this.passClass3 = 'fa fa-eye field-icon toggle-password';
      } else {
        this.passType3 = 'password';
        this.passClass3 = 'fa fa-eye-slash field-icon toggle-password';
      }
    }

  }

  // toogleOldpassword(){
  //   let x:any = document.getElementById("oldpassowrd");
  //   if (x.type === "password") {
  //       x.type = "text";
  //   } else {
  //       x.type = "password";
  //   }
  // }

  // toogleNewpassword(){
  //   let x:any = document.getElementById("newpassword");
  //   if (x.type === "password") {
  //       x.type = "text";
  //   } else {
  //       x.type = "password";
  //   }
  // }

  // toogleConfirmpassword(){
  //   let x:any = document.getElementById("confirmpassword");
  //   if (x.type === "password") {
  //       x.type = "text";
  //   } else {
  //       x.type = "password";
  //   }
  // }


  logoutOrg() {
    setTimeout(() => {
      localStorage.setItem("scbToken", '');
      this.router.navigate(['/login']);
    }, 10);
  }


}
