import { Component } from '@angular/core';
import { RouterModule, Routes, Router } from '@angular/router';
import { NgForm } from '@angular/forms';
import { ApiService } from './../../services/ApiService';
import { GlobalUtils } from './../../services/global-utils';
import { NG_VALIDATORS, Validator, Validators, AbstractControl, ValidatorFn } from '@angular/forms';
import { ActivatedRoute, Params, NavigationEnd } from '@angular/router';
import { Subscription } from 'rxjs/Subscription';

declare var swal: any;
declare var $: any;
@Component({

  templateUrl: './resetPassword.component.html',
  styles: [`

     $color1: #f4f4f4;
     $color2: #3197EE;

     .login-parent-div img{
        width: 100%;
        background-color: #f2f6fb;
      }



      .heightInc{
        height: 20vh;
      }

      .text-danger{
        padding: 5px;
        color: #de5350;
      }

     .login-form {
         width: 400px;
         margin: 28% auto 0 auto;
         font-size: 16px;
     }

     .IntroVideoLink{
        color: #8C96A9;
        text-decoration: none;
        outline: none;
    }

     /*css for ripple pin points */

     /*Designate how the animation will take place*/
     @keyframes pulsate {
         0% {
         transform: scale(0.1);
         opacity: 0;
         }
         50% {
         opacity: 1;
         }
         100% {
         transform: scale(1.2);
         opacity: 0;
         }
     }

     /* .wrap is our container div and will vary in name on your site */
     .wrap {
         width: 90%;
         max-width: 672px;
         margin: 0 auto;
     }


     /* Alter the top and left positions to sit on top of your map location */
     .marker {
         width: 100px;
         height: 100px;
         position: absolute;
         top: 130px; /*positions our marker*/
         left: 200px; /*positions our marker*/
         display: block;
     }

     .pin {
         width: 20px;
         height: 20px;
         position: fixed;
         top: 288px;
         left: 486px;
         background: #81D4FA;
         border: 2px solid #FFF;
         border-radius: 50%;
         z-index: 1000;
     }

     .pin-effect {
         width: 100px;
         height: 100px;
         position: fixed;
         top: 248px;
         left:447px;
         display: block;
         background: rgba(3, 169, 244, 0.57);
         border-radius: 50%;
         opacity: 0;
         animation: pulsate 2400ms ease-out infinite;

     }

     /* Adjusts the background image and marker for smaller screens */
     @media only screen and (max-width: 414px) {
         .map-bg {
             background-position: -100px 0px;
         }

         .marker {
             left: 100px;
         }
     }

    .passwordAssistant{
      border: 1px solid lightgray;
      border-radius: 3px;
    }
    .customHeader{
      padding: 20px;
      font-size: 28px;
      font-weight: 400;
      text-align: center;
      text-transform: capitalize;
    }

    .restSubmit {
      box-sizing: border-box;
      display: block;
      width: 100%;
      border-width: 1px;
      border-style: solid;
      padding: 16px;
      outline: 0;
      font-family: inherit;
      font-size: 0.95em;
      background: #28d;
      border-color: transparent;
      color: #fff;
    }

    .errorDiv{
      margin-top: 15px;
      margin-left: 10px;
      border: 1px solid #ebccd1;
      padding: 10px;
      margin-right: 10px;
      background: #f2dede;
      color: black;
      border-radius: 5px;
    }

    .field-icon {
      float: right;
      margin-left: -25px;
      margin-top: -40px;
      margin-right: 8px;
      position: relative;
      z-index: 2;
      cursor: pointer;
      font-size: 19px;
      color: #6d6d6d;
    }

    .restSubmit : hover{
      color:red;
    }

   `],
})
export class PasswordResetPageComponent {


  public newPassword: string = "";
  public confirmPassword: string = "";
  public showNewPasswordError: boolean;
  public showConfirmPasswordError: boolean;
  public passwordMismastchError: boolean;
  public passwordResetSucces: boolean;
  public routeSub: Subscription;
  public generatedToken: any;
  public displayErrorMessage: boolean;



  constructor(private apiService: ApiService,
    private globalUtils: GlobalUtils,
    public router: Router,
    private route: ActivatedRoute) {
  }


  ngOnInit(): void {
    this.routeSub = this.route.params.subscribe((params: Params): void => {
      this.generatedToken = params['token'];
    });

    this.displayErrorMessage = false;

  }


  ngOnDestroy(): void {
    this.routeSub.unsubscribe();
  }


  passwordValidator(event: any) {
    event.srcElement.value = event.srcElement.value.replace(/[()\-\[\];':"\\|,.+<>\/\s]/gi, '');
    if (event.srcElement.value != '') {
      //  console.log('t',event.srcElement.value);
      if ((event.which < 48 || event.which > 57)) {
        event.preventDefault();
      }
    } else {
      // event.srcElement.value=0;
    }
  }





  resetUserPassword() {
    let resetToken: any = this.generatedToken;
    if (this.initiatePasswordValidator()) {
      let passObj = {
        data: {
          form: {
            token: resetToken,
            newPassword: this.newPassword
          }
        }
      };

      this.apiService.resetPassword(passObj).then(response => {
        if (response.status == "success") {
          this.displayErrorMessage = false;
          setTimeout(() => {
            this.passwordResetSucces = true;
          }, 1500);
          this.passwordResetSucces = false;
          setTimeout(() => {
            this.router.navigate(['/login']);
          }, 4000);
        } else {
          if (this.globalUtils.getErrorMsg(response.error.ec)) {
            this.displayErrorMessage = true;
          }
        }
      }).catch(error => {
        console.log('Error while Resetting in', error);
      });
    }

  }


  initiatePasswordValidator() {
    let isValid: boolean = true;
    if (!this.newPassword) {
      this.showNewPasswordError = true;
      isValid = false;
    } else {
      this.showNewPasswordError = false;
    }

    if (!this.confirmPassword) {
      this.showConfirmPasswordError = true;
      isValid = false;
    } else {
      this.showConfirmPasswordError = false;
    }

    if (this.newPassword != this.confirmPassword) {
      this.passwordMismastchError = true;
      isValid = false;
    } else {
      this.passwordMismastchError = false;
    }

    return isValid;
  }


  tooglepassword() {
    $(".toggle-password").click(function () {
      $(this).toggleClass("fa-eye fa-eye-slash");
      let x: any = document.getElementById("newPassword");
      if (x.type === "password") {
        x.type = "text";
      } else {
        x.type = "password";
      }
    });
  }

  toggleConfirmPassowrd() {
    $(".toggle-password1").click(function () {
      $(this).toggleClass("fa-eye fa-eye-slash");

      let y: any = document.getElementById("cpassword");
      if (y.type === "password") {
        y.type = "text";
      } else {
        y.type = "password";
      }
    });
  }









}
