/**
 * @Author: Rakesh Roushan
 * @Date:   2018-04-19T12:40:04+05:30
 * @Email:  rakesh@groupten.com
 * @Project: SChool-Bus
 * @Filename: searchSelectSingleItem.ts
 * @Last modified by:   group10
 * @Last modified time: 2018-05-11T16:53:54+05:30
 * @Copyright: Group10 Technologies
 */

import { Component,EventEmitter,Input,Output,HostListener,ElementRef  } from '@angular/core';
import { ApiService } from './../../services/ApiService';
@Component({
    selector:'single-search-select',
    template:`
        <div class="wrapper" style="width: 100%;position:relative">
            <div class='search-box overlay-history-search mb5'>

            <ng-container *ngIf="_appAutoFocus">
            <input placeholder="Search here" class="custom-form-control custom-font meager-e2e-single-searchselect" type="text" appAutofocus (keyup)="keyUpHandler()" (keydown)="keyDownHandler" [(ngModel)]="userRouteText" style="border:none;box-shadow:none;outline:none">
            </ng-container>

            <ng-container *ngIf="!_appAutoFocus">
            <input placeholder="Search here" class="custom-form-control custom-font meager-e2e-single-searchselect" type="text" (keyup)="keyUpHandler()" (keydown)="keyDownHandler" [(ngModel)]="userRouteText" style="border:none;box-shadow:none;outline:none">
            </ng-container>

                   <!--<input placeholder="Search here" class="custom-form-control custom-font" type="text" (keyup)="keyUpHandler()" (keydown)="keyDownHandler" [(ngModel)]="userRouteText" style="border:none;box-shadow:none;outline:none"> -->
                   <button class='btn btn-link search-btn' (click)="keyUpHandler()">
                   <i class='glyphicon glyphicon-search'></i>
                  </button>
            </div>

            <div class="suggestion-dropdown animated fade-in border-deda" *ngIf="showSuggestionDropdown">
                <ul class="list-group" style="display:inline" *ngIf="noMatchingItemsFound">
                    <li class="list-group-item meager-e2e-single-searchselect-errtxt">No matching items found...</li>
                </ul> 
                <ul class="list-group" style="display:inline" *ngIf="searchSuggestions">
                     
                    <ng-container *ngFor="let route of searchSuggestions">
                            <ng-container *ngIf="searchTypeText === 'onlyRoute'"> 
                                    <li class="list-group-item meager-e2e-single-searchselect-{{route.genericName}}" *ngIf="(route.tags[0]|uppercase) === (selectedSec|uppercase) || (route.tags[1]|uppercase) === (selectedSec|uppercase)" (click)="selectDropdownSuggestions(route)">
                                    
                                            {{route.genericName}}                        
                                    </li>
                                    <li class="list-group-item meager-e2e-single-searchselect-{{route.genericName}}" *ngIf="!selectedSec " (click)="selectDropdownSuggestions(route)">
                                    
                                         {{route.genericName}}                        
                                    </li>
                            </ng-container> 
                            <ng-container *ngIf="searchTypeText !== 'onlyRoute'">                                      
                                <li class="list-group-item meager-e2e-single-searchselect-{{route.genericName}}" (click)="selectDropdownSuggestions(route)">

                                    {{route.genericName}}                        
                                </li>     
                            </ng-container>                
                    </ng-container> 
           
                </ul>
            </div>
        </div>
    `,
    styles:[`
        .border-deda {
          border-radius: 3px;
          border: 1px solid #dedada;
          -webkit-box-shadow: 0 6px 4px -4px grey;
          -moz-box-shadow: 0 6px 4px -4px grey;
          box-shadow: 0 6px 4px -4px grey;
          margin-top: 1%;
          max-height: 210px;
          overflow-y: auto;
        }
        .border-deda::-webkit-scrollbar {
            background-color: white;
            width: 2px;
        }

        .border-deda::-moz-scrollbar {
            background-color: white;
            width: 2px;
        }

        .border-deda::-webkit-scrollbar-thumb {
            background-color: #3498db;
        }

        .border-deda::-moz-scrollbar-thumb {
            background-color: #3498db;
        }
        /*search box CSS starts */
        .search-box {
         display: inline-block;
         width: 100%;
         border-radius: 3px;
         padding: 4px 55px 4px 15px;
         position: relative;
         background: #fff;
         border: 1px solid #ddd;
         -webkit-transition: all 200ms ease-in-out;
         -moz-transition: all 200ms ease-in-out;
         transition: all 200ms ease-in-out;
        }
        .search-box.hovered, .search-box:hover, .search-box:active {
         border: 1px solid #aaa;
        }
        .search-box input[type=text] {
         border: none;
         box-shadow: none;
         display: inline-block;
         padding: 0;
         background: transparent;
        }
        .search-box input[type=text]:hover, .search-box input[type=text]:focus, .search-box input[type=text]:active {
         box-shadow: none;
        }
        .search-box .search-btn {
         position: absolute;
         right: 2px;
         top: 2px;
         color: #aaa;
         border-radius: 3px;
         font-size: 21px;
         padding: 5px 10px 1px;
         -webkit-transition: all 200ms ease-in-out;
         -moz-transition: all 200ms ease-in-out;
         transition: all 200ms ease-in-out;
        }
        .search-box .search-btn:hover {
         color: #fff;
         background-color: #8FBE00;
        }

        /*search box CSS ends */

        .custom-font{
            font-size: 15px;
            font-weight: 400;
        }

        .suggestion-dropdown{
            z-index:10;
            border-top: 1px solid #eee;
            border-right: none;
            position: absolute;
            width: 100%;
        }
        .suggestion-dropdown ul li{
            cursor:pointer;
        }

        `]
})

export class singleSearchSelect{
    private searchTypeText:string;
    public userRouteText:string
    private baseApiUrl:any;
    private baseAPiHeader:any;
    private typingTimer:any;
    public showSuggestionDropdown:boolean;               //timer identifier
    public noMatchingItemsFound:boolean;
    private doneTypingInterval = 500;  //time in ms, 5 second for example.
    public selectedRoutes:any[] = [];
    private searchSuggestions:any[];
    private selectionType:string;
    private globalFilterObj = {};
    public _appAutoFocus:boolean=false;
 
    constructor(private apiService:ApiService,private _elementRef: ElementRef){
        this._appAutoFocus =false;
    }

        get autoFocus(): boolean {
            return this._appAutoFocus;
        }

        @Input('autoFocus')
        set autoFocus(value: boolean) {          
          setTimeout(() => {
            value && (this._appAutoFocus = value);
          }, 90);  
        }

    @Input('searchType')
    set searchType(text:string){
        this.searchTypeText = text;
       
    }
    
    @Input('searchAPIUrl')
    set ApiUrl(url:any){
        this.baseApiUrl = url;
    }

    @Input('searchApiHeader')
    set ApiHeader(header:any){
        this.baseAPiHeader = header;
    }

    @Input('otherParams')
    set otherParams(param:any){
        this.resetSelectSearchUtility(param);
    }

    @Input('filterInputs')
    set filterInputs(param:any){
        this.globalFilterObj = param;
    }
    //
    // @Input('reset')
    // set reset(param:any){
    //    this.resetSelectSearchUtility(param);
    // }
    public selectedSec: any;
    @Input('routeSection')
    set routeSection(section: string){
            
           this.checkReouteType(section);

    }

    @Output()
    public clickOutside = new EventEmitter();

    @Output() emitUserSelections: EventEmitter<any> = new EventEmitter<any>();


    @HostListener('document:click', ['$event.target'])
    public onClick(targetElement:any) {
        const clickedInside = this._elementRef.nativeElement.contains(targetElement);
         if(clickedInside && this.searchSuggestions.length) {
             this.showSuggestionDropdown = true;
         }
         else {
             this.showSuggestionDropdown = false;
         }

    }

    checkReouteType(str: string){
         if(str == 'fn'){
            this.selectedSec = 'Morning';
         }else if (str == 'an'){
             this.selectedSec = 'Afternoon';
         }else if( str == 'en'){
             this.selectedSec = 'Evening';
         }
    }

    // For Clearign Rout Value 

    @Input('clearRoutVal')
    set filterInputsclr(e:any){
        if(e==true)
         this.userRouteText = null;
    }
    

    ngOnInit(){
    }

    keyUpHandler(){ 
        if(this.userRouteText){

        }else{
            this.selectedRoutes = [];
           
            this.emitUserSelections.emit(this.selectedRoutes);
        }
        clearTimeout(this.typingTimer);
        this.typingTimer = setTimeout(this.getSuggestions, this.doneTypingInterval);
    }

    keyDownHandler(){
        clearTimeout(this.typingTimer);
    }

    getSuggestions = () => {

         if(this.searchTypeText == 'onlyRoute'){
             this.getRouteSuggestions();
         }else if(this.searchTypeText == 'pickup'){
              this.getPickupSuggestions();
         }
         else if(this.searchTypeText == 'onlyPickup'){
            this.getPickupDetails();
       }else if(this.searchTypeText == 'member'){
             this.getMemberSuggestion();
         }else if(this.searchTypeText == 'device'){
             this.getDeviceSuggestion();
         }
    };



     selectDropdownSuggestions = (param:any) =>{
         let ifChoiceExists = this.selectedRoutes.findIndex(route => route.genericId == param.genericId);
        
         if(ifChoiceExists == -1) {
             this.selectedRoutes = [];
             this.selectedRoutes.push(param);
             this.userRouteText = param.genericName;
            
             this.showSuggestionDropdown = false; // hide suggestion dropdown.
         }

         this.userRouteText = param.genericName;
         this.showSuggestionDropdown = false;
        
         this.emitUserSelections.emit(false);
         setTimeout( ()=>{ this.emitUserSelections.emit(this.selectedRoutes); },100 );
     }

     getRouteSuggestions = ():void =>{
         let routeType = (this.searchTypeText == 'routePickup')?'pickup':'drop';

         this.baseAPiHeader = {
            data:{
                key:localStorage.getItem('scbToken'),
                filter:{
                    routeName:this.userRouteText
                },
                extra: {
                    maxlimit:'all'
                    
                  }
                
            }
         }

       
         if(Object.keys(this.globalFilterObj).length){
             for(let key of Object.keys(this.globalFilterObj)){
                   this.baseAPiHeader['data']['filter'][key] = this.globalFilterObj[key];
             }
         }
           
        this.apiService[this.baseApiUrl](this.baseAPiHeader).then((response:any)=>{
             if(response.response){

                 this.searchSuggestions = [... response.response];
            

                 for(let route of this.searchSuggestions ){
                  
                         Object.assign(route,{'genericName':route.routeName,'genericId':route.routeId});
                  }
                 this.noMatchingItemsFound = false;
             }else{
                 this.noMatchingItemsFound = true;
                 this.searchSuggestions = [];
             }
             this.showSuggestionDropdown = true;
        });
     }
     
     getPickupDetails = ():void => {
        this.baseAPiHeader = {
             data:{
                 key:localStorage.getItem('scbToken'),
                 filter:{
                      pickuppoint:this.userRouteText
                 }
             }
        }
        
        this.apiService[this.baseApiUrl](this.baseAPiHeader).then((response:any)=>{
               if(response.response){
                       this.searchSuggestions = [... response.response.pickuppoints];
                       for(let pickUp of this.searchSuggestions ){
                     
                        Object.assign(pickUp,{'genericName':pickUp.pickuppoint,'genericId':pickUp.pickupId});
                       }
               }else{
                   this.noMatchingItemsFound = true;
               }
               this.showSuggestionDropdown = true;
        });
    }
     getPickupSuggestions = ():void => {
         this.baseAPiHeader = {
              data:{
                  key:localStorage.getItem('scbToken'),
                  filter:{
                      pickuppoint:this.userRouteText
                  }
              }
         }
         this.apiService[this.baseApiUrl](this.baseAPiHeader).then((response:any)=>{
                if(response.response){
                        this.searchSuggestions = [... response.response.pickuppoints];
                        for(let pickUp of this.searchSuggestions ){
                              Object.assign(pickUp,{'genericName':pickUp.pickuppoint,'genericId':pickUp.pickupId});
                        }
                }else{
                    this.noMatchingItemsFound = true;
                }
                this.showSuggestionDropdown = true;
         });
     }

     getMemberSuggestion = ():void => {
         this.baseAPiHeader = {
             data:{
                 key:localStorage.getItem('scbToken'),
                 filter:{
                     MemberName:this.userRouteText
                 }
             }
         }
         this.apiService[this.baseApiUrl](this.baseAPiHeader).then((response:any)=>{
                if(response.response){
                    this.searchSuggestions = [... response.response];
                    for(let member of this.searchSuggestions ){
                          Object.assign(member,{'genericName':member.memberName,'genericId':member.memberId});
                    }
                    this.noMatchingItemsFound = false;
                }else{
                      this.noMatchingItemsFound = true;
                      this.searchSuggestions = []; //Patch for clearing list when no matching items found.
                }
                this.showSuggestionDropdown = true;
         });
     }

     getDeviceSuggestion = ():void => {
         this.baseAPiHeader = {
             data:{
                 key:localStorage.getItem('scbToken'),
                 filter:{
                     regNo:this.userRouteText
                 }
             }
         }
         this.apiService[this.baseApiUrl](this.baseAPiHeader).then((response:any)=>{
                if(response.response){
                    this.searchSuggestions = [... response.response];
                    for(let vehicle of this.searchSuggestions ){
                          Object.assign(vehicle,{'genericName':vehicle.vehNo,'genericId':vehicle.vehId});
                    }
                    this.noMatchingItemsFound = false;
                }else{
                      this.noMatchingItemsFound = true;
                      this.searchSuggestions = []; //Patch for clearing list when no matching items found.
                }
                this.showSuggestionDropdown = true;
         });
     }

     resetSelectSearchUtility(param:any){
         this.selectedRoutes = [];
         this.searchSuggestions = [];
         this.emitUserSelections.emit(this.selectedRoutes);
         this.showSuggestionDropdown = false;
     }

}
