/**
 * @Author: Deepak Pookkote <group10>
 * @Date:   2018-04-24T11:51:53+05:30
 * @Email:  deepak@groupten.com
 * @Project: School-Bus
 * @Filename: editRouteForm.dir.ts
 * @Last modified by:   group10
 * @Last modified time: 2018-05-08T15:42:58+05:30
 * @Copyright: Group10 Technologies
 */



import {
  Component,
  Input, Output, EventEmitter, NgZone
} from '@angular/core';
import { globalService } from './../../../services/global.service';
import { ApiService } from './../../../services/ApiService';
import { decodeAddress } from './../../../services/decodeAddress.service';
import { RouterModule, Routes, Router } from '@angular/router';
import { Observable, Observer } from 'rxjs';
import { AmazingTimePickerService } from 'amazing-time-picker';

declare var $: any;
declare var google: any;
declare var formattedAddress: any;
const swal = require('sweetalert');

@Component({
  selector: 'edit-route-form',
  template: `
  <!--contents for add Route div begins -->

  <div class="route-heading-container animated fadeIn" style="padding: 2%;background: rgba(158, 158, 158, 0.09);min-height:94px;">
    <div class="back-button pull-left grow meager-e2e-goback-editroute" id="back_btn" title="go back" (click)="hideEditRoute($event)" style="cursor:pointer;">
    <svg width="24" height="24" viewBox="0 0 24 24">
      <g class="nc-icon-wrapper" fill="#429db5">
        <path d="M21 11H6.83l3.58-3.59L9 6l-6 6 6 6 1.41-1.41L6.83 13H21z"></path>
      </g>
    </svg>
    </div>
    <div class="route-heading-tabs" style="padding:25px">
        <div class="list-heading pull-left meager-e2e-editroute-label">Edit Routes</div>
    </div>
  </div>
  <div class="routeDetails" style="overflow-y: scroll;max-height: 74vh;padding:25px;;overflow-x: hidden;">
    <div class="row editFillRouteInfo">
      <div class="form-group">
        <label class="control-label">Route Name<sup>*</sup></label>
        <input class="custom-form-control point-name point-input-text meager-e2e-editroute-rname" [(ngModel)]="routeInformation.routeName">
        <span class="text-danger meager-e2e-editroute-rname-errtext" *ngIf="showRouteNameError">Route name is a required field.</span>
      </div>
      <div class="form-group"> 
        <label class="control-label">Route Type<sup>*</sup></label>
          <select class="custom-form-control point-input-text meager-e2e-editroute-rtype" [(ngModel)]="routeInformation.routeType" >
            <option value="pickup">Pickup</option>
            <option value="drop">Drop</option>
          <!-- <option value="walker">Walker</option>-->
          </select>
          <span class="text-danger meager-e2e-editroute-rtype-errChoose" *ngIf="showRouteTypeError">Choose Route type</span>
      </div>
      <div class="form-group">
        <label class="control-label">Route Start Time (HH:MM)<sup>*</sup></label>
        <input atp-time-picker class="custom-form-control point-input-text meager-e2e-editroute-rstartTime" [(ngModel)]="routeInformation.routeStartTime" type="time" >
        <span class="text-danger meager-e2e-editroute-rstartTime-errtxt" *ngIf="showRouteStartTimeError">You Should Follow (HH:MM) Format</span>
      </div>
      <div class="form-group">
        <label class="control-label">Route End Time (HH:MM)<sup>*</sup></label>
        <input atp-time-picker class="custom-form-control point-input-text meager-e2e-editroute-rendTime" [(ngModel)]="routeInformation.routeEndTime" type="time">
        <span class="text-danger meager-e2e-editroute-rendTime-errtxt" *ngIf="showRouteEndTimeError">You Should Follow (HH:MM) Format</span>
      </div>
      <div class="form-group">
        <label class="control-label">Route Start Location<sup>*</sup></label>
        <textarea class="custom-form-control point-input-text meager-e2e-editroute-rstartloc" id="locationField" [(ngModel)]="inputRouteStartLocation" (change)="geocodeAddress()"></textarea>
        <span class="text-danger meager-e2e-editroute-rstartloc-errtxt" *ngIf="routeStartLocationError">Route location is a required field.</span>
      </div>

      <div class="form-group">
      <label class="control-label">Tags<sup>*</sup></label>
        <input class="custom-form-control meager-e2e-editroute-rtag" [(ngModel)]="tag"  (change)="store()" placeholder=" Enter tag" maxlength="20">
        <span class="text-danger  meager-e2e-editroute-rtag=errtxt" *ngIf="showRouteTagError">Route tag is a required field.</span>
      </div>
      <div >
        <span class=" tagClass cont mr5 text-capitalize mb5"*ngFor="let tag of tagList;let i=index;">
        {{tag}}  <i class="fa fa-times-circle rotate  meager-e2e-editroute-rtag-remove-{{i}}" aria-hidden="true" (click)="removeTag(i)" style="cursor: pointer;"></i>
        </span>
      </div>


      <div class="form-group" *ngIf="routeInformation" style="margin-top:10px;">
        <label class="control-label"  *ngIf="routeInformation.vehNo">Vehicle Assigned :</label> {{routeInformation.vehNo}}
         
        <span class="form-group" *ngIf="routeInformation.vehNo && routeInformation.vehId && routeInformation.vehId!='' && vehRouteData[routeInformation.vehId] && 
        vehRouteData[routeInformation.vehId] && 
        vehRouteData[routeInformation.vehId]['driverName'] && 
        vehRouteData[routeInformation.vehId]['driverName']!=''">
        <br>
        <label class="control-label">Driver Name :</label> {{vehRouteData[routeInformation.vehId]['driverName']}}
      </span >
        <i  class="fa fa-link unlinkIcon mr5 pull-right grow  meager-e2e-editroute-unlinkveh" aria-hidden="true" title="Unlink Vehicle" (click)="unlinkVehicle(routeInformation.routeId,routeInformation.vehId,'')" *ngIf="routeInformation.vehNo"></i>
        <label class="control-label"  *ngIf="!routeInformation.vehNo">Assign Vehicle</label>
        <select *ngIf="!routeInformation.vehNo" class="custom-form-control point-input-text  meager-e2e-editroute-selectveh"  [(ngModel)]="routeInformation.vehId" (click)="showAvailableOnClick(routeInformation.routeStartTime,routeInformation.routeEndTime)">
        <option *ngFor="let device of vehAvailable" value = "{{device.deviceId}}">{{device.VehicleNo}}</option>
        </select>
        <ng-container *ngIf="!routeInformation.vehNo && routeInformation.vehId && routeInformation.vehId!=''">
          <ng-container 
            *ngIf="availableVehObj[routeInformation.vehId] && 
                   availableVehObj[routeInformation.vehId]['vehicleInformation'] && 
                   availableVehObj[routeInformation.vehId]['vehicleInformation']['driverName'] && 
                   availableVehObj[routeInformation.vehId]['vehicleInformation']['driverName']!=''; else noDriverFound">
          </ng-container>
        </ng-container>
        <ng-template #noDriverFound><span class="meager-e2e-routeform-vehid-errtxt" style="color:#424242cc">*No driver assigned for this vehicle</span></ng-template>
        </div>

      <div class="form-group" *ngIf="!routeInformation.vehNo && routeInformation.vehId && routeInformation.vehId!='' && availableVehObj[routeInformation.vehId] && 
      availableVehObj[routeInformation.vehId]['vehicleInformation'] && 
      availableVehObj[routeInformation.vehId]['vehicleInformation']['driverName'] && 
      availableVehObj[routeInformation.vehId]['vehicleInformation']['driverName']!=''">
        <label class="control-label">Driver Name</label>
        <input class="custom-form-control point-name point-input-text meager-e2e-editroute-drivername" [(ngModel)]="availableVehObj[routeInformation.vehId]['vehicleInformation']['driverName']" disabled>
        <span class="text-danger meager-e2e-editroute-drivername-errtxt" *ngIf="showRouteDriverError">Route driver is a required field.</span>
      </div>

      <!--Route Alert Configuration-->
      <div class="form-group">
      <label class="control-label">Alerts :</label>
      <table class="setings-checkbox-main-tab">
          <tr class="settings-checkbox-main-tr">
              <td class="settings-checkbox-main-td">
                  <table>
                      <tr>
                          <td class="check"><input type="checkbox" class="chk regular-checkbox  meager-e2e-editroute-smsAlert"  [(ngModel)]="routeInformation.smsAlert">
                              <i class="fa fa-comments grey alertIcons" aria-hidden="true" title="Sms Alert" data-toggle="tooltip" data-placement="bottom"></i>
                          </td>
                      </tr>
                  </table>
              </td>
              <td class="settings-checkbox-main-td">
                  <table>
                      <tr>
                          <td class="check-mail"><input type="checkbox" class="chk regular-checkbox  meager-e2e-editroute-emailAlert"  [(ngModel)]="routeInformation.emailAlert" >
                              <i class="fa fa-envelope grey alertIcons" aria-hidden="true" title="Email Alert" data-toggle="tooltip" data-placement="bottom"></i>
                          </td>
                      </tr>
                  </table>
              </td>
              <td class="settings-checkbox-main-td">
                  <table>
                      <tr>
                          <td class="check-mail"><input type="checkbox" class="chk regular-checkbox  meager-e2e-editroute-appAlert" [(ngModel)]="routeInformation.appAlert" >
                              <i class="fa fa-bell grey alertIcons" aria-hidden="true" title="App Alert" data-toggle="tooltip" data-placement="bottom"></i>
                          </td>
                      </tr>
                  </table>
              </td>
              <td class="settings-checkbox-main-td">
                  <table>
                      <tr>
                          <td class="check-mail"><input type="checkbox" class="chk regular-checkbox  meager-e2e-editroute-callAlert" [(ngModel)]="routeInformation.callAlert"  >
                            <i class="fa fa-phone alertIcons" aria-hidden="true" title="Call Alert" data-toggle="tooltip" data-placement="bottom"></i>
                          </td>
                      </tr>
                  </table>
              </td>
          </tr>
      </table>
      </div>
      <!--Route Alert Configuration-->

      <div class="form-actions pull-right">
        <button class="webform-submit button-primary btn btn-primary form-submit  meager-e2e-editroute-updatebtn" (click)="editRouteObj(routeInformation.routeId)">Update</button>
      </div>
    </div>
    </div>


  <!--contents for Route ends here-->


  `,
  styles: [`

  list-heading{
      font-size: 30px;
      font-weight: 400;
  }
   .routeDetails::-webkit-scrollbar-track
  {
     -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
      background-color: #F5F5F5;
  }

  div .setings-checkbox-main-tab .settings-checkbox-main-tr .settings-checkbox-main-td {
     padding-right: 50px;
     padding-left: 15px;
  }

  .unlinkIcon{
    color: #fff;
    font-size: 17px;
    width: 35px;
    background: #2ebdd4;
    border-radius: 20px;
    height: 34px;
    text-align: center;
    line-height: 35px;
    box-shadow: 0px 2px 5px rgba(94,94,94,0.68);
    cursor: pointer;
  }

   /*animation css */
  .grow { transition: all .2s ease-in-out; }
  .grow:hover { transform: scale(1.1); }

  .alertIcons{
     font-size: 1.3em;
     margin-left: 3px;
     cursor:pointer;
  }

  .cont .rotate {
    cursor:pointer;
    font-size: 15px;
  }

  .cont .rotate:hover{
    color: red;
    transition: 0.9s;
    transform: rotateY(180deg);
  }

`  ],
})

export class SchoolEditRouteFormDir {

  public EditForm: boolean = false;
  public inputRouteStartLocation: string;
  public routeName: string;
  public routeType: string;
  public driverId: string;
  public routeStartTime: string;
  public routeEndTime: string;
  public routeStartDate: number;
  public routeEndDate: number;
  public vehicleId: number;
  public __ProuteStartLocation: string;
  public __geocodedLatLng: any;
  public routeInformation: any;
  public formatted_address: any[];
  public orgVehicles: any;
  public showRouteNameError: boolean;
  public showRouteTypeError: boolean;
  public showRouteDriverError: boolean;
  public showRouteTimeError: boolean;
  public showRouteStartTimeError: boolean;
  public showRouteEndTimeError: boolean;
  public routeStartLocationError: boolean;
  public routeVehicleError: boolean;
  public showRouteTagError: boolean;
  public selectedVehicle: any;
  //private tagEdit:any=[];

  public vehicleInfo: string;
  public tagList: any = [];
  public tag = '';
  public vehAvailable: any;
  public routeCreateMarker: any;
  public mapObj: any;
  public emailAlert: boolean;
  public smsAlert: boolean;
  public appAlert: boolean;
  public callAlert: boolean;

  public availableVehObj:any = {};

  //private editForm: boolean = false;

  @Input('clickedRouteId') //setting stoppage ID from other directive.
  set clickedRouteId(clickedRouteId: any) {
    this.clickedRouteId = clickedRouteId;
  }

  @Input('routeInfoDetails')
  set routeInfoDetails1(routeInfoDetails: any) {
    this.processRouteDetails(routeInfoDetails);
    //this.routeInformation = routeInfoDetails;
  }

  // @Input('routeStartLocation')
  // set name(routeStartLocation: string) {  //setter for reverse geocoding.
  //   this.__ProuteStartLocation = routeStartLocation;
  //   this.inputRouteStartLocation = this.__ProuteStartLocation
  // }
  //
  // @Input('geocodedLatLng')
  // set geocodedLatLng(geocodedLatLng: any) {
  //   this.__geocodedLatLng = geocodedLatLng;
  // }

  @Input('routeVehicles')
  set routeVehicles(routeVehicles: any) {
    this.processOrgVehicles(routeVehicles);
  }

  @Input('mapObj')
  set map(map: any) {
    this.mapObj = map;
    //this.createRouteMarker();
  }

  @Output() RouteList: EventEmitter<boolean> = new EventEmitter<boolean>();
  //  @Output() geocodeAddress: EventEmitter<string> = new EventEmitter<string>();
  @Output() hideEditRouteForm: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() editRoute: EventEmitter<any> = new EventEmitter<any>();

  public vehRouteData:any = {};

  constructor(public decodeAddress: decodeAddress, private apiService: ApiService) {
  }

  ngOnInit() {
    this.createRouteMarker();
    this.fetchVehicleDatas();
  }
  
  async fetchVehicleDatas(){
    await this.getOrgVehData().then((res:any)=>{
      console.log('veh',res)
      if(res && res.vehDetails && Object.keys(res.vehDetails).length>0){
        this.vehRouteData = res.vehDetails;
      }
    })
  }

  getOrgVehData(){
    return new Promise((resolve,reject)=>{
      let apiHeader = {
           data:{
               key:localStorage.getItem('scbToken'),
            //    extra: {
            //    pageJump: 1
            //  }
           }
      }
      this.apiService.getAllVehicleAllRouteReport(apiHeader).then(response => {
        let result=response;
        if(result.status=="success"){
          resolve(response.response);
        }else{
          reject('failure data');
        }
      }).catch((err)=>{
        reject(err);
      });

    })
  }


  createRouteMarker() {
    //Creating separate route marker.
    let mLatLng = new google.maps.LatLng(this.routeInformation.routeStartLocation.lat, this.routeInformation.routeStartLocation.lng);
    let parent: any = this;
    this.routeCreateMarker = new google.maps.Marker({
      position: mLatLng,
      map: this.mapObj,
      animation: google.maps.Animation.DROP,
      draggable: true
    });

    this.routeCreateMarker.addListener('dragend', function (e: any) {
      //  console.log(e.latLng.lat(),e.latLng.lng())
      parent.reverseGeocodeAddress(new google.maps.LatLng(e.latLng.lat(), e.latLng.lng()))
    });
    this.mapObj.panTo(mLatLng);
    this.bindMapClickEvt();
  }

  bindMapClickEvt() {
    let parent: any = this;
    this.mapObj.addListener('click', function (e: any) {
      var clickedLatLng = new google.maps.LatLng(e.latLng.lat(), e.latLng.lng());
      parent.routeCreateMarker.setPosition(clickedLatLng);
      google.maps.event.trigger(parent.routeCreateMarker, 'dragend', e);
    });
  }

  unbindMapClickEvt() {
    google.maps.event.clearListeners(this.mapObj, 'click');
  }

  store() {
    this.tagList.push(this.tag);
    this.tag = '';
  }
  checkVechAssing(e: any) {
    console.log(this.routeInformation.vehId);
    if (e.target.value === 'walker') {
      if (this.routeInformation.vehId) {
        this.unlinkVehicle(this.routeInformation.routeId, this.routeInformation.vehId, 'same');
      }
    } else {

    }
  }
  // function to remove the contact information from temporary array
  removeTag(i: number) {
    this.tagList.splice(i, 1);
  }

  processOrgVehicles(routeVehicles: any) {
    this.orgVehicles = routeVehicles;
  }

  processRouteDetails(routeDetails: any) {
    let routeInfo = routeDetails;
    this.routeInformation = routeInfo;
    this.selectedVehicle = this.routeInformation.vehId;
    this.vehicleInfo = this.routeInformation.vehNo;
    this.inputRouteStartLocation = this.routeInformation.routeAddress;

    this.smsAlert = this.routeInformation.smsAlert;
    this.appAlert = this.routeInformation.appAlert;
    this.emailAlert = this.routeInformation.emailAlert;
    this.callAlert = this.routeInformation.callAlert;

    let routeStrtLoc = new google.maps.LatLng(this.routeInformation.routeStartLocation.lat, this.routeInformation.routeStartLocation.lng);
    if (this.routeInformation.tags != '' && this.routeInformation.tags != undefined) {
      this.tagList = this.routeInformation.tags;
    }
  }

  getAvailableVehicles() {

    let isValid: boolean = true;

    if (!(this.validateTime(this.routeInformation.routeStartTime))) {
      this.showRouteTimeError = true;
      isValid = false;
    } else {
      this.showRouteTimeError = false;
    }

    if (!(this.validateTime(this.routeInformation.routeEndTime))) {
      this.showRouteTimeError = true;
      isValid = false;
    } else {
      this.showRouteTimeError = false;
    }

    if (isValid) {

      let parent: any;
      if (((this.routeInformation.routeStartTime) && (this.routeInformation.routeStartTime))) {
        // if(this.validateTime('')){
        let apiHeader = {
          data: {
            key: localStorage.getItem('scbToken'),
            filter: {
              routeStartTime: this.routeInformation.routeStartTime,
              routeEndTime: this.routeInformation.routeEndTime
            }
          }
        }
        this.apiService.getOrgVehicles(apiHeader).then(response => {
          this.vehAvailable = response.response;
          if(response.response && response.response.length>0){
            response.response.map((item:any)=>{
              if(item.deviceId) this.availableVehObj[item.deviceId] = item;
            })
          }
        }).catch(error => { });
      }
    }

  }

  // This function is to show the available vehcile Direectlt to User on clik on the select Box instead of (onchange) of timings function
  showAvailableOnClick(startTime: any, endTime: any) {

    if (this.vehAvailable == undefined || this.vehAvailable == null || this.vehAvailable == '') {
      let apiHeader = {
        data: {
          key: localStorage.getItem('scbToken'),
          filter: {
            routeStartTime: startTime,
            routeEndTime: endTime
          }
        }
      };
      this.apiService.getOrgVehicles(apiHeader).then(response => {
        this.vehAvailable = response.response;
      }).catch(error => { });
    } else {
      this.getAvailableVehicles();
    }

  }

  //function to edit route object for emission.
  editRouteObj(routeId: any) {
    
    if (this.validateUserInputs()) {
      let data = {
        data: {
          key: localStorage.getItem('scbToken'),
          form: {
            routeId: routeId,
            routeName: this.routeInformation.routeName,
            // driverId: this.routeInformation.driverId,
            routeStartTime: this.routeInformation.routeStartTime,
            routeEndTime: this.routeInformation.routeEndTime,
            tags: this.tagList,
            routeAddress: this.inputRouteStartLocation,
            smsAlert: this.routeInformation.smsAlert + "",
            appAlert: this.routeInformation.appAlert + "",
            emailAlert: this.routeInformation.emailAlert + "",
            callAlert: this.routeInformation.callAlert + "",
            routeStatus: "ontrip",
            routeType: this.routeInformation.routeType,
            routeStartLocation: {
              lat: this.routeCreateMarker.getPosition().lat(),
              lng: this.routeCreateMarker.getPosition().lng()
            },
            vehId: this.routeInformation.vehId
          }
        }
      };

      this.editRoute.emit(data);
      setTimeout(() => {
        this.routeCreateMarker.setMap(null);

        this.hideEditRouteForm.emit(false);
      }, 300);


    }
  }

  validateUserInputs(): boolean {
    let isValid: boolean = true;
    if (!(this.routeInformation.routeName.trim().length)) {
      this.showRouteNameError = true;
      isValid = false;
    } else {
      this.showRouteNameError = false;
    }

    if (!(this.routeInformation.routeType.trim().length)) {
      this.showRouteTypeError = true;
      isValid = false;
    } else {
      this.showRouteTypeError = false;
    }

    // if (!(this.routeInformation.driverId).trim().length) {
    //   this.showRouteDriverError = true;
    //   isValid = false;
    // } else {
    //   this.showRouteDriverError = false;
    // }

    if (!this.routeInformation.routeStartTime) {
      this.showRouteStartTimeError = true;
      isValid = false;
    } else {
      this.showRouteStartTimeError = false;
    }

    if (!this.routeInformation.routeEndTime) {
      this.showRouteEndTimeError = true;
      isValid = false;
    } else {
      this.showRouteEndTimeError = false;
    }

    if (!(this.inputRouteStartLocation.trim().length)) {
      this.routeStartLocationError = true;
      isValid = false;
    } else {
      this.routeStartLocationError = false;
    }

    if (!this.tagList.length) {
      this.showRouteTagError = true;
      isValid = false;
    } else {
      this.showRouteTagError = false;
    }

    if (!(this.validateTime(this.routeInformation.routeStartTime))) {
      this.showRouteStartTimeError = true;
      isValid = false;
    } else {
      this.showRouteStartTimeError = false;
    }

    if (!(this.validateTime(this.routeInformation.routeEndTime))) {
      this.showRouteEndTimeError = true;
      isValid = false;
    } else {
      this.showRouteEndTimeError = false;
    }
    return isValid;
  }

  hideEditRoute(event: any) {
    this.EditForm = false;
    this.routeCreateMarker.setMap(null);
    this.hideEditRouteForm.emit(false);
    this.unbindMapClickEvt();
  }

  validateTime(time: string) {
    let isValid = /^([0-1]?[0-9]|2[0-4]):([0-5][0-9])?$/.test(time);
    return isValid;
  }

  geocodeAddress() {
    let address = this.inputRouteStartLocation;

    //geocoding using custom service.
    var config = {
      'address': address
    };
    this.decodeAddress.geocode(config).then(response => {
      this.routeCreateMarker.setPosition(response['geometry'].location);
      this.mapObj.panTo(response['geometry'].location);
    }).catch(error => { });
  }

  reverseGeocodeAddress($event: any) {
    let geocoder = new google.maps.Geocoder();
    let address = $event;
    let parent: any = this;
    //reverse geocoding using custom service.
    var config = {
      'latLng': $event
    }
    this.decodeAddress.geocode(config).then(response => {
      this.inputRouteStartLocation = response['formatted_address'];
      $('#locationField').focus();
      $('#locationField').blur();
    }).catch(error => {
      console.log('Error - ', error);
    });
  }

  // unlinkVehicle(routeId: any, vehId: any) {

  //   let parent: any = this;
  //   let data = {
  //     data: {
  //             key: localStorage.getItem('scbToken'),
  //             form: {
  //                 routeId:routeId,
  //                 vehId: vehId,
  //                 removeVeh: 'yes'
  //             }
  //         }
  //   };

  //   swal({
  //     title: 'Are you sure?',
  //     text: "This Vehicle will be unlinked from this route",
  //     type: 'warning',
  //     timer: 10000,
  //     reverseButtons:true,
  //     showCancelButton: true,
  //     confirmButtonColor: '#3085d6',
  //     cancelButtonColor: '#e04d76',
  //     confirmButtonText: 'Yes, Do it!',
  //     cancelButtonText: 'No, keep it'
  //   }).then(()=> {
  //     this.apiService.editRoute(data).then(response => {
  //       var parent: any = this;
  //       if (response) {
  //         swal(
  //           'Removed!',
  //           'Route has been Removed',
  //           'success'
  //         ).done();
  //         setTimeout( ()=>{ swal.close();
  //         }, 500);
  //       this.hideEditRouteForm.emit(false);
  //       this.editRoute.emit(data);
  //       };
  //     }).catch(error => { });
  //   },
  //   function(dismiss:any) {
  //   if (dismiss === 'cancel') {
  //     swal(
  //       'Cancelled',
  //       'Cancelled :)',
  //       'error'
  //     ).done();
  //     setTimeout( ()=>{ swal.close();}, 1000);
  //   }
  // });
  // }

  unlinkVehicle(routeId: any, vehId: any, type: string) { // angular 6 code after migration

    const parent = this;
    let data = {
      data: {
        key: localStorage.getItem('scbToken'),
        form: {
          routeId: routeId,
          vehId: vehId,
          removeVeh: 'yes'
        }
      }
    };
    swal({
      title: 'Are you sure?',
      text: 'This Vehicle will be unlinked from this route',
      icon: 'warning',
      buttons: [
        'No, cancel it!',
        'Yes, I am sure!'
      ],
      dangerMode: true,
    }).then(function (isConfirm: any) {
      if (isConfirm) {
        parent.apiService.editRoute(data).then(response => {
          if (response) {
            //this will redirect back to Dashboard after api response is succes or failure
            // if (type === 'same') {

            // } else {
            parent.hideEditRouteForm.emit(false);

            // }
            parent.editRoute.emit(data);
          }
        }).catch(error => { });

      } else {
        // if (type === 'same') {

        //   this.routeInformation.routeType = 'pickup';
        // }
        // swal('Cancelled', 'Action aborted:)', 'error', {
        // timer : 2000,
        // });
      }
    });

  }


}

/*
Notes : npm install node-sass -g
*/
