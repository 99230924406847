/**
 * @Author: Deepak Pookkote <group10>
 * @Date:   2018-04-24T11:51:53+05:30
 * @Email:  deepak@groupten.com
 * @Project: School-Bus
 * @Filename: view-notifications.dir.ts
 * @Last modified by:   group10
 * @Last modified time: 2018-05-08T12:52:50+05:30
 * @Copyright: Group10 Technologies
 */



/*Auther:Deepak */
import { Component, Input, Output, EventEmitter } from '@angular/core';
import { RouterModule, Routes, Router } from '@angular/router';
import { globalService } from './../../services/global.service';
import { ApiService } from './../../services/ApiService';
const swal = require('sweetalert');
declare var $: any;
@Component({
  selector: 'view-notification',
  templateUrl: './viewNotificationTemplate.html',
  styles: [`
      .notificationHeader{
        padding-top: 0px;
        font-size: 20px;
        color:#454545;
        margin:-10px auto;
      }
      .panel-heading {
        padding: 25px 15px !important;
      }
      /*fixed table css */
      .table-fixed tbody {
    /*   height: 238px; */
    /*   overflow-y: auto; */
       width: 100%;
      }
      .table-fixed thead,
      .table-fixed tbody,
      .table-fixed tr,
      .table-fixed td,
      .table-fixed th {
       display: block;
      }
      .table-fixed tr:after {
       content: "";
       display: block;
       visibility: hidden;
       clear: both;
      }
      .table-fixed tbody td,
      .table-fixed thead > tr > th {
       float: left;
      }
      .table > thead > tr > th,
      .table > thead > tr > td {
       font-size: 14px;
       font-weight: 400;
       border-bottom: 0;
       letter-spacing: 1px;
       vertical-align: top;
       padding: 10px;
       background: #fffff;
       color: #050505;
       min-height:50px;
      }
      .table>tbody>tr>td, .table>tbody>tr>th, .table>tfoot>tr>td, .table>tfoot>tr>th, .table>thead>tr>td, .table>thead>tr>th {
        padding: 10px;
        border-top:1px solid #f5f5f5
      }

      .action-items {
        font-size:16px;
        cursor:pointer;
      }

    .animation-bottom10{
    animation: animationBottom10 2s;
    }
    @keyframes animationBottom10 {
      0% {   transform:translateY(10px); }
      100%{  transform:translateY(0px); }
    }

  .border-deda {
    border: 1px solid #dedada;
    -webkit-box-shadow: 0 6px 4px -4px grey;
    -moz-box-shadow: 0 6px 4px -4px grey;
    box-shadow: 0 6px 4px -4px grey;
    height: 144px;
    overflow-y: auto;
    margin-left:-15px;
    margin-right:-15px;
  }

  .searchbar {
      padding-bottom: 20px;
  }
  .border-deda::-webkit-scrollbar {
      background-color: white;
      width: 2px;
  }

  .border-deda::-moz-scrollbar {
      background-color: white;
      width: 2px;
  }

  .border-deda::-webkit-scrollbar-thumb {
      background-color: #3498db;
  }

  .border-deda::-moz-scrollbar-thumb {
      background-color: #3498db;
  }

  input[type=search]::-webkit-search-cancel-button {
  -webkit-appearance: searchfield-cancel-button;
  }

  .notifDate {
    font-size: 14px;
    margin: -10px auto;
    font-weight: 400;
  }
  .pagination {
    margin: 0px 0 !important;
  }
  .table>tbody tr ::-webkit-scrollbar-track
  {
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
     background-color: #F5F5F5;
  }

  .addedShadow{
     box-shadow: 0 5px 20px 0px rgba(0, 0, 0, 0.1);
    -moz-box-shadow: 0 5px 20px 0px rgba(0, 0, 0, 0.1);
    -webkit-box-shadow: 0 5px 20px 0px rgba(0, 0, 0, 0.1);
  }
  .panel-default>.panel-heading {
    color: #333;
    background-color: #ffff;
    border-color: #ffff;
  }
  .panel {
    box-shadow: 1px 2px 4px 0px lightgrey;
  }
  .panel-default {
    border-color: #fdfafa;
  }

`  ],
})
export class viewNotificationSettingsDir {



  public showFilter: boolean = false;
  public notificationList: any = [];
  public notificationType: any = "";
  public notificationFor: any = "";
  public notificationStatus: any = "";
  public VehicleName: any = "";
  public pageJump: number = 0;
  public limitOfRecords: number;
  public sharehintContainer: any = false;

  constructor(
    public router: Router,
    public globalService: globalService,
    private apiService: ApiService,
  ) {

  }

  @Output() triggerEditNotificationForm: EventEmitter<any> = new EventEmitter<any>();

  ngOnInit() {
    this.viewNotificationList();
  }

  public today: number = Date.now();

  filterFunction() {
    this.pageJump = 0;
    this.viewNotificationList();
    this.notificationType = "";
    this.notificationFor = "";
    this.notificationStatus = "";
    this.VehicleName = "";
  }

  clearFilter() {  //function for clearing all the filters
    this.notificationType = "";
    this.notificationFor = "";
    this.notificationStatus = "";
    this.VehicleName = "";
    setTimeout(() => {
      this.notificationFor = 0;
      this.notificationType = 0;
    });
    this.viewNotificationList();
  }



  closeShareContainer() {
    let parent: any = this;
    setTimeout(function () {
      parent.sharehintContainer = false;
    }, 1000);
  }


  viewNotificationList() {
    this.apiService.viewNotifications({
      data: {
        key: localStorage.getItem('scbToken'),
        filter: {
          notificationFor: this.notificationFor,
          notificationType: this.notificationType,
          status: this.notificationStatus,
          vehNo: this.VehicleName
        },
        extra: {
          orderByDateCreated: "-1",
          pageJump: this.pageJump
        }
      }
    }).then(response => {
      let result = response;
      if (result.status == "success") {
        //if(result.response!=null && result.response!=undefined && result.response.length>1){
        this.notificationList = result.response;
        //}
        // here response is API output
      }
      // Cheking no of records
      if (this.pageJump == 0) {
        this.limitOfRecords = this.notificationList.length;
      }
    })
      .catch(error => {
        console.log('Error while logging in', error);
      });
  }



  removeNotification(notificationId: any) {
    const parent = this;
    var notifObj = {
      data: {
        key: localStorage.getItem('scbToken'),
        form: {
          notificationId: notificationId
        }
      }
    };
    swal({
      title: 'Are you sure?',
      text: 'The selected notification will be removed',
      icon: 'warning',
      buttons: [
        'No, cancel it!',
        'Yes, I am sure!'
      ],
      dangerMode: true,
    }).then(function (isConfirm: any) {
      if (isConfirm) {
        parent.apiService.deleteNotification(notifObj).then(response => {
          if (response) {
            parent.viewNotificationList();
          }
        }).catch(error => { });
      } else {
      }
    });
  }

  passNotificationIdOnClick(notificationId: any) {
    let emittingJson = {
      showEditDirectivePerm: true,
      notifId: notificationId
    }
    this.triggerEditNotificationForm.emit(emittingJson);
  }

  // showEditPickUpForm() {
  //   this.triggerEditNotificationForm.emit(true);
  // }





}
/*
Notes : npm install node-sass -g
*/
