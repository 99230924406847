/**
 * @Author: Rakesh Roushan
 * @Date:   2018-04-23T10:20:49+05:30
 * @Email:  rakesh@groupten.com
 * @Project: SChool-Bus
 * @Filename: route.move.dir.ts
 * @Last modified by:   group10
 * @Last modified time: 2018-04-23T15:25:35+05:30
 * @Copyright: Group10 Technologies
 */


import { Component } from '@angular/core';
import { ApiService } from './../../services/ApiService';
import { FormControl, FormGroup, FormBuilder, Validators } from '@angular/forms';
declare var swal:any;

@Component({
    selector:'route-move-config',
    template:`
        <div class="panel panel-default">
            <div class="panel-heading">Route Move</div>
            <div class="panel-body">
                <div class="row" style="margin-bottom:4%">
                    <div class="col-md-6">
                        <label class="control-label">Source route:</label>
                        <single-search-select class="meager-e2e-move-source-route"
                        [searchType]="'onlyRoute'"
                        [searchAPIUrl]="'getRoutes'"
                        [otherParams]="'resetSearchUtility'"
                        (emitUserSelections)="setSourceRoute($event)">
                        (disableDependents)="disableDependentSearchFields($event)">
                        </single-search-select>
                    </div>
                    <div class="col-md-6">
                        <label class="control-label">Destination route:</label>
                        <single-search-select class="meager-e2e-move-destination-route"
                        [searchType]="'onlyRoute'"
                        [searchAPIUrl]="'getRoutes'"
                        [otherParams]="reset"
                        [filterInputs]="routeFilterInput"
                        (emitUserSelections)="setDestinationRoute($event)">
                        </single-search-select>
                    </div>
                </div>
                <section class="selectedRoutesInfoWrapper" *ngIf="checkRouteSelectionStatus()">
                    <div class="row routeBasicInfo" style="margin-bottom:5%" >
                        <div class="col-md-6 partitionRight">
                            <div class="operationGroupHeading mb5">Route Basic Info</div>
                            <div class="basicInfoGroup">
                                <label class="control-label otherConfigurationLabel">Route Name:</label>
                                <span class="pull-right">{{sourceRoute[0].routeName}}</span>
                            </div>
                            <div class="basicInfoGroup">
                                <label class="control-label">Route Start Time:</label>
                                <span class="pull-right">{{sourceRoute[0].routeStartTime}}</span>
                            </div>
                            <div class="basicInfoGroup">
                                <label class="control-label">Route Start Time:</label>
                                <span class="pull-right">{{sourceRoute[0].routeEndTime}}</span>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="operationGroupHeading mb5">Route Basic Info</div>
                            <div class="basicInfoGroup">
                                <label class="control-label otherConfigurationLabel">Route Name:</label>
                                <span class="pull-right">{{destinationRoute[0].routeName}}</span>
                            </div>

                            <div class="basicInfoGroup">
                                <label class="control-label">Route Start Time:</label>
                                <span class="pull-right">{{destinationRoute[0].routeStartTime}}</span>
                            </div>
                            <div class="basicInfoGroup">
                                <label class="control-label">Route Start Time:</label>
                                <span class="pull-right">{{destinationRoute[0].routeEndTime}}</span>
                            </div>
                        </div>
                    </div>
                    <div class="row mb5">
                        <div class="col-md-6 partitionRight">
                            <div class="operationGroupHeading mb5">Available Pickup Points:</div>
                            <ul class="list-group fixedHeightList">
                                <li class="list-group-item source-pckpt-list" *ngFor="let pickup of sourcePickupPoints" (click)="movePickupPoint(pickup.pickupId)">
                                     {{pickup.pickuppoint}}
                                    <span class="pull-right"><i class="fa fa-angle-right"></i></span>
                                </li>
                            </ul>
                        </div>
                        <div class="col-md-6">
                            <div class="operationGroupHeading mb5">Moved Pickup Points:</div>
                                <div class="formWrapper" *ngIf="destinationPickupPoints?.length">
                                        <ul class="list-group fixedHeightList">
                                            <li class="list-group-item dest-pckpt-list" *ngFor="let pickup of destinationPickupPoints">
                                                <div class="list-wrapper">
                                                    <div class="pull-left mr5" (click)="removePickupPoint(pickup.pickupId)"><i class="fa fa-angle-left mr5"></i></div>
                                                    <div class="point-name pickAddrContainer">{{pickup.pickuppoint}}</div>

                                                </div>
                                            </li>
                                        </ul>
                                        <button class="btn btn-success pull-right" (click)="moveRoute()">Submit</button>
                                </div>
                        </div>
                    </div>
                </section>
            </div>
        </div>
    `,
    styles:[`
        .operationGroupHeading{
            margin-bottom: 6%;
            border-left: 5px solid #03A9F4;
            padding: 8px 8px 8px 4px;
            font-size: 15px;
        }
        .basicInfoGroup{
            padding: 0 4% 1% 5%;
        }
        .partitionRight{
            border-right: 1px solid #9e9e9ebd;
        }
        .source-pckpt-list,.dest-pckpt-list{
            padding:4%;
            cursor:pointer;
            height: 55px;
        }
        .pickAddrContainer{
            overflow: auto;
            width: 67%;
        }
        .custom-dest-input{
            width: 20%;
            top: 10px;
            right: 20px;
            position: absolute;
        }
        input.ng-invalid{
          border:1px solid #f4433661;
        }
        li.pointSelected{
            background: #9e9e9e1f;
        }
        ul.fixedHeightList{
            height: 330px;
            overflow: auto;
        }

        `]
})
export class RouteMoveConfig{
    private sourceRoute:any;
    private destinationRoute:any;
    public routeFilterInput:any;
    private showDestinationFields:boolean;
    private sourcePickupPoints:any;
    private destinationPickupPoints:any;
    public reset:number;
    private routeMoveForm:FormGroup;
    constructor(private apiService:ApiService,public formBuilder:FormBuilder){
        this.sourceRoute = null;
        this.destinationRoute = null;
        this.routeFilterInput = null;
        this.showDestinationFields = false;
        this.sourcePickupPoints = [];
        this.destinationPickupPoints = [];
    }

    ngOnInit(){
        //this.routeMoveForm = this.formBuilder.group({});
    }

    checkRouteSelectionStatus(){
        let flag = false;
        if((this.sourceRoute.length) && (this.destinationRoute.length)) flag = true;
        return flag;
    }

    setSourceRoute(route:any):void{
            if((route)&&(route.length)){
                this.routeFilterInput = {
                    //routeStartTime:route[0].routeStartTime,
                    //routeEndTime:route[0].routeEndTime,
                    //notIn:1
                }
                this.sourceRoute = route;
                this.reset = Math.random();
                this.getRoutePickupPoints(this.sourceRoute[0].routeId).then((response:any)=> {
                    //for(let info of response){info['isSelected'] = false;}
                    this.sourcePickupPoints = response;
                });
            }else{
                this.routeFilterInput = {};
                this.sourceRoute = [];
                this.sourcePickupPoints = [];
                this.reset = Math.random();
            }
    }

    setDestinationRoute(route:any):void{
        if((route)&&(route.length)){
            this.destinationRoute = route;
            //this.getRoutePickupPoints(this.destinationRoute[0].routeId).then((response)=> {this.destinationPickupPoints = response;})
        }else{
            this.destinationRoute = [];
            this.destinationPickupPoints = [];
        }
    }

    getRoutePickupPoints(routeId:any):any{
            let apiHeader = {
                data:{
                    key:localStorage.getItem('scbToken'),
                    filter:{
                        routeId:routeId
                    }
                }
            }

          let promiseObj = this.apiService.getRouteStoppages(apiHeader).then(response => {
                                return response.response.pickuppoints;
                            }).catch(error => {});
          return promiseObj;
    }

    movePickupPoint(pickupId:string):void{
        let pickupIndex = this.sourcePickupPoints.findIndex((ele:any) => ele.pickupId == pickupId);
        let ifPointExists = this.destinationPickupPoints.findIndex((ele:any) =>ele.pickupId == pickupId);
        if((pickupIndex>=0)&&(ifPointExists < 0)){
            this.destinationPickupPoints.push(this.sourcePickupPoints[pickupIndex]);
            //this.sourcePickupPoints[pickupIndex]['isSelected'] = true; //change selected class.
            this.sourcePickupPoints.splice(pickupIndex,1);
           // this.createRouteMoveForm();
        }
    }

    removePickupPoint(pickUpId:string):void{
        let pickupIndex = this.destinationPickupPoints.findIndex((ele:any) => ele.pickupId == pickUpId);
        let sourcePickupIndex = this.sourcePickupPoints.findIndex((ele:any) => ele.pickupId == pickUpId);
        if(pickupIndex >=0){
            this.sourcePickupPoints.push(this.destinationPickupPoints[pickupIndex]);
           // this.sourcePickupPoints[sourcePickupIndex]['isSelected'] = false; //change selected class.
            this.destinationPickupPoints.splice(pickupIndex,1);
           // this.createRouteMoveForm();
        }
    }

    disableDependentSearchFields(param:boolean):void{
        this.showDestinationFields = param;
    }

    createRouteMoveForm():void{
        let formValues:any = [];
        let minTime =  this.destinationRoute[0].routeStartTime;
        let maxTime = this.destinationRoute[0].routeEndTime;
        this.destinationPickupPoints.forEach(function(value:any){
              formValues[value.pickupId] = [value.expectedTime,[Validators.compose([Validators.required,minValueValidator(minTime,maxTime)])]];
        });
        this.routeMoveForm = this.formBuilder.group(formValues);
    }

    moveRoute():void{
        let fromRouteId = this.sourceRoute[0].routeId;
        let toRouteId   = this.destinationRoute[0].routeId;
        let movedPickupPoints = this.destinationPickupPoints.map((index:any) => {return index.pickupId});
        let apiHeader = {
            data:{
                key:localStorage.getItem('scbToken'),
                form:{
                    FromRouteId:fromRouteId,
                    ToRouteId:toRouteId,
                    pickuppoints:movedPickupPoints
                }
            }
        }
        this.apiService.moveRoute(apiHeader).then(response => {
          let result=response;
          if(result.status=="success"){
            swal("Success", "Route Move Successfull", "success").done();
          }else {
              swal("Failure",response.message, "error").done();
          }
        }).catch(error => {});
    }
}

//custom validator.
export const minValueValidator = (min:number,max:number) => {
  return (control:FormControl ) => {
    return ((control.value >= min) && (control.value <= max)) ? null : {
      minMax: true
    }
  };
};