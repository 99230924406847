import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { GlobalUtils } from './../../../services/global-utils';
import { ApiService } from "../../../services/ApiService";
const pageNumber = 0;
@Component({
    selector: 'rfid-app-push',
    styleUrls: ['./rfidreport.css'],
    template: `

    <ng-container *ngIf="isLoader">
                   
    <div class="loaderContainer" *ngIf="pageLoader">
    <loader></loader>
     </div>
                    
    </ng-container>
    <ng-container *ngIf="renderData">
    <div class="panel panel-default">
    <div class="panel-heading">
        <div class="row mb5">
            <div class="reportHeading text-center mb5">RFID  App Push Report</div>
        </div>
    </div>
    <div class="panel-body" style="height:443px;overflow:auto" (scroll)="scrollHandler($event)">
    <div class="text-right">
    <a href ="{{downloadExcel()}}" class="reportExcelDownloadLink">Download excel Report</a>
    </div>
        <table class="table table-bordered table-hover">
            <thead class="text-center">
                    <th class="slNumber-report">#</th>
                    <th class="text-right v-center" style="width:134px !important;min-width:134px">TimeStamp</th>
                    <!-- <th class="text-left v-center"  target="_blank"  >Member Id <br> / Name</th> -->
                    <th class="text-left v-center" style="width:100px;">Member<br>
                        <span>Id / Name </span>
                    </th>
                    <!-- <th class="routecolumnClass text-left v-center">Member Class <br>/ Section</th> -->
                    <th class="text-left v-center">Member
                    Class
                    </th>
                    <th class="text-center v-center">Member section</th>
                    <th style="width:20px !important; min-width:20px !important;">Route</th>
                    <th class="text-center v-center">Vehicle</th>
                    <th class="text-center v-center" style="width:10px;min-width:10px;">Mobile</th>
                    <th class="slNumber-report"><i class="fa fa-envelope"></i></th>
            </thead>
            <tbody>
           
            <ng-container *ngFor="let item of rfidAppPushCollection;let i = index;trackBy:lastRendered">
            <tr>
                <td class="v-center text-right">{{i+1}}</td>
                <td class="v-center text-right">{{item.logTimeMS | date:'yyyy-MM-dd hh:mm a'}}</td>
                <td class="v-center text-left">{{item.memberUid}}<br>{{item.memberName}}</td>
                <td class="v-center text-center">{{item.memberClass}}</td>
                <td class="v-center text-left">{{item.memberSection}}</td>
                <td class="v-center text-left text-capitalize">{{item.routeType}}</td>
                
                <td class="v-center text-left">{{item.vehicleName}}</td>
                <td class="v-center text-right">{{item.mobileno}}</td>
                <td class="v-center text-center text-primary">
                        <i id="envelope_{{i+1}}" class="fa-fw fa fa-1x fa-envelope fa-animation" aria-hidden="true" (click)="openEnvelope($event);" title="{{item.message}}"></i>
                        </td>
                </tr>
            </ng-container>
            
            </tbody>
        </table>
        <ng-container *ngIf="miniLoader"><p class="text-center"><i class="fa fa-spinner fa-pulse fa-fw"></i> Loading</p></ng-container>

    </div>
    </div>
        

  
    </ng-container>

    <ng-container *ngIf="emptyRecordSet">

            <div class="panel panel-default">
            <div class="panel-heading">
                <div class="row mb5">
                    <div class="reportHeading text-center mb5">RFID  App Push Report</div>
                </div>
            </div>
          
            <h4 class="animated pulse text-center"  style="animation-duration: 1s;color:#999FBF; margin:70px;"><i class="fa fa-frown-o"></i>&nbsp;
            No Data to Display</h4>
          
            </div>
        
    </ng-container>
    <ng-container *ngIf="dateSelect">

            <div class="panel panel-default">
            <div class="panel-heading">
                <div class="row mb5">
                    <div class="reportHeading text-center mb5">RFID  App Push Report</div>
                </div>
            </div>
          
            <h4 class="animated pulse text-center"  style="animation-duration: 1s;color:#999FBF; margin:70px;"><i class="fa fa-frown-o"></i>&nbsp;
            {{displayErrorMessage}}</h4>
          
            </div>
        
    </ng-container>

    
    
    `,

})

export class RfidAppPushComponent {

    apiHeaderFormer: any = {};
    isLoader: boolean = false;
    renderData: boolean = false;
    emptyRecordSet: boolean = false;
    dateSelect: boolean = false;
    paginate: number = 0;
    clonedCopyFilter: any = {}
    snackbars: boolean = false;
    miniLoader: boolean = false;
    private displayErrorMessage: string;
    finishedApiCallFlag: boolean = false;
    @Input('getHeader')
    set getHeader(val: any) {
        // this.resetter();
        // this.apiHeaderFormer = val;   
        // if(val){
        //     this.apiHeaderFormer.data.filter = this.dataClearer(JSON.parse(JSON.stringify(this.apiHeaderFormer.data.filter)));

        // } 
        this.resetter();
        let neuveValue = Promise.resolve(val);
        this.paginate = 0;
        neuveValue.then(res => {
            if (res) {
                this.apiHeaderFormer = res;
                this.apiHeaderFormer.data.filter = this.dataClearer(JSON.parse(JSON.stringify(this.apiHeaderFormer.data.filter)));
                this.paginate == 0 ? this.getRfidAppPush(this.paginate) : (this.paginate = 1);
            }

        })

    }

    public rfidAppPushCollection: any = []

    constructor(
        public apiCall: ApiService,
        private globalUtils: GlobalUtils,

    ) {
        this.finishedApiCallFlag = false;
    }


    getRfidAppPush(page: number) {
        this.miniLoader = true;
        this.apiHeaderFormer.data.extra = {
            pageJump: this.paginate
        };

        this.apiCall.getRfidAppPush(this.apiHeaderFormer).then(res => {
            if (res.status === 'success') {

                this.displayErrorMessage = '';
                this.dateSelect = false;

                if (!res.response || res.response == null) {

                    this.emptyRecordSet = res.totalcount == 0 ? true : false;
                    this.renderData = this.emptyRecordSet ? false : true;
                    this.miniLoader = false;
                    this.finishedApiCallFlag = false;

                } else {

                    res.response.forEach((itm: any) => this.rfidAppPushCollection.push(itm));
                    this.miniLoader = false;
                    this.emptyRecordSet = res.totalcount != 0 ? false : true;
                    this.renderData = this.emptyRecordSet ? false : true;
                    this.finishedApiCallFlag = true;

                }
            } else {
                //this.isLoading = false;;

                this.displayErrorMessage = this.globalUtils.getErrorMsg(res.ec);
                this.dateSelect = true;
                // alert(this.displayErrorMessage);
                this.emptyRecordSet = false;
                this.finishedApiCallFlag = false;

            }
        });
    }

    scrollHandler($event: any) {

        //following code hided by krish- its previous
        // if (($event.target.scrollTop + $event.target.offsetHeight) >= $event.target.scrollHeight  && this.finishedApiCallFlag) {
        //     console.log('22222222222222')
        //     this.paginate++;
        //     this.paginate <= 1 && this.getRfidAppPush(this.paginate);
        // }
        if (($event.target.scrollTop + $event.target.offsetHeight + 1) >= $event.target.scrollHeight) {
            this.paginate++;
            this.getRfidAppPush(this.paginate);

        }
    }


    lastRendered(index: any) {
        return index;
    }

    openEnvelope(event: any) {

        const closedEnvelope = event.target.classList.contains('fa-envelope');
        const openedEnvelope = event.target.classList.contains('fa-envelope-open');

        if (closedEnvelope) {
            event.target.classList.remove("fa-envelope");
            event.target.classList.add("fa-envelope-open");
            this.copy(event.target.title);

        }
        if (openedEnvelope) {
            event.target.classList.remove("fa-envelope-open");
            event.target.classList.add("fa-envelope");
            this.copy(' ');
        }

    }
    copy(text: string) {
        var input = document.createElement('input');
        input.setAttribute('value', text);
        document.body.appendChild(input);
        input.select();
        var result = document.execCommand('copy');
        document.body.removeChild(input);
        return result;
    }

    resetter() {
        this.rfidAppPushCollection = [];
        this.paginate = 0;
    }


    dataClearer(obj: any = {}) {
        let returnObj = {}
        for (let item in obj) {
            if (obj[item]) returnObj[item] = obj[item];
        }
        return returnObj;
    }


    downloadExcel() {

        let data = this.apiHeaderFormer.data;

        let initString: string = '';

        initString = `${this.apiCall.base}report/download_rfidappp_reports&key=${data.key}&fromDate=${data.form.fromDate}&fromTime=${data.form.fromTime}&toDate=${data.form.toDate}&toTime=${data.form.toTime}`;
        if (data.filter.routeType) {
            initString += `&routeType=${data.filter.routeType}`;
        }

        if (data.filter.memberId) {
            initString += `&memberId=${data.filter.memberId}`;
        }
        if (data.filter.memberclass) {
            initString += `&memberclass=${data.filter.memberclass}`;
        }
        if (data.filter.membersection) {
            initString += `&membersection=${data.filter.membersection}`;
        }
        if (data.filter.memberuid) {
            initString += `&memberuid=${data.filter.memberuid}`;
        }
        if (data.filter.mobileNo) {
            initString += `&mobileNo=${data.filter.mobileNo}`;
        }

        return initString;

    }

}