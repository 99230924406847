/**
 * @Author: Deepak Pookkote <group10>
 * @Date:   2018-04-17T11:33:49+05:30
 * @Email:  deepak@groupten.com
 * @Project: School-Bus
 * @Filename: studentEditForm.dir.ts
 * @Last modified by:   group10
 * @Last modified time: 2018-05-11T12:13:59+05:30
 * @Copyright: Group10 Technologies
 */



/*Auther:Deepak */
import { Component, Input, OnInit, OnDestroy } from '@angular/core';
import { globalService } from './../../services/global.service';
import { ApiService } from './../../services/ApiService';
import { ActivatedRoute, Params, NavigationEnd, Router } from '@angular/router';
import { Subscription } from 'rxjs/Subscription';
import { GlobalUtils } from './../../services/global-utils';
import { StorageService } from './../../services/storage.service';
// import { NG_VALIDATORS,Validator,Validators,AbstractControl,ValidatorFn } from '@angular/forms';
const swal = require('sweetalert');
declare var $: any;

@Component({
  selector: 'student-editform',
  styles: [`
    div.section-title{
      font-size:20px;
    }
    div.section-title-border{
      margin-bottom: 20px;
      border-bottom: 1px solid #00BCD4;
      border-bottom-width: medium;
      border-spacing: 1px;
      width:5%;
    }
    .student-basic-info,.student-pickup-info,.student-drop-info,.student-notif-status{
      margin-bottom:10px;
    }
    .rotate:hover{
            color: red;
            transition: 0.9s;
            transform: rotateY(180deg);
    }
    input[type=checkbox], input[type=radio] {
      margin: 4px 4px -3px;
      line-height: normal;
    }

    .customHeight{
      height:42px;
      box-shadow:none;
    }
    .fa-close{
          float: right;
          color: #808080;
          margin: -30px 0px 8px 23px;
          padding: 0 5px 5px 3px;
          cursor: pointer;
          position: absolute;
          z-index: 9999;
          right: 7%
    }

  /*  .fa-close{
          float: right;
          color: grey;
          margin: -30px 0px 8px 23px;
          padding: 0 5px 5px 3px;
          cursor:pointer
          }

  */

    `],
  template: `
  <form>
    <section class="student-basic-info">
    
      <div class="row">
        <div class="section-title">Student Basic Info</div>
        <div class="section-title-border"></div>
      </div>
      <div class="row">
        <div class="form-group col-md-4">
          <label class="control-label">Student ID<sup>*</sup></label>
          <input class="custom-form-control meager-e2e-edit-stu-id" name="memberId" [(ngModel)]="studentInfo.memberUID" disabled>
        </div>
        <div class="form-group col-md-4">
          <label class="control-label">Student Name<sup>*</sup></label>
          <input class="custom-form-control meager-e2e-edit-stu-name" name="memberName" [(ngModel)]="studentInfo.memberName" required appAutofocus>
          <div *ngIf="formError.StudentName==true" class="text-danger">*Student Name should not be empty</div>

        </div>
        <div class="form-group col-md-4">
          <label class="control-label">Class<sup>*</sup></label>
          <!-- <input class="custom-form-control" name="memberClass" [(ngModel)]="studentInfo.memberClass" required> -->
          <select class="custom-form-control meager-e2e-edit-stu-class" name="memberClass" [(ngModel)]="studentInfo.memberClass" required>
          <option *ngFor="let item of classAndSessions.listofclasses" value="{{item}}">{{item}}</option>
          </select>
          <div *ngIf="formError.StudentClass==true" class="text-danger">*Student Class should not be empty</div>
        </div>
      </div>
      <div class="row">

        <div class="col-md-4 form-group">
          <label class="control-label">Section<sup>*</sup></label>
          <!--<input class="custom-form-control" name="memberSection" [(ngModel)]="studentInfo.memberSection" required>-->
          <select class="custom-form-control meager-e2e-edit-stu-section" name="memberSection" [(ngModel)]="studentInfo.memberSection" required>
          <option *ngFor="let item of classAndSessions.listofsections" value="{{item}}">{{item}}</option>
          </select>
          <div *ngIf="formError.StudentSection==true" class="text-danger">*Student section should not be empty</div>
        </div>
        <div class="col-md-4 form-group">
          <label class="control-label">Parent Name<sup>*</sup></label>
          <input class="custom-form-control meager-e2e-edit-stu-parent-name" name="memberParentName" [(ngModel)]="studentInfo.memberParentName" required>
          <div *ngIf="formError.ParentName==true" class="text-danger">*Parent name should not be empty</div>
        </div>
        <div class="col-md-4 form-group">
          <label class="control-label">Primary Contact Number<sup>*</sup></label>
          <input class="custom-form-control meager-e2e-edit-stu-primary-number" name="memberContact" (keyup)="MobileNumberValidator($event)" [(ngModel)]="studentInfo.memberPrimaryMobileNo" type="text" minlength="10" maxlength="13">
          <div *ngIf="formError.Contact==true" class="text-danger">*Contact Number should not be empty/Invalid Number</div>
        </div>
      </div>
      <div class="row contact-details">

        <div class="col-md-4 form-group">
          <label class="control-label">Secondary Contact Number</label>
          <input class="custom-form-control meager-e2e-edit-stu-secondary-number" name="memberSecContact" (keyup)="MobileNumberValidator($event)" [(ngModel)]="studentInfo.memberSecondaryMobileNo" type="text" required minlength="10" maxlength="13">
        </div>
        <div class="col-md-4 form-group">
          <label class="control-label">Student RFID</label>
          <input #rfidReference (change)="setValue(rfidReference.value);" class="custom-form-control meager-e2e-edit-stu-rfid-number" name="memberRfid" [(ngModel)]="studentInfo.rfid" type="text" maxlength="18">
        </div>
        <div class="col-md-4 form-group" *ngIf="linguastical">
        <label class="control-label">Student Name in Native Spell</label>
        <input class="custom-form-control meager-e2e-edit-stu-native-name" name="memberName1" [(ngModel)]="studentInfo.memberName1">
        </div>
      </div>
    </section>
    <!--Student Route Information -->
    <section class="student-pickup-info">
      <div class="row ">

         <!-- <span (click)="showStudentRouteList(studentInfo.memberId,studentInfo.memberName)" class="mr5" style="float:right;cursor: pointer;color: #429db5;padding: 0px 0px 0px 0px;"> Add Pickup Point  </span>
         <span class="mr5" style="float:right;cursor: pointer;color: #429db5;padding: 0px 0px 0px 0px;"> /</span>
        <span (click)="addStudentRoute(studentInfo.memberId,studentInfo.memberName)" class="mr5" style="float:right;cursor: pointer;color: #429db5;" > <i class="fa fa-plus"></i> Add Route </span>
       -->
         <div class="col-md-3 pull-left" style="width:auto !important;">

          <div class="section-title ">Student Route info</div>
          <div class="section-title-border" style="width:30%"></div>
        </div>
        <div Class="col-md-3">
          <span class=" label label-primary mr5 text-capitalize">pickup</span>
        </div>
        <!--<div class="col-md-6" style="width:100% !important;">
          <button *ngIf="selectedRoute!='' && selectedRoute!=undefined" (click)="deleteRoute1Info(studentInfo.memberId)" type="button" class="btn btn-danger btn-xs pull-right mr5" data-toggle="tooltip" data-placement="bottom" title="Remove Route1" style="cursor:pointer;margin-top:-20px;">
                <i class="fa fa-trash rotate mr5"  aria-hidden="true"></i>Remove
          </button>
        </div>-->
      </div>
      <div> 

        <div class="row route-details" *ngFor="let pickup of pickupInfo">
          <div>
            <div class="col-md-4 form-group">
              <label class="control-label">Route Name</label>
              <div>
                <input class="custom-form-control customHeight meager-e2e-edit-stu-route1" name="routename" [value]="pickup.routeName" disabled>
                <span (click)="deletepickupInfo(studentInfo.memberId,pickup.routeId,pickup.pickupId)">
                <i class="fa fa-close"></i>
          </span>
              </div>
            </div> 
            <div class="col-md-4 form-group" *ngIf="!showpickupList">
              <label class="control-label">Pickup Point </label>
              <div title="{{route1Info.pickuppoint}}">
                <input class="custom-form-control customHeight meager-e2e-edit-stu-pp1" name="pointname" [value]="pickup.pickuppoint" disabled>
                <span (click)="emptyPickupPoint(pickup.routeId)">
                      <i class="fa fa-close"></i>
                </span>
                </div>
            </div>

            <div class="col-md-4 form-group" *ngIf="showpickupList">
            <label class="control-label">Pickup Point </label>
              <select class="custom-form-control customHeight" id="pickupPoint" name="pickupPoint" [(ngModel)]="selectedPickupPoint" (change)="estimateEta(selectedPickupPoint,studentInfo.memberId,studentInfo.memberName)" maxlength="10">
              <option *ngIf="routePickUpPoints" value="addpickupPoint">Add Pickup Point</option>   
              <option *ngFor="let point of routePickUpPoints" value="{{point.pickupId}}">{{point.pickuppoint}}</option>
              </select>
              <div *ngIf="formError.pickuppoint==true" class="text-danger">*Pickup Point should not be empty</div>
          </div>

          <div class="col-md-4 form-group" *ngIf="showpickupList">
          <label class="control-label">Estimated Arrival Time</label>
          <input class="custom-form-control customHeight meager-e2e-edit-stu-pp1-exp-time" name="pointEta" [(ngModel)]="selectedPointETA " disabled>
        </div>
          
            <div class="col-md-4 form-group" *ngIf="!showpickupList">
              <label class="control-label">Estimated Arrival Time</label>
              <input class="custom-form-control customHeight meager-e2e-edit-stu-pp1-exp-time" name="pointEta" [(ngModel)]="pickup.expectedTime" disabled>
            </div>
          </div>
        <!--  <button  (click)="deletepickupInfo(studentInfo.memberId,pickup.routeId,pickup.pickupId)" type="button" class="btn btn-primary btn-xs pull-right mr5" data-toggle="tooltip" data-placement="bottom" title="Remove Route1" style="cursor:pointer;margin-right: 15px;">
                <i class="fa fa-edit rotate mr5"  aria-hidden="true"></i>Manage
          </button>-->
        </div>
      </div>
      <div *ngIf="pickupInfo == undefined || pickupInfo?.length == 0">
        <div class="row route-details">
          <div>
            <div class="col-md-4 form-group">
              <label class="control-label">Route Name</label>
              <!--<select class="custom-form-control" id="routeName" name="routeName" [(ngModel)]="selectedRoute" (change)="populateRouteStoppages()">
                  <option *ngFor="let route of schoolRoutes" value="{{route.routeId}}">{{route.routeName}}</option>
              </select>-->
              <single-search-select
                [searchType]="'onlyRoute'"
                [searchAPIUrl]="'getRoutes'"
                [otherParams]="'resetSearchUtility'"
                (emitUserSelections)="setSelectedRoute($event)">
              </single-search-select>
            </div>
            <div class="col-md-4 form-group" *ngIf="!route1Info.pickuppoint">
              <label class="control-label">Pickup Point </label>
                <select class="custom-form-control customHeight" id="pickupPoint" name="pickupPoint" [(ngModel)]="selectedPickupPoint" (change)="estimateEta(selectedPickupPoint,studentInfo.memberId,studentInfo.memberName)" maxlength="10">
                    <option *ngIf="routePickUpPoints" value="addpickupPoint">Add Pickup Point</option>
                    <option *ngFor="let point of routePickUpPoints" value="{{point.pickupId}}">{{point.pickuppoint}}</option>
                </select>
                <div *ngIf="formError.pickuppoint==true" class="text-danger">*Pickup Point should not be empty</div>
            </div>
            <div class="col-md-4 form-group" *ngIf="route1Info.pickuppoint">
              <label class="control-label">Pickup Point </label>
              <div title="{{route1Info.pickuppoint}}">
                <input class="custom-form-contro customHeight" name="pointname" [(ngModel)]="route1Info.pickuppoint" disabled>
              </div>
              <div *ngIf="formError.pickuppoint==true" class="text-danger">*Pickup Point should not be empty</div>
            </div>
            <div class="col-md-4 form-group">
              <label class="control-label">Estimated Arrival Time</label>
              <input class="custom-form-control customHeight meager-e2e-edit-stu-pp2-exp-time" name="pointEta" [(ngModel)]="selectedPointETA " disabled>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="student-drop-info">
      <div class="row">
        <div class="col-md-3 pull-left" style="width:auto !important;">
          <div class="section-title ">Student Route info</div>
          <div class="section-title-border" style="width:30%"></div>
        </div>
        <div Class="col-md-3">
          <span class=" label label-primary mr5 text-capitalize">Drop</span>
        </div>
        <div class="col-md-6" style="width:100%!important;">
          <!-- Route 2 Info edit Area-->
        <!--  <button *ngIf="selectedRouteReturn!='' && selectedRouteReturn!=undefined" (click)="deleteDropInfo(studentInfo.memberId)" type="button" class="btn btn-danger btn-xs pull-right mr5" style="cursor:pointer;margin-top:-20px;" data-toggle="tooltip" data-placement="bottom"
            title="Remove Route2">
            <i class="fa fa-trash rotate mr5"aria-hidden="true"></i>Remove
          </button>-->
        </div>
      </div>
      <div>
        <div class="row route-details" *ngFor="let drop of dropInfo">
          <div class="">
            <div class="col-md-4 form-group">
              <label class="control-label">Route Name </label>
              <div >
                <input class="custom-form-control customHeight meager-e2e-edit-stu-route2" name="dropname" [value]="drop.routeName" disabled>
                <span (click)="deleteDropInfo(studentInfo.memberId,drop.routeId,drop.pickupId)">
                <i class="fa fa-close"></i>
                </span>
              </div>
            </div>
            <div class="col-md-4 form-group" *ngIf="!showDroppickupList">
              <label class="control-label">Pickup Point </label>
              <div title="">
                <input class="custom-form-control customHeight meager-e2e-edit-stu-pp2" name="point2name" [value]="drop.pickuppoint " disabled>
                <span (click)="emptyDropPoint(drop.routeId)">
                      <i class="fa fa-close"></i>
                </span>
              </div>
            </div>
          
            <div class="col-md-4 form-group" *ngIf="showDroppickupList">
                <label class="control-label">Pickup Point </label> 
                <select class="custom-form-control customHeight" id="pickupPoint" name="pickuppoint" [(ngModel)]="studentDropLocation" (change)="estimateEtaReturnRoute(studentDropLocation,studentInfo.memberId,studentInfo.memberName)">
                  <option value="adddropPoint">Add Pickup Point</option>
                  <option *ngFor="let point of studentReturnRoutePickup" value="{{point.pickupId}}">{{point.pickuppoint}}</option>
                </select>
                <div *ngIf="formError.drouppoint==true" class="text-danger">* Pickup point should not be empty</div>
            </div>


            <div class="col-md-4 form-group"  *ngIf="!showDroppickupList">
              <label class="control-label">Estimated Arrival Time</label>
              <input class="custom-form-control customHeight meager-e2e-edit-stu-pp2-exp-time " [(ngModel)]="drop.expectedTime" [ngModelOptions]="{standalone: true}" disabled>
            </div>
          </div>
          <div class="col-md-4 form-group"  *ngIf="showDroppickupList">
          <label class="control-label">Estimated Arrival Time</label>
          <input class="custom-form-control customHeight meager-e2e-edit-stu-pp2-exp-time" [(ngModel)]="routeReturnETA" [ngModelOptions]="{standalone: true}" disabled>
        </div>
         <!-- <button (click)="deleteDropInfo(studentInfo.memberId,drop.routeId,drop.pickupId)" type="button" class="btn btn-primary btn-xs pull-right mr5" data-toggle="tooltip" data-placement="bottom" title="Remove Route1" style="cursor:pointer;margin-right:15px;">
                <i class="fa fa-edit rotate mr5"  aria-hidden="true"></i>Manage
          </button>-->
        </div>
      </div>

      <div *ngIf="dropInfo == undefined || dropInfo?.length == 0">
        <div class="row">
          <div>
            <div class="col-md-4 form-group">
              <label class="control-label">Route Name</label>
              <!--<select class="custom-form-control" id="routeNameReturn" name="routeNameReturn" [(ngModel)]="selectedRouteReturn" (change)="populateRouteStoppagesReturn()">
                <option *ngFor="let route of schoolRoutes" value="{{route.routeId}}">{{route.routeName}}</option>
              </select>-->
              <single-search-select
                [searchType]="'onlyRoute'"
                [searchAPIUrl]="'getRoutes'"
                [otherParams]="'resetSearchUtility'"
                (emitUserSelections)="setSelectedRoute2($event)">
              </single-search-select>
            </div>
            <div class="col-md-4 form-group" *ngIf="!route2Info.pickuppoint"> 
              <label class="control-label">Pickup Point </label> 
               <select class="custom-form-control customHeight" id="pickupPoint" name="pickuppoint" [(ngModel)]="studentDropLocation" (change)="estimateEtaReturnRoute(studentDropLocation,studentInfo.memberId,studentInfo.memberName)">
                  <option *ngIf="selectedRouteReturn" value="adddropPoint">Add Drop Point</option>
                  <option *ngFor="let point of studentReturnRoutePickup" value="{{point.pickupId}}">{{point.pickuppoint}}</option>
              </select>
           <div *ngIf="formError.drouppoint==true" class="text-danger">* Pickup point should not be empty</div>
          </div>
            <div class="col-md-4 form-group" *ngIf="route2Info.pickuppoint">
              <label class="control-label">Pickup Point </label>
              <div title="{{route2Info.pickuppoint}}">
                <input class="custom-form-control customHeight" name="point2name" [(ngModel)]="route2Info.pickuppoint " disabled>
              </div>
              <div *ngIf="formError.drouppoint==true" class="text-danger">* Pickup point should not be empty</div>
            </div>
            <div class="col-md-4 form-group">
              <label class="control-label">Estimated Arrival Time</label>
              <input class="custom-form-control customHeight meager-e2e-edit-stu-pp2-exp-time" [(ngModel)]="routeReturnETA" [ngModelOptions]="{standalone: true}" disabled>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!--@End Student Route Information -->
    <section class="student-notif-status">
      <div class="row">
        <div class="col-md-6 pull-left">
          <div class="section-title">Student Notification Settings</div>
          <div class="section-title-border" style="width:9%"></div>
        </div>
        <div Class="col-md-6">


        </div>
      </div>
      <div class="row">
        <div class="form-group col-md-2">
          Get Notifications:
        </div>
        <div class="form-group col-md-2">
          <input type="checkbox" class="chk regular-checkbox meager-e2e-edit-stu-notification-email" [(ngModel)]="emailAlert" name="emailAlert">
          <label class="control-label">EMAIL</label>
        </div>
        <div class="form-group col-md-2">
          <input type="checkbox" class="chk regular-checkbox meager-e2e-edit-stu-notification-sms" [(ngModel)]="smsAlert" name="smsAlert">
          <label class="control-label">SMS</label>
        </div>
        <div class="form-group col-md-2">
          <input type="checkbox" class="chk regular-checkbox meager-e2e-edit-stu-notification-app" [(ngModel)]="appAlert" name="appAlert">
          <label class="control-label">APP</label>
        </div>
        <div class="form-group col-md-2">
          <input type="checkbox" class="chk regular-checkbox meager-e2e-edit-stu-notification-call" [(ngModel)]="callAlert" name="callAlert">
          <label class="control-label">CALL</label>
        </div>
      </div>
    </section>
    <div class="row form-actions">
      <!--<button style="color:#fff !important;" (click)="validator(studentInfo.memberId);editStudentRoute1Details(studentInfo.memberId);editStudentRoute2Details(studentInfo.memberId);" class="webform-submit button-primary btn btn-primary form-submit pull-right">Update</button>-->
     
      <button 
        *ngIf="dropInfo.length > 0 && pickupInfo.length > 0"
        style="color:#fff !important;" 
        (click)="validator(studentInfo.memberId);"

        class="webform-submit button-primary btn btn-primary form-submit pull-right meager-e2e-edit-stu-update-btn">Update
      </button>
    
      <button  
       *ngIf="dropInfo == undefined || dropInfo?.length == 0 || pickupInfo == undefined || pickupInfo?.length == 0 " 
        style="color:#fff !important;" 
        (click)="validator(studentInfo.memberId);"
             
        class="webform-submit button-primary btn btn-primary form-submit pull-right">Update
      </button>
    
    </div>
  </form>

   `,


})

export class studentEditFormDir implements OnInit, OnDestroy {
  private schoolRoutes: any[];
  private routePickUpPoints: any[];
  private selectedRoute: any;
  private selectedPickupPoint: any;
  private selectedPointETA: any
  private selectedRouteReturn: any = [];
  private studentDropLocation: any = [];
  private studentReturnRoutePickup: any = [];
  private routeReturnETA: any;
  private memberId: any;
  private memberName: any;
  private memberClass: any;
  private memberSection: any;
  private memberParentName: any;
  private memberContact: any;
  private memberSecContact: any;
  private studentList: any = {};
  public studentDetails: any = [];
  public studentInfo: any = [];
  public studentInformation: any = [];
  private locationInfo: any = [];
  public locationDetails: any = [];
  public ArrayDetails: any = [];
  public route1Info: any = [];
  public route2Info: any = [];
  public showDroppickupList: boolean;
  public updatedStudentInfo: any = [];
  public pickupInfo: any = [];
  public dropInfo: any = [];

  public emailAlert: boolean = true;
  public smsAlert: boolean;
  public appAlert: boolean;
  public callAlert: boolean;
  public drouppoint: boolean;
  public pickuppoint: boolean;
  public is_edit: boolean = true;

  public rifdChangeInput: any;
  public showpickupList: boolean;
  public linguastical: boolean = false;
  public classAndSessions: any = {};
  // public route1Info:any={};


  // public arrayValues;


  public formError: any = {};//validator instance
  public errorMesages: any = [];//validator instance

  constructor(private apiService: ApiService,
    private route: ActivatedRoute,
    private router: Router,
    private globalUtils: GlobalUtils,
    private globalService: globalService,
    private storage: StorageService
  ) {

    this.router.routeReuseStrategy.shouldReuseRoute = function () {
      return false;
    };
    this.router.events.subscribe((evt) => {
      if (evt instanceof NavigationEnd) {
        this.router.navigated = false;
      }
    });

    this.getRoutes();
  }

  private apiToken: any = localStorage.getItem("scbToken");
  private routeSub: Subscription;



  ngOnInit(): void {
    this.routeSub = this.route.params.subscribe((params: Params): void => {
      this.viewStudent(params['id']);
    });
    this.getClassAndSessions();
    let lang = this.storage.get('linguastic');
    if (lang) {
      this.linguastical = true;
    } else {
      this.linguastical = false;
    }
  }


  ngOnDestroy(): void {
    this.routeSub.unsubscribe();
  }

  MobileNumberValidator(event: any) {
    event.srcElement.value = event.srcElement.value.replace(/[^\d].+/, "");

    if (event.srcElement.value != '') {
      //  console.log('t',event.srcElement.value);
      if ((event.which < 48 || event.which > 57)) {
        event.preventDefault();
      }
    } else {
      // event.srcElement.value=0;
    }
  }

  methodCallDetector(memId: any) {
  }

  setValue(rfidInput: any) {
    this.rifdChangeInput = rfidInput;
  }




  getRoutes() {

    let parent: any = this;
    var routeObj = {
      data: {
        key: localStorage.getItem('scbToken')
      }
    };
    this.apiService.getRoutes(routeObj).then(response => {
      var parent: any = this;
      if (response) {
        this.schoolRoutes = response.response;
      };
    }).catch(error => { });
  }

  populateRouteStoppages() {
    this.routePickUpPoints = [];
    this.selectedPointETA = '';
    this.populatePickUpPoints(this.selectedRoute);
  }

  populateRouteStoppagesReturn(selectedRoute2: any) {
    // console.log(selectedRoute2);
    var stoppageObj = {
      data: {
        key: localStorage.getItem('scbToken'),
        "filter": {
          "routeId": this.selectedRouteReturn,
        }
      }
    }
    this.apiService.getStoppageDetails(stoppageObj).then(response => {
      this.studentReturnRoutePickup = response.response.pickuppoints;
    });
  }

  estimateEtaReturnRoute(studentDropLocation: any, studId: any, stuName: any) { ///console.log(studentDropLocation);

    let parent = this;
    if (studentDropLocation == 'adddropPoint') {
      swal({
        title: 'Are you sure?',
        text: 'Do you want to add new drop point to selected route',
        icon: 'warning',
        buttons: [
          'No, cancel it!',
          'Yes, I am sure!'
        ],
        dangerMode: false,
      }).then(function (isConfirm: any) {
        if (isConfirm) {
          parent.storage.set({
            'sudentID': studId,
            'studentName': stuName,
            'navigationFrom': 'editStudent',
            'navigateType': 'routeList',
            'selectedRouteId': parent.selectedRouteReturn
          });

          //localStorage.setItem();

          parent.router.navigate(['/school-routes-list']);
        }

      })
    } else {

      let point = this.studentReturnRoutePickup.filter(function (index: any, value: any) {
        return index.pickupId == studentDropLocation
      });
      //  console.log(studentDropLocation);
      this.routeReturnETA = point[0].expectedTime;
      //this.selectedPointETA = point[0].expectedTime;    
    }
    //console.log(this.dropInfo);
  }


  populatePickUpPoints(selectedRoute: any) {
    var stoppageObj = {
      data: {
        key: localStorage.getItem('scbToken'),
        "filter": {
          "routeId": this.selectedRoute,
        }
      }
    }
    this.apiService.getStoppageDetails(stoppageObj).then(response => {
      this.routePickUpPoints = response.response.pickuppoints;
      // console.log(this.routePickUpPoints);
    });
  }

  estimateEta(pointId: any, stuId: any, stuName: any) {
    // console.log(pointId);
    let parent = this;
    if (pointId == 'addpickupPoint') {
      swal({
        title: 'Are you sure?',
        text: 'Do you want to add new pickup point to selected route',
        icon: 'warning',
        buttons: [
          'No, cancel it!',
          'Yes, I am sure!'
        ],
        dangerMode: false,
      }).then(function (isConfirm: any) {
        if (isConfirm) {
          parent.storage.set({
            'sudentID': stuId,
            'studentName': stuName,
            'navigationFrom': 'editStudent',
            'navigateType': 'routeList',
            'selectedRouteId': parent.selectedRoute
          });

          //localStorage.setItem();

          parent.router.navigate(['/school-routes-list']);
        }
      })
    } else {
      let point = this.routePickUpPoints.filter(route => route.pickupId == pointId);
      this.selectedPointETA = point[0].expectedTime;
    }



    //console.log(this.selectedPointETA);
  }

  viewStudent(id: any) {

    this.apiService.viewMember({
      data: {
        key: localStorage.getItem('scbToken'),
        extra: {
          pageJump: 0
        },
        filter: {
          memberId: id,
          memberinfo: true
        }
      }
    }).then(response => {
      let result = response;
      if (result.status == "success") {
        //console.log('Resp',result);
        if (result.response != '' && result.response != undefined && result.response != null) {
          this.studentList = result.response; // here response is API output

          this.smsAlert = this.studentList[0].smsAlert;
          this.appAlert = this.studentList[0].appAlert;
          this.emailAlert = this.studentList[0].emailAlert;
          this.callAlert = this.studentList[0].callAlert;

          this.studentInfo = this.studentList[0];
          localStorage.setItem('previousRfid', this.studentInfo.rfid);
          this.locationInfo = this.studentInfo.memberInfo;

          // console.log(this.locationInfo);
          // Iterate and seperate pickup & drop lists

          if (this.studentInfo.hasOwnProperty('memberInfo') && this.locationInfo.length > 0 && this.locationInfo[0] != undefined && this.locationInfo[0] != '') {
            for (let info of this.locationInfo) {
              this.dropInfo = this.locationInfo.filter(function (index: any) { return index.routeType == 'drop' });
              this.pickupInfo = this.locationInfo.filter(function (index: any) { return index.routeType == 'pickup' });
              console.log(this.pickupInfo)
            }
          }


        }
      }
    })
      .catch(error => {
        console.log('No routes or pickup point Assigned', error)
      });


  }

  async validator(id: any) {

    let errorFlag = false; this.errorMesages = [];

    this.formError.StudentName = false;


    if (this.selectedRoute && this.selectedPickupPoint == undefined) {
      this.formError.pickuppoint = true;
      errorFlag = true;

    } else {
      this.formError.pickuppoint = false;
      // errorFlag =false;
    }
    //  console.log(this.selectedRouteReturn); 
    //console.log('Droup',this.studentDropLocation);
    if (this.selectedRouteReturn && this.studentDropLocation.length <= 0) { //console.log('&&&&&&');
      this.formError.drouppoint = true;
      errorFlag = true;

    } else {
      this.formError.drouppoint = false;

    }


    if (this.studentInfo.memberName == "" || this.studentInfo.memberName == undefined) {
      this.formError.StudentName = true;
      errorFlag = true; (this.errorMesages).push("Student name Should not be empty");
    }

    this.formError.StudentClass = false;
    if (this.studentInfo.memberClass == "" || this.studentInfo.memberClass == undefined) {
      this.formError.StudentClass = true;
      errorFlag = true; (this.errorMesages).push("Student Class Should not be empty");
    }

    this.formError.StudentSection = false;
    if (this.studentInfo.memberSection == "" || this.studentInfo.memberSection == undefined) {
      this.formError.StudentSection = true;
      errorFlag = true; (this.errorMesages).push("Student Section Should not be empty");
    }

    this.formError.ParentName = false;
    if (this.studentInfo.memberParentName == "" || this.studentInfo.memberParentName == undefined) {
      this.formError.ParentName = true;
      errorFlag = true; (this.errorMesages).push("Parent name Should not be empty");
    }

    this.formError.Contact = false;
    if (this.studentInfo.memberPrimaryMobileNo == "" || this.studentInfo.memberPrimaryMobileNo == undefined) {
      this.formError.Contact = true;
      errorFlag = true; (this.errorMesages).push("Invalid Number / Contact number is empty");
    }
    this.formError.Contact = false;
    this.studentInfo.memberPrimaryMobileNo.toString().length >= 10 ? (this.formError.Contact = false)
      : (this.formError.Contact = true,
        errorFlag = true,
        (this.errorMesages).push("Mobile number must be of 10 digit"))
    //  console.log(errorFlag, (this.studentInfo.memberPrimaryMobileNo).toString().length, this.studentInfo.memberPrimaryMobileNo)
    if (errorFlag == false) {
      // console.log(this.studentInfo);
      if ((this.studentInfo.smsAlert != this.smsAlert) || (this.studentInfo.appAlert != this.appAlert) ||
        (this.studentInfo.emailAlert != this.emailAlert) || (this.studentInfo.callAlert != this.callAlert)) {
        let stuRes = await this.editStudent(id);
        if (stuRes)
          this.router.navigate(['/school-students-list', {}]);
      } else {
        let stuRes = await this.editStudent(id).catch(err => { });
        let route1Res = await this.editStudentRoute1Details(id).catch(err => { });
        let route2Res = await this.editStudentRoute2Details(id).catch(err => { });
        if (stuRes)
          this.router.navigate(['/school-students-list', {}]);
      }
    }
  }

  // changeDetect(){
  //   console.log(this.studentInfo.rfid.length);
  //   //this.studentInfo.rfid =  (!this.studentInfo.rfid)?'clear':this.studentInfo.rfid;
  // }

  editStudent(id: any) {
    return new Promise((resolve, reject) => {
      try {
        const mobile2 = this.studentInfo.memberSecondaryMobileNo;
        const mobile1 = this.studentInfo.memberPrimaryMobileNo;
        const parentName = this.studentInfo.memberParentName;
        const section = this.studentInfo.memberSection;
        const clss = this.studentInfo.memberClass;
        const name = this.studentInfo.memberName;
        const name1 = this.studentInfo.memberName1;

        let apiHeader = {
          data: {
            key: localStorage.getItem('scbToken'),
            form: {
              memberId: id,
              MemberName: name ? name : 'clear',
              MemberName1: name1 ? name1 : 'clear',
              MemberClass: clss ? clss : 'clear',
              MemberSection: section ? section : 'clear',
              MemberParentName: parentName ? parentName : 'clear',
              MemberPrimaryMobileNo: mobile1 ? mobile1 : 'clear',
              MemberSecondaryMobileNo: mobile2 ? mobile2 : 'clear',
              smsAlert: this.smsAlert + "",
              appAlert: this.appAlert + "",
              emailAlert: this.emailAlert + "",
              callAlert: this.callAlert + "",
              // rfid: (!this.studentInfo.rfid)?'clear':this.studentInfo.rfid
              //rfid: this.studentInfo.rfid==''?'_null_':this.studentInfo.rfid
            }
          }
        }

        let rfidLengthCheck: string = localStorage.getItem('previousRfid');

        let n = rfidLengthCheck.localeCompare(this.rifdChangeInput);

        if (n == -1) {
          Reflect.set(apiHeader.data['form'], 'rfid', (!this.studentInfo.rfid) ? 'clear' : this.rifdChangeInput);
        } else {
          Reflect.set(apiHeader.data['form'], 'rfid', (!this.studentInfo.rfid) ? 'clear' : this.studentInfo.rfid);
        }
        this.apiService.editMember(apiHeader).then(response => {
          let result = response;
          if (result.status == "success") {
            swal("Success", "Student Information Updated Successfully", "success", {
              timer: 2000
            });    // let localArray = JSON.stringify(arrayValues2['routes']);


            // this.router.navigate(['/school-students-list', {}]);
            resolve({ status: 'success' });
          } else {
            reject(result);
            swal("Failure", this.globalUtils.getErrorMsg(response.ec), "error");
          }
        })
          .catch(error => {
            reject(error);
            console.log('Error while editing member', error);
            swal("Failure", "Update Failed", "warning");
          });
      } catch (err) {
        console.log(err);
        reject(err);
      }
    });
    // Here we are unsing the api for assigning a route to the member  API is seperated as 2 parts//

  }

  addStudentRoute(studentID: any, studentName: any) {


    this.storage.set({
      'sudentID': studentID,
      'studentName': studentName,
      'navigationFrom': 'editStudent',
      'navigateType': ''
    });

    //localStorage.setItem();

    this.router.navigate(['/school-routes-list']);

  }

  showStudentRouteList(studentID: any, studentName: any) {
    this.storage.set({
      'sudentID': studentID,
      'studentName': studentName,
      'navigationFrom': 'editStudent',
      'navigateType': 'routeList'
    });

    //localStorage.setItem();

    this.router.navigate(['/school-routes-list']);
  }

  editStudentRoute1Details(id: any) { //console.log('Pickup Info',this.pickupInfo);
    return new Promise(async (resolve, reject) => {
      try {
        let selectedRoute = '';
        let selectedPickupPoint = '';
        // console.log(id);
        if (this.selectedRoute) {
          selectedRoute = this.selectedRoute ? this.selectedRoute : this.pickupInfo[0].routeId;
          selectedPickupPoint = this.selectedPickupPoint ? this.selectedPickupPoint : this.pickupInfo[0].pickupId;
        } else if (this.pickupInfo.length > 0) {
          selectedRoute = this.pickupInfo[0].routeId;
          selectedPickupPoint = this.pickupInfo[0].pickupId;
        }
        //console.log(this.pickupInfo);
        let arrayValues: any = [];

        arrayValues['routes'] = {};
        arrayValues['routes'][selectedRoute] = {};
        arrayValues['routes'][selectedRoute][selectedPickupPoint] = [id];

        let localArray = JSON.stringify(arrayValues['routes']);

        if (this.pickupInfo.length > 0) {
          var memObj = {
            data: {
              key: localStorage.getItem('scbToken'),
              form: {
                routeId: selectedRoute,
                pickupId: this.pickupInfo[0].pickupId,
                memberId: [id]
              }
            }
          };

          await this.apiService.deleteAssignedRoute(memObj).then(response => {
            if (response) {

            }
          }).catch(error => { });
        }

        this.apiService.AssignMember({
          data: {
            key: localStorage.getItem('scbToken'),
            form: {
              routes: arrayValues['routes']
            }
          }
        }).then(response => {
          let result = response;
          if (result.status == "success") {
            resolve({ status: 'success' });
          } else {
            reject(result);
          }
        })

          .catch(error => {
            reject(error);
            console.log('Error while editing member', error);
            swal("Failure", "Route1 Update Failed", "warning");
          });
      } catch (err) {
        console.log(err);
        reject(err);
      }
    });

  }

  editStudentRoute2Details(id: any) { // console.log(this.selectedRouteReturn);
    return new Promise(async (resolve, reject) => {
      try {
        let selectedRoute2 = '';
        let selectedPickupPoint2 = '';

        if (this.selectedRouteReturn) {

          selectedRoute2 = this.selectedRouteReturn;
          selectedPickupPoint2 = this.studentDropLocation

        } else if (this.dropInfo.length > 0) {

          selectedRoute2 = this.dropInfo[0].routeId;
          selectedPickupPoint2 = this.dropInfo[0].pickupId;
        }


        // console.log(selectedRoute2);
        // console.log(selectedPickupPoint2);
        let arrayValues2: any = [];

        arrayValues2['routes'] = {};
        arrayValues2['routes'][selectedRoute2] = {};
        arrayValues2['routes'][selectedRoute2][selectedPickupPoint2] = [id];

        if (this.dropInfo.length > 0) {

          var memObj = {
            data: {
              key: localStorage.getItem('scbToken'),
              form: {
                routeId: selectedRoute2,
                pickupId: this.dropInfo[0].pickupId,
                memberId: [id]
              }
            }
          };

          await this.apiService.deleteAssignedRoute(memObj).then(response => {
            if (response) {
              // console.log(response.response);
            }
          }).catch(error => { });
        }

        this.apiService.AssignMember({
          data: {
            key: localStorage.getItem('scbToken'),
            form: {
              routes: arrayValues2['routes']
            }
          }
        }).then(response => {
          let result = response;
          if (result.status == "success") {
            resolve({ status: 'success' });
          } else {
            reject(result);
          }
        })

          .catch(error => {
            reject(error);
            console.log('Error while editing member', error);
            swal("Failure", "Route 2 Update Failed", "warning");
          });
      } catch (err) {
        console.log(err);
        reject(err);
      }
    });

  }




  deletepickupInfo(memid: any, routeId: any, pickupId: any) {
    let parent = this;
    let selectedRoute1 = this.route1Info.routeId;
    let selectedPickupPoint1 = this.route1Info.pickupId;
    var memObj = {
      data: {
        key: localStorage.getItem('scbToken'),
        form: {
          routeId: routeId,
          pickupId: pickupId,
          memberId: [memid]
        }
      }
    }; //console.log(memObj);
    swal({
      title: 'Are you sure?',
      text: 'This Route Will be removed for Student',
      icon: 'warning',
      buttons: [
        'No, cancel it!',
        'Yes, I am sure!'
      ],
      dangerMode: true,
    }).then(function (isConfirm: any) {
      if (isConfirm) {
        swal({
          title: 'Success!',
          text: 'Route has been updated',
          icon: 'success'
        }).then(function () {
          parent.apiService.deleteAssignedRoute(memObj).then(response => {
            if (response) {
              setTimeout(() => {
                parent.router.navigate(['/edit-student-details', memid]);
              }, 1000);
            }
          }).catch(error => { });
        });
      } else {
        swal('Cancelled', 'Action aborted :)', 'error');
      }
    });
  }




  deleteDropInfo(memId: any, routeId: any, pickupId: any) {

    const parent = this;
    let selectedRoute2 = this.route2Info.routeId;
    let selectedPickupPoint2 = this.route2Info.pickupId;
    let memObj = {
      data: {
        key: localStorage.getItem('scbToken'),
        form: {
          routeId: routeId,
          pickupId: pickupId,
          memberId: [memId]
        }
      }
    };
    swal({
      title: 'Are you sure?',
      text: 'This Route Will be removed for Student',
      icon: 'warning',
      buttons: [
        'No, cancel it!',
        'Yes, I am sure!'
      ],
      dangerMode: true,
    }).then(function (isConfirm: any) {
      if (isConfirm) {
        swal({
          title: 'Success!',
          text: 'Route has been Removed',
          icon: 'success'
        }).then(function () {
          parent.apiService.deleteAssignedRoute(memObj).then(response => {
            if (response) {
              setTimeout(() => {
                parent.router.navigate(['/edit-student-details', memId]);
              }, 1000);
            }
          }).catch(error => { });
        });
      } else {
        swal('Cancelled', 'Action aborted :)', 'error');
      }
    });
  }

  public checkrouteType: string;

  setSelectedRoute(selectedRoute: any) {
    // console.log(selectedRoute + '----' + this.selectedPickupPoint);
    if (selectedRoute.length > 0) {
      this.checkrouteType = selectedRoute[0].routeType;
      this.selectedRoute = selectedRoute[0].routeId;  //carrying forward  previously declared global var strategy.
      this.populatePickUpPoints(this.selectedRoute);
    } else {
      this.selectedRoute = '';
    }
  }

  setSelectedRoute2(selectedRoute2: any) {

    if (selectedRoute2.length > 0) {
      if (((selectedRoute2[0] && selectedRoute2[0].routeType) === (this.pickupInfo[0] && this.pickupInfo[0].routeType)) || (selectedRoute2[0] && selectedRoute2[0].routeType === this.checkrouteType)) {

        swal({
          title: 'Warning',
          text: "Selected route type same as pick route type..!",
          icon: 'error'
        });
      } else {
        this.selectedRouteReturn = selectedRoute2[0].routeId;
        this.populateRouteStoppagesReturn(this.selectedRouteReturn);
      }

    } else {
      this.selectedRouteReturn = '';
    }
  }

  emptyPickupPoint(routeId: any) {
    this.showpickupList = true;
    this.selectedRoute = routeId;
    this.populatePickUpPoints(routeId);
    this.populateRouteStoppages();

  }

  emptyDropPoint(routeId: any) {
    this.showDroppickupList = true;
    //   this.studentDropLocation=routeId;
    this.selectedRouteReturn = routeId;
    this.populateRouteStoppagesReturn(routeId);
    this.populateRouteStoppages();

  }
  getClassAndSessions() {

    this.classAndSessions = this.storage.get('classandsection');

  }

}
