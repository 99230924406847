import { Component, Input, Output } from "@angular/core";
import { async } from "@angular/core/testing";
import { DatePipe } from '@angular/common'
import { UtilityServices } from './../../../services/utility.service';
import { COM } from './../../../services/com.services';
import { StorageService } from './../../../services/storage.service';
import { ApiService } from './../../../services/ApiService';


declare var Highcharts: any;
@Component({
    selector: 'g10-bubble-chart',
    templateUrl: './bubble-chart.component.html',
    styleUrls: [`./bubble-chart.component.css`]
})

export class BubbleChartComponent {

    @Input() _data: any;
    @Input() _pickPoint: any;
    @Input() _latLng:any;
    @Input() _routeName:any;
    @Input() _loaderMSG: any;
    
    // @Input() 
    public finalChartObj: any = {};
    public searchValue: any;
    public update:boolean=false;
    public loaderStage: string='';
    public showLoader:boolean;
    public chart_markerColor = {
        good: {
            symbol: 'circle',
            fillColor: '#a1b126',
            lineWidth: 5,
            lineColor: '#a1b126'
        },
        avg: {
            symbol: 'circle',
            fillColor: '#ef870d',
            lineWidth: 5,
            lineColor: '#ef870d'

        },
        bad: {
            symbol: 'circle',
            fillColor: '#e03e03',
            lineWidth: 5,
            lineColor: '#e03e03'

        }
    }
    public suggestionData: any[];
    public objkeys =Object.keys;
    public token = localStorage.getItem('scbToken');
    public routeNameId={};
    constructor(
        public datepipe: DatePipe,
        private utilityService: UtilityServices,
        private com: COM,
        private storage: StorageService,
        private api: ApiService
    ) {
        
        this.com.comAction.subscribe(async(res: any) => {
            if (res.type == 'searchBubble') {
                 let suggestionData=[];
                 let routeFilter:any = await this.getRouteInfo(res.data); 
                 let getMobileInfo: any = await this.getMobileNumber(res.data); 
                 let getPickupPoint: any = await this.getPickupDetails(res.data);
                //  console.log('getPickupPoint',getPickupPoint)
                 for(let r of routeFilter){
                    suggestionData.push(r);
                 }  
                 for(let m of getMobileInfo){
                    suggestionData.push(m);
                 } 
                for(let p of getPickupPoint){
                    suggestionData.push(p);
                }
                
                //  console.log('suggestionData--',suggestionData)
                 this.emitData(suggestionData);
            }
            if(res.type=='searchSelected'){
                if(res.searchType!="pickup"){
                    this.fliterRouteData(res.data);
              }               
            }
        })
    }

    ngOnInit() {
        // console.log('this._data',this._data)
        this.getRouteWiseData(this._data.data);
       
    }
    ngDoCheck() {
        // if(this._loaderMSG!=false)
        this.loaderStage = this._loaderMSG;
        // else this.showLoader = this._loaderMSG;
    
    }
    // ngAfterViewChecked()
    fliterRouteData(key:any){
        let result ={};
                   this.searchValue = key;
                   if(result[this.searchValue]==undefined) result[this.searchValue]={};
                   result[this.searchValue] =  this.finalChartObj && this.finalChartObj[this.searchValue] 
                   if(result != undefined){
                       let dom:any = document.getElementById('bubble-chart');
                       
                       if(dom!=null&& dom!='') dom.innerHTML ='';
                        this.update= true;
                        setTimeout(()=>{                              
                             this.loadBubbleChart(result); 
                        },200);
                       
                   }
    }
    emitData(data:any){
        this.com.comAction.next({
            type:'searchSuggestion',
            data:data
        })
    }
    getRouteInfo(str: String){
        return new Promise((res,rej)=>{
            try{
            let resObj=[];
            for(let item of this.objkeys(this.finalChartObj)){
                 if(item.toLowerCase()===str.toLowerCase()){
                    let infoData = {
                        rname: item,
                        temp:'temp',
                        info: 'Route Name',
                        icon: 'fa fa-bus"',
                        type:'route'
                    };
                    resObj.push(infoData);      
                }
            }
             res(resObj.length>0? resObj:[]);
        }catch(e){
                rej({});
        }
            
        });
    }
    getPickupDetails(str:any){
        return new Promise((res,rej)=>{
            try{
                let filterData:any=[];
               
                let ids = this.objkeys(this._pickPoint)
                ids.filter((a,i)=>{
                    let chkStr =this._pickPoint[a].toLowerCase().indexOf(str.toLowerCase());
                    let pickupInfo = this._data.obj && this._data.obj[a];
                    if(pickupInfo!=undefined){
                    let splitStr =  pickupInfo.split('#$#');
                    if(chkStr != -1) filterData.push({                       
                            icon: 'fa fa-map-pin',
                            type:'pickup',
                            pickupID:a,
                            pickup: this._pickPoint[a],
                            routeName:  splitStr[4]
                        });
                    }
                    if(ids.length-1 == i) res(filterData);
                    });
               
            }catch(e){
                console.log('error',e);
                rej([])
            }
        });
    }
    checkIsNumber(num:any){
        let m = (''+num).length;
        return m>9 ? Number(num) : 0;
    }
    getMobileNumber(str: string){
        return new Promise(async(res,rej)=>{
            try{
                let chkMobileNumber = this.checkIsNumber(str);
                let apiData={
                    data:{
                        key: this.token
                    }                   
                }
               
                if(chkMobileNumber==0){
                     let chkName:any = await this.checkName(str);
                     res(chkMobileNumber);
                }else{               
                    apiData['data']['filter']={
                        mobileNo:chkMobileNumber,
                        memberInfo: true,
                        memberinfo: true
                    }
                    // console.log('sessionTime',sessionTime,'endTM',endTM)
                    this.api.viewMember(apiData).then(async(response:any)=>{
                       if(response.status == 'success'){
                                let data = response['response'];
                                let result = await this.processData(data);
                            res(result);
                       }else{
                            res([]);
                       }
                    }).catch((e)=>{
                        res([])
                    });
                }
               
              
            }catch(e){
                rej([]);
            }
        });
    }  
    processData(data:any){
        return new Promise((res,rej)=>{
            let result:any=[];
            let d:any = new Date();    
            let sessionTime = this.storage.get('analyticsFilterSession');
            let endTM = sessionTime.split('-')[1];          
            let dateTMS = (tms:any) => {
                let date =
                  d.getFullYear() + "/" + parseInt(d.getMonth() + 1) + "/" + d.getDate();
                return new Date(date + " " + tms).getTime() / 1000;
              };
              console.log('data',data)
            if(data){ 
                for(let d of data){
                    if(d && d['memberInfo']){
                        for(let m of d['memberInfo']){
                        if(dateTMS(m.expectedTime)<=dateTMS(endTM)){
                        let infoData = {
                        memberName:d.memberName,
                        rname: m.routeName,
                        pickup:m.pickuppoint,
                        icon: 'fa fa-user',
                        type:'pickupPoint'
                        }
                        result.push(infoData);
                        }   
                        }
                    }
                res(result)
                }
            }else{
                 res([]);
            }
        })
    }
    checkName(str:any){
          return new Promise(async(res,rej)=>{
            let apiData={
                data:{
                    key: this.token
                }                
            };
            apiData['data']['filter']={
                MemberName: str,
                memberInfo: true,
                memberinfo: true
            }
            let response= await this.api.viewMember(apiData);
            let data = response['response'];
            let result = await this.processData(data);
            res(result);
          });
    } 
    formatAMPM(date:any) {
        var hours = date.getHours();
        var minutes = date.getMinutes();
          var ampm = hours >= 12 ? 'pm' : 'am';
        hours = hours % 12;
        hours = hours ? hours : 12; // the hour '0' should be '12'
        minutes = minutes < 10 ? '0'+minutes : minutes;
        var strTime = hours + ':' + minutes + ' ' + ampm;
        return strTime;
      }
    getAvgTimeBasedData(obj: any) {
        return new Promise((res, rej) => {
            let objectCreation = {};
            let info:any = {};
            let objArray = Object.keys(obj);
            let tmsToReadble = ((tms: number) => {
                let date = new Date(tms);
                return date.getHours() + ':' + date.getMinutes() + ':' + date.getSeconds();
            });
            let dateTMS = (tms:any,Rtms:any) => { 
                let d:any =new Date(Number(Rtms)); 
                let date =
                d.getFullYear() + "/" + parseInt(d.getMonth() + 1) + "/" + d.getDate();
                return new Date(date + " " + tms).getTime();
              };
            for (let date in obj) {
                Object.keys(obj[date]).forEach((item, i) => {
                    let split = obj[date][item].split('#$#');
                    let rName = split[split.length - 1];
                    let reachedTime = split[0];
                    let callMadeTime = split[2];
                    var callMadeMSDate = new Date(parseInt(callMadeTime, 10));
                    let callMadeDateFormatted = this.datepipe.transform(callMadeMSDate.toString(), 'HH:mm:ss');
                    if (objectCreation[rName] == undefined) objectCreation[rName] = {};
                    if (objectCreation[rName][item] == undefined) objectCreation[rName][item] = [];
                    if (objectCreation[rName][item]['diff'] == undefined) objectCreation[rName][item]['diff'] = [];
                    if (objectCreation[rName][item]['time'] == undefined) objectCreation[rName][item]['time'] = [];
                    if (info[rName] == undefined) info[rName] = {};
                    let etaTMS = dateTMS(split[1].slice(0, 2) + ':' + split[1].slice(2, 4),reachedTime)
                    let timeDiff = Math.abs((Number(etaTMS) / 1000) - (Number(reachedTime) / 1000));
                    timeDiff = Math.floor(Math.floor(timeDiff) / 60);                    
                    let location = this._latLng[item];
                    let exSplit = [split[1].slice(0,2),split[1].slice(2,4)];
                    var ampm = exSplit[0] >= 12 ? 'pm' : 'am';
                    var mints = exSplit[1] < 10 ? exSplit[1] : exSplit[1];
                    let tempStatus='';
                    if (timeDiff >= -2 && timeDiff <= 2) tempStatus='good';
                    if ((timeDiff > -5 && timeDiff <= -3) || (timeDiff >= 3 && timeDiff < 5)) tempStatus='avg';
                    if (timeDiff <= -5 || timeDiff >= 5) tempStatus='bad';
                    let tempTMD:any = timeDiff.toString();
                    if(location!=undefined){
                        objectCreation[rName][item]['time'].push(callMadeDateFormatted);
                    objectCreation[rName][item]['diff'].push(timeDiff);
                        info[rName][item] = {
                            "RouteName": rName,
                            "pickupID": item,
                            "date": callMadeTime,
                            "pickupName": this._pickPoint[item]?this._pickPoint[item]:'',
                            "callMade": tmsToReadble(Number(callMadeTime)),
                            "reachedTM": tmsToReadble(Number(reachedTime)),
                            "expectedTM": split[1],
                            "diff": timeDiff,
                            "time_difference": timeDiff +' min',
                            "ata": this.formatAMPM(new Date(Number(reachedTime))),
                            "eta": exSplit[0]+':'+mints +' '+ampm,
                            "performance": tempStatus,
                            "isPositiveValue": tempTMD<0?false:true,
                            "timestamp_difference_number":timeDiff,
                            "routeID": split[3]
                        };
                        let loc = location.split(',');
                        info[rName][item]['location']={
                            lat:loc[0],
                            lng:loc[1]
                        }
                        
                    }
                })
                let position = objArray.indexOf(date);
                if (objArray.length - 1 == position) {
                  this.storage.set({'chartRouteInfo':info});
                    let arraySum = {};
                    for (let route in objectCreation) {
                        if (arraySum[route] == undefined) arraySum[route] = {};
                        for (let pp in objectCreation[route]) {
                            let good = 0, avg = 0, bad = 0;
                            let datas: any = objectCreation[route][pp];
                            let avgTime = this.utilityService.getAverageTime(datas['time']);
                            let findSum = this.utilityService.findSum(datas['diff']);
                            let timeDiff = Math.round(findSum / datas['diff'].length);
                            if (arraySum[route][pp] == undefined) arraySum[route][pp] = {};
                            if (arraySum[route][pp][avgTime] == undefined) arraySum[route][pp][avgTime] = {};
                            //   arraySum[route][avgTime].push(timeDiff);
                            if (arraySum[route][pp][avgTime]['good'] == undefined) arraySum[route][pp][avgTime]['good'] = 0;
                            if (arraySum[route][pp][avgTime]['avg'] == undefined) arraySum[route][pp][avgTime]['avg'] = 0;
                            if (arraySum[route][pp][avgTime]['bad'] == undefined) arraySum[route][pp][avgTime]['bad'] = 0;

                            if (timeDiff >= -2 && timeDiff <= 2) good++;
                            if ((timeDiff > -5 && timeDiff <= -3) || (timeDiff >= 3 && timeDiff < 5)) avg++;
                            if (timeDiff <= -5 || timeDiff >= 5) bad++;
                            if (bad == 1) {
                                arraySum[route][pp][avgTime]['bad'] += 1;
                                info[route][pp]['color'] = 'bad';
                            }
                            if (good == 1) {
                                arraySum[route][pp][avgTime]['good'] += 1;
                                info[route][pp]['color'] = 'good'
                            }
                            if (avg == 1) {
                                arraySum[route][pp][avgTime]['avg'] += 1;
                                info[route][pp]['color'] = 'avg'
                            }
                        }
                    }
                    res({ "perform": arraySum, "info": info });
                }
            }
        });
    }
    pad(num:number){
        return num>9 ? num : '0'+num; 
    }
    timeStampToHumanRead(TMS: number) {
        let date:any = new Date(TMS);
        return date.getFullYear()+'-'+ this.pad(parseInt(date.getMonth() + 1)) +'-'+ this.pad(date.getDate()) +' '+date.getHours() + ':' + date.getMinutes() + ':' + date.getSeconds();
      }
   async singlePointValues(data:any,routeInfo:any){
    //    console.log('data', data, 'routeInfo',routeInfo)
    this.loaderStage='Waiting for server response..!';
                 this.showLoader= true;           
        let routeID = data.routeID;
        let date = this.timeStampToHumanRead(Number(data.date))
        let vehIdObj = {
            data:{
                filter:{ routeId: routeID },
                form: {
                    fromDate: date.split(' ')[0]
                },
                key:this.token
            }
        } 
        let sessionTime = this.storage.get('analyticsFilterSession').split('-');
        let endTM = sessionTime; 
        
        this.api.getCallAlertLog(vehIdObj).then((res:any)=>{
            this.loaderStage='Getting vehicle details...!';
               let keys = Object.keys(res['response']);
               let vehID = res['response'][keys[0]][0].vehId;
               this.com.comAction.next({
                type:'selectedSinglePickupPoint',
                data: data,
                changeComp: true,
                singleRoute:routeInfo,
                vehID:vehID
              });
        });
              
       
  
    }

    async  getRouteWiseData(obj: any) {
            
        let response = await this.getAvgTimeBasedData(obj);
        let objKeys = Object.keys;
        let objValues = Object['values'];
        let info = response['info'];
        let getRoutePercentage: any = await this.utilityService.getRouteBasedPercentage(response['perform']);
        let routes = objKeys(getRoutePercentage);
        let ind = 0;
        for (let r of getRoutePercentage) {
            
            let k = objKeys(r)[0];
            let values = objValues(info[k]);
            this.finalChartObj[k] = values;
            if (ind == routes.length - 1) {
                if(this._routeName !=undefined && this._routeName !=null && this._routeName !=''){
                    let temp ={};
                    
                    let dom:any = document.getElementById('bubble-chart');
                    dom.innerHTML ='';
                    this.update= true;
                    temp[this._routeName] = this.finalChartObj[this._routeName];
                    setTimeout(()=>{ this.loadBubbleChart(temp); },200);
                }else{
                    this.loadBubbleChart(this.finalChartObj)
                }
                
            }
            ind++;
        }
    }

    createChartData(inlineData: any, keys: any) {
        return new Promise((res, rej) => {

            let sorted = inlineData;
            let seriesData: any = [];
            let maxItem = 0;
            for (let i = 0; i < keys.length; i++) {
                let item = keys[i];
                let temp = {};
                temp['name'] = item;
                temp['showInLegend'] = false;
                // temp['data'] = 
                let data = sorted[item];
                let dataFramer = [];
                for (let j = 0; j < data.length; j++) {
                    let temp = {};
                    temp['x'] = j;
                    temp['y'] = i;
                    temp['myData'] = {
                        data: data[j],
                        routeName: item
                    };
                    let colorCode = data[j]['color'];
                    temp['marker'] = this.chart_markerColor[colorCode];
                    dataFramer.push(temp);
                }
                temp['data'] = dataFramer;
                // sorted[item].length ? sorted[item].map((i, index) => index) : [];
                seriesData.push(temp);
            }
            res(seriesData);
        })
    }
    async loadBubbleChart(inlineData: any) {
// console.log('inlineData',inlineData);11111q
        let keys = Object.keys(inlineData);
        let seriesData:any = await this.createChartData(inlineData, keys);
        let parent = this;
        Highcharts.setOptions({
            colors: ['#D3D3D3']
        });
        Highcharts.chart('bubble-chart', {

            title: {
                text: ''
            },
            yAxis: {
                reversed: true,
                title: {
                    text: 'RouteName'
                },
                allowDecimals: false,
                gridLineColor: 'transparent',
                max: keys.length - 1,
                labels: {
                    formatter: function () {
                        let val = ''

                        if (seriesData[this.value]['name'] != undefined) val = seriesData[this.value]['name']
                        return val;
                        // return '<a href="' + categoryLinks[this.value] + '">' +
                        //     this.value + '</a>';
                    }
                },

            },
            tooltip: {
                formatter: function () {
                    let d = this.point['myData']['data'];
                    let info = '<div style="width:20px;word-wrap:break-all">';
                    if (d.RouteName != undefined) info += '<strong>Route Name: </strong>' + d.RouteName + '<br>';
                    if (d.callMade != undefined) info += '<strong>Call Time: </strong>' + d.callMade + '<br>';
                    if (d.diff != undefined) info += '<strong>Time Difference: </strong>' + d.diff + '<br>';
                    if (d.reachedTM != undefined) info += '<strong>Expected Time: </strong>' + d.reachedTM + '<br>';
                    if (d.pickupName != undefined) info += '<strong>Pickup Name: </strong>' + d.pickupName + '<br>';
                    info += '</div>';
                    return info;
                },
                shared: false
            },
            xAxis: {
                visible: false,
                accessibility: {
                    rangeDescription: 'Range: 2010 to 2017'
                }
            },


            plotOptions: {
                line: {
                    dataLabels: {
                        enabled: true,
                        verticalAlign: 'bottom',
                        align: 'center',
                        y: 30,
                        color: 'silver',
                        style: {
                            fontSize: '9px',
                            fontWeight: 'lighter'
                        },
                        formatter: function () {

                            if (this.point && this.point.myData && this.point.myData.data && this.point.myData.data.callMade) {

                                let t = this.point.myData.data.callMade.split(':');
                                return t[0] + ':' + t[1]
                            } else {
                                return this.y;

                            }
                        }
                    }
                },
                series: {
                    states: {
                        hover: {
                            enabled: false
                        }
                    },
                    lineWidth: 1,
                    // marker: this.chart_markerColor['blue'],
                    label: {
                        connectorAllowed: false
                    },
                    pointStart: 0,
                    point: {
                        events: {
                          click: function () {
                            // let seriesData1:any = seriesData.reverse();
                            let rName = this.myData['data']['RouteName'];
                            // console.log('seriesData',this.x,'this.y',this.y,seriesData1[this.x],seriesData1)
                            // console.log('labels', this.myData['data'])
                            //['data'][this.x]['myData']['data']  
                            let singleRoute = inlineData[rName];
                            // console.log(this.x,"this.myData['data']",this.myData['data'], 'singleRoute',singleRoute,);
                            parent.singlePointValues(this.myData['data'],singleRoute)
                        }
                           
                        }
                      }
                }
            },

            series: seriesData,

            responsive: {
                rules: [{
                    condition: {
                        maxWidth: 500
                    },
                    chartOptions: {
                        legend: {
                            layout: 'horizontal',
                            align: 'center',
                            verticalAlign: 'bottom'
                        }
                    }
                }]
            }

        });

    }
}