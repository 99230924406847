import { Component, Input, Output } from '@angular/core';

@Component({
    selector: 'memberwiserfid',
    template: `
    <ng-container *ngIf="routeWiseMemberInfo.length"> 
    <div class="panel panel-default">
    <div class="panel-heading">
        <div class="row mb5">
            <div class="reportHeading text-center mb5">Member RFID Report</div>
        </div>
        <div class="row mb5">
            <div class="col-md-6 text-left">
                <label class="control-label">Start Date : </label>
                <span class="reportStartDate">{{intermediateObj.reportStartTime}}</span>
            </div>
            <div class="col-md-6 text-right">
                <label class="control-label">End Date : </label>
                <span class="reportEndDate">{{intermediateObj.reportEndTime}}</span>
            </div>
        </div>
        <div class="row">
            <div class="col-md-6   text-left">
                <label class="control-label">Member Name : </label>
                <span class="reportDetails routeName">{{intermediateObj.memberName}}</span>
            </div>
            <div class="col-md-6 reportExcelDownloadLink  text-right mb5" *ngIf="routeWiseMemberInfo?.length">
                <a href="{{memberReportExcelDownloadLink}}" class="reportExcelDownloadLink" target="_blank">Download excel Report</a>
            </div>
        </div>
        <div class="row mb5">
            <div class="col-md-6 text-left">
                <label class="control-label">Member Section : </label>
                <span class="reportDetails routeName">{{intermediateObj.memberSection}}</span>
            </div>
            <div class="col-md-6 text-right">
                <label class="control-label">Member RFID : </label>
                <span class="reportDetails routeName">{{intermediateObj.memberRFID}}</span>
            </div>
        </div>
        <div class="row">
            <div class="col-md-6   text-left">
                <label class="control-label">Member Class : </label>
                <span class="reportDetails routeName">{{intermediateObj.memberClass}}</span>
            </div>
            <div class="col-md-6   text-right">
                <label class="control-label">Member ID : </label>
                <span class="reportDetails routeName">{{intermediateObj.memberID}}</span>
            </div>
        </div>
        <div class="row">
            <div class="col-md-6   text-left">
                <label class="control-label">Member Route : </label>
                <span class="reportDetails routeName">{{intermediateObj.memberRoute}}</span>
            </div>
        </div>
        </div>
        <div class="panel-body memberRfidPanelBody" style="max-height:444px">
            <div class="row">
                <div class="col-md-12 table-responsive">
                    <table class="table table-stripped table-bordered" *ngIf="!noRouteRfidRecords">
                        <thead>
                            <tr>
                                <th class="text-center v-center slNumber-report">#</th>
                                <th class="text-center routecolumnClass v-center">Route</th>
                                <th class="text-center v-center">Location</th>
                                <th class="text-center v-center">Swipe Timing / Vehicle Name</th>
                            </tr>
                        </thead>
                        <tbody >
                            <tr *ngFor="let memberInfo of routeWiseMemberInfo;let i = index">
                                <td class="text-right v-center">{{i+1}}</td>
                                <td class="text-left text-capitalize v-center">{{memberInfo.routeName===null?memberInfo.routeType:memberInfo.routeName}}</td>
                                <td class="text-left v-center">{{memberInfo.rfidswipNearLocation}}</td>
                                <td class="text-left v-center">
                                    <div>{{memberInfo.rfidSwipTime }}</div>
                                    <div>{{memberInfo.vehicleName}}</div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
             
                </div>
            </div>
        </div>


</div>
</ng-container>
<ng-container *ngIf="!routeWiseMemberInfo.length">
<div class="panel panel-default">
<div class="panel-heading">
<div class="row mb5">
    <div class="reportHeading text-center mb5">Member RFID Report</div>
</div>
</div>
<div class="panel-body">
<h4 class="animated pulse text-center" style="animation-duration: 1s;color:#999FBF"><i class="fa fa-frown-o"></i>&nbsp;<span class="meager-e2e-member-rfid-report-no-records">No records found</span></h4>
</div>
</div>
</ng-container>


    `,
    styleUrls: ["./rfidreport.css"]
})

export class MemberwiserfidComponent {

   

    public routeWiseMemberInfo:any=[];
    public intermediateObj:any = {};
    public memberReportExcelDownloadLink:any ={}

    @Input('memberwiserfid')
    set memberwiserfid(val:any){
        this.routeWiseMemberInfo = val;
    }
    @Input('headerData')
    set headerData(val:any){
        this.intermediateObj =val;
    }

    @Input('excel')
    set excel(val:any){
        this.memberReportExcelDownloadLink = val;
    }
}