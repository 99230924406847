import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'timepicker-custom-widget',
  template : `
    <ng-container  *ngIf="format=='hh:mm'">  
    <input [(ngModel)]="hh" 
    placeholder="HH" 
    (keyup)="hh=$event.target.value;action()" /> : 
    <input 
    [(ngModel)]="mm" 
    placeholder="MM"
    (keyup)="mm=$event.target.value;action()" /> 
    </ng-container>
  `,
  styles: [`
   input{
    width: 18% !important;
     padding: 5px; border: 1px solid silver;
     margin: 3px;
   }
  `]
})
export class TimepickerComponent  {
  hh = ''; mm = ''; ss = '';
  @Input() format:string; 
  @Input() type:number
  @Output() res: EventEmitter<any> = new EventEmitter();
  action(){
     this.validate();
      
    if(this.format=="hh:mm"){
      if(this.hh!='' && this.mm!=''){
      this.res.emit({
        'hh:mm': this.hh+':'+this.mm,
        'hh.mm': +(this.hh+'.'+this.mm)
      })
      }
    }
  }

  validate(){
      this.hh = this.hh.substring(0, 2).replace('.','').replace(new RegExp(/^[a-zA-Z]*$/),'');
      this.hh=this.hh.replace(/[^0-9]/g,'0')
      if(+this.hh>23) this.hh = '23';
    
     this.mm = this.mm.substring(0, 2).replace('.','').replace(new RegExp(/^[a-zA-Z]*$/),'');
     this.mm=this.mm.replace(/[^0-9]/g,'0')
     if(+this.mm>60) this.mm = '60';
     
  }
}
