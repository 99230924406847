import { Component, Input, Output, EventEmitter } from '@angular/core';
import { globalService } from './../../../services/global.service';
import { ApiService } from './../../../services/ApiService';
import { excelUpload } from './../../../services/excelUploadService'
import { StorageService } from './../../../services/storage.service';
import { ExcelService } from './../../../services/excel.service';
const swal = require('sweetalert');

@Component({
    selector: 'route-timeline-dir',
    template: `
  <div class="route-container  animated fadeIn">
  <div class="route-heading-container" style="padding:18px;background: rgba(158, 158, 158, 0.09);">
      <div class="row action" style="margin-top:-14px;margin-bottom:4px;">
          <div *ngIf="!showMemberUpload" class="back-button pull-left grow err-chk-goback" (click)="hideTimeline($event)" data-toggle="tooltip" data-placement="bottom"
              title="go back" style="cursor:pointer;">
              <svg width="24" height="24" viewBox="0 0 24 24">
                  <g class="nc-icon-wrapper" fill="#429db5">
                      <path d="M21 11H6.83l3.58-3.59L9 6l-6 6 6 6 1.41-1.41L6.83 13H21z"></path>
                  </g>
              </svg>
          </div>
          <div *ngIf="showMemberUpload" class="back-button pull-left grow" (click)="hideMemberUploadSection($event)" data-toggle="tooltip"
              data-placement="bottom" title="Go back" style="cursor:pointer;">
              <svg width="24" height="24" viewBox="0 0 24 24">
                  <g class="nc-icon-wrapper" fill="#429db5">
                      <path d="M21 11H6.83l3.58-3.59L9 6l-6 6 6 6 1.41-1.41L6.83 13H21z"></path>
                  </g>
              </svg> 
          </div>

          <a href="{{routeInfoExcelLink}}" target="_blank">
              <i title="Download route pickup info meager-e2e-excel-download" (click)="downloadRoutePickupInfo()" class="fa fa-download excelDownload grow err-chk-download-routepickupinfo" aria-hidden="true"></i>
          </a>
      </div>
      <div class="container-fluid route-details-container" style="cursor:pointer">
          <div class="row routeHeading mb5">
              <div class="pull-left routeName">{{routeInformation.routeName}}
                  <sup class="supClass text-capitalize">{{routeInformation.routeType}}</sup>
              </div>
              <div class="pull-right routeTiming" title="Route Timings">
                  <span><i class="fa fa-clock-o mr5" aria-hidden="true"></i>
                  {{routeInformation.routeStartTime}} - {{routeInformation.routeEndTime}}</span>
              </div>
          </div>
          <div class="row">
              <div class="col-md-6 routeGeneralInfo mb5" style="padding-left:0px;">
                  <span class="route-avatars">
                      <i class="fa fa-bus mr5" title="Assigned Vehicle"></i>
                  </span>{{routeInformation.vehNo}}</div>
              <div class="col-md-3 routeGeneralInfo mb5" style="padding-left:0px;">
                  <span class="route-avatars">
                      <i class="fa fa-user mr5" title="Students"></i>
                  </span>{{routeInformation.totalmember}}</div>
              <div class="col-md-3 routeGeneralInfo mb5">
                  <span class="route-avatars">
                      <i class="fa fa-map-pin mr5" title="Pickup Points"></i>
                  </span>{{routeInformation.totalPickup}}</div>
                  
          </div>

          <div class="row">
              <div class="col-md-12 routeGeneralInfo mb5" style="padding-left:0px;">
                  <span class="route-avatars">
                      <i class="fa fa-map-marker mr5" title="Location"></i>
                  </span>{{routeInformation.routeAddress}}
              </div>
          </div>
          <div class="row">
             <div class="col-xs-2 col-md-2 col-lg-2" style="color:#6a6a6a;" *ngIf="routeInformation.smsAlert==true" style="padding-left:0px">
              <i class="fa fa-comments grey alertIcons" aria-hidden="true" title="Sms Alert" data-toggle="tooltip" data-placement="bottom"></i>
             </div>
             <div class="col-xs-2 col-md-2 col-lg-2" style="color:#6a6a6a;" *ngIf="routeInformation.emailAlert==true" style="padding-left:0px">
              <i class="fa fa-envelope grey alertIcons" aria-hidden="true" title="Email Alert" data-toggle="tooltip" data-placement="bottom"></i>
             </div>
             
             <div class="col-xs-2 col-md-2 col-lg-2" style="color:#6a6a6a;" *ngIf="routeInformation.appAlert==true" style="padding-left:0px">
              <i class="fa fa-bell grey alertIcons" aria-hidden="true" title="App Alert" data-toggle="tooltip" data-placement="bottom"></i>
             </div>
             <div class="col-xs-2 col-md-2 col-lg-2" style="color:#6a6a6a;" *ngIf="routeInformation.callAlert==true" style="padding-left:0px">
              <i class="fa fa-phone alertIcons" aria-hidden="true" title="Call Alert" data-toggle="tooltip" data-placement="bottom"></i>
             </div>

          </div>
          <div class="row">
              <div class="col-md-12" style="padding:0px;">
                  <span class="pull-right meager-e2e-editRouteForm" (click)="showEditRouteForm()">
                      <i class="fa fa-pencil grow mr5 edit err-chk-edit-named-route" aria-hidden="true" style="margin-left:10px;font-size: 17px;" data-toggle="tooltip"
                          title="Edit Route" data-placement="bottom"></i>
                  </span>
                  <span class="pull-right">
                    <!-- <div class=""> -->
                            <!-- <a class="dropdown-toggle" title="Reports" data-toggle="dropdown" style="cursor:pointer"><i
                                    class="fa fa-file-text-o text-secondary fa-1x grow mr5 edit" aria-hidden="true"></i>
                            </a>-->
                            <!-- <span class="caret"></span></button> -->
                            <!--<ul class="dropdown-menu">
                            <li class="text-center">Extras</li>
                            <li class="divider"></li>
                                <li title="Halt Log" style="cursor:pointer;text-align:center" class="text-center">
                                <button class="btn btn-primary">Halt Log</button>
                            </li>
                               
                            </ul>-->
                            <!-- </div> -->
                  </span>
              </div>
          </div>
      </div>
  </div>
  <!--Header tab Ends here-->
  <!--pickup Header-->

  <div *ngIf="!showMemberUpload" class="container-fluid" style="background: #f6f6f6;border-top: 1px solid lightgray;">
      <div class="row">
          <div class="search-bar col-md-9 col-sm-6 xol-xs-6 col-lg-9">
              <label class="control-label" style="margin:4px;">
                  <i class="fa fa-search mr5" aria-hidden="true"></i>Filter Pickup Points</label>
              <input class="form-control err-chk-filter-pickup" (change)="PickupPointName=$event.target.value;filterFunction($event.target.value);" type="search">
          </div>
          <div class="createNewRoute pull-right grow err-chk-add-pickup" style="margin-right:10px;">
              <span (click)="showStoppageUploadform()" data-toggle="tooltip" data-placement="bottom" title="Add Pickup Point" style="cursor:pointer;">+</span>
          </div>
          <div *ngIf="routeDetails?.length > 0" class="createNewRoute pull-right grow err-chk-excelupload" style="margin-right:10px;">
              <span (click)="showMemberUploadForm()" data-toggle="tooltip" data-placement="bottom" title="Member Assign" style="cursor:pointer;">
                  <i class="fa fa-upload" aria-hidden="true"></i>
              </span>
          </div>
      </div>
  </div>
 
  <div class="routeDetails " style="overflow-y: scroll;overflow-x:hidden;height: 55vh;background: #fff;" *ngIf="!showMemberUpload">
      <div class="routeList" *ngIf="routeDetails?.length > 0; else falsyTemplate">
          <li title="Click here to view Pickup Point Details" class="list-item-container" *ngFor='let routeDetails of routeDetails;let i=index;'>
              <div class="List_points" style="padding:10px;">
                  <div class="row" style="padding-bottom:5px;">
                      <div class="col-md-2  pull-left" style="max-width:5px;">{{i+1}}.</div>
                      <div (click)="showStoppageDetails(routeDetails.pickupId)" class="col-md-8 col-lg-8 col-sm-8 err-chk-pickup-{{i}}">
                          <i class="fa fa-map-marker mr5"></i>{{routeDetails.pickuppoint}}</div>
                      <div class="pull-right col-md-2">
                      </div>
                  </div>
                  <div class="row" style="padding-top:10px;">
                      <div class="col-md-2" style="max-width:5px;"></div>
                      <div (click)="showStoppageDetails(routeDetails.pickupId)" class="col-md-2 col-lg-2 col-sm-2 err-chk-pickup-{{i}}">
                          <i class="fa fa-user mr5 pull-left" aria-hidden="true" style="padding-top:2px;"></i>{{routeDetails.totalmember}}</div>
                      <div (click)="showStoppageDetails(routeDetails.pickupId)" class="col-md-6 err-chk-pickup-{{i}}" style="width:59%;">
                          <i class="fa fa-clock-o mr5" aria-hidden="true"></i>{{routeDetails.expectedTime}}</div>
                      <div class="col-md-2">
                          <i class="fa fa-trash pull-right rotate delete err-chk-pickup-delete-{{i}}" aria-hidden="true" data-toggle="tooltip" data-placement="bottom" title="Remove Pickup point"
                              (click)="deletePickupPoint(routeDetails.pickupId)" style="cursor:pointer"></i>
                      </div>
                  </div>
              </div>
          </li>
      </div>
      <ng-template #falsyTemplate>
          <h4 class="animated pulse text-center" style="animation-duration: 1s;color:#999FBF">
            <i class="fa fa-frown-o"></i>&nbsp;No pickup points to display</h4>
        </ng-template>
  </div>
  <section class="memberUploadSection" *ngIf="showMemberUpload" style="padding:10px;">
      <div class="container-fluid upload-points-dir">
          <div class="upload-point-container">
              <div class="point-excel-upload">
                  <div class="drop-file-container">
                      <div class="icon-drag-drop">
                          <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 48 48"
                              xml:space="preserve" width="100" height="100">
                              <g class="nc-icon-wrapper">
                                  <path fill="#E6E6E6" d="M41,47H7c-1.105,0-2-0.895-2-2V3c0-1.105,0.895-2,2-2l24,0l12,12v32C43,46.105,42.105,47,41,47z"></path>
                                  <path fill="#B3B3B3" d="M31,1v10c0,1.105,0.895,2,2,2h10L31,1z"></path>
                                  <path fill="#B3B3B3" d="M34,35H14c-0.552,0-1,0.448-1,1s0.448,1,1,1h20c0.552,0,1-0.448,1-1S34.552,35,34,35z"></path>
                                  <path fill="#B3B3B3" d="M34,25H14c-0.552,0-1,0.448-1,1s0.448,1,1,1h20c0.552,0,1-0.448,1-1S34.552,25,34,25z"></path>
                                  <path fill="#B3B3B3" d="M22,15h-8c-0.552,0-1,0.448-1,1s0.448,1,1,1h8c0.552,0,1-0.448,1-1S22.552,15,22,15z"></path>
                              </g>
                          </svg>
                      </div>
                      <div class="title">Drag and drop a csv - xls file</div>
                      <div class="action-title">
                          <i class="fa fa-upload mr5"></i>
                          <span class="  ">Or, select from your computer</span>
                      </div>
                      <input type="file" accept=".csv,.xml,.xls,.xlsx,.xlsm" multiple="false" id="memberAssign-input" (change)="onFileChange($event)"
                          class="test-stops-input ng-pristine ng-untouched ng-valid ng-isolate-scope meager-e2e-fileupload">
                  </div>
              </div>
          </div>
          <span class="excel-template-downalod-ref pull-right meager-e2e-excelTemplateDownload" (click)="downloadTemplate()">
              <a class="excel-template-link" tabindex="0">Download an excel template.</a>
          </span>
      </div>
  </section>
</div>
  `,
    styles: [`
    .createNewRoute{
       font-size: 2.27rem;
       font-weight: 400;
       width: 35px;
       border-radius: 50%;
       color: white;
       line-height: 35px;
       text-align: center;
       height: 35px;
       margin: 24px auto;
       box-shadow: 0px 2px 5px rgba(94, 94, 94, 0.68);>
       cursor: pointer;
       background: #00BCD4;
       z-index: 10;
    }
    span {
      line-height: 14px;
    }
    .list-item-container{
       display: inline-block;
       list-style: none;
       position: relative;
       width: 100%;
       border-bottom: 1px solid #e6e6e6;
       min-height: 50px;
       padding: 2%;
       cursor:pointer;
    }
    .list-heading{
      font-size: 30px;
      font-weight: 400;
    }
    .routeName{
      font-size: 17px;
    }
    .routeTiming{
      font-size:13px;
    }
    .routeGeneralInfo{
      font-weight:300;
    }
    .route-user-avatar{
      margin-left: -22px;
    }
    .route-heading-tabs{
      min-height:45px;
    }

   .rotate:hover{
           color: red;
           transition: 0.9s;
           transform: rotateY(180deg);
   }

   animation css */
   .grow { transition: all .2s ease-in-out; }
   .grow:hover { transform: scale(1.1); }

   .supClass {
   background: #337ab7;
   display: inline;
   padding: .2em .3em;
   font-size: 79%;
   font-weight: 700;
   line-height: 1;
   color: #fff;
   text-align: center;
   white-space: nowrap;
   vertical-align: baseline;
   border-radius: .25em;
   margin-left: 4px;
   top: -0.5em;

   }

   div .setings-checkbox-main-tab .settings-checkbox-main-tr .settings-checkbox-main-td {
       padding-right: 50px;
   }

   .chk {

       width: 18px;
       height: 18px;
       cursor: pointer;
       border: 2px solid #2980b9 !important;
   }

   .regular-checkbox {
       -webkit-appearance: none;
       display: inline-block;
       position: relative;
   }

   .regular-checkbox:checked {
       color: #2980b9;
   }

   .regular-checkbox:checked:after {
       content: '✔';
       font-size: 13px;
       position: absolute;
       top: -2px;
       left: 2px;
       color: #2980b9;
   }

   .alertIcons{
    font-size: 1em;
    margin-left: 5px;
    color: #716969;
   }


   input[type=search]::-webkit-search-cancel-button {
   -webkit-appearance: searchfield-cancel-button;
   }

   .excelDownload {
     position: fixed;
     margin-top:5px;
     margin-left:29%;
     color:#429db5;
     cursor:pointer;
   }

   i.fa.fa-download.excelDownload:hover {
    color: #524a4a;
   }
   .upload-point-container{
    margin-top: 1em;
    padding-bottom: 1em;
    background-color: #fff;
  }
  .point-excel-upload{
    padding: 1em;
    color: #b5c4c9;
    text-align: center;
  }
  .drop-file-container{
    position: relative;
    border: 2px dashed #b5c4c9;
    border-radius: 5px;
    padding: 30px;
    background-color: #f5f5f5;
  }
  .icon-drag-drop{
    font-size: 7em;
    margin-bottom: 20px;
    color: #b5c4c9;
  }
  .icon-upload{
    color: #4a4847;
    margin-right: .5em;
  }
  .drop-file-container input{
    position: absolute;
    opacity: 0;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    height: 100%;
    width:100%;
  }

  .excel-template-link{
    text-decoration:none;
    cursor:pointer;
    font-size:13px;
  }

    `]
})


export class routeTimelineDir {
    //private showingStoppageDetails: boolean = false;
    public routeDetails: any[];
    public showingTimeline: boolean = false;
    public _routeStoppages: any = [];
    public PickupPointName: any = "";
    public RouteName: any;
    public startDate: any = null;
    public endDate: any = null;
    public routeInformation: any = {};
    public tagList: any = [];
    routeInfoExcelLink: string;
    showMemberUpload: boolean;

    public getRouteName: string = '';
    // haltrcordapiHeader: { "data": { "key": string; "form": { "vehId": string[]; "fromDate": string; "fromTime": string; "toDate": string; "toTime": string; "speed": { "halt": { "value": number; }; }; }; "extra": { "pageJump": number; }; }; };
    public disabledbtn: string = 'disabling';
    public EndDateString: any;
    public StartDateString: any;
    disabledbtnfordaterange: string;
    public haltonlyReportDatas: any = [];
    public pickupointDatas: any = [];
    public haltwithpickupPoint: any = {
        haltonlyReportData: this.haltonlyReportDatas,
        pickupoint: this.pickupointDatas,
    };

    constructor(
        private apiService: ApiService,
        public excelUpload: excelUpload,
        private storage: StorageService,
        private excel: ExcelService
    ) {
        this.showMemberUpload = false;
    }

    @Input('routeId') _routeId: any;


    /*input used for displaying route hideTimeline */
    @Input('routeStoppages')
    set name(routeStoppages: any) {
        if (routeStoppages == undefined)
            routeStoppages = {};
        // console.log('hhhh', routeStoppages);

        //let getRouteName = this.globalservice.getData().newRouteName;

        this.routeDetails = routeStoppages.pickuppoints;

        // this.RouteName = getRouteName;

        //  console.log(this.routeDetails);

    }





    @Output() getBack: EventEmitter<boolean> = new EventEmitter<boolean>();
    @Output() addPickupPoint: EventEmitter<boolean> = new EventEmitter<boolean>();
    @Output() memberUploadForm: EventEmitter<boolean> = new EventEmitter<boolean>();
    @Output() showingStoppageDetails: EventEmitter<any> = new EventEmitter<any>();
    @Output() removePickUpPoint: EventEmitter<any> = new EventEmitter<any>();
    @Output() filterTextEmitter: EventEmitter<any> = new EventEmitter<any>();
    @Output() showingRouteEditForm: EventEmitter<any> = new EventEmitter<any>();
    @Output() pickupHaltEventEmitter: EventEmitter<any> = new EventEmitter<any>();
    // @Output() public pickupHaltEventEmitter = new EventEmitter();

    ngOnInit() {

        let getRouteName = this.storage.get('newRouteName');
        // console.log(getRouteName);
        if (getRouteName) {
            let data = {
                data: {
                    key: localStorage.getItem('scbToken'),
                    filter: {
                        //routeId: this._routeId,
                        routeName: getRouteName
                    }
                }
            };
            this.apiService.getRoutes(data).then(response => {
                ///          console.log(response);
                if (response) {

                    this._routeId = response.response[0].routeId;
                    this.storage.set({
                        'currentRouteID': response.response[0].routeId
                    });

                    this.processRouteDetails();

                }
            }).catch(() => { });

        } else {
            this.processRouteDetails();
        }


        this.storage.set({
            'routeInfo': ''
        });


    }
    processRouteDetails() {

        let newRouteid = this.storage.get('currentRouteID');
        if (newRouteid) {

            this._routeId = newRouteid;
        } else {
            this._routeId = this._routeId;
        }

        let data = {
            data: {
                key: localStorage.getItem('scbToken'),
                filter: {
                    routeId: this._routeId

                }

            }
        };
        this.apiService.getRoutes(data).then(response => {

            if (response) {
                this.routeInformation = response.response[0];
                this.storage.set({
                    'routeInfo': this.routeInformation
                });

                if (this.routeInformation.tags != '' && this.routeInformation.tags != undefined) {
                    this.tagList = this.routeInformation.tags;

                }
                localStorage.setItem("routeTimings", this.routeInformation.routeStartTime + "--" + this.routeInformation.routeEndTime);
            }
        }).catch(() => { });
    }

    filterFunction(filterText: any) {
        let routeId = this._routeId;
        this.filterTextEmitter.emit({ filterText, routeId });
    }

    hideTimeline() {

        this.storage.set({
            'currentRouteID': '',
            'newRouteName': ''
        });

        this.showingTimeline = false;
        this.showMemberUpload = false;
        this.getBack.emit(this.showingTimeline);
        localStorage.setItem('routeTimings', '');
    }

    routeInfo() {
        this.routeDetails = this._routeStoppages;
    }


    showStoppageUploadform() {
        this.addPickupPoint.emit(true);
    }

    showMemberUploadForm() {
        this.showMemberUpload = true;
        // this.memberUploadForm.emit(true);
    }

    hideMemberUploadSection() {
        this.showMemberUpload = false;
    }

    showStoppageDetails(pickupId: any) {
        this.showingStoppageDetails.emit({ event: true, pickupId: pickupId });
    }

    showEditRouteForm() {
        this.showingRouteEditForm.emit({ value: true, routeId: this._routeId });
    }


    deletePickupPoint(pickupId: any) {
        var requestHeader = {
            data: {
                key: localStorage.getItem('scbToken'),
                form: {
                    pickupId: pickupId
                }
            }
        }
        this.removePickUpPoint.emit(requestHeader);
    }


    downloadRoutePickupInfo() {
        this.routeInfoExcelLink = `${this.apiService.base}Routedetails/downloadroute_pickups&key=${localStorage.getItem('scbToken')}&routeid=${this._routeId}`;
    }


    downloadTemplate() {
        window.open('https://api-school.placer.in/api/v2/webroot/sample/studentpickup.xls');
    }


    onFileChange(event: any) {

        let parent: any = this;
        if (event.target.files && event.target.files.length > 0) {
            let file = event.target.files[0];
            var apiKey = localStorage.getItem('scbToken');
            parent.excelUpload.memberAssignUpload(apiKey, this._routeId, file, 'memberAssign-input', function (obj: any) {

                if (obj.status == 'success') {
                    swal('Success', 'Student Assign Excel Uploaded Successfully', 'success');
                    setTimeout(() => {
                        this.hideMemberUploadSection();
                        //this.processRouteDetails();
                    }, 1000);
                } else if (obj.status == 'failure') {

                    let apiRepsponceArray: any = [];
                    let errorArray: any = [];
                    let orgError: any = [];
                    apiRepsponceArray = obj.response.failed;
                    //  console.log(apiRepsponceArray);
                    Object.keys(apiRepsponceArray).forEach(function (key: any) {
                        errorArray = apiRepsponceArray[key];

                        for (var index in errorArray) {
                            orgError.push(errorArray[index]);
                        }
                        //console.log(errorArray);
                        swal({
                            title: 'Failure',
                            text: 'Not Exisiting/Assigned already' + orgError,
                            icon: 'error',
                        });
                    });

                } else if (obj.status == 'partial') {
                    let apiRepsponceArray: any = [] = obj.response.succeed;
                    let DuplicatedArray: any = [] = obj.response.failed;
                    let errorArray: any = [];
                    let duparray: any = [];
                    let orgError: any = [];
                    let dupValues: any = [];
                    Object.keys(apiRepsponceArray).forEach(function (key: any) {
                        errorArray = apiRepsponceArray[key];

                        for (var index in errorArray) {
                            orgError.push(errorArray[index]);
                        }
                    });
                    Object.keys(DuplicatedArray).forEach(function (key: any) {
                        duparray = DuplicatedArray[key];

                        for (var index in duparray) {
                            dupValues.push(duparray[index]);
                        }
                    });
                    let span = document.createElement("span");
                    span.innerHTML = "Success :-<b>" + orgError + "</b><br><hr>Duplicated :-<b>" + dupValues + "</b>";
                    swal({
                        title: 'Partially Uploaded',
                        content: span,
                        icon: 'warning',
                    });
                } else {
                    //do nothing
                    console.log('failure');
                }
            });
        }
    }



}
