import { Component,Input,Output } from "@angular/core";

declare var Highcharts: any;
declare var $:any
@Component({
    selector:'g10-column-chart',
    templateUrl: './column-chart.component.html',
    styleUrls:['./column-chart.component.css']
})

export class ColumnChartComponent{
    @Input() _data: any;
    @Input() _chartType:string;
    constructor(){}

    ngOnInit(){
        this.init();
      //   console.log('Colum_data',this._data)
    }

    init(){
      
        this.render();
    }

    render(){
        
        var chart = {
            type: 'bar'
         };
         var title = {
            text: 'Time based performance chart'   
         };
         var subtitle = {
            text: 'Sorted Bad performance'  
         };
         var xAxis = {
            categories: this._data['category'],
            crosshair: true
         };
         var yAxis = {
            min: 0,
            title: {
               text: 'Count'         
            }      
         };
         var tooltip = {
            headerFormat: '<span >{point.key}</span><table>',
           
         };
         var plotOptions = {
            column: {
               pointPadding: 0.2,
               borderWidth: 0
            }
         };  
         var credits = {
            enabled: false
         };
            
      
         var json:any = {};   
         json.chart = chart; 
         json.title = title;   
         json.subtitle = subtitle; 
         json.tooltip = tooltip;
         json.xAxis = xAxis;
         json.yAxis = yAxis;  
         json.series = this._data['series'];
         json.plotOptions = plotOptions;  
         json.credits = credits;
         $('#column-chart').highcharts(json);
    }
}