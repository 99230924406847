import { Component, OnInit, Input } from '@angular/core';
import { ApiService } from '../../services/ApiService';
import { StorageService } from "./../../services/storage.service";
import { Observable } from 'rxjs';
import { KDTreeService } from "./../../services/kdtree.service";
import { keyframes } from '@angular/animations';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';


declare const Highcharts: any;


@Component({
    selector: 'route-analytics',
    templateUrl: './route-analytics.html',
    styles: [`
.highcharts-data-label-connector {
    display:none;
}

.date-picker-style{
    border: 1px solid #969696;
    background: white;
    border-radius: 5px;}

    .mt-4{
        margin-top:40px;
    }
    `]
})
export class routeAnalyticsComponent implements OnInit {

    public formControl: any;
    public Role: any = {};
    public roleControl: any;
    public orgReportStatus: any;
    public formError: any = {};
    dateTS: string | number;
    duration: string;
    allRouteInfo: any;
    // routeDetails: any;
    sessionTypeList: string = "";
    morningRoute: any;
    noonRoute: any;
    eveningRoute: any;
    allSessionRoute: any
    routeInfosession: any;
    routePickup_points: any;
    routesDetails: any;
    routeID: any;
    pickuppoints: any;
    date: string;
    fromTime: string;
    toTime: string;
    chartData: any;
    earlier: any;
    delay: any;
    noTimestamp: any = [];
    ontime: any;
    todayDate: Date;
    routeName: any = [];
    routeData: any = {}
    pickuppointDetails: any;
    vehId: any;
    routeId: any;
    trackData: any;
    filterForm: any;
    com: any;
    route2: any;
    date_ts: any;
    totalNoTime: any;



    constructor(public storage: StorageService,
        private apiService: ApiService,
        public storageservice: StorageService,
        private KDTree: KDTreeService,
        private formBuilder: FormBuilder,

    ) {
    }

    ngOnInit(): void {

    }




    sessionRoute(data: any) {
        if (this.sessionTypeList === "Morning") {
            this.morningRoute = data

            // this.callDonutChart()
            // console.log(this.morningRoute.length)
            // this.callDonutChart({
            //     'Buses on route': this.morningRoute.length,
            //     'Buses on time': this.morningRoute,
            //     'Buses not a route': 0,
            //     'Buses late': 0
            // })

        }
        if (this.sessionTypeList === "Afternoon") {
            this.noonRoute = data
            // this.callDonutChart()
            // console.log(this.noonRoute.length)

        }
        if (this.sessionTypeList === "Evening") {
            this.eveningRoute = data
            // this.callDonutChart()
            // console.log(this.eveningRoute.length)

        }
        if (this.sessionTypeList === "") {
            this.allSessionRoute = data
            // this.callDonutChart()
            // console.log(this.allSessionRoute.length)

        }



    }


    callDonutChart(chartData = {
        'Reached Early': this.earlier,
        'Reached on time': this.ontime,
        'Timestamps N/A': this.noTimestamp,
        'Reached late': this.delay
    }) {
        // console.log('Donut Ob', pichartArrayObj)
        let parent = this;
        Highcharts.chart('donutChart', {
            chart: {
                plotBackgroundColor: null,
                plotBorderWidth: null,
                plotShadow: false
            },
            title: {
                text: 'Day Report',
                align: 'center'
            },

            legend: {
                align: '',
                reversed: true,
                // padding: 50,
                // symbolHeight: 6,
                // symbolWidth: 6,
            },
            tooltip: {
                headerFormat: '',
                pointFormat:
                    '{point.name}' +
                    ' <br><b>{point.y}</b><br/>'

            },
            plotOptions: {
                pie: {
                    allowPointSelect: true, borderWidth: 0,
                    // shadow: {
                    //     color: '#a4a4a4',
                    //     offsetX: 0,
                    //     offsetY: 0,
                    //     opacity: '0.5',
                    //     width: 3
                    // },
                    //    center: [90, 90],

                    // dataLabels: {
                    //     enabled: true,
                    //     style: {
                    //         textOutline: true,
                    //         color: "black"
                    //     },
                    //     distance: -35,
                    //     format: '{point.percentage:.1f}%',
                    // },
                    size: 155,
                },
            },
            series: [{
                type: 'pie',
                name: '',

                data: [
                    {
                        name: "Reached Early - this.earlier",
                        y: chartData["Reached Early"], //this.routeDetails.length,
                        color: "#6CE2EB",
                        legendDesc: "1 Shipment"
                    },
                    {
                        name: "Reached on time - this.ontime",
                        y: chartData["Reached on time"],
                        color: "#41B8D5",
                        legendDesc: "2 Shipments"
                    },
                    {
                        name: "Timestamps N/A -    this.noTimestamp",
                        y: chartData["Timestamps N/A"],
                        color: "#2F5F98",
                        legendDesc: "3-5 Shipments"
                    },
                    {
                        name: "Reached late -  this.delay",
                        y: chartData["Reached late"],
                        color: "#2D8BBA",
                        legendDesc: ">5 Shipments"
                    }
                ],
                innerSize: "40%",
                dataLabels: {
                    enabled: true,
                    connectorWidth: 0

                },

            }
            ],

            // point: {
            //     events: {

            //     }
            // },

            exporting: {
                enabled: false
            }

        });

    }





    callBarchart() {
        Highcharts.chart('barChart', {
            chart: {
                type: 'column'
            },
            title: {
                text: 'Weekly Report'
            },

            xAxis: {
                categories: [
                    '15.07.2024',
                    '16.07.2024',
                    '17.07.2024',
                    '18.07.2024',
                    '19.07.2024'
                ],
                crosshair: true
            },
            yAxis: {
                min: 0,
                // title: {
                //     text: 'Rainfall (mm)'
                // }
            },
            tooltip: {
                headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
                pointFormat: '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
                    '<td style="padding:0"><b>{point.y:.1f} mm</b></td></tr>',
                footerFormat: '</table>',
                shared: true,
                useHTML: true
            },
            plotOptions: {
                column: {
                    pointPadding: 0.2,
                    borderWidth: 0
                }
            },
            series: [{
                name: 'Reached Early',
                color: "#6CE2EB",
                data: [49.9, 71.5, 106.4, 129.2, 144.0,]

            }, {
                name: 'Reached on time',
                color: "#41B8D5",
                data: [83.6, 78.8, 98.5, 93.4, 106.0,]

            }, {
                name: 'Timestamps N/A',
                color: "#2F5F98",

                data: [48.9, 38.8, 39.3, 41.4, 47.0,]

            }, {
                name: 'Reached late',
                color: "#2D8BBA",

                data: [42.4, 33.2, 34.5, 39.7, 52.6,]

            }],
            exporting: {
                enabled: false
            }
        });


    }


}


