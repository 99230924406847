
import { Component,EventEmitter,Output } from '@angular/core';
import { ApiService } from './../../services/ApiService';
import { GlobalUtils } from './../../services/global-utils';

declare var require: any
const swal = require('sweetalert');




type campaignType = 'allRoute' | 'pickupRoute' | 'dropRoute' | 'member';
interface ICampaignBasicOperation {
    selectedCampaignType: campaignType;
    orgRoutes: routeType[];
    routePickupPts: pickupPointType[];

    totalRouteCnt: number;
    showLoader: boolean;

    setCampaignType(): void,
    getOrgRoutes(): void,
    getRouteStoppages(routeId: number): void
}

interface routeType {
    routeId: string,
    routeName: string,
    routeType: string
    totalPickup: number
    totalmember: number
}

interface pickupPointType {
    pickupId: string;
    pickuppoint: string;
    expectedTime: string;
    pickupAddress: string;
}

@Component({
    selector: 'add-campaign-form',
    templateUrl: './createCampaignTemplate.html',
    styles: [`
    .border-gray{
        border: solid 1px #d0d0d0;
    }
    .createCampaignSection{
      margin:5% 0 2% 0;
    }
    .campaignStatusIcon{
        font-size:19px;
        color:#808080c2;
    }
    .campaignToSection{
        padding: 0.2% 0 0 1%;
        margin-left: -5%;
        line-height:2;
    }
    .campaignSenderTitle{
        font-size: 17px;
        font-weight: lighter;
        color: #484848;
    }
     a:hover{
        text-decoration:none;
    }
    .panel{
        border:none;
    }
    .panel-heading{
        padding: 2% 0 1% 0;
        background: white;
        border: none;
    }
    .v-textColorGreenLight {
      color: #95d1c4;
    }
    .contentTitleDescription{
        color: #8080809e;
        font-style: italic;
        opacity: .8;
    }
    .groupHasError{
        color: rgba(255, 0, 0, 0.45);
    }

    div.selectAllContainer{
        padding: 1%;
        margin-bottom: 2%;  
        border-bottom: 1px solid #9e9e9e29;
    }
    .regular-checkbox{
        margin-right:5px;
        top:2px
    }
    .routeSearchRow{
        max-height: 400px;
        min-height: 399px;
    }
    li.campaignSelectedRoutes{
        border-radius: 0;
        background: #f7f6f6;
        border: none;
        padding: 10px;
    }

    li.search-choice {
        position: relative;
        margin: 5px 0 5px 5px;
        padding: 8px 20px 8px 10px;
        -moz-border-radius: 2px;
        -webkit-border-radius: 2px;
        border-radius: 2px;
        background-color: #eee;
        background-clip: padding-box;
        color: #333;
        line-height: 13px;
        cursor: default;
        font-size: 12px;
        float: left;
        list-style: none;
        z-index: 100;
    }
    a.removeSearchItemLink {
        position: absolute;
        top: 8px;
        right: 3px;
        display: block;
        width: 12px;
        height: 12px;
        background: url(./../../assets/webroot/css/chosen-sprite.png) -42px 1px no-repeat;
        font-size: 1px;
        cursor: pointer;
    }
    .addCustomCampaignIcon{
        font-size: 2em;
        color: #4e9db5;
        cursor:pointer;
    }


    .campaignFields{
        margin-bottom:6%;
    }
    .routeList{
        box-shadow: -1px 1px 5px 3px #9e9e9e14;
    }
    span.routeType {
        font-size: 0.95em;
        color: #8bc34ac4;
        font-weight: lighter;
    }
    span.routeName {
        font-size: 1.05em;
    }
    .routeLocationInfo,.routeTimingsInfo {
        font-size: 0.9em;
        padding-left: 0.5em;
    }

    .routeLocationInfo{
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    li.pick-item{
        padding: 5px 5px 5px 15px;
    }
    .divider{
        top: 2px;
        font-size: 19px;
    } 
    .routeBasicInfo{
        top:5px;
    }  
    .viewPickPtLnk{
        font-size: 0.9em;
        padding-left: 2.3%;
        cursor: pointer;
    }
    .routePickupPointContainer{
        padding: 0.5% 0 0 3%;
    }
    #footer {
        bottom: 3px;
        width: 84%;
        position: fixed;
        height: 75px;
        background-color: #fff;
    }
    #footer .footer-block {
        margin: 20px 0;
    }

    #footer{
        box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 46px rgba(0,0,0,0.23);
    }

    .errorDiv{
        padding-left: 4rem;
        padding-top: 1rem; 
    }
                             
                                

    `],
})
export class createCampaignOperation implements ICampaignBasicOperation {
    public selectedCampaignType: campaignType;
    public orgRoutes: routeType[];
    public routePickupPts: pickupPointType[];

    public totalRouteCnt: number;
    public showLoader: boolean;
    public showRowLoader: boolean;
    public selectAllRouteOption: boolean;
    public clickedRoute: string;
    public campaignName:string;
    public routeDetails: any[];
    public availableCampaignTemplates: any[];
    public selectedTemplate: string;
    public campaignType: string;
    public templateContent: any;
    public showDynamicTemplates: boolean;
    public showCountInfo: boolean;
    public campaignMessagesSent: number;
    public campaignHeader: any;
    public enableHoliday:boolean;
    public classList:string='';

    constructor(private apiService: ApiService) {
        this.selectedCampaignType = 'allRoute';
        this.orgRoutes = [];
        this.routePickupPts = [];
        this.routeDetails = [];
        this.availableCampaignTemplates = [];
        this.showLoader = false;
        this.selectAllRouteOption = false;
        this.showRowLoader = false;
        this.showDynamicTemplates = false;
        this.showCountInfo = false;
        this.templateContent = null;
        this.campaignType = 'all';
        this.campaignHeader = {};
        this.enableHoliday = true;
    };


    @Output() hideCreateCampaign:EventEmitter<any> = new EventEmitter<any>();

    ngOnInit() {
        this.getOrgRoutes();
        this.getAvailableTemplates();
    }

    setCampaignType = (): void => {
        this.selectAllRouteOption = false;
        this.getOrgRoutes();
    }

    getOrgRoutes = (): void => {
        let apiHeader = {
            data: {
                key: localStorage.getItem('scbToken'),
                projections: ["routeName", "routeId", "routeType", "routeAddress", "routeStartTime"],
                extra: {
                    maxlimit: 'all'
                }
            }
        }

        if ((this.selectedCampaignType == 'pickupRoute') || (this.selectedCampaignType == 'dropRoute')) {
            let val = (this.selectedCampaignType == 'pickupRoute') ? 'pickup' : 'drop';
            apiHeader.data['filter'] = {};
            Reflect.set(apiHeader.data['filter'], 'routeType', val);
        }

        this.showLoader = true;
        this.apiService.getRoutes(apiHeader).then(response => {
            if (response.response) {
                this.totalRouteCnt = response.totalroutecnt
                this.orgRoutes = response.response.map((index: any) => { index['isSelected'] = false; index['allPtsSelected'] = false; index['isClicked'] = false; return index; });
            } else {

            }
            this.showLoader = false;
        });
    }

    getRouteStoppages = (routeId: any): void => {
        let route = this.orgRoutes.filter((index: any) => { return index.routeId == routeId }).reduce((acc, cur) => { acc = cur; return acc }, {});
        route['isClicked'] = !route['isClicked'];

        route['allPtsSelected'] = route['isSelected'];
        //if route is not viewed previously then get data from api else avoid call.
        if (!(routeId in this.routeDetails)) this.fetchDetails(routeId, route['isSelected']);
    }

    fetchDetails = (routeId: string, isRouteSelected: boolean): void => {
        let apiHeader = {
            data: {
                key: localStorage.getItem('scbToken'),
                projections: ["pickupId", "pickuppoint", "expectedTime", "pickupAddress"],
                filter: {
                    routeId: routeId,
                }
            }
        };

        this.routePickupPts = [];
        this.showRowLoader = true;

        this.apiService.getRouteStoppages(apiHeader).then(response => {
            if (response.response.pickuppoints) {
                this.routePickupPts = [...response.response.pickuppoints];
                this.routeDetails[routeId] = response.response.pickuppoints.map((index: any) => { index['isSelected'] = isRouteSelected; return index });
            }
            this.showRowLoader = false;
        }).catch(error => {

        })
    }

    selectAllRoute = (event: any): void => {
        this.orgRoutes.map((index: any) => {
            index['isSelected'] = event.target.checked;
            index['allPtsSelected'] = event.target.checked;
        });
        if (Object.keys(this.routeDetails).length) {//make selections as per parent select all route option.
            Object.keys(this.routeDetails).map((index) => {
                this.routeDetails[index].map((index: any) => {
                    index.isSelected = event.target.checked;
                })
            });
        }
    }

    revertAllRouteSelection = (event: any, routeId: string): void => {
        if (event.target.checked) { //check if all routes are selected in order to check select all route option.
            if (this.isAllRouteSelected()) this.selectAllRouteOption = true;
        } else this.selectAllRouteOption = false;
        //select/de-select all pickup-points,if route option is checked.
        if (this.routeDetails[routeId]) this.selectAllPt(event, routeId);
        this.revertAllPtSelection(event, routeId);
    }

    isAllRouteSelected = (): boolean => {
        let arr = this.orgRoutes.filter((index: any) => { return index.isSelected == false });
        return (arr.length) ? false : true;
    }

    //select all pickup-points of a selected route
    selectAllPt = (event: any, routeId: string): void => {
        this.routeDetails[routeId].map((index: any) => { index['isSelected'] = event.target.checked; });

        //mark desired route if not marked
        let arr = this.orgRoutes.filter((index: any) => index.routeId == routeId);
        arr[0]['isSelected'] = event.target.checked;
        //check if all routes are selected or not...
        this.setAllRouteSelection();
    }

    isAllPtSelected = (routeId: string): boolean => {
        let arr = this.routeDetails[routeId].filter((index: any) => { return index.isSelected == false });
        return (arr.length) ? false : true;
    }


    revertAllPtSelection = (event: any, routeId: string): void => {
        let route = this.orgRoutes.filter((index) => { return index.routeId == routeId });
        if (event.target.checked) {
            if ((this.routeDetails[routeId]) && (this.isAllPtSelected(routeId))) { // all point are selected ,auto check the select all pickup point option
                route[0]['allPtsSelected'] = true;
            } else {
            }
        } else {
            route[0]['allPtsSelected'] = false;
        }
        //check whether single pickup-point is selected or not,if yes check relative route checkbox.
        if (this.routeDetails[routeId]) {
            let arr = this.routeDetails[routeId].filter((index: any) => index.isSelected == true);
            if (arr.length) route[0]['isSelected'] = true;
            else route[0]['isSelected'] = false;
        }
        //check if all routes are selected or not...
        this.setAllRouteSelection();

    }
    //check/uncheck select all route option.
    setAllRouteSelection = (): void => {
        if (this.isAllRouteSelected()) this.selectAllRouteOption = true;
        else this.selectAllRouteOption = false;
    }

    getAvailableTemplates = (): void => {
        let apiHeader = {
            data: {
                key: localStorage.getItem('scbToken')
            }
        }
        this.apiService.getAvailableCampaignTenplates(apiHeader).then(response => {
            this.availableCampaignTemplates = response.response;
        }).catch(error => {

        });
    }

    fetchTemplatePreview = (): void => {
        this.templateContent = this.availableCampaignTemplates.filter((index) =>
            index.templateId == this.selectedTemplate
        ).map((index) => { return index.templateMessage });
        this.templateContent = this.templateContent[0];
    }


    public campError: any = {};

    initialteCampaignValidation(){
        //this.getSelectedRoutes();
       
        let isValid:boolean = true;

        let routeArray= this.getSelectedRoutes();

        //console.log(Object.keys(routeArray));

        if(!Object.keys(routeArray).length){
            isValid = false;
            this.campError.routeNames = true;
        }else{
            this.campError.routeNames = false;
        }

        if(!this.templateContent){
            isValid = false;
            this.campError.contentError = true;
        }else{
            this.campError.contentError = false;
        }
        if(!this.campaignType){
            isValid = false;
            this.campError.typeError = true;
        }else {
            this.campError.typeError = false;
        }
        if(!this.campaignName){
            isValid = false;
            this.campError.nameError = true;
        }else{
            this.campError.nameError = false;
        }

        return isValid;
    }


    getSelectedRoutes() : any[] { 
        let selectedRoutes = this.orgRoutes.filter((index) => index['isSelected'] == true);
        let desiredObj = selectedRoutes.reduce((acc: any, curr: any) => {
            let routeId = curr.routeId;
            if (curr['isClicked']) {
                if (this.isAllPtSelected(routeId)) acc[routeId] = 'all';
                else {
                    let arr = this.routeDetails[routeId].filter((index: any) => index.isSelected == true).map((index: any) => index.pickupId);
                    acc[routeId] = arr;
                }
            } else {
                acc[routeId] = 'all';
            }
            return acc;
        }, {});
      return desiredObj;
    }


    getCampaignCount = (): void => {
        if( this.initialteCampaignValidation()){
            const parent = this;
        let selectedRoutes = this.orgRoutes.filter((index) => index['isSelected'] == true);
        let desiredObj = selectedRoutes.reduce((acc: any, curr: any) => {
            let routeId = curr.routeId;
            if (curr['isClicked']) {
                if (this.isAllPtSelected(routeId)) acc[routeId] = 'all';
                else {
                    let arr = this.routeDetails[routeId].filter((index: any) => index.isSelected == true).map((index: any) => index.pickupId);
                    acc[routeId] = arr;
                }
            } else {
                acc[routeId] = 'all';
            }
            return acc;
        }, {});

        let apiHeader = {
            data: {
                key: localStorage.getItem('scbToken'),
                form: {
                    routeId: desiredObj,
                    sendAlert: 0,
                    messages: this.templateContent,
                    messageType: this.campaignType,
                    messageTitle: this.campaignName,
                    classHolidayEnable:this.enableHoliday,
                    classSkip:this.classList
                }
            }
        };
        //console.log(apiHeader);
        this.campaignHeader = apiHeader;

        this.apiService.createOrgCampaign(apiHeader).then(response => {
            if (response) {
                this.campaignMessagesSent = response.totalmember
                this.showCountInfo = true;
                this.showCountInfo = false;
                let div = document.createElement("div");
                div.innerHTML = "<div style='overflow:auto'>Total Count <br>" + parent.campaignMessagesSent + "</div>";


                swal({
                    title: 'Are you sure?',
                    text: 'The Campaign will be created',
                    content: div,
                    buttons: [
                        'No, Cancel it!',
                        'Yes, I am sure!'
                    ],
                    dangerMode: true,
                }).then(function (isConfirm: any) {
                    if (isConfirm) {
                        parent.createCampaign1();
                        // parent.apiService.createOrgCampaign(apiHeader).then(response => {
                        //     this.campaignMessagesSent = response.totalmember
                        //     this.showCountInfo = true;
                        parent.hideCreateCampaign.emit(false);
                    } else {
                    }
                });
            }

        }).catch(error => { });

        }
        





    }

    createCampaign1() {
        this.campaignHeader.data['form']['sendAlert'] = 1;
       // console.log(this.campaignHeader);
        this.apiService.createOrgCampaign(this.campaignHeader).then(response => {
           // console.log(response);
        }).catch(error => { });
    }

    /** creating dynamic template function starts here */
    createDynamicTemplates(): void {
        this.showDynamicTemplates = !this.showDynamicTemplates;
        this.templateContent = null;
        this.selectedTemplate = null;
    }
    /** creating dynamic template function ends here */

}

