/**
 * @Date:   2018-04-24T11:51:53+05:30
 * @Project: School-Bus
 * @Filename: map.dir.ts
 * @Last modified by:   group10
 * @Last modified time: 2018-04-25T13:45:22+05:30
 * @Copyright: Group10 Technologies
 */

import { Observable, Subscription } from 'rxjs';
import { NgRedux, select } from '@angular-redux/store';
import { AppState } from './../../../store/index';
import { TrackerData_Load } from './../../../actions/tracker-data';
import { VehicleListLoad, VehicleListUpdate } from './../../../actions/vehicle-data';

import { MessageService } from './../../../services/message.service';


import { Component, Input, Output, EventEmitter, ChangeDetectorRef, ElementRef, Renderer, HostListener } from '@angular/core';
import { RouterModule, Routes, Router } from '@angular/router';
import { globalService } from './../../../services/global.service';
import { StorageService } from './../../../services/storage.service';
import { ApiService } from './../../../services/ApiService';
import { animateMarker } from './../../../services/markerAnimate';
import './markerClusterer.js';
import { map } from 'rxjs/operators';

// import { } from '@types/googlemaps';
/// <reference types="googlemaps" />

declare var io: any;
declare var MarkerClusterer: any;
declare var MarkerWithLabel:any;
declare var google: any;
@Component({
  selector: 'map-panel',
  template: `
      <div class="row map map-div-align" id="mapContainer"></div>
      <div class="halt-log"></div>
  `,
  styles: [`
    .map-div-align {
    }
    .map{
      background-color:lightgrey;
    }

`  ],
})
export class MapDir {

  @Input() mapRefreshBeat: number;

  @Input('mapHeight')
  set changeHeight(mapH: number) { // console.log('-=-=-',mapH);
    document.getElementById('mapContainer').style.height = mapH + 'vh';
    this.mapRefresh();
  }


  @Input('clickedVehicleId')
  set vehicleIdWithMapPan(vehId: any) {

    this.focusVehicle = vehId;
    this.getVehCurrentLocation(vehId);
  }



  @Output() emitNewNotif: EventEmitter<any> = new EventEmitter<any>();


  // public __mapHeight: string;
  private allDevices: any = [];
  private orgDevices: any = []; // devices containing current location.
  private globalMapObj: any;
  public schoolLat:any;
  public schoolLong:any;
  private mapBounds: any;
  private deviceMarkers: any = [];
  private deviceMarkerClusters: any;
  private globalSocketObj: any;
  private socketConnectionPermission: boolean;
  private focusVehicle: string;
  public clearBeat: any;
  private busIcon: any = {
    url: './../../../webroot/images/pin-icons/map-markers/testImage1.png',
  };


  subscription: Subscription;

  public markerInfoContent: any = []; public markerVehInfo: any = []; public markerVehLatLng: any = []; public focus_info_windows: any = [];

  geo = {
    /**
     * Calculate the bearing between two positions as a value from 0-360
     *
     * @param lat1 - The latitude of the first position
     * @param lng1 - The longitude of the first position
     * @param lat2 - The latitude of the second position
     * @param lng2 - The longitude of the second position
     *
     * @return int - The bearing between 0 and 360
     */
    bearing: function (lat1: any, lng1: any, lat2: any, lng2: any) {
      const dLon = this._toRad(lng2 - lng1);
      const y = Math.sin(dLon) * Math.cos(this._toRad(lat2));
      const x = Math.cos(this._toRad(lat1)) * Math.sin(this._toRad(lat2)) - Math.sin(this._toRad(lat1)) * Math.cos(this._toRad(lat2)) * Math.cos(dLon);
      const brng = this._toDeg(Math.atan2(y, x));
      return ((brng + 360) % 360);
    },

    /**
      * Since not all browsers implement this we have our own utility that will
      * convert from degrees into radians
      *
      * @param deg - The degrees to be converted into radians
      * @return radians
      */
    _toRad: function (deg: any) {
      return deg * Math.PI / 180;
    },

    /**
     * Since not all browsers implement this we have our own utility that will
     * convert from radians into degrees
     *
     * @param rad - The radians to be converted into degrees
     * @return degrees
     */
    _toDeg: function (rad: any) {
      return rad * 180 / Math.PI;
    },
  };

  //  @select('trackerData') public trackerData$: Observable<any>;
  @select('vehicleData') public vehicleData$: Observable<any>;

  constructor(public apiService: ApiService,
    public storage: globalService,
    public animateMarker: animateMarker,
    private cdr: ChangeDetectorRef,
    private ngRedux: NgRedux<AppState>,
    public messageService: MessageService,
    private router: Router,
    private el: ElementRef,
    private renderer: Renderer,
    public storageSrv: StorageService
  ) {
    this.subscription = this.messageService.getMessage().subscribe(message => {
      // console.log('#-#',  message.text);
      const device = message.text;
      device['deviceId'] = this.storage.data.vehicleIds[device.imei];
      // console.log('#-#', device );
      if (device['deviceId'] !== undefined) {
        this.mapUpdatePosition({ data: device });
      }
    });

    router.events.subscribe((val: any) => {
      // console.log(val.url);
      if (val.url !== '/client-dashboard') {
        // clearTimeout(this.clearBeat);
        this.clearMapBeat();
      }
    } /*whatever*/);
  }

  ngOnDestroy() {
    // unsubscribe to ensure no memory leaks
    this.subscription.unsubscribe();
  }

  ngOnInit() {
    // setTimeout( () => {
    //  this.mapBeat();
    // }, 5000 );
    // this.haltrepofunc();
    this.getSchoolLatLong();
  }
  getSchoolLatLong(){ 

    const apiHeader = {
      'data': {
        'key': localStorage.getItem('scbToken'),
        projections: ["orgLocation"],
      }
    };

    this.apiService.getCurrentOrgDetails(apiHeader).then(response => {
     
      this.schoolLat=response.response.orgLocation.lattitude;
      this.schoolLong=response.response.orgLocation.longitude;
      // this.allDevices = response.response;
      // this.getCurrentLocation();
    }).catch(error => { });
  }
  clearMapBeat() {
    clearTimeout(this.clearBeat);
  }

  mapBeat() {
    // clearTimeout(this.clearBeat);
    this.clearMapBeat();

    const apiHeader = {
      'data': {
        'key': localStorage.getItem('scbToken')
      }
    };

    const getCurrentLocParam = apiHeader;

    this.apiService.getCurrentLocation(getCurrentLocParam).then(response => {
      (response.response).forEach((itm: any) => {
        const device = itm;
        device['deviceId'] = this.storage.data.vehicleIds[device.imei];
        // console.log('#-#', device );
        if (device['deviceId'] !== undefined && device['deviceId'] !== 'undefined') {
          // console.log(device);

          this.storage.data.vehicleIds[itm['imei']] = itm['deviceId'];
          itm['VehicleNo'] = this.storage.data['vehicleLists'][itm['imei']];

          this.mapUpdatePosition({ data: device });
          try{
            if(itm['routeWithTime']){
              let ascTiming:any = Object.keys(itm['routeWithTime']).map((time:any)=> itm['routeWithTime'][time]);
              if(ascTiming.length>0){
                ascTiming = ascTiming.sort((a:any, b:any) => parseFloat(a.replace(':', '.')) - parseFloat(b.replace(':', '.')) );
                itm['routeAssigned'] = [];
                ascTiming.map((item:any)=>{
                  Object.keys(itm['routeWithTime']).map((name:any) => { 
                    if(itm['routeWithTime'][name]==item) 
                    itm['routeAssigned'].push(name) 
                  } );
                })
              }
            }
          }catch(err){
            console.log(err);
          }    

          setTimeout(() => {
            this.ngRedux.dispatch({
              type: VehicleListUpdate,
              payload: itm
            });
          }, 100);

        }
      });

    }).catch((err) => {
      // console.log('error');
    });

    this.clearBeat = setTimeout(() => { this.mapBeat(); }, 10000);

  }

  ngAfterViewInit() {
    this.initMap();
    this.getAllDevices();
    setTimeout(() => { this.mapBeat(); }, 50000);
  }

  ngAfterViewChecked() {
    this.cdr.detectChanges();

  }

  mapRefresh() {
    google.maps.event.trigger(this.globalMapObj, 'resize');
  }

  @HostListener('click') onMouseOver() {
    // let part = this.el.nativeElement.querySelector('.card-text');
    // this.renderer.setElementStyle(part, 'display', 'block');
    this.haltrepofunc();
  }

  initMap(): void {
    const mapOptions = {
      scrollwheel: true,
      zoom: 12,
      maxZoom: 17,
      center: new google.maps.LatLng(28.7041, 77.1025),
      disableDefaultUI: false
    };
    this.globalMapObj = new google.maps.Map(document.getElementById('mapContainer'), mapOptions);

    const trafficLayer = new google.maps.TrafficLayer();
    trafficLayer.setMap(this.globalMapObj);


    const parent = this;

    this.globalMapObj.addListener('center_changed', () => {
      if (this.focusVehicle !== undefined) {
        /* console.log(
           'center_changed', parent.focusVehicle['vehId'], parent.markerVehInfo[ parent.focusVehicle['vehId'] ] , this.markerVehLatLng);
           */
        // vehId


        const look_device_itm: any = (this.ngRedux.getState().vehicleData).filter((item: any) => {
          return item.deviceId === this.focusVehicle['vehId'];
        });


        const mLatlng_upd = new google.maps.LatLng(look_device_itm[0].location.lat, look_device_itm[0].location.lng);
        const date_month = new Date(look_device_itm[0].gpsTimestamp).toLocaleDateString('en-US');
        const date_day = new Date(look_device_itm[0].gpsTimestamp).toLocaleTimeString('en-US');
        const deviceLastUpdated = date_month + ' ' + date_day;
        // look_device_itm[0].gpsDate + ', ' + look_device_itm[0].gpsTime;
        const html = '<b>'
          + look_device_itm[0].VehicleNo + '</b><br/> Updated on: '
          + deviceLastUpdated + '<br/>Speed: '
          + look_device_itm[0].speed + ' Km/h <br>'
          + '<a class="pointer" id="halt_' + this.focusVehicle['vehId'] + '" >Halt Report</a>'
          + ' &nbsp; <a class="pointer" id="movement_' + this.focusVehicle['vehId'] + '" >Movement Report</a>';
        //  look_device_itm[0].VehicleNo
        const marker_focus = new google.maps.Marker({
          position: mLatlng_upd, // this.markerVehLatLng[ this.focusVehicle['vehId'] ],
          draggable: false,
          zIndex: google.maps.Marker.MAX_ZINDEX + 1,
          // icon: {
          //   path: google.maps.SymbolPath.CIRCLE,
          //   fillColor: '#00F',
          //   fillOpacity: 0,
          //   strokeColor: '#00A',
          //   strokeOpacity: 0,
          //   strokeWeight: 1,
          //   scale: 30
          // },
          icon: this.getTransparentMarker(),
          map: this.globalMapObj
        });

        (this.focus_info_windows).forEach((windowObj: any) => {
          windowObj.close();
        });

        // const desc = '<div href="#" class="focus-info-window" data-id = "'
        //               + parent.focusVehicle['vehId']  + '" id="live-' + parent.focusVehicle['vehId'] + '">'
        //               + parent.markerVehInfo[ parent.focusVehicle['vehId'] ] + '</div>';



        const infoWindow = new google.maps.InfoWindow({
          content: html // this.markerInfoContent[ this.focusVehicle['vehId']  ]
        });

        infoWindow.addListener('domready', () => {
          document.getElementById('halt_' + this.focusVehicle['vehId']).addEventListener('click', () => {
            this.storageSrv.set({
              report: 'Halt Log',
              vehicleId: this.focusVehicle['vehId'],
              vehicleName: look_device_itm[0].VehicleNo,
            });
            window.location.href = '#/movementReport';
          });
          document.getElementById('movement_' + this.focusVehicle['vehId']).addEventListener('click', () => {
            this.storageSrv.set({
              report: 'Movement Log',
              vehicleId: this.focusVehicle['vehId'],
              vehicleName: look_device_itm[0].VehicleNo,
            });
            window.location.href = '#/movementReport';
          });
        });

        google.maps.event.addListener(infoWindow, 'closeclick', () => {
          this.focusVehicle = undefined;
          if (this.focus_info_windows !== undefined) {
            (this.focus_info_windows).forEach((windowObj: any) => {
              windowObj.close();
            });
          }
          // currentMark.setMap(null); // removes the marker
          // then, remove the infowindows name from the array
        });

        infoWindow.open(this.globalMapObj, marker_focus);
        this.focus_info_windows.push(infoWindow);

      }
    });



  }

  getAllDevices() { // getting all devices for an org.
    const apiHeader = {
      'data': {
        'key': localStorage.getItem('scbToken')
      }
    };
    this.apiService.getOrgVehicles(apiHeader).then(response => {
      this.allDevices = response.response;
      this.getCurrentLocation();
    }).catch(error => { });

  }

  getCurrentLocation() { // Gettings current location of all active trackers in an org.





    const apiHeader = {
      'data': {
        'key': localStorage.getItem('scbToken')
      }
    };



    this.apiService.getOrgVehicles(apiHeader).then(response => {
      const APIresponse = response.response;

      this.ngRedux.dispatch({
        type: VehicleListLoad,
        payload: APIresponse
      });

    }).catch(error => { });

    this.apiService.getVehicleId(apiHeader).then(response => {
      this.storage.data.vehicleLists = response.response;
    }).catch(error => { });



    const getCurrentLocParam = apiHeader;
    // getCurrentLocParam.data['getLatestPosition'] = 1;
    this.storage.data.vehicleIds = [];
    this.apiService.getCurrentLocation(getCurrentLocParam).then(response => {
      (response.response).forEach((itm: any) => {

        this.storage.data.vehicleIds[itm['imei']] = itm['deviceId'];
        itm['VehicleNo'] = this.storage.data['vehicleLists'][itm['imei']];
        try{
          if(itm['routeWithTime']){
            let ascTiming:any = Object.keys(itm['routeWithTime']).map((time:any)=> itm['routeWithTime'][time]);
            if(ascTiming.length>0){
              ascTiming = ascTiming.sort((a:any, b:any) => parseFloat(a.replace(':', '.')) - parseFloat(b.replace(':', '.')) );
              itm['routeAssigned'] = [];
              ascTiming.map((item:any)=>{
                Object.keys(itm['routeWithTime']).map((name:any) => { 
                  if(itm['routeWithTime'][name]==item) 
                  itm['routeAssigned'].push(name) 
                } );
              })
            }
          }
        }catch(err){
          console.log(err);
        }    
        this.ngRedux.dispatch({
          type: VehicleListUpdate,
          payload: itm
        });
      });


      this.orgDevices = [];
      (this.vehicleData$).forEach((vehData: any) => {
        (vehData).forEach((device: any) => {
          this.orgDevices.push(device);
        });
      });
      this.renderDevices(this.orgDevices);

      // console.log( '^^^', this.storage.data );


    }).catch(error => { });



    // getCurrentLocation BEAT() dont do this here
    // setTimeout( () => { this.getCurrentLocation(); }, 50000 );




  }



  renderDevices(paramDevices: any) { // Plotting device markers in map.

    let parent = this;
    // var bus = "assets/webroot/images/bus/w.png";
    this.mapBounds = new google.maps.LatLngBounds();
    // console.log(paramDevices);
    for (const device of paramDevices) {
      // cond to check only showing lat lng data;
      
      if(device.location){
        // console.log(device.location)
      const mLatlng = new google.maps.LatLng(device.location.lat, device.location.lng);
      // console.log(device.location.lat, device.location.lng)
      // const imei = device.imei;
      //  carIcon.rotation = this.geo.bearing(0, 0, device.location.lat, device.location.lng);
      var marker;
     
      // marker = new google.maps.Marker({
      //   position: mLatlng,
      //   draggable: false,
      //   zIndex: google.maps.Marker.MAX_ZINDEX + 1,
      //   icon: this.getMarkerImgPath(device, 0),
      //   // icon: carIcon,
      //   // icon : bus,
      //   map: this.globalMapObj,
      //   index: device.deviceId,
      // });
      //console.log("device.deviceId "+device.currentRouteAssigned)
      if (device.currentRouteAssigned == '' ) { 

        marker = new google.maps.Marker({
        position: mLatlng,
        draggable: false,
        zIndex: google.maps.Marker.MAX_ZINDEX + 1,
        icon: this.getMarkerImgPath(device, 0),
        // icon: carIcon,
        // icon : bus,
        map: this.globalMapObj,
        index: device.deviceId,
      });
      }else{ 
        
       // let distanceCalc=this.geo.bearing(this.schoolLat,this.schoolLong,device.location.lat, device.location.lng);
      
        let distCalc=this.distance(this.schoolLat,this.schoolLong,device.location.lat, device.location.lng);
       
        if(distCalc>1){
          marker =  new MarkerWithLabel({
      
            position: mLatlng,
            draggable: false,
            zIndex: google.maps.Marker.MAX_ZINDEX + 1,
            icon: this.getMarkerImgPath(device, 0),
            // icon: carIcon,
            // icon : bus,
            map: this.globalMapObj,
            index: device.deviceId,
            labelContent:device.currentRouteAssigned,
            labelClone : device.currentRouteAssigned,
            labelAnchor: new google.maps.Point(30, 30),
            // labelClass: "map-marker-label", // existing old code 
            labelClass: "map-marker-label",
            });
       }else{ 
        marker = new google.maps.Marker({
          position: mLatlng,
          draggable: false,
          zIndex: google.maps.Marker.MAX_ZINDEX + 1,
          icon: this.getMarkerImgPath(device, 0),
          // icon: carIcon,
          // icon : bus,
          map: this.globalMapObj,
          index: device.deviceId,
        });
         
       }
        
      }


       

      this.mapBounds.extend(mLatlng);
      this.deviceMarkers.push(marker);
      //   this.deviceMarkers[  device.deviceId ] = marker;

      const infoWindowContent = '<div class="window-content-container">' + device.location.lat + '</div>';
      const infoWindow = new google.maps.InfoWindow({
        content: infoWindowContent
      });

      try{
        if(device.deviceId){
      infoWindow.addListener('domready', () => {
        let haltDevEl =  document.getElementById('halt_' + device.deviceId);
        if(haltDevEl){
          haltDevEl.addEventListener('click', () => {
            this.storageSrv.set({
              report: 'Halt Log',
              vehicleId: device.deviceId,
              vehicleName: deviceDetails.VehicleNo,
            });
            window.location.href = '#/movementReport';
          });
        }
       let movementDevEl = document.getElementById('movement_' + device.deviceId);
       if(movementDevEl){
         movementDevEl.addEventListener('click', () => {
          this.storageSrv.set({
            report: 'Movement Log',
            vehicleId: device.deviceId,
            vehicleName: deviceDetails.VehicleNo,
          });
          window.location.href = '#/movementReport';
        });
       }
        let movementAndHaltEl = document.getElementById('movementandhalt_' + device.deviceId);
        if(movementAndHaltEl){
          movementAndHaltEl.addEventListener('click', () => {
            this.storageSrv.set({
              report: 'Movement & Halt log',
              vehicleId: device.deviceId,
              vehicleName: deviceDetails.VehicleNo,
            });
            window.location.href = '#/movementReport';
          });
        }
      }); 
    }
    }catch(e){   }
      // console.log( '#', device );
      let deviceDetails = this.allDevices.filter(function (index: any, value: any) { return index.deviceId === device.deviceId; });
      deviceDetails = deviceDetails[0];
      
      if (deviceDetails.VehicleNo !== undefined && deviceDetails.VehicleNo !== 'undefined') {
        
       
       if(device.currentRouteAssigned==''){ 

       }else{
      
        // let distanceCalc=this.geo.bearing(this.schoolLat,this.schoolLong,device.location.lat, device.location.lng);
      
     //  let distCalc=this.distance(this.schoolLat,this.schoolLong,device.location.lat, device.location.lng);
      
      // if(distanceCalc>1){
      //   var infowindow = new google.maps.InfoWindow({
      //     content:"Veh Name  : "+deviceDetails.VehicleNo+"<br> Route Name : "+device.currentRouteAssigned,
      //     buttons: { 
      //       close: { visible: false }  
      //    } 
          
      //  });
      
      //  infowindow.open(this.globalMapObj,marker);
     // }
       }
        const date_month_initial = new Date(device.gpsTimestamp).toLocaleDateString('en-US');
        const date_day_initial = new Date(device.gpsTimestamp).toLocaleTimeString('en-US');
        const deviceLastUpdated_initial = date_month_initial + ' ' + date_day_initial;


        const deviceLastUpdated = deviceLastUpdated_initial; // device.gpsDate + ', ' + device.gpsTime;

        const infoHTML = '<b>'
          + deviceDetails.VehicleNo + '</b><br/> Updated on: '
          + deviceLastUpdated + '<br/>Speed: '
          + device.speed + ' Km/h' + '<br>'
          // + '<a class="halt-log" id="' + deviceDetails.VehicleNo + '">Halt Report</a>';
          + '<a class="pointer" id="halt_' + device.deviceId + '" >Halt Report</a>'
          + ' &nbsp; <a class="pointer" id="movement_' + device.deviceId + '" >Movement Report</a>'
          + ' &nbsp; <a class="pointer" id="movementandhalt_' + device.deviceId + '" >Movement and Halt Report</a>';



        this.markerInfoContent[deviceDetails.deviceId] = infoHTML;

        this.markerVehInfo[deviceDetails.deviceId] = infoHTML;
        this.markerVehLatLng[deviceDetails.deviceId] = new google.maps.LatLng(device.location.lat, device.location.lng);

        const parent = this;

        marker.addListener('mouseover', function () {
          (parent.focus_info_windows).forEach((windowObj: any) => {
            windowObj.close();
          });
          // infoWindow.close();
          const desc = '<div href="#" data-id = "' + deviceDetails.deviceId + '" id="live-' + deviceDetails.deviceId + '">'
            + parent.markerInfoContent[deviceDetails.deviceId] + '</div>';
          infoWindow.setContent(desc);
          infoWindow.open(this.globalMapObj, this);
          parent.focus_info_windows.push(infoWindow);
        });
        marker.addListener('click', function () {

          (parent.focus_info_windows).forEach((windowObj: any) => {
            windowObj.close();
          });

          const desc = '<div href="#" data-id = "' + deviceDetails.deviceId + '" id="live-' + deviceDetails.deviceId + '">'
            + parent.markerInfoContent[deviceDetails.deviceId] + '</div>';
          infoWindow.setContent(desc);
          infoWindow.open(this.globalMapObj, this);
          parent.focus_info_windows.push(infoWindow);
        });
        // marker.addListener('mouseup', function () {
        //   const desc = '<div href="#" data-id = "' + deviceDetails.deviceId + '" id="live-' + deviceDetails.deviceId + '">'
        //     + parent.markerInfoContent[deviceDetails.deviceId] + '</div>';
        //   infoWindow.setContent(desc);
        //   infoWindow.open(this.globalMapObj, this);
        // });


        marker.addListener('mouseout', function () {
          // infoWindow.close();
        });

      } // ! undefined
    }//end of if cond lat lng
    }
    this.globalMapObj.fitBounds(this.mapBounds);


    const clusteringOptions = {
						
      //imagePath: 'https://github.com/googlemaps/v3-utility-library/tree/master/markerclusterer/images',
    styles:[{

    url: "assets/webroot/images/map/map_ic3/m1.png",
          width: 53,
          height:53,
          textSize:12,
          textColor:"white",
    }],
    minimumClusterSize:3,
    maxZoom:14
};

    this.deviceMarkerClusters = new MarkerClusterer(
      this.globalMapObj, this.deviceMarkers,
      //{ maxZoom: 16, imagePath: 'https://googlemaps.github.io/js-marker-clusterer/images/m' }
      clusteringOptions
    );



  }

  @Input('mapSocket')
  set mapSocketFunc(e: any) {
    // this.mapUpdatePosition(e);
  }

  haltrepofunc() {



    let haltReport = document.querySelector('.halt-log');

    haltReport.addEventListener('click', (e: any) => {

      // console.log('haltReport triggered', e.target.id)



    });

  }



  mapUpdatePosition(e: any) {
    // bottle neck
    const device: any = e['data'];
    if (device.vehNo !== '') {
      device.VehicleNo = device.vehNo;
    }
    if (device.VehicleNo !== undefined && device.VehicleNo !== 'undefined') {
      // console.log( '@@',device,"###",this.markerInfoContent[device.deviceId] ,"###");
      const deviceLastUpdated = device.gpsDate + ', ' + device.gpsTime;
      this.markerInfoContent[device.deviceId] = '<b>'
        + device.VehicleNo + '</b><br/> Updated on: '
        + deviceLastUpdated + '<br/>Speed: '
        + device.speed + ' Km/h <br/>'
        // + '<a class="halt-log" id="' + deviceDetails.VehicleNo + '">Halt Report</a>';
        + '<a class="pointer" id="halt_' + device.deviceId + '" >Halt Report</a>'
        + ' &nbsp; <a class="pointer" id="movement_' + device.deviceId + '" >Movement Report</a>';
      // console.log('######', deviceDetails);

      if (e && device && device.location) {
        const Imei = device.imei;
        const marker = this.deviceMarkers.filter((el: any) => {
          // console.log(  el.index ,this.storage.data,   Imei  );
          return el.index === device.deviceId; // this.storage.data.vehicleIds[ Imei ];
        });
        if (marker[0]) { // if device exists then carry operations.

          const mlatLng = new google.maps.LatLng(device.location.lat, device.location.lng);

          this.markerVehLatLng[device.deviceId] = mlatLng;


          let bearing = this.geo.bearing(
            marker[0].getPosition().lat(),
            marker[0].getPosition().lng(),
            device.location.lat, device.location.lng
          );
          bearing = Math.round(bearing);
          marker[0].setIcon(this.getMarkerImgPath(device, bearing));


          const deviceDetails = e.data;



          //   document.getElementById('live-' + deviceDetails.deviceId ).innerHTML = desc;

          this.animateMarker.animateTo(marker[0], mlatLng, { easing: 'linear', duration: 2000 });
        } else {
          // console.log('marker-unavailable');
        }
      }

    } else { // ! undefined
      // console.log(device);
    }
  }

  getTransparentMarker() {
    
      return {
        url: 'assets/webroot/images/map/trans.png'
      };
   
  }
  getMarkerImgPath(device: any, bearing = 0) {
    let overspeed = this.storageSrv.get('overspeed');
    let markerIcon = {};

    if (device.speed >= 0) {
      markerIcon = {
        url: 'assets/webroot/images/map/bus_red.png?ang=' + (bearing),
        rotation: bearing,
      };
    }

    if (device.speed >= 5) {
      markerIcon = {
        url: 'assets/webroot/images/map/marker-icon-green.png?ang=' + (bearing), // this.busUrlRotate( 50 ),
        //  origin: new google.maps.Point(0, 100), // origin
        rotation: bearing,
        // anchor: new google.maps.Point(0, 0) // orig 10,50 back of car, 10,0 front of car, 10,25 center of car
      };
    }
    if (overspeed && device.speed >= overspeed) {
      markerIcon = {
        url: 'assets/webroot/images/map/marker-icon-blue.png?ang=' + (bearing),
        rotation: bearing,
      };
    }
    // console.log(markerIcon,overspeed);
   
    return markerIcon;
  }
  distance(lat1:any,lon1:any,lat2:any,lon2:any) {
    if ((lat1 == lat2) && (lon1 == lon2)) {
      return 0;
    }
    else {
      let R = 6378137;
    let dLat = this.degreesToRadians(lat2 - lat1);
    let dLong = this.degreesToRadians(lon2 - lon1);
    let a = Math.sin(dLat / 2)
            *
            Math.sin(dLat / 2) 
            +
            Math.cos(this.degreesToRadians(lat1)) 
            * 
            Math.cos(this.degreesToRadians(lat1)) 
            *
            Math.sin(dLong / 2) 
            * 
            Math.sin(dLong / 2);

    let c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    let distance = R * c;

    return distance * 0.001;
    }
  }
  degreesToRadians(degrees:any){
    return degrees * Math.PI / 180;
}



  getVehCurrentLocation(vehObj: any) {
    // console.log(vehObj);
    if (vehObj) {
      const desiredImei = this.orgDevices.filter(function (index: any) {
        // console.log('click',vehObj);
        return index.deviceId === vehObj.vehId;
      });
      if (desiredImei.length) {


        const look_deviceId = desiredImei[0]['deviceId']; //  this.storage.data.vehicleIds[ desiredImei[0]['imei'] ];


        const look_device_itm: any = (this.ngRedux.getState().vehicleData).filter((item: any) => {
          // console.log(item.deviceId ,'===', look_deviceId);
          return item.deviceId === look_deviceId;
        });

        // console.log(look_device_itm[0]);
        // if(look_device_itm[0].location){

        const mLatlngg = new google.maps.LatLng(look_device_itm[0].location.lat, look_device_itm[0].location.lng);
        const bounds = new google.maps.LatLngBounds();
        bounds.extend(mLatlngg);
        this.globalMapObj.fitBounds(bounds);
        this.globalMapObj.panTo(mLatlngg);
        // }

      }
    }
  }




}

/*
Notes : npm install node-sass -g
*/
