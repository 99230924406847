
import { Component,
    ElementRef,
    AfterViewInit,
    OnDestroy,
    ViewChild,
} from '@angular/core';
import { RouterModule, Routes, Router } from '@angular/router';
import { AppComponent } from './../../../app.component';

import { globalService } from './../../../services/global.service';
import { ApiService } from './../../../services/ApiService';
import { authGaurd } from './../../../services/auth-guard.service';

import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { NgZone } from '@angular/core';
import { StorageService } from '../../../services/storage.service';

const swal = require('sweetalert');



@Component({
 // template: ``,


templateUrl: './adminDashboard.component.html',
styles: [`
  @media (min-width: 768px){
  .circle-tile {
      margin-bottom: 30px;
  }
  }

  .circle-tile {
      margin-bottom: 15px;
      text-align: center;
  }

  .circle-tile-heading {
      position: relative;
      width: 80px;
      height: 80px;
      margin: 0 auto -40px;
      border: 3px solid rgba(255,255,255,0.3);
      border-radius: 100%;
      color: #fff;
      transition: all ease-in-out .3s;
  }

  /* -- Background Helper Classes */

  /* Use these to cuztomize the background color of a div. These are used along with tiles, or any other div you want to customize. */

   .dark-blue {
      background-color: #34495e;
  }

  .green {
      background-color: #16a085;
  }

  .blue {
      background-color: #2980b9;
  }

  .orange {
      background-color: #f39c12;
  }

  .red {
      background-color: #5bc0de;
  }

  .purple {
      background-color: #f5f5f5;
  }

  .dark-gray {
      background-color: #7f8c8d;
  }

  .gray {
      background-color: #95a5a6;
  }

  .light-gray {
      background-color: #bdc3c7;
  }

  .yellow {
      background-color: #f1c40f;
  }

  /* -- Text Color Helper Classes */

   .text-dark-blue {
      color: #34495e;
  }

  .text-green {
      color: #16a085;
  }

  .text-blue {
      color: #2980b9;
  }

  .text-orange {
      color: #f39c12;
  }

  .text-red {
      color: #e74c3c;
  }

  .text-purple {
      color: #5794a6;
  }

  .text-faded {
      color: rgba(255,255,255,0.7);
  }



  .circle-tile-heading .fa {
      line-height: 80px;
  }

  .circle-tile-content {
      padding-top: 50px;
  }
  .circle-tile-description {
      text-transform: uppercase;
  }

  .text-faded {
      color: rgba(255,255,255,0.7);
  }

  .circle-tile-number {
      padding: 5px 0 15px;
      font-size: 26px;
      font-weight: 700;
      line-height: 1;
  }

  .circle-tile-footer {
      display: block;
      padding: 5px;
      color: rgba(255,255,255,0.5);
      background-color: rgba(0,0,0,0.1);
      transition: all ease-in-out .3s;
  }

  .circle-tile-footer:hover {
      text-decoration: none;
      color: rgba(255,255,255,0.5);
      background-color: rgba(0,0,0,0.2);
  }

  .main-panel {
      position: relative;
      z-index: 2;
      float: none;
      overflow: hidden;
      width: calc(100% - 260px);
      min-height: 100%;
  }

  .card {
      display: inline-block;
      position: relative;
      width: 100%;
      margin: 25px 0;
      box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.14);
      border-radius: 3px;
      color: rgba(0,0,0, 0.87);
      background: #fff;
  }


  .card .card-header {
      box-shadow: 0 10px 30px -12px rgba(0, 0, 0, 0.42), 0 4px 25px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2);
      margin: -20px 15px 0;
      border-radius: 3px;
      padding: 15px;
      background-color: #6b838f;
  }

  .card-nav-tabs .nav-tabs-title {
      float: left;
      padding: 10px 10px 10px 0;
      line-height: 24px;
  }

  .card-nav-tabs .nav-tabs {
      background: transparent;
      padding: 0;
  }

  .nav-tabs {
      background: #9c27b0;
      border: 0;
      border-radius: 3px;
      padding: 0 15px;
  }
  .nav-tabs > li.active > a, .nav-tabs > li.active > a:hover, .nav-tabs > li.active > a:focus {
      background-color: rgba(255, 255, 255, 0.2);
      transition: background-color .1s .2s;
  }

  .nav-tabs > li > a, .nav-tabs > li > a:hover, .nav-tabs > li > a:focus {
      background-color: transparent;
      border: 0 !important;
      color: #FFFFFF !important;
      font-weight: 500;
  }
  .text-faded {
      color: rgba(255,255,255,1);
  }

  `],

})
export class adminDashboardComponent {

 public totalCount:any=[];
 public dashboardCount:any={};


 constructor(public apiService: ApiService,
            public authGaurd : authGaurd,
            public router: Router,
            private _ngZone: NgZone,
            private service: globalService,
            private storage: StorageService
        ) {


                // Observable.create((observer: any) => {
                //     observer.next('Hello')
                // }).pipe(map((val: any) => val.toUpperCase()))
                //   .subscribe((x: any) => this.addItem(x))

                
                // Observable.create((observer:any) => {
                //     let timeout:any = null;
                //     this._ngZone.runOutsideAngular(() => {
                //     timeout = setTimeout(() => {
                //         observer.next('Hello');
                //         observer.complete('completed');
                //         return () => {
                //         if (timeout) {
                //             clearTimeout(timeout);
                //         }
                //         };
                //     },1000);
                //     });
                // }).subscribe((x:any)=> this.addItem(x));
         this.storage.set({ 
             'orgID' : '' 
        });

 }

 ngOnInit() {
   this.authGaurd.checkAdminToken();
   this.DashboardItemCount();
 }

 redirectToOrgView(){
   this.router.navigate(['sysAdmin/view-admin-organization'],{});
 }


 DashboardItemCount() {
   let parent: any = this;
   var countObj = {
     data: {
      key: localStorage.getItem('adminToken')
    }
   };
   this.apiService.viewAdminCount(countObj).then(response => {
     let result=response;
     if(result.status=="success"){
       if (result.response!=null && result.response!=undefined && result.response!='') {
         this.totalCount = response.response;
         this.dashboardCount=this.totalCount.AdminStats;
       }
     }
   }).catch(error => { });
 }



addItem(val: any) {
    // console.log('val', val);
}







}
