
import { animate, state, style, transition, trigger } from '@angular/animations';
import { AfterViewInit, Component, Input, OnDestroy, OnInit, Output, EventEmitter } from '@angular/core';
import { mapStyle } from './mapStyle';
import { MapService } from './map.service';

import { WayPoint, Location, Singlewaypoint, LatLong, WayPointDataFormat, Weeklyperf, WaypointsData } from './map.interface';

// import * as L from 'leaflet';
import "./../../../assets/js/leaflet_plugins/leaflet_pulse";

import { BehaviorSubject, from, Observable, Subscription } from 'rxjs';
import { map, toArray } from 'rxjs/operators';
import { colorLine } from './lineFile.config'; //'../map-based-analytic/lineFile.config';
declare const L: any;
declare const google: any;
declare const turf: any;
declare const ExcelJS: any;
declare const saveAs: any;


// https://github.com/mapshakers/leaflet-icon-pulse


@Component({
    selector: 'app-map-based-analytic',
    templateUrl: './map-based-analytic.component.html',
    styleUrls: ['./map-based-analytic.component.css'],
})
export class MapBasedAnalyticComponent implements OnInit, AfterViewInit, OnDestroy {
    selectedRouteName: string = '';
    waypointleg: Promise<any>;
    get weeklyperf(): Weeklyperf {
        return this._weeklyperf;
    }
    @Input() set weeklyperf(value: Weeklyperf) {
        this._weeklyperf = value;
        this.perf = value.performance;
    }
    get allRouteInfo(): any {
        return this._allRouteInfo;
    }
    @Input() breakPickupPoint: any;
    @Input() set allRouteInfo(value: any) {

        if (value) { console.log('value---',value)
            this.organizePickupInfo(value);
        }
    }
    @Input('orgLocation') set orgLocation(value: any) {
        if (value) {
            this.setMarkerForSchool(value);
        }
    }

    @Input('movementReport') set movementReport(value: any) {
        if (value) {

            this.mapService.retriveLatLongFromMovementReport(value).then((data: any) => {
                this.clearPolyLine();
                setTimeout(() => {
                    this.plotLine(data);
                }, 0);
            });
        }
    }
    @Output() emit = new EventEmitter();
    @Input() showClose: any;
    close() {

        this.emit.emit(false);
    }

    constructor(public mapService: MapService) { }


    perf: string;
    // tslint:disable-next-line: variable-name
    _weeklyperf: Weeklyperf;
    leafletMapData: any = new BehaviorSubject([]);
    dataForWaypointApi: any = new BehaviorSubject([]);
    localSubscription: Subscription[] = [];
    polyLine: any;
    summaryData = '';
    waypointResponse: any = [];
    miniLeafletMarker: any;
    colorLine: any = colorLine;
    public finalObj = {}
    // tslint:disable-next-line: variable-name
    _allRouteInfo: any;

    selectedPickupPoint: string = "";
    miniLeafletMap: any = null;

    waypointRequest = true;
    /*

    * expectedTime = str.slice(0, 2) + ':'+str.slice(2,4)

    */
    totbad = 0;
    totavg = 0;
    collectionsofBadPoints: any = [];
    collectionsofAveragePoints: any = [];

    time: boolean = false;
    location: boolean = false;


    ngOnInit(): void {
        // console.log('showClose', this.showClose,this._allRouteInfo)
    }
    ngAfterViewInit(): void {
        this.initMap();

        setTimeout(() => {
            this.requestWayPoint();
            this.plotMarker();
        }, 1.0 * 1000);
    }
    ngOnDestroy(): void {
        this.localSubscription.forEach((item: Subscription) => item.unsubscribe());
    }

    schoolMarker: any = null;
    schoolLatLong: any = null;
    setMarkerForSchool(value: any) {
        // console.log(value);

        let lat: number = value.lattitude ? Number(value.lattitude) : null;
        let lng: number = value.longitude ? Number(value.longitude) : null;
        if (lat && lng) {
            this.schoolLatLong = [];
            this.schoolLatLong.push([lat, lng]);
            const greenIcon = L.divIcon({
                // html: '<img src="assets/images/school_start.png" width="20" height="20" style="position:absolute;left:-5px"></i>',
                html: '<i class="fa fa-flag-checkered" aria-hidden="true" style="font-size:20px"></i>',
                iconSize: [0, 35],
                className: 'myDivIcon'
            });
            let schoolMarker: any = L.marker([lat, lng], { icon: greenIcon }).bindPopup(`<h4>School</h4>`).openPopup();
            this.schoolMarker = L.layerGroup([schoolMarker]);

            setTimeout(() => {
                this.schoolMarker.addTo(this.miniLeafletMap);
            }, 0);
        }

    }
    organizePickupInfo(value: any) {

        let breakPoint = { ...value[1] };
        let temp = [...value[0]];
        value = temp;
        this.selectedPickupPoint = breakPoint.pickupName;
        this.selectedRouteName = breakPoint.RouteName;
        // console.log(breakPoint);
        // console.log(this.selectedPickupPoint);

        setTimeout(() => {
            let leafletMapData = [];
            let dataForWaypointApi = [];
            return Object.keys(value).length && from(Object.keys(value)).pipe(map(key => {
                const location = {
                    lat: parseFloat(value[key].location.lat),
                    lng: parseFloat(value[key].location.lng),
                };
                const temp = { perf: value[key].color, location };
                const data = { ...value[key], ...temp };
                return data;
            }), toArray()).subscribe((item) => {
                item.sort((a: any, b: any) => {
                    const aETA = Number(a.expectedTM);
                    const bETA = Number(b.expectedTM);
                    return aETA < bETA ? -1 : 1;
                });
                // console.log(item);
                setTimeout(() => {

                    this.summaryData = '';

                    let temp = [];
                    for (let i of item) {
                        // console.log(i, breakPoint)
                        if (i.pickupID === breakPoint.pickupID) {
                            temp.push(i); break;
                        } else temp.push(i);
                    }
                    item = temp;
                    this._allRouteInfo = item;
                    let lastLat = item[item.length - 1]['location']['lat'];
                    let lastLng = item[item.length - 1]['location']['lng'];


                    this.miniLeafletMap.flyTo([lastLat, lastLng], 12)
                    dataForWaypointApi = item.map(k => [k.location.lat, k.location.lng]);
                    // console.log(dataForWaypointApi);
                    leafletMapData = item.map(k => ({ location: k.location, data: k, perf: k.perf }));
                    this.leafletMapData.next(leafletMapData);
                    this.dataForWaypointApi.next(dataForWaypointApi);
                }, 1 * 1000);

            }).unsubscribe();
        }, 600);
    }


    initMap() {
        this.miniLeafletMap = L.map('map', {
            center: [28.7041, 77.1025],
            zoom: 12,
            attributionControl: false,
            zoomControl: true
        });
        const Stamen_TonerLite = L.tileLayer('http://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}.png', {
            attribution: 'Map tiles by <a href="http://stamen.com">Stamen Design</a>, <a href="http://creativecommons.org/licenses/by/3.0">CC BY 3.0</a> &mdash; Map data &copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>',
            subdomains: 'abcd',
            minZoom: 0,
            maxZoom: 20,
            ext: 'png'
        }).addTo(this.miniLeafletMap);

    }



    clearPolyLine() {
        this.miniLeafletMarker && this.polyLine && this.polyLine.removeFrom(this.miniLeafletMap);
        // this.miniLeafletMap && this.polyLine && this.polyLine.removeFrom(this.miniLeafletMap);
    }
    clearMarker() {
        this.miniLeafletMarker && this.miniLeafletMarker.removeFrom(this.miniLeafletMap);
        // this.miniLeafletMap && this.miniLeafletMarker && this.miniLeafletMarker.removeFrom(this.miniLeafletMap);
    }

    plotMarker() {
        this.localSubscription[1] = this.leafletMapData.asObservable().subscribe({
            next: (markerData: any) => {
                this.miniLeafletMarker && this.clearMarker();
                setTimeout(() => {
                    this.markerLayer(markerData);
                    // this.dataAnalyzer(markerData)
                }, 100);
            },
            complete: () => { },
            error: () => { }
        });
    }

    requestWayPoint() {
        const directionsService = new google.maps.DirectionsService();

        this.localSubscription[0] = this.dataForWaypointApi.asObservable().subscribe({
            next: (wayPointData: any) => {
                // console.log(wayPointData, this.waypointRequest)

                if (wayPointData && this.waypointRequest && wayPointData.length) {
                    // console.log(wayPointData, this.waypointRequest, this.schoolLatLong)
                    wayPointData.unshift(this.schoolLatLong[0]);
                    console.log('wayPointData',wayPointData)
                    // this.polyLine && this.clearPolyLine();
                    // this.waypointResponse = this.mapService.simulteResponse();
                    // if (this.waypointResponse.legs) { this.mapService.retriveLatLongsOnly(this.waypointResponse.legs); }
                    // console.log(wayPointData);

                    this.mapService.getWayPoints(directionsService, wayPointData).then((data: any) => {
                        console.log('waypoint-data', data);
                        if (data) {
                            this.waypointResponse = data;
                            if (this.waypointResponse.legs) {
                                this.mapService.retriveLatLongsOnly(this.waypointResponse.legs);
                            }




                            this.dataCaseAnalyzer(this.leafletMapData.getValue());

                        }
                    }).catch(err => { })//console.log(err));
                }
            },
            error: (err: any) => { },
            complete: () => { }
        });
    }


    // plotLine(wayPointData)

    plotLine(waypt: any) {
        // console.log(waypt);

        // var lastIcon = L.icon.pulse({ iconSize: [2, 2], color: '#7f8c8d', fillColor: '#7f8c8d', heartbeat: 5 });
        const smoothline = turf.lineString(waypt);
        // var smoothline = waypt;
        const curved = turf.bezierSpline(smoothline).geometry.coordinates;
        // var curved = waypt.map(item => item.location);
        // L.marker([item.location.lat, item.location.lng], { icon: greenIcon }).bindPopup(str)
        // var marker = waypt.map(item => L.marker(item.location, { icon: lastIcon }).bindPopup(JSON.stringify(item)).addTo(this.miniLeafletMap));
        // setTimeout(() => {


        // // console.log(curved);
        const point1 = turf.point(curved[0]);
        const point2 = turf.point(curved[(curved.length) - 1]);
        const midpoint = turf.midpoint(point1, point2).geometry.coordinates;
        this.miniLeafletMap.setView(midpoint, 12.5);
        this.polyLine = L.layerGroup();
        const polyLine = L.polyline(curved, { color: 'rgba(116, 185, 255,0.7)', smoothFactor: 1 }).addTo(this.miniLeafletMap);

        this.polyLine.addLayer(polyLine);
        // this.polyLine.addLayer(L.polyline(curved, { color: '#95a5a6', smoothFactor: 1 }).addTo(this.miniLeafletMap));
        this.polyLine.addTo(this.miniLeafletMap);
        // }, 200);
    }

    markerLayer(marker: any) {

        const greenIcon = L.divIcon({
            // html: '<img src="assets/images/school_start.png" width="20" height="20" style="position:absolute;left:-5px"></i>',
            html: '<i class="fa fa-flag-checkered" aria-hidden="true" style="font-size:20px"></i>',
            iconSize: [0, 35],
            className: 'myDivIcon'
        });
        const lastIcon = L.icon.pulse({ iconSize: [5, 5], color: '#7f8c8d', fillColor: '#7f8c8d', heartbeat: 2 });
        const arr = [];

        for (let index = 0; index < marker.length; index++) {
            const item = marker[index];
            const popup = { ...item.data };
            const str = `<p>${popup.pickupName}</p>`;
            let m: any = '';
            // if (index == (marker.length) - 1) {
            //     m = L.marker([item.location.lat, item.location.lng], { icon: lastIcon }).bindPopup(str);
            // } else {
            let pulseic = null;

            if (item.perf === 'good') {
                pulseic = L.icon.pulse({ iconSize: [4, 4], color: this.colorLine[item.perf].color, fillColor: this.colorLine[item.perf].color, heartbeat: 3 });
            } else {
                pulseic = L.icon.pulse({ iconSize: [4, 4], color: this.colorLine[item.perf].color, fillColor: this.colorLine[item.perf].color });
            }

            m = L.marker([item.location.lat, item.location.lng], { icon: pulseic }).bindPopup(str);
            // }
            arr.push(m);
        }

        this.miniLeafletMarker = L.layerGroup(arr);
        this.miniLeafletMarker.addTo(this.miniLeafletMap);

    }

    public cacheCaseArray: any = [];

    dataCaseAnalyzer(leafLetMapData: any) {
        const SCHOOL = 0, GOOD = 1, AVG = 2, BAD = 3;
        this.finalObj['time'] = true;
        this.finalObj['location'] = true;
        let caseRes: any = {
            '0-1': `No changes required`,
            '0-2': `average performance in _current_ pickup point only this can be due to either delayed start from school or change ETA _eta_ to _ata_
                    recomended to ensure bus started correctly from school or change to ETA _eta_ to _ata_ only if trend continues `,
            '0-3': `Delay in _current_ pickup point only this can be due to either delayed start from school or change ETA _eta_ to _ata_
                    recomended to ensure bus started correctly from school or change to ETA _eta_ to _ata_`,
            '1-1': `No changes required`,
            '1-2': `Route average delay occurs at _current_ pickup point.We recommend to change in ETA _eta_ to _ata_ only if same trend continues`,
            '1-3': `Route delay occurs at _current_ pickup point.We strongly recommend to change in ETA _eta_ to _ata_`,
            '2-1': `No changes required`,
            '2-2': `We have encountered constant average delays in _current_ and _previous_ pickupoints,we recomend to fix issue in either _previous_ pickup point or change ETA _eta_ to _ata_ only if trend continues`,
            '2-3': `Route delay occurs at _current_ pickup point due to _previous_ pickup points average performances, we strongly recommend to fix _previous_ pickup point average performance or change ETA _eta_ to ETA _eta_`,
            '3-1': `No changes required`,
            '3-2': `Route delay in _current_ pickup point is due to _previous_ pickup point poor performance,we recommend to fix issue in _previous_ pickup point or change ETA _eta_ to _ata_`,
            '3-3': `Route delay occurs at _current_ pickup point is due to poor performance in _previous_  pickup points,we recommend to fix issue in _previous_ pickup point or change ETA _eta_ to _ata_`
        };


        let temp: any = [];
        // let tempLeafletData = [];

        let totBad = 0;
        let totAverage = 0;
        let caseStruct: any = {};
        // console.log(leafLetMapData.length, leafLetMapData);
        this.cacheCaseArray = [];


        if (leafLetMapData.length) {
            temp.push(SCHOOL);
            // for (let i = 0; i < 10000000000; i++) {
            //     console.log(i);
            // }
            let caseArray = [];
            for (let i = 0; i < leafLetMapData.length; i++) {
                const item = leafLetMapData[i];

                let tmpVar = 0;
                if (item.perf === 'good') tmpVar = GOOD;
                if (item.perf === 'avg') tmpVar = AVG;
                if (item.perf === 'bad') tmpVar = BAD;
                // console.log(item);
                let keyFramed = temp[temp.length - 1] + '-' + tmpVar;
                // console.log('lem ', temp[temp.length - 1] + '-' + tmpVar, temp);

                // let gETA = item.data.eta;
                // let eta: any = new Date().toLocaleString();
                // let current: any = leafLetMapData[i + 1];
                // let leg = this.waypointResponse.legs[i]

                // eta = new Date(eta).setHours(current.data.expectedTM.slice(0, 2));
                // eta = new Date(eta).setMinutes(current.data.expectedTM.slice(2, 4));
                // const preETA = new Date(eta).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
                // eta = new Date(eta + leg.duration.value).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
                caseArray.push({
                    key: keyFramed,
                    res: caseRes[keyFramed],
                    index: i,
                    pickupdetail: item.data,
                })
                // console.log('data-waypoint', this.waypointResponse.legs[i]);
                temp.push(tmpVar);


            }

            console.log(caseArray, this.waypointResponse.legs);


            let data = caseArray[caseArray.length - 1]['res'];
            let lastKey = caseArray[caseArray.length - 1]['key']
            // console.log('caseAnalyser', data);

            // this.summaryData = caseArray[caseArray.length - 1]['res'];
            this.summaryData = this.analysisResponse(data, caseArray[caseArray.length - 1], leafLetMapData);


            let bothBtnDisabled: any = ['0-1', '1-1', '2-1', '3-1'];
            let locEnableTimeDisable: any = ['0-3', '1-3', '2-3', '3-3'];
            let timeEnableLocDisable: any = ['0-2', '1-2', '2-2', '3-2'];

            let both = bothBtnDisabled.includes(lastKey);
            let location: any = locEnableTimeDisable.includes(lastKey);
            let timeLoc: any = timeEnableLocDisable.includes(lastKey);
            console.log('lastKey--', lastKey);
            console.log('both', both, 'location', location, 'time', timeLoc);
            if (both) {
                this.finalObj['time'] = true;
                this.finalObj['location'] = true;
            } else if (location) {
                this.finalObj['time'] = true;
                this.finalObj['location'] = false;
            } else if (timeLoc) {
                this.finalObj['time'] = false;
                this.finalObj['location'] = true;
            }

            // console.log('finalObj', caseArray, this.waypointResponse.legs);
            this.cacheCaseArray = caseArray;

            // ? true : false;
            // this.location = locEnableTimeDisable.includes(lastKey) ? true : false;
            // console.log('Time--->', time, 'location--->', location);
            // if()


        }


        return;
    }


    excelgen() {
        this.excelgenerator(this.cacheCaseArray, this.waypointResponse.legs);
    }

    excelgenerator(temp1: any, temp2: any) {
        if (temp1.length == 0 || temp2.length == 0) {
            alert("Issue in Excel Report Generation");
        }
        for (var i = 1; i < temp1.length; i++) {



            let prev = temp1[i - 1]['pickupdetail'];
            let current = temp1[i]['pickupdetail'];
            let leg = temp2[i];
            let eta: any = new Date().toLocaleString();
            eta = new Date(eta).setHours(current.expectedTM.slice(0, 2));
            eta = new Date(eta).setMinutes(current.expectedTM.slice(2, 4));

            eta = new Date(eta + leg.duration.value).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
            current['googleETA'] = eta;

        }

        var workbook = new ExcelJS.Workbook();
        var worksheet = workbook.addWorksheet(`${temp1[0]['pickupdetail']['RouteName']}`, {
            properties: { tabColor: { argb: "00B050" } },
        });
        worksheet.mergeCells("A1:K1");
        worksheet.getCell("A1").alignment = { horizontal: "center" };
        worksheet.getCell("A1").value = temp1[0]['pickupdetail']['RouteName'];
        worksheet.getCell("A1").value = temp1[0]['pickupdetail']['RouteName'];

        // worksheet.mergeCells("A2:E2");
        // worksheet.mergeCells("G2:K2");
        // worksheet.getCell("A2").value = 
        // worksheet.getCell("K2").value = 




        var headerFields = [
            "Pickup Name",
            "ATA",
            "call Made",
            "Difference",
            "ETA",
            "School Expected Time",
            "Google ETA",
            // "Location",
            "performance",
            "reached Time",
            "Time Difference",
            "Timestamp Difference Number"]

        worksheet.addRow(headerFields.map(i => i.charAt(0).toUpperCase() + i.slice(1)));


        for (var i = 0; i < temp1.length; i++) {

            let temp = [];

            let item = temp1[i]['pickupdetail'];
            temp.push(item["pickupName"] ? item["pickupName"] : '-');
            temp.push(item["ata"] ? item["ata"] : '-');
            temp.push(item["callMade"] ? item["callMade"] : '-');
            temp.push(item["diff"] ? item["diff"] : '-');
            temp.push(item["eta"] ? item["eta"] : '-');
            temp.push(item["expectedTM"] ? item["expectedTM"] : '-');
            temp.push(item["googleETA"] ? item["googleETA"] : '-');
            temp.push(item["performance"] ? item["performance"] : '-');
            temp.push(item["reachedTM"] ? item["reachedTM"] : '-');
            temp.push(item["time_difference"] ? item["time_difference"] : '-');
            temp.push(item["timestamp_difference_number"] ? item["timestamp_difference_number"] : '-');


            worksheet.addRow(temp);
        }

        setTimeout(() => {
            workbook.xlsx
                .writeBuffer()
                .then((data: any) => {
                    var blob = new Blob([data], {
                        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
                    });
                    let now = Date.now();

                    saveAs(blob, `Route_Analysis_Download${now}.xlsx`);
                    return true;
                })
        }, 200);
    }

    analysisResponse(data: string, cases: any, leafLetMapData: any): string {

        let cur = cases.index;
        let prev = cur - 1;
        let current = leafLetMapData[cur];

        let str: string = data;


        let eta: any = new Date().toLocaleString();
        eta = new Date(eta).setHours(current.data.expectedTM.slice(0, 2));
        eta = new Date(eta).setMinutes(current.data.expectedTM.slice(2, 4));
        let ata = current.data.ata;

        if (prev && leafLetMapData[prev]) {
            let previous = leafLetMapData[prev];
            str = str.replace(/_previous_/g, `"${previous.data.pickupName}"`);
        }
        eta = new Date(eta).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
        str = str.replace(/ETA _eta_/g, eta);
        str = str.replace(/_ata_/g, ata);

        str = str.replace(/_current_/g, `"${current.data.pickupName}"`);
        // console.log(str);

        return str;
    }










    stringifyNumber(n: any) {
        if (n) {
            const special = ['zeroth', 'first', 'second', 'third', 'fourth', 'fifth', 'sixth', 'seventh', 'eighth', 'ninth', 'tenth', 'eleventh', 'twelfth', 'thirteenth', 'fourteenth', 'fifteenth', 'sixteenth', 'seventeenth', 'eighteenth', 'nineteenth'];
            const deca = ['twent', 'thirt', 'fort', 'fift', 'sixt', 'sevent', 'eight', 'ninet'];

            if (n < 20) { return special[n]; }
            if (n % 10 === 0) { return deca[Math.floor(n / 10) - 2] + 'ieth'; }
            return deca[Math.floor(n / 10) - 2] + 'y-' + special[n % 10];
        }

    }










}



// import { animate, state, style, transition, trigger } from '@angular/animations';
// import { AfterViewInit, Component, Input, OnDestroy, OnInit,EventEmitter, Output} from '@angular/core';
// import { mapStyle } from "./mapStyle"
// import { MapService } from "./map.service";

// import { WayPoint, Location, Singlewaypoint, LatLong, WayPointDataFormat, Weeklyperf, WaypointsData } from "./map.interface";

// // import * as L from 'leaflet';assets/js/leaflet_pulse
// import "./../../../assets/js/leaflet_plugins/leaflet_pulse";

// import { BehaviorSubject, from, Observable, Subscription } from 'rxjs';
// import { map, toArray } from 'rxjs/operators';
// import { colorLine } from "./lineFile.config";
// declare const L: any;
// declare const google: any;
// declare const turf: any


// // https://github.com/mapshakers/leaflet-icon-pulse 


// @Component({
//     selector: 'app-map-based-analytic',
//     templateUrl: './map-based-analytic.component.html',
//     styleUrls: ['./map-based-analytic.component.css'],
// })
// export class MapBasedAnalyticComponent implements OnInit, AfterViewInit, OnDestroy {


//     perf: string;
//     _weeklyperf: Weeklyperf;
//     leafletMapData: any = new BehaviorSubject([]);
//     dataForWaypointApi: any = new BehaviorSubject([]);
//     localSubscription: Subscription[] = [];
//     polyLine: any;
//     summaryData: string = '';
//     waypointResponse: any = [];
//     miniLeafletMarker: any;
//     public selectedPickupPoint: string;
//     _allRouteInfo: any;
//     colorLine: any = colorLine;
//     get weeklyperf(): Weeklyperf {
//         return this._weeklyperf;
//     }
//     @Input() set weeklyperf(value: Weeklyperf) {
//         this._weeklyperf = value;
//         this.perf = value.performance;
//     }
//     get allRouteInfo() {
//         return this._allRouteInfo;
//     }
//     @Input() set allRouteInfo(value: any) {
//         if (value) {
//             this.organizePickupInfo(value);
//         }
//     }

//     constructor(public mapService: MapService) { }



//     miniLeafletMap: any = null;

//     waypointRequest = true;
//     /*

//     * expectedTime = str.slice(0, 2) + ':'+str.slice(2,4)

//     */
//     totbad = 0;
//     totavg = 0;
//     collectionsofBadPoints:any = [];
//     collectionsofAveragePoints:any = [];

//     ngOnInit(): void { }
//     ngAfterViewInit(): void {
//         this.initMap();

//         setTimeout(() => {
//             this.requestWayPoint();
//             this.plotMarker();
//         }, 1.0 * 1000);
//     }
//     ngOnDestroy(): void {
//         this.localSubscription.forEach((item: Subscription) => item.unsubscribe());
//     }



//     organizePickupInfo(value: any) {
//         console.log(value);

//         let leafletMapData = [];
//         let dataForWaypointApi = [];
//         return Object.keys(value).length && from(Object.keys(value)).pipe(map(key => {
//             const location = {
//                 lat: parseFloat(value[key].location.lat),
//                 lng: parseFloat(value[key].location.lng),
//             };
//             const temp = { pickupid: key, perf: value[key].color, location };
//             const data = { ...value[key], ...temp };

//             // leafletMapData.push({ location, data, perf: value[key]['color'] });
//             // dataForWaypointApi.push([location.lat, location.lng]);
//             return data;
//         }), toArray()).subscribe((item) => {
//             item.sort((a: any, b: any) => {
//                 const aETA = parseInt(a.expectedTM);
//                 const bETA = parseInt(b.expectedTM);
//                 return aETA < bETA ? -1 : 1;
//             });

//             setTimeout(() => {

//                 this.summaryData = '';

//                 this._allRouteInfo = item;
//                 dataForWaypointApi = item.map(k => [k.location.lat, k.location.lng]);
//                 leafletMapData = item.map(k => ({ location: k.location, data: k, perf: k.perf }));
//                 this.leafletMapData.next(leafletMapData);
//                 this.dataForWaypointApi.next(dataForWaypointApi);
//             }, 1 * 1000);

//         }).unsubscribe();
//     }

//     initMap() {
//         this.miniLeafletMap = L.map('map', {
//             center: [28.7041, 77.1025],
//             zoom: 12,
//             attributionControl: false,
//             zoomControl: true
//         });
//         const Stamen_TonerLite = L.tileLayer('http://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}.png', {
//             attribution: 'Map tiles by <a href="http://stamen.com">Stamen Design</a>, <a href="http://creativecommons.org/licenses/by/3.0">CC BY 3.0</a> &mdash; Map data &copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>',
//             subdomains: 'abcd',
//             minZoom: 0,
//             maxZoom: 20,
//             ext: 'png'
//         }).addTo(this.miniLeafletMap);

//     }

//     clearPolyLine() {
//         this.miniLeafletMarker && this.polyLine && this.polyLine.removeFrom(this.miniLeafletMap);
//         // this.miniLeafletMap && this.polyLine && this.polyLine.removeFrom(this.miniLeafletMap);
//     }
//     clearMarker() {
//         this.miniLeafletMarker && this.miniLeafletMarker.removeFrom(this.miniLeafletMap);
//         // this.miniLeafletMap && this.miniLeafletMarker && this.miniLeafletMarker.removeFrom(this.miniLeafletMap);
//     }

//     plotMarker() {
//         this.localSubscription[1] = this.leafletMapData.asObservable().subscribe({
//             next: (markerData:any) => {
//                 this.miniLeafletMarker && this.clearMarker();
//                 setTimeout(() => {
//                     this.markerLayer(markerData);
//                     // this.dataAnalyzer(markerData)
//                 }, 100);
//             },
//             complete: () => { },
//             error: () => { }
//         });
//     }

//     requestWayPoint() {
//         const directionsService = new google.maps.DirectionsService();

//         this.localSubscription[0] = this.dataForWaypointApi.asObservable().subscribe({
//             next: (wayPointData:any) => {
//                 if (wayPointData && this.waypointRequest) {
//                     this.polyLine && this.clearPolyLine();
//                     this.waypointResponse = this.mapService.simulteResponse();
//                     if (this.waypointResponse.legs) { this.mapService.retriveLatLongsOnly(this.waypointResponse.legs).then((waypt: any) => waypt && this.plotLine(waypt)); }
//                     // this.dataAnalyzer(this.leafletMapData.getValue());
//                     // this.mapService.getWayPoints(directionsService, wayPointData).then((data: any) => {
//                     //     this.waypointResponse = data;
//                     //     if (data.legs) this.mapService.retriveLatLongsOnly(data.legs).then((waypt: any) => {
//                     //         console.log(waypt);
//                     //         return waypt && this.plotLine(waypt)
//                     //     })
//                     //     this.dataAnalyzer(this.leafletMapData.getValue())

//                     // });
//                 }
//             },
//             error: (err:any) => { },
//             complete: () => { }
//         });
//     }


//     // plotLine(wayPointData)

//     plotLine(waypt:any) {


//         const smoothline = turf.lineString(waypt);
//         const curved = turf.bezierSpline(smoothline).geometry.coordinates;
//         const point1 = turf.point(curved[0]);
//         const point2 = turf.point(curved[(curved.length) - 1]);
//         const midpoint = turf.midpoint(point1, point2).geometry.coordinates;
//         this.miniLeafletMap.setView(midpoint, 12.5);
//         this.polyLine = L.layerGroup();
//         const polyLine = L.polyline(curved, { color: 'rgba(116, 185, 255,0.7)', smoothFactor: 1 }).addTo(this.miniLeafletMap);

//         this.polyLine.addLayer(polyLine);
//         // this.polyLine.addLayer(L.polyline(curved, { color: '#95a5a6', smoothFactor: 1 }).addTo(this.miniLeafletMap));
//         this.polyLine.addTo(this.miniLeafletMap);
//     }

//     markerLayer(marker:any) {

//         const greenIcon = L.divIcon({
//             // html: '<img src="assets/images/school_start.png" width="20" height="20" style="position:absolute;left:-5px"></i>',
//             html: '<i class="fa fa-flag-checkered" aria-hidden="true" style="font-size:20px"></i>',
//             iconSize: [0, 35],
//             className: 'myDivIcon'
//         });
//         const lastIcon = L.icon.pulse({ iconSize: [5, 5], color: '#7f8c8d', fillColor: '#7f8c8d' });
//         const arr = [];

//         for (let index = 0; index < marker.length; index++) {
//             const item = marker[index];
//             const popup = { ...item.data };
//             const str = `<p>${popup.pickupName}</p>`;
//             let m: any = '';
//             if (index == 0) {
//                 m = L.marker([item.location.lat, item.location.lng], { icon: greenIcon }).bindPopup(str);
//             } else if (index == (marker.length) - 1) {
//                 m = L.marker([item.location.lat, item.location.lng], { icon: lastIcon }).bindPopup(str);
//             } else {
//                 const pulseic = L.icon.pulse({ iconSize: [4, 4], color: this.colorLine[item.perf].color, fillColor: this.colorLine[item.perf].color });
//                 m = L.marker([item.location.lat, item.location.lng], { icon: pulseic }).bindPopup(str);
//             }
//             arr.push(m);
//         }

//         this.miniLeafletMarker = L.layerGroup(arr);
//         this.miniLeafletMarker.addTo(this.miniLeafletMap);

//     }


// }