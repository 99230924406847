import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { NG_VALIDATORS, Validator, Validators, AbstractControl, ValidatorFn,FormBuilder,FormArray,FormGroup,FormControl } from '@angular/forms';
import { StorageService } from "../../../services/storage.service"


function timeValidate(s:any,e:any){
  return (control:AbstractControl) :{ [keys:string]: boolean }|null =>{
  
   if(s == e){
    
     return {
       sessionTimeError: true
     }
   }else{
     return null;
   }
  }
}


@Component({
  selector: 'session-configuration',
  templateUrl: './session-configuration.component.html',
  styleUrls: ['./session-configuration.component.css']
})
export class SessionConfigurationComponent{
  public defineSessions:any=[];
  public sessionForm:any;
  public sessionStartTimeListener:any={};
  public sessionEndTimeListener:any={};
  public sessionTitle : string = '';
  public sessionSelection : any=[];
  public errorSwal={
    ec1:'Empty field is present',
    ec2:'Start time is greater than End Time',
    ec3:'Minimum session time duration must be of 1 hour',
    ec4:'Start time and end time of same durtion is already available'    
  }
  public uncheck: boolean;
 
 public timepick=true;
  
  @Output() 
  finalSession = new EventEmitter();

  @Input()
  set getSession(value:any){
 
    if(Array.isArray(value)){
      this.defineSessions = value;  
      this.dataLoaded(value);
    } 

    // if(value){
    //   this.onReset()
    //  }
   
  }
 
  constructor(
    private fb:FormBuilder,
    public storage:StorageService
  ) { 
    // { 'createOrgSessions': 'route' }
    
  }

  ngOnInit() {
   
    this.storage.set({ 'sessionTimings': ''})
    this.loadForm();
  }

 
  loadForm(){
    let endtime = this.sessionEndTimeListener['hh.mm']; 
    let starttime = this.sessionStartTimeListener['hh.mm']; 
    let endtimeStr = this.sessionEndTimeListener['hh:mm']; 
    let starttimeStr= this.sessionStartTimeListener['hh:mm']; 
    // console.log(endtime,starttime);
    // this.defineSessions = JSON.parse(JSON.stringify(this.tempData));
    this.sessionForm = this.fb.group({
      sessionName : [this.sessionTitle,[Validators.required,timeValidate(starttime,endtime)]],
      sessionStartTime:[starttimeStr],
      sessionEndTime:[endtimeStr]
    })
  }

  dataLoaded(obj:any){   
    return (this.finalSessionShow(),this.storage.set({'sessionTimings':obj}))
  }
  finalSessionShow(){
    this.finalSession.emit({
      'finalSession':this.defineSessions
    })
  }
  addSessionForm(){
    this.loadForm();
    let error="";
    let startTime:any;
    let endTime:any;
    if(this.sessionForm.valid){
      startTime = parseFloat(this.sessionForm.value.sessionStartTime.replace(':','.'));
      endTime = parseFloat(this.sessionForm.value.sessionEndTime.replace(':','.'));
      let finalTime = endTime - startTime;
      if(finalTime>=1){    
      
      if(this.defineSessions.length==0){
        this.defineSessions.push(this.sessionForm.value);
        this.dataLoaded(this.defineSessions)
        swal('Success',"Successfully added",'success')
        this.onReset();

      }else{
        // this.defineSessions.push(this.sessionForm.value);
       
          let dupTime:boolean = true;
          let sessionTitle = '';
          this.defineSessions.filter((item:any)=>{         
            if(item.sessionStartTime === this.sessionForm.value.sessionStartTime &&
            item.sessionEndTime === this.sessionForm.value.sessionEndTime){
              sessionTitle = item.sessionName;
              return dupTime = false;
              }
            })
                    if(dupTime){
                      let maxLen = this.defineSessions.length; 
                      
                          if(maxLen>=10){
                            error="Reached Maximum Limit of 10 ";
                            swal('Failure',error,'error')
                          }else{
                            this.defineSessions.push(this.sessionForm.value);  
                            this.dataLoaded(this.defineSessions);
                            this.onReset();                         
                            swal('Success',"Successfully added",'success')
                          }
                    }else{
                      error=this.errorSwal.ec4+' with Session Name - '+sessionTitle+'';
                      swal('Failure',error,'error');
                      sessionTitle='';
                    }
        // dupTime 
        // ? (this.defineSessions.length<=10 
        //                                 ? (this.defineSessions.push(this.sessionForm.value),console.log(this.defineSessions.length),
        //                                    swal('Success',"Successfully added",'success')
        //                                    )
        //                                 : (error="Reached Maximum Limit of 10 ",swal('Failure',error,'error')),
        //   this.sessionSelection=[])
        // : (error=this.errorSwal.ec4+' with Session Name - '+sessionTitle+'',swal('Failure',error,'error'),sessionTitle='');

        }

            }else{
              // console.log(finalTime,absFinalTime);

              finalTime>=0 && finalTime<1 
              ? (error = this.errorSwal.ec3,swal('Failure',error,'error'))
              : (error = this.errorSwal.ec2,swal('Failure',error,'error'));  
              
            }
  
    }else{
      error=this.errorSwal.ec1+' at session name';swal('Failure',error,'error')
    }
 
    // console.log(this.defineSessions);
  
  
  }

  onReset() {
    //Leaving out 'name', as it does not have a default value
     this.sessionForm.reset();
     this.timepick= false;
     setTimeout(() => {
       this.timepick = true;
     }, 500);
 }
  checkedArray(selected:number){  
      if(this.defineSessions[selected].checked && this.defineSessions[selected].checked==true){
        this.defineSessions[selected].checked = false;
      } else {
        this.defineSessions[selected].checked = true;
      }
      return this.dataLoaded(this.defineSessions);
  }
  deleteAllSelected(){
    // console.log(this.sessionSelection.length,this.sessionSelection)
    let temp: any = [];
    let selection:boolean = false;
      this.defineSessions.forEach((item:any,index:number) => {
        // console.log(item)
        if(item.checked && item.checked == true){
          
        }else{
          temp.push(item);
        }
      });
      this.defineSessions = temp;    
  
      this.uncheck = false;
      this.dataLoaded(this.defineSessions);
      
      swal('Success',"Selected item deleted successfully.Please click update operation to revert back in server",'success')
  }
  remove(index:number,swalpop?:boolean){
    
   

    if(swalpop==true){
      let name = this.defineSessions[index].sessionName;
      let sttime = this.defineSessions[index].sessionStartTime;
      let etime = this.defineSessions[index].sessionEndTime;
    swal({
      title: "Successfully Deleted",
      text: `session name -${name} of start time - ${sttime} to end time - ${etime} deleted`,
      icon: "success",
      });
    }
   this.defineSessions.forEach((itm:any,ind:number) => {
     ind===index && this.defineSessions.splice(ind,1);
   });
    
    return this.dataLoaded(this.defineSessions);
  }
  checkAll($e:any){
    
   if($e.target.checked){
    this.defineSessions.forEach((item:any,index:number)=>{
      this.defineSessions[index]['checked']=true;
    })
   return  this.dataLoaded(this.defineSessions);
   }
   
    if($e.target.checked == false){
      this.defineSessions.forEach((item:any,index:number)=>{
        this.defineSessions[index]['checked']=false;
      })
    return this.dataLoaded(this.defineSessions)
    }
  }


}
