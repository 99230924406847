/**
 * @Date:   2018-03-14T17:37:50+05:30
 * @Last modified time: 2018-05-11T16:36:39+05:30
 */



import { Component, OnInit, Input, EventEmitter } from '@angular/core';
import { ApiService } from './../../../services/ApiService';
import { chosenUtility } from './../../../services/chosen-utility/jq-chosen-utility.js';
import { bootstrapDateTime } from './../../../services/bootstrapDateTime/datePicker';
import { GlobalUtils } from './../../../services/global-utils';
import { ApiCallDateFormatService } from "../../../services/api-call-date-format.service";
import { COM } from './../../../services/com.services';

//import { } from '@types/googlemaps';

declare const $: any;
declare const google: any;
declare const Pickathing: any;
@Component({
    providers: [bootstrapDateTime],
    selector: 'other-reports-section',
    template: `
        <section class="otherReportSection report-tab-content active animate fade-in">
            <div class="row">
                <div class="col-lg-3 col-md-3 col-sm-5 col-xs-5 pull-left fixedHeight availableReportOptions">
                    <div class="divHeading" style="margin-bottom: 2em;padding-top: 4%;">
                        <span class="">Available Reports</span>
                        <div style="height: 1px;border: 1px solid #64c7e9;width: 3em;"></div>
                    </div>
                    <div class="form-group">
                    <label class="control-label">Select Report:</label>
                    <select class="custom-form-control chosen-select" name="demo-select" id="demo-select">
                    <option style="display:none">Select an Option</option> 
                    <!-- <ng-container *ngIf="readytoDisplay"> ---->
                    <option *ngFor="let option of reportOptions" value="{{option.value}}" class="meager-e2e-rfid-{{option.reportName}}">{{option.reportName}}</option>
                   <!-- </ng-container> -->
                         </select>
                        

                </div>
                 <!--   <div class="form-group">
                        <label class="control-label">Select Report </label>
                        <select class="custom-form-control chosen-select" id="availableReportsDropdown">
                            <option value=""></option>
                            <option *ngFor="let option of reportOptions" value="{{option.value}}">{{option.reportName}}</option>
                        </select>
                    </div> -->
                    <section class="routeVehicleMappedSection" *ngIf="reportSections.routevehiclemapped">
                        <div class="divHeading" style="margin-bottom: 2em;padding-top: 4%;">
                            <span class="">Available Filters</span>
                            <div style="height: 1px;border: 1px solid #64c7e9;width: 3em;"></div>
                        </div>
                        <div class="form-group">
                            <label class="control-label">Vehicle Reg.No</label>
                            <input class="custom-form-control meager-e2e-others-report-all-vehicle-all-routes-veh-reg-no" [(ngModel)]="filterVehicleReg">
                        </div>
                        <div class="form-group">
                            <label class="control-label">Route Name</label>
                            <single-search-select
                            

                            [searchType]="'onlyRoute'"
                            [searchAPIUrl]="'getRoutes'"
                            [otherParams]="'resetSearchUtility'"
                            [routeSection] = "'routeSection'"
                            (emitUserSelections)="setRouteSelection($event)">
                            </single-search-select>
                        </div>
                        <div class="form-group form-actions">
                             <button class="webform-submit button-primary btn btn-primary form-submit pull-right applyFilterBtn meager-e2e-others-report-all-vehicle-all-routes-filter-btn" (click)="applyFilters()">Apply Filters</button>
                        </div>
                    </section>
                    <section class="panicReportSection" *ngIf="reportSections.panic">
                        <div class="divHeading" style="margin-bottom: 1.5em;padding-top: 2.5%;">
                            <span class="">Available Options</span>
                            <div style="height: 1px;border: 1px solid #64c7e9;width: 3em;"></div>
                        </div>
                        <div class="form-group">
                            <label class="control-label">Select Vehicle:</label>
                            <single-search-select
                            [searchType]="'device'"
                            [searchAPIUrl]="'getAllOrgDevices'"
                            [otherParams]="'resetSearchUtility'"
                            (emitUserSelections)="setUserSelection($event)">
                            </single-search-select>
                            <span class="text-danger" *ngIf="emptyVehicleError">Choose Vehicles</span>
                        </div>
                        <div class="form-group">
                            <label class="control-label">Start Date <sup>*</sup></label>
                            <input class="custom-form-control meager-e2e-others-report-panic-log-start-date" id="routeHistoryStartDate">
                            <span class="text-danger" *ngIf="PanicStartDateError">Choose Start Date</span>
                            <!--<span class="text-danger" *ngIf="panicDatesMisMatchError">End Date should be greater than Start Date</span>-->
                        </div>
                        <div class="form-group">
                            <label class="control-label">End Date: <sup>*</sup></label>
                            <input class="custom-form-control meager-e2e-others-report-panic-log-end-date" id="routeHistoryEndDate">
                            <span class="text-danger" *ngIf="panicEndDateError">Choose End Date</span>
                            <span class="text-danger" *ngIf="panicDatesMisMatchError">End Date should be greater than Start Date</span>
                        </div>
                        <div class="form-group form-actions">
                            <button class="webform-submit button-primary btn btn-primary form-submit pull-right viewReportBtn meager-e2e-others-report-panic-log-view-btn" (click)="viewPanicLog('panic')">View</button>
                            <span class = "text-danger">{{displayErrorMessage}}</span>
                        </div>
                    </section>
                    <section class="acReportSection" *ngIf="reportSections.ac"></section>
                    <section class="accDccReportSection" *ngIf="reportSections.accanddecc"></section>
                    <section class="engineReportSection" *ngIf="reportSections.engine"></section>
                    <section class="geofenceReportSection" *ngIf="reportSections.geofence"></section>

                    <section class="vehicleLastUpdateSection" *ngIf="reportSections.vehiclelastupdate">
                        <div class="divHeading" style="margin-bottom: 1.5em;padding-top: 2.5%;">
                            <span class="">Available Options</span>
                            <div style="height: 1px;border: 1px solid #64c7e9;width: 3em;"></div>
                        </div>
                        <div class="form-group">
                            <label class="control-label">Movement Type:</label>
                            <select class="custom-form-control meager-e2e-others-report-vehicle-last-update-movement-type" [(ngModel)]="filterMovementType">
                                <option value="all">All</option>
                                <option value="halt">Halt</option>
                                <option value="moving">Moving</option>
                                <option value="offline">Offline</option>
                            </select>
                        </div>
                      
                        <div class="form-group form-actions">
                                <button class="webform-submit button-primary btn btn-primary form-submit pull-right applyFilterBtn meager-e2e-others-report-vehicle-last-update-filter-btn" (click)="applyLastUpdateFilters()">Apply Filters</button>
                        </div>
                    </section>
 
                    <section class="vehicleLastUpdateSection" *ngIf="reportSections.otherOverspeed" style="with:91px;">
                                <div class="divHeading" style="margin-bottom: 1.5em;padding-top: 2.5%;">
                                    <span class="">Available Options</span>
                                    <div style="height: 1px;border: 1px solid #64c7e9;width: 3em;"></div>
                                </div>
                                <div class="form-group">
                                    <label class="control-label">Select Vehicle:</label>
                                    <single-search-select
                                    [searchType]="'device'"
                                    [searchAPIUrl]="'getAllOrgDevices'"
                                    [otherParams]="'resetSearchUtility'"
                                    (emitUserSelections)="setUserSelection($event)">
                                    </single-search-select>
                                <span class="text-danger" *ngIf="emptyVehicleError">Choose Vehicle </span>
                                </div>
                            

                                <div class="form-group">
                                    <label class="control-label">Start Date: <sup>*</sup></label>
                                    <input class="custom-form-control meager-e2e-others-report-all-vehicle-overspeed-start-date" id="routeHistoryStartDate">
                                <span class="text-danger" *ngIf="overspeedStartDate">Choose Start Date</span>
                                  <!--  <span class="text-danger" *ngIf="routesDatesMisMatchError">End Date should be greater than Start Date</span> -->
                                </div>
                                <div class="form-group">
                                    <label class="control-label">End Date: <sup>*</sup></label>
                                    <input class="custom-form-control meager-e2e-others-report-all-vehicle-overspeed-end-date" id="routeHistoryEndDate">
                                    <span class="text-danger" *ngIf="overspeedEndDate">Choose End Date</span>
                                     <span class="text-danger" *ngIf="routesDatesMisMatchError">End Date should be greater than Start Date</span>
                                </div>

                               <div class="row" *ngIf="showMoreFilter">
                                   <div class="form-group col-md-6">
                                        <label class="control-label">Low Speed Limit:</label>
                                        <input class="custom-form-control meager-e2e-others-report-all-vehicle-low-speed-limit" type="number" min="0" [(ngModel)]="lowspeed">
                                        <span class ="text-danger" *ngIf="overSpeedLimitError">Enter Speed limit in Digits</span>
                                    </div>
                                    <div class="form-group col-md-6">
                                        <label class="control-label">High Speed Limit:</label>
                                        <input class="custom-form-control meager-e2e-others-report-all-vehicle-high-speed-limit" type="number" min="0" [(ngModel)]="highspeed">
                                        <span class ="text-danger" *ngIf="overSpeedLimitError">Enter Speed limit in Digits</span>
                                    </div>
                              </div>
                          
                            <div class="form-group">
                                  <label class="control-label"></label>
                                    <h2 (click)="showMoreFilter=showMoreFilter==true?false:true" class="panel-title pull-right inactiveHeader" > More&nbsp;&nbsp; 
                                            <i aria-hidden="true" *ngIf="!showMoreFilter" class="fa fa-caret-down"></i>
                                            <i aria-hidden="true" *ngIf="showMoreFilter" class="fa fa-caret-up"></i>
                                    </h2>
                                    <span class="text-danger" ></span>
                            </div>

                          <div class="row">                         
                            <div class="form-group form-actions">
                                <button style="    margin: 0px 20px 3px 2px;" class="webform-submit button-primary btn btn-primary form-submit pull-right viewReportBtn meager-e2e-others-report-all-vehicle-view-btn" (click)="viewOtherOverspeedLog1('overspeed')">View</button>
                                <span class="text-danger">{{displayErrorMessage}}</span>
                            </div>
                         </div>

                    </section>

                    <section class="vehicleLastUpdateSection" *ngIf="reportSections.notificationAlert" style="with:91px;">

                            <div class="form-group">
                                    <label class="control-label">Start Date:<sup>*</sup></label>
                                    <input class="custom-form-control meager-e2e-others-report-notification-alert-start-date" id="routeHistoryStartDate">
                                    <span class="text-danger" *ngIf="notificationStartDate">Choose Start Date</span>
                                   <!-- <span class="text-danger" *ngIf="routesDatesMisMatchError">End Date should be greater than Start Date</span>-->
                            </div>
                            <div class="form-group">
                                    <label class="control-label">End Date:<sup>*</sup></label>
                                    <input class="custom-form-control meager-e2e-others-report-notification-alert-end-date" id="routeHistoryEndDate">
                                    <span class="text-danger" *ngIf="notificationEndDate">Choose End Date</span>
                                    <span class="text-danger" *ngIf="routesDatesMisMatchError">End Date should be greater than Start Date</span>
                            </div>
                           
                            <div class="row"> 
                                    <div class="form-group col-md-6">
                                        <label class="control-label">Report Type:<sup>*</sup></label>
                                        <select class="custom-form-control meager-e2e-others-report-notification-alert-report-type"[(ngModel)]="selectedReportType">                                  
                                            <option value="overspeed">Overspeed</option>   
                                            <option value="panic">Panic</option>
                                        
                                        </select>  
                                        <span *ngIf="reportTypeErr" class="text-danger">Choose report type</span>                         
                                    </div>
                                    <div class="form-group col-md-6">
                                        <label class="control-label">Alert Type:</label>
                                        <select class="custom-form-control meager-e2e-others-report-notification-alert-alert-type"[(ngModel)]="selectedAlert">  
                                            <option value="">Select</option>                                
                                            <option value="email">Email</option>   
                                            <option value="sms">SMS</option>
                                        
                                        </select>  
                                                                
                                    </div>
                             </div>

                             <div class="row">                         
                                    <div class="form-group form-actions">
                                        <button style="margin: 0px 20px 3px 2px;" 
                                        class="webform-submit button-primary btn btn-primary form-submit pull-right viewReportBtn meager-e2e-others-report-notification-alert-view-btn"
                                         (click)="viewOtherNotificationLog(0)">View</button>
                                        <span class="text-danger">{{displayErrorMessage}}</span>
                                    </div>
                            </div>                      

                    
                    </section>
                    <section class="vehicleBasedNotificationAlert" *ngIf="reportSections.vehicleBasedNotificationAlert" style="with:91px;">

                            <div class="form-group">
                                    <label class="control-label">Start Date:<sup>*</sup></label>
                                    <input class="custom-form-control meager-e2e-others-report-vehicle-based-notification-alert-start-date" id="routeHistoryStartDate">
                                    <span class="text-danger" *ngIf="vehicleBasedNotiStartDate">Choose Start Date</span>
                                    <!-- <span class="text-danger" *ngIf="routesDatesMisMatchError">End Date should be greater than Start Date</span>-->
                            </div>
                            <div class="form-group">
                                <label class="control-label">End Date:<sup>*</sup></label>
                                <input class="custom-form-control meager-e2e-others-report-vehicle-based-notification-alert-end-date" id="routeHistoryEndDate">
                                <span class="text-danger" *ngIf="vehicleBasedNotiEndDate">Choose End Date</span>
                                <span class="text-danger" *ngIf="vehicleDatesMisMatchError">End Date should be greater than Start Date</span>
                            </div>
                            <div class="form-group">
                                <label class="control-label">Select Route:</label>
                                    <single-search-select
                                    [searchType]="'onlyRoute'"
                                    [searchAPIUrl]="'getRoutes'"
                                    [otherParams]="'resetSearchUtility'"
                                    (emitUserSelections)="setUserSelections($event)">
                                    </single-search-select>
                                <span class="text-danger" *ngIf="chooseRouteError">Choose Route</span>
                           </div>
                            <div class="row">                         
                                    <div class="form-group form-actions">
                                        <button style="margin: 0px 20px 3px 2px;" class="webform-submit button-primary btn btn-primary form-submit pull-right viewReportBtn meager-e2e-others-report-vehicle-based-notification-view-btn" 
                                        (click)="viewOtherVehicleBasedNotification(0)">View</button>
                                        <span class="text-danger">{{displayErrorMessage}}</span>
                                    </div>
                            </div>      

                    
                    </section>
                    <section class="breakDownReport" *ngIf="reportSections.breakDownReport" style="with:91px;">

                    <div class="form-group">
                    <label class="control-label">Start Date:<sup>*</sup></label>
                    <input class="custom-form-control" id="routeHistoryStartDate" placeholder="Select Date">
                    <span class="text-danger" *ngIf="StartDateError">Choose Date</span>

                   </div>

                   <div class="form-group">
                   <label class="control-label">Route Name</label>
                   <single-search-select
                       [searchType]="'onlyRoute'"
                       [searchAPIUrl]="'getRoutes'"
                       [otherParams]="'resetSearchUtility'"
                       [routeSection] = 'routeSection'
                       (emitUserSelections)="setUserRouteSelections($event)">
                   </single-search-select>
               </div>
              
               <div class="form-group">
               <label class="control-label">Message Type</label>
               <select ([ngModel])="bearkDownReportType" (change)="bearkDownReportType=$event.target.value" class="custom-form-control">                                                              
                <option value="">Select</option>
                <option value="1">Breakdown</option>
                <option value="0">Resolve</option>
                 </select>
               </div>

               <div class="form-group">
               <label class="control-label">Mobile Number</label>
               <input type="text" class="custom-form-control" ([ngModel])="breakDownMobileNumber" (change)="breakDownMobileNumber = $event.target.value" (keyup)="MobileNumberValidator($event)" minlength="10" maxlength="10">

               </div>

               <div class="row">                         
               <div class="form-group form-actions">
                   <button style="margin: 0px 20px 3px 2px;" class="webform-submit button-primary btn btn-primary form-submit pull-right viewReportBtn" (click)="breakDownReport(0)">View</button>
               
               </div>
       </div>    
               

                    </section>

                </div>
                <div class="col-lg-9 col-md-9 col-sm-7 col-xs-7 pull-right" *ngIf="!isLoading; else pageLoader">
                    <section class="routeVehicleMappedContentSection" *ngIf="reportSections.routevehiclemapped  && reportSectionsContent.routevehiclemapped">
                        <div class="row orgBasicDetails mb5">
                            <div class="reportHeading text-center mb5">All vehicle-routes report</div>
                        </div>
                        <div class="row orgBasicDetails">
                            <div class="col-md-12">
                                <div class="col-md-6">
                                    <label class="control-label mr5 basicDetailsLabel">Total Route Count:</label>
                                    <span>{{orgBasicDetails.totalRouteCount}}</span>
                                </div>
                            </div>
                        </div>
                        <div class="row orgBasicDetails" style="margin-bottom:10px">
                            <div class="col-md-12">
                                <div class="col-md-6 text-left">
                                    <label class="control-label mr5 basicDetailsLabel">Total Pickup Count:</label>
                                    <span>{{orgBasicDetails.totalPickupCount}}</span>
                                </div>
                                <div class="col-md-6 text-right">
                                    <a href="{{reportExcelDownloadLink}}" class="reportExcelDownloadLink" target="_blank">Download excel Report</a>
                                </div>
                            </div>
                        </div>
                        <div class="reportTableContainer">
                            <ul class="list-group" (scroll)="onScroll($event)" style="height:473px;overflow-y:auto" *ngIf="!noRouteDataFound; else noDataFound">
                                <li class="list-group-item" *ngFor = "let vehInfo of allVehicleAllRouteData;let $index=index;" style="cursor:pointer;border-right: none;
                                    border-left: none;" (click)="expandRow($index,vehInfo.vehId)" [ngClass]="{'selected':clickedVehicleObj == vehInfo.vehId}">
                                    <div class="row wrapperDiv mb5">
                                        <div class="col-md-1 busIconContainer" style="font-size: 31px; margin-right: -23px;">
                                            <span><i class="fa fa-bus"></i></span>
                                        </div>
                                        <div class="col-md-3 BusDetails">
                                            <div class="busName" style="font-size: 16px; margin-top: 2px;">{{vehInfo.vehNo}}</div>
                                            <div class="busCount" style="font-size: 14px;"><label class="control-label">Total Route Count: </label>{{vehInfo.totalRoutecount}}</div>
                                        </div>
                                    </div>
                                    <table class="table table-condensed table-bordered animated fade-in" *ngIf="$index === expandedIndex">
                                          <thead>
                                                <tr>
                                                    <th class="text-center">#</th>
                                                    <th class="text-center">Route Name</th>
                                                    <th class="text-center">Route Start Time</th>
                                                    <th class="text-center">Route End Time</th>
                                                    <th class="text-center">Total Pickup Count</th>
                                                    <th class="text-center">Total Member</th>
                                                </tr>
                                          </thead>
                                          <tbody *ngIf="vehInfo.totalRoutecount > 0">
                                              <tr class="text-center" *ngFor="let route of selectedRouteDetails;let $rowIndex = index">
                                                <td>{{$rowIndex+1}}</td>
                                                <td>{{route.routeName}}</td>
                                                <td>{{route.routeStartTime}}</td>
                                                <td>{{route.routeEndTime}}</td>
                                                <td>{{route.totalPickup}}</td>
                                                <td>{{route.totalmember}}</td>
                                              </tr>
                                          </tbody>
                                          <tbody *ngIf="vehInfo.totalRoutecount == 0">
                                              <tr>
                                                <td colspan="6" class="text-center bg-info">No routes found</td>
                                              </tr>
                                          </tbody>
                                  </table>
                                </li>
                            </ul>
                        </div>
                    </section>
                    <section class="panicReportContentSection" *ngIf="reportSections.panic && reportSectionsContent.panic">
                        <div class="panel panel-default">
                            <div class="panel-heading">
                                <div class="row memberBasicDetails mb5">
                                    <div class="reportHeading text-center mb5">Panic Report</div>
                                </div>
                                <div class="row">
                                    <div class="col-md-12">
                                        <div class="col-md-6">
                                            <label class="control-label">Vehicle Name:</label>
                                            <span class="reportSelectedVehicle">{{this.panicReportSelectedVehName}}</span>
                                        </div>
                                        <div class="col-md-6 text-right">
                                            <label class="control-label">Total Records:</label>
                                            <span class="totalRecordsCout">{{panicLog.length}}</span>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-12">
                                        <div class="col-md-6">
                                            <label class="control-label">Start Date:</label>
                                            <span class="reportStartDate">{{this.panicReportStartDate}}</span>
                                        </div>
                                        <div class="col-md-6 text-right">
                                            <label class="control-label">End Date:</label>
                                            <span class="reportEndDate">{{this.panicReportEndDate}}</span>
                                        </div>
                                    </div>
                                </div>
                                <div class="row mb5" *ngIf="noPanicRecordsFound">
                                    <div class="col-md-12 text-right">
                                        <a href="{{panicReportExcelDownloadLink}}" class="reportExcelDownloadLink" target="_blank" style="margin-right:15px">Download excel Report</a>
                                    </div>
                                </div>
                            </div>
                            <div class="panel-body fixedHeightPanelBody" (scroll)="scrollHandler($event)">
                                <div class="row">
                                    <div class="col-md-12 table-responsive reportTableContainer">
                                        <table class="table table-bordered table-stripped" *ngIf="!noPanicRecordsFound">
                                            <thead>
                                                <tr>
                                                    <th class="text-center">#</th>
                                                    <th class="text-center">Date</th>
                                                    <th style="width: 23%;" class="text-center">Vehicle No</th>
                                                    <th class="text-center">Location</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr *ngFor="let panicDet of panicLog;let i = index">
                                                    <td class="text-right">{{i+1}}</td>
                                                    <td class="text-right">{{panicDet.timeStamp | unixTimestampFormatter:panicDet.timeStamp}}</td>
                                                    <td class="text-left">
                                                    {{panicDet.vehNo}}
                                                    </td>
                                                    <td class="text-left">{{panicDet.nearestLocation}}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                        <h4 class="animated pulse text-center" *ngIf="noPanicRecordsFound" style="animation-duration: 1s;color:#999FBF"><i class="fa fa-frown-o"></i>&nbsp;No records found</h4>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section class="acReportContentSection" *ngIf="reportSections.ac  && reportSectionsContent.ac"></section>
                    <section class="accDccReportContentSection" *ngIf="reportSections.accanddecc  && reportSectionsContent.accanddecc"></section>
                    <section class="engineReportContentSection" *ngIf="reportSections.engine  && reportSectionsContent.engine"></section>
                    <section class="geofenceReportContentSection" *ngIf="reportSections.geofence  && reportSectionsContent.geofence"></section>
                    <section class="vehicleLastUpdateContentSection" *ngIf="reportSections.vehiclelastupdate  && reportSectionsContent.vehiclelastupdate">
                        <div class="reportHeading text-center mb5">Vehicle Last Update</div> 
                        <div class="row" *ngIf="vehicleLastUpdateLog?.length">
                            <div class="col-md-12 text-right">
                                <a href="{{vehicleLastUpdateDownloadLink}}" class="reportExcelDownloadLink" target="_blank" style="margin-right:15px">Download excel Report</a>
                            </div>
                        </div>
                        <table class="table table-stripped custom-table"  *ngIf="!noLastUpdateLog;else noDataFound" >
                            <thead>
                                <tr>
                                    <th style="width:8%" style="text-align:center">#</th>
                                    <th style="text-align:center">Vehicle Name</th>
                                    <th style="text-align:center">Status</th>
                                    <th style="text-align:center">Location</th>
                                    <th style="text-align:center">Updated on</th>
                                </tr>
                            </thead>
                            <tbody style="height:495px">
                                <tr *ngFor="let log of vehicleLastUpdateLog;let i = index;let lastItem = last;" style="height:80px">
                                    <td style="width:8%" style="text-align:right">{{i+1}}</td>
                                    <td style="text-align:left">
                                        <div class="mb5" >{{log.vehNo}}</div>
                                        <div class="vehRouteInfo">
                                            <span class="mr5" *ngFor="let route of log.routeAssigned;let i = index;let lastItem = last;">
                                                <span *ngIf="!lastItem">{{route}},</span>
                                                <span *ngIf="lastItem">{{route}}</span>
                                            </span>
                                        </div>
                                    </td>
                                    <td [ngClass]="log.movementType" style="text-align:left">{{log.movementType | titlecase}}</td>
                                    <td class="haltLocation" style="text-align:left" (click)="plotMovementLocation(log)">{{log.nearestLocation}}</td>
                                    <td>{{log.gpsTimestamp | unixTimestampFormatter}}</td>
                                </tr>
                            </tbody>
                         </table>
                    </section>

                    <section class="vehicleLastUpdateContentSection" *ngIf="reportSections.otherOverspeed && reportSectionsContent.otherOverspeed">

                            <div class="reportHeading text-center mb5">Vehicle Over speed Report </div> 
                          <!--   <div style="float:right;">
                               <a href="{{overspeedDownload}}" class="reportExcelDownloadLink" target="_blank">Download excel Report</a>
                             </div> <br><br> --->
                             <div style="max-height:550px;overflow-y:auto;overflow-x:hidden;" class="" (scroll)="scrollHandler1($event)">
                                <div class="row">
                                    <div class="col-md-12 table-responsive reportTableContainer">
                            <div *ngIf="!falseTemp">
                           
                                    <table class="table table-condensed table-bordered animated fade-in">
                                            <thead>
                                                <tr>
                                                    <th class="text-center"># </th>
                                                    <th class="text-center">Vehicle Number</th>
                                                    <th class="text-center">Location</th>
                                                    <th class="text-center">Speed</th>
                                                    <th class="text-center">TimeStamp</th>
                
                                                </tr>
                                            </thead>
                                            <tbody >
                                            <!-- <ng-container *ngFor="let route  of otherOverspeedLogReport|objectkeys;let $rowIndex = index">-->
                                            <ng-container *ngFor="let route  of otherOverspeedLogReport;trackBy: trackByFn;let $rowIndex = index">
                                           


                                            <tr>
                                                    <td class="text-right">{{$rowIndex+1}}</td>
                                                    <td class="text-left">{{route.vehNo}}</td>
                                                    <td class="text-left"
                                                    style="max-width: 23em !important;width: 23em;min-width: 23em !important;">{{route.nearestLocation}}</td>
                                                    <td class="text-right">{{route.speed}}</td>
                                                    <td class="text-right">{{route.timeStamp | date:'dd/LL/yyyy h:mm:ss aa'}}</td>



                                            </tr>
                                                <!-- <tr class="text-center">
                                                <td>{{$rowIndex+1}}</td>
                                                <td>{{otherOverspeedLogReport[route].vehNo}}</td>
                                            
                                                <td
                                                style="max-width: 23em !important;
                                                width: 23em;
                                                min-width: 23em !important;"
                                                >{{otherOverspeedLogReport[route].nearestLocation}}</td>
                                                <td>{{otherOverspeedLogReport[route].speed}}</td>
                                                <!--<td>{{otherOverspeedLogReport[route].timeStamp| date:'short'}}</td>
                                                <td>{{otherOverspeedLogReport[route].timeStamp| date:'dd/LL/yyyy h:mm:ss aa'}}</td>
                                                </tr>-->
                                                </ng-container>
                                            </tbody>
                                            
                                    </table>

                                 
                            </div>
                            </div> </div> </div>
                          
                            <div *ngIf="falseTemp" >
                                    <h4 class="animated pulse text-center"  style="animation-duration: 1s;color:#999FBF; margin:70px;"><i class="fa fa-frown-o"></i>&nbsp;
                                    No Data to Display</h4>
                            </div>

                          
                       
                    </section>
                 
       
                    <section class="vehicleLastUpdateContentSection" *ngIf="reportSections.notificationAlert && reportSectionsContent.notificationAlert">
                                <div class="reportHeading text-center">Notification Alert Log</div> 
                              
                                    <div *ngIf="!falseTemp">

                                        <div class="row" style="margin: 0; margin-bottom: 12px;">
                                                     <span class="countSpan"> Total Count : {{totNotifiCount}}</span>
                                        </div>
                                        
                                        <table class="table table-condensed table-bordered animated fade-in">
                                            <thead>
                                                <tr>
                                                    <th class="text-center">#</th>
                                                    <th class="text-center">Name</th>
                                                    <th class="text-center">Alert Type</th>
                                                    <th class="text-center">Vehicle Number</th>
                                                    <th class="text-center" *ngIf="speedClm">Speed</th>
                                                    <th class="text-center">Mobile / Email</th>
                                                    <th class="text-center">TimeStamp</th>

                                                </tr>
                                            </thead>
                                            <tbody >
                                                <tr class="text-center" *ngFor="let notification  of notificationReports;let $rowIndex = index">

                                                    <td style="text-align:right">{{$rowIndex+1}}</td>
                                                    <td style="text-align:left">{{notification.memberName}}</td>
                                                    <td style="text-align:left">{{notification.alertvia}}</td>
                                                    <td style="text-align:left">{{notification.vehNo}}</td>
                                                    <td  *ngIf="speedClm" style="text-align:right">{{notification.speedVal}}</td>
                                                    <td style="text-align:right"> 
                                                       <span *ngIf="notification.mobileno" style="text-align:right">
                                                          <i class="fa fa-mobile" aria-hidden="true"></i>
                                                           {{notification.mobileno}}
                                                       </span>
                                                       <span *ngIf="notification.email" style="text-align:right">
                                                             <i class="fa fa-envelope-o" aria-hidden="true"></i>
                                                            {{notification.email}}
                                                       </span>
                                                       
                                                        <span *ngIf="!notification.mobileno && !notification.email" style="text-align:right">--</span>
                                                    </td>
                                                    <td style="text-align:right">{{notification.logTimeMS | date:'dd/LL/yyyy h:mm aa'}}</td>
                                                  
                                                </tr>
                                            </tbody>

                                        </table>
                                 </div>
                                <div *ngIf="falseTemp" > 
                                        <h4 *ngIf="scb36" class="animated pulse text-center" style="animation-duration: 1s;color:#999FBF; margin:70px;">
                                            The report can generate only for a 7 days
                                        </h4>
                                        <h4 *ngIf="!scb36" class="animated pulse text-center"  style="animation-duration: 1s;color:#999FBF; margin:70px;"><i class="fa fa-frown-o"></i>&nbsp;
                                        No Data to Display</h4>
                                </div>
                               

                                <div class="pagination pull-right" style="margin-right:15px;cursor:pointer;" *ngIf="showPaginationNotif">
                                    <a data-toggle="tooltip" title="Previous" *ngIf="pageJump<1">&nbsp;<i class="fa fa-angle-double-left" aria-hidden="true" class="disabled"></i></a>
                                    <a  data-toggle="tooltip" title="Previous" *ngIf="pageJump>0" (click)="pageJump=pageJump-1;viewOtherNotificationLog(pageJump)">&nbsp;<i class="fa fa-angle-double-left" aria-hidden="true"></i></a>
                                    <a class="active" data-toggle="tooltip" title="Current Page">{{pageJump+1}}</a>
                                    <a  data-toggle="tooltip" title="Next" *ngIf="notificationReports?.length > 0 && !falseTemp" (click)="pageJump=pageJump+1;viewOtherNotificationLog(pageJump)" >&nbsp;<i class="fa fa-angle-double-right" aria-hidden="true"></i></a>
                                    <a  data-toggle="tooltip" title="Next" *ngIf="notificationReports?.length < 1" class="disabled" >&nbsp;<i class="fa fa-angle-double-right" aria-hidden="true"></i></a>
                                </div>
                    </section>
 <section class="breakDownReport" *ngIf="reportSections.breakDownReport">
          
 <breakdown-reports ></breakdown-reports>
</section>
                    <section class="vehicleBasedNotificationAlert" *ngIf="reportSections.vehicleBasedNotificationAlert && reportSectionsContent.vehicleBasedNotificationAlert">

                          <div class="reportHeading text-center">Vehicle Based Notification Alert Log</div> 
                            <div *ngIf="!falseTemp">
                                    <div class="row" style="margin: 0; margin-bottom: 12px;">

                                       <div class="col-md-6">
                                         <!--    <span class="countSpan"> Total Count : {{vehicleBasedCount}}</span>-->
                                       </div>
                                       <div class="col-md-6">
                                       <div class="col-md-6 text-right" style="float: right;font-size: 15px;">
                                       <a href="{{vehicleBasedDownload}}" class="reportExcelDownloadLink" target="_blank">Download excel Report</a>
                                   </div>
                                       </div>
                                      <!-- <div class="col-md-8">
                                            <div class="form-group col-md-4">
                                            <input checked type="checkbox" class="chk regular-checkbox" [(ngModel)]="showOnlySMS" (ngModelChange)="reportType($event,'sms')">
                                            <label class="control-label">SMS</label>
                                            </div>
                                            <div class="form-group col-md-4">
                                            <input checked type="checkbox" class="chk regular-checkbox" [(ngModel)]="showOnlyEmail" (ngModelChange)="reportType($event,'email')">
                                            <label class="control-label">Email</label>
                                            </div>
                                       </div>-->                                     


                                    </div>
                                    
                                    <div class="row vehicleInfo">

                                         <ng-container *ngFor="let vehIds  of vehicleLogReports|objectkeys"> 

                                             
                                            <div class="card">
                                                 <div class="col-md-12 report-bord" style="font-weight: 600;font-size: 15px;background: lightgrey;">
                                                     <div class="col-md-2">
                                                            Vehicle Name
                                                     </div>
                                                     <div class="col-md-9">
                                                     <span title="{{vehicleLogReports[vehIds][listDate[0]][0].vehNo}}">{{ vehicleLogReports[vehIds][listDate[0]][0].vehNo }}</span>
                                                     </div>
                                                 </div>
                                         
                                                 <div class="col-md-12 report-bord">
                                                        <div class="col-md-2">
                                                               
                                                                  <table>
                                                                    <thead>
                                                                            <tr>
                                                                                <th class="budge">Alert Log Info</th>
                                                                            </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        <tr>
                                                                            <td>
                                                                            <span > Date </span>
                                                                            </td>
                                                                        </tr>
                                                                        <tr>
                                                                          <td>Alert Type</td>
                                                                        </tr>
                                                                        <tr>
                                                                             <td><span class="style-css" title="Over speed">O.S</span></td>
                                                                        </tr>
                                                                        <tr>
                                                                             <td><span class="style-css"  title="Panic">P</span></td>
                                                                        </tr>
                                                                    </tbody>
                                                                  
                                                                  </table>
                                                                </div>                                                                             
                                                       
                                                                <ng-container *ngFor="let dates of vehicleLogReports[vehIds]|objectkeys">
                                                                    <div class="col-md-2">
                                                                        <div class="card">
                                                                        <!-- <p style="font-weight: 900;"> {{parseInt(new Date(dates).getTime()) | date:'dd-LL-YYY'}}</p>  -->
                                                                        <p style="font-weight: 900;"> {{ dates | datestringtounix | date:'dd-LL-yyyy' }}</p>
                                                                            <table>
                                                                                <tr>
                                                                                    <td></td>
                                                                                    <td style="padding: 0 43px 1px 0px;"><i class="fa fa-envelope-o" aria-hidden="true"></i></td>
                                                                                    <td><i class="fa fa-mobile" aria-hidden="true"></i></td>
                                                                                </tr>
                                                                                <tr>
                                                                                    <td></td>
                                                                                    <td style="padding: 0 43px 1px 0px;">{{vehicleLogReports[vehIds][dates][0].OS_EMAIL_CNT}}</td>
                                                                                    <td>{{vehicleLogReports[vehIds][dates][0].OS_SMS_CNT}}</td>
                                                                                </tr>
                                                                                <tr>
                                                                                    <td></td>
                                                                                    <td style="padding: 0 43px 1px 0px;">{{vehicleLogReports[vehIds][dates][0].PANIC_EMAIL_CNT}}</td>
                                                                                    <td>{{vehicleLogReports[vehIds][dates][0].PANIC_SMS_CNT}}</td>
                                                                                </tr>
                                                                            </table>                                                                                                                                                   
                                                                        </div>   
                                                                    </div>
                                                                </ng-container>
                                                        </div>  
                                              </div>    
                                       
                                        </ng-container>                                   
                                    
                                    </div>

                                    <!--<table class="table table-condensed table-bordered animated fade-in">
                                        <thead>
                                            <tr>
                                                <th class="text-center">#</th>
                                                <th class="text-center">Vehicle Name</th>
                                                <th class="text-center">Date</th>
                                                <th class="text-center">Overspeed SMS</th>
                                                <th class="text-center">Overspeed Email</th>
                                                <th class="text-center">Panic SMS</th>
                                                <th class="text-center">Panic Email</th>

                                            </tr>
                                        </thead>
                                        <tbody >
                                            <ng-container *ngFor="let vehicleNotification  of vehicleLogReports|objectkeys">
                                                 <tr class="text-center" *ngFor="let vehicleId  of vehicleLogReports[vehicleNotification]|objectkeys;let i=index;">
                                                        <td>
                                                            <inc *ngIf="incDiv">
                                                            
                                                            </inc>
                                                        </td>
                                                        <td>{{vehicleLogReports[vehicleNotification][vehicleId].vehNo}}</td>
                                                        <td>{{vehicleNotification}}</td>
                                                        <td>{{vehicleLogReports[vehicleNotification][vehicleId].OS_SMS_CNT}}</td>
                                                        <td>{{vehicleLogReports[vehicleNotification][vehicleId].OS_EMAIL_CNT}}</td>                                                        
                                                        <td>{{vehicleLogReports[vehicleNotification][vehicleId].PANIC_SMS_CNT}}</td>
                                                        <td>{{vehicleLogReports[vehicleNotification][vehicleId].PANIC_EMAIL_CNT}}</td>

                                                 </tr>
                                                
                                            </ng-container>
                                        </tbody>
                                    </table> -->

                            </div>
                            <div *ngIf="falseTemp" > 
                                <h4 *ngIf="scb36" class="animated pulse text-center" style="animation-duration: 1s;color:#999FBF; margin:70px;">
                                    The report can generate only for a 5 days
                                </h4>
                                <h4 *ngIf="!scb36" class="animated pulse text-center"  style="animation-duration: 1s;color:#999FBF; margin:70px;"><i class="fa fa-frown-o"></i>&nbsp;
                                No Data to Display</h4>
                            </div>
                            
                    </section>

                </div>
            </div>
       
        <div id="myModal" class="modal fade" style="padding:7%;">
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-header">
                        <button type="button" class="close" data-dismiss="modal" aria-hidden="true">&times;</button>
                        <h4 class="modal-title">Vehicle Location</h4>
                    </div>
                    <div class="modal-body">
                        <div id="pointLocationMap" style="height:300px"></div>
                    </div>
                </div>
            </div>
        </div>

        <ng-template #noDataFound>
            <h4 class="animated pulse text-center" style="animation-duration: 1s;color:#999FBF"><i class="fa fa-frown-o"></i>&nbsp;No records found</h4>
        </ng-template>

        <ng-template #pageLoader>
            <div class="loaderContainer">
                <loader></loader>
            </div>
        </ng-template>

    `,
    styles: [`
        .availableReportOptions{
            border-right:1px solid #9e9e9e91;
            padding: 0.9em;
        }
        .reportHeading{
            font-size:1.7em;
        }
        div.fixedHeight{
            height: 605px;
            overflow-y:auto;
            overflow-x:hidden;
        }
        .applyFilterBtn, .viewReportBtn{
            color: #fff;
            background-color: #64c7e9;
            border-color: #64c7e9;
            padding: 12px 10px 12px 10px !important;
            font-size: 11px;
        }

        td.moving{
            color:#2EA737;
        }

        td.offline{
            color:#696969;
        }

        td.halt{
            color:red;
        }



        label.basicDetailsLabel {
            font-weight: 600;
            font-size: 14px;
        }
        .reportExcelDownloadLink{
            text-decoration: none;
            cursor: pointer;
        }
        .fixedHeightPanelBody{
            max-height:472px;
            overflow:auto;
        }
        li.selected{
            background-color:#f9f9f9;
        }

        .haltLocation{
            cursor:pointer;
            color: #777 !important;
         }
         .countSpan{
            background: gainsboro;
            padding: 5px 6px 2px 4px;
            border-radius: 5px;
        }
         .routeTile{
            height: 10px;
            width: 10px;
            background: #9e9e9e21;
            padding: 5px;
         }

        .custom-table tbody {
            display:block;
            height:440px;
            overflow:auto;
          }
        .custom-table thead, .custom-table tbody tr {
            display:table;
            width:100%;
            table-layout:fixed;
        }
          
        .custom-table th {
        color: rgba(0, 0, 0, 0.54);
        font-size: 15px;
        font-weight: 500;
        white-space: nowrap;
        }
        .custom-table tbody td{
        vertical-align:middle !important;
        font-size: 13px !important;
        border-top: 1px rgba(0, 0, 0, 0.12) solid !important;
        padding: 0 24px !important;
        }
        .loaderContainer{
            margin: 20% 0 0 30%;
        }
        .vehicleInfo.vInfo{
            border: 1px solid gray;
        }

         .report-bord{
            border: 1px solid gainsboro;
            padding: 2px 3px 4px 3px;
            margin: 3px 2px 7px 4px;
         }
         .title-icon{
            padding: 0 13px 0px 0px;
         }
         .card{margin-top:20px;border-right:0.5px solid #dcdcdc;}
         span.style-css {
            float: right;
            /* font-size: 14px; */
            padding: 8px 0 0px 3px;
            font-weight: 900;
        }
        .budge{
            display: inline-block;
            padding: 1px 6px 1px 1px;
            background: #00a8e6;
            line-height: 16px;
            color: #fff;
            line-height: 15px;
            /* vertical-align: middle; */
            /* text-transform: none; */
            border-radius: 2px;
            text-shadow: 0 1px 0 rgba(0,0,0,.1);
        }

        `]
})

export class otherReportsGroup {
    public reportOptions: any[] = [];
    public walkerList: any = [];
    private allOrgRoutes: any[] = [];
    private rawAllVehicleData: any[] = [];
    private allVehicleAllRouteData: any[] = [];
    private selectedRouteDetails: any[] = [];
    public setFilteredRoute: any[] = [];
    public noRouteDataFound: boolean = false;
    private allOrgVehicles: any[] = [];
    private clickedVehicleObj: string;
    private orgBasicDetails: any = {};
    public speedClm: boolean;
    private filterVehicleReg: any;
    private filterRouteId: any;
    private globalFilterObj: any = {};
    private expandedIndex: any = -1;
    private pageJump: number = 0;
    private isScrolled: boolean = false;
    private noMoredata: boolean;
    private reportExcelDownloadLink: string;
    private selectedReport: any;
    private resetSearchUtility: any;
    private panicReportStartDate: any;
    private panicReportEndDate: any
    private panicReportSelectedVeh: any[];
    private panicReportSelectedVehName: any;
    private noPanicRecordsFound: boolean;
    private panicReportExcelDownloadLink: string;
    private panicLog: any[] = [];
    public setSingleSelectedItem: any = {};
    public isDataProcessed: any = {};
    public notificationStartDate: boolean;
    public notificationEndDate: boolean;
    public notificationReports: any;
    public totNotifiCount: any;
    public scb36: boolean;
    public incDiv: boolean = true;
    public bearkDownReportType: string = '';
    public PanicStartDateError: boolean;
    public panicEndDateError: boolean;
    public panicDatesMisMatchError: boolean;
    public emptyVehicleError: boolean;
    public isLoading: boolean;
    private displayErrorMessage: string;
    private overSpeedReport: boolean;
    public routesDatesMisMatchError: boolean;
    public overspeedEndDate: boolean;
    public overspeedStartDate: boolean;
    public overSpeedLimitError: boolean;
    public reportTypeKeyLookup: any;
    vehicleLastUpdateLog: any[];
    public breakDownAlertCount: number;
    public breakdownReport: any;
    rawVehLog: any[];
    noLastUpdateLog: boolean;
    filterMovementType: string;
    vehicleLastUpdateDownloadLink: string;
    public otherOverspeedLogReport: any = {};
    public falseTemp: boolean;
    public lowspeed: number;
    public highspeed: number;
    public showMoreFilter: boolean;
    public selectedReportType: any;
    public reportTypeErr: boolean;
    public selectedAlert: any;
    public showOnlyEmail: any;
    public showOnlySMS: any;
    public vehicleBasedDownload: any;
    public walkerVehId: any = [];
    public StartDateError: boolean;
    public breakdownMsgObj: any;
    public breakDownMobileNumber: any;
    public totBreakdownMsgCount: number;
    public showPagination: boolean = true;
    public reportSections = {
        ac: false,
        accanddecc: false,
        engine: false,
        geofence: false,
        panic: false,
        routevehiclemapped: false,
        vehiclelastupdate: false,
        otherOverspeed: false,
        notificationAlert: false,
        vehicleBasedNotificationAlert: false,
        breakDownReport: false
    };
    public reportSectionsContent = {
        ac: false,
        accanddecc: false,
        engine: false,
        geofence: false,
        panic: false,
        routevehiclemapped: false,
        vehiclelastupdate: false,
        otherOverspeed: false,
        notificationAlert: false,
        vehicleBasedNotificationAlert: false,
        breakDownReport: false
    };
    private reportLookUpTitle = {
        ac: 'AC Log',
        accanddecc: 'Acceleration & De-acceleration',
        engine: 'Engine Log',
        geofence: 'Geofence Log',
        panic: 'Panic log',
        routevehiclemapped: 'All Vehicle All Routes',
        vehiclelastupdate: 'Vehicle Last Update',
        otherOverspeed: 'All Vehicle Overspeeding Log',
        notificationAlert: 'Notification Alert Log',
        vehicleBasedNotificationAlert: 'Vehicle Based Notification Alert',
        breakDownReport: 'Break Down Report'
    }

    private reportLookUpFunction = {
        ac: 'initACReport',
        accanddecc: 'initAccDccReport',
        engine: 'initEngineReport',
        geofence: 'initGeofenceReport',
        panic: 'initPanicReport',
        routevehiclemapped: 'initAllVehicleRouteReport',
        vehiclelastupdate: 'initVehicleLastUpdateReport',
        otherOverspeed: 'initOtherOverspeedReourt',
        notificationAlert: 'intiNotificationAlert',
        vehicleBasedNotificationAlert: 'intiNotificationAlert',
        breakDownReport: 'breakDownReportIntiNotificationAlert'
    }

    public parameter: any;
    routeEmitedId: any = "";
    showPaginationNotif: boolean = true;
    showPaginationOverSpeed: boolean = true;
    globalPanicAPIHEADER: any;
    paginate: number;
    finishedApiCallFlag: boolean = false;
    @Input('availableReports')
    set availableReports(param: any) {
        // this.generateOptions(param);
        this.parameter = param;
    }
    @Input('otherReport')
    set otherReport(data: any) {
        if (data !== undefined) {
            this.otherReports(data.others);
        }
    }

    otherReports(obj: any) {
        let report;
        if (localStorage.getItem('userInfo')) {
            report = JSON.parse(localStorage.getItem('userInfo'));
        }
        let uLevel = JSON.parse(localStorage.getItem('userLevel'));

        if (uLevel === 1) {
            let lone = Object.assign(this.parameter);
            this.generateOptions(lone);
        } else if (uLevel === 2) {
            let alertControl = Object.assign(this.parameter, report.reports.others);
            this.generateOptions(alertControl);
        }
    }
    constructor(private apiService: ApiService,
        private chosenUtility: chosenUtility,
        private bootstrapDateTime: bootstrapDateTime,
        public dateChange: ApiCallDateFormatService,
        private globalUtils: GlobalUtils,
        private com: COM) {

        this.com.comAction.subscribe((e: any) => {
            if (e.type === 'pagenation') {
                this.breakDownReport(e.data);
            }
        });

        this.noMoredata = false;
        this.reportExcelDownloadLink = `${this.apiService.base}organization/org_routevehicledownload/key/${localStorage.getItem('scbToken')}/download/yes`;
        this.vehicleLastUpdateLog = [];
        this.rawVehLog = [];
        this.noLastUpdateLog = false;
        this.filterMovementType = 'all';
        this.vehicleLastUpdateDownloadLink = null;
        this.isLoading = false;

        this.finishedApiCallFlag = false;
        this.datePicker();
    }

    ngOnInit() {
        //this.getRouteWalkerList();

        this.reportTypeKeyLookup = {
            movement: {
                movement: {
                    value: 5
                }
            },
            halt: {
                halt: {
                    value: 0
                },
            },
            overspeed: {
                overspeed: {
                    value: null
                }
            },
            lowspeed: {
                lowspeed: {
                    value: null
                }
            },
            betweentwospeed: {
                twospeed: {
                    lowValue: null,
                    highValue: null
                }
            }
        }
        this.getCalendar();
        this.initChosen();
        //this.loadCSS();
        this.pickathpluginInit();
    }
    pickathpluginInit() {
        return new Promise(function (resolve, reject) {

            setTimeout(() =>


                (new Pickathing('demo-select', true, { searchLabel: 'Search for options...' }), resolve(true)), 500);

        }).then(function (res1) {



            return res1 && new Promise((res2, reject) => { // (*)
                // res2('Second')
                var srchIpBox: any = document.getElementsByClassName('Pickathing-dropdown');


                // var PickathingList:any = document.getElementsByClassName('Pickathing-list');
                var span = document.createElement('span');
                var i = document.createElement('i');

                span.setAttribute('id', 'pickathSearchOption');

                i.setAttribute('class', 'fa fa-search');

                srchIpBox[0].appendChild(span);
                span.appendChild(i);

                // PickathingList[0].style.display = 'none';

                span.style.position = 'absolute';
                span.style.top = '16px';
                span.style.right = '19px';
                span.style.zIndex = '999';
                res2(true);
            });

        }).then(function (result) { // (**)



            return result == true && new Promise((res3, reject) => {
                res3(true);
            });

        }).then(function (result) {



            var targetButton: any = document.getElementsByClassName('Pickathing-option');

            /// targetButton[0].innerHTML = '';
            targetButton[0].style.display = "none";

        });
    }
    // loadCSS(){
    //     setTimeout(() => {



    //         var demoSelect = new Pickathing('demo-select', true, {searchLabel: 'Search for options...'});

    //     }, 200);


    // }
    ngAfterViewInit() {


        // this.dynamicallyinjectSearch();


        // this.defaultSelectOptionRemoval('Pickathing-option');
    }
    // dynamicallyinjectSearch(){
    //     setTimeout(() => {
    //         var srchIpBox:any = document.getElementsByClassName('Pickathing-dropdown');


    //         // var PickathingList:any = document.getElementsByClassName('Pickathing-list');
    //         var span = document.createElement('span');
    //         var i = document.createElement('i');

    //         span.setAttribute('id','pickathSearchOption');

    //         i.setAttribute('class','fa fa-search');

    //         srchIpBox[0].appendChild(span);
    //         span.appendChild(i);

    //         // PickathingList[0].style.display = 'none';

    //         span.style.position = 'absolute';
    //         span.style.top = '16px';
    //         span.style.right = '19px';
    //         span.style.zIndex = '999';

    //     }, 600);
    // }
    // defaultSelectOptionRemoval(str:string){
    //     setTimeout(() => {
    //         var targetButton:any = document.getElementsByClassName(str);

    //         // targetButton[0].innerHTML = '';
    //         targetButton[0].style.display = "none";

    //     }, 700);

    // }
    getCalendar() {
        this.bootstrapDateTime.datetime({
            component: 'selectedLogDate'
        });



    }
    datePicker() {
        $('#eventStartTime').datetimepicker({
            format: 'H:i',
            datepicker: false,
            defaultTime: '00:00',
            timepicker: true,
        });
        $('#eventEndTime').datetimepicker({
            format: 'H:i',
            datepicker: false,
            defaultTime: '23:59',
            timepicker: true,
        });
    }

    generateOptions(param: any): void {
        for (let obj in param) {
            let data = {};
            if (param[obj]) {
                data['reportName'] = this.reportLookUpTitle[obj];
                data['value'] = obj;
                this.reportOptions.push(data);
            }
        }
    }

    public selectedRoute: any;
    public selectRouteType: any;
    public vehInfo: any = {};
    getRouteWalkerList() {
        let apiHeader = {
            data: {
                key: localStorage.getItem('scbToken'),
                filter: {
                    type: 'gate'
                }
            }

        }
        this.apiService.getAllOrgDevices(apiHeader).then(response => {
            if (response.response) {


                let walker = response.response;

                (Object.keys(walker)).forEach(report => {

                    this.walkerList.push(walker[report].vehNo);
                    this.walkerVehId.push(walker[report].vehId);
                    if (this.vehInfo[walker[report].vehId] === undefined) {
                        this.vehInfo[walker[report].vehId] = [];
                    }

                    this.vehInfo[walker[report].vehId].push({
                        'vehNo': walker[report].vehNo
                    });
                });

                // this.orgSections = response.response.SectionLists;
            }
        });
    }
    initReportSection({ ac = false, accanddecc = false, engine = false, geofence = false, panic = false, routevehiclemapped = false, vehiclelastupdate = false,
        otherOverspeed = false, notificationAlert = false, vehicleBasedNotificationAlert = false, breakDownReport = false } = {}): void {
        let providedParam = Object.getOwnPropertyNames(arguments[0]);
        let calleFunction = this.reportLookUpFunction[providedParam[0]];
        this.reportSections.ac = ac;
        this.reportSections.accanddecc = accanddecc;
        this.reportSections.engine = engine;
        this.reportSections.geofence = geofence;
        this.reportSections.panic = panic;
        this.reportSections.routevehiclemapped = routevehiclemapped;
        this.reportSections.vehiclelastupdate = vehiclelastupdate;
        this.reportSections.otherOverspeed = otherOverspeed;
        this.reportSections.notificationAlert = notificationAlert;
        this.reportSections.vehicleBasedNotificationAlert = vehicleBasedNotificationAlert;
        this.reportSections.breakDownReport = breakDownReport;

        this[`${calleFunction}`](calleFunction); ///console.log(calleFunction);
        this.initReportSectionContent({});
        this.resetAllValues();
    }

    initializeReportContent(sectionContentId: string): void {
        let obj = {};
        obj['' + sectionContentId] = true;
        this.initReportSectionContent(obj);
    }

    initReportSectionContent({ ac = false, accanddecc = false, engine = false, geofence = false, panic = false, routevehiclemapped = false, vehiclelastupdate = false,
        otherOverspeed = false, notificationAlert = false, vehicleBasedNotificationAlert = false, breakDownReport = false } = {}): void {
        this.reportSectionsContent.ac = ac;
        this.reportSectionsContent.accanddecc = accanddecc;
        this.reportSectionsContent.engine = engine;
        this.reportSectionsContent.geofence = geofence;
        this.reportSectionsContent.panic = panic;
        this.reportSectionsContent.routevehiclemapped = routevehiclemapped;
        this.reportSectionsContent.vehiclelastupdate = vehiclelastupdate;
        this.reportSectionsContent.otherOverspeed = otherOverspeed;
        this.reportSectionsContent.notificationAlert = notificationAlert;
        this.reportSectionsContent.vehicleBasedNotificationAlert = vehicleBasedNotificationAlert;
        this.reportSectionsContent.breakDownReport = breakDownReport;

    }

    initACReport(): void { }
    initAccDccReport(): void { }
    initEngineReport(): void { }
    initGeofenceReport(): void { }
    initPanicReport(): void {
        this.getAllDevices();
        setTimeout(() => {
            this.initJqUtils(undefined, 'routeHistoryStartDate', 'routeHistoryEndDate');
        }, 300)
    }
    initAllVehicleRouteReport(): void {
        this.getReportData('routevehiclemapped');
        this.getAllOrgRoutes();
        setTimeout(() => { this.resetOtherRepoTempErr() }, 200);
    }
    initVehicleLastUpdateReport(): void {
        this.getDevicesLastUpdates('vehiclelastupdate');
        setTimeout(() => { this.resetOtherRepoTempErr() }, 200);

    }

    initOtherOverspeedReourt(): void {
        //  this.getOverspeedReport('otherOverspeed');
        setTimeout(() => {
            this.initJqUtils(undefined, 'routeHistoryStartDate', 'routeHistoryEndDate');
        }, 300);
        setTimeout(() => { this.resetOtherRepoTempErr() }, 200);

    }

    intiNotificationAlert(): void {

        setTimeout(() => {
            this.initJqUtils(undefined, 'routeHistoryStartDate', 'routeHistoryEndDate');
        }, 300);
        setTimeout(() => { this.resetOtherRepoTempErr() }, 200);


    }

    breakDownReportIntiNotificationAlert(): void {

        setTimeout(() => {
            this.initJqUtilsSingleDate(undefined, 'routeHistoryStartDate', 'routeHistoryEndDate', undefined);
        }, 300);
        setTimeout(() => { this.resetOtherRepoTempErr() }, 200);

    }

    resetAllValues(): void {
        /*All route-vehicle mapped vars */
        this.allVehicleAllRouteData = [];
        this.rawAllVehicleData = [];
        this.globalFilterObj = {};
        this.pageJump = 0;
        this.filterVehicleReg = null;
        /*All route-vehicle mapped vars end*/
        /*Panic report vars*/
        this.allOrgVehicles = [];
        this.panicReportSelectedVeh = [];
        this.panicLog = [];
        this.panicReportEndDate = null;
        this.panicReportStartDate = null;
        this.panicReportSelectedVehName = null;
        this.panicReportExcelDownloadLink = null;
        /*Panic report vars end*/
    }

    getAllDevices(): void {
        let apiHeader = {
            data: {
                key: localStorage.getItem('scbToken')
            }
        }
        this.apiService.getAllOrgDevices(apiHeader).then(response => {
            this.allOrgVehicles = response.response;
        }).catch(error => { });
    }
    public selectedVechId: string;
    setUserSelection(selectedValue: any) { // setting single selected item.
        if (selectedValue) this.setSingleSelectedItem = selectedValue;
        this.selectedVechId = selectedValue && selectedValue[0] && selectedValue[0].vehId ? selectedValue[0].vehId : '';

        if (this.isDataProcessed) this.isDataProcessed = false;
    }

    // setUserSelections(devices:any){
    //     this.daySummaryVehSelection =  devices.map((vehInfo:any) =>  {return vehInfo.vehId});
    //     return this.daySummaryVehSelection;
    // }

    setRouteSelection(event: any) {
        // console.log(event);
        this.routeEmitedId = event && event[0] && event[0].routeId;
    }

    getAllOrgRoutes(): void {
        let apiHeader = {
            data: {
                key: localStorage.getItem('scbToken'),
            }
        }
        this.apiService.getRoutes(apiHeader).then(response => {
            this.allOrgRoutes = response.response;
            this.initializeChosenUtility();
        }).catch(error => { })
    }

    applyFilters(): void {
        this.globalFilterObj = {};
        //let isFilterRouteSelected = this.chosenUtility.getControlvalue('orgRoutesDropdown');
        if (this.filterVehicleReg) {
            Object.assign(this.globalFilterObj, { regNo: this.filterVehicleReg });
        }

        // if (this.setFilteredRoute.length) {
        //     Object.assign(this.globalFilterObj, { routeId: this.setFilteredRoute[0]['routeId'] });
        // }

        this.routeEmitedId && this.routeEmitedId.length && Object.assign(this.globalFilterObj, { routeId: this.routeEmitedId });

        this.pageJump = 0;
        this.rawAllVehicleData = [];
        this.allVehicleAllRouteData = [];
        this.getReportData(undefined);
    }

    getReportData(sectionContentId: any): void {
        let apiHeader = {
            data: {
                key: localStorage.getItem('scbToken'),
                extra: {
                    pageJump: this.pageJump
                }
            }
        }
        if (sectionContentId) this.isLoading = true;
        if (Object.getOwnPropertyNames(this.globalFilterObj).length) {
            apiHeader.data['filter'] = this.globalFilterObj;
        } else {
            delete apiHeader.data['filter'];
        }

        this.apiService.getAllVehicleAllRouteReport(apiHeader).then(response => {
            if (response.response) {
                //this.rawAllVehicleData = response.response;
                this.orgBasicDetails = Reflect.get(response.response, 'orgDetails');

                if (response.response.hasOwnProperty('vehDetails')) {
                    let desiredData = Reflect.get(response.response, 'vehDetails');

                    for (let info in desiredData) {
                        Object.assign(desiredData[info], { vehId: info });
                        this.allVehicleAllRouteData.push(desiredData[info]);
                        this.rawAllVehicleData[info] = desiredData[info];
                    }
                    this.noMoredata = false;
                    this.noRouteDataFound = false;
                } else {
                    this.noMoredata = true;
                    if (!this.allVehicleAllRouteData.length) this.noRouteDataFound = true;
                }
            } else {
                this.noMoredata = true;
                this.pageJump = 0;
            }
            this.isLoading = false;
            if (sectionContentId) this.initializeReportContent(sectionContentId);
        }).catch(error => { })
    }

    expandRow(index: any, vehicleId: any): void {
        this.selectedRouteDetails = [];
        this.clickedVehicleObj = vehicleId;
        if (index != this.expandedIndex) {
            let availableRouteInfo = this.rawAllVehicleData['' + vehicleId].RouteInfo;
            let desiredRouteInfo: any[] = [];
            for (let routeId in availableRouteInfo) {
                Object.assign(availableRouteInfo[routeId], { routeId: routeId });
                this.selectedRouteDetails.push(availableRouteInfo[routeId]);
            }
            this.expandedIndex = index === this.expandedIndex ? -1 : index;
        } else {
            this.expandedIndex = index === this.expandedIndex ? -1 : index;
        }
    }

    /*input validation function by Deepak*/
    InitiatePanicInputValidator = () => {
        let isValid: boolean = true;
        this.displayErrorMessage = '';
        //  if(!this.panicReportSelectedVeh.length){
        //    this.emptyVehicleError = true;
        //    isValid =false;
        //  }else{
        //    this.emptyVehicleError = false;
        //  }

        let paniStartDate = $('#routeHistoryStartDate').val();
        let panicEndDate = $('#routeHistoryEndDate').val();

        let dateObj = this.genericDateValidate(paniStartDate, panicEndDate);
        if (dateObj.stTimeStampString.length <= 10) {
            // if (!paniStartDate) {
            this.PanicStartDateError = true;
            isValid = false;
        } else {
            this.PanicStartDateError = false;
        }

        if (dateObj.endTimeStampString.length <= 10) {
            // if (!panicEndDate) {
            this.panicEndDateError = true;
            isValid = false;
        } else {
            this.panicEndDateError = false;
        }
        if (dateObj.stTimeStamp > dateObj.endTimeStamp) {
            // if ($('#routeHistoryStartDate').val() > $('#routeHistoryEndDate').val()) {
            this.panicDatesMisMatchError = true;
            isValid = false;
        } else {
            this.panicDatesMisMatchError = false;
        }
        return isValid;
    }
    scrollHandler($event: any) {

        if (($event.target.scrollTop + $event.target.offsetHeight) >= $event.target.scrollHeight) {

            this.paginate = this.paginate + 1;

            // this.finishedApiCallFlag =  true;
            if (this.finishedApiCallFlag) {

            } else {
                this.panicapiCall();
            }
        }
    }
    scrollHandler1($event: any) {

        if (($event.target.scrollTop + $event.target.offsetHeight) >= $event.target.scrollHeight) {

            //this.paginate = this.paginate+1;
            console.log(this.paginate, this.finishedApiCallFlag);

            if (this.finishedApiCallFlag) {

            } else {
                this.paginate = this.paginate + 1;
                this.calloverSpeedAllVehicleAPI();
            }
        }
    }

    panicapiCall() {

        this.globalPanicAPIHEADER.data.extra = {
            pageJump: this.paginate
        };


        this.apiService.otherOverspeedsLog(this.globalPanicAPIHEADER).then(response => {

            if (response.status === 'success') {
                this.displayErrorMessage = '';
                let res: any = response.response;
                // if(res.length){
                //     res.forEach((item:any,index:number) => {

                //     });
                // }else{

                // }
                if (!res.length) {
                    //console.log("FINISHED");
                    this.finishedApiCallFlag = true;

                } else {
                    res.forEach((item: any, index: number) => {
                        this.panicLog.push(item);
                    });
                    this.finishedApiCallFlag = false;//added by krish
                }


            } else {
                this.noPanicRecordsFound = true;
                this.displayErrorMessage = this.globalUtils.getErrorMsg(response.ec);
            }

            //  console.log('#####', this.noPanicRecordsFound);
            this.isLoading = false;

        }).catch(eror => { });

    }


    //krishna overspeed all vehicle code starts here
    viewOtherOverspeedLog1(sectionContentId: string) {
        let vehicleId: any = "";
        if (this.otherOverspeedValidation()) {

            if (this.setSingleSelectedItem[0]) {

                vehicleId = this.setSingleSelectedItem[0]['vehId'];
            } else {
                vehicleId = '';

            }
            // let startDate = this.bootstrapDateTime.getInputvalue('routeHistoryStartDate').split(' ');
            // let endDate = this.bootstrapDateTime.getInputvalue('routeHistoryEndDate').split(' ');
            let startDate = this.bootstrapDateTime.getInputvalue('routeHistoryStartDate');
            let endDate = this.bootstrapDateTime.getInputvalue('routeHistoryEndDate');
            startDate = this.dateChange.dateConversionForApicalls(startDate, 'date_time');
            endDate = this.dateChange.dateConversionForApicalls(endDate, 'date_time');

            //let overspeed = this.reportTypeKeyLookup.overspeed.overspeed.value;
            let lSpeed = this.lowspeed ? this.lowspeed : '';
            let hSpeed = this.highspeed ? this.highspeed : '';
            this.otherOverspeedLogReport = [];
            //alert("LLLLLL :"+this.otherOverspeedLogReport.length)  ;
            let apiHeader = {
                data: {
                    key: localStorage.getItem('scbToken'),
                    form: {

                        // fromDate: startDate[0],
                        // fromTime: startDate[1] + ':00',
                        // toDate: endDate[0],
                        // toTime: endDate[1] + ':00',
                        // "reportType": "overspeed"
                        fromDate: startDate.date,
                        fromTime: startDate.time,
                        toDate: endDate.date,
                        toTime: endDate.time,
                        "reportType": "overspeed"
                    },
                    filter: {
                        vehId: vehicleId,
                        lowSpeed: lSpeed,
                        highSpeed: hSpeed

                    },
                    // extra: {
                    //     pageJump: this.pageJump
                    // }
                }
            }

            this.globalPanicAPIHEADER = apiHeader;
            this.paginate = 0
            // this.panicapiCall();
            this.calloverSpeedAllVehicleAPI();
            //  this.panicReportExcelDownloadLink = `${this.apiService.base}report/download_notificationreports&key=${localStorage.getItem('scbToken')}&vehId=${this.selectedVechId}&fromDate=${startDate.date}&fromTime=${startDate.time}&toDate=${endDate.date}&toTime=${endDate.time}&reportType=panic`;
            // this.initializeReportContent("overspeed");

        }
    }
    calloverSpeedAllVehicleAPI() {

        this.globalPanicAPIHEADER.data.extra = {
            pageJump: this.paginate
        };

        this.apiService.otherOverspeedsLog(this.globalPanicAPIHEADER).then(response => {
            if (response.status === 'success') {
                this.displayErrorMessage = '';

                let res: any = response.response;
                //console.log('111111222222 ',res.length)
                if (!res.length) {

                    this.finishedApiCallFlag = true;


                    //this.falseTemp=true;
                } else {


                    res.forEach((item: any, index: number) => {
                        console.log('VEHNO : ', item.vehNo);
                        this.otherOverspeedLogReport.push(item);
                    });

                    this.finishedApiCallFlag = false;//added by krish
                }
                this.reportSectionsContent.otherOverspeed = true;

            }
            else {


                this.noPanicRecordsFound = true;

                this.displayErrorMessage = this.globalUtils.getErrorMsg(response.ec);
            }


        }).catch(eror => { });

    }
    //krishna overspeed all vehicle code ends here



    viewPanicLog(sectionContentId: string) {

        if (this.InitiatePanicInputValidator()) {
            let selectedDevices = this.chosenUtility.getControlvalue('devicesDropDown');


            let startDate = this.bootstrapDateTime.getInputvalue('routeHistoryStartDate')
            let endDate = this.bootstrapDateTime.getInputvalue('routeHistoryEndDate')
            let uistartDate = startDate;
            let uiendDate = endDate;

            startDate = this.dateChange.dateConversionForApicalls(startDate, 'date_time')
            endDate = this.dateChange.dateConversionForApicalls(endDate, 'date_time')

            this.panicReportStartDate = uistartDate;
            this.panicReportEndDate = uiendDate;
            // this.panicReportStartDate = indianStartDateFormat;
            // this.panicReportEndDate = indianEndDateFormat;
            this.isLoading = true;
            //this.panicReportSelectedVehName = this.allOrgVehicles.filter(function(index){ return index.vehId == this.panicReportSelectedVeh[0]});
            // let selDevice = this.panicReportSelectedVeh[0];
            // this.panicReportSelectedVehName = this.allOrgVehicles.filter(function(index) {return index.vehId == selDevice})[0]['vehNo'];

            this.panicLog = [];
            let apiHeader: any = {
                data: {
                    key: localStorage.getItem('scbToken'),
                    form: {

                        // fromDate: startDate[0],
                        // fromTime: startDate[1] + ':00',
                        // toDate: endDate[0],
                        // toTime: endDate[1] + ':00',
                        // "reportType": "panic"
                        fromDate: startDate.date,
                        fromTime: startDate.time,
                        toDate: endDate.date,
                        toTime: endDate.time,
                        "reportType": "panic"
                    },
                    filter: {
                        vehId: this.selectedVechId

                    },
                    // extra: {
                    //     pageJump: pageJumpNo
                    // }
                }
            }

            this.globalPanicAPIHEADER = apiHeader;
            this.paginate = 0
            this.panicapiCall();
            this.panicReportExcelDownloadLink = `${this.apiService.base}report/download_notificationreports&key=${localStorage.getItem('scbToken')}&vehId=${this.selectedVechId}&fromDate=${startDate.date}&fromTime=${startDate.time}&toDate=${endDate.date}&toTime=${endDate.time}&reportType=panic`;
            this.initializeReportContent(sectionContentId);
            /// this.apiService.otherOverspeedsLog(apiHeader).then(response => {

            //     if (response.status === 'success') {
            //         this.displayErrorMessage = '';

            //         //check if response is empty.
            //         // this.noPanicRecordsFound = true;
            //         // for (let info of Object.keys(apiRes)) {
            //         //     for (let pInfo in apiRes[info]) {
            //         //         let panicDetails = apiRes[info][pInfo];
            //         //        // console.log('panicDEtail', panicDetails);
            //         //         if (panicDetails.length) {
            //         //             this.noPanicRecordsFound = false;
            //         //             break;
            //         //         } else {
            //         //             continue;
            //         //         }
            //         //     }
            //         // }

            //         //   console.log(this.noPanicRecordsFound);

            //         // if (!this.noPanicRecordsFound) {
            //         //     for (let info of Object.keys(apiRes)) {
            //         //         for (let pInfo in apiRes[info]) {
            //         //             let panicDetails = apiRes[info][pInfo];
            //         //             if (panicDetails.length) {

            //         //             } else {
            //         //                 let emptyObj = {
            //         //                     location: '-',
            //         //                     panicTimeStamp: '-',
            //         //                     timeStamp: '-',
            //         //                     vehNo: '-'
            //         //                 }

            //         //             }

            //         //         }
            //         //     }
            //         // } else {

            //         // }

            //         // this.panicReportExcelDownloadLink = `${this.apiService.base}report/download_notificationreports&key=${localStorage.getItem('scbToken')}&vehId=${this.selectedVechId}&fromDate=${startDate[0]}&fromTime=${startDate[1] + ':00'}&toDate=${endDate[0]}&toTime=${endDate[1] + ':00'}&reportType=panic`;
            //         this.panicReportExcelDownloadLink = `${this.apiService.base}report/download_notificationreports&key=${localStorage.getItem('scbToken')}&vehId=${this.selectedVechId}&fromDate=${startDate.date}&fromTime=${startDate.time}&toDate=${endDate.date}&toTime=${endDate.time}&reportType=panic`;

            //     } else {
            //         this.noPanicRecordsFound = true;
            //         this.displayErrorMessage = this.globalUtils.getErrorMsg(response.ec);
            //     }

            //     //  console.log('#####', this.noPanicRecordsFound);
            //     this.isLoading = false;
            //     this.initializeReportContent(sectionContentId);
            // }).catch(eror => { });



        }

    }
    public userSelectedOptions: any;
    setUserSelections(devicesList: any) {
        // this.panicReportSelectedVeh = devicesList.map((device:any)=> {return device.vehId});

        this.userSelectedOptions = devicesList;

    }

    setUserRouteSelections(selectedRoute: any) {
        // console.log(selectedRoute)
        if (selectedRoute.length) this.setFilteredRoute = selectedRoute;
        else this.setFilteredRoute = [];
    }

    // setUserRoutePickupPoint(selectedPickup: any) {
    //     console.log(selectedPickup)
    // }

    /*Vehicle last update functions starts here*/

    getDevicesLastUpdates(sectionContentId: string): void {
        let apiHeader = {
            data: {
                key: localStorage.getItem('scbToken'),
                extra: {
                    orderByDateCreated: -1
                }
            }
        }
        this.isLoading = true;
        this.apiService.getCurrentLocation(apiHeader).then(response => {
            if (response.response) {
                this.rawVehLog = Object.assign([], response.response);
                this.vehicleLastUpdateLog = Object.assign([], response.response);
                for (let log of this.vehicleLastUpdateLog) {
                    Reflect.set(log, 'movementType', this.getVehStatus(log.speed, log.gpsTimestamp / 1000));
                }
                this.noLastUpdateLog = false;
            } else {
                this.noLastUpdateLog = true;
            }

            this.vehicleLastUpdateDownloadLink = `${this.apiService.base}organization/download_org_trackers&key=${localStorage.getItem('scbToken')}`;
            this.isLoading = false;
            this.initializeReportContent(sectionContentId);
        }).catch(error => { })
    }

    applyLastUpdateFilters(): void {
        let apiHeader = {
            data: {
                key: localStorage.getItem('scbToken'),
                extra: {
                    orderByDateCreated: -1
                }
            }
        }
        this.isLoading = true;
        this.apiService.getCurrentLocation(apiHeader).then(response => {
            if (response.response) {
                this.rawVehLog = Object.assign([], response.response);
                this.vehicleLastUpdateLog = Object.assign([], response.response);
                for (let log of this.vehicleLastUpdateLog) {
                    Reflect.set(log, 'movementType', this.getVehStatus(log.speed, log.gpsTimestamp / 1000));
                }
                this.noLastUpdateLog = false;
            } else {
                this.noLastUpdateLog = true;
            }

        }).catch(error => { })
        // this.getDevicesLastUpdates()
        setTimeout(() => {
            if (this.filterMovementType) this.filterByMovementType();
            this.isLoading = false;


        }, 500);
    }

    filterByMovementType(): void {
        if (this.filterMovementType != 'all') {
            this.vehicleLastUpdateLog = this.rawVehLog.filter((record) => { return record.movementType == this.filterMovementType });
            // console.log(this.filterMovementType,this.vehicleLastUpdateLog,'###',this.rawVehLog);
            this.vehicleLastUpdateDownloadLink = `${this.apiService.base}organization/download_org_trackers&key=${localStorage.getItem('scbToken')}&movements=${this.filterMovementType}`;
        } else {
            this.vehicleLastUpdateLog = Object.assign([], this.rawVehLog);
            this.vehicleLastUpdateDownloadLink = `${this.apiService.base}organization/download_org_trackers&key=${localStorage.getItem('scbToken')}`;
        }

        this.noLastUpdateLog = this.vehicleLastUpdateLog.length ? false : true;
    }

    getVehStatus(speed: number, gpsTime: number): string {
        let status = null;
        let currentTime = Math.floor(Date.now() / 1000);
        if ((currentTime - gpsTime) >= 1800) status = 'offline';
        else {
            if (speed <= 5) status = 'halt';
            else status = 'moving';
        }
        return status;
    }

    plotMovementLocation(info: any) {
        if (info.nearestLocation) {
            $('#myModal').modal('show');

            //map initialization.
            let mapOptions = {
                scrollwheel: true,
                zoom: 17,
                maxZoom: 18,
                center: new google.maps.LatLng(info.location.lat, info.location.lng),
                disableDefaultUI: false
            }
            let haltedMap = new google.maps.Map(document.getElementById('pointLocationMap'), mapOptions);
            let mLatlng = new google.maps.LatLng(parseFloat(info.location.lat), parseFloat(info.location.lng));
            let haltLocationMarker = new google.maps.Marker({
                position: mLatlng,
                icon: './..../../assets/webroot/images/map/geo-2.png',
                map: haltedMap,
            });

            let infoWindow = new google.maps.InfoWindow({ maxWidth: 320 });

            haltLocationMarker.addListener('mouseover', function () {
                let content = `<div><b>Location:</b><span class="locationText" style="">${info.nearestLocation}</span><br/><b>Speed:</b>${info.speed} Km/h</div>`
                infoWindow.setContent(content);
                infoWindow.open(haltedMap, this);
            });

            haltLocationMarker.addListener('mouseout', function () {
                infoWindow.close();
            });
        } else {
            $('#myModal').modal('hide');
        }
    }

    // Overspeed Report API calling 
    // public routesDatesMisMatchError:boolean;

    otherOverspeedValidation() {

        let startDate = $('#routeHistoryStartDate').val();
        let endDate = $('#routeHistoryEndDate').val();

        let dateObj = this.genericDateValidate(startDate, endDate);

        let isValid = true;
        if (dateObj.endTimeStampString.length <= 10) {
            // if (!endDate) {
            this.overspeedEndDate = true;
            isValid = false;
        } else {

            this.overspeedEndDate = false;
        }

        // if (!startDate) {
        if (dateObj.stTimeStampString.length <= 10) {
            this.overspeedStartDate = true;
            isValid = false;
        } else {
            this.overspeedStartDate = false;
        }
        if (dateObj.stTimeStampString && dateObj.endTimeStampString) {
            // if (endDate && startDate) {
            // if ($('#routeHistoryStartDate').val() > $('#routeHistoryEndDate').val()) {
            if (dateObj.stTimeStamp > dateObj.endTimeStamp) {
                this.routesDatesMisMatchError = true;
                isValid = false;
            } else {
                this.routesDatesMisMatchError = false;
            }
        }

        //   if(!this.setSingleSelectedItem[0]){
        //         this.emptyVehicleError=true;
        //         isValid=false;
        //   }else{
        //       this.emptyVehicleError=false;
        //   }

        //   if(!this.reportTypeKeyLookup.overspeed.overspeed.value){
        //       this.overSpeedLimitError=true;
        //       isValid=false;
        //   }else{
        //       this.overSpeedLimitError=false;
        //   }



        return isValid;
    }
    public overspeedDownload: any;
    viewOtherOverspeedLog(pageNumb: any) {

        this.pageJump = pageNumb;

        if (this.otherOverspeedValidation()) {


            let vehicleId: any = "";
            if (this.setSingleSelectedItem[0]) {

                vehicleId = this.setSingleSelectedItem[0]['vehId'];
            } else {
                vehicleId = '';

            }
            // let startDate = this.bootstrapDateTime.getInputvalue('routeHistoryStartDate').split(' ');
            // let endDate = this.bootstrapDateTime.getInputvalue('routeHistoryEndDate').split(' ');
            let startDate = this.bootstrapDateTime.getInputvalue('routeHistoryStartDate');
            let endDate = this.bootstrapDateTime.getInputvalue('routeHistoryEndDate');
            startDate = this.dateChange.dateConversionForApicalls(startDate, 'date_time');
            endDate = this.dateChange.dateConversionForApicalls(endDate, 'date_time');

            //let overspeed = this.reportTypeKeyLookup.overspeed.overspeed.value;
            let lSpeed = this.lowspeed ? this.lowspeed : '';
            let hSpeed = this.highspeed ? this.highspeed : '';

            let apiHeader = {
                data: {
                    key: localStorage.getItem('scbToken'),
                    form: {

                        // fromDate: startDate[0],
                        // fromTime: startDate[1] + ':00',
                        // toDate: endDate[0],
                        // toTime: endDate[1] + ':00',
                        // "reportType": "overspeed"
                        fromDate: startDate.date,
                        fromTime: startDate.time,
                        toDate: endDate.date,
                        toTime: endDate.time,
                        "reportType": "overspeed"
                    },
                    filter: {
                        vehId: vehicleId,
                        lowSpeed: lSpeed,
                        highSpeed: hSpeed

                    },
                    extra: {
                        pageJump: this.pageJump
                    }
                }
            }

            // this.overspeedDownload = `${this.apiService.base}report/download_notificationreports&key=${localStorage.getItem('scbToken')}&vehId=${vehicleId}&fromDate=${startDate[0]}&fromTime=${startDate[1] + ':00'}&toDate=${endDate[0]}&toTime=${endDate[1] + ':00'}&reportType=overspeed&lowSpeed=${lSpeed}&highSpeed=${hSpeed}`;
            this.overspeedDownload = `${this.apiService.base}report/download_notificationreports&key=${localStorage.getItem('scbToken')}&vehId=${vehicleId}&fromDate=${startDate.date}&fromTime=${startDate.time}&toDate=${endDate.date}&toTime=${endDate.time}&reportType=overspeed&lowSpeed=${lSpeed}&highSpeed=${hSpeed}`;

            this.apiService.otherOverspeedsLog(apiHeader).then(response => {
                if (response.response) {
                    if (response.response <= 0) {

                        this.falseTemp = true;

                    } else {

                        this.falseTemp = false;

                        this.otherOverspeedLogReport = response.response;

                    }

                    this.reportSectionsContent.otherOverspeed = true;
                } else {

                    this.noPanicRecordsFound = true;
                    this.displayErrorMessage = this.globalUtils.getErrorMsg(response.ec);
                }
                this.isLoading = false;
                //this.initializeReportContent(sectionContentId);
            }).catch(eror => { });
            // this. showPaginationOverSpeed = this.otherOverspeedLogReport.length>100 ? true : false;
            // this.showPaginationNotif = this.notificationReports.length ?true : false;


        }

    }

    // Ending Overspeed Report

    /*vehicle last update functions ends here*/

    initializeChosenUtility() {
        setTimeout(() => {
            this.chosenUtility.initializeChosen('orgRoutesDropdown');
        }, 100)
    }

    onScroll($event: any) {
        //if(($event.srcElement.scrollTop + $event.srcElement.offsetHeight) >= $event.srcElement.scrollHeight ){
        if (($event.target.scrollTop + $event.target.offsetHeight) >= $event.target.scrollHeight) {
            if (this.noMoredata == false) {
                this.pageJump++;
                this.isScrolled = true;
                this.getReportData(undefined);
            }
        }
        //$(window).scrollTop() + window.innerHeight >= $(window).height()
    }

    initChosen() {
        setTimeout(() => {
            //this.chosenUtility.initializeChosen('availableReportsDropdown');
        }, 100);
        this.bindJqChangeEvt('demo-select');
    }

    bindJqChangeEvt(ele: any) {
        this.selectedVechId = '';
        let parent = this;
        $('#' + ele).change(function (e: any) {
            parent.selectedReport = $(this).val();
            let obj = {};
            obj[parent.selectedReport] = true;
            parent.initReportSection(obj);
        });
    }

    initJqUtils(eleChosenId?: string, eleReportStartTime?: string, eleReportEndTime?: string) {
        let parent = this;
        if (eleChosenId) this.chosenUtility.initializeChosen(eleChosenId);
        if (eleReportStartTime) {
            parent.bootstrapDateTime.datetime({
                component: eleReportStartTime,
                timepicker: true,
                format: 'Y-m-d H:i:s',
                closeOnDateSelect: false
            });
        }
        if (eleReportEndTime) {
            parent.bootstrapDateTime.datetime({
                component: eleReportEndTime,
                timepicker: true,
                format: 'Y-m-d H:i:s',
                closeOnDateSelect: false
            });
        }
    }


    initJqUtilsSingleDate(eleChosenId?: string, eleReportStartTime?: string, eleReportEndTime?: string, pickerConfig?: any) {
        let parent = this;
        let timePicker: boolean;
        let timeFormat: any;
        if ((pickerConfig) && (Object.keys(pickerConfig).length)) {
            timePicker = pickerConfig.timePicker;
            timeFormat = pickerConfig.timeFormat;
        } else {
            timePicker = true;
            timeFormat = 'Y-m-d H:i:s';
        }
        setTimeout(() => {
            if (eleChosenId) this.chosenUtility.initializeChosen(eleChosenId);
            if (eleReportStartTime) {
                parent.bootstrapDateTime.datetime({
                    component: eleReportStartTime,
                    closeOnDateSelect: true
                });
            }
            $('#eventStartTime').datetimepicker({
                format: 'H:i',
                datepicker: false,
                defaultTime: '00:00',
                timepicker: true,
            });
            $('#eventEndTime').datetimepicker({
                format: 'H:i',
                datepicker: false,
                defaultTime: '23:59',
                timepicker: true,
            });
        }, 150);
    }

    parseUnixTimestamp(timeStamp: number) {
        let months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
        let parsedDate = new Date(timeStamp);
        let meriden = (parsedDate.getHours() >= 12) ? 'PM' : 'AM';
        let hrs = (parsedDate.getHours() > 12) ? parsedDate.getHours() - 12 : parsedDate.getHours();
        let formattedTime = `${this.padString(hrs, 2)}: ${this.padString(parsedDate.getMinutes(), 2)}: ${this.padString(parsedDate.getSeconds(), 2)}: ${meriden} `;
        let formattedDate = `${this.padString(parsedDate.getDate(), 2)} ${months[parsedDate.getMonth()]} , ${parsedDate.getFullYear()} `;
        let dummY = new Date();
        let currentDate = `${this.padString(dummY.getDate(), 2)} ${months[dummY.getMonth()]}, ${dummY.getFullYear()} `;

        if (formattedDate == currentDate) {
            return "Today at " + formattedTime;
        } else {
            return formattedDate + " at " + formattedTime;
        }
    }

    padString(text: any, max: number) {
        text = text.toString();
        return text.length < max ? '0' + text : text;
    }


    // Notification alert 

    validateNotification() {
        let isValid = true;
        let startDate = this.bootstrapDateTime.getInputvalue('routeHistoryStartDate');
        let endDate = this.bootstrapDateTime.getInputvalue('routeHistoryEndDate');

        let dateObj = this.genericDateValidate(startDate, endDate)


        if (dateObj.stTimeStampString.length <= 10) {
            // if (!startDate) {
            isValid = false;
            this.notificationStartDate = true;
        } else {
            isValid = true;
            this.notificationStartDate = false;
        }
        if (dateObj.endTimeStampString.length <= 10) {
            // if (!endDate) {
            isValid = false;
            this.notificationEndDate = true;
        } else {
            isValid = true;
            this.notificationEndDate = false;
        }


        // if (endDate && startDate) {
        if (dateObj.stTimeStampString && dateObj.endTimeStampString) {
            // if ($('#routeHistoryStartDate').val() > $('#routeHistoryEndDate').val()) {
            if (dateObj.stTimeStamp > dateObj.endTimeStamp) {
                this.routesDatesMisMatchError = true;
                isValid = false;
            } else {
                this.routesDatesMisMatchError = false;
            }
        }

        if (!this.selectedReportType) {
            this.reportTypeErr = true;
            isValid = false;
        } else {
            this.reportTypeErr = false;
        }


        return isValid;

    }

    viewOtherNotificationLog(pageJump: any) {

        this.pageJump = pageJump;

        if (this.validateNotification()) {

            // let startDate = this.bootstrapDateTime.getInputvalue('routeHistoryStartDate').split(' ');
            // let endDate = this.bootstrapDateTime.getInputvalue('routeHistoryEndDate').split(' ');
            let startDate = this.bootstrapDateTime.getInputvalue('routeHistoryStartDate')
            let endDate = this.bootstrapDateTime.getInputvalue('routeHistoryEndDate')
            startDate = this.dateChange.dateConversionForApicalls(startDate, 'date_time');
            endDate = this.dateChange.dateConversionForApicalls(endDate, 'date_time');
            this.speedClm = this.selectedReportType === 'panic' ? false : true; //console.log(this.speedClm);
            this.isLoading = true;
            let apiData = {
                data: {
                    key: localStorage.getItem('scbToken'),
                    form: {
                        // fromDate: startDate[0],
                        // fromTime: startDate[1] + ':00',
                        // toDate: endDate[0],
                        // toTime: endDate[1] + ':00',
                        // reportType: this.selectedReportType
                        fromDate: startDate.date,
                        fromTime: startDate.time,
                        toDate: endDate.date,
                        toTime: endDate.time,
                        reportType: this.selectedReportType
                    },
                    filter: {
                        alertVia: this.selectedAlert ? this.selectedAlert : ''
                    },
                    extra: {
                        pageJump: this.pageJump
                    }
                }
            }
            this.apiService.notificationLog(apiData).then(res => {


                if (res.status == 'success') {
                    this.showPaginationNotif = +res.alertCnt > 1 ? true : false;

                    if (res.response.length <= 0) {
                        this.falseTemp = true;
                        this.scb36 = false;
                    } else {
                        this.falseTemp = false;
                        this.notificationReports = res.response;
                        this.totNotifiCount = res.alertCnt;
                        this.scb36 = false;


                    }

                } else if (res.status == 'failure') {

                    if (res.ec == 'SCB36') {
                        this.falseTemp = true;
                        this.scb36 = true;

                    }
                }

                this.reportSectionsContent.notificationAlert = true;
                this.isLoading = false;

            });

            // this.showPaginationNotif = this.notificationReports.length>100 ? true : false;

        }
    }
    public vehicleBasedNotiStartDate: boolean;
    public vehicleBasedNotiEndDate: boolean;
    public vehicleDatesMisMatchError: boolean;
    public vehicleBasedCount: any;
    public vehicleLogReports: any = {};
    public listDate: any = [];
    validateVehicleBasedNotification() {

        let isValid = true;
        let startDate = this.bootstrapDateTime.getInputvalue('routeHistoryStartDate');
        let endDate = this.bootstrapDateTime.getInputvalue('routeHistoryEndDate');

        let dateObj = this.genericDateValidate(startDate, endDate);
        // if (!startDate) {
        if (dateObj.stTimeStampString.length <= 10) {
            isValid = false;
            this.vehicleBasedNotiStartDate = true;
        } else {
            isValid = true;
            this.vehicleBasedNotiStartDate = false;
        }

        // if (!endDate) {
        if (dateObj.endTimeStampString.length <= 10) {
            isValid = false;
            this.vehicleBasedNotiEndDate = true;
        } else {
            isValid = true;
            this.vehicleBasedNotiEndDate = false;
        }

        // if (endDate && startDate) {
        if (dateObj.stTimeStampString && dateObj.endTimeStampString) {
            // if ($('#routeHistoryStartDate').val() > $('#routeHistoryEndDate').val()) {
            if (dateObj.stTimeStamp > dateObj.endTimeStamp) {
                this.vehicleDatesMisMatchError = true;
                isValid = false;
            } else {
                this.vehicleDatesMisMatchError = false;
            }
        }

        return isValid;


    }

    viewOtherVehicleBasedNotification(obj: any) {

        if (this.validateVehicleBasedNotification()) {
            let vehId: any = [];
            let vehIds: any;
            // let startDate = this.bootstrapDateTime.getInputvalue('routeHistoryStartDate').split(' ');
            // let endDate = this.bootstrapDateTime.getInputvalue('routeHistoryEndDate').split(' ');
            let startDate = this.bootstrapDateTime.getInputvalue('routeHistoryStartDate')
            let endDate = this.bootstrapDateTime.getInputvalue('routeHistoryEndDate')
            startDate = this.dateChange.dateConversionForApicalls(startDate, 'date_time');
            endDate = this.dateChange.dateConversionForApicalls(endDate, 'date_time');

            let routeName = this.userSelectedOptions && this.userSelectedOptions[0] ? this.userSelectedOptions[0].routeName : '';
            // if(routeName === 'Walkers_M' || routeName === 'Walkers_N' || routeName === 'Walkers_A'){
            //    // let walker: any = [];
            //    vehId = this.walkerVehId.concat(vehId);

            // }else{
            vehId = this.userSelectedOptions && this.userSelectedOptions[0] ? this.userSelectedOptions[0].vehId : '';
            //  }

            vehIds = vehId;

            this.incDiv = false;
            setTimeout(() => {
                this.incDiv = true;
            }, 100);

            this.isLoading = true;
            let apiData = {
                data: {
                    key: localStorage.getItem('scbToken'),
                    form: {
                        // fromDate: startDate[0],
                        // fromTime: startDate[1] + ':00',
                        // toDate: endDate[0],
                        // toTime: endDate[1] + ':00'
                        fromDate: startDate.date,
                        fromTime: startDate.time,
                        toDate: endDate.date,
                        toTime: endDate.time
                    },
                    filter: {
                        vehId: vehIds
                    }
                }
            };


            this.listDate = [];
            // this.vehicleBasedDownload = `${this.apiService.base}report/download_vehicle_based_notifications&key=${localStorage.getItem('scbToken')}&fromDate=${startDate[0]}&fromTime=${startDate[1] + ':00'}&toDate=${endDate[0]}&toTime=${endDate[1] + ':00'}&vehId=${vehId}`;
            this.vehicleBasedDownload = `${this.apiService.base}report/download_vehicle_based_notifications&key=${localStorage.getItem('scbToken')}&fromDate=${startDate.date}&fromTime=${startDate.time}&toDate=${endDate.date}&toTime=${endDate.time}&vehId=${vehId}`;

            this.apiService.vehicleBasedNotification(apiData).then(res => {
                // this.vehicleLogReports = '';
                if (res.status === 'success') {

                    let repotCount = 0;
                    // this.vehicleLogReports= res.response;
                    let vehName: any = [];
                    let vehInfo: any = [];

                    (Object.keys(res.response)).forEach((reportsDates) => {

                        let count = Object.keys(res.response[reportsDates]).length;

                        this.listDate.push(reportsDates);
                        repotCount = repotCount + count;

                        (Object.keys(res.response[reportsDates])).forEach((vehId) => {

                            if (vehInfo[vehId] === undefined) {
                                vehInfo[vehId] = [];
                            }
                            if (vehInfo[vehId][reportsDates] === undefined) {
                                vehInfo[vehId][reportsDates] = [];
                            }

                            vehInfo[vehId][reportsDates].push({
                                OS_EMAIL_CNT: res.response[reportsDates][vehId].OS_EMAIL_CNT,
                                OS_SMS_CNT: res.response[reportsDates][vehId].OS_SMS_CNT,
                                PANIC_EMAIL_CNT: res.response[reportsDates][vehId].PANIC_EMAIL_CNT,
                                PANIC_SMS_CNT: res.response[reportsDates][vehId].PANIC_SMS_CNT,
                                vehNo: res.response[reportsDates][vehId].vehNo,
                            });

                            vehName.push(res.response[reportsDates][vehId].vehNo);

                        });
                    });

                    this.vehicleLogReports = vehInfo;

                    this.vehicleBasedCount = repotCount;

                    this.isLoading = false;
                    this.falseTemp = false;

                } else if (res.status === 'failure') {
                    if (res.ec == 'SCB72') {
                        this.falseTemp = true;
                        this.scb36 = true;
                        this.isLoading = false;

                    }
                }

            });

            this.reportSectionsContent.vehicleBasedNotificationAlert = true;



        }

    }

    // reportType(e :any, type: string){
    //      if(type == 'sms'){
    //           this.showOnlySMS = true;

    //           this.showOnlyEmail = false;
    //      }else if(type == 'email'){
    //         this.showOnlySMS = false;
    //         this.showOnlyEmail = true;
    //      }
    // }
    validateBreakReport() {
        let isValid = true;

        let reportDate = this.bootstrapDateTime.getInputvalue('routeHistoryStartDate');

        // let dateObj = this.genericDateValidate(reportDate,null)
        let dateObj = this.genericDateValidate(reportDate)

        if (dateObj.stTimeStampString.length <= 10) {
            isValid = false;
            this.StartDateError = true;
        } else {
            this.StartDateError = false;
        }

        return isValid;
    }

    public breakdownData: any;
    breakDownReport(pageJump: number) {
        this.totBreakdownMsgCount = 0;
        this.breakdownMsgObj = '';
        this.pageJump = pageJump;
        if (this.validateBreakReport()) {
            let reportDate = this.bootstrapDateTime.getInputvalue('routeHistoryStartDate');
            let routeId = this.setFilteredRoute && this.setFilteredRoute[0] && this.setFilteredRoute[0].routeId ? this.setFilteredRoute[0].routeId : '';
            let mobileNum = this.breakDownMobileNumber ? this.breakDownMobileNumber : '';
            let msgType = this.bearkDownReportType ? this.bearkDownReportType : '';
            // let selectedData = reportDate.split(' ');
            let selectedData = this.dateChange.dateConversionForApicalls(reportDate, 'onlydate');
            let apiData = {
                data: {
                    key: localStorage.getItem('scbToken'),
                    form: {
                        fromDate: selectedData,
                        fromTime: "00:00:01",
                        toDate: selectedData,
                        toTime: "23:59:59"
                    },
                    filter: {
                        bdType: msgType,
                        mobileno: mobileNum,
                        routename: routeId
                    },
                    extra: {
                        pageJump: this.pageJump
                    }
                }
            };
            this.breakdownData = true;
            this.com.comAction.next({
                type: 'breakdownMsg',
                data: apiData
            });
            // console.log(this.breakdownData, apiData);
            this.reportSectionsContent.breakDownReport = true;
            // this.apiService.getBreakDownMsg(apiData).then((res: any) => {

            //     if (res.status === 'success') {
            //         const responce = res.response;
            //         console.log(responce);
            //         if (responce.length > 0) {
            //             this.totBreakdownMsgCount = res.alertCnt;
            //             this.breakdownMsgObj = responce;
            //         } else {
            //             this.breakdownMsgObj = 'null';
            //         }

            //     }
            //     /// console.log(this.totBreakdownMsgCount, this.breakdownMsgObj);

            // });

        }


    }


    MobileNumberValidator(event: any) {
        event.srcElement.value = event.srcElement.value.replace(/[^\d].+/, "");

        if (event.srcElement.value != '') {
            //  console.log('t',event.srcElement.value);
            if ((event.which < 48 || event.which > 57)) {
                event.preventDefault();
            }
        } else {
            // event.srcElement.value=0;
        }
    }

    genericDateValidate(param1: string, param2?: string) {

        // let stDate:any = (param1).split('-');
        let stDate: any = param1 && (param1).split('-') || false;
        stDate = `${stDate[1]}/${stDate[0]}/${stDate[2]}`;
        let stTimeStamp = new Date(stDate).getTime();


        // let endDate:any = (param2).split('-');
        let endDate: any = param2 && (param2).split('-') || false;
        endDate = `${endDate[1]}/${endDate[0]}/${endDate[2]}`;
        let endTimeStamp = new Date(endDate).getTime();

        let stTimeStampString = stTimeStamp.toString();
        let endTimeStampString = endTimeStamp.toString();


        return {
            stTimeStamp: stTimeStamp,
            endTimeStamp: endTimeStamp,
            stTimeStampString: stTimeStampString,
            endTimeStampString: endTimeStampString,

        }
    }
    trackByFn(index: any, item: any) {
        return item; // or item.id
    }
    resetOtherRepoTempErr() {
        this.StartDateError = false;
        this.PanicStartDateError = false;
        this.panicEndDateError = false;
        this.panicDatesMisMatchError = false;
        this.overspeedEndDate = false;
        this.overspeedStartDate = false;
        this.routesDatesMisMatchError = false;

        this.notificationStartDate = false;
        this.notificationEndDate = false;
        this.vehicleBasedNotiStartDate = false;
        this.vehicleBasedNotiEndDate = false;
        this.vehicleDatesMisMatchError = false;
        this.showPaginationOverSpeed = true;
        this.showPaginationNotif = true;
        this.displayErrorMessage = '';
        this.notificationReports = [];
        this.otherOverspeedLogReport = [];
    }



}
