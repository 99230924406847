
/*Auther:Deepak */
import { Component,  OnInit } from '@angular/core';
import { globalService } from './../../../services/global.service';
import { ApiService } from './../../../services/ApiService';


declare var $: any;

@Component({
   
  selector: 'tracker-log',
  templateUrl: './trackerLogTemplate.html',
  styles: [`

  
    .custom-table tbody {
      display:block;
      height:440px;
      overflow:auto;
    }
    .custom-table thead, .custom-table tbody tr {
         display:table;
         width:100%;
         table-layout:fixed;
    }
    tr{
       height:48px;
    }
    .custom-table th {
      color: rgba(0, 0, 0, 0.54);
      font-size: 15px;
      font-weight: 500;
      white-space: nowrap;
    }
    .custom-table tbody td{
      vertical-align:middle !important;
      color: rgba(0, 0, 0, 0.87) !important;
      font-size: 13px !important;
      border-top: 1px rgba(0, 0, 0, 0.12) solid !important;
      padding: 0 24px !important;
    }
    ::placeholder {
      color: #e4e4e4;
      opacity: 1; /* Firefox */
    }
  div.callLogHeader{
      box-shadow: 0 8px 6px -6px #8888882e;
  }  
  div.call-log-details > .table-container {
      padding: 1%;
  }
  .inheritValign{
      vertical-align: inherit;
  }
  .call-log-details{
      margin-bottom:3%;
  }
  .call-log-details:hover{
      box-shadow: 0 8px 6px -6px #8888882e;
  }
  .call-log-container{
      max-height: 515px;
      overflow: auto;
      padding:1%;
  }

  div.pickupInfo{
      margin-bottom: 10px;
      border-left: 5px solid #03A9F4;
      padding: 1%;
      font-size: 1.2em;
      color: #9e9e9eeb;
      background: #9e9e9e0a;
  }
  .call-details-table th{
      font-weight: 500;
      color: rgb(130, 130, 130);
  }
  div.memberClassInfo{
      font-size:13px;
     // padding-left:10px;
  }
  .loaderContainer{
      margin: 20% 0 0 30%;
  }

    


`  ],
})
export class TrackerLogReport implements OnInit {

  private currentOrganization: any = this.globalService.getData().currentOrganization;
  private apiToken: any = localStorage.getItem("adminToken");

  public trackerLogArray:any = [];
  public isDataProcessed: boolean;
  public srchRegNo:any = "";
  public srchImei:any = "";
  public srchBoxid: any = "";
  public viewSearch:boolean;
  public selectedFilDate: any;
  public pageJump: number = 0;


  constructor(
    public globalService: globalService,
    public apiService: ApiService,

  ) {

  }


  ngOnInit(){
    
    this.viewTrackerUpdateLog('');
 }
 public filterObj: any = {};
filterFunction(){
    this.filterObj['organizationId'] = this.currentOrganization;
    this.filterObj['regno'] = this.srchRegNo;
    this.filterObj['imei'] = this.srchImei;
    this.filterObj['boxId'] = this.srchBoxid;
    this.filterObj['date'] = this.selectedFilDate ? this.selectedFilDate : '';
    this.viewTrackerUpdateLog(this.filterObj);
}
  setUserSelection(){

  }
  viewTrackerUpdateLog(obj: any){  
      let filter = {};
      if(obj === ''){
        filter['organizationId'] = this.currentOrganization;            
      }else{
          
        filter = obj;
    
      }
        var logObj = {
          data: {
            key: this.apiToken,
            filter,
            extra: {
              pageJump: this.pageJump
            }
            
          }
        };// console.log(logObj);
        this.apiService.trackerLogHistory(logObj).then(response => {
          if (response) {
            this.trackerLogArray = response.response;
            // console.log(this.trackerLogArray);
          }

        }).catch(error => { });
  }

  selectedDate(obj: string){
        this.selectedFilDate = obj;
       this.filterFunction();
  }
}


 

/*
Notes : npm install node-sass -g
*/