import {
  Component,  ElementRef, AfterViewInit,
  OnDestroy, ViewChild, Input, Output, EventEmitter, NgZone, Injectable
} from '@angular/core';
//import { RouterModule, Routes, Router } from '@angular/router';
//import { AppComponent } from './../../app.component';

import { globalService } from './../../../services/global.service';
import { ApiService } from './../../../services/ApiService';
import { decodeAddress } from './../../../services/decodeAddress.service';

import { RouterModule, Routes, Router } from '@angular/router';
//import { GoogleMapsAPIWrapper } from '@agm/core';
//import { MapsAPILoader } from '@agm/core';
import { Observable, Observer } from 'rxjs';
import { StorageService } from '../../../services/storage.service';
declare var MarkerWithLabel:any;
declare var $: any;
declare var google: any;

@Component({
  selector: 'editPickup-form',
  template: `

      <!--Edit pickUpPoints contents begins here -->
      <div class="route-heading-container" style="padding: 2%;background: rgba(158, 158, 158, 0.09);height: 100px;">
        <div class="back-button pull-left grow" style="cursor:pointer;" (click)="hideEditPickupFormDir($event)"  data-toggle="tooltip" data-placement="bottom" title="go back">
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"> <g class="nc-icon-wrapper" fill="#429db5"> <path d="M21 11H6.83l3.58-3.59L9 6l-6 6 6 6 1.41-1.41L6.83 13H21z"></path> </g> </svg>
        </div>
        <div class="route-heading-tabs" style="padding:20px">
            <div class="list-heading pull-left">Edit Pickup Point</div>
        </div>
      </div>

     <div class="pickUpPointsList routeDetails animated fadeIn"  style="padding:10px;overflow-y:auto;max-height: 74vh;">
        <div class="add-point-form">
          <div class="form-group">
            <label class="control-label">Point Name<sup>*</sup></label>
            <input class="custom-form-control point-name point-input-text meager-e2e-editpickup-pickpoint" [(ngModel)]="stoppageDetails.pickuppoint" appAutofocus>
            <span class="text-danger meager-e2e-editpickup-pickpoint-errtxt" *ngIf="showPickupNameError">Pickup name should not be empty</span>
          </div>
          <div class="form-group">
            <label class="control-label">Point Address<sup>*</sup></label>
            <textarea class="custom-form-control meager-e2e-editpickup-pickaddress" rows="3" [(ngModel)]="inputAddress" (keyup.enter)="geocodeAddress()" (change)="geocodeAddress()"></textarea>
            <span class="text-danger  meager-e2e-editpickup-pickaddress-errtxt" *ngIf="showInputAddressError">You Should Add Pickup Location</span>
          </div>
          <div class="form-group">
            <label class="control-label">Point Radius (Metres)<sup>*</sup></label>
            <input class="custom-form-control point-input-text meager-e2e-editpickup-radius"
            (change)="updateGeoradius()"
            type="number" min="100" step="50" value="500" [(ngModel)]="geoRadius">
              <span class="text-danger  meager-e2e-editpickup-radius-errtxt" *ngIf="showPointRadiusError">You Should Add Point Radius</span>
          </div>

          <div class="form-group">
            <label class="control-label">Estimated Time Of Arrival (HH:MM)<sup>*</sup></label>
            <input  atp-time-picker class="custom-form-control point-name point-input-text meager-e2e-editpickup-eta" [(ngModel)]="stoppageDetails.expectedTime" type="time">
            <span class="text-danger meager-e2e-editpickup-eta-errtxt" *ngIf="showRouteTimeError">You Should Follow (HH:MM) Format</span>
          </div>

          <!--Route Alert Configuration-->
          <div class="form-group">
          <label class="control-label">Alerts :</label>
          <table class="setings-checkbox-main-tab">
              <tr class="settings-checkbox-main-tr">
                  <td class="settings-checkbox-main-td">
                      <table>
                          <tr>
                              <td class="check"><input type="checkbox" class="chk regular-checkbox meager-e2e-editpickup-sms-alert"  [(ngModel)]="stoppageDetails.smsAlert">
                                  <i class="fa fa-comments grey alertIcons" aria-hidden="true" title="Sms Alert" data-toggle="tooltip" data-placement="bottom"></i>
                              </td>
                          </tr>
                      </table>
                  </td>
                  <td class="settings-checkbox-main-td">
                      <table>
                          <tr>
                              <td class="check-mail"><input type="checkbox" class="chk regular-checkbox meager-e2e-editpickup-email-alert"  [(ngModel)]="stoppageDetails.emailAlert" >
                                  <i class="fa fa-envelope grey alertIcons" aria-hidden="true" title="Email Alert" data-toggle="tooltip" data-placement="bottom"></i>
                              </td>
                          </tr>
                      </table>
                  </td>
                  <td class="settings-checkbox-main-td">
                      <table>
                          <tr>
                              <td class="check-mail"><input type="checkbox" class="chk regular-checkbox meager-e2e-editpickup-app-alert" [(ngModel)]="stoppageDetails.appAlert" >
                                  <i class="fa fa-bell grey alertIcons" aria-hidden="true" title="App Alert" data-toggle="tooltip" data-placement="bottom"></i>
                              </td>
                          </tr>
                      </table>
                  </td>
                  <td class="settings-checkbox-main-td">
                      <table>
                          <tr>
                              <td class="check-mail"><input type="checkbox" class="chk regular-checkbox meager-e2e-editpickup-call-alert" [(ngModel)]="stoppageDetails.callAlert"  >
                                <i class="fa fa-phone alertIcons" aria-hidden="true" title="Call Alert" data-toggle="tooltip" data-placement="bottom"></i>
                              </td>
                          </tr>
                      </table>
                  </td>
              </tr>
          </table>
          </div>
          <!--Route Alert Configuration-->

          <div class="form-actions pull-right">
            <button class="webform-submit button-primary btn btn-primary form-submit meager-e2e-editpickup-update" [disabled]="addressLoading" (click)="editPickupPointObj()">Update</button>
          </div>
        </div>
      </div>

      <!--contents for edit ends here-->



  `,
  styles: [`

    animation css */
    .grow { transition: all .2s ease-in-out; }
    .grow:hover { transform: scale(1.1); }

    div .setings-checkbox-main-tab .settings-checkbox-main-tr .settings-checkbox-main-td {
      padding-right: 50px;
      padding-left: 15px;
    }
    .alertIcons{
      font-size: 1.3em;
      margin-left: 3px;
      cursor:pointer;
   }
   
`  ],
})

@Injectable()
export class SchoolEditPickupFormDir {

  public _map: any;
  public _geoRadius: any;
  public _geoFillColor: any;
  public __draggedLatLng: any;
  public pickUpDetails: any;
  public editPickup: boolean = false;
  public stoppageId: any;
  public stoppageDetails: any;
  public inputAddress:string;
  public geoRadius:any
  public showRouteTimeError:boolean;
  public showInputAddressError:boolean;
  public showPointRadiusError:boolean;
  public showPickupNameError:boolean;
  public map:any;
  public routeCreateMarker:any;
  public pickupGeofence:any;
  public localdata:any;
  public ppName:any;
  public ppAddress:any;
  public ppRadius:any;
  public ppTime:any;
  public editPickupBoolean: boolean = false;
  showList: any=[];
  @Output() geoRadiusEM: EventEmitter<number> = new EventEmitter();
  @Output() inputAddressEM: EventEmitter<string> = new EventEmitter();
  @Output() hideEditPickupForm: EventEmitter<string> = new EventEmitter();
  @Output() editPickupPoint: EventEmitter<any> = new EventEmitter<any>();

  @Input('routeId') _routeId: any;
  StartDateString: any;
  routeInfo: any;
  routeInfoStartDate: any;
  routeInfoEndDate: any;

  mapBounds: any;
//  @Input() inputAddress: any;
  //@Input() geoRadius: any;

  @Input('haltLogData') haltLogData: any;
  mapMarker: any=[];
  markers: any;
  routeStopee: Promise<any>;

  @Input('clickedStoppageId') //setting stoppage ID from other directive.
  set clickedStoppageId(clickedStoppageId: any) {
    this.stoppageId = clickedStoppageId;
  }

  @Input('pickupDetails')
  set pickupDetails1(pickupDetail: any) {
    this.processStoppageDetails(pickupDetail.pickuppoints);
  }

  @Input('mapObj')
  set MapObject(mapObj:any){
    this.map = mapObj;
  }
  public addressLoading:boolean=false;

  constructor(public decodeAddress: decodeAddress, 
              public storage: StorageService, 
              public apiService:ApiService,) {
                
                this.markers = {}
  }

  ngOnDestroy(){
    this.markers = {}
  }

  ngOnInit(){
    this.createRouteMarker();
  
  
this.ppName=this.stoppageDetails.pickuppoint;
this.ppAddress=this.inputAddress;
this.ppRadius=this.geoRadius;
this.ppTime=this.stoppageDetails.expectedTime;
  //stoppageDetails.pickuppoint

  //inputAddress
  
  //geoRadius
  
  //stoppageDetails.expectedTime

    // if ((this.storage.get('HaltLog') === false) && (this.storage.get('editpickuppoint') == true)) {
    //   // this.getHaltLogforPickupDisplayPoint(0)
    //   setTimeout(() => {
    //     // if (this.haltonlyReportDatas.length > 0) {
    //     if (this.mapMarker) {
    //       // console.log(this.mapMarker)
    //       this.clearMapMarkers(this.mapMarker);
    //     }
    //     this.mapMarker = [];
    //     this.mapBounds = new google.maps.LatLngBounds();
       
    //   /* this.haltonlyReportDatas.filter((val: any) => {
    //       let mlatLng = new google.maps.LatLng(val.location.lat, val.location.lng);
    //       let marker = new google.maps.Marker({
    //         position: mlatLng,
    //         map: this.map,
            
    //         icon: {
    //           url: "../../../../assets/webroot/images/map/map_red.png",
    //         },
    //         mLatlng: mlatLng,
    //       });
    //       this.mapMarker.push(marker);
    //       this.mapBounds.extend(mlatLng);
    //     }) */
    //     // }
    //     // this.haltonlyReportDatas = [];

    //   }, 900);
    // }



    $(document).ready(function(){
        $('[data-toggle="tooltip"]').tooltip();
    });

  }
 
    /* data checking test */
 
    /* end 1-24-19-2-19-pm data checking test */



  hideEditPickupFormDir($event: any) {
    this.routeCreateMarker.setMap(null);
    this.pickupGeofence.setMap(null);
    this.hideEditPickupForm.emit($event);
    this.unbindMapClickEvt();

    // this.deleteMarker(this.markers,'clearAll');
    this.markers ={};
  }
  mapHaltMarker=(url:string)=>{
    
   
    return{
      url:url,
      scaledSize: new google.maps.Size(10,10),
      origin: new google.maps.Point(0,0), 
      anchor: new google.maps.Point(3.5,3.5),
        // scaledSize: new google.maps.Size(10, 10),
        // origin: new google.maps.Point(0, 0),
        // anchor: new google.maps.Point(0,0),
        // labelOrigin:  new google.maps.Point(0,0),
    }
  
  }

  validateTime(time:string){
    let isValid = /^([0-1]?[0-9]|2[0-4]):([0-5][0-9])?$/.test(time);
    return isValid;
  }


  validateUserInputs(){
      let isValid:boolean = true;

      if(!this.stoppageDetails.pickuppoint){
        this.showPickupNameError = true;
        isValid = false;

      }else{
        this.showPickupNameError = false;
      }

      if(this.__draggedLatLng != null)  {
        this.stoppageDetails.pickuppointLocation = {lat:this.__draggedLatLng.lat(),lng:this.__draggedLatLng.lng()}
      }

      if(!this.inputAddress){
        this.showInputAddressError = true;
        isValid = false;
      }else{
        this.showInputAddressError = false;
      }

      if(!(this.validateTime(this.stoppageDetails.expectedTime))) {
          this.showRouteTimeError = true;
          isValid = false;
      }else{
          this.showRouteTimeError = false;
      }

      if(!this.geoRadius){
        this.showPointRadiusError = true;
        isValid = false
      }else{
        this.showPointRadiusError = false;
      }
      return isValid;
  }

  editPickupPointObj() {
    if(this.stoppageDetails.pickuppoint==this.ppName){ 
     
      this.editPickupBoolean=false;
      
      }else{ 
        this.showList.push('Pickup Name');
        this.editPickupBoolean=true;
      } 

      if(this.inputAddress==this.ppAddress){ 
        
        this.editPickupBoolean=false;
        
        }else{ 
          this.showList.push('Pickup Address');
          this.editPickupBoolean=true;
        } 

        if(this.geoRadius==this.ppRadius){ 
         
          
          this.editPickupBoolean=false;
          
          }else{ 
            
            this.showList.push('Pickup Radius');
            this.editPickupBoolean=true;
          } 
          if(this.stoppageDetails.expectedTime==this.ppTime){ 
           
            this.editPickupBoolean=false;
            }else{ 
              
              this.showList.push('Pickup Time');
              this.editPickupBoolean=true;
            } 
            var data = {
              data: {
                key: localStorage.getItem('scbToken'),
                form: {
                  pickupId: this.stoppageId,
                  expectedTime: this.stoppageDetails.expectedTime,
                  pickuppoint: this.stoppageDetails.pickuppoint,
                  pickupRadius: this.geoRadius,
                  pickupAddress:this.inputAddress,
                  smsAlert: this.stoppageDetails.smsAlert+"",
                  appAlert: this.stoppageDetails.appAlert+"",
                  emailAlert: this.stoppageDetails.emailAlert+"",
                  callAlert:this.stoppageDetails.callAlert+"",
                  pickuppointLocation: {
                    lat: this.stoppageDetails.pickuppointLocation.lat, //lat: 28.703238424365686, lng: 77.0996348009421
                    lng: this.stoppageDetails.pickuppointLocation.lng
                  }
                  //lat: this.routeCreateMarker.getPosition().lat(), //lat: 28.703238424365686, lng: 77.0996348009421
                  //lng: this.routeCreateMarker.getPosition().lng()
                }
              }
            };
            let parent = this;
            swal({
              title: 'Are you sure?',
              text: 'Do you want to update the  changes - '+this.showList+'?',
              icon: 'warning',
              buttons: [
                'No, cancel it!',
                'Yes, I am sure!'
              ],
              dangerMode: true,
            }).then(function (isConfirm: any) { 
              if (isConfirm) { 
             
                //if(this.validateUserInputs()) {
             
                //
          
          
          
                parent.editPickupPoint.emit(data);
                parent.routeCreateMarker.setMap(null);
                parent.pickupGeofence.setMap(null);
             // }
            }
            })

  
  }

  processStoppageDetails(stoppageDetails: any) {
    // console.log(" stoppageDetails[0] ",stoppageDetails[0].pickupAddress, stoppageDetails)
    if(stoppageDetails[0] && stoppageDetails[0]['pickupAddress']==undefined){
      let locationAddress = stoppageDetails[0]['pickuppointLocation'];
      this.inputAddress = locationAddress['lat']+','+locationAddress['lng'];
    }else this.inputAddress = stoppageDetails[0].pickupAddress;

    this.geoRadius    = stoppageDetails[0].pickupRadius;
    this.stoppageDetails = stoppageDetails[0];
  }

 
  createRouteMarker(){
    //Creating separate route marker.
    
    /// console.log(this.haltonlyReportDatas);
    let mLatLng = new google.maps.LatLng(this.stoppageDetails.pickuppointLocation.lat,this.stoppageDetails.pickuppointLocation.lng);
    let parent:any = this;
    this.routeCreateMarker = new google.maps.Marker({
      position:mLatLng,
      map:this.map,
      draggable:true
    });
    this.pickupGeofence = new google.maps.Circle({
      strokeColor: '#FF0000',
      strokeOpacity: 0.8,
      strokeWeight: 2,
      fillColor: '#FF0000',
      fillOpacity: 0.35,
      map: this.map,
      center:  mLatLng,
      radius: this.stoppageDetails.pickupRadius,
      editable: true
    });
    this.routeCreateMarker.addListener('dragend',function(e:any) {
      parent.reverseGeocodeAddress(new google.maps.LatLng(e.latLng.lat(),e.latLng.lng()))
      parent.updateGeocirclePosition(new google.maps.LatLng(e.latLng.lat(),e.latLng.lng()));
    });
    google.maps.event.addListener(this.pickupGeofence, 'radius_changed', function() {
      parent.geoRadius = Math.round(parent.pickupGeofence.getRadius());
      $('#pointGeoRadius').focus();
      $('#pointGeoRadius').blur();
    });
    this.map.panTo(mLatLng);
    this.bindMapClickEvt();

    // this.plotRouteStoppages();

  //   this.haltonlyReportDatas = [];
  //   if((this.storage.get('HaltLog')===false) && (this.storage.get('editpickuppoint')==true)){
  //     this.getHaltLogforPickupDisplayPoint(0)
  //   setTimeout(() => {
  //     // if (this.haltonlyReportDatas.length > 0) {
  //     if (this.mapMarker) {
  //       console.log(this.mapMarker)
  //       this.clearMapMarkers(this.mapMarker);
  //     }
  //     this.mapMarker = [];
  //     this.mapBounds = new google.maps.LatLngBounds();
  //     this.haltonlyReportDatas.filter((val: any) => {
  //       let mlatLng = new google.maps.LatLng(val.location.lat, val.location.lng);
  //       let marker = new google.maps.Marker({
  //         position: mlatLng,
  //         map: this.map,
  //         //animation: google.maps.Animation.DROP,
  //         icon: {
  //           url: "../../../../assets/webroot/images/map/map_red.png",
  //         },
  //         mLatlng: mlatLng,
  //       });
  //       this.mapMarker.push(marker);
  //       this.mapBounds.extend(mlatLng);
  //     })
  //     // }
  //     this.haltonlyReportDatas=[];      

  //   }, 400);
  // }

  }

  bindMapClickEvt(){
    let parent:any = this;
    this.map.addListener('click',function(e:any){
     var clickedLatLng = new google.maps.LatLng(e.latLng.lat(),e.latLng.lng());
     parent.routeCreateMarker.setPosition(clickedLatLng);
     google.maps.event.trigger(parent.routeCreateMarker,'dragend',e);
    });
  }

  unbindMapClickEvt(){
    google.maps.event.clearListeners(this.map,'click');
  }

  geocodeAddress() {
      let address = this.inputAddress;
      //geocoding using custom service.
      var config = {
        'address': address
      };
      this.decodeAddress.geocode(config).then(response => {
         this.routeCreateMarker.setPosition(response['geometry'].location);
         this.pickupGeofence.setCenter(response['geometry'].location);
         this.map.panTo(response['geometry'].location);
      }).catch(error => { });
  }
  clearMapMarkers(markerArr: any) {
    for (var id in markerArr) {
      markerArr[id].setMap(null);
    }
  }

  async reverseGeocodeAddress($event:any){
    let geocoder = new google.maps.Geocoder();
    let address = $event;
    let parent:any = this;
    // console.log('address ',address, $event)
    //reverse geocoding using custom service.
    var config = {
      'latLng': $event
    }
    this.addressLoading=true;
    let response=await this.decodeAddress.geocode(config).catch(error => {
      console.log('Error - ', error);
    });
    if(response){
      this.inputAddress = response['formatted_address'];
      this.stoppageDetails.pickuppointLocation.lat=response['geometry']['location'].lat();
      this.stoppageDetails.pickuppointLocation.lng=response['geometry']['location'].lng();
     //  console.log('RES pose', response.geometry.location.lat())
      $('#locationField').focus();
      $('#locationField').blur();
      this.addressLoading=false;
    }
  }

  updateGeocirclePosition(mLatlng:any){
    this.pickupGeofence.setCenter(mLatlng);
  }

  updateGeoradius(){
    this.pickupGeofence.setRadius(Math.round(this.geoRadius));
  }


  // plotRouteStoppages(){
   
  //   this.routeStopee = Promise.resolve(this.storage.get('stoppage'));
    
  //   let mCount = 0;
  

    

  //   this.routeStopee.then((res:any)=>{
  //     this.markers = {}
     
  //     for (var val of res) {

  //       if(val.point ===  this.stoppageDetails.pickupId){
  //        continue;
  //       }else{

  //         let mlatLng = new google.maps.LatLng(val.latlng.lat, val.latlng.lng);
  //        let cnt =  ++mCount;
        
  //        let marker = new google.maps.Marker({
  //           position: mlatLng,
  //           map: this.map,
  //           //animation: google.maps.Animation.DROP,
  //           label: {
  //             text: '' + cnt,
  //             color: 'white',
  //           },
  //           icon: {
  //             url: "./.../../assets/webroot/images/map/pin-red.png",
  //             //size: new google.maps.Size(64, 64),
  //             scaledSize: new google.maps.Size(65, 65),
  //             origin: new google.maps.Point(0, 0),
  //             anchor: new google.maps.Point(20, 40),
  //             labelOrigin: new google.maps.Point(32, 14)
  //           },
  //           data:val.id,
  //           addr:val.point,
  //           count:cnt,
  //           mLatlng: mlatLng,
           
  //         });

  //         var infowindow = new google.maps.InfoWindow();
  //        marker.addListener('mouseover', function (event: any) {
  //           infowindow.setContent('<p>' + this.addr + '</p>');
  //           infowindow.open(this.mapobj, this);                
  //         });
  //         this.markers['_'+cnt]  =  marker;
  //       }
  //     }
  //     // this.deleteMarker(this.markers,'particularMarker');
     
  //   })
  // }

 
  // deleteMarker(marker:any,flag:any){
  //   setTimeout(() => {
      
    
  //   let len = Object.keys(marker).length;

  //   if(flag === 'particularMarker'){
  //     len && Object.keys(marker).filter((itm,ind)=>{
  //       if(marker[itm]['data'] === this.stoppageDetails.pickupId){     
  //           // marker['_'+marker[itm]['count']]
           
  //         marker['_'+marker[itm]['count']].setMap(null);  
  //         let key = marker[itm]['count']
  //         marker['_'+marker[itm]['count']] = null;
  //         delete  marker['_'+key];
  //         console.log(marker);
         
  //       }
  //     });
  //   }
  //   if(flag === 'clearAll'){
  //     if(len) {
  //       Object.keys(marker).forEach((itm,ind)=> {
  //         marker[itm].setMap(null);
  //         // marker['_'+marker[itm]['count']].setMap(null)
  //       });
  //       marker = {}
  //       console.log(marker);
  //     }
  //   }
   
  // }, 100);
  
  // }
  
}

/*
Notes : npm install node-sass -g
*/
