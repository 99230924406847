import {Component} from '@angular/core';
import { ApiService } from './../../services/ApiService';
import { GlobalUtils } from './../../services/global-utils';
import { NG_VALIDATORS,Validator,Validators,AbstractControl,ValidatorFn } from '@angular/forms';

const swal = require('sweetalert');

@Component({
    selector:'org-email-configuration',
    styles:[`
                .configurationContainer{
                    padding-top:1.85%
                }
                .panel-title{
                    font-size: 20px;
                    color: #454545;
                }
                .panel-title-filter{
                    font-size: 14px;
                    border: 1px solid lightgray;
                    padding: 4px;
                    border-radius: 4px;
                    cursor: pointer;
                }
                .addMobileBtn{
                    font-size: 1.6em;
                    line-height: 1.6em;
                    background: #2396b9;
                    position: fixed;
                    z-index: 999;
                    right: 20px;
                    bottom: 20px;
                    cursor: pointer;
                }

                .btn-circle.btn-xl {
                    width: 70px;
                    height: 70px;
                    padding: 10px 16px;
                    border-radius: 35px;
                    font-size: 24px;
                    line-height: 1.33;
                }

                .btn-circle {
                    width: 45px;
                    height: 45px;
                    padding: 6px 0px;
                    border-radius: 50%;
                    text-align: center;
                    line-height: 1.42857;
                    background: #2396b9;
                    outline:none !Important;
                }

                .panel-default>.panel-heading {
                    color: #333;
                    background-color: #ffff;
                    border-color: #f0f0f0;
                }
                .panel {
                    box-shadow: 1px 2px 4px 0px lightgrey;
                }
                .panel-default {
                    border-color: #fdfafa;
                }

        `],
    templateUrl:'./orgEmailConfigurationTemplate.html'
})

export class OrgEmailConfig {
    private availableOrgMobiles:any[];
    public showFilterOptions:boolean;
    public showMobileAddSection:boolean;
    private newEmailId:string;
    private newUserName:string;
    private showOTPValidationFields:boolean;
    private userReceivedPassword:any;
    private apiGeneratedOTPToken:any;
    private mobileNumberError:boolean;
    private userNameError:boolean;
    private otpError:boolean;

    constructor(private apiService:ApiService,private globalUtils:GlobalUtils){
        this.availableOrgMobiles = [];
        this.showFilterOptions = false;
        this.showMobileAddSection = false;
        this.showOTPValidationFields = false;
    }

    ngOnInit(){
         this.getOrgEmails();
    }

    filterFunction(){
       this.getOrgEmails();
       this.newEmailId="";
       this.newUserName="";
    }

    getOrgEmails(){
        let apiHeader = {
            data : {
                key:localStorage.getItem('scbToken'),
                filter: {
                  username:this.newUserName,
                  useremail:this.newEmailId
                },
                extra: {
                  orderByDateCreated: -1
                }
            }
        }
        this.availableOrgMobiles = [];
        this.apiService.getOrgEmails(apiHeader).then(response => {
            this.availableOrgMobiles = response.response;
        }).catch(error => {})
    }

    initAddMobileSection(){
        this.showMobileAddSection = true;
        this.showOTPValidationFields = false;
        this.userReceivedPassword = null;
        this.apiGeneratedOTPToken = null;
    }

    hideAddMobileSection(){
        this.showMobileAddSection = false;
    }

    //Validate addNotificationSetting

    validateUserInputs() {
      let isValid: boolean = true;
      if (!this.newEmailId) {
        this.mobileNumberError = true;
        isValid = false;
      } else {
        this.mobileNumberError = false;
      }

      if (!this.newUserName) {
        this.userNameError = true;
        isValid = false;
      } else {
        this.userNameError = false;
      }

      return isValid;
    }

    generateOtp(){
      if(this.validateUserInputs()){
        let apiHeader = {
            data:{
                key:localStorage.getItem('scbToken'),
                form:{
                    useremail: this.newEmailId,
                    username:this.newUserName
                }
            }
        }
        this.apiService.validateEmails(apiHeader).then(response => {
             if(response.status=="success"){
                // swal("Success", "Mobile number Added Successfully", "success").done();
                  this.showOTPValidationFields = true;
             }else{
              swal("Failure",this.globalUtils.getErrorMsg(response.ec), "error");
             }
             if(response.otptoken){
                 this.apiGeneratedOTPToken = response.otptoken;
             }
        }).catch(error => {});
      }
    }

    initiateOtpValidator(){
      let isValid: boolean = true;
      if (!this.userReceivedPassword) {
        this.otpError = true;
        isValid = false;
      } else {
        this.otpError = false;
      }

      return isValid;
    }

    verifyUserReceivedOTP(){
      if(this.initiateOtpValidator()){
        let apiHeader = {
            data:{
                key:localStorage.getItem('scbToken'),
                form: {
                    otptoken: this.apiGeneratedOTPToken,
                    otp: this.userReceivedPassword
                }
            }
        }
        this.apiService.verifyuserEnteredEmailOtp(apiHeader).then(response => {
            if(response.status == 'success'){
              swal("Success", "OTP Verified Successfully", "success");
                this.hideAddMobileSection();
                this.getOrgEmails();
            }else{
              swal("Failure",this.globalUtils.getErrorMsg(response.ec), "error");
            }
        }).catch(error => {});
      }

    }


  deleteRegisteredMobileInfo(mailId:any){
    const parent = this ;
    let apiHeader = {
        data:{
            key:localStorage.getItem('scbToken'),
            form:{
                userRegEmailId:mailId
            }
        }
    };
    swal({
        title: 'Are you sure?',
        text: 'This Email will be removed',
        icon: 'warning',
        buttons: [
          'No, cancel it!',
          'Yes, I am sure!'
        ],
        dangerMode: true,
      }).then(function(isConfirm: any) {
        if ( isConfirm ) {
          swal({
            title: 'Success!',
            text: 'Mobile number removed',
            icon: 'success'
          }).then(function() {
            parent.apiService.deleteRegisteredEmail(apiHeader).then(response => {
                if (response) {
                  parent.getOrgEmails();
                }
                }).catch(error => { });
          });
        } else {
         // swal('Cancelled', 'Continue the session :)', 'error');
        }
      });
    }


}
