/*Auther:Deepak */
import { Component,Input } from '@angular/core';
import { RouterModule, Routes, Router } from '@angular/router';
import { globalService } from './../../../services/global.service';
import { ApiService } from './../../../services/ApiService';

declare var $:any;

@Component({
  selector: 'settings-panel',
  template: `

  <!--dropdown menu-->
  <div class="hand_cursor">
       <div class="row activity">
           <div class="pull-left pl text-uppercase" style="padding-top:25px;">Global settings</div>
       </div>
   </div>
<!--Secondary div-->
<div class="row settings-row">
    <table class="setings-checkbox-main-tab">
        <tr class="settings-checkbox-main-tr">
            <td class="settings-checkbox-main-td">
                <table>
                    <tr>
                        <td class="check"><input type="checkbox" class="chk regular-checkbox" [(ngModel)]="orgNotifConfigs.smsAlert" ></td>
                    </tr>
                    <tr>
                        <td class="text-uppercase text-left grey"><i class="fa fa-comments grey" aria-hidden="true"></i> SMS</td>
                    </tr>
                </table>
            </td>
            <td class="settings-checkbox-main-td">
                <table>
                    <tr>
                        <td class="check"><input type="checkbox" class="chk regular-checkbox" [(ngModel)]="orgNotifConfigs.emailAlert" ></td>
                    </tr>
                    <tr>
                        <td class="text-uppercase grey"><i class="fa fa-envelope grey" aria-hidden="true"></i> EMAIL</td>
                    </tr>
                </table>
            </td>
            <td class="settings-checkbox-main-td">
                <table>
                    <tr>
                        <td class="check"><input type="checkbox" class="chk regular-checkbox" [(ngModel)]="orgNotifConfigs.appAlert"></td>
                    </tr>
                    <tr>
                        <td class="text-uppercase grey"><i class="fa fa-bell grey" aria-hidden="true"></i> APP</td>
                    </tr>
                </table>
            </td>
            <td class="settings-checkbox-main-td">
                <table>
                    <tr>
                        <td class="check"><input type="checkbox" class="chk regular-checkbox" [(ngModel)]="orgNotifConfigs.callAlert" ></td>
                    </tr>
                    <tr>
                        <td class="text-uppercase grey"><i class="fa fa-phone" aria-hidden="true"></i> CALL</td>
                    </tr>
                </table>
            </td>
        </tr>
    </table>

    <div class="alertActionBtn">
        <button *ngIf="showLoading==false" (click)="saveOrgNotifConfig()" class="btn btn-primary pull-right">Update</button>
        <button [disabled]="true"  *ngIf="showLoading==true" class="btn btn-primary pull-right">
         <i class="fa fa-refresh fa-spin mr5"></i>Update
        </button>
    </div>
</div>

<div class="alert alert-success" role="alert" *ngIf="updateSuccess" id="success-alert">
  <button  type="button" class="close"   data-dismiss="alert" aria-label="Close"><span aria-hidden="true">&times;</span></button>
  <strong>Success! </strong> Alert Configuration Updated
</div>


  `,
  styles: [ `

    .primary-div {
        margin-top: 10%;
    }

    .bg-blue {
        background-color: #3498db;
    }

    /*Primary iconic nav*/

    .icon .prim-icon {
        margin-left: 10px;
        margin-top: 5px;
    }

    .icon .icon-pad {
        padding: 2px 5px;
    }

    .icon .icon-pad .prim-nav-a {
        color: white;
        padding-left: 14px;
        padding-right: 14px;
        padding-top: 0px;
        padding-bottom: 0px;
        font-size: 24px;
    }

    .img {
        height: 10px;
        z-index: 800;
    }

    .ul {
        position: relative;
        margin-top: -14px;
        padding: 0px 4px;
    }

    ul li .prim-nav-arrow {
        color: white;
        margin-left: 1px;
        padding-left: 26px;
        padding-right: 24px;
        opacity: 0.0;
    }

    ul li .prim-nav-arrow-select {
        opacity: 1;
    }

    /*Secondary nav*/

    .route-text {
        margin-top: -15px;
        font-size: 20px;
        font-weight: 400;
        padding: 7px 18px;
    }

    .sec-nav a {
        text-decoration: none;
        color: black;
    }

    .sec-nav div {
        border-bottom: 3px solid lightgrey;
        border-top: 1px solid lightgrey;
    }

    .sec-nav div:hover {
        cursor: pointer;
        background-color: white;
        border-bottom: 3px solid #3498db;
    }

    .sec-nav .txt-default {
        background-color: white;
        border-bottom: 3px solid #3498db;
    }

    .txt_pad {
        font-size: 15px;
        padding-left: 0px;
        padding-bottom: 10px;
        padding-top: 10px;
        padding-right: 20px;

    }

    .text_upcoming_pad {
        font-size: 15px;
        padding-left: 18px;
        padding-bottom: 10px;
        padding-top: 10px;
        padding-right: 30px;

    }

    /*Search bar stylings*/

    .searchbar {
        padding-top: 20px;
        padding-left: 18px;
        padding-right: 20px;
        padding-bottom: 20px;
        position: relative;
        width: 99%;
    }

    .searchbar input[type=text] {
        background-color: #f7f7f7;
        border: 1px solid lightgrey;
        height: 40px;
        border-radius: 0.2em;
    }

    .srchb {
        position: absolute;
        left: 380px;
        top: 24px;
        padding: 08px 15px;
        color: #969494;
        font-size: 15px;
        z-index: 990;
    }

    .srchb:hover {
        cursor: pointer;
    }

    /*secondary div and dropdown arrow stylings*/

    /*drop down arrow*/

    .activity {
        padding-bottom: 20px;
    }

    .pl {
        padding-left: 18px;
        font-size: 15px;
        font-weight: normal;
        color: #34495e;
    }

    .pr {
        padding-right: 28px;
        font-size: 15px;
        font-weight: normal;
        color: #34495e;
    }

    .drpdwn > label {
        text-decoration: none;
        font-weight: normal;
        cursor: pointer;
        padding-left: 30px;
    }

    .drpdwn > button {
        border: 1px solid white;
        background-color: white;
        font-size: 12px;

        padding-bottom: 5px!important;
        cursor: pointer;
    }

    .drpdown-li-pad {
        padding: 10px;
    }

    .fa-chevron-down {
        font-size: 10px;
    }

    .settings-row {
        margin-left: -29px;
    }

    .red {
        color: #ff6347;
    }

    .orange {
        color: orange;
    }

    .green {
        color: #189a02;
    }

    .grey {
        color: grey;
    }

    div .setings-checkbox-main-tab .settings-checkbox-main-tr .settings-checkbox-main-td {
        padding-left: 30px;
        padding-right: 20px;
    }

    .check {
        padding-left: 15px;
    }

    .alert-success {
      margin-top: 10px;
    }

   .alert {
    padding: 8px;
    margin-bottom: 0px;
    border: none;
    border-radius: 4px;
    margin-top: 20px;
    background-color: #2eff2217;
    color: #000000;
   }

   .alertActionBtn{
        padding-right:17%;
        padding-top:4%;
   }

`  ],
})
export class SettingsDir  {

    public orgNotifConfigs:any = {};
    public updateSuccess:boolean = false;
    public showLoading:boolean = false;

  constructor(public apiService: ApiService,public globalService:globalService) {
    this.getOrgNotifConfigStatus();
  }

  ngOnInit(){


  }


  getOrgNotifConfigStatus():void{
    this.apiService.getCurrentOrgDetails({
      data:{
        key:localStorage.getItem('scbToken'),
        filter:{
          organizationId :this.globalService.getData().currentOrganization
        }
      }
    }).then(response => {
                   let result=response;
                   if(result.status=="success"){
                     this.orgNotifConfigs = result.response;
                   }
            }).catch(error => {
                   console.log('Error while logging in', error)
            });
  }

  saveOrgNotifConfig():void{
    this.showLoading = true ;
    this.apiService.editOrgDetails({
      data: {
      key:  localStorage.getItem('scbToken'),
      form: {
          smsAlert: ''+this.orgNotifConfigs.smsAlert,
          appAlert: ''+this.orgNotifConfigs.appAlert,
          emailAlert: ''+this.orgNotifConfigs.emailAlert,
          callAlert:''+this.orgNotifConfigs.callAlert
          }
      }
    }).then(response => {
      if(response.status == 'success'){

        this.updateSuccess=true;
        setTimeout(() => {
          $("#success-alert").fadeTo(500,500).slideUp(3000, function(){
          this.updateSuccess=false;
        });
      }, 1000);
        this.getOrgNotifConfigStatus(); //callback to reflect changes.
        this.showLoading = false ;
      }
    }).catch(error => {});
  }



 }

/*
Notes : npm install node-sass -g
*/
