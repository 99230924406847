import { Pipe, PipeTransform } from '@angular/core';


@Pipe({
  name: 'arrayfilter'
})
export class ArrayFilterPipe implements PipeTransform {
  transform(value: any, key: any, filterKey: any): any {
    
    if (filterKey == '' || key == '') return value
    else {
      return value.filter((item: any) => {
        if (item[key].toLowerCase().includes(filterKey.toLowerCase())) {
          return item;
        }
      });
    }
  }

}

@Pipe({
  name: 'removeDuplicate'
})
export class ArrayRemoveDuplicatePipe implements PipeTransform{
  transform(value: any, key:any) {
    if(key == '') return value;
    return value.filter((obj:any, pos:any, arr:any) => {
      return arr.map((mapObj:any) => mapObj[key]).indexOf(obj[key]) === pos;
    });
  }
}
