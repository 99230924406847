/**
 * @Author: Deepak Pookkote <group10>
 * @Date:   2018-04-24T11:51:53+05:30
 * @Email:  deepak@groupten.com
 * @Project: School-Bus
 * @Filename: sideMenu.ts
 * @Last modified by:   group10
 * @Last modified time: 2018-04-25T15:55:59+05:30
 * @Copyright: Group10 Technologies
 */



/*Auther:Deepak */
import { Component, Input, Output, EventEmitter } from '@angular/core';
import { RouterModule, Routes, Router, ActivatedRoute } from '@angular/router';
import { globalService } from './../../../services/global.service';
import { ApiService } from './../../../services/ApiService';
import { StorageService } from './../../../services/storage.service';
import { COM } from '../../../services/com.services';
// import { MessageService } from './../../../services/message.service';
const swal = require('sweetalert');
declare var $: any;
@Component({
  selector: 'side-menu',
  templateUrl: './sideMenuTemplate.html',
  styles: [`


    .secondary-div {
        height: 30em;
        overflow-y: scroll;
        overflow-x: hidden;
    }
    .card {
      font-size: 13px;
      border-bottom: 1.5px solid #007bff;
      border-radius: 12px;
      cursor: pointer;
      -webkit-box-shadow: 1px -1px 19px -5px rgba(0,0,0,1);
-moz-box-shadow: 1px -1px 19px -5px rgba(0,0,0,1);
box-shadow: 1px -1px 19px -5px rgba(0,0,0,1);
border:1px solid #ccc;
  }
  .shadow {
    box-shadow: 0 .5rem 1rem rgba(0,0,0,.15) !important;
}

.mt-1, .my-1 {
  margin-top: .25rem !important;
}
.p-2 {
  padding: .5rem !important;
}
.m-0 {
    margin: 0 !important;
}
.p-0 {
  padding: 0 !important;
}
.mr-1, .mx-1 {
  margin-right: .25rem !important;
}
img {
  vertical-align: middle;
  border-style: none;
}
    /*Routee title stylings*/

    .hand_cursor {
      cursor: default;
    }

    .pl {
        padding-left: 30px;
        font-size: 15px;
        font-weight: normal;
        color: #34495e;
    }

    .pr {
        padding-right: 40px;
        font-size: 15px;
        font-weight: normal;
        color: #34495e;
    }

    .txt-pad-right {
        padding-right: 20px;
        padding-left: 0px;
    }

    .progress {
        overflow: visible;
        margin-bottom: 26px;
        height: 4px;
    }

    .progress .progress-bar {
        background-color: #3498db;
        position: relative;
        border-radius: 4px;
    }

    .progress .progress-bar span {
        background-color: #3498db;
        position: absolute;
        bottom: -6px;
        font-size: 14px;
        line-height: 10px;
        padding: 3px 3px 2px 3px;
        right: -1.4em;
        border-radius: 2px;
    }

    /*Search bar stylings*/

    .searchbar {
        padding-top: 20px;
        padding-left: 18px;
        padding-right: 20px;
        padding-bottom: 20px;
        position: relative;
        width: 99%;
    }

    .searchbar input[type=text] {
        background-color: #f7f7f7;
        border: 1px solid lightgrey;
        height: 40px;
        border-radius: 0.2em;
    }

    .srchb {
      position: absolute;
      right: 15px;
      top: 24px;
      padding: 08px 15px;
      color: #969494;
      font-size: 15px;
    }

    .srchb:hover {
        cursor: pointer;
    }

    .animation-bottom10{
      animation: animationBottom10 3s;
    }
    @keyframes animationBottom10 {
      0% {   transform:translateY(10px); }
      100%{  transform:translateY(0px); }
    }

  .border-deda {
    border-radius: 3px;
    border: 1px solid #dedada;
    -webkit-box-shadow: 2px 10px 12px -4px #4439399e;
    -moz-box-shadow: 2px 10px 12px -4px #4439399e;
    box-shadow: 2px 10px 12px -4px #4439399e;
    height: 144px;
    overflow-y: auto;
    cursor: pointer;
    position: absolute;
    z-index: 999999999999;
    background: white;
    width: 53.5vh;
  }

  @media only screen and (min-device-width : 768px) and (max-device-width : 1024px) and (orientation : portrait) and (-webkit-min-device-pixel-ratio : 2) {
    .border-deda {
      border-radius: 3px;
      border: 1px solid #dedada;
      -webkit-box-shadow: 2px 10px 12px -4px #4439399e;
      -moz-box-shadow: 2px 10px 12px -4px #4439399e;
      box-shadow: 2px 10px 12px -4px #4439399e;
      height: 144px;
      overflow-y: auto;
      cursor: pointer;
      position: absolute;
      z-index: 999999999999;
      background: white;
      width: 63vh;
    }
  }

  @media only screen  and (min-width : 1224px) {
    .border-deda {
      border-radius: 3px;
      border: 1px solid #dedada;
      -webkit-box-shadow: 2px 10px 12px -4px #4439399e;
      -moz-box-shadow: 2px 10px 12px -4px #4439399e;
      box-shadow: 2px 10px 12px -4px #4439399e;
      height: 144px;
      overflow-y: auto;
      cursor: pointer;
      position: absolute;
      z-index: 999999999999;
      background: white;
      width: 91%;
    }
  }

    /* Large screens ----------- */
    @media only screen  and (min-width : 1824px) {
      .border-deda {
        border-radius: 3px;
        border: 1px solid #dedada;
        -webkit-box-shadow: 2px 10px 12px -4px #4439399e;
        -moz-box-shadow: 2px 10px 12px -4px #4439399e;
        box-shadow: 2px 10px 12px -4px #4439399e;
        height: 144px;
        overflow-y: auto;
        cursor: pointer;
        position: absolute;
        z-index: 999999999999;
        background: white;
        width: 63vh;
      }
    }
  .border-deda::-webkit-scrollbar {
      background-color: white;
      width: 2px;
  }

  .border-deda::-moz-scrollbar {
      background-color: white;
      width: 2px;
  }
  .table>tbody>tr>td, .table>tbody>tr>th, .table>tfoot>tr>td, .table>tfoot>tr>th, .table>thead>tr>td, .table>thead>tr>th {
    padding: 8px;
    line-height: 1.42857143;
    vertical-align: top;
    border-top: 1px solid #efefef;
  }

  .field-icon {
    float: right;
    margin-left: -25px;
    margin-top: -25px;
    margin-right:5px;
    position: relative;
    z-index: 2;
  }


  /*Secondary nav*/

  .route-text {
      margin-top: -15px;
      font-size: 20px;
      font-weight: 400;
      padding: 7px 18px;
  }

  .sec-nav a {
      text-decoration: none;
      color: black;
  }

  .sec-nav div {
      border-bottom: 3px solid lightgrey;
      border-top: 1px solid lightgrey;
  }

  .sec-nav div:hover {
      cursor: pointer;
      background-color: white;
      border-bottom: 3px solid #3498db;
  }

  .sec-nav .active {
      background-color: white;
      border-bottom: 3px solid #3498db;
  }

  .txt_pad {
      font-size: 15px;
      padding-left: 0px;
      padding-bottom: 10px;
      padding-top: 10px;
      padding-right: 20px;

  }

  .text_upcoming_pad {
    font-size: 15px;
    padding-left: 4px;
    padding-bottom: 10px;
    padding-top: 10px;
    padding-right: 20px;

  }

  .pointer {cursor: pointer;}




`  ],
})
export class SideMenuDir {

  public activeRoutes: any = [];
  public activeRoutesInfo: any = [];
  public allRoutes: any = [];
  public allRoutesInfo: any = [];
  public routeId: any;
  public routeTypeToShown: string;
  public RouteName: any = '';
  public routeName: any = '';
  public sharehintContainer: any = false;
  public noMatchingItemsFound: any = false;
  public timer_vehDetails: any;
  private pageJump: number = 0;
  private noMoredata: boolean;
  public tempArr: any = [];
  public isLoading: boolean; // Loader Var
  public isLoading1: boolean = false;
  public isLoadingActiveRoutes: boolean = true; // Loader Var
  public currentUrl: any;
  public OrgHolidayText: boolean = false;
  public noData: boolean = false;
  public windowObj: any;
  public confID: any;
  routerUrl: any;
  public cameraModuleRender: boolean = false;

  /*Adding Modified variables over here */

  public showRouteType: string = 'AllRoutes';
  public sidebarClickedTab: string = 'AllRoutes';

  /*Ending Modified variables ..*/

  // @Input('showingRouteType')
  // set routeType(routeType:string){
  //   this.routeTypeToShown = routeType;
  //   this.processRouteType(this.routeTypeToShown);
  // }

  @Output() routeIdEmitter: EventEmitter<any> = new EventEmitter<any>();
  @Output() setDefRouteId: EventEmitter<any> = new EventEmitter<any>();
  @Output() mapPanEmitterFlag: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() selectedVehicleId: EventEmitter<any> = new EventEmitter<any>();
  @Output() redirectRouteType: EventEmitter<any> = new EventEmitter<any>();
  @Output() displayInfotable: EventEmitter<boolean> = new EventEmitter<boolean>();

  @Output() routeVehMap: EventEmitter<any> = new EventEmitter();
  showIframe: boolean = false;
  public iframe_head_rname: string = 'routename';
  public iframe_head_vehname: string = 'vehname';
  cleartimeout_Drag: any;


  constructor(
    public router: Router,
    public globalService: globalService,
    public apiService: ApiService,
    private activatedRoute: ActivatedRoute,
    private storage: StorageService,
    private com: COM,
    // public mess:MessageService,

  ) {
    this.isLoading = true;
    this.com.comAction.subscribe((val: any) => val.type === "StorageService" && (this.cameraModuleRender = val.value))

  }



  ngOnInit() {

    this.getAllRoutesInfo();
    this.apiCallSelector();
    this.activatedRoute.url.subscribe(url => {
      this.routerUrl = url[0].path;
    });

    this.overspeedNotifServ()


  }

  getAllRoutesInfo() {
    var routeObj = {
      data: {
        key: localStorage.getItem('scbToken'),
        extra: {
          maxlimit: 'all'

        }
      }
    };

    this.apiService.getRoutes(routeObj).then(response => {
      if (response) {
        if (response !== '' && response !== undefined && response != null) {
          if (response.response) {
            // console.log(response.response);
            let allRouteInfo: any = {};
            for (let route of response.response) {

              if (allRouteInfo[route.routeId] === undefined) {
                allRouteInfo[route.routeId] = {};
              }
              allRouteInfo[route.routeId] = {
                routeName: route.routeName,
                tags: route.tags,
                vehId: route.vehId,
                vehNo: route.vehNo,
                routeType: route.routeType,
                startTime: route.routeStartTime,
                endTime: route.routeEndTime
              };
            }
            // console.log(allRouteInfo);
            this.storage.set({ 'AllRouteInfo': allRouteInfo });
          }
        }
      }
    }).catch(error => { });


  }

  routeVehicleMap(routeobj: any = {}) {

    this.routeVehMap.emit(routeobj);

  }



  setRouteType(selectedTab: any) {
    this.showRouteType = selectedTab;
    this.sidebarClickedTab = selectedTab;
    this.sharehintContainer = false;
  }


  apiCallSelector() {
    if (this.showRouteType === 'UpcomingRoutes') {
      console.log('ENTRY.... ', this.isLoadingActiveRoutes);
      this.isLoading1 = true;
      this.getActiveRoutesDefaultValues();

    } else if (this.showRouteType === 'AllRoutes') {
      this.getAllRoutesDefaultValues(true);
    }
    // this.clearFilters();
  }

  closeShareContainer() {
    let parent: any = this;
    setTimeout(function () {
      parent.sharehintContainer = false;
    }, 5000);
  }

  closeShareContaineronClick() {
    let parent: any = this;
    setTimeout(function () {
      parent.sharehintContainer = false;
    }, 500);
  }


  filterActiveRoutesFunction(name: any) {
    if (this.showRouteType === 'UpcomingRoutes') {
      $('#displayActiveRouteName').val(name);
      if (this.timer_vehDetails) {
        clearTimeout(this.timer_vehDetails);
      }
      this.timer_vehDetails = setTimeout(() => { //functionality to restric api calls on each key press
        this.routeName = '';
        this.routeName = name;
        this.getActiveRoutesDefaultValues();
        //  console.log("test syn cal-1");
      }, 1000);
    }

  }


  clearFilters() {
    if (this.showRouteType === 'UpcomingRoutes') {
      $('#displayActiveRouteName').val('');
      this.routeName = '';
      this.getActiveRoutesDefaultValues();
    } else {
      $('#displayRouteName').val('');
      this.RouteName = '';
      this.getAllRoutes();
    }

  }





  getActiveRoutesDefaultValues(): void {

    let parent: any = this;
    this.isLoading = true;

    let routeObj = {
      data: {
        key: localStorage.getItem('scbToken'),
        filter: {
          routeName: this.routeName
        }
      }
    };
    this.apiService.timelineGraph(routeObj).then(response => {
      // var parent: any = this;
      if (response.status === 'success') {
        if (response.response) {
          this.OrgHolidayText = false;
          this.activeRoutes = response.response.activeRoutes;
          this.activeRoutesInfo = $.map(this.activeRoutes, function (value: any, index: any) {
            return [value];
          });

          // console.log('test',this.activeRoutesInfo);

          this.routeId = this.activeRoutesInfo[0].routeId;

        } else {
          this.activeRoutesInfo = [];
          this.closeShareContaineronClick();
          this.noData = true;
        }

        this.confID = setTimeout(function () {
          if (parent.showRouteType === 'UpcomingRoutes') {
            parent.getActiveRoutesDefaultValues();
            //  console.log("test syn caln");
          }
        }, 40 * 1000);
        this.isLoading1 = false;
      } else {
        if (response.ec === 'SCB73') {
          this.OrgHolidayText = true;
          this.isLoading1 = false;
        }
      }
      this.isLoading = false;
    }).catch(error => {
      console.error(error)
      // setTimeout( () => {
      //   if (this.showRouteType === 'UpcomingRoutes') {
      //     this.getActiveRoutesDefaultValues();
      //   }
      // }, ( 500 * 1000 ) );
    });
    this.isLoading = false;
  }
  ngOnDestroy() {


    // if (this.confID) {
    //   clearTimeout(this.confID);
    // }
    // if (this.timer_vehDetails) {
    //   clearTimeout(this.timer_vehDetails);
    // }
    //clearTimeout(this.confID);
    this.showRouteType = 'AllRoutes'
    clearTimeout(this.confID);
    clearTimeout(this.timer_vehDetails)
    clearTimeout(this.cleartimeout_Drag);
  }


  passRouteId = (routeId: any) => {

    let obj = {
      routeId: routeId,
      randomNum: Math.random(),
      routeMap: false
    };
    this.routeIdEmitter.emit(obj);
    this.displayInfotable.emit(true);
    if (this.showRouteType !== 'UpcomingRoutes') {
      this.getRouteVehicleDetails(routeId); // map pannig by rakesh.
    } else {
      this.getActiveRouteVehicleDetails(routeId); // active routes map panning deepak test
    }

  }


  getRouteVehicleDetails = (routeId: any) => {

    let selectedVehicleId = this.allRoutesInfo.filter(function (route: any) { return route.routeId == routeId })[0];
    // console.log(selectedVehicleId);
    let emitObj = {
      routeId: routeId,
      vehId: selectedVehicleId.vehId
    };

    this.selectedVehicleId.emit(emitObj);
    this.mapPanEmitterFlag.emit(true);
    //this.isLoadingActiveRoutes=false;
  }

  getActiveRouteVehicleDetails = (routeId: any) => {
    let selectedVehicleId = this.activeRoutesInfo.filter(function (route: any) { return route.routeId == routeId })[0];
    let emitObj = {
      routeId: routeId,
      vehId: selectedVehicleId.vehId
    };

    this.selectedVehicleId.emit(emitObj);
    this.mapPanEmitterFlag.emit(true);
  }



  filterFunction(name: any) {  ///console.log('*****');
    $('#displayRouteName').val(name);
    if (this.timer_vehDetails) {
      clearTimeout(this.timer_vehDetails);
    }
    this.timer_vehDetails = setTimeout(() => {
      this.RouteName = '';
      this.RouteName = name;
      this.getAllRoutes();
    }, 1000);

  }



  // Get all org active routes.
  getAllRoutes(): void {
    var routeObj = {
      data: {
        key: localStorage.getItem('scbToken'),
        filter: {
          routeName: this.RouteName
        }
      }
    };

    this.apiService.getRoutes(routeObj).then(response => {
      if (response) {
        if (response !== '' && response !== undefined && response != null) {

          this.allRoutes = response.response;
          this.allRoutesInfo = $.map(this.allRoutes, function (value: any, index: any) {
            return [value];
          });

          //console.log(response.response);

          this.routeId = this.allRoutes[0].routeId;
          //  this.setDefRouteId.emit(this.routeId);
        } else {
          this.closeShareContaineronClick();
        }

      }
    }).catch(error => { });
  }


  getAllRoutesDefaultValues(iscallback: boolean): void {
    if (!this.pageJump) this.isLoading = true;

    let parent: any = this;
    if (iscallback) this.allRoutesInfo = [];
    this.tempArr = [];
    var routeObj = {
      data: {
        key: localStorage.getItem('scbToken'),
        filter: {
          routeName: this.RouteName
        },
        extra: {
          pageJump: this.pageJump
        }
      }
    };

    this.apiService.getRoutes(routeObj).then(response => {
      if (response) {
        if (response !== '' && response !== undefined && response != null) {
          if (response.response) {
            // console.log(response.response);
            let allRouteInfo: any = {};
            for (let route of response.response) {
              // console.log( this.allRoutesInfo );
              if (this.allRoutesInfo.indexOf(route) === -1) {
                this.allRoutesInfo.push(route);


              }

            }

            this.noMoredata = false;
          } else {
            this.noMoredata = true;
            this.pageJump = 0;
            this.closeShareContaineronClick();
          }
          this.isLoading = false;
        }
      }
    }).catch(error => { });
  }

  onScroll($event: any) {
    this.windowObj = $event;
    if (this.showRouteType === 'AllRoutes') {
      if (($event.target.scrollTop + $event.target.offsetHeight) >= $event.target.scrollHeight) {
        if (this.noMoredata === false) {
          this.pageJump++;
          this.getAllRoutesDefaultValues(false);
        }
      }
    }
  }


  overspeedNotifServ() {
    this.apiService.viewNotifications({
      data: {
        key: localStorage.getItem('scbToken'),
        filter: {
          notificationFor: "overspeed",
        }
      }
    }).then(response => {
      let result = response.osval;

      !result && this.storage.set({ overspeed: null });
      result && (this.storage.set({ overspeed: null }), this.storage.set({ overspeed: result }));
    });
  }

  /* working draggable screen */
  iframe_video(param: any) {

    this.showIframe = false;

    this.iframe_head_rname = param.routeName
    this.iframe_head_vehname = param.vehNo

    let isValid = Object.keys(param).length ? true : false

    if (isValid) {
      this.apiService.getVehicleCameraLiveStream({
        data: {
          key: localStorage.getItem('scbToken'),
          filter: {
            vehNo: [param.vehNo]
          }
        }
      }).then(res => {
        isValid = res.response[param.vehNo] === false ? false : true;
        // isValid && (this.showIframe=true);

        if (isValid) {
          this.showIframe = false;
          this.showIframe = true;
          setTimeout(() => {
            // res.response = "http://streaming.placer.in/808gps/open/player/video.html?lang=en&devIdno=918128452&jsession=b66be376-c5d0-4183-83bc-e056bde800c4";
            // res.response[param.vehNo] = "https://www.youtube.com/embed/k5E2AVpwsko";
            let iframe = ` <iframe style="" id="real_iframe" scrolling="no" frameborder="0" height="300" width="670" src="${res.response[param.vehNo]}"></iframe>`
            document.getElementById('real_frame1').innerHTML = iframe;
            document.getElementById('alert').style.zIndex = '999999';
            // document.getElementById('real_iframe').setAttribute('height','250');
            // document.getElementById('real_iframe').setAttribute('width','650');

            this.dragEvent(); // start draggable event only iframe gets loaded

          }, 500);

        } else {
          swal('No Devices Available', 'for ' + this.iframe_head_rname + ' - ' + this.iframe_head_vehname, 'warning');
        }

      });

    } else;
    // }

  }

  animate_back() {

    this.showIframe = false;
    !this.showIframe && clearTimeout(this.cleartimeout_Drag); // on false clear timeout
  }

  dragEvent() {
    $("#alert").draggable({
      containment: $('body')
    });

    this.cleartimeout_Drag = setTimeout(() => { $("#alert").draggable({ containment: $('body') }); this.dragEvent(); }, 500)

    return false;
  }

  //end of draggblescreen


}
